import { useState } from 'react';

const getBackgroundColor = (char) => {
  let backgroundColor = 'bg-primary';
  switch (true) {
    case char < 'D':
      backgroundColor = 'bg-red';
      break;
    case char < 'H':
      backgroundColor = 'bg-green';
      break;
    case char < 'L':
      backgroundColor = 'bg-primary-90';
      break;
    case char < 'P':
      backgroundColor = 'bg-yellow';
      break;
    case char < 'T':
      backgroundColor = 'bg-grey';
      break;
    default:
      backgroundColor = 'bg-primary';
  }
  return backgroundColor;
};

export const UserInitialIcon = ({ name, imageUrl, classes = 'w-8' }) => {
  const [url, setUrl] = useState(imageUrl);
  const firstLetter = name ? name[0].toUpperCase() : '-';
  const backgroundColor = getBackgroundColor(firstLetter);
  const handleImageError = (event) => {
    event.target.style.display = 'none';
    setUrl(null);
  };
  return (
    <>
      {url ? (
        <img
          className={`object-contain rounded-full min-h-8 ${classes}`}
          src={imageUrl}
          alt='profile-img'
          onError={handleImageError}
        />
      ) : (
        <div
          title={name}
          className={`grid place-content-center w-8 h-8 font-bold text-white rounded-full ${backgroundColor} ${classes}`}
        >
          {firstLetter}
        </div>
      )}
    </>
  );
};
