import { Space } from 'antd';
import { Document, View } from 'assets/icons';
import IconButton from 'components/button/IconButton';

// duplicate lest it is used somewhere else.
export const getColumns = (setCurActiveRecordDoc) => [
  {
    key: 'name',
    title: 'Document Name',
    dataIndex: 'name',
    render: (dataIndex, record) => (
      <div className='flex items-center gap-1'>
        {record.file.slice(-3) === 'doc' ? (
          <Document svgClass='w-6 h-6' />
        ) : (
          <Document svgClass='w-6 h-6' />
        )}
        <span>{dataIndex}</span>
      </div>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'right',
    render: (_, record) => (
      <Space size='middle'>
        <IconButton className='group disabled:cursor-not-allowed'>
          <button
            onClick={() => setCurActiveRecordDoc(record?.file)}
            target='_blank'
            rel='noreferrer'
          >
            <View svgClass='stroke-primary group-disabled:stroke-grey w-6 h-6' />
          </button>
        </IconButton>
      </Space>
    ),
  },
];

export const columns = [
  {
    key: 'name',
    title: 'Document Name',
    dataIndex: 'name',
    render: (dataIndex, record) => (
      <div className='flex items-center gap-1'>
        {record.file.slice(-3) === 'doc' ? (
          <Document svgClass='w-6 h-6' />
        ) : (
          <Document svgClass='w-6 h-6' />
        )}
        <span>{dataIndex}</span>
      </div>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'right',
    render: (_, record) => (
      <Space size='middle'>
        <IconButton className='group disabled:cursor-not-allowed'>
          <a href={process.env.REACT_APP_DOC_URL + record?.file} target='_blank' rel='noreferrer'>
            <View svgClass='stroke-primary group-disabled:stroke-grey w-6 h-6' />
          </a>
        </IconButton>
      </Space>
    ),
  },
];
