import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from 'store/context';

const sidebarOptions = [
  {
    option: 'Change Password',
    name: 'PasswordForm',
  },
  {
    option: 'Company Settings',
    name: 'ComapnyForm',
  },
];

const Sidebar = ({ setForm, form }) => {
  const navigate = useNavigate();
  const { user } = useContext(Context);

  const MENU = user.user_type === 'client' ? sidebarOptions : sidebarOptions.slice(0, 1);
  return (
    <div className='h-[75vh] overflow-y-auto w-[15%] bg-white rounded-lg  p-2 '>
      {MENU.map((item) => (
        <button
          key={item.name}
          className={` my-1  bordered py-2 rounded-lg w-full text-center  hover:border-primary-90 ${
            form === item.name
              ? 'bg-primary-90 text-white hover:text-black '
              : 'bg-white hover:text-primary-90'
          } `}
          onClick={() => setForm(item.name)}
        >
          <h2>{item.option}</h2>
        </button>
      ))}
      <button
        onClick={() => navigate('/')}
        className=' my-1  bordered py-2 rounded-lg w-full text-center  hover:border-primary-90'
      >
        <h2>Go To dashboard</h2>
      </button>
    </div>
  );
};

export default Sidebar;
