/** external deps */
import React, { useState } from 'react';
import { Tabs } from 'antd';
/** internal deps */

import PosForm from './posForm';
import InsurerForm from './InsurerForm';
import AllCommission from './allcommission';
import { ContentHeader } from 'components';
import { breadcrumbObj } from './data';
import EmployeeCommisionForm from './employeeCommisionForm';

const CommissionForm = (props = {}) => {
  const permissions = props?.permissions || {};
  const [renderForm, setRenderForm] = useState('insurer');

  const items = [
    {
      key: 'insurer',
      label: 'Insurer Commission',
    },
    {
      key: 'special',
      label: 'Special Agent Commission',
    },
    {
      key: 'common',
      label: 'Common Agent Commission',
    },
    {
      key: 'employee',
      label: 'Employee Commission',
    },
  ];
  const onChange = (key) => {
    setRenderForm(key);
  };

  const renderTabBar = (props, DefaultTabBar) => {
    return (
      <div className={` border border-primary-60 rounded-md w-fit flex  `}>
        {items &&
          items.map(({ key, label }) => {
            return (
              <div
                className={
                  renderForm === key
                    ? `bg-primary-60 cursor-pointer px-2 py-1  rounded font-semibold leading-normal text-xxs text-white`
                    : `bg-transparent cursor-pointer  px-2 py-1 mr-1 text-black rounded font-normal leading-normal text-xxs`
                }
                onClick={() => {
                  onChange(key);
                }}
                {...props}
              >
                {label}
              </div>
            );
          })}
      </div>
    );
  };

  const tabs = (
    <div className='mb-4 bg-white rounded-lg mt-2 ml-2 flex  items-center '>
      <Tabs
        onChange={onChange}
        items={items}
        defaultActiveKey={renderForm}
        renderTabBar={renderTabBar}
      />
    </div>
  );

  return (
    <div className='grid gap-3'>
      <ContentHeader
        title={<p className='font-semibold text-s'>Commission</p>}
        // data={breadcrumbObj}
        permissions={permissions}
        showSearch={false}
      />
      {renderForm === 'insurer' && <InsurerForm tabs={tabs} />}
      {renderForm === 'special' && <PosForm tabs={tabs} />}
      {renderForm === 'common' && <AllCommission tabs={tabs} />}
      {renderForm === 'employee' && <EmployeeCommisionForm tabs={tabs} />}
    </div>
  );
};

export default CommissionForm;
