import {
  CustomModal,
  DateTime,
  FormContainer,
  SearchAPI,
  SelectInput,
  TextInput,
  Toast,
} from 'components';
import { LabelValue, LogsButtons, SubmitButtons, responseOptions } from '../data';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import './styles.css';
import dayjs from 'dayjs';
import { getContacts } from 'data/form-data';
import AddMultiProduct from './AddMultiProduct';
import { useLeads } from '../store';
import { useShallow } from 'zustand/react/shallow';
import { useParams } from 'react-router-dom';
import { elementData } from 'elements';
import { Context } from 'store/context';
import { Anchor, Checkbox } from 'antd';
import Button from 'components/button';
import { customerFields } from 'screens/customers/add-customer/data';
import { NOTIFICATION_MSG } from 'constants';
import { addCustomer } from 'service/api-service';

const CreateLead = ({ permissions }) => {
  const { id: leadId } = useParams();
  const { user } = useContext(Context);

  const {
    renewalDate,
    followUpDate,
    reason,
    addBasicLeadsDetails,
    client,
    clientContact,
    response,
    assignTo,
    productArray,
    initQuotationData,
    addProductsChecked,
  } = useLeads(
    useShallow((state) => ({
      renewalDate: state.createLead.renewalDate,
      reason: state.createLead.reason,
      followUpDate: state.createLead.followUpDate,
      addBasicLeadsDetails: state.addBasicLeadsDetails,
      updateLeadId: state.updateLeadId,
      client: state.createLead.client,
      clientContact: state.createLead.clientContact,
      resetLeadsStore: state.resetLeadsStore,
      response: state.createLead.response,
      assignTo: state.createLead.assignTo,
      initQuotationData: state.initQuotationData,
      productArray: state.createLead.productArray,
      addProductsChecked: state.createLead.addProductsChecked,
    })),
  );
  let retailId;

  const getLocalItems = async () => {
    try {
      const customerFields = JSON.parse(localStorage.getItem('customerFields')) || [];
      const insuredDetailsField = customerFields.find(
        (field) => field?.title === 'Insured Details',
      );
      const customerTypeField = insuredDetailsField?.fields.find(
        (dt) => dt?.name === 'customer_type',
      );
      const retailOption = customerTypeField?.options?.find((option) => option.name === 'Retail');
      retailId = retailOption?.id;
      return retailId;
    } catch (error) {
      console.error('Error fetching retailId:', error);
      return null;
    }
  };

  const [isAddingCustomer, setAddingCustomer] = useState(false);
  const [checkSubmit, setCheckSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const userBranches = Array.isArray(user?.branch) ? user?.branch[0] : user?.branch;
  const [addCustomerInitials, setAddCustomerInitials] = useState({
    customer_name: '',
    email_address: '',
    contact_number: '',
    customer_type: null,
  });

  useEffect(() => {
    const fetchCustomerType = async () => {
      try {
        const retailId = await getLocalItems();
        setAddCustomerInitials((prevInitials) => ({
          ...prevInitials,
          customer_type: retailId,
        }));
      } catch (error) {
        console.error('Error fetching retailId:', error);
      }
    };

    fetchCustomerType();
  }, []);

  useLayoutEffect(() => {
    if (leadId !== 'new') {
      initQuotationData(leadId);
    }
  }, [initQuotationData, leadId]);

  const toggleAddingCustomer = () => {
    setAddingCustomer((pre) => !pre);
  };

  const onCustomerSelect = useCallback(({ option }) => {
    addBasicLeadsDetails({ client: option });
  }, []);
  const onFollowupChange = (data) => {
    addBasicLeadsDetails({ followUpDate: data?.val });
  };
  const HandleInputReason = (data) => {
    addBasicLeadsDetails({ reason: data?.value });
  };
  const onResponseChange = (fields, data) => {
    addBasicLeadsDetails({ response: data?.value });
  };
  const getCustomerContact = useCallback((name, value) => {
    addBasicLeadsDetails({ clientContact: value });
  }, []);
  const handleCancelAssignee = useCallback(() => {
    addBasicLeadsDetails({ response: '' });
  }, []);
  const onassignTo = useCallback(({ option }) => {
    addBasicLeadsDetails({ assignTo: option });
  }, []);
  const handleCheckAddProducts = useCallback(
    (e) => {
      addBasicLeadsDetails({ addProductsChecked: e.target.checked });
    },
    [addBasicLeadsDetails],
  );

  const handleAddCustomer = async (data) => {
    const finalPayload = {
      ...data,
      branch_of: userBranches.id,
    };
    try {
      setLoading(true);
      const resp = await addCustomer({ data: finalPayload });
      if (resp?.data?.status) {
        const customer = {
          name: finalPayload?.customer_name,
          id: resp?.data?.data?.id,
          email_address: finalPayload?.email_address,
          contact_number: finalPayload?.contact_number,
          branch_of: finalPayload?.branch_of,
          customer_type: finalPayload?.customer_type,
        };
        addBasicLeadsDetails({
          client: customer,
        });
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      toggleAddingCustomer();
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='w-full bg-white p-4 min-h-[70vh] h-fit max-h-[70vh] overflow-y-auto relative '>
      <div id='leads' className='grid grid-cols-3 gap-y-5 gap-x-6 items-center  mb-4'>
        <LabelValue
          label='Select Client'
          required={true}
          value={
            <SearchAPI
              type='search'
              divClasses='mt-1'
              placeholder='Client'
              field={{ name: 'customer' }}
              onSelect={onCustomerSelect}
              defaultValue={client}
              searchParams='corp_name'
              onIconClick={() => addBasicLeadsDetails({ client: {} })}
              extraLabelProps={'contact_number'}
              extraRightRender={() => (
                <button type='button' onClick={toggleAddingCustomer}>
                  <span className='text-primary-90'>+ Client</span>
                </button>
              )}
            />
          }
        />
        {client && client?.customer_type?.name === 'Corporation' && (
          <LabelValue
            label='Contact'
            value={
              <SelectInput
                type='select'
                defaultValue={clientContact}
                placeholder={'Contact'}
                field={{ name: 'contact' }}
                dependantFetchOptions={async () => {
                  const resp = await getContacts(client?.id);
                  return resp || [];
                }}
                customOnChange={getCustomerContact}
              />
            }
          />
        )}
        <LabelValue
          label='Client Response'
          required={true}
          value={
            <SelectInput
              disabled={!client?.customer_type?.name}
              key={response}
              menuPlacement='bottom'
              type='select'
              defaultValue={response}
              placeholder={'Client Response'}
              field={{ name: 'client_response' }}
              options={responseOptions}
              customOnChange={onResponseChange}
            />
          }
        />
        {response === 'interested' && response !== null && user?.user_type === 'client' && (
          <LabelValue
            label='Assign To'
            value={
              <SearchAPI
                type='search'
                placeholder='Assign To'
                field={{ name: 'employee' }}
                defaultValue={assignTo}
                onSelect={onassignTo}
                divClasses='w-full bg-white'
                onIconClick={handleCancelAssignee}
              />
            }
          />
        )}

        {response !== 'interested' && response !== 'presently_insured' && response !== null && (
          <LabelValue
            label='Follow Up '
            fieldClass='mt-2'
            required={true}
            value={
              <DateTime
                type='date'
                disablePrev={true}
                defaultValue={followUpDate ? dayjs(followUpDate) : null}
                placeholder={'YYYY-MM-DD'}
                field={{ name: 'follow_up' }}
                customOnChange={(val) => onFollowupChange(val)}
              />
            }
          />
        )}
        {response === 'presently_insured' && (
          <LabelValue
            label='Renewal Date'
            required={true}
            value={
              <DateTime
                defaultValue={renewalDate ? dayjs(renewalDate) : null}
                placeholder='YYYY-MM-DD'
                customOnChange={({ val }) => addBasicLeadsDetails({ renewalDate: val })}
              />
            }
          />
        )}
        {response !== 'interested' && response !== null && (
          <LabelValue
            label='Reason'
            value={
              <TextInput
                InputClasses='reasonField'
                type='textarea'
                rows={4}
                divClasses='col-span-full'
                isMultiline={true}
                defaultValue={reason}
                placeholder={'Reason'}
                field={{ name: 'reason' }}
                customOnChange={(val) => HandleInputReason(val)}
              />
            }
          />
        )}
        {response === 'presently_insured' && (
          <div className='self-end'>
            <Checkbox checked={addProductsChecked} onChange={handleCheckAddProducts}>
              <p className='text-xs'>Add products</p>
            </Checkbox>
          </div>
        )}
      </div>
      {shouldShowForm(response, addProductsChecked) && (
        <div id='multiLeads'>
          {productArray?.map((item, index) => (
            <AddMultiProduct
              submitted={true}
              count={index + 1}
              initials={item}
              setCheckSubmit={setCheckSubmit}
            />
          ))}
          <AddMultiProduct setCheckSubmit={setCheckSubmit} />
        </div>
      )}

      {/* Add Client modal */}
      <CustomModal
        destroyOnClose={true}
        footer={''}
        open={isAddingCustomer}
        title={'Client Details'}
        onCancel={toggleAddingCustomer}
      >
        <div>
          <FormContainer
            accordionClass='grid grid-cols-1 md:grid-cols-2 gap-8'
            onSubmit={handleAddCustomer}
            fields={customerFields}
            initialValues={addCustomerInitials}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddingCustomer();
                    }}
                  />
                  <Button
                    permissions={permissions}
                    id={elementData.client.permissions.create}
                    text={'Add Customer'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting || !isValid ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    disabled={!isValid}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
      {shouldShowLogButtons(response, addProductsChecked) && <LogsButtons />}
    </div>
  );
};

export default CreateLead;

const shouldShowForm = (response, addProductsChecked) => {
  if (response === null) return false;
  if (response === 'interested') return true;
  if (response === 'presently_insured' && addProductsChecked) return true;
  return false;
};
const shouldShowLogButtons = (response, addProductsChecked) => {
  if (response === null) return false;
  if (response === 'interested') return false;
  if (response === 'not_interested') return true;
  if (response === 'not_pickup') return true;
  if (response === 'other') return true;
  if (response === 'presently_insured' && !addProductsChecked) return true;
  return false;
};
