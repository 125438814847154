export const tabData = [
  {
    label: 'all',
    value: '',
  },
  {
    label: 'today',
    value: 'today',
  },
  {
    label: 'last 7 days',
    value: 'week',
  },
  {
    label: 'last 30 days',
    value: 'month',
  },
  {
    label: 'last 3 months',
    value: 'quarter',
  },
];

export const DateRangeOptions = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Last 7 days',
    value: 'week',
  },
  {
    label: 'Last 30 days',
    value: 'month',
  },
  {
    label: 'Last 3 months',
    value: 'quarter',
  },
  {
    label: 'Custom Range',
    value: 'customRange',
  },
];

export const StatusOptions = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Not Started',
    value: 'not_started',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'Backlog',
    value: 'backlog',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
];

export const allColumns = [
  {
    title: <span className='text-red'>Not Started</span>,
    value: 'not_started',
    id: 'not_started',
  },
  {
    title: <span className='text-primary-90'>In Progress</span>,
    value: 'in_progress',
    id: 'in_progress',
  },
  {
    title: <span className='text-green'>Completed</span>,
    value: 'completed',
    id: 'completed',
  },
  {
    title: <span className='text-grey'>Pending Input</span>,
    value: 'pending_input',
    id: 'pending_input',
  },
  {
    title: <span className='text-yellow'>Backlog</span>,
    value: 'backlog',
    id: 'backlog',
  },
  {
    title: <span className='text-red-80'>Archived</span>,
    value: 'archived',
    id: 'archived',
  },
];
