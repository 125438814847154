import Request from '../client';
import { endpoints } from 'constants/endpoints';

export const getUserSalary = async ({ id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.payroll.list(id),
  });
  return resp;
};

export const getPayrollCheckList = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.payroll.check_list,
  });
  return resp;
};

export const getCheckedVals = async ({ date = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.payroll.checks(date),
  });
  return resp;
};
export const createCheckList = async ({ data = undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.payroll.create,
    data,
  });
  return resp;
};
export const editCheckList = async ({ data = undefined, id = '' }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.payroll.update(id),
    data,
  });
  return resp;
};
