/** external deps */
import React, { useEffect, useState, useContext } from 'react';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
  Toast,
} from 'components';
import { addTicketFields } from './data';
import { addTicket, getMyTickets, deleteMyTicket, updateMyTicket } from 'service/api-service';
import { DELETE, EDIT, Trash, View } from 'assets/icons';
import { prepareFormFieldsData } from 'utils';
import { Context } from 'store/context';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { NOTIFICATION_MSG } from 'constants';
import { rowCommonClass } from 'utils/common';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';

const RaiseTicket = (props = {}) => {
  const { user } = useContext(Context);
  const permissions = props?.permissions || [];
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [editInitials, setInitials] = useState();
  const [selectedTicket, setSelectedTicket] = useState();
  const [tableData, setTableData] = useState([]);
  const [total_page, setTotalPage] = useState();
  const [page, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  const [viewTicket, setViewTicket] = useState(false);
  const [data, setData] = useState('');

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addTicketFields,
  });

  const handleView = (data) => {
    setData(data);
    setViewTicket(true);
  };

  const formatClosingDate = (created) => {
    if (!created) return '';

    const formattedDate = dayjs(created).format('MMM DD');
    return formattedDate;
  };

  const columns = [
    {
      key: 'ticket_id',
      title: 'Ticket ID',
      dataIndex: 'ticket_id',
    },
    {
      key: 'created_by',
      title: 'Raised By',
      dataIndex: 'created_by',
      render: (created_by) => <p className='text-black'>{created_by.name || '\u2014'}</p>,
    },
    {
      key: 'created',
      title: 'Date',
      dataIndex: 'created',
      render: (created) => {
        return <span className='text-black'>{dayjs(created)?.format('MMM DD')}</span>;
      },
    },
    {
      key: 'reason',
      title: 'Reason',
      dataIndex: 'reason',
      render: (reason) => (
        <Tooltip className='truncate' title={reason}>
          {reason || '\u2014'}
        </Tooltip>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status) => <p className='text-black capitalize'>{status || '\u2014'}</p>,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <div className='flex gap-2 justify-end'>
          {/* <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.ticket.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.ticket.permissions.delete}
            onClick={() => {
              setSelectedTicket(record);
              toggleDeleteTicket();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton> */}
          <IconButton
            title='View'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            // id={elementData.ticket.permissions.delete}
            onClick={() => handleView(record)}
          >
            <View />
          </IconButton>
        </div>
      ),
    },
  ];

  /* funcs */
  const onAddTicket = async (data) => {
    const resp = await addTicket({ data });
    if (resp?.data?.status) {
      fetchTickets({ query: searchQuery, page: page });
      setAddModal(false);
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  const onDeleteTicket = async () => {
    const id = selectedTicket?.id;
    if (!id) return;
    setLoading(true);
    const resp = await deleteMyTicket({ id, data: { deleted: true } });
    if (resp?.data?.status) {
      fetchTickets({ query: searchQuery, page: page });
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Ticket deleted successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteTicket();
    setLoading(false);
  };

  const onEditTicket = async (data) => {
    setLoading(true);
    const resp = await updateMyTicket({
      id: selectedTicket?.id,
      data: { reason: data.reason },
    });
    toggleAddTicket();
    if (resp?.data?.status) {
      fetchTickets({ query: searchQuery, page: page });
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const toggleAddTicket = () => {
    setInitials(undefined);
    setAddModal((pre) => {
      return !pre;
    });
  };

  const toggleDeleteTicket = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    fetchTickets({ query: query, page: page });
  };

  const onEditPress = (record) => {
    setInitials(record);
    setSelectedTicket(record);
    setAddModal(true);
  };

  const fetchTickets = async ({ query = '', page = 1 }) => {
    setLoading(true);
    const resp = await getMyTickets({ id: user.id, query: query, page: page });
    if (resp.data.status) {
      setTableData(resp.data.data.result);
      setPageNum(resp.data.data.current_page);
      setTotalPage(resp.data.data.total_page);
    }
    setLoading(false);
  };

  const handlePagination = (pageNum) => {
    if (pageNum?.current) {
      setPageNum(pageNum?.current);
      fetchTickets({ query: searchQuery, page: pageNum?.current });
    }
  };

  useEffect(() => {
    if (user.id) {
      fetchTickets({ query: searchQuery, page: page });
    }
  }, [1]);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        title={<p className='font-semibold text-s'>Raise A Ticket</p>}
        btnText='+ Ticket'
        //buttonId={elementData?.ticket?.permissions?.create}
        onClick={toggleAddTicket}
      />
      <TableComponent
        loading={loading}
        columns={columns}
        dataSource={tableData}
        getRowClassName={rowCommonClass}
        pageSize={10}
        total={total_page}
        onChange={handlePagination}
        defaultPage={page}
      />

      {/* add ticket modal */}
      <CustomModal
        title={editInitials ? 'Edit Ticket' : 'Add Ticket'}
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddTicket}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10'>
            {editInitials
              ? 'Edit ticket details'
              : 'To add a new ticket, enter the details of the ticket in the input field below.'}
          </span>
          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditTicket : onAddTicket}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddTicket();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    // id={
                    //   editInitials
                    //     ? elementData.ticket.permissions.update
                    //     : elementData.ticket.permissions.create
                    // }
                    text={editInitials ? 'Update Ticket' : 'Add Ticket'}
                    variant='primary'
                    classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* delete ticket confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteTicket}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the ticket details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteTicket}
            />
            <Button
              text='Delete Ticket'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteTicket}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
      {/* View ticket Modal */}
      <CustomModal
        destroyOnClose={true}
        open={viewTicket}
        onCancel={setViewTicket.bind(this, false)}
        footer={''}
      >
        <div className='flex flex-col'>
          <span className='font-medium text-l 2xl:text-xxl'>Ticket Details</span>
        </div>
        {/* <Divider/> */}
        <div className='grid gap-8 grid-cols-3'>
          <div className='flex flex-col flex-grow'>
            <span className={`text-xs font-medium capitalize`}>Ticket Id</span>
            <span className={`text-xxs break-words capitalize`}>{data?.ticket_id}</span>
          </div>
          <div className='flex flex-col flex-grow'>
            <span className={`text-xs font-medium capitalize`}>Date</span>
            <span className={`text-xxs break-words capitalize`}>
              {formatClosingDate(data?.created)}
            </span>
          </div>
          <div className='flex flex-col flex-grow'>
            <span className={`text-xs font-medium capitalize`}>Name</span>
            <span className={`text-xxs break-words capitalize`}>{data?.created_by?.name}</span>
          </div>
          <div className='flex flex-col flex-grow'>
            <span className={`text-xs font-medium capitalize`}>Reason</span>
            <span className={`text-xxs break-words capitalize`}>{data?.reason}</span>
          </div>
          <div className='flex flex-col flex-grow'>
            <span className={`text-xs font-medium capitalize`}>Status</span>
            <span className={`text-xxs break-words capitalize`}>{data?.status}</span>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default RaiseTicket;
