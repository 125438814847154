import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';

import { CalenderCarousel, Button, Toast } from 'components';
import CreateSheet from './CreateSheet';
import ViewTable from './ViewTable';
import ViewSheet from './ViewSheet';
import { getCheckedVals } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';

const SalarySheet = (props = {}) => {
  const permissions = props?.permissions || [];
  const [activeStep, setActiveStep] = useState(1);
  const [data, setData] = useState([]);
  const [grossData, setGrossData] = useState([]);
  const [id, setID] = useState();
  const [timeline, setTimeline] = useState({
    year: dayjs().format('YYYY'),
    month: dayjs().format('M'),
    formatted: dayjs().format('MMM YYYY'),
  });
  /* func */
  const fetchData = async () => {
    const resp = await getCheckedVals({ date: dayjs(timeline?.formatted).format('MM-YYYY') });
    if (resp?.data?.status) {
      setData(resp?.data?.data);

      const Array = [];
      Array.push(resp?.data?.data);
      setGrossData(Array);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  useEffect(() => {
    fetchData();
  }, [timeline]);

  return (
    <div>
      {activeStep === 1 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className='grid gap-3'
        >
          <CalenderCarousel timeline={timeline} setTimeline={setTimeline} />
          <div className='bg-white border border-grey-40 rounded-lg px-4 py-2 flex justify-between'>
            <div>
              <h2 className='text-xs font-semibold'>{timeline?.formatted}</h2>
              <p className='text-xxxs'>
                Cutoff from{' '}
                {dayjs(`${timeline?.year}-${timeline?.month}`)
                  .startOf('month')
                  .format('DD MMM YYYY')}{' '}
                to{' '}
                {dayjs(`${timeline?.year}-${timeline?.month}`).endOf('month').format('DD MMM YYYY')}
              </p>
            </div>
            <div className='flex gap-3'>
              {!data?.inputs && (
                <Button
                  text='Create Payroll'
                  onClick={() => setActiveStep(2)}
                  permissions={permissions}
                  id={
                    elementData.salarySheet.permissions.create ||
                    elementData.salarySheet.permissions.update
                  }
                />
              )}
              <Button text='View Payroll' onClick={() => setActiveStep(3)} />
            </div>
          </div>
          <ViewSheet fetchData={fetchData} timeline={timeline} data={data} id={data?.id} />
        </motion.div>
      )}
      {activeStep === 2 && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <CreateSheet
            permissions={permissions}
            timeline={timeline}
            onCancel={() => setActiveStep(1)}
            onNext={() => setActiveStep(3)}
            setID={setID}
          />
        </motion.div>
      )}
      {activeStep === 3 && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <ViewTable
            fetchData={fetchData}
            timeline={timeline}
            grossData={grossData}
            setActiveStep={setActiveStep}
            id={data?.id || id}
          />
        </motion.div>
      )}
    </div>
  );
};

export default SalarySheet;
