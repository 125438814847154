import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ContentHeader, TableComponent, Toast } from 'components';
import { AssigneebreadcrumbObj } from './data';
import AssignModal from './modal/AssignModal';
import { NOTIFICATION_MSG } from 'constants';
import { getAssignees } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const columns = [
  {
    key: 'emp_code',
    title: 'Employee Code',
    dataIndex: 'emp_code',
    render: (data) => data || '\u2014',
  },
  {
    key: 'name',
    title: 'Employee',
    dataIndex: 'name',
    render: (data) => data || '\u2014',
  },
  {
    key: 'dep_id',
    title: 'Department',
    dataIndex: 'dep_id',
    render: (data) => {
      return (
        <div className='flex flex-wrap gap-1'>
          {data?.map((item) => (
            <p className='px-2 bg-grey-30 text-xxxs w-fit rounded-md'>{item?.name}</p>
          ))}{' '}
        </div>
      );
    },
  },
  {
    key: 'des_id',
    title: 'Designation',
    dataIndex: 'des_id',
    render: (data) => data?.name || '\u2014',
  },
  {
    key: 'role_id',
    title: 'Role',
    dataIndex: 'role_id',
    render: (data) => data?.name || '\u2014',
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    render: (data) => data || '\u2014',
  },
  {
    key: 'contact',
    title: 'Contact',
    dataIndex: 'contact',
    render: (data) => data || '\u2014',
  },
];

const ShiftAssignees = ({ permissions }) => {
  const { id } = useParams();
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  //add api

  const fetchAssignees = async (pageNum = 1) => {
    setLoading(true);
    try {
      const res = await getAssignees(id, pageNum);
      if (res?.data?.data?.result) {
        setData(res?.data?.data?.result);
        setTotal(res?.data?.data?.total_page);
      }
    } catch (error) {
      Toast('error', NOTIFICATION_MSG.error, 'Please try again');
    }
    setLoading(false);
  };

  useState(() => {
    fetchAssignees();
  }, []);

  return (
    <>
      <ContentHeader
        permissions={permissions}
        // data={AssigneebreadcrumbObj}
        title={<p className='text-s font-semibold'>Assignee List</p>}
        showSearch={false}
        btnText='+ Assignees'
        onClick={setOpenAssignModal.bind(true)}
      />
      <div className='bg-white rounded-md h-full mt-4 p-4 '>
        <TableComponent
          columns={columns}
          dataSource={data}
          loading={loading}
          total={total}
          onChange={fetchAssignees}
          getRowClassName={rowCommonClass}
        />
      </div>
      <AssignModal
        id={id}
        onSuccess={() => fetchAssignees()}
        open={openAssignModal}
        onCancel={() => setOpenAssignModal(false)}
      />
    </>
  );
};

export default ShiftAssignees;
