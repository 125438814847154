import React from 'react';

/* internal deps */
import { Close } from 'assets/icons';
import IconButton from 'components/button/IconButton';

const EmailChips = ({ error, tags, addEmails, handleRemoveEmail, value, onChange, received }) => {
  const renderEmailTags = (tag, index) => (
    <li key={index} className='bg-grey-70 p-1 rounded-md w-fit flex gap-2'>
      <span>{tag}</span>
      {!received && (
        <IconButton onClick={() => handleRemoveEmail(tag)} type='button'>
          <Close className='w-3 h-2 stroke-grey hover:stroke-primary transition-all duration-300' />
        </IconButton>
      )}
    </li>
  );

  return (
    <div>
      <ul className='flex gap-2 py-2 rounded flex-wrap'>{tags.map(renderEmailTags)}</ul>

      <input
        className='border-grey-70 border-b w-full p-2 text-sm outline-0'
        type='email'
        value={value}
        placeholder={received ? 'From' : 'To'}
        onKeyUp={(e) => (e.key === 'Enter' ? addEmails(e) : null)}
        to={tags}
        onChange={onChange}
        disabled={received}
      />
      {error && <p className='text-red'>{error}</p>}
    </div>
  );
};

export default EmailChips;
