import axios from 'axios';
import Request from './client';
import { data } from 'jquery';

export const inquiryManagement = async (
  props = {
    data: '',
    method: 'get',
    id: '',
    customer_id: '',
    employee_id: '',
    category_id: '',
    product_type: '',
    sorting: '',
    query: '',
    pageNum: 1,
  },
) => {
  const resp = await Request({
    method: props.method,
    API:
      props.method === 'delete' || props.method === 'patch'
        ? `inquiry/?id=${props.id}`
        : `inquiry/?${props?.query || ''}`,
    data: props?.data,
  });
  return resp;
};

export const advancedAssign = async (props) => {
  const resp = await Request({
    method: 'patch',
    API: `inquiry/advance/setting`,
    data: props?.data,
  });
  return resp;
};

export const getInquiryDetails = async ({ id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: `inquiry/?id=${id}`,
  });
  return resp;
};
export const loginInquiry = async () => {
  const resp = await Request({
    method: 'post',
    API: `icici/login`,
  });
  return resp;
};
export const IcicEligibility = async ({ data, token }) => {
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json', // Assuming you're sending JSON data
    },
  };
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}icici/eligibility`,
      data,
      axiosConfig,
    );
    return resp;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};
export const submitOnlineQuote = async ({ data, token }) => {
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json', // Assuming you're sending JSON data
    },
  };
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}icici/life-insurance`,
      data,
      axiosConfig,
    );
    return resp;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};
export const submitIcicPatner = async ({ data, token }) => {
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json', // Assuming you're sending JSON data
    },
  };
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}icici/partner`,
      data,
      axiosConfig,
    );
    return resp;
  } catch (error) {
    console.log('error', error);
    throw error;
  }
};

export const searchQuotation = async (
  props = {
    query: '',
  },
) => {
  const resp = await Request({
    method: 'get',
    API: `inquiry/?q=${props?.query || ''}&page=1&status=quoted`,
  });
  return resp;
};

export const searchProposals = async (
  props = {
    query: '',
  },
) => {
  const resp = await Request({
    method: 'get',
    API: `inquiry/?q=${props?.query || ''}&page=1&status=proposals`,
  });
  return resp;
};
