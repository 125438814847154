/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';

/** internal deps */
import { CustomModal, Toast, FormContainer, IconButton, TableComponent, Button } from 'components';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import AddTarget from '../../targets/AddTarget';
import { targetsManagement } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { prepareFormFieldsData } from 'utils';
import { columns, fieldForms } from './data';
import { EDIT, View } from 'assets/icons';
import { rowCommonClass } from 'utils/common';

const DynamicTableData = (props) => {
  const permissions = props?.permissions || {};
  const { loading, targets = [], getTargets, targetId, total_page, page, handlePagination } = props;

  let navigate = useNavigate();

  const [addTarget, setAddTarget] = useState(false);
  const [editInitials, setEditInitials] = useState();
  const [assignEmp, setAssignEmp] = useState(false);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: fieldForms,
  });

  const handleAssignEmployee = async (data) => {
    const resp = await targetsManagement({
      data: data,
      method: 'patch',
      target_id: targetId,
    });

    if (resp?.data?.status) {
      Toast(
        'success',
        NOTIFICATION_MSG.success,
        resp?.data?.msg || 'Employee Assigned successfully.',
      );
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleAssignEmp();
    getTargets();
  };

  const toggleAddTarget = () => setAddTarget((pre) => !pre);
  const toggleAssignEmp = () => setAssignEmp((pre) => !pre);

  const onEditGoals = useCallback((data) => {
    const record = { ...data, type: data?.type?.id };
    setEditInitials(record);
    toggleAddTarget();
  }, []);

  useEffect(() => {
    getTargets({});
  }, []);

  return (
    <main>
      <div className='mt-3 '>
        <div className='z-10'>
          <TableComponent
            loading={loading}
            noDataAction={getTargets}
            getRowClassName={rowCommonClass}
            columns={[
              ...columns,
              {
                title: 'Action',
                key: 'action',
                align: 'left',
                render: (_, record) => (
                  <div className='flex gap-2 justify-start'>
                    <IconButton
                      title='View'
                      permissions={permissions}
                      className='group disabled:cursor-not-allowed'
                      id={elementData.Goals.permissions.view}
                      onClick={() => {
                        navigate(`/view-goal/${record.id}`, {
                          state: { values: record },
                        });
                      }}
                    >
                      <View svgClass='stroke-primary group-disabled:stroke-grey' />
                    </IconButton>
                    <IconButton
                      title='Edit'
                      permissions={permissions}
                      className='group disabled:cursor-not-allowed'
                      id={elementData.Goals.permissions.update}
                      onClick={onEditGoals.bind(this, record)}
                    >
                      <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                    </IconButton>
                  </div>
                ),
              },
            ]}
            dataSource={targets}
            pageSize={10}
            total={total_page}
            onChange={handlePagination}
            defaultPage={page}
          />
        </div>
      </div>

      <CustomModal destroyOnClose={true} open={addTarget} onCancel={toggleAddTarget} footer={''}>
        <AddTarget
          employee={props.employee}
          employee_id={props.employee_id}
          getTargets={getTargets}
          initialValues={editInitials}
          showBack={false}
          goBack={toggleAddTarget}
          type={'edit'}
        />
      </CustomModal>

      <CustomModal
        width={450}
        destroyOnClose={true}
        open={assignEmp}
        onCancel={toggleAssignEmp}
        footer={''}
      >
        <FormContainer
          accordionClass='grid grid-cols-1  gap-8'
          fields={finalFields}
          initialValues={initialValues}
          onSubmit={handleAssignEmployee}
          customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
            return (
              <>
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAssignEmp();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={elementData.Leads.permissions.create}
                    text={'Assign Employee'}
                    type='button'
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              </>
            );
          }}
        />
      </CustomModal>
    </main>
  );
};

export default SecureComponent(DynamicTableData);
