import { endpoints } from 'constants/endpoints';
import Request from './client';

export const addRole = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.role.create,
    data,
  });
  return resp;
};

export const getRoles = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.role.list,
  });
  return resp;
};

export const deleteRole = async ({ id }) => {
  const resp = await Request({
    method: 'delete',
    API: endpoints.role.delete(id),
  });
  return resp;
};

export const getRolePermissions = async ({ id }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.role.permissions(id),
  });
  return resp;
};

export const updateRolePermissions = async ({ id, data }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.role.permissions(id),
    data,
  });
  return resp;
};
