import Request from './client';
import { endpoints } from 'constants/endpoints';

export const designationManagement = async (props = { data: '', method: 'get', id: '' }) => {
  const resp = await Request({
    method: props.method,
    API:
      props.method === 'delete' || props.method === 'patch'
        ? endpoints.designation.designationId(props.id)
        : endpoints.designation.get,
    data: props?.data,
  });
  return resp;
};

export const getDesignation = async () => {
  const resp = await Request({
    method: 'get',
    API: 'designation',
  });
  return resp;
};
