import { isVisible } from '@testing-library/user-event/dist/utils';
import { Checkbox, DateTime, SelectInput, TextInput, Uploadbox } from 'components';
import { fields } from 'components/clauses/data';

export const interviewFormFields = (
  fieldRequired,
  setFieldRequired,
  checkboxRequired,
  setCheckboxRequired,
) => ({
  'Candidate Information': {
    fields: [
      {
        label: 'Candidate Name',
        name: 'name',
        id: 'name',
        placeholder: 'Candidate Name',
        required: true,
        type: 'text',
        component: TextInput,
      },
      {
        label: 'Email Address',
        name: 'email',
        id: 'email',
        placeholder: 'Email Address',
        required: true,
        type: 'email',
        component: TextInput,
      },
      {
        label: 'Contact',
        name: 'phone',
        id: 'phone',
        required: true,
        type: 'text',
        maxLength: 10,
        placeholder: 'Contact Number',
        component: TextInput,
        divClasses: '',
      },
      {
        label: 'Address',
        name: 'address',
        id: 'address',
        placeholder: 'Address',
        required: true,
        type: 'text',
        component: TextInput,
      },
      {
        label: 'Add CV',
        name: 'cv',
        id: 'cv',
        placeholder: 'CV',
        required: true,
        type: 'file',
        component: Uploadbox,
        dragger: false,
        accept: ['.pdf', '.png', 'jpeg', '.jpg'],
      },
    ],
  },
  'Other Information': {
    fields: [
      {
        label: 'Current Location',
        name: 'location',
        id: 'location',
        placeholder: 'Current Location',
        type: 'text',
        component: TextInput,
      },
      {
        label: 'Experience',
        name: 'exp',
        id: 'exp',
        placeholder: 'Select Experience',
        type: 'text',
        component: SelectInput,
        options: [
          {
            label: '0',
            value: '0',
          },
          {
            label: '1-3 years',
            value: '1-3 years',
          },
          {
            label: '3-5 years',
            value: '3-5 years',
          },
          {
            label: '5-8 years',
            value: '5-8 years',
          },
          {
            label: '8+ years',
            value: '8+ years',
          },
        ],
        customChange: (field, value) => {
          if (value && value.value === '0') {
            setFieldRequired && setFieldRequired(false);
          } else {
            setFieldRequired && setFieldRequired(true);
          }
        },
      },
      {
        dependent: true,
        dependent_on: 'exp',
        dependent_value: '0',
        reverseDependent: true,
        label: 'Previous Company Name',
        name: 'pre_org',
        id: 'pre_org',
        placeholder: 'Previous Company Name',
        // required: fieldRequired,
        isVisible: false,
        type: 'text',
        component: TextInput,
      },
      {
        dependent: true,
        dependent_on: 'exp',
        dependent_value: '0',
        reverseDependent: true,
        label: 'Previous Company Email',
        name: 'pre_email',
        id: 'pre_email',
        placeholder: 'Previous Company Email',
        // required: fieldRequired,
        type: 'text',
        component: TextInput,
      },
      {
        dependent: true,
        dependent_on: 'exp',
        dependent_value: '0',
        reverseDependent: true,
        label: 'Company Contact No.',
        name: 'pre_con',
        id: 'pre_con',
        placeholder: 'Company Contact No.',
        // required: fieldRequired,
        type: 'text',
        component: TextInput,
      },
      {
        label: 'Current CTC',
        name: 'current_ctc',
        id: 'current_ctc',
        placeholder: 'Current CTC',
        type: 'number',
        component: TextInput,
      },
      {
        label: 'Expectation CTC',
        name: 'exp_ctc',
        id: 'exp_ctc',
        placeholder: 'Expectation CTC',
        type: 'number',
        component: TextInput,
      },
      {
        label: 'Reason For Change',
        name: 'reason',
        id: 'reason',
        placeholder: 'Reason For Change',
        type: 'text',
        component: TextInput,
        divClasses: 'col-span-full',
        isMultiline: true,
        rows: 4,
      },
      {
        label: 'Ready To Relocate',
        name: 'relocate',
        id: 'relocate',
        type: 'checkbox',
        component: Checkbox,
        divClasses: 'self-end',
      },
      {
        label: 'Notice Period',
        name: 'notice',
        id: 'notice',
        type: 'checkbox',
        component: Checkbox,
        divClasses: 'self-end',
        customOnChange: (field, value) => {
          if (value === true) {
            setCheckboxRequired(true);
          } else {
            setCheckboxRequired(false);
          }
        },
      },
      {
        dependent: true,
        dependent_on: 'notice',
        dependent_value: true,
        label: 'Notice Period Days',
        name: 'n_days',
        id: 'n_days',
        placeholder: 'Notice Period Days',
        required: checkboxRequired,
        type: 'text',
        component: TextInput,
      },
    ],
  },
  'Interview Information': {
    fields: [
      {
        label: 'Availability For Interview',
        name: 'aval',
        id: 'aval',
        placeholder: 'YYYY-MM-DD',
        type: 'text',
        component: DateTime,
        showTime: false,
      },
      {
        label: 'Mode of Interview',
        name: 'type',
        id: 'type',
        placeholder: 'Mode of Interview',
        type: 'select',
        component: SelectInput,
        options: [
          {
            label: 'Walk - in interview',
            value: 'walk_in',
          },
          {
            label: 'Online interview',
            value: 'online',
          },
          {
            label: 'Telephonic',
            value: 'telephonic',
          },
        ],
      },
    ],
  },
});
