import Request from 'service/api-service/client';
// searchApis

export const searchApis = async (props = { api: '', query: '', customer_id: '' }) => {
  const resp = await Request({
    method: 'get',
    API: `search/${props.api}?q=${props.query || ''}&customer_id=${props.customer_id || ''}`,
  });
  return resp;
};

// searchInsuranceApis

export const searchInsuranceApis = async (props = { query: '', id: '' }) => {
  const resp = await Request({
    method: 'get',
    API: `search/insurance_category?q=${props.query || ''}&id=${props.id || ''}`,
  });
  return resp;
};
// searchQuotationApis

export const searchQuotationApis = async (props = { query: '' }) => {
  const resp = await Request({
    method: 'get',
    API: `search/inquiry?q=${props.query || ''}&status=proposals`,
  });
  return resp;
};
export const searchIcici = async (props = { query: '', api: '' }) => {
  const resp = await Request({
    method: 'get',
    API: `${props?.api || ''}?q=${props.query || ''}`,
  });
  return resp;
};
