import React from 'react';
import { Input, DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import './styles.css';
const replaceTags = (str, state) => {
  for (let key in state) {
    // const reg = new RegExp('[' + key + ']', 'g');
    if (state[key])
      str = str?.replace(
        '[' + key + ']',
        state[key].format ? state[key].format('DD-MM-YYYY') : state[key],
      ); //.replace("[", "").replace("]", "")
  }

  return str;
};

const today = dayjs().format('DD-MM-YYYY');

const Template = ({ fields, template, updateState, formState }) => {
  return (
    <div id='custom-form' className='flex h-full ob '>
      <ConfigProvider
        theme={{
          token: {
            colorBorder: '#D1EAF9',
            activeBorderColor: '#D1EAF9',
            multipleItemBorderColor: '#D1EAF9',
          },
        }}
      >
        <div className='bg-white w-1/2 p-4 h-full overflow-auto text-xxs'>
          {fields.includes('letter_number') && (
            <div>
              <label>Letter Number</label>
              <Input
                placeholder='Letter Number...'
                className='bg-[#F5FAFD] border border-[#D1EAF9]'
                onChange={updateState('letter_number')}
                value={formState?.letter_number}
              />
            </div>
          )}
          {fields.includes('candidate_name') && (
            <div className='mt-4'>
              <label>Candidate Name</label>
              <Input
                className='bg-[#F5FAFD] border border-[#D1EAF9]'
                placeholder='Candidate Name...'
                onChange={updateState('candidate_name')}
                value={formState?.candidate_name}
              />
            </div>
          )}
          {fields.includes('designation') && (
            <div className='mt-4'>
              <label>Designation</label>
              <Input
                className='bg-[#F5FAFD] border border-[#D1EAF9]'
                placeholder='Designation...'
                onChange={updateState('designation')}
                value={formState?.designation}
              />
            </div>
          )}
          {fields.includes('joining_date') && (
            <div className='mt-4'>
              <label>Joining Date</label>
              <div>
                <DatePicker
                  placeholder='Joining Date...'
                  onChange={updateState('joining_date')}
                  value={formState?.joining_date}
                  className='w-full p-2 bg-[#F5FAFD]  border-[#D1EAF9] border hover:border-[#D1EAF9] '
                  format='DD-MM-YYYY'
                />
              </div>
            </div>
          )}
          {fields.includes('address') && (
            <div className='mt-4'>
              <label>Candidate Address</label>
              <Input.TextArea
                className='bg-[#F5FAFD] border border-[#D1EAF9]'
                placeholder='Candidate Address...'
                onChange={updateState('address')}
                value={formState?.address}
              />
            </div>
          )}
          {fields.includes('company_name') && (
            <div className='mt-4'>
              <label>Company Name</label>
              <Input
                placeholder='Company Name...'
                onChange={updateState('company_name')}
                value={formState?.company_name}
              />
            </div>
          )}
          {fields.includes('company_address') && (
            <div className='mt-4'>
              <label>Company Address</label>
              <Input.TextArea
                placeholder='Company Addres...'
                onChange={updateState('company_address')}
                value={formState?.company_address}
              />
            </div>
          )}
          {fields.includes('hr_name') && (
            <div className='mt-4'>
              <label>HR Name</label>
              <Input
                className='bg-[#F5FAFD] border border-[#D1EAF9]'
                placeholder='HR Name...'
                onChange={updateState('hr_name')}
                value={formState?.hr_name}
              />
            </div>
          )}
          {fields.includes('hr_name') && (
            <div className='mt-4'>
              <label>Issue Date</label>
              <div>
                <DatePicker
                  placeholder='Issue Date...'
                  onChange={updateState('today_date')}
                  value={formState?.today_date}
                  className='w-full p-2 bg-[#F5FAFD] border border-[#D1EAF9]'
                  format='DD-MM-YYYY'
                />
              </div>
            </div>
          )}
        </div>

        <div
          className={`bg-grey-90 ${fields.length ? 'h-full' : 'h-[70vh]'} overflow-auto p-4 flex-1`}
        >
          <div className='bg-white p-8 pb-16 bordered min-h-full'>
            <div className='font-semibold text-l text-center p-4'> {template?.heading}</div>
            <div className='mt-4 text-right'>Date/Place:{today}</div>
            <div className='mt-4 whitespace-pre-line	'>
              {replaceTags(template?.upper_text, formState)}
            </div>

            <div className='mt-4 text-center'>{template?.subject}</div>
            <div className='mt-6'>{template?.request_line}</div>

            <div className='mt-4 whitespace-pre-line'>
              {replaceTags(template?.description, formState)}
            </div>

            <div className='mt-8 whitespace-pre-line'>
              {replaceTags(template?.bottom_text, formState)}
            </div>
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default Template;
