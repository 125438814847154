/** external deps */
import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
/** internal deps */
import { DELETE, EDIT, Trash } from 'assets/icons';
import { CustomModal, FormContainer, IconButton, TableComponent, Toast, Button } from 'components';
import { getEditedFields } from 'utils';
import {
  addSkillsFields,
  addLanguageFields,
  addSkillsInitials,
  addLanguageInitials,
  languageColumns,
  skillsColumns,
} from './data';
import { NOTIFICATION_MSG } from 'constants';
import {
  deleteLanguage,
  deleteSkill,
  getLanguage,
  getSkill,
  editSkill,
  addLanguage,
  addSkill,
  editLanguage,
} from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const tabClass =
  "relative transition-all after:transition-all after:duration-300 hover:text-primary-90 after:content-[''] after:block after:m-auto after:w-0 after:border-b-2 hover:after:border-primary-90 after:mt-2 hover:after:w-full cursor-pointer top-[1px]";

const LanguageSkills = ({ permissions = [] }) => {
  const [modalType, setModalType] = useState('language');
  const [showModal, setShowModal] = useState(false);
  const [editInitials, setEditInitials] = useState();
  const [showViewModal, setShowViewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tableColumns, setColumns] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('languages');
  const [languages, setLanguages] = useState([]);
  const [skills, setSkills] = useState([]);

  const finalInitial = {
    ...(modalType === 'language'
      ? {
          ...addLanguageInitials,
        }
      : {
          ...addSkillsInitials,
        }),
  };

  /** funcs */
  const toggleModal = (type = 'language') => {
    setModalType(type);
    setShowModal((pre) => !pre);
  };

  const toggleViewModal = () => setShowViewModal((pre) => !pre);
  const toggleDeleteModal = () => setShowDeleteModal((pre) => !pre);
  const handleTabChange = (isLanguage = false) => setActiveTab(isLanguage ? 'languages' : 'skills');

  const handleEdit = async (updatedData) => {
    setLoading(true);
    const data = getEditedFields({ initialValues: editInitials, updatedData });
    const id = updatedData?.id;
    if (!id) return;

    if (Object.keys(data).length) {
      const resp = await (activeTab === 'languages'
        ? editLanguage({ data, id })
        : editSkill({ data, id }));
      if (resp?.data?.status) {
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          `${activeTab === 'languages' ? 'Language' : 'Skill'} updated successfully`,
        );
        activeTab === 'languages' ? onGetLanguages() : onGetSkills();
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      toggleViewModal();
      setLoading(false);
    } else {
      setLoading(false);
      Toast('error', NOTIFICATION_MSG.error, 'Please edit some fields to update');
    }
  };

  const handleDelete = async () => {
    const id = selectedRow?.id;
    if (!id) return;
    setLoading(true);
    const data = { deleted: true };
    const resp = await (activeTab === 'languages'
      ? deleteLanguage({ id, data })
      : deleteSkill({ id, data }));
    if (resp?.data?.status) {
      Toast(
        'success',
        NOTIFICATION_MSG.success,
        `${activeTab === 'languages' ? 'Language' : 'Skill'} deleted successfully`,
      );
      activeTab === 'languages' ? onGetLanguages() : onGetSkills();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteModal();
    setLoading(false);
  };

  /** language management api's */
  const handleAddLanguage = async (updatedData) => {
    let finalPayload = updatedData;
    if (updatedData?.language === 'other') {
      finalPayload = { language: updatedData?.other || 'other', level: updatedData?.level };
    }

    const resp = await addLanguage({ data: finalPayload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Language added Successfully');
      onGetLanguages();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to add Language');
    }
    toggleModal(modalType);
  };

  const onGetLanguages = async () => {
    setLoading(true);
    const resp = await getLanguage();
    if (resp.data?.status) {
      const list = resp.data?.data;
      setLanguages(list);
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Failed to fetch language data from server',
      );
    }
    setLoading(false);
  };

  /** skill management apis */
  const handleAddSkill = async (updatedData) => {
    const resp = await addSkill({ data: updatedData });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      setActiveTab('skills');
      onGetSkills();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to create Skill.');
    }
    toggleModal(modalType);
  };

  const onGetSkills = async () => {
    setLoading(true);
    const resp = await getSkill();
    if (resp.data?.status) {
      const list = resp.data?.data;
      setSkills(list);
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Failed to fetch skills data from server',
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (activeTab === 'languages') {
      onGetLanguages();
    } else {
      onGetSkills();
    }
  }, [activeTab]);

  useEffect(() => {
    const actions = [
      {
        title: 'Action',
        key: 'action',
        align: 'right',
        render: (_, record) => (
          <Space size='middle'>
            <IconButton
              permissions={permissions}
              className='group disabled:cursor-not-allowed'
              onClick={() => {
                setEditInitials(record);
                toggleViewModal();
              }}
            >
              <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
            </IconButton>
            <IconButton
              permissions={permissions}
              onClick={() => {
                setSelectedRow(record);
                toggleDeleteModal();
              }}
              className='group disabled:cursor-not-allowed'
            >
              <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
            </IconButton>
          </Space>
        ),
      },
    ];
    setColumns([...(activeTab === 'languages' ? languageColumns : skillsColumns), ...actions]);
  }, [activeTab]);

  return (
    <main>
      <div className='bg-white p-4 rounded-2xl rounded-b-none flex justify-between items-center '>
        <div className='flex gap-6 border-b border-grey-70 cursor-pointer'>
          <span
            className={`text-xs ${tabClass} ${
              activeTab === 'languages'
                ? 'text-primary-90 after:border-primary-90 after:w-full'
                : 'text-black'
            }  `}
            onClick={() => handleTabChange(true)}
          >
            Languages
          </span>

          <span
            className={`text-xs ${tabClass} ${
              activeTab === 'skills'
                ? 'text-primary-90 after:border-primary-90 after:w-full'
                : 'text-black'
            }`}
            onClick={() => handleTabChange(false)}
          >
            Skills
          </span>
        </div>

        <div className='flex gap-4'>
          <>
            <Button
              text='+ Language'
              variant='transparent'
              classes='xl:px-6'
              onClick={() => {
                toggleModal('language');
                setActiveTab('languages');
              }}
            />
            <Button
              text='+ Skill'
              variant='primary'
              classes='xl:px-6'
              onClick={() => {
                toggleModal('skill');
                setActiveTab('skills');
              }}
            />
          </>
        </div>
      </div>

      {activeTab === 'languages' && (
        <TableComponent
          loading={loading}
          columns={tableColumns}
          dataSource={languages}
          getRowClassName={rowCommonClass}
          showPagination={false}
        />
      )}

      {activeTab === 'skills' && (
        <TableComponent
          loading={loading}
          columns={tableColumns}
          dataSource={skills}
          showPagination={false}
          getRowClassName={rowCommonClass}
        />
      )}

      {/* Add Language/Skill Modal */}
      <CustomModal
        destroyOnClose={true}
        open={showModal}
        onCancel={() => toggleModal(modalType)}
        footer={''}
        title={modalType === 'language' ? 'Add Language' : 'Add Skill'}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 text-grey-80 '>
            {modalType === 'language'
              ? 'To add a new language, enter the details in the input field below.'
              : ' To add a new skill, enter the details in the input field below.'}
          </span>
          <FormContainer
            onSubmit={modalType === 'language' ? handleAddLanguage : handleAddSkill}
            initialValues={finalInitial}
            fields={modalType === 'language' ? addLanguageFields : addSkillsFields}
            accordionClass='grid grid-cols-2  gap-8'
            customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleModal(modalType);
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    text={modalType === 'language' ? 'Add Language' : 'Add Skill'}
                    variant='primary'
                    classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* View/Edit Language/Skill Modal */}
      <CustomModal
        destroyOnClose={true}
        open={showViewModal}
        onCancel={() => toggleViewModal(modalType)}
        footer={''}
        title={
          activeTab === 'languages'
            ? `${editInitials ? 'Edit' : 'View'} Language`
            : `${editInitials ? 'Edit' : 'View'} Skill`
        }
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 '>
            {activeTab === 'languages'
              ? 'To add a new language, enter the details in the input field below.'
              : ' To add a new skill, enter the details in the input field below.'}
          </span>
          <FormContainer
            // disableForm={editInitials ? false : true}
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={handleEdit}
            initialValues={editInitials}
            fields={activeTab === 'languages' ? addLanguageFields : addSkillsFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, disableForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleViewModal(modalType);
                    }}
                  />
                  <Button
                    disabled={disableForm || !isValid}
                    text={'Submit'}
                    variant='primary'
                    classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* Delete modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={
          <div className=' flex flex-row justify-center  bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              text={activeTab === 'languages' ? 'Delete Language' : 'Delete Skill'}
              variant='primary'
              classes={`ml-6 px-8 bg-black`}
              onClick={handleDelete}
              loading={loading}
            />
          </div>
        }
        width={450}
      >
        <div className='flex justify-center flex-col items-center gap-4'>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-xs text-grey-90 pb-3'>
            {`Could you please confirm that you really wish to delete the ${
              activeTab === 'languages' ? 'language' : 'skill'
            }?`}
          </span>
        </div>
      </CustomModal>
    </main>
  );
};

export default LanguageSkills;
