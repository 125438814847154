import { BackArrow } from 'assets/icons';
import { FormContainer, Button, IconButton } from 'components';
import { elementData } from 'elements';
import React from 'react';

const EditClauses = ({ goBack, bulkEditData, onSubmit, permissions = {} }) => {
  return (
    <div className='bg-white rounded-2xl p-4'>
      <div className='flex justify-between items-center'>
        <span className='text-sm font-semibold'>Edit Clauses</span>
        <IconButton onClick={goBack}>
          <BackArrow svgClass='fill-grey-90 w-10 h-10' />
        </IconButton>
      </div>
      <FormContainer
        accordionClass='grid grid-cols-2 gap-2'
        onSubmit={onSubmit}
        fields={bulkEditData?.fields}
        initialValues={bulkEditData?.initials}
        customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
          return (
            <div className='bg-white flex flex-row justify-end py-4 mt-6'>
              <Button
                text='Cancel'
                variant='text'
                type='reset'
                classes='border border-gray-90 text-black px-6'
                onClick={() => {
                  resetForm();
                  goBack();
                }}
              />
              <Button
                disabled={!isValid}
                id={elementData.clause.permissions.update}
                permissions={permissions}
                text={'Update Clauses'}
                variant='primary'
                classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                onClick={submitForm}
                loading={isSubmitting}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default EditClauses;
