import { Checkbox, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { getInsurers, insurerManagement } from 'service/api-service';

const OfflineQuotation = ({ data, accordian }) => {
  const [insurer, setInsurer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    const resp = await getInsurers();
    if (resp?.status) {
      setInsurer(resp?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const selectedInsurer = (arr, id) => {};

  const renderCard = (item, key) => {
    return (
      <div key={key} className='flex justify-between border-b border-grey-40 last:border-0 py-3'>
        <div className='flex gap-2.5'>
          <img src={item?.image} alt='logo' className='w-14' />
          {item?.name}
        </div>
        <Checkbox onChange={(e) => selectedInsurer(e, item?.id)} />
      </div>
    );
  };

  const renderQuoteDetails = (item, key) => {
    return <>quotation-table</>;
  };

  if (loading) {
    return (
      <div className='flex items-center justify-center h-64'>
        <Spin />
      </div>
    );
  }
  return (
    <div className='h-96 overflow-y-scroll'>
      {accordian ? checked.map(renderQuoteDetails) : insurer?.map(renderCard)}
    </div>
  );
};

export default OfflineQuotation;
