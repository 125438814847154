import { Toast } from 'components';
import { produce, original } from 'immer';
import { deleteReminders, getReminders, updateReminders } from 'service/apis/reminders';
import { create } from 'zustand';

/** @type {State} initialValues */
const initialValues = {
  leads: [],
  policy: [],
  quotation: [],
  error: null,
  isFetching: false,
  isSending: false,
};

export const useReminders = create((set, get) => ({
  ...initialValues,
  init: async (triggerLoadingState = true) => {
    try {
      if (triggerLoadingState)
        set(
          produce((state) => {
            state.isFetching = true;
          }),
        );

      const resp = await getReminders();
      if (!resp.data?.data || resp.error || !resp.status)
        throw resp.error ?? new Error("Couldn't fetch data");

      /** @type {Reminder['data']} data */
      const reminders = resp.data.data;
      const remindersLeads = reminders.filter((item) => item.model === 'leads');
      const remindersPolicy = reminders.filter((item) => item.model === 'policy');

      set(
        /** @type {State} state */
        produce((state) => {
          // initialising with empty data if there is none in db.
          state.leads = remindersLeads.length ? remindersLeads : [getNewReminderData('leads')];
          state.policy = remindersPolicy.length ? remindersPolicy : [getNewReminderData('policy')];
          state.quotation = remindersPolicy.length
            ? remindersPolicy
            : [getNewReminderData('quotation')];
        }),
      );
    } catch (e) {
      set(
        produce((state) => {
          state.error = e;
        }),
      );

      Toast('error', 'Error', e ?? "Couldn't fetch data");
    } finally {
      if (triggerLoadingState)
        set(
          produce((state) => {
            state.isFetching = false;
          }),
        );
    }
  },
  /** @param {ReminderKind} type */
  submitReminders: async (type) => {
    /** @type {State[ReminderKind]} */
    const dataToSend = get()[type];

    if (!dataToSend) return;

    const days = new Set();
    for (let i = 0; i < dataToSend.length; i++) {
      const item = dataToSend[i];
      if (days.has(item.days)) {
        Toast('info', 'Info', `Please make sure days are unique for each ${type} reminder`);
        return;
      }
      if (item.notify_email.every((type) => !type.email && !type.notify)) {
        Toast(
          'info',
          'Info',
          `Please select at least one type of notification from ${type} reminder`,
        );
        return;
      }
      days.add(item.days);
    }

    set(
      produce((state) => {
        state.isSending = true;
      }),
    );

    const resp = await updateReminders(dataToSend);
    set(
      produce((state) => {
        state.isSending = false;
      }),
    );

    if (!resp.status || resp.error)
      return Toast('error', 'Error', resp.error ?? "Couldn't update reminders");

    Toast('success', 'Success', resp.data?.msg ?? `Reminders for ${type} updated successfully`);
    get().init(false);
  },
  /**
   * @param {ReminderKind} type
   * @param {string} role
   * @param {number} index
   * @param {'email' | 'notify'} checkboxType
   * @param {0 | 1} checked
   */
  onChangeCheckbox: (type, role, index, checkboxType, checked) => {
    set(
      produce(
        /** @param {State} state */
        (state) => {
          // ?? is short circuiting. so, we have to add a parentheses to make it not skip an equality check
          const reminder = state[type][index];
          if (!reminder) return;

          const roleOfReminder = reminder.notify_email.find((item) => item.role === role);
          if (!roleOfReminder) return;

          roleOfReminder[checkboxType] = checked;
        },
      ),
    );
  },
  /**
   * @param {ReminderKind} type
   * @param {number} index
   * @param {string} numberOfDays
   */
  onChangeNumberOfDays: (type, index, numberOfDays) => {
    set(
      produce(
        /** @param {State} state */
        (state) => {
          const reminder = state[type][index];
          if (!reminder) return;

          reminder.days = numberOfDays;
        },
      ),
    );
  },
  /** @param {ReminderKind} type */
  addNewReminder: (type) => {
    set(
      produce(
        /** @param {State} state */
        (state) => {
          state[type].push(getNewReminderData(type));
        },
      ),
    );
  },
  /** @param {ReminderKind} type
   * @param {number} index */
  removeReminder: async (type, index) => {
    /** @type {State[ReminderKind][number]} */
    let elForDeletion = null;
    set(
      produce(
        /** @param {State} state */
        (state) => {
          const [deletedElement] = state[type].splice(index, 1);
          // if nothing is deleted, or the deleted item isn't from backend, we're done.
          if (deletedElement?.__id) return;

          state.isSending = true;
          // since state is proxy, we won't be able to use it later.
          // see https://immerjs.github.io/immer/original for more
          elForDeletion = original(deletedElement);
        },
      ),
    );

    if (!elForDeletion?.id) return;

    const resp = await deleteReminders(elForDeletion.id);
    set(
      produce((state) => {
        state.isSending = false;
      }),
    );

    if (resp.status) {
      get().init(false);
      return Toast('success', 'Success', 'Reminder deleted successfully');
    }

    Toast('error', 'Error', "Couldn't delete reminder");
    set(
      produce(
        /** @param {State} state */
        (state) => {
          state[type].splice(index, 0, elForDeletion);
        },
      ),
    );
  },
}));

/**
 * to be used when clicking 'Add new reminder' button.
 * @param {ReminderKind} type
 * @returns {Reminder['data'][number]}
 */

const getNewReminderData = (type = 'leads') => ({
  /** since we need to keep track of newly created object to update it in store, we need this internalId. won't be sent to backend */
  __id: +Math.random().toFixed(5),
  model: type,
  days: 1,
  notify_email: [
    {
      role: 'Admin',
      email: 0,
      notify: 0,
    },
    {
      role: 'Created_by',
      email: 0,
      notify: 0,
    },
    {
      role: 'Sell_by',
      email: 0,
      notify: 0,
    },
    {
      role: 'Client',
      email: 0,
      notify: 0,
    },
  ],
});
