import { FormContainer, SelectInput, TextInput, DateTime } from 'components';
import dayjs from 'dayjs';
import React from 'react';
import { prepareFormFieldsData } from 'utils';
import { formStyle } from 'utils/common';

var doSubmit = null;

const PensionAndRetirementForm = ({ formData, onSubmit }) => {
  const maxDate = dayjs().subtract(18, 'years');

  const quoteCustomerFields = {
    'Pension And Retirement Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Date of Birth',
          name: 'dob',
          id: 'dob',
          placeholder: 'Enter Date',
          type: 'date',
          showTime: false,
          component: (props) => <DateTime {...props} disabledDate={maxDate} />,
        },
        {
          label: 'When are you planning to retire?',
          name: 'retirement',
          id: 'retirement',
          placeholder: 'Enter Date',
          type: 'date',
          component: DateTime,
        },

        {
          label: 'Sum Assured Amount',
          name: 'income',
          id: 'income',
          placeholder: 'Sum Assured Amount',
          type: 'select',
          component: SelectInput,
          options: [
            {
              label: '50,000-100,000',
              value: '100000',
            },
            {
              label: '100,000-200,000',
              value: '200000',
            },
            {
              label: '200,000-300,000',
              value: '300000',
            },
          ],
        },
        {
          label: 'Invest',
          name: 'invest',
          id: 'invest',
          placeholder: 'Select Amount',
          type: 'select',
          searchable: true,
          component: SelectInput,
          options: [
            {
              label: '50,000-100,000',
              value: '100000',
            },
            {
              label: '100,000-200,000',
              value: '200000',
            },
            {
              label: '200,000-300,000',
              value: '300000',
            },
          ],
        },
        {
          label: 'Amount',
          name: 'amount',
          id: 'amount',
          placeholder: 'Amount',
          type: 'text',
          component: TextInput,
          maxLength: 30,
        },
        {
          label: 'Get Benefit Payout after policy term(PT)',
          name: 'benefit',
          id: 'benefit',
          placeholder: 'Select Benefits',
          type: 'select',
          searchable: true,
          component: SelectInput,
          options: [
            {
              label: '50,000-100,000',
              value: '100000',
            },
            {
              label: '100,000-200,000',
              value: '200000',
            },
            {
              label: '200,000-300,000',
              value: '300000',
            },
          ],
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: quoteCustomerFields,
    defaultValues: { dob: maxDate.format('YYYY-MM-DD'), ...formData },
  });

  return (
    <FormContainer
      returnFormatted={false}
      accordionClass={formStyle}
      fields={finalFields}
      initialValues={initialValues}
      onSubmit={onSubmit}
      customSubmitRender={({ submitForm }) => {
        doSubmit = submitForm;
        return null;
      }}
    />
  );
};

const submit = () => {
  if (doSubmit) doSubmit();
};

export default {
  submit,
  Form: PensionAndRetirementForm,
};
