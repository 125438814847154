import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableComponent, Button, CustomModal, FormContainer, Toast } from 'components';
import { AssetAllocation_Cols } from './data';
import { getAllocatedAssets } from 'service/api-service';
import { elementData } from 'elements';
import { prepareFormFieldsData } from 'utils';
import { allocateAssetsFields } from '../assets/data';
import { NOTIFICATION_MSG } from 'constants';
import { rowCommonClass } from 'utils/common';

const AssetAllocation = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states */
  const [loading, setLoading] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const [showAllocateModal, setAllocateModal] = useState(false);
  const [editInitials, setInitials] = useState();
  const navigate = useNavigate();
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: allocateAssetsFields,
  });

  /* func */
  const fetchData = async () => {
    setLoading(true);
    const resp = await getAllocatedAssets({ status: 'pending' });
    if (resp?.data?.data) {
      setdataSource(resp?.data?.data?.result);
    }
    setLoading(false);
  };

  const onApproveAllocate = async (data) => {
    setLoading(true);
    const payload = {
      asset: data?.asset,
      status: 'approved',
    };
    const resp = await getAllocatedAssets({
      method: 'patch',
      id: editInitials?.id,
      data: payload,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      fetchData();
      toggleAllocateAsset();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onAllocate = (data) => {
    const initVals = { ...data, employee: data?.requested_by };
    setInitials(initVals);
    setAllocateModal((pre) => !pre);
  };

  const toggleAllocateAsset = () => {
    setInitials(undefined);
    setAllocateModal((pre) => !pre);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='space-y-4 bg-white p-4 border rounded-md border-grey-40 h-full'>
      <div className='flex items-center justify-between'>
        <div className='font-semibold text-s leading-6'>Asset Requests</div>
        <Button
          classes='!py-1'
          text='See all'
          onClick={navigate.bind(this, '/hr/assets-allocation')}
        />
        {/* <button
          className='font-semibold text-primary-40 text-x'
          onClick={navigate.bind(this, '/hr/assets-allocation')}
        >
          See all
        </button> */}
      </div>
      <div className='customTable' id='dashboard'>
        <TableComponent
          getRowClassName={rowCommonClass}
          loading={loading}
          pageSize='3'
          columns={[
            ...AssetAllocation_Cols,
            {
              key: 'action',
              title: 'Action',
              align: 'right',
              render: (_, record) => (
                <Button
                  id={elementData.assetAllocation.permissions.update}
                  permissions={permissions}
                  classes={'!py-1 !px-2 !text-xxxs'}
                  text='Allocate'
                  variant='secondary'
                  onClick={onAllocate.bind(this, record)}
                />
              ),
            },
          ]}
          dataSource={dataSource}
          showPagination={false}
          scroll={{ y: 175 }}
        />
      </div>
      {/* Allocate Asset modal */}
      <CustomModal
        destroyOnClose={true}
        open={showAllocateModal}
        onCancel={toggleAllocateAsset}
        footer={''}
        width={600}
      >
        <FormContainer
          accordionClass='grid gap-8'
          onSubmit={onApproveAllocate}
          initialValues={editInitials || initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    toggleAllocateAsset();
                  }}
                />
                <Button
                  disabled={!isValid}
                  permissions={permissions}
                  id={
                    editInitials
                      ? elementData.assetAllocation.permissions.update
                      : elementData.assetAllocation.permissions.create
                  }
                  text='Save'
                  variant='primary'
                  classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </CustomModal>
    </div>
  );
};

export default AssetAllocation;
