import { TextInput, DateTime } from 'components';

export let addQuoteFormFields = {
  'Inquiry Details': {
    fields: [
      {
        label: 'Inquired Name',
        name: 'enquiredname',
        id: 'enquiredname',
        placeholder: 'Inquired Name',
        type: 'text',
        component: TextInput,
        maxLength: 30,
        required: true,
      },
      {
        label: 'Client Code',
        name: 'clientcode',
        id: 'clientcode',
        placeholder: 'Client Code',
        type: 'text',
        component: TextInput,
        maxLength: 30,
        required: true,
      },
      {
        label: 'Bill To',
        name: 'billto',
        id: 'billto',
        placeholder: 'Bill To',
        type: 'text',
        component: TextInput,
        options: [],
        maxLength: 30,
        required: true,
      },
      {
        label: 'Contact Name',
        name: 'cname',
        id: 'cname',
        placeholder: 'Contact Name',
        type: 'text',
        component: TextInput,
        maxLength: 30,
        required: true,
      },
      {
        label: 'Business Nature',
        name: 'business',
        id: 'business',
        placeholder: 'Business Nature',
        type: 'text',
        component: TextInput,
        maxLength: 30,
        required: true,
      },
      {
        label: 'Bill To Code',
        name: 'billtocode',
        id: 'billtocode',
        placeholder: 'Bill To Code',
        type: 'text',
        component: TextInput,
        options: [],
        maxLength: 30,
        required: true,
      },
    ],
  },
  'Quote Details': {
    fields: [
      {
        label: 'Subject',
        name: 'subject',
        id: 'subject',
        placeholder: 'Subject',
        type: 'text',
        component: TextInput,
        maxLength: 30,
        required: true,
      },
      {
        label: 'Date of Issue',
        name: 'doi',
        id: 'doi',
        placeholder: 'Date of Issue',
        component: DateTime,
        type: 'date',
        maxLength: 30,
      },
      {
        label: 'Class of Insurance',
        name: 'classofinsurance',
        id: 'classofinsurance',
        placeholder: 'Class of Insurance',
        type: 'text',
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Duration',
        name: 'duration',
        id: 'duration',
        placeholder: 'Duration',
        type: 'text',
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Period of Insurance: From',
        name: 'from',
        id: 'from',
        placeholder: 'Period of Insurance: From',
        component: DateTime,
        type: 'date',
        maxLength: 30,
      },
      {
        label: 'Period of Insurance: To',
        name: 'to',
        id: 'to',
        placeholder: 'Period of Insurance: To',
        component: DateTime,
        type: 'date',
        maxLength: 30,
      },
      {
        label: 'Insurer',
        name: 'insurer',
        id: 'insurer',
        placeholder: 'Insurer',
        type: 'text',
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Quote Stage',
        name: 'stage',
        id: 'stage',
        placeholder: 'Quote Stage',
        type: 'text',
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Quote No.',
        name: 'quoteno',
        id: 'quoteno',
        placeholder: 'Quote No.',
        type: 'text',
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Cash Collateral',
        name: 'collateral',
        id: 'collateral',
        placeholder: 'Cash Collateral',
        type: 'text',
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'GST Type',
        name: 'gsttype',
        id: 'gsttype',
        placeholder: 'GST Type',
        type: 'text',
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'CPF or EPF No.',
        name: 'cpf',
        id: 'cpf',
        placeholder: 'CPF or EPF No.',
        type: 'text',
        component: TextInput,
        maxLength: 30,
      },
    ],
  },
  // "Brokerage Details": {
  //   fields: [
  //   {
  //     label: "Bank IFSC Code",
  //     name: "ifsc",
  //     id: "ifsc",
  //     placeholder: "Name",
  //     type: "text",
  //     component: TextInput,
  //     maxLength: 30,
  //   },
  //   {
  //     label: "Bank Account Number",
  //     name: "account",
  //     id: "account",
  //     placeholder: "Bank Account Number",
  //     type: "text",
  //     component: TextInput,
  //     maxLength: 30,
  //   },
  //   {
  //     label: "Bank Account Holder Name",
  //     name: "accountholder",
  //     id: "accountholder",
  //     placeholder: "Bank Account Holder Name",
  //     type: "text",
  //     component: TextInput,
  //     maxLength: 30,
  //   },
  //   {
  //     label: "Bank Account Type",
  //     name: "accounttype",
  //     id: "accounttype",
  //     placeholder: "Bank Account Type",
  //     type: "text",
  //     component: TextInput,
  //     maxLength: 30,
  //   },
  //   ],
  // },
  //   "Control Listing": {
  //     fields: [
  //       {
  //         label: "Credit Rating",
  //         name: "creditrating",
  //         id: "creditrating",
  //         placeholder: "Select Credit Rating",
  //         type: "select",
  //         searchable: true,
  //         component: SelectInput,
  //         options: [],
  //       },
  //       {
  //         label: "Credit Amount",
  //         name: "creditamount",
  //         id: "creditamount",
  //         placeholder: "Credit Amount",
  //         type: "text",
  //         component: TextInput,
  // maxLength: 30,
  //       },
  //       {
  //         label: "Credit Term",
  //         name: "creditterm",
  //         id: "creditterm",
  //         placeholder: "Select Credit Term",
  //         type: "select",
  //         searchable: true,
  //         component: SelectInput,
  //         options: [],
  //       },
  //     ],
  //   },
  //   Description: {
  //     fields: [
  //       {
  //         label: "Description",
  //         name: "description",
  //         id: "description",
  //         placeholder: "Description",
  //         type: "textarea",
  //         component: TextInput,
  //         isMultiline: true,
  //         divClasses: "col-span-3",
  //         maxLength: 300,
  //       },
  //     ],
  //   },
};

export const initialValues = {
  enquiredname: '',
  clientcode: '',
  billto: '',
  clienttype: '',
  cname: '',
  business: '',
  billtocode: '',
  subject: '',
  doi: '',
  contact: '',
  email: '',
  gst: '',
  fax: '',
  biz: '',
  from: '',
  to: '',
  nature: '',
  branch: '',
  role_id: '',
  assistemail: '',
  assistantemail: '',
  Subject: '',
  address2: '',
  classofinsurance: '',
  insurer: '',
  duration: '',
  country: '',
  state: '',
  city: '',
  address: '',
  tpincode: '',
  tcountry: '',
  tstate: '',
  tcity: '',
  taddress: '',
  checkaddress: '',
  ifsc: '',
  account: '',
  accountholder: '',
  accounttype: '',
  creditrating: '',
  creditamount: '',
  creditterm: '',
  description: '',
  stage: '',
  quoteno: '',
  collateral: '',
  gsttype: '',
  cpf: '',
};

export const CLAIM_COVERAGE = [
  {
    id: 1,
    point: 'Cashless Claims at 30 Garages near you',
  },
  {
    id: 2,
    point:
      'Insurer offers self-video claims: quick and easy inspection through Policybazaar App for surveying of damages.',
  },
  {
    id: 3,
    point: 'Insurer offers zero paper claims: every document or signature can be uploaded online.',
  },
  {
    id: 4,
    point:
      'Insurer offers self-video claims: self-upload video of car through Policybazaar App for quick survey of damages.',
  },
  {
    id: 5,
    point: 'Insurer offers zero paper claims: every document or signature can be uploaded online.',
  },
  {
    id: 1,
    point: 'Cashless Claims at 30 Garages near you',
  },
  {
    id: 2,
    point:
      'Insurer offers self-video claims: quick and easy inspection through Policybazaar App for surveying of damages.',
  },
  {
    id: 3,
    point: 'Insurer offers zero paper claims: every document or signature can be uploaded online.',
  },
  {
    id: 4,
    point:
      'Insurer offers self-video claims: self-upload video of car through Policybazaar App for quick survey of damages.',
  },
  {
    id: 5,
    point: 'Insurer offers zero paper claims: every document or signature can be uploaded online.',
  },
];
