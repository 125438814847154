import React, { useCallback, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import dayjs from 'dayjs';
import { Button as AntButton } from 'antd';

import { editCheckList } from 'service/api-service';
import { Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';

const ViewSheet = ({ timeline, data, id, fetchData }) => {
  const [lock, setLock] = useState(data?.inputs);
  const [hold, setHold] = useState(data?.release);

  const customerChartData = {
    labels: ['Gross Pay', 'Deduction', 'Allowance'],
    datasets: [
      {
        data: [data?.gr_pay, data?.deduct, data?.allow],
        backgroundColor: ['#E7854E', '#00AFEB', '#6BB76D'],
        borderColor: ['#fff', '#fff', '#fff'],
        borderWidth: 5,
      },
    ],
  };

  /* func */
  const onEditInput = useCallback(async () => {
    const payload = {
      inputs: !lock,
    };
    const resp = await editCheckList({ id, data: payload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Input Updated');
      // broadcast(REFRESH_EVT);
      fetchData();
      setLock(!lock);
    }
  }, [id, lock]);

  const onEditRelease = useCallback(async () => {
    const payload = {
      release: !hold,
    };
    const resp = await editCheckList({ id, data: payload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Input Updated');
      fetchData();
      setHold(!hold);
    }
  }, [id, hold]);

  return (
    <div className='border border-grey-40 bg-white rounded-lg p-4 grid grid-cols-3'>
      <div>
        <h2 className='text-s font-semibold'>Payout Details</h2>
        <div>
          <div className='rounded-md bg-red-50 px-1 w-0.5 flex gap-3'>
            <h2 className='text-xs font-semibold pl-4'>{data?.gr_pay?.toLocaleString() || 0}</h2>
          </div>
          <p className='text-x pl-4'>Net Pay</p>
        </div>
        <div className='p-4 w-max'>
          <Doughnut
            // width={parentDivWidth}
            width={160}
            data={customerChartData}
            options={{
              cutout: '70%',
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div className='grid grid-cols-2'>
          <div>
            <div className='rounded-md bg-red-50 px-1 w-0.5 flex gap-3'>
              <h2 className='text-xs font-semibold pl-4'>{data?.gr_pay?.toLocaleString() || 0}</h2>
            </div>
            <p className='text-x pl-4'>Gross Pay</p>
          </div>
          <div>
            <div className='rounded-md bg-primary-30 px-1 w-0.5 flex gap-3'>
              <h2 className='text-xs font-semibold pl-4'>{data?.deduct?.toLocaleString() || 0}</h2>
            </div>
            <p className='text-x pl-4'>Deductions</p>
          </div>
          {/* <div>
            <div className='rounded-md bg-primary-30 px-1 w-0.5 flex gap-3'>
              <h2 className='text-xs font-semibold pl-4'>{dayjs(timeline.month)?.daysInMonth()}</h2>
            </div>
            <p className='text-x pl-4'>Work Days</p>
          </div> */}
          <div>
            <div className='rounded-md bg-green-40 px-1 w-0.5 flex gap-3'>
              <h2 className='text-xs font-semibold pl-4'>{data?.allow?.toLocaleString() || 0}</h2>
            </div>
            <p className='text-x pl-4'>Allowance</p>
          </div>
        </div>
      </div>
      <div>
        <h2 className='text-s font-semibold'>Employee Details</h2>
        <div className='bg-primary-light rounded-md p-5 w-max'>
          <p className='text-xs font-semibold'>{data?.tot_emp || 0}</p>
          <p className='text-xxs font-semibold'>Total Employees</p>
          <p className='text-xxs'>
            <span className='text-primary-60'>{data?.tot_emp - data?.prev_tot_emp || 0}</span> vs
            previous month
          </p>
        </div>
        {/* <div className='grid grid-cols-2'>{DATA?.map(renderLabel)}</div> */}
      </div>
      <div className='grid grid-cols-2'>
        <h2 className='font-semibold'>Payroll Inputs</h2>
        <div>
          <AntButton
            className='rounded rounded-r-none'
            type={lock ? `default` : `primary`}
            onClick={onEditInput}
          >
            Unlock
          </AntButton>
          <AntButton
            className='rounded rounded-l-none'
            type={lock ? `primary` : `default`}
            onClick={onEditInput}
          >
            Lock
          </AntButton>
        </div>
        {/* <div className='relative' onPointerDown={startDrag}>
          <motion.div
            className='flex justify-between gap-6 absolute bg-white px-4 py-2 rounded-md border border-grey-90'
            ref={constraintsRef}
          >
            <div>Unlock</div>
            <div>Lock</div>
          </motion.div>
          <motion.div
            className='absolute bg-primary-60 text-primary-60 px-4 py-2 rounded-l-md border border-primary-90'
            drag
            dragConstraints={constraintsRef}
          >
            nlock
          </motion.div>
        </div> */}
        <h2 className='font-semibold'>Employee View Release</h2>
        <div>
          <AntButton
            className='rounded rounded-r-none'
            type={hold ? `default` : `primary`}
            onClick={onEditRelease}
          >
            Release
          </AntButton>
          <AntButton
            className='rounded rounded-l-none'
            type={hold ? `primary` : `default`}
            onClick={onEditRelease}
          >
            Hold
          </AntButton>
        </div>
      </div>
    </div>
  );
};

export default ViewSheet;
