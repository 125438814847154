/** external deps */
import { Pie } from 'react-chartjs-2';
import React, { useEffect, useState } from 'react';
/** internal deps */
import './style.css';
import { employeeLeaveStats } from 'service/api-service';

const LeavesView = ({ employee_id: id }) => {
  const [leaveData, setLeaveData] = useState([]);
  const [totalLeaves, setTotalLeaves] = useState(0);

  const randomColors = [
    '#FF6384',
    '#36A2EB ',
    '#FFCE56',
    '#33CC99',
    '#FF9933',
    '#9933CC',
    '#00CCFF',
    '#FF6666',
    '#66FF99',
    '#FFFF66',
    '#99CC33',
    '#FF99CC',
    '#CCFF33',
    '#3366CC',
    '#CC33FF',
    '#66FFCC',
    '#FFCC00',
    '#33FF99',
    '#FF3366',
    '#99FF33',
    '#FF6633',
    '#66CCFF',
    '#CC66FF',
    '#FF9966',
    '#66CC99',
    '#CC66CC',
    '#99FF66',
    '#FF66CC',
    '#66FF66',
    '#FFCC33',
  ];

  const fetchLeaveInfo = async () => {
    const resp = await employeeLeaveStats({ id });

    if (resp?.status) {
      setLeaveData(resp?.data?.data);
      setTotalLeaves(resp?.data?.total_allowed_offdays);
    }
  };

  useEffect(() => {
    fetchLeaveInfo();
  }, []);

  const graphData = {
    labels: [...leaveData?.map((lv) => lv?.leave_type), 'Total Leaves'],
    datasets: [
      {
        data: [...leaveData?.map((lv) => +lv?.count), +totalLeaves || 0],
        backgroundColor: randomColors.splice(0, leaveData?.length + 1),
      },
    ],
  };

  return (
    <>
      <div className='flex justify-between items-center'>
        <h2 className='font-semibold text-s'>Leaves</h2>
      </div>

      <div className='m-auto h-96 w-96 flex items-center justify-center'>
        <Pie data={graphData} options={{ layout: { autoPadding: false, padding: 10 } }} />
      </div>
    </>
  );
};

export default LeavesView;
