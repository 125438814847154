import { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';

const BussinessGraph = ({ selectedModule, cardCount, children }) => {
  const componentRef = useRef(null);
  const [dim, setDim] = useState(true);

  const employeeTabData = [
    {
      title: 'Employees',
      value: cardCount?.total,
      backgroundColor: 'bg-[#DEE6F2]',
    },
    {
      title: 'Active',
      value: cardCount?.active,
      backgroundColor: 'bg-[#F6E8DA]',
    },
    {
      title: 'Inactive',
      value: cardCount?.inactive,
      backgroundColor: 'bg-[#D8F6DB]',
    },
  ];
  const ClientTabData = [
    {
      title: 'Clients',
      value: cardCount?.total_client + cardCount?.total_contact || 0,
      backgroundColor: 'bg-[#DEE6F2]',
    },
    {
      title: 'Corporate',
      value: cardCount?.corporate || 0,
      backgroundColor: 'bg-[#D8F6DB]',
    },
    {
      title: 'Retail',
      value: cardCount?.retail || 0,
      backgroundColor: 'bg-[#E5D5F3]',
    },
    {
      title: 'Contacts',
      value: cardCount?.total_contact || 0,
      backgroundColor: 'bg-[#F6E8DA]',
    },
  ];
  const leadsTabData = [
    {
      title: 'Leads',
      value: cardCount?.total,
      backgroundColor: 'bg-[#DEE6F2]',
    },
    {
      title: 'Draft',
      value: cardCount?.draft,
      backgroundColor: 'bg-[#F6E8DA]',
    },
    {
      title: 'Quoted',
      value: cardCount?.quoted,
      backgroundColor: 'bg-[#D8F6DB]',
    },
    {
      title: 'Rejected',
      value: cardCount?.rejected,
      backgroundColor: 'bg-[#E5D5F3]',
    },
    {
      title: 'Converted',
      value: cardCount?.converted,
      backgroundColor: 'bg-[#F6DFF0]',
    },
    {
      title: 'Losses',
      value: cardCount?.losses,
      backgroundColor: 'bg-[#DBDADA]',
    },
  ];
  const renderCards = ({ data, expand = false }) => {
    return (
      <div
        className={`${data?.backgroundColor} h-full p-2 flex  flex-col w-full justify-center rounded-lg shadow-custom `}
      >
        <p className='text-xxs font-normal'>{data?.title}</p>
        <p className='text-l font-semibold'>{data?.value}</p>
      </div>
    );
  };

  const employeeBarData = {
    labels: ['Active Employees', 'Inactive Employees'],
    datasets: [
      {
        data: [cardCount?.active, cardCount?.inactive],
        backgroundColor: ['#F6E8DA', '#D8F6DB'],
        borderColor: ['#fff', '#fff'],
        borderWidth: 0,
      },
    ],
  };
  const ClientBarData = {
    labels: ['Corporate', 'Retail', 'Contacts'],
    datasets: [
      {
        data: [cardCount?.corporate, cardCount?.retail, cardCount?.total_contact],
        backgroundColor: ['#D8F6DB', '#E5D5F3', '#F6E8DA'],
        borderColor: ['#fff', '#fff', '#fff'],
        borderWidth: 0,
      },
    ],
  };
  const leadBarData = {
    labels: ['Draft', 'Quote', 'Reject', 'Convert', 'Loss'],
    datasets: [
      {
        data: [
          cardCount?.draft,
          cardCount?.quoted,
          cardCount?.rejected,
          cardCount?.converted,
          cardCount?.losses,
        ],
        backgroundColor: ['#F6E8DA', '#D8F6DB', '#E5D5F3', '#F6DFF0', '#DBDADA'],
        borderColor: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        borderWidth: 0,
      },
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      if (componentRef.current) {
        const width = componentRef.current.clientWidth;
        const height = componentRef.current.clientHeight;
        setDim({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    // Initial setup
    handleResize();

    // Observer setup
    if (componentRef.current) {
      resizeObserver.observe(componentRef.current);
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [componentRef, selectedModule]);

  return (
    <div className='flex gap-4 '>
      <div
        className={`flex flex-col w-full gap-4 ${
          selectedModule === 'leads'
            ? 'w-full'
            : selectedModule === 'employees'
            ? ' w-[60%]'
            : 'w-[50%]'
        }`}
      >
        <div className={` flex justify-between gap-4 h-full w-full `}>
          {selectedModule === 'employees' &&
            employeeTabData?.map((data) => renderCards({ data, expand: true }))}
          {selectedModule === 'clients' &&
            ClientTabData?.map((data) => renderCards({ data, expand: true }))}
          {selectedModule === 'leads' && leadsTabData?.map((data) => renderCards({ data }))}
        </div>
        {children}
      </div>
      <div
        ref={componentRef}
        className={` h-full self-stretch p-1 ${
          selectedModule === 'leads'
            ? 'w-[283px]'
            : selectedModule === 'client'
            ? 'w-1/3'
            : 'w-[40%]'
        } rounded-lg bg-white`}
      >
        <Bar
          data={
            selectedModule === 'employees'
              ? employeeBarData
              : selectedModule === 'clients'
              ? ClientBarData
              : leadBarData
          }
          {...dim}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default BussinessGraph;
