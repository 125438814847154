import { MultiSelect, SelectInput, TextInput, Uploadbox } from 'components';
import formOptions from 'components/forms/formOptions';
import { searchApis } from 'service/api-service';

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Insurance Category',
    path: '',
  },
];

const productColumns = [
  {
    title: 'Product Icon',
    dataIndex: 'logo',
    key: 'logo',
    render: (logo) => <img src={logo} className='h-10 w-10' alt={logo} />,
  },
  {
    title: 'Product Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Form Type',
    dataIndex: 'formid',
    key: 'formid',
    render: (text) => text || '\u2014',
  },
];

const columns = [
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
    render: (text) => {
      return <span className='capitalize'>{text || '\u2014'}</span>;
    },
  },
  {
    key: 'code',
    title: 'Code',
    dataIndex: 'code',
    render: (text) => text || '\u2014',
  },
  {
    key: 'product_type',
    title: 'Product Type',
    dataIndex: 'product_type',
    render: (data) => data?.map((item) => item?.name + ',') || '\u2014',
  },
];

//add Class data

const productFields = {
  'Product Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Product Type',
        name: 'name',
        id: 'name',
        placeholder: 'Enter Product Type',
        type: 'text',
        maxLength: 60,
        required: true,
        component: TextInput,
      },
      {
        label: 'Form Type',
        name: 'formid',
        id: 'formid',
        placeholder: 'Select Form Type',
        type: 'select',
        required: true,
        component: SelectInput,
        options: formOptions,
      },
      {
        label: 'Product Icon',
        name: 'logo',
        id: 'logo',
        type: 'logo',
        required: true,
        component: Uploadbox,
        uploadNoteClass: 'flex flex-row items-center justify-center gap-2',
        accept: ['.png', '.svg', '.jpg', '.jpeg'],
        divClasses: 'h-32 col-span-2',
      },
    ],
  },
};

const addClassFields = {
  'Insurance Category Details': {
    fields: [
      {
        label: 'Category Name',
        name: 'name',
        id: 'name',
        placeholder: 'Insurance Category Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
        isUpperCase: true,
        divClasses: 'test class',
      },
      {
        label: 'Category Code',
        name: 'code',
        id: 'code',
        placeholder: 'Category Code',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
        isUpperCase: true,
      },
      {
        label: 'Insurers',
        menuPlacement: 'bottom',
        name: 'insurer',
        id: 'insurer',
        placeholder: 'Insurers',
        type: 'select',
        component: MultiSelect,
        required: true,
        fetchOptions: () => searchApis({ api: 'insurer' }),
      },
      {
        label: 'Icon',
        name: 'image',
        id: 'image',
        type: 'file',
        component: Uploadbox,
        // divClasses: 'h-[4.5rem] ',
        uploadNoteClass: 'flex flex-row gap-4 mx-auto items-center justify-center -mt-0.5',
      },
    ],
  },
};

const addClassBreadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Insurance Category',
    path: '/insurance-category',
  },
  {
    name: 'Add Category',
    path: '',
  },
];

const editClassBreadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Insurance Category',
    path: '/insurance-category',
  },
  {
    name: 'Edit Class',
    path: '',
    target: '_blank',
  },
];

export {
  breadcrumbObj,
  columns,
  addClassFields,
  addClassBreadCrumbs,
  editClassBreadCrumbs,
  productColumns,
  productFields,
};
