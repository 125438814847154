import { endpoints } from 'constants/endpoints';
import Request from '../client';

export const getTrainingMaterial = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.training.list,
  });
  return resp;
};
export const addTrainingMaterial = async ({ data = undefined } = {}) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.training.create,
    data,
  });
  return resp;
};
export const editTrainingMaterial = async ({ data = undefined, id } = {}) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.training.update(id),
    data,
  });
  return resp;
};
export const deleteTrainingMaterial = async ({ id } = {}) => {
  const resp = await Request({
    method: 'delete',
    API: endpoints.training.delete(id),
  });
  return resp;
};
