/** external deps */
import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import Fuse from 'fuse.js';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
  Toast,
} from 'components';
import { addTargetTypeFields, breadcrumbObj } from './data';
import { targetTypeManagement } from 'service/api-service';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { NOTIFICATION_MSG } from 'constants';
import { rowCommonClass } from 'utils/common';

const TargetType = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [dependentRequired, setDependentRequired] = useState(false);
  const [selectedTargetType, setSelectedTargetType] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredTypes, setFilteredTypes] = useState([]);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addTargetTypeFields({ dependentRequired, setDependentRequired }),
  });
  const columns = [
    {
      key: 'name',
      title: () => <div className='capitalize pl-8'>Name</div>,
      dataIndex: 'name',
      render: (data) => <p className='capitalize pl-8'>{data}</p>,
    },
    {
      key: 'model',
      title: 'Effective Term',
      align: 'center',
      dataIndex: 'model',
      render: (data) => <p className='capitalize flex justify-center'>{data}</p>,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <div className='flex gap-2 justify-center'>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.Goals_type.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.Goals_type.permissions.delete}
            onClick={() => {
              setSelectedTargetType(record);
              toggleDeleteTargetType();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
        </div>
      ),
    },
  ];

  /* Get Table Data */
  const getTargetTypeData = async () => {
    setLoading(true);
    const resp = await targetTypeManagement({ method: 'get' });
    if (resp?.data?.status) {
      setTableData(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  /* funcs */
  const onAddTargetType = async (data) => {
    setLoading(true);
    const finalPayload = {
      ...data,
      category: data?.insurance_category,
    };
    const resp = await targetTypeManagement({ method: 'post', data: finalPayload });
    if (resp?.data?.status) {
      getTargetTypeData();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
    toggleAddTargetType();
  };

  const onDeleteTargetType = async () => {
    const id = selectedTargetType?.id;
    if (!id) return;
    setLoading(true);
    const payload = { deleted: true };
    const resp = await targetTypeManagement({ method: 'patch', id, data: payload });
    if (resp?.data?.status) {
      getTargetTypeData();
      Toast('success', NOTIFICATION_MSG.success, 'Target Type deleted successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteTargetType();
    setLoading(false);
  };

  const onEditTargetType = async (data) => {
    setLoading(true);
    const prev = {
      name: editInitials?.name,
      model: editInitials?.model,
      category: editInitials?.insurance_category,
      product_type: editInitials?.product_type,
    };
    const updatedData = {
      name: data?.name,
      model: data?.model,
      category: data?.insurance_category,
      product_type: data?.product_type,
    };

    const editFields = getEditedFields({
      initialValues: prev,
      updatedData: updatedData,
    });
    if (Object.keys(editFields).length) {
      const resp = await targetTypeManagement({
        method: 'patch',
        id: selectedTargetType?.id,
        data: editFields,
      });
      toggleAddTargetType();
      if (resp?.data?.status) {
        getTargetTypeData();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  const toggleAddTargetType = () => {
    setInitials(undefined);
    setAddModal((pre) => {
      return !pre;
    });
  };

  const toggleDeleteTargetType = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(tableData, {
      keys: ['name', 'model'],
      threshold: 0.2,
    }).search(e.target.value);
    setFilteredTypes(fuse.map((i) => i.item));
  };

  const onEditPress = (record) => {
    console.log('record', record);
    const finalInitials = {
      name: record?.name,
      model: record?.model,
      insurance_category: record?.category?.id,
      product_type: record?.product_type?.name,
    };
    setInitials(finalInitials);
    setSelectedTargetType(record);
    setAddModal(true);
  };

  useEffect(() => {
    getTargetTypeData();
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        // data={breadcrumbObj}
        title={<p className='font-semibold text-s'>Goal Type</p>}
        btnText='+ Goal Type'
        buttonId={elementData?.target_type?.permissions?.create}
        onClick={toggleAddTargetType}
      />
      <TableComponent
        loading={loading}
        getRowClassName={rowCommonClass}
        columns={columns}
        dataSource={searchQuery ? filteredTypes : tableData}
      />

      {/* add branch modal */}
      <CustomModal
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddTargetType}
        footer={''}
        title={editInitials ? 'Edit Goal Type' : 'Add Goal Type'}
        width={1024}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 '>
            {editInitials
              ? 'Edit goal type details'
              : 'To add a new goal type, enter the details of the goal type in the input field below.'}
          </span>

          <FormContainer
            accordionClass='grid grid-cols-2 gap-8'
            onSubmit={editInitials ? onEditTargetType : onAddTargetType}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <>
                  <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                    <Button
                      text='Cancel'
                      variant='text'
                      type='reset'
                      classes='border border-gray-90 text-black px-6'
                      onClick={() => {
                        resetForm();
                        toggleAddTargetType();
                      }}
                    />
                    <Button
                      disabled={!isValid}
                      permissions={permissions}
                      id={
                        editInitials
                          ? elementData.Goals_type.permissions.update
                          : elementData.Goals_type.permissions.create
                      }
                      text={editInitials ? 'Update Goal Type' : 'Add Goal Type'}
                      variant='primary'
                      classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                      onClick={submitForm}
                      loading={loading}
                    />
                  </div>
                </>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* delete branch confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteTargetType}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the goal type details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteTargetType}
            />
            <Button
              text='Delete Goal Type'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteTargetType}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(TargetType);
