// custom version of documents component
import React, { useEffect, useState } from 'react';
/** internal deps */
import {
  CustomModal,
  FormContainer,
  TableComponent,
  Toast,
  Button,
  DocumentViewer,
} from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { getEmployee, updateEmployee } from 'service/api-service';
import { getColumns } from './data';
import '../style.css';
import { rowCommonClass } from 'utils/common';

const AddDocument = ({ fields, initialValues, user_id, tableData: initialTableData = [] }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState();
  const [tableData, setTableData] = useState(initialTableData);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isViewing, setIsViewing] = useState(false);

  const handleClickOnEdit = (item) => {
    setSelectedRecord(item);
    toggleModal();
  };

  const handleClickOnView = (item) => {
    setSelectedRecord(item);
    setIsViewing(true);
    toggleModal();
  };
  const handleClickOnDelete = async (item) => {
    setLoading(true);
    const resp = await updateEmployee({ data: { remove_docs: [item.id] }, id: user_id });
    if (resp?.data?.status) {
      Toast(
        'success',
        NOTIFICATION_MSG.success,
        resp?.data?.msg || 'Document deleted Successfully',
      );
      refetchTableData();
      setSelectedRecord(null);
    } else {
      Toast('error', NOTIFICATION_MSG.error, 'Failed to add document.');
      setLoading(false);
    }
  };

  const columns = getColumns(handleClickOnEdit, handleClickOnView, handleClickOnDelete);
  /* funcs */
  console.log(selectedRecord);
  const toggleModal = () => {
    if (showModal) {
      setShowModal(false);
      setSelectedRecord(null);
      setIsViewing(false);
    } else {
      setShowModal(true);
    }
  };

  /* Add Document */
  const handleAddDocument = async (data) => {
    console.log('data edited', data);
    setLoading(true);
    let dataToSend = { docs: [data] };

    if (selectedRecord) {
      console.log('hrerere');
      const editedRecord = getEditedData(data, selectedRecord);
      if (!editedRecord) {
        Toast('error', NOTIFICATION_MSG.error, 'Please update some field to continue');
        return setLoading(false);
      }

      dataToSend = { docs: [editedRecord] };
    }
    console.log('hree');
    const resp = await updateEmployee({ data: dataToSend, id: user_id });
    if (resp?.data?.status) {
      Toast(
        'success',
        NOTIFICATION_MSG.success,
        resp?.data?.msg || selectedRecord
          ? 'Document added Successfully'
          : 'Document edited Successfully',
      );
      setLoading(false);
      refetchTableData();
      toggleModal();
    } else {
      Toast('error', NOTIFICATION_MSG.error, 'Failed to add document.');
    }
    setLoading(false);
  };

  const refetchTableData = async () => {
    setLoading(true);
    const resp = await getEmployee({ id: user_id });
    console.log('full resp', resp, Array.isArray(resp?.data?.data?.docs));
    if (Array.isArray(resp?.data?.data?.docs)) {
      console.log('hrere');
      setTableData(resp.data.data.docs);
    }
    setLoading(false);
  };

  console.log('tabledata', tableData);
  return (
    <section id='add-document'>
      <div className='flex justify-between items-center'>
        <h2 className='font-semibold text-s'>Documents</h2>
        <Button text='+ Documents' variant='primary' classes='xl:px-6' onClick={toggleModal} />
      </div>
      <TableComponent
        scroll={{
          y: 225,
        }}
        loading={loading}
        showHeader={false}
        getRowClassName={rowCommonClass}
        showPagination={false}
        columns={columns}
        dataSource={tableData}
      />
      <CustomModal
        destroyOnClose={true}
        open={showModal && !isViewing}
        onCancel={toggleModal}
        footer={null}
        title={selectedRecord && !isViewing ? 'Edit' : isViewing ? 'View' : 'Add'}
        Document
      >
        <div>
          {isViewing ? (
            <span className='pb-4 text-xxs 2xl:text-sm 2xl:pb-10 '>{selectedRecord?.name}</span>
          ) : (
            <span className='pb-4 text-xxs 2xl:text-sm 2xl:pb-10 '>
              To {selectedRecord ? 'edit' : 'add'} a new document, fill the details in the input
              field below.
            </span>
          )}
          {isViewing ? (
            <iframe
              src={process.env.REACT_APP_DOC_URL + selectedRecord?.file}
              title={selectedRecord?.name}
              height={400}
              width={'100%'}
            />
          ) : (
            <FormContainer
              accordionClass='grid grid-cols-2 gap-8'
              fields={fields}
              initialValues={selectedRecord ?? initialValues}
              onSubmit={handleAddDocument}
              customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
                return (
                  <div className='flex flex-row justify-end py-4 mt-6 bg-white'>
                    <Button
                      text='Cancel'
                      variant='text'
                      type='reset'
                      classes='border border-gray-90 text-black px-6'
                      onClick={() => {
                        resetForm();
                        toggleModal();
                      }}
                    />
                    <Button
                      disabled={!isValid}
                      // permissions={permissions}
                      text={'Add Document'}
                      variant='primary'
                      classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                      onClick={submitForm}
                      loading={isSubmitting}
                    />
                  </div>
                );
              }}
            />
          )}
        </div>
      </CustomModal>
      <DocumentViewer src={selectedRecord?.file} visible={isViewing} onClose={toggleModal} />
    </section>
  );
};

export default AddDocument;

const getEditedData = (newData, oldData) => {
  const dataToReturn = {};
  if (typeof newData?.file === 'string' && newData.file.startsWith('data:image'))
    dataToReturn.file = newData.file;
  if (newData?.name !== oldData?.name) dataToReturn.name = newData.name;
  if (!Object.values(dataToReturn).length) return null;

  dataToReturn.id = newData.id;
  return dataToReturn;
};
