import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ImagePreviewEmail, ImagePrinter, ImageSave, ImageShareEmail } from 'assets';
import { useLeads } from '../store';
import '../typedefs/quotations';
import {
  Checkbox,
  Input,
  Modal,
  Button as ButtonAntd,
  Select,
  ConfigProvider,
  Divider,
} from 'antd';
import Button from 'components/button';
import UploadArea from './UploadArea';
import { createPortal } from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { DELETE, DocumentIcon, DropdownIcon } from 'assets/icons';
import { DocumentViewer, IconButton, Toast } from 'components';
import { useShallow } from 'zustand/react/shallow';
import { createHtml, createOnlineHtml } from '../data';
import { Context } from 'store/context';
import { NOTIFICATION_MSG } from 'constants';
import { inquiryManagement, sendEmail } from 'service/api-service';
import { useReactToPrint } from 'react-to-print';
import { htmlStringToPdf } from 'utils/common';
import OnlineQuote from './OnlineQuote';
const antdGlobalConfig = {
  theme: {
    token: {
      fontFamily: "'Poppins', sans-serif",
      colorBgContainer: '#f5fafd',
      colorBorder: '#d1eaf9',
      colorPrimary: '#0089cf',
    },
  },
};

useLeads.subscribe((state) => {});

const Quotations = () => {
  const [isOnline, setIsOnline] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [imageData, setImageData] = useState(null);
  const iframeRef = useRef(null);
  /** @type {{isFetching: boolean; data: Quotation | null; error: string | null}} */
  const quotationsData = useLeads((state) => state.quotations.data);
  const quotations = useLeads((state) => state.quotations);
  const { company, user } = useContext(Context);
  const toggleIsCoverageChecked = useLeads((state) => state.toggleIsCoverageChecked);
  const toggleIsProductChecked = useLeads((state) => state.toggleIsProductChecked);
  const { isOnlineProduct } = useLeads((state) => ({
    isOnlineProduct: state.isOnlineProduct,
  }));
  const onSubmitQuotations = useLeads((state) => state.onSubmitQuotations);
  const [preview, setPreview] = useState();
  const [htmlFile, setHtmlFile] = useState();
  const [showPreview, setShowPreview] = useState();
  const setCurrentlyOpenedModalName = useLeads((state) => state.setCurrentlyOpenedModalName);
  const setDocIdToDelete = useLeads((state) => state.setDocIdToDelete);
  const setSelectedProduct = useLeads((state) => state.setSelectedProduct);
  const navigate = useNavigate();
  const sidebar = document.getElementById('leads-portal');
  const params = useParams();
  const handleClickSaveAndExit = async () => {
    const submitted = await onSubmitQuotations(params.mode);
    if (!submitted) return;
    navigate('/lead');
  };
  const handleClickNext = async () => {
    const submitted = await onSubmitQuotations(params.mode);
    if (submitted) navigate(`/lead/${params.mode}/${params.id}/3`);
  };
  // functions for header buttons
  const getHtml = useCallback(() => {
    if (isOnline === true) {
      const rows = [
        {
          insurer: quotationsData?.products[0]?.insurer[0]?.name,
          product: quotationsData?.products[0]?.product_type,
          premium: quotationsData?.insurance_details[0]?.PremiumSummary?.AnnualPremiumWithTax,
          sum: quotationsData?.insurance_details[0]?.PremiumSummary?.AnnualPremium,
          cover: [
            {
              name: quotationsData?.insurance_details[0]?.PremiumSummary?.LifeCoverOption,
              amount: quotationsData?.insurance_details[0]?.PremiumSummary?.DeathBenefit,
            },
          ],
          document: '',
        },
      ];
      return createOnlineHtml(rows, {
        company: company.name,
        customer: quotations?.data?.customer?.name || quotations?.data?.customer?.corp_name,
        companyEmail: company.email,
        sender: user?.name,
        document: '',
      });
    } else {
      const quotation = quotations?.data?.quote;
      if (quotation) {
        const rows = Object.keys(quotation).map((key) => ({
          ...quotation[key],
          insurer: quotation[key]?.insurer,
          id: key,
        }));
        return createHtml(rows, {
          company: company.name,
          customer: quotations?.data?.customer?.name || quotations?.data?.customer?.corp_name,
          companyEmail: company.email,
          sender: user?.name,
          inq: quotation?.id,
          document: quotation?.document,
        });
      }
    }
  }, [quotations, user, company]);

  const onPreview = useCallback(() => {
    const html = getHtml();
    setPreview(html);
    setShowPreview(true);
  }, [getHtml]);

  const printQuote = useReactToPrint({
    content: () => iframeRef.current,
  });
  // setIsOnlineProduct(true);

  const downloadQuote = useCallback(() => {
    const html = getHtml();
    htmlStringToPdf(html);
  }, [getHtml]);
  const closePreview = useCallback(() => {
    setShowPreview(false);
  }, []);

  const sendToEmail = useCallback(async () => {
    const html = getHtml();
    const email = [];
    email.push(quotations?.data?.customer?.email_address);
    const payload = {
      to: email,
      // subject: `Quotation for ` + inquiry.ins_cat_id.name + ` Insurance`,
      subject: `Quotation for Insurance`,
      body: html,
      type_name: 'quotation',
    };
    const resp = await sendEmail({ data: payload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  }, [quotations, getHtml]);

  const handleStatusChange = useCallback(
    (status) => {
      const onOk = async () => {
        const resp = await inquiryManagement({
          method: 'patch',
          id: params.id,
          data: { status },
        });
        if (resp?.data?.status) {
          Toast('success', NOTIFICATION_MSG.success, `Status updated successfully`);
          navigate('/lead');
        } else {
          Toast('error', NOTIFICATION_MSG.error, 'Please try again');
        }
      };
      Modal.confirm({
        title: 'Are you sure?',
        okText: 'Yes',
        onOk: onOk,
      });
    },
    [navigate, params.id],
  );

  useEffect(() => {
    if (quotationsData?.online) {
      setIsOnline(false);
    }
    const html = getHtml();
    setHtmlFile(html);
  }, [getHtml]);

  const handleOpenModal = (imgData) => {
    setOpenModal(true);
    setImageData(imgData);
  };

  if (quotations.isFetching || quotations.error || !quotations.data) return null;

  return (
    <section className='grid max-h-[70vh] overflow-y-auto gap-3 p-2'>
      <ConfigProvider {...antdGlobalConfig}>
        <nav className='flex justify-between'>
          <div className='flex  border border-[#0089CF] rounded-md'>
            <ButtonGroupItem selected={isOnline} onClick={() => setIsOnline(true)}>
              Offline
            </ButtonGroupItem>
            <ButtonGroupItem selected={!isOnline} onClick={() => setIsOnline(false)}>
              Online
            </ButtonGroupItem>
          </div>
          <div className='flex gap-2'>
            {quotations.data.status === 'quoted' && (
              <Select
                className='p-1 pl-2 h-10 rounded-lg border group border-grey-90 hover:border-primary-90'
                suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
                placeholder='Change Status'
                options={STATUS_OPTIONS}
                onChange={handleStatusChange}
                dropdownAlign={{ offset: [-10, 2] }}
              />
            )}
            <ButtonAction onClick={onPreview} image={ImagePreviewEmail} />
            <ButtonAction onClick={sendToEmail} image={ImageShareEmail} />
            <ButtonAction onClick={printQuote} image={ImagePrinter} />
            <ButtonAction onClick={downloadQuote} image={ImageSave} />
          </div>
        </nav>
        <ul className='flex justify-between p-4 shadow-custom'>
          <li>
            Client name: <span className='font-semibold'>{quotations.data.customer?.name}</span>
          </li>
          <li>
            Phone: <span className='font-semibold'>{quotations.data.customer.contact_number}</span>
          </li>
        </ul>
        {isOnline ? (
          <>
            {!isOnlineProduct ? (
              <ul className='grid gap-2 p-4 divide-y shadow-custom'>
                {quotations.data.products.map((product) => (
                  <li className='py-4' key={product.id}>
                    <p className='text-sm font-medium'>{product.product_type.name}</p>
                    <ul className='grid gap-2 divide-y'>
                      {product.insurer.map(
                        (insurer) =>
                          insurer.__toShow !== false && (
                            <li className='grid gap-2 py-4' key={insurer.id}>
                              <figure className='flex gap-2 items-center'>
                                <img
                                  src={process.env.REACT_APP_DOC_URL + insurer.image}
                                  alt=''
                                  className='object-contain h-8 aspect-video'
                                />
                                <figcaption>{insurer.name}</figcaption>
                              </figure>
                              <div className='grid grid-cols-[.7fr,.3fr] items-start gap-6'>
                                <Form
                                  productId={product.id}
                                  insurerId={insurer.id}
                                  values={insurer.__formValues}
                                >
                                  {insurer.__docPayloadWithLink ? (
                                    <div className='flex gap-2 items-center'>
                                      <div
                                        onClick={() =>
                                          handleOpenModal(insurer.__docPayloadWithLink.image)
                                        }
                                        className='cursor-pointer flex space-x-1'
                                      >
                                        <p> Document Uploaded</p>
                                        <DocumentIcon className='w-6 h-6 fill-primary-90' />
                                      </div>
                                      <IconButton
                                        title='Delete'
                                        onClick={() => {
                                          setCurrentlyOpenedModalName('delete');
                                          setDocIdToDelete(insurer.__docPayloadWithLink.id);
                                          setSelectedProduct({
                                            id: product.id,
                                            insurerId: insurer.id,
                                          });
                                        }}
                                      >
                                        {' '}
                                        <DELETE svgClass='stroke-red-90' />
                                      </IconButton>
                                    </div>
                                  ) : (
                                    <Button
                                      classes='!bg-[#0089CF] '
                                      onClick={() => {
                                        setCurrentlyOpenedModalName('upload');
                                        setSelectedProduct({
                                          id: product.id,
                                          insurerId: insurer.id,
                                        });
                                      }}
                                      text='Attach Prospect'
                                    />
                                  )}
                                </Form>
                                <div className='grid gap-3'>
                                  <p>Select type of coverage</p>
                                  <ul className='grid gap-0'>
                                    {product.product_type.coverages.map((coverage) => (
                                      <Checkbox
                                        className='!ml-0'
                                        key={coverage?.name}
                                        checked={isCoverageChecked(insurer, coverage)}
                                        onChange={(e) =>
                                          toggleIsCoverageChecked(
                                            product.id,
                                            insurer.id,
                                            coverage,
                                            e.target.checked,
                                          )
                                        }
                                      >
                                        {coverage?.name}
                                      </Checkbox>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </li>
                          ),
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            ) : (
              <p className='text-md font-semibold text-center py-20 max-h-[50vh] h-[40vh]'>
                Go to online tab...
              </p>
            )}
          </>
        ) : (
          <>
            {isOnlineProduct ? (
              <OnlineQuote />
            ) : (
              <p className='text-md font-semibold text-center py-20 max-h-[50vh] h-[40vh]'>
                Go to offline tab...
              </p>
            )}
          </>
        )}
        <UploadModal />
        <ConfirmationModal />
        {isOnline && (
          <div className='flex justify-end gap-3 '>
            <ButtonAntd
              size='large'
              className='bg-[#C3C3C3] !rounded-md !h-9 border-none [&_*]:hover:text-black hover:border-none'
              onClick={() => navigate('/lead')}
            >
              Cancel
            </ButtonAntd>
            <ButtonAntd
              size='large'
              className='text-white !rounded-md !h-9 border-none bg-[#0089CF] [&_*]:hover:text-white hover:border-none '
              onClick={() => navigate(`/lead/${params.mode}/${params.id}/1`)}
            >
              Prev
            </ButtonAntd>
            <ButtonAntd
              size='large'
              className='text-white !rounded-md !h-9 border-none bg-[#26CBED] [&_*]:hover:text-white'
              onClick={handleClickSaveAndExit}
            >
              Save & Exit
            </ButtonAntd>

            <ButtonAntd
              size='large'
              className='bg-green-60 !rounded-md !h-9 border-none text-white [&_*]:hover:text-white hover:border-none '
              onClick={handleClickNext}
            >
              Next
            </ButtonAntd>
          </div>
        )}
        {sidebar &&
          createPortal(
            <div>
              <div className='grid  gap-2  mr-2'>
                <Divider className='bg-[#00000036] my-0 w-full ' />
              </div>
              <div className='grid overflow-x-scroll gap-2 p-4 mt-5 mr-2 bg-white rounded'>
                <p className='text-sm font-semibold'>Select Insurers</p>
                <ul className='grid gap-2'>
                  {quotations.data.products.map((product) => (
                    <li key={product.id}>
                      <p>{product.product_type.name}</p>
                      <ul className='grid gap-0'>
                        {product.insurer.map((insurer) => (
                          <li key={insurer.id}>
                            <Checkbox
                              className='!ml-0'
                              key={insurer?.name}
                              checked={insurer.__toShow !== false}
                              onChange={(e) =>
                                toggleIsProductChecked(product.id, insurer.id, e.target.checked)
                              }
                            >
                              {insurer?.name}
                            </Checkbox>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            </div>,
            sidebar,
          )}

        <Modal
          title='Quotation'
          width={window.innerWidth - 100}
          open={showPreview}
          onCancel={closePreview}
          footer={false}
        >
          <div
            // ref={contentRef}
            className='p-4 borderded'
            dangerouslySetInnerHTML={{ __html: preview }}
          ></div>
        </Modal>
        <DocumentViewer src={imageData} visible={openModal} onClose={() => setOpenModal(false)} />
        <div className='hidden'>
          <div className='p-4' ref={iframeRef} dangerouslySetInnerHTML={{ __html: htmlFile }}></div>
        </div>
      </ConfigProvider>
    </section>
  );
};

export default Quotations;

/** @param {import('react').ButtonHTMLAttributes & {selected: boolean}} props */
const ButtonGroupItem = ({ selected = false, children, ...rest }) => (
  <button
    className={`${selected ? 'bg-[#0089CF] text-white' : 'bg-transparent'} px-3 py-1 rounded-md`}
    {...rest}
  >
    {children}
  </button>
);

/** @param {import('react').ButtonHTMLAttributes & {image: string}} props */
const ButtonAction = ({ image, ...rest }) => (
  <button className='p-2 rounded-md shadow-custom' {...rest}>
    <img src={image} alt='' />
  </button>
);

/** @param {{productId: string; insurerId: string, values?: { premium: string, sum: string, ncb: string }}} props */
const Form = ({ productId, insurerId, values = { premium: '', sum: '', ncb: '' }, children }) => {
  const onChangeInsurerFormFields = useLeads((state) => state.onChangeInsurerFormFields);

  const handleChange = (name, value) => {
    if (Number.isNaN(+value)) return;
    if (name === 'ncb' && Number(value) > 100) return;
    onChangeInsurerFormFields(productId, insurerId, { ...values, [name]: value });
  };

  return (
    <div className='grid  gap-4'>
      <ul className='flex flex-wrap justify-between items-start'>
        <li className='grid'>
          <p>Premium</p>
          <Input
            /** as someone(`git blame` says raspreet ¯\_ (ツ)_/¯) overrode antd's input height in `src/screens/roles/role-permissions/style.css` */
            className='[&_input]:!h-auto p-0'
            prefix={<p className='h-8 w-8 rounded-md flex justify-center bg-[#CFEDFF] py-1'>₹</p>}
            value={values.premium}
            onChange={(e) => handleChange('premium', e.target.value.trim())}
          />
        </li>
        <li className='grid'>
          <p>Sum Insured</p>
          <Input
            className='[&_input]:!h-auto p-0'
            prefix={<p className='h-8 w-8 rounded-md flex justify-center bg-[#CFEDFF] py-1'>₹</p>}
            value={values.sum}
            onChange={(e) => handleChange('sum', e.target.value.trim())}
          />
        </li>
        <li className='grid'>
          <p>NCB</p>
          <Input
            className='[&_input]:!h-auto p-0'
            prefix={<p className='h-8 w-8 rounded-md flex justify-center bg-[#CFEDFF] py-1'>%</p>}
            value={values.ncb}
            onChange={(e) => handleChange('ncb', e.target.value.trim())}
          />
        </li>
      </ul>
      {children}
    </div>
  );
};

const ConfirmationModal = () => {
  const { currentlyOpenedModalName, setCurrentlyOpenedModalName, deleteUploadedDocs } = useLeads(
    useShallow((state) => ({
      currentlyOpenedModalName: state.quotations.currentlyOpenedModalName,
      setCurrentlyOpenedModalName: state.setCurrentlyOpenedModalName,
      deleteUploadedDocs: state.deleteUploadedDocs,
    })),
  );

  const handleCancel = () => setCurrentlyOpenedModalName(null);
  const handleDelete = async () => {
    const wasDocDeleted = await deleteUploadedDocs();
    if (wasDocDeleted) setCurrentlyOpenedModalName(null);
  };
  return (
    <Modal
      rootClassName='[&_.ant-modal-content]:!p-0 [&_.ant-modal-content]:overflow-hidden [&_.ant-modal-close]:-translate-y-2'
      open={currentlyOpenedModalName === 'delete'}
      title={
        <div className='flex justify-between p-2 bg-primary-30'>
          <p className='text-sm text-white'>Are you sure you want to delete the document?</p>
        </div>
      }
      onCancel={handleCancel}
      footer={null}
    >
      <div className='flex gap-2 justify-end p-4 '>
        <button className='px-3 py-2 rounded bg-slate-200' onClick={handleCancel}>
          Cancel
        </button>
        <button className='px-3 py-2 text-white rounded bg-primary-30' onClick={handleDelete}>
          Yes
        </button>
      </div>
    </Modal>
  );
};

const UploadModal = () => {
  const { currentlyOpenedModalName, setCurrentlyOpenedModalName, uploadDocs, setDocDataToSend } =
    useLeads(
      useShallow((state) => ({
        currentlyOpenedModalName: state.quotations.currentlyOpenedModalName,
        setCurrentlyOpenedModalName: state.setCurrentlyOpenedModalName,
        uploadDocs: state.uploadDocs,
        setDocDataToSend: state.setDocDataToSend,
      })),
    );
  const handleCloseModal = () => {
    setCurrentlyOpenedModalName(null);
    setDocDataToSend(null);
  };

  const handleClickSave = async () => {
    const uploaded = await uploadDocs();
    if (uploaded) setCurrentlyOpenedModalName(null);
  };

  return (
    <Modal
      rootClassName='[&_.ant-modal-content]:!p-0 [&_.ant-modal-content]:overflow-hidden [&_.ant-modal-close]:-translate-y-2'
      open={currentlyOpenedModalName === 'upload'}
      title={
        <div className='flex justify-between p-2 bg-primary-30'>
          <p className='text-sm text-white'>Please select document</p>
        </div>
      }
      onCancel={handleCloseModal}
      footer={null}
      destroyOnClose
    >
      <div className='grid gap-3 p-4'>
        <p className='text-m'>Upload document</p>
        <UploadArea onPickFile={(file) => setDocDataToSend(file)} />
        <div className='grid grid-cols-2 gap-2 justify-self-end'>
          <button className='px-3 py-2 rounded bg-slate-200' onClick={handleCloseModal}>
            Cancel
          </button>
          <button className='px-3 py-2 text-white rounded bg-primary-30' onClick={handleClickSave}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

const isCoverageChecked = (insurer, coverage) => {
  if (!Array.isArray(insurer.__coverages)) return false;
  if (insurer.__coverages.some((__coverage) => __coverage.id === coverage?.id)) return true;
  return false;
};

const STATUS_OPTIONS = [
  {
    label: 'Mark as Accepted',
    value: 'accepted',
  },
  {
    label: 'Mark as Converted',
    value: 'converted',
  },
  {
    label: 'Mark as Rejected',
    value: 'rejected',
  },
];
