import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import './style.css';

Quill.register('modules/imageResize', ImageResize);

const TOOLBAR_OPTIONS = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['link', 'image'],
  [({ color: [] }, { background: [] })], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ['clean'], // remove formatting button
];

const MarkDown = ({ field, form, label, divClasses, disabled, placeholder, ...props }) => {
  const _onChange = (val) => {
    if (props?.customOnChange) {
      props?.customOnChange(field?.name, val, form);
      return form?.setFieldValue(field?.name, val);
    }
    form?.setFieldValue(field?.name, val);
  };
  return (
    <div id='markdown' className={`group relative ${divClasses}`}>
      <label
        className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xxs text-black ${
          props?.required ? 'required' : ''
        } `}
      >
        {label}
      </label>
      <input type='hidden' id={field?.name} name={field?.name} />
      <ReactQuill
        defaultValue={form?.initialValues[field?.name] || props?.defaultValue}
        className={`group ${props?.height} overflow-hidden placeholder:text-xxs bg-[#F5FAFD] border-[#F5FAFD] `}
        theme='snow'
        placeholder={placeholder}
        onChange={_onChange}
        modules={{
          toolbar: {
            container: TOOLBAR_OPTIONS,
          },
          imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize'],
          },
        }}
      />
      {form?.errors[field?.name] && form?.touched[field?.name] && (
        <div
          className={`absolute bg-white ${
            props?.extraRightRender ? 'max-w-[230px]' : ''
          } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
        >
          {form?.errors[field?.name]}
        </div>
      )}
    </div>
  );
};

export default MarkDown;
