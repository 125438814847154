import { NotUploaded } from 'assets';

const { useContext } = require('react');
const { Context } = require('store/context');

const CustomSocialPostTemplate = ({ tab, title, description, upload, selectedAsset }) => {
  const { company } = useContext(Context);

  return (
    <div className='border-2 h-[480px] overflow-hidden w-[50%] m-auto rounded-lg border-grey-80 p-2'>
      <div className='flex items-center gap-2'>
        <img className='h-10 rounded-full' src={company?.logo} alt='' />
        <div className='flex flex-col gap-[-4px]'>
          <p className='text-[16px] text-grey-60 font-medium'>{company?.name}</p>
          <p className='text-xxs text-grey-60 '>Published by {company?.name}</p>
        </div>
      </div>

      <div>
        <p className='font-medium max-h-10 overflow-hidden my-2'>
          {' '}
          {title?.title ? title?.title : `${selectedAsset?.designation?.name} Recruitment`}{' '}
        </p>
        <p className='max-h-16 overflow-y-auto '>
          {description?.description ? description?.description : selectedAsset?.description}
        </p>
      </div>
      <img
        className='h-[250px] w-[100%] mt-2 border-2 rounded-lg bordered'
        src={upload ? upload : selectedAsset?.publish?.image || NotUploaded}
        alt=''
      />
    </div>
  );
};

export default CustomSocialPostTemplate;
