import Request from './client';

export const acknowlegeLeave = async (id, status, note) => {
  const resp = await Request({
    method: 'patch',
    API: 'leave/status/' + id,
    data: {
      status,
      note,
    },
  });
  return resp;
};

export const leaveManagement = async (
  props = {
    id: '',
    method: 'get',
    data: '',
    statusVal: '',
    page: '',
    status: '',
  },
) => {
  let API = `leave/?status=${props?.statusVal || ''}&page=${props?.page}`;

  if (props.method === 'delete' || props.method === 'patch') {
    API = `leave/?id=${props?.id}`;
  }

  if (props.method === 'post') {
    API = 'leave/';
  }

  const resp = await Request({
    method: props?.method,
    API,
    data: props?.data,
  });
  return resp;
};

export const getLeaveBalance = async (id) => {
  const resp = await Request({
    method: 'get',
    API: `leave/balance?leave_type=${id}`,
  });
  return resp;
};

export const leaveDashManagement = async (
  props = { id: '', method: 'get', data: '', statusVal: '', page: '', date: '' },
) => {
  const resp = await Request({
    method: props?.method,
    API:
      props.method === 'delete' || props.method === 'patch'
        ? `leave/${props?.updateStatus ? 'status/' : ''}${props?.id}`
        : `leave/?status=${props?.statusVal || ''}&date=${props?.date || ''}`,
    data: props?.data,
  });
  return resp;
};

export const getParticularLeave = async (leave_id) => {
  const resp = await Request({
    method: 'get',
    API: 'leave/?id=' + leave_id,
  });
  return resp;
};

export const leaveManagementAdmin = async (
  props = { id: '', method: 'get', data: '', statusVal: '', page: '', status: '' },
) => {
  const resp = await Request({
    method: props?.method,
    API:
      props.method === 'delete' || props.method === 'patch'
        ? `leave/requests?id=${props?.id}`
        : `leave/requests?page=${props?.page}&status=${props?.status}`,
    data: props?.data,
  });
  return resp;
};
