import { CustomModal, IconButton, TableComponent } from 'components';
import { Tablecolumns } from './data';
import { Space } from 'antd';
import { EDIT, GoalsEditIcon } from 'assets/icons';
import AddTarget from '../AddTarget';
import { useCallback, useState } from 'react';
import { rowCommonClass } from 'utils/common';
import './index.css';

export const galRowCommonClass = ({ record, index }) => {
  return 'bg-white';
};

const GoalsTable = ({ permissions = {}, loading, dataSource, fetchData, employee }) => {
  const [editModal, setEditModal] = useState(false);
  const [editInitials, setEditInitials] = useState();

  const toggleEditModal = () => {
    setEditModal((prev) => !prev);
  };
  const ongoingPagination = useCallback((pages) => {
    const page = pages?.current;
    if (page) {
      fetchData(page);
    }
  }, []);

  const onEditGoals = useCallback((data) => {
    const record = { ...data, type: data?.type?.id };
    setEditInitials(record);
    toggleEditModal();
  }, []);
  return (
    <div id='goalTable'>
      <TableComponent
        loading={loading}
        getRowClassName={galRowCommonClass}
        columns={[
          ...Tablecolumns,
          {
            title: 'Action',
            key: 'action',
            // align: 'right',
            render: (_, record) => (
              // <Space size='middle'>
              <IconButton
                title='Edit'
                permissions={permissions}
                className='group disabled:cursor-not-allowed'
                // id={elementData.Goals.permissions.update}
                onClick={onEditGoals.bind(this, record)}
              >
                <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
              </IconButton>
              // </Space>
            ),
          },
        ]}
        dataSource={dataSource?.result}
        onChange={ongoingPagination}
        pageSize={5}
        total={dataSource?.total_page}
        defaultPage={dataSource?.current_page}
      />

      <CustomModal destroyOnClose={true} open={editModal} onCancel={toggleEditModal} footer={''}>
        <AddTarget
          employee={employee}
          employee_id={employee?.id}
          getTargets={fetchData}
          initialValues={editInitials}
          showBack={false}
          goBack={toggleEditModal}
          type={'edit'}
        />
      </CustomModal>
    </div>
  );
};

export default GoalsTable;
