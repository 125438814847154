import { LoadingOutlined } from '@ant-design/icons';
import { Drawer, Spin, Tooltip, message, Button as AntButton } from 'antd';
import { CommentIcon, Send } from 'assets/icons';
import TextInput from 'components/form-components/TextInput';
import { UserInitialIcon } from 'components/userinitialicon';
import { useContext, useEffect, useState } from 'react';
import { getLead, postLead } from 'service/api-service';
import { Context } from 'store/context';
import { formatDate } from 'utils';
import './styles.css';

const CommentSection = ({ id }) => {
  // const { id } = useParams();
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getList = async () => {
    setLoading(true);
    const resp = await getLead({ lead_id: id });
    if (resp?.data?.status) {
      setData(resp?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id && id !== 'new') {
      getList();
    }
  }, []);

  const onSendClick = async (data) => {
    if (loading || !comment) return;
    setLoading(true);
    const payload = {
      comment: comment,
      user_id: user?.id,
    };
    const resp = await postLead({ data: payload, lead_id: id });
    if (resp?.data?.status) {
      setComment('');
      getList();
    } else {
      message.error(resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const getSendIcon = () => {
    return (
      <AntButton
        type='text'
        className='flex justify-end items-end'
        disabled={!comment}
        icon={<Send svgClass={` ${comment ? 'fill-primary' : 'fill-grey-90'} w-5 h-5`} />}
      />
    );
  };

  const onChange = (e) => setComment(e.target.value);

  const renderList = (item, index) => (
    <div className='flex gap-2 my-4' key={index}>
      <Tooltip title={item.user_id.name}>
        <div>
          <UserInitialIcon name={item.user_id.name} />
        </div>
      </Tooltip>
      <div className='shadow-custom rounded-md  p-2 w-full'>
        <div className='flex justify-between '>
          <span className='text-s font-semibold'>{item.user_id.name}</span>
          <span className='text-x text-primary-30'>
            {formatDate({
              dateString: item?.updated,
              toUtc: false,
              format: 'DD MMMM, YYYY ',
            })}
          </span>
        </div>
        <span className='break-all'> {item.comment}</span>
      </div>

      {/* <div
        className={`grid grid-flow-col justify-start gap-4 my-1  p-2  border-2 `}
      // onClick={onClick}
      > */}
      {/* <button className={`${backgroundIs} rounded-full h-6 w-6`} title={item.user_id}>
            <span className="text-white table-cell text-center h-7 w-7 text-x align-middle font-medium">
              {getNameLetter({ name: item.user_id })}
            </span>
          </button> */}
    </div>
    // </div>
  );
  return (
    <>
      {/* <AntButton className='border-none flex items-center p-2' onClick={showDrawer} type='primary'>
        Comments
      </AntButton> */}
      <div
        onClick={showDrawer}
        className='bg-white h-8 w-8 rounded-md flex justify-center items-center cursor-pointer'
      >
        <CommentIcon svgClasses=' ' />
      </div>
      {/* <AntButton onClick={showDrawer} className='border-none flex items-center p-0'>
                Comments
            </AntButton> */}
      <Drawer title='Comments' placement='right' onClose={onClose} open={open}>
        <div id='commentSection' className=' relative col-span-full p-1  w-full h-full m-auto'>
          <div className='col-span-full overflow-y-auto ' id='chat-box'>
            {loading ? <Spin tip='Loading' /> : data.map(renderList)}
          </div>
          {/* <div className='absolute bottom-0 right-0'> */}
          <TextInput
            divClasses={'absolute bottom-4  col-span-full commentInput'}
            field={{ name: 'comment', value: comment, onChange }}
            placeholder='Type message here'
            rightIcon={
              loading ? <LoadingOutlined className='text-primary' spin={true} /> : getSendIcon
            }
            onIconClick={() => onSendClick()}
          />
          {/* </div> */}
        </div>
      </Drawer>
    </>
  );
};

export default CommentSection;
