import React from 'react';
import { Switch } from 'antd';
import './style.css';

const CustomSwitch = ({ field, form, divClasses, ...props }) => {
  const _onChange = (e) => {
    form?.setFieldValue(field?.name, e);
  };

  return (
    <div className={`group ${divClasses}`}>
      <label
        className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xs text-black ${
          props?.required ? 'required' : ''
        }`}
      >
        {props.label}
      </label>
      <Switch
        className='bg-grey'
        defaultChecked={props?.checked || form?.initialValues[field?.name]}
        name={field?.name}
        onChange={_onChange}
        required={props?.required}
        checkedChildren={props?.checkedChildren}
        unCheckedChildren={props?.unCheckedChildren}
      />

      {form?.errors[field?.name] && form?.touched[field?.name] && (
        <div
          className={`bg-white last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
        >
          {form?.errors[field?.name]}
        </div>
      )}
    </div>
  );
};

export default CustomSwitch;
