export const parseQuoteData = (data) => {
  return {
    customer: data?.customer_id?.id,
    contact: data?.contact?.id,
    employee: data?.assign_to_employee?.id,
    branch: data?.branch,
    inquiry: data?.inquiry?.id,
    des: data?.des,
    subject: data?.subject,
    category: data?.insurance_category,
    doi: data?.doi,
    duration: data?.duration,
    ins_from: data?.from,
    ins_to: data?.to,
    stage: data?.stage,
    gst_type: data?.gst_type,
    collateral: data?.collateral,
    app_reference: data?.app_reference,
    pre_payment: data?.premium_payment,
    // insurance_details: JSON.stringify(data?.insurance_details),
    product: data?.product,
    insurance_details: data?.insurance_details,
  };
};

export const createExpandableList = (list) => {
  let obj = {};
  list.forEach((item) => {
    obj[item.employee.id] = item;
  });
  return Object.keys(obj).map((key) => {
    const targets = list.filter((i) => i.employee.id == key); // to check for strings
    return {
      ...obj[key],
      key: key,
      targets,
      total: targets.length,
      progress: targets.reduce((item) => item),
    };
  });
};
