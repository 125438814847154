import React, { useEffect, useRef } from 'react';
import { motion, useAnimation, useScroll, useSpring } from 'framer-motion';
import './style.css';

const Playground = () => {
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({
    container: scrollRef,
  });

  return (
    <div ref={scrollRef} style={{ width: '100%', height: '100vh', overflow: 'scroll' }}>
      <motion.div className='w-48 h-48 bg-green m-6'></motion.div>
      <motion.div className='w-48 h-48 bg-green m-6'></motion.div>
      <motion.div className='w-48 h-48 bg-red m-6' style={{ scaleX: scrollYProgress }}></motion.div>
      <motion.div className='w-48 h-48 bg-green m-6'></motion.div>
      <motion.div className='w-48 h-48 bg-green m-6'></motion.div>
      <motion.div className='w-48 h-48 bg-green m-6'></motion.div>
      <motion.div className='w-48 h-48 bg-green m-6'></motion.div>
      <motion.div className='w-48 h-48 bg-green m-6'></motion.div>
      <motion.div className='w-48 h-48 bg-green m-6'></motion.div>
      <motion.div className='w-48 h-48 bg-green m-6'></motion.div>
      <motion.div className='w-48 h-48 bg-green m-6'></motion.div>
      <motion.div className='w-48 h-48 bg-green m-6'></motion.div>
      <motion.div className='w-48 h-48 bg-green m-6'></motion.div>
    </div>
  );
};

export default Playground;
