import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableComponent, Button } from 'components';
import { UpcomingLeave_Cols } from './data';
import { leaveDashManagement } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const UpcomingLeave = (props = {}) => {
  const date = props?.date || '';
  /* states */
  const [loading, setLoading] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const navigate = useNavigate();

  /* func */
  const fetchData = async () => {
    setLoading(true);
    const resp = await leaveDashManagement({ statusVal: 'drafted', date });
    if (resp?.data?.data) {
      setdataSource(resp?.data?.data?.result);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [date]);
  return (
    <div className='space-y-4 bg-white p-4 border rounded-md border-grey-40 h-full'>
      <div className='flex items-center justify-between'>
        <div className='font-semibold text-s leading-6'>Upcoming Leaves</div>
        <Button classes='!py-1' text='See all' onClick={navigate.bind(this, '/hr/leaves')} />
        {/* <button
          className='font-semibold text-primary-40 text-x'
          onClick={navigate.bind(this, '/hr/leaves')}
        >
          See all
        </button> */}
      </div>
      <div className='customTable' id='dashboard'>
        <TableComponent
          getRowClassName={rowCommonClass}
          loading={loading}
          pageSize='3'
          columns={UpcomingLeave_Cols}
          dataSource={dataSource}
          showPagination={false}
          scroll={{ y: 250 }}
        />
      </div>
    </div>
  );
};

export default UpcomingLeave;
