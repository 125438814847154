export const breadcrumbObjAdd = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Policy',
    path: '/policy',
  },
  {
    name: 'View Policy',
    path: '',
    target: '_blank',
  },
];

export const SECTION_DATA_VIEW = [
  {
    title: '',
    keys: ['policy_type', 'inquiry.lead_id'],
  },
  {
    title: 'Client Details',
    keys: [
      'customer',
      'contact.name',
      'contact_number',
      'email_address',
      'branch',
      'nature_of_business',
    ],
  },
  {
    title: 'Policy Details',
    keys: [
      'duration',
      'issue_date',
      'comm_date',
      'exp_date',
      'category',
      'product_type',
      'employee',
    ],
  },
  {
    title: 'Insurance Company Details',
    keys: ['insurer', 'premium', 'sum_insured', 'ncb', 'g_premium', 'gst_pre', 'other_fees'],
  },
  {
    title: 'Billing Address',
    keys: [
      'billing_address.address',
      'billing_address.country',
      'billing_address.state',
      'billing_address.city',
      'billing_address.pincode',
    ],
  },
  {
    title: 'Registered Address',
    keys: [
      'registered_address.address',
      'registered_address.country',
      'registered_address.state',
      'registered_address.city',
      'registered_address.pincode',
    ],
  },
];
