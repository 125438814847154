import { Image } from 'antd';
import React from 'react';

/**
 * @typedef {import("antd").ImageProps} ImageProps
 * @param {Omit<ImageProps, 'preview'> & {visible: boolean; previewProps: ImageProps['preview']; onClose: () => void}} props
 */
const DocumentViewer = ({ src, visible = false, previewProps, onClose, style = {}, ...rest }) => {
  const source = getFileSrc(src);
  const mimeType = getFileType(src);

  return (
    <Image
      style={{ ...style, display: 'none' }}
      src={source}
      // fallback='/favicon.ico'
      preview={{
        destroyOnClose: true,
        onVisibleChange: onClose,
        visible,
        imageRender: mimeType.startsWith('image')
          ? undefined
          : () => (
              <object
                data={source}
                aria-label='document'
                height='90%'
                width='80%'
                type={mimeType}
              />
            ),
        toolbarRender: mimeType.startsWith('image') ? undefined : () => null,
      }}
      {...rest}
    />
  );
};

/** @param {string} src */
export const getFileType = (src) => {
  if (!src || typeof src !== 'string') return 'application/octet-stream';

  const fileExtension = src.split('.').pop().toLowerCase();

  switch (fileExtension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return `image/${fileExtension}`;
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/octet-stream';
  }
};

/** @param {string} src */
export const getFileSrc = (src) => {
  if (!src) return null;

  if (src.startsWith('http')) return src;
  return `${process.env.REACT_APP_DOC_URL}${src}`;
};

export default DocumentViewer;
