// import { QUOTE_STAGE } from "constants";

export const QuoteTableColumns = [
  {
    title: 'Quote Number',
    dataIndex: 'quote_no',
    id: 'quote_no',
    key: 'quoteno',
    render: (text) => text || '\u2014',
  },
  // {
  //   title: "Date of Issue",
  //   dataIndex: "doi",
  //   id: "doi",
  //   key: "doi",
  //   render: (text) => text || "\u2014",
  // },
  {
    title: 'Insured Name',
    dataIndex: 'customer',
    id: 'insured_name',
    key: 'insured_name',
    render: (text) => text?.customer_name || '\u2014',
  },
  {
    title: 'Product',
    dataIndex: 'product',
    id: 'product',
    key: 'product',
    render: (data) => data?.map((item) => item?.name) || '\u2014',
  },
  {
    title: 'Premium Payment',
    dataIndex: 'pre_payment',
    id: 'pre_payment',
    key: 'pre_payment',
    // render: (status) => QUOTE_STAGE.filter((ac) => ac?.value === status)[0]?.["label"],
  },
  // {
  //   title: "Quote Stage",
  //   dataIndex: "stage",
  //   id: "stage",
  //   key: "stage",
  //   render: (status) => QUOTE_STAGE.filter((ac) => ac?.value === status)[0]?.["label"],
  // },
];

export const policyTableColumns = [
  {
    title: 'Insured Name',
    dataIndex: 'insured_name',
    key: 'insured_name',
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact_name',
    key: 'contact_name',
  },
  {
    title: 'Business Source',
    dataIndex: 'biz_source',
    key: 'biz_source',
  },
  {
    title: 'Bill to',
    dataIndex: 'bill_to',
    key: 'bill_to',
  },
  {
    title: 'Business Nature',
    dataIndex: 'business_nature',
    key: 'business_nature',
  },
  {
    title: 'Policy Number',
    dataIndex: 'policy_number',
    key: 'policy_number',
  },
  {
    title: 'New or Renewal',
    dataIndex: 'new_or_renewal',
    key: 'new_or_renewal',
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: 'Issue Date',
    dataIndex: 'Commence Date',
    key: 'Commence Date',
  },
  {
    title: 'Commence Date',
    dataIndex: 'actual_expire_date',
    key: 'actual_expire_date',
  },
  // {
  //   title: "Insurance Category",
  //   dataIndex: "insurance_category",
  //   key: "insurance_category",
  // },
  // {
  //   title: "Category Code",
  //   dataIndex: "Category Code",
  //   key: "Category Code",
  // },
  {
    title: 'Quotation ID',
    dataIndex: 'quotation_id',
    key: 'quotation_id',
  },
  {
    title: 'Assigned To',
    dataIndex: 'assigned_to',
    key: 'assigned_to',
  },
  {
    title: 'Premium Currency',
    dataIndex: 'premium_currency',
    key: 'premium_currency',
  },
  {
    title: 'Insured Billing Address 1',
    dataIndex: 'insured_billing_address_1',
    key: 'insured_billing_address_1',
  },
  {
    title: 'Insured Billing Address 2',
    dataIndex: 'insured_billing_address_2',
    key: 'insured_billing_address_2',
  },
  {
    title: 'Insured Billing Address 3',
    dataIndex: 'insured_billing_address_3',
    key: 'insured_billing_address_3',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Branch Details',
    dataIndex: 'branch_details',
    key: 'branch_details',
  },
  {
    title: 'Terms Condition',
    dataIndex: 'terms_condition',
    key: 'terms_condition',
  },
];
