export const breadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Client',
    path: '/client',
  },
  {
    name: 'View Client Details',
    path: '',
  },
];

/**
 * @param {{
 * customer_name:string;
 * assign_to_employee: string;
 * customer_type : string;
 * bank_account_holder_name: string;
 * bank_account_number: string;
 * bank_account_type: string;
 * bank_ifsc_code: string;
 * billing_address_1: string;
 * billing_address_2: string;
 * billing_address_3: string;
 * billing_postcode: string;
 * registered_address_1: string;
 * registered_address_2: string;
 * registered_address_3: string;
 * registered_postcode: string;
 * biz_source: string;
 * branch_of: string;
 * client_name: string;
 * client_type: string;
 * contact_number: string;
 * country: string;
 * credit_amount: string;
 * credit_rating: string;
 * credit_term: string;
 * date_of_birth: string;
 * description: string;
 * email_address: string;
 * fax_number: string;
 * gst_registered: string;
 * id: string
 * local_status: string;
 * nature_of_business: string;
 * }} data
 * @returns
 */
export const formatViewData = (data) => {
  const res = {
    'Personal Details': [],
    'Address Details': [],
    'Registered Details': [],
    'Bank Details': [],
    'Control Listing': [],
    Description: [],
  };
  if (!data) return res;

  Object.entries(data).forEach(([key, val]) => {
    switch (key) {
      case 'customer_code':
        res['Personal Details'].push({
          label: key,
          value: val,
          name: 'Client Code',
        });
        break;
      case 'lead_source':
      case 'client_type':
      case 'client_name':
      case 'gst_registered':
      case 'fax_number':
      case 'biz_source':
      case 'assign_to_employee':
      case 'branch_of':
        res['Personal Details'].push({
          label: key,
          value: val,
          name: key.replaceAll('_', ' '),
        });
        break;
      case 'date_of_birth':
        res['Personal Details'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
        break;
      case 'customer_name':
      case 'customer_type':
        res['Personal Details'].push({
          label: key,
          value: val,
          name: key.replaceAll('customer_', 'Client '),
        });
        break;
      case 'contact_number':
        res['Personal Details'].push({
          label: key,
          value: val,
          name: key.replaceAll('_', ' '),
          phone: true,
        });
        break;
      case 'email_address':
        res['Personal Details'].push({
          label: key,
          value: val,
          name: key.replaceAll('_', ' '),
          email: true,
        });
        break;
      case 'nature_of_business':
        res['Personal Details'].push({
          label: key,
          value: val,
          name: key.replaceAll('_', ' '),
          className: 'col-span-full',
        });
        break;
      case 'address':
      case 'city':
      case 'state':
      case 'country':
      case 'postcode':
        res['Address Details'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
        break;
      case 'r_address':
      case 'r_city':
      case 'r_state':
      case 'r_country':
      case 'r_postcode':
        res['Registered Details'].push({ label: key, value: val, name: key.replaceAll('r_', ' ') });
        break;
      case 'bank_ifsc_code':
      case 'bank_account_number':
      case 'bank_account_holder_name':
      case 'bank_account_type':
        res['Bank Details'].push({
          label: key,
          value: val,
          name: key.replaceAll('bank_', 'Bank ').replaceAll('_', ' '),
        });
        break;
      case 'credit_rating':
      case 'credit_amount':
      case 'credit_term':
        res['Control Listing'].push({
          label: key,
          value: val,
          name: key?.replaceAll('credit_', 'Credit '),
        });
        break;
      case 'description':
        res['Description'].push({ label: key, value: val, name: key, className: 'col-span-full' });
        break;
      default:
        break;
    }
  });
  return res;
};

export const formatOverViewData = (data) => {
  const res = {
    _: [],
  };
  if (!data) return res;

  Object.entries(data).forEach(([key, val]) => {
    switch (key) {
      case 'client_name':
        res._.push({
          label: key,
          value: val,
          name: 'Name',
        });
        break;
      case 'date_of_birth':
      case 'lead_source':
        res._.push({ label: key, value: val, name: key.replaceAll('_', ' ') });
        break;
      case 'customer_name':
      case 'customer_type':
        res._.push({ label: key, value: val, name: key.replaceAll('customer_', 'Client ') });
        break;
      case 'email_address':
        res._.push({ label: key, value: val, name: key.replaceAll('_', ' '), email: true });
        break;
      case 'contact_number':
        res._.push({ label: key, value: val, name: key.replaceAll('_', ' '), phone: true });
        break;
      default:
        break;
    }
  });
  return res;
};
