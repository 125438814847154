import Request from 'service/api-service/client';

export const getInquiryCount = async () => {
  return await Request({
    method: 'get',
    API: `inquiry/count`,
  });
};

export const leadTabApi = async (props = { status: '', employee_id: '' }) => {
  const resp = await Request({
    method: 'get',
    API: `inquiry/?employee_id=${props.employee_id || ''}&status=${props.status}`,
  });
  return resp;
};

export const getRejectedLead = async ({ date = '', employee_id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: `inquiry/remarks?date=${date || ''}&employee_id=${employee_id || ''}`,
  });
  return resp;
};

// getLeadDiscussion

export const getLead = async (props = { lead_id: '' }) => {
  const resp = await Request({
    method: 'get',
    API: `inquiry/comment?id=${props?.lead_id}`,
  });
  return resp;
};

export const postLead = async (props = { data: '', lead_id: '' }) => {
  const resp = await Request({
    method: 'post',
    API: `inquiry/comment?id=${props?.lead_id}`,
    data: props?.data,
  });
  return resp;
};

export const postUploadDocument = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: 'inquiry/quote/doc',
    data,
  });
  return resp;
};

export const deleteUploadDocument = async ({ id = '' }) => {
  const resp = await Request({
    method: 'delete',
    API: `inquiry/quote/doc?id=${id}`,
  });
  return resp;
};
