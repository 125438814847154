/** external deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Space } from 'antd';
import Fuse from 'fuse.js';
/** internal deps */
import { DELETE, EDIT, Trash } from 'assets/icons';
import {
  TableComponent,
  IconButton,
  CustomModal,
  Toast,
  Button,
  FormContainer,
  ContentHeader,
} from 'components';
import { SecureComponent } from 'permission-provider';
import {
  addInsurerContact,
  deleteInsurerContact,
  editInsurerContact,
  getInsurerContact,
} from 'service/api-service';
import { elementData } from 'elements';
import { addContactFields, columns } from './data';
import { NOTIFICATION_MSG } from 'constants';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { rowCommonClass } from 'utils/common';

const InsurerContacts = (props = {}) => {
  const { id: insurer_id } = useParams();
  const permissions = props?.permissions || [];

  /* states */
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [contacts, setContacts] = useState([]);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editInitials, setEditInitials] = useState();
  const [initialValues, formFields] = prepareFormFieldsData({ fields: addContactFields });
  const [searchQuery, setSearchQuery] = useState();
  const [filteredData, setFilteredData] = useState([]);

  /** funcs */
  const toggleModal = (isAdding = true) => {
    if (isAdding) setEditInitials();
    setShowModal((pre) => !pre);
  };

  const onEditClick = (record) => {
    setEditInitials(record);
    toggleModal(false);
  };

  const toggleDeleteModal = (record) => {
    setSelectedRow(record);
    setDeleteModal((pre) => !pre);
  };

  const onGetContacts = async () => {
    setLoading(true);
    const resp = await getInsurerContact({ insurer_id });
    if (resp?.data?.status) {
      const list = resp.data.data;
      setContacts(list);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to fetch contacts from server');
    }
    setLoading(false);
  };

  const onDeleteContact = async () => {
    const contact_id = selectedRow?.id;
    if (!contact_id) return;
    setLoading(true);
    const resp = await deleteInsurerContact({ id: contact_id, data: { deleted: true } });
    if (resp?.data?.status) {
      onGetContacts();
      Toast('success', NOTIFICATION_MSG.success, 'Contact Deleted Successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteModal();
    setLoading(false);
  };

  const onAddContact = async (data) => {
    const finalPayload = {
      ...data,
      insurer_id,
    };
    const resp = await addInsurerContact({ data: finalPayload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      onGetContacts();
      toggleModal();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  const onEditContact = async (updatedData) => {
    const contact_id = editInitials?.id;
    const editedFields = getEditedFields({ initialValues: editInitials, updatedData });
    if (Object.keys(editedFields).length) {
      const resp = await editInsurerContact({
        data: editedFields,
        id: contact_id,
      });
      if (resp?.data?.status) {
        onGetContacts();
        toggleModal();
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.data?.msg || 'Contact updated successfully',
        );
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
    } else {
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) {
      return setSearchQuery('');
    }
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(contacts, {
      keys: ['name', 'email', 'mobile', 'id_card'],
      threshold: 0.2,
    }).search(e.target.value);
    setFilteredData(fuse.map((i) => i.item));
  };

  useEffect(() => {
    onGetContacts();
  }, []);

  return (
    <main>
      <div className='grid gap-3'>
        <ContentHeader
          title={<h2 className='font-semibold text-l'>Contacts</h2>}
          showSearch={true}
          id={elementData.insurer.permissions.create}
          permissions={permissions}
          btnText='+ Contact'
          onClick={toggleModal}
          onChange={handleOnChange}
        />
        <TableComponent
          loading={loading}
          getRowClassName={rowCommonClass}
          noDataAction={onGetContacts}
          columns={[
            ...columns,
            {
              title: 'Action',
              key: 'action',
              align: 'right',
              render: (_, record) => (
                <Space size='middle'>
                  <IconButton
                    permissions={permissions}
                    id={elementData.insurer.permissions.delete}
                    title='Delete'
                    className='group disabled:cursor-not-allowed'
                    onClick={() => toggleDeleteModal(record)}
                  >
                    <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                  </IconButton>
                  <IconButton
                    permissions={permissions}
                    id={elementData.insurer.permissions.update}
                    title='Edit'
                    className='group disabled:cursor-not-allowed'
                    onClick={() => onEditClick(record)}
                  >
                    <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                  </IconButton>
                </Space>
              ),
            },
          ]}
          dataSource={searchQuery ? filteredData : contacts}
          total={contacts.length}
          pageSize={10}
        />
      </div>

      {/* Delete contact modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={
          <div className=' flex flex-row justify-center  bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              permissions={permissions}
              id={elementData.insurer.permissions.delete}
              text='Delete Contact'
              variant='primary'
              classes={`ml-6 px-8   
               bg-black
              `}
              onClick={onDeleteContact}
              loading={loading}
            />
          </div>
        }
        width={450}
      >
        <div className='flex justify-center flex-col items-center gap-4'>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-xs text-grey-90 pb-3'>
            Could you please confirm that you really wish to delete the Contact details?
          </span>
        </div>
      </CustomModal>

      {/* Add/Edit Contact Modal */}
      <CustomModal
        title={editInitials ? 'Edit Contact' : 'Create Contact'}
        destroyOnClose={true}
        open={showModal}
        onCancel={toggleModal}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm 2xl:pb-10 '>
            {editInitials
              ? 'To edit the contact, enter the details in the input field below.'
              : ' To create a contact, enter the details in the input field below.'}
          </span>

          <FormContainer
            onSubmit={editInitials ? onEditContact : onAddContact}
            initialValues={editInitials || initialValues}
            fields={formFields}
            accordionClass='grid grid-cols-2  gap-8'
            customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleModal();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.insurer.permissions.update
                        : elementData.insurer.permissions.create
                    }
                    text={editInitials ? 'Edit Contact' : 'Create Contact'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
    </main>
  );
};

export default SecureComponent(InsurerContacts);
