import { Send } from 'assets/icons';
import { TextInput } from 'components';
import React, { useState } from 'react';
import { commentManagement } from 'service/api-service';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';

const Comments = ({ task_id, getList }) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  const onSendClick = async (data) => {
    if (loading || !comment) return;
    setLoading(true);
    const payload = {
      comment: comment,
    };
    const resp = await commentManagement({ method: 'post', data: payload, task_id });
    if (resp?.data?.status) {
      setComment('');
      getList();
    } else {
      message.error(resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const getSendIcon = (value) => {
    return (
      <Button
        type='text'
        className='flex justify-end items-end'
        disabled={!comment}
        icon={<Send svgClass={` ${comment ? 'fill-primary' : 'fill-grey-90'} w-5 h-5`} />}
      />
    );
  };

  const onChange = (e) => setComment(e.target.value);

  return (
    <div className='col-span-full'>
      <div className='col-span-full'>
        <TextInput
          field={{ name: 'comment', value: comment, onChange }}
          label='Comments'
          placeholder='Enter Your Comment'
          rightIcon={
            loading ? <LoadingOutlined className='text-primary' spin={true} /> : getSendIcon
          }
          onIconClick={onSendClick}
          required={true}
        />
      </div>
    </div>
  );
};

export default Comments;
