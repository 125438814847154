import { TextInput } from 'components';

const addRoleFields = {
  add_role: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Role Name',
        name: 'name',
        id: 'name',
        placeholder: 'Role Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
    ],
  },
};

const addRoleInitials = {
  name: '',
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Master',
    // path: "/",
  },
  {
    name: 'Roles',
    path: '/roles',
  },
];

export { addRoleFields, addRoleInitials, breadcrumbObj };
