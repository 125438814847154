/** external deps */
import React, { useState, useEffect } from 'react';
import { Space } from 'antd';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
  Toast,
} from 'components';
import { addNonPerformingEmployeesFields, breadcrumbObj } from './data';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { prepareFormFieldsData, getEditedFields } from 'utils';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import {
  deleteNonPerformingEmployee,
  getNonPerformingEmployee,
  patchNonPerformingEmployee,
  postNonPerformingEmployee,
} from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import Fuse from 'fuse.js';
import { rowCommonClass } from 'utils/common';

const NonPerformingEmployees = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [employeesData, setEmployeesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredEmployees, setfilteredEmployees] = useState([]);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addNonPerformingEmployeesFields,
  });

  const columns = [
    {
      key: 'emp_id',
      title: 'Employee',
      dataIndex: 'emp_id',
    },
    {
      key: 'reason',
      title: 'Reason',
      dataIndex: 'reason',
      className: 'truncate',
    },
    {
      key: 'note',
      title: 'Note',
      dataIndex: 'note',
      className: 'truncate',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.non_performing_employees.permissions.delete}
            onClick={() => onDeletePress(record)}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.non_performing_employees.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  /* funcs */
  const onAddEmployee = async (data) => {
    setLoading(true);
    const resp = await postNonPerformingEmployee({
      data,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      getNonPerformingEmployees();
      toggleAddEmployee();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onDeleteEmployee = async () => {
    setLoading(true);
    const id = editInitials?.id;
    if (!id) return;
    const resp = await deleteNonPerformingEmployee({ id });
    if (resp?.data?.status) {
      getNonPerformingEmployees();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleDeleteEmployee();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onEditEmployee = async (data) => {
    setLoading(true);
    const editedFields = getEditedFields({
      initialValues: editInitials,
      updatedData: data,
    });
    if (Object.keys(editedFields).length) {
      const resp = await patchNonPerformingEmployee({
        data: editedFields,
        id: editInitials?.id,
      });
      if (resp?.data?.status) {
        getNonPerformingEmployees();
        toggleAddEmployee();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to update data');
      }
      setLoading(false);
    } else {
      setLoading(false);
      return Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  const toggleAddEmployee = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  const toggleDeleteEmployee = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    setSearchQuery(e.target.value);
    const fuse = new Fuse(employeesData, {
      keys: ['emp_id', 'note', 'reason'],
      threshold: 0.2,
    }).search(e.target.value);
    setfilteredEmployees(fuse.map((i) => i.item));
  };

  const onEditPress = (record) => {
    setInitials(record);
    setAddModal(true);
  };

  const onDeletePress = (record) => {
    setInitials(record);
    toggleDeleteEmployee();
  };

  const getNonPerformingEmployees = async () => {
    setLoading(true);
    const resp = await getNonPerformingEmployee();
    if (resp?.data?.status) {
      const employees = resp?.data?.data.map((emp) => {
        return {
          ...emp,
          emp_id: emp?.emp_id?.name,
        };
      });
      setEmployeesData(employees);
    }
    setLoading(false);
  };

  useEffect(() => {
    getNonPerformingEmployees();
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        data={breadcrumbObj}
        btnText='+ Employee'
        buttonId={elementData?.non_performing_employees?.permissions?.create}
        onClick={toggleAddEmployee}
      />
      <TableComponent
        loading={loading}
        columns={columns}
        dataSource={searchQuery ? filteredEmployees : employeesData}
        getRowClassName={rowCommonClass}
      />

      {/* add Employees modal */}
      <CustomModal
        destroyOnClose={true}
        width={450}
        open={showAddModal}
        onCancel={toggleAddEmployee}
        footer={''}
        title={editInitials ? "Edit Employee's performance" : "Add Employee's performance"}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 text-grey-80'>
            {editInitials
              ? "Edit Employee's performance details"
              : "To add a employee's performance, enter the details in the input field below."}
          </span>

          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditEmployee : onAddEmployee}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddEmployee();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.non_performing_employees.permissions.update
                        : elementData.non_performing_employees.permissions.create
                    }
                    text={editInitials ? 'Update' : 'Add'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* delete Employees confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteEmployee}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the employee's performance?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteEmployee}
            />
            <Button
              text='Delete'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteEmployee}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(NonPerformingEmployees);
