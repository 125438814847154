import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space } from 'antd';

import { ContentHeader, IconButton, TableComponent } from 'components';
import { breadcrumbObj } from './data';
import { EDIT, View } from 'assets/icons';
import { CompanyPolicyManagement } from 'service/api-service';
import { elementData } from 'elements';
import { rowCommonClass } from 'utils/common';

const columns = [
  {
    key: 'label',
    title: 'Name',
    dataIndex: 'title',
  },
  {
    key: 'des',
    title: 'Policies',
    dataIndex: 'des',
    render: (value) => (
      <div
        className='line-clamp-2 h-10 overflow-hidden'
        dangerouslySetInnerHTML={{ __html: value }}
      ></div>
    ),
  },
];

// const dataSource = [];

const CompanyPolicies = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  // const [showDeleteModal, setShowDeleteModal] = useState(false);

  // const selecteRef = useRef();

  const navigate = useNavigate();

  const fetchPolicy = async () => {
    setLoading(true);
    try {
      const res = await CompanyPolicyManagement({ method: 'get' });
      if (res?.data?.status) setDataSource(res?.data?.data);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  // const deletePolicy = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await CompanyPolicyManagement({ method: 'delete', id: selecteRef?.current?.id });
  //     if (res?.data?.status) setDataSource(res?.data?.data);
  //     fetchPolicy()
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    fetchPolicy();
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        // data={breadcrumbObj}
        title={<p className='font-semibold text-s'>Company Policies</p>}
        btnText='+ Company Policies'
        showSearch={false}
        buttonId={elementData?.company_policy?.permissions?.create}
        onClick={() => navigate('/hr/company-policies-add')}
      />
      <TableComponent
        getRowClassName={rowCommonClass}
        loading={loading}
        columns={[
          ...columns,
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
              <Space size='middle'>
                <IconButton
                  title='View'
                  // id={elementData.company_policy.permissions.view}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    navigate(`/hr/company-policies-view/${record.id}`);
                  }}
                >
                  <View svgClass='stroke-primary group-disabled:stroke-grey' />
                </IconButton>
                {/* <IconButton
                  title='Delete'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  //   id={elementData.leaves_type.permissions.delete}
                  onClick={() => {
                    selecteRef.current = record;
                    setShowDeleteModal(true);
                  }}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton> */}
                <IconButton
                  title='Edit'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.company_policy.permissions.update}
                  onClick={() => navigate(`/hr/company-policies-edit/${record.id}`)}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
              </Space>
            ),
          },
        ]}
        dataSource={dataSource}
      />
      {/* delete class confirm modal */}
      {/* <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={() => setShowDeleteModal(false)}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the company policy?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={() => setShowDeleteModal(false)}
            />
            <Button
              text='Delete Policy'
              variant='primary'
              classes={`ml-6 px-8 bg-black`}
              onClick={() => deletePolicy()}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal> */}
    </div>
  );
};

export default CompanyPolicies;
