/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
/** internal deps */
import { SecureComponent } from 'permission-provider';
import { ContentHeader, Toast, MarkDown } from 'components';
import { breadCrumbsObj } from './data';
import { addTermsCondition, editTermsCondition, getTermsCondition } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';

const TermsCondition = (props = {}) => {
  const permissions = props?.permissions || {};
  /** state */
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [editInitial, setEditInitial] = useState();
  const [terms, setTerms] = useState();

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await getTermsCondition();
    if (resp?.data?.status) {
      const data = resp?.data?.data[0];
      setData(data);
      const initVals = {
        terms: data?.terms,
      };
      setEditInitial(initVals);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddTerms = useCallback(async () => {
    const id = data?.id;
    const payloadObj = {
      id: data?.id,
      terms: terms,
    };
    setData(payloadObj);

    const resp = id
      ? await editTermsCondition({ data: payloadObj, id })
      : await addTermsCondition({ data: payloadObj });
    if (resp?.data?.status) {
      Toast(
        'success',
        NOTIFICATION_MSG.success,
        `Terms & Condition ${data ? 'updated' : 'created'} successfully`,
      );
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  }, [terms, data]);

  const editTerms = useCallback((field, value) => {
    setTerms(value);
  }, []);

  if (loading) {
    return (
      <div className='flex items-center justify-center h-64'>
        <Spin />
      </div>
    );
  }

  return (
    <div className='grid gap-3'>
      <ContentHeader
        // data={breadCrumbsObj}
        title={<p className='font-semibold text-s'>Terms & Condition</p>}
        showSearch={false}
        btnText={'Save'}
        onClick={handleAddTerms}
        permissions={permissions}
        id={
          editInitial
            ? elementData.termsCondition.permissions.update
            : elementData.termsCondition.permissions.create
        }
      />
      <div className='bg-white' id='terms-condition'>
        <MarkDown
          name='terms'
          id='terms'
          field={{ name: 'terms' }}
          placeholder='Enter Terms & Conditions'
          type='textarea'
          isMultiline={true}
          divClasses='col-span-full'
          rows={6}
          defaultValue={editInitial?.terms}
          customOnChange={editTerms}
        />
      </div>
    </div>
  );
};

export default SecureComponent(TermsCondition);
