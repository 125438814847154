import dayjs from 'dayjs';

export const showDates = (data) => {
  const s = dayjs(data?.from_date);
  const e = dayjs(data?.to_date);

  if (s.isSame(e)) return <span className='text-primary'>{s.format('Do MMM YYYY')}</span>;
  return (
    <span className='text-primary'>
      {s.format('Do MMM YYYY')} <span className='text-black'>to</span> {e.format('Do MMM YYYY')}
    </span>
  );
};

export const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Leaves Requests',
    path: '',
  },
];

export const leavesTableColumns = [
  {
    key: 'from_date',
    title: 'Dates',
    render: showDates,
  },
  {
    key: 'lt_id',
    title: 'Leave Type',
    dataIndex: 'leave_type',
    render: (val) => val?.label,
  },
  {
    key: 'days',
    title: 'No. of Days',
    dataIndex: 'days',
    render: (days) => days,
  },
  {
    key: 'w_handovr',
    title: 'Work Handover Employee',
    dataIndex: 'w_handovr',
    render: (val) =>
      val?.name || <span className='text-x text-primary-90 font-normal leading-normal'>NA</span>,
  },
];

export const REFRESH_EVT = 'REFRESH_EVT';
