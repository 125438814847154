/** external deps */
import { PrinterOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import React, { useState, useRef, useEffect, useContext } from 'react';
/** internal deps */
import { Context } from 'store/context';
import MultiEmail from 'components/multiEmail/MultipleEmail';

const GST_VALUE = 18;

const Num = (val) => (isNaN(val) ? 0 : val || 0);

const getSubTotal = (summary) => {
  if (summary && summary.length > 1) {
    return summary.reduce((acc, item) => acc + Num(item?.quantity) * Num(item?.amount), 0);
  }
  if (summary && summary.length === 1) return Num(summary[0]?.quantity) * Num(summary[0]?.amount);
  return 0;
};
const getGST = (subtotal) => (subtotal / 100) * GST_VALUE;

const InvoiceData = ({ record, idData, emailData }) => {
  const { company } = useContext(Context);
  const currentRef = useRef();
  const [showButtons, setShowButtons] = useState(true);
  // const date = record?.date;
  const due = record?.dueDate;
  const title = record?.title;
  const { items: summary } = record;
  const email = record?.sent_to?.email || emailData;
  const subtotal = getSubTotal(summary) || 0;
  const gst = getGST(subtotal);
  const total = subtotal + gst;
  const id = record?.id;
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    if (pathname === '/invoices/create') {
      setShowButtons(false);
    } else {
      setShowButtons(true);
    }
  }, []);

  const printInvoice = useReactToPrint({
    content: () => currentRef.current,
  });

  return (
    <>
      <div ref={currentRef} className='bg-white p-4 border border-grey-70 rounded-xl'>
        <div className='flex justify-between mb-6 noPrint'>
          {/* <MobileInsuranceLogo svgClass="w-12" /> */}
          <div className='text-right text-3xl font-semibold'>Invoice</div>
          {showButtons ? (
            <div className='flex space-x-1 items-center'>
              <Button className='border border-primary-90 px-2 ' onClick={printInvoice}>
                <PrinterOutlined className='text-primary text-s' />
              </Button>
              <Button className='border border-primary-90 px-2 '>
                <WhatsAppOutlined className='text-green text-s' />
              </Button>
              <MultiEmail imageRef='invoice-slip' emailData={email} type='invoice' />
            </div>
          ) : null}
        </div>
        <div id='invoice-slip'>
          <div className='flex items-center justify-between'>
            <div className='w-1/2'>
              <div className='font-semibold text-primary-90  text-xs'>Invoice To :</div>
              <div className='text-xs'>{title}</div>
              {/* below email */}
              <div className='text-xs'>{email}</div>
            </div>
            <div className='w-1/2 text-right'>
              {id ? <div className='text-xs'>Invoice No: {id || idData}</div> : null}
              <div className='text-xs font-semibold text-primary-90 mb-[60px]'>Due Date: {due}</div>
            </div>
          </div>

          {/* <Divider /> */}
          <div className='flex w-full font-medium devide-x-0 text-white text-xs bg-primary-90 py-2 mt-7 rounded-t-lg  px-2'>
            <div className='flex-1'>Item</div>
            <div className='w-24 text-right'>Qty.</div>
            <div className='w-24 text-right'>Unit Price</div>
            <div className='w-24 text-right'>Total</div>
          </div>
          {/* <Divider /> */}
          <div className='border-2 border-t-0 border-grey-light rounded-b-lg'>
            {summary?.map((item, index) => (
              <div key={index} className='flex w-full text-xs mt-2 border-b-white  px-2'>
                <div className='flex-1'>{item?.name}</div>
                <div className='w-24 text-right'>{item?.quantity || 0}</div>
                <div className='w-24 text-right'>{item?.amount || 0}</div>
                <div className='w-24 text-right'>{(item?.amount || 0) * (item?.quantity || 0)}</div>
              </div>
            ))}
          </div>

          {/* <Divider /> */}
          <div className='bg-grey-light mt-4'>
            <div className='flex justify-between w-full text-xs py-2 px-2 rounded-t-lg border-b-white'>
              {/* <div className="flex-1"></div>
          <div className="w-24 text-right"></div> */}
              {/* <div className="flex justify-between"> */}
              <div className='text-right '>Subtotal</div>
              <div className='text-right'>{subtotal}</div>
              {/* </div> */}
            </div>
            <div className='flex justify-between w-full text-xs px-2 py-2 '>
              {/* <div className="flex-1"></div>
          <div className="w-24 text-right"></div> */}
              {/* <div className="w-24 text-right font-semibold">GST({GST_VALUE}%)</div> */}
              <div className='text-right '>
                Total GST
                {/* (18%) */}
              </div>
              <div className='text-right'>
                18%
                {/* {gst.toFixed()} */}
              </div>
            </div>
            <div className='flex justify-between w-full text-xs rounded-b-lg py-2 px-2'>
              {/* <div className="flex-1"></div>
          <div className="w-24 text-right"></div> */}
              <div className='text-right font-semibold'>Total Price</div>
              <div className='text-right text-red-90 font-bold'>{total.toFixed()}</div>
            </div>
          </div>
          {/* <Divider /> */}
          <div className='mt-10'>Thankyou</div>
          <div className='flex justify-between mt-10 items-end'>
            <div className='w-1/2'>
              <div className='font-semibold text-xs mb-2 text-primary-90'>
                Payment Information :
              </div>
              <div className='text-xs'>Bank: {company?.bank_name}</div>
              <div className='text-xs'>Account Name: {company?.acc_name}</div>
              <div className='text-xs'>Account No: {company?.acc_number}</div>
              <div className='text-xs'>IFSC Code: {company?.ifsc_code}</div>
              <div className='text-xs'>GST Number: {company?.gst}</div>
            </div>
            <div className='w-1/2 text-right'>
              <div className='text-xs'>{company?.name}</div>
              <div className='text-xs'>{company?.address}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceData;
