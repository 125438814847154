import React, { useState } from 'react';
import Sidebar from './sidebar';
import PasswordForm from './passwordform';
import jwt_Decode from 'jwt-decode';
import { SecureComponent } from 'permission-provider';
import { Navigate, useNavigate } from 'react-router-dom';

const PosSetting = () => {
  const [form, setForm] = useState('PasswordForm');
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('AUTH'));
  const decode = jwt_Decode(token?.access_token);

  if (!token || decode?.pos_agent === false) {
    navigate('/pos-login');
  }
  if (!token) return <Navigate to='/pos-login' />;

  return (
    <>
      <div className='flex justify-between bg-grey-light'>
        <Sidebar setForm={setForm} form={form} />
        <PasswordForm />
      </div>
    </>
  );
};

export default SecureComponent(PosSetting);
