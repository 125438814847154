import { Select } from 'antd';
import { DropdownIcon } from 'assets/icons';
import { MultiUserInitial, Toast } from 'components';
import { NOTIFICATION_MSG, ACTIVITY_STATUS } from 'constants';
import { meetingManagement, taskManagement } from 'service/api-service';
import { formatDate } from 'utils';

export const activityTypes = {
  MEETING: 'Meeting',
  TASK: 'Task',
};

export const onselect = async (status, record) => {
  const resp = await (record?.activity === activityTypes.MEETING
    ? meetingManagement({ method: 'patch', id: record?.id, data: { status } })
    : taskManagement({ method: 'patch', id: record?.id, data: { status } }));
  if (resp?.data?.status) {
    Toast(
      'success',
      NOTIFICATION_MSG.success,
      `${record?.activity === activityTypes.TASK ? 'Task' : 'Meeting'} updated successfully`,
    );
  } else {
    Toast('error', NOTIFICATION_MSG.error, 'Please try again');
  }
};

export const taskColumns = [
  {
    key: 'subject',
    title: 'Subject',
    dataIndex: 'subject',
    render: (text) => text || '-',
  },
  {
    key: 'from_date',
    title: 'From',
    dataIndex: 'from_date',
    render: (dateString) => {
      return formatDate({ dateString, toUtc: false, format: 'DD MMMM,YYYY, HH:mm' });
    },
  },
  {
    key: 'to_date',
    title: 'To',
    dataIndex: 'to_date',
    render: (dateString) => {
      return formatDate({ dateString, toUtc: false, format: 'DD MMMM,YYYY, HH:mm' });
    },
  },
  {
    key: 'assign_to_employee',
    title: 'Assigned To',
    dataIndex: 'assign_to_employee',
    render: (emp) => (
      <div className='truncate'>
        {' '}
        <MultiUserInitial data={emp} value='name' label='employee' showImage={true} />
      </div>
    ),
  },
  {
    key: 'created_by',
    title: 'Assigned By',
    dataIndex: 'created_by',
    render: (emp) => emp?.name || '\u2014',
  },
  //   {
  //     key: 'contact_id',
  //     title: 'Contact Name',
  //     dataIndex: 'contact_id',
  //     render: (text) => text || '\u2014',
  //   },
  {
    key: 'customer_id',
    title: 'Client Name',
    dataIndex: 'customer_id',
    render: (client) => client?.customer_name || '\u2014',
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (_, record) => {
      return (
        <Select
          defaultValue={() => ACTIVITY_STATUS.find((i) => i.value === record?.status)}
          className='uppercase w-max !border-0 '
          popupClassName=''
          options={ACTIVITY_STATUS}
          suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
          onSelect={(status) => onselect(status, record)}
          getPopupContainer={(trigger) => trigger.parentNode}
        />
      );
    },
  },
];

export const REFRESH_EVT = 'REFRESH_TASK';
