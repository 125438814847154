import { FormContainer, SelectInput, TextInput } from 'components';
import { countries } from 'data/form-data';
import React from 'react';
import { prepareFormFieldsData } from 'utils';
import { formStyle } from 'utils/common';

var doSubmit = null;

const HealthForm = ({ formData, onSubmit }) => {
  const quoteCustomerFields = {
    'Health Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'You would like to insure',
          name: 'like_to_insure',
          id: 'like_to_insure',
          type: 'select',
          component: SelectInput,
          options: [
            {
              label: 'Self',
              value: 'self',
            },
            {
              label: 'Spouse',
              value: 'spouse',
            },
            {
              label: 'Son',
              value: 'son',
            },
            {
              label: 'Daughter',
              value: 'daughter',
            },
            {
              label: 'Father',
              value: 'father',
            },
            {
              label: 'Mother',
              value: 'mother',
            },
            // {
            //   label: "Grandfather",
            //   value: "grandfather",
            // },
            // {
            //   label: "Grandmother",
            //   value: "grandmother",
            // },
          ],
          checkbox: true,
          isMulti: true,
          hideSelectedOptions: false,
          closeMenuOnSelect: false,
          // divClasses: "col-span-3",
        },
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'self',
          label: 'What is your age?',
          name: 'age',
          id: 'age',
          placeholder: 'Enter Age',
          type: 'text',
          component: TextInput,
        },
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'spouse',
          label: 'What is your spouse age?',
          name: 'spouse_age',
          id: 'spouse_age',
          placeholder: 'Enter Age',
          type: 'text',
          component: TextInput,
        },
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'son',
          label: 'What is your son age?',
          name: 'son_age',
          id: 'son_age',
          placeholder: 'Enter Age',
          type: 'text',
          component: TextInput,
        },
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'daughter',
          label: 'What is your daughter age?',
          name: 'daughter_age',
          id: 'daughter_age',
          placeholder: 'Enter Age',
          type: 'text',
          component: TextInput,
        },
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'mother',
          label: 'What is your mother age?',
          name: 'mother_age',
          id: 'mother_age',
          placeholder: 'Enter Age',
          type: 'text',
          component: TextInput,
        },
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'father',
          label: 'What is your father age?',
          name: 'father_age',
          id: 'father_age',
          placeholder: 'Enter Age',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Do you have any illness or medical history?',
          name: 'illness',
          id: 'illness',
          placeholder: 'Existing illness or medical history',
          type: 'select',
          component: SelectInput,
          options: [
            {
              label: 'Existing illness',
              value: 'existing_illness',
            },
            {
              label: 'Surgical Procedure',
              value: 'surgical_procedure',
            },
            {
              label: 'None of these',
              value: 'none_of_these',
            },
          ],
        },
      ],
    },
    Address: {
      fields: [
        {
          label: 'Country',
          name: 'country',
          id: 'country',
          placeholder: 'Select Country',
          type: 'select',
          // required: true,
          component: SelectInput,
          options: countries,
        },
        {
          label: 'State',
          name: 'state',
          id: 'state',
          placeholder: 'State',
          type: 'text',
          // required: true,
          component: TextInput,
          maxLength: 30,
        },
        {
          label: 'City/Town',
          name: 'city',
          id: 'city',
          placeholder: 'City/Town',
          type: 'text',
          // required: true,
          component: TextInput,
          maxLength: 30,
        },
        {
          label: 'Pincode',
          name: 'pincode',
          id: 'pincode',
          placeholder: 'Pincode',
          type: 'text',
          // required: true,
          component: TextInput,
          maxLength: 10,
        },
        {
          label: 'Address',
          name: 'address',
          id: 'address',
          placeholder: 'Address',
          type: 'text',
          // required: true,
          component: TextInput,
          isMultiline: true,
          maxLength: 60,
          divClasses: 'md:col-span-2',
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: quoteCustomerFields,
    defaultValues: formData,
  });

  return (
    <FormContainer
      returnFormatted={false}
      accordionClass={formStyle}
      fields={finalFields}
      initialValues={initialValues}
      onSubmit={onSubmit}
      customSubmitRender={({ submitForm }) => {
        doSubmit = submitForm;
        return null;
      }}
    />
  );
};

const submit = () => {
  if (doSubmit) doSubmit();
};

export default {
  submit,
  Form: HealthForm,
};
