import $ from 'jquery';
import { useRef, useEffect, useState } from 'react';
import './style.css';
import { SubmitButtons } from 'screens/leads/data';

window.jQuery = $;
window.$ = $;

require('jquery-ui-sortable');
require('formBuilder');
require('formBuilder/dist/form-render.min.js');

var form;

const CustomForm = ({
  formData,
  formValues,
  setSelectedProduct,
  setValues,
  insurer,
  isEditing,
  submitProduct,
}) => {
  const fb = useRef();
  const [wantExit, setWantExit] = useState(false);

  const onSubmit = (data) => {
    submitProduct(data, wantExit);
  };

  useEffect(() => {
    if (formValues) {
      let newForData = { ...formData };
      for (let i = 0; i < formData.fields.length; i++) {
        const field = formData.fields[i];
        const key = field.label.replace(/ /g, '_');
        if (formValues[key]) {
          newForData.fields[i].value = formValues[key];
        }
      }

      form = $('#fb-render').formRender({ dataType: 'json', formData: newForData?.fields || [] });
    } else {
      form = $('#fb-render').formRender({ dataType: 'json', formData: formData?.fields || [] });
    }
  }, []);

  return (
    <div className='bg-white'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div id='fb-render' className='w-full  ' ref={fb} />

        {formData?.count ? (
          <></>
        ) : (
          <SubmitButtons
            show={true}
            classes='z-10'
            submit={onSubmit}
            reset={() => {}}
            setWantExit={setWantExit}
          />
        )}
      </form>
    </div>
  );
};

export default {
  Form: CustomForm,
};
