export const breadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Adjuster',
    path: '/adjuster',
  },
  {
    name: 'View Adjuster',
    path: '',
    target: '_blank',
  },
];

/**
 * @param {{
 * name:string;
 * company_name: string;
 * insurer_id: string;
 * tel_no: string;
 * email: string;
 * website: string;
 * fee: string;
 * assign_employee: string;
 * description: string;
 * }} data
 * @returns
 */

export const formatViewData = (data) => {
  const res = {
    'Adjuster Information': [],
    Description: [],
  };
  if (!data) return res;
  Object.entries(data).forEach(([key, val]) => {
    switch (key) {
      case 'name':
      case 'company':
      case 'tel_no':
      case 'insurer_id':
      case 'email':
      case 'website':
      case 'fee':
      case 'assign_employee':
        res['Adjuster Information'].push({
          label: key,
          value: val,
          name: key.replace('_', ' '),
        });
        break;
      case 'description':
        res['Description'].push({
          label: key,
          value: val,
          name: key.replace('_', ' '),
          className: 'col-span-full',
        });
        break;
      default:
        break;
    }
  });
  return res;
};
