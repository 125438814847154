/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
/** internal deps */
import { ContentHeader, Filter, TabsComponent } from 'components';
import { elementData } from 'elements';
import { breadCrumbs, policyColumns } from './data';
import AllPolicies from './allPolices';
import OtherPolices from './otherPolices';
import RenewalPolicies from './renewalPolices';
import { getQueryParams } from 'utils/common';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
const MIN_FIELDS = 5;
const MAX_FIELDS = 800;

const PolicyMangement = (props = {}) => {
  const { tab } = getQueryParams();
  const { RangePicker } = DatePicker;
  const permissions = props?.permissions || {};
  let navigate = useNavigate();
  const { state } = useLocation();
  const checkStorage = JSON.parse(localStorage.getItem('policyCurrColumns'));
  const storeColumns = policyColumns.filter((obj1) =>
    checkStorage?.some((obj2) => obj1.key === obj2.key),
  );
  const [activeTabKey, setActiveTabKey] = useState(tab || state?.activetab || 'all');
  const [dateFilterValues, setDateFilterValues] = useState([]);
  const [query, setSearchQuery] = useState('');
  const [curColumns, setCurColumns] = useState(
    checkStorage ? storeColumns : policyColumns?.slice(0, 7),
  );

  const onEditPolicy = async (record) => {
    const initVals = {
      ...record,
      branch: record?.branch?.id,
      insurer: record?.insurer?.id,
      customer: record?.customer,
      contact: record?.contact?.name,
      contact_no: record?.customer?.contact_number,
      email_address: record?.customer?.email_address,
      bs_nature: record?.customer?.nature_of_business,
      insurance_category: record?.category?.id,
      product_type: record?.product_type?.id,
      quotation_id: record?.inquiry,
      address: record?.address?.address,
      state: record?.address?.state,
      city: record?.address?.city,
      pincode: record?.address?.pincode,
      country: record?.address?.country,
    };
    navigate('/add-policy', { state: { initialValues: initVals, activekey: activeTabKey } });
  };

  const policyTabs = [
    {
      key: 'all',
      label: `All Policies`,
      Component: AllPolicies,
      props: {
        columns: curColumns,
        permissions: permissions,
        onEditPolicy: onEditPolicy,
        activeTabKey: activeTabKey,
        state: state,
        dateFilterValues,
        query: query,
      },
    },
    {
      key: 'other',
      label: `Policies from other sources`,
      Component: OtherPolices,
      props: {
        columns: curColumns,
        permissions: permissions,
        onEditPolicy: onEditPolicy,
        activeTabKey: activeTabKey,
        state: state,
        dateFilterValues,
        query: query,
      },
    },
    {
      key: 'renewable',
      label: `Renewals`,
      Component: RenewalPolicies,
      props: {
        columns: curColumns,
        permissions: permissions,
        onEditPolicy: onEditPolicy,
        activeTabKey: activeTabKey,
        state: state,
        dateFilterValues,
        query: query,
      },
    },
  ];

  /* search function */
  const handleOnSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDateFilter = useCallback((dates) => {
    const startDate = dates ? dayjs(dates[0]).format('YYYY-MM-DD') : '';
    const endDate = dates ? dayjs(dates[1]).add(1, 'day').format('YYYY-MM-DD') : '';
    setDateFilterValues({ startDate, endDate });
  }, []);
  useEffect(() => {
    localStorage.setItem('policyCurrColumns', JSON.stringify(curColumns));
  }, [curColumns]);

  return (
    <div>
      <ContentHeader
        showSearch={true}
        onChange={handleOnSearch}
        permissions={permissions}
        buttonId={elementData.policy.permissions.create}
        title={<p className='font-semibold text-s'>Policy Listing</p>}
        // data={breadCrumbs}
        btnText={
          activeTabKey === 'all'
            ? '+ Policies'
            : activeTabKey === 'other'
            ? '+ Policies from other sources'
            : ''
        }
        onClick={() => {
          navigate(`/add-policy`, {
            state: { activekey: activeTabKey },
          });
        }}
      >
        <div className='flex gap-4  mr-1'>
          <RangePicker
            onChange={handleDateFilter}
            className='group-focus-within:text-primary-90 bg-transparent border-gray-400 w-52 mt-1'
            allowClear={true}
          />
          <Filter
            removeValFrom={[20]}
            allColumns={policyColumns}
            activeColumns={curColumns}
            updateActiveColumns={setCurColumns}
            minFields={MIN_FIELDS}
            maxFields={MAX_FIELDS}
          />
        </div>
      </ContentHeader>
      <TabsComponent
        items={policyTabs}
        activeKey={activeTabKey}
        setActiveTabKey={setActiveTabKey}
        renderTabBar={true}
        renderTabBarClasses={'!mb-5 !mt-3'}
      />
    </div>
  );
};

export default PolicyMangement;
