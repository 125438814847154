import React from 'react';
import Reports from './Reports';

const NewReports = (props = {}) => {
  const permissions = props?.permissions || [];

  return (
    <div className='grid gap-3'>
      <Reports permissions={permissions} />
    </div>
  );
};

export default NewReports;
