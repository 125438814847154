import { endpoints } from 'constants/endpoints';
import Request from '../client';

export const getPosList = async ({ page = 1, query, is_active, status }) => {
  return await Request({
    method: 'get',
    API: endpoints.pos.list({ page, query, is_active, status }),
  });
};

export const addPos = async ({ data = undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.pos.create,
    data,
  });
  return resp;
};
export const editPos = async ({ data = undefined, id = '' }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.pos.update(id),
    data,
  });
  return resp;
};
export const deletePos = async ({ data = undefined, id = '' }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.pos.delete(id),
    data,
  });
  return resp;
};
export const getPosDoc = async ({ id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.pos.docs(id),
  });
  return resp;
};
export const posContractMangement = async ({ data, method = 'patch' }) => {
  const resp = await Request({
    method: method,
    API: method === 'patch' ? `pos/register` : `pos/agent-contract`,
    data,
  });
  return resp;
};
export const posRegister = async ({ data = undefined, method = 'get', id } = {}) => {
  const resp = await Request({
    method,
    API: method === 'delete' ? `pos/register/${id}` : `pos/register`,
    data,
  });
  return resp;
};

// pos

export const posEarningManagement = async ({
  method = 'get',
  id = '',
  start_date = '',
  end_date = '',
}) => {
  const resp = await Request({
    method: method,
    API: `pos/earning?pos_id=${id}&start_date=${start_date || ''}&end_date=${end_date || ''}`,
  });
  return resp;
};

export const PosUser = async ({ id }) => {
  const resp = await Request({
    method: 'get',
    API: `pos/?id=${id}`,
  });
  return resp;
};

export const templateManagement = async ({ data = undefined }) => {
  const resp = await Request({
    method: 'post',
    API: `pos/training`,
    data,
  });
  return resp;
};
