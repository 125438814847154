import { Divider } from 'antd';
import { CircleCheck, CircleClose } from 'assets/icons';

const { CustomModal } = require('components');

const LabelValue = ({ label, value }) => (
  <div className='flex flex-col'>
    <div className='border-1 opacity-70'>{label}</div>
    <div className='break-all capitalize text-xs font-semibold'>{value}</div>
  </div>
);

const DateModal = ({ open, handleModal, data }) => {
  const checkIn = data?.check_in || '--';
  const checkOut = data?.check_out || '--';
  const earlyIn = data?.early_in / 3600 || 0;
  const earlyOut = data?.early_out / 3600 || 0;
  const late = data?.late / 3600 || 0;
  const overtime = data?.overtime / 3600 || 0;
  const status = data?.status || '--';

  return (
    <CustomModal open={open} closable={true} onCancel={handleModal} footer={''} width={450}>
      <h2 className='text-xl font-semibold'>{`On ${data?.date || '-'}`}</h2>
      <Divider />
      <div className='flex gap-4'>
        <LabelValue
          label='Status'
          value={
            data?.check_in !== null && data?.check_out !== null && data?.status === 'leave'
              ? 'Half Day'
              : status
          }
        />
      </div>
      <Divider />
      <div className='grid grid-cols-2'>
        <LabelValue label='Check In Time' value={checkIn} />
        <LabelValue label='Check Out Time' value={checkOut} />
      </div>
      <Divider />
      <div className='grid grid-cols-2'>
        <LabelValue label='Early In Time' value={earlyIn.toFixed(2) + 'hr'} />
        <LabelValue label='Early Out Time' value={earlyOut.toFixed(2) + 'hr'} />
      </div>
      <Divider />
      <div className='grid grid-cols-2'>
        <LabelValue label='Late' value={`${late.toFixed(2)}hr`} />
        <LabelValue label='OverTime' value={`${overtime.toFixed(2)}hr`} />
      </div>
      <Divider />
    </CustomModal>
  );
};

export default DateModal;
