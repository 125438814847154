import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Divider,
  InputNumber,
  Radio,
  Modal,
  Spin,
  DatePicker,
  ConfigProvider,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { InvoiceData, ShareInvoices, CustomModal, Breadcrumbs, Toast } from 'components';
import { createInvoiceFunc, getInvoices } from 'service/api-service';
import { useNavigate, useParams } from 'react-router-dom';
import greentick from 'assets/png/greentick.png';
import SendEmail from './email';
import { Searchable } from 'utils/common';
import { breadCrumbsObj } from './data';
import { NOTIFICATION_MSG } from 'constants';
import locale from 'antd/es/date-picker/locale/en_US';
import { CalenderIcon } from 'assets/icons';
const today = dayjs();

const GST_VALUE = 18;
const Num = (val) => (isNaN(val) ? 0 : val || 0);

const getSubTotal = (summary) => {
  if (summary && summary.length > 1)
    return summary.reduce((acc, item) => Num(acc) + Num(item?.qty) * Num(item?.price));

  if (summary && summary.length === 1) return Num(summary[0]?.qty) * Num(summary[0]?.price);
  return 0;
};
const getGST = (subtotal) => (subtotal / 100) * GST_VALUE;

const CreateInvoice = () => {
  const [form] = Form.useForm();
  const due = Form.useWatch('due', form);
  const summary = Form.useWatch('summary', form);
  const send_to_type = Form.useWatch('send_to_type', form);
  const insurer = Form.useWatch('insurer', form);
  const customer = Form.useWatch('customer', form);
  const navigate = useNavigate();
  const { id } = useParams();

  //  modal work

  const [isShown, setIsShown] = useState(false);
  const toggle = useCallback(() => {
    setIsShown((s) => !s);
  }, []);

  const [messageData, setMessageData] = useState(null);
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idData, setIdData] = useState(null);
  const [finalFields, setFinalFields] = useState([]);
  const [email, setEmail] = useState('ello.mahabeer@gmail.com');
  const [nameData, setNameData] = useState(null);
  const [emailData, setEmailData] = useState(null);
  const [initialValues, setInitials] = useState();
  const [dueDate, setDueDate] = useState();

  const send_to = send_to_type === 'customer' ? customer : insurer;

  const subtotal = getSubTotal(summary) || 0;
  const gst = getGST(subtotal);
  const total = subtotal + gst;

  const record = {
    items: summary || [],
    title: send_to?.name,
    date: today.format('YYYY-MM-DD'),
    due: dayjs(dueDate)?.format('YYYY-MM-DD'),
    sent_to: send_to?.id?.toString(),
    choices: send_to_type === 'customer' ? 'customer' : 'insurer',
    gst: gst,
    sub_total: subtotal,
    total: total,
  };
  useEffect(() => {
    if (id) {
      setFinalFields(
        initialValues?.items?.map((item) => [
          {
            key: item?.name,
            name: item?.name,
            amount: item?.amount,
            quantity: item?.quantity,
          },
        ]),
      );
    }
  }, []);

  // prev and current comparision
  const VarRef = useRef(null);
  const myVar = send_to_type;
  const prevVar = VarRef.current;
  VarRef.current = myVar;

  useEffect(() => {
    setNameData(send_to?.name);
    setEmailData(send_to?.email_address);
    if (prevVar !== myVar) {
      setIdData(null);
      setNameData(null);
      setEmailData(null);
    }
  }, [myVar, prevVar, send_to]);

  const createPayload = async () => {
    // const resp = await createInvoiceFunc({ method: "post", data: JSON.stringify(record) });
    const resp = await createInvoiceFunc({ method: 'post', data: record });
    if (resp?.data && resp?.data?.status) {
      if (resp?.data && resp?.data?.status) {
        setIdData(resp?.data?.id);
        navigate(`/invoices`);
      }
    }
  };

  const toggleEmailPopup = () => {
    setEmailModal((pre) => {
      return !pre;
    });
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await getInvoices({ table_id: id });
    if (resp?.data?.status) {
      if (id) {
        setInitials(resp?.data?.data[0]);
      }
    } else Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchData();
  }, []);

  const _onChange = (val) => {
    const date = val?.$D;
    const month = val?.$M;
    const year = val?.$y;
    const time = `${year}-${month}-${date}`;
    setDueDate(time);
  };
  if (loading) {
    return (
      <div className='h-64 flex justify-center items-center'>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <div className='bg-white border rounded-xl border-grey-70 h-12 pt-4 pb-11 mb-3 noPrint'>
        <div className='flex justify-between px-4'>
          <Breadcrumbs data={breadCrumbsObj} />

          <div className='flex space-x-1'>
            <Button className='bg-primary  text-white' onClick={createPayload}>
              {idData ? 'Update' : 'Save'}
            </Button>
          </div>

          <Modal open={isShown} onCancel={toggle} footer={null}>
            <div className='flex justify-between mt-2 mb-1'>
              <div className='flex items-center ml-7 h-10'>
                <img src={greentick} alt='green tick' className='w-full h-full rounded-md' />
              </div>
              <div className='mr-12'>
                {messageData && isShown && (
                  <div className='flex justify-between'>
                    <span className='text-l text-grey-80'>{messageData} !!</span>
                  </div>
                )}
              </div>
            </div>
            <InvoiceData
              record={{ ...record, send_to }}
              idData={idData}
              nameData={nameData}
              emailData={emailData}
            />
            <ShareInvoices />
          </Modal>
        </div>
      </div>
      6:31
      <div className='grid grid-cols-3'>
        <div className='bg-white border rounded-xl border-grey-70 mr-3 col-span-2 noPrint'>
          <div className='flex flex-col pl-6 pt-5'>
            <p className='font-semibold'>Invoice Details</p>
            <p className='font-normal text-grey-90 mt-1'>You can find the invoice details below.</p>
          </div>
          <Form className='p-6' form={form} layout='vertical'>
            <div className='flex items-center'>
              <Form.Item label='Send To' name='send_to_type'>
                <Radio.Group defaultValue={initialValues?.choices}>
                  <Radio size='large' value={'customer'}>
                    Customer
                  </Radio>
                  <Radio size='large' value={'insurer'}>
                    Insurer
                  </Radio>
                </Radio.Group>
              </Form.Item>

              {send_to_type === 'customer' ? (
                <Form.Item name='customer' className='w-72'>
                  <Searchable
                    defaultValues={initialValues?.sent_to}
                    type='search'
                    placeholder='Search Customer'
                    field={{ name: 'customer' }}
                  />
                </Form.Item>
              ) : (
                <Form.Item name='insurer' className='w-72'>
                  <Searchable
                    defaultValues={initialValues?.sent_to}
                    type='search'
                    placeholder='Search Insurer'
                    field={{ name: 'insurer' }}
                  />
                </Form.Item>
              )}
            </div>

            <div className='w-44'>
              <Form.Item label='Due Date' name='due'>
                {/* <DateTime disablePrev placeholder="Select Due Date" customOnChange={_onChange} /> */}
                <ConfigProvider locale={locale}>
                  <DatePicker
                    placeholder='Select Due Date'
                    onChange={_onChange}
                    defaultValue={id ? dayjs(initialValues?.due) : ''}
                    allowClear={false}
                    className={` w-full border-grey-90 text-xxs xl:text-xs p-2 !focus:border-primary-90 inline-block `}
                    suffixIcon={<CalenderIcon svgClass='w-5' />}
                  />
                </ConfigProvider>
              </Form.Item>
            </div>

            <Divider />

            <Form.List name='summary'>
              {(fields, { add, remove }) => {
                return (
                  <>
                    <Form.Item>
                      <div className='flex justify-between '>
                        <div className='bg-blue-500 text-black p-4 pl-0 flex flex-col'>
                          <div className='font-semibold'>Item Summary</div>
                          {/* <div className="text-lg font-bold">Item Summary</div> */}
                          <span className='text-lg text-grey-80 font-semibold'>
                            The add item button allows you to add a summary for an item
                          </span>
                        </div>
                        <div className='bg-green-500 text-black p-4 space-x-1 '>
                          <Button
                            type='default text-primary'
                            className='border border-primary'
                            onClick={() => add()}
                            block
                          >
                            <div className='flex items-center space-x-1'>
                              <PlusOutlined />
                              <span> Add Item</span>
                            </div>
                          </Button>
                        </div>
                      </div>
                      {(id ? initialValues?.items?.length : fields.length) > 0 && (
                        <div className='grid grid-cols-3 '>
                          <span className='text-lg font-semibold'>Item Name</span>
                          <span className='text-lg font-semibold '>Quantity</span>
                          <span className='text-lg font-semibold '>Price</span>
                        </div>
                      )}
                    </Form.Item>
                    {/* {id && finalFields?.map(({ key, name, ...restField }) => (
                      <div key={key} className="grid grid-cols-3 items-center">
                        <Form.Item {...restField} name={[name, "name"]}>
                          <Input placeholder="Item name" className="h-11 flex-1" />
                        </Form.Item>
                        <Form.Item className="px-2" {...restField} name={[name, "quantity"]}>
                          <InputNumber className="w-full" placeholder="QTY" />
                        </Form.Item>
                        <div className="flex items-start">
                          <Form.Item {...restField} name={[name, "amount"]}>
                            <InputNumber placeholder="Unit Price" className="" />
                          </Form.Item>

                          <MinusCircleOutlined
                            className="ml-1 mt-3 text-s"
                            onClick={() => remove(name)}
                          />
                        </div>
                      </div>
                    ))} */}
                    {fields?.map(({ key, name, ...restField }) => (
                      <div key={key} className='grid grid-cols-3 items-center'>
                        <Form.Item {...restField} name={[name, 'name']}>
                          <Input placeholder='Item name' className='h-11 flex-1' />
                        </Form.Item>
                        <Form.Item className='px-2' {...restField} name={[name, 'quantity']}>
                          <InputNumber className='w-full' placeholder='QTY' />
                        </Form.Item>
                        <div className='flex items-start'>
                          <Form.Item {...restField} name={[name, 'amount']}>
                            <InputNumber placeholder='Unit Price' className='' />
                          </Form.Item>

                          <MinusCircleOutlined
                            className='ml-1 mt-3 text-s'
                            onClick={() => remove(name)}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </Form>
        </div>
        <InvoiceData
          record={{ ...record, send_to, dueDate }}
          idData={idData}
          nameData={nameData}
          emailData={emailData}
        />
        <CustomModal
          destroyOnClose={true}
          open={emailModal}
          onCancel={toggleEmailPopup}
          footer={''}
          width={1024}
        >
          <SendEmail toggleEmailPopup={toggleEmailPopup} email={email} />
        </CustomModal>
      </div>
    </>
  );
};

export default CreateInvoice;
