import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Toast, LeadForm } from 'components';
import { SecureComponent } from 'permission-provider';
import { NOTIFICATION_MSG } from 'constants';

const AddInquiry = (props = {}) => {
  const permissions = props?.permissions || [];
  const navigate = useNavigate();
  /* states  */

  const onSuccess = useCallback((msg, id) => {
    Toast('success', NOTIFICATION_MSG.success, msg);
    navigate('/lead-detail/' + id);
  }, []);

  const onError = useCallback((error) => {
    Toast('error', NOTIFICATION_MSG.error, error || 'Please try again');
  }, []);

  const backNavigate = () => {
    navigate(-1);
  };

  return (
    <div className='bg-white w-full h-full overflow-auto'>
      <LeadForm
        permissions={permissions}
        onSuccess={onSuccess}
        onError={onError}
        backNavigate={backNavigate}
        forPos={false}
      />
    </div>
  );
};

export default SecureComponent(AddInquiry);
