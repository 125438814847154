/** external deps */
import React, { useContext } from 'react';
/** internal deps */
import Inquiry from 'screens/customers/inquiry';
import { Context } from 'store/context';

const SalesInquiry = (props = {}) => {
  const { user } = useContext(Context);
  const permissions = props?.permissions || [];

  return (
    <Inquiry permissions={permissions} employee_id={user?.user_type === 'employee' && user?.id} />
  );
};

export default SalesInquiry;
