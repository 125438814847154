import React, { createContext, useState, useContext } from 'react';

const MyContext = createContext();

export const useMyContext = () => useContext(MyContext);

export const MyContextProvider = ({ children }) => {
  const [myStateValue, setMyStateValue] = useState(false);

  return (
    <MyContext.Provider value={{ myStateValue, setMyStateValue }}>{children}</MyContext.Provider>
  );
};
