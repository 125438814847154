import { Field } from 'formik';
import React from 'react';

const FieldArrayField = ({ ind, parentName, fieldName, form, ...props }) => {
  const value = form?.initialValues?.[parentName][ind];

  return <Field {...props} defaultValue={value?.[fieldName]} />;
};

export default FieldArrayField;
