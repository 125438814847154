export const formatQuoteViewData = (data) => {
  const res = {
    _: [],
    'Insurance details': [],
  };
  if (!data) return res;
  Object.entries(data).forEach(([key, val]) => {
    switch (key) {
      case 'quote_no':
      case 'ins_from':
      case 'ins_to':
      case 'collateral':
      case 'stage':
      case 'subject':
      case 'duration':
        res['_'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
        break;
      case 'category':
      case 'branch':
      case 'contact':
      case 'employee':
      case 'inquiry':
        res['_'].push({ label: key, value: val?.name, name: key.replaceAll('_', ' ') });
        break;
      // case "insurance_details":
      //   res["Insurance details"].push({
      //     label: "Amount",
      //     value: val?.amount,
      //     name: "Amount",
      //   });
      //   res["Insurance details"].push({
      //     label: "Benefit",
      //     value: val?.benefit,
      //     name: "Benefit",
      //   });
      //   res["Insurance details"].push({
      //     label: "dob",
      //     value: val?.dob,
      //     name: "Date of Birth",
      //   });
      //   res["Insurance details"].push({
      //     label: "income",
      //     value: val?.income,
      //     name: "Income",
      //   });
      //   res["Insurance details"].push({
      //     label: "invest",
      //     value: val?.invest,
      //     name: "invest",
      //   });
      //   res["Insurance details"].push({
      //     label: "Plan",
      //     value: val?.plan,
      //     name: "Plan",
      //   });
      //   res["Insurance details"].push({
      //     label: "smoke",
      //     value: val?.smoke,
      //     name: "Do you smoke?",
      //   });
      //   break;
      // case "des":
      //   res["Description"].push({
      //     label: key,
      //     value: val,
      //     name: key,
      //     className: "col-span-full",
      //   });
      //   break;
      case 'insurance_details':
        if (val) {
          Object.entries(val).forEach(([key, val]) => {
            res['Insurance details'].push({
              label: key,
              value: val,
              name: key.replaceAll('_', ' '),
            });
          });
        }
        break;
      default:
        break;
    }
  });
  return res;
};
