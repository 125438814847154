import { Checkbox, CustomRadio, DateTime, SearchAPI, SelectInput, TextInput } from 'components';
import dayjs from 'dayjs';

export const PartnerFields = ({
  maxDate,
  setRelationWithLa,
  setMaritlStatus,
  relationWithLa,
  setMwpaOpted,
}) => {
  return {
    'Proposer Information': {
      showAccordionHeader: true,
      fields: [
        {
          label: 'First Name',
          name: 'prosfrstNm',
          id: 'prosfrstNm',
          placeholder: 'First Name',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'Last Name',
          name: 'proslstNm',
          id: 'proslstNm',
          placeholder: 'Last Name',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'DOB',
          name: 'prosdob',
          id: 'prosdob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          showTime: false,
          defaultValue: maxDate,
          required: true,
          component: (props) => (
            <DateTime defaultValue={maxDate} {...props} disabledDate={maxDate} />
          ),
        },
        {
          label: 'Email',
          name: 'prosemail',
          id: 'prosemail',
          placeholder: 'Email',
          required: true,
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Mobile Number',
          name: 'prosmobNo',
          id: 'prosmobNo',
          placeholder: 'Mobile Number',
          required: true,
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Gender',
          name: 'prosgender',
          id: 'prosgender',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' },
            { value: 'Transgender', label: 'Transgender' },
          ],
        },
        {
          label: 'Father Name',
          name: 'prosfathersName',
          id: 'prosfathersName',
          placeholder: 'Father Name',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Mother Name',
          name: 'prosmothersName',
          id: 'prosmothersName',
          placeholder: 'Mother Name',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Marital Status',
          name: 'prosmarital_status',
          id: 'prosmarital_status',
          required: true,
          type: 'select',
          component: SelectInput,
          // customOnChange: (field, option) => {
          //   if (relationWithLa === "Self") {
          //     setMaritlStatus(option?.value)
          //   }
          // },
          options: [
            { value: '697', label: 'Single' },
            { value: '696', label: 'Married' },
            { value: '695', label: 'Divorced' },
            { value: '694', label: 'Widowed' },
          ],
        },
        {
          label: 'Education',
          name: 'proseducation',
          id: 'proseducation',
          type: 'text',
          required: true,
          component: SelectInput,
          options: [
            { label: 'Post Graduate', value: '676' },
            { label: 'Graduate', value: '680' },
            { label: '12th', value: '679' },
            { label: '10th', value: '678' },
            { label: 'Diploma', value: '675' },
            { label: 'Below 10th', value: '677' },
          ],
        },
        {
          label: 'Pan Number',
          name: 'prospanNo',
          id: 'prospanNo',
          placeholder: 'Pan Number',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'Adhar Number',
          name: 'prosaadharCardNo',
          id: 'prosaadharCardNo',
          placeholder: 'Adhar Number',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'RESIDENT STATUS',
          name: 'prosrstSts',
          id: 'prosrstSts',
          type: 'text',
          required: true,
          component: SelectInput,
          options: [
            { label: 'Resident Indian', value: 'Resident Indian' },
            { label: 'NRI', value: 'NRI' },
            { label: 'PIO', value: 'PIO' },
            { label: 'Foreign National', value: 'Foreign National' },
          ],
        },
        {
          label: 'Relation With LA',
          name: 'prosrelationWithLa',
          id: 'prosrelationWithLa',
          placeholder: 'Relation With LA',
          type: 'select',
          required: true,
          component: SelectInput,
          // customOnChange: (field, option) => {
          //   setRelationWithLa(option?.value)
          // },
          options: [
            { label: 'Self', value: 'Self' },
            { label: 'Father', value: 'Father' },
            { label: 'Grand Father', value: 'Grand Father' },
            { label: 'Grand Mother', value: 'Grand Mother' },
            { label: 'Mother', value: 'Mother' },
            { label: 'Spouse', value: 'Spouse' },
          ],
        },
        {
          label: 'Maturity Amount',
          name: 'prosmaturityAmnt',
          id: 'prosmaturityAmnt',
          required: true,
          placeholder: 'Maturity Amount',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Annual Income',
          name: 'prosannIncme',
          id: 'prosannIncme',
          placeholder: 'Annual Income',
          required: true,
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Organisation Type',
          name: 'prosorgType',
          id: 'prosorgType',
          placeholder: 'Organisation Type',
          required: true,
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'Others', value: 'NOT ANSWERED' },
            { label: 'Govt', value: 'GOVT' },
            { label: 'Pvt Ltd', value: 'PRIVATE LTD' },
            { label: 'Public Ltd', value: 'PUBLIC LTD' },
            { label: 'Trust', value: 'TRST' },
            { label: 'Society', value: 'SOCIETY' },
            { label: 'Proprietor', value: 'PROPREITOR' },
            { label: 'Partnership', value: 'PARTNER' },
          ],
        },
        {
          label: 'Name Of Organization',
          name: 'prosnameOfOrg',
          id: 'prosnameOfOrg',
          required: true,
          placeholder: 'Name Of Organization',
          type: 'search',
          getValue: true,
          component: SearchAPI,
        },
        {
          label: ' Organization Description',
          name: 'prosnameOfOrgDesc',
          id: 'prosnameOfOrgDesc',
          placeholder: 'Organization Description',
          type: 'search',
          getValue: true,
          component: SearchAPI,
        },
        {
          label: 'Organisation Type Description',
          name: 'prosorgTypeDesc',
          id: 'prosorgTypeDesc',
          placeholder: 'Organisation Type Description',
          type: 'text',
          component: TextInput,
        },
        {
          dependentArray: true,
          dependent_on: 'prosmarital_status',
          dependent_value: '696',
          label: 'Spouse Name',
          name: 'prosspouseName',
          id: 'prosspouseName',
          placeholder: 'Spouse Name',
          type: 'text',
          component: TextInput,
        },
        {
          label: ' Staff',
          name: 'staff',
          id: 'staff',
          placeholder: 'Staff',
          type: 'Checkbox',
          divClasses: ' mt-5',
          component: Checkbox,
        },
      ],
    },
    'CKyc Document': {
      showAccordionHeader: true,
      fields: [
        {
          label: 'C Kyc Number',
          name: 'ckycNumber',
          id: 'ckycNumber',
          placeholder: 'C Kyc Number',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'ID Proof',
          name: 'idPrf',
          id: 'idPrf',
          placeholder: 'ID Proof',
          required: true,
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'Masked Aadhaar Card Copy', value: 'Adhr' },
            { label: 'Driving License (Current)', value: 'Driving license' },
            { label: 'Job Card Issued by NREGA', value: 'Card Issued by NREGA' },
            { label: 'Passport (Current)', value: 'Passport' },
            { label: 'Voters ID Card', value: 'Voters ID' },
          ],
        },
        {
          label: 'Address Proof',
          name: 'addPrf',
          required: true,
          id: 'addPrf',
          placeholder: 'Address Proof',
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'Masked Aadhaar Card Copy', value: 'Adhr' },
            { label: 'Driving License (Current)', value: 'Driving License' },
            { label: 'Passport (Current)', value: 'Passport' },
            { label: 'Voters ID Card', value: 'Voters ID' },
          ],
        },
        {
          label: 'Age Proof',
          required: true,
          name: 'agePrf',
          id: 'agePrf',
          placeholder: 'Age Proof',
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'Masked Aadhaar Card Copy   ', value: 'Adhr' },
            { label: 'Defence I Card             ', value: 'DEF' },
            { label: 'Driving License (Current)  ', value: 'DL' },
            { label: 'Employers Certificate', value: 'EMPCRT' },
            { label: 'Goverment Employee ID      ', value: 'GOVTID' },
            { label: 'Government Registered document or ID Proof  GSR', value: 'GSR' },
            { label: 'Municipal Record', value: 'MUN' },
            { label: 'PAN card', value: 'PAN' },
            { label: 'Passport', value: 'PSPT' },
            { label: 'School/College Record      ', value: 'SC' },
          ],
        },
        {
          label: 'IT Proof',
          required: true,
          name: 'itPrf',
          id: 'itPrf',
          placeholder: 'IT Proof',
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'PAN', value: 'pancard' },
            { label: 'FORM 60', value: 'form 60' },
          ],
        },
        {
          label: 'Income Proof',
          name: 'incomePrf',
          required: true,
          id: 'incomePrf',
          placeholder: 'Income Proof',
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'Income Tax Returns (Last 3 Years)', value: 'Spouse' },
            { label: 'Bank Cash Flow Stmt,Pass Book (Last 6 Months)', value: 'Spouse' },
            {
              label: 'Salary Slip Signed by authorized signatory of Employer (Last 3 Months)',
              value: 'Spouse',
            },
            { label: 'Land Records', value: 'Spouse' },
            { label: 'Chartered Accnt Certificate', value: 'Spouse' },
            { label: 'Audited Firm Accounts', value: 'Spouse' },
            { label: 'Audited Company Accounts', value: 'Spouse' },
            { label: 'Agricultural Income Certificate', value: 'Spouse' },
            { label: 'Employers Certificate', value: 'Spouse' },
            { label: 'None', value: 'Spouse' },
            { label: 'Form 16', value: 'Spouse' },
          ],
        },
      ],
    },
    'Communication Address': {
      showAccordionHeader: true,
      fields: [
        {
          label: 'Pincode',
          name: 'compincode',
          id: 'compincode',
          placeholder: 'Pincode',
          required: true,
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Landmark',
          name: 'comlandmark',
          id: 'comlandmark',
          placeholder: 'Landmark',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Line 1',
          name: 'comline1',
          required: true,
          id: 'comline1',
          placeholder: 'Line 1',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'line 2',
          name: 'comline2',
          id: 'comline2',
          placeholder: 'Line 2',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Line 3',
          name: 'comline3',
          id: 'comline3',
          placeholder: 'Line 3',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'State',
          name: 'comstate',
          id: 'comstate',
          placeholder: 'State',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [
            { label: 'ANDAMAN AND NICOBAR', value: '1' },
            { label: 'ANDHRA PRADESH', value: '2' },
            { label: 'ARUNACHAL PRADESH', value: '3' },
            { label: 'ASSAM', value: '4' },
            { label: 'BIHAR', value: '5' },
            { label: 'CHANDIGARH', value: '6' },
            { label: 'DADRA AND NAGAR', value: '7' },
            { label: 'DAMAN AND DIU', value: '8' },
            { label: 'DELHI', value: '9' },
            { label: 'GOA', value: '10' },
            { label: 'GUJARAT', value: '11' },
            { label: 'HIMACHAL PRADESH', value: '21' },
            { label: 'JAMMU AND KASHMIR', value: '22' },
            { label: 'KERALA', value: '24' },
            { label: 'LAKSHADWEEP', value: '25' },
            { label: 'MAHARASHTRA', value: '27' },
            { label: 'MANIPUR', value: '28' },
            { label: 'MEGHALAYA', value: '29' },
            { label: 'NAGALAND', value: '31' },
            { label: 'ORISSA', value: '32' },
            { label: 'PUNJAB', value: '34' },
            { label: 'RAJASTHAN', value: '35' },
            { label: 'SIKKIM', value: '36' },
            { label: 'TRIPURA', value: '38' },
            { label: 'UTTAR PRADESH', value: '39' },
            { label: 'JHARKHAND', value: '42' },
            { label: 'UTTARAKHAND', value: '43' },
            { label: 'TELANGANA', value: '45' },
            { label: 'HARAYANA', value: '12' },
            { label: 'KARNATAKA', value: '13' },
            { label: 'MADHYA PRADESH', value: '26' },
            { label: 'MIZORAM', value: '30' },
            { label: 'PONDICHERRY ', value: '33' },
            { label: 'TAMIL NADU', value: '37' },
            { label: 'WEST BENGAL', value: '40' },
            { label: 'CHHATTISGARH', value: '44' },
          ],
        },
        {
          label: 'City',
          name: 'comcity',
          id: 'comcity',
          placeholder: 'City',
          required: true,
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Country',
          name: 'comcountry',
          id: 'comcountry',
          placeholder: 'Country',
          type: 'search',
          component: SearchAPI,
          required: true,
          getValue: true,
        },
      ],
    },
    'Permanent Address': {
      showAccordionHeader: true,
      fields: [
        {
          label: 'Pincode',
          name: 'peraddpincode',
          id: 'peraddpincode',
          placeholder: 'Pincode',
          required: true,
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Landmark',
          name: 'peraddlandmark',
          id: 'peraddlandmark',
          placeholder: 'Landmark',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Line 1',
          name: 'peraddline1',
          required: true,
          id: 'peraddline1',
          placeholder: 'Line 1',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'line 2',
          name: 'peraddline2',
          id: 'peraddline2',
          placeholder: 'Line 2',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Line 3',
          name: 'peraddline3',
          id: 'peraddline3',
          placeholder: 'Line 3',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'State',
          name: 'peraddstate',
          id: 'peraddstate',
          placeholder: 'State',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [
            { label: 'ANDAMAN AND NICOBAR', value: '1' },
            { label: 'ANDHRA PRADESH', value: '2' },
            { label: 'ARUNACHAL PRADESH', value: '3' },
            { label: 'ASSAM', value: '4' },
            { label: 'BIHAR', value: '5' },
            { label: 'CHANDIGARH', value: '6' },
            { label: 'DADRA AND NAGAR', value: '7' },
            { label: 'DAMAN AND DIU', value: '8' },
            { label: 'DELHI', value: '9' },
            { label: 'GOA', value: '10' },
            { label: 'GUJARAT', value: '11' },
            { label: 'HIMACHAL PRADESH', value: '21' },
            { label: 'JAMMU AND KASHMIR', value: '22' },
            { label: 'KERALA', value: '24' },
            { label: 'LAKSHADWEEP', value: '25' },
            { label: 'MAHARASHTRA', value: '27' },
            { label: 'MANIPUR', value: '28' },
            { label: 'MEGHALAYA', value: '29' },
            { label: 'NAGALAND', value: '31' },
            { label: 'ORISSA', value: '32' },
            { label: 'PUNJAB', value: '34' },
            { label: 'RAJASTHAN', value: '35' },
            { label: 'SIKKIM', value: '36' },
            { label: 'TRIPURA', value: '38' },
            { label: 'UTTAR PRADESH', value: '39' },
            { label: 'JHARKHAND', value: '42' },
            { label: 'UTTARAKHAND', value: '43' },
            { label: 'TELANGANA', value: '45' },
            { label: 'HARAYANA', value: '12' },
            { label: 'KARNATAKA', value: '13' },
            { label: 'MADHYA PRADESH', value: '26' },
            { label: 'MIZORAM', value: '30' },
            { label: 'PONDICHERRY ', value: '33' },
            { label: 'TAMIL NADU', value: '37' },
            { label: 'WEST BENGAL', value: '40' },
            { label: 'CHHATTISGARH', value: '44' },
          ],
        },
        {
          label: 'City',
          name: 'peraddcity',
          id: 'peraddcity',
          placeholder: 'City',
          required: true,
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Country',
          name: 'peraddcountry',
          id: 'peraddcountry',
          placeholder: 'Country',
          required: true,
          type: 'search',
          component: SearchAPI,
          getValue: true,
        },
        {
          label: 'Nationality',
          name: 'prosnationality',
          id: 'prosnationality',
          type: 'text',
          required: true,
          component: SelectInput,
          options: [
            { label: 'Indian', value: 'Indian' },
            { label: 'Non Indian', value: 'Non Indian' },
          ],
        },
      ],
    },
    'Life Assured Information': {
      showAccordionHeader: true,
      fields: [
        {
          label: 'First Name',
          name: 'lifefrstNm',
          id: 'lifefrstNm',
          placeholder: 'First Name',
          type: 'text',
          component: TextInput,
          required: true,
        },
        {
          required: true,
          label: 'Last Name',
          name: 'lifelstNm',
          id: 'lifelstNm',
          placeholder: 'Last Name',
          type: 'text',
          component: TextInput,
        },
        {
          required: true,
          label: 'Marital Status',
          name: 'lifemrtlSts',
          id: 'lifemrtlSts',
          type: 'select',
          component: SelectInput,
          // customOnChange: (field, option) => {
          //   if (relationWithLa !== "Self") {
          //     setMaritlStatus(option?.value)
          //   }
          // },
          options: [
            { value: '697', label: 'Single' },
            { value: '696', label: 'Married' },
            { value: '695', label: 'Divorced' },
            { value: '694', label: 'Widowed' },
          ],
        },
        {
          required: true,
          label: 'DOB',
          name: 'lifedob',
          id: 'lifedob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          showTime: false,
          component: (props) => (
            <DateTime defaultValue={maxDate} {...props} disabledDate={maxDate} />
          ),
        },
        {
          required: true,
          label: 'Mobile Number',
          name: 'lifemobNo',
          id: 'lifemobNo',
          placeholder: 'Mobile Number',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Gender',
          required: true,
          name: 'lifegender',
          id: 'lifegender',
          type: 'select',
          component: SelectInput,
          options: [
            { value: 'male', label: 'Male' },
            { value: 'female', label: 'Female' },
            { value: 'Transgender', label: 'Transgender' },
          ],
        },
        {
          label: 'Adhar Card Number',
          name: 'lifeaadharCardNo',
          id: 'lifeaadharCardNo',
          required: true,
          placeholder: 'Adhar Card Number',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Education',
          name: 'lifeeducation',
          id: 'lifeeducation',
          required: true,
          type: 'text',
          component: SelectInput,
          options: [
            { label: 'Post Graduate', value: '676' },
            { label: 'Graduate', value: '680' },
            { label: '12th', value: '679' },
            { label: '10th', value: '678' },
            { label: 'Diploma', value: '675' },
            { label: 'Below 10th', value: '677' },
          ],
        },
        {
          label: 'ID Proof',
          name: 'lifeidPrf',
          id: 'lifeidPrf',
          required: true,
          placeholder: 'ID Proof',
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'Masked Aadhaar Card Copy', value: 'Adhr' },
            { label: 'Driving License (Current)', value: 'Driving license' },
            { label: 'Job Card Issued by NREGA', value: 'Card Issued by NREGA' },
            { label: 'Passport (Current)', value: 'Passport' },
            { label: 'Voters ID Card', value: 'Voters ID' },
          ],
        },
        {
          label: 'Address Proof',
          name: 'lifeaddPrf',
          id: 'lifeaddPrf',
          required: true,
          placeholder: 'Address Proof',
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'Masked Aadhaar Card Copy', value: 'Adhr' },
            { label: 'Driving License (Current)', value: 'Driving License' },
            { label: 'Passport (Current)', value: 'Passport' },
            { label: 'Voters ID Card', value: 'Voters ID' },
          ],
        },
        {
          label: 'Age Proof',
          name: 'lifeagePrf',
          id: 'lifeagePrf',
          required: true,
          placeholder: 'Age Proof',
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'Masked Aadhaar Card Copy   ', value: 'Adhr' },
            { label: 'Defence I Card             ', value: 'DEF' },
            { label: 'Driving License (Current)  ', value: 'DL' },
            { label: 'Employers Certificate', value: 'EMPCRT' },
            { label: 'Goverment Employee ID      ', value: 'GOVTID' },
            { label: 'Government Registered document or ID Proof  GSR', value: 'GSR' },
            { label: 'Municipal Record', value: 'MUN' },
            { label: 'PAN card', value: 'PAN' },
            { label: 'Passport', value: 'PSPT' },
            { label: 'School/College Record      ', value: 'SC' },
          ],
        },
        {
          label: 'IT Proof',
          name: 'lifeitPrf',
          id: 'lifeitPrf',
          required: true,
          placeholder: 'IT Proof',
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'PAN', value: 'pancard' },
            { label: 'FORM 60', value: 'form 60' },
          ],
        },
        {
          label: 'Income Proof',
          name: 'lifeincomePrf',
          id: 'lifeincomePrf',
          placeholder: 'Income Proof',
          required: true,
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'Income Tax Returns (Last 3 Years)', value: 'Spouse' },
            { label: 'Bank Cash Flow Stmt,Pass Book (Last 6 Months)', value: 'Spouse' },
            {
              label: 'Salary Slip Signed by authorized signatory of Employer (Last 3 Months)',
              value: 'Spouse',
            },
            { label: 'Land Records', value: 'Spouse' },
            { label: 'Chartered Accnt Certificate', value: 'Spouse' },
            { label: 'Audited Firm Accounts', value: 'Spouse' },
            { label: 'Audited Company Accounts', value: 'Spouse' },
            { label: 'Agricultural Income Certificate', value: 'Spouse' },
            { label: 'Employers Certificate', value: 'Spouse' },
            { label: 'None', value: 'Spouse' },
            { label: 'Form 16', value: 'Spouse' },
          ],
        },
        {
          label: 'Pincode',
          name: 'lifepincode',
          id: 'lifepincode',
          required: true,
          placeholder: 'Pincode',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Land Line Number',
          name: 'lifelandLnNo',
          id: 'lifelandLnNo',
          placeholder: 'Land Line Number',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'STD Number',
          name: 'lifestdNo',
          id: 'lifestdNo',
          placeholder: 'STD Number',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Nationality',
          name: 'lifenationality',
          id: 'lifenationality',
          type: 'text',
          required: true,
          component: SelectInput,
          options: [
            { label: 'Indian', value: 'Indian' },
            { label: 'Non Indian', value: 'Non Indian' },
          ],
        },
        {
          label: 'Name Of Organization',
          name: 'lifenameOfOrg',
          id: 'lifenameOfOrg',
          required: true,
          placeholder: 'Name Of Organization',
          type: 'search',
          component: SearchAPI,
        },
        {
          label: 'Organisation Type Description',
          name: 'lifeorgTypeDesc',
          id: 'lifeorgTypeDesc',
          placeholder: 'Organisation Type Description',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Annual Income',
          name: 'lifeanuuIncme',
          id: 'lifeanuuIncme',
          required: true,
          placeholder: 'Annual Income',
          type: 'text',
          component: TextInput,
        },
        {
          // dependentArray :true,
          // dependent_on :"lifemrtlSts",
          // // dependent_on : relationWithLa === "Self" ? "prosmarital_status" : "lifemrtlSts",
          // dependent_value : "697",
          // label: 'MWPA opted',
          // name: 'mwpaOpted',
          // id: 'mwpaOpted',
          // required: true,
          // type: 'select',
          // component: SelectInput,
          // customOnChange: (field, option) => {
          //   setMwpaOpted(option?.value)
          // },
          // options: [
          //   { value: 'Yes', label: 'Yes' },
          //   { value: 'No', label: 'No' },
          // ],
        },
      ],
    },
    'Nominee Information': {
      showAccordionHeader: true,
      fields: [
        {
          label: 'First Name',
          name: 'nomfrstNm',
          id: 'nomfrstNm',
          placeholder: 'First Name',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'Last Name',
          name: 'nomlstNm',
          id: 'nomlstNm',
          placeholder: 'Last Name',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'Gender',
          name: 'nomgender',
          id: 'nomgender',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' },
            { value: 'Transgender', label: 'Transgender' },
          ],
        },
        {
          label: 'Relationship',
          name: 'nomrelationship',
          id: 'nomrelationship',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [
            { label: 'AUNT', value: 'Aunt' },
            { label: 'BROTHER IN LAW', value: 'Brother in law' },
            { label: 'Daughter', value: 'Daughter' },
            { label: 'Father', value: 'Father' },
            { label: 'Grand Father', value: 'Grand Father' },
            { label: 'Grand Mother', value: 'Grand Mother' },
            { label: 'GRAND SON', value: 'Grand Son' },
            { label: 'HUSBAND', value: 'Husband' },
            { label: 'MOTHER', value: 'Mother' },
            { label: 'NIECE', value: 'Niece' },
            { label: 'SISTER', value: 'Sister' },
            { label: 'Wife', value: 'Wife' },
            { label: 'Son', value: 'Son' },
            { label: 'UNCLE', value: 'Uncle' },
            { label: 'BROTHER', value: 'Brother' },
            { label: 'GRAND DAUGHTER', value: 'Grand Daughter' },
            { label: 'NEPHEW', value: 'Nephew' },
            { label: 'SISTER IN LAW', value: 'Sister in law' },
          ],
        },
        {
          label: 'DOB',
          name: 'nomdob',
          id: 'nomdob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          showTime: false,
          required: true,
          defaultValue: maxDate,
          component: (props) => (
            <DateTime defaultValue={maxDate} {...props} disabledDate={maxDate} />
          ),
        },
      ],
    },
    'Benificiary Detail': {
      showAccordionHeader: true,
      fields: [
        {
          label: 'First Name',
          name: 'benfrstNm',
          id: 'benfrstNm',
          placeholder: 'First Name',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Last Name',
          name: 'benlstNm',
          id: 'benlstNm',
          placeholder: 'Last Name',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Share Of Benefit',
          placeholder: 'Share Of Benefit',
          name: 'benshareOfBenefit',
          id: 'benshareOfBenefit',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Relationship',
          name: 'benrelationship',
          id: 'benrelationship',
          type: 'select',
          component: SelectInput,
          options: [
            { value: 'Daughter', label: 'Daughter' },
            { value: 'Son', label: 'Son' },
            { value: 'Wife', label: 'Wife' },
          ],
        },
        {
          label: 'DOB',
          name: 'bendob',
          id: 'bendob',
          placeholder: 'YYYY-MM-DD',
          defaultValue: maxDate,
          type: 'date',
          showTime: false,
          component: (props) => (
            <DateTime defaultValue={maxDate} {...props} disabledDate={maxDate} />
          ),
        },
      ],
    },
    'Trustee Details': {
      showAccordionHeader: true,
      fields: [
        {
          label: 'Name',
          name: 'trusteename',
          id: 'trusteename',
          placeholder: 'Name',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'DOB',
          name: 'trusteedob',
          id: 'trusteedob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          showTime: false,
          defaultValue: maxDate,
          required: true,
          component: (props) => (
            <DateTime defaultValue={maxDate} {...props} disabledDate={maxDate} />
          ),
        },
        {
          label: 'Relationship with LA',
          name: 'trusteerelationWithLa',
          id: 'trusteerelationWithLa',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [
            { value: 'Entity', label: 'Entity' },
            { value: 'Individual', label: 'Individual' },
          ],
        },
        {
          label: 'Address',
          name: 'trusteeaddress',
          id: 'trusteeaddress',
          placeholder: 'Address',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'City',
          name: 'trusteecity',
          id: 'trusteecity',
          placeholder: 'City',
          required: true,
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Pincode',
          name: 'trusteepincode',
          id: 'trusteepincode',
          placeholder: 'Pincode',
          type: 'text',
          component: TextInput,
          required: true,
        },
        {
          label: 'State',
          name: 'trusteestate',
          id: 'trusteestate',
          placeholder: 'State',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [
            { label: 'ANDAMAN AND NICOBAR', value: '1' },
            { label: 'ANDHRA PRADESH', value: '2' },
            { label: 'ARUNACHAL PRADESH', value: '3' },
            { label: 'ASSAM', value: '4' },
            { label: 'BIHAR', value: '5' },
            { label: 'CHANDIGARH', value: '6' },
            { label: 'DADRA AND NAGAR', value: '7' },
            { label: 'DAMAN AND DIU', value: '8' },
            { label: 'DELHI', value: '9' },
            { label: 'GOA', value: '10' },
            { label: 'GUJARAT', value: '11' },
            { label: 'HIMACHAL PRADESH', value: '21' },
            { label: 'JAMMU AND KASHMIR', value: '22' },
            { label: 'KERALA', value: '24' },
            { label: 'LAKSHADWEEP', value: '25' },
            { label: 'MAHARASHTRA', value: '27' },
            { label: 'MANIPUR', value: '28' },
            { label: 'MEGHALAYA', value: '29' },
            { label: 'NAGALAND', value: '31' },
            { label: 'ORISSA', value: '32' },
            { label: 'PUNJAB', value: '34' },
            { label: 'RAJASTHAN', value: '35' },
            { label: 'SIKKIM', value: '36' },
            { label: 'TRIPURA', value: '38' },
            { label: 'UTTAR PRADESH', value: '39' },
            { label: 'JHARKHAND', value: '42' },
            { label: 'UTTARAKHAND', value: '43' },
            { label: 'TELANGANA', value: '45' },
            { label: 'HARAYANA', value: '12' },
            { label: 'KARNATAKA', value: '13' },
            { label: 'MADHYA PRADESH', value: '26' },
            { label: 'MIZORAM', value: '30' },
            { label: 'PONDICHERRY ', value: '33' },
            { label: 'TAMIL NADU', value: '37' },
            { label: 'WEST BENGAL', value: '40' },
            { label: 'CHHATTISGARH', value: '44' },
          ],
        },
        {
          label: 'Bank Account Holder',
          name: 'trusteeNameofBankAccountHolder',
          id: 'trusteeNameofBankAccountHolder',
          placeholder: 'Bank Account Holder',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'Bank Account Number',
          name: 'trusteeBankAccountNumber',
          id: 'trusteeBankAccountNumber',
          placeholder: 'Bank Account Number',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'IFSC Code',
          name: 'trusteeIFSC',
          id: 'trusteeIFSC',
          placeholder: 'IFSC Code',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'Mobile Number',
          name: 'trusteemobileNo',
          id: 'trusteemobileNo',
          placeholder: 'Mobile Number',
          required: true,
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Email',
          name: 'trusteeemail',
          id: 'trusteeemail',
          placeholder: 'Email',
          required: true,
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Pan Number',
          name: 'trusteepanNo',
          id: 'trusteepanNo',
          placeholder: 'Pan Number',
          required: true,
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Account Type',
          name: 'trusteeaccounttype',
          id: 'trusteeaccounttype',
          placeholder: 'Account Type',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [
            { label: 'Saving', value: 'Saving' },
            { label: 'Current', value: 'Current' },
          ],
        },
      ],
    },
    'Payment Details': {
      showAccordionHeader: true,
      fields: [
        {
          label: 'First Name',
          name: 'payfname',
          id: 'payfname',
          placeholder: 'First Name',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'Last Name',
          name: 'paylname',
          id: 'paylname',
          placeholder: 'Last Name',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'Gender',
          name: 'paygender',
          id: 'paygender',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' },
            { value: 'Transgender', label: 'Transgender' },
          ],
        },
        {
          label: 'DOB',
          name: 'paydob_str',
          id: 'paydob_str',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          showTime: false,
          defaultValue: maxDate,
          required: true,
          component: (props) => (
            <DateTime defaultValue={maxDate} {...props} disabledDate={maxDate} />
          ),
        },
        {
          label: 'Sum Assured',
          name: 'paysa',
          id: 'paysa',
          placeholder: 'Sum Assured',
          type: 'text',
          required: true,
          component: TextInput,
        },
      ],
    },
    'Health Details': {
      showAccordionHeader: true,
      fields: [
        {
          label: 'Height In Ft.',
          placeholder: 'Height In Ft.',
          name: 'HQ01',
          id: 'HQ01',
          type: 'text',
          component: TextInput,
          required: true,
        },
        {
          label: ' Inch.',
          placeholder: 'Inch',
          name: 'HQ02',
          id: 'HQ02',
          type: 'text',
          component: TextInput,
          required: true,
        },
        {
          label: "Weight in Kg's.",
          placeholder: "Weight in Kg's.",
          name: 'HQ03',
          id: 'HQ03',
          type: 'text',
          component: TextInput,
          required: true,
        },

        {
          label: '1.Do you consume or have ever consumed tobacco?',
          name: 'HQ05ans1',
          id: 'HQ05ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ],
          defaultValue: 'no',
        },
        {
          dependentArray: true,
          dependent_on: 'HQ05ans1',
          dependent_value: 'Yes',
          label: 'Tobacco consumed as ',
          name: 'HQ05ans2',
          id: 'HQ05ans2',
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'Pan', value: 'pan' },
            { label: 'Cigarettes', value: 'Cigarettes' },
            { label: 'Bidi', value: 'bidi' },
            { label: 'Gutka', value: 'gutka' },
            { label: 'Cigar', value: 'cigar' },
            { label: 'All of the above', value: 'All of the above' },
          ],
        },
        {
          dependentArray: true,
          dependent_on: 'HQ05ans1',
          dependent_value: 'Yes',
          label: 'Tobacco consumed as ',
          name: 'HQ05ans3',
          id: 'HQ05ans3',
          type: 'select',
          component: SelectInput,
          options: [
            { label: '6-10units', value: '6_to_10units' },
            { label: '>10units', value: 'greater_than_10units' },
            { label: '<5units', value: 'less_than_5units' },
          ],
        },
        {
          dependentArray: true,
          dependent_on: 'HQ05ans1',
          dependent_value: 'Yes',
          label: 'Since how many years',
          placeholder: 'Since how many years',
          name: 'HQ05ans4',
          id: 'HQ05ans4',
          type: 'text',
          component: TextInput,
        },
        {
          label: '2.Do you consume or have ever consumed  Alcohol?',
          name: 'HQ06ans1',
          id: 'HQ05ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          dependentArray: true,
          dependent_on: 'HQ06ans1',
          dependent_value: 'Yes',
          label: 'Alcohol consumed as  ',
          name: 'HQ06ans2',
          id: 'HQ06ans2',
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'Beer(Bottles)', value: 'Beer(Bottles)' },
            { label: 'Wine(Glasses)', value: 'Wine(Glasses)' },
            { label: 'Liquor(Pegs)', value: 'Liquor(Pegs)' },
            { label: 'All of the above', value: 'All of the above' },
          ],
        },
        {
          dependentArray: true,
          dependent_on: 'HQ06ans1',
          dependent_value: 'Yes',
          label: 'Alcohol Quantity',
          name: 'HQ06ans3',
          id: 'HQ06ans3',
          type: 'select',
          component: SelectInput,
          options: [
            { label: '1–2', value: '1–2' },
            { label: '>2', value: 'greater_than_2' },
            { label: 'Occasional', value: 'Occasional' },
          ],
        },
        {
          dependentArray: true,
          dependent_on: 'HQ06ans1',
          dependent_value: 'Yes',
          label: 'Since how many years',
          placeholder: 'Since how many years',
          name: 'HQ06ans4',
          id: 'HQ06ans4',
          type: 'text',
          component: TextInput,
        },
        {
          label: '3.Do you consume or have ever consumed  Narcotics?',
          name: 'HQ07ans1',
          id: 'HQ07ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label:
            '4.Is your occupation associated with any specific hazard or do you take part in activities or have hobbies that could be dangerous in any way ? (eg - occupation - Chemical factory, mines, explosives, radiation, corrosive chemicals j - aviation other than as a fare paying passenger, diving, mountaineering, any form of racing, etc )',
          name: 'HQ09ans1',
          id: 'HQ09ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label:
            '5.Are you employed in the armed, para military or police forces ?(If Yes, please provide Rank, Department/Division, Date of last medical & category after medical exam).',
          name: 'HQ125ans1',
          id: 'HQ125ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '6.Have you lost weight of 10 kgs or more in the last six months?',
          name: 'HQ144ans1',
          id: 'HQ144ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label:
            '7.Family details of the life assured(include parents/sibling) Are any of your family members suffering from /have suffered from/have died of heart disease,Diabetes Mellitus, cancer or any other hereditary/familial disorder, before 55 years of age.if Yes please provide details below.',
          name: 'HQ165ans1',
          id: 'HQ165ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label:
            '8.Have you undergone or been advised to undergo any tests/investigations or any surgery or hospitalized for observation or treatment in the past?',
          name: 'HQ166ans1',
          id: 'HQ166ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(1).Hypertension/ High BP/ high cholesterol',
          name: 'HQ167ans1',
          id: 'HQ167ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(2).Chest Pain/ Heart Attack/ any other heart disease or problem',
          name: 'HQ167ans2',
          id: 'HQ167ans2',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(3).Undergone angioplasty, bypass surgery, heart surgery',
          name: 'HQ167ans3',
          id: 'HQ167ans3',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(4).Diabetes/ High Blood Sugar/ Sugar in Urine',
          name: 'HQ167ans4',
          id: 'HQ167ans4',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(5).Asthma, Tuberculosis or any other respiratory disorder',
          name: 'HQ167ans5',
          id: 'HQ167ans5',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(6).Nervous disorders/ stroke/ paralysis/ epilepsy',
          name: 'HQ167ans6',
          id: 'HQ167ans6',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(7).Any GastroIntestinal disorders like Pancreatitis, Colitis etc.',
          name: 'HQ167ans7',
          id: 'HQ167ans7',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(8).Liver disorders/ Jaundice/ Hepatitis B or C',
          name: 'HQ167ans8',
          id: 'HQ167ans8',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(9).Genitourinary disorders related to kidney, prostate, urinary system',
          name: 'HQ167ans9',
          id: 'HQ167ans9',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(10).HIV infection AIDS or positive test for HIV',
          name: 'HQ167ans10',
          id: 'HQ167ans10',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(11).Any blood disorders like Anaemeia, Thalassemia etc',
          name: 'HQ167ans11',
          id: 'HQ167ans11',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(12).Psychiatric or mental disorders.',
          name: 'HQ167ans12',
          id: 'HQ167ans12',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '9(13).Any other disorder Not mentioned above',
          name: 'HQ167ans13',
          id: 'HQ167ans13',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label:
            '10. Did you have any ailment/injury/accident requiring treatment/medication for more than a week or have you availed leave for more than 5 days on medical grounds in the last two years?',
          name: 'HQ24ans1',
          id: 'HQ24ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label:
            '11.Have you ever suffered/are suffering from or have undergone investigations or treatment for any gynecological complications such as disorders of cervix,uterus,ovaries,breast, breast lump,cyst etc.',
          name: 'HQ168ans1',
          id: 'HQ168ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label: '12.Are you pregnant at present ? ',
          name: 'HQ61ans1',
          id: 'HQ61ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        {
          label:
            '13.With reference to extending coverage of life insurance Group Loan Secure policy of ICICI Prudential, under lender borrower group, you have an option to allow ICICI Prudential to initiate split payment of the claim amounts due to Nominee appointed by you, on the happening of any contingent event, to the extent of outstanding loans in the name of Master policy name. In this regard, the remaining proceeds of the claims due may accordingly be addressed in the name of the Nominee. I hereby provide my consent to allow ICICI Prudential to initiate split payment of the claim amount to Master policy name to the extent of outstanding loan. ',
          name: 'HQ172ans1',
          id: 'HQ172ans1',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
          ],
          defaultValue: 'No',
        },
        // {
        //   label: '14.In the last 3 months have you been tested positive for COVID-19 ',
        //   name: 'CHQ1ans1',
        //   id: 'CHQ1ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label:
        //     '15.In the last 3 months have you been self-isolated with symptoms on medical advice? ',
        //   name: 'CHQ2ans1',
        //   id: 'CHQ2ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label:
        //     '16.In the last 1 month have you been advised to self-isolate due to COVID-19 (excluding mandatory government orders to remain at home) ',
        //   name: 'CHQ3ans1',
        //   id: 'CHQ3ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label:
        //     '17.In the last 1 month did you have persistent cough, fever, raised temperature or been in contact with an individual suspected or confirmed to have COVID-19?',
        //   name: 'CHQ4ans1',
        //   id: 'CHQ4ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label:
        //     '18.Do you work in an occupation, where you have a higher risk to get in close contact with COVID-19 patients or with coronavirus contaminated material',
        //   name: 'CHQ5ans1',
        //   id: 'CHQ5ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label:
        //     '19.Have you or your family members travelled overseas post 1st Jan 2020 OR planned to travel during the next 6 months?',
        //   name: 'CHQ6ans1',
        //   id: 'CHQ6ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label:
        //     '20.In the last 3 months have you or your family members been tested positive for COVID-19?',
        //   name: 'CHQ9ans1',
        //   id: 'CHQ9ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label:
        //     '21.In the last 3 months have you or your family members been self-isolated with symptoms on medical advice?',
        //   name: 'CHQ10ans1',
        //   id: 'CHQ10ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label:
        //     '22.In the last 1 month have you or your family members been advised to self-isolate due to COVID-19 (excluding mandatory government orders to remain at home)?',
        //   name: 'CHQ11ans1',
        //   id: 'CHQ11ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label:
        //     '23. In the last 1 month did you have persistent cough, fever ,sore throat, nausea, vomiting ,diarrhea, difficulty in breathing ,loss of smell and taste any other symptoms of coronavirus (COVID-19) and advised to do a Covid test or you/your family member have been in contact with an individual suspected or confirmed  to have COVID-19? ',
        //   name: 'CHQ20ans1',
        //   id: 'CHQ20ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label:
        //     '24. Do you work in an occupation like health care worker/Corona warrior Include (General Practitioners, Doctors, Hospital Doctors, Surgeons, Therapists, Nurses, Pathologist, paramedics, Pharmacist, Ward helpers, Individuals working in Hospitals/ Clinics having novel coronavirus (SARS-CoV-2/COVID-19) Ward ?)  where you have a higher risk to get in close contact with COVID-19 patients or with coronavirus contaminated material ? ',
        //   name: 'CHQ21ans1',
        //   id: 'CHQ21ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label: '25. Are your currently residing outside of India ',
        //   name: 'CHQ38ans1',
        //   id: 'CHQ38ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label: '26.Have you been vaccinated for COVID19?',
        //   name: 'CHQ30ans1',
        //   id: 'CHQ30ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label:
        //     '27.In the last 1 months have you or any of your family member been self-isolated with symptoms on medical advice?(excluding mandatory government orders to remain at home)',
        //   name: 'CHQ19ans1',
        //   id: 'CHQ19ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label: '28. Have you ever been tested positive for COVID 19  ',
        //   name: 'CHQ14ans1',
        //   id: 'CHQ14ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label: '29. Have you travelled abroad in past 14 days',
        //   name: 'CHQ43ans1',
        //   id: 'CHQ43ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label: '30. Do you intend to travel abroad in next 3 month',
        //   name: 'CHQ56ans1',
        //   id: 'CHQ56ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
        // {
        //   label:
        //     '31. In the last 1 month have you or your family members had a persistent cough, fever, raised temperature or been in contact with an individual suspected or confirmed to have COVID-19?',
        //   name: 'CHQ12ans1',
        //   id: 'CHQ12ans1',
        //   divClasses: 'col-span-full mt-4',
        //   component: CustomRadio,
        //   options: [
        //     { value: 'Yes', label: 'Yes' },
        //     { value: 'No', label: 'No' },
        //   ],
        //   defaultValue: 'No',
        // },
      ],
    },
  };
};

export const formatPartnerPayload = (data, quoteData) => {
  return {
    appNo: '',
    advisorCode: '',
    source: 'NIVESHPAYBOL',
    sourceKey: 'NIVESHPAYBOLKEY',
    salesDataReqd: 'N',
    dependentFlag: 'N',
    sourceTransactionId: '100012301',
    sourceOfFund: 'Salary',
    sourceOfFundDesc: '',
    partnerThankYouUrl: '',
    clientId: '',
    uidId: '37608',
    proposerInfos: {
      frstNm: data?.prosfrstNm,
      lstNm: data?.proslstNm,
      mrtlSts: data?.prosmarital_status,
      dob: dayjs(data?.prosdob).format('DD-MMM-YYYY'),
      gender: data?.prosgender,
      isStaff: data?.staff === true ? '1' : '0',
      mobNo: data?.prosmobNo,
      orgType: data?.prosorgType,
      orgTypeDesc: data?.prosorgTypeDesc,
      relationWithLa: data?.prosrelationWithLa,
      sharePortfolio: 'No',
      maturityAmnt: data?.prosmaturityAmnt,
      desig: '',
      fathersName: data?.prosfathersName,
      customerInvestmentProfile: '',
      mothersName: data?.prosmothersName,
      spouseName: data?.prosspouseName,
      ckycNumber: data?.ckycNumber,
      kycDoc: {
        idPrf: data?.idPrf,
        addPrf: data?.addPrf,
        agePrf: data?.agePrf,
        itPrf: data?.itPrf,
        incomePrf: data?.incomePrf,
        lddIdOthrDesc: '',
        lddIdNumber: '',
        lddIdExpiryDate: '',
      },
      currentlyInvestigated: 'No',
      investigationDtls: '',
      comunctnAddress: {
        pincode: data?.compincode,
        landmark: data?.comlandmark,
        state: data?.comstate,
        line1: data?.comline1,
        line3: data?.comline3,
        city: data?.comcity,
        country: data?.comcountry,
        line2: data?.comline2,
      },
      prmntAddress: {
        pincode: data?.peraddpincode,
        landmark: data?.peraddlandmark,
        state: data?.peraddstate,
        line1: data?.peraddline1,
        line3: data?.peraddline3,
        city: data?.peraddcity,
        country: data?.peraddcountry,
        line2: data?.peraddline2,
      },
      education: data?.proseducation,
      yrInService: '',
      lifeStageDetails: {
        calculator: '',
        goals: '',
        lifeStage: '',
        investmentPreferance: '',
        subGoals: '',
      },
      occ: 'SPVT',
      occDesc: '',
      myProf: '',
      indsType: '',
      indsTypeDesc: '',
      indsTypeQuestion: 'N',
      nameOfOrg: data?.prosnameOfOrg,
      nameOfOrgDesc: data?.prosnameOfOrgDesc,
      objective: 'Protection',
      annIncme: data?.prosannIncme ?? '0',
      panNo: data?.prospanNo,
      aadharCardNo: data?.prosaadharCardNo,
      photoSubmitted: 'No',
      nationality: data?.prosnationality,
      email: data?.prosemail,
      pltclyExpsd: 'No',
      objective_others: '',
      landLnNo: '',
      stdNo: '',
      rural_lan: '',
      rural_policyno: '',
      rural_policyamt: '',
      insuranceInfos: [],
      rstSts: data?.prosrstSts,
    },
    lifeAssrdInfos: {
      frstNm: data?.prosrelationWithLa === 'Self' ? data?.prosfrstNm : data?.lifefrstNm,
      lstNm: data?.prosrelationWithLa === 'Self' ? data?.proslstNm : data?.lifelstNm,
      dob: dayjs(data?.prosrelationWithLa === 'Self' ? data?.prosdob : data?.lifedob).format(
        'DD-MMM-YYYY',
      ),
      mrtlSts: data?.prosrelationWithLa === 'Self' ? data?.prosmarital_status : data?.lifemrtlSts,
      gender: data?.prosrelationWithLa === 'Self' ? data?.prosgender : data?.lifegender,
      pinCode: data?.prosrelationWithLa === 'Self' ? data?.peraddpincode : data?.lifepincode,
      mobNo: data?.prosrelationWithLa === 'Self' ? data?.prosmobNo : data?.lifemobNo,
      landLnNo: data?.lifelandLnNo,
      stdNo: data?.lifestdNo,
      nationality:
        data?.prosrelationWithLa === 'Self' ? data?.prosnationality : data?.lifenationality,
      aadharCardNo:
        data?.prosrelationWithLa === 'Self' ? data?.prosaadharCardNo : data?.lifeaadharCardNo,
      education: data?.prosrelationWithLa === 'Self' ? data?.proseducation : data?.lifeeducation,
      kycDoc: {
        idPrf: data?.prosrelationWithLa === 'Self' ? data?.idPrf : data?.lifeidPrf,
        addPrf: data?.prosrelationWithLa === 'Self' ? data?.addPrf : data?.lifeaddPrf,
        agePrf: data?.prosrelationWithLa === 'Self' ? data?.agePrf : data?.lifeagePrf,
        itPrf: data?.prosrelationWithLa === 'Self' ? data?.itPrf : data?.lifeitPrf,
      },
      desig: '',
      occ: 'SPVT',
      occDesc: '',
      myProf: '',
      indsTypeQuestion: 'N',
      indsType: '',
      indsTypeDesc: '',
      nameOfOrg: data?.prosrelationWithLa === 'Self' ? data?.prosorgType : data?.lifeorgType,
      nameOfOrgDesc:
        data?.prosrelationWithLa === 'Self' ? data?.prosorgTypeDesc : data?.lifeorgTypeDesc,
      rstSts: data?.prosrelationWithLa === 'Self' ? data?.prosrstSts : data?.prosrstSts,
      annIncme: data?.prosrelationWithLa === 'Self' ? data?.prosannIncme : data?.lifeanuuIncme,
    },
    healthDetails: [
      {
        answer1: data?.HQ01,
        code: 'HQ01',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer1: data?.HQ02,
        code: 'HQ02',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer1: data?.HQ03,
        code: 'HQ03',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer1: data?.HQ05ans1 === 'Yes' ? data?.HQ05ans1 : 'No',
        code: 'HQ05',
        answer3: data?.HQ05ans1 === 'Yes' ? data?.HQ05ans3 : '',
        answer2: data?.HQ05ans1 === 'Yes' ? data?.HQ05ans2 : '',
        answer4: data?.HQ05ans1 === 'Yes' ? data?.HQ05ans4 : '',
      },
      {
        answer1: data?.HQ06ans1 === 'Yes' ? data?.HQ06ans1 : 'No',
        code: 'HQ06',
        answer3: data?.HQ06ans1 === 'Yes' ? data?.HQ06ans3 : '',
        answer2: data?.HQ06ans1 === 'Yes' ? data?.HQ06ans2 : '',
        answer4: data?.HQ06ans1 === 'Yes' ? data?.HQ06ans4 : '',
      },
      {
        answer1: data?.HQ07ans1 === 'Yes' ? data?.HQ07ans1 : 'No',
        code: 'HQ07',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer1: data?.HQ09ans1 === 'Yes' ? data?.HQ09ans1 : 'No',
        code: 'HQ09',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer1: data?.HQ125ans1 === 'Yes' ? data?.HQ125ans1 : 'No',
        code: 'HQ125',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer1: data?.HQ144ans1 === 'Yes' ? data?.HQ144ans1 : 'No',
        code: 'HQ144',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer1: data?.HQ165ans1 === 'Yes' ? data?.HQ165ans1 : 'No',
        code: 'HQ165',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer1: data?.HQ166ans1 === 'Yes' ? data?.HQ166ans1 : 'No',
        code: 'HQ166',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer15: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans15 : 'No',
        answer1: data?.HQ167ans1 ?? 'No',
        code: 'HQ167',
        answer3: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans3 : 'No',
        answer2: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans2 : 'No',
        answer4: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans4 : 'No',
        answer5: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans5 : 'No',
        answer6: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans6 : 'No',
        answer7: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans7 : 'No',
        answer8: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans8 : 'No',
        answer9: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans9 : 'No',
        answer10: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans10 : 'No',
        answer11: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans11 : 'No',
        answer12: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans12 : 'No',
        answer13: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans13 : 'No',
        answer14: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans14 : 'No',
      },
      {
        answer1: 'No',
        code: 'HQ21',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer1: data?.HQ24ans1 === 'Yes' ? data?.HQ24ans1 : 'No',
        code: 'HQ24',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer1: data?.HQ168ans1 === 'Yes' ? data?.HQ168ans1 : 'No',
        code: 'HQ168',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer1: data?.HQ61ans1 === 'Yes' ? data?.HQ61ans1 : 'No',
        code: 'HQ61',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      {
        answer1: data?.HQ172ans1 === 'Yes' ? data?.HQ172ans1 : 'No',
        code: 'HQ172',
        answer3: '',
        answer2: '',
        answer4: '',
      },
      // {
      //   code: 'CHQ1',
      //   answer1: data?.CHQ1ans1 === 'Yes' ? data?.CHQ1ans1 : 'No',
      // },
      // {
      //   code: 'CHQ2',
      //   answer1: data?.CHQ2ans1 === 'Yes' ? data?.CHQ2ans1 : 'No',
      // },
      // {
      //   code: 'CHQ3',
      //   answer1: data?.CHQ3ans1 === 'Yes' ? data?.CHQ3ans1 : 'No',
      // },
      // {
      //   code: 'CHQ4',
      //   answer1: data?.CHQ4ans1 === 'Yes' ? data?.CHQ4ans1 : 'No',
      // },
      // {
      //   code: 'CHQ5',
      //   answer1: data?.CHQ5ans1 === 'Yes' ? data?.CHQ5ans1 : 'No',
      // },
      // {
      //   code: 'CHQ6',
      //   answer1: data?.CHQ6ans1 === 'Yes' ? data?.CHQ6ans1 : 'No',
      // },
      // {
      //   code: 'CHQ9',
      //   answer1: data?.CHQ9ans1 === 'Yes' ? data?.CHQ9ans1 : 'No',
      // },
      // {
      //   code: 'CHQ10',
      //   answer1: data?.CHQ10ans1 == 'Yes' ? 'Yes' : 'No',
      // },
      // {
      //   code: 'CHQ11',
      //   answer1: data?.CHQ11ans1 == 'Yes' ? 'Yes' : 'No',
      // },
      // {
      //   code: 'CHQ21',
      //   answer1: data?.CHQ21ans1 == 'Yes' ? 'Yes' : 'No',
      // },
      // {
      //   code: 'CHQ20',
      //   answer1: data?.CHQ20ans1 == 'Yes' ? 'Yes' : 'No',
      // },
      // {
      //   code: 'CHQ38',
      //   answer1: data?.CHQ38ans1 == 'Yes' ? 'Yes' : 'No',
      // },
      // {
      //   code: 'CHQ30',
      //   answer1: data?.CHQ30ans1 == 'Yes' ? 'Yes' : 'No',
      // },
      // {
      //   code: 'CHQ19',
      //   answer1: data?.CHQ19ans1 == 'Yes' ? 'Yes' : 'No',
      // },
      // {
      //   code: 'CHQ14',
      //   answer1: data?.CHQ14ans1 == 'Yes' ? 'Yes' : 'No',
      // },
      // {
      //   code: 'CHQ43',
      //   answer1: data?.CHQ43ans1 == 'Yes' ? 'Yes' : 'No',
      // },
      // {
      //   code: 'CHQ56',
      //   answer1: data?.CHQ56ans1 == 'Yes' ? 'Yes' : 'No',
      // },
      // {
      //   code: 'CHQ12',
      //   answer1: data?.CHQ12ans1 == 'Yes' ? 'Yes' : 'No',
      // },
    ],
    productSelection: {
      CIBenefit: quoteData?.product?.Product?.CIBenefit,
      premiumPayingFrequency: quoteData?.product?.Product?.ModeOfPayment,
      policyTerm: JSON.parse(quoteData?.product?.Product?.Term),
      premiumPayingTerm: quoteData?.product?.Product?.PremiumPaymentTerm,
      sumAssured: quoteData?.PremiumSummary?.DeathBenefit,
      productType: quoteData?.product?.Product?.ProductType,
      productName: quoteData?.product?.Product?.productName,
      productId: quoteData?.product?.Product?.ProductCode,
      LifeCoverOption: quoteData?.PremiumSummary?.LifeCoverOption,
      lumpsumPercentage: quoteData?.PremiumSummary?.LumpsumPercentage,
      premiumpaymentoption: quoteData?.PremiumSummary?.PremiumPaymentOption,
      payoutTerm: JSON.stringify(quoteData?.PremiumSummary?.PayoutTerm),
      option: quoteData?.PremiumSummary?.DeathBenefitOption,
      tobacco: quoteData?.PremiumSummary?.Tobacco,
      aDHB: quoteData?.PremiumSummary?.ADHB,
      iPSDiscount: quoteData?.PremiumSummary?.IPSDiscount,
    },
    mwpaOpted: 'No',
    mwpaBenefit: '',
    nomineeInfos: {
      frstNm: data?.nomfrstNm,
      relationship: data?.nomrelationship,
      gender: data?.nomgender,
      dob: dayjs(data?.nomdob).format('DD-MMM-YYYY'),
      lstNm: data?.nomlstNm,
    },
    beneficiaryDtls: [
      {
        frstNm: data?.benfrstNm,
        lstNm: data?.benlstNm,
        relationWithLa: data?.benrelationship,
        shareOfBenefit: data?.benshareOfBenefit,
        dob: dayjs(data?.bendob).format('DD-MMM-YYYY'),
      },
    ],
    trusteeDtls: [
      {
        name: data?.trusteename,
        dob: dayjs(data?.trusteedob).format('DD-MMM-YYYY'),
        relationWithLa: data?.trusteerelationWithLa,
        address: data?.trusteeaddress,
        city: data?.trusteecity,
        pincode: data?.trusteepincode,
        state: data?.trusteestate,
        trusteeNameofBankAccountHolder: data?.trusteeNameofBankAccountHolder,
        trusteeBankAccountNumber: data?.trusteeBankAccountNumber,
        trusteeaccounttype: data?.trusteeaccounttype,
        trusteeIFSC: data?.trusteeIFSC,
        panNo: data?.trusteepanNo,
        mobileNo: data?.trusteemobileNo,
        email: data?.trusteeemail,
        salutation: 'Mr',
      },
    ],
    eiaDetails: {
      isEIAOpted: 'No',
      eiaInsuranceRepository: 'CAMSRep',
      EIAAccountNumber: '',
    },
    paymentData: {
      pincode: data?.peraddpincode,
      extraAtsInfo: {
        fname: data?.payfname,
        lname: data?.paylname,
        dob_str: dayjs(data?.paydob_str).format('DD-MMM-YYYY'),
        gender: data?.paygender,
        sa: JSON.parse(data?.paysa ?? ''),
      },
    },
    otpDetails: {
      otpReqCreatedTime: '16-09-22 10:31:51',
      otpReqUpdatedTime: '16-09-22 10:31:51',
      otpReqOtpNo: null,
      otpReqStatus: 'Verified',
      otpReqExpiryTimeInMin: '30',
    },
  };
};

export const formatInitialValue = (data) => {
  return {
    // appNo: '',
    // advisorCode: '',
    // source: 'NIVESHPAYBOL',
    // sourceKey: 'NIVESHPAYBOLKEY',
    // salesDataReqd: 'N',
    // dependentFlag: 'N',
    // sourceTransactionId: '100012301',
    // sourceOfFund: 'Salary',
    // sourceOfFundDesc: '',
    // partnerThankYouUrl: '',
    // clientId: '',
    // uidId: '37608',
    // proposerInfos: {
    prosfrstNm: data?.frstNm,
    proslstNm: data?.lstNm,
    //   prosmarital_status: data?.mrtlSts,
    //   dob: dayjs(data?.prosdob).format('DD-MMM-YYYY'),
    //   gender: data?.prosgender,
    //   isStaff: data?.staff === true ? '1' : '0',
    //   mobNo: data?.prosmobNo,
    //   orgType: data?.prosorgType,
    //   orgTypeDesc: data?.prosorgTypeDesc,
    //   relationWithLa: data?.prosrelationWithLa,
    //   sharePortfolio: 'No',
    //   maturityAmnt: data?.prosmaturityAmnt,
    //   desig: '',
    //   fathersName: data?.prosfathersName,
    //   customerInvestmentProfile: '',
    //   mothersName: data?.prosmothersName,
    //   spouseName: data?.prosspouseName,
    //   ckycNumber: data?.ckycNumber,
    //   kycDoc: {
    //     idPrf: data?.idPrf,
    //     addPrf: data?.addPrf,
    //     agePrf: data?.agePrf,
    //     itPrf: data?.itPrf,
    //     incomePrf: data?.incomePrf,
    //     lddIdOthrDesc: '',
    //     lddIdNumber: '',
    //     lddIdExpiryDate: '',
    //   },
    //   currentlyInvestigated: 'No',
    //   investigationDtls: '',
    //   comunctnAddress: {
    //     pincode: data?.compincode,
    //     landmark: data?.comlandmark,
    //     state: data?.comstate,
    //     line1: data?.comline1,
    //     line3: data?.comline3,
    //     city: data?.comcity,
    //     country: data?.comcountry,
    //     line2: data?.comline2,
    //   },
    //   prmntAddress: {
    //     pincode: data?.peraddpincode,
    //     landmark: data?.peraddlandmark,
    //     state: data?.peraddstate,
    //     line1: data?.peraddline1,
    //     line3: data?.peraddline3,
    //     city: data?.peraddcity,
    //     country: data?.peraddcountry,
    //     line2: data?.peraddline2,
    //   },
    //   education: data?.proseducation,
    //   yrInService: '',
    //   lifeStageDetails: {
    //     calculator: '',
    //     goals: '',
    //     lifeStage: '',
    //     investmentPreferance: '',
    //     subGoals: '',
    //   },
    //   occ: 'SPVT',
    //   occDesc: '',
    //   myProf: '',
    //   indsType: '',
    //   indsTypeDesc: '',
    //   indsTypeQuestion: 'N',
    //   nameOfOrg: data?.prosnameOfOrg,
    //   nameOfOrgDesc: data?.prosnameOfOrgDesc,
    //   objective: 'Protection',
    //   annIncme: '1000000',
    //   panNo: data?.prospanNo,
    //   aadharCardNo: data?.prosaadharCardNo,
    //   photoSubmitted: 'No',
    //   nationality: data?.prosnationality,
    //   email: data?.prosemail,
    //   pltclyExpsd: 'No',
    //   objective_others: '',
    //   landLnNo: '',
    //   stdNo: '',
    //   rural_lan: '',
    //   rural_policyno: '',
    //   rural_policyamt: '',
    //   insuranceInfos: [],
    //   rstSts: data?.prosrstSts,
    // },
    // lifeAssrdInfos: {
    //   frstNm: data?.lifefrstNm,
    //   lstNm: data?.lifelstNm,
    //   dob: dayjs(data?.lifedob).format('DD-MMM-YYYY'),
    //   mrtlSts: data?.lifemrtlSts,
    //   gender: data?.lifegender,
    //   pinCode: data?.lifepincode,
    //   mobNo: data?.lifemobNo,
    //   landLnNo: data?.lifelandLnNo,
    //   stdNo: data?.lifestdNo,
    //   nationality: data?.lifenationality,
    //   aadharCardNo: data?.lifeaadharCardNo,
    //   education: data?.lifeeducation,
    //   kycDoc: {
    //     idPrf: data?.lifeidPrf,
    //     addPrf: data?.lifeaddPrf,
    //     agePrf: data?.lifeagePrf,
    //     itPrf: data?.lifeitPrf,
    //   },
    //   desig: '',
    //   occ: 'SPVT',
    //   occDesc: '',
    //   myProf: '',
    //   indsTypeQuestion: 'N',
    //   indsType: '',
    //   indsTypeDesc: '',
    //   nameOfOrg: data?.lifeorgType,
    //   nameOfOrgDesc: data?.lifeorgTypeDesc,
    //   rstSts: data?.prosrstSts,
    //   annIncme: data?.lifeanuuIncme,
    // },
    // healthDetails: [
    //   {
    //     answer1: data?.HQ01,
    //     code: 'HQ01',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer1: data?.HQ02,
    //     code: 'HQ02',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer1: data?.HQ03,
    //     code: 'HQ03',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer1: data?.HQ05ans1 === 'Yes' ? data?.HQ05ans1 : 'No',
    //     code: 'HQ05',
    //     answer3: data?.HQ05ans1 === 'Yes' ? data?.HQ05ans3 : '',
    //     answer2: data?.HQ05ans1 === 'Yes' ? data?.HQ05ans2 : '',
    //     answer4: data?.HQ05ans1 === 'Yes' ? data?.HQ05ans4 : '',
    //   },
    //   {
    //     answer1: data?.HQ06ans1 === 'Yes' ? data?.HQ06ans1 : 'No',
    //     code: 'HQ06',
    //     answer3: data?.HQ06ans1 === 'Yes' ? data?.HQ06ans3 : '',
    //     answer2: data?.HQ06ans1 === 'Yes' ? data?.HQ06ans2 : '',
    //     answer4: data?.HQ06ans1 === 'Yes' ? data?.HQ06ans4 : '',
    //   },
    //   {
    //     answer1: data?.HQ07ans1 === 'Yes' ? data?.HQ07ans1 : 'No',
    //     code: 'HQ07',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer1: data?.HQ09ans1 === 'Yes' ? data?.HQ09ans1 : 'No',
    //     code: 'HQ09',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer1: data?.HQ125ans1 === 'Yes' ? data?.HQ125ans1 : 'No',
    //     code: 'HQ125',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer1: data?.HQ144ans1 === 'Yes' ? data?.HQ144ans1 : 'No',
    //     code: 'HQ144',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer1: data?.HQ165ans1 === 'Yes' ? data?.HQ165ans1 : 'No',
    //     code: 'HQ165',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer1: data?.HQ166ans1 === 'Yes' ? data?.HQ166ans1 : 'No',
    //     code: 'HQ166',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer15: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans15 : 'No',
    //     answer1: data?.HQ167ans1 ?? 'No',
    //     code: 'HQ167',
    //     answer3: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans3 : 'No',
    //     answer2: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans2 : 'No',
    //     answer4: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans4 : 'No',
    //     answer5: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans5 : 'No',
    //     answer6: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans6 : 'No',
    //     answer7: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans7 : 'No',
    //     answer8: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans8 : 'No',
    //     answer9: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans9 : 'No',
    //     answer10: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans10 : 'No',
    //     answer11: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans11 : 'No',
    //     answer12: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans12 : 'No',
    //     answer13: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans13 : 'No',
    //     answer14: data?.HQ167ans1 === 'Yes' ? data?.HQ167ans14 : 'No',
    //   },
    //   {
    //     answer1: 'No',
    //     code: 'HQ21',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer1: data?.HQ24ans1 === 'Yes' ? data?.HQ24ans1 : 'No',
    //     code: 'HQ24',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer1: data?.HQ168ans1 === 'Yes' ? data?.HQ168ans1 : 'No',
    //     code: 'HQ168',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer1: data?.HQ61ans1 === 'Yes' ? data?.HQ61ans1 : 'No',
    //     code: 'HQ61',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    //   {
    //     answer1: data?.HQ172ans1 === 'Yes' ? data?.HQ172ans1 : 'No',
    //     code: 'HQ172',
    //     answer3: '',
    //     answer2: '',
    //     answer4: '',
    //   },
    // {
    //   code: 'CHQ1',
    //   answer1: data?.CHQ1ans1 === 'Yes' ? data?.CHQ1ans1 : 'No',
    // },
    // {
    //   code: 'CHQ2',
    //   answer1: data?.CHQ2ans1 === 'Yes' ? data?.CHQ2ans1 : 'No',
    // },
    // {
    //   code: 'CHQ3',
    //   answer1: data?.CHQ3ans1 === 'Yes' ? data?.CHQ3ans1 : 'No',
    // },
    // {
    //   code: 'CHQ4',
    //   answer1: data?.CHQ4ans1 === 'Yes' ? data?.CHQ4ans1 : 'No',
    // },
    // {
    //   code: 'CHQ5',
    //   answer1: data?.CHQ5ans1 === 'Yes' ? data?.CHQ5ans1 : 'No',
    // },
    // {
    //   code: 'CHQ6',
    //   answer1: data?.CHQ6ans1 === 'Yes' ? data?.CHQ6ans1 : 'No',
    // },
    // {
    //   code: 'CHQ9',
    //   answer1: data?.CHQ9ans1 === 'Yes' ? data?.CHQ9ans1 : 'No',
    // },
    // {
    //   code: 'CHQ10',
    //   answer1: data?.CHQ10ans1 == 'Yes' ? 'Yes' : 'No',
    // },
    // {
    //   code: 'CHQ11',
    //   answer1: data?.CHQ11ans1 == 'Yes' ? 'Yes' : 'No',
    // },
    // {
    //   code: 'CHQ21',
    //   answer1: data?.CHQ21ans1 == 'Yes' ? 'Yes' : 'No',
    // },
    // {
    //   code: 'CHQ20',
    //   answer1: data?.CHQ20ans1 == 'Yes' ? 'Yes' : 'No',
    // },
    // {
    //   code: 'CHQ38',
    //   answer1: data?.CHQ38ans1 == 'Yes' ? 'Yes' : 'No',
    // },
    // {
    //   code: 'CHQ30',
    //   answer1: data?.CHQ30ans1 == 'Yes' ? 'Yes' : 'No',
    // },
    // {
    //   code: 'CHQ19',
    //   answer1: data?.CHQ19ans1 == 'Yes' ? 'Yes' : 'No',
    // },
    // {
    //   code: 'CHQ14',
    //   answer1: data?.CHQ14ans1 == 'Yes' ? 'Yes' : 'No',
    // },
    // {
    //   code: 'CHQ43',
    //   answer1: data?.CHQ43ans1 == 'Yes' ? 'Yes' : 'No',
    // },
    // {
    //   code: 'CHQ56',
    //   answer1: data?.CHQ56ans1 == 'Yes' ? 'Yes' : 'No',
    // },
    // {
    //   code: 'CHQ12',
    //   answer1: data?.CHQ12ans1 == 'Yes' ? 'Yes' : 'No',
    // },
    //   ],
    //   productSelection: {
    //     CIBenefit: quoteData?.product?.Product?.CIBenefit,
    //     premiumPayingFrequency: quoteData?.product?.Product?.ModeOfPayment,
    //     policyTerm: JSON.parse(quoteData?.product?.Product?.Term),
    //     premiumPayingTerm: quoteData?.product?.Product?.PremiumPaymentTerm,
    //     sumAssured: quoteData?.PremiumSummary?.DeathBenefit,
    //     productType: quoteData?.product?.Product?.ProductType,
    //     productName: quoteData?.product?.Product?.productName,
    //     productId: quoteData?.product?.Product?.ProductCode,
    //     LifeCoverOption: quoteData?.PremiumSummary?.LifeCoverOption,
    //     lumpsumPercentage: quoteData?.PremiumSummary?.LumpsumPercentage,
    //     premiumpaymentoption: quoteData?.PremiumSummary?.PremiumPaymentOption,
    //     option: quoteData?.PremiumSummary?.DeathBenefitOption,
    //     tobacco: quoteData?.PremiumSummary?.Tobacco,
    //     aDHB: quoteData?.PremiumSummary?.ADHB,
    //     iPSDiscount: quoteData?.PremiumSummary?.IPSDiscount,
    //   },
    //   mwpaOpted: 'No',
    //   mwpaBenefit: '',
    //   nomineeInfos: {
    //     frstNm: data?.nomfrstNm,
    //     relationship: data?.nomrelationship,
    //     gender: data?.nomgender,
    //     dob: dayjs(data?.nomdob).format('DD-MMM-YYYY'),
    //     lstNm: data?.nomlstNm,
    //   },
    //   beneficiaryDtls: [
    //     {
    //       frstNm: data?.benfrstNm,
    //       lstNm: data?.benlstNm,
    //       relationWithLa: data?.benrelationship,
    //       shareOfBenefit: data?.benshareOfBenefit,
    //       dob: dayjs(data?.bendob).format('DD-MMM-YYYY'),
    //     },
    //   ],
    //   trusteeDtls: [
    //     {
    //       name: data?.trusteename,
    //       dob: dayjs(data?.trusteedob).format('DD-MMM-YYYY'),
    //       relationWithLa: data?.trusteerelationWithLa,
    //       address: data?.trusteeaddress,
    //       city: data?.trusteecity,
    //       pincode: data?.trusteepincode,
    //       state: data?.trusteestate,
    //       trusteeNameofBankAccountHolder: data?.trusteeNameofBankAccountHolder,
    //       trusteeBankAccountNumber: data?.trusteeBankAccountNumber,
    //       trusteeaccounttype: data?.trusteeaccounttype,
    //       trusteeIFSC: data?.trusteeIFSC,
    //       panNo: data?.trusteepanNo,
    //       mobileNo: data?.trusteemobileNo,
    //       email: data?.trusteeemail,
    //       salutation: 'Mr',
    //     },
    //   ],
    //   eiaDetails: {
    //     isEIAOpted: 'No',
    //     eiaInsuranceRepository: 'CAMSRep',
    //     EIAAccountNumber: '',
    //   },
    //   paymentData: {
    //     pincode: data?.peraddpincode,
    //     extraAtsInfo: {
    //       fname: data?.payfname,
    //       lname: data?.paylname,
    //       dob_str: dayjs(data?.paydob_str).format('DD-MMM-YYYY'),
    //       gender: data?.paygender,
    //       sa: JSON.parse(data?.paysa ?? ''),
    //     },
    //   },
    //   otpDetails: {
    //     otpReqCreatedTime: '16-09-22 10:31:51',
    //     otpReqUpdatedTime: '16-09-22 10:31:51',
    //     otpReqOtpNo: null,
    //     otpReqStatus: 'Verified',
    //     otpReqExpiryTimeInMin: '30',
    //   },
  };
};
