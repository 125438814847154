/** external deps */
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';

/** internal deps */

import { Logout } from 'service/api-service';
import { getPermission } from 'utils';

import { Context } from 'store/context';
import { useMyContext } from 'store/modal';
import { getHrSidebarContent, getHrUserSidebar, getSidebarContent } from './content';

const useSidebar = ({ permissions = [], dashboardType }) => {
  let CONTENT;
  const { user } = useContext(Context);
  const { setMyStateValue } = useMyContext();
  const [sidebarItems, setSidebarItems] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    // const fetchData = async () => {
    //   const resp = await getLeaveRequestCount();
    //   const {
    //     data: { data },
    //   } = resp;
    //   localStorage.setItem("messageCount", data);
    // };
    // fetchData();
  }, []);

  const messageCount = localStorage.getItem('messageCount');

  const items = [
    {
      label: (
        <Link to='/dashboard' className='p-4'>
          Profile
        </Link>
      ),
      key: '0',
    },
    {
      label: (
        <Link to='/login' className='p-4' onClick={Logout}>
          Logout
        </Link>
      ),
      key: '1',
    },
  ];

  if (user?.position === 'HR') {
    CONTENT = getHrUserSidebar({ setMyStateValue, messageCount, user });
  } else if (dashboardType === 'HR') {
    CONTENT = getHrSidebarContent({ messageCount, user });
  } else {
    CONTENT = getSidebarContent({ setMyStateValue, messageCount });
  }

  const getPermittedtabs = () => {
    const interData = CONTENT?.menu?.map((item) => {
      // set public:true for showing the menu link to everyone
      if (item?.public) {
        return item;
      }
      if (item?.submenu) {
        const interData = item.submenu?.map((subMenu) => {
          if (subMenu?.key === 'raise_ticket') {
            if (user?.user_type === 'client') {
              return undefined;
            } else {
              return subMenu;
            }
          } else if (getPermission(permissions, subMenu?.name)?.read) {
            return subMenu;
          }
          return undefined;
        });
        return {
          ...item,
          submenu: interData.filter((element) => {
            return element !== undefined;
          }),
        };
      }
      if (getPermission(permissions, item?.name)?.read) {
        return item;
      }
      return undefined;
    });

    const removedUndefined = interData.filter((item) => {
      return item !== undefined;
    });

    return removedUndefined.filter((item) => {
      if (item?.submenu) {
        return item.submenu.length > 0;
      } else {
        return item;
      }
    });
  };

  useEffect(() => {
    const menu = getPermittedtabs();
    setSidebarItems([{ ...CONTENT, menu }]);
    //if only hr module access
    if (!pathname.includes('hr') && menu[0]?.key === 'hr') navigate('/hr/dashboard');
  }, [permissions]);

  return {
    sidebarItems,
    items,
    CONTENT,
  };
};

export default useSidebar;
