import Request from '../client';

export const expenseTypeManagement = async ({ data = undefined, method = 'get', id } = {}) => {
  const resp = await Request({
    method,
    API: method === 'delete' || method === 'patch' ? `expense/types?id=${id}` : `expense/types`,
    data,
  });
  return resp;
};

export const expenseManagement = async ({
  data = undefined,
  method = 'get',
  id,
  start = '',
  end = '',
  page = 1,
} = {}) => {
  const resp = await Request({
    method,
    API:
      method === 'delete' || method === 'patch'
        ? `expense/?id=${id}`
        : `expense/?start_date=${start || ''}&end_date=${end || ''}&page=${page}`,
    data,
  });
  return resp;
};
