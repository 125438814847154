import { Tag } from 'antd';
import { DefaultUser } from 'assets';
import {
  CustomFieldArray,
  CustomTextInput,
  DateTime,
  SearchAPI,
  SelectInput,
  TextInput,
  User,
} from 'components';
import { allowanceVariant, getAllowance, getTax } from 'service/api-service';

const VariantOptions = [
  { label: 'Allowance', value: 'a' },
  { label: 'Deduction', value: 'd' },
];

const AmountType = [
  { label: 'Fixed', value: 'f' },
  { label: 'Percentage', value: 'p' },
];

/* PAYROLL SETUP */
export const payrollColumns = [
  {
    key: 'user',
    title: 'Employee',
    dataIndex: 'user',
    render: (data) => {
      return <User image={data?.image} name={data?.name} />;
    },
  },
  {
    key: 'b_salary',
    title: 'Basic Salary',
    dataIndex: 'b_salary',
  },
  {
    key: 'am_leave',
    title: 'Leave Amount',
    dataIndex: 'am_leave',
  },
  {
    key: 'allow_ded',
    title: 'Allowance',
    dataIndex: 'allow_ded',
    render: (data) => {
      const resArr = data.filter((item) => item.type === 'a');
      if (resArr.length > 0) {
        return resArr.map((item) => (
          <Tag className='max-w-[100px] truncate' color={'success'}>
            {item?.name || '\u2014'}
          </Tag>
        ));
      } else {
        return '\u2014';
      }
    },
  },
  {
    key: 'allow_ded',
    title: 'Deduction',
    dataIndex: 'allow_ded',
    render: (data) => {
      const resArr = data.filter((item) => item.type === 'd');
      if (resArr.length > 0) {
        return resArr.map((item) => (
          <Tag className='max-w-[100px] truncate' color={'error'}>
            {item?.name || '\u2014'}
          </Tag>
        ));
      } else {
        return '\u2014';
      }
    },
  },
  {
    key: 'taxes',
    title: 'Taxes',
    dataIndex: 'taxes',
    render: (data) => {
      return data.map((item) => <Tag color={'error'}>{item?.name || '\u2014'}</Tag>);
    },
  },
];

export const addBasicFields = {
  'Basic Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Employee Name',
        name: 'user',
        id: 'user',
        placeholder: 'Employee Name',
        type: 'search',
        required: true,
        component: SearchAPI,
      },
      {
        label: 'Basic Salary',
        name: 'b_salary',
        id: 'b_salary',
        placeholder: 'Basic Salary',
        type: 'number',
        required: true,
        // component: TextInput,
        component: CustomTextInput,
        customOnChange: ({ field, value, form }) => {
          form.setFieldValue('am_leave', (value / 30).toFixed(2));
        },
      },
      {
        label: 'Allowance & Deduction',
        name: 'allow_ded',
        id: 'allow_ded',
        placeholder: 'Select Allowance & Deduction',
        type: 'select',
        component: SelectInput,
        options: [],
        checkbox: true,
        isMulti: true,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        dependantFetchOptions: async () => {
          const resp = await getAllowance();
          if (resp?.data?.status) {
            return resp?.data?.data.map((item) => ({
              id: item.id,
              name: item.name,
            }));
          }
        },
      },
      {
        label: 'Paid Leave Amount',
        name: 'am_leave',
        id: 'am_leave',
        placeholder: 'Paid Leave Amount',
        type: 'number',
        component: TextInput,
        required: true,
      },
      {
        label: 'Taxes',
        name: 'taxes',
        id: 'taxes',
        placeholder: 'Select Taxes',
        type: 'select',
        component: SelectInput,
        options: [],
        checkbox: true,
        isMulti: true,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        dependantFetchOptions: async () => {
          const resp = await getTax();
          if (resp?.data?.status) {
            return resp?.data?.data.map((item) => ({
              id: item.id,
              name: item.name,
            }));
          }
        },
      },
    ],
  },
};

/* SALARY SHEET */

export const sheetColumns = [
  {
    key: 'employee',
    title: 'Employee',
    dataIndex: 'employee',
    render: (_, data) => {
      return (
        <div className='flex items-center'>
          <img
            src={data?.image || DefaultUser}
            className='h-6 w-6 mr-2'
            alt='name'
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = DefaultUser;
            }}
          />
          {data?.name || '\u2014'}
        </div>
      );
    },
  },
  {
    key: 'net_payable',
    title: 'Net Payable',
    dataIndex: 'net_payable',
  },
];

export const customDeductFields = {
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        name: 'custom_data',
        type: 'fieldArray',
        id: 'custom_data',
        addButtonText: '+ More',
        valueSchema: {
          name: '',
          variant: '',
          value_type: '',
          value: '',
        },
        component: CustomFieldArray,
        divClasses: 'col-span-full',
        gridCols: 'grid-cols-4 py-2',
        fields: [
          {
            label: 'Name',
            name: 'name',
            id: 'name',
            placeholder: 'Name',
            type: 'text',
            component: TextInput,
            maxLength: 30,
          },
          {
            label: 'Type',
            name: 'variant',
            id: 'variant',
            placeholder: 'Type',
            type: 'select',
            component: SelectInput,
            options: VariantOptions,
          },
          {
            label: 'Amount Type',
            name: 'value_type',
            id: 'value_type',
            placeholder: 'Amount Type',
            type: 'select',
            component: SelectInput,
            options: AmountType,
          },
          {
            label: 'Value',
            name: 'value',
            id: 'value',
            placeholder: 'Value',
            type: 'text',
            component: TextInput,
            maxLength: 30,
          },
        ],
      },
    ],
  },
};

export const deductFields = {
  'Deduction Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Select Deduction',
        name: 'deduction',
        id: 'deduction',
        placeholder: 'Select Deduction',
        type: 'select',
        component: SelectInput,
        fetchOptions: () => allowanceVariant({ variant: 'deduction' }),
        customOnChange: ({ field, values, form }) => {
          form?.setFieldValue(field, values);
        },
        checkbox: true,
        isMulti: true,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
      },
    ],
  },
};
export const allowFields = {
  'Allowance Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Select Allowance',
        name: 'allowance',
        id: 'allowance',
        placeholder: 'Select Allowance',
        type: 'select',
        component: SelectInput,
        fetchOptions: () => allowanceVariant({ variant: 'allowance' }),
        customOnChange: ({ field, values, form }) => {
          form?.setFieldValue(field, values);
        },
        checkbox: true,
        isMulti: true,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
      },
    ],
  },
};

export const sheetFields = {
  'Allowance Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Month',
        name: 'month',
        id: 'month',
        placeholder: 'Select Month',
        type: 'select',
        component: SelectInput,
        options: [
          { label: 'January', value: '1' },
          { label: 'February', value: '2' },
          { label: 'March', value: '3' },
          { label: 'April', value: '4' },
          { label: 'May', value: '5' },
          { label: 'June', value: '6' },
          { label: 'July', value: '7' },
          { label: 'August', value: '8' },
          { label: 'September', value: '9' },
          { label: 'October', value: '10' },
          { label: 'November', value: '11' },
          { label: 'December', value: '12' },
        ],
      },
      {
        label: 'Year',
        name: 'year',
        id: 'year',
        placeholder: 'Select Year',
        type: 'date',
        component: DateTime,
        picker: 'year',
        format: 'YYYY',
      },
    ],
  },
};

export const breadcrumbObjSalary = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Salary Sheet',
    path: '',
  },
];
/* ALLOWANCE & DEDUCTION */

export const allowanceColumns = [
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
  },
  {
    key: 'type',
    title: 'Type',
    dataIndex: 'type',
    render: (data) => VariantOptions.filter((item) => item.value === data)[0]?.label || '\u2014',
  },
  {
    key: 'amount',
    title: 'Amount',
    dataIndex: 'amount',
  },
  {
    key: 'am_type',
    title: 'Amount Type',
    dataIndex: 'am_type',
    render: (data) => AmountType.filter((item) => item.value === data)[0]?.label || '\u2014',
  },
];

export const allowanceFields = {
  'Allowance Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Name',
        name: 'name',
        id: 'name',
        placeholder: 'Name',
        type: 'text',
        component: TextInput,
        required: true,
      },
      {
        label: 'Type',
        name: 'type',
        id: 'type',
        placeholder: 'Type',
        type: 'select',
        component: SelectInput,
        options: VariantOptions,
        required: true,
      },
      {
        label: 'Amount Type',
        name: 'am_type',
        id: 'am_type',
        placeholder: 'Amount Type',
        type: 'select',
        component: SelectInput,
        options: AmountType,
        required: true,
      },
      {
        label: 'Amount',
        name: 'amount',
        id: 'amount',
        placeholder: 'Amount',
        type: 'text',
        component: TextInput,
        required: true,
      },
    ],
  },
};

export const breadcrumbObjAllowance = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Allowance & Deduction',
    path: '',
  },
];
/* TAX SLABS */

export const taxSlabColumns = [
  {
    key: 'am_from',
    title: 'Salary From',
    dataIndex: 'am_from',
  },
  {
    key: 'am_to',
    title: 'Salary To',
    dataIndex: 'am_to',
  },
  {
    key: 'type',
    title: 'Type',
    dataIndex: 'type',
    render: (data) => AmountType.filter((item) => item.value === data)[0]?.label || '\u2014',
  },
  {
    key: 'value',
    title: 'Value',
    dataIndex: 'value',
  },
];

export const taxSlabFields = {
  'Slab Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Salary From',
        name: 'am_from',
        id: 'am_from',
        placeholder: 'Salary From',
        type: 'text',
        component: TextInput,
        required: true,
      },
      {
        label: 'Salary To',
        name: 'am_to',
        id: 'am_to',
        placeholder: 'Salary To',
        type: 'text',
        component: TextInput,
        required: true,
      },
      {
        label: 'Type',
        name: 'type',
        id: 'type',
        placeholder: 'Type',
        type: 'select',
        component: SelectInput,
        options: AmountType,
        required: true,
      },
      {
        label: 'Value',
        name: 'value',
        id: 'value',
        placeholder: 'Value',
        type: 'number',
        component: TextInput,
        required: true,
      },
    ],
  },
};

export const breadcrumbObjTaxSlab = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Tax Slab',
    path: '',
  },
];

/* BASIC DETAILS */

export const breadcrumbObjBasicInfo = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Payroll Setup',
    path: '',
  },
];
