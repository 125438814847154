import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';
import { Progress } from 'antd';

import { Checklist, CustomModal, TextInput, Toast, Button, Loader } from 'components';
import { createCheckList, getCheckedVals, getPayrollCheckList } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';

const CreateSheet = ({ timeline, onCancel, onNext, setID, permissions }) => {
  const [loading, setLoading] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [checklist, setChecklist] = useState([]);

  const workingDayRef = useRef(0);
  const selectedCheckListRef = useRef([]);

  const fetchData = async ({ data }) => {
    setLoading(true);
    const resp = await getCheckedVals({ date: dayjs(timeline?.formatted).format('MM-YYYY') });
    if (resp?.data?.status) {
      const idsToCheck = resp?.data?.data?.checklist;
      const idSet = new Set(idsToCheck.map((item) => item.id));
      const payload = data.map((item) => {
        if (idSet.has(item.id)) {
          return { ...item, value: true };
        }
        return item;
      });
      setChecklist(payload);
      workingDayRef.current = resp?.data?.data?.days;
      selectedCheckListRef.current = payload;
    }
    setLoading(false);
  };

  const fetchCheckList = async () => {
    try {
      const resp = await getPayrollCheckList();
      if (resp?.data.status) {
        setChecklist(resp?.data?.data);
        fetchData({ data: resp?.data?.data });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const onSubmit = async () => {
    setShowProgressBar(true);
    let selectedIds = [];
    for (const item of selectedCheckListRef?.current) {
      if (item.value === true) {
        selectedIds.push(item.id);
      }
    }
    const payload = {
      days: workingDayRef.current,
      checklist: selectedIds,
    };

    const resp = await createCheckList({ data: payload });
    if (resp?.data?.status) {
      setID(resp?.data?.data?.id);
      setShowProgressBar(false);
      onNext();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setShowProgressBar(false);
  };

  useEffect(() => {
    fetchCheckList();
  }, []);

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((prevPercentage) => prevPercentage + 5);
      // If the percentage reaches 88, clear the interval
      if (percentage >= 90) {
        clearInterval(interval);
      }
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [percentage]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='grid gap-4 scrollbar-hide'>
      <div className='bg-white border items-center border-grey-40 rounded-lg px-4 py-2 flex justify-between'>
        <h2 className='text-xs font-semibold'>Payroll Process</h2>
        <Button variant='secondary' text='Reset' onClick={() => setChecklist([...checklist])} />
      </div>

      <motion.div layout className='bg-white border border-grey-40 rounded-lg p-4'>
        <div className='h-56 overflow-x-hidden overflow-y-auto'>
          <Checklist
            option={checklist}
            onChange={(data) => (selectedCheckListRef.current = data)}
          />
        </div>

        <div className='bg-white flex justify-between py-4 mt-6 w-full items-end'>
          <div>
            <TextInput
              type='number'
              placeholder='Enter no of working day'
              label='Working day'
              field={{ name: 'day' }}
              defaultValue={workingDayRef.current}
              onChange={(e) => (workingDayRef.current = e.target.value)}
            />
          </div>
          <div>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={() => onCancel()}
            />
            <Button
              id={
                elementData.salarySheet.permissions.create ||
                elementData.salarySheet.permissions.update
              }
              permissions={permissions}
              text={'Process Payroll'}
              variant='primary'
              classes={`ml-6 px-8 ${loading ? 'bg-grey' : 'bg-black'}`}
              onClick={onSubmit}
              loading={loading}
            />
          </div>
        </div>
      </motion.div>

      <CustomModal
        title={'Processing Payload'}
        closable={false}
        open={showProgressBar}
        footer={''}
        width={400}
      >
        <div className='grid gap-3'>
          <Progress status='active' percent={percentage} size={[300, 20]} strokeColor={'#5DD6EF'} />
        </div>
      </CustomModal>
    </div>
  );
};

export default CreateSheet;
