import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button, CustomModal, TableComponent, ViewData } from 'components';
import { AllTickets_Cols } from './data';
import { getRaisedTickets } from 'service/api-service';
import { rowCommonClass } from 'utils/common';
import { ViewCvIcon } from 'assets/svg';
import { data } from 'jquery';
import { Divider } from 'antd';

const AllTickets = (props = {}) => {
  const date = props?.date || '';
  const navigate = useNavigate();
  /* states */
  const [loading, setLoading] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const [showCVModal, setShowCVModal] = useState(false);
  const [viewTicket, setViewTicket] = useState(false);
  const [data, setData] = useState('');

  /* func */
  const fetchData = async () => {
    setLoading(true);
    const resp = await getRaisedTickets({});
    // console.info('resp', resp);
    if (resp?.status) {
      setdataSource(resp?.data?.data.result);
      // console.log('data1', dataSource);
    }
    setLoading(false);
  };

  const handleView = (data) => {
    setData(data);
    setViewTicket(true);
  };

  useEffect(() => {
    fetchData();
  }, [date]);

  return (
    <div className='p-4 space-y-4 h-full bg-white rounded-md border border-grey-40'>
      <div className='flex justify-between items-center'>
        <div className='font-semibold leading-6 text-s'>Tickets Received</div>
        <Button classes='!py-1' text='See all' onClick={navigate.bind(this, '/hr/tickets')} />
      </div>
      <div className='customTable' id='dashboard'>
        <TableComponent
          getRowClassName={rowCommonClass}
          loading={loading}
          pageSize='3'
          columns={[
            ...AllTickets_Cols,
            {
              key: 'action',
              title: 'Action',
              align: 'right',
              render: (_, record) => (
                <Button
                  // id={elementData.assetAllocation.permissions.update}
                  // permissions={permissions}
                  classes={'!py-1 !px-2 !text-xxxs'}
                  text='View'
                  variant='secondary'
                  onClick={() => handleView(record)}
                />
              ),
            },
          ]}
          dataSource={dataSource}
          showPagination={false}
          scroll={{ y: 175 }}
        />
      </div>
      <CustomModal
        destroyOnClose={true}
        open={viewTicket}
        onCancel={setViewTicket.bind(this, false)}
        footer={''}
      >
        <div className='flex flex-col'>
          <span className='font-medium text-l 2xl:text-xxl'>Ticket Details</span>
        </div>
        {/* <Divider/> */}
        <div className='flex flex-row gap-8'>
          <div className='flex flex-col flex-grow'>
            <span className={`text-xs font-medium capitalize`}>Ticket Id</span>
            <span className={`text-xxs break-words capitalize`}>{data?.ticket_id}</span>
          </div>
          <div className='flex flex-col flex-grow'>
            <span className={`text-xs font-medium capitalize`}>Name</span>
            <span className={`text-xxs break-words capitalize`}>{data?.created_by?.name}</span>
          </div>
          <div className='flex flex-col flex-grow'>
            <span className={`text-xs font-medium capitalize`}>Reason</span>
            <span className={`text-xxs break-words capitalize`}>{data?.reason}</span>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default AllTickets;
