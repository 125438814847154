import { ViewData } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import { prepareViewData } from 'utils/common';
import { SECTION_DATA_VIEW } from './data';
import dayjs from 'dayjs';

const ViewLog = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  return (
    <div>
      <div className='p-4 bg-white  rounded-2xl'>
        <ViewData
          data={prepareViewData({
            data: {
              ...state?.initialValues,
              customer: state?.initialValues?.customer?.customer_name,
              log_created_by: state?.initialValues?.created_by?.name,
              time: dayjs(state?.initialValues?.created).format('HH:MM  A'),
              date: dayjs(state?.initialValues?.created).format('DD-MM-YYYY'),
            },
            format: SECTION_DATA_VIEW,
            keys: {},
            allowToReturnObjects: true,
            phone: 'contact_number',
            email: 'email_address',
          })}
          pageTitle={`Caller ID : ${state?.initialValues?.caller_id}`}
          showGoToBack={true}
          goBack={() =>
            navigate(-1, {
              state: { activetab: state?.activekey },
            })
          }
        />
      </div>
    </div>
  );
};

export default ViewLog;
