import React, { useContext } from 'react';
import { Button, FormContainer } from 'components';
import { SecureComponent } from 'permission-provider';
import { addPolicyFormFields, initialValues } from './data';
import { Context } from 'store/context';

const AddPayment = ({ goBack, editInitial }) => {
  const { user } = useContext(Context);

  // add policy
  const handleAddPayment = async (data) => {
    const finalPayload = {
      ...data,
      assign_to_employee:
        typeof data?.assign_to_employee === 'number'
          ? data?.assign_to_employee
          : Object.keys(user).length > 0
          ? user?.id
          : '',
    };
  };

  //edit policy
  const handleUpdatePayment = async (data) => {
    // console.log('handleUpdatePayment data===>', data);
  };

  return (
    <div className='bg-white p-4 rounded-2xl flex flex-col'>
      <div className='flex flex-col px-2'>
        <span className='text-l 2xl:text-xxl font-medium'>Add Payment</span>
        <span className='text-xxs text-grey-80 '>
          To add a new Payment, enter the details of the Payment in the input field below.
        </span>
      </div>

      <FormContainer
        accordionClass='grid grid-cols-2 lg:grid-cols-3 gap-8'
        fields={addPolicyFormFields}
        initialValues={editInitial || { ...initialValues, assign_to_employee: user?.name }}
        onSubmit={editInitial ? handleUpdatePayment : handleAddPayment}
        customSubmitRender={({ isSubmitting, submitForm, resetForm }) => {
          return (
            <div className='bg-white flex flex-row justify-end py-4 mt-6'>
              <Button
                text='Cancel'
                variant='text'
                type='reset'
                classes='border border-gray-90 text-black px-6'
                onClick={() => {
                  resetForm();
                  goBack();
                }}
              />
              <Button
                text='Add Payment'
                type='button'
                variant='primary'
                classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                onClick={submitForm}
                loading={isSubmitting}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default SecureComponent(AddPayment);
