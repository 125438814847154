import React from 'react';
import { Breadcrumbs, SearchComponent, Button } from 'components';

const ContentHeader = ({
  title,
  data,
  btnText,
  onClick,
  onChange,
  buttonId,
  showSearch = true,
  permissions = [],
  children,
  variant,
  appendChildren = true,
  divClasses,
  extraRightRender,
}) => {
  return (
    <div
      className={`flex min-w-full gap-5 items-center m-auto 2xl:text-s ${
        btnText ? 'py-1' : 'py-2'
      } 2xl:h-[86px]  rounded-lg ${divClasses}`}
    >
      {data && <Breadcrumbs data={data} />}
      {title}
      <div className='flex gap-3 items-center ml-auto'>
        {appendChildren && children}
        {showSearch && (
          <SearchComponent
            placeholder='Search'
            onChange={onChange}
            extraRightRender={extraRightRender}
            style={{ fontSize: '14px' }}
          />
        )}
        {btnText && (
          <Button
            permissions={permissions}
            id={buttonId}
            text={btnText}
            variant={variant || 'primary'}
            onClick={onClick}
            classes='mt-1 '
          />
        )}
        {!appendChildren && children}
      </div>
    </div>
  );
};
export default ContentHeader;
