/** external deps */
import React, { useContext, useState } from 'react';

/** internal deps */
import { FormContainer, Toast, CustomModal, Button } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
import { meetingManagement, taskManagement } from 'service/api-service';
import { Context } from 'store/context';
import { formatDate, getEditedFields, handleDefaultValues } from 'utils';
import {
  createMeetingFields,
  createMeetingInitials,
  createTaskFields,
  createTaskInitials,
} from './data';
import { broadcast } from 'utils/communication';
import { REFRESH_EVT } from './tasks/data';
import { REFRESH_MEET } from './meetings/data';

export const Add = ({ setLoading, ...props }) => {
  const permissions = props?.permissions || [];
  const [modalType, setModalType] = useState('task');
  const [showModal, setShowModal] = useState(false);
  const { user } = useContext(Context);

  const toggleModal = (type = 'task') => {
    setModalType(type);
    setShowModal((pre) => !pre);
  };
  const finalInitial = {
    ...(modalType === 'task'
      ? {
          ...createTaskInitials,
          // assign_to_employee: handleDefaultValues({ user }),
        }
      : {
          ...createMeetingInitials,
          // assign_to_employee: handleDefaultValues({ user }),
        }),
  };

  const handleCreateTask = async (updatedData) => {
    setLoading(true);
    console.log(updatedData, '<===updatedData');
    const data = getEditedFields({ initialValues: finalInitial, updatedData });
    const finalPayload = {
      ...data,
      to_date: formatDate({ dateString: data.to_date }),
      from_date: formatDate({ dateString: data.from_date }),
      // assign_to_employee:
      //   typeof data?.assign_to_employee === 'number'
      //     ? data?.assign_to_employee
      //     : Object.keys(user).length > 0
      //     ? user?.id
      //     : '',
    };
    const resp = await taskManagement({ data: finalPayload, method: 'post' });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Task created Successful');
      broadcast(REFRESH_EVT);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to create task.');
    }
    toggleModal(modalType);
    setLoading(false);
  };

  const handleCreateMeeting = async (updatedData) => {
    console.log(updatedData, 'updatedData');
    setLoading(true);
    const data = getEditedFields({ initialValues: finalInitial, updatedData });
    const finalPayload = {
      ...data,
      to_date: formatDate({ dateString: data.to_date }),
      // assign_to_employee:
      //   typeof data?.assign_to_employee === 'number'
      //     ? data?.assign_to_employee
      //     : Object.keys(user).length > 0
      //     ? user?.id
      //     : '',
    };

    const resp = await meetingManagement({ data: finalPayload, method: 'post' });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      broadcast(REFRESH_MEET);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to create Meeting.');
    }
    toggleModal(modalType);
    setLoading(true);
  };

  return (
    <div>
      <Button
        id={elementData.task.permissions.create}
        permissions={permissions}
        text='+ Task'
        variant='transparent'
        classes='mr-2 xl:px-6'
        onClick={() => {
          toggleModal('task');
        }}
      />
      <Button
        id={elementData.meeting.permissions.create}
        permissions={permissions}
        text='+ Meeting'
        variant='primary'
        classes='xl:px-6'
        onClick={() => {
          toggleModal('meeting');
        }}
      />

      {/* Add Task/Meeting Modal */}
      <CustomModal
        destroyOnClose={true}
        open={showModal}
        onCancel={() => toggleModal(modalType)}
        footer={''}
        title={modalType === 'task' ? 'Add Task' : 'Add Meeting'}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 '>
            {modalType === 'task'
              ? 'To add a new task, enter the details in the input field below.'
              : ' To add a new meeting, enter the details in the input field below.'}
          </span>
          <FormContainer
            onSubmit={modalType === 'task' ? handleCreateTask : handleCreateMeeting}
            initialValues={finalInitial}
            fields={
              modalType === 'task'
                ? createTaskFields({ disableCustomer: false })
                : createMeetingFields({ disableCustomer: false })
            }
            accordionClass='grid grid-cols-2  gap-8'
            customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleModal(modalType);
                    }}
                  />
                  <Button
                    id={
                      modalType === 'task'
                        ? elementData.task.permissions.create
                        : elementData.meeting.permissions.create
                    }
                    permissions={permissions}
                    disabled={!isValid}
                    text={modalType === 'task' ? 'Add Task' : 'Add Meeting'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
    </div>
  );
};
