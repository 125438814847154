/** external deps */
import { NavLink } from 'react-router-dom';
import {
  FallOutlined,
  ExceptionOutlined,
  DiffOutlined,
  HomeOutlined,
  BarChartOutlined,
  CalendarOutlined,
  PayCircleOutlined,
  BankOutlined,
  HddOutlined,
  AppstoreAddOutlined,
  ContainerOutlined,
  TeamOutlined,
  FontColorsOutlined,
  AlertOutlined,
  MenuOutlined,
  BarsOutlined,
  FormOutlined,
  ControlOutlined,
  FileProtectOutlined,
  TagsOutlined,
  AuditOutlined,
  ContactsOutlined,
  UserOutlined,
  HighlightOutlined,
  SolutionOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
/** internal deps */
import { elementData } from 'elements';

import {
  Faq,
  Support,
  AdminIcon,
  BranchesIcon,
  CustomerManagementIcon,
  DashboardIcon,
  EmployeeIcon,
  GearIcon,
  PoliciesIcon,
} from 'assets/icons';
import { Badge } from 'antd';
import { useContext } from 'react';
import { Context } from 'store/context';

const svgClass = `transition-all duration-500 fill-white group-hover:stroke-primary-90 stroke-black group-open/edit:hover:stroke-black`;
const childClass = `w-4 h-4 stroke-black transition-all duration-500 group-hover:stroke-primary-90 group-hover:fill-primary-90 group-[.active]:stroke-primary-90 inline-block`;

export const getHrUserSidebar = ({ setMyStateValue, messageCount }) => {
  return {
    menu: [
      {
        icon: <DashboardIcon svgClass={svgClass} />,
        label: 'Dashboard',
        link: '/',
        name: elementData.dashboard.name,
        key: 'dashboard',
      },
      {
        label: 'Master',
        name: elementData.master.name,
        submenu: [
          {
            label: (
              <NavLink
                to='/branches'
                className={({ isActive }) =>
                  isActive ? 'gap-4 p-4 text-primary-90 ' : 'gap-4 p-4 '
                }
              >
                Branch
              </NavLink>
            ),
            icon: <BranchesIcon svgClass={childClass} />,
            name: elementData.branch.name,
            key: 'branches',
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/department'>
                Department
              </NavLink>
            ),
            icon: <BankOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'department',
            name: elementData.department.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/roles'>
                Roles
              </NavLink>
            ),
            icon: <UserOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'roles',
            name: elementData.roles.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/designation'>
                Designation
              </NavLink>
            ),
            icon: <ContactsOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'designation',
            name: elementData.designation.name,
          },
        ],
        key: 'master',
      },
      {
        icon: <EmployeeIcon svgClass={svgClass} />,
        label: 'Employees',
        link: '/employees',
        name: elementData.employee.name,
        key: 'employee',
      },
      {
        icon: <AdminIcon svgClass={svgClass} />,
        label: 'Inventory',
        name: 'Inventory',
        submenu: [
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/asset-type'>
                Asset Type
              </NavLink>
            ),
            icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'asset-type',
            name: elementData.assetType.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/inventory-management'>
                Inventory Management
              </NavLink>
            ),
            icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'inventory-management',
            name: elementData.assets.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/assets-allocation'>
                Assets Allocation
              </NavLink>
            ),
            icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'assets-allocation',
            name: elementData.assetAllocation.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-2' to='/hr/request-for-asset'>
                Request For Asset
              </NavLink>
            ),
            icon: <AlertOutlined svgClass={childClass} />,
            key: 'request-asset',
            name: elementData.assetAllocation.name,
          },
        ],
      },
      {
        icon: <CustomerManagementIcon svgClass={svgClass} />,
        label: 'Client',
        link: '/client',
        name: elementData.client.name,
        key: 'customers',
      },
      {
        icon: <AdminIcon svgClass={svgClass} />,
        label: 'Sales',
        name: 'Sales',
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Lead Management
              </div>
            ),
            name: elementData.Leads.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/lead'>
                Leads
              </NavLink>
            ),
            icon: <TeamOutlined style={{ fontSize: '15px' }} />,
            key: 'lead',
            name: elementData.Leads.name,
          },
          // {
          //   label: (
          //     <NavLink className='gap-4 p-4' to='quotation-engine'>
          //       Quotation Engine
          //     </NavLink>
          //   ),
          //   icon: <TagsOutlined style={{ fontSize: '15px' }} />,
          //   key: 'quotation-engine',
          //   name: elementData.Leads.name,
          // },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/quotation'>
                Quotation
              </NavLink>
            ),
            icon: <TagsOutlined style={{ fontSize: '15px' }} />,
            key: 'quotation',
            name: elementData.Leads.name,
          },
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                POS Agents Management
              </div>
            ),
            name: elementData.pos.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/management'>
                POS Agents
              </NavLink>
            ),
            icon: <PayCircleOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'pay-role',
            name: elementData.pos.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/training'>
                Study Material
              </NavLink>
            ),
            icon: <HddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'pos',
            name: elementData.pos_training.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/terms-condition'>
                Terms & Conditions
              </NavLink>
            ),
            icon: <HighlightOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'terms-condition',
            name: elementData.termsCondition.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/pos-contract-edit'>
                POS Contract
              </NavLink>
            ),
            icon: <SolutionOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'pos-contract',
            name: elementData.pos.name, //TBD
          },
          {
            label: (
              <button className='gap-4 p-4' onClick={setMyStateValue.bind(this, true)}>
                Send Reference Link
              </button>
            ),
            icon: <HddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'pos-ref',
            name: elementData.posContract.name, //TBD
          },
        ],
        key: 'pos',
      },
      {
        icon: <GearIcon svgClass={svgClass} />,
        label: 'Operations',
        name: 'Operations',
        submenu: [
          {
            label: (
              <NavLink className='gap-4 p-4' to='/insurance-category'>
                Manage Insurance Category
              </NavLink>
            ),
            icon: <MenuOutlined svgClass={childClass} />,
            name: elementData.insurance_category.name,
            key: 'insurance-category',
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/insurer'>
                Manage Insurance Company
              </NavLink>
            ),
            icon: <BarsOutlined svgClass={childClass} />,
            key: 'insurer',
            name: elementData.insurer.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/form'>
                Custom Form
              </NavLink>
            ),
            icon: <FormOutlined svgClass={childClass} />,
            key: 'form',
            name: elementData.custom_form.name,
          },
        ],
        key: 'operations',
      },
      {
        icon: <GearIcon svgClass={svgClass} />,
        label: 'Reports',
        name: 'Reports',
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Reports
              </div>
            ),
            name: elementData.Report.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/reports'>
                Business Reports
              </NavLink>
            ),
            icon: <ContainerOutlined svgClass={childClass} />,
            name: elementData.BussinessReport.name,
            key: 'policy-reports',
          },
          // {
          //   label: (
          //     <NavLink className='gap-4 p-4' to='/sales-report'>
          //       Daily Sales Report
          //     </NavLink>
          //   ),
          //   icon: <ControlOutlined svgClass={childClass} />,
          //   key: 'daily-sales',
          //   name: elementData.dailyReport.name,
          // },
          // {
          //   label: (
          //     <NavLink className='gap-4 p-4' to='/expense-report'>
          //       Expense Report
          //     </NavLink>
          //   ),
          //   icon: <FileProtectOutlined svgClass={childClass} />,
          //   key: 'expense-report',
          //   name: elementData.ExpenseReport.name,
          // },
        ],
        key: 'reports',
      },
      // {
      //   label: 'Daily Goals',
      //   icon: <FallOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //   link: '/daily-goals',
      //   key: 'daily-goals',
      //   name: elementData.dailyGoals.name, //TBD
      // },
      {
        icon: <GearIcon svgClass={svgClass} />,
        label: 'Leaves',
        name: elementData.leaves.name,
        submenu: [
          {
            label: (
              <NavLink className='gap-4 p-4' to='/leaves-type'>
                Leaves Type
              </NavLink>
            ),
            icon: <DiffOutlined style={{ fontSize: '16px', margin: 0 }} />,
            name: elementData.leaves_type.name, //TBD
            key: 'leaves-type',
          },
          {
            label: (
              <NavLink className='gap-4 p-4 space-x-4' to='/leaves'>
                <span>Leave Requests</span>
                {messageCount ? <Badge count={messageCount} showZero color='#faad14' /> : null}
              </NavLink>
            ),
            icon: <ExceptionOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'leaves',
            name: elementData.leaves_request.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/holidays'>
                Holidays Calendar
              </NavLink>
            ),
            icon: <HomeOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'holidays',
            name: elementData.holidays_calender.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-2' to='/hr/request-leave'>
                Request For Leave
              </NavLink>
            ),
            icon: <CalendarOutlined svgClass={childClass} />,
            key: 'request-leave',
            name: elementData.request_leave.name,
          },
        ],
      },
      {
        icon: <GearIcon svgClass={svgClass} />,
        label: 'Attendance',
        name: elementData.attendance.name,
        submenu: [
          {
            label: (
              <NavLink className='gap-4 p-4' to='/attendance-settings'>
                Shift Management
              </NavLink>
            ),
            icon: <GearIcon svgClass={childClass} />,
            key: 'attendance',
            name: elementData.HR.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/attendance'>
                View Attendance
              </NavLink>
            ),
            icon: <CalendarOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'view_attendance',
            name: elementData.HR.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/performance'>
                Performance
              </NavLink>
            ),
            icon: <BarChartOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'performance',
            name: elementData.HR.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-2' to='/hr/my-attendance'>
                Attendance
              </NavLink>
            ),
            icon: <FontColorsOutlined svgClass={childClass} />,
            key: 'attendance',
            // name: elementData.mark_attendance.name,
            name: elementData.performance.name,
          },
        ],
      },
      {
        icon: <PoliciesIcon svgClass={childClass} />,
        label: 'Letter Management',
        link: '/official-letters',
        key: 'create_form',
        name: elementData.letter.name, //TBD
      },
      {
        icon: <EmployeeIcon svgClass={svgClass} />,
        label: 'Asset Requests',
        link: '/asset-request',
        key: 'asset-request',
        name: elementData.assetRequest.name,
      },
      {
        icon: <AdminIcon svgClass={svgClass} />,
        label: 'Goals',
        name: 'Goals',
        submenu: [
          {
            label: (
              <NavLink className='gap-4 p-4' to='/goal-type'>
                Manage Goal Type
              </NavLink>
            ),
            icon: <GearIcon svgClass={childClass} />,
            key: 'goal_type',
            name: elementData.Goals_type.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/goals'>
                Goals
              </NavLink>
            ),
            icon: <GearIcon svgClass={childClass} />,
            key: 'Goal',
            name: elementData.Goals.name,
          },
        ],
      },
      {
        icon: <AdminIcon svgClass={svgClass} />,
        label: 'Accounts',
        name: 'accounts',
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Accounts
              </div>
            ),
            name: elementData.Accounts.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/payroll'>
                Payroll
              </NavLink>
            ),
            icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'payroll',
            name: elementData.payroll.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/invoices'>
                Invoices
              </NavLink>
            ),
            icon: <HddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'accounts',
            name: elementData.Invoices.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/company-expense'>
                Company Expenses
              </NavLink>
            ),
            icon: <BankOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'Expense',
            name: elementData.company.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/pos-earning'>
                View Agent Earnings
              </NavLink>
            ),
            icon: <ContainerOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'pos-earning',
            name: elementData.posEarning.name, //TBD
          },
        ],
        key: 'accounts',
      },
      {
        icon: <ExceptionOutlined style={{ fontSize: '16px', margin: 0 }} />,
        label: 'Policies',
        link: '/policy',
        name: elementData.policy.name, //TBD
        key: 'policy',
      },
      {
        icon: <AdminIcon svgClass={svgClass} />,
        label: 'Admin',
        name: 'accounts',
        submenu: [
          {
            label: (
              <NavLink className='gap-4 p-4' to='/commission-form'>
                Earning Settings
              </NavLink>
            ),
            icon: <ContainerOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'Commission',
            name: elementData.earningSetting.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/reminders'>
                Reminders
              </NavLink>
            ),
            icon: <ContainerOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'reminders',
            name: elementData.earningSetting.name, //TBD
          },
        ],
      },
      // {
      //   icon: <AdminIcon svgClass={svgClass} />,
      //   label: 'Personal',
      //   name: 'Personal',
      //   submenu: [
      //     {
      //       label: (
      //         <NavLink className='gap-4 p-4' to='/request-leave'>
      //           Request For Leave
      //         </NavLink>
      //       ),
      //       icon: <CalendarOutlined svgClass={childClass} />,
      //       key: 'request-leave',
      //       name: elementData.request_leave.name,
      //     },
      //     {
      //       label: (
      //         <NavLink className='gap-4 p-4' to='/my-attendance'>
      //           Attendance
      //         </NavLink>
      //       ),
      //       icon: <FontColorsOutlined svgClass={childClass} />,
      //       key: 'attendance',
      //       name: elementData.mark_attendance.name,
      //     },
      //     {
      //       label: (
      //         <NavLink className='gap-4 p-4' to='/request-for-asset'>
      //           Request For Asset
      //         </NavLink>
      //       ),
      //       icon: <AlertOutlined svgClass={childClass} />,
      //       key: 'request-asset',
      //       name: elementData.request_asset.name,
      //     },
      //     {
      //       label: (
      //         <NavLink className='gap-4 p-4' to='/goals'>
      //           Daily Goals
      //         </NavLink>
      //       ),
      //       icon: <FallOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //       key: 'daily-goals',
      //       name: elementData.goals_daily.name, //TBD
      //     },
      //   ],
      // },
      // support
      {
        icon: <Support svgClass={svgClass} />,
        label: 'Support',
        name: 'Support',
        submenu: [
          {
            label: (
              <NavLink className='gap-4 p-4' to='/helpline'>
                Helpline
              </NavLink>
            ),
            icon: <Support svgClass={childClass} />,
            key: 'Support',
            name: elementData.Helpline.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/faq'>
                Faq
              </NavLink>
            ),
            icon: <Faq svgClass={childClass} />,
            key: 'faq',
            name: elementData.FAQ.name,
          },

          {
            label: (
              <NavLink className='gap-4 p-4' to='/ticket'>
                Tickets Management
              </NavLink>
            ),
            icon: <AuditOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'ticket',
            name: elementData.ticket.name,
          },
        ],
      },
      {
        icon: <GearIcon svgClass={svgClass} />,
        label: 'Recruitment',
        // name: elementData.HR.name,
        submenu: [
          // {
          //   label: (
          //     <NavLink className='gap-4 p-4' to='job-kpr-kpi'>
          //       JOB KRAs
          //     </NavLink>
          //   ),
          //   icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
          //   key: 'job-kpr-kpi',
          //   name: elementData.jobKra.name, //TBD
          // },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/job-management'>
                Manage Recruitment
              </NavLink>
            ),
            icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'manage-req',
            name: elementData.manageRecruitment.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/job-interviews'>
                Interviews
              </NavLink>
            ),
            icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'interviews',
            name: elementData.interview.name, //TBD
          },
        ],
      },
    ],
  };
};

export const getSidebarContent = ({ setMyStateValue }) => {
  return {
    menu: [
      {
        icon: <DashboardIcon svgClass={svgClass} />,
        label: 'Dashboard',
        link: '/',
        name: elementData.dashboard.name,
        key: 'dashboard',
        // submenu: [
        //   {
        //     label: (
        //       <NavLink
        //         to="/"
        //         className={({ isActive }) => (isActive ? "gap-4 p-4 text-primary-90" : "gap-4 p-4")}
        //       >
        //         My Dashboard
        //       </NavLink>
        //     ),
        //     icon: <User svgClass={childClass} />,
        //     key: "my-dashboard",
        //     name: elementData.mydashboard.name,
        //   },
        //   {
        //     label: (
        //       <NavLink
        //         className={({ isActive }) => (isActive ? "gap-4 p-4 text-primary-90" : "gap-4 p-4")}
        //         to="task-dashboard"
        //       >
        //         Task Dashboard
        //       </NavLink>
        //     ),
        //     icon: <Task svgClass={childClass} />,
        //     key: "task-dashboard",
        //     name: elementData.taskdashboard.name,
        //   },
        // ],
      },

      {
        label: 'Master',
        name: elementData.master.name,
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Master
              </div>
            ),
            name: elementData.master.name,
            disabled: true,
          },
          {
            label: (
              <NavLink
                to='/branches'
                className={({ isActive }) => (isActive ? 'gap-4 p-4 text-primary-90' : 'gap-4 p-4')}
              >
                Branch
              </NavLink>
            ),
            icon: <BranchesIcon svgClass={childClass} />,
            name: elementData.branch.name,
            key: 'branches',
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/department'>
                Department
              </NavLink>
            ),
            icon: <BankOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'department',
            name: elementData.department.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/roles'>
                Roles
              </NavLink>
            ),
            icon: <UserOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'roles',
            name: elementData.roles.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/designation'>
                Designation
              </NavLink>
            ),
            icon: <ContactsOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'designation',
            name: elementData.designation.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/reasons'>
                Reasons
              </NavLink>
            ),
            icon: <ContactsOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'reasons',
            name: elementData.department.name,
          },
        ],
        key: 'master',
      },
      {
        icon: <EmployeeIcon svgClass={svgClass} />,
        label: 'Employees',
        link: '/employees',
        name: elementData.employee.name,
        key: 'employee',
      },
      {
        icon: <CustomerManagementIcon svgClass={svgClass} />,
        label: 'Client',
        link: '/client',
        name: elementData.client.name,
        key: 'customers',
      },
      {
        icon: <AdminIcon svgClass={svgClass} />,
        label: 'Sales',
        name: 'Sales',
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Lead Management
              </div>
            ),
            name: elementData.Leads.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/lead'>
                Leads
              </NavLink>
            ),
            icon: <TeamOutlined style={{ fontSize: '15px' }} />,
            key: 'lead',
            name: elementData.Leads.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/quotation'>
                Quotation
              </NavLink>
            ),
            icon: <TagsOutlined style={{ fontSize: '15px' }} />,
            key: 'quotation',
            name: elementData.Leads.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/proposals'>
                Proposals
              </NavLink>
            ),
            icon: <TagsOutlined style={{ fontSize: '15px' }} />,
            key: 'quotation-engine',
            name: elementData.Leads.name,
          },
          // {
          //   label: (
          //     <NavLink className='gap-4 p-4' to='quotation-engine'>
          //       Quotation Engine
          //     </NavLink>
          //   ),
          //   icon: <TagsOutlined style={{ fontSize: '15px' }} />,
          //   key: 'quotation-engine',
          //   name: elementData.Leads.name,
          // },
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                POS Agents Management
              </div>
            ),
            name: elementData.pos.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/management'>
                POS Agents
              </NavLink>
            ),
            icon: <PayCircleOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'pay-role',
            name: elementData.pos.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/training'>
                Study Material
              </NavLink>
            ),
            icon: <HddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'pos',
            name: elementData.pos_training.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/terms-condition-edit'>
                Terms & Conditions
              </NavLink>
            ),
            icon: <HighlightOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'terms-condition',
            name: elementData.termsCondition.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/pos-contract-edit'>
                POS Contract
              </NavLink>
            ),
            icon: <SolutionOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'pos-contract',
            name: elementData.pos.name, //TBD
          },
          {
            label: (
              <button className='gap-4 px-4' onClick={setMyStateValue.bind(this, true)}>
                Send Reference Link
              </button>
            ),
            icon: <HddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'pos-ref',
            name: elementData.pos.name, //TBD
          },
        ],
        key: 'pos',
      },
      {
        icon: <ExceptionOutlined style={{ fontSize: '16px', margin: 0 }} />,
        label: 'Policies',
        link: '/policy',
        name: elementData.policy.name, //TBD
        key: 'policy',
      },
      {
        icon: <AdminIcon svgClass={svgClass} />,
        label: 'Goals',
        name: elementData.Goals.name,
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Goals
              </div>
            ),
            name: elementData.Goals.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/goal-type'>
                Manage Goal Type
              </NavLink>
            ),
            icon: <GearIcon svgClass={childClass} />,
            key: 'target_type',
            name: elementData.Goals.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/goals'>
                Goals
              </NavLink>
            ),
            icon: <GearIcon svgClass={childClass} />,
            key: 'Goal',
            name: elementData.Goals.permissions.view,
          },
        ],
      },
      {
        icon: <GearIcon svgClass={svgClass} />,
        label: 'Operations',
        name: 'Operations',
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Operations
              </div>
            ),
            name: 'Operations',
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/insurance-category'>
                Manage Insurance Category
              </NavLink>
            ),
            icon: <MenuOutlined svgClass={childClass} />,
            name: elementData.insurance_category.name,
            key: 'insurance-category',
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/insurer'>
                Manage Insurance Company
              </NavLink>
            ),
            icon: <BarsOutlined svgClass={childClass} />,
            key: 'insurer',
            name: elementData.insurer.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/form'>
                Custom Form
              </NavLink>
            ),
            icon: <FormOutlined svgClass={childClass} />,
            key: 'form',
            name: elementData.custom_form.name,
          },
        ],
        key: 'operations',
      },
      {
        icon: <GearIcon svgClass={svgClass} />,
        label: 'Reports',
        name: 'Reports',
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Reports
              </div>
            ),
            name: elementData.Report.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/reports'>
                Business Reports
              </NavLink>
            ),
            icon: <ContainerOutlined svgClass={childClass} />,
            name: elementData.BussinessReport.name,
            key: 'policy-reports',
          },
          // {
          //   label: (
          //     <NavLink className='gap-4 p-4' to='/sales-report'>
          //       Daily Sales Reports
          //     </NavLink>
          //   ),
          //   icon: <ControlOutlined svgClass={childClass} />,
          //   key: 'daily-sales',
          //   name: elementData.dailyReport.name,
          // },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/reconciliation'>
                Reconciliation
              </NavLink>
            ),
            icon: <MenuFoldOutlined svgClass={childClass} />,
            key: 'Reconciliation',
            name: elementData.BussinessReport.name,
          },
          // {
          //   label: (
          //     <NavLink className='gap-4 p-4' to='/expense-report'>
          //       Expense Report
          //     </NavLink>
          //   ),
          //   icon: <FileProtectOutlined svgClass={childClass} />,
          //   key: 'expense-report',
          //   name: elementData.ExpenseReport.name,
          // },
        ],
        key: 'reports',
      },
      {
        icon: <AdminIcon svgClass={svgClass} />,
        label: 'Admin',
        name: 'Admin',
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Admin
              </div>
            ),
            name: 'Admin',
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/commission-form'>
                Earning Settings
              </NavLink>
            ),
            icon: <ContainerOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'Commission',
            name: elementData.earningSetting.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/reminders'>
                Reminders
              </NavLink>
            ),
            icon: <ContainerOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'reminders',
            name: elementData.earningSetting.name, //TBD
          },
        ],
      },
      {
        icon: <GearIcon svgClass={svgClass} />,
        label: 'HR',
        link: '/hr/dashboard',
        name: elementData.HR.name,
        key: 'hr',
      },
      {
        icon: <Support svgClass={svgClass} />,
        label: 'Support',
        name: 'Support',
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Support
              </div>
            ),
            name: 'Support',
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/helpline'>
                Helpline
              </NavLink>
            ),
            icon: <Support svgClass={childClass} />,
            key: 'Support',
            name: elementData.Helpline.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/faq'>
                Faq
              </NavLink>
            ),
            icon: <Faq svgClass={childClass} />,
            key: 'faq',
            name: elementData.FAQ.name,
          },

          {
            label: (
              <NavLink className='gap-4 p-4' to='/ticket'>
                Tickets Management
              </NavLink>
            ),
            icon: <AuditOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'ticket',
            name: elementData.ticket.name,
          },
        ],
      },
      // personalTab

      // {
      //   icon: <AdminIcon svgClass={svgClass} />,
      //   label: 'Personal',
      //   name: 'Personal',
      //   submenu: [
      //     {
      //       label: (
      //         <NavLink className='gap-4 p-4' to='request-leave'>
      //           Request For Leave
      //         </NavLink>
      //       ),
      //       icon: <CalendarOutlined svgClass={childClass} />,
      //       key: 'request-leave',
      //       name: elementData.request_leave.name,
      //     },
      //     {
      //       label: (
      //         <NavLink className='gap-4 p-4' to='my-attendance'>
      //           Attendance
      //         </NavLink>
      //       ),
      //       icon: <FontColorsOutlined svgClass={childClass} />,
      //       key: 'attendance',
      //       name: elementData.mark_attendance.name,
      //     },
      //     {
      //       label: (
      //         <NavLink className='gap-4 p-4' to='request-for-asset'>
      //           Request For Asset
      //         </NavLink>
      //       ),
      //       icon: <AlertOutlined svgClass={childClass} />,
      //       key: 'request-asset',
      //       name: elementData.request_asset.name,
      //     },
      //     {
      //       label: (
      //         <NavLink className='gap-4 p-4' to='goals'>
      //           Daily Goals
      //         </NavLink>
      //       ),
      //       icon: <FallOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //       key: 'daily-goals',
      //       name: elementData.goals_daily.name, //TBD
      //     },
      //   ],
      // },

      //   {
      //     icon: <AdminIcon svgClass={svgClass} />,
      //     label: 'Accounts',
      //     name: 'accounts',
      //     submenu: [
      //       {
      //         label: (
      //           <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
      //             Accounts
      //           </div>
      //         ),
      //         name: elementData.Accounts.name,
      //         disabled: true,
      //       },
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='payroll'>
      //             Payroll
      //           </NavLink>
      //         ),
      //         icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //         key: 'payroll',
      //         name: elementData.payroll.name, //TBD
      //       },
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='invoices'>
      //             Invoices
      //           </NavLink>
      //         ),
      //         icon: <HddOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //         key: 'accounts',
      //         name: elementData.Invoices.name, //TBD
      //       },
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='company-expense'>
      //             Company Expenses
      //           </NavLink>
      //         ),
      //         icon: <BankOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //         key: 'Expense',
      //         name: elementData.company.name, //TBD
      //       },
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='pos-earning'>
      //             View Agent Earnings
      //           </NavLink>
      //         ),
      //         icon: <ContainerOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //         key: 'pos-earning',
      //         name: elementData.posEarning.name, //TBD
      //       },
      //     ],
      //     key: 'accounts',
      //   },
      //   {
      //     icon: <AdminIcon svgClass={svgClass} />,
      //     label: 'Inventory',
      //     name: 'Inventory',
      //     submenu: [
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='asset-type'>
      //             Asset Type
      //           </NavLink>
      //         ),
      //         icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //         key: 'asset-type',
      //         name: elementData.assetType.name, //TBD
      //       },
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='inventory-management'>
      //             Inventory Management
      //           </NavLink>
      //         ),
      //         icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //         key: 'inventory-management',
      //         name: elementData.assets.name, //TBD
      //       },
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='assets-allocation'>
      //             Assets Allocation
      //           </NavLink>
      //         ),
      //         icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //         key: 'assets-allocation',
      //         name: elementData.assetAllocation.name, //TBD
      //       },
      //     ],
      //   },

      //   {
      //     icon: <AdminIcon svgClass={svgClass} />,
      //     label: 'Personal',
      //     name: 'Personal',
      //     submenu: [
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='request-leave'>
      //             Request For Leave
      //           </NavLink>
      //         ),
      //         icon: <CalendarOutlined svgClass={childClass} />,
      //         key: 'request-leave',
      //         name: elementData.request_leave.name,
      //       },
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='my-attendance'>
      //             Attendance
      //           </NavLink>
      //         ),
      //         icon: <FontColorsOutlined svgClass={childClass} />,
      //         key: 'attendance',
      //         name: elementData.mark_attendance.name,
      //       },
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='request-for-asset'>
      //             Request For Asset
      //           </NavLink>
      //         ),
      //         icon: <AlertOutlined svgClass={childClass} />,
      //         key: 'request-asset',
      //         name: elementData.request_asset.name,
      //       },
      //       // {
      //       //   label: (
      //       //     <NavLink className="gap-4 p-4" to="goals">
      //       //       Daily Goals
      //       //     </NavLink>
      //       //   ),
      //       //   icon: <FallOutlined style={{ fontSize: "16px", margin: 0 }} />,
      //       //   key: "daily-goals",
      //       //   name: elementData.goals_daily.name, //TBD
      //       // },
      //     ],
      //   },
      // support

      //   {
      //     icon: <GearIcon svgClass={svgClass} />,
      //     label: 'Recruitment',
      //     // name: elementData.HR.name,
      //     submenu: [
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='job-kpr-kpi'>
      //             JOB KRAs
      //           </NavLink>
      //         ),
      //         icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //         key: 'job-kpr-kpi',
      //         name: elementData.jobKra.name, //TBD
      //       },
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='job-management'>
      //             Manage Recruitment
      //           </NavLink>
      //         ),
      //         icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //         key: 'manage-req',
      //         name: elementData.manageRecruitment.name, //TBD
      //       },
      //       {
      //         label: (
      //           <NavLink className='gap-4 p-4' to='job-interviews'>
      //             Interviews
      //           </NavLink>
      //         ),
      //         icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
      //         key: 'interviews',
      //         name: elementData.interview.name, //TBD
      //       },
      //     ],
      //   },
    ],
  };
};

export const getHrSidebarContent = ({ messageCount, user }) => {
  return {
    menu: [
      {
        icon: <DashboardIcon svgClass={svgClass} />,
        label: 'Dashboard',
        link: '/hr/dashboard',
        name: elementData.HR.name,
        key: 'dashboard',
      },

      {
        icon: <EmployeeIcon svgClass={svgClass} />,
        label: 'Employees',
        link: '/hr/employees',
        name: elementData.employee.name,
        key: 'employee',
      },
      {
        icon: <AdminIcon svgClass={svgClass} />,
        label: 'Accounts',
        name: 'accounts',
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Accounts
              </div>
            ),
            name: elementData.Accounts.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/payroll'>
                Payroll
              </NavLink>
            ),
            icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'payroll',
            name: elementData.payroll.name, //TBD
          },
          //   {
          //     label: (
          //       <NavLink className='gap-4 p-4' to='invoices'>
          //         Invoices
          //       </NavLink>
          //     ),
          //     icon: <HddOutlined style={{ fontSize: '16px', margin: 0 }} />,
          //     key: 'accounts',
          //     name: elementData.Invoices.name, //TBD
          //   },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/company-expense'>
                Company Expenses
              </NavLink>
            ),
            icon: <BankOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'Expense',
            name: elementData.company.name, //TBD
          },
          //   {
          //     label: (
          //       <NavLink className='gap-4 p-4' to='pos-earning'>
          //         View Agent Earnings
          //       </NavLink>
          //     ),
          //     icon: <ContainerOutlined style={{ fontSize: '16px', margin: 0 }} />,
          //     key: 'pos-earning',
          //     name: elementData.posEarning.name, //TBD
          //   },
        ],
        key: 'accounts',
      },
      {
        icon: <AdminIcon svgClass={svgClass} />,
        label: 'Inventory',
        name: 'Inventory',
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Inventory
              </div>
            ),
            name: elementData.assetType.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/asset-type'>
                Asset Type
              </NavLink>
            ),
            icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'asset-type',
            name: elementData.assetType.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/inventory-management'>
                Inventory Management
              </NavLink>
            ),
            icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'inventory-management',
            name: elementData.assets.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/assets-allocation'>
                Assets Allocation
              </NavLink>
            ),
            icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'assets-allocation',
            name: elementData.assetAllocation.name, //TBD
          },
          // {
          //   label: (
          //     <NavLink className='gap-4 p-4' to='asset-request'>
          //       Asset Requests
          //     </NavLink>
          //   ),
          //   icon: <AlertOutlined svgClass={childClass} />,
          //   key: 'asset-request',
          //   name: elementData.assetAllocation.name, //TBD
          // },
          {
            label: (
              <NavLink className='gap-4 p-2' to='/hr/request-for-asset'>
                Request For Asset
              </NavLink>
            ),
            icon: <AlertOutlined svgClass={childClass} />,
            key: 'request-asset',
            name: elementData.assetAllocation.name,
          },
        ],
      },

      {
        icon: <GearIcon svgClass={svgClass} />,
        label: 'Leaves',
        name: elementData.leaves.name,
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Leaves
              </div>
            ),
            name: elementData.leaves_type.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/leaves-type'>
                Leaves Type
              </NavLink>
            ),
            icon: <DiffOutlined style={{ fontSize: '16px', margin: 0 }} />,
            name: elementData.leaves_type.name, //TBD
            key: 'leaves-type',
          },
          {
            label: (
              <NavLink className='gap-4 p-4 space-x-4' to='/hr/leaves'>
                <span>Leave Requests</span>
                {messageCount ? <Badge count={messageCount} showZero color='#faad14' /> : null}
              </NavLink>
            ),
            icon: <ExceptionOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'leaves',
            name: elementData.leaves_request.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/holidays'>
                Holidays Calendar
              </NavLink>
            ),
            icon: <HomeOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'holidays',
            name: elementData.holidays_calender.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-2' to='/hr/request-leave'>
                Request For Leave
              </NavLink>
            ),
            icon: <CalendarOutlined svgClass={childClass} />,
            key: 'request-leave',
            name: elementData.request_leave.name,
          },
        ],
      },
      {
        icon: <GearIcon svgClass={svgClass} />,
        label: 'Attendance',
        name: elementData.attendance.name,
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Attendance
              </div>
            ),
            // name: elementData.HR.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/attendance-settings'>
                Shift Management
              </NavLink>
            ),
            icon: <GearIcon svgClass={childClass} />,
            key: 'shift-mgmnt',
            name: elementData.attendance_settings.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/attendance'>
                View Attendance
              </NavLink>
            ),
            icon: <CalendarOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'view_attendance',
            name: elementData.view_attendance.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/performance'>
                Performance
              </NavLink>
            ),
            icon: <BarChartOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'performance',
            name: elementData.performance.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-2' to='/hr/my-attendance'>
                Attendance
              </NavLink>
            ),
            icon: <FontColorsOutlined svgClass={childClass} />,
            key: 'attendance',
            // name: elementData.mark_attendance.name,
            name: elementData.attendance.name,
          },
        ],
      },
      {
        icon: <PoliciesIcon svgClass={childClass} />,
        label: 'Letter Management',
        link: '/hr/official-letters',
        key: 'create_form',
        name: elementData.letter.name, //TBD
      },
      {
        icon: <GearIcon svgClass={svgClass} />,
        label: 'Recruitment',
        // name: elementData.HR.name,
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Recruitment
              </div>
            ),
            name: elementData.HR.name, //TBD
            disabled: true,
          },
          // {
          //   label: (
          //     <NavLink className='gap-4 p-4' to='job-kpr-kpi'>
          //       JOB KRAs
          //     </NavLink>
          //   ),
          //   icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
          //   key: 'job-kpr-kpi',
          //   name: elementData.jobKra.name, //TBD
          // },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/job-management'>
                Manage Recruitment
              </NavLink>
            ),
            icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'manage-req',
            name: elementData.manageRecruitment.name, //TBD
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/job-interviews'>
                Interviews
              </NavLink>
            ),
            icon: <AppstoreAddOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'interviews',
            name: elementData.interview.name, //TBD
          },
        ],
      },
      {
        icon: <PoliciesIcon svgClass={childClass} />,
        label: 'Company policies',
        link: '/hr/company-policies',
        key: 'create_form',
        name: elementData.company_policy.name,
      },
      {
        icon: <GearIcon svgClass={svgClass} />,
        label: 'Tickets',
        name: elementData.attendance.name,
        submenu: [
          {
            label: (
              <div className='flex items-center font-semibold text-grey-90 hover:text-grey-90'>
                Tickets
              </div>
            ),
            // name: elementData.HR.name,
            disabled: true,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/raise-ticket'>
                Raise Ticket
              </NavLink>
            ),
            icon: <GearIcon svgClass={childClass} />,
            key: 'raise_ticket',
            // name: elementData.view_attendance.name,
          },
          {
            label: (
              <NavLink className='gap-4 p-4' to='/hr/tickets'>
                View Tickets
              </NavLink>
            ),
            icon: <CalendarOutlined style={{ fontSize: '16px', margin: 0 }} />,
            key: 'view_attendance',
            name: elementData.view_attendance.name,
          },
        ],
      },
    ],
  };
};
