import { endpoints } from 'constants/endpoints';
import Request from './client';

export const getNotifications = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.notification.list,
  });
  return resp;
};

export const readNotifications = async () => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.notification.read,
    data: { read: true },
  });
  return resp;
};
