/** external deps */
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { ReloadOutlined } from '@ant-design/icons';

/** internal deps */
import { IconButton, Timeline } from 'components';

dayjs.extend(relativeTime);

const renderTimeline = (item, index) => {
  const timeAgo = dayjs(item?.created).fromNow();
  const heading = item?.module + ' ' + item?.activity;
  const content = `${item?.user_id || item?.pos_id?.name} has ${item?.activity}  `;
  const name = `${item?.name}`;
  const last_content = `${item?.module} ${timeAgo}.`;
  return (
    <Timeline
      key={index}
      time={timeAgo}
      heading={heading}
      content={content}
      count={index}
      name={name}
      last_content={last_content}
      module={item?.module}
      activity={item?.activity}
    />
  );
};

const UpcomingActivity = ({
  heading,
  subheading,
  filterActivities,
  timeLine = [],
  loading,
  noDataAction,
  user,
  activityClass = '',
}) => {
  /* states */
  const [filterActivity, setFilterActivity] = useState([]);

  /* funcs */
  const handleSelect = (e) => {
    if (e === 'All') {
      setFilterActivity(timeLine);
    } else {
      const filter = timeLine.filter((item) => item.module === e);
      setFilterActivity(filter);
    }
  };

  useEffect(() => {
    if (!loading) {
      setFilterActivity(timeLine);
    }
  }, [loading]);

  return (
    <>
      {/* {user?.position == "HR" && (
        <>
          <div className="flex justify-between border-b border-dashed border-grey-70 pb-6">
            <h1 className="text-sm font-semibold">{heading}</h1>
            <Select
              disabled={!timeLine?.length}
              defaultValue="All"
              className="min-w-[150px] activitySelect group !bg-[#F9F9F9] !text-grey-90 rounded px-2"
              popupClassName="min-w-24"
              dropdownAlign={{ offset: [-5, 15] }}
              suffixIcon={<DropdownIcon svgClass={"w-2 fill-grey-90"} />}
              options={RECENT_ACTIVITY.filter((i) =>
                i.displayFilter ? i.displayFilter.includes(user?.position) : true
              )}
              onSelect={handleSelect}
            />
          </div>
        </>
      )} */}
      <p className='text-xxs text-grey-90 pb-6 '>{subheading}</p>
      {loading ? (
        <div className='h-96 flex justify-center items-center'>
          <Spin />
        </div>
      ) : !timeLine?.length ? (
        <div className='flex justify-center items-center pt-2 h-40 flex-col'>
          <span className='font-medium'>Reload</span>
          <IconButton onClick={noDataAction}>
            <ReloadOutlined className='text-sm text-grey-90' />
          </IconButton>
        </div>
      ) : (
        <div className={`h-[260px] overflow-y-auto p-4 bg-white ${activityClass}`}>
          {filterActivities?.map(renderTimeline) || filterActivity?.map(renderTimeline)}
        </div>
      )}
    </>
  );
};

export default UpcomingActivity;
