import { Radio } from 'antd';
import { SelectInput, TextInput, Checkbox, CustomRadio, SelectSearchWithName } from 'components';
import { CheckBoxFormField } from 'pspdfkit';
import { getAssetType } from 'service/api-service';
import { getReasons } from 'service/api-service/reasons';

const fieldArray = [
  {
    label: 'Asset Type',
    name: 'assetType',
    id: 'assetType',
    placeholder: 'Asset Type',
    required: true,
    type: 'select',
    component: SelectInput,
    options: [],
    dependantFetchOptions: async () => {
      const resp = await getAssetType();
      return resp?.data?.data?.result || [];
    },
  },
  {
    label: 'Reason',
    name: 'reason',
    id: 'reason',
    placeholder: 'Reason',
    required: true,
    type: 'select',
    component: SelectSearchWithName,
    options: [],
    fetchOptions: async () => getReasons({ type: 'asset' }),
    valIndex: 'value',
    customOnChange: (field, value, form) => {
      form?.setFieldValue(field, value.value);
    },
  },
  {
    label: 'Immediate Needed',
    name: 'imd_need',
    id: 'imd_need',
    required: true,
    type: 'radio',
    component: CustomRadio,
    options: [
      {
        value: 'yes',
        label: 'Yes',
      },
      {
        value: 'no',
        label: 'No',
      },
    ],
  },
];

const addAssetsFields = {
  assets: {
    showAccordionHeader: false,
    fields: fieldArray,
  },
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Your Asset Request',
    path: '/request-for-asset',
  },
];

export { addAssetsFields, breadcrumbObj };
