import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button, ContentHeader, FormContainer, MarkDown, TextInput, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';

import { CompanyPolicyManagement } from 'service/api-service';
import { prepareFormFieldsData } from 'utils';
import { useNavigate } from 'react-router-dom';

export const breadcrumbObj = (type) => [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Company Policies',
    path: '/hr/company-policies',
  },
  {
    name: type,
    path: '',
  },
];

const fields = {
  assignFields: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Policy Title',
        name: 'title',
        id: 'title',
        placeholder: 'Policy Title',
        type: 'text',
        component: TextInput,
        required: true,
      },
      {
        label: 'Policy Description',
        name: 'des',
        id: 'des',
        placeholder: 'Policy Description',
        type: 'textarea',
        component: MarkDown,
        required: true,
      },
    ],
  },
};

const AddEditCompanyPolicy = ({ permissions, type }) => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id;

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState();

  const fetchPolicy = async () => {
    setLoading(true);
    try {
      const res = await CompanyPolicyManagement({ method: 'get', id });
      if (res?.data?.status) setDataSource(res?.data?.data);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    try {
      let res;
      if (id) {
        res = await CompanyPolicyManagement({ method: 'patch', data, id });
      } else {
        res = await CompanyPolicyManagement({ method: 'post', data });
      }
      if (res?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, res?.data?.msg || 'Toast Created Successfully');
        navigate('/hr/company-policies');
      } else {
        Toast('error', NOTIFICATION_MSG.error, res?.error || 'Please try again');
      }
    } catch (error) {
      Toast('error', 'Please try again');
    }
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: fields,
  });

  useEffect(() => {
    if (id) fetchPolicy();
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader permissions={permissions} data={breadcrumbObj(type)} showSearch={false} />
      <div className='bg-white p-4'>
        <h3 className='text-m font-semibold'>{type} Company Policy</h3>
        {!loading && (
          <FormContainer
            accordionClass='grid grid-cols-1 gap-4'
            onSubmit={onSubmit}
            fields={finalFields}
            loading={loading}
            initialValues={dataSource || initialValues}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      navigate('/hr/company-policies');
                      resetForm();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    text={'Save'}
                    variant='primary'
                    classes={`ml-6 px-8 bg-black`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AddEditCompanyPolicy;
