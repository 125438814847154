import { DateTime, TextInput } from 'components';

const addHolidayFields = {
  Leave: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Name',
        name: 'label',
        id: 'label',
        placeholder: 'Name',
        type: 'text',
        required: true,
        component: TextInput,
        divClasses: 'col-span-full',
      },
      {
        label: 'Start Date',
        name: 'start',
        id: 'start',
        placeholder: 'YYYY-MM-DD',
        type: 'date',
        required: true,
        showTime: false,
        component: DateTime,
        disablePrev: true,
        modifyValues: (form, value) => {
          if (!form) return;
          form?.setFieldValue('end', value);
        },
      },
      {
        label: 'End Date',
        name: 'end',
        id: 'end',
        placeholder: 'YYYY-MM-DD',
        type: 'date',
        required: true,
        showTime: false,
        component: DateTime,
      },
      {
        label: 'Note',
        name: 'notes',
        id: 'notes',
        placeholder: 'Reason',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        rows: 5,
        divClasses: 'col-span-full',
      },
    ],
  },
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Holidays',
    path: '',
  },
];

export { addHolidayFields, breadcrumbObj };
