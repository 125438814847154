/** external deps */
import { Space } from 'antd';
import React, { useCallback, useState } from 'react';
/** internal deps */
import { DELETE, EDIT, Trash } from 'assets/icons';
import { Button, CustomModal, IconButton, TableComponent, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
import { deleteCommission } from 'service/api-service';
import AddCommission from './addCommission';
import { commissionCols } from './data';
import { rowCommonClass } from 'utils/common';

const CommissionTable = ({
  permissions = {},
  data = [],
  loading = false,
  setLoading = {},
  refresh = {},
  commissionSet = '',
  id = null,
} = {}) => {
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [editInitials, setInitials] = useState();
  const [selectedVal, setSelectedVal] = useState();

  const columns = [
    ...commissionCols,
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <div className='flex gap-2 justify-end'>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.branch.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.branch.permissions.delete}
            onClick={() => {
              setSelectedVal(record);
              toggleDeleteCommission();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
        </div>
      ),
    },
  ];

  const toggleDeleteCommission = () => setDeleteModal((pre) => !pre);
  const toggleAddAsset = () => setAddModal((pre) => !pre);

  const onEditPress = useCallback((record) => {
    const payload = {
      ...record,
      insurance_category: record?.insurance_category?.id,
      product_type: record?.product_type?.id,
    };
    setInitials(payload);
    toggleAddAsset();
  }, []);

  const onDeleteCommission = async () => {
    setLoading(true);
    const resp = await deleteCommission({ id: selectedVal.id });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleDeleteCommission();
      refresh();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.msg);
    }
    setLoading(false);
  };

  return (
    <>
      <TableComponent
        getRowClassName={rowCommonClass}
        scroll={{ y: '40vh' }}
        loading={loading}
        columns={columns}
        dataSource={data}
      />
      <AddCommission
        open={showAddModal}
        toggle={toggleAddAsset}
        setLoading={setLoading}
        loading={loading}
        id={id}
        editInitials={editInitials}
        refresh={refresh}
        commissionSet={commissionSet}
      />

      {/* delete asset confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteCommission}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the commission ?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteCommission}
            />
            <Button
              text='Delete Commission'
              variant='primary'
              classes={`ml-6 px-8 bg-black`}
              onClick={onDeleteCommission}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default CommissionTable;
