/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { Space } from 'antd';
/** internal deps */
import {
  Button,
  ContentHeader,
  CustomModal,
  FormContainer,
  IconButton,
  TableComponent,
  Toast,
} from 'components';
import { allowanceColumns, allowanceFields, breadcrumbObjAllowance } from './data';
import { elementData } from 'elements';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { NOTIFICATION_MSG } from 'constants';
import { createAllowance, deleteAllowance, editAllowance, getAllowance } from 'service/api-service';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { SecureComponent } from 'permission-provider';
import { rowCommonClass } from 'utils/common';

const AllowanceDeductions = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editInitials, setInitials] = useState();
  const [selectedAllowance, setSelectedAllowance] = useState();
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: allowanceFields,
  });

  const fetchData = async () => {
    setLoading(true);
    const resp = await getAllowance();
    if (resp?.data?.status) {
      setTableData(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to load data');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleAddAllowance = () => {
    setInitials(undefined);
    setAddModal((pre) => {
      return !pre;
    });
  };

  const onAddAllowance = async (data) => {
    setLoading(true);
    const finalPayload = {
      ...data,
    };
    const resp = await createAllowance({ data: finalPayload });
    if (resp?.data?.status) {
      toggleAddAllowance();
      fetchData();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const handleEditClick = (record) => {
    setSelectedAllowance(record);
    const payload = {
      ...record,
    };
    setInitials(payload);
    setAddModal((pre) => {
      return !pre;
    });
  };

  const onEditAllowance = useCallback(
    async (data) => {
      setLoading(true);
      const editFields = getEditedFields({ initialValues: editInitials, updatedData: data });
      if (Object.keys(editFields).length) {
        const resp = await editAllowance({
          id: selectedAllowance?.id,
          data: editFields,
        });

        if (resp?.data?.status) {
          Toast(
            'success',
            NOTIFICATION_MSG.success,
            resp?.data?.msg || 'Slab updated successfully.',
          );
          fetchData();
        } else {
          Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
        }
        toggleAddAllowance();
        setLoading(false);
      } else {
        setLoading(false);
        Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save.');
      }
    },
    [editInitials, selectedAllowance],
  );

  const toggleDeleteAllowance = () => setDeleteModal((pre) => !pre);

  const onDeleteAllowance = async () => {
    const id = selectedAllowance?.id;
    if (!id) return;
    setLoading(true);
    const resp = await deleteAllowance({ id });
    if (resp?.data?.status) {
      fetchData();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Entry deleted successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteAllowance();
    setLoading(false);
  };

  /* search function */
  const searchFunction = useCallback(
    (e) => {
      if (!e.target.value) setSearchQuery('');
      const query = e.target.value;
      setSearchQuery(query);
      const fuse = new Fuse(tableData, {
        keys: ['name', 'type', 'am_type', 'amount'],
        threshold: 0.2,
      }).search(e.target.value);
      setFilteredData(fuse.map((i) => i.item));
    },
    [tableData],
  );
  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={searchFunction}
        data={breadcrumbObjAllowance}
        btnText='+ Add'
        buttonId={elementData?.allowance?.permissions?.create}
        onClick={toggleAddAllowance}
      />
      <TableComponent
        loading={loading}
        getRowClassName={rowCommonClass}
        columns={[
          ...allowanceColumns,
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
              <Space size='middle'>
                <IconButton
                  title='Edit'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.allowance.permissions.update}
                  onClick={() => handleEditClick(record)}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Delete'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.allowance.permissions.delete}
                  onClick={() => {
                    setSelectedAllowance(record);
                    toggleDeleteAllowance();
                  }}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton>
              </Space>
            ),
          },
        ]}
        dataSource={searchQuery ? filteredData : tableData}
        noDataAction={fetchData}
      />
      {/* add allowance modal */}
      <CustomModal
        destroyOnClose={true}
        open={showAddModal}
        title={`${editInitials ? 'Edit' : 'Add'} Allowance & Deduction`}
        onCancel={toggleAddAllowance}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 text-grey-80'>
            {editInitials
              ? 'Edit allowance & deduction details'
              : 'To add new allowance & deduction, enter the details in the input field below.'}
          </span>

          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditAllowance : onAddAllowance}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddAllowance();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.allowance.permissions.update
                        : elementData.allowance.permissions.create
                    }
                    text={editInitials ? 'Update' : 'Add'}
                    variant='primary'
                    classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* delete allowance confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteAllowance}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteAllowance}
            />
            <Button
              text='Delete'
              variant='primary'
              classes={`ml-6 px-8`}
              onClick={onDeleteAllowance}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(AllowanceDeductions);
