import React from 'react';
import './style.css';

const pulseClass = `w-5 h-5 rounded-full bg-[#0369a1] animation-pulse`;

export const LoadingIndicator = () => {
  return (
    <div className='flex justify-between items-start mt-2.5 w-32'>
      <div className={`${pulseClass} pulse-bubble-1`} />
      <div className={`${pulseClass} pulse-bubble-2`} />
      <div className={`${pulseClass} pulse-bubble-3`} />
    </div>
  );
};
