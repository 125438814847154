/** external deps */
import React, { useCallback, useState } from 'react';
import { DatePicker, Divider, Select } from 'antd';
import dayjs from 'dayjs';

/** internal deps */
import {
  Button,
  Filter,
  TabsComponent,
  AddTasksComponent,
  Tasks,
  Meetings,
  SearchComponent,
} from 'components';
import { allColumns, DateRangeOptions, StatusOptions } from './data';
import { SecureComponent } from 'permission-provider';
import { generatePermittedTabs } from 'utils';
import { elementData } from 'elements';
import { DropdownIcon } from 'assets/icons';
import { getQueryParams } from 'utils/common';
import './style.css';

const { RangePicker } = DatePicker;

const TaskDashboard = (props) => {
  const permissions = props?.permissions || [];
  const { tab } = getQueryParams();

  // states */
  const [key, setActiveTabKey] = useState(tab || 'task');
  const [status, setStatus] = useState('');
  const [searchQuery, setSearchQeury] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [activeColumns, setActiveColumns] = useState(allColumns);
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [showdateRange, setShowDateRange] = useState(false);

  const tabs = [
    {
      key: 'task',
      label: `Tasks`,
      id: elementData.task.name,
      Component: Tasks,
    },
    {
      key: 'meeting',
      label: `Meetings`,
      id: elementData.meeting.name,
      Component: Meetings,
    },
  ];

  const toggleTable = () => {
    setShowTable((pre) => !pre);
  };

  const onSelectDateRange = useCallback((e) => {
    if (e !== 'customRange') {
      setEndDate(dayjs().format('YYYY-MM-DD'));
    }
    if (e === 'today') {
      setStartDate(dayjs().subtract(0, 'day').format('YYYY-MM-DD'));
      setShowDateRange(false);
    } else if (e === 'week') {
      setStartDate(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
      setShowDateRange(false);
    } else if (e === 'month') {
      setStartDate(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));
      setShowDateRange(false);
    } else if (e === 'quarter') {
      setStartDate(dayjs().subtract(3, 'month').format('YYYY-MM-DD'));
      setShowDateRange(false);
    } else if (e === 'customRange') {
      setShowDateRange(true);
    }
  }, []);

  const handleCustomRange = (e) => {
    const startDate = dayjs(e[0]).format('YYYY-MM-DD');
    const endDate = dayjs(e[1]).format('YYYY-MM-DD');
    if (startDate && endDate) {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const onStatusChange = useCallback((val) => {
    setStatus(val);
  }, []);

  return (
    <main id='task-dashboard-screen'>
      <div className='flex items-center rounded-lg bg-white px-2 w-min' id='reports-details'>
        <Select
          className='group hover:text-primary-90 rounded px-2'
          suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
          bordered={false}
          options={DateRangeOptions}
          onSelect={onSelectDateRange}
          placeholder='Select Date'
          defaultValue='Today'
        />
        <Divider orientation='center' type='vertical' className='h-4' />

        {showdateRange && (
          <>
            <div id='dashboard'>
              <RangePicker onChange={handleCustomRange} />
            </div>
            <Divider orientation='center' type='vertical' className='h-4' />
          </>
        )}
      </div>
      <TabsComponent
        extraProps={{
          activeColumns,
          loading,
          permissions,
          showTable,
          searchQuery,
          setSearchQeury,
          endDate,
          startDate,
          status,
        }}
        extraRightRender={
          <div className='flex flex-row gap-4'>
            {showTable && <SearchComponent onChange={(e) => setSearchQeury(e.target.value)} />}
            {!showTable && (
              <Filter
                allColumns={allColumns}
                activeColumns={activeColumns}
                updateActiveColumns={setActiveColumns}
                minFields={3}
              />
            )}
            {showTable && (
              <div className='flex items-center'>
                <Select
                  className='group bg-primary-60 text-white rounded px-2'
                  suffixIcon={<DropdownIcon svgClass={'w-2'} />}
                  bordered={false}
                  options={StatusOptions}
                  onSelect={onStatusChange}
                  placeholder='Select Status'
                  defaultValue=''
                />
              </div>
            )}
            <Button
              variant='secondary'
              text={`View ${
                showTable
                  ? key === 'task'
                    ? 'Dashboard Task'
                    : 'Dashboard Meeting'
                  : key === 'task'
                  ? 'All Task'
                  : 'All Meeting'
              }`}
              onClick={toggleTable}
            />
            <AddTasksComponent permissions={permissions} setLoading={setLoading} />
          </div>
        }
        items={generatePermittedTabs(tabs, permissions)}
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
        url={'/?module=task-dashboard&'}
      />
    </main>
  );
};

export default SecureComponent(TaskDashboard);
