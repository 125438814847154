import { SearchAPI, SelectInput, TextInput } from 'components';
import { searchApis, searchInsuranceApis } from 'service/api-service';
import { preventEKey } from 'utils/common';

export const AssignmentFields = ({
  setselectedProduct,
  setselectedCategory,
  unassigned,
  setEnteredCount,
  enteredCount,
}) => {
  return {
    assignFields: {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Select Category',
          name: 'ins_cat_id',
          id: 'ins_cat_id',
          divClasses: 'py-2',
          placeholder: 'Select Category',
          type: 'select',
          component: SelectInput,
          fetchOptions: async () => searchApis({ api: 'insurance_category' }),
          customOnChange: (value, id) => {
            setselectedCategory(id);
          },
          required: true,
        },
        {
          label: 'Select Product',
          name: 'product_type',
          id: 'product_type',
          // divClasses: 'py-5',
          placeholder: 'Select Product',
          type: 'select',
          component: SelectInput,
          options: [],
          divClasses: 'mb-5 mt-3',
          required: true,
          customOnChange: (value, id) => {
            setselectedProduct(id);
          },
          dependency: ['ins_cat_id'],
          dependantFetchOptions: async (form) => {
            const id = form?.values?.ins_cat_id?.id || form?.values?.ins_cat_id;
            const resp = await searchInsuranceApis({ api: 'insurance_category', id });
            return resp?.data?.data[0]?.product_type?.map((i) => ({
              name: i.name,
              label: i.name,
              value: i.id,
              id: i.id,
            }));
          },
        },
        {
          label: 'Number Of Clients',
          divClasses: 'mt-12 relative',
          name: 'customer_count',
          id: 'customer_count',
          placeholder: 'number of clients',
          type: 'number_range',
          range: unassigned,
          // customOnChange : ({field,value})=> {
          //   console.log("ee",value)
          // },

          // InputClasses:"number",
          handleKeyPress: (e) => {
            preventEKey(e);
            setEnteredCount(e.key);
            // count =  typeof JSON.parse(e.key) === "number" ? JSON.parse(e.key) : 0;
          },
          component: TextInput,
          required: true,
          // extraRightRender: () => {
          //   return (
          //     <p
          //       className={`text-red-90  text-xxxs mt-14   w-full ${
          //         enteredCount > unassigned ? 'flex' : 'hidden'
          //       }  `}
          //     >
          //       {' '}
          //       should not greater than {unassigned ?? 0}{' '}
          //     </p>
          //   );
          // },
        },
        {
          label: 'Select Asignee',
          name: 'assign_to_employee',
          divClasses: 'py-2 mt-4',
          id: 'assign_to_employee',
          placeholder: 'Select Asignee',
          type: 'select',
          component: SearchAPI,
          required: true,
        },
      ],
    },
  };
};

export const ClientAssignmentInitials = {
  insurance_category: '',
  product_type: '',
  number_of_client: '',
  assigned_to_employee: '',
};
