import React from 'react';
import { UserInitialIcon } from 'components';
import { Tooltip } from 'antd';

const EmployeeList = ({ data, label }) => {
  const renderSection = (item) => {
    return (
      <Tooltip title={`${item?.emp_code}-${item?.name}`}>
        <div>
          <UserInitialIcon
            name={item?.name}
            imageUrl={process.env.REACT_APP_DOC_URL + item?.image}
          />
        </div>
      </Tooltip>
    );
  };
  return (
    <div>
      <label
        className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xs text-black`}
      >
        {label}
      </label>
      <div className='grid grid-cols-8 gap-1 gap-y-2'>{data?.map(renderSection)}</div>
    </div>
  );
};

export default EmployeeList;
