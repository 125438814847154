import { ACTIVITY_STATUS } from 'constants';
import { formatDate } from 'utils';

export const taskColumns = [
  {
    key: 'subject',
    title: 'Subject',
    dataIndex: 'subject',
    // fixed:"left",
    width: 100,
    render: (text) => text || '\u2014',
  },
  {
    key: 'contact_id',
    title: 'Contact Name',
    dataIndex: 'contact_id',
    // fixed:"left",
    width: 150,
    render: (text) => text || '\u2014',
  },
  {
    key: 'assign_to_employee',
    title: 'Assigned To',
    dataIndex: 'assign_to_employee',
    // fixed:"left",
    width: 180,
    render: (text) => text || '\u2014',
  },
  {
    key: 'from_date',
    title: 'From',
    dataIndex: 'from_date',
    width: 200,
    render: (dateString) => {
      return formatDate({ dateString, toUtc: false, format: 'dddd MM,YYYY, HH:mm' });
    },
  },
  {
    key: 'to_date',
    title: 'To',
    dataIndex: 'to_date',
    width: 200,
    render: (dateString) => {
      return formatDate({ dateString, toUtc: false, format: 'dddd MM,YYYY, HH:mm' });
    },
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    fixed: 'right',
    width: 120,
    render: (_, record) => {
      return ACTIVITY_STATUS.find((i) => i.value === record?.status)?.label;
    },
  },
];

export const meetingColumns = [
  {
    key: 'to_date',
    title: 'To',
    dataIndex: 'to_date',
    width: 200,
    render: (dateString) => {
      return formatDate({ dateString, toUtc: false, format: 'dddd MM,YYYY, HH:mm' });
    },
  },
  {
    key: 'assign_to_employee',
    title: 'Assigned To',
    dataIndex: 'assign_to_employee',
    render: (text) => text || '\u2014',
  },
  {
    key: 'activity_type',
    title: 'Activity Type',
    dataIndex: 'activity_type',
    render: (text) => text || '\u2014',
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    width: 120,
    render: (_, record) => {
      return ACTIVITY_STATUS.find((i) => i.value === record?.status)?.label;
    },
  },
];

const dashboardItems = (permissions) => {
  const data = [
    {
      label: 'Statistics',
      value: 'statistics',
      permission: true,
    },
    {
      label: 'All Leads',
      value: 'allLeads',
      permission: permissions?.Sales?.children?.Leads?.read,
    },
    {
      label: 'Policy Sold',
      value: 'policiessold',
      permission: permissions?.Policy?.read,
    },
    {
      label: 'Leads Graph',
      value: 'leadgraph',
      permission: permissions?.Sales?.children?.Leads?.read,
    },
    {
      label: 'Policy Renewals',
      value: 'policyrenewals',
      permission: permissions?.Policy?.read,
    },
    {
      label: 'Client Graph',
      value: 'clientgraph',
      permission: permissions?.Client?.read,
    },
    // {
    //   label: 'Employee Graph',
    //   value: 'employeegraph',
    //   permission: permissions?.Employee?.read,
    // },
    // { label: 'Reports', value: 'reports' },
    // { label: 'Pos Online Leads', value: 'posOnlineLeads' },
    // { label: 'Upcoming Events', value: 'upcomingEvents' },
    // { label: 'Upcoming Meeting', value: 'upcomingmeetings' },
    // { label: 'In Progress Task', value: 'inprogresstask' },
    // { label: 'Pos Requests', value: 'posrequests' },
    // { label: 'Login Count', value: 'loginCount' },
  ];
  return data?.filter((item) => item?.permission === true);
};

const hrDashboardItems = (permissions) => {
  const data = [
    {
      label: 'Statistics',
      value: 'statistics',
      permission: true,
    },
    {
      label: 'Attendance',
      value: 'attendance',
      permission: permissions?.HR?.children?.['View Attendance']?.read,
    },
    {
      label: 'Upcoming Events',
      value: 'upcomingEvents',
      permission: true,
    },
    {
      label: 'Meeting',
      value: 'meeting',
      permission: permissions?.HR?.children?.Meeting?.read,
    },
    {
      label: 'Leaves Request',
      value: 'leaveRequests',
      permission: permissions?.HR?.childre?.['Leave Request']?.read,
    },
    {
      label: 'Absense',
      value: 'absense',
      permission: permissions?.HR?.children?.['View Attendance']?.read,
    },
    {
      label: 'Assets Allocation',
      value: 'assetsAllocation',
      permission: permissions?.HR?.children?.['Asset Allocation']?.read,
    },
  ];
  return data?.filter((item) => item?.permission === true);
};

const employeeItems = (permissions) => {
  const data = [
    {
      label: 'Statistics',
      value: 'statistics',
      permission: true,
    },
    {
      label: 'My Performance',
      value: 'myPerformance',
      permission: permissions?.HR?.children?.Performance?.read,
    },
    {
      label: 'Assigned Leads',
      value: 'assignedLeads',
      permission: permissions?.Sales?.children?.Leads?.read,
    },
    {
      label: 'All Leads',
      value: 'allLeads',
      permission: permissions?.Sales?.children?.Leads?.read,
    },
    {
      label: 'Policy Sold',
      value: 'policiessold',
      permission: permissions?.Policy?.read,
    },
    {
      label: 'Leads Graph',
      value: 'leadgraph',
      permission: permissions?.Sales?.children?.Leads?.read,
    },
    {
      label: 'Policy Renewals',
      value: 'policyrenewals',
      permission: permissions?.Policy?.read,
    },
    {
      label: 'Client Graph',
      value: 'clientgraph',
      permission: permissions?.Client?.read,
    },
    // {
    //   label: 'Employee Graph',
    //   value: 'employeegraph',
    //   permission: permissions?.Employee?.read,
    // },
    // { label: 'Login Count', value: 'loginCount' },
  ];
  return data?.filter((item) => item?.permission === true);
};

const adminLayout = [
  { i: 'statistics', x: 0, y: 0, w: 12, h: 2.5, minW: 4, maxW: 10, minH: 9, maxH: 10 },

  { i: 'allLeads', x: 0, y: 0, w: 12, h: 9, minW: 8, maxW: 10, minH: 10, maxH: 14 },

  { i: 'policiessold', x: 0, y: 1, w: 6, h: 10, minW: 8, maxW: 10, minH: 8, maxH: 10 },
  { i: 'leadgraph', x: 6, y: 1, w: 6, h: 10, minW: 8, maxW: 10, minH: 8, maxH: 10 },

  { i: 'policyrenewals', x: 0, y: 2, w: 12, h: 8, minW: 8, maxW: 10, minH: 8, maxH: 10 },

  { i: 'clientgraph', x: 0, y: 3, w: 6, h: 8, minW: 8, maxW: 10, minH: 8, maxH: 10 },
  // { i: 'employeegraph', x: 6, y: 3, w: 6, h: 8, minW: 8, maxW: 10, minH: 8, maxH: 10 },

  // { i: 'posrequests', x: 6, y: 1, w: 6, h: 8.5, minW: 6, maxW: 10, minH: 8, maxH: 10 },
  // { i: 'reports', x: 0, y: 1, w: 6, h: 8.5, minW: 12, maxW: 12, minH: 10, maxH: 12 },

  // { i: 'posOnlineLeads', x: 0, y: 2, w: 6, h: 8, minW: 2, maxW: 10, minH: 9, maxH: 18 },

  // { i: 'inprogresstask', x: 8, y: 2, w: 6, h: 8.5, minW: 6, maxW: 10, minH: 9, maxH: 10 },
  // { i: 'loginCount', x: 0, y: 5, w: 6, h: 8, minW: 6, maxW: 10, minH: 6, maxH: 10 },
  // { i: 'upcomingmeetings', x: 0, y: 5, w: 6, h: 8.5, minW: 6, maxW: 10, minH: 6, maxH: 10 },
  // { i: 'upcomingEvents', x: 6, y: 5, w: 6, h: 9.5, minW: 6, maxW: 10, minH: 8, maxH: 12 },
];

const hrLayout = [
  { i: 'statistics', x: 8, y: 0, w: 6, h: 9, minW: 4, maxW: 6, minH: 8, maxH: 10 },
  { i: 'upcomingEvents', x: 6, y: 1, w: 6, h: 9, minW: 6, maxW: 10, minH: 8, maxH: 12 },
  { i: 'meeting', x: 0, y: 1, w: 6, h: 9, minW: 6, maxW: 10, minH: 9, maxH: 10 },
  { i: 'leaveRequests', x: 6, y: 2, w: 6, h: 9, minW: 6, maxW: 10, minH: 6, maxH: 10 },
  { i: 'absense', x: 0, y: 2, w: 6, h: 9, minW: 4, maxW: 5, minH: 6, maxH: 10 },
  { i: 'assetsAllocation', x: 0, y: 2, w: 6, h: 9, minW: 6, maxW: 10, minH: 6, maxH: 10 },
];

const employeeLayout = [
  { i: 'statistics', x: 0, y: 0, w: 12, h: 3, minW: 4, maxW: 10, minH: 9, maxH: 10 },
  { i: 'allLeads', x: 0, y: 0, w: 12, h: 9, minW: 8, maxW: 10, minH: 10, maxH: 14 },
  { i: 'policiessold', x: 0, y: 1, w: 6, h: 10, minW: 8, maxW: 10, minH: 8, maxH: 10 },
  { i: 'myPerformance', x: 0, y: 2, w: 6, h: 8.5, minW: 6, maxW: 10, minH: 6, maxH: 10 },
  { i: 'assignedLeads', x: 0, y: 2, w: 6, h: 8.5, minW: 4, maxW: 5, minH: 6, maxH: 10 },
  { i: 'leadgraph', x: 6, y: 1, w: 6, h: 10, minW: 8, maxW: 10, minH: 8, maxH: 10 },
  { i: 'policyrenewals', x: 6, y: 1, w: 6, h: 8.5, minW: 6, maxW: 10, minH: 8, maxH: 10 },
  { i: 'clientgraph', x: 6, y: 1, w: 6, h: 8.5, minW: 6, maxW: 10, minH: 8, maxH: 10 },
  // { i: 'employeegraph', x: 6, y: 3, w: 6, h: 8, minW: 8, maxW: 10, minH: 8, maxH: 10 },
];

export { employeeLayout, hrLayout, adminLayout, employeeItems, hrDashboardItems, dashboardItems };
