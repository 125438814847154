/** external deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin, Tooltip } from 'antd';

/** internal deps */
import { SecureComponent } from 'permission-provider';
import { ContentHeader, ViewData, TableComponent } from 'components';
import { breadCrumbs, formatViewData, productColumns } from './data';
import { elementData } from 'elements';
import { getInsuranceCategoryObj } from 'service/api-service';
import CustomDetails from './CustomDetails';
import { rowCommonClass } from 'utils/common';

const colors = [
  'bg-red/50',
  'bg-yellow/50',
  'bg-green/50',
  'bg-primary/50',
  'bg-red-90/50',
  'bg-green-60/50',
];

const ViewClassDetails = ({ permissions = {} }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  /** internal deps */
  const { id } = useParams();

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await getInsuranceCategoryObj({ id });
    if (resp?.data?.status) {
      setData(resp?.data?.data);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className='flex items-center justify-center h-64'>
        <Spin />
      </div>
    );
  }

  return (
    <div className='grid gap-3'>
      <ContentHeader data={breadCrumbs} showSearch={false} />
      <ViewData
        permissions={permissions}
        id={elementData.insurance_category.permissions.view}
        data={formatViewData(data)}
        classes='bg-white rounded-2xl p-4'
        showGoToBack={true}
        goBack={() => navigate(-1)}
        valueClasses='!text-s font-medium'
        titleClasses='!text-xs font-normal'
      />
      <TableComponent
        loading={loading}
        getRowClassName={rowCommonClass}
        columns={[
          ...productColumns,
          {
            title: 'Documents',
            key: 'doc',
            align: 'right',
            render: (_, record) => (
              <div className='flex flex-wrap justify-end'>
                {record?.docs?.map((item, key) => (
                  <Tooltip
                    title={item}
                    className={`w-8 h-8 flex items-center justify-center border rounded-full p-1 m-1 ${colors[key]}`}
                  >
                    {item.slice(0, 2)}
                  </Tooltip>
                ))}
                <CustomDetails fetchData={fetchData} {...record} />
              </div>
            ),
          },
        ]}
        dataSource={data?.product_type}
        showPagination={false}
      />
    </div>
  );
};

export default SecureComponent(ViewClassDetails);
