import { FormContainer, SearchAPI, SelectInput, TextInput, Button, CustomModal } from 'components';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddCustomer } from 'screens';
import { getBranches } from 'service/api-service';
import { prepareFormFieldsData } from 'utils';

const CustomerDetails = ({ handleFormNavigation, formData, setFormData, editRecord }) => {
  const navigate = useNavigate();
  const [isAddingCustomer, setAddingCustomer] = useState(false);

  const editedData = {
    customer_id: editRecord?.customer,
    contact: editRecord?.contact,
    assign_to_employee: {
      name: editRecord?.employee?.name,
      id: editRecord?.employee?.id,
    },
    branch: editRecord?.branch,
    inquiry: editRecord?.inquiry,
    description: editRecord?.des,
  };

  const toggleAddingCustomer = () => {
    setAddingCustomer((pre) => !pre);
  };

  const quoteCustomerFields = {
    'Customer Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Client Name',
          name: 'customer_id',
          id: 'customer_id',
          placeholder: 'Client Name',
          type: 'search',
          required: true,
          component: SearchAPI,
          extraRightRender: () => (
            <button type='button' onClick={toggleAddingCustomer}>
              <span className='text-primary-90'>+ Create Customer</span>
            </button>
          ),
        },
        {
          label: 'Contact Name',
          name: 'contact',
          id: 'contact',
          placeholder: 'Contact Name',
          type: 'search',
          component: SearchAPI,
        },
        {
          label: 'Assign To ',
          name: 'assign_to_employee',
          id: 'assign_to_employee',
          placeholder: 'Assign To',
          type: 'search',
          component: SearchAPI,
          required: true,
        },
        {
          label: 'Branch',
          name: 'branch',
          id: 'branch',
          placeholder: 'Branch',
          type: 'select',
          component: SelectInput,
          options: [],
          fetchOptions: getBranches,
          required: true,
        },
        {
          label: 'Inquiry',
          name: 'inquiry',
          id: 'inquiry',
          placeholder: 'Inquiry',
          type: 'search',
          component: SearchAPI,
        },
        {
          label: 'Description',
          name: 'description',
          id: 'description',
          placeholder: 'Write description here...',
          type: 'text',
          component: TextInput,
          isMultiline: true,
          rows: 3,
          maxLength: 300,
          divClasses: 'col-span-full',
        },
      ],
    },
  };

  const onNextClick = (data) => {
    setFormData((pre) => ({ ...pre, ...data }));
    handleFormNavigation();
  };

  const onCancelAdd = useCallback(() => {
    navigate(-1);
  }, []);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: quoteCustomerFields,
    defaultValues: formData,
  });

  return (
    <div>
      <div className='flex justify-between px-2'>
        <div className='flex flex-col px-2'>
          <span className='text-l 2xl:text-xxl font-medium'>Customer Details</span>
          <span className='text-xxs text-grey-80 '>
            {'To add customer details enter the details in the input field below.'}
          </span>
        </div>
      </div>
      <FormContainer
        returnFormatted={false}
        accordionClass='grid grid-cols-2 lg:grid-cols-3 gap-8'
        fields={finalFields}
        initialValues={editRecord ? editedData : initialValues}
        onSubmit={onNextClick}
        customSubmitRender={({ isSubmitting, submitForm, isValid }) => {
          return (
            <div className='bg-white flex flex-row justify-end py-4 mt-6'>
              <Button
                text='Cancel'
                variant='text'
                type='reset'
                classes='border border-gray-90 text-black px-6'
                onClick={onCancelAdd}
              />
              <Button
                // text={state?.initialValues ? "Update Contact" : "Add Contact"}
                text='Next'
                type='button'
                variant='primary'
                classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                onClick={submitForm}
                loading={isSubmitting}
                disabled={!isValid}
              />
            </div>
          );
        }}
      />

      <CustomModal
        destroyOnClose={true}
        footer={''}
        open={isAddingCustomer}
        onCancel={toggleAddingCustomer}
      >
        <AddCustomer
          onCancel={toggleAddingCustomer}
          onSuccess={toggleAddingCustomer}
          showBackButton={false}
          renderRequiredOnly={true}
        />
      </CustomModal>
    </div>
  );
};

export default CustomerDetails;
