import React, { useEffect, useState } from 'react';
import { CustomModal, DateTime, FormContainer, TableComponent, TextInput, Toast } from 'components';
import { addOfficeEvent, getOfficeEventData } from 'service/api-service';
import { UpcomingOfficeEvents_Cols } from './data';
import { rowCommonClass } from 'utils/common';
import Button from 'components/button';
import { NOTIFICATION_MSG } from 'constants';
import { prepareFormFieldsData } from 'utils';

const CustomEvents = (props = {}) => {
  const date = props?.date || '';
  /* states */
  const [loading, setLoading] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const [showEventModal, setShowEventModal] = useState(false);

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDateTime = () => ({
    disabledHours: () => {
      return [...range(0, 9), ...range(20, 24)];
    },
  });

  const addEventFields = {
    events: {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Date and Time (For Event)',
          name: 'date',
          id: 'date',
          type: 'text',
          placeholder: 'YYYY-MM-DD  HH:MM',
          disablePrev: true,
          component: DateTime,
          showTime: true,
          disabledDateTime: disabledDateTime,
          required: true,
        },
        {
          label: 'Event Name',
          name: 'title',
          id: 'title',
          placeholder: 'Event Name',
          type: 'text',
          required: true,
          component: TextInput,
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addEventFields,
  });

  /* func */
  const fetchData = async (pageNum = 1) => {
    setLoading(true);
    const resp = await getOfficeEventData({ date });
    if (resp) {
      setdataSource(resp?.data?.data?.result);
    }
    setLoading(false);
  };

  const onAddEvent = async (data) => {
    setLoading(true);
    const payload = {
      title: data?.title,
      date: data?.date,
    };
    const resp = await addOfficeEvent({
      data: payload,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      fetchData();
      toggleAddEvent();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const addEvent = () => {
    setShowEventModal((pre) => !pre);
  };
  const toggleAddEvent = () => {
    setShowEventModal((pre) => !pre);
  };

  // console.info('first', dataSource);
  // const handlePagination = (pageNum) => (pageNum?.current ? fetchData(pageNum?.current) : '');

  useEffect(() => {
    fetchData();
  }, [date]);
  return (
    <div className='space-y-4 bg-white p-4 border rounded-md border-grey-40'>
      <div className='flex items-start justify-between'>
        <div className='font-semibold text-s leading-6'>Office Events</div>
        <div>
          <Button classes='!py-1' text='Add Event' onClick={addEvent} />
        </div>
      </div>
      <div className='customTable' id='dashboard'>
        <TableComponent
          getRowClassName={rowCommonClass}
          loading={loading}
          // pageSize='10'
          columns={UpcomingOfficeEvents_Cols}
          dataSource={dataSource}
          // onChange={handlePagination}
          showPagination={false}
          scroll={{ y: 175 }}
        />
      </div>
      <CustomModal
        destroyOnClose={true}
        open={showEventModal}
        onCancel={toggleAddEvent}
        footer={''}
        width={600}
      >
        <h1 className='text-xl font-semibold text-center'>Add Event</h1>
        <FormContainer
          accordionClass='grid gap-8'
          onSubmit={onAddEvent}
          initialValues={initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6 gap-4'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    toggleAddEvent();
                  }}
                />
                <Button
                  text='Save'
                  variant='primary'
                  classes={('ml-6 px-8 bg-black', { 'bg-grey': isSubmitting })}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </CustomModal>
    </div>
  );
};

export default CustomEvents;
