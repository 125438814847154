import { TextInput, DateTime, SelectInput } from 'components';

const fieldArray = [
  {
    label: 'Employee Name',
    name: 'asset_name',
    id: 'employee_name',
    placeholder: 'Employee Name',
    type: 'text',
    required: true,
    component: TextInput,
    maxLength: 30,
    divClasses: 'col-span-full',
  },
  {
    label: 'Asset Name',
    name: 'purchase_from',
    id: 'asset',
    placeholder: 'Asset Name',
    type: 'text',
    required: true,
    component: TextInput,
    maxLength: 30,
  },
  {
    label: 'Request Date',
    name: 'request_date',
    id: 'request_date',
    placeholder: 'MM-DD-YYYY',
    type: 'date',
    required: true,
    showTime: false,
    component: DateTime,
  },
  {
    label: 'Status',
    name: 'status',
    id: 'status',
    placeholder: 'Status',
    required: true,
    type: 'select',
    component: SelectInput,
    options: [
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Returned',
        value: 'returned',
      },
      {
        label: 'Approved',
        value: 'approved',
      },
    ],
  },
  {
    label: 'Asset Type',
    name: 'assetType',
    id: 'assetType',
    placeholder: 'Asset Type',
    required: true,
    type: 'select',
    component: SelectInput,
    options: [
      {
        label: 'Asset Type 1',
        value: 'asset_type1',
      },
      {
        label: 'Asset Type 2',
        value: 'asset_type2',
      },
      {
        label: 'Asset Type 3',
        value: 'asset_type3',
      },
    ],
  },
];

const addAssetsFields = {
  assets: {
    showAccordionHeader: false,
    fields: fieldArray,
  },
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Your Asset Request',
    path: '/asset-request',
  },
];

export { addAssetsFields, breadcrumbObj };
