import { useState } from 'react';
import { Button, CustomModal, FormContainer, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
import { postCommissionPos, updateCommission } from 'service/api-service';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { InsurerFields } from './data';

const AddCommission = ({
  open,
  toggle,
  setLoading,
  loading,
  editInitials,
  refresh,
  commissionSet,
  id,
}) => {
  const [options, setOptions] = useState([]);
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: InsurerFields({ setOptions, options }),
  });
  const onAddCommission = async (payload) => {
    setLoading(true);
    const data = {
      ...payload,
      comm_set: commissionSet,
      [commissionSet]: id,
    };
    const resp = await postCommissionPos({ data });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      refresh();
      toggle();
    } else {
      const head = resp?.data?.msg;
      Object.keys(head).forEach((key) => {
        Toast('error', NOTIFICATION_MSG.error, `${key} : ${head[key]}` || 'Please try again');
      });
    }
    setLoading(false);
  };

  const onEditCommission = async (data) => {
    setLoading(true);
    const editedFields = getEditedFields({
      initialValues: editInitials,
      updatedData: data,
    });
    if (Object.keys(editedFields).length) {
      const resp = await updateCommission({
        data: data,
        id: editInitials?.id,
      });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        refresh();
        toggle();
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to update data');
      }
    } else {
      return Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
    setLoading(false);
  };

  return (
    <CustomModal
      title={editInitials ? 'Update Commission' : 'Add Commission'}
      destroyOnClose={true}
      open={open}
      onCancel={toggle}
      footer={''}
    >
      <div>
        <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 text-grey-80'>
          {editInitials
            ? 'Edit fields below'
            : 'To add a new Commission, enter the details of the Commission in the input field below.'}
        </span>
        <FormContainer
          accordionClass='grid grid-cols-2  gap-8'
          onSubmit={editInitials ? onEditCommission : onAddCommission}
          initialValues={editInitials ? editInitials : initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    toggle();
                  }}
                />
                <Button
                  id={
                    editInitials
                      ? elementData.earningSetting.permissions.update
                      : elementData.earningSetting.permissions.create
                  }
                  text={editInitials ? 'Update Commission' : 'Add Commission'}
                  variant='primary'
                  classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </div>
    </CustomModal>
  );
};

export default AddCommission;
