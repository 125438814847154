import { Spin } from 'antd';
import { Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { targetsManagement } from 'service/api-service';

const TargetList = (props) => {
  const { assign_by, assignTo, assign_date, departmentName } = props;
  return (
    <ol className='border-l border-grey-90 last:border-0 group mx-2 mt-3'>
      <li className='ml-4 pb-6 relative -top-2 group-last:pb-0'>
        <div
          className={`absolute w-4 h-4 rounded-full mt-1 -left-6 border-2 bg-primary-light border-primary-90`}
        />

        <div className='flex justify-between'>
          <h3 className='text-xxs font-semibold text-gray-900 dark:text-white'>{departmentName}</h3>
        </div>
        <p className='mb-1 text-xxs normal-case text-grey-90'>
          <span className='text-grey-90 text-x'>
            {assign_by},assign target to {assignTo} on {assign_date} .
          </span>
        </p>
      </li>
    </ol>
  );
};

const Target = () => {
  const [loading, setLoading] = useState(false);
  const [targetData, setTargetData] = useState();
  const onGetTarget = async () => {
    setLoading(true);
    const resp = await targetsManagement({ target_id: null });
    if (resp?.data?.status) {
      setTargetData(resp?.data?.data?.result);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    onGetTarget();
  }, []);

  const targetDataSection =
    Array.isArray(targetData) && targetData.length && targetData.slice(0, 3);
  return (
    <div>
      <div className='min-h-[300px]  border-2 border-grey-70 rounded-lg bg-white flex flex-col   p-2 m-0'>
        <div>
          <p className='font-medium text-sm mb-3'>Targets</p>
        </div>
        {loading ? (
          <Spin size='large' />
        ) : (
          Array.isArray(targetDataSection) &&
          targetDataSection.length &&
          targetDataSection.map((ele, i) => {
            return (
              <div key={i}>
                <TargetList
                  assign_by={ele?.assign_by?.name}
                  assignTo={ele?.employee?.name}
                  assign_date={ele?.assign_date}
                  loading={loading}
                  departmentName={ele?.department?.name}
                />
              </div>
            );
          })
        )}
        <Link
          to={{
            pathname: '/targets',
          }}
        >
          <p className='text-primary-90 font-semibold'>View All Details</p>
        </Link>
      </div>
    </div>
  );
};

export default Target;
