import { TextInput } from 'components';

export const sendEmailFields = {
  email: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Client Email',
        name: 'email',
        id: 'email',
        placeholder: 'Type Email',
        type: 'email',
        component: TextInput,
        options: [],
      },
      {
        label: 'Subject',
        name: 'subject',
        id: 'subject',
        placeholder: 'Enter Subject',
        type: 'text',
        component: TextInput,
        options: [],
      },
    ],
  },
};
