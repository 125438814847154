import React, { useEffect, useState } from 'react';
import { IconButton, TableComponent, CustomModal, FormContainer, Button, Toast } from 'components';
import { useNavigate, useLocation } from 'react-router-dom';
import { policyColumns, customerTransactionFields } from './data';
import { CustomerTransactions } from 'service/api-service';
import { elementData } from 'elements';
import { prepareFormFieldsData } from 'utils';
import { NOTIFICATION_MSG } from 'constants';
import { BackArrow } from 'assets/icons';
import { rowCommonClass } from 'utils/common';

const Transactions = (permissions = {}) => {
  const [transactionData, setTransactionData] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  let navigate = useNavigate();
  const policydetails = state?.policyRecord;

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: customerTransactionFields,
    defaultValues: { number: 1, amount: policydetails.premium },
    fieldsToExclude: policydetails && policydetails?.sell_by !== 'pos' ? ['pos_comm'] : [],
  });

  /* funcs */
  const toggleModal = () => setshowModal((pre) => !pre);

  /* get Transaction */
  const getCustomerTransaction = async () => {
    setLoading(true);
    const resp = await CustomerTransactions({ method: 'get', policy_id: state?.policyRecord?.id });
    if (resp?.data?.status) {
      setTransactionData(resp?.data?.data);
      setLoading(false);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to add document.');
    }
  };

  /* Add Transaction */
  const handleAddTransaction = async (data) => {
    setLoading(true);
    const finalPayload = {
      ...data,
      policy: Number(policydetails?.id),
    };
    const resp = await CustomerTransactions({ method: 'post', data: finalPayload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Payment added Successful');
      setLoading(false);
      getCustomerTransaction();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to add payment.');
    }
    toggleModal();
  };

  useEffect(() => {
    getCustomerTransaction();
  }, []);

  // const onChange = () => {
  //   console.log('searching....');
  // };

  return (
    <div>
      <div className='bg-white rounded-2xl rounded-b-none p-4 flex justify-between items-center'>
        <div className='flex flex-row gap-2'>
          <IconButton onClick={() => navigate(-1)} className='group top-1 flex items-center'>
            <BackArrow svgClass='fill-grey-90 w-8 h-8 group-hover:fill-primary-90 transition-all  duration-500 group-hover:-translate-x-1' />
            <span className='text-xxs text-grey-90 group-hover:text-primary-90 font-medium'>
              Go back
            </span>
          </IconButton>

          <div className='flex flex-row gap-4 items-center justify-center'>
            <span className='font-semibold text-l'>Transactions</span>{' '}
            <div className='pt-0.5 font-medium'>
              Policy No.{' '}
              <button
                className='text-primary-90 '
                onClick={() => {
                  navigate(`/view-policy/${policydetails?.id}`, {
                    state: { initialValues: policydetails },
                  });
                }}
              >
                {policydetails?.policy_num}
              </button>
            </div>
          </div>
        </div>
        <div className='flex gap-4'>
          {/* <SearchComponent onChange={onChange} /> */}
          <Button
            permissions={permissions}
            id={elementData.transactions.permissions.create}
            text='+ New Transaction'
            variant='primary'
            classes='xl:px-6'
            onClick={toggleModal}
          />
        </div>
      </div>

      <TableComponent
        loading={loading}
        getRowClassName={rowCommonClass}
        columns={policyColumns}
        dataSource={transactionData}
      />

      <CustomModal
        title={'Add new transaction'}
        destroyOnClose={true}
        open={showModal}
        onCancel={toggleModal}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10  '>
            To add a new transaction, fill the details in the input field below.
          </span>

          <FormContainer
            accordionClass='grid grid-cols-2 gap-8'
            fields={finalFields}
            initialValues={initialValues}
            onSubmit={handleAddTransaction}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleModal();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    // permissions={permissions}
                    text={'Add Transaction'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default Transactions;
