/** external deps */
import React, { useRef, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
/** internal deps */
import { Checkbox, Toast } from 'components';
import { Filter as FilterIcon } from 'assets/icons';
import { NOTIFICATION_MSG } from 'constants';

const Filter = ({
  allColumns,
  stroke,
  activeColumns,
  updateActiveColumns,
  minFields = 5,
  maxFields = 8,
  removeValFrom = [],
  customStyle,
}) => {
  const [filterVisible, setFilterVisible] = useState(false);
  /* closes filter when click outside */
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setFilterVisible(false);
        }
      };
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const colRemove = allColumns.filter(function (value, index) {
    return removeValFrom.indexOf(index) === -1;
  });

  const sortedCols = colRemove.toSorted((a, b) => a.title.localeCompare?.(b.title));

  const checkboxChangeHandler = (e, data) => {
    if (e.target.checked) {
      if (activeColumns.length >= maxFields) {
        Toast(
          'error',
          NOTIFICATION_MSG.error,
          `Atleast ${maxFields} field Filter should be present`,
        );
        e.target.checked = false;
        return;
      }
      updateActiveColumns((prev) => [...prev, data]);
    } else {
      if (activeColumns.length <= minFields) {
        Toast(
          'error',
          NOTIFICATION_MSG.error,
          `Atleast ${minFields} field Filter should be present`,
        );
        e.target.checked = true;
        return;
      }
      updateActiveColumns((prev) => prev.filter((item) => item.id !== data.id));
    }
  };

  return (
    <div ref={wrapperRef} className={`relative self-center pt-2 ${customStyle}`}>
      <Tooltip placement='top' title='Filter' color='#474747'>
        <button className='w-[19px]' onClick={() => setFilterVisible((prevState) => !prevState)}>
          <span className='sr-only'>Filters</span>
          <FilterIcon
            stroke={stroke}
            className={`max-w-full max-h-full fill-white ${stroke ? ' -mt-4' : ''} `}
          />
        </button>
      </Tooltip>
      <div
        className={`absolute z-10 -translate-x-1/2 left-1/2 top-[150%] bg-white shadow-custom rounded-md py-4 px-2 w-max gap-3 ${
          filterVisible ? 'grid' : 'hidden'
        }`}
        // onMouseLeave={() => setFilterVisible(false)}
      >
        <p className='font-medium'>Fields Filters</p>
        <ul className='grid overflow-y-auto overflow-x-hidden gap-2 px-2 max-h-44'>
          {sortedCols.map((item) => (
            <li key={item.id} className='flex gap-6 justify-between items-center'>
              <p className='text-xxs'>{item.title}</p>
              <Checkbox
                checked={activeColumns.includes(item)}
                onChange={(e) => checkboxChangeHandler(e, item)}
                updateSpanClass=' -translate-y-1/2'
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Filter;
