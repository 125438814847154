/** external deps */
import React, { useState } from 'react';

/** internal deps */
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { ContentHeader, TabsComponent } from 'components';
import CreateExpense from './CreateExpense';
import ExpenseType from './ExpenseType';
import { breadcrumbObj } from './data';
import { generatePermittedTabs } from 'utils';

const CompanyExpense = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showTypeModal, setTypeModal] = useState(false);
  const tabs = [
    {
      key: 'exp_type',
      id: elementData.expense_type.permissions.view,
      label: `Expense Type`,
      Component: ExpenseType,
      props: {
        permissions: permissions,
        id: elementData.expense_type.permissions.view,
        showTypeModal: showTypeModal,
        setTypeModal: setTypeModal,
      },
    },
    {
      key: 'com_exp',
      id: elementData.company.permissions.view,
      label: `Company Expense`,
      Component: CreateExpense,
      props: {
        permissions: permissions,
        id: elementData.company.permissions.view,
        showAddModal: showAddModal,
        setAddModal: setAddModal,
      },
    },
  ];

  // const [key, setActiveTabKey] = useState(
  //   getKeyOfNextIfPrevIsEmpty(generatePermittedTabs(tabs, permissions)),
  // );
  const [key, setActiveTabKey] = useState('exp_type');
  /* func */
  const toggleAdd = () => {
    setTypeModal(true);
  };
  const toggleAddExp = () => {
    setAddModal(true);
  };
  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        showSearch={false}
        title={<p className='font-semibold text-s'>Company Expense</p>}
        // data={breadcrumbObj}
        btnText={key === 'exp_type' ? '+ Expense Type' : '+ Expense'}
        buttonId={
          key === 'exp_type'
            ? elementData?.expense_type?.permissions?.create
            : elementData?.company?.permissions?.create
        }
        onClick={key === 'exp_type' ? toggleAdd : toggleAddExp}
      />
      <TabsComponent
        items={generatePermittedTabs(tabs, permissions)}
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
      />
    </div>
  );
};

export default SecureComponent(CompanyExpense);
