// export const passwordFields = {
//     Fields: {
//         showAccordionHeader: false,
//         passwordFields: [
//             {
//                 label: "Old Password",
//                 name: "old_password",
//                 id: "old_password",
//                 placeholder: "Old Password",
//                 type: "password",
//                 required: true,
//                 // component: PasswordInput,
//                 maxLength: 30,
//             },
//             {
//                 label: "New Password",
//                 name: "new_password",
//                 id: "new_password",
//                 placeholder: "New Password",
//                 type: "password",
//                 required: true,
//                 // component: PasswordInput,
//                 maxLength: 30,
//             },
//             {
//                 label: "Confirm Password",
//                 name: "cPassword",
//                 id: "cPassword",
//                 placeholder: "Confirm Password",
//                 type: "password",
//                 required: true,
//                 // component: PasswordInput,
//                 maxLength: 30,
//             },
//         ]
//     }
// }

import { PasswordInput } from 'components';

// export default passwordFields;
export const passwordFields = {
  'Password Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Old Password',
        name: 'old_password',
        id: 'old_password',
        placeholder: 'Old Password',
        type: 'password',
        required: true,
        component: PasswordInput,
        maxLength: 30,
      },
      {
        label: 'New Password',
        name: 'new_password',
        id: 'new_password',
        placeholder: 'New Password',
        type: 'password',
        required: true,
        component: PasswordInput,
        maxLength: 30,
      },
      {
        label: 'Confirm Password',
        name: 'confirm_password',
        id: 'confirm_password',
        placeholder: 'Confirm Password',
        type: 'password',
        required: true,
        component: PasswordInput,
        maxLength: 30,
      },
    ],
  },
};
