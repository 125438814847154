/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Spin } from 'antd';
/** internal deps */
import { IconButton, Toast, Module, ContentHeader, MultiEmail } from 'components';
import { BackArrow } from 'assets/icons';
import { LabelValue } from 'screens/quotation-engine/components';
import { NOTIFICATION_MSG } from 'constants';
import { getPermission } from 'utils';
import { elementData } from 'elements';
import { useStore } from 'store/context';
import { getTickets, updateTicket } from 'service/api-service';

const { TextArea } = Input;

const TicketReview = (props = {}) => {
  const permissions = props?.permissions || [];
  const [loading, setLoading] = useState(false);
  const [ticketsData, setTicketsData] = useState();
  const [remark, setRemark] = useState('');
  const { id } = useParams();
  const [opened, dispatch] = useStore((state) => state.dialerOpened);

  const navigate = useNavigate();
  /** funcs */
  const getFaqTickets = useCallback(async () => {
    setLoading(true);
    const response = await getTickets({ id: id });
    if (response?.data?.status) {
      setTicketsData(response?.data?.data);
    }
    setLoading(false);
  }, [id]);

  const handleUpdateStatus = useCallback(
    async (status) => {
      const state = status ? 'close' : 'open';
      const data = {
        status: state,
        remarks: remark,
      };
      const resp = await updateTicket({ id: id, data });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
    },
    [id, remark],
  );

  useEffect(() => {
    getFaqTickets();
  }, []);

  const call = useCallback(
    (e) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (opened) {
        dispatch({ type: 'DIALER_CLOSE' });
      } else {
        dispatch({ type: 'DIALER_OPEN', contact: ticketsData?.customer?.contact_number });
      }
    },
    [opened, ticketsData],
  );

  if (loading) {
    return (
      <div className='flex items-center justify-center h-64'>
        <Spin />
      </div>
    );
  }

  return (
    <>
      {getPermission(permissions, elementData.ticket.permissions.view)?.read ? (
        <div className='bg-white py-3 mt-4 divide-y divide-grey-40'>
          <ContentHeader
            permissions={permissions}
            btnText={ticketsData?.status === 'open' && 'Close'}
            showSearch={false}
            buttonId={elementData?.ticket?.permissions?.update}
            onClick={handleUpdateStatus.bind(this, true)}
            title={
              <IconButton
                onClick={() => navigate(-1)}
                className='group top-1 flex items-center mt-2'
              >
                <BackArrow svgClass='fill-grey-90 w-8 h-8 group-hover:fill-primary-90 transition-all  duration-500 group-hover:-translate-x-1' />
                Ticket Details
              </IconButton>
            }
            children={
              <>
                <Button onClick={call} type='primary'>
                  Call
                </Button>
                <MultiEmail
                  emailData={ticketsData?.customer?.email_address || ''}
                  type={'ticket'}
                />
              </>
            }
          />
          <div className='gap-4 w-full grid grid-cols-5 p-4 divide-x divide-grey-40'>
            <div className=''>
              <LabelValue
                label='Client Name'
                value={ticketsData?.customer?.customer_name}
                vertical
              />
              <LabelValue label='Email' value={ticketsData?.customer?.email_address} vertical />
              <LabelValue label='Phone' value={ticketsData?.customer?.contact_number} vertical />
            </div>
            <div className='col-span-4 px-4'>
              <p className='font-medium text-left text-m capitalize'>{ticketsData?.title}</p>
              <p className='text-secondary-70 text-left text-s custom-truncate'>
                {ticketsData?.description}
              </p>
            </div>
          </div>
          {ticketsData?.status === 'open' && (
            <div className='py-2 px-4'>
              <p>Remarks</p>
              <div className='flex items-center gap-4'>
                <TextArea
                  rows={4}
                  placeholder='Remarks...'
                  maxLength={200}
                  onChange={(e) => setRemark(e.target.value)}
                />
                <IconButton
                  permissions={permissions}
                  id={elementData?.ticket?.permissions?.update}
                  onClick={handleUpdateStatus.bind(this, false)}
                >
                  <Button type='primary'>Save</Button>
                </IconButton>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Module />
      )}
    </>
  );
};

export default TicketReview;
