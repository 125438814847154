import React from 'react';
import { ConfigProvider, Divider, Input, Select, Spin } from 'antd';
import TextInput from 'react-autocomplete-input';
import { tags } from 'constants/hr';
import './styles.css';
const tagsOptions = tags.map((tag) => ({
  label: <p className='capitalize'>{tag.substr(1, tag.length - 2).replace(/_/g, ' ')}</p>,
  value: tag.substr(1, tag.length - 2),
}));

const Template = ({ state, updateState, loading }) => {
  if (loading) {
    return (
      <div className='flex items-center justify-center h-64'>
        <Spin />
      </div>
    );
  }
  return (
    <div className='flex h-full'>
      <ConfigProvider
        theme={{
          token: {
            colorBorder: '#D1EAF9',
            activeBorderColor: '#D1EAF9',
            inputFontSize: 12,
            multipleItemBorderColor: '#D1EAF9',
          },
        }}
      >
        <div className='bg-white w-1/2 p-4 h-full overflow-auto'>
          <Divider className='!my-1 ' orientation='left' orientationMargin={0} plain>
            <p className='text-xs font-bold'>Meta Info</p>
          </Divider>

          <div className='grid grid-cols-2 gap-4 items-center'>
            <div className='mb-4 text-xxs'>
              <label>Letter Name</label>
              <Input
                className='bg-[#F5FAFD] border border-[#D1EAF9] text-xxs'
                placeholder='Letter Name...'
                onChange={updateState('name')}
                value={state?.name}
              />
            </div>
            <div id='tagSelect' className='mb-3 text-xxs'>
              <label>Tags</label>
              <Select
                placeholder='Tags...'
                onChange={updateState('fields')}
                showArrow={true}
                value={state?.fields || []}
                options={tagsOptions}
                mode='multiple'
                className='w-full bg-[#F5FAFD] border border-[#D1EAF9] text-xxs  rounded-md p-1 h-[2.688rem]  max-h-12 overflow-y-auto'
              />
            </div>
          </div>
          <Divider className='!my-1' orientation='left' orientationMargin={0} plain>
            <p className='text-xs font-bold'>Template Info</p>
          </Divider>
          <div className='text-xxs'>
            <label>Heading</label>
            <Input
              placeholder='Heading...'
              className='bg-[#F5FAFD] border border-[#D1EAF9] text-xxs !placeholder:text-xxs'
              onChange={updateState('heading')}
              value={state?.heading}
            />
          </div>
          <div className='mt-4 relative text-xxs'>
            <label>Upper Text</label>
            <TextInput
              className='bordered w-full h-32 p-4 bg-[#F5FAFD] border border-[#D1EAF9] text-xxs !placeholder:text-xxs'
              onChange={updateState('upper_text')}
              trigger={['[']}
              options={{ '[': tags }}
              value={state?.upper_text}
              maxOptions={tags.length}
            />
          </div>
          <div className='mt-4 text-xxs'>
            <label>Subject</label>
            <Input
              placeholder='Subject...'
              className='bg-[#F5FAFD] border border-[#D1EAF9] text-xxs !placeholder:text-xxs'
              onChange={updateState('subject')}
              value={state?.subject}
            />
          </div>
          <div className='mt-4 text-xxs'>
            <label>Respect Line</label>
            <Input
              className='bg-[#F5FAFD] border border-[#D1EAF9] text-xxs !placeholder:text-xxs'
              placeholder='Respect Line...'
              onChange={updateState('request_line')}
              value={state?.request_line}
            />
          </div>
          <div className='mt-4 relative text-xxs'>
            <label>Descriptions</label>
            <TextInput
              className='bg-[#F5FAFD] border border-[#D1EAF9] text-xxs !placeholder:text-xxs w-full h-44 p-4'
              trigger={['[']}
              options={{ '[': tags }}
              onChange={updateState('description')}
              value={state?.description}
              maxOptions={tags.length}
            />
          </div>
          <div className='mt-4 relative'>
            <label className='text-xxs'>Bottom Text</label>
            <TextInput
              className='bg-[#F5FAFD] border border-[#D1EAF9] text-xxs !placeholder:text-xxs w-full h-32 p-4'
              trigger={['[']}
              options={{ '[': tags }}
              onChange={updateState('bottom_text')}
              value={state?.bottom_text}
              maxOptions={tags.length}
            />
          </div>
        </div>

        <div className='bg-grey-90 h-full overflow-auto p-4 flex-1'>
          <div className='bg-white p-8 pb-16 bordered min-h-full'>
            <div className='font-semibold text-l text-center p-4'> {state?.heading}</div>
            <div className='mt-4 text-right'>Date/Place:</div>
            <div className='mt-4 whitespace-pre-line	'>{state?.upper_text}</div>

            <div className='mt-4 text-center'>{state?.subject}</div>
            <div className='mt-6'>{state?.request_line}</div>

            <div className='mt-4 whitespace-pre-line'>{state?.description}</div>

            <div className='mt-8 whitespace-pre-line'>{state?.bottom_text}</div>
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default Template;
