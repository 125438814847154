/** external deps */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
/** internal deps */
import { DELETE, EDIT, Trash, View } from 'assets/icons';
import {
  CustomModal,
  Filter,
  FormContainer,
  IconButton,
  TableComponent,
  Toast,
  Button,
} from 'components';
import { elementData } from 'elements';
import { taskManagement, meetingManagement } from 'service/api-service';
import { Context } from 'store/context';
import { formatDate, getEditedFields, getPermission, handleDefaultValues } from 'utils';
import {
  createMeetingFields,
  createMeetingInitials,
  createTaskFields,
  createTaskInitials,
  activityTypes,
  createTaskSlicedFields,
  createMeetingSlicedFields,
} from './data';
import { NOTIFICATION_MSG } from 'constants';
import './style.css';
import { rowCommonClass } from 'utils/common';

const TaskMeeting = ({
  customerName,
  employeeName,
  permissions = [],
  taskColumns = [],
  meetingColumns = [],
  showActions = false,
  showAddButtons = true,
  // showFilters = false,
  customer_id,
  employee_id,
  pageSize = 10,
  showTotal = true,
  onRowClick,
  scroll = false,
  showPagination,
  showFilter = false,
  allColumns,
  filterCol,
  setFilterCol,
  MIN_FIELDS,
  custFieldName = 'customer_id',
  disableCustomer = true,
}) => {
  const [modalType, setModalType] = useState('task');
  const [showModal, setShowModal] = useState(false);
  const [viewInitials, setViewInitials] = useState();
  const [editInitials, setEditInitials] = useState();
  const [showViewModal, setShowViewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tableColumns, setColumns] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(
    // getPermission(permissions, elementData.task.name)?.read ? "tasks" : "meetings"
    'tasks',
  );
  const [tasks, setTasks] = useState({});
  const [meetings, setMeetings] = useState({});
  const [filteredTableData, setFilteredTableData] = useState();
  // const [selectedFilter, setFilter] = useState(ACTIVITY_STATUS[0]);
  const { user } = useContext(Context);
  const tabClass =
    "relative transition-all after:transition-all after:duration-300 hover:text-primary-90 after:content-[''] after:block after:m-auto after:w-0 after:border-b-2 hover:after:border-primary-90 after:mt-2 hover:after:w-full cursor-pointer top-[1px]";

  // filter
  const finalInitial = {
    ...(modalType === 'task'
      ? {
          ...createTaskInitials,
          assign_to_employee: handleDefaultValues({ user }),
        }
      : {
          ...createMeetingInitials,
          assign_to_employee: handleDefaultValues({ user }),
        }),
    customer_id: customerName,
    assign_to_employee: [employeeName?.name],
  };

  /** funcs */
  const toggleModal = (type = 'task') => {
    setModalType(type);
    setShowModal((pre) => !pre);
  };

  const toggleViewModal = () => setShowViewModal((pre) => !pre);
  const toggleDeleteModal = () => setShowDeleteModal((pre) => !pre);
  const handleTabChange = (isTask = false) => setActiveTab(isTask ? 'tasks' : 'meetings');

  const handleView = (record) => {
    setEditInitials();
    setViewInitials(record);
    toggleViewModal();
  };

  const onEditClick = useCallback((record) => {
    const assginToEmployee = record?.assign_to_employee?.map((item) => item?.id);
    setEditInitials({ ...record, assign_to_employee: assginToEmployee });
    toggleViewModal();
  }, []);

  const handleEdit = async (updatedData) => {
    setLoading(true);
    const data = getEditedFields({ initialValues: editInitials, updatedData });
    const finalData = {
      ...data,
      assign_to_employee: data.assign_to_employee
        ? [...data.assign_to_employee, JSON.parse(employee_id)]
        : updatedData?.assign_to_employee,
    };
    const id = updatedData?.id;
    if (!id) return;
    if (Object.keys(data).length) {
      const resp = await (updatedData?.activity === activityTypes.TASK
        ? taskManagement({ data: finalData, id, method: 'patch' })
        : meetingManagement({ data: finalData, id, method: 'patch' }));
      if (resp?.data?.status) {
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          `${activeTab === 'tasks' ? 'Task' : 'Meeting'} updated successfully`,
        );
        activeTab === 'tasks' ? onGetTasks() : onGetMeetings();
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      toggleViewModal();
      setLoading(false);
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit some fields to update');
    }
  };

  const handleDelete = async () => {
    const id = selectedRow?.id;
    if (!id) return;
    setLoading(true);
    const resp = await (selectedRow?.activity === activityTypes.TASK
      ? taskManagement({ id, method: 'delete', customer_id })
      : meetingManagement({ id, method: 'delete', customer_id }));
    if (resp?.data?.status) {
      Toast(
        'success',
        NOTIFICATION_MSG.success,
        `${selectedRow?.activity === activityTypes.TASK ? 'Task' : 'Meeting'} deleted successfully`,
      );
      selectedRow?.activity === activityTypes.TASK ? onGetTasks() : onGetMeetings();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteModal();
    setLoading(false);
  };

  const handlePageChange = (pageNum) =>
    activeTab === 'tasks' ? onGetTasks(pageNum) : onGetMeetings(pageNum);

  /** task management api's */
  const handleCreateTask = async (updatedData) => {
    const data = getEditedFields({ initialValues: finalInitial, updatedData });

    const finalPayload = {
      ...data,
      customer_id: customer_id || updatedData?.customer_id,
      employee_id: employee_id ? employee_id : null,
      assign_to_employee: employee_id
        ? [JSON.parse(employee_id), ...updatedData?.assign_to_employee]
        : [...updatedData?.assign_to_employee],
      to_date: formatDate({ dateString: data.to_date }),
      from_date: formatDate({ dateString: data.from_date }),
    };

    const resp = await taskManagement({
      data: finalPayload,
      method: 'post',
      customer_id,
      employee_id,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Task created Successful');
      onGetTasks();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to create task.');
    }
    toggleModal(modalType);
  };

  const onGetTasks = async ({ page = 1 } = {}) => {
    setLoading(true);
    const resp = await taskManagement({ method: 'get', customer_id, employee_id, page });
    if (resp.data?.status) {
      const list = resp.data?.data?.result;
      const total = resp.data?.data.total_page;
      const page = resp.data?.data.current_page;
      setTasks({ list, total, page });
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to fetch tasks from server');
    }
    setLoading(false);
  };

  /** meeting management api's */
  const onGetMeetings = async ({ page = 1 } = {}) => {
    setLoading(true);
    const resp = await meetingManagement({ method: 'get', customer_id, employee_id, page });
    if (resp?.data?.status) {
      const list = resp.data?.data.result;
      const total = resp.data?.data.total_page;
      const page = resp.data?.data.current_page;
      setMeetings({ list, total, page });
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to fetch meetings from server');
    }
    setLoading(false);
  };

  const handleCreateMeeting = async (updatedData) => {
    const data = getEditedFields({ initialValues: finalInitial, updatedData });
    const finalPayload = {
      ...data,
      to_date: formatDate({ dateString: data.to_date }),
      customer_id: customer_id || updatedData?.customer_id,
      employee_id: employee_id ? employee_id : null,
      assign_to_employee: employee_id
        ? [JSON.parse(employee_id), ...updatedData?.assign_to_employee]
        : [...updatedData?.assign_to_employee],
    };

    const resp = await meetingManagement({
      data: finalPayload,
      method: 'post',
      customer_id,
      employee_id,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      setActiveTab('meetings');
      onGetMeetings();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to create Meeting.');
    }
    toggleModal(modalType);
  };

  useEffect(() => {
    onGetTasks();
    onGetMeetings();
  }, []);

  console.log('editInitials', editInitials);

  useEffect(() => {
    const actions = [
      showActions
        ? {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (_, record) => (
              <div className='flex justify-center items-center'>
                <Space size='middle'>
                  <IconButton
                    title='View'
                    permissions={permissions}
                    id={
                      activeTab === 'tasks'
                        ? elementData.task.permissions.view
                        : elementData.meeting.permissions.view
                    }
                    onClick={() => {
                      handleView(record);
                    }}
                    className='group disabled:cursor-not-allowed'
                  >
                    <View svgClass='stroke-primary group-disabled:stroke-grey' />
                  </IconButton>
                  <IconButton
                    title='Delete'
                    permissions={permissions}
                    id={
                      activeTab === 'tasks'
                        ? elementData.task.permissions.delete
                        : elementData.meeting.permissions.delete
                    }
                    onClick={() => {
                      setSelectedRow(record);
                      toggleDeleteModal();
                    }}
                    className='group disabled:cursor-not-allowed'
                  >
                    <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                  </IconButton>
                  <IconButton
                    title='Edit'
                    permissions={permissions}
                    id={
                      activeTab === 'tasks'
                        ? elementData.task.permissions.update
                        : elementData.meeting.permissions.update
                    }
                    className='group disabled:cursor-not-allowed'
                    onClick={onEditClick.bind(this, record)}
                  >
                    <EDIT />
                  </IconButton>
                </Space>
              </div>
            ),
          }
        : {},
    ];
    setColumns([...(activeTab === 'tasks' ? taskColumns : meetingColumns), ...actions]);
    // setFilter(ACTIVITY_STATUS[0]);
    setFilteredTableData();
  }, [activeTab]);

  return (
    <main className='min-h-table'>
      <div className='bg-white p-4 rounded-2xl rounded-b-none flex justify-between items-center '>
        <div className='flex gap-6 border-b border-grey-70'>
          {/* {getPermission(permissions, elementData.task.name)?.read && ( */}
          <span
            className={`text-xs ${tabClass} ${
              activeTab === 'tasks'
                ? 'text-primary-90 after:border-primary-90 after:w-full'
                : 'text-black'
            }  `}
            onClick={() => handleTabChange(true)}
          >
            Tasks
          </span>
          {/* )} */}

          {/* {getPermission(permissions, elementData.meeting.name)?.read && ( */}
          <span
            className={`text-xs ${tabClass} ${
              activeTab === 'meetings'
                ? 'text-primary-90 after:border-primary-90 after:w-full'
                : 'text-black'
            }`}
            onClick={() => handleTabChange(false)}
          >
            Meetings
          </span>
          {/* )} */}
        </div>

        <div className='flex gap-4'>
          {showFilter && (
            <Filter
              allColumns={allColumns}
              activeColumns={filterCol}
              updateActiveColumns={setFilterCol}
              minFields={MIN_FIELDS}
              maxFields={8}
            />
          )}
          {showAddButtons && (
            <>
              <Button
                id={elementData.task.permissions.create}
                permissions={permissions}
                text='+ Task'
                variant='transparent'
                classes='xl:px-6'
                onClick={() => {
                  toggleModal('task');
                  setActiveTab('tasks');
                }}
              />
              <Button
                id={elementData.meeting.permissions.create}
                permissions={permissions}
                text='+ Meeting'
                variant='primary'
                classes='xl:px-6'
                onClick={() => {
                  toggleModal('meeting');
                  setActiveTab('meetings');
                }}
              />
            </>
          )}
          {/* {!showAddButtons &&
            showFilters &&
            getPermission(
              permissions,
              activeTab === "tasks" ? elementData.task.name : elementData.meeting.name
            )?.read && (
              <Select
                className="min-w-[9.375rem] group border border-grey-90 hover:border-primary-90 hover:text-primary-90 rounded px-2"
                popupClassName="min-w-24"
                dropdownAlign={{ offset: [-8, 12] }}
                suffixIcon={<DropdownIcon svgClass={"w-2 group-hover:fill-primary-90"} />}
                options={ACTIVITY_STATUS}
                onSelect={onStatusFilter}
                value={selectedFilter}
              />
            )} */}
        </div>
      </div>

      {getPermission(permissions, elementData.task.name)?.read && activeTab === 'tasks' && (
        <TableComponent
          showTotal={showTotal}
          pageSize={pageSize}
          loading={loading}
          getRowClassName={rowCommonClass}
          noDataAction={onGetTasks}
          columns={tableColumns}
          dataSource={filteredTableData ? filteredTableData : tasks?.list}
          total={tasks?.total}
          page={tasks?.page}
          onChange={handlePageChange}
          onRowClick={onRowClick}
          scroll={scroll}
          showPagination={showPagination}
        />
      )}

      {getPermission(permissions, elementData.meeting.name)?.read && activeTab === 'meetings' && (
        <TableComponent
          onRowClick={onRowClick}
          showTotal={showTotal}
          pageSize={pageSize}
          loading={loading}
          getRowClassName={rowCommonClass}
          noDataAction={onGetMeetings}
          columns={tableColumns}
          dataSource={filteredTableData ? filteredTableData : meetings?.list}
          total={meetings?.total}
          onChange={handlePageChange}
          showPagination={showPagination}
          page={tasks?.page}
        />
      )}

      {/* Add Task/Meeting Modal */}
      <CustomModal
        destroyOnClose={true}
        open={showModal}
        onCancel={() => toggleModal(modalType)}
        footer={''}
        title={modalType === 'task' ? 'Add Task' : 'Add Meeting'}
      >
        <div>
          <span className='text-xxs 2xl:text-sm 2xl:pb-10  '>
            {modalType === 'task'
              ? 'To add a new task, enter the details in the input field below.'
              : ' To add a new meeting, enter the details in the input field below.'}
          </span>
          <FormContainer
            onSubmit={modalType === 'task' ? handleCreateTask : handleCreateMeeting}
            initialValues={finalInitial}
            fields={
              modalType === 'task'
                ? createTaskFields({
                    disableCustomer: disableCustomer,
                    custFieldName: custFieldName,
                  })
                : createMeetingFields({
                    disableCustomer: disableCustomer,
                    custFieldName: custFieldName,
                  })
            }
            accordionClass='grid grid-cols-2  gap-8'
            customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleModal(modalType);
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      modalType === 'task'
                        ? elementData.task.permissions.create
                        : elementData.meeting.permissions.create
                    }
                    text={modalType === 'task' ? 'Add Task' : 'Add Meeting'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* View/Edit Task/Meeting Modal */}
      <CustomModal
        destroyOnClose={true}
        open={showViewModal}
        onCancel={() => toggleViewModal(modalType)}
        footer={''}
        title={
          activeTab === 'tasks'
            ? `${editInitials ? 'Edit' : 'View'} Task`
            : `${editInitials ? 'Edit' : 'View'} Meeting`
        }
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 text-grey-80 '>
            {activeTab === 'tasks'
              ? 'To create a new task, enter the details in the input field below.'
              : ' To create a new meeting, enter the details in the input field below.'}
          </span>
          <FormContainer
            disableForm={editInitials ? false : true}
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={handleEdit}
            initialValues={editInitials ? editInitials : viewInitials}
            fields={
              activeTab === 'tasks'
                ? editInitials
                  ? createTaskFields()
                  : createTaskSlicedFields()
                : editInitials
                ? createMeetingFields()
                : createMeetingSlicedFields()
            }
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, disableForm }) => {
              return (
                <div className='flex justify-end items-center py-4 mt-6 '>
                  {viewInitials && viewInitials?.description && (
                    <Tooltip title={viewInitials?.description}>
                      <InfoCircleOutlined className='text-xs' />
                    </Tooltip>
                  )}
                  <div className='bg-white flex flex-row justify-end'>
                    {editInitials ? (
                      <>
                        <Button
                          text='Cancel'
                          variant='text'
                          type='reset'
                          classes='border border-gray-90 text-black px-6'
                          onClick={() => {
                            resetForm();
                            toggleViewModal(modalType);
                          }}
                        />
                        <Button
                          disabled={disableForm || !isValid}
                          id={
                            activeTab === 'tasks'
                              ? elementData.task.permissions.update
                              : elementData.meeting.permissions.update
                          }
                          text={'Update'}
                          variant='primary'
                          classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                          onClick={submitForm}
                          loading={loading}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* Delete modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={
          <div className=' flex flex-row justify-center  bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              text={activeTab === 'tasks' ? 'Delete Task' : 'Delete Meeting'}
              variant='primary'
              classes={`ml-6 px-8   
               bg-black
              `}
              onClick={handleDelete}
              loading={loading}
            />
          </div>
        }
        width={450}
      >
        <div className='flex justify-center flex-col items-center gap-4'>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-xs text-grey-90 pb-3'>
            {`Could you please confirm that you really wish to delete the ${
              activeTab === 'tasks' ? 'task' : 'meeting'
            }?`}
          </span>
        </div>
      </CustomModal>
    </main>
  );
};

export default TaskMeeting;
