import { endpoints } from 'constants/endpoints';
import Request from './client';

export const addEmployee = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.employee.create,
    data,
  });
  return resp;
};

export const updateEmployee = async ({ data, id }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.employee.delete(id),
    data,
  });
  return resp;
};

export const getEmployees = async ({ query = '' } = {}) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.getEmployees.employee(query),
  });
  return resp;
};

export const getEmployee = async ({ id }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.getEmployee.employeeId(id),
  });
  return resp;
};

export const getAllEmployees = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.getAllEmployees.searchEmployee,
  });
  return resp;
};

export const deleteEmployee = async ({ id }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.employee.delete(id),
    data: { is_active: false },
  });
  return resp;
};

export const getEmployeePermissions = async ({ id }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.employee.permissions(id),
  });
  return resp;
};

export const updateEmployeePermissions = async ({ id, data }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.employee.permissions(id),
    data,
  });
  return resp;
};

export const EmployeeDocument = async (props = { data: '', method: 'get', id: '' }) => {
  const resp = await Request({
    method: props.method,
    API:
      props.method === 'post'
        ? endpoints.employee.create_doc
        : endpoints.employee.list_doc(props?.id),
    data: props?.data,
  });
  return resp;
};

export const EmployeeExperience = async (
  props = { data: '', method: 'get', id: '', employee_id: '', query: '' },
) => {
  const resp = await Request({
    method: props.method,
    API:
      props.method === 'delete' || props.method === 'patch'
        ? endpoints.employeeExperience.deletePatch(props?.id)
        : endpoints.employeeExperience.employee_id(props?.employee_id),
    data: props?.data,
  });
  return resp;
};

export const employeeById = async ({ employee_id }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.employeeById.employee_id(employee_id),
  });

  return resp;
};

export const getEmployeePerformanceCount = async ({ id }) => {
  return await Request({
    method: 'get',
    API: endpoints.employee.performance_count(id),
  });
};

export const getEmployeeLeads = async ({ id }) => {
  return await Request({
    method: 'get',
    API: endpoints.employee.leads(id),
  });
};

export const employeeLeaveStats = async ({ id }) => {
  return await Request({
    method: 'get',
    API: endpoints.employee.leaves(id),
  });
};

export const getTimeline = async () => {
  const resp = await Request({
    method: 'get',
    API: `employee/activity`,
  });
  return resp;
};

export const taskMeetings = async ({ employee_id = '' }) => {
  return await Request({
    method: 'get',
    API: `employee/task-meeting/${employee_id}`,
  });
};

// nonPerformingEmployeeManagement

export const getNonPerformingEmployee = async () => {
  const resp = await Request({
    method: 'get',
    API: `employee/non-performing`,
  });
  return resp;
};

export const postNonPerformingEmployee = async (props = { data: '' }) => {
  const resp = await Request({
    method: 'post',
    API: `employee/non-performing`,
    data: props?.data,
  });
  return resp;
};

export const deleteNonPerformingEmployee = async (props = { id: '' }) => {
  const resp = await Request({
    method: 'delete',
    API: `employee/non-performing/${props?.id}`,
  });
  return resp;
};

export const patchNonPerformingEmployee = async (props = { id: '', data: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `employee/non-performing/${props?.id}`,
    data: props?.data,
  });
  return resp;
};

export const getTotalTargets = async ({ start, end, id, branch_id }) => {
  return await Request({
    method: 'get',
    API: `employee/targets?id=${id}`,
  });
};

export const getLeadTimeline = async ({ model, model_id }) => {
  const resp = await Request({
    method: 'get',
    API: `employee/activity?model=${model || ''}&model_id=${model_id || 0}`,
  });
  return resp;
};
