import React, { useState, useEffect, useContext } from 'react';
import { Button, Space } from 'antd';
import { MultiUserInitial, TableComponent } from 'components';
import User from './User';
import { getEmployeeLeads } from 'service/api-service';
import { Context } from 'store/context';
import { useNavigate } from 'react-router-dom';
import { getPermission } from 'utils';
import { elementData } from 'elements';
import ShowMoreArrayData from '../ShowMoreArrayData';
import { rowCommonClass } from 'utils/common';

const AssignedLeads = (props = {}) => {
  const permissions = props?.permissions || [];
  const [loading, setLoading] = useState(false);
  const { user } = useContext(Context);
  const [leadsData, setLeadsData] = useState([]);
  const navigate = useNavigate();

  const getAllLeads = async () => {
    setLoading(true);
    const resp = await getEmployeeLeads({ id: user?.id });
    if (resp?.data?.status) {
      setLeadsData(resp?.data?.data?.result);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllLeads();
  }, []);

  const columns = [
    {
      key: 'name',
      title: 'Employee',
      dataIndex: 'assigned_to',
      render: (_, record) => (
        <User
          name={record?.assigned_to?.name ? record?.assigned_to?.name : '_'}
          image={record?.assigned_to?.image}
        />
      ),
    },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      render: (_, record) => {
        let arrayData =
          record &&
          record?.ins_cat_id.map((ele) => {
            return ele.name;
          });
        return <ShowMoreArrayData data={arrayData} />;
      },
    },
    {
      key: 'type',
      title: 'Product Type',
      dataIndex: 'products',
      render: (_, record) => {
        const productArray = record?.products?.map((item) => item?.product_type);
        let arrayData =
          record &&
          productArray?.map((ele) => {
            return { name: ele?.name };
          });
        return (
          <div className='truncate'>
            <MultiUserInitial showImage data={arrayData} value={'name'} label={'Products'} />
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <Button
            onClick={() => {
              navigate(`/lead/edit/${record?.id}/1`);
            }}
            className='bg-[#FFF8DD] border-none !text-[#FFC839] font-semibold text-x px-2'
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className='flex flex-col justify-between mt-1 gap-3 pb-2 mb-2'>
      <div className='flex flex-row items-start justify-between'>
        <div className='font-semibold text-s leading-6'>Assign Leads</div>
      </div>
      <div className='customTable' id='dashboard'>
        <TableComponent
          rowKey='key'
          pageSize='3'
          loading={loading}
          columns={columns}
          dataSource={leadsData}
          showPagination={false}
          getRowClassName={rowCommonClass}
          scroll={{ y: 200 }}
        />
      </div>
    </div>
  );
};

export default AssignedLeads;
