import React, { useEffect, useState } from 'react';
import { DatePicker, Calendar, Button } from 'antd';
import dayjs from 'dayjs';

import { LineHeading, Toast } from 'components';
import { SecureComponent } from 'permission-provider';
import { ClockIcon } from 'assets/icons';
import DateModal from './dateModal';

import './style.css';
import { NOTIFICATION_MSG } from 'constants';
import clsx from 'clsx';
import { attendanceMarkEmp, getMyAttendance } from 'service/api-service';

const status = ['present', 'absent', 'leave', 'weekend', 'holiday'];

const MyAttendance = ({ permissions = {} }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [showDateModal, setShowDateModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [data, setData] = useState();

  const getTodayAttendance = (data?.data || []).find(
    ({ date }) => date === dayjs().format('YYYY-MM-DD'),
  );

  const fullCellRender = (value) => {
    const listData = data?.data?.find(({ date }) => date === dayjs(value).format('YYYY-MM-DD'));

    return (
      <div
        onClick={() => {
          if (listData) {
            setModalData(listData);
            setShowDateModal(true);
          }
        }}
        className='p-0.5'
      >
        <div
          className={clsx('border p-4 border-grey-20 rounded-md', {
            'bg-green-200': listData?.status === 'present',
            'bg-red-200': listData?.status === 'absent',
            'bg-yellow-200': listData?.status === 'leave',
            'bg-gray-200': listData?.status === 'weekend',
            'bg-purple-200': listData?.status === 'holiday',
          })}
        >
          {value.format('DD')}
        </div>
      </div>
    );
  };

  const fetchData = async ({ date }) => {
    const start = date.startOf('month').format('YYYY-MM-DD');
    const end = date.endOf('month').format('YYYY-MM-DD');
    try {
      const res = await getMyAttendance({ start, end });
      if (res?.data?.data) {
        setData(res?.data.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const markAttendance = async () => {
    const todayDate = dayjs().format('YYYY-MM-DD');
    const todayTime = dayjs().format('HH:mm:ss');

    const keyName = getTodayAttendance?.check_in ? 'check_out' : 'check_in';

    const resp = await attendanceMarkEmp({
      data: {
        [keyName]: todayTime,
        date: todayDate,
      },
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Attendance Marked');
      fetchData({ date: dayjs() });
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  const handleOnDateChange = async (e) => {
    setSelectedDate(e);
    fetchData({ date: e });
  };

  const toggleDateModal = () => {
    setShowDateModal((prev) => !prev);
  };

  useEffect(() => {
    fetchData({ date: dayjs() });
  }, []);

  return (
    <div className='grid gap-3'>
      <div className='flex flex-col bg-white shadow-custom rounded-lg py-4 px-6'>
        <div className='flex flex-row items-center justify-between w-full '>
          <div>
            <LineHeading title='My Attendance' />
          </div>
          <div className='flex items-center gap-2'>
            {data?.shift &&
              selectedDate?.format('MM') === dayjs().format('MM') &&
              !getTodayAttendance?.check_out && (
                <div>
                  <Button
                    onClick={() => markAttendance()}
                    className={`text-white !border-none font-medium  ${
                      getTodayAttendance?.check_in ? 'bg-grey-50' : 'bg-green-60'
                    }`}
                  >
                    {getTodayAttendance?.check_in ? 'Check out' : 'Check In'}
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>

      <div className='flex items-center justify-between bg-white shadow-custom rounded-lg py-2 px-6'>
        <div className='flex items-center gap-4'>
          <DatePicker.MonthPicker
            label='select date'
            className='bg-[#F5FAFD] border-[#D1EAF9] '
            defaultValue={dayjs()}
            picker='date'
            allowClear={false}
            onChange={handleOnDateChange}
          />
          <div className='flex flex-row gap-2 col-span-3 text-xs items-center'>
            <ClockIcon className='!w-2 !h-2' /> {data?.shift?.name} :
            {!data?.shift && <p className='text-primary-90'>No Shift Assigned To User</p>}
            {data?.shift && (
              <div className='flex items-center gap-2'>
                <p className='text-primary-90'>
                  {data?.shift?.start || '--'} to {data?.shift?.end || '--'}
                </p>
                <p>
                  Duration : <span className='text-primary-90'>{data?.shift?.duration}</span>
                </p>
              </div>
            )}
          </div>
        </div>

        <div className='flex gap-4 items-center'>
          {status.map((value) => {
            return (
              <div key={value} className='flex items-center gap-2'>
                <div
                  className={clsx('h-3 w-3 rounded-md', {
                    'bg-green-200': value === 'present',
                    'bg-red-200': value === 'absent',
                    'bg-yellow-200': value === 'leave',
                    'bg-gray-200': value === 'weekend',
                    'bg-purple-200': value === 'holiday',
                  })}
                />
                <p className='capitalize'>{value}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className='bg-white shadow-custom rounded-lg overflow-hidden'>
        <Calendar
          fullCellRender={fullCellRender}
          mode='month'
          className='rounded-cl shadow-custom !text-center attendanceCalendar'
          headerRender={() => null}
          style={{ padding: 20 }}
        />
      </div>
      <DateModal open={showDateModal} data={modalData} handleModal={toggleDateModal} />
    </div>
  );
};

export default SecureComponent(MyAttendance);
