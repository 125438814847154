/** external deps */
import { Progress } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
/** internal deps */
import { FormContainer } from 'components';
import Button from 'components/button';
import { addReportFilterFormFields } from './data';
import { prepareFormFieldsData } from 'utils';

const ReportFilter = ({ setForm, setFormData, formData }) => {
  const { state } = useLocation();
  const isEditing = state?.initialValues ? true : false;

  const [initialValues, finalFormFields] = prepareFormFieldsData({
    fields: addReportFilterFormFields,
    defaultValues: formData,
  });
  const handleAddReport = async (data) => {
    let obj = Object.assign(formData, data);
    setFormData(obj);
    setForm('chart');
  };

  const onEditSubmit = async (data) => {
    console.log('updated', data);
  };

  return (
    <div className='bg-white  flex flex-col p-4 rounded-lg'>
      <div className='relative mx-32 pb-4'>
        <div className='absolute text-xxs font-medium top-5 -left-9 '>Report Details</div>
        <Progress percent={51} strokeColor={'#26CBED'} showInfo={false} />
        <div className='absolute text-xxs font-medium top-5 inset-x-1/2 text-primary-90'>
          Filters
        </div>
        <div className='absolute bg-black w-4 h-4 rounded-full top-1 inset-x-1/2 z-10'></div>
        <div className='absolute text-xxs font-medium top-5 -right-9'>Select Chart</div>
      </div>
      <FormContainer
        fields={finalFormFields}
        // initialValues={isEditing ? state?.initialValues : { ...initialValues }}
        initialValues={initialValues}
        onSubmit={isEditing ? onEditSubmit : handleAddReport}
        customSubmitRender={({ isValid, isSubmitting, submitForm }) => {
          return (
            <div className='bg-white flex flex-row justify-end py-4 mt-6'>
              <Button
                text='Previous'
                variant='text'
                // type="reset"
                classes='border border-gray-90 text-black px-6'
                onClick={setForm.bind(this, 'details')}
              />
              <Button
                disabled={!isValid}
                //   id={
                //     state?.initialValues
                //       ? elementData.Report.permissions.update
                //       : elementData.Report.permissions.create
                //   }
                text={'Next'}
                classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                onClick={submitForm}
                loading={isSubmitting}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default ReportFilter;
