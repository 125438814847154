/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Space } from 'antd';
import Fuse from 'fuse.js';
/** internal deps */
import { Button, CustomModal, ContentHeader, TableComponent, IconButton, Toast } from 'components';
import { breadcrumbObj } from './data';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { useNavigate } from 'react-router-dom';
import { deleteInvoices, getInvoices } from 'service/api-service';
import InvoiceViewer from './InvoiceViewer';
import { NOTIFICATION_MSG } from 'constants';
import { rowCommonClass } from 'utils/common';

const Invoices = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredInvoices, setfilteredInvoices] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await getInvoices();
    if (resp?.data?.status) {
      const dataToBeShow = resp?.data?.data;
      setInvoiceData(dataToBeShow);
    } else Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const toggleViewModal = () => setShowViewModal((pre) => !pre);

  const columns = [
    {
      key: 'invoice_name',
      title: 'Invoice Name',
      dataIndex: 'title',
    },
    {
      key: 'sent_to',
      title: 'Sent To',
      dataIndex: 'choices',
    },
    {
      key: 'sent_to',
      title: 'Reciever Name',
      // dataIndex: "name",
      render: (obj) => obj?.sent_to?.name,
    },
    {
      key: 'invoice_date',
      title: 'Invoice Date',
      dataIndex: 'date',
    },
    {
      key: 'due_date',
      title: 'Due Date',
      dataIndex: 'due',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <InvoiceViewer
            permissions={permissions}
            id={elementData.Invoices.permissions.view}
            record={record}
          />

          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.Invoices.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.Invoices.permissions.delete}
            onClick={() => {
              setSelectedInvoice(record);
              toggleDeleteInvoice();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];
  /* funcs */
  const toggleDeleteInvoice = () => setDeleteModal((pre) => !pre);

  const onDeleteInvoice = useCallback(async () => {
    const id = selectedInvoice?.id;
    if (!id) return;
    setLoading(true);
    const resp = await deleteInvoices({ id });
    if (resp?.data?.status) {
      fetchData();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Branch deleted successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteInvoice();
    setLoading(false);
  }, [selectedInvoice]);

  const toggleAddInvoice = useCallback(() => {
    navigate('/invoices/create');
  }, []);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) {
      return setSearchQuery('');
    }
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(invoiceData, {
      keys: ['title', 'choices', 'date', 'due'],
      threshold: 0.2,
    }).search(e.target.value);
    setfilteredInvoices(fuse.map((i) => i.item));
  };

  const onEditPress = useCallback((record) => {
    navigate(`/invoices/edit/${record?.id}`);
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        data={breadcrumbObj}
        btnText='+ Create New Invoice'
        buttonId={elementData?.Invoices?.permissions?.create}
        onClick={toggleAddInvoice}
      ></ContentHeader>
      <TableComponent
        loading={loading}
        columns={columns}
        dataSource={searchQuery ? filteredInvoices : invoiceData}
        getRowClassName={rowCommonClass}
      />

      {/* show view modal */}
      <CustomModal
        open={showViewModal}
        closable={true}
        onCancel={toggleViewModal}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleViewModal}
            />
          </div>
        </div>
      </CustomModal>

      {/* delete invoice confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteInvoice}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the invoice?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteInvoice}
            />
            <Button
              text='Delete Invoice'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteInvoice}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(Invoices);
