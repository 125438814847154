import {
  CustomTextInput,
  DateTime,
  SearchAPI,
  SelectInput,
  TextInput,
  Uploadbox,
} from 'components';
import {
  fetchLocationData,
  getCityOptions,
  getContacts,
  getCountryOptions,
  getStateOptions,
} from 'data/form-data';
import {
  getBranches,
  searchApis,
  searchInsuranceApis,
  getInquiryDetails,
  getInsuranceCategory,
} from 'service/api-service';
import { deleteEmptyValues } from 'utils';

const countryOptions = getCountryOptions();

const DURATION_OPTIONS = [
  { label: '12 Months', value: '12' },
  { label: '24 Months', value: '24' },
  { label: '36 Months', value: '36' },
];

// const AmountType = [
//   { label: 'Fixed', value: 'f' },
//   { label: 'Percentage', value: 'p' },
// ];

const handleDependantFeilds = ({ formVals, field }) => {
  const customer_type = formVals?.customer?.customer_type;

  if (field?.name === 'contact') {
    if (customer_type?.name?.toLowerCase() === 'corporation') return false;

    return true;
  }
};

export const addPolicyFields = ({
  respData,
  setRespData,
  activekey,
  setPinCodeError,
  pincodeError,
}) => {
  // let isContactRequired = false;
  // const [isContactRequired,setIsContactRequired] = useState(false);
  return {
    Policy: {
      showAccordionHeader: false,
      fields: [
        {
          label: 'New Or Renewal',
          name: 'policy_type',
          id: 'policy_type',
          placeholder: 'New Or Renewal',
          type: 'select',
          component: SelectInput,
          required: activekey === 'other' ? false : true,
          options: [
            { label: 'New', value: 'new' },
            { label: 'Renewal', value: 'renewal' },
          ],
        },
        {
          dependent: true,
          dependent_on: 'policy_type',
          dependent_value: 'new',
          label: 'Proposal ID',
          name: 'quotation_id',
          id: 'proposal_id',
          placeholder: 'Proposal ID',
          type: 'select',
          required: activekey === 'other' ? false : true,
          component: SearchAPI,
          searchParams: 'lead_id',
          onSelect: async ({ option, form }) => {
            if (option?.id) {
              form?.setFieldValue('quotation_id', option);
              const resp = await getInquiryDetails({ id: option?.id });
              if (resp?.data?.status) {
                const reqId = resp?.data?.data?.s_insurer?.id + resp?.data?.data?.s_product?.id;
                const reqData = option?.quote?.find((ele) => ele?.id === JSON.stringify(reqId));
                const filteredProduct = resp?.data?.data?.products?.find((item) => item?.s_insurer);
                const product = {
                  name: filteredProduct?.product_type?.name,
                  label: filteredProduct?.product_type?.name,
                  value: filteredProduct?.product_type?.id,
                  id: filteredProduct?.product_type?.id,
                };
                const category = {
                  name: filteredProduct?.product_type?.ins_category?.name,
                  label: filteredProduct?.product_type?.ins_category?.name,
                  value: filteredProduct?.product_type?.ins_category?.id,
                  id: filteredProduct?.product_type?.ins_category?.id,
                };
                const insurer = {
                  name: filteredProduct?.s_insurer?.name,
                  label: filteredProduct?.s_insurer?.name,
                  value: filteredProduct?.s_insurer?.id,
                  id: filteredProduct?.s_insurer?.id,
                };

                form?.setFieldValue('insurer', insurer);
                form?.setFieldValue('ncb', reqData?.ncb);
                form?.setFieldValue('premium', reqData?.premium);
                form?.setFieldValue('sum_insured', reqData?.sum);
                form?.setFieldValue('insurance_category', category);
                form?.setFieldValue('product_type', product);
                form?.setFieldValue('branch', resp?.data?.data?.customer?.branch_of?.id);
              }
            }
            form?.setFieldValue('customer', option?.customer);
            form?.setFieldValue('contact', option?.contact?.id);
            form?.setFieldValue('contact_no', option?.customer?.contact_number);
            form?.setFieldValue('email_address', option?.customer?.email_address);
          },
        },
        {
          label: 'Policy Number',
          name: 'p_number',
          id: 'p_number',
          placeholder: 'Policy Number',
          type: 'text',
          component: TextInput,
          // isMultiline: true,
          // maxLength: 300,
          divClasses: '',
          // rows: 3,
        },
        {
          label: 'Attach Policy',
          name: 'doc',
          id: 'doc',
          placeholder: 'Attach Policy',
          component: Uploadbox,
          dragger: false,
          accept: ['.png, .jpeg, .jpg'],
        },
      ],
    },
    'Client Details': {
      showAccordionHeader: true,
      fields: [
        {
          label: 'Insured Name',
          name: 'customer',
          id: 'customer',
          placeholder: 'Insured Name',
          type: 'search',
          searchParams: 'corp_name',
          required: true,
          component: SearchAPI,
          // onDefaultValueSet: (data) => {
          // },
        },
        {
          label: 'Contact Name',
          name: 'contact',
          id: 'contact',
          placeholder: 'Contact Name',
          type: 'select',
          component: SelectInput,
          dependency: ['customer'],
          dependantFetchOptions: async (form) => {
            if (!form?.values?.customer) return [];
            const resp = await getContacts(form?.values?.customer?.id);
            return resp || [];
          },
          dependent: true,
          dependent_on: 'customer',
          shouldHideField: handleDependantFeilds,
        },
        {
          label: 'Contact Number',
          name: 'contact_no',
          id: 'contact_no',
          placeholder: 'Contact Number',
          // type: 'number',
          maxLength: 10,
          component: TextInput,
          required: true,
        },
        {
          label: 'Email Address',
          name: 'email_address',
          placeholder: 'Email Address',
          type: 'email',
          component: TextInput,
        },
        {
          label: 'Branch Details',
          name: 'branch',
          id: 'branch',
          placeholder: 'Branch Details',
          type: 'select',
          component: SelectInput,
          fetchOptions: async () => await getBranches(),
        },
        {
          label: 'Business Nature',
          name: 'bs_nature',
          id: 'bs_nature',
          placeholder: 'Business Nature',
          type: 'textarea',
          component: TextInput,
          isMultiline: true,
          maxLength: 300,
          divClasses: 'col-span-3',
          rows: 3,
        },
      ],
    },
    'Policy Details': {
      fields: [
        // {
        //   label: 'Policy Number',
        //   name: 'policy_num',
        //   id: 'policy_num',
        //   placeholder: 'Policy Number',
        //   type: 'text',
        //   maxLength: 30,
        //   component: TextInput,
        //   required: true,
        // },
        {
          label: 'Duration',
          name: 'duration',
          id: 'duration',
          placeholder: 'YYYY-MM-DD',
          type: 'select',
          component: SelectInput,
          options: DURATION_OPTIONS,
        },
        {
          label: 'Issue Date',
          name: 'issue_date',
          id: 'issue_date',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          component: DateTime,
          disableNext: true,
          maxLength: 30,
        },
        {
          label: 'Commence Date',
          name: 'comm_date',
          id: 'comm_date',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          component: DateTime,
          maxLength: 30,
        },
        {
          label: 'Expire Date',
          name: 'exp_date',
          id: 'exp_date',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          component: DateTime,
          required: true,
          disablePrev: true,
          maxLength: 30,
        },

        {
          label: 'Category of Insurance',
          name: 'insurance_category',
          id: 'insurance_category',
          placeholder: 'Category of Insurance',
          type: 'select',
          component: SelectInput,
          required: true,
          maxLength: 30,
          fetchOptions: async () => await getInsuranceCategory(),
        },
        {
          label: 'Product Type',
          name: 'product_type',
          id: 'product_type',
          placeholder: 'Product Type',
          type: 'select',
          required: true,
          component: SelectInput,
          dependency: ['insurance_category'],
          dependantFetchOptions: async (form) => {
            const id = form?.values?.insurance_category?.id || form?.values?.insurance_category;
            const resp = await searchInsuranceApis({ api: 'insurance_category', id });
            return resp?.data?.data[0]?.product_type?.map((i) => ({
              name: i.name,
              label: i.name,
              value: i.id,
              id: i.id,
            }));
          },
        },
        {
          label: 'Assign To ',
          name: 'employee',
          id: 'employee',
          placeholder: 'Assign To',
          type: 'search',
          component: SearchAPI,
          required: true,
        },
      ],
    },
    // 'Premium Details': {
    //   fields: [
    //     {
    //       label: 'Premium',
    //       name: 'premium',
    //       id: 'premium',
    //       placeholder: 'Premium',
    //       type: 'number',
    //       component: TextInput,
    //     },
    //     {
    //       label: 'Sum Insured',
    //       name: 'sum_insured',
    //       id: 'sum_insured',
    //       placeholder: 'Sum Insured',
    //       type: 'number',
    //       component: TextInput,
    //     },
    // {
    //   label: 'Gross Premium',
    //   name: 'g_premium',
    //   id: 'g_premium',
    //   placeholder: 'Gross Premium',
    //   type: 'number',
    //   component: CustomTextInput,
    //   customOnChange: async ({ field, value, form }) => {
    //     form?.setFieldValue('total_amt', value);
    //   },
    // },
    // {
    //   label: 'GST Premium',
    //   name: 'gst_pre',
    //   id: 'gst_pre',
    //   placeholder: 'GST Premium',
    //   type: 'number',
    //   component: CustomTextInput,
    //   customOnChange: async ({ field, value, form }) => {
    //     const premium = form?.values?.g_premium;
    //     const gst = value;
    //     const amount = (premium * gst) / 100;

    //     form?.setFieldValue('gst_pre_amt', amount);
    //     form?.setFieldValue('total_amt', premium - amount);
    //   },
    // },
    // // {
    // //   label: 'GST Premium Amount',
    // //   name: 'gst_pre_amt',
    // //   id: 'gst_pre_amt',
    // //   placeholder: 'GST Premium Amount',
    // //   type: 'number',
    // //   component: TextInput,
    // // },
    // {
    //   label: 'Other Fees',
    //   name: 'other_fees',
    //   id: 'other_fees',
    //   placeholder: 'Other Fees',
    //   type: 'number',
    //   component: CustomTextInput,
    //   customOnChange: async ({ field, value, form }) => {
    //     const g_premium = form?.values?.g_premium;
    //     const gst_pre_amt = form?.values?.gst_pre_amt;
    //     form?.setFieldValue('total_amt', g_premium - gst_pre_amt - value);
    //   },
    // },
    // {
    //   label: 'Discount Type',
    //   name: 'dis_type',
    //   id: 'dis_type',
    //   placeholder: 'Discount Type',
    //   type: 'select',
    //   component: SelectInput,
    //   options: AmountType,
    // },
    // {
    //   label: 'Discount',
    //   name: 'discount',
    //   id: 'discount',
    //   placeholder: 'Discount',
    //   type: 'number',
    //   component: TextInput,
    //   maxLength: 30,
    // },

    // {
    //   label: 'Discount Percentage',
    //   name: 'dis_per',
    //   id: 'dis_per',
    //   placeholder: 'Discount Percentage',
    //   type: 'number',
    //   component: CustomTextInput,
    //   customOnChange: async ({ field, value, form }) => {
    //     const premium = form?.values?.g_premium;
    //     const gst_pre_amt = form?.values?.gst_pre_amt;
    //     const other_fees = form?.values?.other_fees;
    //     const gst = value;
    //     const amount = (premium * gst) / 100;

    //     form?.setFieldValue('dis_amt', amount);
    //     form?.setFieldValue('total_amt', premium - gst_pre_amt - other_fees - amount);
    //   },
    // },
    // {
    //   label: 'Discount Amount',
    //   name: 'dis_amt',
    //   id: 'dis_amt',
    //   placeholder: 'Discount Amount',
    //   type: 'number',
    //   component: TextInput,
    // },
    // {
    //   label: 'Total Amount',
    //   name: 'total_amt',
    //   id: 'total_amt',
    //   placeholder: 'Total Amount',
    //   type: 'number',
    //   component: TextInput,
    // },
    // ],
    // },
    'Insurance Company Details': {
      sub_title: 'Insurance Company Details',
      fields: [
        {
          label: 'Insurer',
          name: 'insurer',
          id: 'insurer',
          placeholder: 'Insurer',
          type: 'select',
          component: SelectInput,
          required: true,
          options: [],
          fetchOptions: () => searchApis({ api: 'insurer' }),
        },
        {
          label: 'Premium Amount',
          name: 'premium',
          id: 'premium',
          placeholder: 'Premium Amount',
          type: 'number',
          component: TextInput,
        },
        {
          label: 'Sum Insured',
          name: 'sum_insured',
          id: 'sum_insured',
          placeholder: 'Sum Insured',
          type: 'number',
          component: TextInput,
        },
        {
          label: 'NCB Amount',
          name: 'ncb',
          id: 'ncb',
          placeholder: 'NCB Amount',
          type: 'number',
          component: TextInput,
        },
        {
          label: 'Gross Premium',
          name: 'g_premium',
          id: 'g_premium',
          placeholder: 'Gross Premium',
          type: 'number',
          component: CustomTextInput,
          customOnChange: async ({ field, value, form }) => {
            form?.setFieldValue('total_amt', value);
          },
        },
        {
          label: 'GST Premium',
          name: 'gst_pre',
          id: 'gst_pre',
          placeholder: 'GST Premium',
          type: 'number',
          component: CustomTextInput,
          customOnChange: async ({ field, value, form }) => {
            const premium = form?.values?.g_premium;
            const gst = value;
            const amount = (premium * gst) / 100;

            form?.setFieldValue('gst_pre_amt', amount);
            form?.setFieldValue('total_amt', premium - amount);
          },
        },
        {
          label: 'Other Fees',
          name: 'other_fees',
          id: 'other_fees',
          placeholder: 'Other Fees',
          type: 'number',
          component: CustomTextInput,
          customOnChange: async ({ field, value, form }) => {
            const g_premium = form?.values?.g_premium;
            const gst_pre_amt = form?.values?.gst_pre_amt;
            form?.setFieldValue('total_amt', g_premium - gst_pre_amt - value);
          },
        },
      ],
    },
    'Address Details': {
      sub_title: 'Billing Address',
      fields: [
        {
          label: 'Country',
          name: 'country',
          id: 'country',
          placeholder: 'Country',
          type: 'select',
          required: true,
          component: SelectInput,
          options: countryOptions,
          dependency: ['pincode'],
          customChange: (_, __, form) => {
            form?.setFieldValue('city', '');
          },
        },
        {
          label: 'State',
          name: 'state',
          id: 'state',
          placeholder: 'State',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [],
          dependency: ['country'],
          dependantFetchOptions: async (form) => {
            if (!form?.values?.country) return [];
            const country = form?.values?.country;
            const resp = await getStateOptions(country);
            if (resp) {
              return resp;
            } else return [];
          },
        },
        {
          label: 'City/Town',
          name: 'city',
          id: 'city',
          placeholder: 'City/Town',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [],
          dependency: ['country', 'state'],
          dependantFetchOptions: async (form) => {
            if (!form?.values?.country || !form?.values?.state) return [];
            const country = form?.values?.country;
            const state = form?.values?.state;
            const resp = await getCityOptions(country, state);
            if (resp) {
              return resp;
            } else return [];
          },
        },
        {
          label: 'Postal Code',
          name: 'pincode',
          id: 'pincode',
          placeholder: 'Postal Code',
          type: 'text',
          required: true,
          component: CustomTextInput,
          customOnChange: async ({ field, value, form }) => {
            const zipCode = value;
            const data = await fetchLocationData(zipCode);
            if (data) {
              form?.setFieldValue('country', data?.country);
              form?.setFieldValue('state', data?.state);
              form?.setFieldValue('city', data?.city);
            } else setPinCodeError('Enter a valid pincode');
          },
          error: pincodeError,
          maxLength: 12,
        },
        {
          label: 'Address',
          name: 'address',
          id: 'address',
          placeholder: 'Address',
          type: 'text',
          required: true,
          component: TextInput,
          maxLength: 60,
          divClasses: 'col-span-2',
        },
        // {
        //   label:
        //     'As with the Insured Billing Address, the Insured Registered address will also be the same.',
        //   name: 'checkaddress',
        //   id: 'checkaddress',
        //   type: 'checkbox',
        //   checked: true,
        //   component: Checkbox,
        //   divClasses: 'col-span-2 self-end',
        // },
      ],
    },

    // 'Registered Address': {
    //   dependent: true,
    //   dependent_on: 'checkaddress',
    //   dependent_value: false,
    //   subTitle: 'Registered Address',
    //   fields: [
    //     {
    //       label: 'Address',
    //       name: 'r_address',
    //       id: 'r_address',
    //       placeholder: 'Address',
    //       type: 'text',
    //       component: TextInput,
    //       maxLength: 60,
    //     },
    //     {
    //       label: 'State',
    //       name: 'r_state',
    //       id: 'r_state',
    //       placeholder: 'State',
    //       type: 'text',
    //       component: TextInput,
    //       maxLength: 60,
    //     },

    //     {
    //       label: 'City',
    //       name: 'r_city',
    //       id: 'r_city',
    //       placeholder: 'City',
    //       type: 'text',
    //       component: TextInput,
    //       maxLength: 60,
    //     },
    //     {
    //       label: 'Pincode',
    //       name: 'r_pincode',
    //       id: 'r_pincode',
    //       placeholder: 'Pincode',
    //       type: 'text',
    //       component: TextInput,
    //       maxLength: 60,
    //     },
    //     {
    //       label: 'Country',
    //       name: 'r_country',
    //       id: 'r_country',
    //       placeholder: 'Selected country',
    //       type: 'select',
    //       component: SelectInput,
    //       options: countries,
    //     },
    //   ],
    // showAccordionHeader: false,
    // },

    Description: {
      fields: [
        {
          label: 'Description',
          name: 'description',
          id: 'description',
          placeholder: 'Description',
          type: 'textarea',
          component: TextInput,
          isMultiline: true,
          maxLength: 300,
          divClasses: 'col-span-3',
          rows: 3,
        },
      ],
    },
  };
};

export const parsePayload = (edited) => {
  let finalPayload = {
    ...edited,
    address: {},
    // registered_address: {},
    customer: {},
    policy_info: {},
  };
  Object.keys(edited).forEach((item) => {
    // if (item === 'premium') finalPayload['premium']['policy_info'] = edited?.premium;
    // if (item === 'sum_insured') finalPayload['sum_insured']['policy_info'] = edited?.sum_insured;
    // customer
    if (item === 'customer') finalPayload['customer']['id'] = edited?.customer;
    if (item === 'contact_no') finalPayload['customer']['contact_number'] = edited?.contact_no;
    if (item === 'email_address') finalPayload['customer']['email_address'] = edited?.email_address;
    if (item === 'bs_nature') finalPayload['customer']['nature_of_business'] = edited?.bs_nature;
    // // billing address
    if (item === 'city') finalPayload['address']['city'] = edited?.city;
    if (item === 'state') finalPayload['address']['state'] = edited?.state;
    if (item === 'country') finalPayload['address']['country'] = edited?.country;
    if (item === 'address') finalPayload['address']['address'] = edited?.address;
    if (item === 'pincode') finalPayload['address']['pincode'] = edited?.pincode;
    // // registered address
    // if (item === 'r_city') finalPayload['registered_address']['r_city'] = edited?.r_city;
    // if (item === 'r_state') finalPayload['registered_address']['r_state'] = edited?.r_state;
    // if (item === 'r_country') finalPayload['registered_address']['r_country'] = edited?.r_country;
    // if (item === 'r_address') finalPayload['registered_address']['r_address'] = edited?.r_address;
    // if (item === 'r_pincode') finalPayload['registered_address']['r_pincode'] = edited?.r_pincode;
  });

  return deleteEmptyValues(finalPayload, 2);
};
