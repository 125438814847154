/** external deps */
import React, { useContext, useEffect, useState } from 'react';

/** internal deps */
import { Toast, UpcomingActivity } from 'components';
import { SecureComponent } from 'permission-provider';
import { NOTIFICATION_MSG } from 'constants';
import { Context } from 'store/context';
import Statistics from '../my-dashboard/component/DashboardEntities/Statistics';
import PerformanceGraph from './component/PerformanceGraph';
import Leave from './component/Leave';
import Target from './component/Target';
import UpcomingEvents from './component/UpcomingEvents';
import { getTimeline } from 'service/api-service';

const HrDashboard = () => {
  /** states oard = () => {
  /** states */
  const [timeLine, setTimeLine] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(Context);
  // const { Title, Text } = Typography;

  /* funcs  */
  const onGettimeline = async () => {
    setLoading(true);
    const resp = await getTimeline();
    if (resp?.data?.status) {
      setTimeLine(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    onGettimeline();
  }, []);

  return (
    <>
      <div className='grid grid-cols-8 grid-rows-6 gap-2 bg-slate-400  h-full w-full mt-3 items-start'>
        <div className=' h-fit border-2 border-grey-70 rounded-lg bg-white col-span-2 row-span-6 overflow-hidden'>
          {/* UpcomingActivity  */}
          <UpcomingActivity
            noDataAction={onGettimeline}
            loading={loading}
            timeLine={timeLine}
            heading={'Recent Activities'}
            user={user}
          />
        </div>
        <div className='  border-2 border-grey-70 rounded-lg bg-white col-span-4 row-span-3 box-border '>
          {/* section : Performance Graph , comp : Report Tab */}
          <PerformanceGraph />
        </div>
        <div className='  border-2 border-grey-70 rounded-lg bg-white col-span-2 row-span-3 overflow-hidden '>
          {/* section : statistics , comp : Target Overview */}
          <Statistics />
        </div>
        <div className='rounded-lg  col-span-2 row-span-3'>
          {/* section : leaves , Seprate Component  */}
          <Leave />
        </div>
        <div className='  col-span-2 row-span-3  overflow-hidden '>
          <Target />
        </div>
        <div className=' col-span-2  row-span-3'>
          {/* section : Upcoming Events , comp : Calendar */}
          <UpcomingEvents />
        </div>
      </div>
    </>
  );
};

export default SecureComponent(HrDashboard);
