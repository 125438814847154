import { TextInput } from 'components';
import { useEffect, useState } from 'react';

const CorporationNameWrapper = ({ form, ...props }) => {
  const [required, setRequired] = useState(false);

  useEffect(() => {
    if (form?.values && (form?.values?.customer_type.label).toLowerCase() === 'corporation') {
      setRequired(true);
    }

    if (form?.values && (form?.values?.customer_type.label).toLowerCase() !== 'corporation') {
      setRequired(false);
    }
  }, [form]);

  return (
    <div>
      <TextInput form={form} {...props} required={required} />
    </div>
  );
};

export default CorporationNameWrapper;
