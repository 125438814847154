import { useContext, useEffect } from 'react';

import { ContentHeader } from 'components';
import permissions from 'components/permissions';
import { Context } from 'store/context';
import { debounce } from 'utils';
import LeadTable from 'screens/customers/inquiry/LeadTable';

const QuotationBreadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Quotation',
    path: '',
  },
];

const Quotation = () => {
  const {
    leads: { list: leads, updateLeads },
  } = useContext(Context);
  /* state */

  useEffect(() => {
    updateLeads({ status: 'quoted' });
  }, []);

  const handleSearch = debounce(async (e) => {
    const query = e?.target?.value;
    await updateLeads({ pageNum: 1, query, status: 'quoted' });
  }, 700);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        title={<p className='font-semibold text-s'>Quotations Listing</p>}
        // data={QuotationBreadCrumbs}
        onChange={handleSearch}
      />

      {/* table comp */}

      <LeadTable permissions={permissions} tabKey={'quoted'} />
    </div>
  );
};

export default Quotation;
