/** external deps */
import React from 'react';
/** internal deps */

const MonthTabs = ({ records }) => {
  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  }

  const listMonths = records.map((element) => {
    return (
      <div className='flex flex-col bg-white rounded-lg'>
        <p className='text-primary-90 font-medium px-4 py-2'>
          {getMonthName(parseInt(element.month))}
        </p>
        <div className='flex flex-row  px-4 py-2  text-xxs mb-4'>
          <div className='flex flex-col space-y-1 w-20'>
            {element?.dates?.map((item) => (
              <span>{item}</span>
            ))}
          </div>
          <div className='flex flex-col space-y-1'>
            {element.note.map((item) => (
              <span>{item}</span>
            ))}
          </div>
        </div>
      </div>
    );
  });

  return <div className='grid grid-cols-3 gap-4'>{listMonths}</div>;
};

export default MonthTabs;
