// import User from './User';
// import { Button, Tooltip, Space } from 'antd';
// import Status from './Status';
import EventsTags from './DashboardAssets/EventsTags';

export const hrWishesColumns = [
  {
    key: 'label',
    title: 'Type',
    dataIndex: 'type',
    render: (_, record) => <EventsTags label={record?.label} />,
  },
  {
    key: 'start',
    title: 'Date',
    dataIndex: 'start',
  },
  {
    key: 'notes',
    title: 'Note',
    dataIndex: 'notes',
    render: (_, record) => <p className='truncate flex w-auto'>{record?.notes || '-'}</p>,
  },

  // {
  //   title: "View",
  //   key: "action",
  //   align: "right",
  //   render: (_, record) => (
  //     <Space size="middle">
  //       <Button className="bg-[#DCFDFD] !text-[#41CAC8] border-none font-semibold text-x px-2">
  //         View
  //       </Button>
  //     </Space>
  //   ),
  // },
];

export const upcomingEventsData = [
  {
    customer_name: 'Sam',
    date_of_birth: '22, Oct 2023',
    type: 'Birthday',
  },
  {
    customer_name: 'Samira',
    date_of_birth: '22, Oct 2023',
    type: 'Aniversary',
  },
  {
    customer_name: 'SHalu',
    date_of_birth: '22, Oct 2023',
    type: 'Work Aniversary',
  },
  {
    customer_name: 'Sam',
    date_of_birth: '22, Oct 2023',
    type: 'Birthday',
  },
  {
    customer_name: 'Bharti',
    date_of_birth: '22, Oct 2023',
    type: 'Aniversary',
  },
  {
    customer_name: 'Kiran',
    date_of_birth: '22, Oct 2023',
    type: 'Work Aniversary',
  },
  {
    customer_name: 'Sam',
    date_of_birth: '22, Oct 2023',
    type: 'Birthday',
  },
  {
    customer_name: 'Guri',
    date_of_birth: '22, Oct 2023',
    type: 'Aniversary',
  },
  {
    customer_name: 'Sam',
    date_of_birth: '22, Oct 2023',
    type: 'Work Aniversary',
  },
];
export const clientFilterOptions = [
  {
    label: 'Retail',
    value: 'retail',
  },
  {
    label: 'Corporation',
    value: 'corporation',
  },
];

export const timelineOptions = [
  // {
  //   label: 'Today',
  //   value: 'today',
  // },
  {
    label: 'Last 7 days',
    value: 'week',
  },
  {
    label: 'Last 30 days',
    value: 'month',
  },
  {
    label: 'Last 3 months',
    value: 'quarter',
  },
  {
    label: 'Last 6 months',
    value: 'half',
  },
  {
    label: 'Last year',
    value: 'year',
  },
  // {
  //   label: 'Custom Range',
  //   value: 'customRange',
  // },
];
