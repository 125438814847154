import axios from 'axios';
import Request from './client';
import navigationService from 'service/navigation';
import { clearLocalStorage } from 'utils/localStorage';
import { endpoints } from 'constants/endpoints';
import { useOneSignalNotificationPrompt } from 'hooks/useOneSignalNotificationHandler';

export * from './auth';
export * from './branch';
export * from './role';
export * from './recruitment';
export * from './customer';
export * from './employee';
export * from './company-policies';
export * from './goal';
export * from './lead';
export * from './department';
export * from './designation';
export * from './policy';
export * from './insurer';
export * from './custom-form';
export * from './insurance-category';
export * from './pos';
export * from './hr/asset';
export * from './hr/company-expense';
export * from './notifications';
export * from './hr/payroll';
export * from './hr/tax';
export * from './hr/allowance';
export * from './hr/salary';
export * from './dashboard/statistics';
export * from './task';
export * from './recruitment';
export * from './insurance-product';
export * from '../apis/commissionMangement';
export * from '../apis/getPos';
export * from './pos/training';
export * from './pos/terms';
export * from './pos/contract';
export * from '../apis/account';
export * from '../apis/adjuster';
export * from '../apis/attendance';
export * from '../apis/clauses';
export * from '../apis/customerActivitiesOverview';
export * from '../apis/getPos';
export * from '../apis/inquiry';
export * from '../apis/inventory';
export * from '../apis/letterManagement';
export * from '../apis/payroll';
export * from '../apis/quote';
export * from '../apis/quote';
export * from '../apis/reports';
export * from '../apis/search';
export * from '../apis/tickets';
export * from '../apis/user';
export * from '../apis/vehicleProduct';
export * from './insurance-product';
export * from './recruitment';
export * from './customer';
export * from './policy';
export * from './leave';
export * from './leave-request';
export * from './pos';
export * from './hrData';
export * from './recruitment';
export * from './createInvoice';
export * from './dashboard';
export * from './tickets';

export const CustomerTransactions = async ({
  data = undefined,
  method = 'get',
  id,
  policy_id,
} = {}) => {
  const resp = await Request({
    method,
    API: endpoints.CustomerTransactions.policy_id(policy_id),
    data,
  });
  return resp;
};

export const getUserProfile = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.user.profile,
  });
  return resp;
};

export const getPosProfile = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.pos.profile,
  });
  return resp;
};

export const getAllLeaves = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.getAllLeaves.leaveTypes,
  });

  localStorage.setItem('leaves', JSON.stringify(resp?.data?.data));
  return resp;
};

//this function is not currently calling any api, may be in the future
// const clearCacheData = () => {
//   caches &&
//     caches.keys().then((names) => {
//       Promise.all(names.map((name) => caches.delete(name)))
//         .then(() => {
//           alert('Cache Cleared Successfully');
//         })
//         .catch((error) => {
//           console.error('Error clearing cache:', error);
//           alert('Cache Clearing Failed');
//         });
//     });
// };
export const Logout = async () => {
  const resp = await deletePlayerId();
  useOneSignalNotificationPrompt.getState().setNotificationShown(false);

  if (resp?.status) {
    clearLocalStorage();
    // clearCacheData();
    sessionStorage.clear();
    delete axios.defaults.headers.common['Authorization'];
    if (navigationService.navigation) {
      navigationService.navigation('login');
    }
  }
};

export const deletePlayerId = async () => {
  const resp = await Request({
    method: 'delete',
    API: endpoints.deletePlayerId.playerId,
  });
  return resp;
};

export const getFields = async (props = { type: 'customer' }) => {
  let api = endpoints.getFields.customer;
  switch (props.type) {
    case 'customer':
      api = endpoints.getFields.customer;
      break;
    case 'contact':
      api = endpoints.getFields.contacts;
      break;
    default:
      api = endpoints.getFields.customer;
  }
  const resp = await Request({
    method: 'get',
    API: api,
  });
  return resp;
};

export const contactManagement = async (
  props = { data: '', method: 'get', id: '', customer_id: '', pageNum: 1 },
) => {
  const resp = await Request({
    method: props.method,
    API:
      props.method === 'patch'
        ? endpoints.contactManagement.customerId(props?.id, props?.pageNum)
        : endpoints.contactManagement.contact(props?.customer_id, props?.pageNum),
    data: props?.data,
  });
  return resp;
};

// export const commissionMangement = async ({ data = '', method, id = '', type = '' }) => {
//   const resp = await Request({
//     method: method,
//     API:
//       method === 'patch' || method === 'delete'
//         ? `account/commission/${id}`
//         : type === 'insurer'
//         ? `account/commission?comm_set=insurer`
//         : `account/commission?comm_set=pos`,
//     data: data,
//   });
//   return resp;
// };

// export const getPos = async () => {
//   const resp = await Request({
//     method: 'get',
//     API: 'search/pos',
//   });
//   return resp;
// };

// export const getDesignation = async () => {
//   const resp = await Request({
//     method: 'get',
//     API: 'designation',
//   });
//   return resp;
// };

// export const companySetting = async ({ method = 'get', data = ' ', id = ' ' }) => {
//   const resp = await Request({
//     method: method,
//     API: method === 'patch' ? `company-settings` : `company-settings/${id}`,
//     data: data,
//   });
//   return resp;
// };

/** Policy from other source */

/** Inquiry Management */

// export const manualInquiry = async (props = { id: '' }) => {
//   const resp = await Request({ method: 'delete', API: `user/product/data/${props?.id}` });
//   return resp;
// };

//pos inquiry management

// export const posInquiryManagement = async (props = { data: '' }) => {
//   const resp = await Request({
//     method: 'post',
//     API: 'user/product/data',
//     data: props?.data,
//   });
//   return resp;
// };

/** Customer Activities Overview */

// export const getActivity = async (props = { customer_id: '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `activities?customer_id=${props?.customer_id}`,
//   });
//   return resp;
// };

// export const kraManagement = async ({ method = 'get', data = [], id = '' }) => {
//   const resp = await Request({
//     method: method,
//     API:
//       method === 'patch' || method === 'delete'
//         ? `recruitment/job-kpr/${id}`
//         : `recruitment/job-kpr`,
//     data: data,
//   });
//   return resp;
// };

// export const kpiManagement = async ({ method = 'get', data = [], id = '' }) => {
//   const resp = await Request({
//     method: method,
//     API:
//       method === 'patch' || method === 'delete'
//         ? `recruitment/job-kpi/${id}`
//         : `recruitment/job-kpi`,
//     data: data,
//   });
//   return resp;
// };

// export const jobRequirements = async ({ method = 'get', data = [], id = '' }) => {
//   const resp = await Request({
//     method: method,
//     API: method === 'patch' || method === 'delete' ? `recruitment/job/${id}` : `recruitment/job`,
//     data: data,
//   });
//   return resp;
// };

// export const GetRecruitment = async ({ method = 'get', id = '' }) => {
//   const resp = await Request({
//     method: method,
//     API: `recruitment/job?table_id=${id}`,
//   });
//   return resp;
// };

// export const ApplicationManagement = async ({ method = 'get', data = [], id = '' }) => {
//   const resp = await Request({
//     method: method,
//     API:
//       method === 'post'
//         ? `recruitment/job-application`
//         : `recruitment/job-application${method === 'get' ? `?job_req=${id}` : `/${id}`}`,
//     data: data,
//   });
//   return resp;
// };

// export const interviewManagement = async ({ method = 'get', data = [], id }) => {
//   const resp = await Request({
//     method: method,
//     API:
//       method === 'patch' || method === 'delete'
//         ? `recruitment/interview/${id}`
//         : 'recruitment/interview',
//     data: data,
//   });
//   return resp;
// };

// export const posEarningManagement = async ({
//   method = 'get',
//   id = '',
//   start_date = '',
//   end_date = '',
// }) => {
//   const resp = await Request({
//     method: method,
//     API: `pos/earning?pos_id=${id}&start_date=${start_date || ''}&end_date=${end_date || ''}`,
//   });
//   return resp;
// };

/** Insurance Category Management */

// export const insuranceProductManagement = async (
//   props = { category_id: '', data: '', method: 'get', id: '' },
// ) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `insurance-product/${props.id}`
//         : `insurance-product/?category_id=${props.category_id || ''}`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const compareInsuranceProduct = async ({ category_id = '', premium = '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `insurance-product/compare?category_id=${category_id}&premium=${premium}`,
//   });
//   return resp;
// };

/* Profile Education Management  */
// export const educationManagement = async (
//   props = { data: '', method: 'get', id: '', query: '' },
// ) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `user/profile/education/${props.id}`
//         : `user/profile/education`,
//     data: props?.data,
//   });
//   return resp;
// };

/* Profile Work Experience Management  */
// export const experienceManagement = async (
//   props = { data: '', method: 'get', id: '', query: '' },
// ) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `user/profile/experience/${props.id}`
//         : `user/profile/experience`,
//     data: props?.data,
//   });
//   return resp;
// };

/* Profile Project Management  */
// export const projectManagement = async (props = { data: '', method: 'get', id: '', query: '' }) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `user/profile/project/${props.id}`
//         : `user/profile/project`,
//     data: props?.data,
//   });
//   return resp;
// };

/* Profile Language Management  */
// export const languageManagement = async (
//   props = { data: '', method: 'get', id: '', query: '' },
// ) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `user/profile/language/${props.id}`
//         : `user/profile/language`,
//     data: props?.data,
//   });
//   return resp;
// };

/* Profile Skills Management  */
// export const skillManagement = async (props = { data: '', method: 'get', id: '', query: '' }) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `user/profile/skill/${props.id}`
//         : `user/profile/skill`,
//     data: props?.data,
//   });
//   return resp;
// };

/* Profile Emergency Management  */
// export const emergencyManagement = async (
//   props = { data: '', method: 'get', id: '', query: '' },
// ) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `user/profile/emergency/contact/${props.id}`
//         : `user/profile/emergency/contact`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const searchApis = async (props = { api: '', query: '', customer_id: '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `search/${props.api}?q=${props.query || ''}&customer_id=${props.customer_id || ''}`,
//   });
//   return resp;
// };

/* Overview customers latest comments */
// no use !!
// export const latestComments = async ({ customer_id = '' }) => {
//   const resp = await Request({
//     method: `get`,
//     API: `latest/comment?customer_id=${customer_id}`,
//   });
//   return resp;
// };

/* profile update  */
// export const updateProfile = async ({ data }) => {
//   const resp = await Request({
//     method: 'patch',
//     API: `user/profile/`,
//     data,
//   });
//   return resp;
// };

// export const clauseManagement = async (props = { category_id: '', method: 'get', data: '' }) => {
//   const resp = await Request({
//     method: props?.method,
//     API: `clauses/${props?.insurance_product_id}`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const adjusterManagement = async (props = { adjuster_id: '', method: 'get', data: '' }) => {
//   const resp = await Request({
//     method: props?.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `adjuster/${props?.adjuster_id}`
//         : `adjuster/`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const getTimeline = async () => {
//   const resp = await Request({
//     method: 'get',
//     API: `employee/activity`,
//   });
//   return resp;
// };

// export const foldersManagement = async (props = { method: 'get', data: '' }) => {
//   const resp = await Request({
//     method: props?.method,
//     API: props.method === 'post' || props?.method === 'get' ? `report/folder` : `report/folder`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const addInsurer = async ({ data }) => {
//   const resp = await Request({
//     method: 'post',
//     API: `client/insurance-product/insurer`,
//     data,
//   });
//   return resp;
// };

// not getting used below one !!
// export const getInsurerAPI = async ({ product_id }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `client/insurance-product/insurer?product_id=${product_id}`,
//   });
//   return resp;
// };

// export const insurerProduct = async (props = { insurer_id: "", method: "get", data: "" }) => {
//   const resp = await Request({
//     method: props?.method,
//     API:
//       props.method === "delete" || props.method === "patch"
//         ? `insurer/${props?.insurer_id}`
//         : `insurer`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const rtoNumberFetch = async ({ q = '' }) => {
//   return await Request({
//     method: 'get',
//     API: `product/rto-number?${q}`,
//   });
// };

// export const getVehicleBrands = async ({ type = 'car', q = '' }) => {
//   return await Request({
//     method: 'get',
//     API: `product/brand?category=${type}&q=${q}`,
//   });
// };

// export const getVehicleBrandModal = async ({ q = '', brand }) => {
//   return await Request({
//     method: 'get',
//     API: `product/brand-model/${brand}?q=${q}`,
//   });
// };

// export const getVehicleFuelType = async ({ model }) => {
//   return await Request({
//     method: 'get',
//     API: `product/model-fuel/${model}`,
//   });
// };

// export const getVehicleVarient = async ({ model, q = '', fuelType }) => {
//   return await Request({
//     method: 'get',
//     API: `product/variants/${model}?fuel=${fuelType}&q=${q}`,
//   });
// };

/* Insurer api's under Insurance Product  .. not in use */
// not in use
// export const insurerProduct = async (props = { id: '', method: 'get', data: '' }) => {
//   const resp = await Request({
//     method: props?.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `insurance-product/insurer/${props.id}`
//         : `insurance-product/insurer?product_id=${props.id}`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const quotesManagement = async ({
//   data = '',
//   method = 'get',
//   employee_id = '',
//   customer_id = '',
//   quote_id,
//   page = 1,
// }) => {
//   const resp = await Request({
//     method,
//     API:
//       method === 'delete' || method === 'patch'
//         ? `quote/${quote_id}`
//         : `quote/?employee_id=${employee_id || ''}&customer_id=${customer_id || ''}&page=${
//             page || ''
//           }`,
//     data,
//   });
//   return resp;
// };

// export const addQuoteApi = async (data = '') => {
//   return await Request({
//     method: 'post',
//     API: 'quote/',
//     data,
//   });
// };

// export const getQuoteApi = async ({ employee_id = '', customer_id = '', stage = '' }) => {
//   return await Request({
//     method: 'get',
//     API: `quote/?employee_id=${employee_id || ''}&customer_id=${customer_id || ''}&stage=${stage}`,
//   });
// };

// not getting used !!
// export const getQuoteNumApi = async ({ employee_id = '', customer_id = '' }) => {
//   return await Request({
//     method: 'get',
//     API: employee_id
//       ? `quote/count?employee_id=${employee_id || ''}`
//       : customer_id
//       ? `quote/count?customer_id=${customer_id || ''}`
//       : `quote/count`,
//   });
// };
// not getting used !!
// export const getTargetType = async ({ department_id = '' }) => {
//   return await Request({
//     method: 'get',
//     API: `target/type?department_id=${department_id || ''}`,
//   });
// };
// not getting used !!
// export const getDepartmentEmployees = async ({ deptId = '' }) => {
//   return await Request({
//     method: 'get',
//     API: `employee/department?department_id=${deptId || ''}`,
//   });
// };
// not getting used !!
// export const getTotalAdminTargets = async () => {
//   return await Request({
//     method: 'get',
//     API: `target/total`,
//   });
// };

// export const getTotalHRTargets = async ({ id }) => {
//   return await Request({
//     method: 'get',
//     API: `target/total?employee_id=${id}`,
//   });
// };

// export const taskMeetings = async ({ employee_id = '' }) => {
//   return await Request({
//     method: 'get',
//     API: `employee/task-meeting/${employee_id}`,
//   });
// };

// export const getTotalCount = async (type) => {
//   return await Request({
//     method: 'get',
//     API: `${type}/count`,
//   });
// };
// export const getInquiryCount = async () => {
//   return await Request({
//     method: 'get',
//     API: `inquiry/count`,
//   });
// };

// export const leaveTypeManagement = async (props = { id: '', method: 'get', data: '' }) => {
//   const resp = await Request({
//     method: props?.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `leave/types?id=${props?.id}`
//         : `leave/types`,
//     data: props?.data,
//   });
//   return resp;
// };

export const holidayManagement = async (props = { id: '', method: 'get', data: '', year: '' }) => {
  const resp = await Request({
    method: props?.method,
    API:
      props.method === 'delete' || props.method === 'patch'
        ? `leave/holiday/${props?.id}`
        : `leave/holidays?year=${props.year}`,
    data: props?.data,
  });
  return resp;
};

// export const nonPerformingEmployeeManagement = async (
//   props = { id: '', method: 'get', data: '' },
// ) => {
//   const resp = await Request({
//     method: props?.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `employee/non-performing/${props?.id}`
//         : `employee/non-performing`,
//     data: props?.data,
//   });
//   return resp;
// };

// not in use
// export const attendanceManagement = async (props = { id: '', method: 'get', data: '' }) => {
//   const resp = await Request({
//     method: props?.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `attendance/setting?id=${props?.id}`
//         : `attendance/setting`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const shitfManagement = async (props = { id: '', method: 'get', data: '' }) => {
//   const resp = await Request({
//     method: props?.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `attendance/shift?id=${props?.id}`
//         : `attendance/shift`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const getAssignees = async (id, pageNum) => {
//   const resp = await Request({
//     method: 'get',
//     API: `attendance/assigns?id=${id}&page=${pageNum}`,
//   });
//   return resp;
// };

// export const deleteAttendance = async (props = { id: '', method: 'patch', data: '' }) => {
//   const resp = await Request({
//     method: props?.method,
//     API: `attendance/setting?id=${props?.id}`,
//     data: props?.data,
//   });
//   return resp;
// };

// // not in use
// export const AttendanceCount = async () => {
//   const resp = await Request({
//     method: 'get',
//     API: 'attendance/count',
//   });
//   return resp;
// };

// not in use
// export const attendanceManage = async (props = { id: '', method: 'get', data: '' }) => {
//   const resp = await Request({
//     method: props?.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `attendance/${props?.id}`
//         : `attendance/`,
//     data: props?.data,
//   });
//   return resp;
// };

// get leave request data (number of messages): not in use
// export const getLeaveRequestCount = async () => {
//   return await Request({
//     method: 'get',
//     API: `leave/count`,
//   });
// };

// attendance :not in use
// export const getAttendance = async ({ method = 'get', data, date = '', department_id = '' }) => {
//   const resp = await Request({
//     method: method,
//     API: `attendance/?date=${date}&department_id=${department_id}`,
//     data,
//   });
//   return resp;
// };

// export const getAttendancebyStatus = async ({
//   data,
//   date = '',
//   status = '',
//   shift_id = '',
// }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `attendance/?start=${date}&status=${status}&shift=${shift_id}`,
//     data,
//   });
//   return resp;
// };

// export const updateAttendanceStatus = async ({ data }) => {
//   const resp = await Request({
//     method: 'patch',
//     API: `attendance/?id=${data?.id}&status=${data?.status}`,
//   });
//   return resp;
// };

// attendance count :not in use
// export const getAttendanceCount = async ({
//   method = 'get',
//   data,
//   date = '',
//   department_id = '',
// }) => {
//   const resp = await Request({
//     method: method,
//     API: `attendance/count?date=${date}&department_id=${department_id}`,
//     data,
//   });
//   return resp;
// };

// my attendence
// export const getMyAttendance = async (
//   props = { id: '', method: 'get', start: null, end: null },
// ) => {
//   const searchParams = new URLSearchParams();
//   if (props?.start) searchParams.set('start', props.start);
//   if (props?.end) searchParams.set('end', props.end);
//   if (props?.id) searchParams.set('user', props.id);
//   return await Request({
//     method: props?.method,
//     API: `attendance/mark?${searchParams.toString()}`,
//   });
// };

// not in use !!
// export const getOnlyMyAttendance = async (
//   props = { id: '', method: 'get', start_date: null, endDate: null },
// ) => {
//   const searchParams = new URLSearchParams();
//   if (props.start_date) searchParams.set('start_date', props.start_date);
//   if (props.endDate) searchParams.set('end_date', props.endDate);
//   return await Request({
//     method: props?.method,
//     API: `attendance/employee/performance/count?${searchParams.toString()}&employee_id=${
//       props.id || ''
//     }`,
//   });
// };

// not in use
// export const getTodayAttendance = async ({ period, method = 'post', data }) => {
//   const resp = await Request({
//     method,
//     API: `attendance/mark/employee/${period}`,
//     data,
//   });
//   return resp;
// };

// export const attendanceMarkEmp = async ({ method = 'post', data }) => {
//   const resp = await Request({
//     method,
//     API: `attendance/mark`,
//     data,
//   });
//   return resp;
// };

// export const getShiftHistory = async ({ id = '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `attendance/shift?employee_id=${id}`,
//   });
//   return resp;
// };

// export const PosUser = async ({ id }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `pos/${id}`,
//   });
//   return resp;
// };

// export const getTotalTargets = async ({ start, end, id, branch_id }) => {
//   return await Request({
//     method: 'get',
//     API: `employee/targets/${id}`,
//   });
// };

// not in use !!
// export const buyPolicies = async ({ data }) => {
//   return await Request({
//     method: 'post',
//     API: `policies/buy`,
//     data,
//   });
// };
// not in use
// export const getPOSEmployees = async (props = { query: '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `employee/?pos_agent=true`,
//   });
//   return resp;
// };

// not in use
// export const PostPOSEmployees = async (props = { data: '' }) => {
//   const resp = await Request({
//     method: 'post',
//     API: `employee/?pos_agent=true`,
//     data: props.data,
//   });
//   return resp;
// };

// export const getInsurerProduct = async (props = { insurer_id: '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `insurance-product/?insurer_id=${props?.insurer_id || ''}`,
//   });
//   return resp;
// };

// export const leadTabApi = async (props = { status: '', employee_id: '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `inquiry/?employee_id=${props.employee_id || ''}&status=${props.status}`,
//   });
//   return resp;
// };

// export const templateManagement = async ({ data = undefined, method = 'get', id } = {}) => {
//   const resp = await Request({
//     method,
//     API: method === 'delete' || method === 'patch' ? `pos/training/${id}` : `pos/training`,
//     data,
//   });
//   return resp;
// };

// export const getCurrentUserLeads = async ({
//   source = '',
//   employee_id = '',
//   customer_id = '',
//   pos_id = '',
//   category_id = '',
//   product_type = '',
// }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `user/product/data?source=${source}&employee_id=${employee_id}&customer_id=${customer_id}&pos_id=${pos_id}&category_id=${category_id}&product_type=${product_type}`,
//   });
//   return resp;
// };

// export const getPosOnlineLeads = async ({ id = '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `user/product/data/${id}`,
//   });
//   return resp;
// };

export const sendEmail = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: `send-email`,
    data,
  });
  return resp;
};

// export const getReport = async (
//   props = { start: null, end: null, customer_id: '', pos_id: '' },
// ) => {
//   const resp = await Request({
//     method: 'get',
//     API: `report?start_date=${props?.start || ''}&end_date=${props?.end || ''}&customer_id=${
//       props?.customer_id || ''
//     }&pos_id=${props?.pos_id || ''}`,
//   });
//   return resp;
// };

// export const reportsManagement = async ({
//   type = '',
//   modules = '',
//   start_date = '',
//   end_date = '',
//   branch_id = '',
//   category = '',
//   product = '',
// }) => {
//   const resp = await Request({
//     method: 'get',
//     API: endpoints.report.list(type, modules, start_date, end_date, branch_id, category, product),
//   });
//   return resp;
// };

// export const importClients = async ({ data }) => {
//   const resp = await Request({
//     method: 'post',
//     API: endpoints.customer.import(),
//     data: data,
//   });
//   return resp;
// };

// not in use
// export const getAssetsAllocationRequests = async () => {
//   const resp = await Request({
//     method: 'get',
//     API: 'account/asset/allocation/request',
//   });
//   return resp;
// };

// export const getAdminStatistics = async () => {
//   const resp = await Request({
//     method: 'get',
//     API: 'admin/statistics',
//   });
//   return resp;
// };

// not in use
// export const getEarningChartData = async ({ start_date, end_date }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `chart?start_date=${start_date}&end_date=${end_date}`,
//   });
//   return resp;
// };

// export const getPoliciesChartData = async ({ start_date, end_date, source = '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `policies/chart?start_date=${start_date}&end_date=${end_date}&according=${source}`,
//   });
//   return resp;
// };

// export const allocationRequest = async ({
//   data = undefined,
//   method = 'get',
//   id,
//   page = 1,
// } = {}) => {
//   const resp = await Request({
//     method,
//     API:
//       method === 'delete' || method === 'patch'
//         ? `inventory/allocation?id=${id}`
//         : method === 'post'
//         ? `inventory/allocation`
//         : `inventory/allocation?user_id=${id}&page=${page}`,
//     data,
//   });
//   return resp;
// };

// export const assetAllocationRequest = async ({
//   data = undefined,
//   method = 'get',
//   id,
//   status,
// } = {}) => {
//   const resp = await Request({
//     method,
//     API:
//       method === 'delete' || method === 'patch'
//         ? `inventory/allocation/${id}`
//         : `inventory/allocation?status=${status || ''}`,
//     data,
//   });
//   return resp;
// };

// export const getPolicyCount = async () => {
//   return await Request({
//     method: 'get',
//     API: `policies/count`,
//   });
// };

// export const searchAssets = async (props = { api: '', query: '', asset_type_id: '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `search/asset?q=${props.query || ''}&asset_type_id=${props.asset_type_id || ''}`,
//   });
//   return resp;
// };

// export const SlabManagement = async ({ data = undefined, method = 'get', id } = {}) => {
//   const resp = await Request({
//     method,
//     API:
//       method === 'delete' || method === 'patch' ? `payroll/tax_slab?id=${id}` : `payroll/tax_slab`,
//     data,
//   });
//   return resp;
// };

// export const PayRollSheetManagement = async ({ data = undefined, method = 'get', id } = {}) => {
//   const resp = await Request({
//     method,
//     API: method === 'delete' || method === 'patch' ? `payroll/sheet/${id}` : `payroll/sheet`,
//     data,
//   });
//   return resp;
// };

// not in use
// export const PayRollManagement = async ({
//   data = undefined,
//   method = 'get',
//   id,
//   month,
//   year,
// } = {}) => {
//   const resp = await Request({
//     method,
//     API:
//       method === 'delete' || method === 'patch'
//         ? `payroll/data/${id}`
//         : `payroll/data?month=${month || ''}&year=${year || ''}`,
//     data,
//   });
//   return resp;
// };

// not in use
// export const PayRollDataManagement = async ({
//   data = undefined,
//   method = 'get',
//   id,
//   employee_id,
// } = {}) => {
//   const resp = await Request({
//     method,
//     API:
//       method === 'delete' || method === 'patch'
//         ? `payroll/basic/data/${id}`
//         : `payroll/basic/data?employee_id=${employee_id || ''}`,
//     data,
//   });
//   return resp;
// };

// not in use
// export const AddPayRollSheetData = async ({
//   data = undefined,
//   method = 'get',
//   id,
//   employee_id,
//   sheet_id,
// } = {}) => {
//   const resp = await Request({
//     method,
//     API:
//       method === 'delete' || method === 'patch'
//         ? `payroll/sheet/data/${id}`
//         : `payroll/sheet/data?employee_id=${employee_id || ''}&sheet_id=${sheet_id || ''}`,
//     data,
//   });
//   return resp;
// };

/* profile update  */

// export const buyPolicy = async ({ inquiry_id = '', quote_id = '' }) => {
//   const resp = await Request({
//     method: 'post',
//     API: `policies/buy?inquiry_id=${inquiry_id}&quote_id=${quote_id}`,
//   });
//   return resp;
// };

// export const vehicleManagement = async ({
//   data = {
//     vehicleno: 'mh02fb2727',
//   },
// } = {}) => {
//   const resp = await Request({
//     method: 'post',
//     replaceUrl: 'https://rto-vehicle-details.p.rapidapi.com/',
//     data,
//     header: {
//       'content-type': 'application/json',
//       'Content-Type': 'application/json',
//       'X-RapidAPI-Key': '87c241625fmsh448cc5b3d4ddbedp1e2ee2jsne14bf726c2f7',
//       'X-RapidAPI-Host': 'rto-vehicle-details.p.rapidapi.com',
//     },
//   });
//   return resp;
// };

// export const getRejectedLead = async ({ date = '', employee_id = '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `inquiry/remarks?date=${date || ''}&employee_id=${employee_id || ''}`,
//   });
//   return resp;
// };

export const getDailySalesReport = async ({ date = '' }) => {
  const resp = await Request({
    method: 'get',
    API: `daily/transaction?date=${date || ''}`,
  });
  return resp;
};

// export const getLeadDiscussion = async (props = { data: '', method: 'get', lead_id: '' }) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `inquiry/comment?id=${props.lead_id}`
//         : `inquiry/comment?id=${props?.lead_id}`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const employeeTargetPerformace = async (
//   props = { employee_id: '', start_date: '', end_date: '' },
// ) => {
//   const resp = await Request({
//     method: 'get',
//     API: `employee/performance/target?employee_id=${props?.employee_id}&start_date=${props?.start_date}&end_date=${props?.end_date}`,
//   });
//   return resp;
// };

// export const employeeAttendancePerformace = async (
//   props = { employee_id: '', start_date: '', end_date: '' },
// ) => {
//   const resp = await Request({
//     method: 'get',
//     API: `attendance/employee/performance/count?employee_id=${props?.employee_id}&start_date=${props?.start_date}&end_date=${props?.end_date}`,
//   });
//   return resp;
// };

// export const letterManagement = async (props = { data: '', method: 'get', id: '', query: '' }) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `form/${props.id}`
//         : `form/?q=${props.query || ''}&table_id=${props?.id || ''}`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const dailyGoalManagement = async (
//   props = { data: '', method: 'get', id: '', date: '', status: '' },
// ) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `account/goals/${props.id}`
//         : `account/goals?date=${props.date || ''}&status=${props.status || ''}`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const getHelpline = async (props = { data: '', method: 'get', id: '' }) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `tickets/help-line/${props.id}`
//         : 'tickets/help-line',
//     data: props?.data,
//   });
//   return resp;
// };

// export const getFaq = async (props = { data: '', method: 'get', id: '' }) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `tickets/faq/${props.id}`
//         : 'tickets/faq',
//     data: props?.data,
//   });
//   return resp;
// };

// export const getTickets = async (
//   props = { data: '', method: 'get', id: '', table_id: '', status: '' },
// ) => {
//   const resp = await Request({
//     method: props.method,
//     API:
//       props.method === 'delete' || props.method === 'patch'
//         ? `tickets/${props.id}`
//         : `tickets/?table_id=${props.table_id || ''}&status=${props?.status || ''}`,
//     data: props?.data,
//   });
//   return resp;
// };

// export const updateTicketStatus = async (props = { data: '', id: '' }) => {
//   return await Request({
//     method: 'patch',
//     API: `tickets/status/${props?.id}`,
//     data: props?.data,
//   });
// };

// export const deleteTaxField = async (props = { data: '', label: '' }) => {
//   return await Request({
//     method: 'delete',
//     API: `payroll/tax-label/${props?.label}`,
//     data: props?.data,
//   });
// };

// export const getLeadTimeline = async ({ model, model_id }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `employee/activity?model=${model || ''}&model_id=${model_id || ''}`,
//   });
//   return resp;
// };

// export const getPosInquiryDetails = async ({ id = '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `user/product/data/${id}`,
//   });
//   return resp;
// };

// export const getMonthlyExpense = async ({ start_date = '', end_date = '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `account/count/expenses?start_date=${start_date}&end_date=${end_date}`,
//   });
//   return resp;
// };

// export const searchInsuranceApis = async (props = { query: '', id: '' }) => {
//   const resp = await Request({
//     method: 'get',
//     API: `search/insurance_category?q=${props.query || ''}&id=${props.id || ''}`,
//   });
//   return resp;
// };
