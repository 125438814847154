import { Divider, Space } from 'antd';
import { View } from 'assets/icons';
import { CustomModal, IconButton, TableComponent, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
import { useEffect, useState } from 'react';
import { contactManagement } from 'service/api-service';
import { rowCommonClass } from 'utils/common';
import { LeadSourceSwitch } from '../data';

const PAGE_SIZE = 5;

const ContactModal = ({ viewContact, setViewContact }) => {
  const [loading, setLoading] = useState(false);
  const [details, setdetails] = useState('');
  const [selected, setSelected] = useState({});
  const [viewModal, setViewModal] = useState(false);

  const handleCancel = () => {
    setViewContact({ modal: false, data: null });
  };
  const handleCancelViewModal = () => {
    setViewModal((prev) => !prev);
  };
  const columns = [
    {
      key: 'customer_name',
      title: 'Customer Name',
      dataIndex: 'customer_name',
      render: (data) => <p className='truncate'>{data?.name || data}</p>,
    },
    {
      key: 'branch_of',
      title: 'Branch',
      dataIndex: 'branch_of',
      render: (data) => <p className='truncate'>{data?.name}</p>,
    },
    {
      key: 'email_address',
      title: 'Email',
      dataIndex: 'email_address',
      render: (data) => <p className='truncate'>{data?.name || data}</p>,
    },
    {
      key: 'contact_number',
      title: 'Contact',
      dataIndex: 'contact_number',
    },
    {
      key: 'date_of_birth',
      title: 'D.O.B',
      dataIndex: 'date_of_birth',
    },
    {
      key: 'assign_to_employee',
      title: 'Created By',
      dataIndex: 'assign_to_employee',
      render: (data) => <p className='truncate'>{data?.name || data}</p>,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='View'
            id={elementData.client.permissions.view}
            className='group disabled:cursor-not-allowed'
            onClick={() => {
              setSelected(record);
              setViewModal(true);
              //   handleViewData();
            }}
          >
            <View svgClass='stroke-primary group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  const onGetContacts = async (pageNum = 1) => {
    setLoading(true);
    const resp = await contactManagement({
      method: 'get',
      customer_id: viewContact?.data?.id,
      pageNum: pageNum || 1,
    });
    if (resp?.data?.status) {
      const list = resp.data.data?.result;
      setdetails(list);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to fetch contacts from server');
    }
    setLoading(false);
  };

  const handlePagination = (pageNum) =>
    pageNum?.current ? onGetContacts((pageNum = pageNum.current)) : '';

  useEffect(() => {
    if (viewContact?.data !== null) {
      onGetContacts();
    }
  }, [viewContact]);

  return (
    <div>
      <CustomModal
        footer={false}
        open={viewContact?.modal}
        width={900}
        onCancel={handleCancel}
        closable={true}
      >
        <h2 className='text-l font-bold'> Contact Details</h2>
        <Divider className='mt- bg-grey-40' />
        <TableComponent
          loading={loading}
          noDataAction={onGetContacts}
          getRowClassName={rowCommonClass}
          columns={columns}
          dataSource={details}
          onChange={handlePagination}
          pageSize={PAGE_SIZE}
          total={details.length}
        />
      </CustomModal>

      {/* Perticular Contact Details Modal  */}

      <CustomModal
        footer={false}
        open={viewModal}
        width={1000}
        onCancel={handleCancelViewModal}
        closable={true}
        title={'Contact Details'}
      >
        <Divider className='mt- bg-grey-40' />
        <div className=' grid grid-cols-2 gap-8'>
          {/* insured details */}
          <div>
            <p className='text-m font-semibold mb-2'>Insured Details</p>
            <div className='grid grid-cols-2 gap-4'>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Customer Name
                <p className='text-xxs font-normal'>{selected?.customer_name || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Contact Number
                <p className='text-xxs font-normal'>{selected?.contact_number || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Branch Of
                <p className='text-xxs font-normal'>{selected?.branch_of?.name || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Date of Birth
                <p className='text-xxs font-normal'>{selected?.dob || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Email Address
                <p className='text-xxs font-normal'>{selected?.email_address || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Assigned To Employee
                <p className='text-xxs font-normal'>{selected?.assign_to_employee?.name || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Lead Source
                <p className='text-xxs font-normal'>
                  {LeadSourceSwitch(selected?.lead_source) || '_'}
                </p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Nature of Business
                <p className='text-xxs font-normal'>{selected?.nature_of_business || '_'}</p>
              </p>
            </div>
          </div>
          {/* registered details */}
          <div>
            <p className='text-m font-semibold mb-2'>Registered Details</p>
            <div className='grid grid-cols-2 gap-4'>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Address
                <p className='text-xxs font-normal'>{selected?.r_address || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                State
                <p className='text-xxs font-normal'>{selected?.r_state || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                City
                <p className='text-xxs font-normal'>{selected?.r_city || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                PostCode
                <p className='text-xxs font-normal'>{selected?.r_postcode || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Country
                <p className='text-xxs font-normal'>{selected?.r_country || '_'}</p>
              </p>
            </div>
          </div>
        </div>

        <Divider className='mt- bg-grey-40' />
        <div className='grid grid-cols-2 gap-8'>
          {/* billing details */}
          <div>
            <p className='text-m font-semibold mb-2'>Billing Details</p>

            <div className='grid grid-cols-2 gap-4'>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Address
                <p className='text-xxs font-normal'>{selected?.address || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                State
                <p className='text-xxs font-normal'>{selected?.state || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                City
                <p className='text-xxs font-normal'>{selected?.city || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                PostCode
                <p className='text-xxs font-normal'>{selected?.postcode || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Country
                <p className='text-xxs font-normal'>{selected?.country || '_'}</p>
              </p>
            </div>
          </div>
          {/* bank details */}
          <div>
            <p className='text-m font-semibold mb-2'>Bank Details</p>

            <div className='grid grid-cols-2 gap-4'>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Bank Ifsc Code
                <p className='text-xxs font-normal'>{selected?.bank_ifsc_code || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Account Holder Name
                <p className='text-xxs font-normal'>{selected?.bank_account_holder_name || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Bank Account Number
                <p className='text-xxs font-normal'>{selected?.bank_account_number || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Bank Account Type
                <p className='text-xxs font-normal'>{selected?.bank_account_type?.name || '_'}</p>
              </p>
              <p className='text-xs font-semibold flex flex-col'>
                {' '}
                Country
                <p className='text-xxs font-normal'>{selected?.country || '_'}</p>
              </p>
            </div>
          </div>
        </div>
        <Divider className='mt- bg-grey-40' />
        <div>
          <p className='text-xs font-semibold flex flex-col'>
            {' '}
            Description
            <p className='text-xxs font-normal'>{selected?.description || '_'}</p>
          </p>
        </div>
      </CustomModal>
    </div>
  );
};

export default ContactModal;
