import React from 'react';
import { Dropdown } from 'antd';

export default function CustomDropdown({
  items,
  onClick,
  children,
  trigger = ['hover'],
  placement = 'bottomRight',
  disabled = false,
}) {
  return (
    <Dropdown
      disabled={disabled}
      menu={{ items, onClick }}
      trigger={trigger}
      placement={placement}
      destroyPopupOnHide={true}
    >
      <div>
        {!children && (
          <div className='grid gap-1 place-content-center cursor-pointer bg-primary-50 w-8 h-8 rounded-md dark:!bg-primary'>
            <div className='h-1 w-1 bg-secondary-80 rounded-full dark:bg-white'></div>
            <div className='h-1 w-1 bg-secondary-80 rounded-full dark:bg-white'></div>
            <div className='h-1 w-1 bg-secondary-80 rounded-full dark:bg-white'></div>
          </div>
        )}
        {children}
      </div>
    </Dropdown>
  );
}
