export const columns = [
  {
    title: 'Insurer',
    key: 'insurer',
    dataIndex: 'insurer',
    render: (data) => data.name,
  },
  {
    title: 'Category',
    key: 'category',
    // dataIndex : "insurer",
    render: (data) =>
      data.categories.map((item) => (
        <ul>
          {' '}
          <li>{item.category.name}</li>
        </ul>
      )),
  },
  {
    title: 'Commission',
    key: 'commission',
    // dataIndex : "insurer",
    render: (data) =>
      data.categories.map((item) => (
        <ul>
          {' '}
          <li>{item.pos_commission}</li>
        </ul>
      )),
  },
];
