import dayjs from 'dayjs';
import React, { useState } from 'react';
import { FormContainer, SelectInput, TextInput, DateTime, CustomRadio } from 'components';
import { prepareFormFieldsData } from 'utils';
import { formStyle } from 'utils/common';
import { SubmitButtons } from 'screens/leads/data';

const LifeForm = ({
  formData,
  type,
  setValues,
  insurer,
  setSelectedProduct,
  isEditing,
  submitProduct,
}) => {
  const maxDate = dayjs().subtract(18, 'years');
  const [wantExit, setWantExit] = useState(false);

  let defaultType = '';
  if (type === 'TermLifeForm') {
    defaultType = 'tlp';
  } else if (type === 'PensionAndRetirementForm') {
    defaultType = 'prp';
  } else if (type === 'InvestmentAndTaxPlanningForm') {
    defaultType = 'itp';
  } else if (type === 'ChildSavingForm') {
    defaultType = 'csp';
  }

  const onSubmit = (data) => {
    submitProduct(data, wantExit);
  };

  const quoteCustomerFields = {
    'Life Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Product Type',
          name: 'product_type',
          id: 'product_type',
          className: 'hidden',
        },
        {
          label: 'Choose Plan',
          name: 'plan',
          id: 'plan',
          type: 'select',
          defaultValue: defaultType,
          component: SelectInput,
          options: [
            {
              label: 'Term Life Plans',
              value: 'tlp',
            },
            {
              label: 'Investment & Tax Planning',
              value: 'itp',
            },
            {
              label: 'Child Savings Plan',
              value: 'csp',
            },
            {
              label: 'Pension & Retirement Plans',
              value: 'prp',
            },
          ],
        },
        {
          label: 'DOB',
          name: 'dob',
          id: 'dob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          showTime: false,
          component: (props) => <DateTime {...props} disabledDate={maxDate} />,
        },
        {
          dependent: true,
          dependent_on: 'plan',
          dependent_value: 'tlp',
          label: 'Do you smoke or chew tobacco?',
          name: 'smoke',
          id: 'smoke',
          type: 'radio',
          component: CustomRadio,
          options: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ],
          defaultValue: 'no',
        },
        {
          dependent: true,
          dependent_on: 'plan',
          dependent_value: 'csp',
          label: 'Child DOB',
          name: 'c_dob',
          id: 'c_dob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          component: DateTime,
        },
        {
          dependent: true,
          dependent_on: 'plan',
          dependent_value: 'prp',
          label: 'When are you planning to retire?',
          name: 'retirement',
          id: 'retirement',
          placeholder: 'Date',
          type: 'date',
          component: DateTime,
        },
      ],
    },
    'Select the Sum Assured Amount!': {
      dependent: true,
      dependent_on: 'plan',
      dependent_value: 'tlp',
      fields: [
        {
          label: 'Sum Assured Amount',
          name: 'income',
          id: 'income',
          placeholder: 'Sum Assured Amount',
          type: 'select',
          component: SelectInput,
          options: [
            {
              label: '50,000-100,000',
              value: '100000',
            },
            {
              label: '100,000-200,000',
              value: '200000',
            },
            {
              label: '200,000-300,000',
              value: '300000',
            },
          ],
        },
      ],
    },
    'How much would you like to invest?': {
      dependent: true,
      dependent_on: 'plan',
      dependent_value: 'prp',
      fields: [
        {
          label: 'Invest',
          name: 'invest',
          id: 'invest',
          placeholder: 'Amount',
          type: 'select',
          searchable: true,
          component: SelectInput,
          options: [
            {
              label: '50,000-100,000',
              value: '100000',
            },
            {
              label: '100,000-200,000',
              value: '200000',
            },
            {
              label: '200,000-300,000',
              value: '300000',
            },
          ],
        },
        {
          label: 'Amount',
          name: 'amount',
          id: 'amount',
          placeholder: 'Amount',
          divClasses: 'customCssForLead',
          type: 'text',
          component: TextInput,
          maxLength: 30,
        },
        {
          label: 'Get Benefit Payout after policy term(PT)',
          name: 'benefit',
          id: 'benefit',
          placeholder: 'Benefits',
          type: 'select',
          searchable: true,
          component: SelectInput,
          options: [
            {
              label: '50,000-100,000',
              value: '100000',
            },
            {
              label: '100,000-200,000',
              value: '200000',
            },
            {
              label: '200,000-300,000',
              value: '300000',
            },
          ],
        },
      ],
      showAccordionHeader: true,
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: quoteCustomerFields,
    defaultValues: { dob: maxDate.format('YYYY-MM-DD'), ...formData },
  });

  return (
    <div className='bg-white'>
      <FormContainer
        returnFormatted={false}
        accordionClass={formStyle}
        fields={finalFields}
        initialValues={initialValues}
        onSubmit={onSubmit}
        customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, dirty }) => {
          return formData?.count ? (
            <></>
          ) : (
            <SubmitButtons
              show={true}
              classes='z-10'
              submit={submitForm}
              reset={resetForm}
              setWantExit={setWantExit}
            />
          );
        }}
      />
    </div>
  );
};

export default {
  Form: LifeForm,
};
