/** external deps */
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router';

/** internal deps */
import {
  Branches,
  Employees,
  EmployeePermissions,
  Roles,
  RolePermissions,
  ViewEmployee,
  CustomerManagement,
  AddCustomer,
  ViewCustomerDetails,
  InsuranceCategory,
  AddClass,
  AddContact,
  Insurer,
  AddInsurer,
  ViewInsurer,
  Profile,
  TaskDashboard,
  ViewClassDetails,
  AddEditEmployee,
  Adjuster,
  ViewAdjuster,
  AddAdjuster,
  SalesInquiry,
  Quotes,
  Playground,
  AddQuote,
  SalesTargets,
  Designation,
  TargetType,
  NotFound,
  Invoice,
  LeavesType,
  Leaves,
  Attendance,
  ProvidentFund,
  Accounts,
  NonPerformingEmployees,
  Invoices,
  AttendanceSettings,
  Holidays,
  Performance,
  PayRole,
  ViewTargetDetails,
  Leads,
  NewReports,
  PayRollManagement,
  QuotationEngine,
  CategoryForm,
  Transactions,
  Department,
  MyAttendance,
  PosManagement,
  PosTraining,
  ViewPolicy,
  PolicyMangement,
  AddPolicy,
  Commission,
  ViewInquiry,
  OfficialLetters,
  AddInquiry,
  CommissionForm,
  Setting,
  RequestForAsset,
  AssetRequest,
  JobKprKpi,
  JobManagement,
  Interviewmanagement,
  LearningAsset,
  DailySalesReport,
  DailyGoals,
  ExpenseReport,
  Helpline,
  FAQ,
  Ticket,
  TicketReview,
  AddTemplateContainer,
  LetterTemplateSetting,
  TermsCondition,
  ViewTermsCondition,
  PosContract,
  ViewPosManagement,
  AssetsType,
  AllAssets,
  Notifications,
  PosEarning,
  Reconciliation,
  Reminders,
} from 'screens';
import ChatScreen from '../chat/chat-screen';
import CreateInvoice from '../invoices/create-invoice';
import { elementData } from 'elements';
import { Context, reducer } from 'store/context';
import { checkUserSession } from 'utils';
import {
  Module,
  Navbar,
  LoadingIndicator,
  Header,
  QuotationTable,
  StoreProvider,
  ToggleDialer,
  Dialer,
  ExcelMapper,
} from 'components';
import Dashboard from 'screens/dashboard';
import Quotation from 'screens/sales/quotes/Quotation';
import { ElisopsLogo } from 'assets/hr_icons';
import CompanyExpense from 'screens/hr/company-expense';
import ImportClients from 'screens/customers/importClient';
import Proposals from 'screens/proposals/Proposals';
import ViewLog from 'screens/customers/client-call-logs/ViewLog';
import LeadsModule from 'screens/leads';
import ViewLeads from 'screens/leads/leads-components/view-leads';
import Reasons from 'screens/reasons';
import RaiseTicket from 'screens/hr/tickets/raise-ticket';
// import ShiftAssignees from 'screens/hr/attendance/attendance-settings/shiftAssignees';

const initialState = { dialerOpened: false };

const Admin = () => {
  const navigate = useNavigate();
  const { user } = useContext(Context);

  const [isOpen, setIsOpen] = useState(true);
  const {
    permissions: { loading, list: permissions },
  } = useContext(Context);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 1024) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };
    window.addEventListener('resize', handleWindowResize);

    // return () => {
    // window.removeEventListener("resize", handleWindowResize);
    // }
  }, []);

  useLayoutEffect(() => {
    const token = checkUserSession();
    if (!token) {
      navigate('login');
    }
  }, []);

  if (loading) {
    return (
      <div className='flex flex-col gap-2 justify-center items-center h-screen'>
        {/* <MobileInsuranceLogo svgClass={'w-32'} /> */}
        <img src={ElisopsLogo} alt='ELISOPS' />
        {/* <img src={user.logo || MobileInsuranceLogo} alt="logo" className="w-20" /> */}
        <LoadingIndicator />
      </div>
    );
  }
  return (
    <div className='flex'>
      {/* <Sidebar permissions={permissions} isOpen={isOpen} /> */}
      <div
        className={`bg-grey-light ${
          isOpen ? 'w-full' : 'w-[92%]'
        } overflow-y-auto h-screen max-h-screen content-start flex flex-col`}
      >
        <Navbar permissions={permissions} isOpen={isOpen} setIsOpen={setIsOpen} />
        {/* <Header permissions={permissions} /> */}
        <StoreProvider initialState={initialState} reducer={reducer}>
          <ToggleDialer />
          <Dialer />

          <main className='p-4 overflow-auto flex-[18px]'>
            <Routes>
              <Route
                path='/'
                element={
                  // <MyDashboard
                  //   fallback={user?.position === "pos" ? navigate("/pos-home") : <Module />}
                  //   permissions={permissions}
                  //   id={elementData.mydashboard.name}
                  // />

                  <Dashboard
                    // fallback={<Login/>}
                    fallback={user?.position === 'pos' ? navigate('/pos-home') : <Module />}
                    permissions={permissions}
                    id={elementData.dashboard.name}
                  />
                }
              />

              <Route
                path='/team-chat'
                element={
                  <ChatScreen
                    fallback={user?.position === 'pos' ? navigate('/pos-home') : <Module />}
                    permissions={permissions}
                    id={elementData.mydashboard.name}
                  />
                }
              />

              <Route
                path='task-dashboard'
                element={
                  <TaskDashboard
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.taskdashboard.name}
                  />
                }
              />
              <Route
                path='branches'
                element={
                  <Branches
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData?.branch?.name}
                  />
                }
              />
              {/* employees */}
              <Route
                path='employees'
                element={
                  <Employees
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.employee.name}
                  />
                }
              />
              <Route
                path='employee/:id'
                element={
                  <EmployeePermissions
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.employee.permissions.update}
                  />
                }
              />
              <Route
                path='add-employees'
                element={
                  <AddEditEmployee
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.employee.permissions.create}
                  />
                }
              />
              <Route
                path='edit-employees/:id'
                element={
                  <AddEditEmployee
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.employee.permissions.update}
                  />
                }
              />
              <Route
                path='view-employees/:id'
                element={
                  <ViewEmployee
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.employee.permissions.view}
                  />
                }
              />
              <Route
                path='import-employee'
                element={
                  <ExcelMapper
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.employee.permissions.create}
                  />
                }
              />
              {/* Customer management */}
              <Route
                path='client'
                element={
                  <CustomerManagement
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.client.name}
                  />
                }
              />
              <Route
                path='add-client'
                element={
                  <AddCustomer
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData?.client.permissions.create}
                  />
                }
              />
              <Route
                path='edit-client'
                element={
                  <AddCustomer
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData?.client.permissions.create}
                  />
                }
              />
              <Route
                path='view-client/:id'
                element={
                  <ViewCustomerDetails
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.client.permissions.view}
                  />
                }
              />
              <Route
                path='add-contact'
                element={
                  <AddContact
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData?.client.permissions.create}
                  />
                }
              />
              <Route
                path='import-clients'
                element={
                  <ImportClients
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData?.client.permissions.create}
                  />
                }
              />
              {/* Roles */}
              <Route
                path='roles'
                element={
                  <Roles
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.roles.name}
                  />
                }
              />
              <Route
                path='roles/:id'
                element={
                  <RolePermissions
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.roles.permissions.update}
                  />
                }
              />
              {/* insurance-category  */}
              <Route
                path='insurance-category'
                element={
                  <InsuranceCategory
                    fallback={<Module />}
                    id={elementData.insurance_category.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path={'add-category'}
                element={
                  <AddClass
                    fallback={<Module />}
                    id={elementData.insurance_category.permissions.create}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='edit-category'
                element={
                  <AddClass
                    fallback={<Module />}
                    id={elementData.insurance_category.permissions.update}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='insurance-category/view/:id'
                element={
                  <ViewClassDetails
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.insurance_category.permissions.view}
                  />
                }
              />
              {/* insurer */}
              <Route
                path='insurer'
                element={
                  <Insurer
                    fallback={<Module />}
                    id={elementData.insurer.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='add-insurer'
                element={
                  <AddInsurer
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.insurer.permissions.create}
                  />
                }
              />
              <Route
                path='view-insurer/:id'
                element={
                  <ViewInsurer
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.insurer.permissions.view}
                  />
                }
              />
              {/* adjuster */}
              <Route
                path='adjuster'
                element={
                  <Adjuster
                    fallback={<Module />}
                    id={elementData.adjuster.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='add-adjuster'
                element={
                  <AddAdjuster
                    fallback={<Module />}
                    id={elementData.adjuster.permissions.create}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='view-adjuster/:id'
                element={
                  <ViewAdjuster
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.adjuster.permissions.view}
                  />
                }
              />
              {/* Sales Inquiry */}
              <Route
                path='lead'
                element={
                  <SalesInquiry
                    fallback={<Module />}
                    id={elementData.Leads.name}
                    permissions={permissions}
                  />
                }
              />
              {/* <Route
                path='create-leads'
                element={
                  <AddInquiry
                    fallback={<Module />}
                    id={elementData.Leads.name}
                    permissions={permissions}
                  />
                }
              /> */}
              <Route
                path='lead/:mode/:id/:step'
                element={
                  <LeadsModule
                    fallback={<Module />}
                    id={elementData.Leads.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='edit-lead/:id'
                element={
                  <AddInquiry
                    fallback={<Module />}
                    id={elementData.Leads.name}
                    permissions={permissions}
                  />
                }
              />
              {/* sales quotes */}
              <Route
                path='quotes'
                element={
                  <Quotes
                    fallback={<Module />}
                    id={elementData.Quote.name}
                    permissions={permissions}
                  />
                }
              />
              {/* sales targets */}
              <Route
                path='goals'
                element={
                  <SalesTargets
                    fallback={<Module />}
                    id={elementData.Goals.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='view-goal/:id'
                element={
                  <ViewTargetDetails
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.Goals.permissions.view}
                  />
                }
              />
              <Route
                path='/goal-type'
                element={
                  <TargetType
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.Goals_type.name}
                  />
                }
              />
              <Route
                path='add-quote'
                element={
                  <AddQuote
                    fallback={<Module />}
                    id={elementData.Quote.permissions.create}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='edit-quote'
                element={
                  <AddQuote
                    fallback={<Module />}
                    id={elementData.Quote.permissions.update}
                    permissions={permissions}
                  />
                }
              />

              <Route
                path='create-quote'
                element={
                  <QuotationTable
                    fallback={<Module />}
                    id={elementData.Quote.permissions.create}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='quotation'
                element={
                  <Quotation
                    fallback={<Module />}
                    id={elementData.Quote.permissions.view}
                    permissions={permissions}
                  />
                }
              />
              {/* profile */}
              <Route path='profile' element={<Profile />} />
              {/* Department */}
              <Route
                path='department'
                element={
                  <Department
                    fallback={<Module />}
                    id={elementData.department.name}
                    permissions={permissions}
                  />
                }
              />
              {/* Reasons */}
              <Route
                path='reasons'
                element={
                  <Reasons
                    fallback={<Module />}
                    id={elementData.designation.name}
                    permissions={permissions}
                  />
                }
              />
              {/* Designation */}
              <Route
                path='designation'
                element={
                  <Designation
                    fallback={<Module />}
                    id={elementData.designation.name}
                    permissions={permissions}
                  />
                }
              />
              {/* Reports */}
              <Route
                path='reports'
                element={
                  <NewReports
                    fallback={<Module />}
                    id={elementData.BussinessReport.name}
                    permissions={permissions}
                  />
                }
              />
              {/* <Route
              path="reports"
              element={
                <Reports
                  fallback={<Module />}
                  id={elementData.Report.name}
                  permissions={permissions}
                />
              }
            /> */}
              {/* <Route
              path="/add-report"
              element={
                <AddReport
                  fallback={<Module />}
                  id={elementData.department.name}
                  permissions={permissions}
                />
              }
            /> */}
              {/* Invoice */}
              <Route
                path='invoice'
                element={
                  <Invoice
                    fallback={<Module />}
                    id={elementData.Invoices.name}
                    permissions={permissions}
                  />
                }
              />
              {/* Playground to test and develop components */}
              <Route path='/playground' element={<Playground />} />

              <Route path='/*' element={<NotFound />} />
              {/* HR */}
              <Route
                path='leaves-type'
                element={
                  <LeavesType
                    fallback={<Module />}
                    id={elementData.leaves_type.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='leaves'
                element={
                  <Leaves
                    isRequesting={false}
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.leaves_request.name}
                  />
                }
              />
              <Route
                path='request-leave'
                element={
                  <Leaves
                    isRequesting={true}
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.request_leave.name}
                  />
                }
              />
              <Route
                path='attendance'
                element={
                  <Attendance
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.attendance.name}
                  />
                }
              />
              <Route
                path='provident-fund'
                element={
                  <ProvidentFund
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.providentFund.name}
                  />
                }
              />
              <Route
                path='hr/asset-type'
                element={
                  <AssetsType
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.assetType.name}
                  />
                }
              />
              <Route
                path='inventory-management'
                element={
                  <AllAssets
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.assets.name}
                  />
                }
              />
              <Route
                path='accounts'
                element={
                  <Accounts
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.accounts.name}
                  />
                }
              />
              <Route
                path='non-performing-employees'
                element={
                  <NonPerformingEmployees
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.non_performing_employees.name}
                  />
                }
              />
              <Route
                path='invoices'
                element={
                  <Invoices
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.Invoices.name}
                  />
                }
              />
              <Route
                path='attendance-settings'
                element={
                  <AttendanceSettings
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.attendance_settings.name}
                  />
                }
              />

              <Route
                path='holidays'
                element={
                  <Holidays
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.holidays_calender.name}
                  />
                }
              />
              <Route
                path='performance'
                element={
                  <Performance
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.performance.name}
                  />
                }
              />
              <Route
                path='pay-role'
                element={
                  <PayRole
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.performance.name}
                  />
                }
              />
              <Route
                path='invoices/create'
                element={
                  <CreateInvoice
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.performance.permissions.create}
                  />
                }
              />
              <Route
                path='invoices/edit/:id'
                element={
                  <CreateInvoice
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.performance.permissions.update}
                  />
                }
              />
              <Route
                path='my-attendance'
                element={
                  <MyAttendance
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.mark_attendance.name}
                  />
                }
              />
              {/* POS */}
              <Route
                path='management'
                element={
                  <PosManagement
                    fallback={<Module />}
                    id={elementData.pos.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='management/view'
                element={
                  <ViewPosManagement
                    fallback={<Module />}
                    id={elementData.pos.permissions.view}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='training'
                element={
                  <PosTraining
                    fallback={<Module />}
                    id={elementData.pos_training.permissions.view}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='terms-condition-edit'
                element={
                  <TermsCondition
                    fallback={<Module />}
                    id={elementData.termsCondition.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='terms-condition/view'
                element={
                  <ViewTermsCondition
                    fallback={<Module />}
                    id={elementData.termsCondition.permissions.view}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='pos-contract-edit'
                element={
                  <PosContract
                    fallback={<Module />}
                    id={elementData.posContract.name}
                    permissions={permissions}
                  />
                }
              />
              {/* HR Form */}
              <Route
                path='official-letters'
                element={
                  <OfficialLetters
                    fallback={<Module />}
                    id={elementData.letter.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='official-letter/create'
                element={
                  <AddTemplateContainer
                    fallback={<Module />}
                    id={elementData.letter.permissions.create}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='official-letter/edit/:id'
                element={
                  <AddTemplateContainer
                    fallback={<Module />}
                    id={elementData.letter.permissions.update}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='create-letter/:id'
                element={
                  <LetterTemplateSetting
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.letter.permissions.view}
                  />
                }
              />
              <Route
                path='policy'
                element={
                  <PolicyMangement
                    fallback={<Module />}
                    id={elementData.policy.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='/add-policy'
                element={
                  <AddPolicy
                    fallback={<Module />}
                    id={elementData.policy.permissions.create}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='/view-policy/:id'
                element={
                  <ViewPolicy
                    fallback={<Module />}
                    id={elementData.policy.permissions.view}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='commission'
                element={
                  <Commission
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.client.name}
                  />
                }
              />
              <Route
                path='lead-detail/:id'
                element={
                  <ViewInquiry
                    fallback={<Module />}
                    id={elementData.Leads.permissions.view}
                    permissions={permissions}
                  />
                }
              />
              {/* Leads POS and online */}
              <Route
                path='new-leads/:id'
                element={
                  // <ViewInquiry
                  //   fallback={<Module />}
                  //   id={elementData.Leads.name}
                  //   permissions={permissions}
                  // />
                  <Leads
                    fallback={<Module />}
                    id={elementData.Leads.permissions.view}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='commission-form'
                element={
                  <CommissionForm
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.earningSetting.name}
                  />
                }
              />
              <Route
                path='/reminders'
                element={
                  <Reminders
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.earningSetting.name}
                  />
                }
              />

              <Route path='setting' element={<Setting />} />

              {/* AssetAllocationForm */}
              {/* <Route
              path="asset-allocation"
              element={
                <AssetAllocationForm
                  fallback={<Module />}
                  permissions={permissions}
                  id={elementData.client.name}
                />
              }
            /> */}
              {/* RequestForAsset */}
              <Route
                path='request-for-asset'
                element={
                  <RequestForAsset
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.assetAllocation.name}
                  />
                }
              />
              {/* AssetRequest */}
              <Route
                path='asset-request'
                element={
                  <AssetRequest
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.assetRequest.name}
                  />
                }
              />
              {/* Payroll Management */}
              <Route
                path='payroll'
                element={
                  <PayRollManagement
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.payroll.name}
                  />
                }
              />
              <Route
                path='job-kpr-kpi'
                element={
                  <JobKprKpi
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.jobKra.name}
                  />
                }
              />
              <Route
                path='job-management'
                element={
                  <JobManagement
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.manageRecruitment.name}
                  />
                }
              />
              <Route
                path='job-interviews'
                element={
                  <Interviewmanagement
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.interview.name}
                  />
                }
              />
              <Route
                path='quotation-engine'
                element={
                  <QuotationEngine
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.payroll.name}
                  />
                }
              />
              <Route
                path='proposals'
                element={
                  <Proposals
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.payroll.name}
                  />
                }
              />

              <Route
                path='form'
                element={
                  <CategoryForm
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.insurance_category.name}
                  />
                }
              />

              <Route
                path='transactions/:id'
                element={
                  <Transactions
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.transactions.name}
                  />
                }
              />
              <Route
                path='learning-asset'
                element={
                  <LearningAsset
                    fallback={<Module />}
                    // permissions={permissions}
                    // id={elementData.interview.name}
                  />
                }
              />
              <Route
                path='pos-earning'
                element={
                  <PosEarning
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.posEarning.name}
                  />
                }
              />
              <Route
                path='sales-report'
                element={
                  <DailySalesReport
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.dailyReport.name}
                  />
                }
              />
              <Route
                path='reconciliation'
                element={
                  <Reconciliation
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.dailyReport.name}
                  />
                }
              />
              <Route
                path='daily-goals'
                element={
                  <DailyGoals
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.dailyGoals.name}
                  />
                }
              />
              <Route
                path='expense-report'
                element={
                  <ExpenseReport
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.ExpenseReport.name}
                  />
                }
              />
              {/* Personal Module */}

              <Route
                path='goals'
                element={
                  <DailyGoals
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.goals_daily.name}
                  />
                }
              />

              {/* Support Module */}

              <Route
                path='helpline'
                element={
                  <Helpline
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.Helpline.name}
                  />
                }
              />

              <Route
                path='faq'
                element={
                  <FAQ fallback={<Module />} permissions={permissions} id={elementData.FAQ.name} />
                }
              />

              <Route
                path='ticket'
                element={
                  <Ticket
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.ticket.name}
                  />
                }
              />
              <Route
                path='ticket-review/:id'
                element={
                  <TicketReview
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.ticket.permissions.view}
                  />
                }
              />

              <Route path='/notifications' element={<Notifications />} />

              {/*  */}
              <Route
                path='company-expense'
                element={
                  <CompanyExpense
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.company.name}
                  />
                }
              />
              <Route
                path='view-client-log'
                element={
                  <ViewLog
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.client.name}
                  />
                }
              />
            </Routes>
          </main>
        </StoreProvider>
      </div>
    </div>
  );
};

export default Admin;
