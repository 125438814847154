import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableComponent, Button, CustomModal } from 'components';
import { UpcomingInterview_Cols } from './data';
import { getUpcomingInterview } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const UpcomingInterview = (props = {}) => {
  const date = props?.date || '';
  /* states */
  const [loading, setLoading] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const navigate = useNavigate();
  const [viewInterview, setViewInterview] = useState(false);
  const [data, setData] = useState('');

  /* func */
  const fetchData = async () => {
    setLoading(true);
    const resp = await getUpcomingInterview({ status: 'pending', date });
    if (resp?.data?.status) {
      setdataSource(resp?.data?.data);
    }
    setLoading(false);
  };
  // console.info('first',data?.data);

  const handleView = (data) => {
    setData(data);
    setViewInterview(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='space-y-4 bg-white p-4 border rounded-md border-grey-40 h-full'>
      <div className='flex items-center justify-between'>
        <div className='font-semibold text-s leading-6'>Upcoming Interviews</div>
        <Button
          classes='!py-1'
          text='+ Schedule'
          onClick={navigate.bind(this, '/hr/job-management')}
        />
      </div>
      <div className='customTable' id='dashboard'>
        <TableComponent
          getRowClassName={rowCommonClass}
          loading={loading}
          pageSize='3'
          columns={[
            ...UpcomingInterview_Cols,
            {
              key: 'action',
              title: 'Action',
              align: 'right',
              render: (_, record) => (
                <Button
                  classes={'!py-1 !px-2 !text-xxxs'}
                  text='View'
                  variant='secondary'
                  onClick={() => handleView(record)}
                />
              ),
            },
          ]}
          dataSource={dataSource}
          showPagination={false}
          scroll={{ y: 175 }}
        />
      </div>
      <CustomModal
        destroyOnClose={true}
        open={viewInterview}
        onCancel={setViewInterview.bind(this, false)}
        footer={''}
      >
        <div className='flex flex-col'>
          <span className='font-medium text-l 2xl:text-xxl'>Interview Details</span>
        </div>
        {/* <Divider/> */}
        <div className='flex flex-row gap-8'>
          <div className='flex flex-col flex-grow'>
            <span className={`text-xs font-medium capitalize`}>Candidate</span>
            <span className={`text-xxs break-words capitalize`}>{data?.name}</span>
          </div>
          <div className='flex flex-col flex-grow'>
            <span className={`text-xs font-medium capitalize`}>Position</span>
            <span className={`text-xxs break-words capitalize`}>{data?.designation}</span>
          </div>
          {/* <div className='flex flex-col flex-grow'>
            <span className={`text-xs font-medium capitalize`}>Interviewer</span>
            <span className={`text-xxs break-words capitalize`}>{data?.employees?.name}</span>
          </div> */}
          <div className='flex flex-col flex-grow'>
            <span className={`text-xs font-medium capitalize`}>Status</span>
            <span className={`text-xxs break-words capitalize`}>{data?.status}</span>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default UpcomingInterview;
