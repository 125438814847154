import React, { useContext, useState } from 'react';
import { TabsComponent, UploadImage } from 'components';
import Project from './Project';
import EmergencyContact from './EmergencyContact';
import MyProfile from './MyProfile';
import { Context } from 'store/context';
import LanguageSkills from './LanguageSkills';
import Education from './Education';
import WorkExperience from './WorkExperience';

const Profile = () => {
  const [key, setActiveTabKey] = useState('1');
  const { user, setUser } = useContext(Context);

  const userName = user?.name?.charAt(0)?.toUpperCase() + user?.name?.slice(1);
  const tabs = [
    {
      key: '1',
      label: `My Profile`,
      Component: MyProfile,
    },
    {
      key: '2',
      label: `Education`,
      Component: Education,
    },
    {
      key: '3',
      label: `Work Experience`,
      Component: WorkExperience,
    },
    {
      key: '4',
      label: `Projects`,
      Component: Project,
    },
    {
      key: '5',
      label: `Language & Skills`,
      Component: LanguageSkills,
    },
    {
      key: '6',
      label: `Emergency Contact`,
      Component: EmergencyContact,
    },
    // {
    //   key: "7",
    //   label: `Leaves`,
    //   Component: <MyProfile />,
    // },
    // {
    //   key: "8",
    //   label: `Claims`,
    //   Component: <MyProfile />,
    // },
  ];

  return (
    <div className='grid '>
      <div className='bg-white rounded-md flex flex-row items-center gap-2'>
        <UploadImage user={user} getUser={setUser} />
        <div className='pt-2'>
          <span className='text-sm font-medium block'>{userName || 'User'}</span>
          <span className='text-xxs text-grey-80'>
            {user?.user_type === 'employee' ? user?.designation || '--' : 'Client'}
          </span>
        </div>
      </div>
      <TabsComponent items={tabs} activeKey={key} setActiveTabKey={setActiveTabKey} />
    </div>
  );
};

export default Profile;
