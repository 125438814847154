import React, { useContext, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import {
  Checkbox,
  CustomModal,
  FormContainer,
  PhoneNumberInput,
  SearchAPI,
  SelectInput,
  TextInput,
  Toast,
  Uploadbox,
} from 'components';
import Button from 'components/button';
import DateRangePicker from 'components/form-components/DateRangePicker';
import { NOTIFICATION_MSG } from 'constants';
import { getAllLeaves, leaveManagement, getLeaveBalance } from 'service/api-service';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { extractBase64String } from 'utils/common';
import MultiDatePicker from 'components/forms/multi-date-picker';
import { Context } from 'store/context';

const AddEditLeave = ({
  open = false,
  toggleMethod = () => {},
  loading = false,
  editInitials,
  setLoading,
  getLeaves,
}) => {
  const [allDocs, setAllDocs] = useState([]);
  const [leaveType, setLeaveType] = useState([]);
  const [pendingLeave, setPendingLeave] = useState(null);
  const [leaveDays, setLeaveDays] = useState(null);

  // const leaveDaysRef = useRef(null);

  const onEditLeave = async (data) => {
    setLoading(true);
    const editedFields = getEditedFields({
      initialValues: editInitials,
      updatedData: data,
    });
    if (Object.keys(editedFields).length) {
      const resp = await leaveManagement({
        method: 'patch',
        data: editedFields,
        id: editInitials?.id,
      });
      if (resp?.data?.status) {
        getLeaves();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to update data');
      }
      setLoading(false);
    } else {
      setLoading(false);
      return Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  const fetchPendingLeaves = async (id, days) => {
    // days are total leaves
    try {
      const res = await getLeaveBalance(id);
      if (res?.status) {
        let used = res?.data?.data?.used || 0;
        setPendingLeave(days - used);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const on_Change = (_, e) => {
    setLoading(true);
    const docs = e?.docs ? JSON.parse(e?.docs) : [];
    fetchPendingLeaves(e?.id, e?.days);
    const allDocuments = [];

    for (let i = 0; i < docs?.length; i++) {
      allDocuments.push({
        label: `Upload ${docs[i]}`,
        name: `${docs[i]?.trim()}`,
        id: `${docs[i]?.trim()}`,
        placeholder: `Upload  ${docs[i]} `,
        required: true,
        component: Uploadbox,
        accept: ['.png, .jpeg, .jpg,'],
        divClasses: `col-span-full `,
      });
    }
    addLeaveFields.Documents = {
      fields: allDocuments,
    };
    setAllDocs(allDocuments);
    setLeaveType(e?.id);
    setLoading(false);
  };

  const addLeaveFields = {
    'Leave Details': {
      fields: [
        {
          label: 'Leave Type',
          name: 'leave_type',
          id: 'leave_type',
          placeholder: ' Leave Type ',
          type: 'select',
          customChange: on_Change,
          component: SelectInput,
          options: [],
          fetchOptions: async () => getAllLeaves(),
        },
        {
          label: 'From - To',
          name: 'date',
          id: 'date',
          placeholder: 'MM-DD-YYYY',
          type: 'date',
          required: true,
          showTime: false,
          component: DateRangePicker,
          disablePrev: true,
          customOnChange: (date, form) => {
            const date1 = dayjs(date[0]);
            const date2 = dayjs(date[1]);
            const days = Math.abs(date1.diff(date2, 'day'));
            setLeaveDays(days + 1);
            form.setFieldValue('days', days + 1);
          },
        },
        {
          label: 'Number of Days',
          name: 'days',
          id: 'days',
          disabled: true,
          placeholder: 'total days',
          type: 'number',
          component: TextInput,
        },
        {
          dependent: true,
          dependent_on: 'days',
          dependent_value: leaveDays > pendingLeave ? leaveDays : pendingLeave,
          label: 'Is this emergency leave?',
          name: 'emrgncy',
          id: 'emrgncy',
          placeholder: 'Select',
          type: 'select',
          component: SelectInput,
          options: [
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ],
        },
        {
          label: 'Work Handover Employee',
          name: 'assign_to_employee',
          id: 'assign_to_employee',
          placeholder: 'Work Handover Employee',
          required: true,
          type: 'search',
          component: SearchAPI,
          maxLength: 30,
        },
        {
          label: 'Reason',
          name: 'reason',
          id: 'reason',
          placeholder: 'Reason',
          type: 'text',
          component: TextInput,
          isMultiline: true,
          rows: 5,
          divClasses: 'col-span-full',
        },
      ],
    },
    'Contact Information During Leave': {
      fields: [
        {
          label: 'Contact Person Name',
          name: 'c_name',
          id: 'c_name',
          placeholder: ' Contact Person Name',
          type: 'text',
          required: true,
          component: TextInput,
        },
        {
          label: 'Contact Person Phone Number',
          name: 'c_phone',
          id: 'c_phone',
          placeholder: 'Contact Person Phone Number',
          required: true,
          type: 'number',
          maxLength: 10,
          component: TextInput,
        },
      ],
    },
    ...(allDocs &&
      allDocs?.length && {
        Documents: {
          fields: allDocs,
        },
      }),
    Checkbox: {
      showAccordionHeader: false,
      fields: [
        // {
        //   label: 'Emergency leave',
        //   name: 'emrgncy',
        //   id: 'emrgncy',
        //   type: 'checkbox',
        //   required: false,
        //   component: Checkbox,
        // },
        {
          label: (
            <span>
              By continuing , you agree to our{' '}
              <Link to='/terms-condition' target={'__blank'} className='text-primary-90'>
                Terms & Policies
              </Link>
            </span>
          ),
          name: 'agree',
          id: 'agree',
          type: 'checkbox',
          required: false,
          component: Checkbox,
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addLeaveFields,
  });

  const onAddLeave = async (data) => {
    const { c_name, c_phone, emrgncy, days = 0 } = data;
    const from_date = data?.date[0];
    const to_date = data?.date[1];
    // const startDate = dayjs(data?.date[0]);
    // const endDate = dayjs(data?.date[1]);
    // const days = endDate.diff(startDate, 'day') + 1;

    let finalPayload = {
      reason: data?.reason,
      from_date,
      to_date,
      days: days || 0,
      leave_type: data?.leave_type || leaveType,
      docs: [],
      w_handovr: data?.assign_to_employee,
      c_name,
      c_phone,
      emrgncy: !!emrgncy,
    };
    const nonFileNames = [
      'leave_type',
      'date',
      'reason',
      'c_name',
      'c_phone',
      'emrgncy',
      'assign_to_employee',
      'days',
      'agree',
      'dates',
      'from_date',
      'to_date',
    ];

    Object.keys(data).forEach((key) => {
      if (!nonFileNames.includes(key)) {
        finalPayload['docs'] = [...finalPayload['docs'], { doc_name: key, doc: data[key] }];
      }
    });

    setLoading(true);
    const resp = await leaveManagement({
      method: 'post',
      data: finalPayload,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      getLeaves();
      toggleMethod();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  return (
    <>
      {/* add leaves modal */}
      <CustomModal
        title={'Add Leave'}
        destroyOnClose={true}
        open={open}
        onCancel={toggleMethod}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10'>
            To add a new leave, enter the details of the leave in the input field below.
          </span>

          <FormContainer
            // disableForm={true}
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditLeave : onAddLeave}
            initialValues={initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, values }) => {
              return (
                <div className='flex justify-between items-center'>
                  <div className='flex gap-2'>
                    {pendingLeave !== null && (
                      <>
                        <h5>Pending Leaves - </h5>
                        <p className='font-semibold'>{pendingLeave}</p>
                      </>
                    )}
                  </div>
                  <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                    <Button
                      text='Cancel'
                      variant='text'
                      type='reset'
                      classes='border border-gray-90 text-black px-6'
                      onClick={() => {
                        setAllDocs([]);
                        resetForm();
                        toggleMethod();
                      }}
                    />
                    <Button
                      disabled={!values?.agree}
                      //   permissions={permissions}
                      text={'Add Leave'}
                      variant='primary'
                      classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                      onClick={submitForm}
                      loading={loading}
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
    </>
  );
};

export default AddEditLeave;
