import { TextInput, SelectInput, SearchAPI } from 'components';

export const addTicketFields = {
  'Ticket Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Reason',
        name: 'reason',
        id: 'reason',
        placeholder: 'Reason',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 100,
      },
    ],
  },
};
