/** external deps */
import React, { useState, useCallback, useEffect } from 'react';
import { Button as AntButton } from 'antd';

import { getParticularLeave } from 'service/api-service';

import { useLocation } from 'react-router-dom';
import ViewModal from './modal/ViewModal';
import ForwardModal from './modal/ForwardModal';
import ConfirmModal from './modal/ConfirmModal';
import ReasonModal from './modal/ReasonModal';
import { IconButton } from 'components';
import { elementData } from 'elements';
import { SecureComponent } from 'permission-provider';

const AcknowlegedLeaveRequest = ({
  record,
  showViewOnly,
  viewBtnClasses,
  setLeaveStatus,
  isRequesting,
  status,
  permissions,
}) => {
  const location = useLocation();
  const userId = location?.search;
  const viewModalForId = Number(userId.split('?')[1]);
  const leaveStatus = record?.status?.toLowerCase();

  const [data, setData] = useState(record);
  const [loading, setLoading] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const [showReason, setShowReason] = useState();
  const [leaveDocs, setLeaveDocs] = useState([]);
  const [assignees, setAssignee] = useState([]);
  const [showFwdModal, setShowFwdModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    if (viewModalForId === record?.id) {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }
  }, [viewModalForId]);

  const onGetLeave = async () => {
    try {
      setLoading(true);
      const resp = await getParticularLeave(record?.id);
      setData(resp?.data?.data);
      setLeaveDocs(resp?.data?.data?.docs || []);
      setAssignee(resp?.data?.data?.assignees || []);
    } catch (error) {}
    setLoading(false);
  };

  const onInfo = useCallback(async () => {
    onGetLeave();
    setShowInfo(true);
  }, []);

  const onReason = useCallback(() => {
    setShowInfo(false);
    setShowReason(true);
  }, []);

  const toggleFwdModal = useCallback(() => {
    setShowInfo(false);
    setShowFwdModal((pre) => !pre);
  }, []);

  const toggleApprModal = () => {
    setShowInfo(false);
    setShowConfirmModal(true);
  };

  return (
    <div className='w-fit flex flex-wrap gap-2'>
      <AntButton disabled={loading} onClick={onInfo} className={`${viewBtnClasses}`}>
        View
      </AntButton>
      {!isRequesting && (
        <>
          <IconButton
            id={elementData.leaves_request.permissions.update}
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            onClick={toggleFwdModal}
          >
            <div className='border border-grey-40 rounded-md px-2 py-1 hover:border-primary-60 hover:text-primary-60'>
              Forward
            </div>
          </IconButton>
          {!showViewOnly && (
            <>
              {leaveStatus !== 'approved' && (
                <IconButton
                  id={elementData.leaves_request.permissions.update}
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => setShowConfirmModal(true)}
                >
                  <div className='bg-success text-white border rounded-md px-2 py-1'>Approve</div>
                </IconButton>
              )}

              {leaveStatus !== 'declined' && (
                <IconButton
                  id={elementData.leaves_request.permissions.update}
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  onClick={onReason}
                >
                  <div className='bg-danger text-white border rounded-md px-2 py-1'>Decline</div>
                </IconButton>
              )}
            </>
          )}
        </>
      )}

      {/* View modal */}
      <ViewModal
        open={showInfo}
        onCancel={() => setShowInfo(false)}
        record={record}
        assignees={assignees}
        loading={loading}
        leaveDocs={leaveDocs}
        data={data}
        toggleFwdModal={toggleFwdModal}
        leaveStatus={leaveStatus}
        onReason={onReason}
        toggleApprModal={toggleApprModal}
        isRequesting={isRequesting}
        permissions={permissions}
      />
      {/* Decline reason modal */}
      <ReasonModal
        open={showReason}
        status={status}
        onCancel={() => setShowReason(false)}
        record={record}
      />
      {/* Decline Confirm modal */}
      <ConfirmModal
        open={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        record={record}
        status={status}
      />
      {/* Forward modal */}
      <ForwardModal open={showFwdModal} onCancel={() => setShowFwdModal(false)} record={record} />
    </div>
  );
};

export default SecureComponent(AcknowlegedLeaveRequest);
