/** external deps */
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/** internal deps */
import {
  ContentHeader,
  FormContainer,
  IconButton,
  Toast,
  Button,
  Module,
  AddProducts,
} from 'components';
import { elementData } from 'elements';
import { SecureComponent } from 'permission-provider';
import { addInsuranceCategory, editInsuranceCategory } from 'service/api-service';
import { getEditedFields, getPermission, handleDefaultValues, prepareFormFieldsData } from 'utils';
import { addClassBreadCrumbs, addClassFields, editClassBreadCrumbs } from '../data';
import { Context } from 'store/context';
import { BackArrow } from 'assets/icons';
import { NOTIFICATION_MSG } from 'constants';

const AddClass = (props = {}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = useContext(Context);
  const permissions = props?.permissions || {};

  const [loading, setLoading] = useState(false);

  const isEditing = state?.initialValues ? true : false;
  const [initialValues, finalFormFields] = prepareFormFieldsData({
    fields: addClassFields,
    fieldsToExclude: [],
    sectionsToExclude: isEditing ? [] : ['Liability Coverage', 'Risk Details'],
  });

  /** funcs */

  const onAddClass = async (data) => {
    setLoading(true);
    const finalPayload = {
      ...data,
      name: data?.name.toUpperCase(),
      code: data?.code.toUpperCase(),
      assign_to_employee:
        typeof data?.assign_to_employee === 'number'
          ? data?.assign_to_employee
          : Object.keys(user).length > 0
          ? user?.id
          : '',
    };
    const resp = await addInsuranceCategory({ data: finalPayload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      navigate(-1);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onUpdateClass = async (updatedData) => {
    setLoading(true);
    const data = getEditedFields({
      initialValues: { ...initialValues, ...state.initialValues },
      updatedData,
    });
    if (Object.keys(data).length > 0) {
      const resp = await editInsuranceCategory({
        data,
        id: state?.initialValues?.id,
      });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        navigate(-1);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  return (
    <div className='grid gap-3'>
      {getPermission(permissions, elementData.insurance_category.permissions.create)?.write ? (
        <>
          <ContentHeader
            showSearch={false}
            data={isEditing ? editClassBreadCrumbs : addClassBreadCrumbs}
          />
          <div className='bg-white p-4 rounded-2xl flex flex-col'>
            <div className='flex justify-between'>
              <div className='flex flex-col '>
                <span className='text-l 2xl:text-xxl font-medium'>
                  {isEditing ? 'Edit Insurance Category' : 'Add Insurance Category'}
                </span>
                <span className='text-xxs text-grey-80 '>
                  {isEditing
                    ? 'To edit Insurance Category, enter the details of the Category in the input field below.'
                    : 'To add a new Insurance Category, enter the details of the Category in the input field below.'}
                </span>
              </div>
              <IconButton onClick={() => navigate(-1)}>
                <BackArrow svgClass='fill-grey-90 w-10 h-10' />
              </IconButton>
            </div>

            <FormContainer
              accordionClass='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'
              fields={finalFormFields}
              initialValues={
                isEditing
                  ? {
                      ...(state?.initialValues ?? {}),
                      insurer: Array.isArray(state?.initialValues?.insurers)
                        ? state.initialValues.insurers.map((insurer) => insurer.id)
                        : [],
                    }
                  : {
                      ...initialValues,
                      assign_to_employee: handleDefaultValues({ user }),
                    }
              }
              onSubmit={isEditing ? onUpdateClass : onAddClass}
              customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
                return (
                  <>
                    <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                      <Button
                        text='Cancel'
                        variant='text'
                        type='reset'
                        classes='border border-gray-90 text-black px-6'
                        onClick={() => {
                          resetForm();
                          navigate(-1);
                        }}
                      />
                      <Button
                        disabled={!isValid}
                        id={
                          state?.initialValues
                            ? elementData.insurance_category.permissions.update
                            : elementData.insurance_category.permissions.create
                        }
                        permissions={permissions}
                        text={state?.initialValues ? 'Update Category' : 'Add Category'}
                        variant='primary'
                        classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                        onClick={submitForm}
                        loading={loading}
                      />
                    </div>
                  </>
                );
              }}
            />
          </div>
          {isEditing && (
            <AddProducts
              productinitialValues={
                isEditing
                  ? state?.initialValues
                  : {
                      ...initialValues,
                      assign_to_employee: handleDefaultValues({ user }),
                    }
              }
            />
          )}
        </>
      ) : (
        <Module />
      )}
    </div>
  );
};

export default SecureComponent(AddClass);
