import SideMenu from '../SideMenu';
import LeadComponent from '../LeadComponent';
import CommentSection from 'components/lead-form/lead-components/CommentSection';
import InquiryTimeline from 'components/lead-form/lead-components/InquiryTimeline';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { useLeads } from 'screens/leads/store';

const LeadsModule = ({ permissions }) => {
  const params = useParams();
  /** @type {Quotation} */
  const quotationsData = useLeads((state) => state.quotations.data);
  // const selectedModuleId = useLeads((state) => state.selectedModuleId);
  const {
    initQuotationData,
    updateLeadId,
    addBasicLeadsDetails,
    setProductArray,
    setIsOnlineProduct,
  } = useLeads(
    useShallow((state) => ({
      addBasicLeadsDetails: state.addBasicLeadsDetails,
      updateLeadId: state.updateLeadId,
      initQuotationData: state.initQuotationData,
      setProductArray: state.setProductArray,
      setIsOnlineProduct: state.setIsOnlineProduct,
    })),
  );

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  useResetLeadsStore();
  useInitLeadsStore();
  // useInitStep();

  // const finadModule = () => {
  //   switch (selectedModuleId) {
  //     case 1:
  //       return 'Lead';
  //     case 2:
  //       return 'Quotation';
  //     case 3:
  //       return 'Proposal';
  //     case 4:
  //       return 'Policy';
  //     default:
  //       return 'Lead';
  //   }
  // };

  useEffect(() => {
    if (params.id !== 'new') {
      updateLeadId(params.id);
      initQuotationData(params.id);
    } else if (params.step !== '1') {
      navigate('/lead/create/new/1');
    }
  }, [initQuotationData, searchParams, updateLeadId, params.id, params.step, navigate]);

  useEffect(() => {
    if (state?.customer) {
      const customer = {
        name: state.customer?.customer_name || state.customer?.corp_name || state?.customer?.name,
        id: state.customer?.id,
        email_address: state.customer?.email_address,
        contact_number: state.customer?.contact_number,
        branch_of: state.customer?.branch_of,
        customer_type: state?.customer?.customer_type,
        date_of_birth: state?.customer?.date_of_birth,
      };
      addBasicLeadsDetails({
        client: customer,
      });
    } else if (quotationsData) {
      const customer = {
        name: quotationsData?.customer?.name || quotationsData?.customer?.corp_name,
        id: quotationsData?.customer?.id,
        email_address: quotationsData?.customer?.email_address,
        contact_number: quotationsData?.customer?.contact_number,
        branch_of: quotationsData?.customer?.branch_of,
        customer_type: quotationsData?.customer?.customer_type,
      };
      addBasicLeadsDetails({
        client: customer,
        response: quotationsData?.response ?? 'interested',
        assignTo: quotationsData?.assigned_to,
        renewalDate: quotationsData?.renewal,
        addProductsChecked: quotationsData?.response === 'presently_insured',
      });
      setIsOnlineProduct(quotationsData?.online);

      const products = quotationsData?.products;

      const insurance = quotationsData?.insurance_details;

      // Create a new array with details from insurance and corresponding id from products
      const ProductsWithId = insurance?.map((insuranceItem) => {
        const matchingProduct = products?.find(
          (product) => product.product_type?.id === insuranceItem.productId,
        );

        return matchingProduct
          ? { ...insuranceItem, id: matchingProduct.id, productName: matchingProduct.name }
          : { ...insuranceItem, id: null, productName: null };
      });
      setProductArray(ProductsWithId || []);
    }
  }, [quotationsData, addBasicLeadsDetails, setProductArray, state, setIsOnlineProduct]);

  return (
    <div>
      <div className='flex mb-2 justify-end'>
        {/* <h2 className='h-10 font-semibold capitalize text-s'>
          {params.mode} {finadModule()}
        </h2> */}
        <div className='flex gap-3'>
          <CommentSection id={params.id} />
          <InquiryTimeline leadId={params.id} state={state?.inquiryMethod} />
        </div>
      </div>
      <div className='flex justify-between w-full'>
        <SideMenu />
        <LeadComponent permissions={permissions} />
      </div>
    </div>
  );
};

export default LeadsModule;

const useInitLeadsStore = () => {
  const updateHighestVisitedStep = useLeads((state) => state.updateHighestVisitedStep);
  const { step } = useParams();

  useEffect(() => {
    updateHighestVisitedStep(Number.isNaN(+step) ? 1 : +step);
  }, [step, updateHighestVisitedStep]);
};

const useResetLeadsStore = () => {
  const resetLeadsStore = useLeads((state) => state.resetLeadsStore);

  useEffect(() => {
    return () => resetLeadsStore();
  }, [resetLeadsStore]);
};
