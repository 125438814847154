/** external deps */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Collapse, DatePicker, Space, Table } from 'antd';
import dayjs from 'dayjs';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  IconButton,
  Toast,
  Loader,
  TableComponent,
  MultiUserInitial,
} from 'components';
import { addExpenseFields } from './data';
import { View } from 'assets/icons';
import { prepareFormFieldsData } from 'utils';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { expenseManagement } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { Context } from 'store/context';
import { rowCommonClass } from 'utils/common';

const { Panel } = Collapse;
const DATE = dayjs().format('YYYY-MM');

const CreateExpense = (props = {}) => {
  const permissions = props?.permissions || [];
  const showAddModal = props?.showAddModal;
  const setAddModal = props?.setAddModal;
  /* states  */
  const { user } = useContext(Context);
  const [showViewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState();
  const [editInitials, setInitials] = useState();
  const [tableData, setTableData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [total, setTotal] = useState(0);
  const [month, setMonth] = useState();
  const [loading, setLoading] = useState(false);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addExpenseFields,
    defaultValues: { type: 'custom', date: dayjs().format('YYYY-MM-DD') },
  });

  const columns = [
    {
      key: 'name',
      title: 'Title',
      dataIndex: 'name',
    },
    {
      key: 'expense_type',
      title: 'Expense Type',
      dataIndex: 'expense_type',
      render: (data) => data?.name || '\u2014',
    },
    {
      key: 'amount',
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      key: 'inv_persons',
      title: 'Involved Persons',
      dataIndex: 'inv_persons',
      width: 300,
      render: (data) => (
        <div className='truncate'>
          {' '}
          <MultiUserInitial data={data} value={'name'} label='involved persons' />
        </div>
      ),
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='View Bill/Receipts'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.company.permissions.view}
            onClick={onViewPress.bind(this, record)}
          >
            <View svgClass='stroke-primary group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  const renderCards = (item, index) => {
    return (
      <div className='p-2'>
        <Collapse key={index} className='w-full' defaultActiveKey={['8']}>
          <Panel className='relative font-semibold' header={item.doc_name} key='1'>
            <object
              className='m-auto box-border min-h-[400px] min-w-[500px] max-w-[500px] h-fit w-fit '
              // type='image/jpeg'
              data={process.env.REACT_APP_DOC_URL + item.doc}
            >
              <Loader />
            </object>
          </Panel>
        </Collapse>
      </div>
    );
  };

  /* funcs */

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await expenseManagement({
      method: 'get',
      start: dayjs().startOf('month').format('YYYY-MM-DD'),
      end: dayjs().endOf('month').format('YYYY-MM-DD'),
    });
    if (resp?.data?.data) {
      setTableData(resp?.data?.data?.expenses?.result);
      setTotalAmount(resp?.data?.data?.total_amount);
      setPageNum(resp?.data?.data?.expenses?.current_page);
      setTotal(resp?.data?.data?.expenses?.total_page);
    } else Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const onAddExpense = useCallback(async (data) => {
    setLoading(true);
    const resp = await expenseManagement({ method: 'post', data: data });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleAddExpense();
      fetchData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, []);

  const toggleAddExpense = useCallback(() => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  }, []);

  const onViewPress = useCallback((record) => {
    setViewData(record);
    setViewModal(true);
  }, []);

  const onMonthChange = async (date, month) => {
    setLoading(true);
    if (month) {
      setMonth(month);
      const resp = await expenseManagement({
        method: 'get',
        start: dayjs(month).startOf('month').format('YYYY-MM-DD'),
        end: dayjs(month).endOf('month').format('YYYY-MM-DD'),
      });
      if (resp?.data?.data) {
        setTableData(resp?.data?.data?.expenses?.result);
        setTotalAmount(resp?.data?.data?.total_amount);
        setPageNum(resp?.data?.data?.expenses?.current_page);
        setTotal(resp?.data?.data?.expenses?.total_page);
      } else Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      setLoading(false);
    } else Toast('error', NOTIFICATION_MSG.error, 'Please choose a month');
  };

  const onPagination = useCallback(
    async (pageNum) => {
      setLoading(true);
      const resp = await expenseManagement({
        method: 'get',
        start: dayjs(month).startOf('month').format('YYYY-MM-DD'),
        end: dayjs(month).endOf('month').format('YYYY-MM-DD'),
        page: pageNum,
      });
      if (resp?.data?.data) {
        setTableData(resp?.data?.data?.expenses?.result);
        setTotalAmount(resp?.data?.data?.total_amount);
        setPageNum(resp?.data?.data?.expenses?.current_page);
        setTotal(resp?.data?.data?.expenses?.total_page);
      } else Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      setLoading(false);
    },
    [month],
  );

  return (
    <>
      <DatePicker
        className='w-36 absolute right-2 -top-14'
        picker='month'
        onChange={onMonthChange}
        defaultValue={dayjs(DATE, 'YYYY-MM')}
      />
      <div className='grid gap-3 relative'>
        <TableComponent
          loading={loading}
          columns={columns}
          getRowClassName={rowCommonClass}
          total={total}
          defaultPage={pageNum}
          dataSource={tableData}
          onChange={onPagination}
          summary={(pageData) => {
            return (
              <Table.Summary.Row className='h-16 font-bold bg-grey-light text-s'>
                <Table.Summary.Cell colSpan={5}>Total Company Expense</Table.Summary.Cell>
                <Table.Summary.Cell align='right'>
                  {totalAmount ? user?.currency + totalAmount?.toFixed(2) : '0'}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />

        {/* add expense modal */}
        <CustomModal
          destroyOnClose={true}
          open={showAddModal}
          onCancel={toggleAddExpense}
          title={editInitials ? 'Edit Expense' : 'Add Expense '}
          footer={''}
        >
          <div>
            <span>
              To {editInitials ? 'edit' : 'add a new'} expense , enter the details in fields given
              below.
            </span>
            <FormContainer
              accordionClass='grid grid-cols-2 gap-8'
              onSubmit={onAddExpense}
              initialValues={initialValues}
              fields={finalFields}
              customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
                return (
                  <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                    <Button
                      text='Cancel'
                      variant='text'
                      type='reset'
                      classes='border border-gray-90 text-black px-6'
                      onClick={() => {
                        resetForm();
                        toggleAddExpense();
                      }}
                    />
                    <Button
                      disabled={!isValid}
                      permissions={permissions}
                      id={
                        editInitials
                          ? elementData.company.permissions.update
                          : elementData.company.permissions.create
                      }
                      text={editInitials ? 'Update Expense' : 'Add Expense'}
                      variant='primary'
                      classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                      onClick={submitForm}
                      loading={loading}
                    />
                  </div>
                );
              }}
            />
          </div>
        </CustomModal>
        {/* View Bill Modal */}
        <CustomModal
          destroyOnClose={true}
          open={showViewModal}
          onCancel={setViewModal.bind(this, false)}
          footer={''}
          title={'Bills / Receipts'}
        >
          <div>
            <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10'>
              Bills and receipts are listed below.
            </span>

            {viewData?.docs.length ? (
              viewData?.docs.map(renderCards)
            ) : (
              <p className='text-gray-90 '> Document not Uploaded </p>
            )}
          </div>
        </CustomModal>
      </div>
    </>
  );
};

export default SecureComponent(CreateExpense);
