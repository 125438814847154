import { Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import React, { useCallback, useEffect, useState } from 'react';
import { getPosDoc, posContractMangement } from 'service/api-service';

const AgentContract = (props = {}) => {
  /* states  */
  const [data, setData] = useState();
  const [object, setObject] = useState();

  /* funcs */
  const fetchData = useCallback(async () => {
    const resp = await posContractMangement({ method: 'get' });
    if (resp?.data?.status) {
      setData(resp?.data?.data[0]);
    } else Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');

    const response = await getPosDoc({ id: props?.id });
    if (response?.data?.status) {
      setObject(response?.data?.data);
    } else Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
  }, [props?.id]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className='flex justify-between items-center'>
        <h2 className='font-semibold text-s'>Contract</h2>
      </div>
      {/* <object data={data?.contract}></object> */}
      <div>
        <a href={process.env.REACT_APP_DOC_URL + data?.contract} target='_blank' rel='noreferrer'>
          {process.env.REACT_APP_DOC_URL + data?.contract}
        </a>
      </div>
      <div>Contract Status:{object?.con_status}</div>
      <div>Account:</div>
      {object?.is_active ? (
        <div className='p-1 border text-green border-green bg-green-light w-max '>Active</div>
      ) : (
        <div className='p-1 border text-red border-red bg-red-light w-max '>Deactive</div>
      )}
    </div>
  );
};

export default AgentContract;
