import { endpoints } from 'constants/endpoints';
import Request from './client';

export const addCustomer = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.customer.create,
    data,
  });
  return resp;
};

export const getCustomer = async ({ id }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.getCustomer.get(id),
  });
  return resp;
};

export const updateCustomer = async ({ data, id }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.customer.update(id),
    data,
  });
  return resp;
};

export const getCustomers = async (props = { query: '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.getCustomers.get(props.query),
  });
  return resp;
};

export const getFollowupCustomers = async (props = { query: '', method: 'get', id: '' }) => {
  const resp = await Request({
    method: props?.method,
    API:
      props?.method === 'delete'
        ? endpoints.getCustomers.deleteFollow(props.id)
        : endpoints.getCustomers.getfollow(props.query),
  });
  return resp;
};

export const customerDocs = async (props = { data: '', method: 'get', id: '' }) => {
  const resp = await Request({
    method: props.method,
    API:
      props.method === 'post'
        ? endpoints.customer.create_doc
        : endpoints.customer.list_doc(props?.id),
    data: props?.data,
  });
  return resp;
};

export const getCustomerChartData = async ({ start_date, end_date }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.customer.chat_data(start_date, end_date),
  });
  return resp;
};

export const getCustomerEventData = async ({ date, pageNum = 1 }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.customer.event_data(date, pageNum),
  });
  return resp;
};

export const getOfficeEventData = async ({ date }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.officeEvents.get(date),
  });
  return resp;
};

export const addOfficeEvent = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.officeEvents.post,
    data: data,
  });
  return resp;
};

export const customerPolicies = async ({ id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.customer.policies(id),
  });
  return resp;
};

export const getTotalCount = async (type) => {
  return await Request({
    method: 'get',
    API: `${type}/count`,
  });
};

export const importClients = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.customer.import(),
    data: data,
  });
  return resp;
};
export const advanceAssignment = async ({ data, category = '', product = '', method = 'get' }) => {
  const resp = await Request({
    method: method,
    API: endpoints.customer.advance_assignment({ category, product }),
    data: data,
  });
  return resp;
};

export const assignEmployee = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.customer.assign_employee(),
    data: data,
  });
  return resp;
};

export const customerCallLogs = async ({
  data,
  method = 'get',
  q = '',
  customer_id = '',
  id = '',
}) => {
  const resp = await Request({
    method: method,
    API: endpoints.customer.callLogs(q, customer_id, id),
    data: data,
  });
  return resp;
};
