/** external deps */
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/** internal deps */
import { ContentHeader, CustomModal, TabsComponent } from 'components';
import { targetsBreads } from './data';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import AddTarget from './AddTarget';
import { getDynamicTableData } from 'service/api-service';
import { debounce } from 'utils';
import AssignedByYou from '../sales/DynamicTableData/AssignedByYou';
import { Context } from 'store/context';

const SalesTargets = (props) => {
  const [addTarget, setAddTarget] = useState(false);
  const [editInitials] = useState();
  const [type, setType] = useState('add');
  const [loading, setLoading] = useState('add');
  const [activeTab, setActiveTab] = useState('assign_to_me');
  const [targets, setTargets] = useState([]);
  const [total_page, setTotalPage] = useState();
  const [page, setPageNum] = useState(1);

  const show = props?.showBreadcrumbs === false ? false : true;
  const permissions = props?.permissions || {};
  const { user } = useContext(Context);
  const { id } = useParams();
  const emp_id = id || user?.id || '';

  const onGetTargets = async ({ start = '', end = '', page = 1, query = '' } = {}) => {
    try {
      setLoading(true);
      const status = activeTab;
      const resp = await getDynamicTableData({
        status,
        employee_id: emp_id,
        page,
        query,
      });
      if (resp?.data?.status) {
        setPageNum(resp?.data?.data?.current_page);
        setTotalPage(resp?.data?.data?.total_page);
        setTargets(resp?.data?.data?.result);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onGetTargets();
  }, [activeTab]);

  const handlePagination = (pageNum) => onGetTargets({ page: pageNum });

  const items = [
    {
      key: 'assign_to_me',
      label: `Assigned To You`,
      Component: AssignedByYou,
      props: {
        permissions,
        id: elementData.Goals.permissions.view,
        loading: loading,
        handlePagination: handlePagination,
        getTargets: onGetTargets,
        targets: targets,
        total_page: total_page,
        page: page,
      },
    },
    {
      key: 'assigned',
      label: `Assigned By You`,
      Component: AssignedByYou,
      props: {
        permissions,
        id: elementData.Goals.permissions.view,
        loading: loading,
        handlePagination: handlePagination,
        getTargets: onGetTargets,
        targets: targets,
        total_page: total_page,
        page: page,
      },
    },
  ];

  const handleAddClick = () => {
    setType('add');
    toggleAddTarget();
  };

  const toggleAddTarget = () => setAddTarget((pre) => !pre);

  const handleSearch = debounce(async (e) => {
    const query = e?.target?.value;
    if (!query) {
      onGetTargets();
    }
    onGetTargets({ query });
  }, 700);

  useEffect(() => {
    onGetTargets();
  }, []);

  return (
    <main>
      <ContentHeader
        // data={show ? targetsBreads : props.showBreadcrumbs}
        title={<p className='font-semibold text-s'>Goals Listing</p>}
        btnText='+ Goal'
        onClick={handleAddClick}
        onChange={handleSearch}
        buttonId={elementData.Goals.permissions.create}
        permissions={permissions}
      />
      <div>
        <TabsComponent
          renderTabBar={true}
          items={items}
          activeKey={activeTab}
          setActiveTabKey={setActiveTab}
          url={'/goals?'}
        />
      </div>
      <CustomModal
        destroyOnClose={true}
        open={addTarget}
        onCancel={toggleAddTarget}
        footer={''}
        title={type === 'add' ? 'Add Goal' : 'Edit Goal'}
        width={1024}
      >
        <AddTarget
          employee={props.employee}
          employee_id={props.employee_id}
          getTargets={onGetTargets}
          initialValues={editInitials}
          showBack={false}
          goBack={toggleAddTarget}
          type={type}
        />
      </CustomModal>
    </main>
  );
};

export default SecureComponent(SalesTargets);
