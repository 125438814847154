import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import './style.css';

const COLORS = ['#2ecc71', '#3498db', '#e67e22', '#e67e22', '#e74c3c'];
const generateWholeNumber = (min, max) => min + Math.floor(Math.random() * (max - min));
const generateRandomColor = () => COLORS[generateWholeNumber(0, COLORS.length)];
const User = ({ image, name }) => {
  return (
    <>
      <div className='flex space-x-2 items-center'>
        <span>
          {image ? (
            <img
              src={process.env.REACT_APP_DOC_URL + image || image}
              className='h-6 w-6 rounded-full'
              alt='img'
            />
          ) : (
            <>
              <Avatar
                style={{
                  backgroundColor: generateRandomColor(),
                }}
                size='medium'
                icon={<UserOutlined />}
              />
            </>
          )}
        </span>
        <span className='text-black capitalize font-semibold'>{name}</span>
      </div>
    </>
  );
};

export default User;
