import {
  FormContainer,
  SearchAPI,
  SelectInput,
  TextInput,
  CustomRadio,
  SelectSearchWithName,
} from 'components';
import React from 'react';
import {
  getVehicleBrandModal,
  getVehicleBrands,
  getVehicleFuelType,
  getVehicleVarient,
} from 'service/api-service';
import { prepareFormFieldsData } from 'utils';
import { formStyle } from 'utils/common';

var doSubmit = null;

const manufactureYear = [
  { value: '2024', label: '2024' },
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
  { value: '2021', label: '2021' },
  { value: '2020', label: '2020' },
  { label: '2019', value: '2019' },
  { label: '2018', value: '2018' },
  { label: '2017', value: '2017' },
  { label: '2016', value: '2016' },
  { label: '2015', value: '2015' },
  { label: '2014', value: '2014' },
  { label: '2013', value: '2013' },
  { label: '2012', value: '2012' },
  { label: '2011', value: '2011' },
  { label: '2010', value: '2010' },
  { label: '2009', value: '2009' },
  { label: '2008', value: '2008' },
  { label: '2007', value: '2007' },
  { label: '2006', value: '2006' },
  { label: '2005', value: '2005' },
  { label: '2004', value: '2004' },
  { label: '2003', value: '2003' },
  { label: '2002', value: '2002' },
  { label: '2001', value: '2001' },
  { label: '2000', value: '2000' },
  { label: '1999', value: '1999' },
  { label: '1998', value: '1998' },
  { label: '1997', value: '1997' },
];

const TwoWheelerForm = ({ formData, onSubmit }) => {
  const quoteCustomerFields = {
    'Vehicle Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: '',
          name: 'vehicle',
          id: 'vehicle',
          type: 'radio',
          component: CustomRadio,
          options: [
            { value: 'old', label: 'Registered Vehicle' },
            { value: 'new', label: 'Brand New Vehicle ' },
          ],
          divClasses: 'col-span-full',
          defaultValue: 'old',
        },
        {
          dependent: true,
          dependent_on: 'vehicle',
          dependent_value: 'old',
          label: 'Registration Number',
          name: 'reg_number',
          id: 'reg_number',
          placeholder: 'Registration Number',
          type: 'text',
          component: TextInput,
        },
        {
          dependent: true,
          dependent_on: 'vehicle',
          dependent_value: 'new',
          label: 'Registered venue',
          name: 'rto_venue',
          id: 'rto_venue',
          placeholder: 'Registered venue',
          type: 'search',
          component: SearchAPI,
        },
        {
          dependent: true,
          dependent_on: 'vehicle',
          dependent_value: 'new',
          label: 'RTO city number',
          name: 'rto_city',
          id: 'rto_city',
          placeholder: 'RTO city number',
          type: 'select',
          component: SelectInput,
          options: [],
          dependency: ['rto_venue'],
          dependantFetchOptions: async (form) => {
            if (!form?.values?.rto_venue) return [];
            return form?.values?.rto_venue?.rto?.map((item) => ({
              id: item.id,
              name: item.rto_No,
            }));
          },
        },
        {
          label: 'Brand',
          name: 'brand',
          id: 'brand',
          placeholder: 'Select Brand',
          type: 'select',
          component: SelectSearchWithName,
          options: [],
          fetchOptions: async () => getVehicleBrands({ type: 'bike' }),
        },
        {
          label: 'Model',
          name: 'model',
          id: 'model',
          placeholder: 'Select model',
          type: 'select',
          component: SelectSearchWithName,
          options: [],
          dependency: ['brand'],
          dependantFetchOptions: async (form) => {
            if (!form?.values?.brand) return [];
            const brand = form?.values?.brand;
            const resp = await getVehicleBrandModal({ brand });
            if (resp && resp?.data && resp?.data?.status) {
              return resp?.data?.data;
            } else return [];
          },
        },
        {
          label: 'Fuel Type',
          name: 'fuel_type',
          id: 'fuel_type',
          placeholder: 'Select Fuel Type',
          type: 'select',
          component: SelectInput,
          options: [],
          dependency: ['model'],
          dependantFetchOptions: async (form) => {
            if (!form?.values?.model) return [];
            const model = form?.values?.model;
            const resp = await getVehicleFuelType({ model });
            if (resp && resp?.data && resp?.data?.status) {
              return resp?.data?.data.map((item) => ({ id: item, name: item }));
            } else return [];
          },
        },

        {
          label: 'Manufacturing Year',
          name: 'manufac_year',
          id: 'manufac_year',
          placeholder: 'Manufacturing Year',
          type: 'select',
          component: SelectInput,
          options: manufactureYear,
          menuPlacement: 'top',
        },
        {
          label: 'Variant',
          name: 'varient',
          id: 'varient',
          placeholder: 'Select Variant',
          type: 'select',
          component: SelectSearchWithName,
          options: [],
          dependency: ['model', 'fuel_type'],
          dependantFetchOptions: async (form) => {
            if (!form?.values?.model || !form?.values?.fuel_type) return [];
            const model = form?.values?.model;
            const fuelType = form?.values?.fuel_type;
            const resp = await getVehicleVarient({ model, fuelType });
            if (resp && resp?.data && resp?.data?.status) {
              return resp?.data?.data;
            } else return [];
          },
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: quoteCustomerFields,
    defaultValues: formData,
  });

  return (
    <FormContainer
      returnFormatted={false}
      accordionClass={formStyle}
      fields={finalFields}
      initialValues={initialValues}
      onSubmit={onSubmit}
      customSubmitRender={({ submitForm }) => {
        doSubmit = submitForm;
        return null;
      }}
    />
  );
};

const submit = () => {
  if (doSubmit) doSubmit();
};

export default {
  submit,
  Form: TwoWheelerForm,
};
