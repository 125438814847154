/** external deps */
import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import Fuse from 'fuse.js';

/** internal deps */
import { EDIT } from 'assets/icons';
import {
  CustomModal,
  IconButton,
  FormContainer,
  Button,
  ContentHeader,
  Toast,
  Checkbox,
  TableComponent,
  SearchComponent,
} from 'components';
import { fields } from './data';
import EditClause from './edit-clause';
import { NOTIFICATION_MSG } from 'constants';
import { generateUniqueId, getEditedFields } from 'utils';
import { elementData } from 'elements';
import { getClause, patchClause, postClause } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const Clauses = ({ insurance_product_id, insurance_class_id, permissions = {} }) => {
  /* states  */
  const [clauses, setClauses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editInitials, setEditInitials] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [bulkEditData, setBulkEditData] = useState({ initials: {}, fields: [] });
  const [searchQuery, setSearchQuery] = useState();
  const [filteredBranch, setFilteredBranches] = useState([]);

  const columns = [
    {
      key: 'name',
      title: 'Clause',
      dataIndex: 'name',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (val) => <span>{val === true ? 'Active' : 'Inactive'}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            id={elementData.clause.permissions.update}
            permissions={permissions}
            title='Edit'
            className='group disabled:cursor-not-allowed'
            onClick={() => onEditClick(record)}
          >
            <EDIT />
          </IconButton>
        </Space>
      ),
    },
  ];

  /*funcs */
  const toggleViewModal = () => setShowModal((pre) => !pre);
  const toggleEditing = () => setIsEditing((pre) => !pre);

  const onEditClick = (record) => {
    setEditInitials(record);
    toggleViewModal();
  };

  const onAddClick = () => {
    setEditInitials();
    toggleViewModal();
  };

  const onBulkEditPress = () => {
    let initials = {};
    let fields = {
      edit: {
        fields: [],
      },
    };

    const intrim = clauses.map((clause) => {
      initials[clause.name] = clause['status'];
      return {
        label: clause.name,
        name: clause.name,
        id: clause.name,
        type: 'checkbox',
        component: Checkbox,
        checked: clause?.status,
      };
    });

    fields = {
      ...fields,
      edit: {
        showAccordionHeader: false,
        ...fields['edit'],
        fields: intrim,
      },
    };

    setBulkEditData({ initials: initials, fields: fields });
    setIsEditing(true);
  };

  const onAddClause = async (data) => {
    setLoading(true);
    const finalPayload = {
      name: data?.name,
      status: data?.status || false,
      insurance_product_id: insurance_product_id,
    };

    const resp = await postClause({
      insurance_product_id,
      data: finalPayload,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Toast Created Successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    onGetClauses();
    setLoading(false);
    toggleViewModal();
  };

  const onEditClause = async (updatedData) => {
    const data = getEditedFields({ initialValues: editInitials, updatedData });
    if (Object.keys(data).length) {
      const finalPayload = [{ ...editInitials, ...data, insurance_class_id }];
      const resp = await patchClause({
        data: finalPayload,
        insurance_product_id,
      });
      if (resp?.data?.status) {
        onEditClick();
        onGetClauses();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to update data');
      }
    } else {
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  const onGetClauses = async () => {
    setLoading(true);
    const resp = await getClause({ insurance_product_id });
    const intrim = resp?.data?.data?.map((item) => {
      return {
        ...item,
        key: generateUniqueId(),
      };
    });
    setClauses(intrim);
    setLoading(false);
  };

  useEffect(() => {
    if (insurance_product_id) {
      onGetClauses();
    }
  }, [insurance_product_id]);

  /*  search clauses */
  const onChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(clauses, {
      keys: ['name', 'status'],
      threshold: 0.2,
    }).search(e.target.value);
    setFilteredBranches(fuse.map((i) => i.item));
  };

  const onBulkEdit = async (data) => {
    let finalPayload = [];
    Object.keys(data).forEach((element) => {
      const found = clauses.find((item) => item.name === element);

      if (found && found?.status !== data[element]) {
        finalPayload.push({
          ...found,
          status: data[element],
          insurance_class_id: insurance_class_id,
        });
      }
    });

    if (!finalPayload?.length)
      return Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');

    const resp = await patchClause({
      data: finalPayload,
      insurance_product_id,
    });
    if (resp?.data?.status) {
      toggleEditing();
      onGetClauses();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to update data');
    }
  };

  if (isEditing)
    return (
      <EditClause
        permissions={permissions}
        bulkEditData={bulkEditData}
        goBack={toggleEditing}
        onSubmit={onBulkEdit}
      />
    );

  return (
    <div>
      <ContentHeader
        btnText='+ Clause'
        title={<h2 className='font-bold text-l'>Clauses</h2>}
        buttonId={elementData.clause.permissions.create}
        permissions={permissions}
        onClick={onAddClick}
        showSearch={false}
        children={
          clauses?.length > 0 && (
            <>
              <SearchComponent onChange={onChange} />
              <Button
                id={elementData.clause.permissions.update}
                permissions={permissions}
                text='Edit'
                onClick={onBulkEditPress}
                variant='transparent'
              />
            </>
          )
        }
      />
      <TableComponent
        rowKey='key'
        loading={loading}
        getRowClassName={rowCommonClass}
        columns={columns}
        dataSource={searchQuery ? filteredBranch : clauses}
      />

      {/*Edit clause Modal */}
      <CustomModal
        title={editInitials ? 'Edit Clause' : 'Add Clause'}
        destroyOnClose={true}
        open={showModal}
        onCancel={toggleViewModal}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10'>
            {`To ${
              editInitials ? ' edit Clause modify' : ' add Clause fill'
            } the details in the input field below`}
          </span>
          <FormContainer
            accordionClass='grid grid-cols-2 gap-2'
            onSubmit={editInitials ? onEditClause : onAddClause}
            initialValues={editInitials || { name: '', status: false }}
            fields={fields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, disableForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleViewModal();
                    }}
                  />
                  <Button
                    id={
                      editInitials
                        ? elementData.clause.permissions.update
                        : elementData.clause.permissions.create
                    }
                    permissions={permissions}
                    disabled={disableForm || !isValid}
                    text={'Submit'}
                    variant='primary'
                    classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default Clauses;
