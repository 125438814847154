/** external deps */
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
/** internal deps */
import { IconChevron } from 'assets/icons';
import { ContentHeader, FormContainer, Button, CustomModal, Toast, IconButton } from 'components';
import { breadCrumbs, helplineFields } from './data';
import { prepareFormFieldsData } from 'utils';
import { NOTIFICATION_MSG } from 'constants';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { elementData } from 'elements';
import { deleteFaq, getFaqData, postFaq, updateFaq } from 'service/api-service';

const FAQ = (props = {}) => {
  const permissions = props?.permissions || [];
  const [deletedRecord, setDeletedRecord] = useState();
  const [showModal, setshowModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editInitials, setInitials] = useState();
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: helplineFields,
  });

  const toggleModal = () => setshowModal((pre) => !pre);
  const toggleDeleteModal = () => setDeleteModal((pre) => !pre);

  const getFaqs = async () => {
    setLoading(true);
    const response = await getFaqData();
    if (response.data.status) {
      setFaqData(response?.data?.data);
    }
    setLoading(false);
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    const resp = await postFaq({ data });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleModal();
      getFaqs();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onDeleteFaq = async () => {
    setLoading(true);
    const resp = await deleteFaq({ id: deletedRecord?.id });
    if (resp?.data?.status) {
      toggleDeleteModal();

      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      getFaqs();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setDeletedRecord();
    setLoading(false);
  };

  const handleEditFaq = async (data) => {
    setLoading(true);
    const resp = await updateFaq({ data, id: editInitials?.id });
    if (resp?.data?.status) {
      toggleModal();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      getFaqs();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onAddPress = () => {
    setInitials(undefined);
    toggleModal();
  };

  const onEditPress = (item) => {
    setInitials(item);
    toggleModal();
  };

  const onDeletePress = (item) => {
    setDeletedRecord(item);
    toggleDeleteModal();
  };

  useEffect(() => {
    getFaqs();
  }, []);

  if (loading) {
    return (
      <div className='flex items-center justify-center h-64'>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <ContentHeader
        permissions={permissions}
        showSearch={false}
        title={<p className='font-semibold text-s'>FAQ</p>}
        // data={breadCrumbs}
        buttonId={elementData?.FAQ?.permissions?.create}
        btnText='+ FAQ'
        onClick={onAddPress}
      />
      <div className='space-y-2 mt-2'>
        {faqData?.map((item, index) => {
          return (
            <>
              <div className='flex flex-row gap-2 w-full'>
                <details class='w-full accordion transition-all duration-500 group border bg-white shadow !border-grey-30 p-4 rounded-md'>
                  <summary class='flex justify-between cursor-pointer list-none items-center gap-3 marker:appearance-none group-open:text-primary'>
                    <span class='mr-auto font-medium capitalize'>{item?.questions}</span>
                    <div className='flex items-center flex-row gap-2'>
                      <IconButton
                        title='Delete'
                        className='group disabled:cursor-not-allowed'
                        permissions={permissions}
                        id={elementData.FAQ.permissions.delete}
                        onClick={() => {
                          onDeletePress(item);
                        }}
                      >
                        <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                      </IconButton>
                      <IconButton
                        title='Edit'
                        className='group disabled:cursor-not-allowed'
                        permissions={permissions}
                        id={elementData.FAQ.permissions.delete}
                        onClick={() => {
                          onEditPress(item);
                        }}
                      >
                        <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                      </IconButton>
                      <IconChevron
                        className={`w-3 h-3 group-open:rotate-180 fill-primary-90 details_icon`}
                      />
                    </div>
                  </summary>
                  <p class='pt-3 text-s transition-all duration-500 text-secondary-70 capitalize'>
                    {item?.answers}
                  </p>
                </details>
              </div>
            </>
          );
        })}
      </div>
      <CustomModal
        width='50vw'
        destroyOnClose={true}
        open={showModal}
        onCancel={toggleModal}
        footer={''}
        title={editInitials ? 'Edit Faq' : 'Add Faq'}
      >
        <div>
          <FormContainer
            accordionClass='grid grid-cols-2 gap-8'
            fields={finalFields}
            initialValues={editInitials || initialValues}
            onSubmit={editInitials ? handleEditFaq : handleSubmit}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleModal();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    text={'Submit'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the Faq ?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              text='Delete Faq'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteFaq}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default FAQ;
