const QuotationCard = ({ count, quoteData, quotationData }) => {
  return (
    <div className='pl-4 pr-20 shadow-shadowmd py-4 rounded-2xl bg-white mt-4'>
      <div className='text-s font-semibold leading-normal'>
        #{count} {quoteData?.insurer} - {quoteData?.product?.name}
      </div>
      <div className='grid grid-cols-3 grid-rows-3 mt-4 gap-y-3'>
        <p>
          {' '}
          Premium : <span className='font-semibold'>{quoteData?.premium}</span>
        </p>
        <p>
          {' '}
          Sum Insured : <span className='font-semibold'> {quoteData?.sum}</span>
        </p>
        <p>
          {' '}
          NCB : <span className='font-semibold'> {quoteData?.ncb}</span>
        </p>
        <div className='col-span-full'>
          <p>Select type of coverages</p>
        </div>
        {/* {sorderData.map(({ label, value }) => {
            return <Detail label={label} value={value} />;
          })} */}
      </div>
    </div>
  );
};

export default QuotationCard;
