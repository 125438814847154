import React from 'react';

const NoPermission = () => {
  return (
    <div className='px-10 border'>
      <div className='bg-red  flex flex-col p-4 rounded-lg'>Lorem Ipsum dummy text</div>
    </div>
  );
};

export default NoPermission;
