export const tabData = [
  {
    label: 'Tax',
    value: 'tax',
  },
  {
    label: 'Invoices',
    value: 'invoices',
  },
];

export const breadcrumbObj = [
  {
    name: 'Commission',
    path: '/commission',
  },
];

/**
*
* @param {
  [
    {
      id: number,
      title: string,
      sub_title: string
      fields: [
        {
          id: number,
          name: string,
          label: string,
          type: string,
          required: boolean,
          options: [{id: number, name: string}],
        }
      ]
    }
  ]
* } sections stuff we get from `res.data.data`(by calling `getCustomerFields`)
* @returns nice array of antd-table-compatible columns
*/
