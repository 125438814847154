/** external deps */
import { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, Menu, Popover, Space } from 'antd';
import { motion } from 'framer-motion';

/** internal deps */
import { DropdownIcon } from 'assets/icons';
import { useDimensions, useSidebar } from 'hooks';
import './style.css';
import { IconMenuCollapsed } from 'assets/svg';

const Header = ({ permissions = [], dashboardType }) => {
  /** vars */
  const { sidebarItems } = useSidebar({ permissions, dashboardType });
  const [selected, setSelected] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);
  const { width } = useDimensions();
  const menuItems = useMemo(
    () => (Array.isArray(sidebarItems[0]?.menu) ? sidebarItems[0]?.menu : []),
    [sidebarItems],
  );
  const [shownMenuItems, hiddenMenuItems] = useMemo(
    () => getTupledMenuItems(menuItems, width),
    [menuItems, width],
  );
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setAnimationKey(animationKey + 1); // Change the key to trigger reanimation
  };

  const renderContent = (item, index) => {
    const dropdownContent = (
      <motion.div
        key={animationKey}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.4 }}
      >
        <Menu>
          {item?.submenu?.map((item) => (
            <Menu.Item key={item?.key} icon={item?.icon} name={item?.name}>
              {/* <motion.div
                className='submenu'
                key={item?.key}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.4 }}
              > */}
              {item?.label}
              {/* </motion.div> */}
            </Menu.Item>
          ))}
        </Menu>
      </motion.div>
    );
    let items = item?.submenu;
    return (
      <>
        {item?.link && (
          <NavLink
            key={index}
            to={item?.link}
            className='group'
            onClick={setSelected.bind(this, index)}
          >
            <div className="relative transition-all after:transition-all after:duration-300 hover:text-primary-90 after:content-[''] after:block after:m-auto after:w-0 after:border-b-2 group-[.active]:after:border-primary-90 hover:after:border-primary-90 group-[.active]:after:w-[0px] hover:after:w-[0px] cursor-pointer">
              <div className='flex items-center justify-center gap-2 py-2 px-2 w-max'>
                {/* <div>{icon}</div> */}
                <span className='group-[.active]:text-primary-90 text-xxs font-medium group-hover:text-primary-90 dark:text-white transition-all duration-500 text-[#000] leading-normal'>
                  {item?.label}
                </span>
              </div>
            </div>
          </NavLink>
        )}

        {!item?.link && (
          <Dropdown
            key={index}
            className='group'
            overlayClassName='h-96 overflow-y-auto'
            // menu={{ items }}
            overlay={dropdownContent}
            onOpenChange={setSelected.bind(this, index)}
            trigger={['click']}
            visible={isOpen[index]}
            onVisibleChange={toggleDropdown}
          >
            <div className="relative transition-all after:transition-all after:duration-300 hover:text-primary-90 after:content-[''] after:block after:m-auto after:w-0 after:border-b-2 group-[.active]:after:border-primary-90 hover:after:border-primary-90 group-[.active]:after:w-[0px] hover:after:w-[0px] cursor-pointer">
              <div className='flex items-center justify-center gap-2 py-2 px-2 w-max'>
                <span className='group-[.active]:text-primary-90 text-xxs font-medium group-hover:text-primary-90 dark:text-white transition-all duration-500 text-[#000] leading-normal'>
                  <button
                    className={`group transition-all duration-500 hover:text-primary-90 ${
                      selected === index && 'text-primary-90'
                    }`}
                    onClick={(e) => e.preventDefault()}
                  >
                    <Space>
                      <span className='group-[.active]:text-primary-90 font-medium group-hover:text-primary-90 dark:text-white transition-all duration-500 text-xxs text-[#000] leading-normal'>
                        {item?.label}
                      </span>
                      <DropdownIcon
                        svgClass={`w-2 transition-all duration-500 group-hover:fill-primary-90 group-open/edit:rotate-180 group-open/edit:hover:fill-black dark:fill-white ${
                          selected === index && 'rotate-180 fill-primary-90'
                        }`}
                      />
                    </Space>
                  </button>
                </span>
              </div>
            </div>
          </Dropdown>
        )}
      </>
    );
  };

  return (
    <nav
      id='nav-bar'
      className='flex relative z-10 justify-between items-center mr-auto bg-white dark:bg-black noPrint'
    >
      <ul className='box-border flex overflow-x-auto gap-2 items-center px-5 mx-auto'>
        {shownMenuItems.map(renderContent)}
      </ul>
      {Boolean(hiddenMenuItems.length) && (
        <Popover
          content={
            <Menu
              className='[&_[role="menuitem"]]:flex [&_[role="menuitem"]]:items-center [&_[role="menuitem"]]:gap-2'
              items={formatMenuItems(hiddenMenuItems)}
            />
          }
        >
          <div className='grid place-content-center w-8 rounded-full cursor-pointer aspect-square'>
            <IconMenuCollapsed />
          </div>
        </Popover>
      )}
    </nav>
  );
};

export default Header;

/**
 * @param {unknown[]} menu
 * @param {number} width
 * @returns {[unknown[], unknown[]]}
 */
const getTupledMenuItems = (menu, width) => {
  if (menu.length <= 7) {
    if (width >= 1300) return [menu, []];
    if (width >= 950) return [menu.slice(0, 6), menu.slice(6)];
    if (width >= 400) return [menu.slice(0, 3), menu.slice(3)];
    return [menu.slice(0, 2), menu.slice(2)];
  }

  // menu.length > 7
  if (width >= 1400) return [menu, []];
  if (width >= 1300) return [menu, []];
  if (width >= 950) return [menu.slice(0, 6), menu.slice(6)];
  if (width >= 400) return [menu.slice(0, 3), menu.slice(3)];
  return [menu.slice(0, 2), menu.slice(2)];
};

/** @param {unknown[]} menu
 * @returns {import('antd').MenuProps['items']}
 */
const formatMenuItems = (menu) =>
  menu.map((item, i) => ({
    ...item,
    label: item.link ? <NavLink to={item.link}>{item.label}</NavLink> : item.label,
    submenu: undefined,
    key: `${item.key}:${i}`,
    children: item.submenu?.map((sub, j) => ({
      ...sub,
      // styles are hardcoded for these icons, so a cheap workaround
      icon: <div>{sub.icon}</div>,
      key: `${item.key}:${i}:${sub.key}:${j}`,
    })),
  }));
