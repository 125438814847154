import { Select } from 'antd';
import { DropdownIcon } from 'assets/icons';
import React, { useEffect, useState } from 'react';
import { getInsurers } from 'service/api-service';

const CommissionFilter = ({ onSelect, placeholder, getEmployees }) => {
  const [options, setOptions] = useState([]);

  const fetchBranchOptions = async () => {
    const res = await getEmployees();
    const myArray = res?.data?.data?.result;
    const names = myArray.map((user) => ({ name: user.name, value: user.name }));
    if (res?.status) {
      setOptions([...names]);
    }
  };

  const fetchOptions_InsurerManagement = async () => {
    const res = await getInsurers();
    const myArray = res?.data?.data;
    const names = myArray.map((user) => ({ name: user.name, value: user.name }));
    console.log(names, 'names');
    if (res?.status) {
      setOptions([...names]);
    }
  };

  useEffect(() => {
    if (getEmployees) {
      fetchBranchOptions();
    } else {
      fetchOptions_InsurerManagement();
    }
  }, []);

  return (
    <div>
      <Select
        className='group border border-grey-90 hover:border-primary-90 hover:text-primary-90 rounded px-2'
        suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
        placeholder={placeholder}
        dropdownAlign={{ offset: [-10, 4] }}
        options={[
          { label: 'All', value: '' },
          ...options.map((item) => ({
            value: item.value,
            label: item.name,
          })),
        ]}
        onSelect={onSelect}
        align='left'
      />
    </div>
  );
};

export default CommissionFilter;
