import OneSignal from 'react-onesignal';
import { useContext, useEffect } from 'react';
import { Context } from 'store/context';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useOneSignalNotificationHandler = () => {
  const { notificationShown, setNotificationShown } = useOneSignalNotificationPrompt();
  const {
    notifications: { updateNotifications },
  } = useContext(Context);

  useEffect(() => {
    const appId = process.env.REACT_APP_ONE_SIGNAL_KEY;
    const shouldDisabledIntialisation = process.env.REACT_APP_DISABLE_ONESIGNAL_INITIALISATION;
    if (!appId || shouldDisabledIntialisation) return;
    const init = async () => {
      try {
        if (!window.OneSignal) await OneSignal.init({ appId });
        if (!notificationShown) {
          await OneSignal.showSlidedownPrompt({ force: true });
          setNotificationShown(true);
        }

        const listener = (data) => {
          console.group('onesignal');
          console.info('data being', data);
          console.groupEnd('onesignal');
          updateNotifications();
        };
        OneSignal.on('notificationDisplay', listener);

        return () => OneSignal.off('notificationDisplay', listener);
      } catch (e) {
        console.group('Error whilst setting up oneSignal');
        console.error(e);
        console.groupEnd('Error whilst setting up oneSignal');
      }
    };
    init();
  }, [notificationShown, setNotificationShown, updateNotifications]);
};

export const useOneSignalNotificationPrompt = create(
  persist(
    (set) => ({
      notificationShown: false,
      setNotificationShown: (notificationShown) => set({ notificationShown }),
    }),
    { name: '@onesignalNotificationPrompt' },
  ),
);

export default useOneSignalNotificationHandler;
