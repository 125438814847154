import React from 'react';
import { useLeads } from '../store';
import '../typedefs/proposals';
import { Button as ButtonAntd, Form, Radio, Space, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants/index';

const Proposals = () => {
  /** @type {{isFetching: boolean; data: Proposals | null; error: string | null}} */
  const proposals = useLeads((state) => state.proposals);
  const onChangeRadioGroup = useLeads((state) => state.onChangeRadioGroup);
  const submitProposals = useLeads((state) => state.submitProposals);
  const navigate = useNavigate();
  const params = useParams();
  const [form] = useForm();

  const handleClickSaveAndExit = async (values) => {
    const submitted = await submitProposals(params.mode);
    if (!submitted) return;
    navigate('/lead');
  };

  const handleClickNext = async () => {
    form.submit();

    let values = null;
    try {
      values = await form.validateFields();
    } catch {
      values = true;
    }
    if (!values || !Object.values(values).some(Boolean))
      return Toast('info', NOTIFICATION_MSG.info, 'Please select at least one insurer');

    const submitted = await submitProposals(params.mode);
    if (submitted) navigate(`/lead/${params.mode}/${params.id}/4`);
  };

  if (proposals.isFetching || proposals.error || !proposals.data) return <Spin />;

  return (
    <section className='grid min-h-[45vh] h-fit overflow-y-auto gap-3 p-2 relative'>
      {/* CLIENT INFO */}
      <ul className='grid grid-cols-3 gap-3 p-4 shadow-custom'>
        <li>
          Client name: <span className='font-semibold'>{proposals.data.clientName}</span>
        </li>
        <li>
          Email: <span className='font-semibold'>{proposals.data.clientEmail ?? '-'}</span>
        </li>
        <li>
          Phone: <span className='font-semibold'>{proposals.data.phoneNumber}</span>
        </li>
        <li>
          Assigned to: <span className='font-semibold'>{proposals.data.assignedTo ?? '-'}</span>
        </li>
      </ul>
      <Form
        className='grid grid-cols-3 gap-2 p-4 shadow-custom'
        layout='vertical'
        form={form}
        onFinish={handleClickSaveAndExit}
        requiredMark={false}
      >
        {proposals.data.products.map((product) => (
          <Form.Item
            className='py-4 [&_label:not(.ant-radio-wrapper)]:font-semibold [&_label:not(.ant-radio-wrapper)]:text-m [&_.ant-col.ant-form-item-label]:pb-0'
            name={product.id}
            key={product.id}
            label={product.name}
            initialValue={product.selectedInsurer}
          >
            <Radio.Group
              onChange={onChangeRadioGroup}
              value={product.selectedInsurer}
              defaultValue={product.selectedInsurer}
              name={product.id}
            >
              <Space direction='vertical'>
                {product.insurers.map(
                  (insurer) =>
                    insurer.__toShow !== false && (
                      <Radio key={insurer.id} value={insurer.id}>
                        {insurer.name}
                      </Radio>
                    ),
                )}
              </Space>
            </Radio.Group>
          </Form.Item>
        ))}
        <Form.Item className='flex absolute right-4 -bottom-2 '>
          <Space>
            <ButtonAntd
              size='large'
              className='bg-[#C3C3C3] !rounded-md !h-9 border-none [&_*]:hover:text-black hover:border-none'
              htmlType='reset'
              onClick={() => navigate('/lead')}
            >
              Cancel
            </ButtonAntd>
            <ButtonAntd
              size='large'
              className='text-white !rounded-md !h-9 border-none bg-[#0089CF] [&_*]:hover:text-white hover:border-none'
              htmlType='button'
              onClick={() => navigate(`/lead/${params.mode}/${params.id}/1`)}
            >
              Prev
            </ButtonAntd>
            <ButtonAntd
              size='large'
              className='text-white !rounded-md !h-9 border-none bg-[#26CBED] [&_*]:hover:text-white'
              htmlType='submit'
              // onClick={handleClickSaveAndExit}
            >
              Save & Exit
            </ButtonAntd>

            <ButtonAntd
              htmlType='button'
              size='large'
              className='bg-green-60 !rounded-md !h-9 border-none text-white [&_*]:hover:text-white hover:border-none'
              onClick={handleClickNext}
            >
              Next
            </ButtonAntd>
          </Space>
        </Form.Item>
      </Form>
    </section>
  );
};

export default Proposals;
