/** external deps */
import React, { useState, useCallback, useEffect } from 'react';
import { Button, Modal, Divider, Input } from 'antd';

import { acknowlegeLeave } from 'service/api-service';
import { broadcast } from 'utils/communication';
import { useLocation } from 'react-router-dom';
import { REFRESH_EVT } from 'screens/hr/leaves/data';
import AllocateModal from './AllocateModal';

const LabelValue = ({ label, value }) => (
  <div className='flex mb-2'>
    <div className='w-36 border-1 font-semibold'>{label}</div>
    <div className='flex-1'>{value}</div>
  </div>
);

const AcknowlegedAssetRequest = ({ record, onGetAssets }) => {
  const location = useLocation();
  const userId = location?.search;
  const viewModalForId = Number(userId.split('?')[1]);

  const [loading, setLoading] = useState();
  const [showInfo, setShowInfo] = useState();
  const [showReason, setShowReason] = useState();
  const [note, setNote] = useState();

  useEffect(() => {
    if (viewModalForId === record?.id) {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }
  }, [viewModalForId]);

  const onAction = useCallback(
    (status, noConfirm) => () => {
      const onOk = async () => {
        setLoading(true);
        const resp = await acknowlegeLeave(record.id, status, note);
        broadcast(REFRESH_EVT);
        setLoading(false);
        close();
      };

      if (!noConfirm) {
        Modal.confirm({
          title: 'Are you sure?',
          okText: 'Yes',
          onOk: onOk,
        });
        return;
      }

      onOk();
    },
    [note],
  );

  const onNoteChange = useCallback((evt) => {
    setNote(evt.target.value);
  }, []);

  const onInfo = useCallback(() => {
    setShowInfo(true);
  }, []);

  const onReason = useCallback(() => {
    setShowReason(true);
  }, []);

  const close = useCallback(() => {
    setShowInfo(false);
    setShowReason(false);
  }, []);

  return (
    <div>
      <div className='flex justify-end space-x-2'>
        {/* <Button disabled={loading} onClick={onInfo}>
          View
        </Button> */}
        <AllocateModal record={record} onGetAssets={onGetAssets} />
      </div>

      <Modal title='Acknowledge Asset Request' open={showInfo} footer={null} onCancel={close}>
        <Divider />
        <LabelValue label='Reason' value={record?.reason} />

        <Divider />
        {record.status === 'Declined' && (
          <>
            <LabelValue label='Reason to Decline' value={record?.note} />
            <Divider />
          </>
        )}

        <div className='flex justify-end items-center'>
          &nbsp;
          <Button
            disabled={loading || record.status === 'Approved'}
            onClick={onAction('approved')}
            className='bg-success text-white'
          >
            Approve
          </Button>
          &nbsp;
          <Button
            disabled={loading || record.status === 'Declined'}
            onClick={onReason}
            className='bg-danger text-white'
          >
            Decline
          </Button>
        </div>
      </Modal>

      <Modal open={showReason} title='Reason to Decline' footer={null} onCancel={close}>
        <Input.TextArea value={note} onChange={onNoteChange}></Input.TextArea>
        <Divider />
        <div className='flex justify-end items-center'>
          <Button
            disabled={!note}
            onClick={onAction('declined', true)}
            className='bg-danger text-white'
          >
            Proceed
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AcknowlegedAssetRequest;
