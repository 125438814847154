import Request from './client';
// leaveTypeManagement

export const getLeaveType = async () => {
  const resp = await Request({
    method: 'get',
    API: `leave/types`,
  });
  return resp;
};

export const postLeaveType = async (props = { data: '' }) => {
  const resp = await Request({
    method: 'post',
    API: `leave/types`,
    data: props?.data,
  });
  return resp;
};

export const deleteLeaveType = async (props = { id: '' }) => {
  const resp = await Request({
    method: 'delete',
    API: `leave/types?id=${props?.id}`,
  });
  return resp;
};

export const patchLeaveType = async (props = { id: '', data: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `leave/types?id=${props?.id}`,
    data: props?.data,
  });
  return resp;
};

// holidayManagement

export const getHoliday = async (props = { year: '', pageNum: 1 }) => {
  const resp = await Request({
    method: 'get',
    API: `leave/holidays?year=${props.year}&page=${props?.pageNum || 1}`,
  });
  return resp;
};

export const postHoliday = async (props = { data: '', year: '' }) => {
  const resp = await Request({
    method: 'post',
    API: `leave/holidays?year=${props.year}`,
    data: props?.data,
  });
  return resp;
};

export const deleteHoliday = async (props = { id: '' }) => {
  const resp = await Request({
    method: 'delete',
    API: `leave/holiday/${props?.id}`,
  });
  return resp;
};

export const patchHoliday = async (props = { id: '', data: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `leave/holiday/${props?.id}`,
    data: props?.data,
  });
  return resp;
};
