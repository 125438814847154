import { FormContainer, SelectInput, TextInput, DateTime } from 'components';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { SubmitButtons } from 'screens/leads/data';
import { prepareFormFieldsData } from 'utils';

const ChildSavingForm = ({
  formData,
  setValues,
  insurer,
  setSelectedProduct,
  isEditing,
  submitProduct,
}) => {
  const maxDate = dayjs().subtract(18, 'years');

  const [wantExit, setWantExit] = useState(false);

  const onSubmit = (data) => {
    submitProduct(data, wantExit);
  };

  const quoteCustomerFields = {
    'Child Saving Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'DOB',
          name: 'dob',
          id: 'dob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          showTime: false,
          component: (props) => <DateTime {...props} disabledDate={maxDate} />,
        },
        {
          label: 'Child DOB',
          name: 'c_dob',
          id: 'c_dob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          showTime: false,
          component: DateTime,
        },
        {
          label: 'Sum Assured Amount',
          name: 'income',
          id: 'income',
          placeholder: 'Sum Assured Amount',
          type: 'select',
          component: SelectInput,
          options: [
            {
              label: '50,000-100,000',
              value: '100000',
            },
            {
              label: '100,000-200,000',
              value: '200000',
            },
            {
              label: '200,000-300,000',
              value: '300000',
            },
          ],
        },
        {
          label: 'Amount',
          name: 'amount',
          id: 'amount',
          placeholder: 'Amount',
          type: 'text',
          component: TextInput,
          divClasses: 'customCssForLead',
          maxLength: 30,
        },
        {
          label: 'Get Benefit Payout after policy term(PT)',
          name: 'benefit',
          id: 'benefit',
          placeholder: 'Benefits',
          type: 'select',
          searchable: true,
          component: SelectInput,
          options: [
            {
              label: '50,000-100,000',
              value: '100000',
            },
            {
              label: '100,000-200,000',
              value: '200000',
            },
            {
              label: '200,000-300,000',
              value: '300000',
            },
          ],
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: quoteCustomerFields,
    defaultValues: { dob: maxDate.format('YYYY-MM-DD'), ...formData },
  });

  return (
    <div className='bg-white'>
      <FormContainer
        returnFormatted={false}
        accordionClass='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-5 pt-0 pt-0'
        fields={finalFields}
        initialValues={initialValues}
        onSubmit={onSubmit}
        customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, dirty }) => {
          return formData?.count ? (
            <></>
          ) : (
            <SubmitButtons
              show={true}
              classes='z-10'
              submit={submitForm}
              reset={resetForm}
              setWantExit={setWantExit}
            />
          );
        }}
      />
    </div>
  );
};

export default {
  Form: ChildSavingForm,
};
