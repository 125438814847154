/** external deps */
import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router';
import { Dropdown, Space, Badge, Popover, List, message, Modal } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
/** internal deps */
import LoggedUser from 'assets/png/user.png';
import { DropdownIcon } from 'assets/icons';
import { Context } from 'store/context';
import { items } from 'data';
import './style.css';
import { rtdb } from 'utils/firebase';
import * as fbRdb from 'firebase/database';
import { useMyContext } from 'store/modal';
import { Toast } from 'components/toast';
import { NOTIFICATION_MSG } from 'constants';
import { UserInitialIcon } from 'components/userinitialicon';
import { CustomModal, Header } from 'components';
import { BellIcon, MessageIcon } from 'assets/svg';
import Button from 'components/button';

const UnreadMessages = ({ list }) => {
  const renderItem = useCallback(
    (item, key) => (
      <Link key={key} to={item.link}>
        <List.Item>
          <List.Item.Meta title={item.sender} description={item.message} />
        </List.Item>
      </Link>
    ),
    [],
  );

  return <List className='w-72' dataSource={list} renderItem={renderItem} />;
};

const RecentNotifications = () => {
  const {
    notifications: { list, loading },
  } = useContext(Context);
  const renderItem = useCallback(
    (item, key) => (
      <List.Item>
        <div className='flex flex-col'>
          <div className='flex justify-between'>
            <span className='block text-xs font-semibold text-secondary'>{item?.heading}</span>

            <span className='text-xxs text-secondary-60 dark:text-secondary-40'>
              {dayjs(item.created).fromNow()}
            </span>
          </div>
          <span>{item?.content}</span>
        </div>
      </List.Item>
    ),
    [],
  );

  return (
    <List
      footer={
        <div className='flex justify-center items-center'>
          <Link to='/notifications'>
            <span className='text-primary-90'>View All</span>
          </Link>
        </div>
      }
      className='w-72 max-h-[80vh] overflow-y-auto'
      dataSource={list}
      loading={loading}
      renderItem={renderItem}
    />
  );
};

const Navbar = ({ permissions, dashboardType }) => {
  const {
    user,
    company,
    notifications: { list: notifications },
  } = useContext(Context);
  const { myStateValue, setMyStateValue } = useMyContext();
  const [unreads, setUnreads] = useState([]);
  const userName = user?.name?.charAt(0)?.toUpperCase() + user?.name?.slice(1);
  const [rotate, setRotate] = useState(false);
  const location = useLocation();
  const heading = location.pathname.split('/').at(1).replaceAll('-', ' ');
  const onClick = () => {
    setRotate(false);
  };

  const textRef = useRef(null);

  const handleCopyClick = () => {
    const textToCopy = textRef.current.innerText;

    // Create a temporary textarea to copy the text
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    // Copy the text from the textarea
    textarea.select();
    document.execCommand('copy');

    // Clean up and remove the temporary textarea
    document.body.removeChild(textarea);
    // Optionally, you can show a notification or feedback to the user
    setMyStateValue(false);
    Toast('success', NOTIFICATION_MSG.success, 'Text copied to clipboard!');
  };

  useEffect(() => {
    const ref = fbRdb.ref(rtdb, 'unread/' + user.id);
    const unsubscribe = fbRdb.onValue(ref, (sanpshot) => {
      let list = [];
      let objs = {};
      sanpshot.forEach((snap) => {
        objs[snap.key] = [];
        snap.forEach((sm) => {
          objs[snap.key].push({ ...sm.val(), link: `team-chat?active=${snap.key}` });
        });
      });

      Object.keys(objs).forEach((key) => {
        list.push({
          ...objs[key][0],
          message: `${objs[key].length} message${objs[key].length > 1 ? 's' : ''}  ${dayjs(
            objs[key][0].sentTime,
          ).fromNow()}`,
        });
      });
      setUnreads(list);
      if (!window.location.pathname.startsWith('team-chat') && list?.length) {
        message.success('You have new message');
      }
    });

    return () => unsubscribe;
  }, []);

  return (
    <>
      <div className='flex sticky top-0 z-20 justify-between items-center px-4 h-16 bg-white border-b border-grey-light dark:bg-black noPrint'>
        <Link to={'/'}>
          <UserInitialIcon
            classes='max-w-32 h-10 max-w-[initial]'
            name={company?.name}
            imageUrl={process.env.REACT_APP_DOC_URL + company?.logo}
          />
        </Link>
        <Header dashboardType={dashboardType} permissions={permissions} />
        <div className='flex gap-4 items-center'>
          {/* <Popover content={<UnreadMessages list={unreads} />}>
            <Badge count={unreads.length}>
              <Link to='/team-chat'>
                <Button icon={<MessageIcon className='h-6 fill-grey-50' />} type='text' />
              </Link>
            </Badge>
          </Popover> */}

          <Popover
            arrow={false}
            content={<RecentNotifications />}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Badge count={notifications.filter((i) => !i.read).length} size='small' color='#0089CF'>
              <BellIcon className='h-6 cursor-pointer fill-grey-50' />
            </Badge>
          </Popover>

          <Dropdown
            className='group'
            menu={{
              items,
              onClick,
            }}
            onOpenChange={setRotate.bind(this, !rotate)}
            trigger={['click']}
          >
            <button
              className={`group transition-all duration-500 hover:text-primary-90 dark:text-white dark:hover:text-primary-90 pr-4 ${
                rotate && 'text-primary-90'
              }`}
              onClick={(e) => e.preventDefault()}
            >
              <Space>
                <UserInitialIcon
                  name={user?.name}
                  imageUrl={process.env.REACT_APP_DOC_URL + user?.image || LoggedUser}
                  classes='w-10 h-10 object-cover max-w-[initial]'
                />
                <DropdownIcon
                  svgClass={`w-3 transition-all duration-500 group-hover:fill-primary-90 dark:fill-white ${
                    rotate && 'rotate-180 fill-primary-90'
                  }`}
                />
              </Space>
            </button>
          </Dropdown>
        </div>
      </div>
      <CustomModal
        width={400}
        className='p-4 bg-white rounded-md'
        open={myStateValue}
        title={'Reference Link'}
        onCancel={setMyStateValue.bind(this, false)}
        footer={
          <div className='flex gap-2 justify-end mr-2'>
            <Button variant='text' text='back' onClick={setMyStateValue.bind(this, false)} />
            <Button variant='primary' text='copy' onClick={handleCopyClick} />
          </div>
        }
      >
        <div ref={textRef}>{window.location.origin + `/pos-signup?ref=${user?.id}`}</div>
      </CustomModal>
    </>
  );
};

export default Navbar;
