import React, { useContext, useState, useEffect } from 'react';
import { ContentHeader, Module } from 'components';
import { useLocation } from 'react-router';
import { SecureComponent } from 'permission-provider';
import CustomerDetails from './CustomerDetails';
import QuotationDetails from './QuotationDetails';
// import CarDetails from "./CarDetails";
import ComparePolicy from './ComparePolicy';
import { Context } from 'store/context';
import { getPermission, handleDefaultValues } from 'utils';
import { elementData } from 'elements';
// import LifeDetails from "./LifeDetails";
// import HealthDetails from "./HealthDetails";
// import BikeDetails from "./BikeDetails";

const AddQuote = ({ permissions = {} }) => {
  const { user } = useContext(Context);
  const { state } = useLocation();
  const location = useLocation();
  const editRecord = location?.state?.record;

  const [activeForm, setActiveFrom] = useState(1);
  const [formData, setFormData] = useState({
    customer_id: state?.customer || null,
    assign_to_employee: handleDefaultValues({ user }),
  });

  const handleFormNavigation = (toBack = false) => {
    toBack ? setActiveFrom((pre) => pre - 1) : setActiveFrom((pre) => pre + 1);
  };

  useEffect(() => {
    setFormData(state?.initialValues);
  }, []);

  const breadcrumbObj = [
    {
      name: 'Dashboard',
      path: '/',
    },
    {
      name: 'Quotes',
      path: '/quotes',
    },
    {
      name: editRecord ? 'Edit Quote' : 'Add Quote',
      path: '/quotes',
    },
  ];
  return (
    <div className='grid gap-5'>
      {getPermission(permissions, elementData.Quote.permissions.create)?.write ? (
        <>
          <ContentHeader data={breadcrumbObj} showSearch={false} />
          <div className='flex flex-col p-4 bg-white rounded-2xl'>
            {activeForm === 1 && (
              <CustomerDetails
                formData={formData}
                editRecord={editRecord}
                setFormData={setFormData}
                handleFormNavigation={handleFormNavigation}
              />
            )}
            {activeForm === 2 && (
              <QuotationDetails
                formData={formData}
                setFormData={setFormData}
                handleFormNavigation={handleFormNavigation}
              />
            )}
            {/* This is hard coded value to be changed in future */}
            {
              activeForm === 3 &&
                // (formData?.insurance_category === 9 ? (
                //   <CarDetails
                //     formData={formData}
                //     setFormData={setFormData}
                //     handleFormNavigation={handleFormNavigation}
                //   />
                // ) : formData?.insurance_category === 4 ? (
                //   <BikeDetails
                //     formData={formData}
                //     setFormData={setFormData}
                //     handleFormNavigation={handleFormNavigation}
                //   />
                // ) : formData?.insurance_category === 3 ? (
                //   <LifeDetails
                //     formData={formData}
                //     setFormData={setFormData}
                //     handleFormNavigation={handleFormNavigation}
                //   />
                // ) : formData?.insurance_category === 8 ? (
                //   <HealthDetails
                //     formData={formData}
                //     setFormData={setFormData}
                //     handleFormNavigation={handleFormNavigation}
                //   />
                // ) : (
                ''
              // ))
              // (formData?.insurance_category === 2 ? (
              //   <CarDetails
              //     formData={formData}
              //     setFormData={setFormData}
              //     handleFormNavigation={handleFormNavigation}
              //   />
              // ) : formData?.insurance_category === 3 ? (
              //   <BikeDetails
              //     formData={formData}
              //     setFormData={setFormData}
              //     handleFormNavigation={handleFormNavigation}
              //   />
              // ) : formData?.insurance_category === 4 ? (
              //   <LifeDetails
              //     formData={formData}
              //     setFormData={setFormData}
              //     handleFormNavigation={handleFormNavigation}
              //   />
              // ) : formData?.insurance_category === 1 ? (
              //   <HealthDetails
              //     formData={formData}
              //     setFormData={setFormData}
              //     handleFormNavigation={handleFormNavigation}
              //   />
              // ) : (
              //   ""
              // ))
            }
            {activeForm === 4 && (
              <ComparePolicy
                formData={formData}
                setFormData={setFormData}
                handleFormNavigation={handleFormNavigation}
                goBack={() => setActiveFrom(3)}
              />
            )}
            {/* {activeForm === 4 && <HealthDetails handleFormNavigation={handleFormNavigation} />} */}
          </div>
        </>
      ) : (
        <Module />
      )}
    </div>
  );
};

export default SecureComponent(AddQuote);
