import { Button as AntButton, Modal, Select } from 'antd';
import { DropdownIcon } from 'assets/icons';
import { FormContainer } from 'components';
import CustomModal from 'components/modal';
import { Toast } from 'components/toast';
import { NOTIFICATION_MSG } from 'constants';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getInquiryDetails, getPosInquiryDetails, inquiryManagement } from 'service/api-service';
import { Context } from 'store/context';
import { prepareFormFieldsData } from 'utils';
import { STATUS_OPTIONS, addStatusFields } from './data';
import PreviousRemarks from './PreviousRemarks';
import Button from 'components/button';

const CreateQoute = ({ state }) => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [employee, setEmployee] = useState({});
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [initVals, fieldForms] = prepareFormFieldsData({
    fields: addStatusFields,
  });

  const getInquiry = async () => {
    setLoading(true);
    if (state === 'pos') {
      const resp = await getPosInquiryDetails({ id });
      const data = resp?.data?.data;
      setData(data);
      setEmployee({
        id: data?.assigned_to?.id,
        name: data?.assigned_to?.name,
        label: data?.assigned_to?.name,
        value: data?.assigned_to?.id,
      });
    } else {
      const resp = await getInquiryDetails({ id });
      const data = resp?.data?.data;
      setData(data);
      setEmployee({
        id: data?.assigned_to?.id,
        name: data?.assigned_to?.name,
        label: data?.assigned_to?.name,
        value: data?.assigned_to?.id,
      });
    }
    setLoading(false);
  };

  const statusChange = useCallback((status) => {
    const onOk = async () => {
      const resp = await inquiryManagement({
        method: 'patch',
        id: id,
        data: { status: status },
      });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, `Status updated successfully`);
        getInquiry();
      } else {
        Toast('error', NOTIFICATION_MSG.error, 'Please try again');
      }
    };
    Modal.confirm({
      title: 'Are you sure?',
      okText: 'Yes',
      onOk: onOk,
    });
  }, []);

  const makeItDraft = () => {
    setShowModal(true);
  };
  const changeLeadStatus = useCallback((status) => {
    if (status === 'draft') {
      makeItDraft();
    } else statusChange(status);
  }, []);

  const handleAddRemark = async (formData) => {
    let array = data?.remarks || [];
    const remarks = { remarks: formData?.remarks, user_id: user?.name };
    array.push(remarks);
    const payload = {
      ...formData,
      status: 'draft',
      remarks: array,
    };
    const resp = await inquiryManagement({
      method: 'patch',
      id: id,
      data: payload,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, `Status updated successfully`);
      setShowModal(false);
      window.location.reload();
    } else {
      Toast('error', NOTIFICATION_MSG.error, 'Please try again');
    }
  };

  useEffect(() => {
    getInquiry();
  }, []);

  return (
    <>
      <div className='flex items-start gap-4'>
        <Link to={`/lead/edit/${id}/2`}>
          <AntButton type='primary'>
            {data?.status === 'quoted' ? 'Edit Quote' : 'Create Quote'}
          </AntButton>
        </Link>
        {data?.status === 'quoted' && (
          <Select
            className='group border border-grey-90 hover:border-primary-90 rounded-lg p-1 pl-2 h-10'
            suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
            placeholder='Change Status'
            options={STATUS_OPTIONS}
            onChange={changeLeadStatus}
            dropdownAlign={{ offset: [-10, 2] }}
          />
        )}
      </div>
      <CustomModal
        open={showModal}
        destroyOnClose={true}
        closable={true}
        onCancel={setShowModal.bind(this, false)}
        footer={''}
        width='600px'
        title='Add Remarks'
      >
        <FormContainer
          onSubmit={handleAddRemark}
          initialValues={initVals}
          fields={fieldForms}
          accordionClass='grid grid-cols-full gap-4'
          customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
            return (
              <>
                <PreviousRemarks data={data} />
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={setShowModal.bind(this, false)}
                  />
                  <Button
                    text={'Save'}
                    variant='primary'
                    classes={`ml-2 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              </>
            );
          }}
        />
      </CustomModal>
    </>
  );
};

export default CreateQoute;
