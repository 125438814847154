import React from 'react';
// import imgGoDigit from 'assets/png/godigit.png';
import IconButton from 'components/button/IconButton';
import { DELETE, EDIT } from 'assets/icons';
import { CaretRightOutlined, FilePdfOutlined, LinkOutlined } from '@ant-design/icons';
import { elementData } from 'elements';

const DisplayCard = ({ title, description, data, onEditPress, onDeletePress, permissions }) => {
  return (
    <div className=' bg-white bordered rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'>
      {/* <img className='rounded-t-lg w-full flex justify-center' src={imgGoDigit} alt='' /> */}
      <div className='p-5'>
        <h5 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white'>
          {title}
        </h5>
        <p className='mb-3 text-red-400 truncate'>{description}</p>
        <div className='gap-4 flex justify-start'>
          {onEditPress && (
            <IconButton
              title='Edit'
              permissions={permissions}
              className='group disabled:cursor-not-allowed'
              id={elementData.pos_training.permissions.update}
              onClick={onEditPress}
            >
              <EDIT />
            </IconButton>
          )}
          {onDeletePress && (
            <IconButton
              title='Delete'
              permissions={permissions}
              className='group disabled:cursor-not-allowed'
              id={elementData.pos_training.permissions.update}
              onClick={onDeletePress}
            >
              <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
            </IconButton>
          )}
          {data?.asset_type === 'Link' && (
            <IconButton
              title='Link'
              className='group disabled:cursor-not-allowed border border-grey-60 rounded-full w-6 h-6 flex items-center justify-center'
              onClick={() => window.open(data?.asset, '_blank')}
            >
              <LinkOutlined className='text-grey-60' />
            </IconButton>
          )}

          {data?.asset_type === 'Document' && (
            <IconButton
              title='pdf'
              className='group disabled:cursor-not-allowed border border-red-60 rounded-full w-6 h-6 flex items-center justify-center'
              onClick={() =>
                window.open(`${process.env.REACT_APP_DOC_URL + data?.asset}`, '_blank')
              }
            >
              <FilePdfOutlined className='text-red-60' />
            </IconButton>
          )}
          {data?.asset_type === 'Video' && (
            <IconButton
              title='video'
              className='group disabled:cursor-not-allowed border border-primary rounded-full w-6 h-6 flex items-center justify-center'
              onClick={() =>
                window.open(`${process.env.REACT_APP_DOC_URL + data?.asset}`, '_blank')
              }
            >
              <CaretRightOutlined className='text-primary' />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};
export default DisplayCard;
