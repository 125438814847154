/** external deps */
import { DatePicker } from 'antd';
import React from 'react';
import dayjs from 'dayjs';

/** internal deps */
import { CalenderIcon } from 'assets/icons';
import './style.css';

/** @param {import('antd').DatePickerProps & {form: import('formik').FormikProps<any>}} props */
const DateTime = ({
  field,
  form,
  placeholder,
  showTime = false,
  showToday,
  label,
  divClasses,
  disabled,
  defaultValue,
  disablePrev = false,
  disableNext = false,
  modifyValues,
  whetherDateShouldBeDisabled,
  disabledDateTime,
  ...props
}) => {
  const _onChange = (_, val) => {
    if (props?.customOnChange) {
      props?.customOnChange({ form, field, val });
    }
    if (props?.onChange) if (form?.setFieldValue) form.setFieldValue(field?.name, val);
    if (modifyValues) modifyValues(form, val);
  };

  const disabledDate = (current) => {
    if (disablePrev) {
      //It is a temporary fix, need to work on it.Logically we have to subtract 1 day but this fixed the
      // issue thats why implemented now
      return current && current < dayjs().subtract(10, 'hours');
    }
    if (disableNext) {
      return current && current.valueOf() > Date.now();
    }
    if (props.disabledDate) {
      return current.isAfter(props.disabledDate);
    }
    if (props.disableCustomDate) {
      return current && current < dayjs(form?.values[props?.disableCustomDate]);
    }

    // const selected = form?.values["from_date"];
    // if (selected) {
    //   return current && current < dayjs(selected);
    // }
  };

  return (
    <div id='common-date-picker' className={`relative group ${divClasses}`}>
      <label
        className={`group-focus-within:text-primary-90 block mb-1 text-xxl md:text-xxs xl:text-xxs text-black ${
          props?.required ? 'required' : ''
        } `}
      >
        {label}
      </label>
      <DatePicker
        id={field?.name}
        showToday={showToday}
        style={{}}
        value={form?.values?.[field?.name] ? dayjs(form?.values?.[field?.name]) : undefined}
        showTime={showTime}
        disabledTime={disabledDateTime}
        name={field?.name}
        placeholder={placeholder}
        className={` w-full border-[#D1EAF9] bg-[#F5FAFD] placeholder:text-xxs text-xl md:text-xxs xl:text-xxs p-[5px] !focus:border-primary-90 inline-block ${
          disabled && 'border-0'
        }`}
        popupClassName='font-inherit'
        suffixIcon={<CalenderIcon svgClass='w-10 md:w-5' />}
        allowClear={props.allowClear ?? true}
        onChange={_onChange}
        disabled={disabled}
        format={props.format || (showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD')}
        // defaultValue={dayjs(form?.initialValues[field?.name]) || defaultValue}
        defaultValue={
          defaultValue ||
          (form?.initialValues?.[field?.name]
            ? dayjs(form?.initialValues?.[field?.name])
            : undefined)
        }
        // {...(field?.name === "to_date" && { disabledDate: disabledDate })}
        // {...(disablePrev && { disabledDate: disabledDate })}
        // {...(disableNext && { disabledDate: disabledDate })}
        getPopupContainer={(trigger) => trigger.parentNode}
        picker={props.picker}
        // disabledDate={props.disabledDate}
        disabledDate={whetherDateShouldBeDisabled ?? disabledDate}
      />
      {form?.errors[field?.name] && form?.touched[field?.name] && (
        <div
          className={`absolute bg-white ${
            props?.extraRightRender ? 'max-w-[230px]' : ''
          } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
        >
          {form?.errors[field?.name]}
        </div>
      )}
    </div>
  );
};

export default DateTime;
