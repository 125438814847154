/** external deps */
import React, { useState, useCallback } from 'react';

/** internal deps */
import { FormContainer, CustomModal, Toast, Button } from 'components';
import { addConfigFields } from './data';
import { prepareFormFieldsData } from 'utils';
import { editProducts } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';

const CustomDetails = ({ id, docs, fetchData }) => {
  const [detailModal, setDetailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addConfigFields,
    defaultValues: { document: docs },
  });

  const openModal = useCallback(() => {
    setDetailModal(true);
  }, []);
  const closeModal = useCallback(() => {
    setDetailModal(false);
  }, []);
  const loadDetails = useCallback(() => {
    openModal();
  }, []);
  const submitCustomDetails = useCallback(
    async (data) => {
      setLoading(true);
      const payload = {
        docs: data.document,
      };
      const resp = await editProducts({ id, data: payload });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        fetchData();
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      closeModal();
      setLoading(false);
    },
    [id],
  );

  return (
    <>
      <Button onClick={loadDetails} text='Documents' />
      <CustomModal
        destroyOnClose={true}
        width={'60%'}
        open={detailModal}
        onCancel={closeModal}
        title='Add Config'
        footer={''}
      >
        <FormContainer
          accordionClass='grid gap-8'
          onSubmit={submitCustomDetails}
          initialValues={initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    closeModal();
                  }}
                />
                <Button
                  disabled={!isValid}
                  text={'Save'}
                  variant='primary'
                  classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </CustomModal>
    </>
  );
};

export default CustomDetails;
