import { CustomFieldArray, SelectInput, TextInput } from 'components';

export const addFormFields = {
  'Form Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Form Name',
        name: 'name',
        id: 'name',
        placeholder: 'Form Name',
        type: 'text',
        required: true,
        component: TextInput,
      },
      {
        name: 'fields',
        type: 'fieldArray',
        id: 'fields',
        addButtonText: '+ More',
        valueSchema: {
          id: '',
          name: '',
          label: '',
          type: '',
          options: [],
        },
        component: CustomFieldArray,
        divClasses: 'col-span-full',
        gridCols: 'grid-cols-4 py-2',
        fields: [
          {
            label: 'Field Name',
            name: 'name',
            id: 'name',
            placeholder: 'Field Name',
            type: 'text',
            required: true,
            component: TextInput,
          },
          {
            label: 'Label',
            name: 'label',
            id: 'label',
            placeholder: 'Label',
            type: 'text',
            required: true,
            component: TextInput,
          },
          {
            label: 'Type',
            name: 'type',
            id: 'type',
            placeholder: 'Select Type',
            type: 'select',
            required: true,
            component: SelectInput,
            options: [
              { label: 'Checkbox', value: 'checkbox' },
              { label: 'Date', value: 'date' },
              { label: 'Number', value: 'number' },
              { label: 'Radio', value: 'radio' },
              { label: 'Select', value: 'select' },
              { label: 'Text', value: 'text' },
              { label: 'Textbox', value: 'textbox' },
            ],
          },
          {
            dependent: true,
            dependent_on: 'type',
            dependent_value: 'select',
            label: 'Options',
            name: 'options',
            id: 'options',
            placeholder: 'Options',
            type: 'select',
            component: SelectInput,
            options: [],
          },
        ],
      },
    ],
  },
};

export const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Custom Form',
    path: '/form',
  },
];
