import { FileTextOutlined } from '@ant-design/icons';
import { Space, Table } from 'antd';
import IconButton from 'components/button/IconButton';
import CustomModal from 'components/modal';
import { useState } from 'react';

const PreviousProductsTable = ({ data }) => {
  const [documentModal, setDocumentModal] = useState(false);
  const [selected, setSelected] = useState({});

  const onDocumentPress = () => {
    setDocumentModal((prev) => !prev);
  };

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Product Expiry',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
    },
    {
      title: 'Document',
      dataIndex: 'document',
      key: 'document',
      align: 'left',
      render: (_, record) => {
        return (
          <div className='flex justify-start'>
            <Space size='middle'>
              <IconButton
                title='Document'
                className='group disabled:cursor-not-allowed'
                onClick={() => {
                  onDocumentPress();
                  setSelected(record);
                }}
              >
                <FileTextOutlined className='border border-grey rounded-full p-1 w-6 h-6' />
              </IconButton>
            </Space>
          </div>
        );
      },
    },
  ];

  return (
    <div className='mt-4'>
      <h2 className='text-md text-primary-90'>Previous Products</h2>
      <Table dataSource={data} columns={columns} pagination={false} />

      <CustomModal
        open={documentModal}
        closable={true}
        onCancel={onDocumentPress}
        footer={''}
        width={450}
      >
        <object width={300} data={process.env.REACT_APP_DOC_URL + selected?.document}>
          {''}
        </object>
      </CustomModal>
    </div>
  );
};

export default PreviousProductsTable;
