import { useParams } from 'react-router-dom';
import MultiProductpolicy from './create-policy';
// import CreatePolicy from './create-policy/CreatePolicy';
import CreateLead from './CreateLead';
import Quotations from './Quotations';
import Proposals from './Proposals';

const LeadComponent = ({ permissions }) => {
  const { step } = useParams();

  return (
    <div className='w-full bg-white  h-fit  rounded-md '>
      {step === '1' && <CreateLead permissions={permissions} />}
      {step === '2' && <Quotations permissions={permissions} />}
      {step === '3' && <Proposals permissions={permissions} />}
      {step === '4' && <MultiProductpolicy permissions={permissions} />}
    </div>
  );
};

export default LeadComponent;
