import { render } from '@testing-library/react';
import { Tooltip } from 'antd';
import { MultiUserInitial, User } from 'components';
import dayjs from 'dayjs';
import { data } from 'jquery';
import { formatDate } from 'utils';
import { date } from 'yup';

// UpcomingBirthday columns
export const UpcomingBirthday_Cols = [
  {
    key: 'name',
    title: 'Employee Name',
    dataIndex: 'name',
    render: (name, data) => {
      return <User name={name || data?.customer_name} />;
    },
  },
  // {
  //   key: 'name',
  //   title: 'Department',
  //   dataIndex: 'name',
  //   render: (name) => {
  //     return <span className='text-black'>{'\u2014'}</span>;
  //   },
  // },
  {
    key: 'dob',
    title: 'Date',
    dataIndex: 'dob',
    // align: 'right',
    render: (date, data) => {
      return (
        <span className='text-black'>{dayjs(data?.joining || data?.dob)?.format('MMM DD')}</span>
      );
    },
  },
  {
    key: 'type',
    title: 'Type',
    render: (date, data) => {
      return (
        <>
          {data.dob && <span className='text-black'>Birthday</span>}
          {data.joining && <span className='text-black'>Work Anniversary</span>}
        </>
      );
    },
  },
];
// AssetAllocation columns

export const AssetAllocation_Cols = [
  {
    key: 'created',
    title: 'Date',
    dataIndex: 'created',
    // className: '!text-center',
    render: (created) => {
      return <span className='text-black'>{dayjs(created)?.format('MMM DD')}</span>;
    },
  },
  {
    key: 'requested_by',
    title: 'Requested By',
    dataIndex: 'requested_by',
    render: (emp) => {
      return <User image={emp?.image} name={emp?.name} />;
    },
  },
  {
    key: 'department',
    title: 'Department',
    dataIndex: 'requested_by',
    render: (requested_by) => {
      return (
        <div className='truncate'>
          <div className='flex gap-1 flex-wrap'>
            {requested_by?.departments.length > 0
              ? requested_by?.departments?.map((item, index) => (
                  <Tooltip title={item?.name}>
                    <div className='bg-grey-40 py-1 px-3 rounded-md text-xxxs' key={index}>
                      {item?.name}
                    </div>
                  </Tooltip>
                ))
              : '\u2014'}
          </div>
        </div>
      );
    },
  },
  {
    key: 'asset_type',
    title: 'Asset Type',
    dataIndex: 'asset_type',
    render: (data) => {
      return (
        <div className='flex gap-1 flex-wrap'>
          {data.length > 0
            ? data?.map((item, index) => (
                <div className='bg-grey-40 py-1 px-3 rounded-md text-xxxs' key={index}>
                  {item?.name}
                </div>
              ))
            : '\u2014'}
        </div>
      );
    },
  },
  {
    key: 'imd_need',
    title: 'Urgent',
    dataIndex: 'imd_need',
    render: (imd_need) => (
      <p className='text-black'>{imd_need === true ? 'Yes' : 'No' || '\u2014'}</p>
    ),
  },
  {
    key: 'reason',
    title: 'Reason',
    dataIndex: 'reason',
    render: (reason) => (
      <div className='truncate'>
        <Tooltip title={reason}>{reason || '\u2014'}</Tooltip>
      </div>
    ),
  },
];
// UpcomingInterview columns

export const InterviewRequest_Cols = [
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
    render: (name) => <p className='text-black'>{name || '\u2014'}</p>,
  },
  /*{
    key: 'email',
    title: 'Email Address',
    dataIndex: 'email',
    render: (email) => <p className='text-black'>{email || '\u2014'}</p>,
  },*/
  {
    key: 'designation',
    title: 'Position',
    dataIndex: 'designation',
    render: (designation) => <p className='text-black'>{designation || '\u2014'}</p>,
  },
  {
    key: 'phone',
    title: 'Contact',
    dataIndex: 'phone',
    render: (phone) => <p className='text-black'>{phone || '\u2014'}</p>,
  },
];
// UpcomingInterview columns

export const UpcomingInterview_Cols = [
  {
    key: 'time',
    title: 'Time',
    dataIndex: 'date',
    className: '!text-center',
    render: (date) => (
      <p className='truncate justify-center flex w-auto text-black'>
        {formatDate({
          dateString: date,
          toUtc: true,
          format: 'hh:mm',
        })}
      </p>
    ),
  },
  {
    key: 'employees',
    title: 'Interviewer',
    dataIndex: 'employees',
    render: (data) => (
      <div className='truncate'>
        <MultiUserInitial data={data} value={'name'} label='interviewer' showImage />
      </div>
    ),
  },
  {
    key: 'name',
    title: 'Candidate',
    dataIndex: 'name',
    render: (name) => <p className='truncate flex w-auto text-black'>{name || '-'}</p>,
  },
  {
    key: 'designation',
    title: 'Position',
    dataIndex: 'designation',
    render: (designation) => (
      <p className='truncate flex w-auto text-black'>{designation || '-'}</p>
    ),
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (status) => <p className='truncate flex w-auto text-black'>{status || '-'}</p>,
  },
];

// Upcoming Leaves
export const UpcomingLeave_Cols = [
  {
    key: 'employee',
    title: 'Employee',
    dataIndex: 'created_by',
    render: (user) => {
      return <User name={user?.name} image={process.env.REACT_APP_DOC_URL + user?.image} />;
    },
  },
  {
    key: 'department',
    title: 'Department',
    dataIndex: 'created_by',
    render: (user) => {
      return <span className='text-black'>{user?.name || '\u2014'}</span>;
    },
  },
  {
    key: 'reason',
    title: 'Reason',
    align: 'center',
    dataIndex: 'leave_type',
    render: (type) => {
      return (
        <div className='flex justify-center items-center gap-1'>{type?.label || '\u2014'}</div>
      );
    },
  },
];

// All Tickets

export const AllTickets_Cols = [
  {
    key: 'ticket_id',
    title: 'Ticket Id',
    dataIndex: 'ticket_id',
    render: (ticket_id) => <p className='text-black'>{ticket_id || '\u2014'}</p>,
  },
  {
    key: 'created_by',
    title: 'Name',
    dataIndex: 'created_by',
    render: (created_by) => <p className='text-black'>{created_by.name || '\u2014'}</p>,
  },
  {
    key: 'reason',
    title: 'Reason',
    dataIndex: 'reason',
    render: (reason) => (
      <div className='truncate'>
        <Tooltip title={reason}>
          {reason || '\u2014'}
          {/* <p className='text-black capitalize'>{reason || '\u2014'}</p> */}
        </Tooltip>
      </div>
    ),
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (status) => <p className='text-black capitalize'>{status || '\u2014'}</p>,
  },
];

// Office Events

export const UpcomingOfficeEvents_Cols = [
  {
    key: 'date',
    title: 'Date',
    dataIndex: 'date',
    // render: (date, data) => {
    //   return (
    //     <span className='text-black'>{dayjs(data?.date)?.format('MMM DD')}</span>
    //   );
    // },
    render: (date) => (
      <p className='truncate flex w-auto text-black'>
        {formatDate({
          dateString: date,
          toUtc: true,
          format: 'MMMM DD, h:mm A',
        })}
      </p>
    ),
  },
  {
    key: 'title',
    title: 'Event',
    dataIndex: 'title',
    render: (title) => <p className='text-black capitalize'>{title || '\u2014'}</p>,
  },
  {
    key: 'name',
    title: 'Created By',
    dataIndex: 'created_by',
    render: (created_by) => <p className='text-black capitalize'>{created_by.name || '\u2014'}</p>,
  },
];
