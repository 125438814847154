/** external deps */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

/** internal deps */
import { Button, FormContainer, IconButton, Module, Toast } from 'components';
import { addCustomer, contactManagement } from 'service/api-service';
import { SecureComponent } from 'permission-provider';
import { getEditedFields, getPermission, prepareFormFieldsData } from 'utils';
import { elementData } from 'elements';
import { BackArrow } from 'assets/icons';
import { NOTIFICATION_MSG } from 'constants';
import { ContactField } from './data';

const AddContact = ({
  goBack,
  getContacts,
  initials,
  permissions = {},
  customer_id: c_id,
  customerData,
}) => {
  const { id: customer_id } = useParams();
  const isAdding = !initials;
  const [loading, setLoading] = useState(false);
  const customerFields = JSON.parse(localStorage.getItem('customerFields'))?.find(
    (ele) => ele?.title === 'Insured Details',
  );
  const leadSourceOptions = customerFields?.fields
    ?.find((ele) => ele?.name === 'lead_source')
    ?.options?.map((item) => {
      return {
        label: item?.name,
        id: item?.id,
        value: item?.id,
        name: item?.name,
      };
    });
  const [initialValues, finalFormFields] = prepareFormFieldsData({
    fields: ContactField(leadSourceOptions),
    defaultValues: {
      assign_to_employee: customerData?.assign_to_employee,
      branch_of: customerData?.branch_of?.id,
    },
  });

  const finalInitialValues =
    {
      ...initials,
      branch_of: initials?.branch_of?.id,
      lead_source: initials?.lead_source?.id,
      bank_account_type: initials?.bank_account_type?.id,
      credit_term: initials?.credit_term?.id,
      credit_rating: initials?.credit_rating?.id,
    } || {};

  /** add contact */
  const handleAddContact = async (data) => {
    setLoading(true);
    const isChecked = data?.checkaddress;
    const finalPayload = {
      ...data,
      parent: customer_id,
      date_of_birth: data?.date_of_birth || null,
      r_country: isChecked ? data?.country : data?.r_country,
      r_address: isChecked ? data?.address : data?.r_address,
      r_state: isChecked ? data?.state : data?.r_state,
      r_city: isChecked ? data?.city : data?.r_city,
      r_postcode: isChecked ? data?.postcode : data?.r_postcode,
    };

    const resp = await addCustomer({ method: 'post', data: finalPayload, customer_id: c_id });
    if (resp?.data?.status) {
      goBack();
      getContacts();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Contact Created Successfully');
    } else {
      const head = resp?.data?.msg;
      if (typeof head === 'string') {
        Toast('error', NOTIFICATION_MSG.error, resp?.data?.msg || 'Please try again');
      } else {
        Object.keys(head).forEach((key) => {
          Toast('error', NOTIFICATION_MSG.error, `${key}: ${head[key]}` || 'Please try again');
        });
      }
    }
    setLoading(false);
  };

  /** edit contact */
  const handleUpdateContact = async (data) => {
    setLoading(true);
    const isChecked = data?.checkaddress;
    const finalPayload = {
      ...data,
      r_country: isChecked ? data?.country : data?.r_country,
      r_address: isChecked ? data?.address : data?.r_address,
      r_state: isChecked ? data?.state : data?.r_state,
      r_city: isChecked ? data?.city : data?.r_city,
      r_postcode: isChecked ? data?.postcode : data?.r_postcode,
    };
    const id = initials?.id;
    if (!id) return;
    const editedFields = getEditedFields({
      initialValues: initials,
      updatedData: { ...finalPayload, customer_id, id },
    });
    if (Object.keys(editedFields).length) {
      const resp = await contactManagement({
        method: 'patch',
        data: editedFields,
        customer_id,
        id,
      });
      if (resp?.data?.status) {
        goBack();
        getContacts();
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.data?.msg || 'Contact updated successfully',
        );
      } else {
        const head = resp?.data?.msg;

        if (typeof head === 'string') {
          Toast('error', NOTIFICATION_MSG.error, resp?.data?.msg || 'Please try again');
        } else {
          Object.keys(head).forEach((key) => {
            Toast('error', NOTIFICATION_MSG.error, `${key}: ${head[key]}` || 'Please try again');
          });
        }
      }
      setLoading(false);
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  return (
    <div className='bg-white  flex flex-col rounded-2xl p-4'>
      {getPermission(permissions, elementData.client.permissions.create)?.write ? (
        <>
          <div className='flex justify-between px-2'>
            <div className='flex flex-col'>
              <span className='text-l 2xl:text-xxl font-medium'>
                {isAdding ? 'Add' : 'Update'} Contact
              </span>
              <span className='text-xxs text-grey-80 '>
                To add a new contact, enter the details of the contact in the input field below.
              </span>
            </div>

            <IconButton onClick={() => goBack()}>
              <BackArrow svgClass='fill-grey-90 w-10 h-10' />
            </IconButton>
          </div>
          <FormContainer
            accordionClass='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 items-center'
            fields={finalFormFields}
            initialValues={isAdding ? initialValues : finalInitialValues}
            onSubmit={isAdding ? handleAddContact : handleUpdateContact}
            customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      goBack();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      isAdding
                        ? elementData.client.permissions.create
                        : elementData.client.permissions.update
                    }
                    text={isAdding ? 'Add Contact' : 'Update Contact'}
                    type='button'
                    variant='primary'
                    classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </>
      ) : (
        <Module />
      )}
    </div>
  );
};

export default SecureComponent(AddContact);
