import { TextInput, PhoneNumberInput, SelectInput, SearchAPI, Checkbox } from 'components';

export const fields = ({ disableCustomerName = true }) => {
  return {
    'Inquiry Details': {
      fields: [
        // {
        //   label: "Inquiry Name",
        //   name: "name",
        //   id: "name",
        //   placeholder: "Enter Inquiry Name",
        //   type: "text",
        //   required: true,
        //   component: TextInput,
        //   maxLength: 30,
        // },
        {
          label: 'Client Name',
          name: 'customer_name',
          id: 'customer_name',
          placeholder: 'Enter Client Name',
          disabled: disableCustomerName || false,
          // component: TextInput,
          component: SearchAPI,
          maxLength: 30,
        },
        {
          label: 'Contact Name',
          name: 'contact_id',
          id: 'contact_id',
          placeholder: 'Search Contact Name',
          type: 'search',
          component: SearchAPI,
          maxLength: 30,
        },
        {
          label: 'Primary Email Address',
          name: 'primary_email_address',
          id: 'primary_email_address',
          placeholder: 'Enter Primary Email Address',
          type: 'text',
          required: true,
          component: TextInput,
          maxLength: 30,
        },
        {
          label: 'Contact Number',
          name: 'contact_number',
          id: 'contact_number',
          placeholder: 'Contact Number',
          required: true,
          type: 'number',
          maxLength: 10,
          component: TextInput,
        },
        {
          label: 'Category of Insurance',
          name: 'insurance_category',
          id: 'insurance_category',
          placeholder: 'Category of Insurance',
          type: 'search',
          component: SearchAPI,
          maxLength: 30,
        },
        {
          dependent: true,
          dependent_on: 'insurance_category',
          dependent_value: 'insurance_category',
          label: 'Product Type',
          name: 'product_type',
          id: 'product_type',
          placeholder: 'Product Type',
          type: 'text',
          component: SelectInput,
          // maxLength: 30,
        },
        // {
        //   label: "Duration",
        //   name: "duration",
        //   id: "duration",
        //   placeholder: "Select Duration",
        //   type: "select",
        //   component: SelectInput,
        //   options: [
        //     { label: "7 Days", value: "7D" },
        //     { label: "14 Days", value: "14D" },
        //     { label: "30 Days", value: "30D" },
        //     { label: "60 Days", value: "60D" },
        //     { label: "90 Days", value: "90D" },
        //   ],
        // },
        // {
        //   label: "Period of Insurance: From",
        //   name: "insurance_period_from",
        //   id: "insurance_period_from",
        //   placeholder: "Period of Insurance: From",
        //   type: "date",
        //   required: true,
        //   showTime: false,
        //   component: DateTime,
        //   maxLength: 30,
        // },
        // {
        //   label: "Period of Insurance: To",
        //   name: "insurance_period_to",
        //   id: "insurance_period_to",
        //   placeholder: "Period of Insurance: To",
        //   type: "date",
        //   showTime: false,
        //   required: true,
        //   component: DateTime,
        //   maxLength: 30,
        // },
        // {
        //   label: "Expected Close Date",
        //   name: "close_date",
        //   id: "close_date",
        //   placeholder: "Expected Close Date",
        //   type: "date",
        //   required: true,
        //   component: DateTime,
        //   maxLength: 30,
        // },
        // {
        //   label: "Probability",
        //   name: "probability",
        //   id: "probability",
        //   placeholder: "Enter Probability",
        //   type: "text",
        //   component: TextInput,
        //   maxLength: 30,
        // },
        // {
        //   label: "Probability",
        //   name: "probability",
        //   id: "probability",
        //   placeholder: "Select Probability",
        //   type: "select",
        //   component: SelectInput,
        //   options: [
        //     { label: "Low", value: "low" },
        //     { label: "Medium", value: "medium" },
        //     { label: "High", value: "high" },
        //   ],
        // },
        // {
        //   label: "Lead Source",
        //   name: "lead_source",
        //   id: "lead_source",
        //   placeholder: "Lead Source",
        //   type: "select",
        //   required: true,
        //   component: SelectInput,
        //   options: [
        //     { label: "Advertisement", value: "AD" },
        //     { label: "Self Service Portal", value: "SP" },
        //     { label: "Cold Call", value: "CC" },
        //     { label: "Existing Customer", value: "EC" },
        //     { label: "Self Generated", value: "SG" },
        //     { label: "External Referral", value: "ER" },
        //     { label: "Employee Referral", value: "EMR" },
        //     { label: "Partner", value: "PN" },
        //     { label: "Public Relations", value: "PR" },
        //     { label: "Direct Mail", value: "DM" },
        //     { label: "Conference", value: "CO" },
        //     { label: "Website", value: "WE" },
        //     { label: "Trade Show", value: "TS" },
        //     { label: "Word of mouth", value: "WM" },
        //     { label: "Others", value: "OT" },
        //   ],
        // },
        // {
        //   label: "Sales Stage",
        //   name: "sales_stages",
        //   id: "sales_stages",
        //   placeholder: "Select Sales Stage",
        //   type: "select",
        //   required: true,
        //   component: SelectInput,
        //   options: [
        //     { label: "Inquiry Created", value: "IC" },
        //     { label: "Analysis", value: "AN" },
        //     { label: "Qualified", value: "QU" },
        //     { label: "Closed Won", value: "CW" },
        //     { label: "Closed Lost", value: "CL" },
        //     { label: "Proposal/Quotation", value: "PQ" },
        //     { label: "Negotiation/Review", value: "NR" },
        //   ],
        // },
        // {
        //   label: "Place of Employment",
        //   name: "employement",
        //   id: "employement",
        //   placeholder: "Place of Employment",
        //   component: TextInput,
        //   type: "text",
        //   maxLength: 30,
        // },
        // {
        //   label: "Next Step",
        //   name: "next_step",
        //   id: "next_step",
        //   placeholder: "Next Step",
        //   component: TextInput,
        //   type: "text",
        //   maxLength: 30,
        // },
        // {
        //   label: "Type of Policy",
        //   name: "policy",
        //   id: "policy",
        //   placeholder: "Type of Policy",
        //   component: TextInput,
        //   type: "text",
        //   maxLength: 30,
        // },
        {
          label: 'Assign To Employee',
          name: 'assign_to_employee',
          id: 'assign_to_employee',
          placeholder: 'Assign To Employee',
          type: 'search',
          component: SearchAPI,
          maxLength: 30,
        },
        {
          label: 'Source',
          name: 'source',
          id: 'source',
          placeholder: 'Source',
          type: 'select',
          component: SelectInput,
          options: [
            { label: 'Web', value: 'web' },
            { label: 'Agent', value: 'agent' },
            { label: 'Employee', value: 'employee' },
          ],
        },
        {
          label: 'Source Ref',
          name: 'source_ref',
          id: 'source_ref',
          placeholder: 'Source Ref',
          type: 'text',
          component: TextInput,
        },
        // {
        //   label: "Submission Date",
        //   name: "submission_date",
        //   id: "submission_date",
        //   placeholder: "Submission Date",
        //   type: "date",
        //   required: true,
        //   showTime: false,
        //   component: DateTime,
        //   maxLength: 30,
        // },
        // {
        //   label: "Request From",
        //   name: "request_from",
        //   id: "request_from",
        //   placeholder: "Request From",
        //   type: "search",
        //   component: SearchAPI,
        //   maxLength: 30,
        // },
        {
          label: 'Presently Insured',
          name: 'presently_insured',
          id: 'presently_insured',
          placeholder: 'Presently Insured',
          type: 'checkbox',
          component: Checkbox,
          checked: true,
          divClasses: 'self-end',
        },
        // {
        //   label: "Nature of Buisness",
        //   name: "business_nature",
        //   id: "business_nature",
        //   placeholder: "Nature of Buisness",
        //   type: "text",
        //   isMultiline: true,
        //   component: TextInput,
        //   divClasses: "col-span-full",
        //   maxLength: 300,
        //   rows: 3,
        // },
      ],
    },
    // Description: {
    //   fields: [
    //     {
    //       label: "Description",
    //       name: "description",
    //       id: "description",
    //       placeholder: "Description",
    //       type: "textarea",
    //       component: TextInput,
    //       isMultiline: true,
    //       divClasses: "col-span-3",
    //       maxLength: 300,
    //       rows: 3,
    //     },
    //   ],
    // },
  };
};

export const getInitialValues = ({ value }) => ({
  // name: "",
  customer_id: '',
  // customer_name: "",
  contact_id: '',
  primary_email_address: '',
  contact_number: '',
  insurance_category: '',
  insurance_product: '',
  // duration: "",
  // insurance_period_from: "",
  // insurance_period_to: "",
  // close_date: "",
  // probability: "",
  // lead_source: "",
  // sales_stages: "",
  // employement: "",
  // next_step: "",
  // policy: "",
  // business_nature: "",
  assign_to_employee: value,
  // submission_date: "",
  // request_from: "",
  presently_insured: true,
  // description: "",
  source_ref: '',
  source: '',
});
