import React from 'react';
import './style.css';
import { User as DefaultUser } from 'assets';
import { DefaultUserIcon } from 'assets/icons';

const User = ({ className, image, name }) => {
  return (
    <>
      <div className='flex space-x-2 items-center'>
        <span>
          {image ? (
            <img
              src={process.env.REACT_APP_DOC_URL + image}
              className='h-6 w-6 rounded-full'
              alt='img'
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = DefaultUser;
              }}
            />
          ) : (
            <DefaultUserIcon />
            // <img src={DefaultUser} className='h-6 w-6 rounded-full' alt='img' />
          )}
        </span>
        <span
          className={
            className ? className : 'text-black capitalize font-semibold max-w-[100px] truncate'
          }
        >
          {name || '\u2014'}
        </span>
      </div>
    </>
  );
};

export default User;
