import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import './PhoneStyle.css';

const PhoneNumberInput = ({ onChange, field, form, InputClasses, ...props }) => {
  const [isPhoneValid, setPhoneValid] = useState(true);
  // const [length, setLength] = useState("");
  const _onChange = (value, country) => {
    const { format } = country;

    if (props?.customOnChange) {
      props?.customOnChange({ value, country, isPhoneValid });
    }
    // setLength(format?.replace(/[^.]+/g, "").length);
    if (format?.replace(/[^.]+/g, '').length === value.length) {
      setPhoneValid(true);
      if (form?.setFieldValue) return form?.setFieldValue(field?.name, `+${value}`);
    } else {
      setPhoneValid(false);
      return form?.setFieldValue(field?.name, ``);
    }
  };

  return (
    <div className='group'>
      <label
        className={`group-focus-within:text-primary-90 block mb-0.5 text-xxs xl:text-xxs text-black ${
          props?.required ? 'required' : ''
        }`}
      >
        {props?.label}
      </label>
      <div className='relative'>
        <PhoneInput
          {...props}
          name={field?.name}
          value={field?.value}
          disabled={props?.disabled}
          enableSearch
          country={'in'}
          className={`border border-[#D1EAF9]  rounded-md   ${
            props?.disabled ? 'border-grey-light' : ''
          } ${isPhoneValid ? 'group-focus-within:border-primary-90' : 'border-red'}`}
          inputClass={`!border-0 !outline-0 left-1 ${props?.disabled ? '!bg-grey-light' : ''} ${
            isPhoneValid ? '' : ' !border-0'
          }`}
          buttonClass={`!border-0 !rounded-lg ${
            props?.disabled ? '!bg-grey-light' : '!bg-[#F5FAFD]'
          }`}
          searchClass='w-full capitalize pt-4 m-auto '
          placeholder={props?.placeholder}
          // enableLongNumbers={length}
          // isValid={isPhoneValid}
        />

        <div className={`absolute right-0 flex items-center cursor-pointer`}>
          {props?.extraRightRender ? props?.extraRightRender() : null}
        </div>

        {form?.errors[field?.name] && form?.touched[field?.name] && (
          <div
            className={`absolute ${
              props?.extraRightRender ? 'max-w-[230px]' : ''
            } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
          >
            {form?.errors[field?.name]}
          </div>
        )}
      </div>
    </div>
  );
};
export default PhoneNumberInput;
