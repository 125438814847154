import React, { useState } from 'react';
import { Button, CustomModal, FormContainer, Toast } from 'components';
import { addHolidayFields } from './data';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
import { patchHoliday, postHoliday } from 'service/api-service';

const AddHolidayModal = ({ open, onCancel, editInitials, permissions, fetchYearData }) => {
  const [loading, setLoading] = useState(false);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addHolidayFields,
  });

  const onAddHoliday = async (data) => {
    setLoading(true);
    const resp = await postHoliday({
      data,
    });

    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      fetchYearData();
      onCancel();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onEditHoliday = async (data) => {
    setLoading(true);
    const editedFields = getEditedFields({
      initialValues: editInitials,
      updatedData: data,
    });
    if (Object.keys(editedFields).length) {
      const resp = await patchHoliday({
        data: editedFields,
        id: editInitials?.id,
      });

      if (resp?.data?.status) {
        fetchYearData();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to update data');
      }
      setLoading(false);
    } else {
      setLoading(false);
      return Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  return (
    <CustomModal
      title={editInitials ? 'Edit Holiday' : 'Add Holiday'}
      destroyOnClose={true}
      open={open}
      onCancel={onCancel}
      footer={''}
    >
      <div>
        <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10'>
          {editInitials
            ? 'Edit holiday details'
            : 'To add a new holiday, enter the details of the holiday in the input field below.'}
        </span>

        <FormContainer
          accordionClass='grid grid-cols-2  gap-8'
          onSubmit={editInitials ? onEditHoliday : onAddHoliday}
          initialValues={editInitials || initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                />
                <Button
                  disabled={!isValid}
                  permissions={permissions}
                  id={
                    editInitials
                      ? elementData.holidays_calender.permissions.update
                      : elementData.holidays_calender.permissions.create
                  }
                  text={editInitials ? 'Update holiday' : 'Submit'}
                  variant='primary'
                  onClick={submitForm}
                  classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </div>
    </CustomModal>
  );
};

export default AddHolidayModal;
