import { createContext, useEffect, useRef, useState } from 'react';
const initialvalue = {
  timer: {},
};

export const TimerContext = createContext(initialvalue);

export const formatTime = (secs) => {
  const hours = Math.floor(secs / 3600)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor(secs / 60)
    .toString()
    .padStart(2, '0');
  const seconds = (secs % 60).toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

const TimerFunction = ({ children }) => {
  const [seconds, setSeconds] = useState(0);
  const intervalRef = useRef();
  const [start, setStart] = useState(false);

  // const updateSeconds = (secs) => {
  //   const finalSeconds = ChangeSeconds();
  //   setSeconds(secs);
  // };

  // const toggleStart = (start = undefined) => setStart((prev) => start ?? !prev);

  useEffect(() => {
    if (start) intervalRef.current = setInterval(() => setSeconds((prev) => prev + 1), 1000);

    return () => clearInterval(intervalRef.current);
  }, [seconds, start]);

  return (
    <TimerContext.Provider value={{ seconds, setStart, setSeconds }}>
      {children}
    </TimerContext.Provider>
  );
};

export default TimerFunction;
