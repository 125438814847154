/** external deps */
import React from 'react';

/** internal deps */
import { ViewData } from 'components';
import { createDocumentFields, initialDocumentValues } from './data';

import TargetOverview from './TargetOverview';
import { getPermission } from 'utils';
import { elementData } from 'elements';
import LeavesView from './LeavesView';
import AddDocument from 'components/documents/view-employee';

const OverviewEmployee = ({ data, setActiveTabKey, permissions = {}, employee_id, rawData }) => {
  return (
    <main
      className='bg-white rounded-2xl p-4 grid grid-cols-2 [&>*]:border-b-2 [&>*:nth-child(odd)]:border-r-2 [&>*]:p-8 [&>*]:border-grey-70 mt-4'
      id='over-view'
    >
      {/* first row */}

      {/* first box */}
      <div className='pr-4'>
        <h2 className='font-semibold text-s'>Personal Details </h2>
        <div className='pt-4'>
          <ViewData data={data} className='grid-cols-2' />
        </div>
      </div>

      {/* second box */}
      <div className='relative'>
        <TargetOverview employee_id={employee_id} setActiveTabKey={setActiveTabKey} />
      </div>
      {/* second row */}

      {/* first box */}
      {getPermission(permissions, elementData.employee.permissions.update).read && (
        <AddDocument
          fields={createDocumentFields}
          initialValues={initialDocumentValues}
          user_id={employee_id}
          type='employee'
          tableData={rawData.docs ?? []}
        />
      )}
      {/* second box */}
      <div className='relative'>
        <LeavesView employee_id={employee_id} />
      </div>
    </main>
  );
};

export default OverviewEmployee;
