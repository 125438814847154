/** external deps */
import React from 'react';
import { useNavigate } from 'react-router-dom';
/** internal deps */
import Error from '../../assets/png/Error.png';
import { Button } from 'components';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className='flex flex-row flex-row-reverse justify-center items-center mx-auto my-4'>
      <img src={Error} alt='assistant' className='h-80 w-80 lg:h-[25rem] lg:w-[25rem]' />
      <div className='w-1/2 space-y-4'>
        <h2 className='text-primary-90 text-6xl font-bold'>Something is wrong</h2>
        <p className='font-medium'>
          The page you are looking for was moved, removed, renamed or might never existed!
        </p>
        <Button
          text='Go Home'
          variant={'primary'}
          classes='font-semibold'
          onClick={() => navigate('/')}
        />
      </div>
    </div>
  );
};

export default NotFound;
