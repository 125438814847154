import ChildSavingForm from './ChildSavingForm';

export default [
  {
    label: 'ICICI Protect Form',
    value: 'ICICIProtectForm',
  },
  {
    label: 'Child Saving Form',
    value: 'ChildSavingForm',
  },
  {
    label: 'Four Wheeler Form',
    value: 'FourWheelerForm',
  },
  {
    label: 'Health Form',
    value: 'HealthForm',
  },
  {
    label: 'Investment And Tax Planning Form',
    value: 'InvestmentAndTaxPlanningForm',
  },
  {
    label: 'Life Form',
    value: 'LifeForm',
  },
  {
    label: 'Two Wheeler Form',
    value: 'TwoWheelerForm',
  },
  {
    label: 'Pension And Retirement Form',
    value: 'PensionAndRetirementForm',
  },
  {
    label: 'Term Life Form',
    value: 'TermLifeForm',
  },
  {
    label: 'Travel From',
    value: 'TravelForm',
  },
];

export const Forms = {
  ChildSavingForm,
};
