import { TimePicker, CustomFieldArray, SelectInput, DateTime, TextInput } from 'components';
import { WEEK_DAYS } from 'constants';
import { searchApis } from 'service/api-service';
import { timeDiffrence } from 'utils';

const addAttendanceSettingsFields = ({ isBreak, setIsBreak }) => ({
  attendanceSettings: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Shift Name',
        name: 'name',
        id: 'season_name',
        placeholder: ' Shift Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Working Days',
        name: 'days',
        id: 'working_days',
        placeholder: 'Working Days',
        type: 'select',
        required: true,
        component: SelectInput,
        options: WEEK_DAYS,
        checkbox: true,
        isMulti: true,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
      },
      // {
      //   label: 'Start',
      //   name: 'start',
      //   id: 'start',
      //   placeholder: 'Select Start Month',
      //   type: 'date',
      //   required: true,
      //   component: DateTime,
      //   picker: 'month',
      //   format: 'YYYY-MM-DD',
      // },
      // {
      //   label: 'End',
      //   name: 'end',
      //   id: 'end',
      //   placeholder: 'Select End Month',
      //   type: 'date',
      //   required: true,
      //   component: DateTime,
      //   picker: 'month',
      //   format: 'YYYY-MM-DD',
      // },
      {
        label: 'Start Time',
        name: 'start',
        id: 'start',
        placeholder: 'HH-MM',
        type: 'text',
        required: true,
        component: TimePicker,
      },
      {
        label: 'End Time',
        name: 'end',
        id: 'end',
        placeholder: 'HH-MM',
        type: 'text',
        required: true,
        component: TimePicker,
        customOnChange: ({ field, val, form }) => {
          const start = form?.values?.start;
          const end = val;
          const diff = timeDiffrence(start, end);
          const formatedDiff = `${diff?.hours}hr ${diff?.minutes}min`;
          form?.setFieldValue('duration', formatedDiff);
        },
      },
      {
        label: 'Shift Duration',
        name: 'duration',
        id: 'duration',
        placeholder: 'Shift Duration',
        type: 'text',
        // required: true,
        disabled: true,
        component: TextInput,
      },
      // {
      //   label: 'Employee',
      //   name: 'assignees',
      //   id: 'assignees',
      //   placeholder: 'Select Employees',
      //   type: 'select',
      //   options: [],
      //   component: SelectInput,
      //   closeMenuOnSelect: false,
      //   isMulti: true,
      //   fetchOptions: async (form) => {
      //     const resp = await searchApis({ api: 'employee' });
      //     return resp ? resp : [];
      //   },
      // },
      {
        label: 'Notes',
        name: 'notes',
        id: 'notes',
        placeholder: 'Write Notes',
        type: 'text',
        // required: true,
        divClasses: 'col-span-full',
        component: TextInput,
        isMultiline: true,
        maxLength: 300,
        rows: 3,
      },
    ],
  },
  breaks: {
    showAccordionHeader: false,
    fields: [
      {
        name: 'breaks',
        type: 'fieldArray',
        id: 'breaks',
        addButtonText: '+ Break',
        valueSchema: { name: '', start: '', end: '' },
        component: CustomFieldArray,
        divClasses: 'col-span-full',
        gridCols: 'grid-cols-3 py-2',
        label: <label className='text-xs xl:text-sm text-primary-90'>Breaks</label>,
        fields: [
          {
            name: 'name',
            id: 'name',
            label: 'Break',
            type: 'text',
            placeholder: 'Break',
            component: TextInput,
            customOnChange: ({ field, value, form }) => {
              if (value) {
                setIsBreak(true);
              } else {
                setIsBreak(false);
              }
            },
          },
          {
            name: 'start',
            id: 'start',
            label: 'Start Time',
            placeholder: 'HH-MM',
            type: 'date',
            required: isBreak,
            component: TimePicker,
          },
          {
            name: 'end',
            id: 'end',
            label: 'End Time',
            placeholder: 'HH-MM',
            type: 'date',
            required: isBreak,
            component: TimePicker,
          },
        ],
      },
    ],
  },
});

const AssigneeFields = {
  attendanceSettings: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Employee',
        name: 'assignees',
        id: 'assignees',
        placeholder: 'Employee',
        type: 'select',
        options: [],
        component: SelectInput,
        closeMenuOnSelect: false,
        isMulti: true,
        fetchOptions: async (form) => {
          const resp = await searchApis({ api: 'employee' });
          return resp ? resp : [];
        },
      },
    ],
  },
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Shift Management',
    path: '',
  },
];
const AssigneebreadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Shift Management',
    path: '/hr/attendance-settings',
  },
  {
    name: 'Assignees',
    path: '',
  },
];

export { addAttendanceSettingsFields, breadcrumbObj, AssigneeFields, AssigneebreadcrumbObj };
