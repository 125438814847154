import { endpoints } from 'constants/endpoints';
import Request from 'service/api-service/client';

export const getFoldersManagement = async () => {
  const resp = await Request({
    method: 'get',
    API: `report/folder`,
  });
  return resp;
};

export const postFoldersManagement = async (props = { data: '' }) => {
  const resp = await Request({
    method: 'post',
    API: `report/folder`,
    data: props?.data,
  });
  return resp;
};

//note : report api work is in progress(backend)
export const getReport = async (
  props = { start: null, end: null, customer_id: '', pos_id: '' },
) => {
  const resp = await Request({
    method: 'get',
    API: `report?start_date=${props?.start || ''}&end_date=${props?.end || ''}&customer_id=${
      props?.customer_id || ''
    }&pos_id=${props?.pos_id || ''}`,
  });
  return resp;
};

export const reportsManagement = async ({
  type = '',
  modules = '',
  start_date = '',
  end_date = '',
  branch_id = '',
  employee_id = '',
  category = '',
  product = '',
  page = 1,
}) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.report.list(
      type,
      modules,
      start_date,
      end_date,
      employee_id,
      branch_id,
      category,
      product,
      page,
    ),
  });
  return resp;
};

export const getReconciliationReport = async ({
  insurer = '',
  start_date = '',
  end_date = '',
  branch = '',
  product = '',
  employee = '',
}) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.report.reconciliation(insurer, start_date, end_date, branch, product, employee),
  });
  return resp;
};
