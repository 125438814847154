import { useNavigate } from 'react-router-dom';

const sidebarOptions = [
  {
    option: 'Change Password',
    name: 'PasswordForm',
  },
  // {
  //     option: "Company Setting",
  //     name: "ComapnyForm"
  // },
];

const Sidebar = ({ setForm, form }) => {
  const navigate = useNavigate();
  return (
    <div className='w-[15%] bg-white rounded-lg relative left-0'>
      {sidebarOptions.map((item) => (
        <button
          key={item.name}
          className={`my-1 bordered py-2 rounded-lg w-full text-center  hover:border-primary-90 ${
            form === item.name
              ? 'bg-primary-90 text-white hover:text-black '
              : 'bg-white hover:text-primary-90'
          } `}
          onClick={() => setForm(item.name)}
        >
          <h2>{item.option}</h2>
        </button>
      ))}
      <button
        onClick={() => navigate('/pos')}
        className=' my-1  bordered py-2 rounded-lg w-full text-center  hover:border-primary-90'
      >
        <h2>Go To Dashboard</h2>
      </button>
    </div>
  );
};

export default Sidebar;
