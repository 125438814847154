import { useCallback, useEffect, useState } from 'react';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { CustomModal, DateTime, IconButton, MultiEmail, TableComponent, Toast } from 'components';
import { elementData } from 'elements';
import { useStore } from 'store/context';
import { policyManagement, renewalPolicyManagement } from 'service/api-service';
import { rowCommonClass } from 'utils/common';
import { TableStatus } from 'components/table/TableStatus';
import Button from 'components/button';
import { LabelValue } from 'screens/leads/data';
import { NOTIFICATION_MSG } from 'constants';
export const statusList = [
  {
    label: <span className='w-full text-left'>Converted</span>,
    key: 'converted',
  },
  {
    label: <span className='w-full text-left'>Losses</span>,
    key: 'losses',
  },
];

const RenewalPolicies = ({ permissions, columns, query = '', dateFilterValues }) => {
  const [renewalPolicyData, setRenewalPolicyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState(false);
  const [followUp, setFollowup] = useState(null);
  const [opened, dispatch] = useStore((state) => state.dialerOpened);

  const toggleDialer = useCallback(
    (e, contact) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (opened) {
        dispatch({ type: 'DIALER_CLOSE' });
      } else {
        dispatch({ type: 'DIALER_OPEN', contact });
      }
    },
    [opened, dispatch],
  );

  const toggleModal = () => {
    setModel((prev) => ({ ...prev, model: !prev?.model }));
  };

  const handleSelectFollowUp = ({ val }) => {
    setFollowup(val);
  };

  const fetchData = async ({ q = '', page = 1 } = {}) => {
    setLoading(true);
    const resp = await renewalPolicyManagement({
      method: 'get',
      page: page,
      q: q,
      customDate: dateFilterValues,
    });
    if (resp?.data) {
      setRenewalPolicyData(resp?.data?.data);
    }
    setLoading(false);
  };

  const handlePagination = (pageNum) => {
    if (pageNum?.current) {
      fetchData({ page: pageNum?.current });
    }
  };

  const onStatusChange = useCallback(async (e, record) => {
    setLoading(true);
    const status = e?.key;
    if (status === 'converted') {
      const resp = await policyManagement({
        data: { status },
        method: 'patch',
        id: record?.id,
      });
      if (resp?.status) {
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.data?.msg || 'Policy updated Successfully',
        );
        fetchData();
      }
    } else {
      setModel({ model: true, id: record?.id });
    }

    setLoading(false);
  }, []);

  const handleSubmitFollowup = async () => {
    const resp = await policyManagement({
      data: { status: 'losses', follow_up: followUp },
      method: 'patch',
      id: model?.id,
    });
    if (resp?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Policy updated Successfully');
      setModel({ model: false, id: null });
      fetchData();
    }
  };

  useEffect(() => {
    fetchData({ q: query });
  }, [query, dateFilterValues]);

  return (
    <div className='mt-4'>
      <TableComponent
        onChange={handlePagination}
        loading={loading}
        scroll={{
          x: columns?.length > 8 ? 3000 : 500,
        }}
        getRowClassName={rowCommonClass}
        columns={[
          ...columns,
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => {
              return (
                <div className='w-36 leading-normal text-x'>
                  <TableStatus
                    permissions={permissions}
                    id={elementData.policy.permissions.update}
                    onChange={onStatusChange}
                    record={record}
                    items={statusList}
                    status={status}
                  />
                </div>
              );
            },
          },
          {
            title: <p className='mr-3'> Action</p>,
            key: 'action',
            align: 'right',
            render: (_, record) => (
              // <MultiEmail
              //   text="Send Reminder"
              //   mailSubject="Renewal Reminder"
              //   titleClasses="!text-x border-none flex items-center !bg-[#DCFDFD] !text-[#41CAC8] !font-semibold !px-4 rounded !py-2"
              //   emailData={record?.customer?.email_address}
              //   summary={`Hello ${record?.customer?.name},\nHope you are doing great. We are just reminding you about your ${record?.product_type?.name} Insurance renewal. The renewal date is ${record?.renewal}. If you have any questions, please feel free to reach out to us.`}
              // />
              <div className='flex gap-2 justify-end'>
                <IconButton
                  title='Send Reminder'
                  permissions={permissions}
                  className='flex justify-center items-start w-6 h-6 rounded-full border group disabled:cursor-not-allowed border-red-60'
                  id={elementData.policy.permissions.view}
                >
                  <MultiEmail
                    text={<MailOutlined className='text-red-60 group-disabled:text-grey' />}
                    button={false}
                    titleClasses={'underline cursor-pointer'}
                    emailData={record?.customer?.email_address}
                    mailSubject='Renewal Reminder'
                    summary={`Hello ${record?.customer?.name},\nHope you are doing great. We are just reminding you about your ${record?.product_type?.name} Insurance renewal. The renewal date is ${record?.renewal}. If you have any questions, please feel free to reach out to us.`}
                  />
                </IconButton>
                <IconButton
                  title='Call'
                  permissions={permissions}
                  className='flex justify-center items-center w-6 h-6 rounded-full border group disabled:cursor-not-allowed border-primary-90'
                  id={elementData.policy.permissions.view}
                  onClick={(e) => toggleDialer(e, record?.customer?.contact_number)}
                >
                  <PhoneOutlined className='text-primary-90 group-disabled:text-grey' />
                </IconButton>
              </div>
            ),
          },
        ]}
        dataSource={renewalPolicyData?.result}
        noDataAction={fetchData}
        pageSize={10}
        total={renewalPolicyData?.total_page}
        defaultPage={renewalPolicyData?.current_page}
      />
      <CustomModal
        open={model?.model}
        closable={true}
        onCancel={toggleModal}
        footer={
          <div className='flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleModal}
            />
            <Button
              text='Submit'
              variant='primary'
              classes={`ml-6 px-8 bg-black`}
              onClick={handleSubmitFollowup}
              loading={loading}
            />
          </div>
        }
        width={450}
      >
        <LabelValue
          vertical={true}
          divClasses='my-10'
          label='Follow Up Date'
          value={
            <DateTime
              type='search'
              placeholder='Select Follow up'
              field={{ name: 'follow_up' }}
              customOnChange={handleSelectFollowUp}
              divClasses='w-full bg-white'
              onIconClick={() => setFollowup(null)}
            />
          }
        />
      </CustomModal>
    </div>
  );
};

export default RenewalPolicies;
