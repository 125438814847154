import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import { Button, message, Spin, Tooltip } from 'antd';
import { Send } from 'assets/icons';
import { TextInput, UserInitialIcon } from 'components';
import { formatDate } from 'utils';
import './style.css';
import { Context } from 'store/context';
import { getLead, postLead } from 'service/api-service';

const LeadDiscussion = () => {
  const { id } = useParams();
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [data, setData] = useState([]);

  const getList = async () => {
    setLoading(true);
    const resp = await getLead({ lead_id: id });
    if (resp?.data?.status) {
      setData(resp?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) getList();
  }, []);

  const onSendClick = async (data) => {
    if (loading || !comment) return;
    setLoading(true);
    const payload = {
      comment: comment,
      user_id: user?.id,
    };
    const resp = await postLead({ data: payload, lead_id: id });
    if (resp?.data?.status) {
      setComment('');
      getList();
    } else {
      message.error(resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const getSendIcon = (value) => {
    return (
      <Button
        type='text'
        className='flex justify-end items-end'
        disabled={!value}
        icon={<Send svgClass={` ${value ? 'fill-primary' : 'fill-grey-90'} w-5 h-5`} />}
      />
    );
  };

  const onChange = (e) => setComment(e.target.value);

  const renderList = (item, index) => (
    <div key={index}>
      <span className='text-x text-grey-90'>
        {formatDate({
          dateString: item?.updated,
          toUtc: false,
          format: 'DD MMMM, YYYY hh:mm A',
        })}
      </span>
      <div
        className={`grid grid-flow-col justify-start gap-4 my-1 items-center bg-grey-light p-2 rounded-md `}
        // onClick={onClick}
      >
        {/* <button className={`${backgroundIs} rounded-full h-6 w-6`} title={item.user_id}>
          <span className="text-white table-cell text-center h-7 w-7 text-x align-middle font-medium">
            {getNameLetter({ name: item.user_id })}
          </span>
        </button> */}
        <Tooltip title={item.user_id}>
          <div>
            <UserInitialIcon name={item.user_id.name} />
          </div>
        </Tooltip>
        <span className='break-all'> {item.comment}</span>
      </div>
    </div>
  );

  return (
    <div className='col-span-full border-l border-grey-40 p-2 relative w-96 '>
      <h2 className='font-semibold text-sm mb-2 border-b border-grey-40 py-2'>Comments</h2>
      <div className='col-span-full overflow-y-auto ' id='chat-box'>
        {loading ? <Spin tip='Loading' /> : data.map(renderList)}
      </div>
      <div className='col-span-full'>
        <TextInput
          divClasses={'w-[368px]'}
          field={{ name: 'comment', value: comment, onChange }}
          placeholder='Type message here'
          rightIcon={
            loading ? <LoadingOutlined className='text-primary' spin={true} /> : getSendIcon
          }
          onIconClick={onSendClick}
        />
      </div>
    </div>
  );
};

export default LeadDiscussion;
