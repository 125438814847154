import {
  FormContainer,
  SelectInput,
  DateTime,
  CustomRadio,
  TextInput,
  Checkbox,
  Uploadbox,
  Toast,
} from 'components';
import Button from 'components/button';
import { NOTIFICATION_MSG } from 'constants';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLeads } from 'screens/leads/store';
import { loginInquiry, submitOnlineQuote } from 'service/api-service';
import { prepareFormFieldsData } from 'utils';
import { formStyle } from 'utils/common';
import { useShallow } from 'zustand/react/shallow';

const deathBenifits = (value) => {
  if (value === 'Income dep' || value === 'Income') {
    return { show: true, value: 'Income', formValue: 'Income dep' };
  } else if (value === 'Lump-Sum-Income dep' || value === 'Lump-Sum-Income dep') {
    return { show: true, value: 'Lump-Sum-Income', formValue: 'Lump-Sum-Income dep' };
  } else if (value === 'Increasing Income dep' || value === 'Increasing Income dep') {
    return { show: true, value: 'Increasing Income', formValue: 'Increasing Income dep' };
  } else {
    return { show: false, value: value, formValue: value };
  }
};
const lifeCover = (value) => {
  if (value === 'Life Plus adhb' || value === 'Life Plus') {
    return { adbh: true, ci: false, value: 'Life Plus', formValue: 'Life Plus adhb' };
  } else if (value === 'All in One adhb CIBenefit' || value === 'All In One') {
    return { adbh: true, ci: true, value: 'All In One', formValue: 'All in One adhb CIBenefit' };
  } else if (value === 'Life and Health CIBenefit' || value === 'Life and Health') {
    return {
      adbh: false,
      ci: true,
      value: 'Life and Health',
      formValue: 'Life and Health CIBenefit',
    };
  } else {
    return { adbh: false, ci: false, value: value, formValue: value };
  }
};

const IciciProtectForm = ({
  formData,
  setValues,
  insurer,
  setSelectedProduct,
  isEditing,
  submitProduct,
  addQuote,
  currProduct,
  // submitQuote,
}) => {
  const maxDate = dayjs().subtract(18, 'years');
  const [wantExit, setWantExit] = useState(false);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const { setButtonPressed, setIsOnlineProduct, client } = useLeads(
    useShallow((state) => ({
      setButtonPressed: state.setButtonPressed,
      setIsOnlineProduct: state.setIsOnlineProduct,
      client: state.createLead.client,
    })),
  );

  const initials = {
    first_name: (formData?.first_name || client?.name?.split(' ')[0]) ?? '',
    last_name: (formData?.last_name || client?.name?.split(' ')[1]) ?? '',
    dob: (formData?.dob || client?.date_of_birth ? dayjs(client?.date_of_birth) : '') ?? '',
    gender: formData?.gender ?? '',
    marital_status: formData?.marital_status ?? '',
    policyholder_fname: formData?.policyholder_fname ?? '',
    policyholder_lname: formData?.policyholder_lname ?? '',
    policyholder_gender: formData?.policyholder_gender ?? '',
    is_policyholder: formData?.is_policyholder === '1' ? 'yes' : 'no',
    staff: formData?.staff === '1' ? true : false,
    age_proof: formData?.age_proof ?? '',
    service_tax: formData?.service_tax === '1' ? true : false,
    ProductType: 'TRADITIONAL',
    ModeOfPayment: formData?.product?.Product?.ModeOfPayment ?? '0',
    ModalPremium: '0',
    AnnualPremium: '0',
    Term: formData?.product?.Product?.Term ?? '0',
    Term1: '0',
    DeathBenefit: formData?.product?.Product?.DeathBenefit ?? '0',
    PremiumPaymentTerm: formData?.product?.Product?.PremiumPaymentTerm ?? '0',
    PortfolioStrategy: '',
    RGF: '0',
    AnnuityOption: '',
    PensionFrequency: '',
    AnnuityYears: '0',
    SpouseAge: '0',
    ChildAge: '0',
    ChildMaturityAge: '22',
    HospitalCarePlan: '',
    UnitOfCoverage: '0',
    SpouseOpted: '0',
    SpouseName: '',
    Option: '0',
    Tobacco: '0',
    HomeLoan: '0',
    SalesChannel: '0',
    RGFTerm: '0',
    Objective: '',
    UserGroup: '',
    LoanTenure: '0',
    LoanAmount: '0',
    AnnuityPayoutFrequency: '',
    PayoutTerm: formData?.product?.Product?.PayoutTerm ?? '',
    PremiumPaymentOption: formData?.product?.Product?.PremiumPaymentOption ?? '',
    JointLifeDetails: '',
    LifeTime: '',
    adhb: formData?.product?.Product?.ADHB ?? '',
    LifeCoverOption: lifeCover(formData?.product?.Product?.LifeCoverOption)?.formValue ?? '',
    CIBenefit: formData?.product?.Product?.CIBenefit ?? '',
    DeathBenefitOption:
      deathBenifits(formData?.product?.Product?.DeathBenefitOption)?.formValue ?? '',
    RIB: 'false',
    isKeralaCess: 'false',
    isDTAA: '',
    CountryName: '',
    TobaccoJL: '0',
    LumpsumPercentage: formData?.product?.Product?.LumpsumPercentage ?? '0',
    IPSDiscount: 'false',
    Occupation: formData?.product?.Product?.Occupation ?? '',
    UID: '37608',
    BenefitIllustration: '',
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const updatedData = {
      first_name: data?.first_name ?? '',
      last_name: data?.last_name ?? '',
      dob: dayjs(data?.dob ? data?.dob : maxDate).format('YYYY-MM-DD'),
      gender: data?.gender ?? '',
      marital_status: data?.marital_status ?? '',
      policyholder_fname: data?.policyholder_fname ?? '',
      policyholder_lname: data?.policyholder_lname ?? '',
      policyholder_gender: data?.policyholder_gender ?? '',
      is_policyholder: data?.is_policyholder === 'yes' ? '1' : '0',
      staff: data?.staff ? '1' : '0',
      age_proof: data?.age_proof ?? '',
      service_tax: data?.service_tax ? '1' : '0',
      product: {
        Product: {
          ProductType: 'TRADITIONAL',
          ModeOfPayment: data?.ModeOfPayment ?? '0',
          ModalPremium: '0',
          AnnualPremium: '0',
          Term: data?.Term ?? '0',
          Term1: '0',
          DeathBenefit: data?.DeathBenefit ?? '0',
          PremiumPaymentTerm: data?.PremiumPaymentTerm ?? '0',
          PortfolioStrategy: '',
          RGF: '0',
          AnnuityOption: '',
          PensionFrequency: '',
          AnnuityYears: '0',
          SpouseAge: '0',
          ChildAge: '0',
          ChildMaturityAge: '22',
          HospitalCarePlan: '',
          UnitOfCoverage: '0',
          SpouseOpted: '0',
          SpouseName: '',
          Option: '0',
          Tobacco: '0',
          HomeLoan: '0',
          SalesChannel: '0',
          RGFTerm: '0',
          Objective: '',
          UserGroup: '',
          LoanTenure: '0',
          LoanAmount: '0',
          AnnuityPayoutFrequency: '',
          PremiumPaymentOption: data?.PremiumPaymentOption ?? '',
          PayoutTerm: deathBenifits(data?.DeathBenefitOption)?.show ? data?.PayoutTerm : '',
          JointLifeDetails: '',
          LifeTime: '',
          ADHB: lifeCover(data?.LifeCoverOption)?.adbh ? data?.adhb : '0',
          LifeCoverOption: lifeCover(data?.LifeCoverOption)?.value ?? '',
          CIBenefit: lifeCover(data?.LifeCoverOption)?.ci ? data?.CIBenefit : '0',
          DeathBenefitOption: deathBenifits(data?.DeathBenefitOption)?.value ?? '',
          RIB: 'false',
          isKeralaCess: 'false',
          isDTAA: '',
          CountryName: '',
          TobaccoJL: '0',
          LumpsumPercentage: data?.LumpsumPercentage ?? '0',
          IPSDiscount: 'false',
          Occupation: data?.Occupation ?? '',
          UID: '37608',
          BenefitIllustration: '',
        },
      },
      company: 'icici pvt. ltd.',
    };

    const finalData = {
      ...updatedData,
      product: {
        Product: {
          ...updatedData?.product.Product,
          ProductType: 'TRADITIONAL',
          ProductName: currProduct?.name,
          ProductCode: currProduct?.product_code,
        },
      },
    };

    if (token) {
      try {
        const resp = await submitOnlineQuote({ data: finalData, token });
        if (resp?.status) {
          if (resp?.data?.status) {
            Toast('success', NOTIFICATION_MSG.success, resp?.data?.data?.ErrorMessage);
            submitProduct({ ...resp?.data?.data, ...finalData }, wantExit);
          } else {
            Toast(
              'info',
              NOTIFICATION_MSG.info,
              resp?.data?.msg?.ErrorMessage || 'Please try again',
            );
          }
        } else {
          Toast('info', NOTIFICATION_MSG.info, resp?.message || 'Please try again');
        }
      } catch (error) {
        Toast('info', NOTIFICATION_MSG.info, 'Wrong details entered!');
      }
    }
    setLoading(false);
  };
  const quoteCustomerFields = {
    'Personal Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'First Name',
          name: 'first_name',
          id: 'first_name',
          placeholder: 'First Name',
          type: 'text',
          component: TextInput,
          required: true,
        },
        {
          label: 'Last Name',
          name: 'last_name',
          id: 'last_name',
          placeholder: 'Last Name',
          type: 'text',
          component: TextInput,
          required: true,
        },
        {
          label: 'DOB',
          name: 'dob',
          id: 'dob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          showTime: false,
          defaultValue: maxDate,
          required: true,
          component: (props) => (
            <DateTime defaultValue={maxDate} {...props} disabledDate={maxDate} />
          ),
        },
        {
          label: 'Gender',
          name: 'gender',
          id: 'gender',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' },
            { value: 'Transgender', label: 'Transgender' },
          ],
        },
        {
          label: 'Marital Status',
          name: 'marital_status',
          id: 'marital_status',
          required: true,
          type: 'select',
          component: SelectInput,
          options: [
            { value: 'married', label: 'Married' },
            { value: 'single', label: 'Single' },
          ],
        },
      ],
    },
    'Policy Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Do you already have policy?',
          name: 'is_policyholder',
          id: 'is_policyholder',
          type: 'radio',
          divClasses: 'col-span-full mt-4',
          component: CustomRadio,
          options: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ],
          defaultValue: 'no',
        },
        {
          dependentArray: true,
          dependent_on: 'is_policyholder',
          dependent_value: 'yes',
          label: 'Policy Holder First Name',
          name: 'policyholder_fname',
          id: 'policyholder_fname',
          placeholder: 'Policy Holder First Name',
          type: 'text',
          component: TextInput,
        },
        {
          dependentArray: true,
          dependent_on: 'is_policyholder',
          dependent_value: 'yes',
          label: ' Policy Holder Last Name',
          name: 'policyholder_lname',
          id: 'policyholder_lname',
          placeholder: 'Policy Holder Last Name',
          type: 'text',
          component: TextInput,
        },
        {
          dependentArray: true,
          dependent_on: 'is_policyholder',
          dependent_value: 'yes',
          label: ' Policy Holder Marital Status',
          name: 'policyholder_gender',
          id: 'policyholder_gender',
          type: 'select',
          component: SelectInput,
          options: [
            { value: 'married', label: 'Married' },
            { value: 'unmarried', label: 'UnMarried' },
          ],
        },
      ],
    },
    'Other Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Age Proof',
          name: 'age_proof',
          id: 'age_proof',
          // divClasses: 'col-span-2',
          component: Uploadbox,
          dragger: false,
          accept: ['.png, .jpeg, .jpg'],
        },
        {
          label: ' Staff',
          name: 'staff',
          id: 'staff',
          placeholder: 'Staff',
          type: 'Checkbox',
          divClasses: ' mt-5',
          component: Checkbox,
        },
        {
          label: ' Service Tax',
          name: 'service_tax',
          id: 'service_tax',
          placeholder: 'service_tax',
          divClasses: ' mt-5',
          type: 'Checkbox',
          component: Checkbox,
        },
      ],
    },
    'Product Details': {
      showAccordionHeader: false,
      fields: [
        {
          name: 'DeathBenefitOption',
          id: 'DeathBenefitOption',
          placeholder: 'Death Benefit Option',
          label: 'Death Benefit Option',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [
            { value: 'Income dep', label: 'Income' },
            { value: 'Lump-Sum', label: 'Lump-Sum' },
            { value: 'Increasing Income dep', label: 'Increasing Income' },
            { value: 'Lump-Sum-Income dep', label: 'Lump-Sum-Income' },
          ],
        },
        {
          // dependentArray: true,
          // dependent_on: 'DeathBenefitOption',
          // dependent_value: 'Lump-Sum-Income dep',
          name: 'LumpsumPercentage',
          id: 'LumpsumPercentage',
          placeholder: 'Lumpsum Percentage',
          label: 'Lumpsum Percentage',
          type: 'text',
          component: TextInput,
        },
        {
          name: 'Term',
          required: true,
          id: 'Term',
          placeholder: 'Term',
          label: 'Term',
          type: 'text',
          component: TextInput,
        },
        {
          dependentArray: true,
          dependent_on: 'DeathBenefitOption',
          dependent_value: 'dep',
          name: 'PayoutTerm',
          id: 'PayoutTerm',
          placeholder: 'Payout Term',
          label: 'Payout Term',
          type: 'select',
          component: SelectInput,
          options: [
            { value: '10', label: '10 Years' },
            { value: '20', label: '20 Years' },
            { value: '30', label: '30 Years' },
          ],
        },
        {
          name: 'DeathBenefit',
          id: 'DeathBenefit',
          required: true,
          placeholder: 'Death Benefit',
          label: 'Death Benefit',
          type: 'text',
          component: TextInput,
        },

        {
          name: 'Occupation',
          id: 'Occupation',
          placeholder: 'Occupation',
          label: 'Occupation',
          required: true,
          type: 'select',
          component: SelectInput,
          options: [
            { value: 'SPVT', label: 'Salaried' },
            { value: 'BSEM', label: 'Self Employed' },
            { value: 'LSPRO', label: 'Others' },
            { value: 'SECA', label: 'Self Employed - CA' },
            { value: 'SEAR', label: 'Self Employed - Doctor' },
            { value: 'LSPRO', label: 'Self Employed - Architect' },
            { value: 'SEBU', label: 'Self Employed - Businessman' },
            { value: 'SECO', label: 'Self Employed - Consultant' },
            { value: 'SELA', label: 'Self Employed - Lawyer' },
            { value: 'SEOT', label: 'Self Employed - Others' },
            { value: 'HSWF', label: 'Housewife' },
            { value: 'AGRI', label: 'Agriculturist' },
            { value: 'STDN', label: 'Student' },
            { value: 'IPRU', label: 'ICICI Group Employee' },
            { value: 'RETD', label: 'Retired' },
            { value: 'PROF', label: 'Professional' },
          ],
        },
        {
          name: 'LifeCoverOption',
          id: 'LifeCoverOption',
          label: 'Life Cover Option',
          required: true,
          type: 'select',
          component: SelectInput,
          options: [
            { value: 'Life', label: 'Life' },
            { value: 'All in One adhb CIBenefit', label: 'All in One' },
            { value: 'Life Plus adhb', label: 'Life Plus' },
            { value: 'Life and Health CIBenefit', label: 'Life and Health' },
          ],
        },
        {
          dependentArray: true,
          dependent_on: 'LifeCoverOption',
          dependent_value: 'CIBenefit',
          name: 'CIBenefit',
          id: 'CIBenefit',
          placeholder: 'CI Benefit ',
          label: 'CI Benefit',
          type: 'number',
          component: TextInput,
        },
        {
          dependentArray: true,
          dependent_on: 'LifeCoverOption',
          dependent_value: 'adhb',
          name: 'adhb',
          id: 'ADHB',
          placeholder: 'ADHB ',
          label: 'ADHB',
          type: 'text',
          component: TextInput,
        },
        {
          name: 'ModeOfPayment',
          id: 'ModeOfPayment',
          label: 'Mode Of Payment',
          required: true,
          type: 'select',
          component: SelectInput,
          options: [
            { value: 'Yearly', label: 'Yearly' },
            { value: 'Half - Yearly', label: 'Half-Yearly' },
            { value: 'Monthly ', label: 'Monthly ' },
          ],
        },
        {
          name: 'PremiumPaymentOption',
          id: 'PremiumPaymentOption',
          label: 'Premium Payment Option',
          type: 'select',
          required: true,
          component: SelectInput,
          options: [
            { value: 'Regular Pay', label: 'Regular Pay' },
            { value: 'Limited Pay', label: 'Limited Pay' },
            // { value: 'Limited Pay &  60-age', label: "Limited Pay 60" },
            // { value: 'Limited Pay & Term - 5', label: "Limited Pay Term" },
            // { value: ' Whole life &    60 - age', label: "Limited Whole life" },
            // { value: 'Regular Pay & Whole life', label: "Regular Whole life" },
            // { value: 'Whole life & Ten Pay', label: "Limited Whole life 10" },
            // { value: 'Single Pay', label: "Single Pay" },
            // { value: 'Life', label: "Life" },
          ],
        },
        {
          name: 'PremiumPaymentTerm',
          id: 'PremiumPaymentTerm',
          placeholder: 'Premium Payment Term',
          label: 'Premium Payment Term',
          type: 'text',
          component: TextInput,
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: quoteCustomerFields,
    defaultValues: initials,
  });
  const handleLogin = async () => {
    const resp = await loginInquiry();
    if (resp?.status) {
      setToken(resp?.data?.token);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  useEffect(() => {
    handleLogin();
  }, []);

  return (
    <div className='bg-white'>
      <FormContainer
        returnFormatted={false}
        accordionClass={formStyle}
        fields={finalFields}
        initialValues={initialValues}
        onSubmit={onSubmit}
        customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, values }) => {
          return formData?.count ? (
            <></>
          ) : (
            // addQuote ?
            <div className='w-full flex justify-end mt-4 gap-4'>
              <Button
                text='Cancel'
                variant='primary'
                type='button'
                classes='!bg-[#C3C3C3]  !text-black px-6 '
              />
              <Button
                disabled={!isValid}
                text={'Save & Exit'}
                onClick={() => {
                  submitForm();
                  setButtonPressed('saveAndNext');
                  setIsOnlineProduct(true);
                }}
                classes={` px-8 !bg-[#069FBE]`}
              />
              <Button
                disabled={!isValid}
                text={'Next'}
                loading={loading}
                onClick={() => {
                  submitForm();
                  setButtonPressed('next');
                  setIsOnlineProduct(true);
                }}
                variant='primary'
                classes={` px-8 !bg-[#12B841] `}
              />
            </div>
            // :
            //     <SubmitButtons
            //         show={true}
            //         classes='z-10'
            //         submit={submitForm}
            //         reset={resetForm}
            //         setWantExit={setWantExit}
            //     />
          );
        }}
      />
    </div>
  );
};

export default {
  Form: IciciProtectForm,
};
