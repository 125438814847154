import { PhoneNumberInput, TextInput } from 'components';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Mobile',
    dataIndex: 'mobile',
    key: 'mobile',
  },
  {
    title: 'Id Card No.',
    dataIndex: 'id_card',
    key: 'id_card',
  },
];

//add Contact fields
const addContactFields = {
  'Insurer Contact Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Contact Name',
        name: 'name',
        id: 'name',
        placeholder: 'Contact Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 40,
      },
      {
        label: 'Email Address',
        name: 'email',
        id: 'email',
        placeholder: 'Email Address',
        type: 'text',
        required: true,
        component: TextInput,
        // maxLength: 60,
      },
      {
        label: 'Mobile',
        name: 'mobile',
        id: 'mobile',
        placeholder: 'Email Mobile',
        type: 'number',
        maxLength: 10,
        component: TextInput,
      },
      {
        label: 'Id Card Number',
        name: 'id_card',
        id: 'id_card',
        placeholder: 'Id Card Number',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
    ],
  },
};

export { columns, addContactFields };
