/** external deps */
import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import dayjs from 'dayjs';
import { AreaChartOutlined, TableOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';
/** internal deps */
import { TableSvg } from 'assets/icons';
import { BranchFilter, TextFilter } from 'components';
import { targetData } from './data';
import './style.css';
import { getTotalTargets } from 'service/api-service';

const renderData = (item, index) => (
  <div
    key={index}
    className='border border-grey-80 rounded-lg px-3 py-4 flex items-center gap-4 min-w-[160px]'
  >
    <div className={`${item.bg} p-2 rounded-md`}> {item.icon}</div>
    <Link
      to={
        item?.desc === 'Total Targets'
          ? '/goals'
          : item?.desc === 'Quotations'
          ? '/quotation'
          : item?.desc === 'Inquiries'
          ? '/lead'
          : item?.desc === 'Claims'
          ? 'targets'
          : item?.desc === 'Policies'
          ? '/policy'
          : null
      }
    >
      <div>
        <span className='font-semibold text-xs '>{item?.title || 0}</span>
        <span className='text-grey-90 text-xxs block font-medium'>{item?.desc}</span>
      </div>
    </Link>
  </div>
);

const TargetOverview = ({ setActiveTabKey, employee_id }) => {
  const [target, setTarget] = useState(targetData);
  const [percentage, setPercentage] = useState(0);
  const [showGraph, setShowGraph] = useState(false);
  const [branchId, setBranchId] = useState('');
  const [graph, setGraph] = useState({});

  const fetchTarget = async ({ start, end }) => {
    const { status, data } = await getTotalTargets({
      start,
      end,
      id: employee_id,
      branch_id: branchId,
    });
    if (status && data) {
      const updateData = target?.map((val) => ({ ...val, title: data?.data[val?.key] }));
      setPercentage((data?.data?.policy / data?.data?.total_target) * 100 || 0);
      setTarget(updateData);
      setGraph(data?.data);
    } else {
      console.log('error');
    }
  };

  const handleBranchSelect = (value) => {
    setBranchId(value);
  };

  const onFilterSelect = (event) => {
    let start = dayjs();
    let end = dayjs().add(1, 'month');
    switch (event) {
      case 'monthly':
        start = dayjs();
        end = dayjs().add(1, 'month');
        break;
      case 'quarterly':
        start = dayjs();
        end = dayjs().add(3, 'month');
        break;
      case '6 month':
        start = dayjs();
        end = dayjs().add(6, 'month');
        break;
      case 'yearly':
        start = dayjs();
        end = dayjs().add(1, 'year');
        break;
      default:
        start = dayjs();
        end = dayjs().add(1, 'month');
    }
    fetchTarget({ start, end });
  };

  useEffect(() => {
    fetchTarget({ start: dayjs(), end: dayjs().add(1, 'month') });
  }, [branchId]);

  const graphData = {
    labels: target?.map(({ desc }) => desc),
    datasets: [
      {
        label: '',
        data: target?.map(({ title }) => +title),
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(153, 102, 255, 0.5)',
          'rgba(255, 159, 64, 0.5)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <div className='flex justify-between items-center'>
        <h2 className='font-semibold text-s'>Targets</h2>
        <div className='flex gap-2'>
          {!setActiveTabKey && <BranchFilter onSelect={handleBranchSelect} />}
          <div className='bg-grey-70 grid place-content-center p-2 rounded-md'>
            <TextFilter
              filters={['monthly', 'quarterly', '6 month', 'yearly']}
              onFilterSelect={onFilterSelect}
            />
          </div>
          {setActiveTabKey && (
            <button
              className='bg-grey-70 rounded-md p-2 opacity-80 hover:opacity-100 transition-all duration-300'
              onClick={() => setActiveTabKey('4')}
            >
              <TableSvg className='w-4 fill-black' />
            </button>
          )}

          <button
            className='bg-grey-70 rounded-md px-2 py-1 opacity-80 hover:opacity-100 transition-all duration-300'
            onClick={() => setShowGraph((pre) => !pre)}
          >
            {!showGraph ? <AreaChartOutlined /> : <TableOutlined />}
            {/* <BarGraphSvg className=" fill-black w-5" /> */}
          </button>
        </div>
      </div>
      {/* checks if graph fields has a value greater than 0 */}
      {showGraph &&
        (Object.values(graph).every((item) => !item) ? (
          <div className='grid items-center justify-center min-h-[80px] bg-white my-2 p-2 rounded-lg'>
            <Empty description={false} imageStyle={{ height: '60px' }} />
            <h2 className='font-medium text-grey-90 text-center'>No Data</h2>
          </div>
        ) : (
          <div className='m-auto h-72 w-2/3 flex items-center justify-center'>
            <Pie data={graphData} />
          </div>
        ))}
      {!showGraph && (
        <>
          <div className='flex items-center mt-4 p-2.5 px-6 shadow-custom rounded-md bg-primary-light/[0.7] w-fit'>
            <div className='flex items-center gap-2'>
              <svg viewBox='0 0 36 36' className='circular-chart orange w-12'>
                <path
                  className='circle-bg'
                  d='M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831'
                />
                <path
                  className='circle'
                  strokeDasharray={`${percentage?.toFixed()}, 100`}
                  d='M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831'
                />
                <text x='18' y='22.35' className='percentage font-bold'>
                  %
                </text>
              </svg>

              <span className='font-bold text-[30px] pr-2'>{percentage?.toFixed(2)}</span>
            </div>

            <span className='text-xxs text-grey-90'>% Target Achieved</span>
          </div>
          <div className='mt-2 grid gap-2 grid-cols-3 shadow-custom p-3.5 rounded-xl'>
            {target?.map(renderData)}
          </div>
        </>
      )}
    </>
  );
};

export default TargetOverview;
