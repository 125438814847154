import { Checkbox, SelectInput, TextInput } from 'components';

const addLeaveTypeFields = {
  'Leave Type': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Leave Type',
        name: 'label',
        id: 'label',
        placeholder: 'Leave Type',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
        divClasses: 'col-span-full',
      },
      {
        label: 'Number Of Days',
        name: 'days',
        id: 'days',
        placeholder: 'Number of Days',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 30,
        divClasses: 'col-span-full',
      },
      {
        label: 'Notes',
        name: 'note',
        id: 'note',
        placeholder: 'Notes',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        rows: 5,
        divClasses: 'col-span-full',
      },
      {
        label: 'Document Required',
        name: 'doc_req',
        id: 'doc_req',
        type: 'checkbox',
        component: Checkbox,
        divClasses: 'col-span-full',
      },
      {
        dependent: true,
        dependent_on: 'doc_req',
        dependent_value: true,
        label: 'Document Name',
        name: 'docs',
        id: 'docs',
        placeholder: ' Document Name',
        type: 'select',
        // required: true,
        component: SelectInput,
        checkbox: true,
        isMulti: true,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        options: [
          {
            label: 'Medical Certificate',
            value: 'Medical Certificate',
          },
          {
            label: 'Travelling Tickets',
            value: 'Travelling Tickets',
          },
          {
            label: 'Marriage Card',
            value: 'Marriage Card',
          },
          {
            label: 'Prescription',
            value: 'Prescription',
          },
          {
            label: 'Other',
            value: 'Other ',
          },
        ],
        divClasses: 'col-span-full',
      },
    ],
  },
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Leaves Type',
    path: '',
  },
];

export { addLeaveTypeFields, breadcrumbObj };
