/** external deps */
import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';

import './style.css';

const CustomRadio = ({ form, field, divClasses, checked, ...props }) => {
  const [value, setValue] = useState();

  const onChange = (e) => {
    const value = e.target.value;
    setValue(value);
    form?.setFieldValue(field?.name, value);
    field?.onChange(e);
    if (props?.customOnChange) {
      props?.customOnChange(field?.name, value);
    }
  };

  useEffect(() => {
    if (form?.initialValues?.[field?.name]) {
      // form?.setFieldValue(field?.name, props?.defaultValue);
      setValue(form?.initialValues?.[field?.name]);
    }
  }, [form?.initialValues?.[field?.name]]);

  return (
    <div className={divClasses}>
      <label
        className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xxs text-black ${
          props?.required ? 'required' : ''
        }`}
      >
        {props.label}
      </label>

      <Radio.Group
        defaultValue={props?.defaultValue}
        {...field}
        onChange={onChange}
        value={value}
        className={`flex ${props?.vertical ? 'flex-col' : 'flex-row'}`}
      >
        {props?.options.map((item, index) => {
          return (
            <Radio value={item?.value} key={index}>
              {item?.label}
            </Radio>
          );
        })}
      </Radio.Group>

      {form?.errors[field?.name] && form?.touched[field?.name] && (
        <div
          className={`bg-white last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
        >
          {form?.errors[field?.name]}
        </div>
      )}
    </div>
  );
};

export default CustomRadio;
