import { endpoints } from 'constants/endpoints';
import Request from './client';

export const getInsurerList = async ({ page = 1, query = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.insurer.list(`?page=${page}&q=${query}`),
  });
  return resp;
};
export const addInsurer = async ({ data = undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.insurer.create,
    data,
  });
  return resp;
};
export const editInsurer = async ({ id = '', data = undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.insurer.update(id),
    data,
  });
  return resp;
};
export const deleteInsurer = async ({ id = '', data = undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.insurer.delete(id),
    data,
  });
  return resp;
};

export const getInsurers = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.insurer.search,
  });
  return resp;
};

export const getInsurerContact = async ({ insurer_id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.insurer.contact(insurer_id),
  });
  return resp;
};
export const addInsurerContact = async ({ data = undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.insurer.create_contact,
    data,
  });
  return resp;
};
export const editInsurerContact = async ({ id = '', data = undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.insurer.update_contact(id),
    data,
  });
  return resp;
};
export const deleteInsurerContact = async ({ id = '', data = undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.insurer.delete_contact(id),
    data,
  });
  return resp;
};
