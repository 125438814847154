//  create-invoice api
import Request from './client';
import { endpoints } from 'constants/endpoints';

export const createInvoiceFunc = async (props) => {
  let { method, data } = props;
  const resp = await Request({
    method: method,
    data: data,
    API: endpoints.invoice.create,
  });
  return resp;
};

//  get invoices function:

export const getInvoices = async (props) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.invoice.getInvoices(props?.table_id || ''),
  });
  return resp;
};

export const deleteInvoices = async (props) => {
  const resp = await Request({
    method: 'delete',
    API: endpoints.invoice.delete(props?.id),
  });
  return resp;
};
