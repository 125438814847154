export const dummyData = [
  {
    policy_name: 'Term insurance',
    sell_by: 'Raj',
    product: 'insurance',
    Premium: '100',
  },
  {
    policy_name: 'Unit Linked Insurance Plans',
    sell_by: 'Mohit',
    product: 'insurance',
    Premium: '200',
  },
  {
    policy_name: 'Endowment plans',
    sell_by: 'Rohit',
    product: 'insurance',
    Premium: '300',
  },
  {
    policy_name: 'Retirement Plans',
    sell_by: 'Akarsh',
    product: 'insurance',
    Premium: '500',
  },
  {
    policy_name: 'Group life insurance',
    sell_by: 'Rajat',
    product: 'insurance',
    Premium: '230',
  },
  {
    policy_name: 'Moneyback policy',
    sell_by: 'Ashish',
    product: 'insurance',
    Premium: '460',
  },
  {
    policy_name: 'Whole life insurance',
    sell_by: 'Rahul',
    product: 'insurance',
    Premium: '190',
  },
];
