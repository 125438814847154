import { ArrowUpIcon } from 'assets/icons';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const DisplayCard = (props) => {
  const { text, icon, total, link, bg, percent = 0, showPercent = 2, filterValue, onClick } = props;
  const handleClick = () => {
    navigate(link);
  };
  const navigate = useNavigate();
  return (
    // <Link
    //   className='w-full'
    //   to={`/${link}`}
    //   // to={{
    //   //   pathname: `/${link}`,
    //   // }}
    // >
    <div
      onClick={onClick && handleClick}
      className={`group  space-x-0.5 px-2 py-2 h-[100px] rounded-md bg-white text-black shadow-darkmd`}
    >
      <div className='flex flex-row justify-between items-end'>
        <div className={`flex flex-col items-start gap-[2px] leading-[1.2]`}>
          <p className='text-grey 2xl:text-xs'>{text}</p>
          <p className={`text-xl font-semibold stats-dash`}>
            {total > 999 ? (total / 1000).toFixed(1) + 'K+' : total || 0}
          </p>
        </div>
        {showPercent == 2 && (
          <div
            // className={`text-left h-[40px] w-[40px] p-0.5 flex items-center justify-center rounded-m`}
            className={`font-semibold flex items-center gap-1 ${
              percent < 0 ? 'text-red-status' : 'text-green-60'
            }`}
          >
            {/* {icon} */}
            <ArrowUpIcon
              svgClass={`w-[8px] ${
                percent < 0 ? 'stroke-red-status  rotate-180' : 'stroke-green-60'
              }`}
            />
            {Math.abs(percent?.toFixed(1))}%
          </div>
        )}
      </div>
      {filterValue && link && !onClick && (
        <Link className='w-full !m-0' to={`/reports?filterModule=${filterValue}`}>
          <p className='text-primary-90 mt-[6px]'>View Reports</p>
        </Link>
      )}
      {!filterValue && link && !onClick && (
        <Link className='w-full !m-0' to={link}>
          <p className='text-primary-90 mt-[6px]'>View Reports</p>
        </Link>
      )}
      {!link && !onClick && (
        <Link className='w-full !m-0'>
          <p className='text-primary-90 mt-[6px]'></p>
        </Link>
      )}
    </div>
    // </Link>
  );
};

export default DisplayCard;
