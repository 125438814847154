/** external deps */
import { Select } from 'antd';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
/** internal deps */
import { RightArrowHead } from 'assets/icons';
import { Context } from 'store/context';
import LeadsTab from '../DashboardTab/DashboardTabs';
import AllLeadsTab from './DashboardAssets/AllLeadsTab';
import { searchApis } from 'service/api-service';
import './style.css';
import ProposalTable from './DashboardAssets/ProposalTable';

const AllLeads = () => {
  const {
    leads: { list: leads, updateLeads, loading: inquiryLoading, total_lead },
  } = useContext(Context);

  const [employees, setEmployees] = useState([]);
  const [key, setActiveTabKey] = useState('Draft');
  const { user } = useContext(Context);
  const [employeeID, setemployeeID] = useState(user?.position === 'employee' ? user?.id : null);

  const getEmployeeData = (value) => {
    setemployeeID(value);
  };
  useEffect(() => {
    updateLeads({ page: 1, employee_id: employeeID, status: key });
  }, [employeeID, key]);

  const tabs = [
    {
      id: '1',
      key: 'Draft',
      label: `Drafted`,
      Component: AllLeadsTab,
      props: {
        badgeCount: total_lead?.draft_leads,
        leads: leads,
        inquiryLoading: inquiryLoading,
      },
    },
    {
      id: '2',
      key: 'Quoted',
      label: `Quoted`,
      Component: AllLeadsTab,
      props: {
        badgeCount: total_lead?.quoted_leads,
        leads: leads,
        inquiryLoading: inquiryLoading,
      },
    },
    {
      id: '4',
      key: 'Proposals',
      label: `Proposals`,
      Component: ProposalTable,
      props: {
        badgeCount: total_lead?.proposals,
        leads: leads,
        inquiryLoading: inquiryLoading,
      },
    },
    {
      id: '3',
      key: 'Converted',
      label: `Converted`,
      Component: AllLeadsTab,
      props: {
        badgeCount: total_lead?.converted_leads,
        leads: leads,
        inquiryLoading: inquiryLoading,
      },
    },
  ];

  const fetchOptions = async () => {
    const resp = await searchApis({ api: 'employee' });
    const data = resp?.data?.data;
    const options = data?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
    setEmployees(options);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <div id='lead-tab' className='flex-col col-span-full justify-between hidden md:flex '>
      <div className='flex flex-row justify-between items-start relative '>
        <div className='text-sm font-semibold leading-6 pb-2 lg:absolute lg:mt-[7px] pl-0'>
          Recent Leads
        </div>
      </div>
      <LeadsTab
        // onChange={onchangeTab}
        items={tabs}
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
        className='pl-4 bg-white'
        extraRightRender={
          <div id='dashboard' className='flex gap-2 justify-end items-center w-fit'>
            {user?.position === 'Admin' && (
              <Select
                showSearch={true}
                variant='borderless'
                style={{ width: 158, backgroundColor: '#F5F7FB' }}
                placeholder={
                  <span style={{ fontWeight: 400, fontSize: '14px', color: '#000' }}>
                    Select Employee
                  </span>
                }
                options={employees}
                onChange={getEmployeeData}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                suffixIcon={<RightArrowHead svgClass='rotate-90 w-2.5 h-1.5 fill-black' />}
              />
            )}
            <div className='text-[#0780BE] px-2 py-2 text-xxs'>
              <Link to='/lead'>View All</Link>
            </div>
          </div>
        }
      />
    </div>
  );
};
export default AllLeads;
