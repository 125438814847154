/**
 *
 * @param {{
 * name: string;
 * primary_email_address: string;
 * contact_number: string;
 * date_of_birth:string;
 * other_phone: string;
 * id_card_number: string;
 * passport_number: string;
 * passport_issuing_date: string;
 * id_card_type: string;
 * passport_issuing_country: string;
 * nationality: string;
 * contact_type: string;
 * lead_source: string;
 * source: string;
 * assign_to_employee: string;
 * credit_rating: string;
 * credit_limit: string;
 * credit_term: string;
 * mas_&_unsaction_date: string;
 * description: string;
 * billing_address_1: string;
 * billing_address_2: string;
 * billing_address_3: string;
 * billing_postcode: string;
 * billing_country: string;
 * company: string;
 * facebook: string;
 * instagram: string;
 * }} data
 * @returns
 */

export const formatViewData = (data) => {
  const res = {
    'Basic Details': [],
    'Address Details': [],
    'Registered Address': [],
    'Bank Details': [],
    Description: [],
    // 'Control Listing': [],
    // "Job History": [],
  };
  if (!data) return res;

  Object.entries(data).forEach(([key, val]) => {
    switch (key) {
      case 'customer_name':
        res['Basic Details'].push({ label: key, value: val, name: 'Client Name' });
        break;
      case 'email_address':
      case 'contact_number':
      case 'date_of_birth':
      case 'nature_of_business':
      case 'id_card_number':
      case 'id_card_type':
      case 'passport_number':
      case 'passport_issuing_date':
      case 'passport_issuing_country':
      case 'nationality':
      case 'contact_type':
      case 'lead_source':
      case 'source':
      case 'assign_to_employee':
        res['Basic Details'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
        break;
      case 'address':
      case 'city':
      case 'state':
      case 'country':
      case 'postcode':
        res['Address Details'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
        break;
      case 'r_address':
      case 'r_city':
      case 'r_state':
      case 'r_country':
      case 'r_postcode':
        res['Registered Address'].push({ label: key, value: val, name: key.replaceAll('r_', ' ') });
        break;
      case 'bank_ifsc_code':
      case 'bank_account_number':
      case 'bank_account_holder_name':
      case 'bank_account_type':
        res['Bank Details'].push({
          label: key,
          value: val,
          name: key.replaceAll('bank_', 'Bank ').replaceAll('_', ' '),
        });
        break;
      case 'description':
        res['Description'].push({ label: key, value: val, name: key, className: 'col-span-full' });
        break;
      // case 'credit_rating':
      // case 'credit_amount':
      // case 'credit_term':
      // case 'mas_&_unsaction_date':
      //   res['Control Listing'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
      //   break;
      case 'company':
        res['Job History'].push({ label: key, value: val, name: key });
        break;
      default:
        break;
    }
  });
  return res;
};
