import React, { useCallback, useState } from 'react';
import { Modal } from 'antd';
import { MarkDown, Toast } from 'components';
import { editMeeting, editTask } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';

const Description = ({ task, content, label = true, onGetTasks }) => {
  const [showModal, setModal] = useState(false);
  const [desc, setDesc] = useState(content);
  const [loading, setLoading] = useState(false);

  const onEdit = useCallback((field, value) => {
    setDesc(value);
  }, []);

  const onSave = useCallback(async () => {
    setLoading(true);
    const payload = {
      description: desc,
    };
    const resp =
      task?.activity === 'Task'
        ? await editTask({ id: task?.id, data: payload })
        : await editMeeting({ id: task?.id, data: payload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      togglePopup();
      onGetTasks();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, [desc, task]);

  const togglePopup = () => {
    setModal((pre) => !pre);
  };
  return (
    <div className='col-span-full'>
      <label
        className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xs text-black`}
      >
        Description
      </label>
      <div
        className='bg-grey-light rounded cursor-pointer'
        onClick={togglePopup}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
      <Modal onOk={onSave} okText={'Update'} onCancel={togglePopup} open={showModal}>
        <MarkDown
          name='description'
          id='description'
          field={{ name: 'description' }}
          placeholder='Enter description ...'
          type='textarea'
          isMultiline={true}
          divClasses='col-span-full'
          rows={6}
          defaultValue={content}
          customOnChange={onEdit}
        />
      </Modal>
    </div>
  );
};

export default Description;
