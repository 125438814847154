/** external deps */
import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

/** internal deps */
import { Button, CustomTab, ContentHeader } from 'components';
import { items, tabData } from './data';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { Context } from 'store/context';
import AssignModal from './AssignModal';
import { getQueryParams } from 'utils/common';
import { debounce } from 'utils';
import LeadTable from './LeadTable';
import { searchApis } from 'service/api-service';
import { AdvanceAssignmentIcon } from 'assets/icons';

const Inquiry = ({ permissions = [], customer_id, employee_id }) => {
  const {
    leads: { list: leads, updateLeads, pageNum },
    user,
  } = useContext(Context);
  const navigate = useNavigate();
  const { active } = getQueryParams();
  const [tabKey, setTabKey] = useState('draft');
  const [selectedValue, setSelectedValue] = useState();
  const [employeedata, setEmployeeeData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [inquiryMethod, setInquiryMethod] = useState(active ?? 'manual');

  /** funcs */

  const getEmployeeData = async () => {
    const resp = await searchApis({ api: 'employee' });
    const data = resp?.data?.data?.map((contact) => ({
      value: contact.id,
      label: contact.name,
      name: contact.name,
      id: contact.id,
    }));
    setEmployeeeData(data);
    const response = await searchApis({ api: 'insurance_category' });
    setAllCategories(response?.data?.data);
    const dataCategory = response?.data?.data.map((category) => ({
      value: category.id,
      label: category.name,
      name: category.name,
      id: category.id,
    }));
    setCategoryData(dataCategory);
  };
  useEffect(() => {
    getEmployeeData();
  }, []);

  useEffect(() => {
    const data = allCategories.filter((option) => selectedValue?.insurance_category === option.id);
    const products = data[0]?.product_type?.map((product) => ({
      value: product.id,
      label: product.name,
      name: product.name,
      id: product.id,
    }));
    setProductData(products);
  }, [selectedValue?.insurance_category]);

  const handleSearch = debounce(async (e) => {
    const query = e?.target?.value;
    await updateLeads({
      pageNum: 1,
      query,
      status: tabKey,
      employee_id: selectedValue?.employee_id,
      category_id: selectedValue?.insurance_category,
      products: selectedValue?.products,
      type: active === 'online' ? 'online' : active === 'pos' ? 'pos' : 'employee',
    });
  }, 700);

  const handleAddClick = () => {
    navigate('/lead/create/new/1');
  };

  // get data

  const renderTab = (item, index) => {
    return (
      <CustomTab label={item} key={index} period={inquiryMethod} setPeriod={setInquiryMethod} />
    );
  };

  const onchangeTab = (key) => {
    console.log(key, 'key');
    setTabKey(key);
    updateLeads({
      pageNum,
      status: key,
      employee_id: selectedValue?.employee_id,
      category_id: selectedValue?.insurance_category,
      products: selectedValue?.products,
      type: active === 'online' ? 'online' : active === 'pos' ? 'pos' : user?.user_type,
    });
  };

  useEffect(() => {
    updateLeads({
      status: tabKey,
      employee_id: selectedValue?.employee_id ?? employee_id,
      category_id: selectedValue?.insurance_category,
      products: selectedValue?.products,
      type: active === 'online' ? 'online' : active === 'pos' ? 'pos' : user?.user_type,
    });
  }, [inquiryMethod]);

  useEffect(() => {
    if (selectedValue)
      updateLeads({
        pageNum,
        employee_id: selectedValue?.employee_id,
        category_id: selectedValue?.insurance_category,
        product_type: selectedValue?.products,
        status: tabKey,
        type: active === 'online' ? 'online' : active === 'pos' ? 'pos' : user?.user_type,
      });
  }, [selectedValue]);

  const renderTabBar = (props, DefaultTabBar) => {
    return (
      <div className={` border border-primary-60 rounded-md w-fit flex  `}>
        {items &&
          items.map(({ key, label }) => {
            return (
              <div
                className={
                  tabKey === key
                    ? `bg-primary-60 cursor-pointer px-2 py-1  rounded font-semibold leading-normal text-xxs text-white`
                    : `bg-transparent cursor-pointer  px-2 py-1 mr-1 text-black rounded font-normal leading-normal text-xxs`
                }
                onClick={() => {
                  onchangeTab(key);
                }}
                {...props}
              >
                {label}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <main className='bg-none rounded-lg'>
        <div className='pb-3 '>
          <ContentHeader
            title={
              <div className='border border-primary-60 rounded-md'>{tabData.map(renderTab)}</div>
            }
            permissions={permissions}
            onChange={handleSearch}
            appendChildren={false}
            // data={breadCrumbsObj}
            btnText='+ Leads'
            buttonId={elementData.Leads.permissions.create}
            onClick={handleAddClick}
            children={
              <div className='flex gap-2 items-center '>
                <Select
                  // bordered
                  allowClear={{ clearIcon: <CloseOutlined /> }}
                  onClear={() => {
                    setSelectedValue({
                      ...selectedValue,
                      employee_id: '',
                    });
                  }}
                  style={{
                    width: 170,
                    border: '0.5px  solid #ABABAB',
                    marginTop: '2px',
                    borderRadius: '5px',
                    paddingLeft: '10px',
                    paddingTop: '1px',
                    paddingBottom: '1px',
                  }}
                  value={selectedValue?.employee_id}
                  placeholder={<span className='px-2 py-2 text-[14px]'>Select asignee</span>}
                  onChange={(e) => {
                    setSelectedValue({
                      ...selectedValue,
                      employee_id: e,
                    });
                  }}
                  // onChange={filterTable}
                  showSearch={true}
                  options={employeedata}
                  className='cursor-pointer border-[0.5px] flex items-center justify-center border-gray-300 rounded-[5px] p-[1px]'
                />
                <Select
                  allowClear={{ clearIcon: <CloseOutlined /> }}
                  onClear={() => {
                    setSelectedValue({
                      ...selectedValue,
                      insurance_category: '',
                    });
                  }}
                  style={{
                    width: 130,
                    border: '0.5px  solid #ABABAB',
                    borderRadius: '5px',
                    marginTop: '2px',
                    paddingLeft: '10px',
                    paddingTop: '1px',
                    paddingBottom: '1px',
                  }}
                  value={selectedValue?.insurance_category}
                  placeholder={<span className='px-2 py-2 text-[14px]'>Category</span>}
                  onChange={(e) => {
                    setSelectedValue({
                      ...selectedValue,
                      insurance_category: e,
                    });
                  }}
                  showSearch={true}
                  options={categoryData}
                  className='cursor-pointer border-[0.5px] flex items-center justify-center border-gray-300 rounded-[5px] p-[1px]'
                />
                {selectedValue?.insurance_category && (
                  <Select
                    allowClear={{ clearIcon: <CloseOutlined /> }}
                    onClear={() => {
                      setSelectedValue({
                        ...selectedValue,
                        products: '',
                      });
                    }}
                    style={{ width: 150, textAlign: 'center' }}
                    value={selectedValue?.products}
                    placeholder={<span className='px-2 py-2 text-[14px] '>Select Product</span>}
                    onChange={(e) => {
                      setSelectedValue({
                        ...selectedValue,
                        products: e,
                      });
                    }}
                    showSearch={true}
                    className='cursor-pointer border-[0.5px] flex items-center justify-center border-gray-300 rounded-[5px] p-[1px]'
                    options={productData}
                  />
                )}

                {(selectedValue?.employee_id ||
                  selectedValue?.insurance_category ||
                  selectedValue?.products) && (
                  <Button
                    text='Clear'
                    type='reset'
                    // classes="border border-gray-90 text-black cursor-pointer"
                    onClick={() => {
                      setSelectedValue({});
                    }}
                  />
                )}

                {/* <Button
                  text='Advanced Assignment'
                  type='button'
                  onClick={() => {
                    setOpenAssignModal(true);
                  }}
                /> */}
                <button
                  onClick={() => {
                    setOpenAssignModal(true);
                  }}
                >
                  <AdvanceAssignmentIcon svgClass='h-8 w-8' />
                </button>
              </div>
            }
          />
        </div>
        <div className='flex text-center items-center justify-between bg-none pb-3'>
          <p className='pl-5 font-semibold text-s'>Leads Listing</p>
          {/* <TabsComponent
          items={items}
          activeKey={tabKey}
          setActiveTabKey={setTabKey}
        />  */}
          <Tabs
            // onChange={onchangeTab}
            items={items}
            renderTabBar={renderTabBar}
            type='card'
            defaultActiveKey={tabKey}
            className='pl-4'
          />
        </div>

        {/* table comp */}

        <LeadTable
          permissions={permissions}
          customer_id={customer_id}
          inquiryMethod={inquiryMethod}
          selectedValue={selectedValue}
          tabKey={tabKey}
        />

        <AssignModal
          open={openAssignModal}
          allCategories={allCategories}
          onCancel={() => setOpenAssignModal(false)}
        />
      </main>
    </>
  );
};

export default SecureComponent(Inquiry);
