import React, { useState } from 'react';

import { Button, FormContainer, IconButton, Toast } from 'components';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { BackArrow } from 'assets/icons';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { targetsManagement } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { addGoalFields } from './data';
import './style.css';

const AddTarget = ({
  goBack,
  initialValues,
  permissions = {},
  showBack = true,
  getTargets,
  type = 'add',
  employee,
  employee_id,
}) => {
  const [loading, setLoading] = useState(false);
  const [endRequired, setEndDateRequired] = useState(true);
  const isAdding = type === 'add';

  const [initVals, fieldForms] = prepareFormFieldsData({
    fields: addGoalFields({ setEndDateRequired, endRequired }),
    defaultValues: { employee },
  });

  const handleAddGoal = async (data) => {
    setLoading(true);
    const payload = {
      ...data,
    };
    const resp = await targetsManagement({ method: 'post', employee_id, data: payload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Success');
      await getTargets();
      goBack();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.data?.msg || 'Failed to create target.');
    }
    setLoading(false);
  };

  //edit target
  const handleUpdateTarget = async (data) => {
    setLoading(true);
    const initPayload = { ...initialValues, employee: initialValues?.employee?.id };
    const payload = { ...data, employee: data?.employee?.id };
    const editFields = getEditedFields({ initialValues: initPayload, updatedData: payload });
    if (Object.keys(editFields).length) {
      const target_id = initialValues?.id;
      const resp = await targetsManagement({
        data: editFields,
        method: 'patch',
        target_id,
      });

      if (resp?.data?.status) {
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.data?.msg || 'Target updated successfully.',
        );
        getTargets();
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
      goBack();
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save.');
    }
  };

  return (
    <div className='bg-white flex flex-col rounded-2xl rounded-b-none p-4 w-full'>
      <div className='flex justify-between '>
        <div className='flex flex-col'>
          <span className='text-xxs '>
            To {isAdding ? 'add a new' : 'update'} goal, enter the details of the target in the
            input field below.
          </span>
        </div>
        {showBack && (
          <IconButton onClick={() => goBack()}>
            <BackArrow svgClass='fill-grey-90 w-10 h-10' />
          </IconButton>
        )}
      </div>

      <FormContainer
        returnFormatted={isAdding}
        accordionClass='grid grid-cols-1 sm:grid-cols-2 gap-8'
        fields={fieldForms}
        initialValues={isAdding ? initVals : initialValues}
        onSubmit={isAdding ? handleAddGoal : handleUpdateTarget}
        customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
          return (
            <>
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    goBack();
                  }}
                />
                <Button
                  disabled={!isValid}
                  permissions={permissions}
                  id={elementData.Leads.permissions.create}
                  text={isAdding ? 'Add Target' : 'Update Target'}
                  type='button'
                  variant='primary'
                  classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default SecureComponent(AddTarget);
