import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { ContentHeader, PdfViewerComponent, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { getPosContract, posContractMangement } from 'service/api-service';

const DigitalSignature = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const [base64String, setBase64String] = useState('');

  const breadCrumbs = [
    {
      name: 'Pos Contract',
      path: '/pos-contract',
    },
    {
      name: 'Digital Signature',
      path: '',
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await getPosContract();
    if (resp?.data?.status) {
      const data = resp?.data?.data[0];
      setData(data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const submitContract = useCallback(async () => {
    setLoading(true);
    const data = 'contract,data:application/pdf;base64,' + base64String;
    const finalPayload = {
      docs: [
        {
          name: 'contract_doc',
          file: data,
        },
      ],
    };
    const resp = await posContractMangement({ data: finalPayload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Contract submitted successfully');
      navigate('/pos/pos-review');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, [base64String]);

  if (loading) {
    return (
      <div className='flex items-center justify-center h-64'>
        <Spin />
      </div>
    );
  }
  return (
    <div className='grid gap-3'>
      <ContentHeader
        data={breadCrumbs}
        btnText='Submit'
        showSearch={false}
        onClick={submitContract}
      />
      {data?.contract && (
        <div className='PDF-viewer'>
          <PdfViewerComponent document={data?.contract} setBase64String={setBase64String} />
        </div>
      )}
    </div>
  );
};

export default DigitalSignature;
