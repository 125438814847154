/** external deps */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

/** internal deps */
import { SecureComponent } from 'permission-provider';
import { DateTime, SelectInput, TableComponent, TabsComponent } from 'components';

// for chart
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { columns } from './data';
import clsx from 'clsx';
import { getAttendancebyStatus, getShift } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

Chart.register(CategoryScale);

const attendanceTabs = [
  {
    key: 'present',
    id: 'present',
    type: 'present',
    label: `Present`,
    Component: () => null,
  },
  {
    key: 'absent',
    id: 'absent',
    type: 'absent',
    label: `Absent`,
    Component: () => null,
  },
  {
    key: 'leave',
    id: 'leave',
    type: 'leave',
    label: `Leave`,
    Component: () => null,
  },
  {
    key: 'weekdaysoff',
    id: 'weekdaysoff',
    type: 'weekdaysoff',
    label: `Week days off`,
    Component: () => null,
  },
];

const LabelValue = ({ value, label, color }) => {
  return (
    <div className='flex flex-row items-center gap-1'>
      <div style={{ background: color }} className={clsx('w-3 h-3 rounded-full')}></div>
      {label}: <span>{value || 0}</span>
    </div>
  );
};

const Attendance = ({ permissions = [] }) => {
  /* states  */
  const [loading, setLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('present');
  const [attendanceData, setTableData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [shiftId, setShiftId] = useState('');

  const barGraphData = {
    labels: ['Present', 'Leave', 'Absent'],
    datasets: [
      {
        data: [attendanceData?.present, attendanceData?.leave, attendanceData?.absent],
        backgroundColor: ['#d3f4fb', '#ffebc3', '#fed9d7', '#e2e2e2'],
        borderColor: ['#25CBED', '#FFBD39', '#FA423B', '#707070'],
        borderWidth: 1,
      },
    ],
  };

  const handleOnDateChange = async ({ val }) => {
    setSelectedDate(val);
  };

  const fetchData = async ({ shiftId = '' }) => {
    setLoading(true);
    const resp = await getAttendancebyStatus({
      date: selectedDate,
      status: activeTabKey,
      shift_id: shiftId,
    });
    if (resp?.data?.status) {
      setTableData(resp?.data?.data);
    }
    setLoading(false);
  };

  const EmployeesData = [
    {
      label: 'Present',
      value: attendanceData?.present,
      color: '#25CBED',
    },
    {
      label: 'Leave',
      value: attendanceData?.leave,
      color: '#FFBD39',
    },
    {
      label: 'Absent',
      value: attendanceData?.absent,
      color: '#FA423B',
    },
  ];

  useEffect(() => {
    if (shiftId) {
      fetchData({ shiftId });
    }
  }, [selectedDate, activeTabKey, shiftId]);

  return (
    <div className='grid grid-cols-5 gap-4'>
      <div className='flex flex-col gap-3 col-span-3 bg-white rounded-xl shadow-custom pb-5'>
        <div className='flex flex-row justify-between items-center border-b-2 border-grey-light p-5 h-fit'>
          <div className='flex flex-row gap-2 items-center justify-center h-fit'>
            <div className='h-7 w-1.5 rounded bg-primary-90'></div>
            <p className='text-grey font-medium text-s'>Employee Attendance</p>
          </div>

          <div className='flex items-center gap-2'>
            <SelectInput
              type='select'
              placeholder='Search Shift'
              field={{ name: 'shift' }}
              divClasses='w-64'
              fetchOptions={async () => getShift()}
              customOnChange={(_, { id }) => setShiftId(id)}
            />
            <DateTime
              defaultValue={dayjs()}
              value={dayjs()}
              placeholder='Select Date'
              customOnChange={handleOnDateChange}
            />
          </div>
        </div>

        {shiftId && (
          <div className='flex flex-col w-auto px-4'>
            <TabsComponent
              items={attendanceTabs}
              activeKey={activeTabKey}
              setActiveTabKey={setActiveTabKey}
            />
            <TableComponent
              loading={loading}
              columns={columns}
              dataSource={attendanceData?.data || []}
              getRowClassName={rowCommonClass}
            />
          </div>
        )}
        {!shiftId && (
          <div className='w-full py-4 text-xxxs h-full grid place-content-center'>
            <p>Please select a shift</p>
          </div>
        )}
      </div>
      {/* second container */}
      <div className='flex flex-col gap-3 col-span-2 shadow-custom rounded-xl bg-white p-4'>
        <div className='bg-white'>
          <p className='font-medium'>
            Total Employees:{' '}
            <span className='text-[#25CBED]'>
              {' '}
              {(attendanceData?.present || 0) +
                (attendanceData?.leave || 0) +
                (attendanceData?.absent || 0)}
            </span>
          </p>
          <div className='grid grid-cols-3 text-grey-90 text-xxs font-medium gap-3 pt-4 '>
            {EmployeesData.map((items, index) => (
              <LabelValue key={index} {...items} />
            ))}
          </div>
        </div>
        <div className='flex items-center  px-4 py-2 bg-white'>
          <Bar
            data={barGraphData}
            height={250}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SecureComponent(Attendance);
