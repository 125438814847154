/** external deps */
import React, { useState } from 'react';
import { TreeSelect } from 'antd';
import { DropdownIcon } from 'assets/icons';
import './style.css';

const treeData = [
  {
    title: 'Policies',
    value: 'policies',
  },
  {
    title: 'Quotations',
    value: 'quotations',
  },
  {
    title: 'Clients',
    value: 'clients',
  },
  {
    title: 'Pos',
    value: 'pos',
  },
  {
    title: 'Leads',
    value: 'leads',
  },
  {
    title: 'Employees',
    value: 'employees',
  },
];

const SelectDropDown = ({ field, form, divClasses, ...props }) => {
  const [value, setValue] = useState();
  let selected = '';
  if (props?.nodeTitle) {
    selected = form?.values[field?.name]?.id || form?.values[field?.name];
  } else {
    selected = form?.values[field?.name];
  }

  function findParentNodeValue(data, targetValue, parentValue = null) {
    for (const node of data) {
      if (node.value === targetValue) {
        return parentValue;
      }

      if (node.children) {
        const found = findParentNodeValue(node.children, targetValue, node.value);
        if (found !== null) {
          return found;
        }
      }
    }

    return null;
  }
  const onChange = (val) => {
    if (props?.customOnChange) {
      const parentValue = findParentNodeValue(props?.options || treeData, val);
      props?.customOnChange(field?.name, parentValue, form);
    }

    if (form?.setFieldValue) return form.setFieldValue(field?.name, val);
    setValue(val);
  };

  //temporary fix for automatically set label for target-type
  const handleSelect = (value, option) => {
    if (props?.onSelect) return props.onSelect({ value, option, form, field });
    form.setFieldValue(field?.name, value);
  };

  return (
    <div id='treeSelect'>
      <div className='flex justify-between'>
        <label
          className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xxs text-black ${
            props?.required ? 'required' : ''
          }`}
        >
          {props.label}
        </label>
      </div>
      <TreeSelect
        suffixIcon={<DropdownIcon svgClass='w-3 h-3' />}
        value={value}
        dropdownStyle={{
          maxHeight: 400,
        }}
        defaultValue={() => {
          if (selected) {
            return selected;
          }
        }}
        optionFilterProp='children'
        treeData={props?.options ? props?.options : treeData}
        treeDefaultExpandAll
        name={field?.name}
        treeNodeLabelProp='title'
        onChange={onChange}
        onSelect={handleSelect}
        placeholder={selected ? selected : props?.placeholder}
        className={
          'w-full py-[0.5px]  px-2 rounded-md border border-[#D1EAF9] bg-[#F5FAFD] text-grey'
        }
      />
      {form?.errors[field?.name] && form?.touched[field?.name] && (
        <div
          className={`absolute bg-white  last:left-0 text-red-90 flex items-center cursor-pointer text-m md:text-x mt-1 `}
        >
          {form?.errors[field?.name]}
        </div>
      )}
    </div>
  );
};
export default SelectDropDown;
