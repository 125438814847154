import { Space, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { DocumentIcon, View } from 'assets/icons';
import {
  Button,
  ContentHeader,
  CustomModal,
  DocumentViewer,
  FormContainer,
  IconButton,
  Module,
  TableComponent,
  TextInput,
  Toast,
  ViewData,
  SelectSearchWithName,
} from 'components';
import { elementData } from 'elements';
import { getRecruitmentDetails, updateApplication } from 'service/api-service';
import { viewBreadCrumbs } from './management/data';
import { getPermission, prepareFormFieldsData } from 'utils';
import { NOTIFICATION_MSG } from 'constants';
import AddInterview from './AddInterview';
import { TableStatus } from 'components/table/TableStatus';
import { prepareViewData, rowCommonClass } from 'utils/common';
import { getReasons } from 'service/api-service/reasons';

const fields = {
  approvedfields: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Rejection Reason',
        name: 'r_reason',
        id: 'r_reason',
        placeholder: 'Select a Rejection Reason',
        type: 'select',
        required: true,
        component: SelectSearchWithName,
        options: [],
        fetchOptions: async () => getReasons({ type: 'recruitment' }),
        valIndex: 'value',
        customOnChange: (field, value, form) => {
          form?.setFieldValue(field, value.value);
        },
      },
    ],
  },
};

const APPLICATION_STATUS = {
  PENDING: 'pending',
  HIRED: 'hired',
  REJECTED: 'rejected',
};

export const ApplicationStatusList = [
  {
    label: <span className='w-full text-left'>Pending</span>,
    key: APPLICATION_STATUS.PENDING,
  },
  {
    label: <span className='w-full text-left'>Rejected</span>,
    key: APPLICATION_STATUS.REJECTED,
  },
  {
    label: <span className='w-full text-left'>Hired</span>,
    key: APPLICATION_STATUS.HIRED,
  },
];

export const SECTION_DATA_VIEW = [
  {
    title: 'Basic Details',
    keys: ['name', 'phone', 'email', 'exp', 'location', 'notice', 'n_days'],
  },
  {
    title: 'Other Details',
    keys: [
      'current_ctc',
      'exp_ctc',
      'aval',
      'type',
      'status',
      'reason',
      'pre_org',
      'pre_email',
      'pre_con',
    ],
  },
];

const ViewApplications = ({ permissions }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [applicationsData, setApplicationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewAppDetails, setViewAppDetails] = useState(false);
  const [viewData, setViewData] = useState();
  const [recruitmentDetails, setRectruitmentDetails] = useState({});
  const [showReasonModal, setReasonModal] = useState(false);
  const [declinedVal, setDeclinedVal] = useState();
  const [curActiveRecordDoc, setCurActiveRecordDoc] = useState(null);
  const isViewDocModalOpen = Boolean(curActiveRecordDoc);
  const handleCloseViewDocModal = () => setCurActiveRecordDoc(null);

  const [initialValues, reasonFields] = prepareFormFieldsData({
    fields: fields,
  });

  const toggleReasonModal = () => {
    setReasonModal((pre) => !pre);
  };

  const getRecrDetails = async () => {
    setLoading(true);
    try {
      const resp = await getRecruitmentDetails({ id });
      if (resp?.data?.status) {
        setRectruitmentDetails(resp.data?.data);
        setApplicationData(resp?.data?.data?.applications);
      }
    } catch (error) {}
    setLoading(false);
  };

  const onStatusChange = (e, record) => {
    try {
      const status = e?.key;
      if (status === record?.status) return;

      if (status === APPLICATION_STATUS.REJECTED) {
        setDeclinedVal(record);
        toggleReasonModal();
        return;
      }

      onStatusUpdate({ status }, record?.id);
    } catch (error) {}
  };

  const onStatusUpdate = async (data, id) => {
    setLoading(true);

    const finalPayload = {
      status: data?.status || 'rejected',
      r_reason: data?.r_reason,
    };

    try {
      const resp = await updateApplication({ data: finalPayload, id: id || declinedVal?.id });

      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        getRecrDetails();
      } else Toast('error', NOTIFICATION_MSG.error, resp?.data?.msg);
    } catch (error) {}

    setReasonModal(false);
    setLoading(false);
  };

  const onViewClick = (record) => {
    console.info('record', record);

    const data = prepareViewData({
      data: record,
      format: SECTION_DATA_VIEW,
      keys: {
        aval: 'Availability',
        type: 'interview type',
        exp_ctc: 'Expected CTC',
        pre_org: 'Previous Company Name',
        pre_email: 'Previous Company Email',
        pre_con: 'Previous Company Contact',
        n_days: 'Notice Period Days',
      },
      allowToReturnObjects: true,
      phone: 'phone',
      email: 'email',
    });
    // console.info('data', data);
    setViewData(data);
    setViewAppDetails(true);
  };

  const applicationColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Contact',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        return (
          <TableStatus
            permissions={permissions}
            id={elementData.applicationDetails.permissions.update}
            onChange={onStatusChange}
            record={record}
            items={ApplicationStatusList}
            status={status}
          />
        );
      },
    },
    {
      title: 'Reject Reason',
      dataIndex: 'r_reason',
      key: 'r_reason',
    },
    {
      title: 'CV',
      align: 'center',
      key: 'cv',
      render: (_, record) => {
        return (
          <IconButton
            title='view Cv'
            className='group disabled:cursor-not-allowed'
            id={elementData.applicationDetails.permissions.view}
            onClick={() => setCurActiveRecordDoc(record?.cv)}
          >
            <DocumentIcon className='w-4 h-4 fill-primary-90' />
          </IconButton>
        );
      },
    },
    {
      title: 'Invite',
      align: 'center',
      key: 'invite',
      render: (_, record) => {
        return <AddInterview app_id={record?.id} permissions={permissions} />;
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='view'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.applicationDetails.permissions.view}
            onClick={onViewClick.bind(this, record)}
          >
            <View svgClass='stroke-primary group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  const goBack = () => {
    setViewAppDetails(false);
  };

  useEffect(() => {
    getRecrDetails();
  }, []);

  if (viewAppDetails) {
    return (
      <div className='bg-white rounded-2xl relative p-4'>
        <ViewData
          pageTitle='Application Details'
          data={viewData}
          showGoToBack={true}
          goBack={goBack}
        />
      </div>
    );
  }

  return (
    <div className='bg-white  w-full relative rounded-xl p-4'>
      <ContentHeader
        permissions={permissions}
        // data={viewBreadCrumbs}
        title={<p className='text-s font-semibold'>Applications Listing</p>}
        showSearch={false}
        btnText='Back'
        variant='secondary'
        onClick={() => navigate('/hr/job-management')}
      />

      {/* Recruitment Details */}
      <section class='shadow-custom p-2 shadow-grey-20'>
        <h1 className='font-bold text-xl'>Recruitment Details</h1>
        <div class='grid grid-cols-3'>
          <div class='flex flex-col'>
            <span className=''>Designation</span>
            <span className='text-xxs text-grey-50'>{recruitmentDetails?.designation?.name}</span>
          </div>

          <div class='flex flex-col'>
            <span className=''>Experience Required</span>
            <span className='text-xxs text-grey-50'>{recruitmentDetails?.exp}</span>
          </div>

          <div class='flex flex-col'>
            <span className=''>Closing Date</span>
            <span className='text-xxs text-grey-50'>{recruitmentDetails?.c_date}</span>
          </div>

          <div class='flex flex-col col-span-full mt-2'>
            <span className=''>Description</span>
            <div
              id='html-viewer'
              className='text-xxs text-grey- text-justify'
              dangerouslySetInnerHTML={{ __html: recruitmentDetails?.notes }}
            ></div>
          </div>
        </div>
      </section>

      <div className='w-full'>
        <div className='flex py-4 justify-between '>
          <h1 className='font-bold text-xl'>Applications</h1>
          <Button
            onClick={() => {
              navigate(`/careers?reqId=${id}`);
            }}
            text='+ Application'
            varient='primary'
            classes='bg-black p-2 rounded-3xl text-white'
          />
        </div>

        <div className='max-h-[70vh] overflow-y-auto mt-4'>
          {getPermission(permissions, elementData.applicationDetails.name)?.read ? (
            <TableComponent
              columns={applicationColumns}
              dataSource={applicationsData}
              showPagination={false}
              loading={loading}
              getRowClassName={rowCommonClass}
            />
          ) : (
            <Module />
          )}
        </div>
      </div>

      <CustomModal
        open={showReasonModal}
        title='Reason to Decline'
        footer={null}
        onCancel={toggleReasonModal}
      >
        <div>
          <FormContainer
            accordionClass='grid  gap-8'
            onSubmit={onStatusUpdate}
            initialValues={initialValues}
            fields={reasonFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleReasonModal();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    text={'Reject'}
                    variant='primary'
                    classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      <DocumentViewer
        src={curActiveRecordDoc}
        onClose={handleCloseViewDocModal}
        visible={isViewDocModalOpen}
      />
    </div>
  );
};

export default ViewApplications;
