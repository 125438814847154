import { Select } from 'antd';
import { DropdownIcon } from 'assets/icons';
import React, { useEffect, useState } from 'react';
import { getBranches } from 'service/api-service';
import './style.css';

const BranchFilter = ({ onSelect, ...props }) => {
  const [options, setOptions] = useState([]);

  const fetchBranchOptions = async () => {
    const res = await getBranches();
    if (res?.data?.status) {
      setOptions(res?.data?.data || []);
    }
  };

  useEffect(() => {
    fetchBranchOptions();
  }, []);

  return (
    <div>
      <Select
        className='group border border-grey-90 hover:border-primary-90 hover:text-primary-90 rounded px-2'
        suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
        placeholder={'Select Branch'}
        dropdownAlign={{ offset: [-10, 4] }}
        options={[
          { label: 'All', value: '' },
          ...options.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        ]}
        onSelect={onSelect}
        onChange={props?.onChange}
        align='left'
        {...props}
      />
    </div>
  );
};

export default BranchFilter;
