import dayjs from 'dayjs';

export const columns = [
  {
    title: 'Caller ID',
    dataIndex: 'caller_id',
    key: 'caller_id',
  },
  {
    title: 'Call Date and Time',
    dataIndex: 'created',
    key: 'created',
    render: (data) => (
      <p>
        {dayjs(data).format('DD-MM-YYYY')}, {dayjs(data).format('HH:MM  A')}
      </p>
    ),
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: 'Call Type',
    dataIndex: 'call_type',
    key: 'call_type',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

export const SECTION_DATA_VIEW = [
  {
    title: 'Client Logs Details',
    keys: ['customer', 'log_created_by', 'call_type', 'status', 'date', 'time', 'duration'],
  },
];
