import { endpoints } from 'constants/endpoints';
import Request from './client';

export const targetTypeManagement = async ({ data = undefined, method = 'get', id }) => {
  const resp = await Request({
    method,
    API:
      method === 'delete' || method === 'patch'
        ? endpoints.targetTypeManagement.targetId(id)
        : endpoints.targetTypeManagement.targetType,
    data,
  });
  return resp;
};

export const getDynamicTableData = async (props) => {
  const { status, employee_id, query, page = 1 } = props;
  return await Request({
    method: 'get',
    API: endpoints.dynamicTable.get(query, page, status, employee_id),
  });
};

export const targetsManagement = async ({
  data = undefined,
  method = 'get',
  employee_id,
  target_id,
  start,
  end,
  page = 1,
}) => {
  const resp = await Request({
    method,
    API:
      method === 'delete' || method === 'patch'
        ? endpoints.goal.target(employee_id, target_id)
        : endpoints.goal.employee_id(start, end, employee_id, page),
    data,
  });
  return resp;
};

export const viewGoals = async ({ id = '', page = 1, status = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.goal.view(id, page, status),
  });
  return resp;
};

export const employeeGoalGraph = async ({ id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.goal.employee(id),
  });
  return resp;
};
