import { endpoints } from 'constants/endpoints';
import Request from './client';

export const getStatistics = async (props) => {
  let { id } = props;
  const resp = await Request({
    method: 'get',
    API: endpoints.getStatistics.employee_id(id),
  });
  return resp;
};

//  upcoming Events  :/leave/holiday?date=2023-06-30
export const upComingEvents = async (props = { id: '', method: 'get', data: '', date: '' }) => {
  const resp = await Request({
    method: props?.method,
    API:
      props.method === 'delete' || props.method === 'patch'
        ? endpoints.upComingEvents.holidayId(props?.id)
        : endpoints.upComingEvents.holidayDate(props?.date),
    data: props?.data,
  });
  return resp;
};
