import React, { useState } from 'react';
import { CustomModal, FormContainer, SearchAPI } from 'components';

import { leaveManagementAdmin } from 'service/api-service';
import { prepareFormFieldsData } from 'utils';
import Button from 'components/button';

const fields = {
  approvedfields: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Select Employee',
        name: 'employee',
        id: 'employee',
        placeholder: 'Select Employee',
        type: 'search',
        required: true,
        component: SearchAPI,
      },
    ],
  },
};

const ForwardModal = ({ open, onCancel, record }) => {
  const [loading, setLoading] = useState(false);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: fields,
  });

  const forwardLeave = async (data) => {
    setLoading(true);
    try {
      await leaveManagementAdmin({
        method: 'patch',
        id: record?.id,
        data: { forward: true, user_id: data.employee },
      });
      onCancel();
    } catch (error) {}
    setLoading(false);
  };
  return (
    <CustomModal open={open} title='Forward to' footer={null} onCancel={onCancel}>
      <div>
        <FormContainer
          // disableForm={true}
          accordionClass='grid  gap-8'
          onSubmit={forwardLeave}
          initialValues={initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, values }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                />
                <Button
                  disabled={!isValid}
                  //   permissions={permissions}
                  text={'Forward'}
                  variant='primary'
                  classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </div>
    </CustomModal>
  );
};

export default ForwardModal;
