import React, { useContext, useState } from 'react';
import { Button, FormContainer, Toast } from 'components';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { CompanyFields } from './data';
import { NOTIFICATION_MSG } from 'constants';
import { Context } from 'store/context';
import { updateCompanySetting } from 'service/api-service';

const CompanyForm = () => {
  const [loading, setLoading] = useState(false);
  const { company } = useContext(Context);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: CompanyFields,
  });

  const onFinish = async (updatedData) => {
    setLoading(true);
    const data = getEditedFields({
      initialValues: company,
      updatedData,
    });

    if (Object.keys(data).length > 0) {
      const resp = await updateCompanySetting({
        data,
      });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, 'Company Details Saved Successfully');
        window.location.reload();
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Company Info Not Saved');
      }
    } else {
      return Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
    setLoading(false);
  };

  return (
    <div className='h-[75vh] overflow-y-auto w-[84%] bg-white rounded-lg p-4 '>
      <div className='flex justify-center items-center flex-col'>
        <span className='text-xl font-medium'>Company Details</span>
        <span className='text-grey-90 text-x mb-8 text-center max-w-l font-medium'>
          Enter your company details in the field below to Change company details.
        </span>
      </div>

      <FormContainer
        accordionClass='grid grid-cols-2  gap-8'
        onSubmit={onFinish}
        initialValues={company || initialValues}
        fields={finalFields}
        customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, values }) => {
          return (
            <>
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={resetForm}
                />
                <Button
                  // disabled={!isValid}
                  // permissions={permissions}
                  // id={
                  //     editInitials
                  //         ? elementData.branch.permissions.update
                  //         : elementData.branch.permissions.create
                  // }
                  text={'Save'}
                  variant='primary'
                  classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default CompanyForm;
