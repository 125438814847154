import Request from './client';
import { endpoints } from 'constants/endpoints';

export const CompanyPolicyManagement = async (
  props = { id: '', method: 'get', data: '', page: 1 },
) => {
  let API = endpoints.company_policy.leave;

  if (props?.id) {
    API = endpoints.company_policy.leaveId(props?.id);
  }

  const resp = await Request({
    method: props?.method,
    API,
    data: props?.data,
  });
  return resp;
};

export const companySetting = async ({ id = ' ' }) => {
  const resp = await Request({
    method: 'get',
    API: `company-settings?id=${id}`,
  });
  return resp;
};

export const updateCompanySetting = async ({ data = ' ' }) => {
  const resp = await Request({
    method: 'patch',
    API: `company-settings`,
    data: data,
  });
  return resp;
};
