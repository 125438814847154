/** external deps */
import React, { useState } from 'react';
import { Upload } from 'antd';
/** internal deps */
import { User } from 'assets';
import { PenIcon, UploadIcon } from 'assets/icons';
import { Toast, IconButton } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import './style.css';
import { updateProfile } from 'service/api-service';

const UploadImage = ({ user, getUser }) => {
  const [uploadButton, setUploadButton] = useState(false);
  const [image, setImage] = useState(null);

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      setImage(file.name + ',' + target.result);
      setUploadButton(true);
    };
    reader.readAsDataURL(file);
    return false;
  };

  const handleSubmit = async (data) => {
    const finalPayload = {
      image: image,
    };
    const resp = await updateProfile({ data: finalPayload });
    if (resp?.data?.status) {
      const image = resp?.data?.data?.image;
      setImage(image);
      getUser();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setUploadButton(false);
  };

  return (
    <div className='relative h-16 w-16 m-2'>
      <img
        src={
          image?.substring(image.indexOf(',') + 1) ||
          process.env.REACT_APP_DOC_URL + user?.image ||
          User
        }
        className='rounded-full w-full h-full m-2 object-fill'
        alt='user'
      />
      {uploadButton ? (
        <IconButton
          className='absolute flex items-center justify-center bg-transparent/50 rounded-full m-2 w-16 h-16 shadow-custom top-0'
          onClick={handleSubmit}
        >
          <UploadIcon svgClass={'w-8 h-8'} />
        </IconButton>
      ) : (
        <Upload accept='.png, .jpeg, .jpg' showUploadList={false} beforeUpload={beforeUpload}>
          <IconButton className='bg-white rounded-full p-1 absolute -bottom-1 -right-3 shadow-custom'>
            <PenIcon svgClass={'w-4 h-4'} />
          </IconButton>
        </Upload>
      )}
    </div>
  );
};

export default UploadImage;
