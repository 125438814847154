import axios from 'axios';

export const fetchLocationData = (zipCode) =>
  axios
    .get(`http://api.geonames.org/postalCodeSearchJSON?postalcode=${zipCode}&username=ello.aayush`)
    .then((response) => {
      const { postalCodes } = response.data;
      if (postalCodes && postalCodes.length > 0) {
        const location = postalCodes[0];
        const zip = {
          country: location.countryCode,
          state: location.adminName1,
          city: location.adminName2,
        };
        return zip;
      } else {
        return null;
      }
    })
    .catch((error) => {
      return null;
    });
