import React, { useContext, useState, useMemo, useEffect } from 'react';
import {
  FormContainer,
  FourWheelerForm,
  HealthForm,
  LifeForm,
  IconButton,
  Toast,
  TwoWheelerForm,
  ChildSavingForm,
  PensionAndRetirementForm,
  InvestmentAndTaxPlanningForm,
  Checkbox,
  TextInput,
  SelectInput,
  SearchAPI,
  PhoneNumberInput,
  TermLifeForm,
  CustomModal,
} from 'components';
import { getInquiryDetails, inquiryManagement } from 'service/api-service';
import { SecureComponent } from 'permission-provider';
import { getInitialValues } from './data';
import { getEditedFields } from 'utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Context } from 'store/context';
import { BackArrow } from 'assets/icons';
import { NOTIFICATION_MSG } from 'constants';
import { AddCustomer } from 'screens';
import { Divider, Spin } from 'antd';
import { elementData } from 'elements';

// const Forms = {
//   TwoWheelerForm,
//   FourWheelerForm,
//   LifeForm,
//   HealthForm,
//   ChildSavingForm,
//   PensionAndRetirementForm,
//   InvestmentAndTaxPlanningForm,
//   TermLifeForm,
// };

const arrayToObject = (list) => {
  let result = {};
  list.forEach((i) => {
    result[i.id] = i;
  });
  return result;
};

const AddInquiry = ({
  // goBack,
  getInquiry,
  // initialValues,
  customerName,
  rowId,
  // type = 'edit',
  permissions = [],
}) => {
  const { user } = useContext(Context);
  const { id } = useParams();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [editInitials, setEditInitials] = useState();
  const [customerDocs, setCustomerDocs] = useState([]);
  const [productDocs, setProductDocs] = useState([]);
  const [formData, setFormData] = useState({ ...data?.insurance_details });
  const navigate = useNavigate();
  const [isAddingCustomer, setAddingCustomer] = useState(false);
  const fetchFormData = async () => {
    setLoading(true);
    if (id) {
      const resp = await getInquiryDetails({ id });
      const data = resp?.data.data;
      setData({ ...data, product_type: data?.product_type });
      setEditInitials({ ...data, product_type: data?.product_type?.name });
      setFormData(data?.insurance_details);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchFormData();
  }, []);

  const optionsObject = useMemo(() => arrayToObject(options), [options]);

  const toggleAddingCustomer = () => {
    setAddingCustomer((pre) => !pre);
  };

  //fields

  const fields = ({ disableCustomerName = true }) => {
    return {
      'Lead Details': {
        showAccordionHeader: false,
        subTitle: <h2 className='text-sm font-semibold text-primary-90'>Basic Information</h2>,
        fields: [
          {
            label: 'Category of Insurance',
            name: 'insurance_category',
            id: 'insurance_category',
            placeholder: 'Category of Insurance',
            type: 'search',
            component: SearchAPI,
            maxLength: 30,
            onSelect: ({ option }) => {
              setOptions(option?.product_type);
            },
            onDefaultValueSet: (data) => {
              if (data.data && data.data[0]) setOptions(data.data[0]?.product_type);
            },
          },
          {
            label: 'Client Name',
            name: 'customer_id',
            id: 'customer_id',
            placeholder: 'Enter Client Name',
            disabled: disableCustomerName || false,
            component: SearchAPI,
            onSelect: ({ value, option, form, field }) => {
              setCustomerDocs(option.docs_list);
              form?.setFieldValue('customer_id', value);
              form?.setFieldValue('primary_email_address', option.email_address);
              form?.setFieldValue('contact_number', option.contact_number);
            },
            maxLength: 30,
            required: true,
            extraRightRender: () => (
              <button type='button' onClick={toggleAddingCustomer}>
                <span className='text-primary-90'>+ Create Customer</span>
              </button>
            ),
          },
          {
            label: 'Primary Email Address',
            name: 'primary_email_address',
            id: 'primary_email_address',
            placeholder: 'Enter Primary Email Address',
            type: 'text',
            required: true,
            component: TextInput,
            maxLength: 30,
          },
          {
            label: 'Contact Number',
            name: 'contact_number',
            id: 'contact_number',
            placeholder: 'Contact Number',
            required: true,
            type: 'number',
            maxLength: 10,
            component: TextInput,
          },
          {
            label: 'Presently Insured',
            name: 'presently_insured',
            id: 'presently_insured',
            placeholder: 'Presently Insured',
            type: 'checkbox',
            component: Checkbox,
            checked: true,
            divClasses: 'self-end',
          },
          {
            label: 'Product Type',
            name: 'product_type',
            id: 'product_type',
            placeholder: 'Product Type',
            type: 'text',
            component: SelectInput,
            options: options?.map((i) => ({ label: i.name, value: i })),
            customOnChange: (field, value, form) => {
              const productDocs = value.value.docs;
              const result = productDocs
                ? productDocs?.filter((item) => !customerDocs.some((obj) => obj === item))
                : [];
              setProductDocs(result);
            },
            remarks: productDocs.length > 0 && `${productDocs.map((item) => item)} is required`,
            subTitle: (
              <>
                <h2 className='text-sm font-semibold text-primary-90'>Insured Information</h2>
              </>
            ),
            divClasses: 'flex items-center gap-6',
          },
        ],
      },
    };
  };

  const isAdding = data;
  const finalInitialValues = {
    ...data,
    ...(editInitials && getInitialValues({ value: user?.name })),
  };

  const handleAddInquiry = async (data) => {
    setLoading(true);
    const finalPayload = customerName
      ? {
          ...data,
          customer_id: state?.customer_id,
          assign_to_employee:
            typeof data?.assign_to_employee === 'number'
              ? data?.assign_to_employee
              : Object.keys(user).length > 0
              ? user?.id
              : '',
          insurance_details: formData,
          source_ref: user?.id,
        }
      : {
          ...data,
          customer_id: data?.customer_id,
          assign_to_employee:
            typeof data?.assign_to_employee === 'number'
              ? data?.assign_to_employee
              : Object.keys(user).length > 0
              ? user?.id
              : '',
          insurance_details: formData,
          source_ref: user?.id,
        };
    const resp = await inquiryManagement({ method: 'post', data: finalPayload });
    if (resp?.data?.status) {
      navigate('/lead');
      getInquiry();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  //edit inquiry
  const handleUpdateInquiry = async (data) => {
    setLoading(true);
    if (!data.customer_id) return;
    const editedFields = getEditedFields({
      initialValues: finalInitialValues,
      updatedData: { ...data },
    });

    const finalPayload = { ...editedFields, insurance_details: formData };
    if (Object.keys(editedFields).length) {
      const resp = await inquiryManagement({
        method: 'patch',
        data: finalPayload,
        id: rowId,
        customer_id: state?.customer_id,
      });
      if (resp?.data?.status) {
        // goBack();
        navigate('/lead');
        getInquiry();
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.data?.msg || 'Inquiry updated successfully',
        );
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  if (loading && id)
    return (
      <div className='flex justify-center items-center h-64'>
        <Spin />
      </div>
    );

  return (
    <>
      <div className='flex flex-col p-4 w-full bg-white rounded-2xl rounded-b-none'>
        <div className='flex justify-between items-center px-2'>
          <div className=''>
            <span className='font-medium text-l 2xl:text-xxl'>
              {/* {isAdding ? "Create Lead" : "Edit Lead"} */}
              {editInitials ? 'Edit Lead' : 'Create Lead'}
            </span>
          </div>

          <IconButton onClick={() => navigate('/lead')} className='flex top-1 items-center group'>
            <BackArrow svgClass='fill-grey-90 w-8 h-8 group-hover:fill-primary-90 transition-all duration-500 group-hover:-translate-x-1' />
            <span className='font-medium text-xxs text-grey-90 group-hover:text-primary-90'>
              Cancel
            </span>
          </IconButton>
        </div>
        <Divider />

        <FormContainer
          accordionClass='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-5'
          fields={fields({ disableCustomerName: state?.customer_id ? true : false })}
          initialValues={editInitials || finalInitialValues}
          onSubmit={editInitials ? handleUpdateInquiry : handleAddInquiry}
          customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm, values }) => {
            const formType = optionsObject[values?.product_type?.id]?.formid || '';

            return (
              <>
                {formType === 'TwoWheelerForm' ? (
                  <TwoWheelerForm
                    formData={formData}
                    setFormData={setFormData}
                    submitForm={submitForm}
                    resetForm={resetForm}
                    loading={loading}
                    setLoading={setLoading}
                    goBack={() => navigate('/lead')}
                  />
                ) : formType === 'FourWheelerForm' ? (
                  <FourWheelerForm
                    formData={formData}
                    setFormData={setFormData}
                    submitForm={submitForm}
                    resetForm={resetForm}
                    loading={loading}
                    setLoading={setLoading}
                    goBack={() => navigate('/lead')}
                  />
                ) : formType === 'LifeForm' ? (
                  <LifeForm
                    formData={formData}
                    setFormData={setFormData}
                    submitForm={submitForm}
                    resetForm={resetForm}
                    loading={loading}
                    setLoading={setLoading}
                    goBack={() => navigate('/lead')}
                  />
                ) : formType === 'HealthForm' ? (
                  <HealthForm
                    formData={formData}
                    setFormData={setFormData}
                    submitForm={submitForm}
                    resetForm={resetForm}
                    loading={loading}
                    setLoading={setLoading}
                    goBack={() => navigate('/lead')}
                  />
                ) : formType === 'ChildSavingForm' ? (
                  <ChildSavingForm
                    formData={formData}
                    setFormData={setFormData}
                    submitForm={submitForm}
                    resetForm={resetForm}
                    loading={loading}
                    setLoading={setLoading}
                    goBack={() => navigate('/lead')}
                  />
                ) : formType === 'PensionAndRetirementForm' ? (
                  <PensionAndRetirementForm
                    formData={formData}
                    setFormData={setFormData}
                    submitForm={submitForm}
                    resetForm={resetForm}
                    loading={loading}
                    setLoading={setLoading}
                    goBack={() => navigate('/lead')}
                  />
                ) : formType === 'InvestmentAndTaxPlanningForm' ? (
                  <InvestmentAndTaxPlanningForm
                    formData={formData}
                    setFormData={setFormData}
                    submitForm={submitForm}
                    resetForm={resetForm}
                    loading={loading}
                    setLoading={setLoading}
                    goBack={() => navigate('/lead')}
                  />
                ) : formType === 'TermLifeForm' ? (
                  <TermLifeForm
                    formData={formData}
                    setFormData={setFormData}
                    submitForm={submitForm}
                    resetForm={resetForm}
                    loading={loading}
                    setLoading={setLoading}
                    goBack={() => navigate('/lead')}
                  />
                ) : (
                  <div className='flex justify-center items-center w-full h-44 text-grey-90 text-l'>
                    Please Select Product Type
                  </div>
                )}
              </>
            );
          }}
        />
      </div>

      <CustomModal
        destroyOnClose={true}
        footer={''}
        open={isAddingCustomer}
        onCancel={toggleAddingCustomer}
      >
        <AddCustomer
          onCancel={toggleAddingCustomer}
          onSuccess={toggleAddingCustomer}
          showBackButton={false}
          renderRequiredOnly={true}
          permissions={permissions}
          id={elementData?.client.permissions.create}
          removeEmptyFields={true}
          isPopup={true}
        />
      </CustomModal>
    </>
  );
};

export default SecureComponent(AddInquiry);
