/** external deps */
import { Progress } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
/** internal deps */
import { FormContainer } from 'components';
import Button from 'components/button';
import { prepareFormFieldsData } from 'utils';
import { addReportChartFormFields } from './data';

const ReportChart = ({ setForm, setFormData, formData }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const isEditing = state?.initialValues ? true : false;
  const [initialValues, finalFormFields] = prepareFormFieldsData({
    fields: addReportChartFormFields,
  });

  const handleAddReport = async (data) => {
    let obj = Object.assign(formData, data);
    setFormData(obj);
    // console.log("data", formData);
    navigate('/reports');
  };

  const onEditSubmit = async (data) => {
    console.log('updated', data);
  };
  return (
    <div className='bg-white  flex flex-col p-4 rounded-lg'>
      <div className='relative mx-32 pb-4'>
        <div className='absolute text-xxs font-medium top-5 -left-9 '>Report Details</div>
        <Progress percent={100} strokeColor={'#26CBED'} showInfo={false} />
        <div className='absolute text-xxs font-medium top-5 right-1/2'>Filters</div>
        <div className='absolute text-xxs font-medium top-5 -right-9 text-primary-90'>
          Select Chart
        </div>
        <div className='absolute bg-black w-4 h-4 rounded-full top-1 right-0 z-10'></div>
      </div>
      <FormContainer
        accordionClass='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 lg:w-10/12'
        fields={isEditing ? finalFormFields : addReportChartFormFields}
        initialValues={isEditing ? state?.initialValues : { ...initialValues, ...formData }}
        onSubmit={isEditing ? onEditSubmit : handleAddReport}
        customSubmitRender={({ isValid, isSubmitting, submitForm }) => {
          return (
            <div className='bg-white flex flex-row justify-end py-4 mt-6'>
              <Button
                text='Previous'
                variant='text'
                // type="reset"
                classes='border border-gray-90 text-black px-6'
                onClick={setForm.bind(this, 'filter')}
              />
              <Button
                disabled={!isValid}
                //   id={
                //     state?.initialValues
                //       ? elementData.Report.permissions.update
                //       : elementData.Report.permissions.create
                //   }
                text={isEditing ? 'Update Report' : 'Create Report'}
                classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                onClick={submitForm}
                loading={isSubmitting}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default ReportChart;
