import { endpoints } from 'constants/endpoints';
import Request from 'service/api-service/client';

/** @param {unknown[]} data */
export const updateReminders = (data) =>
  Request({
    method: 'patch',
    API: endpoints.reminders.update,
    data,
  });

export const getReminders = () =>
  Request({
    method: 'get',
    API: endpoints.reminders.update,
  });
export const deleteReminders = (id) =>
  Request({
    method: 'delete',
    API: endpoints.reminders.delete(id),
  });
