import React, { useState } from 'react';

import { TabsComponent } from 'components';
import { elementData } from 'elements';
import { generatePermittedTabs } from 'utils';

import BasicInformation from './BasicInformation';
import AllowanceDeductions from './AllowanceDeductions';
import TaxSlabs from './TaxSlabs';
import Taxes from './taxes';
import SalarySheet from './salary-sheet';

const PayRollManagement = (props = {}) => {
  const permissions = props?.permissions || [];
  const [key, setActiveTabKey] = useState('taxes');

  const tabs = [
    {
      key: 'taxes',
      id: elementData.taxes.permissions.view,
      label: `Taxes`,
      Component: Taxes,
      props: {
        permissions,
        id: elementData.taxes.permissions.view,
      },
    },
    {
      key: 'tax_slab',
      id: elementData.taxSlab.permissions.view,
      label: `Tax Slab`,
      Component: TaxSlabs,
      props: {
        permissions,
        id: elementData.taxSlab.permissions.view,
      },
    },
    {
      key: 'allowance_deduction',
      id: elementData.allowance.permissions.view,
      label: `Allowance & Deduction`,
      Component: AllowanceDeductions,
      props: {
        permissions,
        id: elementData.allowance.permissions.view,
      },
    },
    {
      key: 'payroll_setup',
      id: elementData.basicInfo.permissions.view,
      label: `Payroll Setup`,
      Component: BasicInformation,
      props: {
        permissions,
        id: elementData.basicInfo.permissions.view,
      },
    },
    {
      key: 'process_payroll',
      id: elementData.salarySheet.permissions.view,
      label: `Process Payroll`,
      Component: SalarySheet,
      props: {
        permissions,
        id: elementData.salarySheet.permissions.view,
      },
    },
  ];

  return (
    <div className='h-full [&>div]:!h-full'>
      <TabsComponent
        tabPosition={'left'}
        items={generatePermittedTabs(tabs, permissions)}
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
        url={'/hr/payroll?'}
        classes='h-full'
      />
    </div>
  );
};

export default PayRollManagement;
