import { Link } from 'react-router-dom';
import {
  TextInput,
  Uploadbox,
  CustomTextInput,
  PhoneNumberInput,
  DateTime,
  SelectInput,
  Checkbox,
} from 'components';
import {
  fetchLocationData,
  getCityOptions,
  getCountryOptions,
  getStateOptions,
} from 'data/form-data';
import { POS_MIN_AGE } from 'constants/pos';
import dayjs from 'dayjs';

const countryOptions = getCountryOptions();

const breadcrumb = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Pos Agent',
    path: '',
  },
];

const tabsItem = [
  {
    key: 'registered',
    label: `Registered POS`,
  },
  {
    key: 'request',
    label: `Registration Request`,
  },
  {
    key: 'submitted',
    label: `Document Approval`,
  },
];

const getPosManagementFields = ({
  setIfscCode,
  bankDetails,
  error,
  setPinCodeError,
  pincodeError,
}) => ({
  'Personal Details': {
    showAccordionHeader: true,
    fields: [
      {
        label: 'Upload Image',
        name: 'image',
        id: 'image',
        placeholder: 'Upload Image',
        required: true,
        component: Uploadbox,
        type: 'upload',
        accept: ['.png, .jpeg, .jpg,'],
        divClasses: ' col-span-full m-auto w-[300px] text-center',
      },
      {
        label: 'Name',
        name: 'name',
        id: 'name',
        placeholder: 'Name',
        type: 'search',
        required: true,
        component: TextInput,
      },
      {
        label: 'Email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
        type: 'text',
        required: true,
        component: TextInput,
        divClasses: '',
      },
      {
        label: 'Contact',
        name: 'contact',
        id: 'contact',
        placeholder: 'Contact',
        required: true,
        type: 'number',
        maxLength: 10,
        component: TextInput,
      },
      {
        label: 'Date of Birth',
        name: 'dob',
        id: 'dob',
        placeholder: 'YYYY-MM-DD',
        type: 'date',
        required: true,
        showTime: false,
        component: DateTime,
        whetherDateShouldBeDisabled: shouldDateBeDisabledForPosDobField,
        // defaultValue: leastValidDateForPosDOB,
      },
      {
        heading: 'Experience',
        label: 'Working Experience(in months)',
        name: 'experience',
        id: 'experience',
        placeholder: 'Working Experience(in months)',
        type: 'text',
        component: TextInput,
      },
      {
        label: 'Previous work details',
        name: 'detail',
        id: 'detail',
        placeholder: 'Previous work details',
        type: 'text',
        component: TextInput,
      },
    ],
  },
  'Address Details': {
    fields: [
      {
        label: 'Country',
        name: 'country',
        id: 'country',
        placeholder: 'Country',
        type: 'select',
        required: true,
        component: SelectInput,
        options: countryOptions,
        dependency: ['pincode'],
        customChange: (_, __, form) => {
          form?.setFieldValue('city', '');
        },
      },
      {
        label: 'State',
        name: 'state',
        id: 'state',
        placeholder: 'State',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [],
        dependency: ['country'],
        dependantFetchOptions: async (form) => {
          if (!form?.values?.country) return [];
          const country = form?.values?.country;
          const resp = await getStateOptions(country);
          if (resp) {
            return resp;
          } else return [];
        },
      },
      {
        label: 'City/Town',
        name: 'city',
        id: 'city',
        placeholder: 'City/Town',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [],
        dependency: ['country', 'state'],
        dependantFetchOptions: async (form) => {
          if (!form?.values?.country || !form?.values?.state) return [];
          const country = form?.values?.country;
          const state = form?.values?.state;
          const resp = await getCityOptions(country, state);
          if (resp) {
            return resp;
          } else return [];
        },
      },
      {
        label: 'Pincode',
        name: 'pincode',
        id: 'pincode',
        placeholder: 'Pincode',
        type: 'text',
        required: true,
        component: CustomTextInput,
        customOnChange: async ({ field, value, form }) => {
          const zipCode = value;
          const data = await fetchLocationData(zipCode);
          if (data) {
            form?.setFieldValue('country', data?.country);
            form?.setFieldValue('state', data?.state);
            form?.setFieldValue('city', data?.city);
          } else setPinCodeError('Enter a valid pincode');
        },
        error: pincodeError,
        maxLength: 12,
      },
      {
        label: 'Address',
        name: 'address',
        id: 'address',
        placeholder: 'Address',
        type: 'text',
        required: true,
        component: TextInput,
        isMultiline: true,
        maxLength: 60,
        divClasses: 'col-span-2',
      },
    ],
  },
  'Upload Documents': {
    fields: [
      {
        label: 'Aadhar Card',
        name: 'aadhar_number',
        id: 'aadhar_number',
        placeholder: 'Aadhar Number',
        required: true,
        component: Uploadbox,
        type: 'upload',
        accept: ['.png, .jpeg, .jpg,'],
      },
      {
        label: 'Pan Card',
        name: 'pan_number',
        id: 'pan_number',
        placeholder: 'Pan Number',
        required: true,
        component: Uploadbox,
        type: 'upload',
        accept: ['.png, .jpeg, .jpg,'],
      },
      {
        label: 'Upload Bank Statement',
        name: 'bank_statement',
        id: 'bank_statement',
        placeholder: 'Upload Bank Statement',
        required: true,
        component: Uploadbox,
        type: 'upload',
        accept: ['.png, .jpeg, .jpg,'],
      },
      {
        label: 'Upload Educational Proof',
        name: 'educational_proof',
        id: 'educational_proof',
        placeholder: 'Upload Educational Proof',
        required: true,
        component: Uploadbox,
        type: 'upload',
        accept: ['.png, .jpeg, .jpg,'],
      },
    ],
  },
  'Bank Details': {
    fields: [
      {
        label: 'Bank Name',
        name: 'bank_name',
        id: 'bank_name',
        placeholder: 'Bank name',
        type: 'text',
        required: true,
        component: TextInput,
      },
      {
        label: 'Account Number',
        name: 'acc_number',
        id: 'acc_number',
        placeholder: 'Account Number',
        type: 'text',
        required: true,
        maxLength: 17,
        component: TextInput,
      },
      {
        label: 'IFSC Code',
        name: 'ifsc_code',
        id: 'ifsc_code',
        placeholder: 'IFSC Code',
        type: 'ifsc',
        isUpperCase: true,
        required: true,
        maxLength: 11,
        component: CustomTextInput,
        customOnChange: ({ field, value, form }) => {
          setIfscCode(value);
        },
        dependent_field: ({ field, value, form }) => {
          form?.setFieldValue('bank_name', bankDetails?.BANK);
        },
        customIcon: true,
        title: `${bankDetails?.BANK || 'Enter Ifsc first'}, ${bankDetails?.BRANCH || '.'},${
          bankDetails?.CITY || '.'
        }`,
        error: error,
      },
      {
        label: (
          <span>
            By continuing , you agree to our{' '}
            <Link to='/terms-condition' target={'__blank'} className='text-primary-90'>
              Terms & Condition
            </Link>
          </span>
        ),
        name: 'checkbox',
        id: 'checkbox',
        required: true,
        type: 'checkbox',
        component: Checkbox,
        checked: false,
        divClasses: 'col-span-full self-end',
      },
    ],
  },
});

export { breadcrumb, tabsItem, getPosManagementFields };

/** @param {import('dayjs').Dayjs} date */
const shouldDateBeDisabledForPosDobField = (date) => {
  // adding some years makes the date(in the question) in the future implies it should be disabled.
  return date.add(POS_MIN_AGE, 'years').isAfter(dayjs(), 'day');
};
// const leastValidDateForPosDOB = dayjs().subtract(POS_MIN_AGE, 'years');
