import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ScreensRoutes from './routes';
import navigationService from 'service/navigation';
import { MyContextProvider } from 'store/modal';
import runOneSignal from 'service/onesignal/OneSignal';
import { Context } from 'store/context';
import OneSignal from 'react-onesignal';
import { getItem } from 'utils';
import { ConfigProvider } from 'antd';

const useNotificationsListener = () => {
  const {
    notifications: { updateNotifications },
  } = useContext(Context);
  const navigate = useNavigate();
  useEffect(() => {
    const listener = (data) => {
      console.group('onesignal');
      console.info('data being', data);
      console.groupEnd('onesignal');
      if (getItem('AUTH')) updateNotifications();
    };

    runOneSignal().then(() => {
      OneSignal.on('notificationDisplay', listener);
    });
    navigationService.navigation = navigate;

    return () => {
      OneSignal.off('notificationDisplay', listener);
    };
  }, []);
};

const App = () => {
  useNotificationsListener();

  return (
    <MyContextProvider>
      <div className='max-w-[1920px] m-auto'>
        <ConfigProvider {...antdGlobalConfig}>
          <ScreensRoutes />
        </ConfigProvider>
      </div>
    </MyContextProvider>
  );
};

/**
 * @type import('antd/es/config-provider').ConfigProviderProps
 */
const antdGlobalConfig = {
  theme: {
    token: {
      fontFamily: "'Poppins', sans-serif",
      colorBorder: '#d1eaf9',
      colorPrimary: '#0089cf',
    },
  },
};

export default App;
