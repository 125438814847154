import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLayout, Button, CustomModal, FormContainer, Toast } from 'components';
import { getPosContract, posContractMangement } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { prepareFormFieldsData } from 'utils';
import { UploadFields } from './data';
import FileDownloader from './FileDownloader';

const ViewPosContract = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: UploadFields,
  });

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await getPosContract();
    if (resp?.data?.status) {
      const data = resp?.data?.data[0];
      setData(data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const submitContract = useCallback(async (data) => {
    setLoading(true);
    const finalPayload = {
      docs: [
        {
          name: 'contract_doc',
          file: data?.contract,
        },
      ],
    };
    const resp = await posContractMangement({ data: finalPayload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Contract submitted successfully');
      navigate('/pos/pos-review');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, []);

  return (
    <>
      <AuthLayout showImage={false}>
        <div className=' grid gap-6'>
          <div>
            You have to sign the contract to continue.Kindly select appropriate option to sign the
            contract
          </div>
          <div className='flex flex-col gap-3 justify-center items-center'>
            {/* <Button text='Sign Digitally' onClick={navigate.bind(this, '/digital-signature')} /> */}
            <Button text='Sign Manually' onClick={openModal} />
          </div>
        </div>
      </AuthLayout>
      <CustomModal
        title={'Upload Signed Document'}
        open={showModal}
        destroyOnClose={true}
        onCancel={closeModal}
        footer={''}
      >
        <div className='flex flex-col justify-center gap-6'>
          <div className='flex gap-5 justify-center align-middle'>
            <div className='w-full mt-6 rounded-lg'>
              <div className='flex flex-row justify-center'>
                <span className='text-lg 2xl:text-xl font-normal'>Download Contract</span>
              </div>
              <div
                className='w-full h-full flex flex-row justify-center'
                style={{ alignItems: 'center' }}
              >
                <FileDownloader url={data?.contract} />
              </div>
            </div>
            <div className='w-full mt-6 rounded-lg'>
              <div className='flex flex-row justify-center'>
                <span className='text-lg 2xl:text-xl font-normal'>Upload here</span>
              </div>
              <FormContainer
                accordionClass='grid grid-cols-1  gap-4'
                onSubmit={submitContract}
                initialValues={initialValues}
                fields={finalFields}
                customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
                  return (
                    <div className='bg-white flex flex-row justify-center mt-2'>
                      <Button
                        disabled={!isValid}
                        text={'Upload'}
                        variant='primary'
                        classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                        onClick={submitForm}
                        loading={loading}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default ViewPosContract;
