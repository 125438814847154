import React, { useCallback, useEffect, useState } from 'react';
import { Divider, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-autocomplete-input/dist/bundle.css';
import { ContentHeader, Module, Toast } from 'components';
import Template from './template';
import { NOTIFICATION_MSG } from 'constants';
import { getPermission } from 'utils';
import { elementData } from 'elements';
import {
  getLetterManagement,
  patchLetterManagement,
  postLetterManagement,
} from 'service/api-service';

const AddTemplateContainer = (props = {}) => {
  const permissions = props?.permissions || [];
  const [state, setState] = useState();
  const [letter, setLetter] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const updateState = useCallback(
    (key) => (evt) => {
      let value = typeof evt === 'string' || key === 'fields' ? evt : evt.target.value || '';

      if (key !== 'fields') value = value.replace(/\[\[/g, '[');

      setState((s) => ({ ...s, [key]: value }));
    },
    [],
  );
  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await getLetterManagement({ id: id });

    if (resp?.data?.status) {
      setLetter(resp?.data?.data[0]?.name);
      setState(resp?.data?.data[0]?.template);
    } else Toast('error', NOTIFICATION_MSG.error, resp?.msg);
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchData();
    } else setState();
  }, []);

  const save = useCallback(async () => {
    const payload = {
      name: state.name,
      template: state,
    };
    const resp = await postLetterManagement({ data: payload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Success');
      navigate('/official-letters');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.data?.msg || 'Failed to create letter.');
    }
  }, [state]);

  const update = useCallback(async () => {
    const payload = {
      name: state.name,
      template: state,
    };
    const resp = await patchLetterManagement({ data: payload, id: id });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Success');
      navigate('/official-letters');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.data?.msg || 'Failed to create letter.');
    }
  }, [id, state]);

  return (
    <>
      {getPermission(
        permissions,
        id ? elementData?.letter?.permissions?.update : elementData?.letter?.permissions?.create,
      )?.write ? (
        <div className='h-full bg-white flex flex-col'>
          <ContentHeader
            title={
              <>
                <Button
                  onClick={() => navigate(-1)}
                  type='text'
                  size='large'
                  className='-mt-2'
                  icon={<ArrowLeftOutlined />}
                />
                <h2 className='font-bold text-l'>{letter || 'Letter Template Settings'}</h2>
              </>
            }
            showSearch={false}
            children={
              <>
                <Button
                  // disabled={!state?.hasOwnProperty("name")}
                  disabled={
                    state === undefined || state === undefined
                      ? true
                      : state?.hasOwnProperty('name') && state?.hasOwnProperty('fields')
                      ? false
                      : true
                  }
                  onClick={letter ? update : save}
                  size='large'
                  type='primary'
                >
                  Save
                </Button>
              </>
            }
          />
          <Divider className='m-0 mb-4' />
          <div className='flex-1 overflow-hidden'>
            <Template state={state} updateState={updateState} loading={loading} />
          </div>
        </div>
      ) : (
        <Module />
      )}
    </>
  );
};

export default AddTemplateContainer;
