import { TimePicker } from 'antd';
import React from 'react';
import dayjs from 'dayjs';

/** internal deps */
import './style.css';

const TimePickerComponent = ({
  field,
  form,
  placeholder,
  label,
  divClasses,
  disabled,
  defaultValue,
  disablePrev = false,
  disableNext = false,
  format = 'HH:mm',
  ...props
}) => {
  const _onChange = (_, val) => {
    if (props?.customOnChange) {
      props?.customOnChange({ field, val, form });
    }
    if (form?.setFieldValue) return form.setFieldValue(field?.name, val);
    if (props?.customOnChange) {
      props?.customOnChange({ field, val, form });
    }
  };

  return (
    <div id='TimePicker' className={`group relative  ${divClasses}`}>
      <label
        className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xxs text-black ${
          props?.required ? 'required' : ''
        } `}
      >
        {label}
      </label>
      <TimePicker
        id={field?.name}
        name={field?.name}
        placeholder={placeholder}
        className={` w-full border-[#D1EAF9] bg-[#F5FAFD]  text-xxs xl:text-xxs p-2 xl:p-[5px] !focus:border-primary-90 inline-block ${
          disabled && 'border-0'
        }`}
        popupClassName='font-inherit'
        allowClear={false}
        onChange={_onChange}
        disabled={disabled}
        defaultValue={
          form?.initialValues[field?.name]
            ? dayjs(form?.initialValues[field?.name], format)
            : defaultValue
            ? dayjs(defaultValue, format)
            : undefined
        }
        format={format}
        getPopupContainer={(trigger) => trigger.parentNode}
      />
      {form?.errors[field?.name] && form?.touched[field?.name] && (
        <div
          className={`absolute bg-white ${
            props?.extraRightRender ? 'max-w-[230px]' : ''
          } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
        >
          {form?.errors[field?.name]}
        </div>
      )}
    </div>
  );
};

export default TimePickerComponent;
