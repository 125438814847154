import React from 'react';
import { Tabs, Badge } from 'antd';
import './style.css';

const DashboardTabs = ({
  items,
  activeKey,
  setActiveTabKey,
  extraRightRender,
  extraLeftRender,
  extraProps,
}) => {
  const TabsPane = Tabs.TabPane;
  return (
    <div>
      <Tabs
        activeKey={activeKey}
        tabPosition='top'
        onChange={(key) => {
          setActiveTabKey(key);
        }}
        destroyInactiveTabPane={true}
        tabBarExtraContent={{ right: extraRightRender, left: extraLeftRender }}
      >
        {items.map((item) => {
          if (Object.keys(item).length <= 0) return null;
          const { Component } = item;
          return (
            <>
              <TabsPane
                tab={
                  // item.props.badgeCount ? (
                  <span className='text-xs flex '>
                    <p className='mt-1'>{item.label}</p>
                    <sup>
                      <Badge
                        // className='site-badge-count-109 customBadgeStyle'
                        showZero={true}
                        count={item?.props?.badgeCount ? item?.props.badgeCount : 0}
                        style={{
                          padding: '0px 4px',
                          fontSize: '12px',
                          fontWeight: 600,
                          backgroundColor: '#DFDFDF',
                          color: '#000000',
                          borderRadius: '3px',
                          marginLeft: '8px',
                          marginTop: '5px',
                          scale: '1.2',
                        }}
                      />
                    </sup>
                  </span>
                  // )
                  // : (
                  // <span className='text-xs'>{item.label}</span>
                  // )
                }
                key={item.key}
              >
                <span className='transition-all duration-500 '>
                  <Component {...item?.props} {...extraProps} />
                </span>
              </TabsPane>
            </>
          );
        })}
      </Tabs>
    </div>
  );
};

export default DashboardTabs;
