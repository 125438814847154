import { FormContainer, SelectInput, TextInput, DateTime } from 'components';
import dayjs from 'dayjs';
import React from 'react';
import { prepareFormFieldsData } from 'utils';

var doSubmit = null;

const ChildSavingForm = ({ formData, onSubmit }) => {
  const maxDate = dayjs().subtract(18, 'years');

  const quoteCustomerFields = {
    'Child Saving Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Date of Birth',
          name: 'dob',
          id: 'dob',
          placeholder: 'Enter Date',
          type: 'date',
          showTime: false,
          component: (props) => <DateTime {...props} disabledDate={maxDate} />,
        },
        {
          label: 'Child Date of Birth',
          name: 'c_dob',
          id: 'c_dob',
          placeholder: 'Enter Date',
          type: 'date',
        },
        {
          label: 'Sum Assured Amount',
          name: 'income',
          id: 'income',
          placeholder: 'Sum Assured Amount',
          type: 'select',
          component: SelectInput,
          options: [
            {
              label: '50,000-100,000',
              value: '100000',
            },
            {
              label: '100,000-200,000',
              value: '200000',
            },
            {
              label: '200,000-300,000',
              value: '300000',
            },
          ],
        },
        {
          label: 'Amount',
          name: 'amount',
          id: 'amount',
          placeholder: 'Amount',
          type: 'text',
          component: TextInput,
          maxLength: 30,
        },
        {
          label: 'Get Benefit Payout after policy term(PT)',
          name: 'benefit',
          id: 'benefit',
          placeholder: 'Select Benefits',
          type: 'select',
          searchable: true,
          component: SelectInput,
          options: [
            {
              label: '50,000-100,000',
              value: '100000',
            },
            {
              label: '100,000-200,000',
              value: '200000',
            },
            {
              label: '200,000-300,000',
              value: '300000',
            },
          ],
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: quoteCustomerFields,
    defaultValues: { dob: maxDate.format('YYYY-MM-DD'), ...formData },
  });

  return (
    <FormContainer
      returnFormatted={false}
      accordionClass='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-5 pt-0 pt-0'
      fields={finalFields}
      initialValues={initialValues}
      onSubmit={onSubmit}
      customSubmitRender={({ submitForm }) => {
        doSubmit = submitForm;
        return null;
      }}
    />
  );
};

const submit = () => {
  if (doSubmit) doSubmit();
};

export default {
  submit,
  Form: ChildSavingForm,
};
