import { FormContainer } from 'components';
import Button from 'components/button';
import React, { useState } from 'react';
import { prepareFormFieldsData } from 'utils';
import { sendEmailFields } from './data';
import permissions from 'components/permissions';
import { elementData } from 'elements';
// import { Form } from "antd";

const SendEmail = ({ toggleEmailPopup, email }) => {
  //   const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [initialVals, finalFields] = prepareFormFieldsData({
    fields: sendEmailFields,
    defaultValues: { email: email },
  });

  const onSendEmail = () => {};
  return (
    <div>
      <FormContainer
        accordionClass='grid grid-cols-2  gap-8'
        onSubmit={onSendEmail}
        initialValues={initialVals}
        fields={finalFields}
        customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
          return (
            <div className='bg-white flex flex-row justify-end py-4 mt-6'>
              <Button
                text='Cancel'
                variant='text'
                type='reset'
                classes='border border-gray-90 text-black px-6'
                onClick={() => {
                  resetForm();
                  toggleEmailPopup();
                }}
              />
              <Button
                disabled={!isValid}
                permissions={permissions}
                id={elementData.Leads.permissions.create}
                text={'Send Email'}
                type='button'
                variant='primary'
                classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                onClick={submitForm}
                loading={loading}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default SendEmail;
