import React from 'react';
// import FileViewer from "react-file-viewer";

/**
 * @deprecated
 */

const FilePreview = ({ fileType, filePath }) => {
  return (
    <div>
      {/* <FileViewer
        fileType={fileType}
        filePath={filePath}
        // errorComponent={CustomErrorComponent}
        // onError={this.onError}
      /> */}
    </div>
  );
};

export default FilePreview;
