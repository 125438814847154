import { ContentHeader, TabsComponent, ViewData } from 'components';
import { elementData } from 'elements';
import { SecureComponent } from 'permission-provider';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VIewPolicy from 'screens/customers/policy/view-policy';
import { generatePermittedTabs } from 'utils';
import { breadcrumbs, formatOverViewPosData, SECTION_DATA_VIEW } from './data';
import OverviewPos from './overview';
import Leads from './leads';
import { prepareViewData } from 'utils/common';

const ViewPosManagement = ({ permissions = {} }) => {
  /** vars */
  let navigate = useNavigate();
  const { state } = useLocation();
  const data = state?.initialValues;

  /** states */
  // const [data, setData] = useState({});
  const [key, setActiveTabKey] = useState('1');

  const tabs = [
    {
      key: '1',
      id: elementData.pos.permissions.view,
      label: `Overview`,
      Component: OverviewPos,
      props: {
        permissions,
        id: elementData.pos.permissions.view,
        data: formatOverViewPosData(data),
        classes: 'bg-white rounded-2xl p-4',
        showGoToBack: true,
        setActiveTabKey: setActiveTabKey,
        pos_id: data?.id,
        goBack: () => navigate(-1),
      },
    },
    {
      key: '2',
      id: elementData.pos.permissions.view,
      label: `Details`,
      Component: ViewData,
      props: {
        permissions,
        id: elementData.pos.permissions.view,
        data: prepareViewData({
          data: data,
          format: SECTION_DATA_VIEW,
          keys: { acc_number: 'Account Number', ifsc_code: 'IFSC Code', dob: 'Date of Birth' },
          allowToReturnObjects: true,
          phone: 'contact',
          email: 'email',
        }),
        classes: 'bg-white rounded-2xl p-4',
        showGoToBack: true,
        goBack: () => navigate(-1),
      },
    },
    {
      key: '3',
      id: [elementData.task.name, elementData.meeting.name],
      label: `Policy`,
      Component: VIewPolicy,
      props: {
        user: { id: data?.id, name: data?.name },
        type: 'pos',
        id: elementData.policy.name,
        permissions: permissions,
      },
    },
    {
      key: '4',
      label: `Leads`,
      id: elementData.Leads.name,
      Component: Leads,
      props: {
        pos_id: data?.id,
        permissions: permissions,
        id: elementData.Leads.name,
      },
    },
  ];
  return (
    <section className='grid gap-3'>
      <ContentHeader data={breadcrumbs} showSearch={false} />
      <TabsComponent
        items={generatePermittedTabs(tabs, permissions)}
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
      />
    </section>
  );
};

export default SecureComponent(ViewPosManagement);
