import { TextInput } from 'components';

const addFolderFields = {
  'Folder Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Folder Name',
        name: 'name',
        id: 'name',
        placeholder: 'Folder Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
        divClases: 'col-span-full',
      },
      {
        label: 'Folder Description',
        name: 'description',
        id: 'description',
        placeholder: 'Folder Description.',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        maxLength: 300,
        divClases: 'col-span-full',
        rows: 2,
      },
    ],
  },
};

const addFolderInitials = {
  name: '',
  description: '',
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Reports',
    path: '',
  },
];

const dummyData = [
  {
    type: 'Life',
    name: 'Monthly Sales Report',
    module: 'Sales',
    folder: 'Q1 2023',
    owner: 'John Doe',
  },
  {
    type: 'Life',
    name: 'Monthly Sales Report',
    module: 'Sales',
    folder: 'Q1 2023',
    owner: 'Jane Doe',
  },
  {
    type: 'Non Life',
    name: 'Quarterly Marketing',
    module: 'Marketing',
    folder: 'Q3 2021',
    owner: 'Bob Johnson',
  },
  {
    type: 'Life',
    name: 'Employee Performance Review',
    module: 'Human Resources',
    folder: 'Q2 2022',
    owner: 'Alice Lee',
  },
  {
    type: 'Non Life',
    name: 'Customer Satisfaction Survey Results',
    module: 'Customer Service',
    folder: 'Q2 2021',
    owner: 'David Kim',
  },
];

export { addFolderFields, addFolderInitials, breadcrumbObj, dummyData };
