import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CompanyPolicyManagement } from 'service/api-service';
import { ContentHeader } from 'components';

export const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Company Policies',
    path: '/hr/company-policies',
  },
  {
    name: 'View',
    path: '',
  },
];

const ViewCompanyPolicy = ({ permissions }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState();

  const fetchPolicy = async () => {
    setLoading(true);
    try {
      const res = await CompanyPolicyManagement({ method: 'get', id });
      if (res?.data?.status) setDataSource(res?.data?.data);
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPolicy();
  }, []);
  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        data={breadcrumbObj}
        showSearch={false}
        // buttonId={elementData?.leaves_type?.permissions?.create}
      />
      <div className='bg-white p-4 grid gap-4'>
        <div>
          <h3 className='text-xxs font-semibold opacity-60'>Policy Title</h3>
          <span>{dataSource?.title || '-'}</span>
        </div>
        <div>
          <h3 className='text-xxs font-semibold opacity-60'>Policy Description</h3>
          <div className='h-20' dangerouslySetInnerHTML={{ __html: dataSource?.des }}></div>
        </div>
      </div>
    </div>
  );
};

export default ViewCompanyPolicy;
