import Request from 'service/api-service/client';

export const getLetterManagement = async (props = { id: '', query: '' }) => {
  const resp = await Request({
    method: 'get',
    API: `form/?q=${props.query || ''}&table_id=${props?.id || ''}`,
  });
  return resp;
};

export const postLetterManagement = async (props = { data: '', id: '', query: '' }) => {
  const resp = await Request({
    method: 'post',
    API: `form/?q=${props.query || ''}&id=${props?.id || ''}`,
    data: props?.data,
  });
  return resp;
};

export const deleteLetterManagement = async (props = { id: '' }) => {
  const resp = await Request({
    method: 'delete',
    API: `form/?id=${props.id}`,
  });
  return resp;
};

export const patchLetterManagement = async (props = { data: '', id: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `form/?id=${props.id}`,
    data: props?.data,
  });
  return resp;
};
