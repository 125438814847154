import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { InsuranceLogo } from 'assets/icons';
import { FormContainer, Toast, Button } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { SecureComponent } from 'permission-provider';
import { posRegister } from 'service/api-service';
import { prepareFormFieldsData } from 'utils';
import { getQueryParams } from 'utils/common';
import useBankDetails from 'data/form-data/ifsc';
import { getPosManagementFields } from '../posManagement/data';

const maxDate = dayjs().subtract(18, 'years');

const PosSignup = (props = {}) => {
  /** state */
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { ref } = getQueryParams() || {};
  const [ifscCode, setIfscCode] = useState();
  const { bankDetails, error } = useBankDetails(ifscCode);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: getPosManagementFields({
      maxDate,
      setIfscCode,
      bankDetails,
      error,
    }),
    defaultValues: { dob: maxDate.format('YYYY-MM-DD') },
  });

  const onPosSignup = useCallback(
    async (data) => {
      setLoading(true);
      const address = {
        city: data?.city,
        state: data?.state,
        country: data?.country,
        pincode: data?.pincode,
        address: data?.address,
      };
      const finalPayload = {
        ...data,
        pos_agent: true,
        ref: ref,
        address,
        docs: [
          {
            name: 'aadhar_number',
            file: data?.aadhar_number,
          },
          {
            name: 'pan_number',
            file: data?.pan_number,
          },
          {
            name: 'bank_statement',
            file: data?.bank_statement,
          },
          {
            name: 'educational_proof',
            file: data?.educational_proof,
          },
        ],
      };
      const resp = await posRegister({ method: 'post', data: finalPayload });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        navigate('/pos-login');
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
    },
    [ref],
  );

  return (
    <>
      <div className='h-screen overflow-y-auto flex flex-col items-center bg-grey-light'>
        <div className='w-full rounded-xl shadow-lg bg-white'>
          <div className='w-fit mt-6 m-auto'>
            <InsuranceLogo svgClass='w-[90px] h-16' />
          </div>

          <div className='flex justify-center px-14 flex-col mt-2'>
            <span className='text-l xl:text-xl font-medium'>Register POS</span>
            <span className='text-grey-90 text-x pb-[30px] font-medium'>
              In order to create your pos account, please fill out details below.
            </span>
          </div>
          <div className='px-14 pb-14 py-0'>
            <FormContainer
              accordionClass='grid grid-cols-3 gap-8'
              onSubmit={onPosSignup}
              initialValues={initialValues}
              fields={finalFields}
              customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, values }) => {
                return (
                  <div className='bg-white flex flex-row justify-center py-4'>
                    <Button
                      disabled={values?.checkbox !== true}
                      loading={loading}
                      text={'Register'}
                      variant='primary'
                      classes={`ml-6 px-8 text-xs lg:text-lg xl:text-xl py-2 px-4 xl:px-9 py-3 2xl:py-3 ${
                        isSubmitting ? 'bg-grey' : 'bg-black'
                      }`}
                      onClick={submitForm}
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SecureComponent(PosSignup);
