/** external deps */
import React from 'react';
/** internal deps */
import { Search } from 'assets/icons';
import { TextInput } from 'components';
import './style.css';

const SearchComponent = ({ onChange, className, placeholder, extraRightRender, ...props }) => {
  return (
    <TextInput
      {...props}
      placeholder={placeholder || 'Search'}
      onChange={onChange}
      type={extraRightRender ? 'text' : 'search'}
      leftIcon={
        <Search svgClass='w-3.5 h-3.5 2xl:w-6 2xl:w-6 stroke-grey-90 group-focus-within:stroke-primary-90 !bg-transparent -ml-1' />
      }
      extraRightRender={extraRightRender}
      name={extraRightRender ? 'Custom Search' : 'Search'}
      InputClasses='!rounded-md !w-52 h-9 2xl:h-10 !bg-transparent !border-[0.5px] !border-[#ABABAB] !rounded-[7px] !placeholder:text-[#ABABAB] text-14px'
    />
  );
};

export default SearchComponent;
