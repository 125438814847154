const { TextInput, Uploadbox } = require('components');
export const CompanyFields = {
  'Company Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Upload Company Logo',
        name: 'logo',
        id: 'logo',
        placeholder: 'Company Logo',
        // required: true,
        component: Uploadbox,
        accept: ['.png, .jpeg, .jpg, .pdf, .svg'],
        divClasses: 'col-span-full w-[280px] text-center m-auto',
      },
      {
        label: 'Company Name',
        name: 'name',
        id: 'name',
        placeholder: 'Enter Company Name',
        type: 'text',
        wantValidation: true,
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Company Email',
        name: 'email',
        id: 'email',
        placeholder: 'Enter Company Email',
        type: 'text',
        required: true,
        component: TextInput,
        // maxLength: 30,
      },
      {
        label: 'Contact',
        name: 'contact',
        id: 'contact',
        placeholder: 'Enter Contact',
        type: 'tel',
        required: true,
        component: TextInput,
        maxLength: 10,
      },
      {
        label: 'Company Address',
        name: 'address',
        id: 'address',
        placeholder: 'Enter Company Address',
        type: 'text',
        required: true,
        component: TextInput,
        // maxLength: 14,
      },
      {
        label: 'Bank Name',
        name: 'bank_name',
        id: 'bank_name',
        placeholder: 'Enter Bank Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 50,
      },
      {
        label: 'Account Name',
        name: 'acc_name',
        id: 'acc_name',
        placeholder: 'Enter Account Name',
        type: 'text',
        required: true,
        component: TextInput,
        // maxLength: 14,
      },
      {
        label: 'Account Number',
        name: 'acc_number',
        id: 'acc_number',
        placeholder: 'Enter Account Number',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 17,
      },
      {
        label: 'IFSC Code',
        name: 'ifsc_code',
        id: 'ifsc_code',
        placeholder: 'Enter IFSC Code',
        type: 'text',
        required: true,
        component: TextInput,
        isUpperCase: true,
        // maxLength: 14,
      },
      {
        label: 'GST Number',
        name: 'gst',
        id: 'gst',
        placeholder: 'Enter GST Number',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 15,
        isUpperCase: true,
      },
      {
        label: 'HSN Code',
        name: 'hsn_code',
        id: 'hsn_code',
        placeholder: 'Enter HSN Code',
        type: 'text',
        required: true,
        component: TextInput,
        // minLenght: 6,
        maxLength: 12,
      },
    ],
  },
};
