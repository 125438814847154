import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import { AbsentIcon, EmpDashIcon, EmployeeHrIcon, OnLeaveIcon } from 'assets/icons';
import { getTodaysAttendance } from 'service/api-service';
import { DisplayCard } from 'components';
import { StatsIconClass } from 'utils/common';
import UpcomingInterview from './UpcomingInterview';
import { link } from 'assets/hr_icons';

const Statics = (props = {}) => {
  const date = props?.date || '';
  /* states */
  const [dataSource, setdataSource] = useState();

  /* func */
  const fetchData = async () => {
    const resp = await getTodaysAttendance({ date });
    if (resp?.data?.status) {
      const dataCount = resp?.data?.data;
      setdataSource(dataCount);
    }
  };

  let data = [
    {
      id: 'total_employee',
      text: 'Employees',
      icon: <EmpDashIcon svgClass={StatsIconClass} />,
      total: dataSource?.total_employee || 0,
      showPercent: 0,
      link: '/hr/employees',
      onClick: true,
    },
    {
      id: 'leave',
      text: 'On leave today',
      icon: <OnLeaveIcon svgClass={StatsIconClass} />,
      total: dataSource?.leaves || 0,
      showPercent: 0,
      link: '/hr/leaves?tab=approved',
      onClick: true,
    },
    {
      id: 'interview',
      text: 'Interviews today',
      icon: <OnLeaveIcon svgClass={StatsIconClass} />,
      total: dataSource?.interviews || 0,
      showPercent: 0,
      link: '/hr/job-interviews',
      onClick: true,
    },
    {
      id: 'ticket_count',
      text: 'Tickets',
      icon: <OnLeaveIcon svgClass={StatsIconClass} />,
      total: dataSource?.ticket_count || 0,
      showPercent: 0,
      link: '/hr/tickets',
      onClick: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, [date]);

  return (
    <div className='flex gap-4'>
      {data.map((data, index) => (
        <motion.div
          className='w-full'
          key={index}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 0.6 }}
        >
          <DisplayCard key={index} {...data} />
        </motion.div>
      ))}
    </div>
  );
};

export default Statics;
