import { useEffect, useState } from 'react';
import { getPos } from 'service/api-service';
import EarningTable from './earning-table';
import { InsurerSideBar } from 'components';

const PosEarning = (props = {}) => {
  const permissions = props?.permissions || [];
  const [agent, setAgent] = useState();
  const [barLoading, setBarLoading] = useState(false);
  const [sideBarData, setSideBarData] = useState([]);

  const getAllPos = async () => {
    setBarLoading(true);
    const resp = await getPos();
    setSideBarData(resp?.data?.data);
    if (resp?.data?.data) {
      setBarLoading(false);
    }
  };
  useEffect(() => {
    getAllPos();
  }, []);

  return (
    <div className='flex justify-between gap-4'>
      <InsurerSideBar
        checkbox={false}
        setAgent={setAgent}
        agent={agent}
        data={sideBarData}
        header={'Pos'}
        loading={barLoading}
        width={300}
      />

      <EarningTable agent={agent} permissions={permissions} />
    </div>
  );
};

export default PosEarning;
