/** external deps */
import React, { useEffect, useState } from 'react';
import { Space } from 'antd';

/** internal deps */
import { elementData } from 'elements';
import { SecureComponent } from 'permission-provider';
import {
  ContentHeader,
  CustomModal,
  FormContainer,
  IconButton,
  TableComponent,
  Toast,
  Button,
} from 'components';
import { departmentManagement } from 'service/api-service';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { addDepartmentFields, addDepartmentInitials, breadcrumbObj, columns } from './data';
import { NOTIFICATION_MSG } from 'constants';
import { getEditedFields } from 'utils';
import { rowCommonClass } from 'utils/common';

const Department = (props = {}) => {
  const permissions = props?.permissions || [];

  /* States */
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [tableData, setTableData] = useState([]);
  const [editInitials, setInitials] = useState();

  /* funcs */
  const toggleDeleteModal = () => setDeleteModal((pre) => !pre);
  const toggleAddDepartment = () => {
    setInitials(undefined);
    setShowAddModal((pre) => !pre);
  };

  /* Get Table Data */
  const getDepartmentData = async () => {
    setLoading(true);
    const resp = await departmentManagement();
    if (resp?.data?.status) {
      setTableData(resp?.data?.data);
    }
    setLoading(false);
  };

  /*  Add Department */
  const handleAddDepartment = async (data) => {
    setLoading(true);
    const finalPayload = {
      ...data,
      head: data?.assigned_to_employee,
    };
    const resp = await departmentManagement({ method: 'post', data: finalPayload });
    if (resp?.data?.status) {
      getDepartmentData();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
    setShowAddModal(false);
  };

  /* Delete Department */
  const handleDeleteDepartment = async () => {
    const id = selectedRow?.id;
    if (!id) return;
    setLoading(true);
    const resp = await departmentManagement({ method: 'delete', id });
    if (resp?.data?.status) {
      getDepartmentData();
      Toast(
        'success',
        NOTIFICATION_MSG.success,
        resp?.data?.msg || 'Department deleted successfully',
      );
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteModal();
    setLoading(false);
  };

  const handleEditDepartment = async (data) => {
    setLoading(true);
    const prev = { name: editInitials?.name, head: editInitials?.assigned_to_employee };
    const updatedData = { name: data?.name, head: data?.assigned_to_employee };

    const editFields = getEditedFields({
      initialValues: prev,
      updatedData: updatedData,
    });

    if (Object.keys(editFields).length) {
      const resp = await departmentManagement({
        method: 'patch',
        id: selectedRow?.id,
        data: editFields,
      });
      toggleAddDepartment();
      if (resp?.data?.status) {
        getDepartmentData();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save.');
    }
  };

  const handleEditClick = (record) => {
    const finalInitials = {
      name: record?.name,
      assigned_to_employee: record?.head?.name,
    };
    setInitials(finalInitials);
    setSelectedRow(record);
    setShowAddModal(true);
  };

  useEffect(() => {
    getDepartmentData();
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        showSearch={false}
        // data={breadcrumbObj}
        title={<p className='font-semibold text-s'>Department Listing</p>}
        btnText='+ Department'
        permissions={permissions}
        buttonId={elementData.department.permissions.create}
        onClick={toggleAddDepartment}
      />
      <TableComponent
        loading={loading}
        columns={[
          ...columns,
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
              <div className='flex gap-2 justify-end'>
                <IconButton
                  title='Edit'
                  permissions={permissions}
                  id={elementData.department.permissions.update}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    handleEditClick(record);
                  }}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Delete'
                  permissions={permissions}
                  id={elementData.department.permissions.delete}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    setSelectedRow(record);
                    toggleDeleteModal();
                  }}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton>
              </div>
            ),
          },
        ]}
        dataSource={tableData}
        pageSize={10}
        total={tableData.length}
        getRowClassName={rowCommonClass}
      />

      {/* add department modal */}
      <CustomModal
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddDepartment}
        footer={''}
        title={editInitials ? 'Edit Department' : 'Add Department'}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10'>
            {editInitials
              ? 'Edit Department details'
              : 'To add a new department, enter the details of the department in the input field below.'}
          </span>
          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? handleEditDepartment : handleAddDepartment}
            initialValues={editInitials || addDepartmentInitials}
            fields={addDepartmentFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddDepartment();
                    }}
                  />
                  <Button
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.department.permissions.update
                        : elementData.department.permissions.create
                    }
                    text={editInitials ? 'Update Department' : 'Add Department'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting || !isValid ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                    disabled={!isValid}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* Delete Modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the Department details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              permissions={permissions}
              id={elementData.department.permissions.delete}
              text='Delete Department'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={handleDeleteDepartment}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(Department);
