/** external deps */
import React, { useState } from 'react';
import { Space } from 'antd';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
} from 'components';
import { addTaxFields, breadcrumbObj } from './data';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { prepareFormFieldsData } from 'utils';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { taxes } from './dummy';
import { rowCommonClass } from 'utils/common';

const Taxes = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  // const [selectedTax, setSelectedTax] = useState();
  const [tableData, setTableData] = useState(taxes);
  const [loading, setLoading] = useState(false);
  const [filteredTaxes, setfilteredTaxes] = useState([]);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addTaxFields,
  });

  const columns = [
    {
      key: 'tax_name',
      title: 'Tax Name',
      dataIndex: 'tax_name',
    },
    {
      key: 'tax_percentage',
      title: 'Tax Percentage(%)',
      dataIndex: 'tax_percentage',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => (
        <div className='flex items-center'>
          <div
            className={`w-3 h-3 rounded-md mr-2 ${
              record.status === 'Active' ? 'bg-green-90' : 'bg-yellow'
            }`}
          ></div>
          {record.status}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.tax.permissions.delete}
            onClick={() => {
              // setSelectedTax(record);
              toggleDeleteTax();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.tax.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  /* funcs */
  const onAddTax = async (data) => {};

  const onDeleteTax = async () => {};

  const onEditTax = async (data) => {};

  const toggleAddTax = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  const toggleDeleteTax = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
  };

  const onEditPress = (record) => {
    setInitials(record);
    // setSelectedTax(record);
    setAddModal(true);
  };

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        data={breadcrumbObj}
        btnText='+ Tax'
        buttonId={elementData?.tax?.permissions?.create}
        onClick={toggleAddTax}
      />
      <TableComponent
        loading={loading}
        columns={columns}
        dataSource={searchQuery ? filteredTaxes : tableData}
        getRowClassName={rowCommonClass}
      />

      {/* add tax modal */}
      <CustomModal
        title={editInitials ? 'Edit Tax' : 'Add Tax'}
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddTax}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 text-grey-80'>
            {editInitials
              ? 'Edit tax details'
              : 'To add a new tax, enter the details of the tax in the input field below.'}
          </span>
          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditTax : onAddTax}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddTax();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.tax.permissions.update
                        : elementData.tax.permissions.create
                    }
                    text={editInitials ? 'Update Tax' : 'Add Tax'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* delete tax confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteTax}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the tax?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteTax}
            />
            <Button
              text='Delete Tax'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteTax}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(Taxes);
