import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { Button, Confetti, FormContainer, Toast } from 'components';
import { policyManagement, otherSourcepolicy, addPolicy } from 'service/api-service';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { addPolicyFields, parsePayload } from './data';
import { useLeads } from 'screens/leads/store';
import { NOTIFICATION_MSG } from 'constants';
import { Context } from 'store/context';
import { elementData } from 'elements';
import './styles.css';

const CreatePolicy = (props = {}) => {
  const permissions = props?.permissions || {};
  const { user } = useContext(Context);

  const proposals_Id = useLeads((state) => state?.proposals_Id);
  let exit = false;

  const { state } = useLocation();
  const isEditing = state?.initialValues ? true : false;
  const policyType = state?.activekey;
  const [respData, setRespData] = useState([]);
  const params = useParams();
  let playRef;

  const [initialValues, finalFields] = prepareFormFieldsData({
    // fields: policyType === "all" ? addPolicyFields : addOtherPolicyFields,
    fields: addPolicyFields({
      respData,
      setRespData,
      selectedProduct: props?.selectedProduct,
    }),
    fieldsToExclude: user?.user_type === 'employee' ? ['employee'] : [],
    defaultValues: {
      inquiry: proposals_Id?.leadId,
      insurance_category: props?.selectedProduct?.product_type?.ins_category?.id,
      product_type: props?.selectedProduct?.product_type?.id,
      policy_type: 'new',
      issue_date: dayjs().format('YYYY-MM-DD'),
      country: 'IN',
      ncb: respData?.quote?.filter(
        (d) =>
          d?.id === props?.selectedProduct?.s_insurer?.id &&
          d?.product?.id === props.selectedProduct?.product_type?.id,
      )?.[0]?.ncb,
      insurer: respData?.quote?.filter(
        (d) =>
          d?.id === props?.selectedProduct?.s_insurer?.id &&
          d?.product?.id === props.selectedProduct?.product_type?.id,
      )?.[0]?.id,
      premium: respData?.quote?.filter(
        (d) =>
          d?.id === props?.selectedProduct?.s_insurer?.id &&
          d?.product?.id === props.selectedProduct?.product_type?.id,
      )?.[0]?.premium,
      sum_insured: respData?.quote?.filter(
        (d) =>
          d?.id === props?.selectedProduct?.s_insurer?.id &&
          d?.product?.id === props.selectedProduct?.product_type?.id,
      )?.[0]?.sum,
      customer: respData?.customer,
      contact: respData?.contact?.id,
      contact_no: respData?.customer?.contact_number,
      email_address: respData?.customer?.email_address,
      branch: respData?.customer?.branch_of?.id,
      ...state?.defaultValues,
    },
  });

  const navigate = useNavigate();

  const onAddPolicy = async (payload) => {
    // if (policyType === 'all') {
    const billing_address = {
      address: payload?.address,
      state: payload?.state,
      city: payload?.city,
      pincode: payload?.pincode,
      country: payload?.country,
    };
    const customer = {
      id: payload?.customer,
      contact_number: payload?.contact_no,
      email_address: payload?.email_address,
      nature_of_business: payload?.bs_nature,
    };
    const finalPayload = {
      ...payload,
      g_premium: payload && payload?.g_premium === '' ? 0 : payload?.g_premium,
      sum_insured: payload && payload?.sum_insured === '' ? 0 : payload?.sum_insured,
      premium: payload && payload?.premium === '' ? 0 : payload?.premium,
      gst_pre: payload && payload?.gst_pre === '' ? 0 : payload?.gst_pre,
      other_fees: payload && payload?.other_fees === '' ? 0 : payload?.other_fees,
      inquiry: proposals_Id?.id,
      category: payload?.insurance_category,
      bill_to: payload?.customer_bill_to,
      sell_id: user?.id,
      customer,
      address: billing_address,
      employee: user?.user_type === 'employee' ? user?.id : payload.employee,
    };

    const resp = await addPolicy({
      data: finalPayload,
    });

    if (resp?.data?.status) {
      const currSelectedProduct = props.products.filter((d) => d.id !== props.selectedProduct.id);
      props.setProducts(currSelectedProduct);
      props.setSelectedProduct(currSelectedProduct[0]?.id);
      playPopper();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Policy Created Successfully');
      setTimeout(() => {
        if (exit || props.products.length === 1) {
          navigate('/policy');
        }
      }, 2000);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    // } else {
    //   const finalPayload = {
    //     ...payload,
    //     category: payload?.insurance_category,
    //     sell_id: user?.id,
    //     other: true,
    //   };
    //   const resp = await otherSourcepolicy({
    //     data: finalPayload,
    //     method: 'post',
    //   });

    //   if (resp?.data?.status) {
    //     Toast(
    //       'success',
    //       NOTIFICATION_MSG.success,
    //       resp?.data?.msg || 'Policy Created Successfully',
    //     );
    //     navigate(-1);
    //   } else {
    //     Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    //   }
    // }
  };
  const onEditPolicy = async (payload) => {
    const editFields = getEditedFields({
      initialValues: state?.initialValues,
      updatedData: payload,
    });

    if (Object.keys(editFields).length) {
      const finalPayload = parsePayload(editFields);
      // if (policyType === 'all') {
      const resp = await policyManagement({
        data: finalPayload,
        method: 'patch',
        id: state?.initialValues?.id,
      });

      if (resp?.data?.status) {
        playPopper();
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.data?.msg || 'Policy updated Successfully',
        );
        setTimeout(() => {
          navigate('/policy');
        }, 2000);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      // } else {
      //   const resp = await otherSourcepolicy({
      //     data: finalPayload,
      //     method: 'patch',
      //     id: state?.initialValues?.id,
      //   });

      //   if (resp?.data?.status) {
      //     Toast(
      //       'success',
      //       NOTIFICATION_MSG.success,
      //       resp?.data?.msg || 'Policy updated Successfully',
      //     );
      //     navigate(-1);
      //   } else {
      //     Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      //   }
      // }
    } else {
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save.');
    }
  };

  const playPopper = () => {
    playRef.play();
    playRef.play();
    playRef.play();
    playRef.play();
    playRef.play();
    playRef.play();
  };

  return (
    <div>
      <Confetti
        ref={(ref) => {
          playRef = ref;
        }}
      />
      <div
        id='leads'
        className='flex flex-col bg-white rounded-lg min-h-[68vh] max-h-[70vh] overflow-y-auto'
      >
        <div className='flex justify-between'>
          <div className='flex flex-col px-2'>
            <span className='font-medium text-s 2xl:text-l'>
              {state?.initialValues ? 'Edit' : 'Add'} Policy
            </span>
            <span className='text-xxs text-grey-80'>
              {state?.initialValues
                ? 'Edit details below'
                : 'To add a new Policy, enter the details of the Policy in the input field below.'}
            </span>
          </div>
        </div>
        <FormContainer
          accordionClass='grid grid-cols-3 gap-8 '
          onSubmit={isEditing ? onEditPolicy : onAddPolicy}
          initialValues={isEditing ? state?.initialValues : initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, disableForm }) => {
            return (
              <>
                <div className='flex justify-end pr-2 py-4 gap-3'>
                  <Button
                    text='Cancel'
                    variant='text'
                    classes='!bg-[#C3C3C3] !text-black '
                    type='reset'
                    onClick={() => navigate('/lead')}
                  />
                  <Button
                    text={'Prev'}
                    variant='text'
                    onClick={() => navigate(`/lead/${params.mode}/${params.id}/3`)}
                    // loading={isSubmitting}
                    classes=' !text-white !bg-[#0089CF]'
                  />
                  <Button
                    // id={
                    //   state?.initialValues
                    //     ? elementData.policy.permissions.update
                    //     : elementData.policy.permissions.create
                    // }
                    // permissions={permissions}
                    disabled={state?.policyType === 'all' ? disableForm || !isValid : ''}
                    text={state?.initialValues ? 'Update' : 'Create'}
                    variant='primary'
                    classes='!py-1'
                    onClick={() => {
                      exit = false;
                      submitForm();
                    }}
                    loading={isSubmitting}
                  />
                  {props?.products && props?.products.length > 1 && (
                    <Button
                      // id={
                      //   state?.initialValues
                      //     ? elementData.policy.permissions.update
                      //     : elementData.policy.permissions.create
                      // }
                      // permissions={permissions}
                      disabled={state?.policyType === 'all' ? disableForm || !isValid : ''}
                      text='Create and Exit'
                      variant='primary'
                      classes='!py-1 !bg-green'
                      onClick={() => {
                        exit = true;
                        submitForm();
                      }}
                      loading={isSubmitting}
                    />
                  )}
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
};

export default CreatePolicy;
