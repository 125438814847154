import { Checkbox, Col, InputNumber, Row, Spin } from 'antd';
import { useEffect } from 'react';
import { useReminders } from './store';
import './typedefs';
import { DeleteFormIcon } from 'assets/icons';

const Reminders = () => {
  /** @type {State['policy']} */
  const policies = useReminders((state) => state.policy);
  /** @type {State['leads']} */
  const leads = useReminders((state) => state.leads);
  /** @type {State['quotation']} */
  const quotations = useReminders((state) => state.quotation);
  const isFetching = useReminders((state) => state.isFetching);
  // const error = useReminders((state) => state.error);
  const init = useReminders((state) => state.init);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div>
      <h2 className='text-sm font-semibold'>Reminders</h2>
      <section className='divide-y-2 divide-gray-600 divide-dashed'>
        {isFetching ? (
          <div className='flex justify-center mt-[40vh]'>
            <Spin size='large' />
          </div>
        ) : (
          <>
            <ReminderSection data={policies} kind='policy' />
            <ReminderSection data={leads} kind='leads' />
            <ReminderSection data={quotations} kind='quotation' />
          </>
        )}
      </section>
    </div>
  );
};

export default Reminders;

///////////////////////////////////////////////////////////////

/** @param {{data: State[ReminderKind], kind: ReminderKind}} props */
const ReminderSection = ({ data, kind }) => {
  const init = useReminders((state) => state.init);
  const isSending = useReminders((state) => state.isSending);
  const submitReminders = useReminders((state) => state.submitReminders);
  const handleClickCancel = () => init();

  return (
    <section className='grid gap-4 py-6 aria-busy:cursor-wait' aria-busy={isSending}>
      {data.map((section, i, arr) => (
        <ReminderTable
          reminder={section}
          showAddMoreButton={i === arr.length - 1}
          type={kind}
          key={i}
          index={i}
          curLength={arr.length}
        />
      ))}
      <div className='flex gap-2 justify-self-end'>
        <button
          className='px-5 py-2 bg-gray-400 rounded-3xl disabled:cursor-not-allowed'
          onClick={handleClickCancel}
          disabled={isSending}
        >
          Cancel
        </button>
        <button
          className='px-5 py-2 text-white rounded-3xl bg-primary-90 disabled:cursor-not-allowed'
          onClick={() => submitReminders(kind)}
          disabled={isSending}
        >
          Save
        </button>
      </div>
    </section>
  );
};

/**
 *
 * @param {{reminder: Reminder['data'][number]; type: ReminderKind; index: number; curLength: number}} props
 * @returns
 */
const ReminderTable = ({ reminder, type, curLength, index }) => {
  const onChangeNumberOfDays = useReminders((state) => state.onChangeNumberOfDays);
  const addNewReminder = useReminders((state) => state.addNewReminder);
  const removeReminder = useReminders((state) => state.removeReminder);

  /** @param {string | number | null} days*/
  const handleNumberOfDaysChange = (days) => {
    if (!days) return;
    onChangeNumberOfDays(type, index, days);
  };

  return (
    <section className='grid gap-4 px-3 py-2 w-full bg-white rounded-2xl'>
      <div className='flex justify-between items-center'>
        <h2 className='text-sm'>Reminders for {labelForReminderType[type]}</h2>
        <p className='flex gap-4 items-center'>
          <span className='min-w-max text-xs' id={`${type}:label-days`}>
            Days before
          </span>
          <InputNumber
            min={1}
            max={36500}
            onChange={handleNumberOfDaysChange}
            value={reminder.days}
            aria-labelledby={`${type}:label-days`}
            type='number'
            style={{ backgroundColor: '#F2F2F2', border: '0px', borderRadius: 0 }}
            placeholder='Enter no. of days'
            className='placeholder:text-xxs placeholder:text-[#959595] placeholder:bg-[#F2F2F2] [&_.ant-input]:h-8 [&_.ant-input]:w-44'
          />
        </p>
      </div>
      <div className='grid gap-3'>
        <Row className='p-2 bg-grey-light *:!font-bold'>
          <Col span={16}>
            <p className='text-xs'>Role</p>
          </Col>
          <Col span={4}>
            <p className='text-xs'>Email</p>
          </Col>
          <Col span={4}>
            <p className='text-xs'>Push Notifications</p>
          </Col>
        </Row>
        {reminder.notify_email.map((values) => (
          <ReminderCheckboxesForRole key={values.role} type={type} {...values} index={index} />
        ))}
      </div>
      <div className='flex gap-4 justify-self-end'>
        {curLength > 1 && (
          <button
            className='flex gap-1 items-center p-2 text-xs font-semibold text-red-60'
            onClick={() => removeReminder(type, index)}
          >
            <DeleteFormIcon
              className='h-5 w-5 [&_line]:stroke-red-60 [&_path]:stroke-red-60'
              stroke={null}
            />
            Remove
          </button>
        )}
        {index === curLength - 1 && (
          <button
            className='p-2 text-xs font-semibold text-primary-90'
            onClick={() => addNewReminder(type)}
          >
            + Add New Reminder
          </button>
        )}
      </div>
    </section>
  );
};

/**
 * @param {State[ReminderKind][number]['notify_email'][number] & {index: number; type: ReminderKind}} props
 */
const ReminderCheckboxesForRole = ({ email, index, notify, role, type }) => {
  const onChangeCheckbox = useReminders((state) => state.onChangeCheckbox);

  return (
    <Row>
      <Col span={16}>
        <p className='pl-4 text-xs text-gray-800'>{backendLabelsToCleanOnes[role]}</p>
      </Col>
      <Col span={4}>
        <Checkbox
          name={`${type}:${role}:${index}`}
          checked={Boolean(email)}
          // value='email'
          className='[&_.ant-checkbox-input]:scale-150 [&_.ant-checkbox-inner]:scale-150'
          onChange={(e) => onChangeCheckbox(type, role, index, 'email', e.target.checked ? 1 : 0)}
        />
      </Col>
      <Col span={4}>
        <Checkbox
          disabled={role === 'Client'}
          name={`${type}:${role}:${index}`}
          checked={Boolean(notify)}
          // value='push'
          className='[&_.ant-checkbox-input]:scale-150 [&_.ant-checkbox-inner]:scale-150'
          onChange={(e) => onChangeCheckbox(type, role, index, 'notify', e.target.checked)}
        />
      </Col>
    </Row>
  );
};

const backendLabelsToCleanOnes = {
  Admin: 'Admin',
  Created_by: 'Create by',
  Sell_by: 'Sell by',
  Client: 'Client',
};

/** @type {Record<ReminderKind, string>} */
const labelForReminderType = {
  leads: 'Leads',
  policy: 'Policy Renewal',
  quotation: 'Quotations',
};

// if (process.env.NODE_ENV === 'development') {
//   useReminders.subscribe((state) => console.log('reminders: current state is ', state));
// }
