export const breadcrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Pos Management',
    path: '/management',
  },
  {
    name: 'View Pos Details',
    path: '',
  },
];

/**
 *
 * @param {{
 * name: string;
 * contact: string;
 * email: string;
 * password: string;
 * branch: string;
 * role_id: string;
 * designation: string;
 * department: string;
 * report_to_employee: string;
 * profile: string;
 * city: string;
 * state: string;
 * country: string;
 * address: string;
 * pincode: string;
 * tcity: string;
 * tstate: string;
 * tcountry: string;
 * taddress: string;
 * tpincode: string;
 * }} data
 * @returns
 */

export const SECTION_DATA_VIEW = [
  {
    title: 'Personal Details',
    keys: ['name', 'contact', 'email', 'experience', 'dob', 'detail'],
  },
  {
    title: 'Address Details',
    keys: ['address.address', 'country', 'state', 'city', 'pincode'],
  },
  {
    title: 'Bank Details',
    keys: ['ifsc_code', 'bank_name', 'acc_number'],
  },
];

export const formatOverViewPosData = (data) => {
  const res = {
    _: [],
  };
  if (!data) return res;

  Object.entries(data).forEach(([key, val]) => {
    switch (key) {
      case 'name':
        res._.push({ label: key, value: val, name: key.replaceAll('_', ' ') });
        break;
      case 'email':
        res._.push({ label: key, value: val, name: key.replaceAll('_', ' '), email: true });
        break;
      case 'contact':
        res._.push({ label: key, value: val, name: key.replaceAll('_', ' '), phone: true });
        break;
      case 'dob':
        res._.push({ label: key, value: val, name: 'Date of Birth' });
        break;
      default:
        break;
    }
  });
  return res;
};
