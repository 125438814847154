import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Select } from 'antd';
import dayjs from 'dayjs';

import { getClientGraph } from 'service/api-service';
import { DropdownIcon } from 'assets/icons';
import { Loader } from 'components';
import { clientFilterOptions, timelineOptions } from './data';
import './style.css';

const PolicyGraphs = ({ startDate, endDate, clientType }) => {
  const componentRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dim, setDim] = useState({ height: 0, width: 0 });

  const fetchData = async () => {
    setLoading(true);
    const resp = await getClientGraph({ start: startDate, end: endDate, type: clientType });
    if (resp?.data?.status) {
      setData(resp?.data?.data?.customer_by_date);
    }

    setLoading(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (componentRef.current) {
        const width = componentRef.current.clientWidth;
        const height = componentRef.current.clientHeight;
        setDim({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    // Initial setup
    handleResize();

    // Observer setup
    if (componentRef.current) {
      resizeObserver.observe(componentRef.current);
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [componentRef]);

  useEffect(() => {
    if (startDate && endDate) fetchData();
  }, [startDate, endDate, clientType]);

  const chartData = {
    labels: data?.map((item) => item.created),
    datasets: [
      {
        label: 'Dataset 1',
        data: data?.map((item) => item.customer_count),
        fill: false,
        backgroundColor: '#26CBED',
        borderColor: '#26CBED',
        borderWidth: 2,
        lineTension: 0,
      },
      // {
      //   label: 'Dataset 2',
      //   borderColor: 'rgb(255, 99, 132)',
      //   data: [5, 15, 10, 20, 25],
      //   fill: false,
      // backgroundColor: "#4BC0C0",
      // borderColor: "#4BC0C0"
      // },
    ],
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <div ref={componentRef} className='cursor-pointer'>
        <Bar
          {...dim}
          data={chartData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

const Header = ({ setStartDate, setEndDate, setClientType }) => {
  const onSelectDateRange = useCallback((e) => {
    if (e !== 'customRange') {
      setEndDate(dayjs().add(1, 'day').format('YYYY-MM-DD'));
    }
    if (e === 'today') {
      setStartDate(dayjs().subtract(0, 'day').format('YYYY-MM-DD'));
    } else if (e === 'week') {
      setStartDate(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
    } else if (e === 'month') {
      setStartDate(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));
    } else if (e === 'quarter') {
      setStartDate(dayjs().subtract(3, 'month').format('YYYY-MM-DD'));
    }
  }, []);

  const onSelectType = (val) => {
    setClientType(val);
  };

  return (
    <div id='lead-tab' className='flex justify-between items-center'>
      <div className='flex flex-row justify-between items-start'>
        <div className='text-sm font-semibold leading-6'>Clients</div>
      </div>
      <div className='flex items-center'>
        <Select
          className='group hover:text-primary-90 rounded px-2 [&_.ant-select-selection-placeholder]:!text-xxs [&_.ant-select-selection-item]:!text-xxs'
          suffixIcon={<DropdownIcon svgClass={'w-2 mr-2 group-hover:fill-primary-90'} />}
          bordered={false}
          options={timelineOptions}
          onSelect={onSelectDateRange}
          placeholder='Select Date'
          defaultValue='week'
        />
        <Select
          className='group hover:text-primary-90 rounded px-2 [&_.ant-select-selection-placeholder]:!text-xxs [&_.ant-select-selection-item]:!text-xxs'
          suffixIcon={<DropdownIcon svgClass={'w-2 mr-2 group-hover:fill-primary-90'} />}
          bordered={false}
          options={clientFilterOptions}
          onSelect={onSelectType}
          placeholder='Select Type'
          defaultValue={null}
        />
      </div>
    </div>
  );
};

const ClientGraph = () => {
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().add(1, 'day').format('YYYY-MM-DD'));
  const [clientType, setClientType] = useState('');

  return (
    <div>
      <Header setStartDate={setStartDate} setEndDate={setEndDate} setClientType={setClientType} />
      <PolicyGraphs clientType={clientType} startDate={startDate} endDate={endDate} />
    </div>
  );
};

export default ClientGraph;
