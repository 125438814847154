import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Select } from 'antd';
import dayjs from 'dayjs';

import { getInsuranceCategory, getSoldPolicies } from 'service/api-service';
import { BarGraphIcon, DropdownIcon, LineGraphIcon } from 'assets/icons';
import { IconButton, Loader } from 'components';
import { timelineOptions } from './data';
import './style.css';

/**
 * @typedef {import('react-chartjs-2').ChartProps<'bar'>['data']} BarChartData
 */

const PolicyGraphs = ({ startDate, endDate, product, category, showChart }) => {
  const componentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [dim, setDim] = useState({ height: 0, width: 0 });

  /**
   * @type {[BarChartData, React.Dispatch<BarChartData>]} state
   */
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data } = await getSoldPolicies({ start: startDate, end: endDate, product, category });
      const prettifiedChartData = getPrettifiedChartJsCompatibleData(data, Boolean(product.length));
      setChartData(prettifiedChartData);
      setLoading(false);
    };
    if (startDate && endDate) fetchData();
  }, [startDate, endDate, product, category]);

  useEffect(() => {
    const handleResize = () => {
      if (componentRef.current) {
        const width = componentRef.current.clientWidth;
        const height = componentRef.current.clientHeight;
        setDim({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    // Initial setup
    handleResize();

    // Observer setup
    if (componentRef.current) {
      resizeObserver.observe(componentRef.current);
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [componentRef]);

  // console.log('data+++', data);

  if (loading) {
    return <Loader />;
  }

  return (
    <div ref={componentRef} className='cursor-pointer'>
      {showChart ? (
        <Bar
          {...dim}
          data={chartData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                // display: false,
              },
            },
          }}
        />
      ) : (
        <Line
          {...dim}
          data={chartData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                // display: false,
              },
            },
          }}
        />
      )}
    </div>
  );
};

const Header = ({
  showChart,
  setStartDate,
  setEndDate,
  setProduct,
  setCategory,
  setShowChart,
  product,
}) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSelectDateRange = useCallback(
    (e) => {
      if (e !== 'customRange') {
        setEndDate(dayjs().add(1, 'day').format('YYYY-MM-DD'));
      }
      if (e === 'today') {
        setStartDate(dayjs().subtract(0, 'day').format('YYYY-MM-DD'));
      } else if (e === 'week') {
        setStartDate(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
      } else if (e === 'month') {
        setStartDate(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));
      } else if (e === 'quarter') {
        setStartDate(dayjs().subtract(3, 'month').format('YYYY-MM-DD'));
      }
      setCategory(undefined);
      setProduct([]);
    },
    [setCategory, setEndDate, setProduct, setStartDate],
  );

  const handlecategories = (val, item) => {
    setCategory(val);
    setProductOptions(
      item.product_type?.map((ele) => {
        return {
          label: ele?.name,
          value: ele?.id,
        };
      }),
    );
  };

  const handleProducts = (val) => {
    console.log('val', val);
    const object = val.length > 0 ? [...val] : val;
    if (object.length >= 3) {
      object.shift();
    }
    console.log('object', object);
    setProduct(object);
  };

  const fetchCategoryOptions = async () => {
    setLoading(true);
    const resp = await getInsuranceCategory();
    if (resp?.data?.status) {
      setCategoryOptions(
        resp?.data?.data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
            product_type: item?.product_type,
          };
        }),
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCategoryOptions();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div id='lead-tab' className='flex justify-between items-center space-x-2'>
      <div className='flex flex-row justify-between items-start'>
        <div className='text-sm font-semibold leading-6 whitespace-nowrap'>Policies Sold</div>
      </div>
      <div className='flex gap-2.5 items-center'>
        <Select
          className='[&_.ant-select-selection-placeholder]:!text-xxs [&_.ant-select-selection-item]:!text-xxs'
          suffixIcon={<DropdownIcon svgClass={'w-2 '} />}
          bordered={false}
          options={timelineOptions}
          style={{ width: 120, backgroundColor: '#F5F7FB' }}
          onSelect={onSelectDateRange}
          placeholder='Date'
          defaultValue='week'
          size='medium'
        />
        <Select
          className='[&_.ant-select-selection-placeholder]:!text-xxs [&_.ant-select-selection-item]:!text-xxs'
          bordered={false}
          options={categoryOptions}
          onSelect={handlecategories}
          style={{ width: 120, backgroundColor: '#F5F7FB' }}
          placeholder='Category'
          allowClear={true}
          size='medium'
          onClear={() => {
            setCategory(undefined);
            setProduct([]);
          }}
          suffixIcon={<DropdownIcon svgClass={'w-2'} />}
        />
        <Select
          value={product}
          mode='multiple'
          bordered={false}
          options={productOptions}
          onChange={handleProducts}
          placeholder='Product'
          allowClear={true}
          onClear={() => {
            setProduct([]);
          }}
          style={{ width: 100, backgroundColor: '#F5F7FB' }}
          size='medium'
          suffixIcon={<DropdownIcon svgClass={'w-2'} />}
          maxTagCount={'responsive'}
          className='w-36 [&_.ant-select-selection-placeholder]:!text-xxs [&_.ant-select-selection-item]:!text-xxs'
          maxTagTextLength={5}
        />
        <IconButton title={showChart ? 'Table' : 'Chart'} onClick={() => setShowChart(!showChart)}>
          {!showChart ? (
            <BarGraphIcon svgClass='w-6 h-6 text-primary-90' />
          ) : (
            <LineGraphIcon svgClass='w-6 h-6 text-primary-90' />
          )}
        </IconButton>
      </div>
    </div>
  );
};

const PolicySold = () => {
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().add(1, 'day').format('YYYY-MM-DD'));
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState('');
  const [showChart, setShowChart] = useState(true);

  return (
    <div>
      <Header
        showChart={showChart}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setProduct={setProduct}
        setCategory={setCategory}
        setShowChart={setShowChart}
        product={product}
      />
      <PolicyGraphs
        startDate={startDate}
        endDate={endDate}
        product={product}
        category={category}
        showChart={showChart}
      />
    </div>
  );
};

export default PolicySold;

/** @param {PolicyData} data
 * @returns {BarChartData} bar chart data
 */
const getPrettifiedChartJsCompatibleData = (data, productsSelected = false) => {
  if (!data?.status) return { labels: [], datasets: [] };

  if (productsSelected) {
    const flatItems = data.data.product_data.flatMap((item) =>
      item.data.map((datum) => ({ ...datum, ...item.product })),
    );
    const dates = Array.from(new Set(flatItems.map((item) => item.created)));
    const categories = data.data.product_data.map((item) => item.product.name);

    const datasets = [];

    let flag = false;

    categories.forEach((category) => {
      const curCatItems = flatItems.filter((item) => item.name === category);
      const dataset = {
        label: category,
        data: [],
        backgroundColor: flag ? '#E12A2A' : '#26CBED',
        borderColor: flag ? '#E12A2A' : '#26CBED',
      };
      dates.forEach((date) => {
        const curCatItemDateIndex = curCatItems.findIndex((item) => item.created === date);

        if (curCatItemDateIndex === -1) {
          dataset.data.push(0);
        } else {
          dataset.data.push(curCatItems[curCatItemDateIndex].policy_count);
        }
      });
      datasets.push(dataset);
      flag = true;
    });

    return { labels: dates, datasets };
  }

  const overallCounts = [];
  const labels = [];
  for (let i = 0; i < data.data.overall_data.length; i++) {
    const overallItem = data.data.overall_data[i];
    labels.push(overallItem.created);
    overallCounts.push(overallItem.policy_count);
  }
  const datasets = [
    { label: 'Overall', data: overallCounts, backgroundColor: '#26CBED', borderColor: '#26CBED' },
  ];
  return { labels, datasets };
};

/**
 * @typedef {object} PolicyData
 * @property {boolean} status
 * @property {object} data
 * @property {object[]} data.overall_data
 * @property {string} data.overall_data.created
 * @property {number} data.overall_data.policy_count
 * @property {object[]} data.product_data
 * @property {object} data.product_data.product
 * @property {number} data.product_data.product.id
 * @property {string} data.product_data.product.name
 * @property {object[]} data.product_data.data
 * @property {string} data.product_data.data.created
 * @property {number} data.product_data.data.policy_count
 * @property {number} data.total_policy_count
 */
