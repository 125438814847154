import { endpoints } from 'constants/endpoints';
import Request from '../client';

export const getAdminStatistics = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.statistics.admin,
  });
  return resp;
};

export const getEmployeeStaticCount = async ({ id }) => {
  return await Request({
    method: 'get',
    API: endpoints.statistics.employee(id),
  });
};

export const getTodaysAttendance = async ({ date = '' }) => {
  const resp = await Request({
    method: 'get',
    API: `leave/hr/attendance/count?date=${date}`,
  });
  return resp;
};
