/** external deps */
import React, { useEffect, useState } from 'react';
import { Select, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
/** internal deps */
import { TableComponent, IconButton, CustomModal, Toast, FormContainer } from 'components';
import { columns } from '../../targets/data';
import { DropdownIcon, View, Permission } from 'assets/icons';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import AddTarget from '../../targets/AddTarget';
import { targetsManagement } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import Button from 'components/button';
import { prepareFormFieldsData } from 'utils';
import { fieldForms } from './data';
import { TARGET_STATUS } from 'constants';
import { rowCommonClass } from 'utils/common';

const DynamicTableData = (props) => {
  let navigate = useNavigate();
  const permissions = props?.permissions || {};
  const {
    handlePagination,
    urlData = 'assigned',
    loading,
    targets,
    total_page,
    getTargets,
  } = props;
  const [_, setLoading] = useState(false);
  const [addTarget, setAddTarget] = useState(false);
  const [editInitials] = useState();
  const [assignEmp, setAssignEmp] = useState(false);
  const [targetId, setTargetId] = useState('');

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: fieldForms,
  });

  const assignEmployee = (record) => {
    setTargetId(record?.id);
    toggleAssignEmp();
  };

  const handleAssignEmployee = async (data) => {
    const resp = await targetsManagement({
      data: data,
      method: 'patch',
      target_id: targetId,
    });

    if (resp?.data?.status) {
      Toast(
        'success',
        NOTIFICATION_MSG.success,
        resp?.data?.msg || 'Employee Assigned successfully.',
      );
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
    toggleAssignEmp();
    getTargets();
  };

  const onStatusChange = async (status, record) => {
    const resp = await targetsManagement({
      method: 'patch',
      employee_id: record?.id,
      data: { status: status },
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Status Changed successfully.');
    } else {
      Toast('error', NOTIFICATION_MSG.error, 'Please try again');
    }
  };

  const toggleAddTarget = () => setAddTarget((pre) => !pre);
  const toggleAssignEmp = () => setAssignEmp((pre) => !pre);

  useEffect(() => {
    getTargets({});
  }, []);

  return (
    <main>
      <div className='mt-3 '>
        <div className='z-10'>
          <TableComponent
            getRowClassName={rowCommonClass}
            loading={loading}
            scroll={{
              x: columns.length > 8 ? 2000 : 500,
            }}
            columns={[
              ...columns.filter((i) =>
                i.displayFilter ? i.displayFilter.includes(urlData) : true,
              ),
              {
                key: 'status',
                title: 'Goal Status',
                dataIndex: 'status',
                render: (_, record) => {
                  return (
                    <Select
                      defaultValue={() => TARGET_STATUS.find((i) => i.value === record?.status)}
                      className='uppercase w-max !border-0 '
                      popupClassName=''
                      options={TARGET_STATUS}
                      suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
                      onSelect={(status) => onStatusChange(status, record)}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                  );
                },
              },
              {
                title: 'Action',
                key: 'action',
                align: 'right',
                render: (_, record) => (
                  <Space size='middle'>
                    {urlData === 'unassigned' && (
                      <IconButton
                        title='Assign Employee'
                        permissions={permissions}
                        id={elementData.Goals.permissions.update}
                        className='group disabled:cursor-not-allowed'
                        onClick={() => assignEmployee(record)}
                      >
                        <Permission svgClass='stroke-grey' />
                      </IconButton>
                    )}
                    <IconButton
                      title='View'
                      permissions={permissions}
                      className='group disabled:cursor-not-allowed'
                      id={elementData.Goals.permissions.view}
                      onClick={() => {
                        navigate(`/view-goal/${record.id}`, {
                          state: { initialValues: record },
                        });
                      }}
                    >
                      <View svgClass='stroke-primary group-disabled:stroke-grey' />
                    </IconButton>
                  </Space>
                ),
              },
            ]}
            onChange={handlePagination}
            total={total_page}
            dataSource={targets}
          />
        </div>
      </div>

      <CustomModal destroyOnClose={true} open={addTarget} onCancel={toggleAddTarget} footer={''}>
        <AddTarget
          employee={props.employee}
          employee_id={props.employee_id}
          getTargets={getTargets}
          initialValues={editInitials}
          showBack={false}
          goBack={toggleAddTarget}
          type={'edit'}
        />
      </CustomModal>

      <CustomModal
        width={450}
        destroyOnClose={true}
        open={assignEmp}
        onCancel={toggleAssignEmp}
        footer={''}
      >
        <FormContainer
          accordionClass='grid grid-cols-1  gap-8'
          fields={finalFields}
          initialValues={initialValues}
          onSubmit={handleAssignEmployee}
          customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
            return (
              <>
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAssignEmp();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={elementData.Leads.permissions.create}
                    text={'Assign Employee'}
                    type='button'
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              </>
            );
          }}
        />
      </CustomModal>
    </main>
  );
};

export default SecureComponent(DynamicTableData);
