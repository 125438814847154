import { ContentHeader } from 'components';
import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { getMimeType } from 'utils';
import { learningassets } from './data';

const LearningAsset = () => {
  const { state } = useLocation();
  const [seconds, setSeconds] = useState(2 * 60 * 60);
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (seconds > 0) intervalRef.current = setInterval(() => setSeconds((prev) => prev - 1), 1000);

    return () => clearInterval(intervalRef.current);
  }, [seconds]);

  // const assetType = state?.asset_type ?? 'document';

  return (
    <>
      <ContentHeader
        data={learningassets}
        showSearch={false}
        btnText='back'
        onClick={() => {
          navigate('/training');
        }}
      />

      <div className='mt-4 flex justify-between'>
        <div className='bg-white h-[75vh] w-[73vw] overflow-y-auto'>
          {/* <object type={getMimeType(assetType)} data={state?.asset} height={"100%"} width={"100%"}>
                    

                </object> */}
          <iframe src={state?.asset} height={'100%'} width={'100%'}></iframe>
        </div>
        <div className='bg-white h-[75vh] w-[22vw]'>
          <div className='bg-grey-light border-2 border-primary-90 rounded-3xl py-4 text-primary-90  text-3xl font-semibold'>
            {formatTime(seconds)}
          </div>
        </div>
      </div>
    </>
  );
};

export default LearningAsset;

/**
 * takes seconds and returns a nice string of the form `mm:ss`
 */
export const formatTime = (secs = 0) => {
  let adjustedSecs = secs;
  const hours = Math.floor(adjustedSecs / (60 * 60));
  adjustedSecs -= hours * 60 * 60;
  const minutes = Math.floor(adjustedSecs / 60);
  adjustedSecs -= minutes * 60;
  const seconds = adjustedSecs % 60;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};
