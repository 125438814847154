/** external deps */
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/** internal deps */
import { SecureComponent } from 'permission-provider';
import { ContentHeader, ViewData, TabsComponent, Toast } from 'components';
import { breadCrumbs, INSURER_DATA_VIEW } from './data';
import { elementData } from 'elements';
import { generatePermittedTabs } from 'utils';
import InsurerContacts from '../contacts';
import { NOTIFICATION_MSG } from 'constants';
import { prepareViewData } from 'utils/common';

const ViewInsurer = ({ permissions = {} }) => {
  const [key, setActiveTabKey] = useState('1');
  const { id: insurer_id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const data = state?.initialValues;

  const handleCopyClick = ({ text }) => {
    // Create a temporary textarea to copy the text
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);

    // Copy the text from the textarea
    textarea.select();
    document.execCommand('copy');

    // Clean up and remove the temporary textarea
    document.body.removeChild(textarea);
    // Optionally, you can show a notification or feedback to the user
    Toast('success', NOTIFICATION_MSG.success, 'link copied to clipboard!');
  };
  const handleGoBack = () => navigate(-1);

  const tabs = [
    {
      key: '1',
      id: elementData.insurer.permissions.view,
      label: `Insurer Details`,
      Component: ViewData,
      props: {
        data: prepareViewData({
          data: data,
          format: INSURER_DATA_VIEW,
          keys: { dep_id: 'Department', ifsc_code: 'IFSC Code', des_id: 'designation' },
          allowToReturnObjects: true,
          phone: 'phone',
          email: 'email',
        }),
        classes: 'bg-white rounded-2xl p-4',
        showGoToBack: true,
        goBack: handleGoBack,
        extraRender: (
          <div className='bg-white flex gap-10'>
            <div className='flex flex-col'>
              <p className='font-semibold text-xs'>Illustration link</p>
              <span
                onClick={() => handleCopyClick({ text: state?.initialValues?.illustration_link })}
              >
                {state?.initialValues?.illustration_link}
              </span>
            </div>

            <div className='flex flex-col'>
              <p className='font-semibold text-xs'>Payment link</p>
              <span onClick={() => handleCopyClick({ text: state?.initialValues?.payment_link })}>
                {state?.initialValues?.payment_link}
              </span>
            </div>
          </div>
        ),
      },
    },
    {
      key: '2',
      id: elementData.insurer.name,
      label: `Contact`,
      Component: InsurerContacts,
      props: {
        insurer_id,
        permissions,
      },
    },
  ];

  return (
    <div className='grid gap-3'>
      <ContentHeader data={breadCrumbs} showSearch={false} />
      <TabsComponent
        items={generatePermittedTabs(tabs, permissions)}
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
      />
    </div>
  );
};

export default SecureComponent(ViewInsurer);
