import { components } from 'react-select';
import { DropdownIcon } from 'assets/icons';

// Select input data
export const selectStyles = {
  control: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#F8F8F8' : '#F5FAFD',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '0 !important',
    },
    borderRadius: '6px',
    borderWidth: isSelected ? '2px !important' : '1px',
    borderColor: isDisabled ? '#F8F8F8' : isFocused ? '#00C3E2' : '#D1EAF9',
    paddingBlock: '0px',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    color: isDisabled ? '#aaa' : 'white',
    minWidth: '12rem',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      // fontSize: '1rem',
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 99 }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      borderColor: isFocused ? 'tertiary' : 'default',
      cursor: isFocused ? 'pointer' : 'default',
      backgroundColor: isFocused ? '#EBEBEB' : isSelected ? '#e6e7e8' : 'default',
      color: isFocused ? '#00C3E2' : '#000',
      // outerHeight: "40px",
      ':active': {
        ...styles[':active'],
        backgroundColor: '#e6e7e8',
      },
    };
  },
  dropdownIndicator: (styles, { isFocused }) => {
    return {
      ...styles,
      fill: isFocused ? '#00C3E2' : '000b10',
    };
  },
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon svgClass={'w-3 active:fill-primary-90'} />
    </components.DropdownIndicator>
  );
};
