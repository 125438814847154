import { PhoneNumberInput, SelectInput, TextInput } from 'components';

export const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Client',
    path: '/client',
  },
  {
    name: 'Add Client',
    path: '',
    target: '_blank',
  },
];

export const breadcrumbObjEdit = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Client',
    path: '/client',
  },
  {
    name: 'Edit Client',
    path: '',
    target: '_blank',
  },
];

export const contactFields = {
  'Client Details': {
    showAccordionHeader: false,
    fields: [
      {
        id: 'customer_type',
        options: [
          {
            value: 'retail',
            label: 'Retail',
          },
          {
            value: 'corporation',
            label: 'Corporation',
          },
        ],
        name: 'customer_type',
        label: 'Customer Type',
        type: 'select',
        component: SelectInput,
        required: true,
      },
      {
        label: 'Client Name',
        name: 'customer_name',
        id: 'customer_name',
        placeholder: 'Client Name',
        component: TextInput,
        required: true,
        maxLength: 30,
      },
      {
        label: 'Contact Number',
        name: 'contact_number',
        id: 'contact_number',
        placeholder: 'Contact Number',
        required: true,
        type: 'number',
        maxLength: 10,
        component: TextInput,
      },
      {
        label: 'Email Address',
        name: 'email_address',
        id: 'email_address',
        placeholder: 'Email Address',
        type: 'text',
        component: TextInput,
        required: true,
        // maxLength: 30,
      },
    ],
  },
};

export const customerFields = {
  'Customer Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Customer Name',
        name: 'customer_name',
        id: 'customer_name',
        placeholder: 'Customer Name',
        component: TextInput,
        required: true,
        maxLength: 30,
      },
      {
        label: 'Contact Number',
        name: 'contact_number',
        id: 'contact_number',
        placeholder: 'Contact Number',
        required: true,
        type: 'number',
        maxLength: 10,
        component: TextInput,
      },
      {
        label: 'Email Address',
        name: 'email_address',
        id: 'email_address',
        placeholder: 'Email Address',
        type: 'text',
        component: TextInput,
        // maxLength: 30,
      },
    ],
  },
};

export const extraFields = [
  {
    label: 'Spouse D.O.B',
    name: 'spouse',
    options: [],
    type: 'date',
  },
  {
    label: 'Son D.O.B',
    name: 'son',
    options: [],
    type: 'date',
  },
  {
    label: 'Father D.O.B',
    name: 'father',
    options: [],
    type: 'date',
  },
  {
    label: 'Mother D.O.B',
    name: 'mother',
    options: [],
    type: 'date',
  },
  {
    label: 'Daughter D.O.B',
    name: 'daughter',
    options: [],
    type: 'date',
  },
  {
    label: 'Self D.O.B',
    name: 'self',
    options: [],
    type: 'date',
  },
];

export const validateClientDocumentArray = (array) => {
  let result = true;
  let arr = [];
  let obj = {};
  for (let i = 0; i < array?.length; i++) {
    obj = array[i];
    if (obj?.product !== null && obj?.expiry_date === null) {
      arr.push(obj);
    }
  }
  if (arr.length) {
    result = false;
  }
  return result;
};
