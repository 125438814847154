/** external deps */
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/** internal deps */
import { ContentHeader, FormContainer, IconButton, Toast, Button, Module } from 'components';
import { elementData } from 'elements';
import { addInsurer, editInsurer } from 'service/api-service';
import {
  formatAddress,
  getEditedFields,
  getPermission,
  handleDefaultValues,
  prepareFormFieldsData,
} from 'utils';
import { addInsurerBreadCrumbs, addInsurerFields, editInsurerBreadCrumbs } from './data';
import { Context } from 'store/context';
import { BackArrow } from 'assets/icons';
import { NOTIFICATION_MSG } from 'constants';
import { SecureComponent } from 'permission-provider';

const AddInsurer = (props = {}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const permissions = props?.permissions || {};
  const isEditing = state?.initialValues ? true : false;
  const [initialValues, finalFormFields] = prepareFormFieldsData({
    fields: addInsurerFields,
  });

  const intitialCategories = state?.initialValues?.category?.map((item) => item.id);
  const { user } = useContext(Context);

  /** funcs */
  const onAddInsurer = async (updatedData) => {
    setLoading(true);
    const finalPayload = {
      ...updatedData,
      comm_per: 0,
      ...(formatAddress({ addressObject: updatedData, forApi: true }) && {
        address_id: formatAddress({ addressObject: updatedData, forApi: true }),
      }),
    };

    const resp = await addInsurer({ data: finalPayload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      navigate(-1);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onUpdateInsurer = async (updatedData) => {
    setLoading(true);
    const data = getEditedFields({
      initialValues: state?.initialValues,
      updatedData,
    });
    const finalPayload = {
      ...data,
      ...(formatAddress({ addressObject: updatedData, forApi: true }) && {
        address_id: formatAddress({ addressObject: updatedData, forApi: true }),
      }),
    };
    if (Object.keys(data).length > 0) {
      const resp = await editInsurer({
        data: finalPayload,
        id: state?.initialValues?.id,
      });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        navigate(-1);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
    } else {
      setLoading(false);
      return Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  return (
    <div className='grid gap-3'>
      {getPermission(permissions, elementData.insurer.permissions.create)?.write ? (
        <>
          <ContentHeader
            showSearch={false}
            data={isEditing ? editInsurerBreadCrumbs : addInsurerBreadCrumbs}
          />
          <div className='bg-white p-4 rounded-2xl flex flex-col'>
            <div className='flex justify-between px-2'>
              <div className='flex flex-col px-2'>
                <span className='text-l 2xl:text-xxl font-medium'>
                  {isEditing ? 'Edit Insurer' : 'Add Insurer'}
                </span>
                <span className='text-xxs text-grey-80 '>
                  {isEditing
                    ? 'To edit Insurer, enter the details of the Insurer in the input field below.'
                    : 'To add a new Insurer, enter the details of the Insurer in the input field below.'}
                </span>
              </div>
              <IconButton onClick={() => navigate(-1)}>
                <BackArrow svgClass='fill-grey-90 w-10 h-10' />
              </IconButton>
            </div>

            <FormContainer
              accordionClass='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'
              fields={finalFormFields}
              initialValues={
                isEditing
                  ? { ...state?.initialValues, category: intitialCategories }
                  : {
                      ...initialValues,
                      assign_to_employee: handleDefaultValues({ user }),
                    }
              }
              onSubmit={isEditing ? onUpdateInsurer : onAddInsurer}
              customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
                return (
                  <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                    <Button
                      text='Cancel'
                      variant='text'
                      type='reset'
                      classes='border border-gray-90 text-black px-6'
                      onClick={() => {
                        resetForm();
                        navigate(-1);
                      }}
                    />
                    <Button
                      disabled={!isValid}
                      id={
                        state?.initialValues
                          ? elementData.insurer.permissions.update
                          : elementData.insurer.permissions.create
                      }
                      permissions={permissions}
                      text={state?.initialValues ? 'Update Insurer' : 'Add Insurer'}
                      variant='primary'
                      classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                      onClick={submitForm}
                      loading={loading}
                    />
                  </div>
                );
              }}
            />
          </div>
        </>
      ) : (
        <Module />
      )}
    </div>
  );
};

export default SecureComponent(AddInsurer);
