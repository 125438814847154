/** external deps */
import React from 'react';
import dayjs from 'dayjs';
// import { useNavigate } from 'react-router-dom';
/** internal deps */
import { TableComponent, Button, MultiUserInitial } from 'components';
import { User } from 'assets';
import { rowCommonClass } from 'utils/common';
// import { BackArrow } from 'assets/icons';

const AllLeadsTab = (props) => {
  const { leads, inquiryLoading } = props;
  // const navigate = useNavigate();
  const onError = (event) => {
    event.target.src = User;
  };

  const columns = [
    {
      key: 'lead_id',
      title: 'Id',
      dataIndex: 'lead_id',
    },
    {
      key: 'assigned_to',
      title: 'Assigned To',

      dataIndex: 'assigned_to',
      render: (data) => (
        <div className='flex items-center capitalize'>
          <img
            src={process.env.REACT_APP_DOC_URL + data?.image}
            onError={onError}
            className='mr-2 w-6 h-6 rounded-full'
            alt='name'
          />
          {data?.name || '\u2014'}
        </div>
      ),
    },
    {
      key: 'customer',
      title: 'Client Name',
      dataIndex: 'customer',
      render: (data) => (
        <span>
          {data?.customer_type?.name === 'Corporation' ? data?.corp_name : data?.name || '\u2014'}
        </span>
      ),
    },
    {
      key: 'ins_cat_id',
      title: 'Category',
      dataIndex: 'ins_cat_id',
      render: (data) => (
        <div className='truncate'>
          <MultiUserInitial showImage data={data} value={'name'} label={'Products'} />
        </div>
      ),
    },
    {
      key: 'product_type',
      title: 'Product',
      dataIndex: 'product_type',
      render: (_, record) => {
        const productArray = record?.products?.map((item) => item?.product_type);
        let arrayData =
          record &&
          productArray?.map((ele) => {
            return { name: ele?.name };
          });
        return (
          <div className='truncate'>
            <MultiUserInitial showImage data={arrayData} value={'name'} label={'Products'} />
          </div>
        );
      },
    },
    {
      key: 'customer',
      title: 'Contact',
      dataIndex: 'customer',
      render: (data) => <span>{data?.contact_number || '\u2014'}</span>,
    },
    {
      key: 'created_by',
      title: 'Created by',
      dataIndex: 'created_by',
      render: (data) => <span>{data?.name || '\u2014'}</span>,
    },
    {
      key: 'created',
      title: 'Created at',
      dataIndex: 'created',
      render: (data) => <span>{dayjs(data).format('MMM DD,YYYY') || '\u2014'}</span>,
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_, record) => (
    //     <Button
    //       text={record?.status === 'draft' ? 'Create Quotation' : 'View'}
    //       type='button'
    //       onClick={() => {
    //         handleView(record, _.id);
    //       }}
    //       variant='primary'
    //       classes={`!px-2 !py-1 border capitalize bg-white rounded-md !text-xxxs !font-medium border-black !text-black hover:border-primary-90 hover:!text-primary-90`}
    //     />
    //   ),
    // },
  ];

  // const handleView = (obj, id) => {
  //   obj?.status === 'draft'
  //     ? navigate(`/quotation-engine?inq_id=${id}`)
  //     : navigate(`/edit-lead/${id}`);
  // };

  return (
    <div id='dashboard'>
      <TableComponent
        loading={inquiryLoading}
        dataSource={leads}
        columns={columns}
        showPagination={false}
        scroll={{ y: 170 }}
        getRowClassName={rowCommonClass}
      />
    </div>
  );
};

export default AllLeadsTab;
