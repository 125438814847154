import React, { useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { Button, Form, Input, Modal, Space, Tooltip } from 'antd';
import html2canvas from 'html2canvas';
import { Toast } from 'components/toast';
import { sendEmail } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';

const getLabel = (email, index, removeEmail) => {
  return (
    <div data-tag key={index}>
      <div data-tag-item>{email}</div>
      <span data-tag-handle onClick={() => removeEmail(index)}>
        ×
      </span>
    </div>
  );
};

const EmailField = ({ value, onChange }) => (
  <ReactMultiEmail
    placeholder='Enter Emails'
    // emails={emails.includes(emailData) ? [...emails] : [emailData, ...emails]}
    emails={value}
    onChange={onChange}
    autoFocus={true}
    // onFocus={() => setFocused(true)}
    // onBlur={() => setFocused(false)}
    required={true}
    getLabel={getLabel}
  />
);

const MultiEmail = (props) => {
  const {
    imageRef,
    emailData,
    text = 'Email',
    button = true,
    titleClasses,
    summary,
    mailSubject,
    type = '',
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);
  const [payloadData, setPayloadData] = useState(null);
  const [form] = Form.useForm();

  // useEffect(() => {
  //   const postReqFunc = async () => {
  //     const resp = await sendEmail({ data: payloadData });
  //     if (resp?.data?.status) {
  //       Toast("success", NOTIFICATION_MSG.success, resp?.data?.msg);
  //     } else {
  //       Toast("error", NOTIFICATION_MSG.error, resp?.error || "Please try again");
  //     }
  //   };

  //   if (payloadData !== null) {
  //     postReqFunc();
  //   }

  //   if (emailData) {
  //     const emailVar = emails.includes(emailData) ? [...emails] : [emailData, ...emails];
  //     setEmails([...emailVar]);
  //   }
  // }, [payloadData, emailData]);

  const toPdfConvert = (currentRef, fieldsValue) => {
    html2canvas(currentRef, {
      logging: false,
      useCORS: true,
      scale: 1,
    }).then((canvas) => {
      const imgData = canvas.toDataURL();
      // Convert to Base64 string
      const payload = {
        to: [...fieldsValue?.email],
        subject: fieldsValue?.subject,
        body: fieldsValue?.message,
        type_name: type,
        attachments: [
          {
            attachment: imgData,
            type: 'base64',
          },
        ],
      };
      setPayloadData(payload);
    });
  };

  const showModal = () => {
    // setEmails([])
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    // setEmails([]);
    setIsModalOpen(false);
    form.resetFields();
  };

  const { TextArea } = Input;

  const onFinish = async (fieldsValue) => {
    if (imageRef) {
      const ref = document.getElementById(imageRef);
      if (ref) {
        toPdfConvert(ref, fieldsValue);
      }
    } else {
      const payload = {
        to: [...fieldsValue?.email],
        subject: fieldsValue?.subject,
        body: fieldsValue?.message,
        type_name: type,
      };
      setPayloadData(payload);
      const resp = await sendEmail({ data: payload });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        setIsModalOpen(false);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
    }
    setIsModalOpen(false);
    setEmails([]);
    form.resetFields();
  };

  return (
    <>
      <Tooltip className='truncate' title={text}>
        <Space className='truncate' direction='vertical'>
          {button ? (
            <Button onClick={showModal} className={`bg-grey-80 text-white ${titleClasses}`}>
              {text}
            </Button>
          ) : (
            <p className={titleClasses} onClick={showModal}>
              {text}
            </p>
          )}
        </Space>
      </Tooltip>

      <Modal
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        title={<p className='text-m pt-4 ml-4 capitalize'>Send email</p>}
      >
        <Form
          initialValues={{ email: [emailData], subject: mailSubject, message: summary }}
          onFinish={onFinish}
          form={form}
          className='p-4'
        >
          <p className='text-xs mb-1'>Email</p>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: 'Please Input Email',
              },
            ]}
          >
            <EmailField />
          </Form.Item>
          <p className='text-xs mb-1'>Subject</p>
          <Form.Item
            name={'subject'}
            rules={[
              {
                required: true,
                message: 'Please Input Subject',
              },
            ]}
          >
            <Input placeholder='Enter Subject' />
          </Form.Item>
          <p className='text-xs mb-1'>Message</p>
          <Form.Item name={'message'}>
            <TextArea autoSize={{ minRows: 4, maxRows: 5 }} placeholder='Enter Message' />
          </Form.Item>
          <Form.Item>
            <div className='flex justify-end space-x-1'>
              <Button type='default' onClick={handleCancel}>
                Cancel
              </Button>
              <Button type='primary' htmlType='submit'>
                Send Email
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default MultiEmail;
