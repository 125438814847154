import React from 'react';
import { AuthLayout, FormContainer, Toast } from 'components';
import { ForgotFields, ForgotInitials } from './data';
import { requestResetLink } from 'service/api-service';
import { useNavigate } from 'react-router-dom';
import { NOTIFICATION_MSG } from 'constants';
import { InsuranceLogo } from 'assets/icons';

const ForgotPassword = () => {
  let navigate = useNavigate();

  const onForgotPassword = async (data) => {
    const resp = await requestResetLink({ data });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Reset link sent successfuly');
      navigate(-1);
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        typeof resp?.error === 'string' ? resp.error : 'Something went wrong',
      );
    }
  };

  return (
    <AuthLayout showImage={true}>
      <div className='w-fit m-auto'>
        <InsuranceLogo svgClass='w-[90px] h-16' />
      </div>

      <div className='flex justify-center items-center flex-col mt-2'>
        <span className='text-xl font-medium'>Forgot Password</span>
        <span className='text-grey-90 text-x pb-8 text-center max-w-md font-medium'>
          Don't worry we had your back. Please enter your email address to receive a password reset
          link.
        </span>
      </div>

      <FormContainer
        onSubmit={onForgotPassword}
        previousButton
        initialValues={ForgotInitials}
        fields={ForgotFields}
        submitButtonProps={{ submitText: 'Reset Password' }}
      />
    </AuthLayout>
  );
};

export default ForgotPassword;
