import React, { useState } from 'react';
import { CustomModal, IconButton, Loader, TableComponent } from 'components';
import { taskColumns } from './data';
import { elementData } from 'elements';
import { View as ViewIcon } from 'assets/icons';
import ModalView from '../view-modal';
import { getQueryParams, rowCommonClass } from 'utils/common';

const TaskTable = (props = {}) => {
  const permissions = props?.permissions || {};
  const { tab } = getQueryParams();
  const data = props?.tasks || [];
  const page = props?.page || 1;
  const total_page = props?.total_page || 1;
  const startDate = props?.startDate || '';
  const endDate = props?.endDate || '';
  const loading = props?.loading || false;

  /* state */
  const [showPopModal, setPopModal] = useState(false);

  /** funcs */
  const togglePopup = () => setPopModal((pre) => !pre);

  const handlePagination = (pageNum) => {
    if (pageNum?.current)
      props?.onGetTasks({ page: pageNum?.current, startDate: startDate, endDate: endDate });
  };

  const onClose = () => {
    window.history.replaceState(null, null, `?module=task-dashboard&tab=${tab}&id=`);
    togglePopup();
  };

  const onRowSelect = (record) => {
    window.history.replaceState(
      null,
      null,
      `?module=task-dashboard&tab=${record.activity.toLowerCase()}&id=${record.id}`,
    );
    togglePopup();
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <TableComponent
        getRowClassName={rowCommonClass}
        loading={loading}
        columns={[
          ...taskColumns,
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
              <IconButton
                title='View'
                permissions={permissions}
                className='group disabled:cursor-not-allowed'
                id={elementData.task.permissions.view}
                onClick={() => onRowSelect(record)}
              >
                <ViewIcon svgClass='stroke-primary group-disabled:stroke-grey' />
              </IconButton>
            ),
          },
        ]}
        dataSource={data}
        pageSize={10}
        total={total_page}
        onChange={handlePagination}
        defaultPage={page}
      />
      {/* View Task */}
      <CustomModal
        open={showPopModal}
        destroyOnClose={true}
        closable={true}
        onCancel={onClose}
        footer={''}
        width='80%'
        height='500px'
        overflow={'scroll'}
      >
        <ModalView onGetTasks={props?.onGetTasks} startDate={startDate} endDate={endDate} />
      </CustomModal>
    </div>
  );
};

export default TaskTable;
