import { MultiUserInitial } from 'components';
import dayjs from 'dayjs';

export const OPTIONS = [
  {
    label: <span>By Policy</span>,
    value: 'policy',
  },
  {
    label: <span>By Customer</span>,
    value: 'customer',
  },
  {
    label: <span>By POS</span>,
    value: 'pos',
  },
];

export const breadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Reports',
    path: '',
  },
];

export const moduleOptions = [
  {
    value: 'employees',
    label: 'Employees',
  },
  {
    value: 'leads',
    label: 'Leads',
  },
  {
    value: 'clients',
    label: 'Clients',
  },
];
export const DateRangeOptions = [
  {
    value: 'last 30 days',
    label: 'Last 30 Days',
  },
  {
    value: 'last 3 months',
    label: 'Last 3 Months',
  },
  {
    value: 'last 6 months',
    label: 'Last 6 Months',
  },
  {
    value: 'last 1 year',
    label: 'Last 1 Year',
  },
  {
    value: 'customRange',
    label: 'Custom Range',
  },
];

export const filterOptions = [
  {
    value: 'retail',
    label: 'Retail',
  },
  {
    value: 'corporation',
    label: 'Corporation',
  },
];

export const formatColumnData = (sections) => {
  return sections.flatMap((section) =>
    section.fields.map((column) => ({
      id: column.id,
      title: column.name,
      key: column.name,
      dataIndex: column.name,
      render: (data) => data?.name || data,
    })),
  );
};

export const EmployeeColumns = [
  {
    id: 1,
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 2,
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 3,
    title: 'Emp Code',
    key: 'emp_code',
    dataIndex: 'emp_code',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 4,
    title: 'Department',
    key: 'dep_id',
    dataIndex: 'dep_id',
    render: (data) => data?.map((item) => <p>{item?.name || '_'}</p>),
  },
  {
    id: 5,
    title: 'Designation',
    key: 'des_id',
    dataIndex: 'des_id',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 6,
    title: 'Role',
    key: 'role_id',
    dataIndex: 'role_id',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 7,
    title: 'Contact',
    key: 'contact',
    dataIndex: 'contact',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 8,
    title: 'D.O.B',
    key: 'dob',
    dataIndex: 'dob',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 8,
    title: 'Premium',
    key: 'premium',
    dataIndex: 'premium',
    render: (data) => data ?? '\u2014',
  },
  {
    id: 9,
    title: 'Status',
    key: 'is_active',
    dataIndex: 'is_active',
    render: (data) => (data === true ? 'Active' : 'Inactive'),
  },
  {
    id: 10,
    title: 'Joining Date',
    key: 'joining',
    dataIndex: 'joining',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 11,
    title: 'Ifsc Code',
    key: 'ifsc_code',
    dataIndex: 'ifsc_code',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 12,
    title: 'Bank Account',
    key: 'account',
    dataIndex: 'account',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 13,
    title: 'Referred By',
    key: 'referred_id',
    dataIndex: 'referred_id',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 14,
    title: 'Bank Details',
    key: 'bank_details',
    dataIndex: 'bank_details',
    render: (_, record) => (
      <p>
        {' '}
        Bank :{record?.account || '_'}, IFSC :{record?.ifsc_code || '_'}
      </p>
    ),
  },
  {
    id: 15,
    title: 'Address',
    key: 'address',
    dataIndex: 'address',
    render: (data) => (
      <p>
        {data?.address},{data?.city},{data?.state},{data?.country},{data?.pincode}
      </p>
    ),
  },
  {
    id: 16,
    title: 'Branches',
    key: 'branches',
    dataIndex: 'branches',
    render: (data) => data?.map((item) => <p>{item?.name || '_'}</p>),
  },
  {
    id: 17,
    title: 'Work from',
    key: 'work_from',
    dataIndex: 'work_from',
    render: (data) => data?.name || data || '_',
  },
];

export const ClientColumns = [
  {
    id: 1,
    title: 'Client Type',
    key: 'customer_type',
    dataIndex: 'customer_type',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 2,
    title: 'Local Status',
    key: 'local_status',
    dataIndex: 'local_status',
    render: (data) => data?.name || data || '_',
    displayFilter: ['corporation'],
  },
  {
    id: 3,
    title: 'Corporate Name',
    key: 'corp_name',
    dataIndex: 'corp_name',
    render: (data) => data?.name || data || '_',
    displayFilter: ['corporation'],
  },
  {
    id: 3,
    title: 'Branch',
    key: 'branch_of',
    dataIndex: 'branch_of',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 4,
    title: 'Assign To',
    key: 'assign_to_employee',
    dataIndex: 'assign_to_employee',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 5,
    title: 'Email',
    key: 'email_address',
    dataIndex: 'email_address',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 6,
    title: 'Contact',
    key: 'contact_number',
    dataIndex: 'contact_number',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 7,
    title: 'Contact person name',
    key: 'cp_name',
    dataIndex: 'cp_name',
    render: (data) => data?.name || data || '_',
    displayFilter: ['corporation'],
  },
  {
    id: 8,
    title: 'Number of contacts',
    key: 'num_contact',
    dataIndex: 'num_contact',
    render: (data) => data?.name || data || '_',
    displayFilter: ['corporation'],
  },
  {
    id: 9,
    title: 'Created Date',
    key: 'created',
    dataIndex: 'created',
    render: (data) => dayjs(data).format('YYYY-MM-DD'),
  },
  {
    id: 10,
    title: 'Gst Number',
    key: 'gst_registered',
    dataIndex: 'gst_registered',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 11,
    title: 'Nature of Business',
    key: 'nature_of_business',
    dataIndex: 'nature_of_business',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 14,
    title: 'Bank Details',
    key: 'bank_details',
    dataIndex: 'bank_details',
    render: (_, record) => (
      <p>
        Bank :{record?.bank_account_number || '_'}, IFSC :{record?.bank_ifsc_code || '_'}
      </p>
    ),
  },
  {
    id: 15,
    title: 'Address',
    key: 'address',
    dataIndex: 'ChangedAddress',
    render: (_, record) =>
      `${record?.address || '_'},${record?.city || '_'},${record?.state || '_'},${
        record?.country || '_'
      },${record?.postcode || '_'}`,
  },
  {
    id: 16,
    title: 'Created By',
    key: 'created_by',
    dataIndex: 'created_by',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 17,
    title: 'Customer Code',
    key: 'customer_code',
    dataIndex: 'customer_code',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 18,
    title: 'Account Holder Name',
    key: 'bank_account_holder_name',
    dataIndex: 'bank_account_holder_name',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 19,
    title: 'Account Type',
    key: 'bank_account_type',
    dataIndex: 'bank_account_type',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 20,
    title: 'Credit Amount',
    key: 'credit_amount',
    dataIndex: 'credit_amount',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 21,
    title: 'Credit Rating',
    key: 'credit_rating',
    dataIndex: 'credit_rating',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 23,
    title: 'Credit Term',
    key: 'credit_term',
    dataIndex: 'credit_term',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 24,
    title: 'Fax Number',
    key: 'fax_number',
    dataIndex: 'fax_number',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 25,
    title: 'Lead Source',
    key: 'lead_source',
    dataIndex: 'lead_source',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 26,
    title: 'Registered Address',
    key: 'r_address',
    dataIndex: 'clientR_address',
    render: (_, record) =>
      `${record?.r_address || '_'},${record?.r_city || '_'},${record?.r_state || '_'},${
        record?.r_country || '_'
      },${record?.r_postcode || '_'}`,
  },
];

export const leadsColumns = [
  {
    id: 1,
    title: 'Assign to',
    key: 'assigned_to',
    dataIndex: 'assigned_to',
    width: 200,
    render: (data) => (data ? `${data?.name} : ${data?.emp_code}` || data : '_'),
  },
  {
    id: 2,
    title: 'Product',
    key: 'products',
    dataIndex: 'products',
    render: (data) => {
      const array = data?.map((item) => {
        return { name: item?.product_type?.name };
      });
      return (
        <div className='truncate'>
          {' '}
          <MultiUserInitial showImage data={array ?? []} value={'name'} label={'Products'} />
        </div>
      );
    },
  },
  {
    id: 3,
    title: 'Client Name',
    key: 'customer',
    dataIndex: 'customer',
    render: (data) =>
      data?.customer_type?.name === 'Corporation' ? data?.corp_name : data?.name || '_',
  },
  {
    id: 4,
    title: 'Contact',
    key: 'customer',
    dataIndex: 'customer',
    render: (data) => data?.contact_number || data || '_',
  },
  {
    id: 5,
    title: 'Email',
    key: 'customer',
    dataIndex: 'customer',
    render: (data) => data?.email_address || data || '_',
  },
  {
    id: 6,
    title: 'Created at',
    key: 'created',
    dataIndex: 'created',
    render: (data) => dayjs(data).format('YYYY-MM-DD') || '_',
  },
  {
    id: 7,
    title: 'Created by',
    key: 'created_by',
    dataIndex: 'created_by',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 8,
    title: 'Insurance Category',
    key: 'ins_cat_id',
    dataIndex: 'ins_cat_id',
    render: (data) => {
      const array = data?.map((item) => {
        return { name: item?.name };
      });
      return (
        <div className='truncate'>
          {' '}
          <MultiUserInitial
            showImage
            data={array ?? []}
            value={'name'}
            label={'Insurance Category'}
          />
        </div>
      );
    },
  },
  {
    id: 9,
    title: 'Insurer',
    key: 'products',
    dataIndex: 'products',
    render: (data) => {
      const initialData = data?.map((item) => {
        return {
          insurer: item?.insurer,
          product: item?.product_type,
        };
      });
      let arr = [];
      for (let i = 0; i < initialData?.length; i++) {
        const childArray = initialData[i]?.insurer;
        for (let j = 0; j < childArray?.length; j++) {
          arr.push({
            name: childArray[j]?.name,
          });
        }
      }
      return (
        <div className='truncate'>
          {' '}
          <MultiUserInitial showImage data={arr ?? []} value={'name'} label={'Insurer'} />
        </div>
      );
    },
  },
  {
    id: 10,
    title: 'Remarks',
    key: 'remarks',
    dataIndex: 'remarks',
    render: (data) => data?.name || data || '_',
  },
  {
    id: 11,
    title: 'Remind Date',
    key: 'remind_date',
    dataIndex: 'remind_date',
    render: (data) => '_' || dayjs(data).format('YYYY-MM-DD'),
  },
  {
    id: 13,
    title: 'Renewal Date',
    key: 'renewal',
    dataIndex: 'renewal',
    render: (data) => '_' || dayjs(data).format('YYYY-MM-DD'),
  },
  {
    id: 12,
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (data) => data?.name || data || '_',
  },
];

const checkType = (value) => {
  let finalValue = '';
  if (Array.isArray(value)) {
    finalValue = value?.map((itm) => `${itm?.props?.children ?? '_'},`).join('');
  } else if (typeof value === 'string' || typeof value === 'number') {
    finalValue = value;
  } else {
    finalValue = value?.props?.children?.join('');
  }

  return finalValue;
};

export const parsedData = (data) => {
  const res = [];
  data?.forEach((item) => {
    let payload = {};
    let keys = Object.keys(item);
    keys?.forEach((key) => {
      let value = item[key];

      const finalValue = checkType(value);
      payload[key] = finalValue;
    });
    res.push(payload);
  });

  return res;
};

const checkColumns = ({ columns, item }) => {
  const exportData = {};
  columns.forEach((column) => {
    if (column.dataIndex === 'clientR_address') {
      exportData[column.key] =
        `${item.r_address || '-'},${item.r_city || '-'},${item.r_state || '-'},${
          item.r_country || '-'
        },${item.r_postcode || '-'}` || '_';
    } else if (column.dataIndex === 'ChangedAddress') {
      exportData[column.key] =
        `${item.address || '-'},${item.city || '-'},${item.state || '-'},${item.country || '-'},${
          item.postcode || '-'
        }` || '_';
    } else if (column.dataIndex === 'bank_details') {
      exportData[column.key] = ` Bank :${item.bank_account_number || '_'}, IFSC :${
        item.bank_ifsc_code || '_'
      }`;
    } else if (column.key in item) {
      exportData[column.key] = column.render?.(item[column.dataIndex]) || item[column.dataIndex];
    } else {
      exportData[column.key] = '_';
    }
  });
  return exportData;
};

export const ColumnsToExport = ({ data, columns }) => {
  return data?.map((item) => {
    const exportData = checkColumns({ columns, item });
    return exportData;
  });
};

export const componentOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Graph',
    value: 'graph',
  },
  {
    label: 'Table',
    value: 'table',
  },
];
