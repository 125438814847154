import React, { useState } from 'react';

import { CustomModal, FormContainer, SelectSearchWithName, TextInput, Toast } from 'components';
import Button from 'components/button';

import { leaveManagementAdmin } from 'service/api-service';
import { prepareFormFieldsData } from 'utils';
import { broadcast } from 'utils/communication';
import { REFRESH_EVT } from '../data';
import { NOTIFICATION_MSG } from 'constants';
import { getReasons } from 'service/api-service/reasons';

const fields = {
  approvedfields: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Select Reason',
        name: 'd_reason',
        id: 'd_reason',
        placeholder: 'Select a Reason',
        type: 'select',
        component: SelectSearchWithName,
        options: [],
        fetchOptions: async () => getReasons({ type: 'leaves' }),
        valIndex: 'value',
        customOnChange: (field, value, form) => {
          form?.setFieldValue(field, value);
        },
      },
    ],
  },
};
const ReasonModal = ({ open, onCancel, record, status }) => {
  const [loading, setLoading] = useState(false);

  const onDecline = async (data) => {
    setLoading(true);
    const resp = await leaveManagementAdmin({
      method: 'patch',
      id: record?.id,
      data: { status: 'declined', d_reason: data.d_reason },
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Leave Request Declined');
      broadcast(REFRESH_EVT);
    }
    setLoading(false);
    onCancel();
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: fields,
  });

  return (
    <CustomModal open={open} title='Reason to Decline' footer={null} onCancel={onCancel}>
      <div>
        <FormContainer
          accordionClass='grid  gap-8'
          onSubmit={onDecline}
          initialValues={initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, values }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                />
                <Button
                  disabled={!isValid}
                  text={'Decline'}
                  variant='primary'
                  classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </div>
    </CustomModal>
  );
};

export default ReasonModal;
