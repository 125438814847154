/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
/** internal deps */
import { Button, InsurerSideBar } from 'components';
import { getCommission, getInsurers } from 'service/api-service';
import AddCommission from './addCommission';
import CommissionTable from './CommissionTable';

const InsurerForm = (props = {}) => {
  const permissions = props?.permissions || {};
  const [checked, setChecked] = useState(1);
  const [showAddModal, setAddModal] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [editInitials, setInitials] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sideBarData, setSideBarData] = useState([]);

  /* funcs */

  const fetchInsurers = async () => {
    setLoading(true);
    const resp = await getInsurers();
    setSideBarData(resp?.data?.data);
    setChecked(resp.data?.data[0].id);
    setLoading(false);
  };

  useEffect(() => {
    const data = tableData.filter((item) => item?.insurer?.id == checked);
    setCardData(data);
  }, [checked, tableData]);

  const toggleAddAsset = () => {
    setAddModal((pre) => !pre);
  };

  const onAddPress = useCallback(() => {
    setInitials(undefined);
    toggleAddAsset();
  }, []);

  const getCommissionList = async () => {
    setLoading(true);
    const resp = await getCommission({ type: 'insurer' });
    if (resp?.data?.status) {
      setLoading(false);
    }
    setTableData(resp?.data?.data || []);
  };

  useEffect(() => {
    fetchInsurers();
    getCommissionList();
  }, []);
  return (
    <>
      <div className='flex justify-between gap-4'>
        <InsurerSideBar
          checkbox={false}
          setAgent={setChecked}
          agent={checked}
          data={sideBarData}
          header={'Insurers'}
          loading={loading}
          width={300}
        />

        <div className='w-full bordered bg-white relative rounded-lg'>
          {props.tabs}
          <Button
            loading={loading}
            text='+ Commission'
            variant='primary'
            classes={`z-10 absolute right-4 top-2`}
            onClick={onAddPress}
          />
          {/* <div className='grid grid-cols-2 h-[55vh] overflow-y-auto'>
            {cardData?.map((item, i) => (
              <Commissioncard
                key={item.id}
                editInitials={item}
                getCommissionList={getCommissionList}
              />
            ))}
          </div> */}
          <div className='p-4'>
            <CommissionTable
              permissions={permissions}
              loading={loading}
              setLoading={setLoading}
              data={cardData}
              refresh={getCommissionList}
              commissionSet='insurer'
              id={Number(checked)}
            />
          </div>
        </div>

        <AddCommission
          open={showAddModal}
          toggle={toggleAddAsset}
          setLoading={setLoading}
          loading={loading}
          editInitials={editInitials}
          refresh={getCommissionList}
          commissionSet='insurer'
          id={Number(checked)}
        />
      </div>
    </>
  );
};

export default InsurerForm;
