/** external deps */
import React from 'react';
import { NavLink } from 'react-router-dom';

/** internal deps */
import { DropdownIcon } from 'assets/icons';
import { useSidebar } from 'hooks';

const Sidebar = ({ isOpen, permissions = [] }) => {
  /** vars */
  const { sidebarItems } = useSidebar({ permissions });

  /** states */
  let defaultClassName = `cursor-pointer flex items-center justify-between p-2 text-black font-regular transition-all duration-500 group hover:text-primary-90 gap-2 mt-2 rounded-l group-open/edit:font-medium group-open/edit:bg-gradient-to-r from-primary-80 to-white ${
    isOpen ? 'ml-2' : 'ml-6 '
  }`;
  let activeClassName = `flex items-center justify-between p-2 text-black font-medium gap-2 bg-gradient-to-r from-primary-80 to-white mt-2 rounded-l ${
    isOpen ? 'ml-2' : 'ml-6 '
  }`;
  let defaultChidName = `flex items-center justify-between p-2 text-black font-regular transition-all duration-500 group hover:text-primary-90 gap-2 rounded-l ${
    isOpen ? 'ml-4' : 'ml-6'
  }`;
  let activeChidName = `group active flex items-center justify-between p-2 text-primary-90 ${
    isOpen ? 'ml-4' : 'ml-6'
  }`;
  /*closes details tag when clicked outside */
  var details = [...document.querySelectorAll('details')];
  document.addEventListener('click', function (e) {
    if (!details.some((f) => f.contains(e.target))) {
      details.forEach((f) => f.removeAttribute('open'));
    } else {
      details.forEach((f) => (!f.contains(e.target) ? f.removeAttribute('open') : ''));
    }
  });

  const renderSubmenu = (item, index) => (
    <NavLink
      key={index}
      to={item?.link}
      className={({ isActive }) => (isActive ? activeChidName : defaultChidName)}
    >
      <div className={`flex gap-2 items-center`}>
        {item?.icon}
        <span className={`text-xxs 2xl:text-xs ${isOpen ? '' : 'hidden'}`}>{item?.label}</span>
      </div>
    </NavLink>
  );

  const renderMenu = (item, index) => {
    return item?.submenu ? (
      <details key={index} className='group/edit'>
        <summary to={item?.link} className={`group ${defaultClassName}`}>
          {item.icon}
          <span className={`text-xs mr-auto 2xl:text-s ${isOpen ? '' : 'hidden'}`}>
            {item.label}
          </span>
          <DropdownIcon
            svgClass={`w-3 transition-all duration-500 group-hover:fill-primary-90 group-open/edit:rotate-180 group-open/edit:hover:fill-black`}
          />
        </summary>
        <div className={`flex flex-col bg-grey-light ${isOpen ? 'ml-2' : 'ml-6 '}`}>
          {item?.submenu?.map((item, index) => {
            return renderSubmenu(item, index);
          })}
        </div>
      </details>
    ) : (
      <NavLink
        key={index}
        to={item?.link}
        className={({ isActive }) => (isActive ? activeClassName : defaultClassName)}
      >
        <div className='flex gap-2'>
          {item?.icon}
          <span className={`text-xs 2xl:text-s ${isOpen ? '' : 'hidden'}`}>{item?.label}</span>
        </div>
      </NavLink>
    );
  };

  const renderContent = (item, index) => {
    return (
      <div
        key={index}
        className={` bg-white rounded-r-3xl sticky h-screen top-0 z-30 shadow-custom ${
          isOpen ? 'w-[24%]' : 'w-[8%]'
        }`}
      >
        <div className='h-fit grid justify-center py-6'>
          <div className={`grid justify-center items-center ${isOpen ? '' : 'hidden'}`}>
            {item?.logo}
          </div>
          <div className={`grid justify-center items-center ${isOpen ? 'hidden' : ''}`}>
            {item?.mobilelogo}
          </div>
        </div>
        <ul>
          {item?.menu?.map((item, index) => {
            return renderMenu(item, index);
          })}
        </ul>
      </div>
    );
  };
  return <>{sidebarItems[0]?.menu?.length > 0 ? sidebarItems.map(renderContent) : null}</>;
};

export default Sidebar;
