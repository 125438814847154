/** external deps */
import React, { useEffect, useState } from 'react';

/** internal deps */
import { TableComponent, ViewData, Button, AddDocument } from 'components';
import { SecureComponent } from 'permission-provider';

import { formatOverViewData } from '../view-customer-details/data';
import { activityColumns, createDocumentFields, initialDocumentValues } from './data';
import { getPermission } from 'utils';
import { elementData } from 'elements';
import './style.css';
import { getActivity } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const Overview = ({ data, setActiveTabKey, permissions = {}, customer_id }) => {
  const overviewData = formatOverViewData(data);

  /* states  */
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState([]);

  /* funcs */
  const onGetActivity = async () => {
    setLoading(true);
    const resp = await getActivity({ customer_id });
    if (resp?.data?.status) {
      const list = resp.data.data;
      setActivity(list);
    }
    setLoading(false);
  };

  useEffect(() => {
    onGetActivity();
  }, []);

  return (
    <main
      className='bg-white rounded-2xl mt-4 p-1 grid grid-cols-2  [&>*]:border-b-2 [&>*:nth-child(odd)]:border-r-2 [&>*]:p-8 [&>*]:border-grey-70'
      id='over-view'
    >
      {/* first row */}
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-10 border-b-2 border-grey-70 py-8"> */}
      {/* first box */}
      <div className=''>
        <h2 className='font-semibold text-s'>Client Details </h2>
        <div className='pt-4'>
          <ViewData data={overviewData} className='grid-cols-2' />
        </div>
      </div>

      {(getPermission(permissions, elementData.task.name)?.read ||
        getPermission(permissions, elementData.meeting.name)?.read) && (
        <div className='relative'>
          <div className='flex justify-between items-center'>
            <h2 className='font-semibold text-s'>Recent Activities</h2>
            <Button
              onClick={() => setActiveTabKey('4')}
              text='View More'
              variant='primary'
              classes='xl:px-6'
            />
          </div>
          <div className='pt-6'>
            <TableComponent
              showPagination={false}
              loading={loading}
              columns={activityColumns}
              getRowClassName={rowCommonClass}
              dataSource={loading ? [] : activity}
            />
          </div>
        </div>
      )}
      {/* </div> */}

      {/* second row */}
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-10 border-b-2 border-grey-70 py-8"> */}
      <div className=''>
        <AddDocument
          fields={createDocumentFields}
          initialValues={initialDocumentValues}
          customer_id={customer_id}
          type='customer'
        />
      </div>
      {/* </div> */}

      {/* third row */}
      {/* <div className=" grid grid-cols-1 md:grid-cols-2  gap-10  py-8 h-60"> */}
    </main>
  );
};

export default SecureComponent(Overview);
