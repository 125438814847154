// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC2Rl334Bv2tZcNxeYRnE0RtnsHs_GJMK8',
  authDomain: 'test-d9f76.firebaseapp.com',
  projectId: 'test-d9f76',
  storageBucket: 'test-d9f76.appspot.com',
  messagingSenderId: '84359951067',
  appId: '1:84359951067:web:3278b7dd31116c49a112fe',
  measurementId: 'G-97R79X3YGP',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
export const rtdb = getDatabase(app);
