import { endpoints } from 'constants/endpoints';
import Request from './client';

export const getInsuranceCategory = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.ins_cat.list,
  });
  return resp;
};
export const getInsuranceCategoryObj = async ({ id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.ins_cat.single(id),
  });
  return resp;
};
export const addInsuranceCategory = async ({ data = undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.ins_cat.create,
    data,
  });
  return resp;
};
export const editInsuranceCategory = async ({ id = '', data = undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.ins_cat.update(id),
    data,
  });
  return resp;
};
export const deleteInsuranceCategory = async ({ id = '', data = undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.ins_cat.delete(id),
    data,
  });
  return resp;
};
