import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useBankDetails = (ifscCode) => {
  const [bankDetails, setBankDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBankDetails = useCallback(async () => {
    try {
      const response = await axios.get(`https://ifsc.razorpay.com/${ifscCode}`);
      setBankDetails(response.data);
      setLoading(false);
      setError(null);
    } catch (error) {
      setError('Invalid IFSC code or unable to fetch bank details.');
      setLoading(false);
    }
  }, [ifscCode]);

  useEffect(() => {
    if (ifscCode) {
      fetchBankDetails();
    } else {
      setLoading(false);
      setBankDetails(null);
      // setError("IFSC code is required.");
    }
  }, [ifscCode]);
  return { bankDetails, loading, error };
};

export default useBankDetails;
