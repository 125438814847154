import Request from 'service/api-service/client';

export const getClause = async (props = { insurance_product_id: '' }) => {
  const resp = await Request({
    method: 'get',
    API: `clauses/${props?.insurance_product_id}`,
  });
  return resp;
};

export const postClause = async (props = { data: '', insurance_product_id: '' }) => {
  const resp = await Request({
    method: 'post',
    API: `clauses/${props?.insurance_product_id}`,
    data: props?.data,
  });
  return resp;
};

export const patchClause = async (props = { data: '', insurance_product_id: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `clauses/${props?.insurance_product_id}`,
    data: props?.data,
  });
  return resp;
};
