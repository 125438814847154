import { endpoints } from 'constants/endpoints';
import Request from './client';

export const getProductsByCategory = async ({ ins_category = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.ins_product.list(ins_category),
  });
  return resp;
};

export const editProductsByCategory = async ({ data = undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.ins_product.create,
    data,
  });
  return resp;
};

export const addProducts = async ({ data = undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.ins_product.create,
    data,
  });
  return resp;
};
export const editProducts = async ({ id = '', data = undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.ins_product.update(id),
    data,
  });
  return resp;
};
export const deleteProducts = async ({ id = '' }) => {
  const resp = await Request({
    method: 'delete',
    API: endpoints.ins_product.delete(id),
  });
  return resp;
};

export const compareInsuranceProduct = async ({ category_id = '', premium = '' }) => {
  const resp = await Request({
    method: 'get',
    API: `insurance-product/compare?category_id=${category_id}&premium=${premium}`,
  });
  return resp;
};

export const getInsurerProduct = async (props = { insurer_id: '' }) => {
  const resp = await Request({
    method: 'get',
    API: `insurance-product/?insurer_id=${props?.insurer_id || ''}`,
  });
  return resp;
};
