/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
/** internal deps */
import { SecureComponent } from 'permission-provider';
import { getTermsCondition } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { ContentHeader, Toast } from 'components';
import './style.css';

const ViewTermsCondition = (props = {}) => {
  // const permissions = props?.permissions || [];
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [breadCrumb, setBreadCrumb] = useState('');
  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await getTermsCondition();
    if (resp?.data?.status) {
      const data = resp?.data?.data[0];
      setData(data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, []);

  const breadCrumbsView = [
    {
      name: breadCrumb === 'pos-login' ? 'Login' : 'Signup',
      path: `/` + breadCrumb,
    },
    {
      name: 'Terms & Condition',
      path: '',
    },
  ];

  useEffect(() => {
    const parts = document.referrer.split('/');
    const prevPath = parts[parts.length - 1];
    setBreadCrumb(prevPath);
    fetchData();
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader data={breadCrumbsView} showSearch={false} />
      {loading ? (
        <div className='flex items-center justify-center h-64'>
          <Spin />
        </div>
      ) : (
        <div id='terms' className='overflow-y-auto bg-white rounded-lg px-6 py-2'>
          <div id='view-terms' dangerouslySetInnerHTML={{ __html: data?.terms }}></div>
        </div>
      )}
    </div>
  );
};

export default SecureComponent(ViewTermsCondition);
