import {
  TextInput,
  PhoneNumberInput,
  SelectInput,
  DateTime,
  SearchAPI,
  CustomTextInput,
  Uploadbox,
  Checkbox,
  MultiSelect,
  CustomFieldArray,
} from 'components';
import { EMPLOYEE_MIN_AGE } from 'constants/employee';
import {
  fetchLocationData,
  getCityOptions,
  getCountryOptions,
  getStateOptions,
} from 'data/form-data';
import dayjs from 'dayjs';
import { departmentManagement, getBranches, getRoles, searchApis } from 'service/api-service';
import { preventEKey } from 'utils/common';
import { documentOptions } from 'utils/options';
const countryOptions = getCountryOptions();

export const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Employees',
    path: '/employees',
  },
];

export const breadcrumbObjAdd = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Employees',
    path: '/employees',
  },
  {
    name: 'Add Employee Details',
    path: '',
    target: '_blank',
  },
];

export const breadcrumbsEdit = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Employees',
    path: '/employees',
  },
  {
    name: 'Edit Employee Details',
    path: '',
    target: '_blank',
  },
];

export const initialValues = {
  name: '',
  contact: '',
  email: '',
  password: '',
  branch: '',
  role_id: '',
  designation: '',
  department_id: '',
  joining: '',
  report_id: '',
  image: '',
  pincode: '',
  country: '',
  state: '',
  city: '',
  address: '',
  tpincode: '',
  tcountry: '',
  tstate: '',
  tcity: '',
  taddress: '',
  checkaddress: true,
  account: '',
  ifsc_code: '',
  docs: [{ name: '', file: null }],
  bank: '',
  alt_con: '',
};

export const excelformFields = [
  'name',
  'contact',
  'email',
  'password',
  'branch',
  'role_id',
  'designation',
  'department_id',
  'joining',
  'report_id',
  'image',
  'pincode',
  'country',
  'state',
  'city',
  'address',
  'tpincode',
  'tcountry',
  'tstate',
  'tcity',
  'taddress',
];

export const viewEmployeeFormFields = {
  'Personal Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Name',
        name: 'name',
        id: 'name',
        placeholder: 'Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'DOB',
        name: 'dob',
        id: 'dob',
        placeholder: 'YYYY-MM-DD',
        type: 'date',
        required: true,
        component: TextInput,
      },
      {
        label: 'Contact Number',
        name: 'contact',
        id: 'contact',
        placeholder: 'Contact Number',
        type: 'number',
        required: true,
        maxLength: 13,
        // component: PhoneNumberInput,
        component: TextInput,
      },
      {
        label: 'Email Address',
        name: 'email',
        id: 'email',
        placeholder: 'Email Address',
        type: 'text',
        required: true,
        component: TextInput,
        // maxLength: 30,
      },
      {
        label: 'Branch',
        name: 'branch',
        id: 'branch',
        placeholder: 'Select Branch',
        type: 'select',
        required: true,
        component: MultiSelect,
        hideCheckBox: true,
        hideOptionAll: true,
        fetchOptions: () => searchApis({ api: 'branch' }),
      },
      {
        label: 'Assign Role',
        name: 'role_id',
        id: 'role_id',
        placeholder: 'Assign Role',
        type: 'select',
        component: SelectInput,
        maxLength: 30,
        fetchOptions: () => searchApis({ api: 'roles' }),
      },
    ],
  },
};

export const viewEmployeeInitials = {
  name: '',
  contact: '',
  email: '',
  password: '',
  branch: '',
  role: '',
  designation: '',
  department_id: '',
  report_id: '',
};

export const parseData = (data) => {
  return {
    name: data?.name,
    contact: data?.contact,
    designation: {
      name: data?.des_id?.name,
      id: data?.des_id?.id,
      value: data?.des_id?.id,
      label: data?.des_id?.name,
    },
    report_id: data?.report_to?.name,
    email: data?.email,
    work_from: data?.work_from,
    referred_by: data?.referred_by,
    branch: data?.branches?.filter(Boolean).map((item) => item.name),
    department_id: data?.dep_id?.map((item) => item.name),
    role_id: {
      name: data?.role_id?.name,
      id: data?.role_id?.id,
      value: data?.role_id?.id,
      label: data?.role_id?.name,
    },
    city: data?.address?.city,
    state: data?.address?.state,
    country: data?.address?.country,
    address: data?.address?.address,
    pincode: data?.address?.pincode,
    tcity: data?.temp_address?.city,
    tstate: data?.temp_address?.state,
    tcountry: data?.temp_address?.country,
    taddress: data?.temp_address?.address,
    tpincode: data?.temp_address?.pincode,
    image: data?.image,
    dob: data?.dob,
    joining: data?.joining,
    emp_code: data?.emp_code,
    employee: data?.referred_id?.name,
    referred_id: data?.referred_id,
    ifsc_code: data?.ifsc_code,
    account: data?.account,
    docs: data?.docs ?? [],
    bank: data?.bank,
    alt_con: data?.alt_con ?? '',
  };
};

export const addEmployeeFormFields = ({
  setIfscCode,
  bankDetails,
  error,
  setPinCodeError,
  pincodeError,
}) => ({
  'Personal Details': {
    showAccordionHeader: false,
    id: 'personal-details',
    subTitle: (
      <h2 id='personal-details' className='font-semibold text-s'>
        Personal Details
      </h2>
    ),
    fields: [
      {
        label: 'Name',
        name: 'name',
        id: 'name',
        placeholder: 'Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Date of Birth',
        name: 'dob',
        id: 'dob',
        placeholder: 'YYYY-MM-DD',
        type: 'date',
        required: true,
        showTime: false,
        component: DateTime,
        whetherDateShouldBeDisabled: shouldDateBeDisabledForEmployeesDOBField,
      },
      {
        label: 'Contact Number',
        name: 'contact',
        id: 'contact',
        placeholder: 'Contact Number',
        type: 'number',
        required: true,
        maxLength: 13,
        // component: PhoneNumberInput,
        component: TextInput,
      },
      {
        label: 'Alternate Contact Number',
        name: 'alt_con',
        id: 'alt_con',
        placeholder: 'Alternate Contact Number',
        type: 'number',
        required: false,
        component: TextInput,
        // maxLength:"15",
        handleKeyPress: (e) => {
          preventEKey(e);
        },
      },
      {
        label: 'Email Address',
        name: 'email',
        id: 'email',
        placeholder: 'Email Address',
        type: 'text',
        required: true,
        component: TextInput,
        // maxLength: 30,
      },

      {
        label: 'Branch',
        name: 'branch',
        id: 'branch',
        placeholder: 'Branch',
        type: 'select',
        required: true,
        component: MultiSelect,
        hideCheckBox: true,
        hideOptionAll: true,
        options: [],
        fetchOptions: getBranches,
      },
      {
        label: 'Assign Role',
        name: 'role_id',
        id: 'role_id',
        placeholder: 'Assign Role',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [],
        fetchOptions: getRoles,
      },
      {
        label: 'Department',
        name: 'department_id',
        id: 'department_id',
        placeholder: 'Department',
        type: 'select',
        required: true,
        component: MultiSelect,
        hideCheckBox: true,
        hideOptionAll: true,
        fetchOptions: departmentManagement,
      },
      {
        label: 'Designation',
        name: 'designation',
        id: 'designation',
        type: 'select',
        component: SelectInput,
        fetchOptions: async () => searchApis({ api: 'designation' }),
      },
      {
        label: 'Report To',
        name: 'report_id',
        id: 'report_id',
        placeholder: 'Report To',
        type: 'search',
        component: SearchAPI,
      },
      {
        label: 'Joining Date',
        name: 'joining',
        id: 'joining',
        placeholder: 'Joining Date',
        type: 'date',
        component: DateTime,
        required: true,
        disableNext: true,
      },
      {
        label: 'Work From',
        name: 'work_from',
        id: 'work_from',
        placeholder: 'Work From',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          {
            label: 'Home',
            value: 'home',
          },
          {
            label: 'In Field',
            value: 'in_field',
          },
          {
            label: 'Office',
            value: 'office',
          },
        ],
      },
      {
        label: 'Referred By',
        name: 'referred_by',
        id: 'referred_by',
        placeholder: 'Referred By',
        type: 'select',
        component: SelectInput,
        options: [
          {
            label: 'Online',
            value: 'online',
          },
          {
            label: 'Employee',
            value: 'employee',
          },
          {
            label: 'Other',
            value: 'other',
          },
        ],
      },
      {
        dependent: true,
        dependent_on: 'referred_by',
        dependent_value: 'employee',
        label: 'Referred By Employee ',
        name: 'employee',
        id: 'employee',
        placeholder: 'Employee',
        type: 'search',
        component: SearchAPI,
      },
      {
        label: 'Upload Picture',
        name: 'image',
        id: 'image',
        placeholder: 'image',
        component: Uploadbox,
        dragger: false,
        accept: ['.png, .jpeg, .jpg'],
      },
    ],
  },
  'Bank Details': {
    showAccordionHeader: false,
    id: 'bank-details',
    subTitle: (
      <h2 id='bank-details' className='font-semibold text-s'>
        Bank Details
      </h2>
    ),
    fields: [
      {
        label: 'Bank Name',
        name: 'bank',
        id: 'bank',
        placeholder: 'Bank Name',
        type: 'text',
        component: TextInput,
        maxLength: 16,
      },
      {
        label: 'Account Number',
        name: 'account',
        id: 'account',
        placeholder: 'Account Number',
        type: 'text',
        component: TextInput,
        maxLength: 16,
      },
      {
        label: 'IFSC Code',
        name: 'ifsc_code',
        id: 'ifsc_code',
        isUpperCase: true,
        placeholder: 'IFSC Code',
        type: 'ifsc',
        maxLength: 11,
        component: CustomTextInput,
        customOnChange: ({ field, value, form }) => {
          setIfscCode(value);
        },
        customIcon: bankDetails,
        title: `${bankDetails?.BANK}, ${bankDetails?.BRANCH},${bankDetails?.CITY}`,
        error: error,
      },
    ],
  },
  // 'Profile Picture': {
  //   fields: [
  //     {
  //       label: 'Upload Picture',
  //       name: 'image',
  //       id: 'image',
  //       placeholder: 'image',
  //       component: Uploadbox,
  //       accept: ['.png, .jpeg, .jpg'],
  //     },
  //   ],
  // },
  'Additional Details': {
    showAccordionHeader: false,
    id: 'additional-details',
    subTitle: (
      <h2 id='additional-details' className='font-semibold text-s'>
        Additional Details
      </h2>
    ),
    fields: [
      {
        label: 'Country',
        name: 'country',
        id: 'country',
        placeholder: 'Country',
        type: 'select',
        required: true,
        component: SelectInput,
        options: countryOptions,
        dependency: ['pincode'],
        customChange: (_, __, form) => {
          form?.setFieldValue('city', '');
        },
      },
      {
        label: 'State',
        name: 'state',
        id: 'state',
        placeholder: 'State',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [],
        dependency: ['country'],
        dependantFetchOptions: async (form) => {
          if (!form?.values?.country) return [];
          const country = form?.values?.country;
          const resp = await getStateOptions(country);
          if (resp) {
            return resp;
          } else return [];
        },
      },
      {
        label: 'City/Town',
        name: 'city',
        id: 'city',
        placeholder: 'City/Town',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [],
        dependency: ['country', 'state'],
        dependantFetchOptions: async (form) => {
          if (!form?.values?.country || !form?.values?.state) return [];
          const country = form?.values?.country;
          const state = form?.values?.state;
          const resp = await getCityOptions(country, state);
          if (resp) {
            return resp;
          } else return [];
        },
      },
      {
        label: 'Address',
        name: 'address',
        id: 'address',
        placeholder: 'Address',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
        divClasses: 'col-span-2',
      },
      {
        label: 'Pincode',
        name: 'pincode',
        id: 'pincode',
        placeholder: 'Pincode',
        type: 'text',
        required: true,
        component: CustomTextInput,
        customOnChange: async ({ field, value, form }) => {
          const zipCode = value;
          const data = await fetchLocationData(zipCode);
          if (data) {
            form?.setFieldValue('country', data?.country);
            form?.setFieldValue('state', data?.state);
            form?.setFieldValue('city', data?.city);
          } else setPinCodeError('Enter a valid pincode');
        },
        error: pincodeError,
        maxLength: 12,
      },
      {
        label: 'As with the permanent address, the temporary address will also be the same.',
        name: 'checkaddress',
        id: 'checkaddress',
        type: 'checkbox',
        component: Checkbox,
        checked: true,
        divClasses: 'col-span-2',
      },
    ],
  },
  temp_address: {
    dependent: true,
    dependent_on: 'checkaddress',
    dependent_value: false,
    subTitle: 'Temporary Address',
    fields: [
      {
        label: 'Country',
        name: 'tcountry',
        id: 'tcountry',
        placeholder: 'Country',
        type: 'select',
        searchable: true,
        component: SelectInput,
        options: countryOptions,
        customChange: (field, values, form) => {
          form?.setFieldValue('tcity', '');
        },
      },
      {
        label: 'State',
        name: 'tstate',
        id: 'tstate',
        placeholder: 'State',
        type: 'select',
        component: SelectInput,
        options: [],
        dependency: ['tcountry'],
        dependantFetchOptions: async (form) => {
          if (!form?.values?.tcountry) return [];
          const country = form?.values?.tcountry;
          const resp = await getStateOptions(country);
          if (resp) {
            return resp;
          } else return [];
        },
      },
      {
        label: 'City/Town',
        name: 'tcity',
        id: 'tcity',
        placeholder: 'City/Town',
        type: 'select',
        component: SelectInput,
        dependency: ['tstate'],
        dependantFetchOptions: async (form) => {
          if (!form?.values?.tcountry || !form?.values?.tstate) return [];
          const country = form?.values?.tcountry;
          const state = form?.values?.tstate;
          const resp = await getCityOptions(country, state);
          if (resp) {
            return resp;
          } else return [];
        },
      },
      {
        label: 'Address',
        name: 'taddress',
        id: 'taddress',
        placeholder: 'Address',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        divClasses: 'col-span-2',
        maxLength: 60,
      },
      {
        label: 'Pincode',
        name: 'tpincode',
        id: 'tpincode',
        placeholder: 'Pincode',
        type: 'text',
        maxLength: 12,
        component: CustomTextInput,
        customOnChange: async ({ field, value, form }) => {
          const zipCode = value;
          const data = await fetchLocationData(zipCode);
          if (data) {
            form?.setFieldValue('tcountry', data?.country);
            form?.setFieldValue('tstate', data?.state);
            form?.setFieldValue('tcity', data?.city);
          } else setPinCodeError('Enter a valid pincode');
        },
        error: pincodeError,
      },
    ],
    showAccordionHeader: false,
  },
  Documents: {
    customClasses: 'h-[80vh]',
    showAccordionHeader: false,
    id: 'documents',
    subTitle: (
      <h2 id='documents' className='font-semibold text-s'>
        Documents
      </h2>
    ),
    fields: [
      {
        name: 'docs',
        type: 'fieldArray',
        id: 'docs',
        addButtonText: '+ Document',
        buttonsClassName: '-mt-8 -ml-4',
        valueSchema: { name: '', file: null },
        component: CustomFieldArray,
        divClasses: 'col-span-full',
        gridCols: 'grid-cols-3 py-2',
        fields: [
          {
            label: 'Document Name',
            name: 'name',
            id: 'name',
            placeholder: 'Document Name',
            type: 'text',
            component: SelectInput,
            options: documentOptions,
            maxLength: 30,
            required: false,
          },
          {
            label: 'Upload Document',
            name: 'file',
            id: 'file',
            placeholder: 'file',
            required: false,
            component: Uploadbox,
            dragger: false,
            accept: ['.png, .jpeg, .jpg, .pdf, .txt , .doc'],
          },
        ],
      },
    ],
  },
});

export const EmployeeFields = ({
  maxDate,
  setIfscCode,
  bankDetails,
  error,
  setPinCodeError,
  pincodeError,
  isEditing,
}) => {
  const items = [
    {
      key: 'personal-details',
      href: '#personal-details',
      title: 'Personal Details',
      color: 'red',
      fields: {
        'Personal Details': {
          subTitle: <h2 className='font-semibold text-s'>Personal Details</h2>,
          showAccordionHeader: false,
          fields: [
            {
              label: 'Name',
              name: 'name',
              id: 'name',
              placeholder: 'Name',
              type: 'text',
              required: true,
              component: TextInput,
              maxLength: 30,
            },
            {
              label: 'Date of Birth',
              name: 'dob',
              id: 'dob',
              placeholder: 'MM-DD-YYYY',
              type: 'date',
              required: true,
              showTime: false,
              component: DateTime,
              // disabledDate: maxDate,
            },
            {
              label: 'Contact Number',
              name: 'contact',
              id: 'contact',
              placeholder: 'Contact Number',
              type: 'tel',
              required: true,
              maxLength: 13,
              // component: PhoneNumberInput,
              component: TextInput,
            },
            {
              label: 'Alternate Contact Number',
              name: 'alt_con',
              id: 'alt_con',
              placeholder: 'Alternate Contact Number',
              type: 'number',
              required: false,
              component: TextInput,
            },
            {
              label: 'Email Address',
              name: 'email',
              id: 'email',
              placeholder: 'Email Address',
              type: 'text',
              required: true,
              component: TextInput,
              // maxLength: 30,
            },

            {
              label: 'Branch',
              name: 'branch',
              component: MultiSelect,
              id: 'branch',
              placeholder: 'Branch',
              hideCheckBox: true,
              hideOptionAll: true,
              type: 'select',
              checkbox: false,
              required: true,
              options: [],
              fetchOptions: getBranches,
            },
            {
              label: 'Assign Role',
              name: 'role_id',
              id: 'role_id',
              placeholder: 'Assign Role',
              type: 'select',
              required: true,
              component: SelectInput,
              options: [],
              fetchOptions: getRoles,
            },
            {
              label: 'Department',
              name: 'department_id',
              id: 'department_id',
              placeholder: 'Select Department',
              type: 'select',
              required: true,
              component: MultiSelect,
              hideCheckBox: true,
              hideOptionAll: true,
              fetchOptions: departmentManagement,
            },
            {
              label: 'Designation',
              name: 'designation',
              id: 'designation',
              type: 'select',
              component: SelectInput,
              fetchOptions: async () => searchApis({ api: 'designation' }),
            },
            {
              label: 'Report To',
              name: 'report_id',
              id: 'report_id',
              placeholder: 'Report To',
              type: 'search',
              component: SearchAPI,
            },
            {
              label: 'Joining Date',
              name: 'joining',
              id: 'joining',
              placeholder: 'Joining Date',
              type: 'date',
              component: DateTime,
              required: true,
              disableNext: true,
            },
            {
              label: 'Work From',
              name: 'work_from',
              id: 'work_from',
              placeholder: 'Work From',
              type: 'select',
              required: true,
              component: SelectInput,
              options: [
                {
                  label: 'Home',
                  value: 'home',
                },
                {
                  label: 'In Field',
                  value: 'in_field',
                },
                {
                  label: 'Office',
                  value: 'office',
                },
              ],
            },
            {
              label: 'Referred By',
              name: 'referred_by',
              id: 'referred_by',
              placeholder: 'Referred By',
              type: 'select',
              component: SelectInput,
              options: [
                {
                  label: 'Online',
                  value: 'online',
                },
                {
                  label: 'Employee',
                  value: 'employee',
                },
                {
                  label: 'Other',
                  value: 'other',
                },
              ],
            },
            {
              dependent: true,
              dependent_on: 'referred_by',
              dependent_value: 'employee',
              label: 'Referred By Employee ',
              name: 'employee',
              id: 'employee',
              placeholder: 'Select Employee',
              type: 'search',
              component: SearchAPI,
            },
          ],
        },
      },
    },
    {
      key: 'bank-details',
      href: '#bank-details',
      title: 'Bank Details',
      color: 'yellow',
      fields: {
        'Bank Details': {
          subTitle: <h2 className='font-semibold text-s'>Bank Details</h2>,
          showAccordionHeader: false,
          fields: [
            {
              label: 'Bank Name',
              name: 'bank',
              id: 'bank',
              placeholder: 'Bank Name',
              type: 'text',
              component: TextInput,
              maxLength: 16,
            },
            {
              label: 'Account Number',
              name: 'account',
              id: 'account',
              placeholder: 'Account Number',
              type: 'text',
              component: TextInput,
              maxLength: 16,
            },
            {
              label: 'IFSC Code',
              name: 'ifsc_code',
              id: 'ifsc_code',
              isUpperCase: true,
              placeholder: 'IFSC Code',
              type: 'ifsc',
              maxLength: 11,
              component: CustomTextInput,
              customOnChange: ({ field, value, form }) => {
                setIfscCode(value);
              },
              customIcon: bankDetails,
              title: `${bankDetails?.BANK}, ${bankDetails?.BRANCH},${bankDetails?.CITY}`,
              error: error,
            },
          ],
        },
      },
    },
    {
      key: 'additional-details',
      href: '#additional-details',
      title: 'Additional Details',
      color: 'pink',
      fields: {
        'Additional Details': {
          subTitle: <h2 className='font-semibold text-s'>Additional Details</h2>,
          showAccordionHeader: false,
          fields: [
            {
              label: 'Country',
              name: 'country',
              id: 'country',
              placeholder: 'Country',
              type: 'select',
              required: true,
              component: SelectInput,
              options: countryOptions,
              dependency: ['pincode'],
              customChange: (_, __, form) => {
                form?.setFieldValue('city', '');
              },
            },
            {
              label: 'State',
              name: 'state',
              id: 'state',
              placeholder: 'State',
              type: 'select',
              required: true,
              component: SelectInput,
              options: [],
              dependency: ['country'],
              dependantFetchOptions: async (form) => {
                if (!form?.values?.country) return [];
                const country = form?.values?.country;
                const resp = await getStateOptions(country);
                if (resp) {
                  return resp;
                } else return [];
              },
            },
            {
              label: 'City/Town',
              name: 'city',
              id: 'city',
              placeholder: 'City/Town',
              type: 'select',
              required: true,
              component: SelectInput,
              options: [],
              dependency: ['country', 'state'],
              dependantFetchOptions: async (form) => {
                if (!form?.values?.country || !form?.values?.state) return [];
                const country = form?.values?.country;
                const state = form?.values?.state;
                const resp = await getCityOptions(country, state);
                if (resp) {
                  return resp;
                } else return [];
              },
            },
            {
              label: 'Address',
              name: 'address',
              id: 'address',
              placeholder: 'Address',
              type: 'text',
              required: true,
              component: TextInput,
              maxLength: 60,
              divClasses: 'col-span-2',
            },
            {
              label: 'Pincode',
              name: 'pincode',
              id: 'pincode',
              placeholder: 'Pincode',
              type: 'text',
              required: true,
              component: CustomTextInput,
              customOnChange: async ({ field, value, form }) => {
                const zipCode = value;
                const data = await fetchLocationData(zipCode);
                if (data) {
                  form?.setFieldValue('country', data?.country);
                  form?.setFieldValue('state', data?.state);
                  form?.setFieldValue('city', data?.city);
                } else setPinCodeError('Enter a valid pincode');
              },
              error: pincodeError,
              maxLength: 12,
            },
            {
              label: 'As with the permanent address, the temporary address will also be the same.',
              name: 'checkaddress',
              id: 'checkaddress',
              type: 'checkbox',
              component: Checkbox,
              checked: true,
              divClasses: 'col-span-2',
            },
          ],
        },

        temp_address: {
          dependent: true,
          dependent_on: 'checkaddress',
          dependent_value: false,
          subTitle: 'Temporary Address',
          fields: [
            {
              label: 'Country',
              name: 'tcountry',
              id: 'tcountry',
              placeholder: 'Select Country',
              type: 'select',
              searchable: true,
              component: SelectInput,
              options: countryOptions,
              customChange: (field, values, form) => {
                form?.setFieldValue('tcity', '');
              },
            },
            {
              label: 'State',
              name: 'tstate',
              id: 'tstate',
              placeholder: 'State',
              type: 'select',
              component: SelectInput,
              options: [],
              dependency: ['tcountry'],
              dependantFetchOptions: async (form) => {
                if (!form?.values?.tcountry) return [];
                const country = form?.values?.tcountry;
                const resp = await getStateOptions(country);
                if (resp) {
                  return resp;
                } else return [];
              },
            },
            {
              label: 'City/Town',
              name: 'tcity',
              id: 'tcity',
              placeholder: 'City/Town',
              type: 'select',
              component: SelectInput,
              dependency: ['tstate'],
              dependantFetchOptions: async (form) => {
                if (!form?.values?.tcountry || !form?.values?.tstate) return [];
                const country = form?.values?.tcountry;
                const state = form?.values?.tstate;
                const resp = await getCityOptions(country, state);
                if (resp) {
                  return resp;
                } else return [];
              },
            },
            {
              label: 'Address',
              name: 'taddress',
              id: 'taddress',
              placeholder: 'Address',
              type: 'text',
              component: TextInput,
              isMultiline: true,
              divClasses: 'col-span-2',
              maxLength: 60,
            },
            {
              label: 'Pincode',
              name: 'tpincode',
              id: 'tpincode',
              placeholder: 'Pincode',
              type: 'text',
              maxLength: 12,
              component: CustomTextInput,
              customOnChange: async ({ field, value, form }) => {
                const zipCode = value;
                const data = await fetchLocationData(zipCode);
                if (data) {
                  form?.setFieldValue('tcountry', data?.country);
                  form?.setFieldValue('tstate', data?.state);
                  form?.setFieldValue('tcity', data?.city);
                } else setPinCodeError('Enter a valid pincode');
              },
              error: pincodeError,
            },
          ],
          showAccordionHeader: false,
        },
      },
    },
    {
      key: 'documents',
      href: '#documents',
      title: 'Documents',
      fields: {
        Documents: {
          subTitle: <h2 className='font-semibold text-s'>Documents</h2>,
          showAccordionHeader: false,
          fields: [
            {
              name: 'docs',
              type: 'fieldArray',
              id: 'docs',
              addButtonText: '+ Another',
              valueSchema: { name: '', file: null },
              component: CustomFieldArray,
              divClasses: 'col-span-full',
              gridCols: 'grid-cols-3 py-2',
              fields: [
                {
                  label: 'Document Name',
                  name: 'name',
                  id: 'name',
                  placeholder: 'Document Name',
                  type: 'text',
                  component: SelectInput,
                  options: documentOptions,
                  maxLength: 30,
                  required: false,
                },
                {
                  label: 'Upload Document',
                  name: 'file',
                  id: 'file',
                  placeholder: 'file',
                  required: false,
                  component: Uploadbox,
                  dragger: false,
                  accept: ['.png, .jpeg, .jpg, .pdf, .txt , .doc'],
                },
              ],
            },
          ],
        },
      },
    },
  ];

  return items;
};

/** @param {import('dayjs').Dayjs} date */
const shouldDateBeDisabledForEmployeesDOBField = (date) =>
  // adding some years makes the date(in the question) in the future implies it should be disabled.
  date.add(EMPLOYEE_MIN_AGE, 'years').isAfter(dayjs(), 'day');
// const leastValidDateForEmployeeDOB = dayjs().subtract(EMPLOYEE_MIN_AGE, 'years');
