import React, { useEffect, useState } from 'react';
import { MobileInsuranceLogo } from 'assets/icons';
import { CLAIM_COVERAGE } from './data';
import dayjs from 'dayjs';

const GridCell = ({ value, className }) => (
  <div className={`border border-grey-80 p-2 ${className}`}>{value}</div>
);

const PDFComponent = ({ getPolicies, details }) => {
  const [policies, setPolicies] = useState([]);
  useEffect(() => {
    if (typeof getPolicies === 'function') {
      setPolicies(getPolicies() || []);
    }
  }, []);

  // const renderDiscountsRows = (discount, index) => (
  //   <div key={index}>
  //     <GridCell value={discount.value} />
  //   </div>
  // );

  const renderPolicies = (policy) => {
    return (
      <>
        <div className='col-span-2'>
          <GridCell value={policy.name} className='font-bold' />
        </div>
        <div>
          <GridCell value={'INR. ' + policy.premium} />
        </div>
        <div>
          <GridCell value={policy.no_claim} />
        </div>
        <div>
          <GridCell value={policy.od_discount} />
        </div>
        {/* {policy.discounts.map(renderDiscountsRows)} */}
      </>
    );
  };

  return (
    <div className='p-4 border border-grey-70'>
      <div className='flex gap-4 items-center pb-4'>
        <MobileInsuranceLogo svgClass='w-12' />
        <h1 className='text-l text-primary-90 font-semibold'>Ello Insurance</h1>
      </div>
      <div className='font-semibold flex justify-between items-end'>
        <div>
          To,
          <div>{details.name}</div>
        </div>
        <div>Quote Date: {dayjs().format('DD-MM-YYYY')}</div>
      </div>
      <br />
      <div className='font-semibold'>Quotation Cum Proposal</div>
      <div className='grid grid-cols-5'>
        <div className='col-span-2'>
          <GridCell value='Name' className='font-bold ' />
        </div>
        <div>
          <GridCell value='Price' className='font-bold' />
        </div>
        <div>
          <GridCell className='font-bold' value='New No-Claim' />
        </div>
        <div>
          <GridCell className='font-bold' value='OD Discount' />
        </div>
        {policies.map(renderPolicies)}
      </div>
      <div className='py-4 px-3'>
        <h2 className='text-xs font-bold'> Claims Coverage</h2>
        <ul className='py-2 space-y-2'>
          {CLAIM_COVERAGE.map(({ point }, idx) => (
            <li className='flex gap-2 items-center bg-grey-light py-2 px-3 rounded-md' key={idx}>
              <div className='bg-green !w-2 !h-2 rounded-full'> </div>
              <span> {point}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PDFComponent;
