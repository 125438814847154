import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { LeadForm, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { SecureComponent } from 'permission-provider';
import './style.css';

const PosForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const onSuccess = useCallback((msg) => {
    Toast('success', NOTIFICATION_MSG.success, msg);
    navigate('/pos');
  }, []);

  const onError = useCallback((error) => {
    Toast('error', NOTIFICATION_MSG.error, error || 'Please try again');
  }, []);

  const backNavigate = () => {
    navigate(-1);
  };

  return (
    <>
      <div id='pos-lead-form' className='bg-white rounded-lg overflow-y-auto'>
        <LeadForm
          onSuccess={onSuccess}
          onError={onError}
          backNavigate={backNavigate}
          producttype={state?.productDetails}
          forPos={true}
        />
      </div>
    </>
  );
};

export default SecureComponent(PosForm);
