import TabsComponent from 'components/tabs';
import React, { useState } from 'react';
import OfflineQuotation from './OfflineQuotation';
import OnlineQuotation from './OnlineQuotation';

const QuotationCard = ({
  data,
  accordian,
  insurer,
  childProps,
  inquiry_id,
  getInquiry,
  company,
  customer,
  companyEmail,
  sender,
}) => {
  const [key, setActiveTabKey] = useState('1');
  const tabs = [
    {
      key: '1',
      label: `Offline`,
      Component: OfflineQuotation,
    },
    {
      key: '2',
      label: `Online`,
      Component: OnlineQuotation,
    },
  ];

  return (
    <div className='px-7 mb-auto'>
      {childProps && childProps}
      <TabsComponent
        items={tabs}
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
        extraProps={{ data, accordian, insurer }}
      />
    </div>
  );
};

export default QuotationCard;
