// import { Tabs } from 'antd';
// import { useState } from 'react';
import Kra from './kra';
// import Kpi from './kpi';

const JobKprKpi = (props = {}) => {
  const permissions = props?.permissions || [];
  // const [renderModule, setRenderModule] = useState('1');

  // const items = [
  //   {
  //     key: '1',
  //     label: `JOB KPR's `,
  //     // children: "this is it"
  //   },
  //   {
  //     key: '2',
  //     label: `JOB KPI's `,
  //     children: `Coming Soon...`,
  //   },
  // ];
  // const onChange = (key) => {
  //   setRenderModule(key);
  // };

  return (
    <>
      {/* <div className=" rounded-lg  flex justify-start items-center ">
        <Tabs onChange={onChange} items={items} defaultActiveKey={renderModule} />
      </div> */}

      <Kra permissions={permissions} />
      {/* {renderModule === "1" && <Kra />} */}
      {/* {renderModule === "2" && <Kpi />} */}
    </>
  );
};

export default JobKprKpi;
