import React, { useState } from 'react';

const ShowMoreArrayData = ({ data }) => {
  const [showMore, setShowMore] = useState(false);
  const firstItems = data.slice(0, 1);
  const remainingItems = showMore ? data.slice(1) : [];
  return (
    <div>
      <div>{firstItems.length > 0 && firstItems[0]}</div>
      {showMore && remainingItems.length > 0 && <div>,{remainingItems.join(', ')}</div>}
      {!showMore && data.length > 1 && (
        <p className='text-primary-30 text-start items-start' onClick={() => setShowMore(true)}>
          More
        </p>
      )}
      {data.length === 0 && '_'}
    </div>
  );
};

export default ShowMoreArrayData;
