import { endpoints } from 'constants/endpoints';
import Request from '../client';

export const getSalary = async ({ query = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.salary.list(query),
  });
  return resp;
};
export const addSalary = async ({ data = undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.salary.create,
    data,
  });
  return resp;
};
export const editSalary = async ({ data = undefined, id = '' }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.salary.update(id),
    data,
  });
  return resp;
};
export const deleteSalary = async ({ id = '' }) => {
  const resp = await Request({
    method: 'delete',
    API: endpoints.salary.delete(id),
  });
  return resp;
};
