import { InsuranceLogo } from 'assets/icons';
import { Confetti, Toast } from 'components';
import Button from 'components/button';
import { NOTIFICATION_MSG } from 'constants';
import React, { useEffect, useState } from 'react';
import { inquiryManagement } from 'service/api-service';

const BUyPolicy = ({ id }) => {
  let playRef;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState('');
  const [confetti, setConfetti] = useState(false);

  function getParameterValue(url, parameter) {
    const parameterRegex = new RegExp(`${parameter}=([^&]+)`);
    const match = url.match(parameterRegex);
    if (match && match.length > 1) {
      return decodeURIComponent(match[1]);
    }
    return null;
  }
  useEffect(() => {
    const query = window.location.search;
    const inquiry = getParameterValue(query, 'inq');
    const quotation = getParameterValue(query, 'quote');
    const company = getParameterValue(query, 'company');
    const premium = getParameterValue(query, 'premium');
    const data = {
      inquiry: inquiry,
      quotation: quotation,
      company: company,
      premium: premium,
    };
    setData(data);
  }, []);

  const confer = () => {
    // playRef.play();
    // playRef.play();
    // playRef.play();
    // playRef.play();
  };

  const purchasePolicy = async () => {
    setLoading(true);
    const resp = await inquiryManagement({
      method: 'patch',
      id: data?.inquiry,
      data: { status: 'accepted' },
    });
    // const resp = await buyPolicy({ inquiry_id: data?.inquiry, quote_id: data?.quotation });
    if (resp?.data?.status) {
      Toast(
        'success',
        NOTIFICATION_MSG.success,
        resp?.data?.msg || 'Policy purchased Successfully',
      );
      setConfetti(true);
      confer();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  return (
    <>
      <Confetti
        ref={(ref) => {
          playRef = ref;
        }}
      />
      {confetti ? (
        <div className='h-screen flex items-center'>
          <div className='shadow-custom m-auto w-1/2 flex flex-col justify-center items-center p-6 rounded-lg'>
            <h1>Thank You</h1>
          </div>
        </div>
      ) : (
        <div className='h-screen flex items-center'>
          <div className='shadow-custom m-auto w-1/2 flex flex-col justify-center items-center p-6 rounded-lg'>
            <div className='w-fit m-auto'>
              <InsuranceLogo svgClass='w-[90px] h-16' />
            </div>

            <div className='flex justify-center items-center flex-col mt-2'>
              <span className='text-l xl:text-xl font-medium'>Buy Policy</span>
              <span className='text-grey-90 text-x pb-[30px] text-center font-medium'>
                In order to buy the policy, please click the buy button.
              </span>
            </div>
            <div className='grid grid-cols-2'>
              <div>Company:-</div>
              <div>{data?.company}</div>
              <div>Premium:-</div>
              <div>{data?.premium}</div>
            </div>

            <Button text='Buy Now' onClick={purchasePolicy} loading={loading} />
          </div>
        </div>
      )}
    </>
  );
};

export default BUyPolicy;
