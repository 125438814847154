import Request from 'service/api-service/client';

// adjusterManagement

export const getAdjusterManagement = async () => {
  const resp = await Request({
    method: 'get',
    API: `adjuster/`,
  });
  return resp;
};

export const postAdjuster = async (props = { data: '' }) => {
  const resp = await Request({
    method: 'post',
    API: `adjuster/`,
    data: props?.data,
  });
  return resp;
};

export const deleteAdjuster = async (props = { adjuster_id: '' }) => {
  const resp = await Request({
    method: 'delete',
    API: `adjuster/${props?.adjuster_id}`,
  });
  return resp;
};

export const updateAdjuster = async (props = { adjuster_id: '', data: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `adjuster/${props?.adjuster_id}`,
    data: props?.data,
  });
  return resp;
};
