import React, { useState } from 'react';
import imgGoDigit from 'assets/png/godigit.png';
// import reliance from "assets/png/reliance.png";
import { EditableLabel } from 'screens/customers/quote/add-quote/ComparePolicy';
import { IconChevron } from 'assets/icons';
import { Button } from 'antd';
// import { Context } from 'store/context';

const PolicyData = ({ productsPolicy = [], data }) => {
  // const { user } = useContext(Context);
  const [activeDetailsIndex, setActiveDetailsIndex] = useState(undefined);

  const buyPolicy = async (policy) => {
    // const payload = {
    //   premium: policy?.premium,
    //   sum_insured: policy?.sum_insured,
    //   details: data?.insurance_details,
    //   sell_id: user?.id,
    //   sell_by: 'employee',
    //   customer: data?.customer?.id,
    //   product: policy?.id,
    //   renewal: data?.ins_to,
    //   quotation: data?.id,
    // };
    // const resp = await buyPolicies({
    //   data: payload,
    // });
    // if (resp?.data?.status) {
    //   Toast("success", NOTIFICATION_MSG.success, resp?.data?.msg || "Policy Bought Successfully");
    // } else {
    //   Toast("error", NOTIFICATION_MSG.error, resp?.error || "Please try again");
    // }
  };

  // const [productsPolicy, setProductPolicy] = useState([
  //     {
  //         id: 1,
  //         name: "Digital Insurance Plan",
  //         image: imgGoDigit,
  //         highlights: [
  //             "Network of 2,000+ cashless",
  //             "24x7 claims insurance services",
  //             "Easy claim settlement process",
  //         ],
  //         discounts: [
  //             { id: "10", name: "New No-Claim", value: "20%" },
  //             { id: "11", name: "OD Discount", value: "60%" },
  //         ],
  //         price: 2499,
  //     },
  //     {
  //         id: 2,
  //         name: "Reliance General Insurance",
  //         image: reliance,
  //         highlights: [
  //             "Network of 2,000+ cashless",
  //             "24x7 claims insurance services",
  //             "Easy claim settlement process",
  //         ],

  //         discounts: [
  //             { id: "12", name: "New No-Claim", value: "20%" },
  //             { id: "13", name: "OD Discount", value: "60%" },
  //         ],
  //         price: 2499,
  //     },
  //     {
  //         id: 3,
  //         name: "Digital Insurance Plan",
  //         image: imgGoDigit,
  //         highlights: [
  //             "Network of 2,000+ cashless",
  //             "24x7 claims insurance services",
  //             "Easy claim settlement process",
  //         ],
  //         discounts: [
  //             { id: "13", name: "New No-Claim", value: "10%" },
  //             { id: "14", name: "OD Discount", value: "70%" },
  //         ],
  //         price: 1799,
  //     },
  //     {
  //         id: 4,
  //         name: "Reliance General Insurance",
  //         image: reliance,
  //         highlights: [
  //             "Network of 2,000+ cashless",
  //             "24x7 claims insurance services",
  //             "Easy claim settlement process",
  //         ],

  //         discounts: [
  //             { id: "15", name: "New No-Claim", value: "30%" },
  //             { id: "16", name: "OD Discount", value: "80%" },
  //         ],
  //         price: 2299,
  //     },
  //     {
  //         id: 5,
  //         name: "Reliance General Insurance",
  //         image: reliance,
  //         highlights: [
  //             "Network of 2,000+ cashless",
  //             "24x7 claims insurance services",
  //             "Easy claim settlement process",
  //         ],

  //         discounts: [
  //             { id: "17", name: "New No-Claim", value: "70%" },
  //             { id: "18", name: "OD Discount", value: "30%" },
  //         ],
  //         price: 2099,
  //     },
  // ]);
  // const [selectedPolicies, setSelectedPolicies] = useState([]);

  // const handleChange = ({ event, policyId }) => {
  //   let prevSelectedPolicies = selectedPolicies;
  //   if (event.target.checked) {
  //     prevSelectedPolicies.push(policyId);
  //   } else {
  //     prevSelectedPolicies = prevSelectedPolicies.filter((pId) => pId !== policyId);
  //   }
  //   setSelectedPolicies(prevSelectedPolicies);
  // };

  const handleExpandDetailsClick = (policyId) => {
    setActiveDetailsIndex(activeDetailsIndex !== policyId ? policyId : '');
  };

  const onChange = ({ e, parentId }) => {
    // const updateFor = e.target.name.split("-")[0];
    // const updateElementId = e.target.name.split("-")[1];
    // const value = e.target.value;
    // const parent = parentId;
    // if (updateFor === "percent") {
    //   setProductPolicy((pre) => {
    //     return pre.map((item) => {
    //       if (item.id === parent) {
    //         return {
    //           ...item,
    //           discounts: item.discounts.map((dis) => {
    //             if (dis.id === updateElementId) {
    //               return { ...dis, value: value };
    //             }
    //             return dis;
    //           }),
    //         };
    //       }
    //       return item;
    //     });
    //   });
    // } else if (updateFor === "discount") {
    //   setProductPolicy((pre) => {
    //     return pre.map((item) => {
    //       if (item.id === parent) {
    //         return {
    //           ...item,
    //           discounts: item.discounts.map((dis) => {
    //             if (dis.id === updateElementId) {
    //               return { ...dis, name: value };
    //             }
    //             return dis;
    //           }),
    //         };
    //       }
    //       return item;
    //     });
    //   });
    // } else if (updateFor === "inr") {
    //   setProductPolicy((pre) => {
    //     return pre.map((item) => {
    //       if (item.id === parent) {
    //         return {
    //           ...item,
    //           price: value,
    //         };
    //       }
    //       return item;
    //     });
    //   });
    // }
  };
  return (
    <div>
      <div className='grid gap-6 pt-6'>
        {productsPolicy?.map((policy, i) => (
          <>
            <div className='flex items-center justify-between gap-6'>
              <div key={i} className='w-full'>
                <div className='grid gap-4'>
                  <div className='rounded-lg border-2 border-grey-80'>
                    <p className='p-4 pb-0 font-semibold text-grey text-xxs'>{policy.name}</p>
                    <div className='grid  md:grid-cols-2 md:gap-3 lg:grid-cols-3 xl:grid-cols-4 items-center justify-between divide-x divide-grey-70 pb-4 pt-2'>
                      <div className='px-4 w-44 2xl:w-4/6'>
                        <img
                          src={policy.image ? policy.image : imgGoDigit}
                          alt={policy.name}
                          className='rounded-xl h-16 2xl:h-full w-full'
                        />
                      </div>
                      {/* <ul className="px-2 2xl:px-4">
                        {comparePolicyData.highlights.map((item) => (
                          <li
                            key={item}
                            className="flex gap-2 items-baseline before:rounded-full before:w-2 before:h-2 before:bg-grey-90 before:block text-grey text-x"
                          >
                            {item}
                          </li>
                        ))}
                        {policy.benefit}
                      </ul> */}
                      <div
                        className='product_benefits max-lines'
                        dangerouslySetInnerHTML={{ __html: policy.benefits }}
                      />
                      <div className='grid grid-cols-[1fr,max-content] gap-y-1 px-2 2xl:px-4'>
                        {/* {comparePolicyData.discounts.map((discount) => {
                      return (
                        <Fragment key={discount.name}>
                          <EditableLabel
                            className="w-28 outline-transparent text-x text-grey"
                            defaultValue={discount.name}
                            maxLength="18"
                            name={"discount-" + discount.id}
                            onChange={(e) => {
                              onChange({ e: e, parentId: policy.id });
                            }}
                          />
                          <EditableLabel
                            className="w-8 outline-transparent text-x text-grey"
                            defaultValue={discount.value}
                            maxLength="3"
                            name={"percent-" + discount.id}
                            onChange={(e) => {
                              onChange({ e: e, parentId: policy.id });
                            }}
                          />
                        </Fragment>
                      );
                    })} */}
                      </div>
                      <div className='flex items-center justify-around px-2 2xl:px-4'>
                        <p className='flex gap-2 items-baseline font-semibold text-grey '>
                          <span className='text-xxs'>INR.</span>
                          <EditableLabel
                            className='w-24 text-xl outline-transparent border-0 truncate'
                            defaultValue={new Intl.NumberFormat(undefined).format(policy.premium)}
                            maxLength='10'
                            name={'inr-' + policy.id}
                            onChange={(e) => {
                              onChange({ e: e, parentId: policy.id });
                            }}
                          />
                          {/* <span contentEditable="true" className="text-xxl outline-transparent">
                            {new Intl.NumberFormat(undefined).format(policy.price)}
                          </span> */}
                        </p>
                        {/* <div className="">
                          <Checkbox
                            updateClass="!pl-0"
                            variant="secondary"
                            size="lg"
                            isRounded={true}
                            updateSpanClass=" -translate-y-8 translate-x-6 !rounded-full scale-150 !h-6 !w-6"
                            onChange={(e) => {
                              handleChange({ event: e, policyId: policy.id });
                            }}
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className='flex justify-end items-end px-3 py-2 rounded-md rounded-t-none bg-grey-light'>
                      <button
                        onClick={() => handleExpandDetailsClick(policy.id)}
                        className='flex gap-2 items-center text-x'
                      >
                        View More Details
                        <IconChevron
                          className={`w-3 h-3 fill-primary-90 details_icon ${
                            activeDetailsIndex === policy.id ? 'rotate-0' : '-rotate-90'
                          }`}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* buy button */}
              <div>
                <Button
                  shape='default'
                  className='bg-primary text-white'
                  onClick={() => buyPolicy(policy)}
                >
                  Buy Now
                </Button>
              </div>
            </div>
            {/* policy details */}
            <div
              className={`${
                activeDetailsIndex === policy.id ? 'block' : 'hidden'
              } grid grid-cols-1 border border-primary-70 bg-primary-light/[0.3] mt-2 p-2 gap-4 rounded-lg transition-all duration-500`}
            >
              <div className='px-2'>
                <span className='font-medium leading-10'>Conditions</span>
                {policy?.condition ? (
                  <div
                    className='product_benefits max-lines'
                    dangerouslySetInnerHTML={{ __html: policy?.condition }}
                  />
                ) : (
                  <div className='text-grey-80 text-x'>No details</div>
                )}
              </div>
              <div className='px-2'>
                <span className='font-medium leading-10'>Exclusion</span>
                {policy?.exclusion ? (
                  <div
                    className='product_benefits max-lines'
                    dangerouslySetInnerHTML={{ __html: policy?.exclusion }}
                  />
                ) : (
                  <div className='text-grey-80 text-x'>No details</div>
                )}
              </div>
              <div className='px-2'>
                <span className='font-medium leading-10'>Description</span>
                {policy?.description ? (
                  <div
                    className='product_benefits max-lines'
                    dangerouslySetInnerHTML={{ __html: policy?.description }}
                  />
                ) : (
                  <div className='text-grey-80 text-x'>No details</div>
                )}
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default PolicyData;
