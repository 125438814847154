import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

import { ContentHeader } from 'components';
import UpcomingInterview from './UpcomingInterview';
import UpcomingLeave from './UpcomingLeave';
import RequestForInterview from './RequestForInterview';
import AssetAllocation from './AssetAllocation';
import CurrentMonthBirthday from './CurrentMonthBirthday';
import Statics from './Statics';
import { getPermission } from 'utils';
import { elementData } from 'elements';
import AllTickets from './AllTickets';
import CustomEvents from './CustomEvents';

export const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Hr Dashboard',
    path: '',
  },
];

const HRDashboard = ({ permissions }) => {
  /* states */
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  /* func */
  const getDateRange = (date) => {
    setDate(date.format('YYYY-MM-DD'));
  };
  return (
    <>
      <div className='grid gap-3'>
        {/* <ContentHeader
          permissions={permissions}
          // title={<p className='font-semibold text-s'>Hr Dashboard</p>}
          // data={breadcrumbObj}
          showSearch={false}
        > */}
        <div id='reports-details' className='absolute top-[-54px] right-[0px]'>
          <div className='flex items-center justify-end' id='dashboard'>
            <DatePicker
              className='w-4/5'
              defaultValue={dayjs()}
              format='YYYY-MM-DD'
              onChange={getDateRange}
            />
          </div>
        </div>
        {/* </ContentHeader> */}
        <div className='grid grid-cols-12 gap-4'>
          <div className='col-span-8 flex flex-col gap-2'>
            {/* {getPermission(permissions, elementData.attendance.name) && ( */}
            <Statics permissions={permissions} date={date} />
            {/* )} */}
            {getPermission(permissions, elementData.interview.name)?.read && (
              <UpcomingInterview date={date} permissions={permissions} />
            )}
          </div>
          {getPermission(permissions, elementData.leaves_request.name)?.read && (
            <div className='col-span-4'>
              <UpcomingLeave date={date} permissions={permissions} />
            </div>
          )}
          {getPermission(permissions, elementData.interview.name)?.read && (
            <div className='col-span-4'>
              <RequestForInterview date={date} permissions={permissions} />
            </div>
          )}
          {getPermission(permissions, elementData.assetAllocation.name)?.read && (
            <div className='col-span-8'>
              <AssetAllocation permissions={permissions} />
            </div>
          )}
          {getPermission(permissions, elementData.employee.name)?.read && (
            <div className='col-span-5'>
              <CurrentMonthBirthday date={date} permissions={permissions} />
            </div>
          )}
          {getPermission(permissions, elementData.ticket.name)?.read && (
            <div className='col-span-7'>
              <AllTickets date={date} permissions={permissions} />
              {/* <AllTickets/> */}
            </div>
          )}
          {getPermission(permissions, elementData.employee.name)?.read && (
            <div className='col-span-5'>
              <CustomEvents date={date} permissions={permissions} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HRDashboard;
