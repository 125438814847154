/** external deps */
import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
/** internal deps */
import {
  IconButton,
  CustomModal,
  Toast,
  Button,
  MultiEmail,
  TableComponent,
  MultiUserInitial,
} from 'components';
import { DELETE, LeadCreateQuoteIcon, Trash, View } from 'assets/icons';
import { inquiryManagement } from 'service/api-service';
import { elementData } from 'elements';
import { Context, useStore } from 'store/context';
import { NOTIFICATION_MSG } from 'constants';
import { getQueryParams, rowCommonClass, Searchable } from 'utils/common';
import InsurerModal from './InsurerModal';

const PAGE_SIZE = 10;

const LeadTable = ({ permissions = [], customer_id, inquiryMethod, selectedValue, tabKey }) => {
  const {
    leads: { list: leads, updateLeads, total, loading: inquiryLoading, pageNum },
  } = useContext(Context);
  const navigate = useNavigate();
  const { active } = getQueryParams();

  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedInquiryRow, setSelectedInquiryRow] = useState();
  const [inquiry, setInquiry] = useState([]);
  const [showInsurerModal, setShowInsurerModal] = useState(false);
  const [insurerModalData, setInsurerModalData] = useState({});

  /** funcs */
  const [opened, dispatch] = useStore((state) => state.dialerOpened);

  const toggleDialer = useCallback(
    (e, contact) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (opened) {
        dispatch({ type: 'DIALER_CLOSE' });
      } else {
        dispatch({ type: 'DIALER_OPEN', contact });
      }
    },
    [opened, dispatch],
  );

  const AssignToEmployee = async (data, record) => {
    const finalPayload = {
      assigned_to: data?.id,
    };
    const resp = await inquiryManagement({ method: 'patch', data: finalPayload, id: record?.id });
    if (resp?.data?.status) {
      updateLeads({
        pageNum,
        employee_id: selectedValue?.employee_id,
        category_id: selectedValue?.insurance_category,
        product_type: selectedValue?.product_type,
        status: tabKey,
        type: active === 'online' ? 'online' : active === 'pos' ? 'pos' : 'employee',
      });
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  const handleChangeStatus = async (record) => {
    setShowInsurerModal(true);
    setInsurerModalData(record);
  };
  const toggleInsurerModal = () => {
    setShowInsurerModal((prev) => !prev);
  };

  const InquiryColumns = [
    {
      title: 'Assigned To',
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      // width: active === 'pos' ? 1 : 200,
      id: 'assigned_to',
      render: (data, record) => (
        <Searchable
          type='search'
          placeholder='Type here to search'
          field={{ name: 'employee' }}
          defaultValue={data}
          getVals={false} // To stop Api from being call when data populates
          onChange={(data) => {
            AssignToEmployee(data, record);
          }}
          divClasses='w-full bg-white'
        />
      ),
      //sorter: true,
    },
    {
      title: 'ID',
      dataIndex: 'lead_id',
      key: 'lead_id',
      id: 'lead_id',
      //sorter: true,
    },
    {
      title: 'Product',
      dataIndex: 'products',
      key: 'products',
      id: 'products',
      render: (data) => {
        const newData = data?.map((item) => {
          return {
            name: item?.product_type?.name,
          };
        });
        return (
          <div className='truncate capitalize'>
            <MultiUserInitial showImage data={newData} value={'name'} label={'Products'} />
          </div>
        );
      },

      //sorter: true,
    },
    {
      title: 'Client Name',
      dataIndex: 'customer',
      key: 'customer_id',
      id: 'customer_id',
      render: (data) => (
        <span className='capitalize'>{data?.name || data?.corp_name || '\u2014'}</span>
      ),
      //sorter: true,
    },
    {
      title: 'Contact',
      dataIndex: 'customer',
      key: 'customer_contact',
      id: 'customer_contact',
      width: 200,
      render: (data) =>
        (
          <div
            className='flex w-full underline truncate cursor-pointer'
            onClick={(e) => toggleDialer(e, data?.contact_number)}
          >
            {data?.contact_number}
          </div>
        ) || '\u2014',
      //sorter: true,
    },
    {
      title: () => <div className=' -ml-10   '>Email</div>,
      dataIndex: 'customer',
      key: 'customer_email',
      width: 200,
      id: 'customer_email',
      render: (data) => {
        return (
          (
            <div className='-ml-10 '>
              <MultiEmail
                text={data?.email_address}
                button={false}
                titleClasses={'underline cursor-pointer'}
                imageRef='invoice-slip'
                emailData={data?.email_address}
              />
            </div>
          ) || '\u2014'
        );
      },

      //sorter: true,
    },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      key: 'created_by',
      id: 'created_by',
      render: (text) => text?.name || '\u2014',
    },
    {
      title: 'Created at',
      key: 'action',
      // align: 'right',
      render: (_, record) => {
        const date = dayjs(record?.created);
        const dateToCheck = dayjs(record?.created);
        const currentDate = dayjs();

        let colorClass = '';
        if (currentDate.diff(dateToCheck, 'hour') < 1) {
          colorClass = 'text-green';
        } else if (
          currentDate.diff(dateToCheck, 'hour') > 1 &&
          currentDate.diff(dateToCheck, 'hour') < 2
        ) {
          colorClass = 'text-yellow';
        } else {
          colorClass = 'text-red';
        }

        return (
          <div>
            <span className={colorClass}>{dayjs(date).fromNow()}</span>
          </div>
        );
      },
    },
  ].concat(
    tabKey === 'quoted'
      ? [
          {
            title: 'Status',
            key: 'status',
            // align: 'right',
            render: (record) => {
              if (record?.status === 'quoted') {
                return (
                  <Button
                    disabled={record?.status !== 'quoted'}
                    text='Propose'
                    onClick={() => navigate(`/lead/create/${record.id}/3`, record)}
                    classes={`${record?.status !== 'quoted' ? 'hidden ' : ''}`}
                  ></Button>
                );
              } else {
                return <p>{record?.status}</p>;
              }
            },
          },
        ]
      : [],
  );

  const toggleDeleteInquiry = () => setDeleteModal((pre) => !pre);

  /* Delete inquiry */
  const onDeleteInquiry = async () => {
    setLoading(true);
    const id = selectedInquiryRow?.id;
    if (!id) return;
    const payload = { status: 'deleted' };
    const resp = await inquiryManagement({ method: 'patch', id, data: payload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Lead deleted successfully');
      toggleDeleteInquiry();
      updateLeads({
        pageNum,
        status: tabKey,
        type: active === 'online' ? 'online' : active === 'pos' ? 'pos' : 'employee',
      });
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onTableSort = useCallback(
    (page, filters, sorter) => {
      if (sorter && Object.keys(sorter)?.length > 0) {
        const { columnKey: item, order: sortingOrder } = sorter;
        const order = sortingOrder === 'descend' ? '-' : '';

        const columnKeyToSortField = {
          assigned_to: 'assigned_to__name',
          products: 'inquiry_products__product_type__name',
          customer_id: 'customer__customer_name',
          customer_contact: 'customer__contact_number',
          customer_email: 'customer__email_address',
          lead_id: 'lead_id',
        };

        const sortedItem = columnKeyToSortField[item] || '';

        updateLeads({
          pageNum: page?.current,
          order,
          sortedItem,
          status: tabKey,
          employee_id: selectedValue?.employee_id,
          category_id: selectedValue?.insurance_category,
          product_type: selectedValue?.product_type,
          type: active === 'online' ? 'online' : active === 'pos' ? 'pos' : 'employee',
        });
      } else if (page?.current) {
        updateLeads({
          pageNum: page?.current,
          status: tabKey,
          type: active === 'online' ? 'online' : active === 'pos' ? 'pos' : 'employee',
        });
      }
    },
    [updateLeads, tabKey, selectedValue],
  );

  // const getRowClassName = (record) => {
  //   const dateToCheck = dayjs(record?.created);
  //   const currentDate = dayjs();
  //   let colorClass = '';
  //   if (record?.status === 'draft' && currentDate.diff(dateToCheck, 'hour') < 1) {
  //     colorClass = 'bg-green-20';
  //   } else if (
  //     currentDate.diff(dateToCheck, 'hour') >= 1 &&
  //     currentDate.diff(dateToCheck, 'hour') < 2 &&
  //     record?.status === 'draft'
  //   ) {
  //     colorClass = 'bg-yellow-light';
  //   } else if (currentDate.diff(dateToCheck, 'hour') >= 2 && record?.status === 'draft') {
  //     colorClass = 'bg-red-20';
  //   }
  //   return colorClass;
  // };

  useEffect(() => {
    if (leads) {
      setInquiry(leads);
    }
  }, [leads]);

  return (
    <>
      <TableComponent
        getRowClassName={rowCommonClass}
        className='mt-3'
        loading={loading || inquiryLoading}
        noDataAction={() =>
          updateLeads({
            pageNum,
            employee_id: selectedValue?.employee_id,
            category_id: selectedValue?.insurance_category,
            product_type: selectedValue?.product_type,
            status: tabKey,
            type: active === 'online' ? 'online' : active === 'pos' ? 'pos' : 'employee',
          })
        }
        columns={[
          ...InquiryColumns,
          {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
              <div className='flex gap-2'>
                {record?.status === 'draft' && (
                  <IconButton
                    title='Create Quote'
                    permissions={permissions}
                    id={elementData.Leads.permissions.view}
                    className='w-5 h-5 group disabled:cursor-not-allowed'
                    onClick={() => navigate(`/lead/create/${record?.id}/2`)}
                  >
                    <LeadCreateQuoteIcon className='w-4 h-4' />
                  </IconButton>
                )}
                {/* <IconButton
                  title='View'
                  permissions={permissions}
                  id={elementData.Leads.permissions.view}
                  className='group disabled:cursor-not-allowed'
                  onClick={() =>
                    navigate(`/lead-detail/${record?.id}`, {
                      state: inquiryMethod,
                    })
                  }
                >
                  <View svgClass='stroke-primary group-disabled:stroke-grey' />
                </IconButton> */}
                <IconButton
                  title='view'
                  permissions={permissions}
                  id={elementData.Leads.permissions.update}
                  className='group disabled:cursor-not-allowed'
                  onClick={() =>
                    navigate(`/lead/edit/${record?.id}/${getStepForLinkByTab(tabKey)}`, {
                      state: { customer_id: customer_id, inquiryMethod: inquiryMethod },
                    })
                  }
                >
                  <View svgClass='stroke-primary group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Delete'
                  permissions={permissions}
                  id={elementData.Leads.permissions.delete}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    setSelectedInquiryRow(record);
                    toggleDeleteInquiry();
                  }}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton>
              </div>
            ),
          },
        ]}
        dataSource={inquiry}
        pageSize={PAGE_SIZE}
        total={total}
        onChange={onTableSort}
        defaultPage={pageNum}
      />

      {/* delete modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteInquiry}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='w-16 h-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='pb-3 text-xs text-center text-grey-80'>
            Could you please confirm that you really wish to delete the lead?
          </span>
          <div className='flex flex-row justify-center pt-4 bg-white space-x-4'>
            <Button
              id={elementData.Leads.permissions.delete}
              permissions={permissions}
              text='Delete Lead'
              variant='primary'
              classes={`ml-6 px-8   
             bg-black
            `}
              onClick={onDeleteInquiry}
              loading={loading}
            />
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteInquiry}
            />
          </div>
        </div>
      </CustomModal>
      <InsurerModal
        showInsurerModal={showInsurerModal}
        data={insurerModalData}
        toggleInsurerModal={toggleInsurerModal}
      />
    </>
  );
};

export default LeadTable;

const getStepForLinkByTab = (tab) => {
  switch (tab) {
    case 'quoted':
    case 'rejected':
    case 'accepted':
    case 'losses':
      return 2;
    case 'converted':
      return 4;
    case 'draft':
    default:
      return 1;
  }
};
