import { useEffect, useState } from 'react';

import { Button } from 'components';
import { getCommission } from 'service/api-service';
import AddCommission from './addCommission';
import CommissionTable from './CommissionTable';

const AllCommission = (props = {}) => {
  const { permissions } = props;
  const [loading, setLoading] = useState(false);
  const [showAddModal, setAddModal] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [data, setData] = useState([]);

  const getCommissionList = async () => {
    setLoading(true);
    const resp = await getCommission({ type: 'pos' });
    if (resp?.data?.status) {
      setLoading(false);
    }
    setData(resp?.data?.data || []);
    setLoading(false);
  };
  const toggleAddAsset = () => setAddModal((pre) => !pre);

  useEffect(() => {
    getCommissionList();
  }, []);

  useEffect(() => {
    const newData = data?.filter((item) => item.comm_set === 'pos_all');
    setCardData(newData);
  }, [data]);

  return (
    <div className='w-full bordered bg-white relative rounded-lg max-h-[80vh]'>
      {props.tabs}
      <div className=' overflow-y-auto h-[360px]  p-4'>
        <Button
          classes={`z-10 absolute right-4 top-2 `}
          onClick={toggleAddAsset}
          text='+  Commission'
          variant='primary'
        />

        {/* <div className='grid grid-cols-2 h-[60vh] overflow-y-auto'>
        {cardData?.map((item, i) => (
          <Commissioncard
            key={item.id}
            editInitials={item}
            getCommissionList={getCommissionList}
          />
        ))}
      </div> */}
        <CommissionTable
          permissions={permissions}
          loading={loading}
          setLoading={setLoading}
          data={cardData}
          refresh={getCommissionList}
          commissionSet='pos_all'
        />
        <AddCommission
          open={showAddModal}
          toggle={toggleAddAsset}
          setLoading={setLoading}
          loading={loading}
          refresh={getCommissionList}
          commissionSet='pos_all'
        />
      </div>
    </div>
  );
};

export default AllCommission;
