import React, { useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { PieChartOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { BarGraphIcon } from 'assets/icons';
import { IconButton } from 'components';

const legendColors = [
  'rgb(255, 99, 132)',
  'rgb(75, 192, 192)',
  'rgb(255, 205, 86)',
  'rgb(201, 203, 207)',
  'rgb(54, 162, 235)',
  'rgb(100, 155, 141)',
  'rgb(191, 87, 104)',
];

const ReconciliationGraph = ({ data, insurer }) => {
  const [showChart, setShowChart] = useState(true);

  const CardData = [
    {
      title: 'Premium',
      value: data?.premium,
    },
    {
      title: 'GST',
      value: data?.total_gst_premium,
    },
    {
      title: 'Gross Premium',
      value: data?.gross_premium,
    },
    {
      title: 'Insurer Commission',
      value: data?.total_insurer_commission,
    },
    {
      title: 'Pos Commission',
      value: data?.total_pos_commission,
    },
    {
      title: 'Employee Commission',
      value: data?.total_employee_commission,
    },
    {
      title: 'Other Fees',
      value: data?.total_other_fees,
    },
    // {
    //   title: 'Discount',
    //   value: data?.total_discount,
    // },
  ];

  const GraphData = {
    labels: [
      'Premium',
      'GST',
      'Gross Premium',
      'Insurer Commission',
      'Pos Commission',
      'Employee Commission',
      'Other Fees',
      // 'Discount',
    ],
    datasets: [
      {
        data: [
          data?.premium,
          data?.total_gst_premium,
          data?.gross_premium,
          data?.total_insurer_commission,
          data?.total_pos_commission,
          data?.total_employee_commission,
          data?.total_other_fees,
          // data?.total_discount,
        ],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(75, 192, 192)',
          'rgb(255, 205, 86)',
          'rgb(201, 203, 207)',
          'rgb(54, 162, 235)',
          'rgb(100, 155, 141)',
          'rgb(191, 87, 104)',
        ],
        borderColor: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        borderWidth: 5,
      },
    ],
  };

  const renderGraphColors = (item, index) => {
    return (
      <div className='w-fit text-xxs' key={index}>
        <span
          className={`w-3 h-3 mr-2 inline-block `}
          style={{
            backgroundColor: legendColors[index],
          }}
        />
        {item?.title}
      </div>
    );
  };

  return (
    <div className='flex gap-3'>
      <div className='bg-white p-2 w-1/4'>
        <img
          className='w-12 h-12'
          src={process.env.REACT_APP_DOC_URL + insurer?.image}
          alt={insurer?.name}
        />
        <p className='text-xs'>{insurer?.name}</p>
        <p className='text-xxs'>
          Email:
          <span className='text-grey-90 px-2'>{insurer?.email}</span>
        </p>
        <p className='text-xxs'>
          Phone:
          <span className='text-grey-90 px-2'>{insurer?.phone}</span>
        </p>
        <p className='text-xxs'>
          Website:
          <span className='text-grey-90 px-2'>{insurer?.website}</span>
        </p>
      </div>
      <div className={`h-44 w-3/4 p-2 flex gap-10 bg-white relative`}>
        <div className='pl-4 absolute top-4 right-3 z-10'>
          <IconButton
            title={showChart ? 'Bar' : 'Chart'}
            className='bg-primary-50 px-2 pt-1 pb-1.5 items-center rounded'
            onClick={() => setShowChart(!showChart)}
          >
            {showChart ? (
              <BarGraphIcon svgClass='w-4 h-4 text-primary-90' />
            ) : (
              <PieChartOutlined className='text-primary-90 w-4 h-4' />
            )}
          </IconButton>
        </div>
        {!showChart && (
          <div className='w-max'>
            <Doughnut
              data={GraphData}
              height={90}
              width={500}
              options={{
                cutout: '60%',
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>
        )}
        {showChart && (
          <div className='w-max'>
            <Bar
              data={GraphData}
              height={160}
              width={600}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
            />
          </div>
        )}
        <div
          className={clsx('w-max', {
            'grid grid-cols-3 gap-x-3 items-center': !showChart,
          })}
        >
          {CardData?.map(renderGraphColors)}
        </div>
      </div>
    </div>
  );
};

export default ReconciliationGraph;
