export const items = [
  {
    key: 'draft',
    label: `Draft `,
  },
  {
    key: 'quoted',
    label: `Quoted `,
  },
  {
    key: 'rejected',
    label: `Rejected `,
  },
  {
    key: 'converted',
    label: `Converted `,
  },
  {
    key: 'losses',
    label: `Losses `,
  },
];
