import { Tag, Tooltip } from 'antd';
import { MultiUserInitial } from 'components';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

export const breadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Client',
    path: '',
    target: '_blank',
  },
];

/**
*
* @param {
  [
    {
      id: number,
      title: string,
      sub_title: string
      fields: [
        {
          id: number,
          name: string,
          label: string,
          type: string,
          required: boolean,
          options: [{id: number, name: string}],
        }
      ]
    }
  ]
* } sections stuff we get from `res.data.data`(by calling `getCustomerFields`)
* @returns nice array of antd-table-compatible columns


*/

export const ShowPreference = (array, name) => {
  if (name === 'preference_product' || name === 'preference_category') {
    return array?.map((item) => {
      return {
        name: item?.[name]?.name ? item?.[name]?.name : '\u2014',
      };
    });
  } else {
    return array;
  }
};

export const formatColumnData = (sections, setViewContact) => {
  return sections.flatMap((section) =>
    section.fields.map((column) => ({
      id: column.id,
      key: column.name,
      dataIndex: column.name,
      title: column?.name === 'customer_name' ? 'Client Name' : column?.label,
      render: (data, record) =>
        column?.name === 'customer_name' || column?.name === 'corp_name' ? (
          <div className='flex gap-4 truncate'>
            <p
              className='cursor-pointer'
              onClick={() =>
                column?.name === 'customer_name'
                  ? setViewContact({ modal: true, data: record })
                  : ''
              }
            >
              <Tooltip title={data?.name || data} className='truncate'>
                {data?.name || data || '\u2014'}
              </Tooltip>
            </p>
          </div>
        ) : (
          <div className='truncate '>
            {' '}
            {Array.isArray(data) ? (
              <MultiUserInitial data={ShowPreference(data, column?.name)} showImage value='name' />
            ) : (
              <Tooltip title={data?.name || data}>{data?.name || data || '\u2014'}</Tooltip>
            )}
          </div>
        ),
    })),
  );
};

export const sortByKeys = (array, targetKeys) => {
  const targetObjects = [];
  const remainingObjects = [];

  array.forEach((obj) => {
    if (targetKeys.includes(obj.key)) {
      targetObjects.push(obj);
    } else {
      remainingObjects.push(obj);
    }
  });

  return [...targetObjects, ...remainingObjects];
};

export const excelClientFields = [
  {
    label: 'Local Status',
    value: 'local_status',
  },
  {
    label: 'Customer Type',
    value: 'customer_type',
  },
  {
    label: 'Corporation Name',
    value: 'corp_name',
  },
  {
    label: 'Contact Person name',
    value: 'cp_name',
  },
  {
    label: 'Contact Person Designation',
    value: 'cp_desg',
  },
  {
    label: 'Customer Name',
    value: 'customer_name',
  },
  {
    label: 'Contact Number',
    value: 'contact_number',
  },
  {
    label: 'Email Address',
    value: 'email_address',
  },
  {
    label: 'Gst Number',
    value: 'gst_registered',
  },
  {
    label: 'Fax Number',
    value: 'fax_number',
  },
  {
    label: 'Assign To',
    value: 'assign_to_employee',
  },
  {
    label: 'Branch',
    value: 'branch_of',
  },
  {
    label: 'D.O.B',
    value: 'date_of_birth',
  },
  {
    label: 'Lead Source',
    value: 'lead_source',
  },
  {
    label: 'Nature of Business',
    value: 'nature_of_business',
  },
  {
    label: 'Address',
    value: 'address',
  },
  {
    label: 'State',
    value: 'state',
  },
  {
    label: 'City',
    value: 'city',
  },
  {
    label: 'Postcode',
    value: 'postcode',
  },
  {
    label: 'Country',
    value: 'country',
  },
  {
    label: 'Registered address is same as Billing address',
    value: 'as_with_the_billing_address_the_registered_address_will_also_be_the_same',
  },
  {
    label: 'Registered Address',
    value: 'r_address',
  },
  {
    label: 'Registered State',
    value: 'r_state',
  },
  {
    label: 'Registered City',
    value: 'r_city',
  },
  {
    label: 'Registered Country',
    value: 'r_country',
  },
  {
    label: 'Registered PostCode',
    value: 'r_postcode',
  },
  {
    label: 'Bank Account Number',
    value: 'bank_account_number',
  },
  {
    label: 'Bank Account Holder Name',
    value: 'bank_account_holder_name',
  },
  {
    label: 'Bank Account Type',
    value: 'bank_account_type',
  },
  {
    label: 'Bank Ifsc Code',
    value: 'bank_ifsc_code',
  },
  {
    label: 'Credit Rating',
    value: 'credit_rating',
  },
  {
    label: 'Credit Amount',
    value: 'credit_amount',
  },
  {
    label: 'Credit Term',
    value: 'credit_term',
  },
  {
    label: 'Description',
    value: 'description',
  },
];
// export const excelClientFields = [
//   'local_status',
//   'customer_type',
//   'corp_name',
//   'cp_name',
//   'cp_desg',
//   'customer_name',
//   'contact_number',
//   'email_address',
//   'gst_registered',
//   'fax_number',
//   'assign_to_employee',
//   'branch_of',
//   'date_of_birth',
//   'lead_source',
//   'nature_of_business',
//   'address',
//   'state',
//   'city',
//   'postcode',
//   'country',
//   'as_with_the_billing_address_the_registered_address_will_also_be_the_same',
//   'r_address',
//   'r_state',
//   'r_city',
//   'r_postcode',
//   'r_country',
//   'bank_ifsc_code',
//   'bank_account_number',
//   'bank_account_holder_name',
//   'bank_account_type',
//   'credit_rating',
//   'credit_amount',
//   'credit_term',
//   'description',
// ];

// {
//   id: 105,
//   name: 'Advertisement',
//   label: 'Advertisement',
//   value: 105,
// },
// {
//   id: 106,
//   name: 'Self Service Portal',
//   label: 'Self Service Portal',
//   value: 106,
// },
// {
//   id: 107,
//   name: 'Cold Call',
//   label: 'Cold Call',
//   value: 107,
// },
// {
//   id: 108,
//   name: 'Existing Customer',
//   label: 'Existing Customer',
//   value: 108,
// },
// {
//   id: 109,
//   name: 'Self Generated',
//   label: 'Self Generated',
//   value: 109,
// },
// {
//   id: 110,
//   name: 'External Referral',
//   label: 'External Referral',
//   value: 110,
// },
// {
//   id: 111,
//   name: 'POS',
//   label: 'POS',
//   value: 111,
// },
// {
//   id: 112,
//   name: 'Sales',
//   label: 'Sales',
//   value: 112,
// },

export const LeadSourceSwitch = (lead_source) => {
  let result = '';

  switch (lead_source) {
    case 105:
      return (result = 'Advertisement');
    case 106:
      return (result = 'Self Service Portal');
    case 107:
      return (result = 'Cold Call');
    case 108:
      return (result = 'Existing Customer');
    case 109:
      return (result = 'Self Generated');
    case 110:
      return (result = 'External Referral');
    case 111:
      return (result = 'POS');
    case 112:
      return (result = 'Sales');
    default:
      return result;
  }
};

export const FOLLOWUP_COLUMNS = [
  {
    key: 'customer',
    title: 'Customer Name',
    dataIndex: 'customer',
    render: (item) => item?.customer_name,
  },
  {
    key: 'customer',
    title: 'Phone',
    dataIndex: 'customer',
    render: (item) => item?.contact_number || item?.contact || '\u2014',
  },
  {
    key: 'customer',
    title: 'Email',
    dataIndex: 'customer',
    render: (item) => (
      <p className='truncate'>{item?.email_address ? item?.email_address : '\u2014'}</p>
    ),
    // render: (item,record) => console.log("item",record),
  },
  {
    key: 'follow_up',
    title: 'Follow Up',
    dataIndex: 'follow_up',
  },
  {
    key: 'reason',
    title: 'Reason',
    dataIndex: 'reason',
    render: (item) => (
      <Tooltip title={item}>
        <div className='truncate'>{item}</div>
      </Tooltip>
    ),
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (item) =>
      item === 'not_interested' ? (
        <Tag icon={<CloseCircleOutlined />} color='error'>
          Not Interested
        </Tag>
      ) : item === 'not_pickup' ? (
        <Tag icon={<SyncOutlined spin />} color='processing'>
          Not Pickup
        </Tag>
      ) : (
        <Tag icon={<CloseCircleOutlined />} color='error'>
          error
        </Tag>
      ),
  },
];
