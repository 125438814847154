import React, { useCallback, useEffect, useState, useContext } from 'react';
import { query, collection, orderBy, onSnapshot, limit } from 'firebase/firestore';
import { db, rtdb } from 'utils/firebase';
import { getEmployees } from 'service/api-service';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { Button, Popover, Menu } from 'antd';
import { MoreOutlined, GroupOutlined } from '@ant-design/icons';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Conversation,
  ConversationList,
  Avatar,
  Search,
  ConversationHeader,
} from '@chatscope/chat-ui-kit-react';
import userIcon from 'assets/png/user.png';
import { addDoc, serverTimestamp } from 'firebase/firestore';
import * as fbRdb from 'firebase/database';
import { Context } from 'store/context';
import { getQueryParams } from 'utils/common';
import { Loader } from 'components';
import runWebSocket from 'service/socket';

const ChatBox = () => {
  const [messages, setMessages] = useState([]);
  const [selected, setSeleted] = useState();
  const [srch, setQuery] = useState();
  const [users, setUsers] = useState([]);
  const [socket, setSocket] = useState(null);
  const [empListLoading, setEmpListLoading] = useState(false);
  const { user } = useContext(Context);
  const { active } = getQueryParams();

  const onUserSelect = useCallback(
    (usr, skipUrlUpdate) => () => {
      setSeleted(usr);
      try {
        const ref = fbRdb.ref(rtdb, 'unread/' + user.id + '/' + usr.id);
        fbRdb.remove(ref);
      } catch (er) {}
      if (!skipUrlUpdate) window.history.replaceState(null, null, `/team-chat?active=${usr?.id}`);
    },
    [],
  );

  const loadEmployees = useCallback(async () => {
    const resp = await getEmployees({});
    const list = resp?.data?.data?.result || [];
    setUsers(list.filter((i) => i.id != user.id));
    if (active) {
      const select = list.find((i) => i.id == active);
      onUserSelect(select, true)();
    }
  }, [active, onUserSelect, user]);

  const chatFilter = useCallback(
    (msg) =>
      (msg.senderId == user.id || msg.senderId == selected?.id) &&
      (msg.receiverId == user.id || msg.receiverId == selected?.id),
    [selected, user],
  );

  const userFilter = useCallback(
    (user) => {
      return srch ? user.name.toLowerCase().includes(srch.toLowerCase()) : true;
    },
    [srch],
  );

  const onSend = useCallback(
    async (message) => {
      if (message.trim() === '') {
        alert('Enter valid message');
        return;
      }
      const { id, name, image } = user;
      await addDoc(collection(db, 'messages'), {
        message,
        sender: name,
        avatar: image,
        sentTime: serverTimestamp(),
        senderId: id,
        receiverId: selected.id,
      });

      const ref = fbRdb.ref(rtdb, 'unread/' + selected.id + '/' + id);
      fbRdb.push(ref, {
        message,
        sentTime: new Date().toISOString(),
        sender: name,
      });
    },
    [user, selected],
  );

  useEffect(() => {
    loadEmployees();
  }, []);

  const createGroup = useCallback(async (payload) => {
    // console.log(payload);
    // await addDoc(collection(db, "groups"), {
    //   name: payload.name,
    //   users: payload.users,
    //   created: serverTimestamp(),
    // });
  }, []);

  const onMenu = useCallback(({ key }) => {
    if (key == '1') {
    }
  }, []);

  const chatOptions = (
    <Menu
      onClick={onMenu}
      items={[
        {
          label: 'Create Group',
          key: '1',
          icon: <GroupOutlined />,
        },
      ]}
    />
  );

  const searchEmployee = useCallback(async (value) => {
    const query = value || '';
    setEmpListLoading(true);
    const resp = await getEmployees({ query: `q=${query}` });
    if (resp?.data?.data) {
      setUsers(resp?.data?.data?.result);
    }
    setEmpListLoading(false);
  }, []);

  // useEffect(() => {
  //   const q = query(collection(db, 'messages'), orderBy('sentTime', 'asc'), limit(50));

  //   const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
  //     const fetchedMessages = [];
  //     QuerySnapshot.forEach((doc) => {
  //       fetchedMessages.push({ ...doc.data(), id: doc.id });
  //     });
  //     const sortedMessages = fetchedMessages.sort((a, b) => a.createdAt - b.createdAt);
  //     setMessages(sortedMessages);
  //   });

  //   return () => unsubscribe;
  // }, [selected]);

  const handShake = async () => {
    try {
      const resp = await runWebSocket();
      setSocket(resp);
    } catch (error) {
      console.error('WebSocket connection failed:', error);
    }
  };

  useEffect(() => {
    handShake();
  }, []);

  useEffect(() => {
    if (socket) {
      const handleOpen = () => {
        console.log('Connected to WebSocket server');
      };

      const handleMessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received message:', data);
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      const handleClose = () => {
        console.log('Disconnected from WebSocket server');
      };

      socket.addEventListener('open', handleOpen);
      socket.addEventListener('message', handleMessage);
      socket.addEventListener('close', handleClose);

      return () => {
        socket.removeEventListener('open', handleOpen);
        socket.removeEventListener('message', handleMessage);
        socket.removeEventListener('close', handleClose);
        socket.close();
      };
    }
  }, [socket]);

  return (
    <MainContainer>
      <ConversationList>
        <div className='p-2 border-b border-grey-40 flex'>
          <Search placeholder='Search...' onChange={searchEmployee} onClearClick={searchEmployee} />
          <Popover placement='rightTop' title={'Options'} content={chatOptions} trigger='click'>
            <Button type='text' icon={<MoreOutlined />} />
          </Popover>
        </div>

        {empListLoading ? (
          <div className='h-64 items-center'>
            <Loader />
          </div>
        ) : (
          users.filter(userFilter).map((user) => (
            <Conversation
              onClick={onUserSelect(user)}
              key={user.id}
              name={user.name}
              active={selected?.id === user.id}
            >
              <Avatar
                src={user.image ? process.env.REACT_APP_DOC_URL + user.image : userIcon}
                name={user.name}
              />
            </Conversation>
          ))
        )}
        {/*  */}
      </ConversationList>
      <ChatContainer>
        <ConversationHeader>
          <Avatar
            src={selected?.image ? process.env.REACT_APP_DOC_URL + selected?.image : userIcon}
            name='User'
          />
          <ConversationHeader.Content userName={selected?.name} />
          <ConversationHeader.Actions>
            {/* <StarButton title='Add to favourites' />
            <VoiceCallButton title='Start voice call' />
            <VideoCallButton title='Start video call' />
            <InfoButton title='Show info' /> */}
          </ConversationHeader.Actions>
        </ConversationHeader>
        <MessageList>
          {selected
            ? messages?.filter(chatFilter).map((message) => (
                <Message
                  key={message.id}
                  model={{
                    ...message,
                    direction: message.senderId === user.id ? 'outgoing' : 'incoming',
                  }}
                >
                  <Avatar src={message.avatar || userIcon} name={message.sender} />
                </Message>
              ))
            : null}
        </MessageList>

        <MessageInput
          placeholder='Type message here'
          onSend={onSend}
          className='pr-4'
          disabled={!selected}
        />
      </ChatContainer>
    </MainContainer>
  );
};

export default ChatBox;
