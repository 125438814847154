/** external deps */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space } from 'antd';
import Fuse from 'fuse.js';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
  Toast,
} from 'components';
import { addRoleFields, addRoleInitials, breadcrumbObj } from './data';
import { DELETE, Permission, Trash } from 'assets/icons';
import { addRole, deleteRole } from 'service/api-service';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { Context } from 'store/context';
import { NOTIFICATION_MSG } from 'constants';
import { rowCommonClass } from 'utils/common';

const Roles = (props = {}) => {
  const permissions = props?.permissions || [];
  const navigate = useNavigate();

  // states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredRoles, setFilteredRoles] = useState([]);

  const {
    roles: { list: roles, updateRoles },
  } = useContext(Context);

  const columns = [
    {
      key: 'name',
      title: 'Role Name',
      dataIndex: 'name',
      width: '90%',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <div className='flex gap-2 justify-end'>
          <IconButton
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.roles.permissions.update}
            onClick={() => navigate(`/roles/${record.id}`)}
          >
            <Permission svgClass='stroke-grey' />
          </IconButton>

          <IconButton
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.roles.permissions.delete}
            onClick={() => {
              setSelectedRole(record);
              toggleDeleteRole();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
        </div>
      ),
    },
  ];

  const onAddRole = async (data) => {
    setLoading(true);
    try {
      const resp = await addRole({ data });
      if (resp?.data?.status) {
        await updateRoles();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
    } catch (error) {}
    toggleAddRole();
    setLoading(false);
  };

  const onDeleteRole = async () => {
    const id = selectedRole?.id;
    if (!id) return;
    setLoading(true);
    const resp = await deleteRole({ id });
    if (resp?.data?.status) {
      updateRoles();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteRole();
    setLoading(false);
  };

  const onEditRole = async (data) => {};

  const toggleAddRole = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  const toggleDeleteRole = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) {
      return setSearchQuery('');
    }
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(roles, {
      keys: ['name'],
      threshold: 0.2,
    }).search(e.target.value);
    setFilteredRoles(fuse.map((i) => i.item));
  };

  useEffect(() => {
    if (!roles.length) updateRoles();
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        buttonId={elementData.roles.permissions.create}
        title={<p className='font-semibold text-s'>Roles Listing</p>}
        // data={breadcrumbObj}
        btnText='+ Roles'
        onClick={toggleAddRole}
      />
      <TableComponent
        loading={loading}
        columns={columns}
        noDataAction={updateRoles}
        dataSource={searchQuery ? filteredRoles : roles}
        showPagination={false}
        getRowClassName={rowCommonClass}
      />

      {/* add role modal */}
      <CustomModal
        width={500}
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddRole}
        footer={''}
        title={editInitials ? 'Edit Role' : 'Add Role'}
      >
        <div>
          <span className='text-xxs'>
            {editInitials
              ? 'Edit role details'
              : 'To add a new role, enter the details of the role in the input field below.'}
          </span>
          <FormContainer
            onSubmit={editInitials ? onEditRole : onAddRole}
            initialValues={editInitials || addRoleInitials}
            fields={addRoleFields}
            formClasses='grid-cols-1   gap-8'
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddRole();
                    }}
                  />
                  <Button
                    text={editInitials ? 'Edit Role' : 'Add Role'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting || !isValid ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* delete role confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteRole}
        footer={
          <div className=' flex flex-row justify-center space-x-3  bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteRole}
            />
            <Button
              text='Delete'
              variant='primary'
              classes={`ml-6 px-9   
                 bg-black
                `}
              onClick={onDeleteRole}
              loading={loading}
            />
          </div>
        }
        width={450}
      >
        <div className='flex justify-center flex-col items-center'>
          <Trash className='h-10 w-10' />
          <span className='text-l 2xl:text-4xl'>Are you sure?</span>
          <span className='text-center text-grey-80'>
            Could you please confirm that you really wish to delete the role details?
          </span>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(Roles);
