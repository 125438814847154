import React, { useState } from 'react';
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
} from 'components';
import { Space } from 'antd';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { addPayRoleFields, breadcrumb } from './data';
import { payRole } from './dummy';
import { prepareFormFieldsData } from 'utils';
import Fuse from 'fuse.js';
import { rowCommonClass } from 'utils/common';
const PayRole = (props = {}) => {
  const permissions = props?.permissions || [];
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [loading, setLoading] = useState(false);
  const [editInitials, setInitials] = useState();
  const [tableData, setTableData] = useState(payRole);
  const [filteredPayRole, setfilteredPayRole] = useState([]);
  const [selectedPayRole, setSelectedPayRole] = useState();
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addPayRoleFields,
  });
  const columns = [
    {
      key: 'employee',
      title: 'Employee',
      dataIndex: 'employee',
      render: (_, record) => (
        <div className='flex items-center'>
          <img
            src='https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745'
            className='h-6 w-6 mr-2'
            alt='employee'
          />
          {record.employee}
        </div>
      ),
    },
    {
      key: 'package',
      title: 'Package(CTC) ',
      dataIndex: 'package',
    },
    {
      key: 'taxes',
      title: 'Taxes',
      dataIndex: 'taxes',
    },
    {
      key: 'pending_payment',
      title: 'Pending Payment',
      dataIndex: 'pending_payment',
    },
    {
      key: 'overtime_hours',
      title: 'Overtime Hours',
      dataIndex: 'overtime_hours',
    },
    {
      key: 'provident_fund',
      title: 'Provident Fund',
      dataIndex: 'provident_fund',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.pay_role.permissions.delete}
            onClick={() => {
              setSelectedPayRole(record);
              toggleDeletePayRole();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.pay_role.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];
  // func
  const onAddPayRole = async (data) => {};
  const onDeletePayRole = async () => {};
  const onEditPayRole = async (data) => {};

  const toggleAddPayRole = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };
  const toggleDeletePayRole = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(tableData, {
      keys: ['employee', 'package', 'taxes', 'pending_payment', 'overtime_hours', 'provident_fund'],
      threshold: 0.2,
    }).search(e.target.value);
    setfilteredPayRole(fuse.map((i) => i.item));
  };
  const onEditPress = (record) => {
    setInitials(record);
    setSelectedPayRole(record);
    setAddModal(true);
  };

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        data={breadcrumb}
        btnText='+ Pay Role'
        buttonId={elementData?.pay_role?.permissions?.create}
        onClick={toggleAddPayRole}
      />
      <TableComponent
        loading={loading}
        columns={columns}
        dataSource={searchQuery ? filteredPayRole : tableData}
        getRowClassName={rowCommonClass}
      />
      <CustomModal
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddPayRole}
        footer={''}
        width={750}
        title={editInitials ? 'Edit Pay Role' : 'Add Pay Role'}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 '>
            {editInitials
              ? 'Edit Pay Role details'
              : 'To add a new Pay Role, enter the details of the Pay Role in the input field below.'}
          </span>
          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditPayRole : onAddPayRole}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddPayRole();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.pay_role.permissions.update
                        : elementData.pay_role.permissions.create
                    }
                    text={editInitials ? 'Update Pay Role' : 'Add Pay Role'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
      {/* delete Pay Role confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeletePayRole}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the Pay Role?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeletePayRole}
            />
            <Button
              text='Delete Pay Role'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeletePayRole}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(PayRole);
