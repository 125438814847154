/** external deps */
import { Progress } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
/** internal deps */
import { FormContainer } from 'components';
import Button from 'components/button';
import { prepareFormFieldsData } from 'utils';
import { addReportDetailsFormFields } from './data';

const ReportDetails = ({ setForm, formData, setFormData }) => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const isEditing = state?.initialValues ? true : false;
  const [initialValues, finalFormFields] = prepareFormFieldsData({
    fields: addReportDetailsFormFields,
    fieldsToExclude: [],
    sectionsToExclude: [],
  });

  const handleAddReport = async (data) => {
    setForm('filter');
    setFormData(data);
  };

  const onEditSubmit = async (data) => {
    console.log('updated', data);
  };
  return (
    <div className='bg-white  flex flex-col p-4 rounded-lg'>
      <div className='relative mx-32 pb-4'>
        <div className='absolute text-xxs font-medium top-5 -left-9 text-primary-90'>
          Report Details
        </div>
        <div className='absolute bg-black w-4 h-4 rounded-full top-1 z-10'></div>
        <Progress strokeColor={'#26CBED'} showInfo={false} />
        <div className='absolute text-xxs font-medium top-5 right-1/2'>Filters</div>
        <div className='absolute text-xxs font-medium top-5 -right-9'>Select Chart</div>
      </div>
      <FormContainer
        accordionClass='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'
        fields={isEditing ? finalFormFields : addReportDetailsFormFields}
        initialValues={isEditing ? state?.initialValues : { ...initialValues, ...formData }}
        onSubmit={isEditing ? onEditSubmit : handleAddReport}
        customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
          return (
            <div className='bg-white flex flex-row justify-end py-4 mt-6'>
              <Button
                text='Cancel'
                variant='text'
                type='reset'
                classes='border border-gray-90 text-black px-6'
                onClick={() => {
                  resetForm();
                  navigate(-1);
                }}
              />
              <Button
                disabled={!isValid}
                //   id={
                //     state?.initialValues
                //       ? elementData.Report.permissions.update
                //       : elementData.Report.permissions.create
                //   }
                text={'Next'}
                classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                onClick={submitForm}
                loading={isSubmitting}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default ReportDetails;
