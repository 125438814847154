import React from 'react';
import { Tooltip } from 'antd';
/** internal deps */
import { SecureComponent } from 'permission-provider';

const IconButton = ({ children, title, color, ...props }) => {
  return (
    <Tooltip title={title} placement={'top'} color={color}>
      <button {...props}>{children}</button>
    </Tooltip>
  );
};

export default SecureComponent(IconButton);
