/** external deps */
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
/** internal deps */
import { SecureComponent } from 'permission-provider';
import { ContentHeader, FormContainer, IconButton, Toast, Button, Module } from 'components';
import { adjusterFields, addAdjusterBreadCrumbs } from './data';
import { getEditedFields, getPermission, prepareFormFieldsData } from 'utils';
import { BackArrow } from 'assets/icons';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
// import { postAdjuster, updateAdjuster } from 'service/Apis/adjuster';
import { postAdjuster, updateAdjuster } from 'service/api-service';

const AddAdjuster = (props) => {
  const permissions = props?.permissions || {};
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [initialValues, finalFields] = prepareFormFieldsData({ fields: adjusterFields });

  /* add adjuster */
  const onAddAdjuster = async (data) => {
    setLoading(true);
    const resp = await postAdjuster({
      data,
    });
    if (resp?.data?.status) {
      Toast(
        'success',
        NOTIFICATION_MSG.success,
        resp?.data?.msg || 'adjuster created successfully',
      );
      navigate(-1);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  /* update adjuster */
  const onUpdateAdjuster = async (updatedData) => {
    setLoading(true);
    const data = getEditedFields({ initialValues: state?.initialValues, updatedData });
    if (Object.keys(data).length > 0) {
      const resp = await updateAdjuster({
        data,
        adjuster_id: state?.initialValues?.id,
      });
      if (resp?.data?.status) {
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.data?.msg || 'adjuster updated successfully',
        );
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      navigate(-1);
      setLoading(false);
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  return (
    <div className='grid gap-3'>
      {getPermission(permissions, elementData.adjuster.permissions.create)?.write ? (
        <>
          {' '}
          <ContentHeader showSearch={false} data={addAdjusterBreadCrumbs} />
          <div className='bg-white p-4 rounded-2xl flex flex-col'>
            <div className='flex justify-between px-2'>
              <div className='flex flex-col'>
                <span className='text-l 2xl:text-xxl font-medium'>Add Adjuster</span>
                <span className='text-xxs text-grey-80 '>
                  To add a adjuster, enter the details of the adjuster in the input field below.
                </span>
              </div>
              <IconButton onClick={() => navigate(-1)}>
                <BackArrow svgClass='fill-grey-90 w-10 h-10' />
              </IconButton>
            </div>

            <FormContainer
              accordionClass='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'
              fields={finalFields}
              initialValues={state?.initialValues ? state.initialValues : initialValues}
              onSubmit={state?.initialValues ? onUpdateAdjuster : onAddAdjuster}
              customSubmitRender={({ isSubmitting, submitForm, resetForm }) => {
                return (
                  <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                    <Button
                      text='Cancel'
                      variant='text'
                      type='reset'
                      classes='border border-gray-90 text-black px-6'
                      onClick={() => {
                        resetForm();
                        navigate(-1);
                      }}
                    />
                    <Button
                      id={
                        state?.initialValues
                          ? elementData.adjuster.permissions.update
                          : elementData.adjuster.permissions.create
                      }
                      permissions={permissions}
                      text={state?.initialValues ? 'Update Adjuster' : 'Add Adjuster'}
                      variant='primary'
                      classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                      onClick={submitForm}
                      loading={loading}
                    />
                  </div>
                );
              }}
            />
          </div>
        </>
      ) : (
        <Module />
      )}
    </div>
  );
};

export default SecureComponent(AddAdjuster);
