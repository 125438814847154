import { IconButton, MultiEmail, TableComponent } from 'components';
import { elementData } from 'elements';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { EDIT, View } from 'assets/icons';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { otherSourcepolicy } from 'service/api-service';
import { useStore } from 'store/context';
import { rowCommonClass } from 'utils/common';

const OtherPolices = ({
  permissions,
  columns,
  onEditPolicy,
  activeTabKey,
  query = '',
  dateFilterValues,
}) => {
  const navigate = useNavigate();
  const [otherSourceData, setOtherSourceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opened, dispatch] = useStore((state) => state.dialerOpened);

  const toggleDialer = useCallback(
    (e, contact) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (opened) {
        dispatch({ type: 'DIALER_CLOSE' });
      } else {
        dispatch({ type: 'DIALER_OPEN', contact });
      }
    },
    [opened, dispatch],
  );

  const fetchData = async ({ q = '', page = 1 } = {}) => {
    setLoading(true);
    const resp = await otherSourcepolicy({
      method: 'get',
      page: page,
      q: q,
      customDate: dateFilterValues,
    });
    if (resp?.data) {
      setOtherSourceData(resp?.data?.data);
    }
    setLoading(false);
  };

  const handlePagination = (pageNum) => {
    if (pageNum?.current) {
      fetchData({ page: pageNum?.current });
    }
  };

  useEffect(() => {
    fetchData({ q: query });
  }, [query, dateFilterValues]);

  return (
    <div className='mt-4'>
      <TableComponent
        loading={loading}
        onChange={handlePagination}
        scroll={{
          x: columns?.length > 8 ? 3000 : 500,
        }}
        getRowClassName={rowCommonClass}
        columns={[
          ...columns?.filter((i) => (i.displayFilter ? i.displayFilter.includes('other') : true)),
          {
            title: 'Action',
            key: 'action',
            // align: 'right',
            render: (_, record) => (
              <div className='flex gap-2'>
                <IconButton
                  title='Send Reminder'
                  permissions={permissions}
                  className='flex justify-center items-start w-6 h-6 rounded-full border group disabled:cursor-not-allowed border-red-60'
                  id={elementData.policy.permissions.view}
                >
                  <MultiEmail
                    text={<MailOutlined className='text-red-60 group-disabled:text-grey' />}
                    button={false}
                    titleClasses={'underline cursor-pointer'}
                    // imageRef="invoice-slip"
                    emailData={record?.customer?.email_address}
                    mailSubject='Renewal Reminder'
                    title={'reminder'}
                    summary={`Hello ${record?.customer?.name},\nHope you are doing great. We are just reminding you about your ${record?.product_type?.name} Insurance renewal. The renewal date is ${record?.renewal}. If you have any questions, please feel free to reach out to us.`}
                  />
                </IconButton>
                <IconButton
                  title='Call'
                  permissions={permissions}
                  className='flex justify-center items-center w-6 h-6 rounded-full border group disabled:cursor-not-allowed border-primary-90'
                  id={elementData.policy.permissions.view}
                  onClick={(e) => toggleDialer(e, record?.customer?.contact_number)}
                >
                  <PhoneOutlined className='text-primary-90 group-disabled:text-grey' />
                </IconButton>
                <IconButton
                  title='View'
                  className='group disabled:cursor-not-allowed'
                  id={elementData.policy.permissions.view}
                  onClick={() => {
                    navigate(`/view-policy/${record.id}`, {
                      state: { initialValues: record, activekey: activeTabKey },
                    });
                  }}
                >
                  <View svgClass='stroke-primary group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Edit'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.policy.permissions.update}
                  onClick={() => {
                    onEditPolicy(record);
                  }}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
              </div>
            ),
          },
        ]}
        dataSource={otherSourceData?.result}
        noDataAction={fetchData}
        pageSize={10}
        total={otherSourceData?.total_page}
        defaultPage={otherSourceData?.current_page}
      />
    </div>
  );
};

export default OtherPolices;
