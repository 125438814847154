/** external deps */
import React, { useContext } from 'react';
/** internal deps */
import Assistant from 'assets/png/assistant.png';
import { Context } from 'store/context';

const PosInactive = () => {
  const { user } = useContext(Context);
  const userName = user?.name?.charAt(0)?.toUpperCase() + user?.name?.slice(1);
  return (
    <div className='flex items-center justify-center h-screen'>
      <div className={`w-[44rem] rounded-[14px] bg-primary-light/40 shadow-custom`}>
        <div className='flex relative min-h-40 p-4'>
          <div className='pt-4 pb-0 w-9/12'>
            <h2 className='font-medium text-xs'>Hello {userName || 'User'},</h2>
            <p className='text-xxs'>
              Your contract is not active yet.We'll send you a mail when your account will be
              activated
            </p>
          </div>
          <img
            src={Assistant}
            alt='assistant'
            className='absolute right-0 bottom-0 overflow-y-visible max-h-[110%]'
          />
        </div>
      </div>
    </div>
  );
};

export default PosInactive;
