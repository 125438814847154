const motorCoverage = [
  'Third-Party Liability Only Cover',
  'Collision Damage',
  'Own Damage',
  'Personal Accident Cover',
  'Zero Depreciation Insurance',
  'Comprehensive Car Insurance',
];

const healthCoverage = [
  'Critical Illness',
  'Individual Health Insurance',
  'Family Floater Health Insurance',
  'Senior Citizen Health Insurance',
  'Group Health Insurance',
  'Personal Accident Cover',
  'Maternity Health Insurance',
  'Mediclaim Insurance',
  'Disease-specific (Corona Kavach, etc.)',
  'Hospital Daily Cash',
];

const lV = (v) => ({ label: v, value: v });

export const coverageOptions = [...motorCoverage.map(lV), ...healthCoverage.map(lV)];

export const priceFormatter = (value) => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const percentFormatter = (value) => `${value}%`;

export const arrayToObject = (list) => {
  const res = {};
  list.forEach((i) => {
    res[i.id] = i;
  });

  return res;
};

export const createHtml = (data, { company, customer, companyEmail, sender, inq }) => {
  return `<html>
  <style>
    table {
      width: 100%;
      border: 1px solid #eee;
    }
    thead {
      background: #ddd;
      font-weight: bold;
    }
    td {
      border: 1px solid #eee;
      padding: 5px;
    }
    th {
      border: 1px solid #eee;
      text-align: left;
      padding: 5px;
    }
  </style>
  <body>
    <p contenteditable="true">Hello, ${customer}</p>
    <br>
    <p contenteditable="true">Thank you for your interest in ${company}. In regards to your query, we are delighted to make you the following offers. You can
    directly buy any policy by simply click on BUT NOW link or you can contact with us on ${companyEmail}</p>
    <br>
    <h2>Quotation</h2>
    <br>
    <table>
      <thead>
        <tr>
          <th>Company</th>
          <th>Product</th>
          <th>Premium</th>
          <th>Coverage</th>
          <th>Coverage Amount</th>
          <th>Sum Insured</th>
          <th>NCB</th>
          <th>Document</th>
          <th>Link</th>
        </tr>
      </thead>
    <tbody>
      ${data
        .map(
          (d) =>
            `<tr>
          <td>${d.insurer}</td>
          <td>${d.product?.name}</td>
          <td>INR. ${Number(d.premium || 0).toFixed(2)}</td>
          <td>${d.cover?.join('<br>')}</td>
          <td>${Object.keys(d.coverAmount || {})
            .map((key) => 'INR. ' + Number(d.coverAmount[key] || 0).toFixed(2))
            .join('<br>')}</td>
          <td>INR. ${Number(d.sum || 0).toFixed(2)}</td>
          <td>${d.ncb || 0}%</td>
          <td><a href=${d?.document?.doc || ''} target="_blank">${d?.document?.name || ''}<a/></td>
          <td><a href="${window.location.origin}/buy-policy?inq=${inq}&quote=${d.id}&company=${
            d.insurer
          }&premium=${Number(d.premium || 0).toFixed(2)}">BUY</a></td>
        </tr>`,
        )
        .join(' ')}
    </tbody>
    </table>
    <br>
    <p>Please let us know if you require any further information. Please note that the Early Bird Discount is a limited time offer and will only be available until</p>
    <br>
    <p>We look forward to hearing from you.</p>
    <br>
    <p>Kind regards</p>
    <p>${sender}</p>
    <p>${company}</p>
  </body>
</html>`;
};
