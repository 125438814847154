import { useEffect, useCallback, useState } from 'react';
import { Select } from 'antd';
import clsx from 'clsx';
import './style.css';
import { getInsuranceCategory } from 'service/api-service';
const SELECT_ALL_OPTION = {
  value: '',
  label: 'All',
};

const parse = (data) => {
  return {
    label: data.name,
    options: data.product_type.map((i) => ({
      label: i.name,
      value: i.id,
      formId: i.formid,
      categoryId: data.id,
      category: data.name,
    })),
  };
};

const filterOption = (input, option) =>
  (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase());

const getObject = (list, id) => {
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].options.length; j++) {
      if (list[i].options[j].value == id) {
        return list[i].options[j];
      }
    }
  }
  return null;
};

const ProductTypePicker = ({
  onChange,
  selected,
  classes,
  field,
  form,
  handleDeselect,
  divClasses,
  disabled,
  isValid = true,
  isMulti = false,
  showSelectAll = false,
  ...props
}) => {
  const [list, setList] = useState([]);
  const [value, setValue] = useState(selected);

  const handleChange = useCallback(
    (selected, item) => {
      setValue(selected);
      if (form?.setFieldValue) {
        return form.setFieldValue(field?.name, item);
      }
      onChange(item);
    },
    [onChange, form, field],
  );

  const load = useCallback(async () => {
    const resp = await getInsuranceCategory({});
    if (resp?.data?.status) {
      const l = resp?.data?.data?.map(parse);
      setList(l);
      if (selected) {
        onChange(getObject(l, selected));
      }
    }
  }, [selected, onChange]);

  useEffect(() => {
    if (form?.initialValues?.[field?.name]) {
      setValue(form?.initialValues?.[field?.name]?.id);
      return form.setFieldValue(field?.name, getObject(list, selected));
    }
  }, []);

  useEffect(() => {
    load();
  }, []);

  // const arrayData = selected && selected.map((ele) =>ele.name)

  return (
    <div className={`group ${divClasses}`}>
      {props.label && (
        <div className=' pr-2 flex justify-between items-center'>
          <label
            className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xs text-black ${
              props?.required ? 'required' : ''
            }`}
          >
            {props.label}
          </label>
        </div>
      )}
      <Select
        // options={list}
        onDeselect={handleDeselect}
        mode={isMulti ? 'multiple' : ''}
        options={isValid ? (showSelectAll ? [SELECT_ALL_OPTION, ...(list || [])] : list) : []}
        placeholder='Select Product Type'
        className={clsx(
          'border border-grey-90 rounded-md  block w-full py-1 px-2 focus:outline-none focus-within:border-primary-90 placeholder-grey-90 placeholder:text-grey-90',
          {
            '!bg-grey-light border-grey-light cursor-not-allowed': disabled,
          },
          classes,
        )}
        showSearch
        filterOption={filterOption}
        onChange={handleChange}
        value={selected}
      />
    </div>
  );
};

export default ProductTypePicker;
