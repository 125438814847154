import Checkbox from 'components/form-components/Checkbox';
import DateTime from 'components/form-components/DateTime';
import TextInput from 'components/form-components/TextInput';

export const addStatusFields = {
  Target: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Reminder Date',
        name: 'remind_date',
        id: 'remind_date',
        type: 'date',
        component: DateTime,
      },
      {
        label: 'Sold by another broker',
        name: 'sta',
        id: 'sta',
        type: 'checkbox',
        placeholder: 'Sold by another broker',
        component: Checkbox,
        divClasses: 'self-end',
      },

      {
        label: 'Other reason',
        name: 'other_reason',
        id: 'other_reason',
        type: 'checkbox',
        placeholder: 'Other reason',
        component: Checkbox,
        divClasses: 'self-end',
        updateClass: '!mt-0',
      },
      {
        label: '',
        name: 'remarks',
        id: 'remarks',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        placeholder: 'Please add some remarks here...',
        InputClasses: 'h-32',
      },
    ],
  },
};

export const STATUS_OPTIONS = [
  {
    label: 'Mark as draft',
    value: 'draft',
  },
  {
    label: 'Mark as Converted',
    value: 'converted',
  },
];
