/** external deps */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Button as AntButton, Drawer, Modal, Select, Spin, Tag } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { SyncOutlined, CheckCircleOutlined } from '@ant-design/icons';
/** internal deps */
import { CustomModal, FormContainer, Toast, Button, UpcomingActivity } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { formatInquiryViewData } from 'screens/customers/inquiry/data';
import { Searchable } from 'utils/common';
import { prepareFormFieldsData } from 'utils';
import { addStatusFields, STATUS_OPTIONS } from './data';
import ViewData from './ViewData';
import PreviousRemarks from './PreviousRemarks';
import { Context } from 'store/context';
import { ActivityIcon, DropdownIcon } from 'assets/icons';
import {
  getPosInquiryDetails,
  getInquiryDetails,
  inquiryManagement,
  getLeadTimeline,
} from 'service/api-service';

const ViewInquiry = () => {
  /** states */
  const [timeLine, setTimeLine] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const { user } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [employee, setEmployee] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [initVals, fieldForms] = prepareFormFieldsData({
    fields: addStatusFields,
  });

  const onGettimeline = async () => {
    setLoading(true);
    const model = state === 'pos' ? 'UserInquiry' : 'inquiry';
    const resp = await getLeadTimeline({
      model,
      model_id: id,
    });
    if (resp?.data?.status) {
      setTimeLine(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }

    setLoading(false);
  };

  const handleAddRemark = async (formData) => {
    let array = data?.remarks || [];
    const remarks = { remarks: formData?.remarks, user_id: user?.name };
    array.push(remarks);
    const payload = {
      ...formData,
      status: 'draft',
      remarks: array,
    };
    const resp = await inquiryManagement({
      method: 'patch',
      id: id,
      data: payload,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, `Status updated successfully`);
      setShowModal(false);
      window.location.reload();
    } else {
      Toast('error', NOTIFICATION_MSG.error, 'Please try again');
    }
  };

  const statusChange = useCallback((status) => {
    const onOk = async () => {
      const resp = await inquiryManagement({
        method: 'patch',
        id: id,
        data: { status: status },
      });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, `Status updated successfully`);
        getInquiry();
      } else {
        Toast('error', NOTIFICATION_MSG.error, 'Please try again');
      }
    };
    Modal.confirm({
      title: 'Are you sure?',
      okText: 'Yes',
      onOk: onOk,
    });
  }, []);

  const makeItDraft = () => {
    setShowModal(true);
  };
  const changeLeadStatus = useCallback((status) => {
    if (status === 'draft') {
      makeItDraft();
    } else statusChange(status);
  }, []);

  const getInquiry = async () => {
    setLoading(true);
    if (state === 'pos') {
      const resp = await getPosInquiryDetails({ id });
      const data = resp?.data?.data;
      setData(data);
      setEmployee({
        id: data?.assigned_to?.id,
        name: data?.assigned_to?.name,
        label: data?.assigned_to?.name,
        value: data?.assigned_to?.id,
      });
    } else {
      const resp = await getInquiryDetails({ id });
      const data = resp?.data?.data;
      setData(data);
      setEmployee({
        id: data?.assigned_to?.id,
        name: data?.assigned_to?.name,
        label: data?.assigned_to?.name,
        value: data?.assigned_to?.id,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getInquiry();
    onGettimeline();
  }, []);

  const AssignToEmployee = async (data) => {
    const finalPayload = {
      assigned_to: data?.id,
    };
    const resp = await inquiryManagement({ method: 'patch', data: finalPayload, id: id });
    if (resp?.data?.status) {
      getInquiry();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    // setLoading(false);
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  if (loading)
    return (
      <div className='flex items-center justify-center h-64'>
        <Spin />
      </div>
    );

  return (
    <div className='bg-white rounded-lg'>
      <ViewData
        id={data?.id}
        classes={`relative bg-white rounded-2xl p-4 space-y-4 `}
        pageTitle={
          <div className='flex items-center'>
            Lead Detail - {data?.id}
            &nbsp; &nbsp; &nbsp;
            {data?.status === 'draft' && <Tag color='default'>Draft</Tag>}
            {data?.status === 'quoted' && (
              <Tag icon={<SyncOutlined spin />} color='processing'>
                Processing
              </Tag>
            )}
            {data?.status === 'converted' && (
              <Tag icon={<CheckCircleOutlined />} color='success'>
                Converted
              </Tag>
            )}
          </div>
        }
        data={formatInquiryViewData(data)}
        showGoToBack={true}
        goBack={() => navigate('/lead')}
        customButton={
          <div className='flex items-center'>
            <div className='border-r border-grey-40 pr-2'>
              <Link to={`/lead/edit/${id}/2`}>
                <AntButton type='primary' className='mr-4'>
                  {data?.status === 'quoted' ? 'Edit Quote' : 'Create Quote'}
                </AntButton>
              </Link>
              {data?.status === 'quoted' && (
                <Select
                  className='group border border-grey-90 hover:border-primary-90 rounded-lg p-1 pl-2 h-10'
                  suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
                  placeholder='Change Status'
                  options={STATUS_OPTIONS}
                  onChange={changeLeadStatus}
                  dropdownAlign={{ offset: [-10, 2] }}
                />
              )}
            </div>
            <div className='flex items-center gap-4 ml-4'>
              <h2>Assign To:</h2>
              <Searchable
                type='search'
                placeholder='Type here to search'
                field={{ name: 'employee' }}
                defaultValue={employee}
                onChange={AssignToEmployee}
                divClasses='w-44'
              />
            </div>
            <>
              <AntButton onClick={showDrawer} className='border-none flex items-center p-0'>
                <img src={ActivityIcon} alt='green tick' className='h-10 w-10 rounded-md' />
              </AntButton>
              <Drawer title='Lead Timeline' placement='right' onClose={onClose} open={open}>
                <UpcomingActivity
                  filterActivities={timeLine}
                  noDataAction={onGettimeline}
                  loading={loading}
                  timeLine={timeLine}
                  heading={'Recent Activities'}
                  user={user}
                  activityClass='!h-full'
                />
              </Drawer>
            </>
          </div>
        }
        bottomSection={
          data?.remarks ? (
            <>
              <div className='cursor-pointer flex items-center justify-between'>
                <h4 className='font-semibold text-primary-90 text-sm'>Remarks</h4>
              </div>
              <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'>
                <div className='mb-8'>
                  <div className='font-medium'>Remarks</div>
                  <div className='text-xxs'>{data?.remarks[0]?.remarks}</div>
                </div>
                <div>
                  <div className='font-medium'>Reminder Date</div>
                  <div className='text-xxs'>{data?.remind_date}</div>
                </div>
              </div>
            </>
          ) : null
        }
      />

      <CustomModal
        open={showModal}
        destroyOnClose={true}
        closable={true}
        onCancel={setShowModal.bind(this, false)}
        footer={''}
        width='600px'
        title='Add Remarks'
      >
        <FormContainer
          onSubmit={handleAddRemark}
          initialValues={initVals}
          fields={fieldForms}
          accordionClass='grid grid-cols-full gap-4'
          customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
            return (
              <>
                <PreviousRemarks data={data} />
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={setShowModal.bind(this, false)}
                  />
                  <Button
                    text={'Save'}
                    variant='primary'
                    classes={`ml-2 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              </>
            );
          }}
        />
      </CustomModal>
    </div>
  );
};

export default ViewInquiry;
