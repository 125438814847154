/** external deps */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import Fuse from 'fuse.js';
import { Space, Tabs } from 'antd';
import { DiffOutlined } from '@ant-design/icons';
import { CLOCK } from 'assets/icons';

/** internal deps */
import {
  TableComponent,
  IconButton,
  Filter,
  CustomModal,
  Toast,
  SearchComponent,
  Button,
  MultiEmail,
  Breadcrumbs,
} from 'components';
import { breadCrumbsObj, items } from './data';
import AddInquiry from './add-leads';
import { DELETE, EDIT, Trash, View } from 'assets/icons';
import { inquiryManagement, searchApis, getCurrentUserLeads } from 'service/api-service';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { Context, useStore } from 'store/context';
import { NOTIFICATION_MSG } from 'constants';
import { useNavigate } from 'react-router-dom';
import { DefaultUser } from 'assets';
import { Searchable, getQueryParams, rowCommonClass } from 'utils/common';
import dayjs from 'dayjs';

const MIN_FIELDS = 6;
const MAX_FIELDS = 999;
const PAGE_SIZE = 10;

const Leads = ({ permissions = [], employee_id, customer_id, pos_id }) => {
  const [searchQuery, setSearchQuery] = useState();
  const [tabKey, setTabKey] = useState(1);
  const [filteredInquiries, setFilteredInquiries] = useState([]);
  const [addInquiry, setAddInquiry] = useState(false);
  const [drafted, setDrafted] = useState([]);
  const [quoted, setQuoted] = useState([]);
  const [remarks, setRemarks] = useState([]);
  const [converted, setConverted] = useState([]);
  const [loses, setLoses] = useState([]);
  // const [list, setList] = useState([]);
  const [curAddEntry, setCurAddEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedInquiryRow, setSelectedInquiryRow] = useState();
  const [inquiry, setInquiry] = useState([]);
  const [type, setType] = useState([]);
  const [inquiryMethod, setInquiryMethod] = useState('pos');
  const [selectedValue, setSelectedValue] = useState([]);
  // const [employeedata, setEmployeeeData] = useState([]);
  // const [categoryData, setCategoryData] = useState([]);
  // const [productData, setProductData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const { active } = getQueryParams();

  const {
    selectedData: { handleSelectedData },
  } = useContext(Context);

  const AssignToEmployee = async (data, record) => {
    const finalPayload = {
      assign_to_employee: data?.id,
    };
    const resp = await inquiryManagement({ method: 'patch', data: finalPayload, id: record?.id });
    if (resp?.data?.status) {
      onGetInquiry();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg ?? 'Assigned successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    // setLoading(false);
  };

  const navigate = useNavigate();
  const InquiryColumns = [
    {
      title: 'Assigned To',
      dataIndex: 'assign_to_employee',
      key: 'assign_to_employee',
      id: 'assign_to_employee',
      render: (data, record) => (
        <div className='flex items-center'>
          <img
            src={data?.image || DefaultUser}
            className='mr-2 w-6 h-6'
            alt='name'
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = DefaultUser;
            }}
          />

          <Searchable
            type='search'
            placeholder='Type here to search'
            field={{ name: 'employee' }}
            defaultValue={data}
            onChange={(data) => {
              AssignToEmployee(data, record);
            }}
            divClasses='w-24 bg-white'
          />
        </div>
      ),
    },
    {
      title: 'Product',
      dataIndex: 'product_type',
      key: 'product_type',
      id: 'product_type',
      render: (data) => data?.name || '\u2014',
    },

    {
      title: 'Client Name',
      dataIndex: 'customer_id',
      key: 'customer_id',
      id: 'customer_id',
      render: (text, data) => {
        if (data.source === 'online') {
          return data?.name || '\u2014';
        }
        return text || '\u2014';
      },
    },
    {
      title: 'Contact',
      dataIndex: 'contact_number',
      key: 'contact_number',
      id: 'contact_number',
      render: (text, data) => {
        if (data.source === 'online') {
          return (
            (
              <div
                className='underline cursor-pointer'
                onClick={(e) => toggleDialer(e, data?.contact)}
              >
                {data?.contact}
              </div>
            ) || '\u2014'
          );
        }
        return (
          (
            <div className='underline cursor-pointer' onClick={(e) => toggleDialer(e, text)}>
              {text}
            </div>
          ) || '\u2014'
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'primary_email_address',
      key: 'primary_email_address',
      id: 'primary_email_address',
      render: (text, data) => {
        if (data.source === 'online') {
          return (
            (
              <MultiEmail
                text={data?.email}
                button={false}
                titleClasses={'underline cursor-pointer'}
                imageRef='invoice-slip'
                emailData={data?.email}
              />
            ) || '\u2014'
          );
        }
        return (
          (
            <MultiEmail
              text={text}
              button={false}
              titleClasses={'underline cursor-pointer'}
              imageRef='invoice-slip'
              emailData={text}
            />
          ) || '\u2014'
        );
      },
    },
    {
      title: 'Period of Insurance: From',
      dataIndex: 'insurance_period_from',
      key: 'insurance_period_from',
      id: 'insurance_period_from',
      render: (text) => text || '\u2014',
    },
    {
      title: 'Period of Insurance: To',
      dataIndex: 'insurance_period_to',
      key: 'insurance_period_to',
      id: 'insurance_period_to',
      render: (text) => text || '\u2014',
    },
    {
      title: 'Expected Close Date',
      dataIndex: 'close_date',
      key: 'close_date',
      id: 'close_date',
      render: (text) => text || '\u2014',
    },
    {
      title: 'Lead Source',
      dataIndex: 'lead_source',
      key: 'lead_source',
      id: 'lead_source',
      render: (text) => text || '\u2014',
    },
    {
      title: 'Sales Stage',
      dataIndex: 'sales_stages',
      key: 'sales_stages',
      id: 'sales_stages',
      render: (text) => text || '\u2014',
    },
    {
      title: 'Nature of Business',
      dataIndex: 'business_nature',
      key: 'business_nature',
      id: 'business_nature',
      render: (text) => text || '\u2014',
    },
    {
      title: 'Submission Date',
      dataIndex: 'submission_date',
      key: 'submission_date',
      id: 'submission_date',
      render: (text) => text || '\u2014',
    },
    {
      title: 'Request From',
      dataIndex: 'request_from',
      key: 'request_from',
      id: 'request_from',
      render: (text) => text || '\u2014',
    },
    {
      title: 'Presently Insured',
      dataIndex: 'presently_insured',
      key: 'presently_insured',
      id: 'presently_insured',
      render: (text) => text || '\u2014',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      id: 'description',
      render: (text) => text || '\u2014',
    },
  ];

  /** funcs */
  const [curColumns, setCurColumns] = useState(InquiryColumns.slice(0, 5));
  const [opened, dispatch] = useStore((state) => state.dialerOpened);

  const toggleDialer = useCallback(
    (e, contact) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (opened) {
        dispatch({ type: 'DIALER_CLOSE' });
      } else {
        dispatch({ type: 'DIALER_OPEN', contact });
      }
    },
    [opened, dispatch],
  );

  const onGetInquiry = async () => {
    setLoading(true);

    const resp = await getCurrentUserLeads({ pos_id: pos_id });
    if (resp?.data?.status) {
      // setList(resp.data.data);
      let data = [];
      if (inquiryMethod == 'pos') {
        data = resp?.data?.data?.map((i) => {
          return {
            ...i,
            customer_id: i?.name,
            contact_number: i?.contact,
          };
        });
      } else {
        data = resp?.data?.data;
      }

      setQuoted(data.filter((item) => item.status === 'quoted'));
      setDrafted(data.filter((item) => item.status === 'draft' && !item.remarks));
      setConverted(data.filter((item) => item.status === 'converted'));
      setRemarks(data.filter((item) => item.status === 'draft' && item.remarks));
      setLoses(data.filter((item) => item.status === 'draft' && item.remarks && item.sold_another));

      // setInquiry(data);
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Failed to get Inquiry data from server',
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    if (tabKey == 1) {
      setInquiry(drafted);
    } else if (tabKey == 2) {
      setInquiry(quoted);
    } else if (tabKey == 3) {
      setInquiry(remarks);
    } else if (tabKey == 4) {
      setInquiry(converted);
    } else if (tabKey == 5) {
      setInquiry(loses);
      // fetchLosesData();
    } else {
      setInquiry(drafted);
    }
  });

  const getEmployeeData = async () => {
    const resp = await searchApis({ api: 'employee' });
    const data = resp?.data?.data?.map((contact) => ({
      value: contact.id,
      label: contact.name,
      name: contact.name,
      id: contact.id,
    }));
    // setEmployeeeData(data);
    const response = await searchApis({ api: 'insurance_category' });
    setAllCategories(response?.data?.data);
    const dataCategory = response?.data?.data.map((category) => ({
      value: category.id,
      label: category.name,
      name: category.name,
      id: category.id,
    }));
    // setCategoryData(dataCategory);
  };
  useEffect(() => {
    getEmployeeData();
  }, []);

  // useEffect(() => {
  //   // const data = allCategories.filter((option) => selectedValue.insurance_category === option.id);
  //   // const products = data[0]?.product_type?.map((product) => ({
  //   //   value: product.id,
  //   //   label: product.name,
  //   //   name: product.name,
  //   //   id: product.id,
  //   // }));
  //   // setProductData(products);
  // }, [selectedValue.insurance_category]);

  useEffect(() => {
    if (active === 'manual') {
      setInquiryMethod(1);
    } else if (active === 'pos') {
      setInquiryMethod(2);
    } else {
      setInquiryMethod(3);
    }
    onGetInquiry();
  }, [selectedValue, inquiryMethod]);

  const handleSearch = (e) => {
    const searchText = e.target.value.trim();
    if (!searchText) return setSearchQuery('');
    setSearchQuery(searchText);
    const fuse = new Fuse(inquiry, {
      keys: ['assign_to_employee', 'customer_id', 'primary_email_address'],
      threshold: 0.2,
    }).search(searchText);
    setFilteredInquiries(fuse.map((i) => i.item));
  };

  const handleAddClick = () => {
    setCurAddEntry({ customer_id, customer_name: customer_id?.name });
    setAddInquiry(true);
    setType('add');
    navigate('/lead/create/new/1');
  };

  const handleGoBack = () => {
    setAddInquiry(false);
    setCurAddEntry(null);
  };

  // get data
  const toggleDeleteInquiry = () => setDeleteModal((pre) => !pre);

  /* Delete inquiry */
  const onDeleteInquiry = async () => {
    setLoading(true);
    const id = selectedInquiryRow?.id;
    if (!id) return;
    const resp = await inquiryManagement({ method: 'delete', id });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg ?? 'Deleted successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }

    toggleDeleteInquiry();
    onGetInquiry();
    setLoading(false);
  };

  useEffect(() => {
    onGetInquiry();
  }, []);

  if (addInquiry)
    return (
      <AddInquiry
        permissions={permissions}
        id={elementData.Leads.permissions.create}
        goBack={handleGoBack}
        initialValues={curAddEntry}
        getInquiry={onGetInquiry}
        customerName={customer_id}
        rowId={selectedInquiryRow?.id}
        type={type}
      />
    );
  const onchangeTab = (key) => {
    setTabKey(key);
  };

  const getRowClassName = (record) => {
    const dateToCheck = dayjs(record?.created);
    const currentDate = dayjs();
    let colorClass = '';
    if (currentDate.diff(dateToCheck, 'hour') < 1) {
      colorClass = 'bg-[#d3fbd3]';
    } else if (
      currentDate.diff(dateToCheck, 'hour') > 1 &&
      currentDate.diff(dateToCheck, 'hour') < 2
    ) {
      colorClass = 'bg-[#fbfbce]';
    } else {
      colorClass = 'bg-[#fbe7e7]';
    }

    return colorClass;
  };

  return (
    <main className='bg-white rounded-lg'>
      <div className='flex justify-between items-center px-6 py-1 bg-white'>
        {/* <h2 className="font-semibold text-l">Leads</h2> */}
        <Breadcrumbs data={breadCrumbsObj} />
        <div className='flex gap-4'>
          <Filter
            removeValFrom={[15, 19, 20]}
            allColumns={InquiryColumns}
            activeColumns={curColumns}
            updateActiveColumns={setCurColumns}
            minFields={MIN_FIELDS}
            maxFields={MAX_FIELDS}
          />
          <SearchComponent onChange={handleSearch} />
          <Button
            text='+ Create New Lead'
            variant='primary'
            classes='xl:px-6'
            onClick={handleAddClick}
            permissions={permissions}
            id={elementData.Leads.permissions.create}
          />
        </div>
      </div>
      <Tabs
        onChange={onchangeTab}
        items={items}
        defaultActiveKey={tabKey}
        className='pl-4 bg-white'
      />

      <TableComponent
        getRowClassName={rowCommonClass}
        className='mt-3'
        loading={loading}
        noDataAction={onGetInquiry}
        scroll={{
          x: curColumns.length > 8 ? 2000 : 500,
          // y: '48vh',
        }}
        columns={[
          ...curColumns,
          {
            title: <CLOCK svgClass={'h-4 w-4 mx-auto stroke-black fill-black'} />,
            key: 'action',
            align: 'right',

            render: (_, record) => {
              const date = dayjs(record?.created);
              const dateToCheck = dayjs(record?.created);
              const currentDate = dayjs();

              let colorClass = '';
              if (currentDate.diff(dateToCheck, 'hour') < 1) {
                colorClass = 'text-green';
              } else if (
                currentDate.diff(dateToCheck, 'hour') > 1 &&
                currentDate.diff(dateToCheck, 'hour') < 2
              ) {
                colorClass = 'text-yellow';
              } else {
                colorClass = 'text-red';
              }

              return (
                <Space size='middle'>
                  <span className={colorClass}>{dayjs(date).fromNow()}</span>
                </Space>
              );
            },
          },

          {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
              <Space size='middle'>
                {record?.status === 'draft' && (
                  <IconButton
                    title='Create Quote'
                    permissions={permissions}
                    id={elementData.Leads.permissions.view}
                    className='flex justify-center items-center w-6 h-6 rounded-full border group disabled:cursor-not-allowed'
                    onClick={() => navigate(`/lead/edit/${record?.id}/2`)}
                  >
                    <DiffOutlined />
                  </IconButton>
                )}
                <IconButton
                  title='View'
                  permissions={permissions}
                  id={elementData.Leads.permissions.view}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => navigate(`/lead-detail/${record?.id}`, { state: record })}
                >
                  <View svgClass='stroke-primary group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Edit'
                  permissions={permissions}
                  id={elementData.Leads.permissions.update}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => navigate(`/lead/edit/${record?.id}/1`, { state: customer_id })}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Delete'
                  permissions={permissions}
                  id={elementData.Leads.permissions.delete}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    setSelectedInquiryRow(record);
                    toggleDeleteInquiry();
                  }}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton>
              </Space>
            ),
          },
        ]}
        dataSource={searchQuery ? filteredInquiries : inquiry}
        pageSize={PAGE_SIZE}
        total={inquiry?.length}
      />

      {/* delete modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteInquiry}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='w-16 h-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='pb-3 text-xs text-center text-grey-80'>
            Could you please confirm that you really wish to delete the inquiry details?
          </span>
          <div className='flex flex-row justify-center pt-4 bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteInquiry}
            />
            <Button
              id={elementData.Leads.permissions.delete}
              permissions={permissions}
              text='Delete Inquiry'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteInquiry}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </main>
  );
};

export default SecureComponent(Leads);
