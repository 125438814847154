import { Badge, Checkbox, Drawer } from 'antd';
import { FilterNewIcon } from 'assets/icons';
import Button from 'components/button';
import React, { useEffect, useState } from 'react';
import {
  departmentManagement,
  designationManagement,
  getBranches,
  getRoles,
} from 'service/api-service';
import { clientFilterOptions, statusOptions } from './data';
import IconButton from 'components/button/IconButton';
import './style.css';

const MultiFilter = ({ filterBy = [], setFilteredValues }) => {
  const [open, setOpen] = useState(false);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [departmentOptions, setDepartmentsOptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [loading, setLoading] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedClient, setSelectedclient] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [leadsOptions, setLeadsOptions] = useState([]);
  let finalStatus = '';
  const count = [
    selectedBranch,
    selectedDepartment,
    selectedDesignation,
    selectedRole,
    selectedStatus,
    selectedClient,
    selectedLeads,
  ]?.filter((item) => item?.length > 0);

  const getLocalItems = () => {
    const options = JSON.parse(localStorage.getItem('customerFields'))
      ?.filter((d) => d?.title === 'Insured Details')?.[0]
      ?.fields.filter((dt) => dt?.name === 'lead_source');
    setLeadsOptions(options?.[0]?.options);
  };

  const fetchOptions = async () => {
    setLoading(true);

    // Roles options

    if (filterBy?.find((item) => item == 'roles')) {
      const resp = await getRoles();
      const data = resp?.data?.data;
      if (resp?.status) {
        const finalOptions = data?.map((item) => {
          return {
            ...item,
            label: item?.name,
            value: item?.id,
          };
        });
        if (finalOptions) {
          setRolesOptions(finalOptions);
        }
      }
    }

    // designation Options

    if (filterBy?.find((item) => item == 'designations')) {
      const resp = await designationManagement();
      const finalOptions = resp?.data?.data?.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      });
      if (finalOptions) {
        setDesignationOptions(finalOptions || []);
      }
    }

    // Department Options

    if (filterBy?.find((item) => item == 'departments')) {
      const res = await departmentManagement();
      const finalOptions = res?.data?.data?.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      });
      if (finalOptions) {
        setDepartmentsOptions(finalOptions || []);
      }
    }

    // Branch Options

    if (filterBy?.find((item) => item == 'branch')) {
      const res = await getBranches();
      if (res?.data?.status) {
        setBranchOptions(res?.data?.data || []);
      }
    }

    setLoading(false);
  };

  const handleFilter = () => {
    setOpen((prev) => !prev);
  };

  const handleRoles = (e, data) => {
    if (e?.target?.checked) {
      setSelectedRole((prev) => [...prev, data?.id]);
    } else {
      const filtered = selectedRole?.filter((itm) => itm !== data?.id);
      setSelectedRole(filtered);
    }
  };

  const handleDesignation = (e, data) => {
    if (e?.target?.checked) {
      setSelectedDesignation((prev) => [...prev, data?.value]);
    } else {
      const filtered = selectedDesignation?.filter((itm) => itm !== data?.value);
      setSelectedDesignation(filtered);
    }
  };
  const handleDepartments = (e, data) => {
    if (e?.target?.checked) {
      setSelectedDepartment((prev) => [...prev, data?.value]);
    } else {
      const filtered = selectedDepartment?.filter((itm) => itm !== data?.value);
      setSelectedDepartment(filtered);
    }
  };
  const handleStatus = (e, data) => {
    if (e?.target?.checked) {
      setSelectedStatus((prev) => [...prev, data?.value]);
    } else {
      const filtered = selectedStatus?.filter((itm) => itm !== data?.value);
      setSelectedStatus(filtered);
    }
  };
  const handleLeadsChange = (e, data) => {
    if (e?.target?.checked) {
      setSelectedLeads((prev) => [...prev, data?.id]);
    } else {
      const filtered = selectedLeads?.filter((itm) => itm !== data?.id);
      setSelectedLeads(filtered);
    }
  };
  const handleBranch = (e, data) => {
    if (e?.target?.checked) {
      setSelectedBranch([data?.id]);
    } else {
      setSelectedBranch('');
    }
  };
  const handleClient = (e, data) => {
    if (e?.target?.checked) {
      setSelectedclient(data?.id);
    } else {
      setSelectedclient('');
    }
  };

  const handleClearAll = () => {
    setSelectedDepartment([]);
    setSelectedDesignation([]);
    setSelectedRole([]);
    setSelectedStatus([]);
    setSelectedLeads([]);
    setSelectedBranch('');
    setSelectedclient('');
    setFilteredValues({
      roles: [],
      designation: [],
      department: [],
      branch: '',
      client: '',
      status: [],
    });
    setOpen(false);
  };
  const handleApply = () => {
    finalStatus = selectedStatus?.length === 2 ? '' : selectedStatus[0] || '';
    setFilteredValues({
      roles: selectedRole,
      designation: selectedDesignation,
      department: selectedDepartment,
      branch: selectedBranch[0] || '',
      client: selectedClient,
      status: finalStatus,
      leadSource: selectedLeads.length > 0 ? selectedLeads : null,
    });
    setOpen(false);
  };

  const DrawerFooter = () => {
    return (
      <div className='flex gap-2 gap-x-4 justify-end items-center w-full'>
        <p
          onClick={handleClearAll}
          className='text-[#26CBED] text-xxs font-semibold cursor-pointer'
        >
          CLEAR ALL
        </p>
        <Button onClick={handleApply} classes='font-semibold py-1' text='APPLY' />
      </div>
    );
  };

  useEffect(() => {
    getLocalItems();
    fetchOptions();
  }, []);

  const Filters = () => {
    return (
      <div>
        <div className='grid gap-y-4 p-4 pl-8'>
          {/* roles */}
          {filterBy?.find((itm) => itm === 'roles') && (
            <div>
              <p className='mb-2 text-xs font-semibold'>Roles</p>
              <div className='grid grid-cols-3 gap-x-2 gap-4'>
                {rolesOptions?.map((item) => {
                  return (
                    <div className='flex gap-2'>
                      <Checkbox
                        checked={selectedRole?.find((ele) => ele === item?.id)}
                        onClick={(e) => handleRoles(e, item)}
                      />
                      <p className='capitalize'>{item?.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/*     designation */}

          {filterBy?.find((itm) => itm == 'designations') && (
            <div>
              <p className='my-2 text-xs font-semibold'>Designation</p>
              <div className='grid grid-cols-3 gap-x-2 gap-4'>
                {designationOptions?.map((item) => {
                  return (
                    <div className='flex gap-2'>
                      <Checkbox
                        checked={selectedDesignation?.find((ele) => ele === item?.value)}
                        onClick={(e) => handleDesignation(e, item)}
                      />
                      <p className='capitalize'>{item?.label}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* Deaprtments */}

          {filterBy?.find((itm) => itm == 'departments') && (
            <div>
              <p className='my-2 text-xs font-semibold'>Department</p>
              <div className='grid grid-cols-3 gap-x-2 gap-4'>
                {departmentOptions?.map((item) => {
                  return (
                    <div className='flex gap-2'>
                      <Checkbox
                        checked={selectedDepartment?.find((ele) => ele === item?.value)}
                        onClick={(e) => handleDepartments(e, item)}
                      />
                      <p className='capitalize'>{item?.label}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* branch */}

          {filterBy?.find((itm) => itm == 'branch') && (
            <div>
              <p className='my-2 text-xs font-semibold'>Branch</p>
              <div className='grid grid-cols-3 gap-x-2 gap-4'>
                {branchOptions?.map((item) => {
                  return (
                    <div className='flex gap-2'>
                      <Checkbox
                        checked={selectedBranch[0] === item?.id}
                        onClick={(e) => handleBranch(e, item)}
                      />
                      <p className='capitalize'>{item?.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* Assign */}

          {filterBy?.find((itm) => itm == 'assign') && (
            <div>
              <p className='my-2 text-xs font-semibold'>Client filter</p>
              <div className='grid grid-cols-3 gap-x-2 gap-4'>
                {clientFilterOptions?.map((item) => {
                  return (
                    <div className='flex gap-2'>
                      <Checkbox
                        checked={selectedClient === item?.id}
                        onClick={(e) => handleClient(e, item)}
                      />
                      <p className='capitalize'>{item?.label}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {/* status */}

          {filterBy?.find((itm) => itm == 'status') && (
            <div>
              <p className='my-2 text-xs font-semibold'>Status</p>
              <div className='grid grid-cols-3 gap-x-2 gap-4'>
                {statusOptions?.map((item) => {
                  return (
                    <div className='flex gap-2'>
                      <Checkbox
                        checked={selectedStatus?.find((ele) => ele === item?.value)}
                        onClick={(e) => handleStatus(e, item)}
                      />
                      <p className='capitalize'>{item?.label}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {/* Lead Source */}

          {filterBy?.find((itm) => itm === 'lead') && (
            <div>
              <p className='my-2 text-xs font-semibold'>Lead Source</p>
              <div className='grid grid-cols-3 gap-x-2 gap-4'>
                {leadsOptions.map((item) => {
                  return (
                    <div className='flex gap-2'>
                      <Checkbox
                        checked={selectedLeads?.find((ele) => ele === item?.id)}
                        onClick={(e) => handleLeadsChange(e, item)}
                      />
                      <p className='capitalize'>{item?.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div id='filter-badge'>
        <div onClick={handleFilter}>
          <Badge className='mt-[6px]' count={count?.length}>
            <IconButton
              title='Filter'
              className='group disabled:cursor-not-allowed'
              // onClick={handleFilter}
            >
              <FilterNewIcon svgClasses='cursor-pointer' />
            </IconButton>
          </Badge>
        </div>
      </div>
      <Drawer
        maskClosable={false}
        title={<h2 className='text-sm font-semibold'>Filters</h2>}
        open={open}
        placement='right'
        onClose={handleFilter}
        width={550}
        footer={<DrawerFooter />}
      >
        <Filters />
      </Drawer>
    </>
  );
};

export default MultiFilter;
