import React, { useState, useCallback, useEffect } from 'react';
import { List, Checkbox } from 'antd';
import { coverageOptions } from './config';

export const LabelValue = ({ label, value, w = 32, w2, vertical, labelClass = '' }) => (
  <div className={`${vertical ? '' : 'flex'} items-end p-4 py-2 ${labelClass}`}>
    <label className={`w-${w} font-semibold`}>{label}</label>
    {value && <div className={`${w2 ? 'w-' + w2 : 'flex-1'}`}>{value}</div>}
  </div>
);

export const CoverageComponent = ({ onChange, defaultSelected = [], coverageOption }) => {
  const [selected, setSelected] = useState([]);

  const doSelect = useCallback(
    (item) => () => {
      setSelected((s) => {
        if (s.indexOf(item) >= 0) {
          return s.filter((i) => i !== item);
        } else return [...s, item];
      });
    },
    [],
  );

  const selectAll = useCallback(() => {
    setSelected((s) => {
      if (s.length !== coverageOption.length) return coverageOption.map((i) => i.value);
      else return [];
    });
  }, []);

  const renderItem = useCallback(
    (item, key) => (
      <List.Item
        key={key}
        extra={<Checkbox className='m-2' checked={selected.indexOf(item.value) >= 0} />}
        onClick={doSelect(item.value)}
      >
        <List.Item.Meta title={item.label} className='cursor-pointer' />
      </List.Item>
    ),
    [doSelect, selected],
  );

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  useEffect(() => {
    if (defaultSelected && defaultSelected.length) {
      setSelected(defaultSelected);
    }
  }, [defaultSelected]);

  return (
    <div>
      <div
        onClick={selectAll}
        className='cursor-pointer flex items-center justify-between p-1 font-semibold bg-white border-b border-grey-40'
      >
        Select Types of Coverage
        {/* <Checkbox className='m-2' checked={selected.length === coverageOption.length} /> */}
      </div>
      <List
        dataSource={coverageOption}
        renderItem={renderItem}
        className='max-h-72 overflow-auto px-2'
      />
    </div>
  );
};
