/** external deps */
import React, { useState } from 'react';

/** internal deps */
import { BarGraph, LineChart, PieChart, VerticalBarGraph } from 'assets';

const OPTIONS = [
  { image: PieChart, value: 'pie_chart', label: 'Pie Chart' },
  { image: VerticalBarGraph, value: 'VerticalBarGraph', label: 'Vertical Bar Graph' },
  { image: BarGraph, value: 'BarGraph', label: 'Bar Graph' },
  { image: LineChart, value: 'LineChart', label: 'Line Chart' },
];

const ChartSelect = ({ label, ...props }) => {
  const [value, setValue] = useState();
  const [selected, setSelected] = useState();

  const _onChange = ({ index, val }) => {
    setValue(val);
    setSelected(index);
    if (props?.setFieldValue) return props.setFieldValue(props?.name, val);
  };

  return (
    <>
      {OPTIONS.map((item, index) => (
        <div className='group active cursor-pointer' key={index}>
          <div
            onClick={() => {
              _onChange({ index, val: item?.value });
            }}
            className={`border border-grey-90 group-hover:border-primary-90 h-32 rounded-md ${
              selected === index && 'border-primary-90'
            }`}
          >
            <img src={item?.image} alt='chart' className='w-full h-full rounded-md' />
          </div>
          <label
            className={`group-hover:text-primary-90 ${selected === index && 'text-primary-90'}`}
          >
            {item?.label}
          </label>
        </div>
      ))}
      <input name={props?.name} type='hidden' value={value} required={true} />
    </>
  );
};

export default ChartSelect;
