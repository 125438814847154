export const elementData = {
  dashboard: {
    name: 'Dashboard',
    read: 'Dashboard.read',
    write: 'Dashboard.write',
    permissions: {
      // create: "Dashboard.children.create_dashboard",
      // update: "Dashboard.children.update_dashboard",
      // delete: "Dashboard.children.delete_dashboard",
      // view: "Dashboard.children.view_dashboard",
    },
  },
  mydashboard: {
    name: 'Dashboard.children.My Dashboard',
    read: 'Dashboard.children.My Dashboard.read',
    write: 'Dashboard.children.My Dashboard.write',
    permissions: {
      // create: "My Dashboard.children.create_dashboard",
      // update: "My Dashboard.children.update_dashboard",
      // delete: "My Dashboard.children.delete_dashboard",
      // view: "My Dashboard.children.view_dashboard",
    },
  },
  taskdashboard: {
    name: 'Dashboard.children.Task Dashboard',
    read: 'Dashboard.children.Dashboard.read',
    write: 'Dashboard.children.Dashboard.write',
    permissions: {
      // create: "Dashboard.children.create_dashboard",
      // update: "Dashboard.children.update_dashboard",
      // delete: "Dashboard.children.delete_dashboard",
      // view: "Dashboard.children.view_dashboard",
    },
  },
  hrdashboard: {
    name: 'Dashboard.children.Hr Dashboard',
    read: 'Dashboard.children.Dashboard.read',
    write: 'Dashboard.children.Dashboard.write',
    permissions: {
      // create: "Dashboard.children.create_dashboard",
      // update: "Dashboard.children.update_dashboard",
      // delete: "Dashboard.children.delete_dashboard",
      // view: "Dashboard.children.view_dashboard",
    },
  },
  //Master
  master: {
    name: 'Master',
    // read: "Dashboard.read",
    // write: "Dashboard.write",
    permissions: {
      // create: "Dashboard.children.create_dashboard",
      // update: "Dashboard.children.update_dashboard",
      // delete: "Dashboard.children.delete_dashboard",
      // view: "Dashboard.children.view_dashboard",
    },
  },

  branch: {
    name: 'Master.children.Branch',
    read: 'Master.children.Branch.read',
    write: 'Master.children.Branch.write',
    permissions: {
      create: 'Master.children.Branch.children.Create Branch',
      update: 'Master.children.Branch.children.Update Branch',
      delete: 'Master.children.Branch.children.Delete Branch',
      view: 'Master.children.Branch.children.View Branch',
    },
  },
  roles: {
    name: 'Master.children.Roles',
    read: 'Master.children.Roles.read',
    write: 'Master.children.Roles.write',
    permissions: {
      create: 'Master.children.Roles.children.Create Roles',
      update: 'Master.children.Roles.children.Update Roles',
      delete: 'Master.children.Roles.children.Delete Roles',
      view: 'Master.children.Roles.children.View Roles',
    },
  },
  department: {
    name: 'Master.children.Department',
    read: 'Master.children.Department.read',
    write: 'Master.children.Department.write',
    permissions: {
      create: 'Master.children.Department.children.Create Department',
      update: 'Master.children.Department.children.Update Department',
      delete: 'Master.children.Department.children.Delete Department',
      view: 'Master.children.Department.children.View Department',
    },
  },
  designation: {
    name: 'Master.children.Designation',
    read: 'Master.children.Designation.read',
    write: 'Master.children.Designation.write',
    permissions: {
      create: 'Master.children.Designation.children.Create Designation',
      update: 'Master.children.Designation.children.Update Designation',
      delete: 'Master.children.Designation.children.Delete Designation',
      view: 'Master.children.Designation.children.View Designation',
    },
  },
  //Employee
  employee: {
    name: 'Employee',
    read: 'Employee.read',
    write: 'Employee.write',
    permissions: {
      create: 'Employee.children.Create Employee',
      update: 'Employee.children.Update Employee',
      delete: 'Employee.children.Delete Employee',
      view: 'Employee.children.View Employee',
    },
  },
  permission_update: {
    name: 'Employee.children.Employee Permission',
    read: 'Employee.children.Employee Permission.read',
    write: 'Employee.children.Employee Permission.write',
    permissions: {
      // create: "Permission.children.permission_create",
      update: 'Employee.children.Employee Permission.children.Employee Permission Update',
      // delete: "Permission.children.permission_delete",
      // view: "Permission.children.permission_view",
    },
  },
  //Customer
  client: {
    name: 'Client',
    read: 'Client.read',
    write: 'Client.write',
    permissions: {
      create: 'Client.children.Create Client',
      update: 'Client.children.Update Client',
      delete: 'Client.children.Delete Client',
      view: 'Client.children.View Client',
    },
  },
  contacts: {
    name: 'Client.children.Contacts',
    read: 'Client.children.Contacts.read',
    write: 'Client.children.Contacts.write',
    permissions: {
      create: 'Client.children.Contacts.children.Create Contacts',
      update: 'Client.children.Contacts.children.Update Contacts',
      delete: 'Client.children.Contacts.children.Delete Contacts',
      view: 'Client.children.Contacts.children.View Contacts',
    },
  },
  task: {
    name: 'Task',
    read: 'Task.read',
    write: 'Task.write',
    permissions: {
      create: 'Task.children.Create Task',
      update: 'Task.children.Update Task',
      delete: 'Task.children.Delete Task',
      view: 'Task.children.View Task',
    },
  },
  meeting: {
    name: 'Meeting',
    read: 'Meeting.read',
    write: 'Meeting.write',
    permissions: {
      create: 'Meeting.children.Create Meeting',
      update: 'Meeting.children.Update Meeting',
      delete: 'Meeting.children.Delete Meeting',
      view: 'Meeting.children.View Meeting',
    },
  },

  //Sales Module

  Leads: {
    name: 'Sales.children.Leads',
    read: 'Sales.children.Leads.read',
    write: 'Sales.children.Leads.write',
    permissions: {
      create: 'Sales.children.Leads.children.Create Leads',
      update: 'Sales.children.Leads.children.Update Leads',
      delete: 'Sales.children.Leads.children.Delete Leads',
      view: 'Sales.children.Leads.children.View Leads',
    },
  },
  pos: {
    name: 'Sales.children.POS',
    read: 'Sales.children.POS.read',
    write: 'Sales.children.POS.write',
    permissions: {
      create: 'Sales.children.POS.children.Create POS',
      update: 'Sales.children.POS.children.Update POS',
      delete: 'Sales.children.POS.children.Delete POS',
      view: 'Sales.children.POS.children.View POS',
    },
  },
  pos_training: {
    name: 'Sales.children.POS Training',
    read: 'Sales.children.POS Training.read',
    write: 'Sales.children.POS Training.write',
    permissions: {
      create: 'Sales.children.POS Training.children.Create POS Training',
      update: 'Sales.children.POS Training.children.Update POS Training',
      delete: 'Sales.children.POS Training.children.Delete POS Training',
      view: 'Sales.children.POS Training.children.View POS Training',
    },
  },
  posContract: {
    name: 'Sales.children.POS Training',
    read: 'Sales.children.POS Training.read',
    write: 'Sales.children.POS Training.write',
    permissions: {
      create: 'Sales.children.POS Training.children.Create POS Training',
      update: 'Sales.children.POS Training.children.Update POS Training',
      delete: 'Sales.children.POS Training.children.Delete POS Training',
      view: 'Sales.children.POS Training.children.View POS Training',
    },
  },
  termsCondition: {
    name: 'Terms And Condition',
    read: 'Terms And Condition.read',
    write: 'Terms And Condition.write',
    permissions: {
      create: 'Terms And Condition.children.Create',
      update: 'Terms And Condition.children.Update',
      // delete: "Terms And Condition.children.Delete",
      view: 'Terms And Condition.children.View',
    },
  },

  policy: {
    name: 'Policy',
    read: 'Policy.read',
    write: 'Policy.write',
    permissions: {
      create: 'Policy.children.Create Policy',
      update: 'Policy.children.Update Policy',
      delete: 'Policy.children.Delete Policy',
      view: 'Policy.children.View Policy',
    },
  },

  clause: {
    name: 'Insurance Category.children.Clause',
    read: 'Insurance Category.children.Clause.read',
    write: 'Insurance Category.children.Clause.write',
    permissions: {
      create: 'Insurance Category.children.Clause.children.Create Clause',
      update: 'Insurance Category.children.Clause.children.Update Clause',
      delete: 'Insurance Category.children.Clause.children.Delete Clause',
      view: 'Insurance Category.children.Clause.children.View Clause',
    },
  },

  adjuster: {
    name: 'Adjuster',
    read: 'Adjuster.read',
    write: 'Adjuster.write',
    permissions: {
      create: 'Adjuster.children.Create Adjuster',
      update: 'Adjuster.children.Update Adjuster',
      delete: 'Adjuster.children.Delete Adjuster',
      view: 'Adjuster.children.View Adjuster',
    },
  },

  Quote: {
    name: 'Quote',
    read: 'Quote.read',
    write: 'Quote.write',
    permissions: {
      create: 'Quote.children.Create Quote',
      update: 'Quote.children.Update Quote',
      // delete:"Quote.children.Delete Quote",
      view: 'Quote.children.View Quote',
    },
  },

  //Goals Module
  Goals: {
    name: 'Goals',
    read: 'Goals.read',
    write: 'Goals.write',
    permissions: {
      create: 'Goals.children.Create Goals',
      update: 'Goals.children.Update Goals',
      delete: 'Goals.children.Delete Goals',
      view: 'Goals.children.View Goals',
    },
  },

  Goals_type: {
    name: 'Goals.children.Goal Type',
    read: 'Goals.children.Goal Type.read',
    write: 'Goals.children.Goal Type.write',
    permissions: {
      create: 'Goals.children.Goal Type.children.Create Goal Type',
      update: 'Goals.children.Goal Type.children.Update Goal Type',
      delete: 'Goals.children.Goal Type.children.Delete Goal Type',
      view: 'Goals.children.Goal Type.children.View Goal Type',
    },
  },
  // HR modules

  company_policy: {
    name: 'Company Policy',
    read: 'Company Policy.read',
    write: 'Company Policy.write',
    permissions: {
      create: 'Company Policy.children.Create Company Policy',
      update: 'Company Policy.children.Update Company Policy',
      delete: 'Company Policy.children.Create Company Policy',
      view: 'Company Policy.children.View Company Policy',
    },
  },

  HR: {
    name: 'HR',
    read: 'HR.read',
    write: 'HR.write',
    permissions: {
      create: 'HR.children.Create HR',
      update: 'HR.children.Update HR',
      delete: 'HR.children.Delete HR',
      view: 'HR.children.View HR',
      status: 'HR.children.Leave Status',
    },
  },
  dailyGoals: {
    name: 'HR.children.Goal',
    read: 'HR.children.Goal.read',
    write: 'HR.children.Goal.write',
    permissions: {
      create: 'HR.children.Create Goal',
      update: 'HR.children.Update Goal',
      delete: 'HR.children.Delete Goal',
      view: 'HR.children.View Goal',
    },
  },
  letter: {
    name: 'HR.children.Letter',
    read: 'HR.children.Letter.read',
    write: 'HR.children.Letter.write',
    permissions: {
      create: 'HR.children.Letter.children.Create Letter',
      update: 'HR.children.Letter.children.Update Letter',
      delete: 'HR.children.Letter.children.Delete Letter',
      view: 'HR.children.Letter.children.View Letter',
    },
  },
  leaves_type: {
    name: 'HR.children.Leave Type',
    read: 'HR.children.Leave Type.read',
    write: 'HR.children.Leave Type.write',
    permissions: {
      create: 'HR.children.Leave Type.children.Create Leave Type',
      update: 'HR.children.Leave Type.children.Update Leave Type',
      delete: 'HR.children.Leave Type.children.Delete Leave Type',
      view: 'HR.children.Leave Type.children.View Leave Type',
    },
  },
  leaves_request: {
    name: 'HR.children.Leave Request',
    read: 'HR.children.Leave Request.read',
    write: 'HR.children.Leave Request.write',
    permissions: {
      create: 'HR.children.Leave Request.children.Create Leave Request',
      update: 'HR.children.Leave Request.children.Update Leave Request',
      delete: 'HR.children.Leave Request.children.Delete Leave Request',
      view: 'HR.children.Leave Request.children.View Leave Request',
    },
  },
  leaves: {
    name: 'HR.children.Leave',
    read: 'HR.children.Leave.read',
    write: 'HR.children.Leave.write',
    permissions: {
      create: 'HR.children.Leave.children.Create Leave',
      update: 'HR.children.Leave.children.Update Leave',
      delete: 'HR.children.Leave.children.Delete Leave',
      view: 'HR.children.Leave.children.View Leave',
    },
  },
  attendance: {
    name: 'HR.children.Mark Attendance',
    read: 'HR.children.Mark Attendance.read',
    write: 'HR.children.Mark Attendance.write',
    permissions: {
      create: 'HR.children.Mark Attendance.children',
      update: 'HR.children.Mark Attendance.children',
      delete: 'HR.children.Mark Attendance.children',
      view: 'HR.children.Mark Attendance.children',
    },
  },
  myattendance: {
    name: 'My Attendance',
    read: 'My Attendance.read',
    write: 'My Attendance.write',
    permissions: {
      create: 'My Attendance.children.Create Leaves',
      update: 'My Attendance.children.Update Leaves',
      delete: 'My Attendance.children.Delete Leaves',
      view: 'My Attendance.children.View Leaves',
    },
  },

  providentFund: {
    name: 'ProvidentFund',
    read: 'ProvidentFund.read',
    write: 'ProvidentFund.write',
    permissions: {
      create: 'ProvidentFund.children.Create ProvidentFund',
      update: 'ProvidentFund.children.Update ProvidentFund',
      delete: 'ProvidentFund.children.Delete ProvidentFund',
      view: 'ProvidentFund.children.View ProvidentFund',
    },
  },
  assetRequest: {
    name: 'HR.children.HR Asset Request',
    read: 'HR.children.HR Asset Request.read',
    write: 'HR.children.HR Asset Request.write',
    permissions: {
      create: 'HR.children.HR Asset Request.children.Create HR Asset Request',
      update: 'HR.children.HR Asset Request.children.Update HR Asset Request',
      delete: 'HR.children.HR Asset Request.children.Delete HR Asset Request',
      view: 'HR.children.HR Asset Request.children.View HR Asset Request',
    },
  },
  jobKra: {
    name: 'HR.children.Job Kra',
    read: 'HR.children.Job Kra.read',
    write: 'HR.children.Job Kra.write',
    permissions: {
      create: 'HR.children.Job Kra.children.Create Job Kra',
      update: 'HR.children.Job Kra.children.Update Job Kra',
      delete: 'HR.children.Job Kra.children.Delete Job Kra',
      view: 'HR.children.Job Kra.children.View Job Kra',
    },
  },
  manageRecruitment: {
    name: 'HR.children.Job Recruitment',
    read: 'HR.children.Job Recruitment.read',
    write: 'HR.children.Job Recruitment.write',
    permissions: {
      create: 'HR.children.Job Recruitment.children.Create Job Recruitment',
      update: 'HR.children.Job Recruitment.children.Update Job Recruitment',
      delete: 'HR.children.Job Recruitment.children.Delete Job Recruitment',
      view: 'HR.children.Job Recruitment.children.View Job Recruitment',
    },
  },
  applicationDetails: {
    name: 'HR.children.Job Application',
    read: 'HR.children.Job Application.read',
    write: 'HR.children.Job Application.write',
    permissions: {
      create: 'HR.children.Job Application.children.Create Job Application',
      update: 'HR.children.Job Application.children.Update Job Application',
      delete: 'HR.children.Job Application.children.Delete Job Application',
      view: 'HR.children.Job Application.children.View Job Application',
    },
  },
  interview: {
    name: 'HR.children.Interview',
    read: 'HR.children.Interview.read',
    write: 'HR.children.Interview.write',
    permissions: {
      create: 'HR.children.Interview.children.Create Interview',
      update: 'HR.children.Interview.children.Update Interview',
      delete: 'HR.children.Interview.children.Delete Interview',
      view: 'HR.children.Interview.children.View Interview',
    },
  },
  assets: {
    name: 'HR.children.Asset',
    read: 'HR.children.Asset.read',
    write: 'HR.children.Asset.write',
    permissions: {
      create: 'HR.children.Asset.children.Create Asset',
      update: 'HR.children.Asset.children.Update Asset',
      delete: 'HR.children.Asset.children.Delete Asset',
      view: 'HR.children.Asset.children.View Asset',
    },
  },
  assetAllocation: {
    name: 'HR.children.Asset Allocation',
    read: 'HR.children.Asset Allocation.read',
    write: 'HR.children.Asset Allocation.write',
    permissions: {
      create: 'HR.children.Asset Allocation.children.Create Asset Allocation',
      update: 'HR.children.Asset Allocation.children.Update Asset Allocation',
      delete: 'HR.children.Asset Allocation.children.Delete Asset Allocation',
      view: 'HR.children.Asset Allocation.children.View Asset Allocation',
    },
  },
  assetType: {
    name: 'HR.children.Asset Type',
    read: 'HR.children.Asset Type.read',
    write: 'HR.children.Asset Type.write',
    permissions: {
      create: 'HR.children.Asset Type.children.Create Asset Type',
      update: 'HR.children.Asset Type.children.Update Asset Type',
      delete: 'HR.children.Asset Type.children.Delete Asset Type',
      view: 'HR.children.Asset Type.children.View Asset Type',
    },
  },
  request_asset: {
    name: 'Personal.children.Personal Asset',
    read: 'Personal.children.Personal Asset.read',
    write: 'Personal.children.Personal Asset.write',
    permissions: {
      create: 'Personal.children.Personal Asset.children.Create Personal Asset',
      update: 'Personal.children.Personal Asset.children.Update Personal Asset',
      delete: 'Personal.children.Personal Asset.children.Delete Personal Asset',
      view: 'Personal.children.Personal Asset.children.View Personal Asset',
    },
  },

  //Inventory Module
  // assets: {
  //   name: 'Inventory.children.Asset',
  //   read: 'Inventory.children.Asset.read',
  //   write: 'Inventory.children.Asset.write',
  //   permissions: {
  //     create: 'Inventory.children.Asset.children.Create Asset',
  //     update: 'Inventory.children.Asset.children.Update Asset',
  //     delete: 'Inventory.children.Asset.children.Delete Asset',
  //     view: 'Inventory.children.Asset.children.View Asset',
  //   },
  // },
  // assetAllocation: {
  //   name: 'Inventory.children.Asset Allocation',
  //   read: 'Inventory.children.Asset Allocation.read',
  //   write: 'Inventory.children.Asset Allocation.write',
  //   permissions: {
  //     create: 'Inventory.children.Asset Allocation.children.Create Asset Allocation',
  //     update: 'Inventory.children.Asset Allocation.children.Update Asset Allocation',
  //     delete: 'Inventory.children.Asset Allocation.children.Delete Asset Allocation',
  //     view: 'Inventory.children.Asset Allocation.children.View Asset Allocation',
  //   },
  // },
  // assetType: {
  //   name: 'Inventory.children.Asset Type',
  //   read: 'Inventory.children.Asset Type.read',
  //   write: 'Inventory.children.Asset Type.write',
  //   permissions: {
  //     create: 'Inventory.children.Asset Type.children.Create Asset Type',
  //     update: 'Inventory.children.Asset Type.children.Update Asset Type',
  //     delete: 'Inventory.children.Asset Type.children.Delete Asset Type',
  //     view: 'Inventory.children.Asset Type.children.View Asset Type',
  //   },
  // },

  accounts: {
    name: 'Accounts',
    read: 'Accounts.read',
    write: 'Accounts.write',
    permissions: {
      create: 'Accounts.children.Create Leaves',
      update: 'Accounts.children.Update Leaves',
      delete: 'Accounts.children.Delete Leaves',
      view: 'Accounts.children.View Leaves',
    },
  },
  tax: {
    name: 'Tax',
    read: 'Tax.read',
    write: 'Tax.write',
    permissions: {
      create: 'Tax.children.Create Leaves',
      update: 'Tax.children.Update Leaves',
      delete: 'Tax.children.Delete Leaves',
      view: 'Tax.children.View Leaves',
    },
  },
  non_performing_employees: {
    name: 'Non-Performing Employees',
    read: 'Non-Performing Employees.read',
    write: 'Non-Performing Employees.write',
    permissions: {
      create: 'Non-Performing Employees.children.Create Leaves',
      update: 'Non-Performing Employees.children.Update Leaves',
      delete: 'Non-Performing Employees.children.Delete Leaves',
      view: 'Non-Performing Employees.children.View Leaves',
    },
  },
  attendance_settings: {
    name: 'HR.children.Shift Management',
    read: 'HR.children.Shift Management.read',
    write: 'HR.children.Shift Management.write',
    permissions: {
      create: 'HR.children.Shift Management.children.Create Shift Management',
      update: 'HR.children.Shift Management.children.Update Shift Management',
      delete: 'HR.children.Shift Management.children.Delete Shift Management',
      view: 'HR.children.Shift Management.children.View Shift Management',
    },
  },
  view_attendance: {
    name: 'HR.children.View Attendance',
    read: 'HR.children.View Attendance.read',
    write: 'HR.children.View Attendance.write',
    permissions: {
      create: 'HR.children.View Attendance.children',
      update: 'HR.children.View Attendance.children',
      delete: 'HR.children.View Attendance.children',
      view: 'HR.children.View Attendance.children',
    },
  },
  holidays_calender: {
    name: 'HR.children.Holiday',
    read: 'HR.children.Holiday.read',
    write: 'HR.children.Holiday.write',
    permissions: {
      create: 'HR.children.Holiday.children.Create Holiday',
      update: 'HR.children.Holiday.children.Update Holiday',
      delete: 'HR.children.Holiday.children.Delete Holiday',
      view: 'HR.children.Holiday.children.View Holiday',
    },
  },
  performance: {
    name: 'HR.children.Performance',
    read: 'HR.children.Performance.read',
    write: 'HR.children.Performance.write',
    permissions: {
      create: 'HR.children.Performance.children',
      update: 'HR.children.Performance.children ',
      delete: 'HR.children.Performance.children ',
      view: 'HR.children.Performance.children ',
    },
  },
  pay_role: {
    name: 'Pay role',
    read: 'Pay role.read',
    write: 'Pay role.write',
    permissions: {
      create: 'Pay role.children.Create Leaves',
      update: 'Pay role.children.Update Leaves',
      delete: 'Pay role.children.Delete Leaves',
      view: 'Pay role.children.View Leaves',
    },
  },
  HrDashboard: {
    name: 'HrDashboard',
    read: 'HrDashboard Employees.read',
    write: 'HrDashboard Employees.write',
    permissions: {
      create: 'HrDashboard Employees.children.Create Leaves',
      update: 'HrDashboard Employees.children.Update Leaves',
      delete: 'HrDashboard Employees.children.Delete Leaves',
      view: 'HrDashboard Employees.children.View Leaves',
    },
  },

  transactions: {
    name: 'transactions',
    read: 'transactions.read',
    write: 'transactions.write',
    permissions: {
      create: 'transactions.children.Create transactions',
      update: 'transactions.children.Update transactions',
      delete: 'transactions.children.Delete transactions',
      view: 'transactions.children.View transactions',
    },
  },

  Accounts: {
    name: 'Account',
    read: 'Account.read',
    write: 'Account.write',
    permissions: {
      create: 'Account.children.Create Account',
      update: 'Account.children.Update Account',
      delete: 'Account.children.Delete Account',
      view: 'Account.children.View Account',
    },
  },

  //Support module

  Helpline: {
    name: 'Support.children.Helpline',
    read: 'Support.children.Helpline.read',
    write: 'Support.children.Helpline.write',
    permissions: {
      create: 'Support.children.Create Helpline',
      update: 'Support.children.Update Helpline',
      delete: 'Support.children.Delete Helpline',
      view: 'Support.children.View Helpline',
    },
  },

  FAQ: {
    name: 'Support.children.Faq',
    read: 'Support.children.Faq.read',
    write: 'Support.children.Faq.write',
    permissions: {
      create: 'Support.children.Faq.children.Create Faq',
      update: 'Support.children.Faq.children.Update Faq',
      delete: 'Support.children.Faq.children.Delete Faq',
      view: 'Support.children.Faq.children.View Faq',
    },
  },

  ticket: {
    name: 'Support.children.Faq Tickets',
    read: 'Support.children.Faq Tickets.read',
    write: 'Support.children.Faq Tickets.write',
    permissions: {
      create: 'Support.children.Faq Tickets.children.Create Faq Tickets',
      update: 'Support.children.Faq Tickets.children.Update Faq Tickets',
      delete: 'Support.children.Faq Tickets.children.Delete Faq Tickets',
      view: 'Support.children.Faq Tickets.children.View Faq Tickets',
    },
  },
  // Account modules
  payroll: {
    name: 'Account.children.Payroll',
    read: 'Account.children.Payroll.read',
    write: 'Account.children.Payroll.write',
    permissions: {
      create: 'Account.children.Create Payroll',
      update: 'Account.children.Update Payroll',
      delete: 'Account.children.Delete Payroll',
      view: 'Account.children.View Payroll',
    },
  },
  basicInfo: {
    name: 'Account.children.Payroll',
    read: 'Account.children.Payroll.read',
    write: 'Account.children.Payroll.write',
    permissions: {
      create: 'Account.children.Payroll.children.Create Salary',
      update: 'Account.children.Payroll.children.Update Salary',
      delete: 'Account.children.Payroll.children.Delete Salary',
      view: 'Account.children.Payroll.children.View Salary',
    },
  },
  taxes: {
    name: 'Account.children.Payroll',
    read: 'Account.children.Payroll.read',
    write: 'Account.children.Payroll.write',
    permissions: {
      create: 'Account.children.Payroll.children.Create Taxes',
      update: 'Account.children.Payroll.children.Update Taxes',
      delete: 'Account.children.Payroll.children.Delete Taxes',
      view: 'Account.children.Payroll.children.View Taxes',
    },
  },
  taxSlab: {
    name: 'Account.children.Payroll',
    read: 'Account.children.Payroll.read',
    write: 'Account.children.Payroll.write',
    permissions: {
      create: 'Account.children.Payroll.children.Create Tax Slab',
      update: 'Account.children.Payroll.children.Update Tax Slab',
      delete: 'Account.children.Payroll.children.delete Tax Slab',
      view: 'Account.children.Payroll.children.View Tax Slab',
    },
  },
  allowance: {
    name: 'Account.children.Payroll',
    read: 'Account.children.Payroll.read',
    write: 'Account.children.Payroll.write',
    permissions: {
      create: 'Account.children.Payroll.children.Create Allowance',
      update: 'Account.children.Payroll.children.Update Allowance',
      delete: 'Account.children.Payroll.children.Delete Allowance',
      view: 'Account.children.Payroll.children.Allowance',
    },
  },
  salarySheet: {
    name: 'Account.children.Payroll',
    read: 'Account.children.Payroll.read',
    write: 'Account.children.Payroll.write',
    permissions: {
      create: 'Account.children.Payroll.children.Create Salary Sheet',
      update: 'Account.children.Payroll.children.Update Salary Sheet',
      delete: 'Account.children.Payroll.children.Delete Salary Sheet',
      view: 'Account.children.Payroll.children.Salary Sheet',
    },
  },

  Invoices: {
    name: 'Account.children.Invoice',
    read: 'Account.children.Invoice.read',
    write: 'Account.children.Invoice.write',
    permissions: {
      create: 'Account.children.Create Invoice',
      update: 'Account.children.Update Invoice',
      delete: 'Account.children.Delete Invoice',
      view: 'Account.children.View Invoice',
    },
  },

  company: {
    name: 'Account.children.Expenses',
    read: 'Account.children.Expenses.read',
    write: 'Account.children.Expenses.write',
    permissions: {
      create: 'Account.children.Expenses.children.Create Expenses',
      update: 'Account.children.Expenses.children.Update Expenses',
      delete: 'Account.children.Expenses.children.Delete Expenses',
      view: 'Account.children.Expenses.children.View Expenses',
    },
  },
  expense_type: {
    name: 'Account.children.Expenses',
    read: 'Account.children.Expenses.read',
    write: 'Account.children.Expenses.write',
    permissions: {
      create: 'Account.children.Expenses.children.Create Expense Type',
      update: 'Account.children.Expenses.children.Update Expense Type',
      delete: 'Account.children.Expenses.children.Delete Expense Type',
      view: 'Account.children.Expenses.children.View Expense Type',
    },
  },

  posEarning: {
    name: 'Account.children.Pos Earning',
    read: 'Account.children.Pos Earning.read',
    write: 'Account.children.Pos Earning.write',
    permissions: {
      create: 'Account.children.Create Pos Earning',
      update: 'Account.children.Update Pos Earning',
      delete: 'Account.children.Delete Pos Earning',
      view: 'Account.children.View Pos Earning',
    },
  },

  //Admin module
  earningSetting: {
    name: 'Admin.children.Commission',
    read: 'Admin.children.Commission.read',
    write: 'Admin.children.Commission.write',
    permissions: {
      create: 'Admin.children.Create Commission',
      update: 'Admin.children.Update Commission',
      delete: 'Admin.children.Delete Commission',
      view: 'Admin.children.View Commission',
    },
  },

  //Report module
  Report: {
    name: 'Report',
  },
  BussinessReport: {
    name: 'Report.children.Business Report',
    read: 'Report.children.Business Report.read',
    write: 'Report.children.Business Report.write',
    permissions: {
      create: 'Report.children.Create Business Report',
      update: 'Report.children.Update Business Report',
      delete: 'Report.children.Delete Business Report',
      view: 'Report.children.View Business Report',
    },
  },
  ExpenseReport: {
    name: 'Report.children.Expense Report',
    read: 'Report.children.Expense Report.read',
    write: 'Report.children.Expense Report.write',
    permissions: {
      create: 'Report.children.Create Expense Report',
      update: 'Report.children.Update Expense Report',
      delete: 'Report.children.Delete Expense Report',
      view: 'Report.children.View Expense Report',
    },
  },
  dailyReport: {
    name: 'Report.children.Daily Sale Report',
    read: 'Report.children.Daily Sale Report.read',
    write: 'Report.children.Daily Sale Report.write',
    permissions: {
      create: 'Report.children.Create Daily Sale Report',
      update: 'Report.children.Update Daily Sale Report',
      delete: 'Report.children.Delete Daily Sale Report',
      view: 'Report.children.View Daily Sale Report',
    },
  },
  reconciliation: {
    name: 'Report.children.Daily Sale Report',
    read: 'Report.children.Daily Sale Report.read',
    write: 'Report.children.Daily Sale Report.write',
    permissions: {
      create: 'Report.children.Create Daily Sale Report',
      update: 'Report.children.Update Daily Sale Report',
      delete: 'Report.children.Delete Daily Sale Report',
      view: 'Report.children.View Daily Sale Report',
    },
  },
  //Operation module
  insurer: {
    name: 'Operations.children.Insurer',
    read: 'Operations.children.Insurer.read',
    write: 'Operations.children.Insurer.write',
    permissions: {
      create: 'Operations.children.Insurer.children.Create Insurer',
      update: 'Operations.children.Insurer.children.Update Insurer',
      delete: 'Operations.children.Insurer.children.Delete Insurer',
      view: 'Operations.children.Insurer.children.View Insurer',
    },
  },

  insurance_category: {
    name: 'Operations.children.Insurance Category',
    read: 'Operations.children.Insurance Category.read',
    write: 'Operations.children.Insurance Category.write',
    permissions: {
      create: 'Operations.children.Insurance Category.children.Create Insurance Category',
      update: 'Operations.children.Insurance Category.children.Update Insurance Category',
      delete: 'Operations.children.Insurance Category.children.Delete Insurance Category',
      view: 'Operations.children.Insurance Category.children.View Insurance Category',
    },
  },
  custom_form: {
    name: 'Operations.children.Custom Form',
    read: 'Operations.children.Custom Form.read',
    write: 'Operations.children.Custom Form.write',
    permissions: {
      create: 'Operations.children.Custom Form.children.Create Custom Form',
      update: 'Operations.children.Custom Form.children.Update Custom Form',
      delete: 'Operations.children.Custom Form.children.Delete Custom Form',
      view: 'Operations.children.Custom Form.children.View Custom Form',
    },
  },

  //Personal
  request_leave: {
    name: 'Personal.children.Personal Leave Request',
    read: 'Personal.children.Personal Leave Request.read',
    write: 'Personal.children.Personal Leave Request.write',
    permissions: {
      create: 'Personal.children.Personal Leave Request.children.Create Personal Leave Request',
      update: 'Personal.children.Personal Leave Request.children.Update Personal Leave Request',
      delete: 'Personal.children.Personal Leave Request.children.Delete Personal Leave Request',
      view: 'Personal.children.Personal Leave Request.children.View Personal Leave Request',
    },
  },
  mark_attendance: {
    name: 'Personal.children.Personal Attendance',
    read: 'Personal.children.Personal Attendance.read',
    write: 'Personal.children.Personal Attendance.write',
    permissions: {
      create: 'Personal.children.Personal Attendance.children.Create Personal Attendance',
      update: 'Personal.children.Personal Attendance.children.Update Personal Attendance',
      delete: 'Personal.children.Personal Attendance.children.Delete Personal Attendance',
      view: 'Personal.children.Personal Attendance.children.View Personal Attendance',
    },
  },
  // request_asset: {
  //   name: 'Personal.children.Personal Asset',
  //   read: 'Personal.children.Personal Asset.read',
  //   write: 'Personal.children.Personal Asset.write',
  //   permissions: {
  //     create: 'Personal.children.Personal Asset.children.Create Personal Asset',
  //     update: 'Personal.children.Personal Asset.children.Update Personal Asset',
  //     delete: 'Personal.children.Personal Asset.children.Delete Personal Asset',
  //     view: 'Personal.children.Personal Asset.children.View Personal Asset',
  //   },
  // },
  goals_daily: {
    name: 'Personal.children.Personal Daily Goal',
    read: 'Personal.children.Personal Daily Goal.read',
    write: 'Personal.children.Personal Daily Goal.write',
    permissions: {
      create: 'Personal.children.Personal Daily Goal.children.Create Personal Daily Goal',
      update: 'Personal.children.Personal Daily Goal.children.Update Personal Daily Goal',
      delete: 'Personal.children.Personal Daily Goal.children.Delete Personal Daily Goal',
      view: 'Personal.children.Personal Daily Goal.children.View Personal Daily Goal',
    },
  },

  insurance_product: {
    name: 'Insurance Product',
    read: 'Insurance Product.read',
    write: 'Insurance Product.write',
    permissions: {
      create: 'Insurance Product.children.Create Insurance Product',
      update: 'Insurance Product.children.Update Insurance Product',
      delete: 'Insurance Product.children.Delete Insurance Product',
      view: 'Insurance Product.children.View Insurance Product',
    },
  },

  assets_type: {
    name: 'Asset Type',
    read: 'Asset Type.read',
    write: 'Asset Type.write',
    permissions: {
      create: 'Asset Type.children.Create Asset Type',
      update: 'Asset Type.children.Update Asset Type',
      delete: 'Asset Type.children.Delete Asset Type',
      view: 'Asset Type.children.View Asset Type',
    },
  },
  asset_request: {
    name: 'Asset Request',
    read: 'Asset Request.read',
    write: 'Asset Request.write',
    permissions: {
      create: 'Asset Request.children.Create Asset Request',
      update: 'Asset Request.children.Update Asset Request',
      delete: 'Asset Request.children.Delete Asset Request',
      view: 'Asset Request.children.View Asset Request',
    },
  },
};
