/** external deps */
import React, { useEffect, useState } from 'react';

/** internal deps */
import { ContentHeader, TableComponent, TabsComponent, Toast } from 'components';
import { getQueryParams, rowCommonClass } from 'utils/common';
import { getRaisedTickets, updateMyTicket } from 'service/api-service';
import { TableStatus } from 'components/table/TableStatus';
import { elementData } from 'elements';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import { NOTIFICATION_MSG } from 'constants';
import { data } from 'jquery';

const tabs = [
  {
    key: 'pending',
    // id: elementData.company.permissions.view,
    label: `Pending`,
    Component: () => null,
  },
  {
    key: 'resolve',
    // id: elementData.expense_type.permissions.view,
    label: `Resolve`,
    Component: () => null,
  },

  {
    key: 'in_discussion',
    // id: elementData.expense_type.permissions.view,
    label: `In-Discussion`,
    Component: () => null,
  },
];

export const TICKET_STATUS = {
  RESOLVE: 'resolve',
  IN_DISCUSSION: 'in_discussion',
  PENDING: 'pending',
};

export const TicketStatusList = [
  {
    label: <span className='w-full text-left'>Pending</span>,
    key: TICKET_STATUS.PENDING,
  },
  {
    label: <span className='w-full text-left'>Resolve</span>,
    key: TICKET_STATUS.RESOLVE,
  },

  {
    label: <span className='w-full text-left'>In-Discussion</span>,
    key: TICKET_STATUS.IN_DISCUSSION,
  },
];

const Tickets = ({ permissions }) => {
  /* states  */
  const { tab } = getQueryParams();
  const [pageNum, setPageNum] = useState(1);
  const [total, setTotal] = useState(0);
  const [ticketsData, setTicketsData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [key, setActiveTabKey] = useState('pending');

  const onStatusChange = async (e, record) => {
    try {
      const status = e?.key;

      if (String(status).toLowerCase() === String(record?.status).toLowerCase()) return;
      setLoading(true);
      // console.info('first', record?.id, 'data', status);
      const resp = await updateMyTicket({ id: record?.id, data: { status } });

      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, 'Ticket Updated Successfully');
        fetchTickets();
      } else Toast('error', NOTIFICATION_MSG.error, 'Failed To Updated Ticket');
    } catch (error) {}
    setLoading(false);
  };

  const columns = [
    {
      key: 'ticket_id',
      title: 'Ticket ID',
      dataIndex: 'ticket_id',
    },
    {
      key: 'created_by',
      title: 'Raised By',
      dataIndex: 'created_by',
      render: (created_by) => <p className='text-black'>{created_by.name || '\u2014'}</p>,
    },
    {
      key: 'created',
      title: 'Date',
      dataIndex: 'created',
      render: (created) => {
        return <span className='text-black'>{dayjs(created)?.format('MMM DD')}</span>;
      },
    },
    {
      key: 'reason',
      title: 'Reason',
      dataIndex: 'reason',
      // render: (reason) => <p className='text-black capitalize'>{reason || '\u2014'}</p>,
      render: (reason) => (
        <div className='truncate'>
          <Tooltip title={reason}>{reason || '\u2014'}</Tooltip>
        </div>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status, record) => {
        return (
          <TableStatus
            permissions={permissions}
            id={elementData.manageRecruitment.permissions.update}
            onChange={onStatusChange}
            record={record}
            status={status}
            items={TicketStatusList}
          />
        );
      },
    },
  ];

  const fetchTickets = async () => {
    setLoading(true);
    const resp = await getRaisedTickets({ query: searchQuery, page: pageNum, status: key });
    if (resp.data.status) {
      setTicketsData(resp.data.data.result);
      setPageNum(resp.data.data.current_page);
      setTotal(resp.data.data.total_page);
    }
    setLoading(false);
  };

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    // fetchTickets({ query: query, page: pageNum });
  };

  const handlePagination = (pageNum) => {
    if (pageNum?.current) {
      setPageNum(pageNum?.current);
      // fetchTickets({ query: searchQuery, page: pageNum?.current, });
    }
  };

  useEffect(() => {
    fetchTickets();
  }, [key, pageNum, searchQuery]);

  return (
    <div className='grid gap-0'>
      <ContentHeader
        title={<p className='font-semibold leading-normal text-s'>Tickets</p>}
        permissions={permissions}
        onChange={handleOnChange}
      />
      <TabsComponent
        items={tabs}
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
        url='/hr/tickets?'
      />
      <div className='bg-white p-2 rounded-md'>
        <TableComponent
          getRowClassName={rowCommonClass}
          loading={loading}
          columns={columns}
          dataSource={ticketsData}
          total={total}
          onChange={handlePagination}
          defaultPage={pageNum}
        />
      </div>
    </div>
  );
};

export default Tickets;
