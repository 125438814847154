/** external deps */
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { Skeleton, Checkbox, Tooltip } from 'antd';
/** internal deps */
import { selectStyles, DropdownIndicator } from './data';
import { InfoCircleOutlined } from '@ant-design/icons';

const SELECT_ALL_OPTION = {
  value: 'select-all',
  label: 'All',
};

const CheckBoxComponent = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  checkbox,
  values,
  ...rest
}) => {
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={innerProps}
    >
      <div className='flex justify-between'>
        {children}
        {checkbox && <Checkbox checked={isSelected || values?.includes(rest.value)} />}
      </div>
    </components.Option>
  );
};

/**
 *  Custom Select Input with some advanced options
 * @param {{
 * form: Form instance of formik,
 * field: {name:string,value:string,onChange: Formik on change}
 * options: [{label:string,value:sting}]
 * defaultValue: string
 * menuPlacement: auto | top | bottom
 * setObject:boolean(It check that we have to set whole object to the field or only value)
 * }[]} rawData
 */

const SelectSearchWithName = ({
  form,
  field,
  options = [],
  defaultValue,
  menuPlacement = 'auto',
  dependantFetchOptions,
  dependency = [],
  divClasses,
  checkbox,
  hideSelectedOptions = true,
  closeMenuOnSelect = true,
  setObject = false,
  ...props
}) => {
  const [animatedComponents] = useState(makeAnimated());
  const [apiOptions, setOptions] = useState([]);
  const [CheckOptions, setCheckOptions] = useState('something');
  const [loading, setLoading] = useState(false);

  const isSelectAllSelected = (e) => {
    const opts = dependantFetchOptions ? apiOptions : options;
    if (!e) return false;
    return e?.length === (opts?.length || opts?.length);
  };

  const getValue = () => {
    if (props?.isMulti && isSelectAllSelected(form?.values[field?.name])) {
      return SELECT_ALL_OPTION;
    } else {
      const keys = field.name.split('.');
      const values = keys?.length > 1 ? form?.values[keys[0]] : form?.values[field?.name];
      const finalValue =
        keys?.length > 1 ? values[Number(keys[1])]?.[keys[2]] : form?.values[field?.name];
      let _options = options?.length ? options : apiOptions;
      if (props?.customChange || props?.customOnChange) {
        return _options.find(
          (option) =>
            option?.id === values?.value ||
            option?.label === values?.label ||
            option?.label === values ||
            option?.id === values,
        );
      } else if (props?.customOnChange) {
        return;
        // return _options.filter(
        //   (option) => values?.includes(option.value) || values?.includes(option.label)
        // );
      } else if (checkbox || props?.isMulti) {
        return _options.filter(
          (option) => values?.includes(option.value) || values?.includes(option.label),
        );
      } else if (typeof finalValue === 'object') {
        return finalValue;
      } else if (finalValue) {
        return _options.filter((option) => finalValue === option.id || finalValue === option.label);
      } else {
        return _options.filter(
          (option) =>
            values === option.id ||
            values === option.label ||
            values?.name === option.id ||
            values?.name === option.label,
        );
      }
    }
  };

  const _onChange = (e, actionMeta) => {
    if (form?.setFieldValue) {
      if (checkbox) {
        const { action, option, removedValue } = actionMeta;
        let values = [];
        if (action === 'select-option' && option.value === SELECT_ALL_OPTION.value) {
          values = options?.length ? options : apiOptions;
        } else if (
          (action === 'deselect-option' && option.value === SELECT_ALL_OPTION.value) ||
          (action === 'remove-value' && removedValue.value === SELECT_ALL_OPTION.value)
        ) {
          values = [];
        } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected(e)) {
          let _options = options?.length ? options : apiOptions;
          values = _options.filter(({ value }) => value !== option.value);
        } else {
          values = e.filter((option) => option.value !== SELECT_ALL_OPTION.value);
        }
        if (props?.customOnChange) {
          props?.customOnChange(field.name, e, form);
          return form?.setFieldValue(field.name, values);
        }
        return form?.setFieldValue(
          field.name,
          values.map((e) => e.id),
        );
      }
      if (props?.isMulti) {
        return form?.setFieldValue(
          field.name,
          e?.map((e) => e.id),
        );
      }
      if (props?.customChange) {
        props?.customChange(field.name, e, form);
        return form?.setFieldValue(field.name, e?.id);
      }

      if (props?.customOnChange) {
        console.info('herere', props.valIndex);
        props?.customOnChange(field.name, e, form);
        return form?.setFieldValue(field.name, e[props.valIndex || 'id']);
      }
      const finalValueToSet = setObject ? e : e?.id;
      return form?.setFieldValue(field.name, finalValueToSet);
    } else if (props?.customOnChange) {
      props?.customOnChange(field.name, e, form);
      return e;
    }
  };

  const customFilter = (searchText) => {
    if (props?.paginatedSearch && searchText) {
      setLoading(true);
      props?.fetchOptions({ query: searchText }).then((resp) => {
        if (resp && resp?.data && resp?.data.data) {
          setOptions(
            resp?.data?.data?.result.map((i) => {
              return {
                ...i,
                label: (
                  <p className='capitalize'> {i?.name || i?.label || i[props?.displayLabel]}</p>
                ),
                value: i?.name,
              };
            }),
          );
          setCheckOptions(
            resp?.data?.data?.result.map((i) => {
              return {
                ...i,
                label: (
                  <p className='capitalize'>{i?.name || i?.label || i[props?.displayLabel]}</p>
                ),
                value: i?.name,
              };
            }),
          );
        }
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props?.paginatedSearch && !options?.length && props?.fetchOptions) {
      setLoading(true);
      props?.fetchOptions().then((resp) => {
        if (resp && resp?.data && resp?.data.status) {
          setOptions(
            resp?.data?.data?.result.map((i) => {
              return {
                ...i,
                label: (
                  <p className='capitalize'> {i?.name || i?.label || i[props?.displayLabel]}</p>
                ),
                value: i?.name,
              };
            }),
          );
        }
        setCheckOptions(
          resp?.data?.data?.result.map((i) => {
            return {
              ...i,
              label: <p className='capitalize'>{i?.name || i?.label || i[props?.displayLabel]}</p>,
              value: i?.name,
            };
          }),
        );
        setLoading(false);
      });
    } else if (!options?.length && props?.fetchOptions) {
      setLoading(true);
      props?.fetchOptions().then((resp) => {
        if (resp && resp?.data && resp?.data.status) {
          setOptions(
            resp?.data?.data.map((i) => {
              return {
                ...i,
                label: <p className='capitalize'>{i?.name || i?.label}</p>,
                value: i?.name,
              };
            }),
          );
        }
        setCheckOptions(
          resp?.data?.data?.map((i) => {
            return {
              ...i,
              label: <p className='capitalize'>{i?.name || i?.label}</p>,
              value: i?.name,
            };
          }),
        );
        setLoading(false);
      });
    }
  }, []);

  useEffect(
    () => {
      if (dependantFetchOptions && typeof dependantFetchOptions === 'function') {
        setLoading(true);
        dependantFetchOptions(form).then((resp) => {
          if (resp?.length) {
            setOptions(
              resp.map((i) => {
                return {
                  ...i,
                  label: <p className='capitalize'>{i?.name}</p>,
                  value: i?.name,
                };
              }),
            );
          }
          setLoading(false);
        });
      }
    },
    dependency.map((item) => form?.values?.[item]),
  );

  useEffect(() => {
    if (defaultValue) {
      form?.setFieldValue(field?.name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <div
      className={
        props?.subTitle && `flex justify-between  col-span-full border-t border-[#D1EAF9] pt-4`
      }
    >
      {props?.subTitle && props?.subTitle}
      <div
        id='select-list-item'
        className={`group ${divClasses}  ${props?.disabled ? 'cursor-not-allowed' : ''} ${
          checkbox && 'hide-cross-button'
        }`}
      >
        <div className='flex pr-2 items-center justify-between'>
          <label
            className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xxs text-black ${
              props?.required ? 'required' : ''
            }`}
          >
            {props?.label}
          </label>
          {props?.tooltip && (
            <Tooltip title={props.tooltip}>
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </div>
        <div className='relative '>
          {loading ? (
            <Skeleton.Input active={true} size='large' />
          ) : (
            <Select
              options={
                options?.length
                  ? checkbox
                    ? [SELECT_ALL_OPTION, ...options]
                    : options
                  : checkbox
                  ? [SELECT_ALL_OPTION, ...apiOptions]
                  : apiOptions
              }
              value={getValue()}
              name={field?.name}
              isDisabled={props?.disabled}
              className='text-xl md:text-xxs  '
              isLoading={props?.loading || loading}
              classNamePrefix='select'
              isClearable={props?.isClearable}
              isSearchable={props?.isSearchable}
              defaultValue={() => {
                if (form?.initialValues[field?.name]) {
                  return (options?.length > 0 ? options : apiOptions).find(
                    (item) =>
                      item?.value === form?.initialValues[field?.name] ||
                      item?.label === form?.initialValues[field?.name] ||
                      item?.value === form?.initialValues[field?.name]?.name ||
                      item?.label === form?.initialValues[field?.name]?.name,
                  );
                } else if (dependantFetchOptions && defaultValue) {
                  return apiOptions.find(
                    (item) => item?.value === defaultValue || item?.label === defaultValue,
                  );
                }
                return options.find(
                  (item) => item?.value === defaultValue || item?.label === defaultValue,
                );
              }}
              onInputChange={customFilter}
              placeholder={props?.placeholder}
              onChange={_onChange}
              menuPlacement={menuPlacement}
              isMulti={props?.isMulti}
              styles={selectStyles}
              noOptionsMessage={() =>
                props?.fetchOptions && props?.required ? (
                  <span className='text-red-90'>Failed to load data please reload</span>
                ) : (
                  'No options'
                )
              }
              closeMenuOnSelect={closeMenuOnSelect}
              components={{
                animatedComponents,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option: (props) => (
                  <CheckBoxComponent
                    {...props}
                    checkbox={checkbox}
                    values={form?.values[field?.name] || []}
                  />
                ),
              }}
            />
          )}
          <div className={`absolute right-0 flex items-center cursor-pointer`}>
            {props?.extraRightRender ? props?.extraRightRender() : null}
          </div>
          {CheckOptions?.length === 0 && (
            <div
              className={`absolute bg-white ${
                props?.extraRightRender ? 'max-w-[230px]' : ''
              } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
            >
              {props?.emptyOptions}
            </div>
          )}
          {props?.remarks && (
            <div
              className={`absolute bg-white ${
                props?.extraRightRender ? 'max-w-[230px]' : ''
              } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
            >
              {props?.remarks}
            </div>
          )}
          {form?.errors[field?.name] && form?.touched[field?.name] && (
            <div
              className={`absolute bg-white ${
                props?.extraRightRender ? 'max-w-[230px]' : ''
              } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
            >
              {form?.errors[field?.name]}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectSearchWithName;
