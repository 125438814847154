import { Tooltip } from 'antd';

export const breadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Policy',
    path: '',
    target: '_blank',
  },
];

export const policyColumns = [
  {
    title: 'Policy No.',
    dataIndex: 'p_number',
    key: 'p_number',
    id: 'p_number',
    render: (data) => data || '\u2014',
  },
  {
    title: 'Product',
    dataIndex: 'product_type',
    key: 'product',
    id: 'product',
    render: (data) => data?.name || '\u2014',
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    id: 'customer',
    render: (data) => data?.name || data?.corp_name || '\u2014',
  },
  {
    title: 'Insurer',
    dataIndex: 'insurer',
    key: 'insurer',
    id: 'insurer',
    render: (data) => data?.name || '\u2014',
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    id: 'duration',
    render: (data) => {
      if (data === '12') return 'Year';
      else if (data === '24') return 'Bi-annual';
      else if (data === '36') return 'Tri-annual';
      else return '\u2014';
    },
  },
  {
    title: 'Expire Date',
    dataIndex: 'exp_date',
    key: 'exp_date',
    id: 'exp_date',
  },
  {
    title: 'Sell By',
    dataIndex: 'sell_by',
    key: 'sell_by',
    id: 'sell_by',
    width: 180,
    render: (type, data) =>
      data?.sell_obj?.name && type ? data?.sell_obj?.name + '(' + type + ')' : '\u2014',
    displayFilter: ['renewable', 'all'],
  },
  {
    title: 'Attach Policy',
    dataIndex: 'attach_poilcy',
    key: 'attach_poilcy',
    id: 'attach_poilcy',
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact',
    key: 'contact',
    id: 'contact',
    render: (data, record) => data?.name || '\u2014',
  },
  {
    title: 'Contact Number',
    dataIndex: 'contact_no',
    key: 'contact_no',
    id: 'contact_no',
    render: (data, record) => data?.contact_no || '\u2014',
  },
  {
    title: 'Email Address',
    dataIndex: 'email_address',
    key: 'email_address',
    id: 'email_address',
    render: (data, record) => record?.customer?.email_address || '\u2014',
  },
  {
    title: 'Branch Details',
    dataIndex: 'branch',
    key: 'branch',
    id: 'branch',
    render: (data, record) => record?.branch?.name || '\u2014',
  },
  {
    title: 'Business Nature',
    dataIndex: 'bs_nature',
    key: 'bs_nature',
    id: 'bs_nature',
    width: 170,
    render: (data, record) => {
      return (
        <div className='truncate  max-w-20'>
          <Tooltip title={record?.customer?.nature_of_business || ''}>
            {record?.customer?.nature_of_business || '\u2014'}
          </Tooltip>
        </div>
      );
    },
  },
  {
    title: 'Issue Date',
    dataIndex: 'issue_date',
    key: 'issue_date',
    id: 'issue_date',
    render: (data, record) => record?.issue_date || '\u2014',
  },
  {
    title: 'Commence Date',
    dataIndex: 'comm_date',
    key: 'comm_date',
    id: 'comm_date',
    render: (data, record) => record?.comm_date || '\u2014',
  },

  {
    title: 'Category of Insurance',
    dataIndex: 'insurance_category',
    key: 'insurance_category',
    id: 'insurance_category',
    render: (data, record) => record?.category?.name || '\u2014',
  },
  {
    title: 'Product Type',
    dataIndex: 'product_type',
    key: 'product_type',
    id: 'product_type',
    render: (data, record) => record?.product_type?.name || '\u2014',
  },
  {
    title: 'Assign To ',
    dataIndex: 'employee',
    key: 'employee',
    id: 'employee',
    render: (data, record) => record?.employee?.name || '\u2014',
  },
  {
    title: 'Premium Amount',
    dataIndex: 'premium',
    key: 'premium',
    id: 'premium',
    render: (data, record) => record?.premium || '\u2014',
  },
  {
    title: 'Sum Insured',
    dataIndex: 'sum_insured',
    key: 'sum_insured',
    id: 'sum_insured',
    render: (data, record) => record?.sum_insured || '\u2014',
  },
  {
    title: 'NCB Amount',
    dataIndex: 'ncb',
    key: 'ncb',
    id: 'ncb',
    render: (data, record) => record?.ncb || '\u2014',
  },
  {
    title: 'Gross Premium',
    dataIndex: 'g_premium',
    key: 'g_premium',
    id: 'g_premium',
    render: (data, record) => record?.gst_pre || '\u2014',
  },
  {
    title: 'GST Premium',
    dataIndex: 'gst_pre',
    key: 'gst_pre',
    id: 'gst_pre',
    // render:(data,record) => record?.customer?.email_address ||  '\u2014',
  },
  {
    title: 'Other Fees',
    dataIndex: 'other_fees',
    key: 'other_fees',
    id: 'other_fees',
    // render:(data,record) => record?.customer?.email_address ||  '\u2014',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    id: 'address',
    render: (data, record) => record?.address?.address || '\u2014',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    id: 'state',
    render: (data, record) => record?.address?.state || '\u2014',
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    id: 'city',
    render: (data, record) => record?.address?.city || '\u2014',
  },
  {
    title: 'Postcode',
    dataIndex: 'pincode',
    key: 'pincode',
    id: 'pincode',
    render: (data, record) => record?.address?.pincode || '\u2014',
  },

  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    id: 'country',
    render: (data, record) => record?.address?.country || '\u2014',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    id: 'description',
    render: (data, record) => (
      <Tooltip title={record?.description || ''} className='truncate'>
        {record?.description || '\u2014'}
      </Tooltip>
    ),
  },
];
