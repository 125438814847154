/** external deps */
import React, { useEffect, useState, useContext } from 'react';
import { Space } from 'antd';
import Fuse from 'fuse.js';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
  Toast,
} from 'components';
import { addBranchFields, breadcrumbObj } from './data';
import { addBranch, deleteBranch, updateBranch } from 'service/api-service';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { deleteEmptyValues, formatAddress, getEditedFields, prepareFormFieldsData } from 'utils';
import { Context } from 'store/context';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { NOTIFICATION_MSG } from 'constants';
import { rowCommonClass } from 'utils/common';

const Branches = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [selectedBranch, setSelectedBranch] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredBranch, setFilteredBranches] = useState([]);
  /* store  */
  const {
    branches: { list: branches, updateBranches, loading: branchLoading },
  } = useContext(Context);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addBranchFields,
    defaultValues: { country: 'IN' },
  });

  const columns = [
    {
      key: 'name',
      title: 'Branch Name',
      dataIndex: 'name',
    },
    {
      key: 'address',
      title: 'Address',
      dataIndex: 'address_id',
      width: 550,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <div className='flex gap-2 justify-end'>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.branch.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.branch.permissions.delete}
            onClick={() => {
              setSelectedBranch(record);
              toggleDeleteBranch();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
        </div>
      ),
    },
  ];

  /* funcs */
  const onAddBranch = async (data) => {
    const finalPayload = {
      ...data,
      address: {
        city: data?.city,
        state: data?.state,
        country: data?.country,
        pincode: data?.pincode,
        address: data?.address,
      },
    };
    const resp = await addBranch({ data: finalPayload });
    if (resp?.data?.status) {
      updateBranches();
      setAddModal(false);
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  const onDeleteBranch = async () => {
    const id = selectedBranch?.id;
    if (!id) return;
    setLoading(true);
    const resp = await deleteBranch({ id, data: { deleted: true } });
    if (resp?.data?.status) {
      updateBranches();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Branch deleted successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteBranch();
    setLoading(false);
  };

  const parsePayload = (edited) => {
    let finalPayload = {
      address: {},
    };

    Object.keys(edited).forEach((item) => {
      if (item === 'name') finalPayload['name'] = edited?.name;
      if (item === 'b_hr') finalPayload['b_hr'] = edited?.b_hr;

      //address fields
      if (item === 'city') finalPayload['address']['city'] = edited?.city;
      if (item === 'state') finalPayload['address']['state'] = edited?.state;
      if (item === 'country') finalPayload['address']['country'] = edited?.country;
      if (item === 'address') finalPayload['address']['address'] = edited?.address;
      if (item === 'pincode') finalPayload['address']['pincode'] = edited?.pincode;
    });
    return deleteEmptyValues(finalPayload, 1);
  };

  const onEditBranch = async (data) => {
    setLoading(true);
    const editFields = getEditedFields({ initialValues: editInitials, updatedData: data });

    if (Object.keys(editFields).length) {
      const finalPayload = parsePayload(editFields);
      const resp = await updateBranch({
        id: selectedBranch?.id,
        data: finalPayload,
      });
      toggleAddBranch();
      if (resp?.data?.status) {
        updateBranches();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  const toggleAddBranch = () => {
    setInitials(undefined);
    setAddModal((pre) => {
      return !pre;
    });
  };

  const toggleDeleteBranch = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    updateBranches({ query });
    // const fuse = new Fuse(tableData, {
    //   keys: ['name', 'address_id'],
    //   threshold: 0.2,
    // }).search(e.target.value);
    // setFilteredBranches(fuse.map((i) => i.item));
  };

  const onEditPress = (record) => {
    const filtered = branches.find((branch) => branch.id === record.id);

    const finalInitials = {
      name: filtered?.name,
      city: filtered?.address_id?.city,
      address: filtered?.address_id?.address,
      country: filtered?.address_id?.country,
      state: filtered?.address_id?.state,
      pincode: filtered?.address_id?.pincode,
      b_hr: filtered?.b_hr?.name,
    };
    setInitials(finalInitials);
    setSelectedBranch(record);
    setAddModal(true);
  };

  useEffect(() => {
    if (branches.length) {
      setTableData(
        branches.map((branch) => {
          return {
            ...branch,
            address_id: formatAddress({ addressObject: branch?.address_id }),
            key: branch.id,
          };
        }),
      );
    }
  }, [branches]);

  useEffect(() => {
    if (!branches.length) updateBranches();
  }, [searchQuery]);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        title={<p className='font-semibold text-s'>Branch Listing</p>}
        // data={breadcrumbObj}
        btnText='+ Branch'
        buttonId={elementData?.branch?.permissions?.create}
        onClick={toggleAddBranch}
      />
      <TableComponent
        loading={branchLoading || loading}
        columns={columns}
        dataSource={tableData}
        getRowClassName={rowCommonClass}
      />

      {/* add branch modal */}
      <CustomModal
        title={editInitials ? 'Edit Branch' : 'Add Branch'}
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddBranch}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10'>
            {editInitials
              ? 'Edit branch details'
              : 'To add a new branch, enter the details of the branch in the input field below.'}
          </span>
          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditBranch : onAddBranch}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddBranch();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.branch.permissions.update
                        : elementData.branch.permissions.create
                    }
                    text={editInitials ? 'Update Branch' : 'Add Branch'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* delete branch confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteBranch}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the branch details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteBranch}
            />
            <Button
              text='Delete Branch'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteBranch}
              loading={loading || branchLoading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(Branches);
