/** external deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Tabs, Modal } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
  Toast,
  MultiEmail,
  User,
} from 'components';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { DELETE, EDIT, Trash, View } from 'assets/icons';
import { breadcrumb, getPosManagementFields, tabsItem } from './data';
import { debounce, formatAddress, getEditedFields, prepareFormFieldsData } from 'utils';
import { addPos, deletePos, editPos, getPosList } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { useStore } from 'store/context';
import useBankDetails from 'data/form-data/ifsc';
import { rowCommonClass } from 'utils/common';

const maxDate = dayjs().subtract(18, 'years');

const PosManagement = (props = {}) => {
  const permissions = props?.permissions || [];
  const navigate = useNavigate();
  /** state */
  const [showAddModal, setAddModal] = useState(false);
  const [tabKey, setTabKey] = useState('registered');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editInitials, seteditInitials] = useState();
  const [tableData, setTableData] = useState([]);
  const [posId, setPosId] = useState();
  const [selectedPosManagement, setSelectedPosManagement] = useState({});
  const [total_page, setTotalPage] = useState();
  const [page, setPageNum] = useState(1);
  const [pincodeError, setPinCodeError] = useState();

  const [ifscCode, setIfscCode] = useState(editInitials?.ifsc_code);
  const { bankDetails, error } = useBankDetails(ifscCode);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: getPosManagementFields({
      setIfscCode,
      bankDetails,
      error,
      setPinCodeError,
      pincodeError,
    }),
    fieldsToExclude: ['checkbox'],
  });
  const [opened, dispatch] = useStore((state) => state.dialerOpened);

  /** functions */

  const toggleDialer = useCallback(
    (e, contact) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (opened) {
        dispatch({ type: 'DIALER_CLOSE' });
      } else {
        dispatch({ type: 'DIALER_OPEN', contact });
      }
    },
    [opened, dispatch],
  );

  const registerAgent = useCallback(async (record) => {
    setLoading(true);
    Modal.confirm({
      title: 'Confirm to Activate',
      okText: 'Activate',
      onOk: async () => {
        const id = record?.id;
        if (!id) {
          return null;
        }
        const payload = {
          is_active: true,
        };
        const resp = await editPos({ id, data: payload });
        if (resp?.data?.status) {
          Toast(
            'success',
            NOTIFICATION_MSG.success,
            resp?.data?.msg || 'POS registered successfully',
          );
          onchangeTab('registered');
          fetchData();
        } else Toast('error', NOTIFICATION_MSG.error, 'Please try again');
      },
    });
    setLoading(false);
  }, []);

  const verifyAgent = useCallback(async (record) => {
    setLoading(true);
    Modal.confirm({
      style: { padding: 10, backgroundColor: '#FFFFFF', borderRadius: 10 },
      title: 'Confirm to Verify',
      okText: 'Verify',
      onOk: async () => {
        const id = record?.id;
        if (!id) {
          return null;
        }
        const payload = {
          con_status: 'approved',
        };
        const resp = await editPos({ id, data: payload });
        if (resp?.data?.status) {
          Toast(
            'success',
            NOTIFICATION_MSG.success,
            resp?.data?.msg || 'POS registered successfully',
          );
          onchangeTab('registered');
          fetchData();
        } else Toast('error', NOTIFICATION_MSG.error, 'Please try again');
      },
    });
    setLoading(false);
  }, []);

  const handleView = useCallback((record) => {
    navigate('/management/view', { state: { initialValues: record } });
  }, []);

  const columns = [
    {
      key: 'image',
      title: 'Name',
      dataIndex: 'image',
      render: (image, record) => <User name={record?.name} image={image} />,
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      render: (data) =>
        (
          <MultiEmail
            text={data}
            button={false}
            titleClasses={'underline cursor-pointer'}
            imageRef='invoice-slip'
            emailData={data}
          />
        ) || '\u2014',
    },
    {
      key: 'contact',
      title: 'Contact',
      dataIndex: 'contact',
      render: (data) =>
        data ? (
          <div className='cursor-pointer underline' onClick={(e) => toggleDialer(e, data)}>
            {data}
          </div>
        ) : (
          '\u2014'
        ),
    },
    {
      key: 'address',
      title: 'Address',
      dataIndex: 'address',
      render: (data) => formatAddress({ addressObject: data }),
    },
    {
      title: tabKey === 'request' ? 'Activation' : '',
      key: 'activate',
      render: (_, record) =>
        tabKey === 'request' ? (
          <IconButton
            permissions={permissions}
            id={elementData.pos.permissions.update}
            onClick={registerAgent.bind(this, record)}
            className='text-green-70 border border-green-70 px-4 rounded-md'
          >
            Activate
          </IconButton>
        ) : null,
      width: tabKey === 'request' ? 150 : 1,
    },
    {
      title: tabKey === 'submitted' ? 'Verification' : '',
      key: 'activate',
      render: (_, record) =>
        tabKey === 'submitted' ? (
          <IconButton
            permissions={permissions}
            id={elementData.pos.permissions.update}
            onClick={verifyAgent.bind(this, record)}
            className='text-green-70 border border-green-70 px-4 rounded-md'
          >
            Verify
          </IconButton>
        ) : null,
      width: tabKey === 'submitted' ? 150 : 1,
    },
    {
      title: 'Actions',
      key: 'action',
      // align: 'center',
      width: tabKey !== 'registered' && 250,
      render: (_, record) => (
        <div className='flex gap-2 '>
          {tabKey !== 'registered' && (
            <>
              <IconButton
                title='Aknowledge'
                permissions={permissions}
                className='group disabled:cursor-not-allowed border border-red-60 rounded-full w-6 h-6 '
                id={elementData.pos.permissions.view}
              >
                <MultiEmail
                  text={
                    <MailOutlined className='text-red-60 group-disabled:text-grey flex items-start justify-center' />
                  }
                  button={false}
                  emailData={record?.email}
                  mailSubject='Document Verification'
                  summary={`Hello ${record?.name},\nHope you are doing great. We are just reminding you about your documents verification.If you have any questions, please feel free to reach out to us.`}
                />
              </IconButton>
            </>
          )}
          <IconButton
            title='View'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.employee.permissions.view}
            onClick={() => handleView(record)}
          >
            <View svgClass='stroke-primary group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.pos.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.pos.permissions.delete}
            onClick={() => deletepos(record)}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
        </div>
      ),
    },
  ];

  const fetchData = async ({ page = 1, query = '' } = {}) => {
    setLoading(true);
    const resp = await getPosList({
      page,
      query,
      is_active: tabKey === 'request' ? 'False' : tabKey === 'registered' ? 'True' : '',
      status: tabKey === 'submitted' ? 'submitted' : '',
    });
    if (resp?.data?.data) {
      setTableData(resp?.data?.data?.result);
      setPageNum(resp?.data?.data?.current_page);
      setTotalPage(resp?.data?.data?.total_page);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onAddPosManagement = async (data) => {
    setLoading(true);
    const address = {
      city: data?.city || '',
      state: data?.state || '',
      country: data?.country || '',
      pincode: data?.pincode || '',
      address: data?.address || '',
    };
    const finalPayload = {
      ...data,
      address,
      pos_agent: true,
      docs: [
        {
          name: 'aadhar_number',
          file: data?.aadhar_number || null,
        },
        {
          name: 'pan_number',
          file: data?.pan_number || null,
        },
        {
          name: 'bank_statement',
          file: data?.bank_statement || null,
        },
        {
          name: 'educational_proof',
          file: data?.educational_proof || null,
        },
      ],
    };
    const resp = await addPos({ data: finalPayload });
    if (resp?.data?.status) {
      fetchData();
      toggleAddPosManagement();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      const head = resp?.data?.msg;
      Object.keys(head).forEach((key) => {
        Toast('error', NOTIFICATION_MSG.error, `${key} : ${head[key]}` || 'Please try again');
      });
    }
    setLoading(false);
  };

  const onDeletePosManagement = async () => {
    setLoading(true);
    const resp = await deletePos({ id: posId, data: { deleted: true } });
    if (resp?.data?.status) {
      fetchData();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
    toggleDeletePosManagement();
  };

  const onchangeTab = useCallback((key) => {
    setTabKey(key);
  }, []);

  const onEditPress = (record) => {
    const finalInitials = {
      ...record,
      address: record?.address?.address,
      city: record?.address?.city,
      state: record?.address?.state,
      country: record?.address?.country,
      pincode: record?.address?.pincode,
    };
    record?.docs.forEach((item) => {
      finalInitials[item.name] = item.file;
    });
    seteditInitials(finalInitials);
    setIfscCode(finalInitials?.ifsc_code);
    setSelectedPosManagement(record);
    setAddModal(true);
  };
  const onEditPosManagement = async (data) => {
    setLoading(true);
    const editFields = getEditedFields({
      initialValues: editInitials,
      updatedData: data,
    });
    let newpayload = { ...editFields };
    if (
      editFields?.bank_statement ||
      editFields?.aadhar_number ||
      editFields?.pan_number ||
      editFields?.educational_proof
    ) {
      newpayload = {
        ...editFields,
        docs: [
          editFields.aadhar_number && {
            id: editInitials?.docs[3].id,
            name: 'aadhar_number',
            file: editFields?.aadhar_number,
          },
          editFields.pan_number && {
            id: editInitials?.docs[2].id,
            name: 'pan_number',
            file: editFields?.pan_number,
          },
          editFields.bank_statement && {
            id: editInitials?.docs[1].id,
            name: 'bank_statement',
            file: editFields?.bank_statement,
          },
          editFields.educational_proof && {
            id: editInitials?.docs[0].id,
            name: 'educational_proof',
            file: editFields?.educational_proof,
          },
        ].filter(Boolean),
      };
    }
    if (Object.keys(editFields).length) {
      const resp = await editPos({
        id: selectedPosManagement?.id,
        data: newpayload,
      });

      if (resp?.data?.status) {
        fetchData();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        toggleAddPosManagement();
      } else {
        const head = resp?.data?.msg;
        Object.keys(head).forEach((key) => {
          Toast('error', NOTIFICATION_MSG.error, `${key} : ${head[key]}` || 'Please try again');
        });
      }
    } else {
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
    setLoading(false);
  };

  const deletepos = (record) => {
    setPosId(record?.id);
    toggleDeletePosManagement();
  };

  const toggleAddPosManagement = () => {
    setIfscCode(undefined);
    seteditInitials(undefined);
    setAddModal((pre) => !pre);
  };

  const toggleDeletePosManagement = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = debounce(async (e) => {
    const query = e?.target?.value;
    fetchData({ query });
  }, 700);

  const handlePagination = (pageNum) => {
    if (pageNum?.current) fetchData({ page: pageNum?.current });
  };

  useEffect(() => {
    fetchData();
  }, [tabKey]);

  const renderTabBar = (props, DefaultTabBar) => {
    return (
      <div className={` border border-primary-60 rounded-md w-fit flex  `}>
        {tabsItem &&
          tabsItem.map(({ key, label }) => {
            return (
              <div
                className={
                  tabKey === key
                    ? `bg-primary-60 cursor-pointer px-2 py-1  rounded font-semibold leading-normal text-xxs text-white`
                    : `bg-transparent cursor-pointer  px-2 py-1 mr-1 text-black rounded font-normal leading-normal text-xxs`
                }
                onClick={() => {
                  onchangeTab(key);
                }}
                {...props}
              >
                {label}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        title={<p className='font-semibold text-s'>Pos Agent Listing</p>}
        // data={breadcrumb}
        btnText='+ POS'
        buttonId={elementData?.pos?.permissions?.create}
        onClick={toggleAddPosManagement}
      />
      <Tabs
        renderTabBar={renderTabBar}
        onChange={onchangeTab}
        items={tabsItem}
        defaultActiveKey={tabKey}
        activeKey={tabKey}
      />
      <TableComponent
        loading={loading}
        columns={columns}
        dataSource={tableData}
        noDataAction={fetchData}
        pageSize={10}
        total={total_page}
        onChange={handlePagination}
        defaultPage={page}
        getRowClassName={rowCommonClass}
      />
      {/* Add edit pos*/}
      <CustomModal
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddPosManagement}
        footer={''}
        title={editInitials ? 'Edit Pos' : 'Add Pos'}
        width={750}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 text-grey-80'>
            {editInitials
              ? 'Edit Pos details'
              : 'To add a new Pos, enter the details of the Pos in the input field below.'}
          </span>
          <FormContainer
            accordionClass='grid grid-cols-2 gap-8'
            onSubmit={editInitials ? onEditPosManagement : onAddPosManagement}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddPosManagement();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.pos.permissions.update
                        : elementData.pos.permissions.create
                    }
                    text={editInitials ? 'Update Pos' : 'Add Pos'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
      {/* delete Pos Management confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeletePosManagement}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the Pos?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeletePosManagement}
            />
            <Button
              text='Delete POS Management'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeletePosManagement}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(PosManagement);
