import {
  Module,
  Dialer,
  Header,
  Navbar,
  StoreProvider,
  ToggleDialer,
  LoadingIndicator,
} from 'components';
import React, { useContext, useState } from 'react';
import { Route, Routes } from 'react-router';
import { Context, reducer } from 'store/context';
import {
  LeavesType,
  Leaves,
  Attendance,
  ProvidentFund,
  Accounts,
  NonPerformingEmployees,
  Invoices,
  AttendanceSettings,
  Holidays,
  Performance,
  PayRole,
  MyAttendance,
  AssetsType,
  AllAssets,
  CreateInvoice,
  Employees,
  PayRollManagement,
  RequestForAsset,
  OfficialLetters,
  AssetRequest,
  JobKprKpi,
  JobManagement,
  Interviewmanagement,
  AssetAllocationTab,
  PosEarning,
} from 'screens';
import { elementData } from 'elements';
import CompanyExpense from 'screens/hr/company-expense';
import CompanyPolicies from './company-policies';
import AddEditCompanyPolicy from './company-policies/AddEditCompanyPolicy';
import ViewCompanyPolicy from './company-policies/ViewCompanyPolicy';
import HRDashboard from './dashboard';
import ShiftAssignees from './attendance/attendance-settings/shiftAssignees';
import ViewApplications from './jobs/viewapplications';
import { ElisopsLogo } from 'assets/hr_icons';
import Tickets from './tickets';
import RaiseTicket from './tickets/raise-ticket';
import UserDetails from './user-details';

const initialState = { dialerOpened: false };

const HR = () => {
  const [isOpen, setIsOpen] = useState(true);

  const {
    permissions: { loading, list: permissions },
  } = useContext(Context);

  if (loading) {
    return (
      <div className='flex justify-center flex-col items-center h-screen gap-2'>
        {/* <MobileInsuranceLogo svgClass={'w-32'} /> */}
        <img src={ElisopsLogo} alt='ELISOPS' />
        <LoadingIndicator />
      </div>
    );
  }
  return (
    <div className='flex'>
      <div
        className={`bg-grey-light ${
          isOpen ? 'w-full' : 'w-[92%]'
        } overflow-y-auto h-screen max-h-screen content-start flex flex-col`}
      >
        <Navbar
          permissions={permissions}
          dashboardType='HR'
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        {/* <Header permissions={permissions} dashboardType='HR' /> */}
        <StoreProvider initialState={initialState} reducer={reducer}>
          <ToggleDialer />
          <Dialer />

          <main className='p-4 overflow-auto flex-[18px]'>
            <Routes>
              <Route
                path='dashboard'
                element={
                  <HRDashboard
                    fallback={<Module />}
                    id={elementData.HR.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='leaves-type'
                element={
                  <LeavesType
                    fallback={<Module />}
                    id={elementData.leaves_type.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='leaves'
                element={
                  <Leaves
                    isRequesting={false}
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.leaves_request.name}
                  />
                }
              />
              <Route
                path='request-leave'
                element={
                  <Leaves
                    isRequesting={true}
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.request_leave.permissions.view}
                  />
                }
              />
              <Route
                path='attendance'
                element={
                  <Attendance
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.attendance.name}
                  />
                }
              />
              <Route
                path='attendance-settings/shift-assignees/:id'
                element={
                  <ShiftAssignees
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.attendance_settings.name}
                  />
                }
              />
              <Route
                path='provident-fund'
                element={
                  <ProvidentFund
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.providentFund.name}
                  />
                }
              />
              <Route
                path='asset-type'
                element={
                  <AssetsType
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.assetType.name}
                  />
                }
              />
              <Route
                path='inventory-management'
                element={
                  <AllAssets
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.assets.name}
                  />
                }
              />
              <Route
                path='assets-allocation'
                element={
                  <AssetAllocationTab
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.assetAllocation.name}
                  />
                }
              />
              <Route
                path='accounts'
                element={
                  <Accounts
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.accounts.name}
                  />
                }
              />
              <Route
                path='non-performing-employees'
                element={
                  <NonPerformingEmployees
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.non_performing_employees.name}
                  />
                }
              />
              <Route
                path='invoices'
                element={
                  <Invoices
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.Invoices.name}
                  />
                }
              />
              <Route
                path='attendance-settings'
                element={
                  <AttendanceSettings
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.attendance_settings.name}
                  />
                }
              />
              <Route
                path='holidays'
                element={
                  <Holidays
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.holidays_calender.name}
                  />
                }
              />
              <Route
                path='performance'
                element={
                  <Performance
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.performance.name}
                  />
                }
              />
              <Route
                path='pay-role'
                element={
                  <PayRole
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.performance.name}
                  />
                }
              />
              <Route
                path='invoices/create'
                element={
                  <CreateInvoice
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.performance.permissions.create}
                  />
                }
              />
              <Route
                path='invoices/edit/:id'
                element={
                  <CreateInvoice
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.performance.permissions.update}
                  />
                }
              />
              <Route
                path='my-attendance'
                element={
                  <MyAttendance
                    fallback={<Module />}
                    permissions={permissions}
                    // id={elementData.mark_attendance.name}
                    id={elementData.performance.name} // change this
                  />
                }
              />
              <Route
                path='Employees'
                element={
                  <Employees
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.employee.name}
                  />
                }
              />
              <Route
                path='pos-earning'
                element={
                  <PosEarning
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.posEarning.name}
                  />
                }
              />
              <Route
                path='company-expense'
                element={
                  <CompanyExpense
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.company.name}
                  />
                }
              />
              <Route
                path='payroll'
                element={
                  <PayRollManagement
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.payroll.name}
                  />
                }
              />
              <Route
                path='request-for-asset'
                element={
                  <RequestForAsset
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.assetAllocation.name}
                  />
                }
              />
              <Route
                path='official-letters'
                element={
                  <OfficialLetters
                    fallback={<Module />}
                    id={elementData.letter.name}
                    permissions={permissions}
                  />
                }
              />
              <Route
                path='asset-request'
                element={
                  <AssetRequest
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.assetRequest.name}
                  />
                }
              />
              <Route
                path='job-kpr-kpi'
                element={
                  <JobKprKpi
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.jobKra.name}
                  />
                }
              />
              <Route
                path='job-management'
                element={
                  <JobManagement
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.manageRecruitment.name}
                  />
                }
              />
              <Route
                path='job-interviews'
                element={
                  <Interviewmanagement
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.interview.name}
                  />
                }
              />

              <Route
                path='job-management/view-applications/:id'
                element={
                  <ViewApplications
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.applicationDetails.name}
                  />
                }
              />
              <Route
                path='company-policies'
                element={
                  <CompanyPolicies
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.company_policy.read}
                  />
                }
              />
              <Route
                path='company-policies-add'
                element={
                  <AddEditCompanyPolicy
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.company_policy.permissions.create}
                    type='Create'
                  />
                }
              />
              <Route
                path='company-policies-edit/:id'
                element={
                  <AddEditCompanyPolicy
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.company_policy.permissions.update}
                    type='Edit'
                  />
                }
              />
              <Route
                path='tickets'
                element={
                  <Tickets
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.employee.name}
                  />
                }
              />
              <Route
                path='raise-ticket'
                element={
                  <RaiseTicket
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.ticket.name}
                  />
                }
              />
              <Route
                path='company-policies-view/:id'
                element={
                  <ViewCompanyPolicy
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.company_policy.permissions.view}
                  />
                }
              />
              <Route
                path='user-details/:id'
                element={
                  <UserDetails
                    fallback={<Module />}
                    permissions={permissions}
                    id={elementData.applicationDetails.name}
                  />
                }
              />
            </Routes>
          </main>
        </StoreProvider>
      </div>
    </div>
  );
};

export default HR;
