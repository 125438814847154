import { TextInput, SelectInput, DateTime, Uploadbox, SearchAPI, MultiSelect } from 'components';
import { getAssetType, getAssets } from 'service/api-service';

const AssetsTypeFields = {
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Assets Type',
        name: 'name',
        id: 'name',
        placeholder: 'Assets Type',
        type: 'text',
        required: true,
        component: TextInput,
      },
    ],
  },
};

const allocateAssetsFields = {
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Employee',
        name: 'employee',
        id: 'employee',
        required: true,
        placeholder: 'Employee',
        type: 'search',
        component: SearchAPI,
      },
      {
        label: 'Asset Type',
        name: 'asset_type',
        id: 'asset_type',
        placeholder: 'Asset Type',
        type: 'select',
        required: true,
        component: SelectInput,
        dependantFetchOptions: async () => {
          const resp = await getAssetType({ search: true });
          return resp?.data?.data || [];
        },
      },
      {
        dependent: true,
        dependent_on: 'asset_type',
        hideField: true,
        label: 'Assets Allocation',
        name: 'asset',
        id: 'asset',
        placeholder: 'Assets Allocation',
        type: 'select',
        required: true,
        component: MultiSelect,
        options: [],
        dependency: ['asset_type'],
        dependantFetchOptions: async (form) => {
          const asset_type = form?.values?.asset_type || '';
          const resp = await getAssets({ avail: true, asset_type, search: true });
          return resp?.data?.data.length > 0
            ? resp?.data?.data.map((item) => ({
                id: item.id,
                name: `${item.ser_no}-${item.name}`,
              }))
            : [];
        },
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
      },
    ],
  },
};

const addAssetsFields = {
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Asset Name',
        name: 'name',
        id: 'name',
        placeholder: 'Asset Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Asset Type',
        name: 'asset_type',
        id: 'asset_type',
        placeholder: 'Asset Type',
        type: 'select',
        required: true,
        component: SelectInput,
        dependantFetchOptions: async () => {
          const resp = await getAssetType({ search: true });
          return resp ? resp?.data?.data : [];
        },
      },
      {
        label: 'Purchase Date',
        name: 'purchase_date',
        id: 'purchase_date',
        placeholder: 'YYYY-MM-DD',
        type: 'date',
        required: true,
        showTime: false,
        component: DateTime,
        disableNext: true,
      },
      {
        label: 'Purchase From',
        name: 'purchase_from',
        id: 'purchase_from',
        placeholder: 'Purchase From',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Company',
        name: 'manufacturer',
        id: 'manufacturer',
        placeholder: 'Company',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Model',
        name: 'model',
        id: 'model',
        placeholder: 'Model',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Serial Number',
        name: 'ser_no',
        id: 'ser_no',
        placeholder: 'Serial Number',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Condition',
        name: 'condition',
        id: 'condition',
        placeholder: 'Condition',
        type: 'text',
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Warranty (Months)',
        name: 'warranty',
        id: 'warranty',
        placeholder: 'Warranty',
        type: 'number',
        component: TextInput,
        maxLength: 2,
      },
      {
        label: 'Amount',
        name: 'amount',
        id: 'amount',
        placeholder: 'Amount',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Assigned Person',
        name: 'assignee',
        id: 'assignee',
        placeholder: 'Assigned Person',
        type: 'search',
        component: SearchAPI,
      },
      {
        label: 'Notes',
        name: 'description',
        id: 'description',
        placeholder: 'Notes',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        rows: 5,
        divClasses: 'col-span-full',
      },
      {
        label: 'Upload Bills',
        name: 'bills',
        id: 'bills',
        placeholder: 'Bills',
        type: 'file',
        component: Uploadbox,
        maxCount: 500,
        rows: 5,
        divClasses: 'col-span-full',
      },
    ],
  },
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Assets',
    path: '',
  },
];

const typebreadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Asset Type',
    path: '',
  },
];

const allocateBreadObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Asset Allocation',
    path: '',
  },
];

export {
  addAssetsFields,
  breadcrumbObj,
  typebreadcrumbObj,
  allocateAssetsFields,
  AssetsTypeFields,
  allocateBreadObj,
};
