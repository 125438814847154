/** external deps */
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
/** internal deps */
import { LOGO } from 'assets/icons';
import { AuthLayout, FormContainer, Toast } from 'components';
import { ResetFields, ResetInitials } from './data';
import { resetPassword } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';

const ForgotPassword = () => {
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const onSubmitPassword = async (data) => {
    const finalPayload = {
      ...data,
      token: searchParams.get('token'),
    };
    const resp = await resetPassword({ data: finalPayload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Reset link sent successfuly');
      navigate('/login');
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        typeof resp?.error === 'string' ? resp.error : 'Something went wrong',
      );
    }
  };

  return (
    <AuthLayout showImage={true}>
      <div className='w-fit m-auto'>
        <LOGO svgClass='w-[90px] h-16' />
      </div>

      <div className='flex justify-center items-center flex-col'>
        <span className='text-xl font-medium'>Reset Password</span>
        <span className='text-grey-90 text-x mb-8 text-center max-w-l font-medium'>
          Enter your new password in the field below to reset your password.
        </span>
      </div>

      <FormContainer
        accordionClass='grid grid-cols-1  gap-8'
        onSubmit={onSubmitPassword}
        previousButton
        initialValues={ResetInitials}
        fields={ResetFields}
        submitButtonProps={{ submitText: 'Reset Password' }}
      />
    </AuthLayout>
  );
};

export default ForgotPassword;
