/** external deps */
import React, { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { Tag } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  Toast,
} from 'components';
import { addAssetsFields, breadcrumbObj } from './data';
import { Trash } from 'assets/icons';
import { prepareFormFieldsData } from 'utils';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import AcknowlegedAssetRequest from './AcknowlegedAssetRequest';
import { NOTIFICATION_MSG } from 'constants';
import { getAssetAllocation } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const AssetRequest = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredAssets, setfilteredAssets] = useState([]);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addAssetsFields,
  });

  const columns = [
    {
      key: 'requested_by',
      title: 'Requested By',
      dataIndex: 'requested_by',
      render: (data) => data?.name || '\u2014',
    },
    {
      key: 'asset',
      title: 'Asset',
      dataIndex: 'asset_type',
      render: (data) => data?.map((item) => item.name) || '\u2014',
    },
    {
      key: 'allocation_date',
      title: 'Request Date',
      dataIndex: 'allocation_date',
      render: (data) => data || '\u2014',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status) => {
        return (
          <Tag icon={<SyncOutlined spin />} color={(status = 'processing')}>
            {status}
          </Tag>
        );
      },
    },
    {
      key: 'action',
      title: 'Action',
      // width: 280,
      align: 'right',
      render: (record) => <AcknowlegedAssetRequest record={record} onGetAssets={onGetAssets} />,
    },
  ];

  const onGetAssets = async () => {
    setLoading(true);
    const resp = await getAssetAllocation({ status: 'pending' });
    if (resp?.data?.status) {
      setTableData(resp.data?.data?.result);
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Failed to fetch Requested Assets from server',
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    onGetAssets();
  }, []);

  /* funcs */
  const onAddAsset = async (data) => {};

  const onDeleteAsset = async () => {};

  const onEditAsset = async (data) => {};

  const toggleAddAsset = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  const toggleDeleteAsset = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(tableData, {
      keys: ['emp_id', 'asset_type', 'date'],
      threshold: 0.2,
    }).search(e.target.value);
    setfilteredAssets(fuse.map((i) => i.item));
  };

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        data={breadcrumbObj}
        onChange={handleOnChange}
        buttonId={elementData?.assets?.permissions?.create}
        onClick={toggleAddAsset}
      />
      <TableComponent
        loading={loading}
        getRowClassName={rowCommonClass}
        columns={columns}
        dataSource={searchQuery ? filteredAssets : tableData}
      />

      {/* add Asset modal */}
      <CustomModal destroyOnClose={true} open={showAddModal} onCancel={toggleAddAsset} footer={''}>
        <div>
          <div className='flex flex-col'></div>
          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditAsset : onAddAsset}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddAsset();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.assets.permissions.update
                        : elementData.assets.permissions.create
                    }
                    text={editInitials ? 'Update Asset' : 'Save'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={() => {
                      submitForm();
                      toggleAddAsset();
                    }}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* delete asset confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteAsset}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the Asset ?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteAsset}
            />
            <Button
              text='Delete Asset'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteAsset}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(AssetRequest);
