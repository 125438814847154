import { SearchAPI, TextInput } from 'components';

export const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Master',
    // path: "/",
  },
  {
    name: 'Department',
    path: '',
  },
];

export const columns = [
  {
    key: 'name',
    title: 'Department Name',
    dataIndex: 'name',
    render: (text) => text || '\u2014',
  },
  {
    key: 'head',
    title: 'Department Head',
    dataIndex: 'head',
    render: (text) => text?.name || '\u2014',
  },
];

export const addDepartmentFields = {
  'Department Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Department Name',
        name: 'name',
        id: 'name',
        placeholder: 'Department Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 50,
      },
      {
        label: 'Department Head',
        name: 'assigned_to_employee',
        id: 'assigned_to_employee',
        placeholder: 'Department Head',
        type: 'search',
        // required: true,
        component: SearchAPI,
      },
    ],
  },
};

export const addDepartmentInitials = {
  name: '',
  assigned_to_employee: '',
  head: '',
};
