import Request from './client';
import { endpoints } from 'constants/endpoints';

export const departmentManagement = async (
  props = { data: '', method: 'get', id: '', query: '' },
) => {
  const resp = await Request({
    method: props.method,
    API:
      props.method === 'delete' || props.method === 'patch'
        ? endpoints.departmentManagement.department(props.id)
        : endpoints.departmentManagement.departmentQuery(props.query || ''),
    data: props?.data,
  });
  return resp;
};
