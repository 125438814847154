import { Link } from 'react-router-dom';
import { Logout } from 'service/api-service';
export const items = [
  {
    label: (
      <Link to='/pos/pos-setting' className='p-4'>
        Setting
      </Link>
    ),
    key: '0',
  },
  {
    label: (
      <Link to='/pos-login' className='p-4' onClick={Logout}>
        Logout
      </Link>
    ),
    key: '1',
  },
];
