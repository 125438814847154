// import { setup } from "axios-cache-adapter";
import axios from 'axios';
import { Logout } from '.';
import { getItem, handleError, setItem } from '../../utils';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const BASE_URL = "https://7fa3-203-190-154-123.ngrok-free.app/api/v1/";
if (!BASE_URL) throw new Error('NO BACKEND ENDPOINT SET.');

// const api = setup({
//   baseURL: BASE_URL,
//   cache: {
//     maxAge: 24 * 60 * 60 * 5,
//     exclude: {
//       query: false,
//     },
//     // invalidate: async (config, request) => {
//     //   if (request.clearCache) {
//     //     let caches = Object.keys(config.store.store);
//     //     let path = request.url.split(BASE_URL)[1].slice(0, -1);
//     //     for (let i = 0; i < caches.length; i++) {
//     //       if (caches[i].indexOf(path) !== -1) {
//     //         await config.store.removeItem(caches[i]);
//     //       }
//     //     }
//     //   }
//     // },
//     clearOnStale: true,
//   },
// });

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

// adds auth headers to protected routes
axiosInstance.interceptors.request.use((config) => {
  const tokens = getItem('AUTH');
  const { access_token } = tokens || { access_token: null };
  let shouldAddAuthHeaders = true;
  //exception condition for deleting playerId
  if (config.url.includes('player-id') || config.url.includes('notification')) {
    shouldAddAuthHeaders = true;
  } else {
    shouldAddAuthHeaders = !config.url.includes('/auth/') && access_token; // not adding token for some requests. e.g: /auth/user/login
  }

  if (shouldAddAuthHeaders) config.headers.Authorization = access_token;
  return config;
});

// if request fails for token being invalid, generates a new token and sends request again
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const { refresh_token } = getItem('AUTH') || { refresh_token: null };

      if (!refresh_token) throw new Error('Session Expired. Please log in again.');

      let res;
      try {
        res = await axios.post(`${BASE_URL}auth/user/refresh/token`, {
          token: refresh_token,
        });
      } catch (error) {
        //if refresh token is not valid logout
        Logout();
      }

      const tokens = res?.data?.data || { access_token: null };

      setItem('AUTH', tokens);
      error.config.headers.Authorization = tokens.access_token;
      return axios(error.config);
    } else {
      return handleError(error);
    }
  },
);

/**
 * helper function to make api calls to backend
 * @param {{
 * method: 'get' | 'post' | 'patch' | 'put' | 'delete',
 * API: string,
 * data: {[string]: string}
 * clearCacheEntry: boolean
 * cancelToken: any
 * replaceUrl: false | string
 * }} options
 * @returns data from backend
 */
const Request = async ({
  method = 'get',
  API,
  data = undefined,
  clearCacheEntry = false,
  cancelToken = undefined,
  header = {},
  replaceUrl = false,
}) => {
  const axiosOptions = {
    headers: { ...header },
    clearCache: clearCacheEntry,
    cancelToken,
    data,
  };

  const url = replaceUrl ? replaceUrl : `${BASE_URL}${API}`;

  try {
    if (method === 'delete' || method === 'get') {
      const response = await axiosInstance[method](url, axiosOptions);
      if (response.status) {
        return { data: response?.data, status: true };
      } else {
        return response;
      }
    } else {
      const response = await axiosInstance[method](url, data, axiosOptions);
      if (response.status) {
        return { data: response?.data, status: true };
      } else {
        return response;
      }
    }
  } catch (error) {
    console.log('API: ', API);
    console.log('ERROR:', error);
    return handleError(error);
  }
};

export default Request;
