import { produce } from 'immer';
import { create } from 'zustand';

const BasicInitials = {
  clientHelper: {
    preferenceProducts: [],
  },
};

export const useBasicHelper = create((set, get) => ({
  ...BasicInitials,
  addPreferenceCategory: (details) => {
    set((state) => (state.clientHelper.preferenceProducts = details));
  },
  changePreferenceCategory: (value, index) => {
    set(produce((state) => void (state.clientHelper.preferenceCategory[index] = value)));
  },
  resetBasicStore: () => set(BasicInitials),
}));
