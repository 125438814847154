import { FormContainer, SelectInput, TextInput, Button, DateTime } from 'components';
import React from 'react';
import { getInsuranceCategory } from 'service/api-service';
import { prepareFormFieldsData } from 'utils';

const QuotationDetails = ({ handleFormNavigation, formData, setFormData }) => {
  const quoteCustomerFields = {
    'Quotation Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Category',
          name: 'insurance_category',
          id: 'insurance_category',
          placeholder: 'Select Category',
          type: 'select',
          component: SelectInput,
          fetchOptions: getInsuranceCategory,
          required: true,
        },
        {
          label: 'Premium Payment',
          name: 'premium_payment',
          id: 'premium_payment',
          placeholder: 'Premium Payment',
          type: 'text',
          component: TextInput,
          maxLength: 30,
          required: true,
        },
        {
          label: 'Premium Duration',
          name: 'duration',
          id: 'duration',
          placeholder: 'Select Premium Duration',
          type: 'select',
          component: SelectInput,
          required: true,
          options: [
            { label: 'Monthly', value: 'M' },
            { label: 'Quarterly', value: 'Q' },
            { label: '6 Months', value: 'SM' },
            { label: 'Yearly', value: 'Y' },
          ],
        },
        {
          label: 'Period of Insurance: From',
          name: 'from',
          id: 'from',
          placeholder: 'Period of Insurance: From',
          component: DateTime,
          type: 'date',
          maxLength: 30,
        },
        {
          label: 'Period of Insurance: To',
          name: 'to',
          id: 'to',
          placeholder: 'Period of Insurance: To',
          component: DateTime,
          type: 'date',
          maxLength: 30,
        },
        {
          label: 'GST Type',
          name: 'gsttype',
          id: 'gsttype',
          placeholder: 'GST Type',
          type: 'select',
          component: SelectInput,
          required: true,
          options: [
            { label: 'Exempted', value: 'exempted' },
            { label: 'Included', value: 'included' },
            { label: 'Excluded', value: 'excluded' },
          ],
        },
        {
          label: 'Cash Collateral',
          name: 'collateral',
          id: 'collateral',
          placeholder: 'Cash Collateral',
          type: 'text',
          component: TextInput,
          maxLength: 30,
        },
        {
          label: 'Application Reference',
          name: 'app_reference',
          id: 'app_reference',
          placeholder: 'Application Reference',
          type: 'text',
          component: TextInput,
          maxLength: 30,
        },
      ],
    },
  };

  const onNextClick = (data) => {
    setFormData((pre) => ({ ...pre, ...data }));
    handleFormNavigation();
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: quoteCustomerFields,
    defaultValues: formData,
  });

  return (
    <div>
      <div className='flex justify-between px-2'>
        <div className='flex flex-col px-2'>
          <span className='text-l 2xl:text-xxl font-medium'>Quotation Details</span>
          <span className='text-xxs text-grey-80 '>
            {'To add Quotation Details enter the details in the input field below.'}
          </span>
        </div>
      </div>
      <FormContainer
        returnFormatted={false}
        accordionClass='grid grid-cols-2 lg:grid-cols-3 gap-8'
        fields={finalFields}
        initialValues={initialValues}
        onSubmit={onNextClick}
        customSubmitRender={({ isSubmitting, submitForm, isValid }) => {
          return (
            <div className='bg-white flex flex-row justify-end py-4 mt-6'>
              <Button
                text='Previous'
                variant='text'
                type='reset'
                classes='border border-gray-90 text-black px-6'
                onClick={() => handleFormNavigation(true)}
              />
              <Button
                // text={state?.initialValues ? "Update Contact" : "Add Contact"}
                text='Next'
                type='button'
                variant='primary'
                classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                onClick={submitForm}
                loading={isSubmitting}
                disabled={!isValid}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default QuotationDetails;
