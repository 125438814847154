import Request from 'service/api-service/client';

export const getCommission = async ({ type = '' }) => {
  const resp = await Request({
    method: 'get',
    API: `commission/?commission_set=${type}`,
  });
  return resp;
};

export const updateCommission = async ({ data = '', id = '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `commission/?id=${id}`,
    data: data,
  });
  return resp;
};

export const deleteCommission = async ({ id = '' }) => {
  const resp = await Request({
    method: 'delete',
    API: `commission/?id=${id}`,
  });
  return resp;
};

export const postCommissionPos = async ({ data = '' }) => {
  const resp = await Request({
    method: 'post',
    API: 'commission/',
    data: data,
  });
  return resp;
};
