/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Space } from 'antd';
import Fuse from 'fuse.js';
/** internal deps */
import { Button, CustomModal, ContentHeader, TableComponent, IconButton, Toast } from 'components';
import { breadcrumbObj } from './data';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { addForm, deleteForm, editForm, getForm } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import FormBuilder from './formBuilder';
import { rowCommonClass } from 'utils/common';

const CategoryForm = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [selectedForm, setSelectedForm] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredForm, setfilteredForm] = useState([]);

  const columns = [
    {
      key: 'name',
      title: 'Form Name',
      dataIndex: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.custom_form.permissions.delete}
            onClick={() => {
              setSelectedForm(record);
              toggleDeleteForm();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.custom_form.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  /* funcs */

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await getForm();
    if (resp?.data?.data) {
      setTableData(resp?.data?.data);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const onAddForm = useCallback(async (data) => {
    if (data?.name) {
      const resp = await addForm({ data });

      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        toggleAddForm();
        fetchData();
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
    } else {
      Toast('error', NOTIFICATION_MSG.error, 'Form Name is required');
    }
    setLoading(true);

    setLoading(false);
  }, []);

  const onDeleteForm = useCallback(async () => {
    setLoading(true);
    const resp = await deleteForm({ id: selectedForm?.id });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Form deleted successfully');
      fetchData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteForm();
    setLoading(false);
  }, [selectedForm]);

  const onEditForm = useCallback(
    async (data) => {
      setLoading(true);
      if (data?.name) {
        const resp = await editForm({
          data,
          id: editInitials?.id,
        });

        if (resp?.data?.status) {
          Toast(
            'success',
            NOTIFICATION_MSG.success,
            resp?.data?.msg || 'Form updated successfully.',
          );
          fetchData();
        } else {
          Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
        }
        toggleAddForm();
        setInitials();
      } else {
        Toast('error', NOTIFICATION_MSG.error, 'Form Name is required');
      }

      setLoading(false);
    },
    [selectedForm, editInitials],
  );

  const toggleAddForm = useCallback(() => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  }, []);

  const toggleDeleteForm = useCallback(() => setDeleteModal((pre) => !pre), []);

  /* search function */
  const handleOnChange = useCallback(
    (e) => {
      if (!e.target.value) setSearchQuery('');
      const query = e.target.value;
      setSearchQuery(query);
      const fuse = new Fuse(tableData, {
        keys: ['name'],
        threshold: 0.2,
      }).search(e.target.value);
      setfilteredForm(fuse.map((i) => i.item));
    },
    [tableData],
  );

  const onEditPress = useCallback((record) => {
    setInitials(record);
    setSelectedForm(record);
    setAddModal(true);
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        title={<p className='font-semibold text-s'>Custom Form</p>}
        // data={breadcrumbObj}
        btnText='+ Form'
        buttonId={elementData?.custom_form?.permissions?.create}
        onClick={toggleAddForm}
      />
      <TableComponent
        getRowClassName={rowCommonClass}
        loading={loading}
        columns={columns}
        dataSource={searchQuery ? filteredForm : tableData}
      />

      {/* delete form confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteForm}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]'>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the form?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteForm}
            />
            <Button
              text='Delete Form'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteForm}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>

      {/* add form modal */}
      <CustomModal destroyOnClose={true} open={showAddModal} footer={''} closable={false}>
        <div className='h-screen'>
          <FormBuilder
            showFooterButtons={true}
            showHeaderButtons={false}
            onSave={onAddForm}
            loading={loading}
            editedData={editInitials}
            onUpdate={onEditForm}
            onClose={toggleAddForm}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(CategoryForm);
