import React, { useEffect, useState } from 'react';
import { TableComponent } from 'components';
import { getCustomerEventData } from 'service/api-service';
import { UpcomingBirthday_Cols, WorkAnniversary_Cols } from './data';
import { rowCommonClass } from 'utils/common';

const CurrentMonthBirthday = (props = {}) => {
  const date = props?.date || '';
  /* states */
  const [loading, setLoading] = useState(false);
  const [dataSource, setdataSource] = useState([]);

  /* func */
  const fetchData = async (pageNum = 1) => {
    setLoading(true);
    const resp = await getCustomerEventData({ date, pageNum });
    if (resp?.data?.data) {
      //setdataSource(resp?.data?.data?.user_events);
      const user_event = resp?.data?.data?.user_events;
      const work_anivv = resp?.data?.data?.work_aniv;
      const events = [...user_event, ...work_anivv];
      setdataSource(events);
    }
    setLoading(false);
  };
  const handlePagination = (pageNum) => (pageNum?.current ? fetchData(pageNum?.current) : '');

  useEffect(() => {
    fetchData();
  }, [date]);
  return (
    <div className='space-y-4 bg-white p-4 border rounded-md border-grey-40 h-full'>
      <div className='flex items-start justify-between'>
        <div className='font-semibold text-s leading-6'>Birthdays and Anniversary</div>
      </div>
      <div className='customTable' id='dashboard'>
        <TableComponent
          getRowClassName={rowCommonClass}
          loading={loading}
          pageSize='10'
          columns={UpcomingBirthday_Cols}
          dataSource={dataSource}
          onChange={handlePagination}
          showPagination={false}
          scroll={{ y: 175 }}
        />
      </div>
    </div>
  );
};

export default CurrentMonthBirthday;
