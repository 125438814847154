import { endpoints } from 'constants/endpoints';
import Request from '../client';

export const getPosContract = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.contract.details,
  });
  return resp;
};
export const addPosContract = async ({ data = undefined } = {}) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.contract.create,
    data,
  });
  return resp;
};
export const editPosContract = async ({ data = undefined, id } = {}) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.contract.update(id),
    data,
  });
  return resp;
};
