/** external deps */
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/** internal deps */
import { SecureComponent } from 'permission-provider';
import { ContentHeader, ViewData, TabsComponent } from 'components';
import { breadCrumbs, formatViewData } from './data';
import { elementData } from 'elements';
import { generatePermittedTabs } from 'utils';

const ViewAdjuster = ({ permissions = {} }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const data = state?.initialValues;

  /* states */
  const [key, setActiveTabKey] = useState('1');

  /* funcs */
  const handleGoBack = () => navigate(-1);

  const tabs = [
    {
      key: '1',
      id: elementData.adjuster.permissions.view,
      label: `Adjuster Details`,
      Component: ViewData,
      props: {
        data: formatViewData(data),
        classes: 'bg-white rounded-2xl p-4',
        showGoToBack: true,
        goBack: handleGoBack,
      },
    },
  ];

  return (
    <div className='grid gap-3'>
      <ContentHeader data={breadCrumbs} showSearch={false} />
      <TabsComponent
        items={generatePermittedTabs(tabs, permissions)}
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
      />
    </div>
  );
};

export default SecureComponent(ViewAdjuster);
