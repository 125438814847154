/** external deps */
import React, { useState, useEffect } from 'react';
import { Empty, Spin } from 'antd';
import Fuse from 'fuse.js';
/** internal deps */
import { ContentHeader, CustomModal, Button, FormContainer, Toast, ActionCard } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { addPosTrainingFields, breadcrumb } from './data';
import { elementData } from 'elements';
import {
  addTrainingMaterial,
  deleteTrainingMaterial,
  editTrainingMaterial,
  getTrainingMaterial,
} from 'service/api-service';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { Trash } from 'assets/icons';

const PosTraining = (props = {}) => {
  const permissions = props?.permissions || {};
  /* states  */
  const [loading, setLoading] = useState(false);
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showViewModal, setViewModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [CardData, setCardData] = useState([]);
  const [selectedPosTraining, setSelectedPosTraining] = useState();
  const [filteredPosTraining, setfilteredPosTraining] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  /* store  */
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addPosTrainingFields,
  });

  /* funcs */
  const toggleDeleteModal = (item) => {
    setDeleteModal((pre) => !pre);
    setSelectedRow(item);
  };
  const toggleView = (item) => {
    setViewModal((pre) => !pre);
    setSelectedRow(item);
  };

  const getPosTrainingList = async () => {
    setLoading(true);
    const resp = await getTrainingMaterial();
    if (resp?.data?.status) {
      setCardData(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onAddPosTraining = async (data) => {
    setLoading(true);
    const finalPayload = {
      ...data,
    };
    const resp = await addTrainingMaterial({ data: finalPayload });
    if (resp?.data?.status) {
      getPosTrainingList();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
    toggleAddPosTraining();
  };

  /* Delete Training */
  const onDeletePosTraining = async (item) => {
    setLoading(true);
    const id = selectedRow?.id;
    if (!id) return;
    setLoading(true);
    const resp = await deleteTrainingMaterial({ id });
    if (resp?.data?.status) {
      getPosTrainingList();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Product deleted successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteModal();
    setLoading(false);
  };

  const onEditPosTraining = async (data) => {
    setLoading(true);
    const editFields = getEditedFields({
      initialValues: editInitials,
      updatedData: data,
    });
    if (Object.keys(editFields).length > 0) {
      const resp = await editTrainingMaterial({
        id: selectedPosTraining?.id,
        data: editFields,
      });
      toggleAddPosTraining();
      if (resp?.data?.status) {
        getPosTrainingList();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  const onEditPress = (record) => {
    const finalInitials = {
      ...record,
      asset_type: record?.asset_type.toLowerCase(),
    };
    setInitials(finalInitials);
    setSelectedPosTraining(record);
    setAddModal(true);
  };

  const toggleAddPosTraining = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  useEffect(() => {
    getPosTrainingList();
  }, []);

  const renderCard = (item) => {
    return (
      <ActionCard
        key={item.id}
        title={item.title}
        description={item.description}
        data={item}
        onEditPress={onEditPress.bind(null, item)}
        onDeletePress={toggleDeleteModal.bind(null, item)}
        onViewPress={toggleView.bind(null, item)}
        permissions={permissions}
      />
    );
  };

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(CardData, {
      keys: ['title', 'asset', 'asset_type'],
      threshold: 0.2,
    }).search(e.target.value);
    const newData = fuse.map((i) => i.item);
    setfilteredPosTraining(newData);
  };

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        title={<p className='font-semibold text-s'>Pos Taining</p>}
        // data={breadcrumb}
        btnText='+ New'
        buttonId={elementData?.pos_training?.permissions?.create}
        onClick={toggleAddPosTraining}
      />

      <div className='bg-white py-4 px-6'>
        {loading ? (
          <Spin size='lg' className='absolute top-[50%] left-[50%]' />
        ) : filteredPosTraining.length > 0 || CardData.length > 0 ? (
          <div className='gap-8 grid grid-cols-4 pr-4'>
            {(searchQuery ? filteredPosTraining : CardData)?.map(renderCard)}
          </div>
        ) : (
          <div className='w-full h-full flex p-10 justify-center flex-col items-center'>
            <Empty description={false} imageStyle={{ height: '60px' }} />
            <span>No data found</span>
          </div>
        )}
      </div>
      <CustomModal
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddPosTraining}
        footer={''}
        width={750}
      >
        <div>
          <div className='flex flex-col'>
            <span className='text-l 2xl:text-xxl font-medium'>
              {editInitials ? 'Edit Training Material' : 'Add Training Material'}
            </span>
            <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 text-grey-80'>
              {editInitials
                ? 'Edit training material details'
                : 'To add a new training material, enter the details in the input field below.'}
            </span>
          </div>
          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditPosTraining : onAddPosTraining}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddPosTraining();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.pos_training.permissions.update
                        : elementData.pos_training.permissions.create
                    }
                    text={editInitials ? 'Update' : 'Add'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* Delete Modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the Training details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              permissions={permissions}
              id={elementData.pos_training.permissions.delete}
              text='Delete'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeletePosTraining}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
      {/* View Video Modal */}
      <CustomModal
        open={showViewModal}
        closable={true}
        onCancel={toggleView}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '></div>
      </CustomModal>
    </div>
  );
};

export default PosTraining;
