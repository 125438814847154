import React, { useState } from 'react';
import dayjs from 'dayjs';
import { RightArrowHead } from 'assets/icons';
import clsx from 'clsx';

const currentYear = dayjs().year();
const lastFourYears = Array.from({ length: 4 }, (_, index) => currentYear - index);

const lastFourYearsMonths = lastFourYears.reverse().reduce((acc, year) => {
  const yearMonths = Array.from({ length: 12 }, (_, monthIndex) => ({
    year,
    month: monthIndex + 1,
    formatted: dayjs().year(year).month(monthIndex).format('MMM YYYY'),
  }));

  return [...acc, ...yearMonths];
}, []);

const CalenderCarousel = ({ timeline, setTimeline }) => {
  const [active, setActive] = useState(timeline?.formatted);
  const [currentIndex, setCurrentIndex] = useState(lastFourYearsMonths.length);

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) =>
        ((prevIndex - 1 + lastFourYearsMonths.length) % lastFourYearsMonths.length) - 11,
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => ((prevIndex + 1) % lastFourYearsMonths.length) + 11);
  };

  const renderCards = (item, index) => {
    const onDateChange = () => {
      setTimeline(item);
      setActive(item.formatted);
    };
    return (
      <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
        <button
          onClick={onDateChange}
          disabled={item.year == dayjs().format('YYYY') && item.month > dayjs().format('M')}
          className={clsx(
            'py-1.5 px-4 transition-all duration-300 rounded-md disabled:cursor-not-allowed disabled:text-grey-50 hover:text-primary-40 hover:bg-primary-60/10 disabled:hover:bg-transparent',
            {
              'text-primary-40':
                item.year < dayjs().format('YYYY') || item.month < dayjs().format('M'),
              'bg-primary-60 text-white': item.formatted === active,
            },
          )}
        >
          {item.formatted}
        </button>
      </div>
    );
  };

  const visibleCards = lastFourYearsMonths.slice(currentIndex - 12, currentIndex);

  return (
    <div className='relative grid grid-cols-12 items-center gap-2 border border-grey-40 rounded-lg hover:border-primary-60 py-2 px-4 bg-white'>
      <div className='absolute'>
        <button
          disabled={currentIndex == 12}
          className='ml-3 p-2 rounded-md bg-grey-light disabled:hidden'
          onClick={handlePrev}
        >
          <RightArrowHead svgClass={'rotate-180'} />
        </button>
      </div>
      {visibleCards?.map(renderCards)}
      <div className='absolute right-0'>
        <button
          disabled={currentIndex === lastFourYearsMonths.length}
          className='group mr-3 p-2 rounded-md bg-grey-light disabled:hidden'
          onClick={handleNext}
        >
          <RightArrowHead />
        </button>
      </div>
    </div>
  );
};

export default CalenderCarousel;
