/** external deps */
import React from 'react';

/** internal deps */
import { AddDocument, ViewData } from 'components';

import { createDocumentFields, initialDocumentValues } from './data';

import { getPermission } from 'utils';
import { elementData } from 'elements';
import EarningTable from 'screens/pos/posearning/earning-table';
import AgentContract from '../contract';

const OverviewPos = ({ data, setActiveTabKey, permissions = {}, pos_id }) => {
  /* states  */

  /* funcs */

  return (
    <main
      className='bg-white rounded-2xl p-4 grid grid-cols-2 [&>*]:border-b-2 [&>*:nth-child(odd)]:border-r-2 [&>*]:p-8 [&>*]:border-grey-70'
      id='over-view'
    >
      {/* first row */}

      {/* first box */}
      <div className=' pr-4 '>
        <h2 className='font-semibold text-s'>Personal Details </h2>
        <div className='pt-4'>
          <ViewData data={data} className='grid-cols-2' />
        </div>
      </div>

      {/* second box */}
      {getPermission(permissions, elementData.posEarning.name)?.read && (
        <div className='relative'>
          <div className='flex justify-between items-center'>
            <h2 className='font-semibold text-s'>Earnings</h2>
          </div>
          <EarningTable agent={pos_id} />
        </div>
      )}

      {/* second row */}

      {/* first box */}
      {getPermission(permissions, elementData.pos.permissions.update).read && (
        <AddDocument
          fields={createDocumentFields}
          initialValues={initialDocumentValues}
          user_id={pos_id}
          type='pos'
        />
      )}

      {/* second box */}
      {getPermission(permissions, elementData.pos.permissions.view)?.read && (
        <AgentContract id={pos_id} />
      )}
    </main>
  );
};

export default OverviewPos;
