import * as Yup from 'yup';
import dayjs from 'dayjs';

import { getItem, setItem, deleteItem } from './localStorage';
import { countries, getCountryOptions, getStateOptions } from 'data/form-data';
import {
  TextInput,
  PhoneNumberInput,
  SelectInput,
  DateTime,
  SearchAPI,
  CurrencyInput,
  Uploadbox,
  Checkbox,
  MultiSelect,
  CustomFieldArray,
} from 'components';
import {
  getBranches,
  // employeeWithBranch,
  getFields,
  searchApis,
  searchInsuranceApis,
  // insuranceCategoryManagement,
  // searchApis,
} from 'service/api-service';
import { WEEK_DAYS } from 'constants';
import html2canvas from 'html2canvas';
import CustomerNameWrapper from 'screens/customers/add-customer/wrapper-components/CustomerName';
import CorporationNameWrapper from 'screens/customers/add-customer/wrapper-components/CorporationNameWrapper';
import { useBasicHelper } from './BasicStore';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const countryOptions = getCountryOptions();

export const createSchema = (validations, initialValues = {}) => {
  if (Object.keys(initialValues).length === 0) return;
  let shape = {};
  for (let i = 0; i < validations.length; i++) {
    let _type = validations[i]?.type;
    let _key = validations[i]?.name;
    let _label = validations[i]?.label;
    let _required = validations[i]?.required;
    let _req_msg = validations[i]?.req_msg;
    let _err_msg = validations[i]?.err_msg;
    let _matches = validations[i]?.matches;
    let _not_match_error = validations[i]?.not_match_error;
    let _min = validations[i]?.min;
    let _max = validations[i]?.max;
    let _minValue = validations[i]?.minValue ?? 0;
    let _range = validations[i]?.range;
    let _wantValidation = validations[i]?.want_validation ?? true;

    if (_type === 'email') {
      if (_wantValidation) {
        shape[_key] = Yup.string().email(_err_msg || 'Please provide a valid email address');
      } else {
        shape[_key] = Yup.string()
          .optional()
          .email(_err_msg || 'Please provide a valid email address');
      }
      if (_required) {
        shape[_key] = Yup.string().required(_req_msg || `${_label || _key} is required`);
      }
    } else if (_type === 'upload') {
      if (_required) {
        shape[_key] = Yup.string().required(_req_msg || `${_label || _key} is required`);
      }
    } else if (_type === 'tel') {
      shape[_key] = Yup.string()
        .matches(/^\+?(?:[0-9] ?){6,14}[0-9]$/, 'Invalid phone number')
        .required('Phone number is required');

      if (_required) {
        shape[_key] = Yup.string().required(_req_msg || `${_label || _key} is required`);
      }
    } else if (_type === 'natural') {
      shape[_key] = Yup.number()
        .typeError('Amount must be a number')
        .nullable()
        .positive()
        .min(1, 'Value must be greater than 0');
      if (_min) shape[_key] = shape[_key].min(_min, 'Minimum length should ' + _min + '');
      if (_max) shape[_key] = shape[_key].max(_max, 'Maximum length should ' + _max + '');
      if (_required)
        shape[_key] = shape[_key]?.required(_req_msg || `${_label || _key} is required`);
    } else if (_type === 'number') {
      shape[_key] = Yup.number()
        .typeError('Amount must be a number')
        .nullable()
        .positive()
        .min(_minValue, `Value must be greater than ${_minValue}`);
      if (_min) shape[_key] = shape[_key].min(_min, 'Minimum length should ' + _min + '');
      if (_max) shape[_key] = shape[_key].max(_max, 'Maximum length should ' + _max + '');
      if (_required)
        shape[_key] = shape[_key]?.required(_req_msg || `${_label || _key} is required`);
    } else if (_type === 'number_range') {
      shape[_key] = Yup.number().typeError('only number is allowed').max(_range, 'Exceeding limit');

      if (_min) shape[_key] = shape[_key].min(_min, 'Minimum length should ' + _min + '');
      if (_max) shape[_key] = shape[_key].max(_max, 'Maximum length should ' + _max + '');
      if (_required)
        shape[_key] = shape[_key]?.required(_req_msg || `${_label || _key} is required`);
    } else if (_type === 'ifsc' && _key === 'ifsc_code') {
      if (_required) {
        shape[_key] = Yup.string().required(_req_msg || `${_label || _key} is required`);
      }
      //
      shape[_key] = Yup.string()
        .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, `${_label || _key} is invalid` || _not_match_error)
        .nullable();
    } else if (_type === 'text' && _key === 'fax_number') {
      if (_required) {
        shape[_key] = Yup.string().required(_req_msg || `${_label || _key} is required`);
      }
      shape[_key] = Yup.string()
        .matches(
          /^\+?\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/,
          `${_label || _key} is invalid` || _not_match_error,
        )
        .nullable();
      // } else if (_type === "text" && _key === "emp_code") {
      //   if (_required) {
      //     shape[_key] = Yup.string().required(_req_msg || `${_label || _key} is required`);
      //   }
      //   shape[_key] = Yup.string()
      //     .matches(
      //       /^[A-Z]{3}\d{3}$/,
      //       "Code must be uppercase and of 6 characters (eg: EMP123)" || _not_match_error
      //     )
      //     .nullable();
    } else if (_type === 'text' && _key !== 'des' && _key !== 'description') {
      if (_required)
        shape[_key] = Yup.string().required(_req_msg || `${_label || _key} is required`);
    } else if (_type === 'textarea') {
      if (_required)
        shape[_key] = Yup.string().required(_req_msg || `${_label || _key} is required`);
    } else if (_key === 'picture') {
      shape[_key] = Yup.array().min(3, 'Minimum three pictures are required');
    } else if (_key === 'zip_code') {
      shape[_key] = Yup.string()
        .matches(/^[a-zA-Z0-9]{0,6}$/, 'Maximum length of 6 characters')
        .nullable();
    } else if (_type === 'password') {
      shape[_key] = Yup.string();
      if (_required) {
        shape[_key] = Yup.string().required('Please enter your Password');
      }
      if (_min) {
        shape[_key] = shape[_key].min(_min, `Minimum length of ${_min} characters`);
      }
      if (_max) {
        shape[_key] = shape[_key].max(_max, `Maximum length of ${_max} characters`);
      }
      if (_matches) {
        shape[_key] = shape[_key].matches(_matches, _not_match_error);
      }
    } else if (_type === 'date') {
      if (_required) {
        shape[_key] = Yup.mixed().test('match', `${_label || _key} is required`, function (date) {
          if (Array.isArray(date)) {
            for (let i = 0; i <= date.length; i++) {
              if (dayjs(date[i]).isValid()) {
                return true;
              } else {
                return false;
              }
            }
          }
          return dayjs(date).isValid();
        });
      }
    } else if (_type === 'url' && _key === 'thumbnailUrl') {
      shape[_key] = Yup.mixed().test(
        'fileSize',
        'Minimum width and height must be 300px',
        async (url) => {
          if (!url) return false;
          return new Promise((resolve, reject) => {
            try {
              var img = new Image();
              img.src = url;
              img.onload = async function () {
                if (this.width < 300 || this.height < 300) {
                  resolve(false);
                } else {
                  resolve(true);
                }
              };
            } catch (e) {
              console.log('CATCH error-');
              reject(e);
            }
          });
        },
      );
    } else if (_type === 'search') {
      if (_required)
        // shape[_key] = Yup.object().required(_req_msg || `${_label || _key} is required`);
        shape[_key] = Yup.lazy((value) =>
          typeof value === 'object'
            ? Yup.object()
                ?.required(_req_msg || `${_label || _key} is required`)
                .typeError(_req_msg || `${_label || _key} is required`) // typeError is necessary here, otherwise we get a bad-looking yup error
            : Yup.string()?.required(_req_msg || `${_label || _key} is required`),
        );
    } else if (_type === 'select') {
      if (_required)
        shape[_key] = Yup.lazy((value) =>
          Array.isArray(value)
            ? Yup.array()
                .min(1)
                ?.required(_req_msg || `${_label || _key} is required`)
            : typeof value === 'object'
            ? Yup.object()
                ?.required(_req_msg || `${_label || _key} is required`)
                .typeError(_req_msg || `${_label || _key} is required`) // typeError is necessary here, otherwise we get a bad-looking yup error
            : Yup.string()?.required(_req_msg || `${_label || _key} is required`),
        );
    } else if (_type === 'DateRange') {
      if (_required) {
        shape[_key] = Yup.array().required();
      }
    }

    // if (_required) {
    //   shape[_key] = shape[_key]?.required(_req_msg || `${_label || _key} is required`);
    // }

    if (_key === 'cPassword') {
      shape['cPassword'] = Yup.mixed().test(
        'match',
        'Password and Confirm Password does not match',
        function (password) {
          return password === this.parent.password;
        },
      );
    }
  }

  return Yup.object().shape(shape);
};

export const handleError = (err) => {
  if (err && err.response && err.response.data) {
    if ([401, 400].includes(err.response.status)) {
      let errorMessage = 'something went wrong';
      if (err?.config.data) {
        const requestData = JSON.parse(err?.config.data);
        Object.keys(requestData).map((i) => {
          const msg = err?.response?.data?.msg;
          if (msg[i]) {
            errorMessage = msg[i][0];
          }
          if (typeof msg === 'string') {
            errorMessage = msg;
          }
          if (typeof msg === 'object') {
            Object.keys(msg).forEach((key) => {
              if (Array.isArray(msg[key])) {
                errorMessage = msg[key][0];
              } else {
                errorMessage = msg[key];
              }
            });
          }

          return null;
        });
      } else {
        errorMessage = err.response.data?.msg;
      }

      return {
        status: false,
        data: err.response.data,
        error: errorMessage,
      };
    } else if (err.response.status === 403) {
      return {
        status: false,
        data: err.response.data,
        error: err.response.data.msg || 'Something went wrong',
      };
    }
  }
  return { status: false, error: err?.message };
};

// export const handleError = (error) => {
//   try {
//     if (error && error.response) {
//       // console.log("error && error.response", error.response);
//       if (error.response.status === 401) {
//         Logout();
//       } else if (error.response.status === 400) {
//         if (error && error.response && error.response.data) {
//           const { errors, msg = "something went wrong." } = error.response.data;
//           if (errors && typeof errors === "string")
//             return {
//               status: false,
//               msg: errors,
//               error: errors,
//             };
//           else if (msg && typeof msg === "string")
//             return {
//               status: false,
//               msg: msg,
//               error: msg,
//             };
//           else if (msg && typeof msg === "object") {
//             const objectDepth = (o) =>
//               Object(o) === o ? 1 + Math.max(-1, ...Object.values(o).map(objectDepth)) : 0;

//             console.log("msg===>", objectDepth(msg));
//             console.log("msg===>", msg);
//             let info = msg[Object.keys(msg)[0]].toString();
//             return {
//               status: false,
//               msg: info,
//               error: info,
//             };
//           }
//         }
//       } else {
//         if (error && error.response && error.response.data) {
//           const { errors, msg = "something went wrong." } = error.response.data;
//           if (errors && typeof errors === "string")
//             return { status: false, msg: errors, error: errors };
//           else if (msg && typeof msg === "string") return { status: false, msg, error: msg };
//         }
//       }
//     }
//   } catch (error) {
//     console.log("HANDLE ERROR:", error);
//   }
//   return { status: false, msg: "something went wrong.", error: "something went wrong." };
// };

export const generateUniqueId = (length = 16) => {
  const randomNumber = Math.ceil(Math.random() * Date.now());
  const uniqueId = randomNumber.toString().substring(0, length);
  return parseInt(uniqueId);
};

export const checkUserSession = () => {
  const auth = getItem('AUTH') || {};
  // if (!auth) {
  // Toast('error',NOTIFICATION_MSG.error,"Access token expired, Please login again")
  //   if (navigationService.navigation) {
  //     navigationService.navigation("login");
  //   }
  // } else {
  return auth?.access_token;
  // }
};

// get states according to selected country
export const getStates = (country) => {
  const selectedCountry = countries.find((i) => i.value === country);
  return selectedCountry?.states;
};

export const formatAddress = ({ addressObject, forApi = false }) => {
  if (forApi) {
    let address = {};
    address['city'] = addressObject?.['city'];
    address['state'] = addressObject?.['state'];
    address['country'] = addressObject?.['country'];
    address['pincode'] = addressObject?.['pincode'];
    address['address'] = addressObject?.['address'];
    const intrim = deleteEmptyValues(address);
    if (Object.keys(intrim).length > 0) return intrim;
    return undefined;
  }
  let addressString =
    addressObject?.address +
    ' ' +
    addressObject?.city +
    ',' +
    addressObject?.state +
    ',' +
    addressObject?.country +
    ',' +
    addressObject?.pincode;

  return addressString;
};

//insert elemet in array at specific position
export const insertElement = (arr, index, element) => {
  const exist = arr?.findIndex((i) => i?.id === element?.id);
  // if (exist !== -1) return; /* Removed to add previous tasks to earlier tasks */
  if (index === -1) {
    arr.push(element);
  } else {
    arr.splice(index, 0, element);
  }
  return arr;
};

export const compareTwoArrays = (arr1, arr2) => {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
};

export const getEditedFields = ({ initialValues = {}, updatedData }) => {
  let final = {};
  if (Object.keys(initialValues).length) {
    Object.keys(initialValues).forEach((i) => {
      if (Array.isArray(initialValues[i])) {
        if (!compareTwoArrays(initialValues[i], updatedData[i])) {
          final[i] = updatedData[i];
        }
      } else if (initialValues[i] !== updatedData[i]) {
        if (!initialValues[i] && !updatedData[i]);
        else {
          final[i] = updatedData[i];
        }
      }
    });
  }

  return final;
};

export const getLabelByValue = (data, value) => {
  const item = data?.find((item) => item?.value === value);
  return item ? item?.label : null;
};

export const deleteEmptyValues = (object, dept = 1) => {
  for (let i = 0; i < dept; i++) {
    Object.keys(object).forEach((i) => {
      if (typeof object[i] === 'object' && !Object.keys(object[i]).length) {
        return delete object[i];
      }
      if (typeof object[i] === 'object' && Object.keys(object[i]).length)
        deleteEmptyValues(object[i]);

      if (!object[i]) return delete object[i];
    });
  }

  return object;
};

export const getPermission = (permissions, id) => {
  if (!permissions || !id) return { read: false, write: false };
  const keys = id ? id.toString().split('.') : [];
  let final = permissions;
  keys?.forEach((key) => {
    final = final?.[key];
  });

  return final;
};

const handleCustomerDependantFields = ({ formVals, field }) => {
  const customerFields = JSON.parse(localStorage.getItem('customerFields')).find(
    (item) => item?.title === 'Insured Details',
  );
  const customerType = customerFields?.fields?.find((item) => item?.name === 'customer_type');
  const retailCustomerId = customerType?.options?.find(
    (item) => (item?.name).toLowerCase() === 'retail',
  )?.id;
  const customer_type = formVals?.customer_type;
  const insuredMembers = formVals?.insured_members;

  if (field?.name === 'date_of_birth') {
    if (customer_type?.label?.toLowerCase() === 'retail' || customer_type === retailCustomerId)
      return false;
    return true;
  }
  if (field?.name === 'insured_members') {
    if (customer_type?.label?.toLowerCase() === 'retail' || customer_type === retailCustomerId)
      return false;
    return true;
  }
  if (field?.name === 'anniversary') {
    if (customer_type?.label?.toLowerCase() === 'retail' || customer_type === retailCustomerId)
      return false;
    return true;
  }
  if (
    field?.name === 'nature_of_business' ||
    field?.name === 'gst_registered' ||
    field?.name === 'fax_number'
  ) {
    if (customer_type?.label?.toLowerCase() === 'corporation') return false;

    return true;
  }

  if (field?.name === 'local_status') {
    if (customer_type?.label?.toLowerCase() === 'corporation') return false;

    return true;
  }

  if (field?.name === 'cp_name') {
    if (customer_type?.label?.toLowerCase() === 'corporation') return false;

    return true;
  }

  if (field?.name === 'cp_desg') {
    if (customer_type?.label?.toLowerCase() === 'corporation') return false;

    return true;
  }
  // if (field?.name === '"gst_registered"') {
  //   if (customer_type?.label?.toLowerCase() === 'corporation') return false;

  //   return true;
  // }
  // if (field?.name === '"fax_number"') {
  //   if (customer_type?.label?.toLowerCase() === 'corporation') return false;

  //   return true;
  // }

  if (field?.name === 'customer_name') {
    if (customer_type?.label?.toLowerCase() === 'corporation') return true;

    return false;
  }

  if (field?.name === 'corp_name') {
    if (customer_type?.label?.toLowerCase() === 'corporation') return false;

    return true;
  }

  if (field?.name === 'self') {
    if (insuredMembers?.includes(111)) return false;
    return true;
  }
  if (field?.name === 'spouse') {
    if (insuredMembers?.includes(112)) return false;
    return true;
  }
  if (field?.name === 'son') {
    if (insuredMembers?.includes(113)) return false;
    return true;
  }
  if (field?.name === 'daughter') {
    if (insuredMembers?.includes(114)) return false;
    return true;
  }
  if (field?.name === 'mother') {
    if (insuredMembers?.includes(115)) return false;
    return true;
  }
  if (field?.name === 'father') {
    if (insuredMembers?.includes(116)) return false;
    return true;
  }
};

const addProductPreferences = ({ array, categoriesOptions, productOption }) => {
  const fields = [];
  // const clientHelper = useBasicHelper.getState().clientHelper;
  for (let i = 0; i < array?.length; i++) {
    const obj = array[i];

    if (obj?.name === 'preference_category') {
      fields.push({
        ...array[i],

        label: 'Product Category',
        name: 'preference_category',
        id: 'preference_category',
        placeholder: 'Product Category',
        type: 'select',
        options: obj?.id ? categoriesOptions : [],
        component: SelectInput,
        fetchOptions: async () => obj?.id ?? searchApis({ api: 'insurance_category' }),
        required: false,
        // since dependencies are really really dynamic, we can't rely on previously-used useEffect inside select input
        // and hence need something different, a la customDispatch
        // will be received inside selectInput.
        customChange: (fullRawName, selectedItem) => {
          if (selectedItem.value) {
            localStorage.setItem('preferenceCategory', selectedItem.value);
            window.dispatchEvent(
              new CustomEvent('productPreference', {
                detail: { value: selectedItem.value },
              }),
            );
          }
        },
      });
    } else if (obj?.name === 'preference_product') {
      fields.push({
        ...array[i],
        label: 'Product Type',
        name: 'preference_product',
        id: 'preference_product',
        placeholder: 'Product Type',
        type: 'select',
        required: false,
        component: SelectInput,
        // options: obj?.id ? productOption : [],
        options: [],
        dependency: ['preference_product'],
        // eventDetail will contain event dispatched by us in prev component
        dependantFetchOptions: async (_form, eventDetail) => {
          // const id = eventDetail?.value;
          const id = JSON.parse(localStorage.getItem('preferenceCategory'));
          if (!id) return;
          const resp = await searchInsuranceApis({ api: 'insurance_category', id });
          return resp?.data?.data[0]?.product_type?.map((i) => ({
            name: i.name,
            label: i.name,
            value: i.id,
            id: i.id,
          }));
        },
      });
    }
  }

  return fields;
};
const AddProductComponents = ({ array, addProduct, setAddProduct, productOption }) => {
  const fields = [];

  for (let i = 0; i < array?.length; i++) {
    const obj = array[i];
    if (obj?.name === 'product') {
      fields.push({
        ...array[i],
        id: 'product',
        label: 'Product Name',
        placeholder: 'Product Name',
        options: productOption,
        component: SelectInput,
        customChange: ({ field, value, form }) => {
          if (value) {
            setAddProduct(true);
          } else {
            setAddProduct(false);
          }
        },
      });
    } else if (obj?.name === 'document') {
      fields.push({
        ...array[i],
        component: Uploadbox,
        dragger: false,
        required: addProduct,
        id: 'document',
      });
    } else if (obj?.name === 'expiry_date') {
      fields.push({
        ...array[i],
        customOnChange: ({ val, form, field }) =>
          form?.setFieldValue(field.name, dayjs(val).format('YYYY-MM-DD')),
        component: DateTime,
        placeholder: 'YYYY-MM-DD',
        required: addProduct,
        id: 'expiry_date',
      });
    }
  }
  return fields;
};

/**
 * @param {{
 *   id: number,
 *   title: string,
 *   sub_title: string
 *   fields: {
 *     id: number,
 *     name: string,
 *     label: string,
 *     type: 'text' | 'select' | 'textarea' | 'number' | 'tel' | 'date' | 'checkbox' | 'radio' | 'email' | 'password' | 'country' | 'currency',
 *     required: boolean,
 *     options: {id: number, name: string}[],
 *   }[]
 * }[]} data
 *
 * {{string: string}} defaultValueObj
 * const
 */
export const formatData = (
  data,
  productOption,
  addProduct,
  setAddProduct,
  categoriesOptions,
  defaultValueObj = {},
  returnRequired = false,
) => {
  const finalData = {};
  const initVals = {};
  if (!data) return {};
  data.forEach((section) => {
    let newSection = section;
    if (section?.title === 'Previous Insurance') {
      newSection = {
        ...section,
        fields: [
          {
            name: 'products',
            type: 'fieldArray',
            // label: <label className='text-xs xl:text-sm text-primary-90'>Add selected product document</label>,
            addButtonText: '+ Product',
            buttonsClassName: '-ml-4 -mt-8',
            valueSchema: { product: null, document: '', expiry_date: null },
            component: CustomFieldArray,
            divClasses: 'col-span-full',
            gridCols: 'grid-cols-3 py-2',
            options: [],
            fields: AddProductComponents({
              array: section?.fields,
              addProduct,
              setAddProduct,
              productOption,
            }),
          },
        ],
      };
    } else if (section?.title === 'Product Preferences') {
      newSection = {
        ...section,
        fields: [
          {
            name: 'preference_product',
            type: 'fieldArray',
            id: 'preference_product',
            buttonsClassName: '-ml-4',
            // label: <label className='text-xs xl:text-sm text-primary-90'>Add selected product document</label>,
            addButtonText: '+ Product',
            valueSchema: { preference_category: '', preference_product: '' },
            component: CustomFieldArray,
            customOnChange: ({ field, form }) => {
              // useBasicHelper.getState().addPreferenceCategory(field.value);
            },
            divClasses: 'col-span-full',
            gridCols: 'grid-cols-3 py-2',
            options: [],
            fields: addProductPreferences({
              array: section?.fields ?? [],
              categoriesOptions,
              productOption,
            }),
          },
        ],
      };
    }

    const fields = newSection?.fields.map((field) => {
      const formattedField = {
        ...field,
        placeholder: `${field.label}`,
      };
      const fieldDefaultValue = defaultValueObj[field.name];
      if (fieldDefaultValue) {
        initVals[field.name] = fieldDefaultValue;

        formattedField.defaultValue = fieldDefaultValue;
      } else {
        initVals[field.name] = '';
      }

      if (field.type === 'checkbox' && field.name.includes('address')) {
        formattedField.name = 'checkaddress';
        formattedField.checked = false;
        formattedField.divClasses = 'col-span-3 self-end';

        initVals.checkaddress = true;
      }

      if (field.type === 'textarea') {
        formattedField.isMultiline = true;

        if (field.name === 'description' || field.name === 'nature_of_business') {
          formattedField.divClasses = 'col-span-full';
          formattedField.rows = 2;
        }
      }

      if (field.type === 'country') {
        formattedField.options = countryOptions;
        formattedField.type = 'select';
        // formattedField.customChange = (_, __, form) => {
        //   form?.setFieldValue('city', '');
        // };
      }
      // if (field.type === 'state') {
      //   formattedField.dependency = ['country'];
      //   formattedField.dependantFetchOptions = async (form) => {
      //     if (!form?.values?.country) return [];
      //     const country = form?.values?.country;
      //     const resp = await getStateOptions(country);
      //     if (resp) {
      //       return resp;
      //     } else return [];
      //   };
      // }
      // if (field.type === 'country') {
      //   formattedField.options = countryOptions;
      //   formattedField.type = 'select';
      //   formattedField.customChange = (_, __, form) => {
      //     form?.setFieldValue('city', '');
      //   };
      // }

      if (field?.name === 'customer_type') {
        formattedField.setObject = true;
      }
      if (field?.name === 'email_address') {
        formattedField.want_validation = false;
      }
      if (field?.name === 'cp_name') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'customer_type';
        formattedField.shouldHideField = handleCustomerDependantFields;
        formattedField.maxLength = 20;
      }
      if (field?.name === 'anniversary') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'customer_type';
        formattedField.placeholder = 'YYYY-MM-DD';
        formattedField.shouldHideField = handleCustomerDependantFields;
      }
      if (field?.name === 'insured_members') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'customer_type';
        formattedField.shouldHideField = handleCustomerDependantFields;
      }
      if (field?.name === 'spouse') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'insured_members';
        formattedField.shouldHideField = handleCustomerDependantFields;
      }
      if (field?.name === 'self') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'insured_members';
        formattedField.shouldHideField = handleCustomerDependantFields;
      }
      if (field?.name === 'daughter') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'insured_members';
        formattedField.shouldHideField = handleCustomerDependantFields;
      }
      if (field?.name === 'mother') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'insured_members';
        formattedField.shouldHideField = handleCustomerDependantFields;
      }
      if (field?.name === 'son') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'insured_members';
        formattedField.shouldHideField = handleCustomerDependantFields;
      }
      if (field?.name === 'father') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'insured_members';
        formattedField.shouldHideField = handleCustomerDependantFields;
      }
      if (field?.name === 'meeting_date') {
        formattedField.placeholder = 'YYYY-MM-DD';
      }
      // if (field?.name === 'product_type') {
      //   formattedField.options = productOption;
      // }

      if (field?.name === 'cp_desg') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'customer_type';
        formattedField.shouldHideField = handleCustomerDependantFields;
        formattedField.maxLength = 20;
      }

      if (field?.name === 'local_status') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'customer_type';
        formattedField.shouldHideField = handleCustomerDependantFields;
      }

      if (field?.name === 'date_of_birth') {
        formattedField.dependent = true;
        formattedField.placeholder = 'YYYY-MM-DD';
        formattedField.dependent_on = 'customer_type';
        formattedField.shouldHideField = handleCustomerDependantFields;
      }
      if (
        field?.name === 'nature_of_business' ||
        field?.name === 'gst_registered' ||
        field?.name === 'fax_number'
      ) {
        formattedField.dependent = true;
        formattedField.dependent_on = 'customer_type';
        formattedField.shouldHideField = handleCustomerDependantFields;
      }

      if (field?.name === 'assign_to_employee') {
        formattedField.byBranch = true;
      }
      if (field?.name === 'branch_of') {
        formattedField.byUser = true;
        formattedField.fetchOptions = async () => await getBranches();
      }

      if (field.options.length)
        formattedField.options = field.options.map((option) => ({
          ...option,
          label: option?.name,
          value: option?.id,
        }));

      if (field.name === 'fax_number') {
        formattedField.matches = /^[\d.+]*$/g;
        formattedField.not_match_error = 'not a valid fax number';
      }

      switch (field.type.toLowerCase()) {
        case 'select':
        case 'country':
          formattedField.component = SelectInput;
          break;
        case 'date':
          formattedField.component = DateTime;
          break;
        case 'tel':
          formattedField.component = TextInput;
          break;
        case 'search':
          formattedField.component = SearchAPI;
          break;
        case 'file':
          formattedField.component = Uploadbox;
          break;
        case 'checkbox':
          formattedField.component = Checkbox;
          break;
        case 'currency':
          formattedField.component = CurrencyInput;
          break;
        case 'multi_select':
          formattedField.component = MultiSelect;
          break;
        default:
          formattedField.component = TextInput;
          break;
      }

      if (field?.name === 'customer_name') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'customer_type';
        formattedField.shouldHideField = handleCustomerDependantFields;
        formattedField.component = CustomerNameWrapper;
      }

      if (field?.name === 'corp_name') {
        formattedField.dependent = true;
        formattedField.dependent_on = 'customer_type';
        formattedField.shouldHideField = handleCustomerDependantFields;
        formattedField.component = CorporationNameWrapper;
      }

      return field?.type === 'fieldArray' ? field : formattedField;
    });

    finalData[section.title] = {
      ...(section.title?.toLowerCase() === 'registered details' && {
        dependent: true,
        dependent_on: 'checkaddress',
        dependent_value: false,
      }),
      fields,
    };
  });

  const insuredFields = finalData?.['Insured Details']?.fields;
  const customerTypeindex = insuredFields?.findIndex((fl) => fl.name === 'customer_type');
  const fieldToMove = insuredFields?.splice(customerTypeindex, 1)[0];
  if (fieldToMove) insuredFields?.unshift(fieldToMove);

  return [finalData, initVals];
};

/**
 * @param {(...args)=> any} func function to debounce
 * @param {number} wait in milliseconds
 * @returns the original function, but debounced
 */
export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const ctx = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(ctx, args), wait);
  };
};

export { getItem, setItem, deleteItem };

/**
*
* @param {
  [
    {
      id: number,
      title: string,
      sub_title: string
      fields: [
        {
          id: number,
          name: string,
          label: string,
          type: string,
          required: boolean,
          options: [{id: number, name: string}],
        }
      ]
    }
  ]
* } sections stuff we get from `res.data.data`(by calling `getCustomerFields`)
* @returns nice array of antd-table-compatible columns
*/
export const formatColumnData = (sections) =>
  sections?.flatMap((section) =>
    section?.fields?.map((column) => ({
      id: column.id,
      title: column.label,
      key: column.name,
      dataIndex: column.name,
    })),
  );

export const formatDate = ({ dateString, format = 'YYYY-MM-DD HH:mm', toUtc = true }) =>
  dateString
    ? toUtc
      ? dayjs(dateString).utc().format(format)
      : dayjs(dateString).format(format)
    : null;

export const handleDefaultValues = (props) => {
  const user = props?.user || {};
  if (user?.user_type === 'client') {
    return '';
  } else {
    return { name: user?.name, id: user?.id };
  }
};

export const getNameLetter = ({ name }) => name?.split(' ')[0].charAt(0).toUpperCase() || '-';

export const generatePermittedTabs = (tabs, permissions) => {
  return tabs.map((tab) => {
    let isAllowed = false;
    if (tab?.key === 'my-dashboard') {
      isAllowed = true;
    } else {
      if (Array.isArray(tab?.id)) {
        tab.id.forEach((item) => {
          if (getPermission(permissions, item)?.read) {
            isAllowed = true;
          }
        });
      }
    }
    if (isAllowed) {
      return tab;
    }

    return tab?.id ? (getPermission(permissions, tab.id)?.read ? tab : {}) : {};
  });
};

export const customFormFields = ({ fields = {} }) => {
  let finalObject = {};
  let initialValues = {};
  return [initialValues, finalObject];
};

export const prepareFormFieldsData = ({
  fields = {},
  fieldsToExclude = [],
  sectionsToExclude = [],
  defaultValues = {},
  toggle,
}) => {
  let finalObject = {};
  let initialValues = {};

  //deleteing the excluded sections
  Object.keys(fields).forEach((item) => {
    if (!sectionsToExclude.includes(item)) {
      finalObject[item] = fields[item];
    }
  });

  //creating inital values and removing excluded fields
  Object.keys(finalObject).forEach((key) => {
    let requiredFieldFlag = false;
    const finalFields = fields?.[key]?.fields?.map((field) => {
      if (!fieldsToExclude.includes(field?.name)) {
        if (field.required) {
          requiredFieldFlag = true;
        }
        if (field.type === 'fieldArray') {
          const fieldArrayInits = {};
          field.fields.forEach((item) => {
            fieldArrayInits[item.name] = '';
          });
          initialValues[field?.name] = [fieldArrayInits];
          // console.log("fieldArrayInits====>", fieldArrayInits);
        } else if (field.type === 'checkbox') {
          /**temporary fix for default value of checkbox */
          // initialValues[field?.name] = false;
          initialValues[field?.name] = field?.checked || false;
        } else if (field.type === 'date') {
          initialValues[field?.name] = null;
        } else {
          initialValues[field?.name] = '';
          if (field.type === 'number') {
            initialValues[field?.name] = '';
          }
        }
        return field;
      }
      return undefined;
    });
    finalObject = {
      ...finalObject,
      [key]: {
        ...finalObject[key],
        fields: finalFields,
        subTitle: toggle && !requiredFieldFlag ? null : finalObject[key]['subTitle'],
      },
    };
  });
  const modifiedInitials = { ...initialValues, ...defaultValues };
  return [modifiedInitials, deleteUndefinedElements(finalObject)];
};

export const deleteUndefinedElements = (data) => {
  if (Array.isArray(data)) {
    return data.filter((element) => {
      return element !== undefined;
    });
  } else if (typeof data === 'object') {
    let pureData = data;
    Object.keys(pureData).forEach((key) => {
      const filteredFields = deleteUndefinedElements(pureData?.[key]?.fields);
      pureData = {
        ...pureData,
        [key]: {
          ...pureData[key],
          fields: filteredFields,
        },
      };
    });
    return pureData;
  }
  return;
};

export const formatFormValues = (data) => {
  let finalObject = {};
  Object.keys(data).forEach((key) => {
    if (('type', Array.isArray(data[key]))) {
      finalObject[key] = data[key];
    } else if (typeof data[key] === 'object') {
      finalObject[key] = data[key]?.id;
    } else {
      finalObject[key] = data[key];
    }
  });
  return finalObject;
};

export const getCustomerFields = async () => {
  let fields = [];
  const fieldsInStorage = getItem('customerFields');
  if (fieldsInStorage?.length) fields = fieldsInStorage;
  else {
    const res = await getFields({ type: 'customer' });
    fields = res.data?.data || [];
    setItem('customerFields', fields);
  }

  return fields;
};

//get only required fields from the fields object
export const retrieveRequiredFields = (fields) => {
  Object.keys(fields).forEach((section) => {
    const intrimFields = fields[section].fields.filter((item) => item?.required === true);
    if (intrimFields.length) {
      fields = {
        ...fields,
        [section]: {
          ...fields[section],
          fields: intrimFields,
        },
      };
    } else {
      delete fields[section];
    }
  });
  return fields;
};

export const parseWeekDays = (values) => {
  if (!Array.isArray(values)) return [];

  let days = [];
  for (let i = 0; i < WEEK_DAYS.length; i++) {
    if (values?.includes(WEEK_DAYS[i].value)) {
      days.push(WEEK_DAYS[i].label);
    }
  }
  return days;
};

export const getInsuranceCategory = (values) => {
  // const resp = insuranceCategoryManagement({ id: values });
};

export const capitalise = (text) => (text ? text[0].toUpperCase() + text.slice(1) : text);

export const toPdfConvert = (currentRef) => {
  html2canvas(currentRef.current, {
    logging: false,
    useCORS: true,
    scale: 1,
  }).then((canvas) => {
    const imgData = canvas.toDataURL();
    console.log('imgData: ', imgData);
  });
};

export const getMimeType = (assetType = 'pdf') => {
  const type = assetType.toLowerCase();

  if (type === 'document') return 'application/pdf';
  if (type === 'video') return 'video/mp4';
  if (type === 'image') return 'image/jpeg';

  return 'application/octet-stream';
};

export const ChangeSeconds = (time_spent) => {
  const arr = time_spent?.split(':');
  const restartTime = arr[0] * 3600 + arr[1] * 60 + +arr[2];

  // const hrs = time_spent.slice(0, 2);
  // const min = time_spent.slice(3, 5);
  // const sec = time_spent.slice(6, 8);

  // const restartTime = hrs * 3600 + min * 60 + sec;
  return restartTime;
};

export const timeDiffrence = (start, end) => {
  const currentDate = dayjs().format('YYYY-MM-DD');
  const startTime = new Date(`${currentDate}T${start}`);
  const endTime = new Date(`${currentDate}T${end}`);

  let difference = endTime - startTime;

  const hours = Math.floor(difference / (1000 * 60 * 60));
  difference %= 1000 * 60 * 60;
  const minutes = Math.floor(difference / (1000 * 60));
  difference %= 1000 * 60;
  const seconds = Math.floor(difference / 1000);

  return { hours, minutes, seconds };
};

export const getKeyOfNextIfPrevIsEmpty = (tabs) => {
  for (let i = 1; i < tabs.length; i++) {
    const prevElement = tabs[i - 1];
    const currentElement = tabs[i];

    if (Object.keys(prevElement).length === 0 && prevElement.constructor === Object) {
      return currentElement.key;
    }
  }
  return '1'; // Return 1 as default active tab
};

export const removeEmptyFields = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== '' && value !== null && value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});
};
