import React, { useEffect, useState } from 'react';
import { useLeads } from '../store';
import { NOTIFICATION_MSG } from 'constants';
import OnlineQuoteCard from '../online-quote-comp/QuoteCard';
import { loginInquiry } from 'service/api-service';
import { Toast } from 'components';

const OnlineQuote = () => {
  return (
    <div className='h-full flex flex-col justify-center items-center px-4'>
      <OnlineQuoteCard />
    </div>
  );
};

export default OnlineQuote;
