import { TextInput } from 'components';
import { useEffect, useState } from 'react';

const CustomerNameWrapper = ({ form, ...props }) => {
  const [required, setRequired] = useState(false);

  useEffect(() => {
    if (form?.values && form?.values?.customer_type?.label === 'Retail') {
      setRequired(true);
    }

    if (form?.values && !form?.values?.customer_type?.label === 'Retail') {
      setRequired(false);
    }
  }, [form]);

  return (
    <div>
      <TextInput
        form={form}
        {...props}
        required={required}
        // disabled={!required}
        placeholder={props?.placeholder}
        // styleInput={{ cursor: required ? "auto" : "not-allowed" }}
      />
    </div>
  );
};

export default CustomerNameWrapper;
