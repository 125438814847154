import ExcelMapper from 'components/mapper';
import { importClients } from 'service/api-service';

const ImportClients = () => {
  return (
    <div>
      <ExcelMapper API={importClients} />
    </div>
  );
};

export default ImportClients;
