import { useEffect, useState } from 'react';
import { Button, InsurerSideBar } from 'components';
import { getCommission, getEmployees } from 'service/api-service';
import AddCommission from './addCommission';
import CommissionTable from './CommissionTable';

const EmployeeCommisionForm = (props) => {
  const { permissions } = props;
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setAddModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [editInitials, setInitials] = useState();
  const [sideBarData, setSideBarData] = useState([]);

  const fetchEmployees = async ({ q = '' } = {}) => {
    setLoading(true);
    const resp = await getEmployees({ query: `q=${q}` });
    if (Array.isArray(resp.data?.data?.result)) setSideBarData(resp.data.data.result);
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    const data = tableData.filter((item) => item?.employee?.id === +selectedEmployeeId);
    setInitials(data[0]);
    setCardData(data);
  }, [selectedEmployeeId, tableData]);

  const toggleAddAsset = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  const getCommissionList = async () => {
    setLoading(true);
    const resp = await getCommission({ type: 'employee' });
    setLoading(false);
    setTableData(resp.data.data);
  };

  useEffect(() => {
    getCommissionList();
  }, []);

  return (
    <>
      <div className='flex gap-4 justify-between'>
        <InsurerSideBar
          checkbox={false}
          setAgent={setSelectedEmployeeId}
          agent={selectedEmployeeId}
          data={sideBarData}
          header='Employees'
          loading={loading}
          width={300}
          showSearch={true}
          onSearch={fetchEmployees}
        />
        <div className='w-full bordered bg-white relative rounded-lg max-h-[80vh]'>
          {props.tabs}
          <div className=' overflow-y-auto h-[360px]  p-4'>
            <h1
              className={`${
                selectedEmployeeId === null ? 'text-grey-90 flex justify-center mt-20   ' : 'hidden'
              }`}
            >
              Please select any agent from left panel
            </h1>

            {selectedEmployeeId !== null && (
              <Button
                classes={`z-10 absolute right-4 top-2`}
                onClick={toggleAddAsset}
                text='+ Commission'
                varient='primary'
              ></Button>
            )}
            {selectedEmployeeId && (
              <CommissionTable
                // key={selectedEmployeeId}
                permissions={permissions}
                loading={loading}
                setLoading={setLoading}
                data={cardData}
                refresh={getCommissionList}
                commissionSet='employee'
                id={selectedEmployeeId}
              />
            )}
            <AddCommission
              open={showAddModal}
              toggle={toggleAddAsset}
              setLoading={setLoading}
              loading={loading}
              editInitials={editInitials}
              refresh={getCommissionList}
              commissionSet='employee'
              id={selectedEmployeeId}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeCommisionForm;
