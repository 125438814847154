/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { Space } from 'antd';
/** internal deps */
import {
  ContentHeader,
  CustomModal,
  FormContainer,
  IconButton,
  TableComponent,
  Toast,
} from 'components';
import { breadcrumbObjTaxSlab, taxSlabColumns, taxSlabFields } from './data';
import { elementData } from 'elements';
import Button from 'components/button';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { NOTIFICATION_MSG } from 'constants';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { SecureComponent } from 'permission-provider';
import { deleteSlab, getSlab, postSlab, updateSlab } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const TaxSlabs = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editInitials, setInitials] = useState();
  const [selectedSlab, setSelectedSlab] = useState();
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: taxSlabFields,
  });

  const fetchData = async () => {
    setLoading(true);
    const resp = await getSlab();
    if (resp?.data?.status) {
      setTableData(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to load data');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleAddSlab = () => {
    setInitials(undefined);
    setAddModal((pre) => {
      return !pre;
    });
  };

  const onAddSlab = async (data) => {
    setLoading(true);
    const finalPayload = {
      ...data,
      am_from: Number(data?.am_from),
      am_to: Number(data?.am_to),
      value: Number(data?.value),
    };
    const resp = await postSlab({ data: finalPayload });
    if (resp?.data?.status) {
      fetchData();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleAddSlab();
    setLoading(false);
  };

  const handleEditClick = (record) => {
    setSelectedSlab(record);
    setInitials(record);
    setAddModal((pre) => {
      return !pre;
    });
  };
  const onEditSlab = useCallback(
    async (data) => {
      setLoading(true);
      const editFields = getEditedFields({ initialValues: editInitials, updatedData: data });
      if (Object.keys(editFields).length) {
        const resp = await updateSlab({
          id: selectedSlab?.id,
          data: editFields,
        });

        if (resp?.data?.status) {
          Toast(
            'success',
            NOTIFICATION_MSG.success,
            resp?.data?.msg || 'Slab updated successfully.',
          );
          fetchData();
        } else {
          Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
        }
        toggleAddSlab();
        setLoading(false);
      } else {
        setLoading(false);
        Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save.');
      }
    },
    [editInitials, selectedSlab],
  );

  const toggleDeleteSlab = () => setDeleteModal((pre) => !pre);

  const onDeleteSlab = async () => {
    const id = selectedSlab?.id;
    if (!id) return;
    setLoading(true);
    const resp = await deleteSlab({ id });
    if (resp?.data?.status) {
      fetchData();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Slab deleted successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteSlab();
    setLoading(false);
  };

  /* search function */
  const searchFunction = useCallback(
    (e) => {
      if (!e.target.value) setSearchQuery('');
      const query = e.target.value;
      setSearchQuery(query);
      const fuse = new Fuse(tableData, {
        keys: ['am_from', 'am_to', 'type', 'value'],
        threshold: 0.2,
      }).search(e.target.value);
      setFilteredData(fuse.map((i) => i.item));
    },
    [tableData],
  );
  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={searchFunction}
        data={breadcrumbObjTaxSlab}
        btnText='+ Tax Slab'
        buttonId={elementData?.taxSlab?.permissions?.create}
        onClick={toggleAddSlab}
      />
      <TableComponent
        loading={loading}
        getRowClassName={rowCommonClass}
        columns={[
          ...taxSlabColumns,
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
              <Space size='middle'>
                <IconButton
                  title='Edit'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.taxSlab.permissions.update}
                  onClick={() => handleEditClick(record)}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Delete'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.taxSlab.permissions.delete}
                  onClick={() => {
                    setSelectedSlab(record);
                    toggleDeleteSlab();
                  }}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton>
              </Space>
            ),
          },
        ]}
        dataSource={searchQuery ? filteredData : tableData}
        noDataAction={fetchData}
      />
      {/* add slab modal */}
      <CustomModal
        title={editInitials ? 'Edit Slab' : 'Add Slab'}
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddSlab}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 '>
            {editInitials
              ? 'Edit slab details'
              : 'To add a new slab, enter the details of the slab in the input field below.'}
          </span>

          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditSlab : onAddSlab}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddSlab();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.taxSlab.permissions.update
                        : elementData.taxSlab.permissions.create
                    }
                    text={editInitials ? 'Update Slab' : 'Add Slab'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* delete slab confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteSlab}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the slab details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteSlab}
            />
            <Button
              text='Delete Slab'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteSlab}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(TaxSlabs);
