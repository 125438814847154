/** external deps */
import React, { useContext } from 'react';
/** internal deps */
import Assistant from 'assets/png/assistant.png';
import { Context } from 'store/context';

const DashboardCard = () => {
  const { user } = useContext(Context);
  const userName = user?.name?.charAt(0)?.toUpperCase() + user?.name?.slice(1);

  return (
    <>
      <div className={`rounded-[14px] bg-primary-light/40 shadow-custom`}>
        <div className='flex relative min-h-40 p-4'>
          <div className='pt-4 pb-0 w-9/12'>
            <h2 className='font-medium text-xs'>Hello {userName || 'User'},</h2>
            <h1 className='font-medium text-xl'>Have a Great Day!</h1>
            <p className='text-xxs'>
              Welcome! We believe in you & look forward to support you along the way.
            </p>
          </div>
          <img
            src={Assistant}
            alt='assistant'
            className='absolute right-0 bottom-0 overflow-y-visible max-h-[110%]'
          />
        </div>
      </div>
    </>
  );
};

export default DashboardCard;
