/** external deps */
import React, { useState, useLayoutEffect, useContext } from 'react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import jwt_Decode from 'jwt-decode';
/** external deps */
import {
  AuthLayout,
  Toast,
  FormContainer,
  Button,
  TextInput,
  PasswordInput,
  Checkbox,
} from 'components';
import { initialValues } from './data';
import { login } from 'service/api-service';
import { setItem, getItem } from 'utils';
import { NOTIFICATION_MSG } from 'constants';
import { EmailOutline, InsuranceLogo } from 'assets/icons';
import { Context } from 'store/context';
import navigationService from 'service/navigation';

const PosLogin = () => {
  const navigate = useNavigate();
  const { setToken } = useContext(Context);
  const [access_token, setauthenticated] = useState(null);
  const [loading, setLoading] = useState(false);
  useLayoutEffect(() => {
    const tokens = getItem('AUTH');
    const { access_token } = tokens || { access_token: null };
    setauthenticated(access_token);
  }, []);

  const fields = {
    login: {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Email Address',
          name: 'email',
          id: 'email',
          placeholder: 'Email Address',
          type: 'email',
          required: true,
          component: TextInput,
          rightIcon: <EmailOutline />,
          // maxLength: 30,
        },
        {
          label: 'Password',
          name: 'password',
          id: 'password',
          placeholder: 'Password',
          type: 'password',
          required: true,
          maxLength: 30,
          validate: false,
          loginOnEnter: (e, form) => {
            if (e?.key === 'Enter' && form?.values?.checkbox === true) {
              onLogin(form?.values);
            } else if (e?.key === 'Enter') {
              Toast('info', NOTIFICATION_MSG.info, 'Kindly agree for Terms & Condition first');
            }
          },
          component: PasswordInput,
          extraRightRender: () => {
            return (
              <>
                <Button
                  text='Forgot Password ?'
                  type='button'
                  variant='text'
                  classes={'flex-end text-xs text-primary-60 bg-white !px-2'}
                  onClick={() => {
                    if (navigationService.navigation) {
                      navigationService.navigation('forgot-password');
                    }
                  }}
                />
              </>
            );
          },
        },
        {
          label: (
            <span>
              By continuing , you agree to our{' '}
              <Link to='/terms-condition' target={'__blank'} className='text-primary-90'>
                Terms & Condition
              </Link>
            </span>
          ),
          name: 'checkbox',
          id: 'checkbox',
          required: true,
          type: 'checkbox',
          onKeyDown: (e, form) => {
            if (e?.key === 'Enter' && form?.values?.checkbox === true) {
              onLogin(form?.values);
            }
          },
          component: Checkbox,
          checked: false,
          divClasses: 'self-end',
        },
      ],
    },
  };

  const onLogin = async (data) => {
    setLoading(true);
    const posData = { ...data, pos_agent: true };

    const resp = await login({ data: posData });
    if (resp?.data?.status) {
      const token = resp.data.tokens;
      setToken(token);
      setItem('AUTH', token);

      const posAgent = jwt_Decode(token?.access_token);
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Login Success');
      if (resp.data.contract_status === 'pending') {
        navigate('/pos/pos-contract');
      } else if (resp.data.contract_status === 'submitted') {
        navigate('/pos/pos-review');
      } else if (resp.data.contract_status === 'approved' && !resp.data.is_active) {
        navigate('/pos/pos-inactive');
      } else if (posAgent) {
        navigate('/pos');
      }
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Login Failed');
    }
    setLoading(false);
  };

  if (!!access_token) {
    return <Navigate replace to='/' />;
  } else {
    return (
      <AuthLayout showImage={true}>
        <div className='w-fit m-auto '>
          <InsuranceLogo svgClass='w-[90px] h-16' />
        </div>

        <div className='flex justify-center items-center flex-col mt-2'>
          <span className='text-l xl:text-xl font-medium'>Login to your Pos account</span>
          <span className='text-grey-90 text-x pb-[30px] text-center font-medium'>
            In order to access your pos account, please enter your credentials.
          </span>
        </div>

        <FormContainer
          accordionClass='grid grid-cols-1  gap-8'
          onSubmit={onLogin}
          initialValues={initialValues}
          fields={fields}
          // submitButtonProps={{ submitText: "Log into your pos Account" }}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, values }) => {
            return (
              <div className='bg-white flex flex-row justify-center py-4 '>
                <Button
                  disabled={values?.checkbox !== true}
                  loading={loading}
                  text={'Log in'}
                  variant='primary'
                  classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                />
              </div>
            );
          }}
        />
      </AuthLayout>
    );
  }
};

export default PosLogin;
