import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const Checklist = ({ option, onChange }) => {
  const [data, setData] = useState(option || []);

  const checkedData = data.filter((item) => item.value);
  const uncheckedData = data.filter((item) => !item.value);

  const handleCheckboxChange = (checkbox, event) => {
    const isChecked = event.target.checked;
    const payload = data.map((item) =>
      item.id === checkbox.id ? { ...item, value: isChecked } : item,
    );
    if (onChange) onChange(payload);
    setData(payload);
  };

  const renderCheckbox = (section) => {
    return (
      <motion.div layout className='flex gap-10 py-1' key={section.id}>
        <label className='checkbox-container !pl-0'>
          <span className='sr-only'>Read</span>
          <input
            type='checkbox'
            name={section.name}
            defaultChecked={section.value}
            onChange={handleCheckboxChange.bind(this, section)}
            data-id={section.id}
            data-type='read'
          />
          <span className='checked-box w-6 h-6 '></span>
        </label>
        <span
          className={`capitalize group-open:text-primary-90 ${section.value && 'line-through'}`}
        >
          {section.name}
        </span>
      </motion.div>
    );
  };

  useEffect(() => {
    setData(option);
  }, [option]);

  return (
    <div className=' grid grid-cols-2'>
      <div>
        <h1 className='text-s font-semibold'>Checklist</h1>
        {uncheckedData.length ? (
          uncheckedData.map(renderCheckbox)
        ) : (
          <h2 className='text-xxs'>(Feeling empty. Tick off some items & make my day)</h2>
        )}
      </div>
      <div>
        <h1 className='text-s font-semibold'>Completed Tasks</h1>
        {checkedData.length ? (
          checkedData.map(renderCheckbox)
        ) : (
          <h2 className='text-xxs'>(Feeling empty. Tick off some items & make my day)</h2>
        )}
      </div>
    </div>
  );
};

export default Checklist;
