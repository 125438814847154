// import navigationService from "service/navigation";
import { getPermission } from 'utils';

export const SecureComponent =
  (Component) =>
  ({ permissions, id, fallback, ...props }) => {
    if (!id || !permissions) {
      if (fallback) return fallback;
      return <Component permissions={permissions} {...props} />;
    }

    const permission = getPermission(permissions, id);
    if (permission?.read === false) {
      if (fallback) return fallback;
      return null;
    }
    return (
      <Component permissions={permissions} {...props} disabled={permission?.write === false} />
    );
  };
