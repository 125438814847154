import { endpoints } from 'constants/endpoints';
import Request from './client';

export const getTasks = async ({
  query = '',
  page = 1,
  period = '',
  startDate = '',
  endDate = '',
  status = '',
}) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.task.list(
      `q=${query}&page=${page}&status=${status}&start=${startDate}&end=${endDate}`,
    ),
  });
  return resp;
};
export const getMeetings = async ({
  query = '',
  page = 1,
  period = '',
  startDate = '',
  endDate = '',
  status = '',
}) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.meeting.list(
      `q=${query}&page=${page}&status=${status}&start=${startDate}&end=${endDate}`,
    ),
  });
  return resp;
};

export const editTask = async (
  props = {
    data: undefined,
    id: '',
  },
) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.task.update(props?.id),
    data: props?.data,
  });
  return resp;
};

export const editMeeting = async (
  props = {
    data: undefined,
    id: '',
  },
) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.meeting.update(props?.id),
    data: props?.data,
  });
  return resp;
};
export const taskManagement = async (
  props = {
    data: '',
    employee_id: '',
    method: 'get',
    id: '',
    customer_id: '',
    page: '',
    period: '',
  },
) => {
  const resp = await Request({
    method: props.method,
    API:
      props.method === 'delete' || props.method === 'patch'
        ? `task-meeting/task?id=${props?.id}&customer_id=${props.customer_id || ''}`
        : `task-meeting/task?page=${props?.page || ''}&customer_id=${
            props.customer_id || ''
          }&period=${props?.period || ''}&employee_id=${props.employee_id || ''}`,
    data: props?.data,
  });
  return resp;
};

export const meetingManagement = async (
  props = {
    data: '',
    method: 'get',
    id: '',
    customer_id: '',
    employee_id: '',
    page: 1,
    period: '',
  },
) => {
  const resp = await Request({
    method: props.method,
    API:
      props.method === 'delete' || props.method === 'patch'
        ? `task-meeting/meeting?id=${props?.id}&customer_id=${props.customer_id || ''}`
        : `task-meeting/meeting?customer_id=${props.customer_id || ''}&page=${
            props.page || ''
          }&period=${props.period || ''}&employee_id=${props.employee_id || ''}`,
    data: props?.data,
  });
  return resp;
};

export const commentManagement = async (props = { data: '', method: 'get', task_id: '' }) => {
  const resp = await Request({
    method: props.method,
    API:
      props.method === 'delete' || props.method === 'patch'
        ? `task-meeting/activities/comment?id=${props.task_id}`
        : `task-meeting/activities/comment?id=${props?.task_id}`,
    data: props?.data,
  });
  return resp;
};

export const getTaskByStatus = async ({ status = '' } = {}) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.task.status(status),
  });
  return resp;
};

export const getUpcomingMeetings = async ({ date = '' } = {}) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.meeting.upcoming(date),
  });
  return resp;
};

export const getTaskTimeline = async ({ id }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.task.activity(id),
  });
  return resp;
};
export const getSingleTask = async ({ id }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.task.single(id),
  });
  return resp;
};
export const getSingleMeeting = async ({ id }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.meeting.single(id),
  });
  return resp;
};
