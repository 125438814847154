import dayjs from 'dayjs';

export const grossPayColumn = [
  {
    title: 'Employees',
    key: 'employee',
    dataIndex: 'tot_emp',
  },
  {
    title: 'Gross Pay',
    key: 'gross_pay',
    dataIndex: 'gr_pay',
    render: (data) => (!Number.isInteger(data) ? data?.toFixed(2) : data),
  },
  {
    title: 'Deduction',
    key: 'deduction',
    dataIndex: 'deduct',
    render: (data) => data?.toFixed(2),
  },
  {
    title: 'Allowance',
    key: 'allowance',
    dataIndex: 'allow',
    render: (data) => data?.toFixed(2),
  },
];

export const parsedData = (data, timeline) => {
  const res = [];
  data?.forEach((item) => {
    res.push({
      'Employee Code': item?.user?.emp_code,
      'Employee Name': item?.user?.name,
      'Days in Month': dayjs(timeline.month).daysInMonth(),
      'Work Days': item?.days,
      'Gross Salary': item?.g_salary.toFixed(2),
      DA: item?.deduct,
      Allowance: item?.allow,
      'Tax Slab': item?.tx_slab,
    });
  });
  return res;
};

export const REFRESH_EVT = 'Payload';
