import { DatePicker, Spin } from 'antd';
import { TableComponent } from 'components';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { posEarningManagement } from 'service/api-service';
import { rowCommonClass } from 'utils/common';
import { columns } from './data';
const { RangePicker } = DatePicker;

const EarningTable = (props = {}) => {
  const agent = props?.agent;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // const [selectDate, setSelcetdate] = useState(false);
  // const [currentDate, setCurrentDate] = useState(dayjs());
  // const [prevDate, setPrevDate] = useState(dayjs().subtract(1, 'month'));

  const getPosDetails = async () => {
    if (agent !== undefined) {
      setLoading(true);
      const resp = await posEarningManagement({ id: agent });
      setData(resp?.data?.data);
      if (resp?.data?.data) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getPosDetails();
  }, [agent]);

  const getDateRange = async (e) => {
    if (e) {
      const startDate = dayjs(e[0].$d).format('YYYY-MM-DD');
      const endDate = dayjs(e[1].$d).format('YYYY-MM-DD');

      const resp = await posEarningManagement({
        id: agent,
        start_date: startDate,
        end_date: endDate,
      });

      if (resp?.data?.status) {
        setData(resp?.data?.data);
        console.log('data', resp?.data?.data);
      }
    } else {
      // setCurrentDate('');
      // setPrevDate('');
      getPosDetails();
    }
  };
  return (
    <div className='w-full rounded-xl relative bg-white p-4 '>
      {agent === undefined && (
        <h1 className='text-grey-90 absolute top-[50%] left-[42%]'>
          Select agent from left panel...
        </h1>
      )}

      {loading || agent === undefined ? (
        <Spin size='lg' className='absolute top-[60%] left-[50%]' />
      ) : (
        <>
          <div className='flex justify-between'>
            <div>
              <h2 className='flex'>
                Total Policy : <p className='text-primary-90'>{data?.total_policy}</p>
              </h2>
              <h2 className='flex'>
                Total Commission : <p className='text-primary-90'> {data?.total_pos_commission}</p>
              </h2>
            </div>
            <div>
              <RangePicker
                //   defaultValue={[dayjs(prevDate, "YYYY-MM-DD"), dayjs(currentDate, "YYYY-MM-DD")]}
                onChange={getDateRange}
              />
            </div>
          </div>
          <TableComponent
            columns={columns}
            dataSource={data?.result}
            getRowClassName={rowCommonClass}
          />
        </>
      )}
    </div>
  );
};

export default EarningTable;
