import React from 'react';
import { CSVLink } from 'react-csv';

const ExportCSV = ({ data, fileName }) => {
  return (
    <button className='rounded p-2 bg-primary-light'>
      <CSVLink data={data} fileName={fileName}>
        Export as CSV
      </CSVLink>
    </button>
  );
};

export default ExportCSV;
