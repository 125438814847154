import React, { useReducer } from 'react';
import { StoreCtx } from 'store/context';

const StoreProvider = ({ reducer, initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <StoreCtx.Provider value={{ state, dispatch }}>{children}</StoreCtx.Provider>;
};

export default StoreProvider;
