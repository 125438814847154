import React from 'react';
import { FieldArray } from 'formik';
import { Button } from 'components';
import FieldArrayField from './FieldArrayField';

const CustomTargetFieldArray = ({ form, field, inputLables, ...props }) => {
  return (
    <div className={`${props?.divClasses} pb-10`}>
      <FieldArray name={field?.name}>
        {({ push, remove }) => {
          const keysLength = props?.fields?.length;
          const gridCols =
            keysLength >= 3 ? 'grid-cols-3' : keysLength === 1 ? 'grid-cols-1' : 'grid-cols-2';
          return (
            <>
              <div className='flex justify-between mt-4 gap-2'>
                <span>{props?.label}</span>
                <Button
                  text={props?.addButtonText || '+ Add'}
                  classes=''
                  onClick={() => push(props?.valueSchema)}
                />
              </div>
              <div>
                {form?.values?.[field?.name]?.length > 0 && (
                  <>
                    <div className={`grid ${props?.gridCols || gridCols} gap-10`}>
                      {props?.fieldLabels?.map((label) => (
                        <span>{label}</span>
                      ))}
                    </div>
                    <div className='text-xs xl:text-s text-secondary-90 font-semibold flex flex-row gap-8 pb-4'>
                      <span className='w-[42%]'>Property Label</span>
                      <span className='w-[42%]'>Property Type</span>
                    </div>

                    {form?.values?.[field?.name]?.map((_, index) => {
                      return (
                        <>
                          <div key={index} className='pb-8 last-of-type:pb-0'>
                            <div>
                              <div className={`flex flex-row gap-8`}>
                                {props?.fields.map((fl, ind) => {
                                  const finalObj = {
                                    ind: `${index}`,
                                    parentName: `${field?.name}`,
                                    fieldName: `${fl.name}`,
                                    ...fl,
                                    name: `${field?.name}.${index}.${fl.name}`,
                                  };
                                  return (
                                    <>
                                      <FieldArrayField form={form} key={ind} {...finalObj} />
                                    </>
                                  );
                                })}
                                <div className='flex items-end gap-2'>
                                  <Button
                                    text='Remove'
                                    variant={'transparent'}
                                    classes='border-red-90 text-red-90 !h-fit'
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export default CustomTargetFieldArray;
