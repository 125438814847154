import { DateTime, MarkDown, MultiSelect, SelectInput, TextInput, Uploadbox } from 'components';
import ExperienceInput from 'components/form-components/experirnceInput';
import { getAllEmployees, searchApis } from 'service/api-service';

export const breadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Recruitment',
    path: '',
    target: '_blank',
  },
];
export const viewBreadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Recruitment',
    path: '/job-management',
  },
  {
    name: 'View Applications',
    path: '',
    target: '_blank',
  },
];

export const RequirementFields = {
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Designation',
        name: 'designation',
        id: 'desigation',
        placeholder: 'Designation',
        required: true,
        type: 'select',
        component: SelectInput,
        fetchOptions: async () => searchApis({ api: 'designation' }),
      },
      {
        label: 'Closing Date',
        name: 'c_date',
        id: 'c_date',
        type: 'text',
        placeholder: ' Closing Date',
        component: DateTime,
        disablePrev: true,
        required: true,
      },
      {
        label: 'Experience',
        name: 'exp',
        id: 'exp',
        type: 'string',
        placeholder: ' Experience',
        component: ExperienceInput,
        required: true,
      },

      {
        label: 'Description',
        name: 'notes',
        id: 'notes',
        required: true,
        type: 'text',
        placeholder: 'job description',
        component: MarkDown,
        divClasses: 'col-span-2',
        // isMultiline: true,
        // rows: 4,
      },
    ],
  },
};

export const applicationFields = {
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Upload Document',
        name: 'image',
        id: 'image',
        placeholder: 'Upload Image',
        required: true,
        component: Uploadbox,
        accept: ['.png, .jpeg, .jpg, .pdf , .doc'],
        divClasses: ' col-span-full m-auto w-[300px] text-center',
      },
      {
        label: 'Name',
        name: 'name',
        id: 'name',
        required: true,
        type: 'text',
        placeholder: 'Enter candidate Name',
        component: TextInput,
        divClasses: 'col-span-2',
      },
      {
        label: 'Contact',
        name: 'phone',
        id: 'phone',
        required: true,
        type: 'text',
        placeholder: 'Enter Contact Number',
        component: TextInput,
        maxLength: 10,
        divClasses: 'col-span-2',
      },
      {
        label: 'Email',
        name: 'email',
        id: 'email',
        required: true,
        type: 'text',
        placeholder: 'Enter Email',
        component: TextInput,
        divClasses: 'col-span-2',
      },
      {
        label: 'Address',
        name: 'address',
        id: 'address',
        required: true,
        type: 'text',
        placeholder: 'Enter address',
        component: TextInput,
        divClasses: 'col-span-2',
      },
    ],
  },
};

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
const disabledDateTime = () => ({
  disabledHours: () => {
    return [...range(0, 9), ...range(20, 24)];
  },
});
export const InviteFields = (fieldRequired, setFieldRequired) => ({
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Date and Time (For Interview)',
        name: 'date',
        id: 'date',
        type: 'text',
        placeholder: 'YYYY-MM-DD  HH:MM',
        component: DateTime,
        showTime: true,
        disablePrev: true,
        // divClasses: 'col-span-2',
        required: true,
        disabledDateTime: disabledDateTime,
      },
      {
        label: 'Mode of Interview',
        name: 'interview_type',
        id: 'interview_type',
        placeholder: 'Mode of Interview',
        required: true,
        type: 'select',
        component: SelectInput,
        options: [
          {
            label: 'Walk - in interview',
            value: 'walk_in',
          },
          {
            label: 'Online interview',
            value: 'online',
          },
          {
            label: 'Telephonic',
            value: 'telephonic',
          },
        ],
        //customChange:()=> {setFieldRequired(true)}
        customChange: (field, option) => {
          if (option?.value === 'online') {
            setFieldRequired(true);
          } else {
            setFieldRequired(false);
          }
        },
        // divClasses: 'w-[386px] ',
      },
      {
        dependent: true,
        dependent_on: 'interview_type',
        dependent_value: 'online',
        label: 'Meeting Link',
        name: 'link',
        id: 'link',
        type: 'text',
        placeholder: 'Paste Meeting Link Here',
        component: TextInput,
        // divClasses: 'col-span-2',
        required: fieldRequired,
      },
      {
        label: 'Assigned Interviewer',
        name: 'assign_to_employee',
        id: 'assign_to_employee',
        placeholder: 'Select Employee',
        type: 'select',
        required: true,
        component: MultiSelect,
        options: [],
        fetchOptions: getAllEmployees,
        checkbox: true,
        // divClasses: 'w-[386px] ',
      },
      {
        label: 'Message for Assign Interviewer',
        name: 'emp_msg',
        id: 'emp_msg',
        type: 'text',
        placeholder: 'Message for Assign Employee',
        component: TextInput,
        // divClasses: 'col-span-2',
        // required: true,
        isMultiline: true,
      },
      {
        label: 'Message for Candidate',
        name: 'cad_msg',
        id: 'cad_msg',
        type: 'text',
        placeholder: 'Message for Candidate',
        component: TextInput,
        // divClasses: 'col-span-2',
        // required: true,
        isMultiline: true,
      },
    ],
  },
});

export const advertFields = {
  'Advert Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Title',
        name: 'title',
        id: 'title',
        type: 'text',
        placeholder: 'Title',
        component: TextInput,
        divClasses: 'col-span-full',
        required: true,
      },
      {
        label: 'Description',
        name: 'desp',
        id: 'desp',
        placeholder: 'Add your Description here....',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        maxLength: 300,
        divClasses: 'col-span-full',
        rows: 4,
      },
    ],
  },
};

export const formatViewData = (data) => {
  const res = {
    'Personal Details': [],
    'Additional Details': [],
  };
  if (!data) return res;
  Object.entries(data).forEach(([key, val]) => {
    switch (key) {
      case 'name':
        res['Personal Details'].push({ label: key, value: val, name: key.replaceAll('_id', '') });
        break;
      case 'contact':
        res['Personal Details'].push({
          label: key,
          value: val,
          name: key.replaceAll('_id', ''),
          phone: true,
        });
        break;
      case 'email':
        res['Personal Details'].push({
          label: key,
          value: val,
          name: key.replaceAll('_', ' '),
          email: true,
        });
        break;
      case 'department_id':
      case 'branch':
        res['Personal Details'].push({
          label: key,
          value: val.join(', '),
          name: key.replaceAll('_id', ''),
        });

        break;
      case 'dob':
      case 'designation':
      case 'role_id':
      case 'report_id':
        res['Personal Details'].push({ label: key, value: val, name: key.replaceAll('_id', '') });
        break;
      case 'emp_code':
        res['Personal Details'].push({ label: key, value: val, name: key.replaceAll('_code', '') });
        break;
      case 'phone':
        res['Personal Details'].push({ label: key, value: val, name: key.replaceAll('_by', '') });
        break;
      case 'work_from':
        res['Personal Details'].push({ label: key, value: val, name: key.replaceAll('_from', '') });
        break;
      case 'referred_id':
        res['Personal Details'].push({ label: key, value: val?.name, name: 'Referred By' });
        break;
      case 'address':
      case 'country':
      case 'state':
      case 'city':
      case 'pincode':
        res['Additional Details'].push({ label: key, value: val, name: key });
        break;
      case 'notice':
        res['Additional Details'].push({ label: key, value: val, name: 'Notice Period' });
        break;
      case 'current_ctc':
        res['Additional Details'].push({ label: key, value: val, name: 'Current CTC' });
        break;
      case 'exp_ctc':
        res['Additional Details'].push({ label: key, value: val, name: 'Expectation CTC' });
        break;
      case 'location':
        res['Additional Details'].push({ label: key, value: val, name: 'location' });
        break;
      case 'type':
        res['Additional Details'].push({ label: key, value: val, name: 'Mode of Interview' });
        break;
      case 'experience':
        res['Additional Details'].push({ label: key, value: val, name: 'Experience' });
        break;
      case 'reason':
        res['Additional Details'].push({ label: key, value: val, name: 'Reason' });
        break;
      case 'availability':
        res['Additional Details'].push({
          label: key,
          value: val,
          name: 'Availablity for interview',
        });
        break;
      case 'relocate':
        res['Additional Details'].push({ label: key, value: val, name: 'Ready to Relocate' });
        break;
      default:
        break;
    }
  });
  return res;
};

const JOB_POST_STATUS = {
  ACTIVE: true,
  CLOSED: false,
};

export const jobStatusList = [
  {
    label: <span className='w-full text-left'>Active</span>,
    key: JOB_POST_STATUS.ACTIVE,
  },
  {
    label: <span className='w-full text-left'>Closed</span>,
    key: JOB_POST_STATUS.CLOSED,
  },
];
