export const statusOptions = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'InActive',
    value: 'inactive',
  },
];

export const clientFilterOptions = [
  {
    name: 'assign',
    label: 'Assign To',
    id: 'assign',
    value: 'assign',
  },
  {
    name: 'assign_by',
    label: 'Assign By',
    id: 'assign_by',
    value: 'assign_by',
  },
  {
    name: 'created',
    label: 'Created By ',
    id: 'created',
    value: 'created',
  },
];
