/** external deps */
import React, { useContext } from 'react';
/** internal deps */
import Assistant from 'assets/png/assistant.png';
import { Context } from 'store/context';
// import { EDIT } from "assets/icons";
// import { CustomModal, FormContainer, IconButton, Button, Toast } from "components";
// import { prepareFormFieldsData } from "utils";
// import { posDocumentFields } from "./data";
// import { posRegister } from "service/api-service";
// import { NOTIFICATION_MSG } from "constants";

const PosReview = () => {
  const { user } = useContext(Context);
  const userName = user?.name?.charAt(0)?.toUpperCase() + user?.name?.slice(1);
  // const [loading, setLoading] = useState(false);
  // const [showEditModal, setEditModal] = useState(false);
  // const [initialValues, finalFields] = prepareFormFieldsData({
  //   fields: posDocumentFields,
  // });

  // const toggleEditModal = useCallback(() => {
  //   setEditModal((pre) => !pre);
  // }, []);

  // const onDocsUpdate = useCallback(async (data) => {
  //   setLoading(true);

  //   const filterNonEmptyValues = (data) => {
  //     return Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== ""));
  //   };
  //   const result = filterNonEmptyValues(data);

  //   const convertToObjectArray = (data) => {
  //     return Object.entries(data).map(([name, file]) => ({ name, file }));
  //   };
  //   const docs = convertToObjectArray(result);

  //   const newpayload = {
  //     docs: docs,
  //   };

  //   const resp = await posRegister({ method: "patch", data: newpayload });
  //   if (resp?.data?.status) {
  //     Toast("success", NOTIFICATION_MSG.success, "Documents updated successfully");
  //     toggleEditModal();
  //   } else {
  //     const head = resp?.data?.msg;
  //     Object.keys(head).forEach((key) => {
  //       Toast("error", NOTIFICATION_MSG.error, `${key} : ${head[key]}` || "Please try again");
  //     });
  //   }
  //   setLoading(false);
  // }, []);

  return (
    <div className='flex items-center justify-center h-screen'>
      <div className={`w-[44rem] rounded-[14px] bg-primary-light/40 shadow-custom relative`}>
        <div className='flex relative min-h-40 p-4'>
          <div className='pt-4 pb-0 w-9/12'>
            <h2 className='font-medium text-xs'>Hello {userName || 'User'},</h2>
            <p className='text-xxs'>
              Wait, your documents are under review.We'll send you a mail when your documents are
              verified and your account is activated.
            </p>
          </div>
          <img
            src={Assistant}
            alt='assistant'
            className='absolute right-0 bottom-0 overflow-y-visible max-h-[110%]'
          />
        </div>
        {/* <IconButton
          title="Edit"
          className="absolute top-0 right-0 group disabled:cursor-not-allowed"
          onClick={toggleEditModal}
        >
          <EDIT svgClass="stroke-green-90 group-disabled:stroke-grey" />
        </IconButton> */}
      </div>
      {/* <CustomModal
        destroyOnClose={true}
        open={showEditModal}
        onCancel={toggleEditModal}
        footer={""}
      >
        <FormContainer
          accordionClass="grid grid-cols-2 gap-8"
          onSubmit={onDocsUpdate}
          initialValues={initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
            return (
              <div className="bg-white flex flex-row justify-end py-4 mt-6">
                <Button
                  loading={loading}
                  text={"Save"}
                  variant="primary"
                  classes={`ml-6 px-8 text-xs lg:text-lg xl:text-xl py-2 px-4 xl:px-9 py-3 2xl:py-3 ${
                    isSubmitting ? "bg-grey" : "bg-black"
                  }`}
                  onClick={submitForm}
                />
              </div>
            );
          }}
        />
      </CustomModal> */}
    </div>
  );
};

export default PosReview;
