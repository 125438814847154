/** external deps */
import { Spin, Tooltip } from 'antd';
import React from 'react';
/** internal deps */
import { UserInitialIcon } from 'components';
import { formatDate } from 'utils';

const CommentList = ({ divClass, comments = [], loading = false, onClick }) => {
  const renderList = (item, index) => (
    <div key={index}>
      <span className='text-x text-grey-90'>
        {formatDate({
          dateString: item?.updated,
          toUtc: false,
          format: 'DD MMMM, YYYY hh:mm A',
        })}
      </span>
      <div
        className={`grid grid-flow-col justify-start gap-4 my-1 items-center bg-grey-light p-2 rounded-md ${divClass}`}
        onClick={onClick}
      >
        <Tooltip title={item.user_id?.name}>
          <div>
            <UserInitialIcon
              name={item.user_id?.name}
              imageUrl={process.env.REACT_APP_DOC_URL + item.user_id?.image}
            />
          </div>
        </Tooltip>
        <span className='break-all'> {item.comment}</span>
      </div>
    </div>
  );

  return (
    <div className='col-span-full'>
      {loading ? <Spin tip='Loading' /> : comments.map(renderList)}
    </div>
  );
};

export default CommentList;
