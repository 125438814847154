import { endpoints } from 'constants/endpoints';
import Request from 'service/api-service/client';
// EducationManagement

export const getEducation = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.user.education.list,
  });
  return resp;
};

export const addEducation = async (props = { data: undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.user.education.create,
    data: props?.data,
  });
  return resp;
};

export const editEducation = async (props = { data: undefined, id: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.user.education.update(props?.id),
    data: props?.data,
  });
  return resp;
};

export const deleteEducation = async (props = { id: '', data: undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.user.education.delete(props?.id),
    data: props?.data,
  });
  return resp;
};

/* Profile Work Experience Management  */

export const getExperience = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.user.experience.list,
  });
  return resp;
};

export const addExperience = async (props = { data: undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.user.experience.create,
    data: props?.data,
  });
  return resp;
};

export const editExperience = async (props = { data: undefined, id: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.user.experience.update(props?.id),
    data: props?.data,
  });
  return resp;
};

export const deleteExperience = async (props = { id: '', data: undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.user.experience.delete(props?.id),
    data: props?.data,
  });
  return resp;
};

/* Profile Project Management  */

export const getProject = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.user.project.list,
  });
  return resp;
};

export const addProject = async (props = { data: undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.user.project.create,
    data: props?.data,
  });
  return resp;
};

export const editProject = async (props = { data: undefined, id: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.user.project.update(props.id),
    data: props?.data,
  });
  return resp;
};

export const deleteProject = async (props = { id: '', data: undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.user.project.delete(props.id),
    data: props?.data,
  });
  return resp;
};

// languageManagement

export const getLanguage = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.user.language.list,
  });
  return resp;
};

export const addLanguage = async (props = { data: undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.user.language.create,
    data: props?.data,
  });
  return resp;
};

export const editLanguage = async (props = { data: undefined, id: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.user.language.update(props.id),
    data: props?.data,
  });
  return resp;
};

export const deleteLanguage = async (props = { id: '', data: undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.user.language.delete(props.id),
    data: props?.data,
  });
  return resp;
};

export const getSkill = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.user.skill.list,
  });
  return resp;
};

export const addSkill = async (props = { data: undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.user.skill.create,
    data: props?.data,
  });
  return resp;
};

export const editSkill = async (props = { data: undefined, id: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.user.skill.update(props.id),
    data: props?.data,
  });
  return resp;
};

export const deleteSkill = async (props = { id: '', data: undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.user.skill.delete(props.id),
    data: props?.data,
  });
  return resp;
};

// emergencyManagement

export const getEmergency = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.user.emergency.list,
  });
  return resp;
};

export const addEmergency = async (props = { data: undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.user.emergency.create,
    data: props?.data,
  });
  return resp;
};

export const editEmergency = async (props = { data: undefined, id: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.user.emergency.update(props.id),
    data: props?.data,
  });
  return resp;
};

export const deleteEmergency = async (props = { id: '', data: undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.user.emergency.delete(props.id),
    data: props?.data,
  });
  return resp;
};
/* profile update  */
export const updateProfile = async ({ data }) => {
  const resp = await Request({
    method: 'patch',
    API: `user/profile/`,
    data,
  });
  return resp;
};

export const getCurrentUserLeads = async ({
  source = '',
  employee_id = '',
  customer_id = '',
  pos_id = '',
  category_id = '',
  product_type = '',
}) => {
  const resp = await Request({
    method: 'get',
    API: `user/product/data?source=${source}&employee_id=${employee_id}&customer_id=${customer_id}&pos_id=${pos_id}&category_id=${category_id}&product_type=${product_type}`,
  });
  return resp;
};

// do note :work needed to be done from backend .
export const getPosOnlineLeads = async ({ id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: `user/product/data/${id}`,
  });
  return resp;
};

export const getPosInquiryDetails = async ({ id = '' }) => {
  const resp = await Request({
    method: 'get',
    // API: `user/product/data/${id}`,
    API: `inquiry/?id=${id}`,
  });
  return resp;
};

export const getDummyData = async (id) => {
  const resp = await Request({
    method: 'get',
    API: `inquiry/?id=${id}&page=1&q=&customer_id=&employee_id=&category_id=&product_type=&sorted_by=&status=quoted&type=employee`,
  });
  return resp;
};

export const patchDummyData = async (props = { id: '', data: undefined }) => {
  console.log(props, 'props!!!');
  const resp = await Request({
    method: 'patch',
    API: `inquiry/?id=${props?.id}`,
    data: props?.data,
  });
  return resp;
};
