import Request from '../client';
import { endpoints } from 'constants/endpoints';

export const getTax = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.tax.list,
  });
  return resp;
};
export const addTax = async ({ data = '' }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.tax.create,
    data: data,
  });
  return resp;
};
export const editTax = async ({ data = '', id = '' }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.tax.update(id),
    data: data,
  });
  return resp;
};
export const deleteTax = async ({ id = '' }) => {
  const resp = await Request({
    method: 'delete',
    API: endpoints.tax.delete(id),
  });
  return resp;
};
