import Request from 'service/api-service/client';

export const getDailyGoal = async (props = { date: '', status: '' }) => {
  const resp = await Request({
    method: 'get',
    API: `account/goals?date=${props.date || ''}&status=${props.status || ''}`,
  });
  return resp;
};

export const postDailyGoal = async (props = { data: '', date: '', status: '' }) => {
  const resp = await Request({
    method: 'post',
    API: `account/goals?date=${props.date || ''}&status=${props.status || ''}`,
    data: props?.data,
  });
  return resp;
};

export const updateDailyGoal = async (props = { data: '', id: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `account/goals/${props.id}`,
    data: props?.data,
  });
  return resp;
};

export const deleteDailyGoal = async (props = { id: '' }) => {
  const resp = await Request({
    method: 'delete',
    API: `account/goals/${props.id}`,
  });
  return resp;
};
