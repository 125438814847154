/** external deps */
import React, { useState, useContext, useEffect } from 'react';
import Fuse from 'fuse.js';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  Toast,
  Module,
} from 'components';
import { addAssetsFields, breadcrumbObj } from './data';
import { getPermission, prepareFormFieldsData } from 'utils';
import { SecureComponent } from 'permission-provider';
import { Context } from 'store/context';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { getAllocation, postAllocation } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const RequestForAsset = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [pageNum, setPageNum] = useState(1);
  const [total, setTotal] = useState(0);
  const [showAddModal, setAddModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [tableData, setTableData] = useState('');
  const [loading, setLoading] = useState(false);
  const [filteredAssets, setfilteredAssets] = useState([]);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addAssetsFields,
  });

  const currentDate = dayjs().format('YYYY-MM-DD');
  const { user } = useContext(Context);

  const columns = [
    {
      key: 'asset_type',
      title: 'Asset Type',
      dataIndex: 'asset_type',
      render: (data) => data?.map((item) => item.name) || '\u2014',
    },
    {
      key: 'request_date',
      title: 'Request Date',
      dataIndex: 'request_date',
      render: () => {
        return <span>{currentDate}</span>;
      },
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      align: 'right',
      render: (status) => {
        return (
          <>
            <Tag
              icon={
                status === 'pending' ? (
                  <SyncOutlined spin />
                ) : status === 'approved' ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
              color={
                status === 'pending' ? 'processing' : status === 'approved' ? 'success' : 'error'
              }
            >
              {status}
            </Tag>
          </>
        );
      },
    },
  ];

  /* funcs */

  const onGetAssets = async () => {
    setLoading(true);
    const id = user?.id;
    if (!id) return null;
    const resp = await getAllocation({ id: user && user?.id });
    if (resp?.data?.data) {
      const data = resp.data?.data?.result;
      setTableData(data);
      setPageNum(resp?.data?.data.current_page);
      setTotal(resp?.data?.data.total_page);
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Failed to fetch Requested Assets from server',
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    onGetAssets();
  }, [user?.id]);

  const onAddAllocation = async (data) => {
    setLoading(true);
    console.info('firs', data);
    const payloadData = {
      status: 'pending',
      requested_by: user?.id,
      asset_type: [data.assetType],
      date: currentDate,
      reason: data.reason,
      imd_need: data.imd_need,
    };
    const resp = await postAllocation({ data: payloadData });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleAddAsset();
      onGetAssets();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const toggleAddAsset = () => {
    setAddModal((pre) => !pre);
  };

  const handlePagination = async (pageNum) => {
    setLoading(true);
    const id = user?.id;
    if (!id) return null;
    const resp = await getAllocation({ id: user && user?.id, page: pageNum });
    if (resp?.data?.data) {
      const data = resp.data?.data?.result;
      setTableData(data);
      setPageNum(resp?.data?.data.current_page);
      setTotal(resp?.data?.data.total_page);
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Failed to fetch Requested Assets from server',
      );
    }
    setLoading(false);
  };

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(tableData, {
      keys: ['asset_type.name', 'status', 'request_date', 'asset'],
      threshold: 0.2,
    }).search(e.target.value);
    setfilteredAssets(fuse.map((i) => i.item));
  };

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        title={<p className='font-semibold text-s'>Your Asset Request</p>}
        // data={breadcrumbObj}
        onChange={handleOnChange}
        btnText='+ Request New Asset'
        buttonId={elementData.assetAllocation.permissions.create}
        onClick={toggleAddAsset}
      />
      {getPermission(permissions, elementData.assetAllocation.permissions.view)?.read ? (
        <TableComponent
          loading={loading}
          columns={columns}
          dataSource={searchQuery ? filteredAssets : tableData}
          total={total}
          onChange={handlePagination}
          defaultPage={pageNum}
          getRowClassName={rowCommonClass}
        />
      ) : (
        <Module />
      )}

      {/* add Asset modal */}
      <CustomModal
        width={600}
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddAsset}
        title={'Add Asset Request'}
        footer={''}
      >
        <div>
          <span>To add a new asset request ,enter details in the fields given below.</span>
          <FormContainer
            accordionClass='grid gap-8'
            onSubmit={onAddAllocation}
            initialValues={initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddAsset();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={elementData?.assetAllocation?.permissions?.create}
                    text={'Save'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(RequestForAsset);
