import React, { useState, useEffect, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { getEmployeePerformanceCount } from 'service/api-service';
import { Context } from 'store/context';
import { Empty } from 'antd';
Chart.register(CategoryScale);

const MyPerformance = () => {
  const { user } = useContext(Context);
  const [performanceData, setPerformanceData] = useState([]);

  const fetchPerformance = async () => {
    const performance = await getEmployeePerformanceCount({ id: user?.id });
    if (performance && performance?.status) {
      setPerformanceData(performance?.data?.data);
    }
  };
  useEffect(() => {
    fetchPerformance();
  }, []);

  const customerChartData = {
    labels: ['On Time', 'Over Due', 'Leaves'],
    datasets: [
      {
        data: [performanceData?.on_time, performanceData?.over_due, performanceData?.leaves],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: ['rgb(255, 99, 132)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className='h-max border border-grey-80 rounded-lg p-2'>
        <div className='flex justify-between pb-2 items-center'>
          <h3 className='text-xs font-semibold text-gray-900 dark:text-white '>My Performance</h3>
        </div>

        <div className='w-max h-auto'>
          {performanceData?.on_time != 0 ||
          performanceData?.over_due != 0 ||
          performanceData?.leaves != 0 ? (
            <>
              <Bar
                width={500}
                height={210}
                data={customerChartData}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </>
          ) : (
            <div className='flex flex-col items-center justify-center py-16'>
              <Empty description={false} imageStyle={{ height: '60px' }} />
              No data Found
            </div>
          )}
        </div>

        {/* <p>No data Found</p> */}
      </div>
    </>
  );
};

export default MyPerformance;
