import { useEffect, useRef, useState } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';

/** @param {{data: GoalsData | null}} props */
const GoalsGraph = ({ data, successRateData, employeeData }) => {
  const componentRef = useRef(null);
  const [dim, setDim] = useState({ height: 0, width: 0 });
  const profile = data?.result[0];
  const remainingCount = data?.total_goal_count - data?.completed_count || 0;
  const dataArr = Array.isArray(data?.result) ? data.result : [];
  const sumTasks = dataArr.reduce((prev, cur) => (cur.field ?? 0) + prev, 0);
  const sumCompletedTasks = dataArr.reduce((prev, cur) => (cur.count ?? 0) + prev, 0);
  const emptySpace = sumCompletedTasks !== 0 && sumTasks - sumCompletedTasks !== 0 ? '' : 100;
  const dataWithPercent = dataArr?.map((item) =>
    JSON.parse(((item.count / item.field) * 100).toFixed(0) || 0),
  );
  const totalDataWithPercent = dataWithPercent.length
    ? dataWithPercent.reduce((acc, ele) => acc + ele)
    : [];
  const graphPercent = ((totalDataWithPercent / (dataArr?.length * 100)) * 100).toFixed(0) || 0;

  const graphData = [
    {
      title: 'User Name',
      data: (
        <p className='text-xxs font-medium '>
          {profile?.employee.name || employeeData?.name || '_'} ( #{' '}
          {profile?.employee.emp_code || employeeData?.emp_code || '_'})
        </p>
      ),
    },
    {
      title: 'Remaining Tasks',
      data: <p className='text-xxs'>{remainingCount || 0}</p>,
    },
    {
      title: 'Total Tasks',
      data: <p className='text-xxs'>{data?.total_goal_count || 0}</p>,
    },
  ];
  const legendColors = ['bg-[#DDFFB2]', 'bg-[#FFD8C7]'];
  const labels = ['Completed', 'Pending'];
  const renderUserData = (item) => {
    return (
      <div className='flex flex-col'>
        <h2 className='text-xxs font-normal text-[#464646]'>{item?.title}</h2>
        {item?.data}
      </div>
    );
  };
  const renderGraphColors = (label, index) => {
    return (
      <div
        className={` text-xxs font-semibold rounded-md h-8 w-24 flex justify-center items-center ${
          label === 'Completed' ? 'text-[#7DCC18]' : 'text-[#F46629]'
        }   mb-4 ${legendColors[index]}  `}
        key={label}
      >
        {label === 'Completed' ? graphPercent : 100 - graphPercent !== 100 ? 100 - graphPercent : 0}{' '}
        %
      </div>
    );
  };

  const progressData = {
    labels: ['Completed', 'Pending'],

    datasets: [
      {
        data: [graphPercent, 100 - graphPercent],
        cutout: ['70%'],
        backgroundColor: ['#7DCC18', '#F46629', '#C6ECFF'],
        display: true,
        borderColor: 'transparent',
      },
    ],
  };
  const emptyData = {
    labels: ['empty'],

    datasets: [
      {
        data: [100],
        cutout: ['70%'],
        backgroundColor: ['#C6ECFF'],
        display: true,
        borderColor: 'transparent',
      },
    ],
  };

  const SuccessGraphData = {
    labels: successRateData?.map((item) => item.month),
    datasets: [
      {
        label: 'Dataset 1',
        data: successRateData?.map((item) => item.count),
        fill: false,
        backgroundColor: '#0089CF',
        borderColor: '#0089CF',
        borderWidth: 2,
        lineTension: 0,
      },
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      if (componentRef.current) {
        const width = componentRef.current.clientWidth;
        const height = componentRef.current.clientHeight;
        setDim({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    // Initial setup
    handleResize();

    // Observer setup
    if (componentRef.current) {
      resizeObserver.observe(componentRef.current);
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [componentRef]);

  return (
    <section className='flex gap-4 h-64'>
      <div className='bg-white h-full w-[22%] p-4  rounded-xl'>
        <h2 className='text-s'> User Details</h2>
        <div className=' flex flex-col w-full gap-3 py-4'>
          {graphData?.map((item) => renderUserData(item))}
        </div>
      </div>
      <div className='bg-white h-full w-[29%] p-4 flex flex-col rounded-xl '>
        <h2 className='text-s'>Task Progress Meter</h2>
        <div className='flex w-full h-full   gap-6  items-center'>
          <div ref={componentRef} className='h-[90%] w-[55%] z-10 relative'>
            {emptySpace ? (
              <Doughnut
                className='doughnut-chart'
                data={emptyData}
                options={{
                  plugins: {
                    legend: { display: false },
                  },
                }}
                // plugin added below is for giving some new design to chart that shows arc at the ending point of rendring data in graph
                plugins={[
                  {
                    id: 'drawСircles',
                    afterUpdate(chart) {
                      const arcs = chart.getDatasetMeta(0).data;

                      arcs.forEach((arc) => {
                        arc.round = {
                          x: (chart.chartArea.left + chart.chartArea.right) / 2,
                          y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                          radius: (arc.outerRadius + arc.innerRadius) / 2,
                          thickness: (arc.outerRadius - arc.innerRadius - 1) / 2,
                          backgroundColor: 'transparent',
                        };
                      });
                    },
                    afterDraw: (chart) => {
                      const { ctx } = chart;

                      chart.getDatasetMeta(0).data.forEach((arc) => {
                        const endAngle = Math.PI / 2 - arc.endAngle;

                        ctx.save();
                        ctx.translate(arc.round.x, arc.round.y);
                        ctx.fillStyle = arc.options.backgroundColor;
                        ctx.beginPath();
                        ctx.arc(
                          arc.round.radius * Math.sin(endAngle),
                          arc.round.radius * Math.cos(endAngle),
                          arc.round.thickness,

                          0,

                          2 * Math.PI,
                        );
                        ctx.closePath();
                        ctx.fill();
                        ctx.restore();
                      });
                    },
                  },
                ]}
              />
            ) : (
              <Doughnut
                className='doughnut-chart'
                data={progressData}
                options={{
                  plugins: {
                    legend: { display: false },
                  },
                }}
                // plugin added below is for giving some new design to chart that shows arc at the ending point of rendring data in graph

                plugins={[
                  {
                    id: 'drawСircles',
                    afterUpdate(chart) {
                      const arcs = chart.getDatasetMeta(0).data;

                      arcs.forEach((arc) => {
                        arc.round = {
                          x: (chart.chartArea.left + chart.chartArea.right) / 2,
                          y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                          radius: (arc.outerRadius + arc.innerRadius) / 2,
                          thickness: (arc.outerRadius - arc.innerRadius - 1) / 2,
                          backgroundColor: 'transparent',
                        };
                      });
                    },
                    afterDraw: (chart) => {
                      const { ctx } = chart;

                      chart.getDatasetMeta(0).data.forEach((arc) => {
                        const endAngle = Math.PI / 2 - arc.endAngle;

                        ctx.save();
                        ctx.translate(arc.round.x, arc.round.y);
                        ctx.fillStyle = arc.options.backgroundColor;
                        ctx.beginPath();
                        ctx.arc(
                          arc.round.radius * Math.sin(endAngle),
                          arc.round.radius * Math.cos(endAngle),
                          arc.round.thickness,

                          0,

                          2 * Math.PI,
                        );
                        ctx.closePath();
                        ctx.fill();
                        ctx.restore();
                      });
                    },
                  },
                ]}
              />
            )}
          </div>
          <div className=' flex flex-col w-[30%] items-center justify-center '>
            {labels.map((label, index) => renderGraphColors(label, index))}
          </div>
        </div>
      </div>

      {/* line graph */}
      <div className='bg-white h-full w-[46%] rounded-xl p-4 '>
        <h2 className='text-s '> Success Rate</h2>

        <div className='h-[90%] w-full ' ref={componentRef}>
          <Line
            {...dim}
            data={SuccessGraphData}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </div>
      </div>
    </section>
  );
};
export default GoalsGraph;

/** @typedef {object} GoalsData
 * @property {number} current_page
 * @property {boolean} has_next
 * @property {number} total_page
 * @property {object[]} result
 * @property {number} result.id
 * @property {object} result.employee
 * @property {number} result.employee.id
 * @property {string} result.employee.name
 * @property {string} result.employee.emp_code
 * @property {string} result.employee.image
 * @property {string} result.employee.contact
 * @property {object} result.type
 * @property {number} result.type.id
 * @property {null|object} result.type.product_type
 * @property {null|object} result.type.category
 * @property {string} result.type.name
 * @property {string} result.type.model
 * @property {boolean} result.type.deleted
 * @property {number} result.type.organization
 * @property {number} result.type.created_by
 * @property {null|number} result.type.updated_by
 * @property {object} result.assign_by
 * @property {number} result.assign_by.id
 * @property {string} result.assign_by.name
 * @property {string} result.status
 * @property {number} result.field
 * @property {number} result.count
 * @property {string} result.start
 * @property {string} result.end
 * @property {string} result.durations
 * @property {boolean} result.repetitive
 * @property {string} result.desp
 * @property {string} result.assign_date
 * @property {number} result.type.product_type.id
 * @property {string} result.type.product_type.name
 * @property {number} result.type.category.id
 * @property {string} result.type.category.name
 * @property {number} ongoing_count
 * @property {number} archived_count
 * @property {number} completed_count
 * @property {number} total_goal_count
 */
