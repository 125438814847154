import React, { useState, useCallback, useEffect, useContext, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Button, Modal, Tabs, Tooltip, Spin, Layout, List, Avatar, Checkbox, Divider } from 'antd';
import {
  SaveOutlined,
  SendOutlined,
  PrinterOutlined,
  PicRightOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import { arrayToObject, createHtml } from './config';
import { InforCard } from './info-card';
import { Context } from 'store/context';
import { getInquiryDetails, inquiryManagement, sendEmail, getInsurers } from 'service/api-service';
import { getQueryParams, htmlStringToPdf } from 'utils/common';
import { IconButton, Toast, SearchAPI, CustomModal, LeadForm } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { LabelValue } from './components';
import { BackArrow } from 'assets/icons';

const { Sider, Content } = Layout;

const cardsArea = {};

const QuotationEngine = ({ permissions }) => {
  const contentRef = useRef(null);
  const iframeRef = useRef(null);
  const navigate = useNavigate();
  const { inq_id } = getQueryParams() || {};
  const { company, user } = useContext(Context);
  /* state*/
  const [loading, setLoading] = useState();
  const [insurers, setInsurers] = useState([]);
  const [quotation, setQuotation] = useState({});
  const [preview, setPreview] = useState();
  const [selected, setSelected] = useState([]);
  const [infoObject, setInfoObject] = useState({});
  const [inquiry, setInquiry] = useState();
  const [htmlFile, setHtmlFile] = useState();
  const [showButton, setShowButton] = useState(false);
  const [showPreview, setShowPreview] = useState();
  const [leadForm, setLeadForm] = useState(false);

  const loadInquiry = useCallback(async () => {
    const resp = await getInquiryDetails({ id: inq_id });
    const data = resp?.data?.data;
    if (inq_id) {
      setInquiry(data);
      if (data && data?.quote) {
        setSelected(data.quote.map((i) => Number(i.id)));
        setShowButton(true);
      }
    }
    const initialData = data?.products?.map((item) => {
      return {
        insurer: item?.insurer,
        product: item?.product_type,
      };
    });

    let arr = [];

    for (let i = 0; i < initialData?.length; i++) {
      for (let j = 0; j < initialData[i]?.insurer?.length; j++) {
        arr.push({ insurer: initialData[i]?.insurer[j], product: initialData[i]?.product });
      }
    }
    setInsurers(arr);
    setSelected(arr);
  }, []);

  const loadInsurers = useCallback(async () => {
    setLoading(true);
    const resp = await getInsurers();
    setInsurers(resp?.data?.data);
    setInfoObject(arrayToObject(resp?.data?.data));
    setLoading(false);
  }, []);

  const doSelect = useCallback(
    (value) => () => {
      const foundObj = selected?.find(
        (item) =>
          item?.insurer?.id === value?.insurer?.id && item?.product?.name === value?.product?.name,
      );
      if (foundObj) {
        setSelected(selected?.filter((ele) => ele !== foundObj));
      } else {
        if (selected?.length === 0) {
          setSelected([value]);
        } else {
          setSelected([...selected, value]);
        }
      }
    },
    [selected],
  );

  const checkIsChecked = ({ array, obj }) => {
    const foundObj = array?.find(
      (item) => item?.insurer?.id === obj?.insurer?.id && item?.product?.id === obj?.product?.id,
    );
    if (foundObj) {
      return true;
    } else {
      return false;
    }
  };

  const onSave = useCallback((data) => {
    setInquiry(data);
    toggleAddingLead();
  }, []);

  const selectAll = useCallback(() => {
    setSelected((s) => {
      if (s?.length === insurers?.length) {
        return [];
      } else {
        return insurers;
      }
    });
  }, [insurers]);

  const onUpdate = useCallback((id, values) => {
    const index = id + values?.product?.id;
    setQuotation((q) => ({
      ...q,
      [index]: values,
    }));
  }, []);

  const handleSave = async () => {
    setLoading(true);
    const rows = Object.keys(quotation).map((key) => ({
      ...quotation[key],
      insurer: quotation[key]?.insurer,
      id: key,
    }));
    const id = inq_id ? inq_id : inquiry?.id;
    const payload = {
      quote: rows,
      id: id,
      status: 'quoted',
    };
    const resp = await inquiryManagement({
      method: 'patch',
      data: payload,
      id: id,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      setShowButton(true);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const toggleAddingLead = () => {
    setLeadForm((pre) => !pre);
  };

  const renderCard = useCallback(
    (value) => {
      const coverageOption = value?.product?.coverages?.map((item) => {
        return { label: item?.name, value: item?.name };
      });
      return (
        <InforCard
          key={value?.insurer?.id}
          insurer={value?.insurer}
          onUpdate={onUpdate}
          handleSave={handleSave}
          inquiry={inquiry?.quote}
          coverageOption={coverageOption}
          value={value}
        />
      );
    },
    [infoObject, onUpdate, inquiry, insurers],
  );

  const renderInsurer = useCallback(
    (item) => (
      <List.Item
        key={item?.insurer?.id}
        className='bg-white rounded m-1 cursor-pointer '
        extra={
          <Checkbox className='m-2' checked={checkIsChecked({ array: selected, obj: item })} />
        }
        onClick={doSelect(item)}
      >
        <List.Item.Meta
          avatar={<Avatar src={process.env.REACT_APP_DOC_URL + item?.insurer?.image} />}
          title={item?.insurer?.name}
          className='px-2  '
        />
      </List.Item>
    ),
    [doSelect, selected],
  );

  const tabs = useMemo(
    () => [
      {
        label: 'Offline',
        children: (
          <div className='p-2 bg-grey-40 ' style={cardsArea}>
            {!selected?.length ? (
              <div className='bg-white p-2 h-full border-l border-grey-40'>
                <div
                  onClick={selectAll}
                  className='text-m cursor-pointer flex items-center justify-between p-1 font-semibold bg-white border-b border-grey-40'
                >
                  Select Insurers
                  <Checkbox
                    className='m-2'
                    checked={insurers?.length && selected?.length === insurers?.length}
                  />
                </div>
                <List dataSource={insurers} renderItem={renderInsurer} className='p-1' />
              </div>
            ) : (
              selected.length && selected && selected?.map(renderCard)
            )}
          </div>
        ),
        key: '1',
      },
      {
        label: 'Online',
        children: 'Coming Soon',
        key: '2',
      },
    ],
    [selected, renderCard],
  );

  const getHtml = useCallback(() => {
    const rows = Object.keys(quotation).map((key) => ({
      ...quotation[key],
      insurer: quotation[key]?.insurer,
      id: key,
    }));
    return createHtml(rows, {
      company: company.name,
      customer: inquiry?.customer?.name || inquiry?.customer?.corp_name,
      companyEmail: company.email,
      sender: user?.name,
      inq: inq_id,
      document: inquiry?.document,
    });
  }, [quotation, user, company, inquiry, inq_id]);

  const onPreview = useCallback(() => {
    const html = getHtml();
    setPreview(html);
    setShowPreview(true);
  }, [getHtml]);

  const closePreview = useCallback(() => {
    setShowPreview(false);
  }, []);

  const sendToEmail = useCallback(async () => {
    const html = getHtml();
    const email = [];
    email.push(inquiry?.customer?.email_address);
    const payload = {
      to: email,
      // subject: `Quotation for ` + inquiry.ins_cat_id.name + ` Insurance`,
      subject: `Quotation for Insurance`,
      body: html,
      type_name: 'quotation',
    };
    const resp = await sendEmail({ data: payload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  }, [inquiry, getHtml]);

  const printQuote = useReactToPrint({
    content: () => iframeRef.current,
  });

  const downloadQuote = useCallback(() => {
    const html = getHtml();
    htmlStringToPdf(html);
  }, [getHtml]);

  useEffect(() => {
    // loadInsurers();
    loadInquiry();
    const html = getHtml();
    setHtmlFile(html);
  }, []);

  const getLeadData = (value) => {
    const data = {
      ...inquiry,
      id: value?.option?.id,
      customer: value?.option?.customer,
    };
    setInquiry(data);
  };

  return (
    <Layout className='h-full noPrint'>
      <Sider width={220}>
        <div className='bg-white p-2 h-full border-r border-grey-40'>
          <div className='flex gap-2'>
            <IconButton onClick={() => navigate(-1)} className='group top-1 flex items-center mt-2'>
              <BackArrow svgClass='fill-grey-90 w-8 h-8 group-hover:fill-primary-90 transition-all  duration-500 group-hover:-translate-x-1' />
            </IconButton>
            <div
              onClick={selectAll}
              className='text-m cursor-pointer flex items-center justify-between p-1 font-semibold bg-white mt-2'
            >
              Lead Details
            </div>
          </div>
          <Divider className='mt-2' />
          {!inq_id && (
            <>
              <SearchAPI
                type='search'
                searchParams='lead_id'
                placeholder={'Search Lead'}
                field={{ name: 'lead' }}
                onSelect={getLeadData}
                defaultValue={{ id: inquiry?.id, name: inquiry?.lead_id }}
                onDefaultValueSet={(data) => {
                  if (data.data && data.data[0]) setInquiry(data.data[0]);
                }}
                extraRightRender={() => (
                  <button type='button' onClick={toggleAddingLead}>
                    <span className='text-primary-90'>+ New Lead</span>
                  </button>
                )}
              />
            </>
          )}
          {inquiry?.customer && (
            <>
              <LabelValue
                label='Customer'
                value={inquiry?.customer?.name || inquiry?.customer?.corp_name}
                vertical
              />
              <LabelValue label='Email' value={inquiry?.customer?.email_address} vertical />
              <LabelValue label='Phone' value={inquiry?.customer?.contact_number} vertical />
            </>
          )}
        </div>
      </Sider>
      <Content className='h-full '>
        <Tabs
          defaultActiveKey='1'
          items={tabs}
          tabBarExtraContent={
            <div className='flex gap-2'>
              {showButton && (
                <>
                  <Tooltip title='Email Preview'>
                    <Button
                      className='flex items-center border border-primary-90'
                      onClick={onPreview}
                      type='text'
                      icon={<PicRightOutlined />}
                    >
                      Email Preview
                    </Button>
                  </Tooltip>
                  <Tooltip title='Send to Customer'>
                    <Button
                      className='flex items-center border border-primary-90'
                      onClick={sendToEmail}
                      type='text'
                      icon={<SendOutlined />}
                    >
                      Share
                    </Button>
                  </Tooltip>
                  <Tooltip title='Print'>
                    <Button
                      className='flex items-center border border-primary-90'
                      onClick={printQuote}
                      type='text'
                      icon={<PrinterOutlined />}
                    >
                      Print
                    </Button>
                  </Tooltip>
                  <Tooltip title='Download'>
                    <Button
                      className='flex items-center border border-primary-90'
                      onClick={downloadQuote}
                      type='text'
                      icon={<DownloadOutlined />}
                    >
                      Download
                    </Button>
                  </Tooltip>
                </>
              )}
              <Tooltip title='Save Quotation'>
                <Button
                  className='flex items-center border border-primary-90'
                  onClick={handleSave}
                  type='text'
                  icon={<SaveOutlined />}
                  disabled={!selected?.length > 0}
                >
                  Save
                </Button>
              </Tooltip>
            </div>
          }
          className='bg-white h-full'
        />
      </Content>
      {selected?.length ? (
        <Sider width={300}>
          <div className='bg-white p-2 h-full overflow-y-auto border-l border-grey-40'>
            <div
              onClick={selectAll}
              className='text-m cursor-pointer flex items-center justify-between p-1 font-semibold bg-white'
            >
              Select Insurers
              <Checkbox
                className='m-2'
                checked={insurers.length && selected.length === insurers.length}
              />
            </div>
            {/* {insurers?.map((item) => (
              <>
                <p>{item?.product?.name}</p> */}
            <List dataSource={insurers} renderItem={renderInsurer} className='bg-grey-40 p-1' />
            {/* </> */}
            {/* ))} */}
          </div>
        </Sider>
      ) : null}

      <Modal
        title='Quotation'
        width={window.innerWidth - 100}
        open={showPreview}
        onCancel={closePreview}
        footer={false}
      >
        <div
          ref={contentRef}
          className='p-4 borderded'
          dangerouslySetInnerHTML={{ __html: preview }}
        ></div>
      </Modal>
      <Modal open={loading} footer={null} closable={false}>
        <div className='h-20 flex justify-center items-center'>
          <Spin />
        </div>
      </Modal>
      <div className='hidden'>
        <div className='p-4' ref={iframeRef} dangerouslySetInnerHTML={{ __html: htmlFile }}></div>
      </div>

      {/* Add Client modal */}
      <CustomModal destroyOnClose={true} footer={''} open={leadForm} onCancel={toggleAddingLead}>
        <LeadForm onSave={onSave} backNavigate={toggleAddingLead} permissions={permissions} />
      </CustomModal>
    </Layout>
  );
};

export default QuotationEngine;
