import Request from 'service/api-service/client';

export const getShift = async () => {
  const resp = await Request({
    method: 'get',
    API: `attendance/shift`,
  });
  return resp;
};

export const postShift = async (props = { data: '' }) => {
  const resp = await Request({
    method: 'post',
    API: `attendance/shift`,
    data: props?.data,
  });
  return resp;
};

export const upadteShift = async (props = { id: '', data: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `attendance/shift?id=${props?.id}`,
    data: props?.data,
  });
  return resp;
};

export const getAssignees = async (id, pageNum) => {
  const resp = await Request({
    method: 'get',
    API: `attendance/assigns?id=${id}&page=${pageNum}`,
  });
  return resp;
};

export const getAttendancebyStatus = async ({ date = '', status = '', shift_id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: `attendance/?start=${date}&status=${status}&shift=${shift_id}`,
  });
  return resp;
};

export const updateAttendanceStatus = async ({ data }) => {
  const resp = await Request({
    method: 'patch',
    API: `attendance/?id=${data?.id}&status=${data?.status}`,
  });
  return resp;
};

// my attendence

export const getMyAttendance = async (
  props = { id: '', method: 'get', start: null, end: null },
) => {
  const searchParams = new URLSearchParams();
  if (props?.start) searchParams.set('start', props.start);
  if (props?.end) searchParams.set('end', props.end);
  if (props?.id) searchParams.set('user', props.id);
  return await Request({
    method: props?.method,
    API: `attendance/mark?${searchParams.toString()}`,
  });
};

export const attendanceMarkEmp = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: `attendance/mark`,
    data,
  });
  return resp;
};

export const getShiftHistory = async ({ id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: `attendance/shift?employee_id=${id}`,
  });
  return resp;
};
