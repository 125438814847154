/** external deps */
import { useRef, useState, useEffect } from 'react';
/** internal deps */
import { Close } from 'assets/icons';
import { CustomModal, Button, IconButton } from 'components';
// import { NOTIFICATION_MSG } from "constants";
// import { sendMail, uploadDoc } from "service/api";
import FileShow from './FileShow';
import './style.css';
// import { checkFileType } from "utils";
import EmailChips from './email-chips';

const MailModal = ({
  showMailModal,
  selectedMessages,
  handleRemoveSelected,
  // attachedFiles,
  handleCloseMailModal,
  emails = [],
  subject,
  body,
  received,
  defaultTags = [],
}) => {
  /* Reference */
  const mailRef = useRef();
  const fileInputRef = useRef(null);

  /* states */
  const [tags, setTags] = useState(defaultTags);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    setTags(defaultTags);
  }, [defaultTags]);

  /* funcs */
  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const isInList = (email) => tags.includes(email);

  const onChange = (event) => {
    const email = event.target.value.trim(); // to remove leading and trailing whitespaces before validation.
    if (!email) {
      setError(false);
    } else if (!isValidEmail(email)) {
      setError('Email is invalid');
    } else {
      setError(false);
    }

    setValue(email);
  };
  const addEmails = (event) => {
    const email = event.target.value.trim();
    if (!email) {
      return; // exit if email is empty or only whitespace
    }
    if (!isValidEmail(email)) {
      setError('Email is invalid');
      return;
    }
    if (isInList(email)) {
      setError(`Email has already been added.`);
      return;
    }
    setTags([...tags, email]);
    setValue('');
    setError(false);
  };

  const handleRemoveEmail = (item) => setTags((prev) => prev.filter((i) => i !== item));

  const handleSendMail = async (e) => {
    // setLoading(true);
    // e.preventDefault();
    // const formData = new FormData(mailRef.current);
    // const formProps = Object.fromEntries(formData);
    // let urls = selectedMessages?.map((val) => ({
    //   url: `${process.env.REACT_APP_DOC_URL}${val.doc_url}`,
    //   type: val?.type,
    // }));
    // let payload = {
    //   to: {
    //     emails: [...tags],
    //   },
    //   subject: formProps?.subject,
    //   body: formProps?.body,
    //   file_url: {
    //     urls,
    //   },
    // };
    // // const resp = await sendMail(payload);
    // const
    // if (resp?.data?.status) {
    //   Toast({
    //     type: "success",
    //     message: NOTIFICATION_MSG.success,
    //     description: resp?.data?.msg,
    //   });
    //   setLoading(false);
    // } else {
    //   Toast({
    //     type: "error",
    //     message: NOTIFICATION_MSG.error,
    //     description: resp?.error || "Failed to send email",
    //   });
    //   setLoading(false);
    // }
    // handleCloseMailModal();
    // setTags([]);
  };

  const handleFileChange = (e) => {
    // setIsUploading(true);
    // const files = Array.from(e.target.files);
    // files.forEach(async (file) => {
    //   if (file) {
    //     const doc_size = file?.size;
    //     const type = checkFileType(file);
    //     const payload = {
    //       msg: file?.name || "no name",
    //       type,
    //       doc_url: URL.createObjectURL(file),
    //       doc_size,
    //     };
    //     const formData = new FormData();
    //     formData.append("doc", file, file?.name);
    //     const { status, data } = await uploadDoc(formData);
    //     if (status) {
    //       const doc_url = data?.data?.doc ?? null;
    //       attachedFiles({ ...payload, doc_url });
    //       setIsUploading(false);
    //     }
    //   }
    // });
  };

  const renderSelected = (message, index) => (
    <div className='relative min-w-max min-h-full mt-1.5' key={index}>
      <input type='hidden' name='file_urls' value={message.doc_url} />

      <FileShow {...message} received={received} />
      {!received && (
        <IconButton
          onClick={() => handleRemoveSelected(message)}
          type='button'
          className='absolute -right-1 -top-1'
        >
          {/* <CloseFilled className="w-[22px] h-[22px] fill-secondary-50 hover:fill-primary" /> */}
        </IconButton>
      )}
    </div>
  );

  useEffect(() => {
    if (Array.isArray(emails) && emails.length > 0) setTags(emails);
  }, [emails]);

  return (
    <CustomModal
      open={showMailModal}
      closable={true}
      onCancel={handleCloseMailModal}
      width={700}
      footer={''}
      destroyOnClose={true}
      className='mail'
      CloseIcon={<Close className='hidden' />}
    >
      <div className='bg-primary-70/[0.7] flex justify-between py-5 px-7 rounded-xl rounded-b-none'>
        <span className='font-semibold text-sm'>Email</span>
        {/* <IconButton onClick={handleCloseMailModal}>
          <Close className="w-4 h-4 stroke-secondary hover:stroke-secondary-80 transition-all duration-500" />
        </IconButton> */}
      </div>
      <div className='flex px-7 pb-10'>
        <form className='grid w-full gap-4' ref={mailRef}>
          <EmailChips
            tags={tags}
            addEmails={addEmails}
            handleRemoveEmail={handleRemoveEmail}
            value={value}
            onChange={onChange}
            error={error}
            received={received}
          />
          <input
            className='border-grey-70 border-b w-full p-2 text-sm outline-0'
            placeholder='Subject'
            name='subject'
            defaultValue={subject}
            disabled={received}
          />
          <textarea
            className='outline-0 text-sm p-2 resize-none'
            rows={5}
            placeholder='Message'
            name='body'
            defaultValue={body}
            disabled={received}
          />
          <div className='flex gap-4 overflow-x-scroll overflow-y-hidden h-20'>
            {selectedMessages.map(renderSelected)}
          </div>

          {!received && (
            <div className='flex gap-4 items-center'>
              <Button
                classes='bt-xs bt-tertiary !text-sm'
                text='Send'
                onClick={handleSendMail}
                loading={loading}
              />

              <div>
                <div className='flex items-center'>
                  {isUploading ? (
                    'Uploading...'
                  ) : (
                    <button type='button' onClick={() => fileInputRef.current.click()}>
                      {/* <AttachmentIcon classes="fill-black w-6 h-6 hover:fill-secondary-70 transition-all duration-300" /> */}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </form>
        <input
          type='file'
          ref={fileInputRef}
          onChange={handleFileChange}
          multiple
          className='hidden'
        />
      </div>
    </CustomModal>
  );
};

export default MailModal;
