import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

import { BackArrow, IconChevron } from 'assets/icons';
import { Checkbox, CustomModal, IconButton, PDFGenerator, Toast } from 'components';
import imgGoDigit from 'assets/png/godigit.png';
import PDFComponent from './pdf';

import './style.css';
import MailModal from 'components/mail-modal';
import Button from 'components/button';
import { parseQuoteData } from 'utils/parse';
import { NOTIFICATION_MSG } from 'constants';
import { useNavigate } from 'react-router-dom';
import { compareInsuranceProduct, addQuoteApi } from 'service/api-service';

export const EditableLabel = (props) => {
  return <input {...props} />;
};

const ComparePolicy = ({ formData, goBack, setFormData, handleFormNavigation }) => {
  const navigate = useNavigate();
  /* States */
  const [loading, setLoading] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showMailModal, setShowMailModal] = useState(false);
  const [activeDetailsIndex, setActiveDetailsIndex] = useState(undefined);

  const handleClickMail = () => setShowMailModal(true);
  const handleCloseMailModal = () => {
    setShowMailModal(false);
  };
  const handleRemoveSelected = () => {
    // console.log('remove');
  };

  const details = {
    'Client Details': [
      {
        name: 'Client Name',
        value: formData?.customer_id?.name,
      },
      {
        name: 'Date of Birth',
        value: '-',
      },
      {
        name: 'GST Type',
        value: formData?.gsttype,
      },
      {
        name: 'PAN Number',
        value: '-',
      },
      {
        name: 'Aadhar Number',
        value: '-',
      },
      {
        name: 'City',
        value: 'Mohali',
      },
      {
        name: 'State',
        value: 'Punjab',
      },
      {
        name: 'Country',
        value: '-',
      },
      {
        name: 'Pincode',
        value: '-',
      },
      {
        name: 'Contact Number',
        value: '-',
      },
      {
        name: 'Email Address',
        value: '-',
      },
    ],
    'Insurer Details': [
      {
        name: 'Insurance',
        value: formData?.insurance_category,
      },
      {
        name: 'Registered venue',
        value: formData?.rto_venue,
      },
      {
        name: 'RTO city number',
        value: formData?.rto_city,
      },
      {
        name: 'Car Brand',
        value: formData?.brand,
      },
      {
        name: 'Model',
        value: formData?.model,
      },
      {
        name: 'Manufacturing Year',
        value: formData?.insurance_details?.manufac_year,
      },
      {
        name: 'Variant',
        value: formData?.varient,
      },
    ],
  };

  const comparePolicyData = {
    discounts: [
      { id: '10', name: 'New No-Claim', value: '20%' },
      { id: '11', name: 'OD Discount', value: '60%' },
    ],
  };

  /* Get Table Data Product */
  const getProductsData = async () => {
    setLoading(true);
    const resp = await compareInsuranceProduct({
      premium: formData?.premium_payment,
      category_id: formData?.insurance_category,
    });
    if (resp?.data?.status) {
      setPolicyData(resp?.data?.data);
    }
    setLoading(false);
  };

  const onChange = ({ e, parentId }) => {
    const updateFor = e.target.name.split('-')[0];
    const updateElementId = e.target.name.split('-')[1];
    const value = e.target.value;
    const parent = parentId;

    if (updateFor === 'percent') {
      setPolicyData((pre) => {
        return pre.map((item) => {
          if (item.id === parent) {
            return {
              ...item,
              discounts: item.discounts.map((dis) => {
                if (dis.id === updateElementId) {
                  return { ...dis, value: value };
                }
                return dis;
              }),
            };
          }

          return item;
        });
      });
    } else if (updateFor === 'discount') {
      setPolicyData((pre) => {
        return pre.map((item) => {
          if (item.id === parent) {
            return {
              ...item,
              discounts: item.discounts.map((dis) => {
                if (dis.id === updateElementId) {
                  return { ...dis, name: value };
                }
                return dis;
              }),
            };
          }

          return item;
        });
      });
    } else if (updateFor === 'inr') {
      setPolicyData((pre) => {
        return pre.map((item) => {
          if (item.id === parent) {
            return {
              ...item,
              price: value,
            };
          }

          return item;
        });
      });
    }
  };

  const handleChange = ({ event, policyId }) => {
    let prevSelectedPolicies = selectedPolicies;
    if (event.target.checked) {
      prevSelectedPolicies.push(policyId);
    } else {
      prevSelectedPolicies = prevSelectedPolicies.filter((pId) => pId !== policyId);
    }
    setSelectedPolicies(prevSelectedPolicies);
  };

  const handleViewQuoteClick = () => {
    toggleViewModal();
  };

  const handleExpandDetailsClick = (policyId) => {
    setActiveDetailsIndex(activeDetailsIndex !== policyId ? policyId : '');
  };

  const toggleViewModal = () => setShowModal((pre) => !pre);

  const getPolicies = () => {
    return policyData.filter((policy) => selectedPolicies.includes(policy.id));
  };

  const onSave = async () => {
    //needs update with form key name
    let product = getPolicies();
    const formatData = parseQuoteData({ ...formData, product: product.map((item) => item?.id) });
    const resp = await addQuoteApi(formatData);
    if (resp?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      navigate('/quotes');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to update data');
    }
  };

  useEffect(() => {
    getProductsData();
  }, [formData]);

  return (
    <div className='py-4 2xl:p-10  bg-white rounded-2xl ' id='compare'>
      <section className='grid gap-8 md:grid-cols-12'>
        <div className='col-span-12 lg:col-span-8 2xl:col-span-8'>
          <div className=''>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex items-center gap-4'>
                <IconButton onClick={goBack} className='group right-2 top-1 flex items-center'>
                  <>
                    <BackArrow svgClass='fill-black w-10 h-10 group-hover:fill-primary-90 transition-all  duration-500 group-hover:-translate-x-1' />
                  </>
                </IconButton>
                <h1 className='text-sm font-semibold'>Compare Policy</h1>
              </div>
              <div className='flex gap-4'>
                <button
                  onClick={handleViewQuoteClick}
                  // disabled={selectedPolicies.length === 0}
                  className='px-4 py-2 rounded-full border-2 text-x 2xl:text-xxs border-primary-90 cursor-pointer'
                >
                  View Quote
                </button>
                <Button
                  //  disabled={selectedPolicies.length === 0}
                  text='Save'
                  variant='primary'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={onSave}
                />
              </div>
            </div>

            {/* FILTERS */}
            <div className='flex gap-2 items-center'>
              <div className='flex flex-wrap gap-2 2xl:gap-4 items-center py-1 rounded'>
                {/* <div className="flex gap-2 items-center">
                  <Filter className="w-4 h-4" />
                  <p className="font-semibold text-x 2xl:text-xxs text-grey">Filters</p>
                </div>
                <div className="flex gap-1 items-baseline py-1 pl-3 rounded-full bg-grey-light">
                  <p id="life-cover" className="text-x 2xl:text-xxs text-grey">
                    Life Cover:
                  </p>
                  <Select
                    suffixIcon={<IconChevron className="w-3 h-3 fill-grey-90" />}
                    aria-labelledby="life-cover"
                    defaultValue="50 lakhs"
                    className="text-x"
                    bordered={false}
                    options={[
                      { value: "50 lakhs", label: "50 lakhs" },
                      { value: "100 lakhs", label: "100 lakhs" },
                      { value: "5 lakhs", label: "5 lakhs" },
                    ]}
                  />
                </div>
                <div className="flex gap-1 items-baseline py-1 pl-3 rounded-full bg-grey-light">
                  <p id="till-age" className="text-x 2xl:text-xxs text-grey">
                    Cover till age:
                  </p>
                  <Select
                    suffixIcon={<IconChevron className="w-3 h-3 fill-grey-90" />}
                    aria-labelledby="till-age"
                    defaultValue="60 years"
                    bordered={false}
                    options={[
                      { value: "60 years", label: "60 years" },
                      { value: "50 years", label: "50 years" },
                      { value: "5 years", label: "5 years" },
                    ]}
                  />
                </div>
                <div className="flex gap-1 items-baseline py-1 pl-3 rounded-full bg-grey-light">
                  <p id="payment-freq" className="text-x 2xl:text-xxs text-grey">
                    Payment Frequency:
                  </p>
                  <Select
                    suffixIcon={<IconChevron className="w-3 h-3 fill-grey-90" />}
                    aria-labelledby="payment-freq"
                    defaultValue="yearly"
                    bordered={false}
                    options={[
                      { value: "yearly", label: "yearly" },
                      { value: "monthly", label: "monthly" },
                      { value: "biweekly", label: "biweekly" },
                    ]}
                  />
                </div> */}

                {/* <button className="flex gap-1 items-center px-4 text-x 2xl:text-xxs text-primary-90">
              <span className="underline whitespace-nowrap">Edit Info</span>
              <IconPencil className="w-3 h-3 stroke-primary-90" />
              </button> */}
              </div>
              {/* <div>
                <Button
                //  disabled={selectedPolicies.length === 0}
                  text="Save"
                  variant="primary"
                  type="reset"
                  classes="border border-gray-90 text-black px-6"
                  onClick={onSave}
                />
              </div> */}
            </div>
          </div>

          {/* POLICIES FROM API */}

          <div className='grid gap-6 pt-4 overflow-y-scroll h-[100vh] scrollbar-hide'>
            {loading ? (
              <Spin tip='Loading' />
            ) : (
              <div className='flex flex-col gap-6'>
                {policyData?.map((policy, i) => (
                  <div key={i}>
                    <div className='grid gap-4'>
                      <div className='rounded-lg border-2 border-grey-80'>
                        <p className='p-4 pb-0 font-semibold text-grey text-xxs'>{policy?.name}</p>
                        <div className='grid  md:grid-cols-2 md:gap-3 lg:grid-cols-3 xl:grid-cols-4 items-center justify-between divide-x divide-grey-70 pb-4 pt-2'>
                          <div className='px-4 w-44 2xl:w-4/6'>
                            <img
                              src={policy?.image ? policy?.image : imgGoDigit}
                              alt={policy?.name}
                              className='rounded-xl h-16 2xl:h-full w-full'
                            />
                          </div>
                          {policy.benefits ? (
                            <div
                              className='product_benefits max-lines'
                              dangerouslySetInnerHTML={{ __html: policy?.benefits }}
                            />
                          ) : (
                            <div></div>
                          )}

                          <div className='grid grid-cols-[1fr,max-content] gap-y-1 px-2 2xl:px-4'>
                            <table className='w-full'>
                              <tbody className='text-secondary-80 text-xxs'>
                                <tr>
                                  <td>New No-Claim</td>
                                  <td>{policy?.no_claim} %</td>
                                </tr>
                                <tr>
                                  <td>OD Discount</td>
                                  <td>{policy?.od_discount} %</td>
                                </tr>
                              </tbody>
                            </table>
                            {/* {comparePolicyData?.discounts.map((discount) => {
                              return (
                               
                                <Fragment key={discount?.name}>
                                  <EditableLabel
                                    className="w-28 outline-transparent text-x text-grey"
                                    defaultValue={discount.name}
                                    maxLength="18"
                                    name={"discount-" + discount.id}
                                    onChange={(e) => {
                                      onChange({ e: e, parentId: policy.id });
                                    }}
                                  />
                                  <EditableLabel
                                    className="w-8 outline-transparent text-x text-grey"
                                    defaultValue={discount.value}
                                    maxLength="3"
                                    name={"percent-" + discount.id}
                                    onChange={(e) => {
                                      onChange({ e: e, parentId: policy.id });
                                    }}
                                  />
                                </Fragment>
                              );
                            })} */}
                          </div>
                          <div className='flex items-center justify-around px-2 2xl:px-4'>
                            <p className='flex gap-2 items-baseline font-semibold text-grey '>
                              <span className='text-xxs'>INR.</span>
                              <EditableLabel
                                className='w-24 text-xl outline-transparent border-0 truncate'
                                defaultValue={new Intl.NumberFormat(undefined).format(
                                  policy.premium,
                                )}
                                maxLength='10'
                                name={'inr-' + policy?.id}
                                onChange={(e) => {
                                  onChange({ e: e, parentId: policy?.id });
                                }}
                              />
                              {/* <span contentEditable="true" className="text-xxl outline-transparent">
                            {new Intl.NumberFormat(undefined).format(policy.price)}
                          </span> */}
                            </p>
                            <div className=''>
                              <Checkbox
                                updateClass='!pl-0'
                                variant='secondary'
                                size='lg'
                                isRounded={true}
                                updateSpanClass=' -translate-y-8 translate-x-6 !rounded-full scale-150 !h-6 !w-6'
                                onChange={(e) => {
                                  handleChange({ event: e, policyId: policy?.id });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='flex justify-end items-end px-3 py-2 rounded-md rounded-t-none bg-grey-light'>
                          <button
                            onClick={() => handleExpandDetailsClick(policy?.id)}
                            className='flex gap-2 items-center text-x'
                          >
                            View More Details
                            <IconChevron
                              className={`w-3 h-3 fill-primary-90 details_icon ${
                                activeDetailsIndex === policy.id ? 'rotate-0' : '-rotate-90'
                              }`}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* policy details */}
                    <div
                      className={`${
                        activeDetailsIndex === policy.id ? 'block' : 'hidden'
                      } grid grid-cols-1 border border-primary-70 bg-primary-light/[0.3] mt-2 p-2 gap-4 rounded-lg transition-all duration-500`}
                    >
                      <div className='px-2'>
                        <span className='font-medium leading-10'>Conditions</span>
                        {policy?.condition ? (
                          <div
                            className='product_benefits max-lines'
                            dangerouslySetInnerHTML={{ __html: policy?.condition }}
                          />
                        ) : (
                          <div className='text-grey-80 text-x'>No details</div>
                        )}
                      </div>
                      <div className='px-2'>
                        <span className='font-medium leading-10'>Exclusion</span>
                        {policy?.exclusion ? (
                          <div
                            className='product_benefits max-lines'
                            dangerouslySetInnerHTML={{ __html: policy?.exclusion }}
                          />
                        ) : (
                          <div className='text-grey-80 text-x'>No details</div>
                        )}
                      </div>
                      <div className='px-2'>
                        <span className='font-medium leading-10'>Description</span>
                        {policy?.description ? (
                          <div
                            className='product_benefits max-lines'
                            dangerouslySetInnerHTML={{ __html: policy?.description }}
                          />
                        ) : (
                          <div className='text-grey-80 text-x'>No details</div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* DETAILS */}
        {/* {(activeDetailsIndex == policy.id ? "yes" :"no")} */}
        <div className='col-span-12 lg:col-span-4 2xl:col-span-4'>
          <section className='grid gap-4 items-start p-4 rounded-md bg-primary-light/[0.3] md:col-start-4 md:col-span-2  bg-ocean'>
            {Object.entries(details)?.map(([key, val]) => (
              <section className='grid gap-3' key={key}>
                <p className='font-semibold text-s'>{key}</p>
                <div className='grid sm:grid-cols-3 md:grid-cols-2 2xl:grid-cols-3 gap-3'>
                  {val?.map((item) => (
                    <div className='' key={item?.name}>
                      <p className='text-x text-grey'>{item?.name}</p>
                      {typeof item?.value === 'string' ? (
                        <p className='font-medium text-xxs'>{item?.value || '-'}</p>
                      ) : null}
                    </div>
                  ))}
                </div>
              </section>
            ))}
          </section>
        </div>
      </section>
      <CustomModal
        width={840}
        destroyOnClose={true}
        open={showModal}
        onCancel={toggleViewModal}
        footer={null}
      >
        <PDFGenerator handleClickMail={handleClickMail}>
          <PDFComponent getPolicies={getPolicies} details={details['Customer Details'][0]} />
        </PDFGenerator>
      </CustomModal>
      <MailModal
        showMailModal={showMailModal}
        defaultTags={[
          formData?.customer_id?.email_address || '',
          formData?.contact?.primary_email_address || '',
        ]}
        handleClickMail={handleClickMail}
        handleCloseMailModal={handleCloseMailModal}
        selectedMessages={[]}
        handleRemoveSelected={handleRemoveSelected}
        attachedFiles={[]}
      />
    </div>
  );
};

// const Filters

export default ComparePolicy;
