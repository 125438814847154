/** external deps */
import React, { useState, useEffect } from 'react';
import { Space } from 'antd';
import Fuse from 'fuse.js';

/** internal deps */
import { ContentHeader, TableComponent, IconButton } from 'components';
import { breadcrumbObj } from './data';
import { DELETE, EDIT } from 'assets/icons';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import AddEditLeaveTypeModal from './AddEditLeaveTypeModal';
import DeleteLeaveTypeModal from './DeleteLeaveTypeModal';
import { getLeaveType } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const LeavesType = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [selectedLeaveType, setSelectedLeaveType] = useState();
  const [leavesData, setLeavesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredLeaveType, setfilteredLeaveType] = useState([]);

  const columns = [
    {
      key: 'label',
      title: 'Leave Type',
      dataIndex: 'label',
    },
    {
      key: 'days',
      title: 'Number of days',
      dataIndex: 'days',
      render: (_, record) => (
        <div>
          {record.days}
          {record.days > 1 ? ' days' : ' day'}
        </div>
      ),
    },
    {
      key: 'note',
      title: 'Notes',
      dataIndex: 'note',
      render: (value) => <div className='truncate'>{value || '--'}</div>,
    },
    {
      key: 'docs',
      title: 'Docs',
      dataIndex: 'docs',
      render: (value) => {
        const data = JSON.parse(value);
        if (Array.isArray(data)) {
          return (
            <div className='flex flex-wrap gap-1'>
              {data?.map((val, ind) => (
                <div className='py-1 px-2 bg-grey-40 rounded-md text-xxxs' key={ind}>
                  {val}
                </div>
              ))}
            </div>
          );
        } else {
          return <div className='truncate'>{'--'}</div>;
        }
      },
    },
    {
      key: 'created_by',
      title: 'Created By',
      dataIndex: 'created_by',
      render: (data) => {
        let value = data?.name || '--';
        if (data?.emp_code) {
          value = `${value}(${data.emp_code})`;
        }
        return <div className='truncate capitalize'>{value}</div>;
      },
    },
    {
      key: 'updated_by',
      title: 'Updated By',
      dataIndex: 'updated_by',
      render: (data) => {
        let value = data?.name || '--';
        if (data?.emp_code) {
          value = `${value}(${data.emp_code})`;
        }
        return <div className='truncate capitalize'>{value}</div>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.leaves_type.permissions.delete}
            onClick={() => {
              setSelectedLeaveType(record);
              toggleDeleteLeaveType();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.leaves_type.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  /* funcs */
  const toggleAddLeaveType = () => {
    setInitials(undefined);
    setAddModal((pre) => {
      return !pre;
    });
  };

  const toggleDeleteLeaveType = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    setSearchQuery(e.target.value);
    const fuse = new Fuse(leavesData, {
      keys: ['label', 'days', 'note', 'created_by.name', 'updated_by.name'],
      threshold: 0.2,
    }).search(e.target.value);
    setfilteredLeaveType(fuse.map((i) => i.item));
  };

  const onEditPress = (record) => {
    let payload = record;
    if (JSON.parse(record?.docs)) {
      payload = { ...record, doc_req: true };
    }
    setInitials(payload);
    setSelectedLeaveType(record);
    setAddModal(true);
  };

  const getLeavesType = async () => {
    setLoading(true);
    const resp = await getLeaveType();
    if (resp?.data?.status) {
      setLeavesData(resp?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getLeavesType();
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        title={<p className='font-semibold leading-normal text-s'>Leave Types</p>}
        // data={breadcrumbObj}
        btnText='+ Leave Type'
        buttonId={elementData?.leaves_type?.permissions?.create}
        onClick={toggleAddLeaveType}
      />
      <TableComponent
        loading={loading}
        columns={columns}
        dataSource={searchQuery ? filteredLeaveType : leavesData}
        getRowClassName={rowCommonClass}
      />

      {/* add leaves type modal */}
      <AddEditLeaveTypeModal
        showAddModal={showAddModal}
        toggleAddLeaveType={toggleAddLeaveType}
        editInitials={editInitials}
        permissions={permissions}
        getLeavesType={getLeavesType}
      />

      {/* delete leaves type confirm modal */}
      <DeleteLeaveTypeModal
        showDeleteModal={showDeleteModal}
        toggleDeleteLeaveType={toggleDeleteLeaveType}
        selectedLeaveType={selectedLeaveType}
        getLeavesType={getLeavesType}
      />
    </div>
  );
};

export default SecureComponent(LeavesType);
