import Request from 'service/api-service/client';

export const getHelpline = async () => {
  const resp = await Request({
    method: 'get',
    API: 'tickets/help-line',
  });
  return resp;
};

export const postHelpline = async (props = { data: '' }) => {
  const resp = await Request({
    method: 'post',
    API: 'tickets/help-line',
    data: props?.data,
  });
  return resp;
};

export const updateHelpline = async (props = { data: '', id: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `tickets/help-line?id=${props.id}`,
    data: props?.data,
  });
  return resp;
};

// getFaq

export const getFaqData = async () => {
  const resp = await Request({
    method: 'get',
    API: 'tickets/faq',
  });
  return resp;
};

export const postFaq = async (props = { data: '' }) => {
  const resp = await Request({
    method: 'post',
    API: 'tickets/faq',
    data: props?.data,
  });
  return resp;
};

export const deleteFaq = async (props = { id: '' }) => {
  const resp = await Request({
    method: 'delete',
    API: `tickets/faq?id=${props.id}`,
  });
  return resp;
};

export const updateFaq = async (props = { data: '', id: '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `tickets/faq?id=${props.id}`,
    data: props?.data,
  });
  return resp;
};

export const getTickets = async (props = { id: '', status: '' }) => {
  const resp = await Request({
    method: 'get',
    API: `tickets/?id=${props.id || ''}&status=${props?.status || ''}`,
  });
  return resp;
};

export const updateTicket = async (props = { data: '', id: '' }) => {
  return await Request({
    method: 'patch',
    API: `tickets/?id=${props?.id}`,
    data: props?.data,
  });
};
