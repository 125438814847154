import { UploadDocumentIcon } from 'assets/svg';
import React, { useState } from 'react';

const FileUploader = ({ onPickFile = () => {} }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles(files);
  };

  const handleInputChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);

    const [file] = files;
    if (!file) return;
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const image = target.result;
      const payload = {
        name: file.name,
        docs: image,
      };
      onPickFile(payload);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div
      className={`bg-slate-50 p-5 text-center ${isDragOver ? 'bg-slate-200' : ''}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input
        type='file'
        id='fileInput'
        onChange={handleInputChange}
        style={{ display: 'none' }}
        multiple
      />
      <label htmlFor='fileInput' className='grid place-items-center'>
        {selectedFiles.length ? (
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        ) : (
          <UploadDocumentIcon />
        )}
        <div className='flex flex-col items-center'>
          <p className='text-sm'>Drag & drop or Browse files</p>
        </div>
      </label>
    </div>
  );
};

export default FileUploader;
