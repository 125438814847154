/** external deps */
import { Link, useLocation } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { Dropdown, Space } from 'antd';
/** internal deps */
import LoggedUser from 'assets/png/user.png';
import { DropdownIcon } from 'assets/icons';
import { items } from './data';
import '../style.css';
import { Context } from 'store/context';

const PosNavbar = () => {
  const { user } = useContext(Context);
  const userName = user?.name?.charAt(0)?.toUpperCase() + user?.name?.slice(1);
  const [rotate, setRotate] = useState(false);
  const location = useLocation();
  const heading = location.pathname.split('/').at(1).replaceAll('-', ' '); // first element would be empty string as pathname always starts with a `/`
  const onClick = () => {
    setRotate(false);
  };

  return (
    <>
      <div className='flex justify-between items-center border-b border-grey-light px-10 h-16 sticky top-0 z-20 bg-white dark:bg-black noPrint shadow-table'>
        <div className='flex items-center gap-4'>
          <Link to={'/pos'}>
            {/* <UserInitialIcon
              name={company?.name}
              imageUrl={process.env.REACT_APP_DOC_URL + company?.logo}
            /> */}
          </Link>
          <label className='text-sm md:text-l font-semibold capitalize'>{heading}</label>
        </div>
        <Dropdown
          className='group'
          menu={{
            items,
            onClick,
          }}
          onOpenChange={setRotate.bind(this, !rotate)}
          trigger={['click']}
        >
          <button
            className={`group transition-all duration-500 hover:text-primary-90 dark:text-white dark:hover:text-primary-90 ${
              rotate && 'text-primary-90'
            }`}
            onClick={(e) => e.preventDefault()}
          >
            <Space>
              <div className='h-10 w-10 mx-1 relative'>
                <img
                  src={process.env.REACT_APP_DOC_URL + user?.image || LoggedUser}
                  className='rounded-full w-full h-full'
                  alt='user'
                />
                {/* <div className="w-2.5 h-2.5 rounded-full bg-red-90 absolute top-1 right-0"></div> */}
              </div>
              {userName || 'User'}
              <DropdownIcon
                svgClass={`w-3 transition-all duration-500 group-hover:fill-primary-90 dark:fill-white ${
                  rotate && 'rotate-180 fill-primary-90'
                }`}
              />
            </Space>
          </button>
        </Dropdown>
      </div>
    </>
  );
};

export default PosNavbar;
