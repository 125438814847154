/** external deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

/** internal deps */
// import { Search } from 'assets/icons';
import { debounce } from 'utils';
import './style.css';
import { Close } from 'assets/icons';
import { rtoNumberFetch, searchApis, searchIcici, searchQuotationApis } from 'service/api-service';

let currentValue;
const SearchAPI = ({
  field,
  form,
  rightIcon,
  leftIcon,
  onIconClick,
  extraRightRender,
  InputClasses,
  divClasses,
  disabled,
  maxLength,
  dataFormatFunction,
  defaultValue,
  onDefaultValueSet,
  byBranch = false,
  byUser = false,
  getValue = false,
  getVals = true,
  searchParams = 'name',
  ...props
}) => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchAfter, setFetchAfter] = useState(getVals);
  const [defaultVal, setDefaultVal] = useState();
  // const {
  //   selectedData: { handleSelectedData, data: selectedDepartment },
  // } = useContext(Context);
  const formatNames = (name) => {
    // return name.includes("branch") ? ["branch", branches.list] : ["employee", employees.list];
    return name.includes('branch')
      ? ['branch', []]
      : name.includes('employee') ||
        name.includes('b_hr') ||
        name.includes('assignee') ||
        name.includes('user')
      ? ['employee', []]
      : name.includes('job_kpi')
      ? ['job_kpi', []]
      : name.includes('pos')
      ? ['pos', []]
      : name.includes('emp_id')
      ? ['employee', []]
      : name.includes('report')
      ? ['report', []]
      : name.includes('customer')
      ? ['customer', []]
      : name.includes('contact')
      ? ['contact', []]
      : name.includes('insurance_category')
      ? ['insurance_category', []]
      : name.includes('department')
      ? ['department', []]
      : name.includes('designation')
      ? ['designation', []]
      : name.includes('insurance_type')
      ? ['insurance_type', []]
      : name.includes('insurer')
      ? ['insurer', []]
      : name.includes('insurance_product')
      ? ['insurance_product', []]
      : name.includes('inquiry')
      ? ['inquiry', []]
      : name.includes('rto_venue')
      ? ['rto_venue', []]
      : name.includes('target_type')
      ? ['target_type', []]
      : name.includes('lead')
      ? ['lead', []]
      : name.includes('quotation_id')
      ? ['quotation_id', []]
      : name.includes('nameOfOrg')
      ? ['nameOfOrg', []]
      : name.includes('country')
      ? ['country', []]
      : name.includes('leave_type')
      ? ['leave_type', []]
      : '';
  };

  const fetch = debounce(async (value, cb) => {
    setLoading(true);
    currentValue = value;
    const [name, storedData] = formatNames(field?.name);
    const apiToCall = async (data) => {
      if (name === 'branch')
        return searchApis({ query: `${value}${byUser ? '&user_branch=true' : ''}`, api: 'branch' });
      if (name === 'employee' || name === 'report')
        return searchApis({
          query: `${value}${byBranch ? '&branch_user=true' : ''}`,
          api: 'employee',
        });
      if (name === 'customer') return searchApis({ query: value, api: 'customer' });
      if (name === 'department') return searchApis({ query: value, api: 'department' });
      if (name === 'designation') return searchApis({ query: value, api: 'designation' });
      if (name === 'job_kpis') return searchApis({ query: value, api: 'job-kpis' });
      if (name === 'pos') return searchApis({ query: value, api: 'pos' });
      if (name === 'target_type') return searchApis({ query: value, api: 'target_type' });
      if (name === 'rto_venue') return rtoNumberFetch({ q: value });
      if (name === 'leave_type') return searchApis({ query: value, api: 'leave_type' });
      if (name === 'inquiry') return searchApis({ query: value, api: 'inquiry' });
      if (name === 'lead') return searchApis({ query: value, api: 'inquiry' }); //TBD inquiry was renamed lead only at front-end
      if (name === 'quotation_id') return searchQuotationApis({ query: value });
      if (name === 'nameOfOrg') return searchIcici({ query: value, api: 'icici/organisation' });
      if (name.includes('country')) return searchIcici({ query: value, api: 'icici/country' });
      if (name === 'contact') {
        const tempStore = form?.values['customer_id'];
        const customer_id = !!id ? id : typeof tempStore === 'object' ? tempStore?.id : tempStore;
        if (!customer_id) {
          return () => [];
        }
        return searchApis({ query: value, api: 'contact', customer_id });
      }
      if (name === 'insurance_category') {
        let res = await searchApis({ query: value, api: 'insurance_category' });
        let update = res?.data?.data.map(({ name, product_type }) => {
          let options = product_type.map(({ name, id }) => {
            return { label: name, value: id };
          });
          return { label: name, options };
        });
        return update;
      }

      if (name === 'insurance_type') return searchApis({ query: value, api: 'insurance_type' });
      if (name === 'insurer') return searchApis({ query: value, api: 'insurer' });
      if (name === 'insurance_product')
        return searchApis({ query: value, api: 'insurance_product' });
    };

    try {
      const resp =
        storedData?.length > 0 ? { data: { status: true, data: storedData } } : await apiToCall();
      if (props?.treeData) {
        setData(resp);
      } else {
        if (resp?.data?.status) {
          if (currentValue === value) {
            let finalResponce;
            if (Array.isArray(resp?.data?.data)) {
              finalResponce = resp?.data?.data;
            } else {
              const { result } = resp?.data?.data;
              finalResponce = result;
            }
            let data = [];
            if (dataFormatFunction && typeof dataFormatFunction === 'function') {
              data = dataFormatFunction(finalResponce);
            } else if (props?.extraLabelProps) {
              //used for showing more values to dropdown
              const key = props?.extraLabelProps;
              data = finalResponce?.map((item) => {
                return {
                  ...item,
                  value: getValue ? item?.value : item?.id,
                  label: (
                    <div className='flex justify-between'>
                      <p>{item?.name || item[searchParams]}</p>
                      <p>{item[key]}</p>
                    </div>
                  ),
                };
              });
            } else {
              data = finalResponce?.map((item) => {
                return {
                  ...item,
                  value: getValue ? item?.value : item?.id,
                  label: item?.name || item?.corp_name || item[searchParams],
                };
              });
            }
            setData(data);
          }
          if (cb) {
            cb({ data: resp?.data, value: form?.initialValues?.[field?.name], form });
          }
        }
      }
    } catch (error) {
      console.log('data::', error);
    }

    setLoading(false);
  }, 700);

  const handleSearch = (newValue) => {
    if (newValue) {
      fetch(newValue);
    } else {
      setData([]);
    }
  };

  const handleChange = (_, option) => {
    if (form?.setFieldValue) {
      return form.setFieldValue(field?.name, option);
    }
  };

  //temporary fix for automatically set report_to when department is selected
  const handleSelect = (value, option) => {
    setFetchAfter(true); // calls Api only when data changes in row of table
    if (props?.onSelect) return props.onSelect({ value, option, form, field });
    if (getValue) {
      form.setFieldValue(field?.name, value);
    }
    // form.setFieldValue(field?.name, value);
  };

  useEffect(() => {
    const value =
      form?.values?.[field?.name]?.id ||
      form?.values?.[field?.name] ||
      form?.values?.[field?.name]?.name ||
      form?.initialValues?.[field?.name]?.id ||
      form?.initialValues?.[field?.name] ||
      form?.initialValues?.[field?.name]?.name ||
      defaultValue?.name;

    if (value && fetchAfter && defaultVal !== value) {
      setDefaultVal(defaultValue?.name);
      fetch(value, onDefaultValueSet);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (defaultValue) {
      form?.setFieldValue(field?.name, defaultValue?.id);
    }
  }, []);
  return (
    <div className={`group ${divClasses}`}>
      {props.label && (
        <div className=' pr-2 flex justify-between items-center'>
          <label
            className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xxs text-black ${
              props?.required ? 'required' : ''
            }`}
          >
            {props.label}
          </label>
          {props.tooltip && (
            <Tooltip title={props.tooltip}>
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </div>
      )}
      <div id='searchApi' className='relative'>
        {/* <div
          onClick={onIconClick}
          className={`absolute inset-y-0 bg-white right-0 px-3 rounded-r-md flex items-center cursor-pointer ${
            disabled && 'border-0'
          }`}
        >
          {!form?.values?.[field?.name]?.name && !form?.values?.[field?.name] ? (
            <Search svgClass='w-5 h-5 stroke-black z-10 group-focus-within:stroke-primary-90' />
          ) : (
            <Close className='w-4 h-4 z-10' />
          )}
        </div> */}
        <Select
          {...field}
          id={field?.name}
          className={`bg-[#F5FAFD] border border-[#D1EAF9] rounded-md  block w-full   px-2  focus:outline-none focus-within:border-primary-90
        placeholder-grey-90 placeholder:text-grey-90 placeholder:text-xxs   ${
          disabled ? '!bg-grey-light border-grey-light cursor-not-allowed' : ''
        }`}
          loading={loading}
          showSearch={true}
          placeholder={props && props?.placeholder}
          style={props?.style}
          defaultActiveFirstOption={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          onSelect={handleSelect}
          notFoundContent={loading ? <span>Loading...</span> : <span>No data found</span>}
          options={data}
          value={
            form?.values?.[field?.name]?.name ||
            form?.values?.[field?.name]?.[searchParams] ||
            form?.initialValues?.[field?.name] ||
            form?.initialValues?.[field?.name]?.name ||
            form?.initialValues?.[field?.name]?.[searchParams] ||
            defaultValue?.name ||
            defaultValue?.corp_name
          }
          defaultValue={
            form?.values[field?.name]?.[searchParams] ||
            form?.values[field?.name]?.name ||
            form?.initialValues[field?.name]?.[searchParams] ||
            defaultValue?.name ||
            form?.initialValues[field?.name]?.name ||
            defaultValue?.corp_name
          }
          suffixIcon={false}
          disabled={disabled}
          dropdownAlign={{ offset: [-10, 12] }}
          getPopupContainer={(trigger) => trigger.parentNode}
          allowClear={true}
          clearIcon={<Close className='w-4 h-4 z-10' />}
          onClear={onIconClick}
        />

        <div className={`absolute right-0 flex items-center cursor-pointer`}>
          {extraRightRender ? extraRightRender() : null}
        </div>

        {form?.errors[field?.name] && form?.touched[field?.name] && (
          <div
            className={`absolute bg-white ${
              extraRightRender ? 'max-w-[230px]' : ''
            } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
          >
            {form?.errors[field?.name]}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchAPI;
