import React, { useCallback } from 'react';
import { Tabs } from 'antd';
import { Searchable } from 'utils/common';
import './style.css';

const UserList = ({ type, selectContact, placeholder, searchParams = '' }) => {
  return (
    <div id='dialer' className='h-48'>
      <Searchable
        type='search'
        placeholder={placeholder}
        field={{ name: type }}
        divClasses='w-64'
        onChange={selectContact}
        searchParams={searchParams}
      />
    </div>
  );
};

const SearchContact = ({ setNumber }) => {
  const selectContact = useCallback((data) => {
    setNumber(data?.contact_number);
  }, []);

  return (
    <div id='dialer' className='w-72 h-full overflow-auto border-r border-grey-40 mr-2'>
      <Tabs
        items={[
          {
            label: 'Client',
            key: '1',
            children: (
              <UserList
                type='customer'
                selectContact={selectContact}
                placeholder='Enter Client Name'
                searchParams='corp_name'
              />
            ),
          },
          {
            label: 'Agent',
            key: '2',
            children: (
              <UserList type='pos' selectContact={selectContact} placeholder='Enter Agent Name' />
            ),
          },
          {
            label: 'Employee',
            key: '3',
            children: (
              <UserList
                type='employee'
                selectContact={selectContact}
                placeholder='Enter Employee Name'
              />
            ),
          },
        ]}
        tabPosition='bottom'
        className='w-full'
      />
    </div>
  );
};

export default SearchContact;
