import React from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import "./style.css";
import * as XLSX from 'xlsx';
import { Button, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { Spin } from 'antd';

const ExcelMapper = ({ API }) => {
  const { state } = useLocation();

  const [header, setheader] = useState([]);
  const [index, setIndex] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [config, setconfig] = useState({});
  const [excelData, setExcelData] = useState([]);
  const [isInvalid, setISInvalid] = useState(false);
  const navigate = useNavigate();
  // var UpdatedheaderKeys = Object?.keys(excelData[0]);
  const onChange = (source) => (dest) => {
    setconfig((c) => ({ ...c, [dest.target.value]: source }));
  };

  const handleImport = async () => {
    const resp = await API({ data: excelData, method: 'post' });

    if (resp?.data?.status) {
      if (resp?.data?.invalid.length > 0) {
        setISInvalid(true);
        setExcelData(resp?.data?.invalid);
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      } else {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        setISInvalid(false);
        setExcelData([]);
        navigate('/client');
      }
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.msg);
    }
  };

  function exportChanges() {
    let newArrayOfObj = [];

    newArrayOfObj = excelData?.map((element) => {
      let obj = {};

      for (let key in config) {
        obj[key] = element[config[key]];
      }

      return obj;
    });
    setExcelData(newArrayOfObj);
    setDisabled(true);
    setheader(Object.keys(newArrayOfObj[0]));
    setIndex(Object.keys(newArrayOfObj[0]));
    Toast('success', NOTIFICATION_MSG.success, "Header's changed successfully");
  }

  const excelFileToJSON = (file) => {
    var reader;
    try {
      reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function (e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {
          type: 'binary',
        });
        var firstSheetName = workbook.SheetNames[0];
        //reading only first sheet data
        var jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheetName]);
        setExcelData(jsonData);
        var headers = Object.keys(jsonData[0]);
        setheader(headers);
        setIndex(headers);
      };
    } catch (e) {
      console.error(e);
    }
  };

  const onUpload = ({ file, fileList }) => {
    setLoading(true);
    if (document.getElementById('file_upload') === null) {
      return;
    }
    var files = document.getElementById('file_upload').files;
    var filename = files[0].name;
    var extension = filename.substring(filename.lastIndexOf('.')).toUpperCase();
    if (extension === '.XLS' || extension === '.XLSX') {
      //Here calling another method to read excel file into json
      excelFileToJSON(files[0]);
    } else {
      alert('Please select a valid excel file.');
    }
    setLoading(false);
  };

  const changeHeader = (element) => {
    const header = state?.find((item) => item.value === element);
    return header?.label;
  };

  return (
    <div className='relative bg-white rounded-2xl p-4'>
      <header className='flex flex-col gap-2 mb-2'>
        <>
          <h4>Choose file :</h4>
          <input
            className='w-60'
            onChange={onUpload}
            accept='.xls,.xlsx,.ods,.csv'
            id='file_upload'
            type='file'
          />
        </>
        {/* <Upload onChange={onUpload} accept=".xls,.xlsx,.ods,.csv">
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload> */}
        {loading && <Spin size='lg' />}
      </header>

      <section className='flex flex-col gap-8 '>
        <div className='px-2 flex flex-col justify-center'>
          {excelData?.length > 0 && (
            <h2 className='m-auto text-l font-semibold'>Select column's Heading </h2>
          )}
          {!excelData?.length > 0 && (
            <h2 className='m-auto text-l font-semibold'>Choose file first. </h2>
          )}

          <table className='table w-full' id='display_excel_data'>
            {header.map((x) => {
              return (
                <div className=' w-2/4 m-auto'>
                  <tr className='flex justify-around px-8 border-2 '>
                    <td className='w-1/2  pl-8' id='Real'>
                      {x}
                    </td>
                    <td className='w-1/2 '>
                      <select className='w-full' onChange={onChange(x)}>
                        {state?.map((item) => {
                          return <option label={item?.label} value={item?.value}></option>;
                        })}
                      </select>
                    </td>
                  </tr>
                </div>
              );
            })}
          </table>
          <div className='change-headers m-auto mt-4 '>
            <Button
              classes={`${!excelData.length ? '!bg-grey-light' : ''}`}
              disabled={!excelData.length ? true : disabled}
              text={'Change Headers'}
              onClick={exportChanges}
            />
          </div>
        </div>

        {isInvalid && (
          <h2 className='m-auto text-l font-semibold text-red-500'> ! Remaining Invalid Data.</h2>
        )}

        <div className='px-2 m-auto'>
          <table className='table bg-white'>
            <thead>
              <tr className=''>
                {index.map((element) => {
                  return (
                    <th className='px-4 w-20 border-2 h-16 bg-gray-300' scope='col'>
                      {changeHeader(element) || element}
                    </th>
                  );
                })}
              </tr>
            </thead>

            {excelData.map((element) => {
              let values = Object.values(element);

              return (
                <tbody>
                  <tr>
                    {values.map((value) => {
                      return <td className='px-4 w-20 border-2 border-black'>{value}</td>;
                    })}
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </section>
      <div className='absolute top-4 right-6 flex justify-center gap-4'>
        <Button
          classes={`${!excelData.length ? '!bg-grey-light' : ''}`}
          text='Import'
          variant='primary'
          disabled={excelData.length ? false : true}
          onClick={handleImport}
        />
        <Button text='Back' variant='secondary' onClick={() => navigate('/client')} />
      </div>
    </div>
  );
};

export default ExcelMapper;
