/** external deps */
import React, { useEffect, useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Empty } from 'antd';
/** internal deps */
import SingleTask from './single-task';
import { getQueryParams } from 'utils/common';
import { CustomModal, ModalView } from 'components';
import { debounce } from 'utils';

const DragNDrop = ({
  sections,
  onGetTasks,
  page,
  fetchMoreData,
  total_page,
  startDate,
  endDate,
}) => {
  const { id } = getQueryParams();
  const { tab } = getQueryParams();
  // const divRef = useRef();
  const [showPopModal, setPopModal] = useState(id || false);

  /** funcs */
  const togglePopup = () => setPopModal((pre) => !pre);

  const onClose = () => {
    window.history.replaceState(null, null, `?module=task-dashboard&tab=${tab}&id=`);
    togglePopup();
  };

  const onCardClick = (task) => {
    window.history.replaceState(
      null,
      null,
      `?module=task-dashboard&tab=${task.activity.toLowerCase()}&id=${task.id}`,
    );
    togglePopup();
  };

  const handleScroll = debounce((event, task) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollTop + clientHeight === scrollHeight) {
      // if (scrollTop + clientHeight === scrollHeight && page < total_page / 10) {
      fetchMoreData({ page: page + 1, status: task?.id, startDate: startDate, endDate: endDate });
    }
  }, 700);

  // useEffect(() => {
  //   const divElem = divRef.current;
  //   divElem.addEventListener('wheel', handleScroll);

  //   return () => {
  //     divElem.removeEventListener('wheel', handleScroll);
  //   };
  // }, []);

  return (
    <div className='grid grid-flow-col gap-4 px-2 py-5 overflow-x-auto auto-cols-[20rem]'>
      {Object.keys(sections).map((key, index) => {
        return (
          <Droppable droppableId={key} key={key} index={index}>
            {(provided) => (
              <div
                className={`relative bg-grey-light p-2 rounded-lg`}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <div
                  className={`after:absolute after:content[''] after:-top-1  after:h-0.5 after:rounded-lg after:w-[95%] after:my-1.5 after:block ${sections[key]?.topborder}`}
                >
                  <span className='text-sm font-medium'>{sections[key]?.title}</span>
                  {sections[key]?.tasks.length > 0 ? (
                    <div
                      // ref={divRef}
                      onScroll={(e) => handleScroll(e, sections[key])}
                      className='h-[43vh] overflow-y-auto'
                    >
                      {sections[key]?.tasks.map((task, index) => (
                        <div onClick={() => onCardClick(task)}>
                          <SingleTask key={index} index={index} taskData={task} section={key} />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className='grid items-center justify-center min-h-[80px] bg-white my-2 p-2 rounded-lg'>
                      <Empty description={false} imageStyle={{ height: '60px' }} />
                      <h2 className='font-medium text-grey-90 text-center'>No Data</h2>
                    </div>
                  )}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        );
      })}
      {/* View Task */}
      <CustomModal
        open={showPopModal}
        destroyOnClose={true}
        closable={true}
        onCancel={onClose}
        footer={''}
        width='80%'
        height='500px'
        overflow={'scroll'}
      >
        <ModalView onGetTasks={onGetTasks} startDate={startDate} endDate={endDate} />
      </CustomModal>
    </div>
  );
};

export default DragNDrop;
