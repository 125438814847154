import { Route, Routes } from 'react-router-dom';
import Admin from 'screens/admin';

import {
  Login,
  ForgotPassword,
  ResetPassword,
  PosLogin,
  BUyPolicy,
  PosSignup,
  ViewTermsCondition,
  HR,
  Pos,
  Playground,
} from './screens';
import { InterviewForm } from 'screens/careers';

const ScreensRoutes = () => {
  return (
    <Routes>
      <Route path='/*' element={<Admin />} />
      <Route path='/hr/*' element={<HR />} />
      <Route path='/pos/*' element={<Pos />} />
      <Route path='/login' element={<Login />} />
      <Route path='/pos-login' element={<PosLogin />} />
      <Route path='/pos-signup' element={<PosSignup />} />
      <Route path='/buy-policy' element={<BUyPolicy />} />
      <Route path='/terms-condition' element={<ViewTermsCondition />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='careers' element={<InterviewForm />} />
      {/* <Route path='/playground' element={<Playground />} /> */}
    </Routes>
  );
};

export default ScreensRoutes;
