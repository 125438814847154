import { createContext, useState, useContext, useLayoutEffect, useEffect } from 'react';
import {
  getBranches,
  getEmployeePermissions,
  getEmployees,
  getCustomers,
  getRoles,
  getUserProfile,
  inquiryManagement,
  getNotifications,
  getTasks,
  companySetting,
  getPosProfile,
  getFollowupCustomers,
  getMyTickets,
} from 'service/api-service';
import { Toast } from 'components';
import { checkUserSession } from 'utils';
import jwtDecode from 'jwt-decode';
import { NOTIFICATION_MSG } from 'constants';
import jwt_Decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const initialValue = {
  permissions: {
    loading: false,
    list: {},
  },
  user: {},
  company: {},
  pos: {},
  loading: false,
  branches: {
    loading: false,
    list: [],
    updateBranches: async () => {},
  },
  employees: {
    loading: false,
    list: [],
    updateEmployees: async () => {},
    total: 0,
    pageNum: 1,
  },
  leads: {
    loading: false,
    list: [],
    updateLeads: async () => {},
    total: 0,
    total_lead: {},
    pageNum: 1,
  },
  tasks: {
    loading: false,
    list: [],
    updateTasks: async () => {},
    total: 0,
    page: 1,
    startDate: '',
    endDate: '',
    status: '',
  },
  customers: {
    list: [],
    updateCustomers: async () => {},
    total: 0,
    pageNum: 1,
  },
  roles: {
    list: [],
    updateRoles: async () => {},
  },
  selectedData: {
    data: {},
    handleSelectedData: () => {},
  },
  setUser: () => {},
  setCompanySetting: () => {},
  setPos: () => {},
  setToken: () => {},
  notifications: {
    list: [],
    updateNotifications: () => {},
    loading: false,
  },
  tickets: {
    loading: false,
    list: [],
    updateTickets: async () => {},
  },
};

// Create reducer
export function reducer(state, action) {
  switch (action.type) {
    case 'DIALER_OPEN':
      return { ...state, dialerOpened: true, contact: action.contact };
    case 'DIALER_CLOSE':
      return { ...state, dialerOpened: false };
    default:
      console.error(`StoreError - Unknow type(${action.type})`);
      return state;
  }
}
export const StoreCtx = createContext({});

export function useStore(stateFn) {
  const context = useContext(StoreCtx);
  let state = context.state;
  let dispatch = context.dispatch;

  // Redux thunk
  async function newDispatch(fn) {
    if (typeof fn === 'function') {
      let action = fn();

      if (action instanceof Promise) {
        action = await action;
      }

      dispatch(action);
    } else {
      dispatch(fn);
    }
  }

  if (typeof stateFn === 'function') {
    state = stateFn(state);
  }
  return [state, newDispatch];
}

export const Context = createContext(initialValue);

const ContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(initialValue);
  const [accessToken, setAccessToken] = useState();
  const token = JSON.parse(localStorage.getItem('AUTH'));

  const decode = token?.access_token ? jwt_Decode(token?.access_token) : null;

  const formatPermissions = (permissions) => {
    let finalPermissions = {};
    permissions.forEach((element) => {
      let childObject = {};
      element?.children.forEach((i) => {
        if (i?.children?.length > 0) {
          let otherChild = {};
          i?.children?.forEach((child) => {
            otherChild[child?.name] = child;
          });
          childObject[i?.name] = { ...i, children: otherChild };
        } else {
          childObject[i?.name] = i;
        }
      });
      finalPermissions[element?.name] = { ...element, children: childObject };
    });
    return finalPermissions;
  };

  const updateBranches = async (props = {}) => {
    setValue((prev) => ({ ...prev, branches: { ...prev.branches, loading: true } }));
    const resp = await getBranches({ query: props.query });
    if (resp?.data?.status) {
      const list = resp.data.data;
      setValue((prev) => ({ ...prev, branches: { ...prev.branches, list } }));
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Failed to get branch data from server',
      );
    }
    setValue((prev) => ({ ...prev, branches: { ...prev.branches, loading: false } }));
  };

  const updateTickets = async (props = {}) => {
    setValue((prev) => ({ ...prev, tickets: { ...prev.tickets, loading: true } }));
    const resp = await getMyTickets({ id: props.id, query: props.query });
    if (resp?.data?.status) {
      const list = resp.data.data;
      setValue((prev) => ({ ...prev, tickets: { ...prev.tickets, list } }));
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Failed to get tickets data from server',
      );
    }
    setValue((prev) => ({ ...prev, tickets: { ...prev.tickets, loading: false } }));
  };

  const updateLeads = async ({
    pageNum = 1,
    query = '',
    customer_id = '',
    employee_id = '',
    category_id = '',
    product_type = '',
    order = '',
    sortedItem = '',
    status = 'draft',
    type = 'employee',
  }) => {
    setValue((prev) => ({
      ...prev,
      leads: { ...prev.leads, loading: true, pageNum: pageNum, total_lead: {} },
    }));
    const resp = await inquiryManagement({
      query: `&page=${pageNum}&q=${query}&customer_id=${customer_id || ''}&employee_id=${
        employee_id || ''
      }&category_id=${category_id || ''}&product_type=${product_type || ''}&sorted_by=${
        order || ''
      }${sortedItem || ''}&status=${status}&type=${type}`,
    });
    if (resp?.data?.status) {
      const list = resp.data.data.result;
      const total = resp.data.data.total_page;
      const total_lead = resp.data.data.total_leads;
      setValue((prev) => ({ ...prev, leads: { ...prev.leads, list, total, total_lead } }));
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to get leads data from server');
    }
    setValue((prev) => ({ ...prev, leads: { ...prev.leads, loading: false } }));
  };

  const updateEmployees = async (
    pageNum = 1,
    query = '',
    e_status = '',
    designation = '',
    role = '',
    department = '',
  ) => {
    setValue((prev) => ({
      ...prev,
      employees: { ...prev.employees, loading: true, pageNum: pageNum },
    }));

    const des = designation?.length ? '[' + designation + ']' : '';
    const roles = role?.length ? '[' + role + ']' : '';
    const dep = department?.length ? '[' + department + ']' : '';
    const resp = await getEmployees({
      query: `&page=${pageNum}&q=${query}&status=${e_status}&designation=${des}&roles=${roles}&department=${dep}`,
    });
    if (resp?.data?.status) {
      const list = resp.data.data.result;
      const total = resp.data.data.total_page;
      setValue((prev) => ({ ...prev, employees: { list, total } }));
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Failed to get employee data from server',
      );
    }
    setValue((prev) => ({ ...prev, employees: { ...prev.employees, loading: false } }));
  };

  const updateTasks = async (query = '', page = 1, startDate = '', endDate = '', status = '') => {
    setValue((prev) => ({
      ...prev,
      tasks: {
        ...prev.tasks,
        loading: true,
        page: page,
        startDate: startDate,
        endDate: endDate,
        status: status,
      },
    }));
    const resp = await getTasks({
      query,
      page,
      startDate,
      endDate,
      status,
    });
    if (resp?.data?.status) {
      const list = resp.data.data.result;
      const total = resp.data.data.total_page;
      setValue((prev) => ({ ...prev, tasks: { ...prev.tasks, list, total } }));
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to get tasks from server');
    }
    setValue((prev) => ({ ...prev, tasks: { ...prev.tasks, loading: false } }));
  };

  const updateCustomers = async ({
    pageNum = 1,
    query = '',
    type = 'retail',
    followup = false,
    method = 'get',
    id = '',
  } = {}) => {
    const resp = followup
      ? await getFollowupCustomers({ query: `&q=${query}&page=${pageNum}`, method, id })
      : await getCustomers({
          query: `&q=${query}&page=${pageNum}&type=${type}`,
        });
    if (resp?.data?.status) {
      const list = resp.data?.data?.result;
      const total = resp.data?.data?.total_page;
      setValue((prev) => ({ ...prev, customers: { ...prev.customers, list, total } }));
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Unable to fetch customers from server',
      );
    }
  };

  const updateRoles = async () => {
    const resp = await getRoles({ query: '' });
    if (resp?.data?.status) {
      const list = resp.data.data;
      setValue((prev) => ({ ...prev, roles: { ...prev.roles, list } }));
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to get role data from server');
    }
  };

  const updatePermissions = async () => {
    const token = checkUserSession();
    if (token) {
      setValue((prev) => ({ ...prev, permissions: { ...prev.permissions, loading: true } }));
      var decoded = jwtDecode(token);
      const id = decoded?.id;
      const posAgent = decode?.pos_agent;

      const permissions = await (posAgent === false && getEmployeePermissions({ id }));
      if (permissions && permissions.data && permissions.data.status) {
        // converting permissions to object
        const list = formatPermissions(permissions?.data?.data);
        setValue((prev) => ({
          ...prev,
          permissions: { ...prev.permissions, list: list },
        }));
      }
    }
    setValue((prev) => ({ ...prev, permissions: { ...prev.permissions, loading: false } }));
  };

  const setUser = async () => {
    const token = checkUserSession();

    if (token && !decode?.pos_agent) {
      const resp = await getUserProfile();
      if (resp?.data?.status) {
        setValue((prev) => ({ ...prev, user: resp?.data?.data }));
        const orgId = resp?.data?.data?.org_id?.id;
        const response = await companySetting({ id: orgId });
        const companyInfo = response?.data?.data;
        if (response?.data?.status) {
          setValue((prev) => ({ ...prev, company: companyInfo }));
        }
      } else {
        localStorage.removeItem('AUTH');
        navigate('/login');
      }
    } else if (token || accessToken) {
      const resp = await getPosProfile();
      if (resp?.data?.status) {
        setValue((prev) => ({ ...prev, user: resp?.data?.data }));
      } else {
        localStorage.removeItem('AUTH');
        navigate('/pos-login');
      }
    }
  };

  const setToken = async (data) => {
    setAccessToken(data?.access_token);
  };

  const handleSelectedData = (data) => {
    setValue((prev) => ({ ...prev, selectedData: { ...prev.roles, data } }));
  };

  const updateNotifications = async () => {
    try {
      setValue((prev) => ({ ...prev, notifications: { ...prev.notifications, loading: true } }));
      const resp = await getNotifications();
      if (resp?.data && resp?.data?.status) {
        setValue((prev) => ({
          ...prev,
          notifications: { ...prev.notifications, list: resp?.data?.data },
        }));
      }
    } catch (error) {}

    setValue((prev) => ({ ...prev, notifications: { ...prev.notifications, loading: false } }));
  };

  useLayoutEffect(() => {
    setUser();
    updatePermissions();
    updateNotifications();
  }, []);

  useEffect(() => {
    if (accessToken) setUser();
  }, [accessToken]);

  const finalValue = {
    ...value,
    branches: {
      ...value.branches,
      updateBranches,
    },
    employees: {
      ...value.employees,
      updateEmployees,
    },
    leads: {
      ...value.leads,
      updateLeads,
    },
    tasks: {
      ...value.tasks,
      updateTasks,
    },
    customers: {
      ...value.customers,
      updateCustomers,
    },
    roles: {
      ...value.roles,
      updateRoles,
    },
    selectedData: {
      ...value.selectedData,
      handleSelectedData,
    },
    setUser: setUser,
    setToken: setToken,
    notifications: {
      ...value.notifications,
      updateNotifications,
    },
    tickets: {
      ...value.tickets,
      updateTickets,
    },
  };

  return <Context.Provider value={finalValue}>{children}</Context.Provider>;
};

export default ContextProvider;
