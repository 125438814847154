import React from 'react';

const LineHeading = ({ title }) => {
  return (
    <div className='flex flex-row gap-1.5 font-medium text-s'>
      <div className='h-auto w-1.5 bg-primary-90 rounded-md'></div> {title}
    </div>
  );
};

export default LineHeading;
