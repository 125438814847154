import React, { useCallback, useEffect, useState } from 'react';
import { ShareAltOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import dayjs from 'dayjs';

import {
  Activity,
  CommentList,
  Comments,
  CustomTextInput,
  DateTime,
  Description,
  EmployeeList,
  Loader,
  TabsComponent,
  Toast,
  UserInitialIcon,
} from 'components';
import {
  commentManagement,
  editMeeting,
  editTask,
  getSingleMeeting,
  getSingleTask,
} from 'service/api-service';
import { formatDate } from 'utils';
import { NOTIFICATION_MSG } from 'constants';
import { getQueryParams } from 'utils/common';
import './style.css';

const CommentsTab = ({ comments, id, getList }) => {
  return (
    <>
      <CommentList comments={comments} />
      <Comments task_id={id} getList={getList} />
    </>
  );
};

const Subject = ({ task, onGetTasks, startDate, endDate }) => {
  const [open, setOpen] = useState(false);
  const [desc, setDesc] = useState(task?.subject);
  const togglePopup = () => {
    setOpen((pre) => !pre);
  };

  const onEdit = useCallback(({ field, value }) => {
    setDesc(value);
  }, []);

  const onSave = useCallback(async () => {
    const payload = {
      subject: desc,
    };
    const resp =
      task?.activity === 'Task'
        ? await editTask({ id: task?.id, data: payload })
        : await editMeeting({ id: task?.id, data: payload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      togglePopup();
      onGetTasks({ startDate: startDate, endDate: endDate });
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  }, [desc, task, startDate, endDate]);
  return (
    <>
      <button onClick={togglePopup} className='text-l 2xl:text-xxl font-medium capitalize pb-4'>
        {task?.subject ||
          formatDate({
            dateString: task?.to_date,
            toUtc: false,
            format: 'DD MMMM, YYYY, hh:mm A',
          })}
      </button>
      <Modal onOk={onSave} okText={'Update'} onCancel={togglePopup} open={open}>
        <CustomTextInput defaultValue={task?.subject} label={'Subject'} customOnChange={onEdit} />
      </Modal>
    </>
  );
};

const ModalView = (props = {}) => {
  const { id } = getQueryParams();
  const { tab } = getQueryParams();
  const startDate = props?.startDate || '';
  const endDate = props?.endDate || '';

  const [taskComments, setComments] = useState([]);
  const [key, setActiveTabKey] = useState('comments');
  const [loading, setLoading] = useState(false);
  const [taskID] = useState(id);
  const [taskTab] = useState(tab);
  const [task, setTask] = useState();

  const handleCopyClick = () => {
    // Create a temporary textarea to copy the text
    const textarea = document.createElement('textarea');
    textarea.value = window.location;
    document.body.appendChild(textarea);

    // Copy the text from the textarea
    textarea.select();
    document.execCommand('copy');

    // Clean up and remove the temporary textarea
    document.body.removeChild(textarea);
    // Optionally, you can show a notification or feedback to the user
    Toast('success', NOTIFICATION_MSG.success, 'Text copied to clipboard!');
  };

  const editDueDate = useCallback(
    async ({ val }) => {
      const payload = {
        to_date: val,
      };
      const resp =
        tab === 'task'
          ? await editTask({ id: taskID, data: payload })
          : await editMeeting({ id: taskID, data: payload });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        props?.onGetTasks({ startDate: startDate, endDate: endDate });
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
    },
    [taskID, startDate, endDate],
  );
  /* FETCH DATA */
  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp =
      taskTab === 'task'
        ? await getSingleTask({ id: taskID })
        : await getSingleMeeting({ id: taskID });
    if (resp?.data?.status) {
      setTask(resp?.data?.data);
    }
    setLoading(false);
  }, [taskID]);

  /* get comments */
  const onGetCommentList = async () => {
    const resp = await commentManagement({ method: 'get', task_id: taskID });
    if (resp?.data?.status) {
      setComments(resp?.data?.data);
    }
  };

  const tabs = [
    {
      key: 'comments',
      label: `Comments`,
      Component: CommentsTab,
      props: {
        comments: taskComments,
        id: taskID,
        getList: onGetCommentList,
      },
    },
    {
      key: 'activity',
      label: `Activity`,
      Component: Activity,
      props: {
        id: taskID,
      },
    },
  ];

  useEffect(() => {
    window.history.replaceState(
      null,
      null,
      `?module=task-dashboard&tab=${taskTab.toLowerCase()}&id=${taskID}`,
    );
    onGetCommentList();
    fetchData();
  }, [taskTab, taskID]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Subject task={task} onGetTasks={props?.onGetTasks} startDate={startDate} endDate={endDate} />
      <section id='task-view-modal' className='grid grid-cols-2 md:grid-cols-3 gap-6'>
        <div className='col-span-2 flex flex-col gap-3'>
          <Description
            task={task}
            content={task?.description}
            onGetTasks={props?.onGetTasks}
            startDate={startDate}
            endDate={endDate}
          />
          <TabsComponent items={tabs} activeKey={key} setActiveTabKey={setActiveTabKey} />
        </div>
        <div className='relative flex flex-col gap-3'>
          <div className='flex justify-between items-center'>
            <label
              className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xs text-black`}
            >
              Actions
            </label>
          </div>
          <Button className='w-full flex items-center bg-grey-light' onClick={handleCopyClick}>
            <ShareAltOutlined /> Share
          </Button>
          <DateTime
            label='Due Date'
            showTime={true}
            defaultValue={task?.to_date ? dayjs(task?.to_date).utc() : undefined}
            placeholder='Select Due Date'
            customOnChange={editDueDate}
          />
          <EmployeeList data={task?.assign_to_employee} label={'Employees'} />
          <div>
            <label
              className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xs text-black`}
            >
              Created by
            </label>
            <Tooltip title={task?.created_by?.name}>
              <div className='w-max h-max'>
                <UserInitialIcon
                  name={task?.created_by?.name}
                  imageUrl={process.env.REACT_APP_DOC_URL + task?.created_by?.image}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </section>
    </>
  );
};

export default ModalView;
