import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import './style.css';

const TextInput = ({
  field,
  ref,
  form,
  rightIcon,
  leftIcon,
  onIconClick = () => null,
  extraRightRender,
  InputClasses,
  divClasses,
  dependent,
  disabled,
  maxLength,
  isUpperCase,
  isMultiline = false,
  customIcon,
  title,
  checkstatus,
  ...props
}) => {
  const [fieldValue, setValue] = useState();
  /* funcs */
  const _onChange = (val) => {
    let value = val?.target.value;
    if (isUpperCase) {
      value = value.toUpperCase();
    }
    setValue(value);
    if (props?.customOnChange) {
      props?.customOnChange({ field, value, form });
    }

    if (field?.onChange) return field?.onChange(val);
  };

  const handleClick = () => {
    if (fieldValue || field?.value) return onIconClick(fieldValue || field?.value);
  };

  const handleKeyPress = (e) => {
    if (props?.inputChange) {
      const value = e.target.value + e.key;
      props?.inputChange({ field, value, form });
    }
    if (e.keyCode === 13) {
      if (fieldValue || field?.value) return onIconClick(fieldValue);
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className={`group ${divClasses}`}>
      <div className='flex justify-between'>
        <label
          className={`group-focus-within:text-primary-90 block mb-1 text-xxl md:text-xxs xl:text-xxs text-black ${
            props?.required ? 'required' : ''
          }`}
        >
          {props.label}
        </label>
        {customIcon && (
          <Tooltip title={title} position='top'>
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </div>
      <div className='relative'>
        <div
          onClick={handleClick}
          className={`absolute inset-y-0 bg-transparent border-y border-[#D1EAF9] group-focus-within:border-primary-90  ${
            rightIcon && 'right-0 border-r px-3 rounded-r-md '
          } ${
            leftIcon && ' pl-3 !border-transparent rounded-l-full'
          } flex items-center cursor-pointer `}
        >
          {rightIcon
            ? typeof rightIcon === 'function'
              ? rightIcon(fieldValue)
              : rightIcon
            : leftIcon
            ? typeof leftIcon === 'function'
              ? leftIcon(fieldValue)
              : leftIcon
            : ''}
        </div>
        {!isMultiline ? (
          <input
            onChange={_onChange}
            {...props}
            ref={ref}
            maxLength={maxLength}
            disabled={disabled}
            name={field?.name || props?.name}
            {...field}
            onKeyDown={props?.handleKeyPress ? props?.handleKeyPress : handleKeyPress}
            className={`${
              leftIcon ? '!pl-8 2xl:!pl-12 ' : ''
            } bg-[#F5FAFD] border border-[#D1EAF9] text-xl md:text-xxs xl:text-xxs rounded-md  block w-full p-[5.5px] xl:p-[5.5px] focus:outline-none focus:border-primary-90
            placeholder-grey-90 customCss placeholder:text-grey-90 placeholder:text-xl md:placeholder:text-xxs xl:placeholder:text-xxs disabled:bg-grey-light disabled:border-grey-light disabled:cursor-not-allowed
          disabled:text-grey-90 placeholder:capitalize
           ${InputClasses} ${isUpperCase ? 'uppercase' : ''}`}
          />
        ) : (
          <textarea
            {...props}
            ref={ref}
            maxLength={maxLength}
            cols={props?.cols || 1}
            rows={props?.rows || 1}
            className={`${
              props.leftIcon ? 'pl-10' : ''
            } bg-[#F5FAFD] border border-[#D1EAF9] text-xl md:text-xxs xl:text-xxs rounded-md focus:ring-primary-90 focus:border-primary-90 block w-full p-[9px] xl:p-[5.5px] focus:outline-none 
            placeholder-grey-90 customCss placeholder:text-grey-90 placeholder:text-xl md:placeholder:text-xxs xl:placeholder:text-xxs  disabled:bg-grey-light disabled:border-grey-light disabled:cursor-not-allowed
            ${InputClasses}`}
            {...field}
            onChange={_onChange}
            onKeyDown={handleKeyPress}
            disabled={disabled}
          />
        )}
        <div className={`absolute right-0 flex items-center cursor-pointer inset-y-0`}>
          {extraRightRender ? extraRightRender() : null}
        </div>

        {checkstatus && (
          <div
            className={`absolute bg-white ${
              extraRightRender ? 'max-w-[230px]' : ''
            } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
          >
            {checkstatus}
          </div>
        )}

        {form?.errors[field?.name] && form?.touched[field?.name] && (
          <div
            className={`absolute bg-white ${
              extraRightRender ? 'max-w-[230px]' : ''
            } last:left-0 text-red-90 flex items-center cursor-pointer text-m md:text-x mt-1 `}
          >
            {form?.errors[field?.name]}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextInput;
