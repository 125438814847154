import { SelectInput, TextInput } from 'components';

export const addtaxFields = {
  'Branch Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Tax Name',
        name: 'name',
        id: 'name',
        placeholder: 'Tax Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Min Salary',
        name: 'min_salary',
        id: 'min_salary',
        placeholder: 'Min Salary',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Max Salary',
        name: 'max_salary',
        id: 'max_salary',
        placeholder: 'Max Salary',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Type',
        name: 'type',
        id: 'type',
        placeholder: 'Type',
        type: 'text',
        required: true,
        component: SelectInput,
        options: [
          { value: 'f', label: 'Fixed' },
          { value: 'p', label: 'Percentage' },
        ],
      },
      {
        label: 'Amount',
        name: 'amount',
        id: 'amount',
        placeholder: 'Amount',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
    ],
  },
};
