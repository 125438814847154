import { TextInput } from 'components';

export const breadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'FAQ',
    path: '',
    target: '_blank',
  },
];

export const helplineFields = {
  transactions: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Question',
        name: 'questions',
        id: 'questions',
        placeholder: 'Question',
        type: 'text',
        maxLength: 100,
        required: true,
        component: TextInput,
        divClasses: 'col-span-2',
      },
      {
        label: 'Answer',
        name: 'answers',
        id: 'answers',
        placeholder: 'Answer',
        type: 'textarea',
        isMultiline: true,
        rows: 3,
        maxLength: 1000,
        required: true,
        component: TextInput,
        divClasses: 'col-span-2',
      },
    ],
  },
};
