/** external deps */
import React, { useEffect, useState } from 'react';
import { Spin, Button as AntButton } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
/** internal deps */
import { CustomModal, FormContainer, Toast, Button } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { formatLeadsViewData } from 'screens/customers/inquiry/data';
import { prepareFormFieldsData } from 'utils';
import { addStatusFields } from '../inquiry/data';
import ViewData from '../inquiry/ViewData';
import { getPosOnlineLeads, getEmployees, inquiryManagement } from 'service/api-service';

const ViewInquiry = () => {
  const [initVals, fieldForms] = prepareFormFieldsData({
    fields: addStatusFields,
  });
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [options, setOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [employee, setEmployee] = useState({});
  const [accordian, setAccordian] = useState(false);
  const [loading, setLoading] = useState(false);
  // const statusChange = async (status) => {
  //   const resp = await inquiryManagement({
  //     method: 'patch',
  //     id: id,
  //     data: { status: status },
  //   });
  //   if (resp?.data?.status) {
  //     Toast('success', NOTIFICATION_MSG.success, `Status updated successfully`);
  //   } else {
  //     Toast('error', NOTIFICATION_MSG.error, 'Please try again');
  //   }
  // };

  const handleAddRemark = async (data) => {
    const payload = {
      status: 'draft',
      remarks: data?.remarks,
    };
    const resp = await inquiryManagement({
      method: 'patch',
      id: id,
      data: payload,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, `Status updated successfully`);
      setShowModal(false);
      window.location.reload();
    } else {
      Toast('error', NOTIFICATION_MSG.error, 'Please try again');
    }
  };

  const getInquiry = async () => {
    setLoading(true);
    const resp = await getPosOnlineLeads({ id });
    const data = resp?.data.data;
    setData(data);
    setEmployee({
      id: data?.assign_to_employee?.id,
      name: data?.assign_to_employee?.name,
      label: data?.assign_to_employee?.name,
      value: data?.assign_to_employee?.id,
    });
    setLoading(false);
  };
  const fetchEmployeeOptions = async () => {
    const res = await getEmployees();
    if (res) {
      setOptions(res?.data?.data?.result || []);
    }
  };
  useEffect(() => {
    getInquiry();
    fetchEmployeeOptions();
  }, []);

  if (loading)
    return (
      <div className='flex items-center justify-center h-64'>
        <Spin />
      </div>
    );

  return (
    <div className='bg-white rounded-lg'>
      <ViewData
        classes={`relative bg-white rounded-2xl p-4 space-y-4 `}
        pageTitle={`Lead Detail - ${data?.id}`}
        data={formatLeadsViewData(data)}
        showGoToBack={true}
        goBack={() => navigate('/')}
        accordian={!accordian}
        customButton={
          <div className='flex gap-6'>
            <Link to={`/lead/edit/${id}/2`}>
              <AntButton type='primary' className='mr-4'>
                {data?.status === 'quoted' ? 'Edit Quote' : 'Create Quote'}
              </AntButton>
            </Link>
          </div>
        }
        bottomSection={
          data?.remarks && !accordian ? (
            <>
              <div className='cursor-pointer flex items-center justify-between p-4 '>
                <h4 className='font-semibold text-primary-90 text-sm'>Remarks</h4>
              </div>
              <div className='px-4 mb-8'>{data?.remarks}</div>
            </>
          ) : null
        }
      />

      <CustomModal
        open={showModal}
        destroyOnClose={true}
        closable={true}
        onCancel={() => setShowModal(false)}
        footer={''}
        width='600px'
        title='Add Remarks'
      >
        <FormContainer
          onSubmit={handleAddRemark}
          initialValues={initVals}
          fields={fieldForms}
          accordionClass='grid grid-cols-full  gap-8'
          customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => setShowModal(false)}
                />
                <Button
                  text={'Save'}
                  variant='primary'
                  classes={`ml-2 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                  loading={isSubmitting}
                />
              </div>
            );
          }}
        />
      </CustomModal>
    </div>
  );
};

export default ViewInquiry;
