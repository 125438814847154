import React, { useState } from 'react';
import { Anchor, Drawer, Switch } from 'antd';

import './style.css';
import Loader from 'components/loader';

const DrawerTabsForm = ({
  title,
  placement = 'right',
  onClose,
  open,
  width,
  permissions,
  id,
  children,
  type,
  drawerLoading = false,
}) => {
  const [toggle, setToggle] = useState(false);
  const formFields = React.useRef([]);

  const getFields = (fields) => {
    formFields.current = fields;
  };
  let newElement;
  if (children) {
    newElement = React.cloneElement(children, {
      getFieldsForParent: getFields,
      toggle: toggle,
    });
  }

  const hanleToggle = (value) => {
    setToggle(value);
  };

  const getItems = (fields) => {
    // eslint-disable-next-line array-callback-return
    const items = Object.keys(fields).map((d) => {
      if (fields[d]?.subTitle) {
        return {
          title: <h2 className='text-s'>{d}</h2>,
          href: '#' + formFields.current[d]?.id,
          key: fields[d]?.id,
        };
      } else {
        return {
          title: '',
          href: '#',
          key: '',
        };
      }
    });
    return items?.filter((d) => d.key);
  };

  return (
    <div id='drawerform'>
      <Drawer
        className='[&_.ant-drawer-wrapper-body]:bg-[#FBFBFB]'
        id='drawerform'
        headerStyle={{
          border: 'none',
          marginTop: '20px',
          marginLeft: '24px',
          marginRight: '24px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          background: 'white',
        }}
        title={
          <div className='flex items-center justify-between  '>
            <h2 className='text-sm font-semibold'>{title}</h2>
            <div className='flex items-center gap-2.5'>
              <Switch onChange={hanleToggle} />
              <p className='text-xxs font-normal'>Show Required & Important Fields</p>
            </div>
          </div>
        }
        placement={'right'}
        onClose={onClose}
        visible={open}
        width={width}
      >
        <div className='h-[25px] bg-grey-light '></div>
        <div className='flex w-full'>
          <div className='fixed w-[234px] h-screen bg-grey-light pl-[8px]'>
            {drawerLoading ? <Loader /> : <Anchor items={getItems(formFields.current)} />}
          </div>
          <div className='overflow-scroll w-full ml-[240px] h-[80vh] mr-6'>{newElement}</div>
        </div>
      </Drawer>
    </div>
  );
};

export default DrawerTabsForm;
