/** external deps */
import React, { useState } from 'react';
import { InputNumber, Select } from 'antd';
/** internal deps */
import { DropdownIcon } from 'assets/icons';
import './style.css';
const { Option } = Select;

const ExperienceInput = ({
  field,
  form,
  rightIcon,
  leftIcon,
  onIconClick,
  extraRightRender,
  InputClasses,
  divClasses,
  disabled,
  maxLength,
  ...props
}) => {
  const [number, symbol] = form.initialValues?.[field?.name]?.split(' ');

  const [type, setType] = useState(symbol ?? 'year');
  const [value, setValue] = useState(Number(field?.value) || Number(number));

  const _onChange = (i) => {
    setValue(i);
    if (form?.setFieldValue) return form.setFieldValue(field?.name, `${i} ${type}`);
  };

  const addonBefore = (
    <Select
      value={type}
      onChange={(i) => {
        setType(i);
        if (form?.setFieldValue) return form.setFieldValue(field?.name, i + value);
      }}
      placeholder='Select'
      style={{ width: 80 }}
      className='bg-[#F5FAFD] '
      dropdownAlign={{ offset: [0, 12] }}
      suffixIcon={<DropdownIcon svgClass={'w-2 group-focus-within:fill-primary-90'} />}
    >
      <Option value='year'>Year</Option>
      <Option value='month'>Month</Option>
    </Select>
  );

  return (
    <div id='exp-input' className={`group ${divClasses}`}>
      <label
        className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xxs text-black ${
          props?.required ? 'required' : ''
        }`}
      >
        {props.label}
      </label>
      <div className='relative'>
        <div
          onClick={onIconClick}
          className={`absolute inset-y-0  border-y border-[#F5FAFD] group-focus-within:border-primary-90 ${
            rightIcon && 'right-0 border-r px-3 rounded-r-md'
          } ${
            leftIcon && 'border-l border-[#F5FAFD] px-3 rounded-l-full'
          } flex items-center cursor-pointer `}
        >
          {rightIcon || leftIcon}
        </div>

        <InputNumber
          type={'number'}
          value={value}
          name={field?.name || props?.name}
          maxLength={maxLength}
          disabled={disabled}
          addonBefore={addonBefore}
          className='w-full border-[#F5FAFD] text-xxs py-0'
          // className={`${
          //   leftIcon ? "!pl-10" : ""
          // } border border-grey-90 text-xxs xl:text-xs rounded-md  block w-full p-1.5 xl:p-2 focus:outline-none focus:!border-primary-90
          // placeholder-grey-90 placeholder:text-grey-90 placeholder:text-xxs xl:placeholder:text-xs disabled:bg-grey-light disabled:border-grey-light disabled:cursor-not-allowed
          // ${InputClasses}`}
          onChange={_onChange}
          placeholder={props?.placeholder}
        />

        <div className={`absolute right-0 flex items-center cursor-pointer`}>
          {extraRightRender ? extraRightRender() : null}
        </div>

        {form?.errors[field?.name] && form?.touched[field?.name] && (
          <div
            className={`absolute bg-white ${
              extraRightRender ? 'max-w-[230px]' : ''
            } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
          >
            {form?.errors[field?.name]}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExperienceInput;
