/** external deps */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/** internal deps */
import { SecureComponent } from 'permission-provider';
import { ContentHeader, TabsComponent, ViewData } from 'components';
import { SECTION_DATA_VIEW, breadcrumbs, formatOverViewEmployeeData } from './data';
import { generatePermittedTabs } from 'utils';
import { elementData } from 'elements';
import OverviewEmployee from './overview';
import Tasks from 'screens/customers/tasks';
import Targets from 'screens/targets';
import EmployeeWorkExperience from './EmployeeWorkExperience';
import { getEmployee } from 'service/api-service';
import { prepareViewData } from 'utils/common';
import ShiftHistory from './shifHistory';
import inquiry from 'screens/customers/inquiry';
import viewTargetDetails from 'screens/targets/view-target-details';

const ViewEmployee = ({ permissions = {} }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [key, setActiveTabKey] = useState('6');
  const [employeeData, setEmployeeData] = useState({});

  useEffect(() => {
    const fetchEmployeeDetail = async () => {
      const resp = await getEmployee({ id });
      if (resp?.data?.status) {
        setEmployeeData(resp?.data?.data);
      }
    };
    fetchEmployeeDetail();
  }, [id]);

  const tabs = [
    {
      key: '1',
      id: elementData.employee.permissions.view,
      label: `Overview`,
      Component: OverviewEmployee,
      props: {
        permissions,
        id: elementData.employee.permissions.view,
        data: formatOverViewEmployeeData(employeeData),
        rawData: employeeData,
        classes: 'bg-white rounded-2xl p-4 ',
        showGoToBack: true,
        setActiveTabKey: setActiveTabKey,
        employee_id: id,
        goBack: () => navigate(-1),
      },
    },
    {
      key: '2',
      id: elementData.employee.permissions.view,
      label: `Details`,
      Component: ViewData,
      props: {
        permissions,
        id: elementData.employee.permissions.view,
        data: prepareViewData({
          data: employeeData,
          format: SECTION_DATA_VIEW,
          keys: { dep_id: 'Department', ifsc_code: 'IFSC Code', des_id: 'designation' },
          allowToReturnObjects: true,
          phone: 'contact',
          email: 'email',
        }),
        classes: 'bg-white rounded-2xl p-4 mt-4',
        showGoToBack: true,
        goBack: () => navigate(-1),
      },
    },
    {
      key: '3',
      id: [elementData.task.name, elementData.meeting.name],
      label: `Tasks`,
      Component: Tasks,
      props: {
        employee_id: id,
        disableCustomer: false,
        employeeName: { id: id, name: employeeData?.name },
        showBreadcrumbs: false,
        permissions,
      },
    },
    {
      key: '4',
      id: elementData.Goals.name,
      label: `Goals`,
      Component: viewTargetDetails,
      props: {
        employeeData: { id: id, name: employeeData?.name, emp_code: employeeData?.emp_code },
        employee_id: id,
        showBreadcrumbs: false,
        permissions,
      },
    },
    {
      key: '5',
      id: elementData.Leads.name,
      label: `Leads`,
      Component: inquiry,
      props: {
        employee_id: id,
        permissions,
        id: elementData.Leads.name,
      },
    },
    // {
    //   key: '6',
    //   id: elementData.Leads.name,
    //   label: `Work History `,
    //   Component: EmployeeWorkExperience,
    //   props: {
    //     employee_id: id,
    //     permissions,
    //     SECTION_DATA_VIEW,
    //     // id: elementData.Leads.name,
    //   },
    // },
    // {
    //   key: '7',
    //   id: elementData.employee.permissions.view,
    //   label: `Shift History`,
    //   Component: ShiftHistory,
    //   props: {
    //     permissions,
    //     employee_id: id,
    //     id: elementData.employee.permissions.view,
    //     classes: 'bg-white rounded-2xl p-4',
    //   },
    // },

    // {
    //   key: "6",
    //   id: elementData.Quote.name,
    //   label: `Quotes`,
    //   Component: Quotes,
    //   props: {
    //     employee_id: state?.id,
    //     permissions,
    //   },SECTION_DATA_VIEW
    // },
    // {
    //   key: "7",
    //   id: elementData.policy.name,
    //   label: `Policy`,
    //   Component: Policy,
    //   props: {
    //     // employee_id: state?.id,
    //     permissions,
    //   },
    // },
  ];

  useEffect(() => {
    // const formattedData = formatViewData(state?.initialValues);
    // setData(formattedData);
    setActiveTabKey('2');
  }, []);
  return (
    <section className='grid gap-3'>
      <TabsComponent
        items={generatePermittedTabs(tabs, permissions)}
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
        renderTabBar={true}
      />
    </section>
  );
};

export default SecureComponent(ViewEmployee);
