/** external deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space } from 'antd';
import Fuse from 'fuse.js';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
  Folders,
  Toast,
} from 'components';
import { addFolderFields, addFolderInitials, breadcrumbObj, dummyData } from './data';
import { DELETE, EDIT, FilledFolder, Folder, Trash } from 'assets/icons';
import { NOTIFICATION_MSG } from 'constants';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { getFoldersManagement, postFoldersManagement } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const Reports = (props = {}) => {
  let navigate = useNavigate();
  const permissions = props?.permissions || [];
  // states
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showFolders, setShowFolders] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [selectedReport, setSelectedReport] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredReports, setFilteredReports] = useState([]);

  const [filterData, setFilterData] = useState();

  const columns = [
    {
      key: 'type',
      title: 'Report Type',
      dataIndex: 'type',
    },
    {
      key: 'name',
      title: 'Report Name',
      dataIndex: 'name',
    },
    {
      key: 'module',
      title: 'Primary Module',
      dataIndex: 'module',
    },
    {
      key: 'folder',
      title: 'Folder Name',
      dataIndex: 'folder',
    },
    {
      key: 'owner',
      title: 'Owner',
      dataIndex: 'owner',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.Report.permissions.delete}
            onClick={() => {
              setSelectedReport(record);
              toggleDeleteReport();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.Report.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  const onDeleteReport = async () => {};

  /* get folder list  */
  const getFolders = async () => {
    setLoading(true);
    const resp = await getFoldersManagement();
    if (resp?.data?.status) {
      const list = resp?.data?.data;
      setFilterData(list);
    }
    setLoading(false);
  };

  /* add folder */
  const onAddFolder = async (data) => {
    const resp = await postFoldersManagement({ data });
    if (resp?.data?.status) {
      toggleAddFolder();
      getFolders();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  const toggleAddFolder = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  const toggleFolderMenu = () => setShowFolders((pre) => !pre);
  const toggleDeleteReport = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(tableData, {
      keys: ['name', 'address_id'],
      threshold: 0.2,
    }).search(e.target.value);
    setFilteredReports(fuse.map((i) => i.item));
  };

  const onEditPress = (record) => {};

  useEffect(() => {
    getFolders();
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        data={breadcrumbObj}
        btnText='+ Report'
        buttonId={elementData.Report.permissions.create}
        onClick={() => {
          navigate('/add-report');
        }}
        appendChildren={false}
      >
        <IconButton onClick={toggleFolderMenu} className='flex justify-center items-center'>
          {showFolders ? (
            <FilledFolder className='h-8 w-8 hover:fill-grey' />
          ) : (
            <Folder className='h-8 w-8' />
          )}
        </IconButton>
      </ContentHeader>
      <div className={`grid ${showFolders ? 'grid-cols-12' : 'grid-cols-1'} gap-4`}>
        <div className='col-span-12 lg:col-span-8'>
          <TableComponent
            loading={loading}
            columns={showFolders ? columns.slice(0, 4) : columns}
            dataSource={searchQuery ? filteredReports : dummyData}
            getRowClassName={rowCommonClass}
          />
        </div>
        {showFolders && (
          <div className='col-span-12 lg:col-span-4'>
            <Folders handleAddClick={toggleAddFolder} data={filterData} />
          </div>
        )}
      </div>

      {/* add Folder modal */}
      <CustomModal
        destroyOnClose={true}
        width={440}
        open={showAddModal}
        onCancel={toggleAddFolder}
        footer={''}
        title={'Add Folder'}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10  '>
            {'To create a new folder, enter the details in the input field below..'}
          </span>
          <FormContainer
            accordionClass='grid grid-cols-1  gap-8'
            onSubmit={onAddFolder}
            initialValues={addFolderInitials}
            fields={addFolderFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddFolder();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    text={'Add Folder'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* delete report confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteReport}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the report details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteReport}
            />
            <Button
              text='Delete Report'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteReport}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(Reports);
