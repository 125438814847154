import React from 'react';
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
Chart.register(CategoryScale);

const PerformanceGraph = () => {
  const CircleDot = (props) => {
    const { text, backgroundColor } = props;
    return (
      <div>
        <div className='flex items-center space-x-1'>
          <div className={`${backgroundColor} w-2 h-2 rounded-lg border bg-red-500`}></div>
          <span className='text-xxs'>{text}</span>
        </div>
      </div>
    );
  };

  const data = {
    labels: ['Performance', 'Absent Rate', 'Overdue Tasks'],
    datasets: [
      {
        label: 'First Dataset',
        data: [65, 23, 34],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 2,
      },
      {
        label: 'Second Dataset',
        data: [28, 23, 45],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Third Dataset',
        data: [20, 45, 56, 67],
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className='h-full box-border'>
      <div className='flex justify-between m-3 h-[15%] '>
        <div>
          <p className='font-normal text-l'>Performance Graph</p>
        </div>
        <div className='flex space-x-2 text-grey-90 text-xxs items-center'>
          <CircleDot text='Performance' backgroundColor='bg-primary-90' />
          <CircleDot text='Absent Rate' backgroundColor='bg-red-90' />
          <CircleDot text='Overdue Tasks' backgroundColor='bg-yellow-90' />
        </div>
      </div>

      <Bar
        width={500}
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default PerformanceGraph;
