/** external deps */
import React, { useEffect, useState, useContext } from 'react';
/** internal deps */
import { getEmployeeStaticCount } from 'service/api-service';
import { DashbaordStatistics, DisplayCard } from 'components';
import { Context } from 'store/context';
import './style.css';
import { InsurerIcon, LeadsIcon } from 'assets/icons';
import { StatsIconClass } from 'utils/common';

const Statistics = (props = {}) => {
  const permissions = props?.permissions || [];
  const { user } = useContext(Context);

  /* states */
  const [dataSource, setdataSource] = useState();

  /* func */
  const fetchData = async () => {
    const resp = await getEmployeeStaticCount({ id: user?.id });
    console.log('resp', resp);
    if (resp?.data?.status) {
      const dataCount = resp?.data?.data;
      setdataSource(dataCount);
    }
  };

  let data = [
    {
      id: 'leave',
      text: 'Leads',
      link: 'lead',
      icon: <LeadsIcon svgClass={StatsIconClass} />,
      total: dataSource?.total_leads || 0,
    },
    {
      id: 'total_insurer',
      text: 'Insurer',
      link: 'insurer',
      icon: <InsurerIcon svgClass={StatsIconClass} />,
      total: dataSource?.total_insurers || 0,
    },
  ];

  useEffect(() => {
    if (user?.position !== 'Admin' || user?.user_type === 'employee') {
      fetchData();
    }
  }, []);

  return (
    <>
      {user?.position === 'Admin' || user?.user_type === 'employee' ? (
        <DashbaordStatistics user={user} permissions={permissions} />
      ) : (
        <>
          <div className='flex flex-col justify-between mt-1 gap-3 pb-2 mb-2'>
            <div className='font-semibold text-sm leading-6'>Statistics</div>
          </div>
          <div className='flex gap-4'>
            {data.map((data, index) => {
              return <DisplayCard key={index} {...data} />;
            })}
          </div>
        </>
      )}
    </>
  );
};

export default Statistics;
