// import { formatDate } from "utils";
import { Claims, IconInquiry, IconPolicies, IconQuotation, IconTargets } from 'assets/icons';
import { SelectInput, Uploadbox } from 'components';
import { QUOTE_STAGE } from 'constants';
import dayjs from 'dayjs';
import { documentOptions } from 'utils/options';

export const taskMeetingColumn = [
  {
    key: 'activity',
    title: 'Type',
    dataIndex: 'activity',
    render: (text) => text || '\u2014',
    width: 90,
  },
  {
    key: 'subject',
    title: 'Subject',
    dataIndex: 'subject',
    render: (text) => text || '\u2014',
    width: 150,
  },
  {
    key: 'assign_to_employee',
    title: 'Assigned To',
    dataIndex: 'assign_to_employee',
    render: (text) => text || '\u2014',
  },
  {
    key: 'from_date',
    title: 'From',
    dataIndex: 'from_date',
    render: (_) => {
      if (_) {
        return dayjs(_).format('dddd MM,YYYY, HH:mm');
      } else {
        return '\u2014';
      }
    },
  },
];

export const inquiryColumns = [
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    className: 'capitalize',
    width: 100,
    id: 'source',
    render: (text) => {
      return text || '\u2014';
    },
  },
  {
    title: 'Employee Name',
    dataIndex: 'assign_to_employee',
    key: 'assign_to_employee',
    id: 'assign_to_employee',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Inquiry Name',
    dataIndex: 'name',
    key: 'name',
    className: 'capitalize',
    id: 'name',
    render: (text, data) => {
      if (data.source === 'online') {
        return data?.type.replaceAll('_', ' ') || '\u2014';
      }
      return text || '\u2014';
    },
  },
  {
    title: 'Client Name',
    dataIndex: 'customer_id',
    key: 'customer_id',
    id: 'customer_id',
    render: (text, data) => {
      if (data.source === 'online') {
        return data?.name || '\u2014';
      }
      return text || '\u2014';
    },
  },
];

export const quotesColumns = [
  {
    title: 'Quote Number',
    dataIndex: 'quote_no',
    id: 'quote_no',
    key: 'quoteno',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Date of Issue',
    dataIndex: 'doi',
    id: 'doi',
    key: 'doi',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Insured Name',
    dataIndex: 'customer',
    id: 'insured_name',
    key: 'insured_name',
    render: (text) => text?.name || '\u2014',
  },
  {
    title: 'Product',
    dataIndex: 'category',
    id: 'product',
    key: 'product',
    render: (text) => text?.name || '\u2014',
  },
  {
    title: 'Quote Stage',
    dataIndex: 'stage',
    id: 'stage',
    key: 'stage',
    render: (status) => QUOTE_STAGE.filter((ac) => ac?.value === status)[0]?.['label'],
  },
];

export const policydataSource = [
  {
    key: '1',
    process_type: 'Lorem Ipsum',
    insured_name: 'Lorem Ipsum',
    contact_name: 'Lorem Ipsum',
    contact: 'Lorem Ipsum',
    biz_source: 'Lorem Ipsum',
    bill_to: 'Lorem Ipsum',
    business_nature: 'Lorem Ipsum',
    policy_number: 'Lorem Ipsum',
  },
  {
    key: '2',
    process_type: 'Lorem Ipsum',
    insured_name: 'Lorem Ipsum',
    contact_name: 'Lorem Ipsum',
    contact: 'Lorem Ipsum',
    biz_source: 'Lorem Ipsum',
    bill_to: 'Lorem Ipsum',
    business_nature: 'Lorem Ipsum',
    policy_number: 'Lorem Ipsum',
  },
  {
    key: '3',
    process_type: 'Lorem Ipsum',
    insured_name: 'Lorem Ipsum',
    contact_name: 'Lorem Ipsum',
    contact: 'Lorem Ipsum',
    biz_source: 'Lorem Ipsum',
    bill_to: 'Lorem Ipsum',
    business_nature: 'Lorem Ipsum',
    policy_number: 'Lorem Ipsum',
  },
  {
    key: '4',
    process_type: 'Lorem Ipsum',
    insured_name: 'Lorem Ipsum',
    contact_name: 'Lorem Ipsum',
    contact: 'Lorem Ipsum',
    biz_source: 'Lorem Ipsum',
    bill_to: 'Lorem Ipsum',
    business_nature: 'Lorem Ipsum',
    policy_number: 'Lorem Ipsum',
  },
  {
    key: '5',
    process_type: 'Lorem Ipsum',
    insured_name: 'Lorem Ipsum',
    contact_name: 'Lorem Ipsum',
    contact: 'Lorem Ipsum',
    biz_source: 'Lorem Ipsum',
    bill_to: 'Lorem Ipsum',
    business_nature: 'Lorem Ipsum',
    policy_number: 'Lorem Ipsum',
  },
];

export const policyTableColumns = [
  {
    title: 'Process Type',
    dataIndex: 'process_type',
    key: 'process_type',
  },
  {
    title: 'Insured Name',
    dataIndex: 'insured_name',
    key: 'insured_name',
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact_name',
    key: 'contact_name',
  },
  {
    title: 'Bill to',
    dataIndex: 'bill_to',
    key: 'bill_to',
  },
];
export const targetData = [
  {
    icon: <IconTargets className='w-5 fill-[#666CFF] stroke-[#666CFF]' />,
    title: 0,
    desc: 'Total Targets',
    bg: 'bg-[#ECEDFF]',
    key: 'total_target',
  },
  {
    icon: <IconQuotation className='w-5 !fill-yellow !stroke-yellow' />,
    title: 0,
    desc: 'Quotations',
    bg: 'bg-[#FFF6E5]',
    key: 'quote',
  },
  {
    icon: <IconPolicies className='w-5 fill-green stroke-green' />,
    title: 0,
    desc: 'Policies',
    bg: 'bg-[#E5FFD2]',
    key: 'policy',
  },
  {
    icon: <IconInquiry className='w-5' />,
    title: 0,
    desc: 'Inquiries',
    bg: 'bg-[#E5F8FE]',
    key: 'inquiry',
  },
  // {
  //   icon: <Claims className='w-5 stroke-red-90 ' />,
  //   title: 0,
  //   desc: 'Claims',
  //   bg: 'bg-[#FFD9D9]',
  //   key: 'claim',
  // },
];

export const createDocumentFields = {
  'Upload Document': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Document Name',
        name: 'name',
        id: 'name',
        placeholder: 'Document Name',
        type: 'text',
        component: SelectInput,
        options: documentOptions,
        maxLength: 30,
        required: true,
      },
      {
        label: 'Upload Document',
        name: 'file',
        id: 'file',
        placeholder: 'file',
        required: true,
        component: Uploadbox,
        accept: ['.png, .jpeg, .jpg, .pdf, .txt , .doc'],
      },
    ],
  },
};

export const initialDocumentValues = {
  name: '',
  file: '',
};
