import { endpoints } from 'constants/endpoints';
import Request from './client';

export const getForm = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.form.list,
  });
  return resp;
};
export const addForm = async ({ data = undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.form.create,
    data,
  });
  return resp;
};
export const deleteForm = async ({ id }) => {
  const resp = await Request({
    method: 'delete',
    API: endpoints.form.delete(id),
  });
  return resp;
};
export const editForm = async ({ data = undefined, id }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.form.update(id),
    data,
  });
  return resp;
};
