import { Alert } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getRejectedLead } from 'service/api-service';
import { Context } from 'store/context';

const LeadAlert = () => {
  const { user } = useContext(Context);
  const [remark, setRemark] = useState();

  const fetchRejectedLead = useCallback(async () => {
    const resp = await getRejectedLead({
      date: dayjs().format('YYYY-MM-DD'),
      employee_id: user?.id,
    });
    if (resp?.data?.status) {
      if (Object.keys(resp?.data?.data).length > 0) {
        const remark = resp?.data?.data[0]?.remarks?.filter(
          (item) => item.date === dayjs().format('YYYY-MM-DD'),
        );
        if (remark) setRemark(remark[0]?.msg);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchRejectedLead();
  }, []);

  if (remark) {
    return (
      <Alert
        message='You have a lead reminder'
        description={remark}
        type='info'
        showIcon
        closable
      />
    );
  }
};

export default LeadAlert;
