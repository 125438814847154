import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { grossPayColumn, parsedData } from './data';
import { ExportToExcel, TableComponent, Toast } from 'components';
import { getUserSalary } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { BackArrow, DropdownIcon } from 'assets/icons';
import { rowCommonClass } from 'utils/common';

const ViewTable = ({ timeline, grossData, setActiveStep, id, fetchData }) => {
  /*state */
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const salaryStatementColumn = [
    {
      title: 'Employee Code',
      key: 'employee',
      dataIndex: 'user',
      render: (user) => user?.emp_code,
    },
    {
      title: 'Employee Name',
      key: 'user',
      dataIndex: 'user',
      render: (user) => user?.name,
    },
    {
      title: 'Days in Month',
      key: 'days',
      render: () => dayjs(timeline.month).daysInMonth(),
    },
    {
      title: 'Work Days',
      key: 'days',
      dataIndex: 'days',
    },
    {
      title: 'Gross Salary',
      key: 'salary',
      dataIndex: 'g_salary',
      render: (data) => (!Number.isInteger(data) ? data?.toFixed(2) : data),
    },
    {
      title: 'DA',
      key: 'da',
      dataIndex: 'deduct',
    },
    {
      title: 'Allowance',
      key: 'allowance',
      dataIndex: 'allow',
    },
    {
      title: 'Tax Slab',
      key: 'slab',
      dataIndex: 'tx_slab',
    },
  ];

  /*func */

  const fetchTableData = async () => {
    setLoading(true);
    const resp = await getUserSalary({ id });
    if (resp?.data?.status) {
      setData(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTableData();
    fetchData();
    // broadcast(REFRESH_EVT);
  }, []);

  return (
    <div className='grid grid-cols-5 gap-3'>
      <div className='bg-white p-4 border border-grey-40 rounded-lg col-span-3'>
        <div className='flex items-center divide-x divide-grey-50 pb-3'>
          <button onClick={() => setActiveStep(1)}>
            <BackArrow svgClass='fill-black w-6 h-6' />
          </button>
          <h2 className='px-2 text-s font-semibold'>Payroll Process</h2>
          <h3 className='px-2 font-semibold'>{timeline?.formatted}</h3>
        </div>
        <div className='border rounded-md overflow-hidden p-1'>
          <TableComponent
            columns={grossPayColumn}
            dataSource={grossData}
            showPagination={false}
            getRowClassName={rowCommonClass}
          />
        </div>
      </div>
      <div className='bg-white p-4 border border-grey-40 rounded-lg col-span-full'>
        <div className='flex items-center justify-between w-full'>
          <h2 className='px-2 text-s font-semibold'>Quick Salary Statement</h2>
          <ExportToExcel
            csvData={parsedData(data, timeline)}
            fileName={`Salary Sheet of ${timeline?.formatted}`}
          />
        </div>
        <div className='flex divide-x divide-grey-50 py-2'>
          <div className='flex items-center justify-center px-2'>
            <h2>Category :</h2>
            <Select
              suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
              allowClear={{ clearIcon: <CloseOutlined /> }}
              showSearch={true}
              className='cursor-pointer'
              placeholder='All'
            />
          </div>
          <div className='flex items-center justify-center px-2'>
            <h2>Employment status :</h2>
            <Select
              suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
              allowClear={{ clearIcon: <CloseOutlined /> }}
              showSearch={true}
              className='cursor-pointer'
              placeholder='All'
            />
          </div>
          <div className='flex items-center justify-center px-2'>
            <h2>Employee Filter :</h2>
            <Select
              suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
              allowClear={{ clearIcon: <CloseOutlined /> }}
              showSearch={true}
              className='cursor-pointer'
              placeholder='All'
            />
          </div>
        </div>
        <div className='border rounded-md overflow-hidden p-1'>
          <TableComponent
            loading={loading}
            columns={salaryStatementColumn}
            dataSource={data}
            getRowClassName={rowCommonClass}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewTable;
