import React, { useState } from 'react';
import { tabData } from './data';
import { CustomTab } from 'components';
import Invoices from 'screens/invoices';
import Tax from 'screens/tax';

export default () => {
  const [period, setPeriod] = useState('Tax');

  const renderTab = (item, index) => {
    return <CustomTab label={item} key={index} period={period} setPeriod={setPeriod} />;
  };
  return (
    <main id='task-dashboard-screen'>
      <div className='mb-4 rounded-lg bg-white p-2 pb-0'>{tabData.map(renderTab)}</div>
      {period === 'Tax' ? <Tax /> : <Invoices />}
    </main>
  );
};
