import { CopyOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import {
  Button,
  ContentHeader,
  CustomModal,
  FormContainer,
  IconButton,
  TableComponent,
  Toast,
} from 'components';
import { RequirementFields, breadCrumbs, jobStatusList } from './data';
import { prepareFormFieldsData } from 'utils';
import { elementData } from 'elements';
import { createRecruitment, getJobList, updateRecruitment } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { EDIT, View } from 'assets/icons';
import Advertise from './advertise';
import { TableStatus } from 'components/table/TableStatus';
import { formatFormPayload, rowCommonClass } from 'utils/common';

const JobManagement = ({ permissions }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({});
  const [showAdvertModal, setAdvertModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editInitials, setInitials] = useState({});
  const [tableData, setTableData] = useState([]);
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: RequirementFields,
  });

  const handleCopyClick = (id) => {
    const textToCopy = `${window.location.origin}/careers?reqId=${id}`;

    // Create a temporary textarea to copy the text
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    // Copy the text from the textarea
    textarea.select();
    document.execCommand('copy');

    // Clean up and remove the temporary textarea
    document.body.removeChild(textarea);
    // Optionally, you can show a notification or feedback to the user
    Toast('success', NOTIFICATION_MSG.success, 'Link copied to clipboard!');
  };

  const toggleModal = () => {
    setInitials(undefined);
    setShowModal((prev) => !prev);
  };

  const toggleAdvertModal = () => {
    setAdvertModal((prev) => !prev);
  };
  const toggleEditModal = () => {
    setShowModal((prev) => !prev);
  };

  const getRequirements = async () => {
    setLoading(true);
    const resp = await getJobList();
    setTableData(resp?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getRequirements();
  }, []);

  const onAddRequirements = async (payload) => {
    setLoading(true);

    try {
      const resp = await createRecruitment({ data: payload });
      if (resp?.data?.status) {
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.data?.data?.msg || 'Job Recruitment Created Successfully',
        );
        getRequirements();
        setShowModal(false);
      } else {
        Toast('error', NOTIFICATION_MSG.error, 'Failed To Create Job Recruitment1');
      }
    } catch (error) {}

    setLoading(false);
  };

  const onEditRequirements = async (data) => {
    setLoading(true);
    const finalPayload = {
      ...data,
      designation: typeof data?.designation === 'number' ? data.designation : undefined,
      exp: `${data?.exp}`,
    };

    formatFormPayload(finalPayload);
    const resp = await updateRecruitment({ id: data?.id, data: finalPayload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Job Recruitment Updated Successfully');
      getRequirements();
      setShowModal(false);
      setLoading(false);
    } else {
      Toast('error', NOTIFICATION_MSG.error, 'Failed To Updated Job Recruitment');
      setLoading(false);
    }
    setLoading(false);
  };

  const onStatusUpdate = async (e, record) => {
    try {
      const status = e?.key;

      if (String(status).toLowerCase() === String(record?.status).toLowerCase()) return;
      setLoading(true);

      const resp = await updateRecruitment({ id: record?.id, data: { status } });

      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, 'Job Recruitment Updated Successfully');
        getRequirements();
      } else Toast('error', NOTIFICATION_MSG.error, 'Failed To Updated Job Recruitment');
    } catch (error) {}
    setLoading(false);
  };

  const onEditpress = (data) => {
    const payload = {
      ...data,
      experience: data?.experience,
      exp_type: data?.exp_type,
      designation: data?.designation?.name,
    };
    setInitials(payload);
  };

  const onViewpress = (record) => {
    setSelectedAsset(record);
    navigate(`/hr/job-management/view-applications/${record?.id}`, {
      state: {
        selected: record,
      },
    });
  };
  const columns = [
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
      render: (data) => data?.name,
    },
    {
      title: 'No. of Applicatons',
      dataIndex: 'application_count',
      key: 'application_count',
    },
    {
      title: 'Interviews',
      dataIndex: 'interview_count',
      key: 'interview_count',
    },
    {
      title: 'Closing Date',
      dataIndex: 'c_date',
      key: 'c_date',
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (val) => val?.name,
    },
    {
      title: 'Updated By',
      dataIndex: 'updated_by',
      key: 'updated_by',
      render: (val) => val?.name || '--',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        return (
          <TableStatus
            permissions={permissions}
            id={elementData.manageRecruitment.permissions.update}
            onChange={onStatusUpdate}
            record={record}
            items={jobStatusList}
            status={status}
          />
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Copy Link'
            className='group disabled:cursor-not-allowed'
            permissions={permissions}
            id={elementData.manageRecruitment.permissions.view}
          >
            <CopyOutlined
              onClick={() => handleCopyClick(record?.id)}
              style={{
                border: '1px solid grey',
                borderRadius: '50%',
                fontSize: '15px',
                height: '25px',
                width: '25px',
                padding: '4px',
              }}
            />
          </IconButton>
          <IconButton
            title='View'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.manageRecruitment.permissions.delete}
            onClick={() => {
              onViewpress(record);
            }}
          >
            <View svgClass='stroke-primary group-disabled:stroke-grey' />
          </IconButton>

          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.manageRecruitment.permissions.update}
            onClick={() => {
              onEditpress(record);
              toggleEditModal();
            }}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  // const onSaveAdvert = (data) => {
  //   try {
  //     //Push the data to the webhook
  //     const id = selectedAsset?.id;
  //     // console.log("id===>", id);
  //     // const encriptedId = encryptText(`${id}`, process.env.REACT_APP_SECRET);
  //     // console.log("LINK====>", `http://localhost:3000/careers?reqId=${encriptedId}`);
  //     // console.log("LINK====>", `http://localhost:3000/careers?reqId=${id}`);
  //     console.log('LINK====>', `${url}/careers?reqId=${id}`);
  //   } catch (error) {
  //     console.log('onSaveAdvert error:', error);
  //   }
  // };
  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        // data={breadCrumbs}
        title={<p className='font-semibold text-s'>Recruitment</p>}
        buttonId={elementData.manageRecruitment.permissions.create}
        btnText='+ Recruitment'
        showSearch={false}
        onClick={toggleModal}
      />
      <TableComponent
        columns={columns}
        getRowClassName={rowCommonClass}
        dataSource={tableData}
        loading={loading}
      />

      {/* Recruitment Modal */}
      <CustomModal
        title={editInitials ? 'Edit  Job Recruitment' : 'Add Job Recruitment'}
        destroyOnClose={true}
        open={showModal}
        onCancel={toggleModal}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10'>
            {editInitials
              ? 'To edit a job recruitment, enter the details of the recruitment in the input field below.'
              : 'To add a new job recruitment, enter the details of the recruitment in the input field below.'}
          </span>

          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditRequirements : onAddRequirements}
            initialValues={editInitials ? editInitials : initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='secondary'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleModal();
                    }}
                  />
                  <Button
                    id={
                      editInitials
                        ? elementData.manageRecruitment.permissions.update
                        : elementData.manageRecruitment.permissions.create
                    }
                    text='Save'
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      <CustomModal
        destroyOnClose={true}
        open={showAdvertModal}
        onCancel={toggleAdvertModal}
        footer={''}
      >
        <Advertise
          refresh={getRequirements}
          setAdvertModal={setAdvertModal}
          selectedAsset={selectedAsset}
        />
        {/* <FormContainer
            accordionClass="grid grid-cols-2  gap-8"
            onSubmit={onSaveAdvert}
            initialValues={{ title: "" }}
            fields={advertFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className="bg-white flex flex-row justify-end py-4 mt-6">
                  <Button
                    text="Cancel"
                    variant="text"
                    type="reset"
                    classes="border border-gray-90 text-black px-6"
                    onClick={() => {
                      resetForm();
                      toggleAdvertModal();
                    }}
                  />
                  <MultiActionButton
                    classes={`ml-6 px-8   ${isSubmitting ? "bg-grey" : "bg-black"}`}
                    items={[
                      {
                        key: 1,
                        label: "Save Only",
                        onClick: () => {
                          redirectRef.current = false;
                        },
                      },
                      {
                        key: 2,
                        label: "Save and publish",
                        onClick: () => {
                          redirectRef.current = true;
                        },
                      },
                    ]}
                  />
                </div>
              );
            }}
          /> */}
      </CustomModal>

      {/* <CustomModal
        open={publishModal}
        closable={true}
        onCancel={togglePublishModal}
        footer={""}
        width={800}
      >
        <div className="py-12 relative">
          <div className="flex justify-between p-10">
            <div className="h-36 w-36 bg-grey-90 rounded-xl flex justify-center bordered">
              Linked In
            </div>
            <div className="h-36 w-36 bg-grey-90 rounded-xl flex justify-center bordered">
              {" "}
              Twitter
            </div>
            <div className="h-36 w-36 bg-grey-90 rounded-xl flex justify-center bordered">
              {" "}
              Instagram
            </div>
            <div className="h-36 w-36 bg-grey-90 rounded-xl flex justify-center bordered">
              Facebook
            </div>
          </div>

          <button className="bg-black text-white p-4 rounded-2xl absolute bottom-3 right-3">
            Publish
          </button>
        </div>
      </CustomModal> */}
    </div>
  );
};
export default JobManagement;
