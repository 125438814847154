import { endpoints } from 'constants/endpoints';
import Request from './client';

export const policyManagement = async (
  props = {
    q: '',
    data: '',
    method: 'get',
    id: '',
    date: '',
    owner: '',
    customer_id: '',
    pos_id: '',
    type: 'all',
    page: 1,
    customDate: { startDate: '', endDate: '' },
  },
) => {
  const resp = await Request({
    method: props.method,
    API:
      props.method === 'delete' || props.method === 'patch'
        ? `policies/?id=${props?.id}`
        : `policies/?q=${props?.q || ''}&date=${props.date || ''}&start_date=${
            props.customDate?.startDate || ''
          }&end_date=${props.customDate?.endDate || ''}&owner=${props.owner || ''}&customer_id=${
            props.customer_id || ''
          }&pos_id=${props.pos_id || ''}&type=${props.type || ''}&page=${props?.page}`,
    data: props.data,
  });
  return resp;
};

export const addPolicy = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.policy.create,
    data,
  });
  return resp;
};

export const otherSourcepolicy = async (
  props = { data: '', method: 'get', id: '', page: 1, customDate: { startDate: '', endDate: '' } },
) => {
  const resp = await Request({
    method: props.method,
    API:
      props.method === 'patch'
        ? `policies/other/${props.id}`
        : `policies/?other=true&page=${props?.page}&start_date=${
            props.customDate?.startDate || ''
          }&end_date=${props.customDate?.endDate || ''}`,
    data: props.data,
  });
  return resp;
};

export const getPoliciesChartData = async ({ start_date, end_date, source = '' }) => {
  const resp = await Request({
    method: 'get',
    API: `policies/chart?start_date=${start_date}&end_date=${end_date}&according=${source}`,
  });
  return resp;
};

export const renewalPolicyManagement = async ({
  method = 'get',
  data = '',
  page = 1,
  q = '',
  employeeID = '',
  customDate = { startDate: '', endDate: '' },
}) => {
  const resp = await Request({
    method: method,
    API: `policies/?renewal=true&q=${q}&employee_id=${employeeID}&start_date=${
      customDate?.startDate || ''
    }&end_date=${customDate?.endDate || ''}`,
    page: page,
    data: data,
  });
  return resp;
};
