import React, { useState, useCallback } from 'react';
import { Modal } from 'antd';
import { IconButton, InvoiceData } from 'components';
import { View } from 'assets/icons';
import { CloseOutlined } from '@ant-design/icons';

const InvoiceViewer = ({ record, ...rest }) => {
  const [isShown, setIsShown] = useState();
  const toggle = useCallback(() => {
    setIsShown((s) => !s);
  }, []);

  return (
    <>
      <IconButton
        title='View'
        {...rest}
        onClick={toggle}
        className='group disabled:cursor-not-allowed'
      >
        <View svgClass='stroke-primary group-disabled:stroke-grey' />
      </IconButton>
      <Modal
        open={isShown}
        footer={false}
        onCancel={toggle}
        okButtonProps={{
          className: 'noPrint',
        }}
        cancelButtonProps={{
          className: 'noPrint',
        }}
        closeIcon={<CloseOutlined className='noPrint' />}
      >
        <InvoiceData record={record} />
      </Modal>
    </>
  );
};

export default InvoiceViewer;
