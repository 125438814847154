/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// /** internal deps */
import { SecureComponent } from 'permission-provider';
import { employeeGoalGraph, viewGoals } from 'service/api-service';
import GoalsTable from './goalsTable';
import GoalsGraph from './goalsGraph';
import { SortSuccessRate } from './data';
import Button from 'components/button';
import { IconButton } from 'components';
import { BackArrow } from 'assets/icons';

const viewGoalBreads = (id) => [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Goals',
    path: '/goals',
  },
  {
    name: 'View Goal',
    path: `/view-goal/${id}`,
  },
];

const ViewTargetDetails = ({ permissions = {}, employeeData }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { employee } = state?.values ?? { employeeData };
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState();
  const [successRateData, setSuccessRateData] = useState([]);

  /* func */
  const fetchData = useCallback(async (page = 1) => {
    setLoading(true);
    const resp = await viewGoals({ id: employee?.id || employeeData?.id, page });
    if (resp?.data?.data) {
      setCount(resp?.data?.data);
    }

    const result = await employeeGoalGraph({ id: employee?.id || employeeData?.id });
    setSuccessRateData(SortSuccessRate(result?.data?.data?.target_by_date));
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <main className=' w-full flex flex-col gap-4  p-4'>
      <div className='flex justify-between'>
        <h2 className='text-sm font-semibold'>Goals Detail</h2>
        <IconButton onClick={() => navigate(-1)} className='group top-1 flex items-center'>
          <>
            <BackArrow svgClass='fill-grey-90 w-8 h-8 group-hover:fill-primary-90 transition-all  duration-500 group-hover:-translate-x-1' />
            <span className='text-xxs text-grey-90 group-hover:text-primary-90 font-medium'>
              Go back
            </span>
          </>
        </IconButton>
      </div>

      <GoalsGraph data={count} employeeData={employeeData} successRateData={successRateData} />

      <GoalsTable
        permissions={permissions}
        loading={loading}
        dataSource={count}
        fetchData={fetchData}
        employee={employee}
      />
    </main>
  );
};

export default SecureComponent(ViewTargetDetails);
