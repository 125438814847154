import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button, CustomModal, TableComponent } from 'components';
import { InterviewRequest_Cols } from './data';
import { getInterviewRequest } from 'service/api-service';
import { rowCommonClass } from 'utils/common';
import { ViewCvIcon } from 'assets/svg';

const RequestForInterview = (props = {}) => {
  const date = props?.date || '';
  const navigate = useNavigate();
  /* states */
  const [loading, setLoading] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const [showCVModal, setShowCVModal] = useState(false);
  const [cv, setCV] = useState('');

  /* func */
  const fetchData = async () => {
    setLoading(true);
    const resp = await getInterviewRequest({ status: 'pending', date });
    if (resp?.data?.status) {
      setdataSource(resp?.data?.data);
    }
    setLoading(false);
  };

  const handleView = (file) => {
    setCV(file);
    //setShowCVModal(true);
  };

  useEffect(() => {
    fetchData();
  }, [date]);
  return (
    <div className='p-4 space-y-4 h-full bg-white rounded-md border border-grey-40'>
      <div className='flex justify-between items-center'>
        <div className='font-semibold leading-6 text-s'>Applications Received</div>
        <Button
          classes='!py-1'
          text='See all'
          onClick={navigate.bind(this, '/hr/job-management')}
        />
      </div>
      <div className='customTable' id='dashboard'>
        <TableComponent
          getRowClassName={rowCommonClass}
          loading={loading}
          pageSize='3'
          columns={[
            ...InterviewRequest_Cols,
            {
              key: 'cv',
              title: 'Resume',
              dataIndex: 'cv',
              render: (cv, record) => {
                return (
                  <>
                    <Link to={`/hr/user-details/${record.id}`} target='_blank'>
                      <button
                        className='flex gap-x-2 justify-center items-center hover:border-black hover:bg-white text-primary-90'
                        //onClick={() => handleView(cv, record)}
                      >
                        <ViewCvIcon />
                        View CV
                      </button>
                    </Link>
                  </>
                );
              },
            },
          ]}
          dataSource={dataSource}
          showPagination={false}
          scroll={{ y: 175 }}
        />
      </div>
      <CustomModal
        destroyOnClose={true}
        open={showCVModal}
        onCancel={setShowCVModal.bind(this, false)}
        footer={''}
      >
        <div className='flex flex-col'>
          <span className='font-medium text-l 2xl:text-xxl'>CV</span>
          <a href={process.env.REACT_APP_DOC_URL + cv} target='_blank' rel='noreferrer' download>
            <div className='relative p-1 rounded-md bg-grey-40'>
              <object height={200} width={300} data={process.env.REACT_APP_DOC_URL + cv}>
                {''}
              </object>
            </div>
          </a>
        </div>
      </CustomModal>
    </div>
  );
};

export default RequestForInterview;
