import React, { useState } from 'react';

import { EyeClosed, EyeOpen } from 'assets/icons';
import './style.css';
const PasswordInput = ({ field, form, name, rightIcon, extraRightRender, ...props }) => {
  const [isVisible, setVisible] = useState(false);
  const toggleShowHide = () => {
    setVisible((pre) => !pre);
  };

  return (
    <div className='group'>
      <label
        className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xxs text-black ${
          props?.required ? 'required' : ''
        }`}
      >
        {props.label}
      </label>
      <div className='relative'>
        <div
          onClick={!props?.disabled ? toggleShowHide : null}
          className={`absolute inset-y-0 right-0 px-3 flex items-center cursor-pointer bg-none border-y border-r border-[#D1EAF9] focus:!ring-primary-90 focus:!border-primary-90 group-focus-within:!border-primary-90 rounded-r-md`}
        >
          {isVisible ? <EyeOpen /> : <EyeClosed />}
        </div>
        <div>
          <input
            {...props}
            type={isVisible ? 'text' : 'password'}
            className={`${
              props.leftIcon ? 'pl-10' : ''
            } border border-[#D1EAF9] bg-[#F5FAFD] text-xs rounded-md focus:ring-primary-90 focus:border-primary-90 block w-full p-1.5 xl:p-1 focus:outline-none 
            placeholder-grey-90 placeholder:text-grey-90 placeholder:text-xxs xl:placeholder:text-xxs disabled:bg-grey-light disabled:border-grey-light disabled:cursor-not-allowed
          `}
            {...field}
            onKeyDown={(e) => props?.loginOnEnter(e, form)}
          />
        </div>

        <div className={`absolute right-0 flex items-center cursor-pointer`}>
          {extraRightRender ? extraRightRender() : null}
        </div>

        {form?.errors[field?.name] && form?.touched[field?.name] && (
          <div
            className={`absolute bg-white ${
              extraRightRender ? 'max-w-[230px]' : ''
            } last:left-0 text-[#B20000] flex items-center cursor-pointer text-x mt-1 bg-white border-white`}
          >
            {form?.errors[field?.name]}
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordInput;
