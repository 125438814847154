import { MsgIconComp, PenEditIcon, ZigZagBlackIcon } from 'assets/icons';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLeads } from 'screens/leads/store';
import { useShallow } from 'zustand/react/shallow';
import ProductCard from './ProductCard';
import QuotationCard from './QuotationCard';

export const Detail = ({ label, value }) => {
  return (
    <div className='space-x-1'>
      <span className='text-xxs leading-normal font-normal capitalize'>
        {label.replaceAll('_', ' ')}
      </span>
      :<span className='font-bold'>{value}</span>
    </div>
  );
};

const ViewLeads = () => {
  const params = useParams();
  const quotationsData = useLeads((state) => state.quotations.data);
  const { initQuotationData } = useLeads(
    useShallow((state) => ({
      initQuotationData: state.initQuotationData,
    })),
  );

  console.log('quotationsData', quotationsData);

  useEffect(() => {
    initQuotationData(params.id);
  }, []);

  const clientDetails = [
    {
      label: 'Client Name',
      value: quotationsData?.customer?.name ?? quotationsData?.customer?.corp_name,
    },
    { label: 'Email', value: quotationsData?.customer?.email_address || '_' },
    { label: 'Phone', value: quotationsData?.customer?.contact_number },
  ];

  const IconsGroup = () => {
    return (
      <div className='space-x-2 flex justify-center items-center'>
        <span>
          <MsgIconComp className='h-7 w-7 flex justify-center items-center bg-white' />
        </span>
        <span>
          <ZigZagBlackIcon />
        </span>
        <span>
          <PenEditIcon />
        </span>
      </div>
    );
  };

  return (
    <div className='flex flex-col'>
      <div className='flex justify-between'>
        {params.step == 1 && (
          <div className='text-s font-semibold leading-normal space-x-2'>
            <span>Leads View</span>
            <span className='text-primary-90 font-bold'>#{quotationsData?.lead_id}</span>
          </div>
        )}

        {params.step == 2 && (
          <div className='text-s font-semibold leading-normal space-x-2'>
            <span>Quotation View</span>
          </div>
        )}
        <IconsGroup />
      </div>
      <div className='grid grid-cols-3 pl-4 pr-20 shadow-shadowmd py-4 rounded-2xl bg-white mt-4'>
        {clientDetails &&
          clientDetails.map(({ label, value }) => {
            return <Detail label={label} value={value} />;
          })}
      </div>

      {/* Leads View */}

      {params.step == 1 &&
        quotationsData?.insurance_details?.map((item, index) => (
          <ProductCard count={index + 1} productData={item} quotationsData={quotationsData} />
        ))}

      {/* Quotation View */}

      {params.step == 2 &&
        quotationsData?.quote?.map((item, index) => (
          <QuotationCard count={index + 1} quoteData={item} quotationsData={quotationsData} />
        ))}
    </div>
  );
};

export default ViewLeads;
