import { Checkbox } from 'antd';
import { CustomModal, Toast } from 'components';
import Button from 'components/button';
import { NOTIFICATION_MSG } from 'constants';
import { useCallback, useContext, useState } from 'react';
import { inquiryManagement } from 'service/api-service';
import { Context } from 'store/context';

const InsurerModal = ({ showInsurerModal, toggleInsurerModal, data }) => {
  const {
    leads: { updateLeads },
  } = useContext(Context);
  const [selected, setSelected] = useState(null);

  const handleClick = useCallback(
    ({ product, insurer }) => {
      setSelected({
        s_product: product,
        s_insurer: insurer,
        id: data?.id,
        status: 'proposals',
      });
    },
    [setSelected, data],
  );

  const handleSubmit = async () => {
    const resp = await inquiryManagement({ id: data?.id, data: selected, method: 'patch' });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleInsurerModal();
      updateLeads({ pageNum: 1, status: 'quoted' });
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  const renderProducts = (item) => {
    const productId = item?.product_type?.id;
    return (
      <div className='flex flex-col gap-2'>
        <p className='font-bold text-xs'> {item?.product_type?.name}</p>
        {item?.insurer?.map((ele) => (
          <div
            className='w-72  py-4 px-2 border-2 rounded-md flex justify-between'
            onClick={() => handleClick({ product: productId, insurer: ele?.id })}
          >
            <img
              src={process.env.REACT_APP_DOC_URL + ele?.image}
              alt=''
              className='h-6 w-6 rounded-full'
            />
            <p className='w-52 truncate'>{ele?.name}</p>
            <Checkbox
              checked={selected?.s_product === productId && selected?.s_insurer === ele?.id}
            />
          </div>
        ))}
      </div>
    );
  };
  return (
    <CustomModal
      open={showInsurerModal}
      closable={true}
      onCancel={toggleInsurerModal}
      footer={''}
      width={950}
    >
      <div className=' grid grid-cols-3 gap-4'>
        {data?.products?.map((item) => renderProducts(item))}
      </div>
      <div className='w-full flex justify-end mt-4'>
        <Button text='Submit' onClick={handleSubmit} />
      </div>
    </CustomModal>
  );
};

export default InsurerModal;
