export const documentOptions = [
  {
    label: 'Aadhar Card',
    value: 'Aadhar Card',
  },
  {
    label: 'Birth Certificate',
    value: 'Birth Certificate',
  },
  {
    label: "Driver's License",
    value: "Driver's License",
  },
  {
    label: 'PAN Card',
    value: 'PAN Card',
  },
  {
    label: 'Passport',
    value: 'Passport',
  },
  {
    label: "Voter's Card",
    value: "Voter's Card",
  },
  {
    label: '10th Certificate',
    value: '10th Certificate',
  },
  {
    label: '12th Certificate',
    value: '12th Certificate',
  },
  {
    label: 'Gradutaion Certificate',
    value: 'Gradutaion certificate',
  },
  {
    label: 'Post Gradutaion Certificate',
    value: 'Post Gradutaion certificate',
  },
  {
    label: 'Other Educational Certificate',
    value: 'Other educational certificate',
  },
  {
    label: 'CV Resume',
    value: 'CV Resume',
  },
];
