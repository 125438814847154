import React, { useState, useRef, useEffect } from 'react';
import { DropdownIcon } from 'assets/icons';
import './style.css';

const Accordion = ({
  title,
  info,
  classes,
  subTitle,
  headerToHeading = false,
  customHeadRender,
  showAccordionHeader = true,
  isClosed,
  onChange,
  customClasses,
}) => {
  /** vars */
  const content = useRef(null);
  /** states */
  const [setActive, setActiveState] = useState('active');
  const [setHeight, setHeightState] = useState(`${content?.current?.scrollHeight}px`);
  const [setRotate, setRotateState] = useState('accordion_icon');

  /** funcs */
  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`);
    setRotateState(setActive === 'active' ? 'accordion_icon -rotate-90' : 'accordion_icon');
    if (onChange) onChange(setActive === '' ? true : false);
  };

  useEffect(() => {
    if (isClosed) toggleAccordion();
  }, []);

  return (
    <div
      className={`flex flex-col w-full shadow-main rounded-xl overflow-visible ${
        showAccordionHeader ? 'py-4' : ''
      } ${customClasses}`}
    >
      {customHeadRender ? (
        customHeadRender(toggleAccordion)
      ) : headerToHeading ? (
        <h2 className='text-xs font-semibold pl-2'>{title}</h2>
      ) : showAccordionHeader ? (
        <button
          type='button'
          className={`flex gap-4 items-baseline bg-grey-light p-2 rounded-md ${setActive}`}
          onClick={toggleAccordion}
        >
          <h4 className='text-left text-xxl md:text-xs  xl:text-xs 2xl:text-s transition-all duration-500 text-primary-90 font-medium'>
            {title}
          </h4>
          <DropdownIcon svgClass={`${setRotate} fill-primary-90 w-4 h-4`} />
        </button>
      ) : null}
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={` ${setActive === '' ? 'accordion_content' : 'accordion_content2'}`}
      >
        {subTitle && <span className='mt-4 px-2 text-grey font-medium block'>{subTitle}</span>}

        <div className={`text-grey pt-4 px-2 overflow:visible ${classes}`}>{info}</div>
      </div>
    </div>
  );
};

export default Accordion;
