import { endpoints } from 'constants/endpoints';
import Request from './client';

export const login = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.auth.login,
    data: data,
  });

  return resp;
};

export const changeUserPassword = async ({ data, authorization }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.auth.changeUserPwd,
    data: data,
    header: { authorization },
  });
  return resp;
};

export const requestResetLink = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.auth.requestResetLink,
    data,
  });
  return resp;
};

export const resetPassword = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.auth.resetPwd,
    data,
  });
  return resp;
};
