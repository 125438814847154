import {
  FormContainer,
  SearchAPI,
  SelectInput,
  TextInput,
  CustomRadio,
  SelectSearchWithName,
  DateTime,
  Uploadbox,
} from 'components';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { SubmitButtons } from 'screens/leads/data';
import { useLeads } from 'screens/leads/store';
import {
  getVehicleBrandModal,
  getVehicleBrands,
  getVehicleFuelType,
  getVehicleVarient,
} from 'service/api-service';
import { prepareFormFieldsData } from 'utils';
import { formStyle } from 'utils/common';
import { useShallow } from 'zustand/react/shallow';

const manufactureYear = [
  { value: '2024', label: '2024' },
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
  { value: '2021', label: '2021' },
  { value: '2020', label: '2020' },
  { label: '2019', value: '2019' },
  { label: '2018', value: '2018' },
  { label: '2017', value: '2017' },
  { label: '2016', value: '2016' },
  { label: '2015', value: '2015' },
  { label: '2014', value: '2014' },
  { label: '2013', value: '2013' },
  { label: '2012', value: '2012' },
  { label: '2011', value: '2011' },
  { label: '2010', value: '2010' },
  { label: '2009', value: '2009' },
  { label: '2008', value: '2008' },
  { label: '2007', value: '2007' },
  { label: '2006', value: '2006' },
  { label: '2005', value: '2005' },
  { label: '2004', value: '2004' },
  { label: '2003', value: '2003' },
  { label: '2002', value: '2002' },
  { label: '2001', value: '2001' },
  { label: '2000', value: '2000' },
  { label: '1999', value: '1999' },
  { label: '1998', value: '1998' },
  { label: '1997', value: '1997' },
];

const TwoWheelerForm = ({
  formData,
  setValues,
  insurer,
  setCheckSubmit,
  setSelectedProduct,
  isEditing,
  submitProduct,
}) => {
  const { client } = useLeads(
    useShallow((state) => ({
      client: state.createLead.client,
    })),
  );
  const [initalType, setInitialType] = useState(true);
  const [wantExit, setWantExit] = useState(false);

  // this wrapper is for rendering this radio field at the parent component using react portal
  const RadioWrapper = (props) => {
    const portalNodes = Array.from(
      document.querySelectorAll(`#leads-custom-radio-${formData?.count}`),
    );
    return portalNodes.at(-1) ? createPortal(<CustomRadio {...props} />, portalNodes.at(-1)) : null;
  };
  const onSubmit = (data) => {
    submitProduct(data, wantExit);
  };

  const previousFields = {
    'Previous Insurance Details': {
      headerToHeading: true,
      showAccordionHeader: false,
      fields: [
        {
          label: 'Upload previous year policy',
          name: 'prev_policy',
          id: 'prev_policy',
          placeholder: 'Previous Policy',
          component: Uploadbox,
          dragger: false,
          accept: ['.png, .jpeg, .jpg'],
        },
        {
          label: 'Upload RC',
          name: 'rc',
          id: 'rc',
          placeholder: 'Upload RC',
          component: Uploadbox,
          dragger: false,
          accept: ['.png, .jpeg, .jpg'],
        },
        {
          label: 'For KYC (Upload Aadhar or Pan Card)',
          name: 'kyc',
          id: 'kyc',
          placeholder: 'Upload Document',
          component: Uploadbox,
          dragger: false,
          accept: ['.png, .jpeg, .jpg'],
        },
      ],
    },
  };

  const quoteCustomerFields = {
    'Vehicle Details': {
      showAccordionHeader: false,
      headerToHeading: false,
      fields: [
        {
          label: '',
          name: 'vehicle',
          id: 'vehicle',
          type: 'checkbox',
          defaultValue: 'new',
          component: CustomRadio,
          customOnChange: (name, value) => setInitialType(value === 'new' ? true : false),
          options: [
            { value: 'new', label: 'Brand New Vehicle ' },
            { value: 'old', label: 'Registered Vehicle' },
          ],
          divClasses: 'col-span-full flex justify-end -mt-6 absolute top-8 right-0',
        },
        {
          dependent: initalType,
          dependent_on: 'vehicle',
          dependent_value: 'old',
          label: 'Owner Name',
          name: 'owner_name',
          defaultValue: client.name || client?.corp_name,
          // divClasses: 'customCssForLead',
          id: 'owner_name',
          placeholder: 'Owner Name',
          type: 'text',
          component: TextInput,
        },
        {
          dependent: initalType,
          dependent_on: 'vehicle',
          dependent_value: 'old',
          label: 'Email Address',
          name: 'email_address',
          divClasses: 'customCssForLead',
          id: 'email_address',
          placeholder: 'Email Address',
          type: 'text',
          component: TextInput,
        },
        {
          dependent: initalType,
          dependent_on: 'vehicle',
          dependent_value: 'old',
          label: 'Phone Number',
          name: 'phone_number',
          // divClasses: 'customCssForLead',
          id: 'phone_number',
          placeholder: 'Phone Number',
          type: 'text',
          component: TextInput,
        },
        {
          dependent: initalType,
          dependent_on: 'vehicle',
          dependent_value: 'old',
          label: 'Date of Registration',
          name: 'reg_date',
          // divClasses: 'customCssForLead',
          id: 'reg_date',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          component: DateTime,
        },
        {
          dependent: initalType,
          dependent_on: 'vehicle',
          dependent_value: 'old',
          label: 'Chasis Number',
          name: 'chassis_number',
          // divClasses: 'customCssForLead',
          id: 'chassis_number',
          placeholder: 'Number',
          type: 'text',
          component: TextInput,
        },
        {
          dependent: initalType,
          dependent_on: 'vehicle',
          dependent_value: 'old',
          label: 'Engine Number',
          name: 'engine_number',
          // divClasses: 'customCssForLead',
          id: 'engine_number',
          placeholder: 'Number',
          type: 'text',
          component: TextInput,
        },
        {
          dependent: initalType,
          dependent_on: 'vehicle',
          dependent_value: 'old',
          label: 'Registration Number',
          name: 'reg_number',
          // divClasses: 'customCssForLead',
          id: 'reg_number',
          placeholder: 'Registration Number',
          type: 'text',
          component: TextInput,
        },
        {
          dependent: !initalType,
          dependent_on: 'vehicle',
          dependent_value: 'new',
          label: 'Registered venue',
          name: 'rto_venue',
          id: 'rto_venue',
          placeholder: 'Registered venue',
          type: 'search',
          component: SearchAPI,
        },
        {
          dependent: !initalType,
          dependent_on: 'vehicle',
          dependent_value: 'new',
          label: 'RTO city number',
          name: 'rto_city',
          id: 'rto_city',
          placeholder: 'RTO city number',
          type: 'select',
          component: SelectInput,
          options: [],
          dependency: ['rto_venue'],
          dependantFetchOptions: async (form) => {
            if (!form?.values?.rto_venue) return [];
            return form?.values?.rto_venue?.rto?.map((item) => ({
              id: item.id,
              name: item.rto_No,
            }));
          },
        },
        {
          label: 'Brand',
          name: 'brand',
          id: 'brand',
          placeholder: 'Brand',
          type: 'select',
          component: SelectSearchWithName,
          options: [],
          fetchOptions: async () => getVehicleBrands({ type: 'bike' }),
        },
        {
          label: 'Model',
          name: 'model',
          id: 'model',
          placeholder: 'Model',
          type: 'select',
          component: SelectSearchWithName,
          options: [],
          dependency: ['brand'],
          dependantFetchOptions: async (form) => {
            if (!form?.values?.brand) return [];
            const brand = form?.values?.brand;
            const resp = await getVehicleBrandModal({ brand });
            if (resp && resp?.data && resp?.data?.status) {
              return resp?.data?.data;
            } else return [];
          },
        },
        {
          label: 'Fuel Type',
          name: 'fuel_type',
          id: 'fuel_type',
          placeholder: 'Fuel Type',
          type: 'select',
          component: SelectInput,
          options: [],
          dependency: ['model'],
          dependantFetchOptions: async (form) => {
            if (!form?.values?.model) return [];
            const model = form?.values?.model;
            const resp = await getVehicleFuelType({ model });
            if (resp && resp?.data && resp?.data?.status) {
              return resp?.data?.data.map((item) => ({ id: item, name: item }));
            } else return [];
          },
        },
        {
          label: 'Variant',
          name: 'varient',
          id: 'varient',
          placeholder: 'Variant',
          type: 'select',
          component: SelectSearchWithName,
          options: [],
          dependency: ['model', 'fuel_type'],
          dependantFetchOptions: async (form) => {
            if (!form?.values?.model || !form?.values?.fuel_type) return [];
            const model = form?.values?.model;
            const fuelType = form?.values?.fuel_type;
            const resp = await getVehicleVarient({ model, fuelType });
            if (resp && resp?.data && resp?.data?.status) {
              return resp?.data?.data;
            } else return [];
          },
        },
        {
          label: 'Manufacturing Year',
          name: 'manufac_year',
          id: 'manufac_year',
          placeholder: 'Manufacturing Year',
          type: 'select',
          component: SelectInput,
          options: manufactureYear,
          menuPlacement: 'top',
        },
        {
          dependent: initalType,
          dependent_on: 'vehicle',
          dependent_value: 'old',
          label: 'Maker',
          name: 'maker',
          id: 'maker',
          placeholder: 'Choose Maker',
          type: 'select',
          component: SelectInput,
          options: [],
          menuPlacement: 'top',
        },
        {
          dependent: initalType,
          dependent_on: 'vehicle',
          dependent_value: 'old',
          label: 'Cubic Cap',
          name: 'cubic_cap',
          id: 'cubic_cap',
          placeholder: 'Cubic Cap Number',
          type: 'select',
          component: TextInput,
        },
      ],
    },

    'Nominee Details': {
      headerToHeading: true,
      fields: [
        {
          label: 'Nominee Name',
          name: 'nominee_name',
          id: 'nominee_name',
          placeholder: 'Name',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Nominee DOB',
          name: 'nominee_dob',
          id: 'nominee_dob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          component: DateTime,
        },
        {
          label: 'Claim Status',
          name: 'claim_status',
          id: 'claim_status',
          placeholder: 'Select Maker',
          type: 'select',
          component: SelectInput,
          options: [
            {
              label: 'Yes',
              name: 'yes',
              id: 'yes',
              value: 'yes',
            },
            {
              label: 'No',
              name: 'no',
              id: 'no',
              value: 'no',
            },
          ],
          menuPlacement: 'top',
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: quoteCustomerFields,
    defaultValues: { ...formData, vehicle: formData?.vehicle ?? 'old', owner_name: 'hlo' },
  });

  return (
    <div className='bg-white vehicleform  '>
      <FormContainer
        returnFormatted={false}
        accordionClass={formStyle}
        fields={
          !initalType || formData?.vehicle === 'old'
            ? { ...finalFields, ...previousFields }
            : finalFields
        }
        initialValues={formData || initialValues}
        onSubmit={onSubmit}
        customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
          return formData?.count ? (
            <></>
          ) : (
            <SubmitButtons
              show={true}
              classes='z-10'
              submit={submitForm}
              reset={resetForm}
              setWantExit={setWantExit}
            />
          );
          //
        }}
      />
    </div>
  );
};

export default {
  Form: TwoWheelerForm,
};
