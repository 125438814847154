/** external deps */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

/** internal deps */
import { ContentHeader, Module } from 'components';
import { breadcrumbObjAdd, breadcrumbObjEdit } from './data';
import ReportChart from './ReportChart';
import ReportDetails from './ReportDetails';
import ReportFilter from './ReportFilter';
import { SecureComponent } from 'permission-provider';
import { getPermission } from 'utils';
import { elementData } from 'elements';

const AddReport = ({ permissions = {} }) => {
  const { state } = useLocation();
  const isEditing = state?.initialValues ? true : false;

  /** states */
  const [form, setForm] = useState('details');
  const [formData, setFormData] = useState({});

  return (
    <div className='grid gap-3'>
      {getPermission(permissions, elementData.Report.permissions.create)?.write ? (
        <>
          {' '}
          <ContentHeader
            data={isEditing ? breadcrumbObjEdit : breadcrumbObjAdd}
            showSearch={false}
          />
          <div className='bg-white  flex flex-col p-4 rounded-lg'>
            {form === 'details' ? (
              <ReportDetails setForm={setForm} setFormData={setFormData} formData={formData} />
            ) : form === 'filter' ? (
              <ReportFilter setForm={setForm} setFormData={setFormData} formData={formData} />
            ) : form === 'chart' ? (
              <ReportChart setForm={setForm} setFormData={setFormData} formData={formData} />
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        <Module />
      )}
    </div>
  );
};

export default SecureComponent(AddReport);
