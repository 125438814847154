import { LeadsLineIcons } from 'assets/icons';
import { SideMenuData } from '../data';
import { useLeads } from '../store';
import { NavLink, useParams } from 'react-router-dom';
import { Divider } from 'antd';

const SideMenu = () => {
  const params = useParams();
  const highestVisitedStep = useLeads((state) => state.highestVisitedStep);

  return (
    <div>
      {SideMenuData?.map((item, index) => {
        return (
          <div
            className={`min-w-${17}vw ${
              Number(params.step) === item.id
                ? `border-0 border-r-4 border-[#0089CF] pr-24 `
                : 'border-0 border-r-4 border-grey-90 pr-24'
            }`}
          >
            <NavLink
              to={getLink(params, item.id, highestVisitedStep)}
              className='flex gap-4 items-center cursor-pointer'
            >
              <div
                className={`h-6 w-6 ${
                  item?.id <= highestVisitedStep ? 'bg-[#0089CF] ' : 'bg-[#DDDDDD]'
                } rounded-full text-white flex justify-center items-center`}
              >
                {item?.id}
              </div>
              <p
                className={`${
                  item?.id <= highestVisitedStep ? 'text-[#0089CF] ' : 'text-[#ABABAB]'
                }`}
              >
                {' '}
                {item?.module}
              </p>
            </NavLink>
            <div className={item?.id === 4 ? 'my-0 ml-3 invisible' : 'my-0 ml-3'}>
              {<LeadsLineIcons />}
            </div>
          </div>
        );
      })}
      {/* {(params.step === '2' || params.step === '4') &&
      <Divider className='bg-black my-0 w-24 '  />} */}
      {/* will show data in quotations */}
      <div id='leads-portal' />
      {/* will show data in multi policy */}
      <div id='policy-portal' />
    </div>
  );
};

export default SideMenu;
/**
 *
 * @param {Record<'mode' | 'id' | 'step'} params
 * @param {string} item
 * @param {number} highestVisitedStep
 */
const getLink = (params, itemStep, highestVisitedStep) => {
  const numericalStep = Number(params.step);
  let shouldMakeDisabled = false;
  if (params.mode === 'create') {
    if (numericalStep !== 4 && numericalStep + 1 <= itemStep && itemStep > highestVisitedStep)
      shouldMakeDisabled = true;
  }

  if (shouldMakeDisabled) return '#';

  return `/lead/${params.mode}/${params.id}/${itemStep}`;
};
