export const breadCrumbsObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Leads',
    path: '',
  },
];

/**
 * @param {{
 * key: string,
 * name: string;
 * customer_name: string;
 * contact_id: string;
 * primary_email_address: string;
 * insurance_category: string;
 * duration: string;
 * insurance_period_from: string;
 * insurance_period_to: string;
 * close_date: string;
 * probability: string;
 * lead_source: string;
 * sales_stages: string;
 * employement: string;
 * next_step: string;
 * policy: string;
 * business_nature: string;
 * assign_to_employee: string;
 * submission_date: string;
 * request_from: string;
 * presently_insured: string;
 * description: string;
 * id: string;
 * name: string;
 * gender: string;
 * contact: string;
 * email: string;
 * type: string;
 * date_of_birth:string;
 * rg_no: string;
 * source:string;
 * source_ref:string;
 * extra_data:string;
 * }} data
 */
export const formatInquiryViewData = (data) => {
  console.log('viewData', data);
  if (data?.extra_data) {
    const res = { _: [] };
    Object.entries(data?.extra_data).forEach(([key, val]) => {
      res['_'].push({ label: key, value: val, name: key?.replaceAll('_', ' ') });
    });
    return res;
  } else {
    const res = {
      'Basic Information': [],
      'Insured Information': [],
      // Remarks: [],
    };
    if (!data) return res;

    Object.entries(data).forEach(([key, val]) => {
      switch (key) {
        case 'name':
        case 'primary_email_address':
        case 'contact_number':
        case 'duration':
        case 'insurance_period_from':
        case 'insurance_period_to':
        case 'close_date':
        case 'probability':
        case 'lead_source':
        case 'sales_stages':
        case 'employement':
        case 'next_step':
        case 'policy':
        case 'request_from':
        case 'renewal':
          res['Basic Information'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
          break;
        case 'p_insured':
          res['Basic Information'].push({
            label: key,
            value: val,
            name: 'Presently Insured',
          });
          break;
        case 'ins_cat_id':
          res['Basic Information'].push({
            label: key,
            // value: val?.map((item) => item?.name),
            value: `${val?.map((item) => item?.name)} , `,
            name: 'Insurance Category',
          });
          break;
        case 'insurer':
        case 'assigned_to':
          res['Basic Information'].push({
            name: 'Assigned To',
            label: key,
            value: val?.name,
          });
          break;
        case 'customer':
          res['Basic Information'].push({
            label: key,
            value: val?.name || val?.corp_name,
            name: key.replaceAll('_'),
          });
          break;
        case 'contact':
          res['Basic Information'].push({
            label: key,
            value: val?.name || val?.customer_name,
            name: key.replaceAll('_'),
          });
          break;
        case 'product_type':
          res['Basic Information'].push({
            label: key,
            value: `${val?.map((item) => item?.name)} , `,
            name: key.replaceAll('_', ' '),
          });
          break;

        case 'business_nature':
          res['Basic Information'].push({
            label: key,
            value: val,
            name: key.replaceAll('_', ' '),
            className: 'col-span-full',
          });
          break;
        case 'insurance_details':
          if (val) {
            Object.entries(val).forEach(([key, val]) => {
              res['Insured Information'].push({
                label: key,
                value: typeof val === 'string' ? val : val?.name,
                name: key.replaceAll('_', ' '),
              });
            });
          }
          break;
        default:
          break;
      }
    });
    return res;
  }
};

// leads formatter
export const formatLeadsViewData = (data) => {
  const res = {
    'Basic Information': [],
    'Additional Information': [],
    'Lead Source': [],
  };
  if (!data) return res;

  Object.entries(data).forEach(([key, val]) => {
    switch (key) {
      case 'name':
      case 'gender':
      case 'contact':
      case 'email':
      case 'type':
      case 'date_of_birth':
      case 'rg_no':
        res['Basic Information'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
        break;
      case 'extra_data':
        if (val) {
          Object.entries(val).forEach(([key, val]) => {
            res['Additional Information'].push({
              label: key,
              value: val,
              name: key.replaceAll('_', ' '),
            });
          });
        }
        break;
      case 'source':
        res['Lead Source'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
        break;
      case 'source_ref':
        if (val?.name) {
          res['Lead Source'].push({
            label: 'Name',
            value: val?.name,
            name: 'Name',
            type: 'link',
          });
        }
        break;
      default:
        break;
    }
  });
  return res;
  // }
};

export const tabData = [
  {
    label: 'manual',
    value: 'manual',
  },
  {
    label: 'pos',
    value: 'pos',
  },
  {
    label: 'online',
    value: 'online',
  },
];

export const items = [
  {
    key: 'draft',
    label: 'Draft',
  },
  {
    key: 'quoted',
    label: 'Quoted',
  },
  {
    key: 'proposals',
    label: 'Proposed',
  },
  {
    key: 'converted',
    label: 'Converted',
  },
  {
    key: 'losses',
    label: 'Losses',
  },
  {
    key: 'rejected',
    label: 'Rejected',
  },
];
