import React, { useState, useEffect, useRef } from 'react';
import { useStore } from 'store/context';
import { Box, Button, Input, ButtonsContainer, CallButton } from './atoms';

import SearchContact from './SearchContact';

const alphaBets = /[a-zA-Z]/;

const Dialer = () => {
  const [state, dispatch] = useStore();
  const [number, setNumber] = useState(state.contact || '');
  const inputRef = useRef();
  const buttons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];

  // ComponentDidMount & ComponentWillUnmount
  useEffect(() => {
    document.addEventListener('click', onOuterClick);
    return () => document.removeEventListener('click', onOuterClick);
  }, []);

  useEffect(() => {
    setNumber(state.contact);
  }, [state.contact]);

  const onOuterClick = () => {
    setNumber('');
    dispatch({ type: 'DIALER_CLOSE' });
  };

  const onDialerClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const onChange = (e) => {
    if ((e.target.value && alphaBets.test(e.target.value)) || e.target.value.length > 10) return;
    setNumber(e.target.value);
  };

  useEffect(() => {
    if (state.dialerOpened) {
      inputRef.current.focus();
    }
  }, [state.dialerOpened]);

  return (
    <Box opened={state.dialerOpened} onClick={onDialerClick}>
      <SearchContact setNumber={setNumber} />
      <div>
        <Input
          type='tel'
          ref={inputRef}
          placeholder='0000-0000'
          value={number}
          onChange={onChange}
        />

        <ButtonsContainer>
          {buttons.map((char) => (
            <Button key={char} onClick={() => setNumber(number + char)}>
              {char}
            </Button>
          ))}
        </ButtonsContainer>

        <CallButton>
          <a href={`tel:${number}`}>Call</a>
        </CallButton>
      </div>
    </Box>
  );
};

export default Dialer;
