import { DateTime, MarkDown, MultiSelect, SearchAPI, SelectInput, TextInput } from 'components';
// import { ACTIVITY_STATUS } from 'constants';
import { getContacts } from 'data/form-data';
import { getEmployees, searchApis } from 'service/api-service';

export const activityTypes = {
  MEETING: 'Meeting',
  TASK: 'Task',
};

export const createTaskFields = (
  props = {
    disableCustomer: true,
    custFieldName: 'customer_id',
  },
) => {
  return {
    'Create Task': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Subject',
          name: 'subject',
          id: 'subject',
          placeholder: 'Subject',
          type: 'text',
          required: true,
          component: TextInput,
          maxLength: 50,
        },
        {
          label: 'Assign to Employee',
          name: 'assign_to_employee',
          id: 'assign_to_employee',
          placeholder: 'Assign to Employee',
          // type: 'search',
          // component: SearchAPI,
          type: 'select',
          component: MultiSelect,
          paginatedSearch: true,
          extraLabelProps: 'emp_code',
          hideSelectedOptions: false,
          closeMenuOnSelect: false,
          fetchOptions: ({ query = '' }) => getEmployees({ query: `q=${query}` }),
          // fetchOptions: () => getEmployees(),
          required: true,
        },
        {
          label: 'From',
          name: 'from_date',
          id: 'from_date',
          placeholder: 'MM-DD-YYYY HH:mm',
          type: 'date',
          required: true,
          showTime: true,
          component: DateTime,
          disablePrev: true,
        },
        {
          label: 'To Date',
          name: 'to_date',
          id: 'to_date',
          placeholder: 'MM-DD-YYYY HH:mm',
          type: 'date',
          showTime: true,
          component: DateTime,
          disableCustomDate: 'from_date',
        },
        {
          label: 'Client Name',
          // name: props.custFieldName,
          name: 'customer_id',
          id: props.custFieldName,
          placeholder: 'Client Name',
          type: 'search',
          tooltip: 'You can select client if the task is related with a client',
          // required: true,
          component: SearchAPI,

          disabled: props.disableCustomer,
        },
        {
          label: 'Contact Name',
          name: 'contact_id',
          id: 'contact_id',
          placeholder: 'Contact Name',
          // type: "search",
          // component: SearchAPI,
          tooltip:
            'You can select contact if the task is related with a contact of selected customer',
          type: 'select',
          component: SelectInput,
          // dependency: [props.custFieldName],
          dependency: ['customer_id'],
          dependantFetchOptions: async (form) => {
            const customer = form?.values?.customer_id?.id || null;
            if (customer) {
              const resp = await getContacts(customer);
              if (resp) {
                return resp;
              } else return [];
            }
          },
        },
        {
          label: 'Description',
          name: 'description',
          id: 'description',
          placeholder: 'Add your Description here....',
          type: 'textarea',
          component: MarkDown,
          divClasses: 'col-span-full',
        },
      ],
    },
  };
};
export const createTaskSlicedFields = (
  props = {
    disableCustomer: true,
    custFieldName: 'customer_id',
  },
) => {
  return {
    'Create Task': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Subject',
          name: 'subject',
          id: 'subject',
          placeholder: 'Subject',
          type: 'text',
          required: true,
          component: TextInput,
          maxLength: 50,
        },
        {
          label: 'Assign to Employee',
          name: 'assign_to_employee',
          id: 'assign_to_employee',
          placeholder: 'Assign to Employee',
          // type: 'search',
          // component: SearchAPI,
          type: 'select',
          component: MultiSelect,
          paginatedSearch: true,
          extraLabelProps: 'emp_code',
          hideSelectedOptions: false,
          closeMenuOnSelect: false,
          fetchOptions: ({ query = '' }) => getEmployees({ query: `q=${query}` }),
          // fetchOptions: () => getEmployees(),
          required: true,
        },
        {
          label: 'From',
          name: 'from_date',
          id: 'from_date',
          placeholder: 'MM-DD-YYYY HH:mm',
          type: 'date',
          required: true,
          showTime: true,
          component: DateTime,
          disablePrev: true,
        },
        {
          label: 'To Date',
          name: 'to_date',
          id: 'to_date',
          placeholder: 'MM-DD-YYYY HH:mm',
          type: 'date',
          showTime: true,
          component: DateTime,
          disableCustomDate: 'from_date',
        },
        {
          label: 'Client Name',
          name: props.custFieldName,
          id: props.custFieldName,
          placeholder: 'Client Name',
          type: 'search',
          // required: true,
          component: SearchAPI,
          disabled: props.disableCustomer,
        },
        {
          label: 'Contact Name',
          name: 'contact_id',
          id: 'contact_id',
          placeholder: 'Contact Name',
          // type: "search",
          // component: SearchAPI,
          type: 'select',
          component: SelectInput,
          dependency: [props.custFieldName],
          dependantFetchOptions: async (form) => {
            const field = props.custFieldName;
            const customer = form?.values[field] || null;
            const resp = await getContacts(customer);
            if (resp) {
              return resp;
            } else return [];
          },
        },
        // {
        //   label: 'Status',
        //   name: 'status',
        //   id: 'status',
        //   placeholder: 'Select Status',
        //   type: 'select',
        //   component: SelectInput,
        //   required: true,
        //   options: ACTIVITY_STATUS,
        // },
      ],
    },
  };
};

export const createTaskInitials = {
  subject: '',
  from_date: '',
  to_date: '',
  status: '',
  customer_id: '',
  contact_id: '',
  description: '',
  assign_to_employee: '',
};

export const createMeetingFields = (
  props = {
    disableCustomer: true,
    custFieldName: 'customer_id',
  },
) => {
  let fields = [
    {
      label: 'Subject',
      name: 'subject',
      id: 'subject',
      placeholder: 'Subject',
      type: 'text',
      required: true,
      component: TextInput,
      maxLength: 50,
    },
    {
      label: 'Assign to Employee',
      name: 'assign_to_employee',
      id: 'assign_to_employee',
      placeholder: 'Assign to Employee',
      // type: 'search',
      // component: SearchAPI,
      type: 'select',
      component: MultiSelect,
      hideCheckBox: true,
      hideOptionAll: true,
      // component: AsyncMultiSelect,
      paginatedSearch: true,
      extraLabelProps: 'emp_code',
      hideSelectedOptions: false,
      closeMenuOnSelect: false,
      fetchOptions: ({ query = '' }) => getEmployees({ query: `q=${query}` }),
      // fetchOptions: () => getEmployees(),
      required: true,
    },
    {
      label: 'To Date',
      name: 'to_date',
      id: 'to_date',
      type: 'date',
      required: true,
      showTime: true,
      component: DateTime,
      placeholder: 'YYYY-MM-DD HH:mm',
      disablePrev: true,
    },
    // {
    //   label: 'Status',
    //   name: 'status',
    //   id: 'status',
    //   placeholder: 'Select Status',
    //   type: 'select',
    //   required: true,
    //   searchable: true,
    //   component: SelectInput,
    //   options: ACTIVITY_STATUS,
    // },
    {
      label: 'Activity type',
      name: 'activity_type',
      id: 'activity_type',
      placeholder: 'Activity type',
      type: 'select',
      searchable: true,
      component: SelectInput,
      options: [
        { label: 'Call', value: 'call' },
        { label: 'Meeting', value: 'meeting' },
        { label: 'Mobile Call', value: 'mobile_call' },
      ],
    },
    {
      label: 'Client Name',
      // name: props.custFieldName,
      name: 'customer_id',
      id: props.custFieldName,
      placeholder: 'Client Name',
      type: 'search',
      // required: true,
      component: SearchAPI,
      disabled: props.disableCustomer,
    },
    {
      label: 'Contact Name',
      name: 'contact_id',
      id: 'contact_id',
      placeholder: 'Contact name',
      // type: "search",
      // component: SearchAPI,
      type: 'select',
      component: SelectInput,
      // dependency: [props.custFieldName],
      dependency: ['customer_id'],
      dependantFetchOptions: async (form) => {
        const customer = form?.values?.customer_id?.id || null;
        const resp = await getContacts(customer);
        if (resp) {
          return resp;
        } else return [];
      },
    },
    {
      label: 'Description',
      name: 'description',
      id: 'description',
      placeholder: 'Add your Description here....',
      type: 'textarea',
      component: MarkDown,
      divClasses: 'col-span-full',
    },
  ];
  return {
    'Create Meeting': {
      showAccordionHeader: false,
      fields,
    },
  };
};
export const createMeetingSlicedFields = (
  props = {
    disableCustomer: true,
    custFieldName: 'customer_id',
  },
) => {
  let fields = [
    {
      label: 'Subject',
      name: 'subject',
      id: 'subject',
      placeholder: 'Subject',
      type: 'text',
      required: true,
      component: TextInput,
      maxLength: 50,
    },
    {
      label: 'Assign to Employee',
      name: 'assign_to_employee',
      id: 'assign_to_employee',
      placeholder: 'Assign to Employee',
      // type: 'search',
      // component: SearchAPI,
      type: 'select',
      component: MultiSelect,
      // component: AsyncMultiSelect,
      paginatedSearch: true,
      extraLabelProps: 'emp_code',
      hideSelectedOptions: false,
      closeMenuOnSelect: false,
      fetchOptions: ({ query = '' }) => getEmployees({ query: `q=${query}` }),
      // fetchOptions: () => getEmployees(),
      required: true,
    },
    {
      label: 'To Date',
      name: 'to_date',
      id: 'to_date',
      type: 'date',
      required: true,
      showTime: true,
      component: DateTime,
      placeholder: 'YYYY-MM-DD HH:mm',
      disablePrev: true,
    },
    // {
    //   label: 'Status',
    //   name: 'status',
    //   id: 'status',
    //   placeholder: 'Select Status',
    //   type: 'select',
    //   required: true,
    //   searchable: true,
    //   component: SelectInput,
    //   options: ACTIVITY_STATUS,
    // },
    {
      label: 'Activity type',
      name: 'activity_type',
      id: 'activity_type',
      placeholder: 'Activity type',
      type: 'select',
      searchable: true,
      component: SelectInput,
      options: [
        { label: 'Call', value: 'call' },
        { label: 'Meeting', value: 'meeting' },
        { label: 'Mobile Call', value: 'mobile_call' },
      ],
    },
    {
      label: 'Client Name',
      name: props.custFieldName,
      id: props.custFieldName,
      placeholder: 'Client Name',
      type: 'search',
      // required: true,
      component: SearchAPI,
      disabled: props.disableCustomer,
    },
    {
      label: 'Contact Name',
      name: 'contact_id',
      id: 'contact_id',
      placeholder: 'Contact name',
      type: 'select',
      // component: SearchAPI,
      component: SelectInput,
      dependency: [props.custFieldName],
      dependantFetchOptions: async (form) => {
        if (!form?.values?.props.custFieldName) return [];
        const customer = form?.values?.props.custFieldName;
        const resp = await searchApis({ api: 'contact', customer_id: customer });
        if (resp) {
          return resp;
        } else return [];
      },
    },
  ];
  return {
    'Create Meeting': {
      showAccordionHeader: false,
      fields,
    },
  };
};

export const createMeetingInitials = {
  subject: '',
  to_date: '',
  assign_to_employee: '',
  status: '',
  activity_type: '',
  contact_id: '',
  customer_id: '',
  description: '',
};
