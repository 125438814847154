import { SearchAPI, TextInput } from 'components';

const addNonPerformingEmployeesFields = {
  nonPerformingEmployees: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Employee',
        name: 'emp_id',
        id: 'emp_id',
        placeholder: 'Select Employee',
        type: 'select',
        required: true,
        component: SearchAPI,
        divClasses: 'col-span-full',
      },
      {
        label: 'Reason',
        name: 'reason',
        id: 'reason',
        placeholder: 'Reason',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        rows: 5,
        divClasses: 'col-span-full',
      },
      {
        label: 'Note',
        name: 'note',
        id: 'note',
        placeholder: 'Note',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        rows: 5,
        divClasses: 'col-span-full',
      },
    ],
  },
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Non Performing Employees',
    path: '',
  },
];

export { addNonPerformingEmployeesFields, breadcrumbObj };
