import { Button, Modal, Form, Input } from 'antd';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ShareInvoices = (props) => {
  const { createPayload } = props;
  const navigate = useNavigate();

  const [isShown, setIsShown] = useState();

  const toggle = useCallback(() => {
    setIsShown((s) => !s);
  }, []);

  const onFinish = (values) => {
    setIsShown(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <div className='flex flex-col '>
        <Button className='bg-red-90 text-white' onClick={toggle}>
          Share On Email
        </Button>
        <Button
          className='bg-green-90 text-white'
          onClick={() => {
            createPayload();
          }}
        >
          Share On WhatsApp
        </Button>
        <Button
          className='bg-grey-90  text-white'
          onClick={() => {
            createPayload();
          }}
        >
          Print
        </Button>
        <Button
          className='bg-grey-  text-black'
          onClick={() => {
            navigate('/invoices');
          }}
        >
          Back To Invoices
        </Button>

        <Modal open={isShown} onCancel={toggle} footer={null}>
          <div className='mt-12'>
            <Form
              name='basic'
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
            >
              <div className='mr-24'>
                <Form.Item
                  label='Send To'
                  name='sendTo'
                  rules={[
                    {
                      required: true,
                      message: 'Please put the mail address !!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label='Message' name='message'>
                  <Input.TextArea
                    autoSize={{
                      minRows: 3,
                      maxRows: 5,
                    }}
                  />
                </Form.Item>
              </div>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <div className='flex justify-end space-x-1'>
                  <Button type='default' htmlType='submit' onClick={toggle}>
                    Cancel
                  </Button>
                  <Button type='primary' htmlType='submit'>
                    Send
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ShareInvoices;
