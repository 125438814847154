import { useState } from 'react';

const { Space, Input, Select } = require('antd');
const { Option } = Select;

const SelectAfter = ({
  field,
  form,
  divClasses,
  disabled,
  defaultValue,
  label,
  name,
  id,
  type,
  placeholder,
  modifyValues,
  ...props
}) => {
  const [enterValue, setEnterValue] = useState('0');
  const [selectValue, setSelectvalue] = useState('Year');
  const [finalValue, setFinalValue] = useState('');

  const onSelect = (val) => {
    setSelectvalue(val);
    // setFinalValue(`${enterValue} ${selectValue}`)
    // if (form?.setFieldValue) return form.setFieldValue(field?.name, value);
  };

  const selectAfter = (
    <Select onSelect={onSelect} style={{ width: 100 }} defaultValue={selectValue}>
      <Option value=' Year'> Year</Option>
      <Option value=' Month'> Month</Option>
    </Select>
  );

  const onchange = (e) => {
    setEnterValue(e.target.value);

    // if (form?.setFieldValue) return form.setFieldValue(field?.name, value);
  };
  // useEffect(() => {
  //     setFinalValue(`${enterValue}${selectValue}`)
  //     if (form?.setFieldValue) return form.setFieldValue(field?.name, finalValue);
  // }, [enterValue, selectValue])
  return (
    <div className={`group relative  ${divClasses}`}>
      <label
        className={`group-focus-within:text-primary-90 block  text-xxs xl:text-xs text-black ${
          props?.required ? 'required' : ''
        } `}
      >
        {label}
      </label>
      <Space direction='vertical'>
        <Input
          id={field?.name}
          name={field?.name}
          placeholder={placeholder}
          addonAfter={selectAfter}
          onChange={onchange}
          className={` w-full text-xxs xl:text-xs p-2  group-focus-within:border-primary-90 inline-block ${
            disabled && 'border-0'
          }`}
          disabled={disabled}
          defaultValue={defaultValue}
        />
      </Space>

      {form?.errors[field?.name] && form?.touched[field?.name] && (
        <div
          className={`absolute bg-white ${
            props?.extraRightRender ? 'max-w-[230px]' : ''
          } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
        >
          {form?.errors[field?.name]}
        </div>
      )}
    </div>
  );
};

export default SelectAfter;
