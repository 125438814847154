/** external deps */
import React, { useContext, useState } from 'react';
import { InputNumber, Select } from 'antd';
/** internal deps */
import { DropdownIcon } from 'assets/icons';
import './style.css';
import { Context } from 'store/context';

const CurrencyInput = ({
  field,
  form,
  rightIcon,
  leftIcon,
  onIconClick,
  extraRightRender,
  InputClasses,
  divClasses,
  disabled,
  maxLength,
  ...props
}) => {
  const { Option } = Select;
  const { user } = useContext(Context);
  // const symbol = field?.value?.slice(0, 1) || user?.currency || "₹";
  // const number = field?.value?.slice(1) || "";

  const [currency, setCurrency] = useState('');
  const [value, setValue] = useState(field?.value);
  const _onChange = (i) => {
    setValue(i);
    if (form?.setFieldValue) return form.setFieldValue(field?.name, i);
    // if (form?.setFieldValue) return form.setFieldValue(field?.name, currency + i);
  };

  const addonBefore = (
    <Select
      onChange={(i) => {
        setCurrency(i);
        if (form?.setFieldValue) return form.setFieldValue(field?.name, i + value);
      }}
      defaultValue={'₹'}
      style={{ width: 50 }}
      dropdownAlign={{ offset: [0, 12] }}
      // showArrow={false}
      suffixIcon={<DropdownIcon svgClass={'w-2 group-focus-within:fill-primary-90'} />}
    >
      <Option value='₹'>₹</Option>
      <Option value='$'>$</Option>
      <Option value='S$'>S$</Option>
      <Option value='€'>€</Option>
      <Option value='£'>£</Option>
      <Option value='¥'>¥</Option>
    </Select>
  );

  return (
    <div id='currency-input' className={`group ${divClasses}`}>
      <label
        className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xxs text-black ${
          props?.required ? 'required' : ''
        }`}
      >
        {props.label}
      </label>
      <div className='relative'>
        <div
          onClick={onIconClick}
          className={`absolute inset-y-0 bg-white border border-[#D1EAF9] group-focus-within:border-primary-90  ${
            rightIcon && 'right-0 border-r px-3 rounded-r-md'
          } ${leftIcon && 'border-l px-3 rounded-l-full'} flex items-center cursor-pointer `}
        >
          {rightIcon || leftIcon}
        </div>

        <InputNumber
          type={'number'}
          value={value}
          name={field?.name || props?.name}
          maxLength={maxLength}
          disabled={disabled}
          addonBefore={addonBefore}
          className='w-full '
          // className={`${
          //   leftIcon ? "!pl-10" : ""
          // } border border-grey-90 text-xxs xl:text-xs rounded-md  block w-full p-1.5 xl:p-2 focus:outline-none focus:!border-primary-90
          // placeholder-grey-90 placeholder:text-grey-90 placeholder:text-xxs xl:placeholder:text-xs disabled:bg-grey-light disabled:border-grey-light disabled:cursor-not-allowed
          // ${InputClasses}`}
          onChange={_onChange}
          placeholder={props?.placeholder}
        />

        <div className={`absolute right-0 flex items-center cursor-pointer`}>
          {extraRightRender ? extraRightRender() : null}
        </div>

        {form?.errors[field?.name] && form?.touched[field?.name] && (
          <div
            className={`absolute bg-white ${
              extraRightRender ? 'max-w-[230px]' : ''
            } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
          >
            {form?.errors[field?.name]}
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrencyInput;
