import { Tooltip } from 'antd';
import { UserInitialIcon } from 'components';
import React, { useCallback } from 'react';

const PreviousRemarks = ({ data }) => {
  const renderList = useCallback(
    (item, index) => (
      <div key={index}>
        <div
          className={`grid grid-flow-col justify-start gap-4 my-1 items-center bg-grey-light p-2 rounded-md `}
        >
          <Tooltip title={item?.user_id}>
            <div>
              <UserInitialIcon name={item?.user_id} />
            </div>
          </Tooltip>
          <span className='break-all'> {item?.remarks}</span>
        </div>
      </div>
    ),
    [],
  );
  return (
    <div>
      {data && data?.remarks && (
        <div className='px-2'>
          <h2 className='font-medium text-xs'>Previous Remarks</h2>
          <div className='max-h-28 overflow-y-auto'>{data?.remarks?.map(renderList)}</div>
        </div>
      )}
    </div>
  );
};

export default PreviousRemarks;
