import { useEffect, useState } from 'react';
import { Button, InsurerSideBar } from 'components';
import { getCommission, getPosList } from 'service/api-service';
import AddCommission from './addCommission';
import CommissionTable from './CommissionTable';

const PosForm = (props) => {
  const { permissions } = props;
  const [checked, setChecked] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setAddModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [editInitials, setInitials] = useState();
  const [sideBarData, setSideBarData] = useState([]);
  const comm_all = tableData?.filter((item) => item.comm_set === 'pos_all');

  const callPos = async ({ q = '' } = {}) => {
    setLoading(true);
    const resp = await getPosList({ query: q });
    setSideBarData(resp?.data?.data?.result);
    setLoading(false);
  };

  useEffect(() => {
    callPos();
  }, []);

  useEffect(() => {
    const data = tableData.filter((item) => item?.pos?.id === +checked);
    setInitials(data[0]);
    setCardData(data);
    if (checked === 'pos_all') {
      setInitials(comm_all[0]);
    }
  }, [checked, tableData]);

  const toggleAddAsset = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  const getCommissionList = async () => {
    setLoading(true);
    const resp = await getCommission({ type: 'pos' });
    if (resp?.data?.status) {
      setLoading(false);
    }
    setTableData(resp?.data?.data || []);
  };

  useEffect(() => {
    getCommissionList();
  }, [tableData.length]);

  return (
    <>
      <div className='flex justify-between gap-4'>
        <InsurerSideBar
          checkbox={false}
          setAgent={setChecked}
          agent={checked}
          data={sideBarData}
          header='Agent'
          loading={loading}
          width={300}
          showSearch={true}
          onSearch={callPos}
        />
        <div className='w-full bordered bg-white relative rounded-lg max-h-[80vh]'>
          {props.tabs}
          <div className=' overflow-y-auto h-[360px]  p-4'>
            <h1
              className={`${
                checked === null ? 'text-grey-90 flex justify-center mt-20   ' : 'hidden'
              }`}
            >
              Please select any agent from left panel
            </h1>

            {checked !== null && (
              <Button
                classes={`z-10 absolute right-4 top-2`}
                onClick={toggleAddAsset}
                text='+ Commission'
                varient='primary'
              ></Button>
            )}

            {/* <div className='grid grid-cols-2 h-[60vh] overflow-y-auto'>
              {cardData?.map((item, i) => (
                <Commissioncard
                  key={item.id}
                  editInitials={item}
                  getCommissionList={getCommissionList}
                />
              ))}
            </div> */}

            {checked && (
              <CommissionTable
                permissions={permissions}
                loading={loading}
                setLoading={setLoading}
                data={cardData}
                refresh={getCommissionList}
                commissionSet='pos'
                id={+checked}
              />
            )}
            <AddCommission
              open={showAddModal}
              toggle={toggleAddAsset}
              setLoading={setLoading}
              loading={loading}
              editInitials={editInitials}
              refresh={getCommissionList}
              commissionSet='pos'
              id={+checked}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PosForm;
