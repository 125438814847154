import React, { useState } from 'react';

import { Checkbox, CustomModal, FormContainer, TextInput, Toast } from 'components';
import Button from 'components/button';
import { prepareFormFieldsData } from 'utils';
import { leaveManagementAdmin } from 'service/api-service';
import { broadcast } from 'utils/communication';
import { REFRESH_EVT } from '../data';
import { NOTIFICATION_MSG } from 'constants';

const fields = {
  approvedfields: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Enter note',
        name: 'note',
        id: 'note',
        placeholder: 'Enter a note',
        type: 'text-area',
        required: true,
        component: TextInput,
      },
      {
        label: 'Paid leave',
        name: 'paid',
        id: 'paid',
        type: 'checkbox',
        required: false,
        defaultChecked: true,
        checked: true,
        component: Checkbox,
      },
    ],
  },
};

const ConfirmModal = ({ open, onCancel, record, status }) => {
  const [loading, setLoading] = useState(false);

  const onApproved = async (data) => {
    setLoading(true);
    const resp = await leaveManagementAdmin({
      method: 'patch',
      id: record?.id,
      data: { status: 'approved', note: data?.note, paid: data?.paid },
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Leave Request Approved');
      broadcast(REFRESH_EVT);
    }
    setLoading(false);
    onCancel();
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: fields,
  });

  return (
    <CustomModal open={open} title='Are you sure' footer={null} onCancel={onCancel}>
      <div>
        <FormContainer
          // disableForm={true}
          accordionClass='grid  gap-8'
          onSubmit={onApproved}
          initialValues={initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, values }) => {
            return (
              <div className='bg-white flex flex-row justify-end'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                />
                <Button
                  disabled={!isValid}
                  //   permissions={permissions}
                  text={'Approve'}
                  variant='primary'
                  classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </div>
    </CustomModal>
  );
};

export default ConfirmModal;
