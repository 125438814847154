import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

import Template from './template';
import { Context } from 'store/context';
import { ContentHeader, Module, MultiEmail, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { getPermission } from 'utils';
import { elementData } from 'elements';
import { getLetterManagement } from 'service/api-service';

const LetterTemplateSetting = (props = {}) => {
  const permissions = props?.permissions || [];
  const { company } = useContext(Context);
  const [template, setTemplate] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formState, setFormSate] = useState({
    company_name: company?.name,
    company_address: company?.address,
  });
  const { id } = useParams();

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await getLetterManagement({ id });
    if (resp?.data?.status) {
      setTemplate(resp?.data?.data[0]?.template);
    } else Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [id]);

  const updateState = useCallback(
    (key) => (evt) => {
      let value = typeof evt === 'string' ? evt : evt.target ? evt.target.value : evt || '';
      setFormSate((s) => ({ ...s, [key]: value }));
    },
    [],
  );

  const print = useCallback(() => {}, []);

  if (loading) {
    return (
      <div className='flex items-center justify-center h-64'>
        <Spin />
      </div>
    );
  }
  return (
    <>
      {getPermission(permissions, elementData?.letter?.permissions?.view)?.write ? (
        <div className='grid gap-4'>
          <ContentHeader
            title={
              <>
                <Button
                  onClick={() => navigate(-1)}
                  type='text'
                  size='large'
                  className='-mt-2'
                  icon={<ArrowLeftOutlined />}
                />
                <h2 className='font-bold text-l'>{template?.name || 'Letter Template Settings'}</h2>
              </>
            }
            showSearch={false}
            children={
              <>
                <Button onClick={print} type='primary'>
                  Print
                </Button>
                <MultiEmail />
              </>
            }
          />
          <div className='bg-white h-full'>
            <Template
              template={template}
              fields={template?.fields || []}
              updateState={updateState}
              formState={formState}
            />
          </div>
        </div>
      ) : (
        <Module />
      )}
    </>
  );
};

export default LetterTemplateSetting;
