import {
  CustomFieldArray,
  DateTime,
  FormContainer,
  SelectInput,
  TextInput,
  Uploadbox,
} from 'components';
import { countries } from 'data/form-data';
import React, { useState } from 'react';
import { SubmitButtons } from 'screens/leads/data';
import { prepareFormFieldsData } from 'utils';
import { formStyle } from 'utils/common';
import './style.css';

const HealthForm = ({
  formData,
  setValues,
  insurer,
  setSelectedProduct,
  isEditing,
  submitProduct,
}) => {
  const [totalSon, setTotalSon] = useState(0);
  const [totalDaughter, setTotalDaughter] = useState(0);
  const [totalBrother, setTotalBrother] = useState(0);
  const [totalSister, setTotalSister] = useState(0);
  const [wantExit, setWantExit] = useState(false);

  const onSubmit = (data) => {
    submitProduct(data, wantExit);
  };

  const quoteCustomerFields = {
    'Health Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'You would like to insure',
          name: 'like_to_insure',
          id: 'like_to_insure',
          type: 'select',
          divcalsses: 'multiInsurer col-span-2',
          component: SelectInput,
          options: [
            {
              label: 'Self',
              value: 'self',
            },
            {
              label: 'Spouse',
              value: 'spouse',
            },
            {
              label: 'Son',
              value: 'son',
            },
            {
              label: 'Daughter',
              value: 'daughter',
            },
            {
              label: 'Brother',
              value: 'brother',
            },
            {
              label: 'Sister',
              value: 'sister',
            },
            {
              label: 'Father',
              value: 'father',
            },
            {
              label: 'Mother',
              value: 'mother',
            },
            // {
            //   label: "Grandfather",
            //   value: "grandfather",
            // },
            // {
            //   label: "Grandmother",
            //   value: "grandmother",
            // },
          ],
          // checkbox: true,
          isMulti: true,
          hideSelectedOptions: false,
          closeMenuOnSelect: false,
          menuPlacement: 'bottom',
          // divClasses: "col-span-3",
          // checkbox: false,
        },
        // spouse
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'spouse',
          name: 'spouse',
          label: 'Spouse',
          labelClass: 'text-xxs font-semibold text-black',
          type: 'fieldArray',
          id: 'spouse',
          addButtonText: '',
          valueSchema: { spouse: '' },
          component: CustomFieldArray,
          limit: 1,
          maxLimit: 1,
          divClasses: '  col-span-full',
          gridCols: 'grid-cols-6 py-2',
          fields: [
            {
              name: 'spouse_name',
              id: 'spouse_name',
              label: 'Name',
              divClasses: ' col-span-2',
              type: 'text',
              placeholder: 'Name',
              component: TextInput,
            },
            {
              name: 'spouse_age',
              id: 'spouse_age',
              label: 'Date of Birth',
              divClasses: ' col-span-2',
              type: 'date',
              placeholder: 'YYYY-MM-DD',
              component: DateTime,
            },
            {
              name: 'spouse_height',
              id: 'spouse_height',
              label: 'Height',
              type: 'text',
              placeholder: 'Height(in cm)',
              component: TextInput,
            },
            {
              name: 'spouse_weight',
              id: 'spouse_weight',
              label: 'Weight',
              type: 'text',
              placeholder: 'Weight(in kg)',
              component: TextInput,
            },
            {
              label: 'PED - pre existing disease if any  please share',
              name: 'spouse_illness',
              id: 'spouse_illness',
              type: 'textarea',
              isMultiline: true,
              rows: 2,
              divClasses: 'col-span-full',
              component: TextInput,
            },
          ],
        },
        // brother
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'brother',
          name: 'Brothers',
          type: 'fieldArray',
          id: 'brothers',
          addButtonText: '+ Brother',
          setlabel: 'Brother',
          valueSchema: totalBrother + totalSister <= 4 ? { brother: '' } : null,
          component: CustomFieldArray,
          buttonsClassName: 'items-end',
          removeButtonText: 'Remove',
          limit: totalBrother + totalSister,
          maxLimit: 4,
          divClasses: '  col-span-full',
          gridCols: 'grid-cols-6 py-2',
          customOnChange: ({ field, form }) => {
            const length = field?.value?.length;
            setTotalBrother(length);
          },
          fields: [
            {
              name: 'brother_name',
              id: 'brother_name',
              label: 'Name',
              divClasses: ' col-span-2',
              type: 'text',
              placeholder: 'Name',
              component: TextInput,
            },
            {
              name: 'brother_age',
              id: 'brother_age',
              label: 'Date of Birth',
              divClasses: ' col-span-2',
              type: 'date',
              placeholder: 'YYYY-MM-DD',
              component: DateTime,
            },
            {
              name: 'brother_height',
              id: 'brother_height',
              label: 'Height',
              type: 'text',
              placeholder: 'Height(in cm)',
              component: TextInput,
            },
            {
              name: 'brother_weight',
              id: 'brother_weight',
              label: 'Weight',
              type: 'text',
              placeholder: 'Weight(in kg)',
              component: TextInput,
            },
            {
              label: 'PED - pre existing disease if any  please share',
              name: 'brother_illness',
              id: 'brother_illness',
              type: 'textarea',
              isMultiline: true,
              rows: 2,
              divClasses: 'col-span-full',
              component: TextInput,
            },
          ],
        },
        // daughter
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'daughter',
          name: 'Daughter',
          type: 'fieldArray',
          id: 'daughter',
          setlabel: 'Daughter',
          buttonsClassName: 'items-end',
          removeButtonText: 'Remove',
          limit: totalSon + totalDaughter,
          maxLimit: 4,
          addButtonText: '+ Daughter',
          valueSchema: { daughter: '' },
          component: CustomFieldArray,
          divClasses: 'col-span-full',
          gridCols: 'grid-cols-6 py-2',
          customOnChange: ({ field, form }) => {
            const length = field?.value?.length;
            setTotalDaughter(length);
          },
          fields: [
            {
              name: 'daughter_name',
              id: 'daughter_name',
              label: 'Name',
              divClasses: ' col-span-2',
              type: 'text',
              placeholder: 'Name',
              component: TextInput,
            },
            {
              name: 'daughter_age',
              id: 'daughter_age',
              label: 'Date of Birth',
              divClasses: ' col-span-2',
              type: 'date',
              placeholder: 'YYYY-MM-DD',
              component: DateTime,
            },
            {
              name: 'daughter_height',
              id: 'daughter_height',
              label: 'Height',
              type: 'text',
              placeholder: 'Height(in cm)',
              component: TextInput,
            },
            {
              name: 'daughter_weight',
              id: 'daughter_weight',
              label: 'Weight',
              type: 'text',
              placeholder: 'Weight(in kg)',
              component: TextInput,
            },
            {
              label: 'PED - pre existing disease if any  please share',
              name: 'daughter_illness',
              id: 'daughter_illness',
              type: 'textarea',
              isMultiline: true,
              rows: 2,
              divClasses: 'col-span-full',
              component: TextInput,
            },
          ],
        },
        // sister
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'sister',
          name: 'Sister',
          type: 'fieldArray',
          id: 'sister',
          setlabel: 'Sister',
          buttonsClassName: 'items-end',
          removeButtonText: 'Remove',
          limit: totalBrother + totalSister,
          maxLimit: 4,
          addButtonText: '+ sister',
          valueSchema: totalBrother + totalSister <= 4 ? { sister: '' } : null,
          component: CustomFieldArray,
          divClasses: 'col-span-full',
          gridCols: 'grid-cols-6 py-2',
          customOnChange: ({ field, form }) => {
            const length = field?.value?.length;
            setTotalSister(length);
          },
          fields: [
            {
              name: 'sister_name',
              id: 'sister_name',
              label: 'Name',
              divClasses: ' col-span-2',
              type: 'text',
              placeholder: 'Name',
              component: TextInput,
            },
            {
              name: 'sister_age',
              id: 'sister_age',
              label: 'Date of Birth',
              divClasses: ' col-span-2',
              type: 'date',
              placeholder: 'YYYY-MM-DD',
              component: DateTime,
            },
            {
              name: 'sister_height',
              id: 'sister_height',
              label: 'Height',
              type: 'text',
              placeholder: 'Height(in cm)',
              component: TextInput,
            },
            {
              name: 'sister_weight',
              id: 'sister_weight',
              label: 'Weight',
              type: 'text',
              placeholder: 'Weight(in kg)',
              component: TextInput,
            },
            {
              label: 'PED - pre existing disease if any  please share',
              name: 'sister_illness',
              id: 'sister_illness',
              type: 'textarea',
              isMultiline: true,
              rows: 2,
              divClasses: 'col-span-full',
              component: TextInput,
            },
          ],
        },
        // son
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'son',
          name: 'son',
          type: 'fieldArray',
          id: 'son',
          setlabel: 'Son',
          buttonsClassName: 'items-end',
          removeButtonText: 'Remove',
          limit: totalDaughter + totalSon,
          maxLimit: 4,
          addButtonText: '+ Son ',
          valueSchema: { son: '' },
          component: CustomFieldArray,
          divClasses: 'col-span-full',
          gridCols: 'grid-cols-6 py-2',
          customOnChange: ({ field, form }) => {
            const length = field?.value?.length;
            setTotalSon(length);
          },
          fields: [
            {
              name: 'son_name',
              id: 'son_name',
              label: 'Name',
              divClasses: ' col-span-2',
              type: 'text',
              placeholder: 'Name',
              component: TextInput,
            },
            {
              name: 'son_age',
              id: 'son_age',
              label: 'Date of Birth',
              divClasses: ' col-span-2',
              type: 'date',
              placeholder: 'YYYY-MM-DD',
              component: DateTime,
            },
            {
              name: 'son_height',
              id: 'son_height',
              label: 'Height',
              type: 'text',
              placeholder: 'Height(in cm)',
              component: TextInput,
            },
            {
              name: 'son_weight',
              id: 'son_weight',
              label: 'Weight',
              type: 'text',
              placeholder: 'Weight(in kg)',
              component: TextInput,
            },
            {
              label: 'PED - pre existing disease if any  please share',
              name: 'son_illness',
              id: 'son_illness',
              type: 'textarea',
              isMultiline: true,
              rows: 2,
              divClasses: 'col-span-full',
              component: TextInput,
            },
          ],
        },
        // mother
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'mother',
          name: 'mother',
          label: 'Mother',
          labelClass: 'text-xxs font-semibold text-black',
          type: 'fieldArray',
          id: 'mother',
          addButtonText: '',
          valueSchema: { mother: '' },
          component: CustomFieldArray,
          limit: 1,
          maxLimit: 1,
          divClasses: '  col-span-full',
          gridCols: 'grid-cols-6 py-2',
          fields: [
            {
              name: 'mother_name',
              id: 'mother_name',
              label: 'Name',
              divClasses: ' col-span-2',
              type: 'text',
              placeholder: 'Name',
              component: TextInput,
            },
            {
              name: 'mother_age',
              id: 'mother_age',
              label: 'Date of Birth',
              divClasses: ' col-span-2',
              type: 'date',
              placeholder: 'YYYY-MM-DD',
              component: DateTime,
            },
            {
              name: 'mother_height',
              id: 'mother_height',
              label: 'Height',
              type: 'text',
              placeholder: 'Height(in cm)',
              component: TextInput,
            },
            {
              name: 'mother_weight',
              id: 'mother_weight',
              label: 'Weight',
              type: 'text',
              placeholder: 'Weight(in kg)',
              component: TextInput,
            },
            {
              label: 'PED - pre existing disease if any  please share',
              name: 'mother_illness',
              id: 'mother_illness',
              type: 'textarea',
              isMultiline: true,
              rows: 2,
              divClasses: 'col-span-full',
              component: TextInput,
            },
          ],
        },
        // father
        {
          dependentArray: true,
          dependent_on: 'like_to_insure',
          dependent_value: 'father',
          name: 'father',
          label: 'Father',
          labelClass: 'text-xxs font-semibold text-black',
          type: 'fieldArray',
          id: 'father',
          addButtonText: '',
          valueSchema: { father: '' },
          component: CustomFieldArray,
          limit: 1,
          maxLimit: 1,
          divClasses: '  col-span-full',
          gridCols: 'grid-cols-6 py-2',
          fields: [
            {
              name: 'father_name',
              id: 'father_name',
              label: 'Name',
              divClasses: ' col-span-2',
              type: 'text',
              placeholder: 'Name',
              component: TextInput,
            },
            {
              name: 'father_age',
              id: 'father_age',
              label: 'Date of Birth',
              divClasses: ' col-span-2',
              type: 'date',
              placeholder: 'YYYY-MM-DD',
              component: DateTime,
            },
            {
              name: 'father_height',
              id: 'father_height',
              label: 'Height',
              type: 'text',
              placeholder: 'Height(in cm)',
              component: TextInput,
            },
            {
              name: 'father_weight',
              id: 'father_weight',
              label: 'Weight',
              type: 'text',
              placeholder: 'Weight(in kg)',
              component: TextInput,
            },
            {
              label: 'PED - pre existing disease if any  please share',
              name: 'father_illness',
              id: 'father_illness',
              type: 'textarea',
              isMultiline: true,
              rows: 2,
              divClasses: 'col-span-full',
              component: TextInput,
            },
          ],
        },
      ],
    },
    'Address Details': {
      headerToHeading: true,
      fields: [
        {
          label: 'Country',
          name: 'country',
          id: 'country',
          placeholder: 'Country',
          type: 'select',
          divClasses: 'customCssForLead',
          // required: true,
          component: SelectInput,
          options: countries,
        },
        {
          label: 'State',
          name: 'state',
          id: 'state',
          placeholder: 'State',
          divClasses: 'customCssForLead',
          type: 'text',
          // required: true,
          component: TextInput,
          maxLength: 30,
        },
        {
          label: 'City/Town',
          name: 'city',
          id: 'city',
          placeholder: 'City/Town',
          divClasses: 'customCssForLead',
          type: 'text',
          // required: true,
          component: TextInput,
          maxLength: 30,
        },
        {
          label: 'Pincode',
          name: 'pincode',
          id: 'pincode',
          divClasses: 'customCssForLead',
          placeholder: 'Pincode',
          type: 'text',
          // required: true,
          component: TextInput,
          maxLength: 10,
        },
        {
          label: 'Address',
          name: 'address',
          id: 'address',
          placeholder: 'Address',
          type: 'text',
          // required: true,
          component: TextInput,
          isMultiline: true,
          maxLength: 60,
          divClasses: 'md:col-span-2 customCssForLead',
        },
      ],
    },
    'Nominee Details': {
      headerToHeading: true,
      fields: [
        {
          label: 'Name',
          name: 'nominee_name',
          id: 'nominee_name',
          placeholder: 'Name',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Nominee DOB',
          name: 'nominee_dob',
          id: 'nominee_dob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          component: DateTime,
        },
        {
          label: 'One id proof of proposer',
          name: 'proposer',
          id: 'proposer',
          divClasses: 'col-span-2',
          component: Uploadbox,
          dragger: false,
          accept: ['.png, .jpeg, .jpg'],
        },
        {
          label: 'In port case atleast 3 years previous policies copy',
          name: 'prev_copy',
          id: 'prev_copy',
          divClasses: 'col-span-2',
          component: Uploadbox,
          dragger: false,
          accept: ['.png, .jpeg, .jpg'],
        },
      ],
    },
    'Previous Insurance Details': {
      headerToHeading: true,
      showAccordionHeader: false,
      fields: [
        {
          label: 'Sum Insured',
          name: 'sum',
          id: 'sum',
          placeholder: 'Sum Insured',
          component: TextInput,
        },
        {
          label: 'Last Year Premium',
          name: 'premium',
          id: 'premium',
          placeholder: 'Last Year Premium',
          component: TextInput,
        },
        {
          label: 'Policy Inception Date',
          name: 'inception_date',
          id: 'inception_date',
          placeholder: 'Policy Inception Date',
          type: 'date',
          component: DateTime,
        },
        {
          label: 'Policy Expiry Date',
          name: 'p_expiry',
          id: 'p_expiry',
          placeholder: 'Policy Expiry Date',
          type: 'date',
          component: DateTime,
        },
        {
          label: 'PED(Pre Existing Disease)',
          name: 'ped',
          id: 'ped',
          placeholder: 'PED(Pre Existing Disease)',
          type: 'textarea',
          isMultiline: true,
          rows: 2,
          divClasses: 'col-span-full',
          component: TextInput,
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: quoteCustomerFields,
    defaultValues: formData,
  });
  return (
    <div id='insurer' className='bg-white'>
      <FormContainer
        returnFormatted={false}
        accordionClass={formStyle}
        fields={finalFields}
        initialValues={initialValues}
        formClasses=''
        onSubmit={onSubmit}
        customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, dirty }) => {
          return formData?.count ? (
            <></>
          ) : (
            <SubmitButtons
              show={true}
              classes='z-10'
              submit={submitForm}
              reset={resetForm}
              setWantExit={setWantExit}
            />
          );
        }}
      />
    </div>
  );
};

export default {
  Form: HealthForm,
};
