import { Checkbox, DateTime, SelectInput, TextInput, Toast } from 'components';
import Button from 'components/button';
import { NOTIFICATION_MSG } from 'constants';
import { getInsuranceCategory, searchInsuranceApis } from 'service/api-service';
import { useLeads } from './store';
import { useShallow } from 'zustand/react/shallow';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from 'store/context';

export const leadsBreadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Leads',
    path: '/create-leads',
  },
];

export const SideMenuData = [
  {
    module: 'Leads',
    value: 'leads',
    id: 1,
  },
  {
    module: 'Quotations',
    value: 'quotations',
    id: 2,
  },
  {
    module: 'Proposals',
    value: 'proposals',
    id: 3,
  },
  {
    module: 'Policy',
    value: 'policy',
    id: 4,
  },
];

export const responseOptions = [
  {
    label: 'Interested',
    name: 'interested',
    value: 'interested',
    id: 'interested',
  },
  {
    label: 'Not Interested',
    name: 'not_interested',
    value: 'not_interested',
    id: 'not_intersted',
  },
  {
    label: 'Already Insured',
    name: 'presently_insured',
    value: 'presently_insured',
    id: 'presently_insured',
  },
  {
    label: "Didn't Pick Up",
    name: 'not_pickup',
    value: 'not_pickup',
    id: 'not_pickup',
  },
  {
    label: 'In Discussion with Another Insurer',
    name: 'other',
    value: 'other',
    id: 'other',
  },
];

export const LabelValue = ({
  label,
  required = false,
  value,
  error,
  divClasses = '',
  fieldClass = '',
}) => (
  <div className={divClasses}>
    <div className={`border-1 text-xxs ${required ? ' required' : ''} `}>{label}</div>
    <div className={`flex-1 ${fieldClass}`}>
      {value}
      {error ? <div className='text-red'>{error}</div> : null}
    </div>
  </div>
);

export const ProductFields = ({
  setProduct,
  setInsurer,
  category,
  setcategory,
  insurerOptions,
  count,
}) => ({
  Product: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Category of Insurance',
        menuPlacement: 'bottom',
        name: 'insurance_category',
        id: 'insurance_category',
        placeholder: 'Category of Insurance',
        type: 'select',
        component: SelectInput,
        customOnChange: (field, val) => {
          setcategory(val);
        },
        required: true,
        fetchOptions: getInsuranceCategory,
      },
      {
        label: 'Product Type',
        name: 'product_type',
        id: 'product_type',
        placeholder: 'Product Type',
        type: 'select',
        required: true,
        menuPlacement: 'bottom',
        component: SelectInput,
        customOnChange: (field, val) => {
          setProduct(val);
        },
        dependency: ['insurance_category'],
        dependantFetchOptions: async (form) => {
          const id = form?.values?.insurance_category?.id || form?.values?.insurance_category;
          const resp = await searchInsuranceApis({ api: 'insurance_category', id });
          return resp?.data?.data[0]?.product_type?.map((i) => ({
            name: i.name,
            label: i.name,
            formId: i.formid,
            product_code: i.product_code,
            value: i.id,
            id: i.id,
          }));
        },
      },
      {
        label: 'Insurance Company',
        name: 'insurer',
        id: 'insurer',
        placeholder: 'Insurance Company',
        type: 'select',
        // this one
        component: SelectInput,
        divClasses: 'multiInsurer',
        getValues: (val) => {
          setInsurer(val);
        },
        menuPlacement: 'bottom',
        required: true,
        options: count ? insurerOptions : [],
        isMulti: true,
        dependency: ['insurance_category'],
        dependantFetchOptions: async () => {
          if (count) {
            return insurerOptions;
          } else {
            return category?.insurers?.map((item) => {
              return {
                label: item?.name,
                value: item?.id,
                id: item?.id,
                name: item?.name,
              };
            });
          }
        },
      },
    ],
  },
});

export const SubmitButtons = ({ show, submit, reset, classes, onlyNext = false }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useContext(Context);

  const { response, setButtonPressed, setIsOnlineProduct, onSubmitLead, client } = useLeads(
    useShallow((state) => ({
      response: state.createLead.response,
      client: state.createLead.client,
      setButtonPressed: state.setButtonPressed,
      onSubmitLead: state.onSubmitLead,
      setIsOnlineProduct: state.setIsOnlineProduct,
    })),
  );

  const handleSubmitLead = async ({ wantNavigate }) => {
    if (client?.id) {
      await onSubmitLead(navigate, params, user, wantNavigate);
    } else {
      Toast('info', NOTIFICATION_MSG.info, 'customer field is required');
    }
  };

  return (
    <div className={`flex  justify-end mt-4 gap-3 ${classes}`}>
      <Button
        text='Cancel'
        variant='primary'
        onClick={() => navigate('/lead')}
        type='button'
        classes='!bg-[#C3C3C3]  !text-black px-6 '
      />
      <Button
        disabled={!show}
        text={'Save & Add Product'}
        classes={` px-8 !bg-[#069FBE]`}
        onClick={() => {
          if (show) {
            setButtonPressed('add');
            submit();
            setTimeout(reset, 1000);
          } else {
            Toast('info', NOTIFICATION_MSG.info, 'Kindly Select Product First');
          }
        }}
      />
      <Button
        text={'Save & Exit'}
        variant='old-primary'
        classes={` px-8`}
        onClick={async () => {
          setIsOnlineProduct(false);
          if (show) {
            setButtonPressed('saveAndNext');
            submit();
          } else {
            handleSubmitLead({ wantNavigate: true });
          }
        }}
      />

      <Button
        text={'Next'}
        disabled={onlyNext ? false : !show}
        variant='primary'
        onClick={async () => {
          setIsOnlineProduct(false);
          if (show) {
            setButtonPressed('next');
            submit();
          }
          if (onlyNext) {
            handleSubmitLead({ wantNavigate: false });
            navigate(`/lead/${params.mode}/${params.id}/2`);
          }
        }}
        classes={` px-8 !bg-[#12B841] `}
      />
    </div>
  );
};

export const LogsButtons = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useContext(Context);

  const { response, onSubmitLead, followUpDate, client, renewalDate } = useLeads(
    useShallow((state) => ({
      response: state.createLead.response,
      client: state.createLead.client,
      followUpDate: state.createLead.followUpDate,
      renewalDate: state.createLead.renewalDate,
      setButtonPressed: state.setButtonPressed,
      onSubmitLead: state.onSubmitLead,
    })),
  );

  const handleSubmitLead = async ({ wantNavigate }) => {
    await onSubmitLead(navigate, params, user, wantNavigate);
  };

  return (
    <div className='flex justify-end'>
      <Button
        text='Cancel'
        variant='primary'
        onClick={() => navigate('/lead')}
        type='button'
        classes='!bg-[#C3C3C3]  !text-black px-6 '
      />
      <Button
        text={'Save & Exit'}
        disabled={
          !client?.id ? true : response === 'presently_insured' ? !renewalDate : !followUpDate
        }
        classes={`ml-4 px-8`}
        onClick={async () => {
          handleSubmitLead({ wantNavigate: true });
        }}
      />
    </div>
  );
};

export const createHtml = (data, { company, customer, companyEmail, sender, inq }) => {
  return `<html>
  <style>
    table {
      width: 100%;
      border: 1px solid #eee;
    }
    thead {
      background: #ddd;
      font-weight: bold;
    }
    td {
      border: 1px solid #eee;
      padding: 10px;
      margin-left :4px;

    }
    .cover{
      padding-top:30px;
    }
    th {
      border: 1px solid #eee;
      text-align: left;
      padding: 5px;
    }
  </style>
  <body>
    <p contenteditable="true">Hello, ${customer}</p>
    <br>
    <p contenteditable="true">Thank you for your interest in ${company}. In regards to your query, we are delighted to make you the following offers. You can
    directly buy any policy by simply click on BUT NOW link or you can contact with us on ${companyEmail}</p>
    <br>
    <h2>Quotation</h2>
    <br>
    <table>
      <thead>
        <tr>
          <th>Company</th>
          <th>Product</th>
          <th>Premium</th>
          <th>Coverage</th>
          <th>Coverage Amount</th>
          <th>Sum Insured</th>
          <th>NCB</th>
          <th>Document</th>
          <th>Link</th>
        </tr>
      </thead>
    <tbody>
      ${data
        .map(
          (d) =>
            `<tr>
          <td>${d.insurer}</td>
          <td>${d.product?.name}</td>
          <td>INR. ${Number(d.premium || 0).toFixed(2)}</td>
           <td class="cover" >${
             d?.cover?.length ? d?.cover.map((co) => `${co?.name}<br>`) : ''
           }</td>
          <td class="cover">${
            d?.cover?.length ? d?.cover.map((co) => `INR ${co?.amount}<br>`) : ''
          }</td>
          <td>INR. ${Number(d.sum || 0).toFixed(2)}</td>
          <td>${d.ncb || 0}%</td>
          <td><a href=${d?.document?.doc || ''} target="_blank">${d?.document?.name || ''}<a/></td>
          <td><a href="${window.location.origin}/buy-policy?inq=${inq}&quote=${d.id}&company=${
            d.insurer
          }&premium=${Number(d.premium || 0).toFixed(2)}">BUY</a></td>
        </tr>`,
        )
        .join(' ')}
    </tbody>
    </table>
    <br>
    <p>Please let us know if you require any further information. Please note that the Early Bird Discount is a limited time offer and will only be available until</p>
    <br>
    <p>We look forward to hearing from you.</p>
    <br>
    <p>Kind regards</p>
    <p>${sender}</p>
    <p>${company}</p>
  </body>
</html>`;
};

export const createOnlineHtml = (data, { company, customer, companyEmail, sender }) => {
  return `<html>
  <style>
    table {
      width: 100%;
      border: 1px solid #eee;
    }
    thead {
      background: #ddd;
      font-weight: bold;
    }
    td {
      border: 1px solid #eee;
      padding: 10px;
      margin-left :4px;

    }
    .cover{
      padding-top:30px;
    }
    th {
      border: 1px solid #eee;
      text-align: left;
      padding: 5px;
    }
  </style>
  <body>
    <p contenteditable="true">Hello, ${customer}</p>
    <br>
    <p contenteditable="true">Thank you for your interest in ${company}. In regards to your query, we are delighted to make you the following offers. You can
    directly buy any policy by simply click on BUT NOW link or you can contact with us on ${companyEmail}</p>
    <br>
    <h2>Quotation</h2>
    <br>
    <table>
      <thead>
        <tr>
          <th>Company</th>
          <th>Product</th>
          <th>Premium</th>
          <th>Coverage</th>
          <th>Coverage Amount</th>
          <th>Sum Insured</th>
          <th>NCB</th>
          <th>Document</th>
        </tr>
      </thead>
    <tbody>
      ${data
        .map(
          (d) =>
            `<tr>
          <td>${d.insurer}</td>
          <td>${d.product?.name}</td>
          <td>INR. ${Number(d.premium || 0).toFixed(2)}</td>
           <td class="cover" >${
             d?.cover?.length ? d?.cover.map((co) => `${co?.name}<br>`) : ''
           }</td>
          <td class="cover">${
            d?.cover?.length ? d?.cover.map((co) => `INR ${co?.amount}<br>`) : ''
          }</td>
          <td>INR. ${Number(d.sum || 0).toFixed(2)}</td>
          <td>${d.ncb || 0}%</td>
          <td><a href=${d?.document?.doc || ''} target="_blank">${d?.document?.name || ''}<a/></td>
        </tr>`,
        )
        .join(' ')}
    </tbody>
    </table>
    <br>
    <p>Please let us know if you require any further information. Please note that the Early Bird Discount is a limited time offer and will only be available until</p>
    <br>
    <p>We look forward to hearing from you.</p>
    <br>
    <p>Kind regards</p>
    <p>${sender}</p>
    <p>${company}</p>
  </body>
</html>`;
};

export const eligiblityFields = {
  'Eligiblity Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Education',
        name: 'education',
        id: 'education',
        type: 'text',
        required: true,
        component: SelectInput,
        options: [
          { label: 'Post Graduate', value: '676' },
          { label: 'Graduate', value: '680' },
          { label: '12th', value: '679' },
          { label: '10th', value: '678' },
          { label: 'Diploma', value: '675' },
          { label: 'Below 10th', value: '677' },
        ],
      },
      {
        label: 'Pincode',
        name: 'pincode',
        id: 'pincode',
        placeholder: 'Pincode',
        type: 'text',
        required: true,
        component: TextInput,
      },
    ],
  },
};
