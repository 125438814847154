import { Checkbox, DateTime, TextInput } from 'components';

export const STATUS_OPTIONS = [
  {
    label: 'Mark as draft',
    value: 'draft',
  },
  {
    label: 'Mark as Converted',
    value: 'converted',
  },
];
export const breadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'View Quotes',
    path: '',
    target: '_blank',
  },
];

export const addStatusFields = {
  Target: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Reminder Date',
        name: 'remind_date',
        id: 'remind_date',
        type: 'date',
        component: DateTime,
      },
      {
        label: 'Sold by another broker',
        name: 'sta',
        id: 'sta',
        type: 'checkbox',
        placeholder: 'Sold by another broker',
        component: Checkbox,
        divClasses: 'self-end',
      },

      {
        label: 'Other reason',
        name: 'other_reason',
        id: 'other_reason',
        type: 'checkbox',
        placeholder: 'Other reason',
        component: Checkbox,
        divClasses: 'self-end',
        updateClass: '!mt-0',
      },
      {
        label: '',
        name: 'remarks',
        id: 'remarks',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        placeholder: 'Please add some remarks here...',
        InputClasses: 'h-32',
      },
    ],
  },
};
