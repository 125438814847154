import { Button, Modal } from 'antd';
import React, { useRef } from 'react';
/**
 * wrapper around antd's modal that is primed for showing any document in it.
 * @param {import('antd').ModalProps & {doc: import('react').ObjectHTMLAttributes}} props
 */
function ViewDocumentInModal({ doc = {}, ...rest }) {
  const itemRef = useRef(null);
  const handleDownload = () => {
    if (itemRef.current?.data) {
      const downloadLink = document.createElement('a');
      downloadLink.href = itemRef.current.data;
      downloadLink.target = '__blank';
      downloadLink.download = 'attachment';
      downloadLink.click();
    } else {
      console.error('Content not found in the object tag.');
    }
  };
  return (
    <Modal
      {...rest}
      width='70vw'
      footer={null}
      bodyStyle={{ display: 'grid', placeContent: 'center' }}
      title={
        <header className='flex gap-4 justify-between items-center pr-10'>
          <p>{doc.title ?? 'Attachment'}</p>
          <Button onClick={handleDownload}>Download</Button>
        </header>
      }
    >
      <object {...doc} height='400px' ref={itemRef}>
        {doc.title ?? 'embedded document'}
      </object>
    </Modal>
  );
}

export default ViewDocumentInModal;
