import React from 'react';
import { TableComponent } from 'components';
import { rowCommonClass } from 'utils/common';

const columns = [
  {
    key: 'date',
    title: 'Date',
    dataIndex: 'date',
    render: (data) => data || '-',
  },
  {
    key: 'check_in',
    title: 'Check In',
    dataIndex: 'check_in',
    render: (data) => data || '-',
  },
  {
    key: 'check_out',
    title: 'Check Out',
    dataIndex: 'check_out',
    render: (data) => data || '-',
  },
  {
    key: 'late',
    title: 'Late Hours',
    dataIndex: 'late',
    render: (data) => {
      return <p>{`${(data / 3600).toFixed(2)}hr`}</p>;
    },
  },
  {
    key: 'overtime',
    title: 'Check Out',
    dataIndex: 'overtime',
    render: (data) => {
      return <p>{`${(data / 3600).toFixed(2)}hr`}</p>;
    },
  },
  {
    key: 'status',
    title: 'Status',
    render: (data) => {
      return <p className='capitalize'>{`${data?.status}`}</p>;
    },
  },
];

const PerformanceTable = ({ data }) => {
  return (
    <div className='flex flex-col bg-white p-2 min-h-[30vh]'>
      <h2 className='text-xl font-semibold mb-4'>Attendance Details</h2>
      <TableComponent
        dataSource={data?.data || []}
        pageSize={10}
        columns={columns}
        getRowClassName={rowCommonClass}
      />
    </div>
  );
};

export default PerformanceTable;
