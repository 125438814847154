/** external deps */
import React, { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { CustomModal, TableComponent, Button, ContentHeader } from 'components';
// import AddQuote from "./add-quote";
import { Space } from 'antd';
import { EDIT, Trash, View } from 'assets/icons';
import { IconButton } from 'components';
import { QuoteTableColumns } from 'data/form-data';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'utils';
import { elementData } from 'elements';
import { quotesManagement } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const Quote = ({ selectedData, employee_id, customer, permissions = {} }) => {
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const navigate = useNavigate();

  const handleViewClick = (record) => {
    selectedData(record);
  };

  const fetchQuotes = async ({ page = 1 }) => {
    setLoading(true);
    const res = await quotesManagement({
      method: 'get',
      employee_id,
      page,
      customer_id: customer?.id,
    });
    if (res) {
      setDataSource(res?.data?.data?.result || []);
    }
    setLoading(false);
  };

  const toggleDeleteModal = () => {
    setDeleteModal((pre) => !pre);
    setLoading(false);
  };
  const handleDeleteQuote = () => {
    setDeleteModal((pre) => !pre);
  };

  // const handleEditClick = (record) => {};

  const handleSearch = debounce(async (e) => {
    const query = e?.target?.value;
    if (!query) {
      return setSearchQuery('');
    }
    setLoading(true);
    setSearchQuery(query);
    const fuse = new Fuse(dataSource, {
      keys: ['category.name', 'employee.name', 'subject'],
      threshold: 0.2,
    }).search(query);
    setFilteredData(fuse.map((i) => i.item));
    setLoading(false);
  }, 700);

  const handlePagination = (page) => fetchQuotes({ page });

  useEffect(() => {
    fetchQuotes({});
  }, []);

  const quotesBreads = [
    {
      name: 'Dashboard',
      path: '/',
    },
    {
      name: 'Quotes',
      path: '/quotes',
    },
  ];

  return (
    <main>
      <div className='mb-2'>
        <ContentHeader
          buttonId={elementData.Quote.permissions.create}
          permissions={permissions}
          data={quotesBreads}
          btnText='+ Quote'
          onClick={() => {
            navigate('/add-quote', { state: { customer } });
          }}
          onChange={handleSearch}
        />
      </div>
      <TableComponent
        getRowClassName={rowCommonClass}
        columns={[
          ...QuoteTableColumns,
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
              <Space size='middle'>
                <IconButton
                  id={elementData.Quote.permissions.view}
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  onClick={handleViewClick.bind(null, record)}
                >
                  <View svgClass='stroke-primary group-disabled:stroke-grey' />
                </IconButton>

                <IconButton
                  id={elementData.Quote.permissions.update}
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  // onClick={handleEditClick.bind(null, record)}
                  onClick={() => {
                    navigate(`/edit-quote`, {
                      state: { initialValues: record },
                    });
                  }}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
              </Space>
            ),
          },
        ]}
        dataSource={searchQuery ? filteredData : dataSource}
        loading={loading}
        onChange={handlePagination}
      />

      {/* delete confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the Quote details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              text='Delete Quote'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={handleDeleteQuote}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </main>
  );
};

export default Quote;
