import { DownloadOutlined } from '@ant-design/icons';
import { Loader } from 'components';
import React, { useState } from 'react';

const FileDownloader = ({ url }) => {
  const [loading, setLoading] = useState(false);
  const downloadContract = () => {
    setLoading(true);
    window.open(process.env.REACT_APP_DOC_URL + url, '_blank');
    // fetch(url, { mode: "no-cors" }).then((response) => {
    //   response.blob().then((blob) => {
    //     // Creating new object of PDF file
    //     const fileURL = window.URL.createObjectURL(blob);
    //     // Setting various property values
    //     let alink = document.createElement("a");
    //     alink.href = fileURL;
    //     alink.download = "SamplePDF.pdf";
    //     alink.click();
    //   });
    setLoading(false);
    // });
  };

  if (!url || loading) {
    return <Loader size={50} />;
  }
  return (
    <div
      className='w-4/5 h-4/5 bg-black bg-opacity-5 rounded-xl flex justify-center'
      style={{ alignItems: 'center' }}
      onClick={downloadContract}
    >
      <DownloadOutlined style={{ fontSize: '100px', opacity: '0.5' }} />
    </div>
  );
};

export default FileDownloader;
