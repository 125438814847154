// import { Image } from "antd";

// import { DocumentIcon, DownloadIcon } from "assets/icons";
// import { formatBytes } from "utils";

const baseDocUrl = process.env.REACT_APP_DOC_URL;

const FileShow = ({ type, msg, doc_url, doc_size, received }) => {
  doc_url = received ? doc_url : `${baseDocUrl}${doc_url}`;
  // if (type === "image") {
  //   return (
  //     <div className="grid gap-2 max-h-24">
  //       <Image
  //         rootClassName="rounded-md overflow-hidden"
  //         height={100}
  //         src={doc_url}
  //         placeholder={
  //           <></>
  //           // <Image
  //           //   preview={false}
  //           //   src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
  //           //   width={200}
  //           // />
  //         }
  //       />
  //     </div>
  //   );
  // }

  // if (type === "video") {
  //   return (
  //     <div className="h-20">
  //       <video
  //         src={doc_url}
  //         controls
  //         className="rounded-md max-h-24"
  //         title={msg}
  //       />
  //     </div>
  //   );
  // }

  if (type === 'docs') {
    return (
      <a href={doc_url} className='group flex gap-4 w-60 justify-between items-center'>
        <div className='flex gap-4'>
          {/* <DocumentIcon classes="w-6" /> */}
          <div className='text-left text-xs'>
            <p className='font-semibold'>{msg}</p>
            {/* <p className="">Size - {formatBytes(doc_size)}</p> */}
          </div>
        </div>
        <div download className='w-6'>
          {/* <DownloadIcon classes="fill-secondary-70 group-hover:fill-secondary transition-all duration-500" /> */}
        </div>
      </a>
    );
  }
  return <>unknown</>;
};

export default FileShow;
