/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Spin, Upload } from 'antd';
/** internal deps */
import { SecureComponent } from 'permission-provider';
import { ContentHeader, IconButton, Toast } from 'components';
import { breadCrumbs } from './data';
import { elementData } from 'elements';
import { EDIT, UploadIcon } from 'assets/icons';
import { addPosContract, editPosContract, getPosContract } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import './style.css';

const { Dragger } = Upload;

const PosContract = (props = {}) => {
  const permissions = props?.permissions || {};
  const [conData, setData] = useState();
  const [file, setFile] = useState();
  const [file64, setFile64] = useState();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const beforeUpload = (file) => {
    setFile(file);
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      setFile64(target?.result);
    };
    reader.readAsDataURL(file);
    return false;
  };

  const handleChange = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-2);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);
  };
  const fetchData = useCallback(async () => {
    const resp = await getPosContract();
    if (resp?.data?.status) {
      const data = resp?.data?.data[0];
      setData(data);
      if (data) {
        const file = [
          {
            uid: data?.id,
            name: data?.name,
            status: 'done',
            url: process.env.REACT_APP_DOC_URL + data?.contract,
          },
        ];
        setFileList(file);
      }
      if (resp?.data?.data.length > 0) {
        const elements = Array.from(document.getElementsByClassName('ant-upload-drag'));
        elements.length > 0 && elements[0].classList.add('hide');
      }
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddContract = useCallback(async () => {
    setLoading(true);

    const id = conData?.id;
    const payload = {
      contract: file64,
      name: file?.name,
    };
    const resp = id
      ? await editPosContract({ data: payload, id })
      : await addPosContract({ data: payload });
    if (resp?.data?.status) {
      Toast(
        'success',
        NOTIFICATION_MSG.success,
        `Contract ${conData ? 'updated' : 'created'} successfully`,
      );
      fetchData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, [conData, file, file64]);

  const UploadListItem = ({ originNode, file }) => {
    return (
      <div className='relative m-auto' id='uploaded-custom-list'>
        <div>{file.status === 'error' ? originNode.props.children : originNode}</div>
        <IconButton
          title='Edit'
          permissions={permissions}
          className='group disabled:cursor-not-allowed absolute top-5 right-2 cursor-pointer'
          id={elementData.posContract.permissions.update}
        >
          <Upload
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            accept={['.pdf', '.docx']}
            maxCount={1}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </Upload>
        </IconButton>
      </div>
    );
  };

  if (loading) {
    return (
      <div className='flex items-center justify-center h-64'>
        <Spin />
      </div>
    );
  }
  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        id={elementData?.posContract}
        showSearch={false}
        // data={breadCrumbs}
        title={<p className='font-semibold text-s'>Pos Contract</p>}
        btnText={'Save'}
        onClick={handleAddContract}
      />
      <div className={`bg-white rounded-lg px-6 pt-3 ${conData ? 'pb-20' : 'pb-3'}`}>
        <div className='grid gap-6' id='pos-contract'>
          <Dragger
            accept={['.pdf', '.docx']}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            fileList={fileList}
            listType='picture'
            maxCount={1}
            itemRender={(originNode, file) => (
              <UploadListItem originNode={originNode} file={file} />
            )}
          >
            <input type='hidden' />
            <div>
              <div className='flex justify-center pb-1'>
                <UploadIcon svgClass='w-5 h-5' />
              </div>
              <div>
                <p className='!text-x'>
                  Drag and drop your file here or <span className='text-primary-90'>Browse</span>
                </p>
                <p className='text-grey-80 !text-x'>Maximum upload size 10 MB.</p>
              </div>
            </div>
          </Dragger>
        </div>
      </div>
    </div>
  );
};

export default SecureComponent(PosContract);
