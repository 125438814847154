import { SelectInput, TextInput } from 'components';

const addTaxFields = {
  tax: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Tax Name',
        name: 'tax_name',
        id: 'tax_name',
        placeholder: 'Tax Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Tax Percentage(%)',
        name: 'tax_percentage',
        id: 'tax_percentage',
        placeholder: 'Tax Percentage(%)',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Status',
        name: 'status',
        id: 'status',
        placeholder: 'Select Status',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { value: 'Active', label: 'Active' },
          { value: 'Inactive', label: 'Inactive' },
        ],
      },
    ],
  },
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Tax',
    path: '',
  },
];

export { addTaxFields, breadcrumbObj };
