/** external deps */
import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

/** internal deps */
import { Button, ContentHeader } from 'components';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { Context } from 'store/context';
import { debounce } from 'utils';
import AssignModal from '../inquiry/AssignModal';
import { items } from './data';
import LeadTable from '../inquiry/LeadTable';
import { searchApis } from 'service/api-service';

const Leads = ({ permissions = [], customer }) => {
  const {
    leads: { list: leads, updateLeads, pageNum },
  } = useContext(Context);

  const [tabKey, setTabKey] = useState('draft');
  const [selectedValue, setSelectedValue] = useState();
  const [employeedata, setEmployeeeData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const { id: customer_id } = useParams();

  const navigate = useNavigate();

  /** funcs */

  const getEmployeeData = async () => {
    const resp = await searchApis({ api: 'employee' });
    const data = resp?.data?.data?.map((contact) => ({
      value: contact.id,
      label: contact.name,
      name: contact.name,
      id: contact.id,
    }));
    setEmployeeeData(data);
    const response = await searchApis({ api: 'insurance_category' });
    setAllCategories(response?.data?.data);
    const dataCategory = response?.data?.data.map((category) => ({
      value: category.id,
      label: category.name,
      name: category.name,
      id: category.id,
    }));
    setCategoryData(dataCategory);
  };
  useEffect(() => {
    getEmployeeData();
  }, []);

  useEffect(() => {
    const data = allCategories.filter((option) => selectedValue?.insurance_category === option.id);
    const products = data[0]?.product_type?.map((product) => ({
      value: product.id,
      label: product.name,
      name: product.name,
      id: product.id,
    }));
    setProductData(products);
  }, [selectedValue?.insurance_category]);

  const handleSearch = debounce(async (e) => {
    const query = e?.target?.value;
    await updateLeads({ pageNum: 1, query, customer_id, status: tabKey });
  }, 700);

  const handleAddClick = () => {
    navigate('/lead/create/new/1', {
      state: {
        customer,
      },
    });
  };

  const onchangeTab = (key) => {
    setTabKey(key);
    updateLeads({ pageNum, customer_id, status: key });
  };

  useEffect(() => {
    updateLeads({ customer_id });
  }, []);

  useEffect(() => {
    if (selectedValue)
      updateLeads({
        pageNum,
        customer_id,
        employee_id: selectedValue?.employee_id,
        category_id: selectedValue?.insurance_category,
        products: selectedValue?.products,
        status: tabKey,
      });
  }, [selectedValue]);

  return (
    <main className='bg-white rounded-lg py-4 pr-4 mt-4'>
      <ContentHeader
        permissions={permissions}
        onChange={handleSearch}
        btnText='+ Create New Lead'
        buttonId={elementData.Leads.permissions.create}
        onClick={handleAddClick}
      />
      {/* <div className='bg-white p-2 pb-0'>{tabData.map(renderTab)}</div> */}
      <Tabs
        onChange={onchangeTab}
        items={items}
        defaultActiveKey={tabKey}
        className='bg-white pl-4'
        tabBarExtraContent={
          <div className='flex gap-2 items-center'>
            <Select
              allowClear={{ clearIcon: <CloseOutlined /> }}
              onClear={() => {
                setSelectedValue({
                  ...selectedValue,
                  employee_id: '',
                });
              }}
              style={{ width: 150 }}
              value={selectedValue?.employee_id}
              placeholder={'Select Asignee'}
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  employee_id: e,
                });
              }}
              // onChange={filterTable}
              showSearch={true}
              options={employeedata}
              className='cursor-pointer'
            />
            <Select
              allowClear={{ clearIcon: <CloseOutlined /> }}
              onClear={() => {
                setSelectedValue({
                  ...selectedValue,
                  insurance_category: '',
                });
              }}
              style={{ width: 150 }}
              value={selectedValue?.insurance_category}
              placeholder={'Select Category'}
              onChange={(e) => {
                setSelectedValue({
                  ...selectedValue,
                  insurance_category: e,
                });
              }}
              showSearch={true}
              options={categoryData}
              className='cursor-pointer'
            />
            {selectedValue?.insurance_category && (
              <Select
                allowClear={{ clearIcon: <CloseOutlined /> }}
                onClear={() => {
                  setSelectedValue({
                    ...selectedValue,
                    products: '',
                  });
                }}
                style={{ width: 150 }}
                value={selectedValue?.products}
                placeholder={'Select Product'}
                onChange={(e) => {
                  setSelectedValue({
                    ...selectedValue,
                    products: e,
                  });
                }}
                showSearch={true}
                className='cursor-pointer'
                options={productData}
              />
            )}

            {(selectedValue?.employee_id ||
              selectedValue?.insurance_category ||
              selectedValue?.products) && (
              <Button
                text='Clear'
                type='reset'
                // classes="border border-gray-90 text-black cursor-pointer"
                onClick={() => {
                  setSelectedValue({});
                }}
              />
            )}

            <Button
              text='Advanced Assignment'
              type='button'
              onClick={() => {
                setOpenAssignModal(true);
              }}
            />
          </div>
        }
      />
      <LeadTable
        permissions={permissions}
        customer_id={customer_id}
        inquiryMethod={'manual'}
        selectedValue={selectedValue}
        tabKey={tabKey}
      />

      <AssignModal
        open={openAssignModal}
        allCategories={allCategories}
        onCancel={() => setOpenAssignModal(false)}
      />
    </main>
  );
};

export default SecureComponent(Leads);
