/** external deps */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

/** internal deps */
import { DELETE, EDIT, Permission, Trash, View } from 'assets/icons';
import { EmployeeFields, addEmployeeFormFields, breadcrumbObj } from './data';
import {
  Button,
  CustomModal,
  IconButton,
  ContentHeader,
  TableComponent,
  Toast,
  MultiEmail,
  User,
  MultiUserInitial,
  Filter,
  MultiFilter,
  DrawerTabsForm,
  Loader,
} from 'components';
import { addEmployee, deleteEmployee, updateEmployee } from 'service/api-service';
import {
  debounce,
  deleteEmptyValues,
  formatAddress,
  getEditedFields,
  prepareFormFieldsData,
} from 'utils';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { Context, useStore } from 'store/context';
import { NOTIFICATION_MSG } from 'constants';
import { TableStatus } from 'components/table/TableStatus';
import './index.css';
import { sortByKeys } from 'screens/customers/data';
import dayjs from 'dayjs';
import useBankDetails from 'data/form-data/ifsc';
import { AddEditEmployee } from 'screens';
import { rowCommonClass } from 'utils/common';

export const EMPLOYEE_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const EmployeeStatusList = [
  {
    label: <span className='w-full text-left'>Active</span>,
    key: EMPLOYEE_STATUS.ACTIVE,
  },
  {
    label: <span className='w-full text-left'>InActive</span>,
    key: EMPLOYEE_STATUS.INACTIVE,
  },
];

const MIN_FIELDS = 5;
const MAX_FIELDS = 800;
const pageSize = 10;
const maxDate = dayjs().subtract(18, 'years');

const Employees = (props = {}) => {
  const permissions = props?.permissions || [];
  /** vars */
  let navigate = useNavigate();
  const [pincodeError, setPinCodeError] = useState();
  const [ifscCode, setIfscCode] = useState();
  const [initialData, setInitialData] = useState([]);
  const { bankDetails, error } = useBankDetails(ifscCode);

  const {
    user,
    employees: { list: employees, updateEmployees, total, loading: employeesLoading, pageNum },
  } = useContext(Context);

  const [opened, dispatch] = useStore((state) => state.dialerOpened);
  const toggleDialer = useCallback(
    (e, contact) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (opened) {
        dispatch({ type: 'DIALER_CLOSE' });
      } else {
        dispatch({ type: 'DIALER_OPEN', contact });
      }
    },
    [opened, dispatch],
  );

  const columns = [
    {
      title: 'Employee Code',
      dataIndex: 'emp_code',
      width: 130,

      key: 'emp_code',
      id: 'emp_code',
      render: (data) => (
        <Tooltip className='truncate' title={data}>
          {' '}
          {data || '\u2014'}
        </Tooltip>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'image',
      key: 'image',
      id: 'image',
      render: (image, record) => (
        <div>
          <Tooltip className='truncate' title={record?.name}>
            <User name={record?.name} image={image} />{' '}
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Designation',
      dataIndex: 'des_id',
      key: 'des_id',
      id: 'des_id',
      render: (data) => (
        <Tooltip className='truncate' title={data?.name}>
          {data?.name || '\u2014'}
        </Tooltip>
      ),
    },
    {
      title: 'Contact Number',
      dataIndex: 'contact',
      key: 'contact',
      id: 'contact',
      render: (data) => (
        <Tooltip className='truncate' title={data}>
          <div className='underline cursor-pointer' onClick={(e) => toggleDialer(e, data)}>
            {data || '\u2014'}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      id: 'email',
      render: (data) =>
        (
          <MultiEmail
            text={data}
            button={false}
            titleClasses={'underline cursor-pointer !truncate'}
            emailData={data}
          />
        ) || '\u2014',
    },
    {
      title: 'Role',
      dataIndex: 'role_id',
      key: 'role',
      id: 'role',
      render: (data) => (
        <Tooltip className='truncate' title={data?.name}>
          {data?.name || '\u2014'}
        </Tooltip>
      ),
    },
    {
      title: 'Department',
      dataIndex: 'dep_id',
      key: 'department',
      id: 'department',
      render: (data) => (
        <div className='truncate'>
          <MultiUserInitial data={data} value={'name'} label='department' />
        </div>
      ),
    },
    {
      title: 'D.O.B',
      dataIndex: 'dob',
      key: 'dob',
      id: 'dob',
      render: (data) => (
        <Tooltip className='truncate' title={data}>
          {' '}
          {data || '\u2014'}
        </Tooltip>
      ),
    },
    {
      title: 'Report To',
      dataIndex: 'report_to',
      key: 'report_to',
      id: 'report_to',
      render: (data) => (
        <Tooltip className='truncate' title={data?.name}>
          {' '}
          {data?.name || '\u2014'}
        </Tooltip>
      ),
    },
    {
      title: 'Joining Date',
      dataIndex: 'joining',
      key: 'joining',
      id: 'joining',
      render: (data) => (
        <Tooltip className='truncate' title={data}>
          {' '}
          {data || '\u2014'}
        </Tooltip>
      ),
    },
    {
      title: 'Work From',
      dataIndex: 'work_from',
      key: 'work_from',
      id: 'work_from',
      render: (data) => (
        <Tooltip className='truncate capitalize' title={data === 'in_field' ? 'In Field' : data}>
          {' '}
          {data === 'in_field' ? 'In Field' : data || '\u2014'}
        </Tooltip>
      ),
    },
    {
      title: 'Account Number',
      dataIndex: 'account',
      key: 'account',
      id: 'account',
      render: (data) => (
        <Tooltip className='truncate' title={data}>
          {' '}
          {data || '\u2014'}
        </Tooltip>
      ),
    },
    {
      title: 'IFSC Code',
      dataIndex: 'ifsc_code',
      key: 'ifsc_code',
      id: 'ifsc_code',
      render: (data) => (
        <Tooltip className='truncate' title={data}>
          {' '}
          {data || '\u2014'}
        </Tooltip>
      ),
    },
  ];

  /** states */
  const checkStorage = JSON.parse(localStorage.getItem('employeeCurrColumns'));
  const storeColumns = columns.filter((obj1) => checkStorage?.some((obj2) => obj1.id === obj2.id));
  const [employeeRow, setEmployeeRow] = useState();
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [showModal, setShowModal] = useState(false);
  const [statusValue, setStatusValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const [curColumns, setCurColumns] = useState(checkStorage ? storeColumns : columns?.slice(0, 5));
  const [filteredValues, setFilteredValues] = useState({});
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  /** funcs */
  const showDrawer = () => {
    setId(null);
    setOpen(true);
  };
  const onClose = () => {
    setFormLoading(true);
    setInitialData([]);
    setId(null);
    setOpen(false);
    setTimeout(() => {
      setFormLoading(false);
    }, 100);
  };

  const onEditClick = (id) => {
    setId(id);
    setOpen(true);
  };
  const onError = (event) => {
    event.target.src = User;
  };

  const onEditPermissionClick = useCallback(
    (record) => {
      if (record?.id === user?.id) {
        Toast('error', NOTIFICATION_MSG.error, 'You are not allowed to this operation');
      } else {
        navigate(`/employee/${record?.id}`);
      }
    },
    [navigate, user?.id],
  );

  const onDeleteClick = useCallback((record) => {
    setEmployeeRow(record);
    toggleDeleteModal();
  }, []);

  const onStatusChange = useCallback(
    async (e, record) => {
      setLoading(true);
      const status = e?.key;
      if (String(status).toLowerCase() === String(record?.status).toLowerCase());
      {
        const resp = await updateEmployee({ id: record.id, data: { status } });
        if (resp?.data?.status) {
          Toast('success', NOTIFICATION_MSG.success, 'Employee Status Updated Successfully');
          const query = '';
          updateEmployees(pageNum, query, filteredValues?.status);
        } else Toast('error', NOTIFICATION_MSG.error, 'Failed To Updated Employee Status');
      }
      setLoading(false);
    },
    [pageNum, updateEmployees],
  );

  const tabs = [
    {
      key: 'active',
      label: `Active`,
      Component: () => null,
    },
    {
      key: 'inactive',
      label: `Inactive`,
      Component: () => null,
    },
  ];

  const handlePagination = (pageNum) => {
    const query = searchQuery ?? '';
    const e_status = filteredValues?.status;
    const designation = filteredValues?.designation;
    const roles = filteredValues?.roles;
    const department = filteredValues?.department;
    if (pageNum?.current) {
      updateEmployees(pageNum?.current, query, e_status, designation, roles, department);
    }
  };

  // Delete Employee API //
  const handleDeleteEmployee = async () => {
    const id = employeeRow?.id;
    if (!id) return;
    setLoading(true);
    const resp = await deleteEmployee({ id });
    if (resp?.data?.status) {
      updateEmployees();
      Toast('success', NOTIFICATION_MSG.success, 'Employee Deleted Successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteModal();
    setLoading(false);
  };

  const toggleDeleteModal = () => setShowModal((pre) => !pre);

  const handleOnChange = debounce(async (e) => {
    const query = e?.target?.value;
    if (!query) {
      updateEmployees();
      setSearchQuery('');
    }
    setLoading(true);
    setSearchQuery(query);
    await updateEmployees(pageNum, query);
    setLoading(false);
  }, 700);

  const formatTableData = (rawData) =>
    rawData.map((i) => {
      return {
        ...i,
        name: i?.name,
        id: i?.id,
        email: i?.email,
        contact: i?.contact,
        address: formatAddress({ addressObject: i?.address }),
        key: i?.id,
      };
    });

  useEffect(() => {
    setTableData(formatTableData(employees));
  }, [employees, statusValue]);

  useEffect(() => {
    updateEmployees();
  }, []);

  useEffect(() => {
    const query = '';
    const e_status = filteredValues?.status;
    const designation = filteredValues?.designation;
    const roles = filteredValues?.roles;
    const department = filteredValues?.department;
    updateEmployees(pageNum, query, e_status, designation, roles, department);
  }, [filteredValues]);

  useEffect(() => {
    localStorage.setItem('employeeCurrColumns', JSON.stringify(curColumns));
  }, [curColumns]);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        btnText='+ Employee'
        buttonId={elementData.employee.permissions.create}
        title={<h2 className='text-s font-semibold'>Employee Listing</h2>}
        // onClick={() => navigate('/add-employees')}
        onClick={showDrawer}
        permissions={permissions}
        onChange={handleOnChange}
        divClasses='!h-[40px]'
        extraRightRender={() => (
          <div className='absolute right-3 flex items-center'>
            <MultiFilter
              setFilteredValues={setFilteredValues}
              filterBy={['roles', 'designations', 'departments', 'status']}
            />
          </div>
        )}
      >
        <div className='flex gap-2  mr-1' id='employeeFilter'>
          <Filter
            removeValFrom={[20]}
            allColumns={columns}
            activeColumns={curColumns}
            updateActiveColumns={setCurColumns}
            minFields={MIN_FIELDS}
            maxFields={MAX_FIELDS}
          />
        </div>
      </ContentHeader>
      <div id='employeePage '>
        <TableComponent
          loading={loading || employeesLoading}
          getRowClassName={rowCommonClass}
          scroll={{
            x: curColumns?.length > 8 ? 3000 : 500,
          }}
          columns={[
            ...curColumns,
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              render: (status, record) => {
                return (
                  <div className='w-36 leading-normal text-x'>
                    <TableStatus
                      permissions={permissions}
                      id={elementData.employee.permissions.update}
                      onChange={onStatusChange}
                      record={record}
                      items={EmployeeStatusList}
                      status={status}
                    />
                  </div>
                );
              },
            },
            {
              title: () => {
                return <div className='text-[14px] font-bold leading-normal'>Action</div>;
              },
              key: 'action',
              render: (_, record) => (
                <div className=' flex gap-3'>
                  <IconButton
                    title='View'
                    permissions={permissions}
                    className='group disabled:cursor-not-allowed'
                    id={elementData.employee.permissions.view}
                    onClick={() => navigate(`/view-Employees/${record?.id}`)}
                  >
                    <View />
                  </IconButton>

                  <IconButton
                    title='Edit'
                    permissions={permissions}
                    className='group disabled:cursor-not-allowed'
                    id={elementData.employee.permissions.delete}
                    onClick={() => onEditClick(record?.id)}
                  >
                    <EDIT />
                  </IconButton>
                  <IconButton
                    title='Permission'
                    permissions={permissions}
                    className='group disabled:cursor-not-allowed'
                    id={elementData.permission_update.name}
                    onClick={() => onEditPermissionClick(record)}
                  >
                    <Permission svgClass='stroke-grey' />
                  </IconButton>
                  <IconButton
                    title='Delete'
                    permissions={permissions}
                    className='group disabled:cursor-not-allowed'
                    id={elementData.employee.permissions.delete}
                    onClick={() => {
                      onDeleteClick(record);
                    }}
                  >
                    <DELETE />
                  </IconButton>
                </div>
              ),
            },
          ]}
          dataSource={tableData}
          pageSize={pageSize}
          total={total}
          onChange={handlePagination}
          defaultPage={pageNum}
          items={tabs}
        />
      </div>

      {/* Delete Employees modal */}
      <CustomModal
        open={showModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={
          <div className='flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              text='Delete Employee'
              variant='primary'
              classes={`ml-6 px-8 bg-black`}
              onClick={handleDeleteEmployee}
              loading={loading || employeesLoading}
            />
          </div>
        }
        width={450}
      >
        <div className='flex flex-col gap-4 justify-center items-center'>
          <Trash className='w-16 h-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='pb-3 text-xs text-center text-grey-90'>
            Could you please confirm that you really wish to delete the Employee details?
          </span>
        </div>
      </CustomModal>

      {/* Add/Edit Drawer */}

      <DrawerTabsForm
        id={id}
        open={open}
        onClose={onClose}
        title={id ? 'Edit Employee' : 'Add Employee'}
        width='80%'
        items={EmployeeFields({
          maxDate,
          setIfscCode,
          bankDetails,
          error,
          setPinCodeError,
          pincodeError,
        })}
        permissions={permissions}
        children={
          formLoading ? (
            <Loader />
          ) : (
            <AddEditEmployee
              initialData={initialData}
              setInitialData={setInitialData}
              entryId={id}
              permissions={permissions}
              onClose={onClose}
            />
          )
        }
      />
    </div>
  );
};

export default SecureComponent(Employees);
