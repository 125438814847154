export const TARGET_STATUS = [
  {
    label: <span className='text-red'>On Going</span>,
    value: 'on_going',
  },
  {
    label: <span className='text-green'>Completed</span>,
    value: 'completed',
  },
  {
    label: <span className='text-grey'>Archieved</span>,
    value: 'archieved',
  },
];

export const ACTIVITY_STATUS = [
  // {
  //   label: <span>All</span>,
  //   value: "all",
  // },
  {
    label: <span className='text-red'>Not Started</span>,
    value: 'not_started',
  },
  {
    label: <span className='text-primary-90'>In Progress</span>,
    value: 'in_progress',
  },
  {
    label: <span className='text-green'>Completed</span>,
    value: 'completed',
  },
  {
    label: <span className='text-grey'>Pending Input</span>,
    value: 'pending_input',
  },
  {
    label: <span className='text-yellow'>Backlog</span>,
    value: 'backlog',
  },
  {
    label: <span className='text-red-80'>Archived</span>,
    value: 'archived',
  },
];

export const QUOTE_STAGE = [
  {
    label: <span className='text-yellow font-semibold'>Created</span>,
    value: 'created',
  },
  {
    label: <span className='text-green font-semibold'>Delivered</span>,
    value: 'delivered',
  },
  {
    label: <span className='text-green-90 font-semibold'>Accepted</span>,
    value: 'accepted',
  },
  {
    label: <span className='text-grey font-semibold'>Reviewed</span>,
    value: 'reviewed',
  },
  // {
  //   label: <span className="text-yellow">Deferred</span>,
  //   value: "deferred",
  // },
  {
    label: <span className='text-red-80 font-semibold'>Rejected</span>,
    value: 'rejected',
  },
];
export const RECENT_ACTIVITY = [
  {
    label: <span>All</span>,
    value: 'All',
  },
  {
    label: <span>Branch</span>,
    value: 'Branch',
    displayFilter: ['HR', 'Admin', null],
  },
  {
    label: <span>Employee</span>,
    value: 'Employee',
    displayFilter: ['HR', 'Admin', null],
  },
  {
    label: <span>Client</span>,
    value: 'Customer',
    displayFilter: ['Admin', null],
  },
  {
    label: <span>Task</span>,
    value: 'Task',
    displayFilter: ['HR', 'Admin', null],
  },
  {
    label: <span>Meeting</span>,
    value: 'Meeting',
    displayFilter: ['HR', 'Admin', null],
  },
  {
    label: <span>Lead</span>,
    value: 'Adjuster',
    displayFilter: ['Admin', null],
  },
  {
    label: <span>Insurer</span>,
    value: 'Insurer',
    displayFilter: ['Admin', null],
  },
  {
    label: <span>Insurance Category</span>,
    value: 'Insurance Category',
    displayFilter: ['Admin', null],
  },
  {
    label: <span>Insurance Product</span>,
    value: 'Insurance Product',
    displayFilter: ['Admin', null],
  },
];

export const NOTIFICATION_MSG = {
  success: 'Success',
  info: 'Info',
  warning: 'warning',
  error: 'Error!',
};

export const WEEK_DAYS = [
  { value: 0, label: 'Monday' },
  { value: 1, label: 'Tuesday' },
  { value: 2, label: 'Wednesday' },
  { value: 3, label: 'Thursday' },
  { value: 4, label: 'Friday' },
  { value: 5, label: 'Saturday' },
  { value: 6, label: 'Sunday' },
];

export const CLAIMS_STATUS = [
  {
    label: <span className='text-red'>Pending</span>,
    value: 'pending',
  },
  {
    label: <span className='text-primary-90'>Submitted to Company</span>,
    value: 'submitted_to_company',
  },
  {
    label: <span className='text-green'>Declined</span>,
    value: 'declined',
  },
  {
    label: <span className='text-grey'>Approved</span>,
    value: 'approved',
  },
];
