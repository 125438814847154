import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { SearchAPI } from 'components';
import { AES } from 'crypto-js';

export const downloadFile = (docUrl, name = 'test') => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', docUrl, true);
  xhr.responseType = 'blob';
  xhr.onload = () => {
    if (xhr.status === 200) {
      const url = window.URL.createObjectURL(new Blob([xhr.response]));
      const link = window.document.createElement('a');
      link.href = url;
      link.download = name; // Specify the file name here
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };
  xhr.send();
};

export const getQueryParams = (query = null) => {
  return (
    (query || window.location.search.replace('?', ''))

      // get array of KeyValue pairs
      .split('&')

      // Decode values
      .map((pair) => {
        let [key, val] = pair.split('=');

        return [key, decodeURIComponent(val || '')];
      })

      // array to object
      .reduce((result, [key, val]) => {
        result[key] = val;
        return result;
      }, {})
  );
};

export const htmlStringToPdf = async (htmlString) => {
  let iframe = document.createElement('iframe');
  iframe.style.visibility = 'hidden';
  document.body.appendChild(iframe);
  let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
  iframedoc.body.innerHTML = htmlString;
  let canvas = await html2canvas(iframedoc.body, {});

  // Convert the iframe into a PNG image using canvas.
  let imgData = canvas.toDataURL('image/png');
  // Create a PDF document and add the image as a page.
  const doc = new JsPDF({
    format: 'a4',
    unit: 'mm',
  });
  doc.addImage(imgData, 'PNG', 0, 0, 210, 297);

  // Get the file as blob output.
  let blob = doc.output('blob');

  var blobUrl = URL.createObjectURL(blob);

  var link = document.createElement('a'); // Or maybe get it from the current document
  link.href = blobUrl;
  link.download = 'quotation.pdf';
  link.innerHTML = 'Click here to download the file';
  document.body.appendChild(link); // Or append it whereever you want
  link.click();
  // Remove the iframe from the document when the file is generated.
  document.body.removeChild(iframe);
  document.body.removeChild(link); //
};

export const Searchable = ({ onChange, defaultValues, fieldName, ...rest }) => {
  const _onChange = (data) => {
    onChange(data.option);
  };
  return <SearchAPI defaultValue={defaultValues} onSelect={_onChange} {...rest} />;
};

export function encryptText(text, secretKey) {
  const encrypted = AES.encrypt(text, secretKey).toString();
  return encrypted;
}

// Decryption function
export function decryptText(encryptedText, secretKey) {
  const decrypted = AES.decrypt(encryptedText, secretKey).toString();
  return decrypted;
}

export function extractBase64String(str) {
  const regex = /data:image\/\w+;base64,(.*)/;
  try {
    const match = str?.match(regex);
    if (match && match.length === 2) return match[1];
    else return '';
  } catch (error) {
    return '';
  }
}

export function extractIntialValues({ obj, key, returnObjects }) {
  if (key.includes('.')) {
    const keys = key.split('.');
    const val = obj?.[keys[0]]?.[keys[1]];
    return val;
  }

  // Check if the key exists in the top-level properties and it is an array
  if (obj?.hasOwnProperty(key) && Array.isArray(obj[key])) {
    return obj[key];
  }

  // Check if the key exists in the top-level properties
  if (obj?.hasOwnProperty(key) && typeof obj[key] !== 'object') {
    return obj[key];
  }

  // Iterate over the top-level properties
  for (let prop in obj) {
    // Check if the property value is an object itself
    if (typeof obj[prop] === 'object' && obj[prop] !== null) {
      // Check if the key exists in the nested object properties
      if (obj[prop].hasOwnProperty(key)) {
        return obj[prop][key];
      }

      // Iterate over the nested object properties
      for (let nestedProp in obj[prop]) {
        // Check if the nested property value is an object itself
        if (typeof obj[prop][nestedProp] === 'object' && obj[prop][nestedProp] !== null) {
          // Check if the key exists in the second level nested object properties
          if (obj[prop][nestedProp].hasOwnProperty(key)) {
            return obj[prop][nestedProp][key];
          }
        }
      }
    }
  }

  // Check if the key exists in the top-level properties and allow to return objects
  if (obj?.hasOwnProperty(key) && returnObjects && !!obj[key]) {
    return {
      ...obj[key],
      label: obj[key]?.name,
      value: obj[key]?.id,
    };
  }

  // Key not found
  return undefined;
}

export const prepareViewData = ({ data, format, keys, allowToReturnObjects, phone, email }) => {
  const finalData = [];
  format.map((section) => {
    let op = {
      heading: section.title,
      details: {},
    };
    section.keys.forEach((key) => {
      const value = extractIntialValues({ obj: data, key, returnObjects: allowToReturnObjects });
      if (key.includes('.')) {
        const splittedKeys = key.split('.');
        const tempKey = splittedKeys.at(-1);
        op = {
          ...op,
          details: { ...op.details, [keys[tempKey] || tempKey]: value },
        };
      } else {
        op = { ...op, details: { ...op.details, [keys[key] || key]: value } };
      }
    });
    finalData.push(op);

    return undefined;
  });

  //change this
  const finalDataobj = {};
  for (const item of finalData) {
    const data = Object.entries(item.details).map((item) => ({
      label: item[0],
      value: item[1],
      name: item[0],
      phone: item[0] === phone,
      email: item[0] === email,
    }));
    finalDataobj[item.heading] = data;
  }

  return finalDataobj;
};

export const formatFormPayload = (data) => {
  //removing undefined keys
  Object.keys(data).forEach((key) => {
    if (!data[key] && typeof data[key] !== 'boolean') {
      delete data[key];
    }
  });
  const finalReturn = {
    ...data,
  };
  return finalReturn;
};

export const getFilteredColumns = (tab, columns) => {
  if (tab === 'allocated') {
    return columns.filter((item) => item.key !== 'updated');
  } else if (tab === 'revoked') {
    return columns.slice(0, 5);
  } else {
    return columns.filter((item) => item.key !== 'asset' && item.key !== 'updated');
  }
};

export const formStyle = 'grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-5 pt-0';

export const StatsIconClass =
  'h-9 w-9 transition-all duration-500 group-hover:h-10 group-hover:w-10 group-hover:fill-primary-90';

export const rowCommonClass = (record, index) => {
  let colorClass;
  colorClass = index % 2 === 0 ? 'bg-[#FFF]' : 'bg-[#FAFAFA]';
  return colorClass;
};

export function preventEKey(event) {
  return ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
}
