import Request from 'service/api-service/client';

export const getSlab = async () => {
  const resp = await Request({
    method: 'get',
    API: `payroll/tax_slab`,
  });
  return resp;
};

export const postSlab = async ({ data = undefined } = {}) => {
  const resp = await Request({
    method: 'post',
    API: `payroll/tax_slab`,
    data,
  });
  return resp;
};

export const deleteSlab = async ({ id } = {}) => {
  const resp = await Request({
    method: 'delete',
    API: `payroll/tax_slab?id=${id}`,
  });
  return resp;
};

export const updateSlab = async ({ data = undefined, id } = {}) => {
  const resp = await Request({
    method: 'patch',
    API: `payroll/tax_slab?id=${id}`,
    data,
  });
  return resp;
};
