import { EmailOutline } from 'assets/icons';
import { TextInput, PasswordInput, Button } from 'components';
import navigationService from 'service/navigation';

const fields = {
  login: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Email Address',
        name: 'email',
        id: 'email',
        placeholder: 'Email Address',
        type: 'email',
        required: true,
        component: TextInput,
        rightIcon: <EmailOutline />,
        // maxLength: 30,
      },
      {
        label: 'Password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
        type: 'password',
        required: true,
        maxLength: 30,
        validate: false,
        component: PasswordInput,
        extraRightRender: () => {
          return (
            <Button
              text='Forgot Password ?'
              type='button'
              variant='text'
              classes={'flex-end text-xs text-primary-90 !px-2'}
              onClick={() => {
                if (navigationService.navigation) {
                  navigationService.navigation('forgot-password');
                }
              }}
            />
          );
        },
      },
    ],
  },
};

const initialValues = {
  email: '',
  password: '',
};

export { fields, initialValues };
