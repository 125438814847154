/** external deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover, Space } from 'antd';
import Fuse from 'fuse.js';
import dayjs from 'dayjs';

/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
  Toast,
} from 'components';
import { addAttendanceSettingsFields, breadcrumbObj } from './data';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { prepareFormFieldsData, parseWeekDays, getEditedFields } from 'utils';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { NOTIFICATION_MSG } from 'constants';
import { SolutionOutlined } from '@ant-design/icons';
import { getShift, postShift, upadteShift } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const columns = [
  {
    key: 'name',
    title: 'Shift Name',
    dataIndex: 'name',
  },
  {
    key: 'days',
    title: 'Working Days',
    dataIndex: 'days',
    render: (_, record) => {
      let splitDays = [];
      for (let i = 0; i < record.days.length; i++) {
        splitDays.push(record.days[i].substring(0, 2));
      }
      return splitDays.join(', ');
    },
  },

  {
    key: 's_start',
    title: 'Shift Start',
    dataIndex: 'start',
    // since backend is only giving us time, and not timestamp, we gotta construct timestamp from scratch
    render: (item) => dayjs('10/05/2023 ' + item).format('hh:mm A'),
  },
  {
    key: 's_end',
    title: 'Shift End',
    dataIndex: 'end',
    render: (item) => dayjs('10/05/2023 ' + item).format('hh:mm A'),
  },
  {
    key: 'duration',
    title: 'Duration',
    dataIndex: 'duration',
  },
  {
    key: 'notes',
    title: 'Notes',
    dataIndex: 'notes',
    render: (item) => {
      return (
        <div className='truncate'>
          <Popover
            overlayStyle={{
              width: item.length < 20 ? '10vw' : '20vw',
            }}
            placement='topLeft'
            content={item}
          >
            <span>{item}</span>
          </Popover>
        </div>
      );
    },
  },
];

const AttendanceSettings = (props = {}) => {
  const permissions = props?.permissions || [];
  const navigate = useNavigate();
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredAttendanceSettings, setfilteredAttendanceSettings] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [isBreak, setIsBreak] = useState(false);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addAttendanceSettingsFields({ isBreak, setIsBreak }),
  });

  const getAttendanceType = async () => {
    setLoading(true);
    const resp = await getShift();
    if (resp?.data?.status) {
      const attendance = resp?.data?.data.map((attendance) => {
        return {
          ...attendance,
          days: parseWeekDays(attendance.days),
        };
      });
      setAttendanceData(attendance);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAttendanceType();
  }, []);

  const onAddAttendanceSetting = async (data) => {
    const finalPayload = {
      ...data,
      breaks: isBreak ? data?.breaks : [],
    };
    setLoading(true);
    const resp = await postShift({
      data: finalPayload,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      getAttendanceType();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleAddAttendanceSetting();
    setLoading(false);
  };

  const toggleDeleteAttendanceType = () => setDeleteModal((pre) => !pre);

  const onDeleteAttendanceSetting = async () => {
    setLoading(true);
    const id = editInitials?.id;
    if (!id) return;
    const data = { is_active: false };
    const resp = await upadteShift({ id, data });
    if (resp?.data?.status) {
      getAttendanceType();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleDeleteAttendanceType();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onEditAttendanceSetting = async (data) => {
    setLoading(true);
    const editedFields = getEditedFields({
      initialValues: editInitials,
      updatedData: data,
    });
    if (Object.keys(editedFields).length) {
      const resp = await upadteShift({
        data: editedFields,
        id: editInitials?.id,
      });
      if (resp?.data?.status) {
        getAttendanceType();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to update data');
      }
      setLoading(false);
    } else {
      setLoading(false);
      return Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
    setInitials(undefined);
    setAddModal(false);
    // setAssigneeModal(false);
  };

  const toggleAddAttendanceSetting = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  const toggleDeleteAttendanceSetting = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(attendanceData, {
      keys: ['name', 'days', 'start', 'end'],
      threshold: 0.2,
    }).search(e.target.value);
    setfilteredAttendanceSettings(fuse.map((i) => i.item));
  };

  const onEditPress = (record) => {
    // const empOptions = record?.assignees?.map((item) => item?.user?.id);
    // const FinalRecord = { ...record, assignees: empOptions.length ? empOptions : [''] };
    setInitials(record);
    setAddModal(true);
  };

  const onDeletePress = (record) => {
    setInitials(record);
    setDeleteModal(true);
  };

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        title={<p className='font-semibold leading-normal text-s'>Shift Management</p>}
        // data={breadcrumbObj}
        btnText='+ Shift Management'
        buttonId={elementData?.attendance_settings?.permissions?.create}
        onClick={toggleAddAttendanceSetting}
      />
      <TableComponent
        loading={loading}
        getRowClassName={rowCommonClass}
        columns={[
          ...columns,
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
              <Space size='middle'>
                <IconButton
                  title='All Assignees'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.attendance_settings.permissions.update}
                  onClick={() => navigate(`/hr/attendance-settings/shift-assignees/${record.id}`)}
                >
                  <SolutionOutlined
                    style={{
                      fontSize: 16,
                      border: '2px solid cyan',
                      borderRadius: '50%',
                      padding: 4,
                      marginTop: '-3px',
                      color: 'cyan',
                    }}
                  />
                </IconButton>
                <IconButton
                  title='Delete'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.attendance_settings.permissions.delete}
                  onClick={() => onDeletePress(record)}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Edit'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.attendance_settings.permissions.update}
                  onClick={() => onEditPress(record)}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
              </Space>
            ),
          },
        ]}
        dataSource={searchQuery ? filteredAttendanceSettings : attendanceData}
      />

      {/* add Attendance modal */}
      <CustomModal
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddAttendanceSetting}
        footer={''}
        title={editInitials ? 'Edit Shift Management' : 'Add Shift Management'}
      >
        <div>
          <div className='flex flex-col'>
            <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 '>
              {editInitials
                ? 'Edit Shift Details'
                : 'To add a new shift Management, enter the details of the shift in the input field below.'}
            </span>
          </div>
          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditAttendanceSetting : onAddAttendanceSetting}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, values }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddAttendanceSetting();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.attendance_settings.permissions.update
                        : elementData.attendance_settings.permissions.create
                    }
                    text={editInitials ? 'Update' : 'Add'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* delete attendance confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteAttendanceSetting}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the setting?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteAttendanceSetting}
            />
            <Button
              text='Delete'
              variant='primary'
              classes='ml-6 px-8 bg-black'
              onClick={onDeleteAttendanceSetting}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(AttendanceSettings);
