/** external deps */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Space } from 'antd';
import Fuse from 'fuse.js';
/** internal deps */
import { DELETE, EDIT, Trash, View } from 'assets/icons';
import { TableComponent, IconButton, CustomModal, Toast, Button, ContentHeader } from 'components';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { adjusterBreads, columns } from './data';
import { NOTIFICATION_MSG } from 'constants';
import { deleteAdjuster, getAdjusterManagement } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const Adjsuter = (props = {}) => {
  const { id: adjuster_id } = useParams();
  const permissions = props?.permissions || [];
  const navigate = useNavigate();

  /* states */
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [adjuster, setAdjuster] = useState([]);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [filteredData, setFilteredData] = useState([]);

  /** funcs */
  const onEditClick = (record) => {
    navigate('/add-adjuster', { state: { initialValues: record } });
  };

  const toggleDeleteModal = (record) => {
    setSelectedRow(record);
    setDeleteModal((pre) => !pre);
  };

  const getAdjuster = async () => {
    setLoading(true);
    const resp = await getAdjusterManagement();
    if (resp?.data?.status) {
      const list = resp.data.data;
      setAdjuster(list);
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Failed to fetch adjusters from server',
      );
    }
    setLoading(false);
  };

  const handleDeleteAdjuster = async () => {
    const id = selectedRow?.id;
    if (!id) return;
    setLoading(true);
    const resp = await deleteAdjuster({ adjuster_id: id });
    if (resp?.data?.status) {
      getAdjuster();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteModal();
    setLoading(false);
  };

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) {
      return setSearchQuery('');
    }
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(adjuster, {
      keys: ['name', 'email', 'company', 'insurer_id'],
      threshold: 0.2,
    }).search(e.target.value);
    setFilteredData(fuse.map((i) => i.item));
  };

  useEffect(() => {
    getAdjuster();
  }, []);

  return (
    <main>
      <div className='grid gap-3'>
        <ContentHeader
          // title={<h2 className="font-semibold text-l">Adjuster</h2>}
          data={adjusterBreads}
          showSearch={true}
          permissions={permissions}
          buttonId={elementData.adjuster.permissions.create}
          btnText='+ Adjuster'
          onClick={() => navigate('/add-adjuster')}
          onChange={handleOnChange}
        />
        <TableComponent
          getRowClassName={rowCommonClass}
          loading={loading}
          noDataAction={getAdjuster}
          columns={[
            ...columns,
            {
              title: 'Action',
              key: 'action',
              align: 'right',
              render: (_, record) => (
                <Space size='middle'>
                  <IconButton
                    title='View'
                    permissions={permissions}
                    id={elementData.adjuster.permissions.view}
                    className='group disabled:cursor-not-allowed'
                    onClick={() => {
                      navigate(`/view-adjuster/${record.id}`, {
                        state: { initialValues: record },
                      });
                    }}
                  >
                    <View svgClass='stroke-primary group-disabled:stroke-grey' />
                  </IconButton>
                  <IconButton
                    permissions={permissions}
                    id={elementData.adjuster.permissions.delete}
                    title='Delete'
                    className='group disabled:cursor-not-allowed'
                    onClick={() => toggleDeleteModal(record)}
                  >
                    <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                  </IconButton>
                  <IconButton
                    permissions={permissions}
                    id={elementData.adjuster.permissions.update}
                    title='Edit'
                    className='group disabled:cursor-not-allowed'
                    onClick={() => onEditClick(record)}
                  >
                    <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                  </IconButton>
                </Space>
              ),
            },
          ]}
          dataSource={searchQuery ? filteredData : adjuster}
          total={adjuster.length}
          pageSize={10}
        />
      </div>

      {/* Delete contact modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={
          <div className=' flex flex-row justify-center  bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              permissions={permissions}
              id={elementData.adjuster.permissions.delete}
              text='Delete Adjuster'
              variant='primary'
              classes={`ml-6 px-8   
               bg-black
              `}
              onClick={handleDeleteAdjuster}
              loading={loading}
            />
          </div>
        }
        width={450}
      >
        <div className='flex justify-center flex-col items-center gap-4'>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-xs text-grey-90 pb-3'>
            Could you please confirm that you really wish to delete the Adjuster details?
          </span>
        </div>
      </CustomModal>
    </main>
  );
};

export default SecureComponent(Adjsuter);
