import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { CircularProgressBar, SearchAPI } from 'components';

export const fieldForms = {
  'Create Task': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Assign To Employee',
        name: 'employee',
        id: 'employee',
        placeholder: 'Assign To Employee',
        type: 'search',
        component: SearchAPI,
        divClasses: 'col-span-full',
      },
    ],
  },
};

export const columns = [
  {
    title: 'Goal Type',
    dataIndex: 'type',
    key: 'type',
    id: 'type',
    render: (val) => (val?.name ? <span>{val?.name}</span> : '\u2014'),
    displayFilter: ['assigned'],
  },
  {
    title: 'Number of Consignments',
    dataIndex: 'field',
    key: 'field',
    id: 'field',
    displayFilter: ['assigned'],
  },
  {
    title: 'Start Date',
    dataIndex: 'start',
    key: 'start',
    id: 'start',
    displayFilter: ['assigned'],
  },
  {
    title: 'End Date',
    dataIndex: 'end',
    key: 'end',
    id: 'end',
    render: (date) => date || '\u2014',
    displayFilter: ['assigned'],
  },
  {
    key: 'progress',
    title: 'Progress',
    dataIndex: 'count',
    render: (count, record) => {
      var percent = (count / record?.field) * 100;
      return <CircularProgressBar percent={percent} />;
    },
  },
  {
    title: 'Goal Status',
    dataIndex: 'status',
    key: 'status',
    id: 'status',
    render: (status) => {
      return (
        <>
          <Tag
            icon={
              status === 'on_going' ? (
                <SyncOutlined spin />
              ) : status === 'completed' ? (
                <CheckCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )
            }
            color={
              status === 'on_going' ? 'processing' : status === 'completed' ? 'success' : 'error'
            }
          >
            {status.replace('_', ' ')}
          </Tag>
        </>
      );
    },
    displayFilter: ['assigned'],
  },
  {
    title: 'Assigned To',
    dataIndex: 'employee',
    key: 'employee',
    id: 'employee',
    render: (val) => val?.name || '\u2014',
    displayFilter: ['assigned'],
  },
];
