import { useLocation, useNavigate } from 'react-router-dom';
import { ViewData } from 'components';
import { SECTION_DATA_VIEW } from './data';
import './style.css';
import { prepareViewData } from 'utils/common';

const ViewPolicy = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <div className='p-4 bg-white  rounded-2xl'>
        <ViewData
          data={prepareViewData({
            data: state?.initialValues,
            format: SECTION_DATA_VIEW,
            keys: {
              lead_id: 'Quotation Id',
              name: 'Contact',
              comm_date: 'Commence Date',
              exp_date: 'Expire Date',
              g_premium: 'Gross Premium',
              gst_pre: 'gst_premium',
              dis_type: 'discount type',
              premium: 'Insurance Premium',
              sum_insured: 'Sum Insured',
              r_address: 'registered address',
              r_state: 'registered state',
              r_city: 'registered city',
              r_pincode: 'registered pincode',
              r_country: 'registered country',
            },
            allowToReturnObjects: true,
            phone: 'contact_number',
            email: 'email_address',
          })}
          pageTitle={`Policy Number : ${state?.initialValues?.policy_num}`}
          showGoToBack={true}
          goBack={() =>
            navigate(`/policy`, {
              state: { activetab: state?.activekey },
            })
          }
        />
      </div>
    </div>
  );
};

export default ViewPolicy;
