/** external deps */
import React, { useState, useEffect, useContext } from 'react';
import Fuse from 'fuse.js';

/** internal deps */
import { CustomModal, IconButton, TableComponent, Toast } from 'components';
import { REFRESH_EVT, leavesTableColumns } from './data';

import { NOTIFICATION_MSG } from 'constants';
import { Context } from 'store/context';
import { leaveManagement, leaveManagementAdmin } from 'service/api-service';
import AcknowlegedLeaveRequest from './Acknowledge';
import AddEditLeave from './AddEditLeave';
import { subscribe } from 'utils/communication';
import Button from 'components/button';
import { Trash } from 'assets/icons';
import { elementData } from 'elements';
import { rowCommonClass } from 'utils/common';

const LeaveTable = ({
  isRequesting,
  status,
  searchQuery,
  permissions,
  setActiveTabKey,
  getRowClassName,
}) => {
  const [showAddModal, setAddModal] = useState(false);
  const [editInitials, setInitials] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [deletemodal, setDeletemodal] = useState(false);
  const [total, setTotal] = useState(0);
  const [leavesData, setLeavesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [filteredLeaves, setfilteredLeaves] = useState([]);
  const [columns, setColumns] = useState([]);
  const { user } = useContext(Context);

  const toggleAddLeave = async () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };
  const toggleDeleteModal = async () => {
    setDeletemodal((pre) => !pre);
  };

  const getLeaves = async () => {
    setActiveTabKey(status);
    setLoading(true);
    const resp = isRequesting
      ? await leaveManagement({ page: 1, statusVal: status })
      : await leaveManagementAdmin({ page: 1, status });
    if (resp?.data?.status) {
      let leaves = resp?.data?.data?.result;
      setLeavesData(leaves);
      setPageNum(resp?.data?.data?.current_page);
      setTotal(resp?.data?.data?.total_page);
    } else Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Plese try again');
    setLoading(false);
  };

  const handlePagination = async (pageNum) => {
    setLoading(true);
    if (pageNum.current) {
      const resp = isRequesting
        ? await leaveManagement({ page: pageNum.current, statusVal: status })
        : await leaveManagementAdmin({ page: pageNum.current, status });

      if (resp?.data?.status) {
        let leaves = resp?.data?.data?.result;
        setLeavesData(leaves);
        setPageNum(resp?.data?.data?.current_page);
        setTotal(resp?.data?.data?.total_page);
      } else Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Plese try again');
    }
    setLoading(false);
  };

  const onCancelLeave = async () => {
    setLoading(true);
    const resp = await leaveManagement({ method: 'delete', id: editInitials?.id });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.msg || 'Leave Canceled Successfully');
      setDeletemodal(false);
      getLeaves();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Plese try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    getLeaves();
    // subscribe(REFRESH_EVT(status), getLeaves);
  }, [status, isRequesting]);

  useEffect(() => {
    subscribe(REFRESH_EVT, getLeaves);
  }, []);

  useEffect(() => {
    setColumns([
      ...leavesTableColumns,
      {
        key: 'action',
        title: 'Actions',
        width: 400,
        render: (record) => {
          // if (user?.position?.toLowerCase() === 'admin') {
          return (
            <div className='flex gap-4'>
              <AcknowlegedLeaveRequest
                permissions={permissions}
                id={elementData.request_leave.permissions.view}
                status={status}
                isRequesting={isRequesting}
                record={record}
              />
              {record?.status === 'drafted' && isRequesting && (
                <IconButton
                  permissions={permissions}
                  id={elementData.request_leave.permissions.delete}
                  onClick={() => {
                    setDeletemodal(true);
                    setInitials(record);
                  }}
                  className='bordered rounded-md px-5 hover:text-[#0284c7] hover:border-[#0284c7]'
                >
                  Cancel Leave
                </IconButton>
              )}
            </div>
          );
          // }
        },
      },
    ]);
  }, [isRequesting, user]);

  useEffect(() => {
    if (!searchQuery) return;
    const fuse = new Fuse(leavesData, {
      keys: [
        'emp_id',
        'lt_id',
        'dates',
        'note',
        'reason',
        'status',
        'created_by.name',
        'leave_type.label',
        'days',
        'from_date',
        'to_date',
      ],
      threshold: 0.2,
    }).search(searchQuery);
    setfilteredLeaves(fuse.map((i) => i.item));
  }, [searchQuery]);

  return (
    <div className='grid gap-3'>
      <TableComponent
        getRowClassName={rowCommonClass}
        loading={loading}
        columns={columns}
        dataSource={searchQuery ? filteredLeaves : leavesData}
        total={total}
        onChange={handlePagination}
        defaultPage={pageNum}
      />

      {/* add leaves modal */}
      <AddEditLeave
        open={showAddModal}
        toggleMethod={toggleAddLeave}
        loading={formLoading}
        setLoading={setFormLoading}
        getLeaves={getLeaves}
      />

      {/* delete modal */}

      <CustomModal
        open={deletemodal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={
          <div className=' flex flex-row justify-center gap-4  bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              text='Cancel Leave'
              variant='primary'
              // classes={`ml-6 px-8
              //  bg-black
              // `}
              onClick={onCancelLeave}
              loading={loading}
            />
          </div>
        }
        width={450}
      >
        <div className='flex justify-center flex-col items-center gap-4'>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-xs text-grey-90 pb-3'>
            {`Could you please confirm that you really wish to Cancel the Leave`}
          </span>
        </div>
      </CustomModal>
    </div>
  );
};

export default LeaveTable;
