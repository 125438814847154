import { CurrencyInput, DateTime, SearchAPI, SelectInput, TextInput } from 'components';

export let addPolicyFormFields = {
  'Payment Information': {
    fields: [
      {
        label: 'Client',
        name: 'client',
        id: 'client ',
        placeholder: 'Select Client',
        type: 'select',
        component: SelectInput,
        options: [],
      },
      {
        label: 'Payment Ref.',
        name: 'payment_ref',
        id: 'payment_ref',
        placeholder: 'Payment Ref.',
        type: 'text',
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Payment Type',
        name: 'payment_type',
        id: 'payment_type',
        placeholder: 'Select Payment Type',
        type: 'select',
        component: SelectInput,
        options: [],
      },
      {
        label: 'Payment Sub Type',
        name: 'payment_sub_type',
        id: 'payment_sub_type',
        placeholder: 'Select Payment Sub Type',
        type: 'select',
        component: SelectInput,
        options: [],
      },
      {
        label: 'Currency',
        name: 'currency',
        id: 'currency',
        placeholder: 'Select Currency',
        type: 'select',
        component: SelectInput,
        options: [],
      },
      {
        label: 'Actual Amount',
        name: 'actual_amount',
        id: 'actual_amount',
        placeholder: 'Actual Amount',
        type: 'currency',
        component: CurrencyInput,
        maxLength: 30,
      },
      {
        label: 'Payment Mode',
        name: 'payment_mode',
        id: 'payment_mode',
        placeholder: 'Select Payment Mode',
        type: 'select',
        component: SelectInput,
        options: [],
      },
      {
        label: 'Payment Date',
        name: 'payment_date',
        id: 'payment_date',
        placeholder: 'Payment Date',
        type: 'date',
        component: DateTime,
        maxLength: 30,
      },
      {
        label: 'Payment Status',
        name: 'payment_status',
        id: 'payment_status',
        placeholder: 'Payment Status',
        type: 'select',
        component: SelectInput,
        options: [],
      },
      {
        label: 'Terms Condition',
        name: 'terms_condition',
        id: 'terms_condition',
        placeholder: 'Select Terms Condition',
        type: 'select',
        component: SelectInput,
        options: [],
      },
      {
        label: 'Related Invoice',
        name: 'related_invoice',
        id: 'related_invoice',
        placeholder: 'Search Related Invoice',
        type: 'search',
        component: SearchAPI,
        maxLength: 30,
      },
      {
        label: 'Assigned To Employee',
        name: 'assign_to_employee',
        id: 'assign_to_employee',
        placeholder: 'Search Employee',
        type: 'search',
        component: SearchAPI,
        maxLength: 30,
      },
      {
        label: 'Branch Detail',
        name: 'banch_detail',
        id: 'banch_detail',
        placeholder: 'Select Branch Detail',
        type: 'select',
        component: SelectInput,
        options: [],
      },
    ],
  },
  'Billing Information': {
    fields: [
      {
        label: 'Bank Name',
        name: 'bank_name',
        id: 'bank_name',
        placeholder: 'Search Bank Name',
        type: 'select',
        component: SelectInput,
        options: [],
      },
      {
        label: 'Bank Account Name',
        name: 'bank_account_name',
        id: 'bank_account_name',
        placeholder: 'Bank Account Name',
        type: 'text',
        component: TextInput,
        maxLength: 30,
      },

      {
        label: 'Ref No. (CC No. | A/C No. | Cheque No.)',
        name: 'ref_no',
        id: 'ref_no',
        placeholder: 'Ref No. (CC No. | A/C No. | Cheque No.)',
        type: 'text',
        component: TextInput,
        maxLength: 30,
      },
    ],
  },
  'Remark Information': {
    fields: [
      {
        label: 'Remark',
        name: 'remark',
        id: 'remark',
        placeholder: 'Remark',
        type: 'textarea',
        component: TextInput,
        maxLength: 300,
        isMultiline: true,
        divClasses: 'col-span-3',
        rows: 3,
      },
    ],
  },
};

export const initialValues = {
  process_type: '',
  insured_name: '',
  contact_name: '',
  contact: '',
  biz_source: '',
  bill_to: '',
  business_nature: '',
  policy_number: '',
  new_or_renewal: '',
  duration: '',
  issue_date: '',
  commence_date: '',
  actual_expire_date: '',
  insurance_category: '',
  class_code: '',
  quotation_id: '',
  assign_to_employee: '',
  premium_currency: '',
  insured_billing_address_1: '',
  insured_billing_address_2: '',
  insured_billing_address_3: '',
  country: '',
  checkaddress: '',
  insured_registered_address_2: '',
  insured_registered_address_3: '',
  insured_registered_country: '',
  branch_details: '',
  terms_condition: '',
  description: '',
};

export const dataSource = [
  {
    key: '1',
    payment_no: 1234,
    payment_ref: 'Lorem ipsum dolor',
    payment_type: 'Premium',
    assign_to_employee: 'Amar',
    status: 'Paid',
  },
];

export const paymentTableColumns = [
  {
    title: 'Payment No.',
    dataIndex: 'payment_no',
    key: 'payment_no',
  },
  {
    title: 'Payment Ref',
    dataIndex: 'payment_ref',
    key: 'payment_ref',
  },
  {
    title: 'Payment Type',
    dataIndex: 'payment_type',
    key: 'payment_type',
  },
  {
    title: 'Assigned To',
    dataIndex: 'assign_to_employee',
    key: 'assign_to_employee',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];
