import React, { useState } from 'react';
import { Upload } from 'antd';

import { UploadIcon } from 'assets/icons';
import './style.css';

const { Dragger } = Upload;

/* 
extentions for the reference
1. .html  
2. .png
3. .csv 
4. .jpeg 
**/

const Uploadbox = ({
  form,
  field,
  accept = ['.png', '.html', '.jpg', '.svg', '.mp4', '.pdf'],
  listType = 'text',
  maxCount = 1,
  uploadNoteClass = '',
  dragger = true,
  InputClasses,
  ...props
}) => {
  const [data, setData] = useState([]);
  // const [errorMessage, setErrorMessage] = useState();

  const beforeUpload = (file) => {
    const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
    // if (accept.includes(fileExtension)) {
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      if (maxCount > 1) {
        const newData = [...data, { doc_name: file.name, doc: target.result }];
        setData(newData);
        form?.setFieldValue(field?.name, newData);
      } else {
        const image = target.result;
        form?.setFieldValue(field?.name, image);
      }
    };
    reader.readAsDataURL(file);
    // setErrorMessage();
    return false;
    // } else {
    //   setErrorMessage('File type' + ' ' + fileExtension + ' ' + 'is not allowed.');
    // }
  };

  const onRemove = (file) => {
    if (maxCount > 1) {
      const newData = data.filter((item) => item.doc_name !== file.name);
      setData(newData);
      form?.setFieldValue(field?.name, newData);
    } else form?.setFieldValue(field?.name, '');
  };

  const prop = {
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  return (
    <div className={`pb-8 ${props?.divClasses}`}>
      <label
        className={`group-focus-within:text-primary-90 block mb-1 text-xxl md:text-xxs xl:text-xxs text-black ${
          props?.required ? 'required' : ''
        }`}
      >
        {props.label}
      </label>

      {dragger ? (
        <Dragger
          listType={listType}
          showUploadList={true}
          beforeUpload={beforeUpload}
          accept={accept}
          maxCount={maxCount}
          onRemove={onRemove}
          {...prop}
        >
          <input type='hidden' {...field} />
          <>
            <div className={uploadNoteClass}>
              {!form?.initialValues?.[field?.name] ? (
                <>
                  <div className='flex justify-center pb-1'>
                    <UploadIcon svgClass=' w-10 md:w-5 h-10 md:h-5' />
                  </div>
                  <div>
                    <p className='  text-xl md:!text-x'>
                      Drag and drop your file here or{' '}
                      <span className='text-primary-90'>Browse</span>
                    </p>
                    <p className='text-grey-80 text-m md:!text-x'>Maximum upload size 10 MB.</p>
                  </div>
                </>
              ) : (
                <div className='m-auto w-7'>
                  <img
                    src={process.env.REACT_APP_DOC_URL + form?.initialValues?.[field?.name]}
                    alt='img'
                  />
                </div>
              )}
            </div>
            <p className='ml-12 w-44 text-center truncate'>
              {field?.value
                ? Array.isArray(field?.value)
                  ? field?.value?.map((item) => item?.value)
                  : field?.value
                : ''}
            </p>
          </>
        </Dragger>
      ) : (
        <>
          <Upload
            className='w-full'
            listType={listType}
            // showUploadList={!errorMessage}
            beforeUpload={beforeUpload}
            accept={accept}
            maxCount={maxCount}
            onRemove={onRemove}
            {...prop}
          >
            <div
              className={`flex items-center gap-[9px] bg-[#F5FAFD] border border-[#D1EAF9] text-xl md:text-xxs xl:text-xs rounded-md w-full p-[9px] xl:p-[7px] focus:outline-none focus:border-primary-90
                        placeholder-grey-90 placeholder:text-grey-90 placeholder:text-xl md:placeholder:text-xxs xl:placeholder:text-xs disabled:bg-grey-light disabled:border-grey-light disabled:cursor-not-allowed
                      disabled:text-grey-90 placeholder:capitalize
                       ${InputClasses} `}
            >
              <button
                type='button'
                className='rounded-[3px] !bg-[#DDDDDD]  px-2  text-x text-black bg-inherit min-w-[90px]'
              >
                Browse File
              </button>
              <p className='w-36 text-x text-[#ABABAB] truncate'>
                {field?.value
                  ? Array.isArray(field?.value)
                    ? field?.value?.map((item) => item?.value)
                    : field?.value
                  : 'No File Choosen'}
              </p>
            </div>
            {/* <p className='w-50 text-x text-[#ff4d4f] '>{errorMessage}</p> */}
          </Upload>
        </>
      )}
    </div>
  );
};
export default Uploadbox;
