/** external deps */
import React, { useState } from 'react';
import { Space } from 'antd';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
} from 'components';
import { addProvidentFundFields, breadcrumbObj } from './data';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { prepareFormFieldsData } from 'utils';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { providentFund } from './dummy';
import { rowCommonClass } from 'utils/common';

const ProvidentFund = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [selectedProvidentFund, setSelectedProvidentFund] = useState();
  const [tableData, setTableData] = useState(providentFund);
  const [loading, setLoading] = useState(false);
  const [filteredProvidentFund, setfilteredProvidentFund] = useState([]);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addProvidentFundFields,
  });

  const columns = [
    {
      key: 'employee',
      title: 'Employee',
      dataIndex: 'employee',
      render: (_, record) => (
        <div className='flex items-center'>
          <img
            src='https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745'
            className='h-6 w-6 mr-2'
            alt='employee'
          />
          {record.employee}
        </div>
      ),
    },
    {
      key: 'provident_fund_type',
      title: 'Provident Fund Type',
      dataIndex: 'provident_fund_type',
    },
    {
      key: 'employee_share_percentage',
      title: 'Employee Share',
      dataIndex: 'employee_share_percentage',
    },
    {
      key: 'organization_share_percentage',
      title: 'Organization Share',
      dataIndex: 'organization_share_percentage',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.providentFund.permissions.delete}
            onClick={() => {
              setSelectedProvidentFund(record);
              toggleDeleteProvidentFund();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.providentFund.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  /* funcs */
  const onAddProvidentFund = async (data) => {};

  const onDeleteProvidentFund = async () => {};

  const onEditProvidentFund = async (data) => {};

  const toggleAddProvidentFund = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  const toggleDeleteProvidentFund = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
  };

  const onEditPress = (record) => {
    setInitials(record);
    setSelectedProvidentFund(record);
    setAddModal(true);
  };

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        data={breadcrumbObj}
        btnText='+ Provident Fund'
        buttonId={elementData?.providentFund?.permissions?.create}
        onClick={toggleAddProvidentFund}
      />
      <TableComponent
        loading={loading}
        columns={columns}
        dataSource={searchQuery ? filteredProvidentFund : tableData}
        getRowClassName={rowCommonClass}
      />

      {/* add Provident Fund modal */}
      <CustomModal
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddProvidentFund}
        footer={''}
        title={editInitials ? 'Edit Provident Fund' : 'Add Provident Fund'}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 text-grey-80'>
            {editInitials
              ? 'Edit Provident Fund details'
              : 'To add a new provident fund, enter the details of the provident fund in the input field below.'}
          </span>
          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditProvidentFund : onAddProvidentFund}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddProvidentFund();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.providentFund.permissions.update
                        : elementData.providentFund.permissions.create
                    }
                    text={editInitials ? 'Update Provident Fund' : 'Add Provident Fund'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* delete Provident Fund confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteProvidentFund}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the provident fund?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteProvidentFund}
            />
            <Button
              text='Delete Provident Fund'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteProvidentFund}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(ProvidentFund);
