export const breadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Insurer',
    path: '/insurer',
  },
  {
    name: 'View Insurer',
    path: '',
  },
];

/**
 * @param {{
 * name:string;
 * email: string;
 * active: string;
 * phone: string;
 * code: string;
 * website: string;
 * address: string;
 * assign_employee: string;
 * fax: string;
 * gst: string;
 * company_no:string;
 * attn: string;
 * comm_per: string;
 * category: string;
 * debtor: string;
 * currency: string;
 * gst_comm: string;
 * creditor: string;
 * firm_comm: string;
 * premium: string;
 * accrued: string;
 * comm_paid: string;
 * transmission: string;
 * discount: string;
 * box :string;
 * city: string;
 * state: string;
 * pincode: string;
 * country: string;
 * country_code: string;
 * user: string;
 * username: string;
 * email_verify: string;
 * is_active: string;
 * }} data
 * @returns
 */

export const INSURER_DATA_VIEW = [
  {
    title: '_',
    keys: [
      'name',
      'phone',
      'email',
      'active',
      'code',
      'website',
      'gst',
      'fax',
      'company_no',
      'comm_per',
      'assign_employee',
      'firm_comm',
      'premium',
      'creditor',
      'debtor',
      'category',
      'currency',
      'gst_comm',
      'discount',
      'accrued',
      'comm_paid',
      'transmission',
      'ac_comm_to_client',
    ],
  },
  {
    title: 'Address Details',
    keys: ['address', 'box', 'city', 'state', 'pincode', 'country', 'country_code'],
  },
  {
    title: 'Description',
    keys: ['description'],
  },
];
