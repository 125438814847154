/** external deps */
import { Space } from 'antd';
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
/** internal deps */
import {
  ContentHeader,
  IconButton,
  TableComponent,
  Toast,
  Button,
  CustomModal,
  Module,
} from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { breadCrumbs } from './data';
import { getPermission } from 'utils';
import { deleteLetterManagement, getLetterManagement } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const OfficialLetters = (props = {}) => {
  const permissions = props?.permissions || [];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState();
  const navigate = useNavigate();

  const columns = [
    {
      key: 'name',
      title: 'Letter Name',
      dataIndex: 'name',
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'template',
      render: (data) => <p className='truncate'>{data?.description || 'N/A'}</p>,
      width: 550,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Create'
            permissions={permissions}
            className='group disabled:cursor-not-allowed text-primary-90 disabled:text-grey rounded-full border w-6 h-6 flex items-center justify-center'
            id={elementData.letter.permissions.delete}
            onClick={() => navigate(`/create-letter/${record?.id}`)}
          >
            <PlusOutlined />
          </IconButton>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.letter.permissions.delete}
            onClick={() => {
              setSelectedLetter(record);
              toggleDeleteLetter();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.letter.permissions.update}
            onClick={() => navigate(`/official-letter/edit/${record?.id}`)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];
  const toggleDeleteLetter = () => setDeleteModal((pre) => !pre);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await getLetterManagement();
    if (resp?.data?.status) {
      setData(resp?.data?.data);
    } else Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const toggleAddLetter = useCallback(() => {
    navigate('/official-letter/create');
  }, []);

  const onDeleteLetter = async () => {
    const id = selectedLetter?.id;
    if (!id) return;
    setLoading(true);
    const resp = await deleteLetterManagement({ id });
    if (resp?.data?.status) {
      fetchData();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Letter deleted successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteLetter();
    setLoading(false);
  };

  return (
    <>
      {getPermission(permissions, elementData?.letter?.permissions?.view)?.read ? (
        <div className='grid gap-3'>
          <ContentHeader
            // data={breadCrumbs}
            title={<p className='font-semibold text-s'>Official Letters</p>}
            showSearch={false}
            permissions={permissions}
            btnText='+ Letter'
            buttonId={elementData?.letter?.permissions?.create}
            onClick={toggleAddLetter}
          />
          <TableComponent
            getRowClassName={rowCommonClass}
            loading={loading}
            columns={columns}
            dataSource={data}
          />
          {/* delete letter confirm modal */}
          <CustomModal
            open={showDeleteModal}
            closable={true}
            onCancel={toggleDeleteLetter}
            footer={''}
            width={450}
          >
            <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
              <Trash className='h-16 w-16' />
              <span className='text-l xl:text-xl'>Are you sure?</span>
              <span className='text-center text-grey-80 text-xs pb-3'>
                Could you please confirm that you really wish to delete the letter details?
              </span>
              <div className='pt-4 flex flex-row justify-center bg-white'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={toggleDeleteLetter}
                />
                <Button
                  text='Delete Letter'
                  variant='primary'
                  classes={`ml-6 px-8 bg-black`}
                  onClick={onDeleteLetter}
                  loading={loading}
                />
              </div>
            </div>
          </CustomModal>
        </div>
      ) : (
        <Module />
      )}
    </>
  );
};

export default OfficialLetters;
