import { DatePicker, Divider, Select, Space } from 'antd';
import { DropdownIcon } from 'assets/icons';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from 'store/context';
import { DateRangeOptions, componentOptions, moduleOptions } from './data';
import { searchApis } from 'service/api-service';

const placeholderClasses =
  '[&_.ant-select-selection-placeholder]:!text-black [&_.ant-select-selection-placeholder]:!text-xxs [&_.ant-select-selection-item]:!text-xxs';
const currentDate = dayjs();
const dateBefore30Days = currentDate.subtract(30, 'day');
const dateBefore3Months = currentDate.subtract(3, 'month');
const dateBefore6Months = currentDate.subtract(6, 'month');
const dateBefore1Year = currentDate.subtract(1, 'year');
const SELECT_ALL_OPTION = {
  value: '',
  label: 'All Branches',
};
const SELECT_ALL_Employee = {
  value: '',
  label: 'All Employees',
};

const BussinessHeader = ({
  setSelectedEmployee,
  selectedModule,
  setEndDate,
  selectedComponent,
  setStartDate,
  setSelectedComponent,
  setSelectedmodule,
  setSelectedBranch,
  setSelectedCategory,
  setSelectedProduct,
  setFilterType,
  handleCustomRange,
}) => {
  const { RangePicker } = DatePicker;
  const { user } = useContext(Context);
  const [showdateRange, setShowDateRange] = useState(false);
  const [employeeOptions, setEmployeeOptions] = useState(false);

  const userBranches = user?.branch?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
  const allEmployees = async () => {
    const resp = await searchApis({ api: 'employee' });
    const options = resp?.data?.data?.map((item) => {
      return {
        ...item,
        label: item?.name,
        value: item?.id,
      };
    });
    setEmployeeOptions(options);
  };
  useEffect(() => {
    allEmployees();
  }, []);

  const onSelectDateRange = useCallback((e) => {
    if (e !== 'customRange') {
      setEndDate(dayjs().format('YYYY-MM-DD'));
    }
    if (e === 'last 30 days') {
      setStartDate(dayjs(dateBefore30Days).format('YYYY-MM-DD'));
      setShowDateRange(false);
    } else if (e === 'last 3 months') {
      setStartDate(dayjs(dateBefore3Months).format('YYYY-MM-DD'));
      setShowDateRange(false);
    } else if (e === 'last 6 months') {
      setStartDate(dayjs(dateBefore6Months).format('YYYY-MM-DD'));
      setShowDateRange(false);
    } else if (e === 'last 1 year') {
      setStartDate(dayjs(dateBefore1Year).format('YYYY-MM-DD'));
      setShowDateRange(false);
    } else if (e === 'customRange') {
      setShowDateRange(true);
    }
  }, []);

  const onSelectModule = (e) => {
    setSelectedmodule(e);
    setSelectedCategory(undefined);
    setSelectedProduct(undefined);
    setFilterType('retail');
  };
  const onSelectBranch = (e) => {
    setSelectedBranch(e);
  };
  const onSelectEmployee = (e) => {
    setSelectedEmployee(e);
  };
  const handleSelectcomponent = (e) => {
    if (selectedComponent.includes('all')) {
      setSelectedComponent(e.filter((item) => item !== 'all'));
    } else if (e.includes('all')) {
      setSelectedComponent(['all']);
    } else if (e.includes('graph') || e.includes('table')) {
      setSelectedComponent(e.filter((opt) => opt !== 'all'));
    } else {
      setSelectedComponent(e);
    }
  };

  return (
    <div className='flex gap-4 justify-between items-center  w-full h-10'>
      <div className='flex justify-between w-full ' wrap>
        <div className='flex gap-4 '>
          <Select
            className={`bg-white ${placeholderClasses} rounded-md px-2`}
            defaultValue={selectedModule}
            bordered={false}
            dropdownAlign={{ offset: [-8, 15] }}
            suffixIcon={<DropdownIcon svgClass={'w-3 fill-black '} />}
            options={moduleOptions}
            onSelect={onSelectModule}
            placeholder='Module'
            style={{
              width: 120,
              fontSize: 20,
            }}
          />
          <Select
            mode='multiple'
            options={componentOptions}
            value={selectedComponent}
            className={`bg-white ${placeholderClasses} rounded-md px-2`}
            placeholder='Component'
            popupClassName='min-w-24'
            onChange={handleSelectcomponent}
          />
        </div>
        <div className=' flex gap-4'>
          <Select
            className={`bg-white ${placeholderClasses} rounded-md px-2`}
            bordered={false}
            options={DateRangeOptions}
            onSelect={onSelectDateRange}
            dropdownAlign={{ offset: [-8, 15] }}
            suffixIcon={<DropdownIcon svgClass={'w-3 fill-black '} />}
            placeholder='Date'
            defaultValue='last 30 days'
            style={{
              width: 120,
            }}
          />

          {showdateRange && (
            <>
              <RangePicker
                className={`bg-white ${placeholderClasses} rounded-md px-2`}
                onChange={handleCustomRange}
                allowClear={false}
              />
            </>
          )}
          <Select
            className={`bg-white ${placeholderClasses} rounded-md px-2`}
            bordered={false}
            options={[SELECT_ALL_OPTION, ...(userBranches || [])]}
            onSelect={onSelectBranch}
            placeholder='Branch'
            dropdownAlign={{ offset: [-8, 15] }}
            suffixIcon={<DropdownIcon svgClass={'w-3 fill-black '} />}
            defaultValue='All Branches'
            style={{
              width: 120,
            }}
          />
          {selectedModule === 'leads' && (
            <Select
              className={`bg-white ${placeholderClasses} rounded-md px-2`}
              allowClear={true}
              onClear={() => setSelectedEmployee('')}
              bordered={false}
              dropdownAlign={{ offset: [-8, 15] }}
              suffixIcon={<DropdownIcon svgClass={'w-3 fill-black '} />}
              options={employeeOptions?.length ? [SELECT_ALL_Employee, ...employeeOptions] : []}
              onSelect={onSelectEmployee}
              placeholder='Employee'
              style={{
                width: 120,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BussinessHeader;
