/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Tabs } from 'antd';
/** internal deps */
import { breadCrumbs } from './data';
import { View } from 'assets/icons';
import { ContentHeader, TableComponent, IconButton, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import dayjs from 'dayjs';
import { getTickets } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const Ticket = (props = {}) => {
  // const permissions = props?.permissions || [];
  const [loading, setLoading] = useState(false);
  const [ticketsData, setTicketsData] = useState();
  const navigate = useNavigate();

  const columns = [
    {
      key: 'customer',
      title: 'Client Name',
      dataIndex: 'customer',
      render: (record) => record?.customer_name || record?.corporation_name || '\u2014',
    },
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      render: (record) => record || '\u2014',
    },
    {
      key: 'created',
      title: 'Time',
      dataIndex: 'created',
      render: (record) => dayjs(record).fromNow() || '\u2014',
    },
    {
      key: 'regarding',
      title: 'Regarding',
      dataIndex: 'regarding',
      render: (record) => record?.regarding || '\u2014',
    },

    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='View'
            onClick={() => {
              navigate(`/ticket-review/${record?.id}`);
            }}
            className='group disabled:cursor-not-allowed'
          >
            <View svgClass='stroke-primary group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  /** funcs */
  const getFaqTickets = useCallback(async () => {
    setLoading(true);
    const response = await getTickets({ status: 'open' });
    if (response?.data?.status) {
      setTicketsData(response?.data?.data);
    } else Toast('error', NOTIFICATION_MSG.error, response?.msg);
    setLoading(false);
  }, []);

  useEffect(() => {
    getFaqTickets();
  }, []);

  const onChange = useCallback(async (val) => {
    const status = val == 1 ? 'open' : 'close';
    setLoading(true);
    const response = await getTickets({ status: status });
    if (response?.data?.status) {
      setTicketsData(response?.data?.data);
    } else Toast('error', NOTIFICATION_MSG.error, response?.msg);
    setLoading(false);
  }, []);

  const tabs = [
    {
      key: '1',
      label: `Pending`,
      children: '',
    },
    {
      key: '2',
      label: `Closed`,
      children: '',
    },
  ];

  return (
    <div className='grid gap-3'>
      <ContentHeader
        // data={breadCrumbs}
        title={<p className='font-semibold text-s'>Tickets</p>}
        showSearch={false}
      />
      <Tabs defaultActiveKey='1' items={tabs} onChange={onChange} />
      <TableComponent
        rowKey='key'
        loading={loading}
        columns={columns}
        dataSource={ticketsData}
        getRowClassName={rowCommonClass}
      />
    </div>
  );
};

export default Ticket;
