/** external deps */
import React, { useState } from 'react';

/** internal deps */
import { Filter as FilterIcon } from 'assets/icons';
import IconButton from 'components/button/IconButton';

const stub = () => {};

const TextFilter = ({ filters = [], onFilterSelect = stub, children, customActionOnClick }) => {
  /* states */
  const [filterVisible, setFilterVisible] = useState(false);

  /* funcs  */
  const _filterSelect = (filter) => {
    setFilterVisible(false);
    if (onFilterSelect) return onFilterSelect(filter);
  };

  const renderList = (item, index) => (
    <li
      onClick={() => _filterSelect(item)}
      key={index}
      className='cursor-pointer  hover:bg-grey-70 rounded-md transition-all duration-500 px-4 py-2'
    >
      <p className='text-xxs capitalize'>{item}</p>
    </li>
  );

  const onToggleFilters = () => {
    if (customActionOnClick) customActionOnClick();
    setFilterVisible((prevState) => !prevState);
  };

  return (
    <div className='self-center relative'>
      <IconButton className='w-4 grid' title='Filter' onClick={onToggleFilters}>
        <FilterIcon className='max-w-full max-h-full' />
      </IconButton>

      {children}

      <div
        className={`absolute z-10 -translate-x-1/2 left-1/2 top-[100%]  ${
          filterVisible ? 'grid' : 'hidden'
        }`}
        onMouseLeave={() => setFilterVisible(false)}
      >
        <div className='bg-white shadow-custom rounded-md p-2 w-40 gap-3 mt-2'>
          <p className='text-lg font-medium p-2'>Filters</p>
          <ul className='max-h-64 '>{filters.map(renderList)}</ul>
        </div>
      </div>
    </div>
  );
};

export default TextFilter;
