import { Space, Spin } from 'antd';
import { ContentHeader, MultiEmail, MultiUserInitial, TableComponent } from 'components';
import Button from 'components/button';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inquiryManagement, searchProposals } from 'service/api-service';
import { rowCommonClass } from 'utils/common';
const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Proposals',
  },
];

const Proposals = ({ permissions }) => {
  const naviagte = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTableData, setSearchTableData] = useState([]);
  const [queryString, setQueryString] = useState('');
  const getData = async ({ page = 1 }) => {
    try {
      setLoading(true);
      const resp = await inquiryManagement({ query: `&status=proposals&page=${page}` });
      if (resp?.data?.status) {
        setTableData(resp?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const searchData = async (query) => {
    try {
      setLoading(true);
      const resp = await searchProposals({
        query: query,
      });

      if (resp?.data?.status) {
        setSearchTableData(resp?.data?.data);
      }
    } catch (error) {
      console.error('Error during search:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePagination = (page) => {
    if (page?.current) {
      if (queryString) {
        searchData(queryString, { page: page?.current });
      } else {
        getData({ page: page?.current });
      }
    }
  };

  const handleSearch = useMemo(
    () =>
      debounce((e) => {
        const cleanQueryString = e.target.value.trim();
        setQueryString(cleanQueryString);
        if (cleanQueryString) {
          searchData(cleanQueryString);
        }
      }, 700),
    [],
  );

  useEffect(() => {
    if (!queryString) getData({ page: 1 });
  }, [queryString]);

  const InquiryColumns = [
    {
      title: 'Assigned To',
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      render: (data) => data?.name || '\u2014',
    },

    {
      title: 'ID',
      dataIndex: 'lead_id',
      key: 'lead_id',
      id: 'lead_id',
    },
    {
      title: 'Product',
      dataIndex: 's_product',
      key: 's_products',
      id: 's_products',
      render: (data, record) => {
        const Insurers = record?.products?.filter((ele) => ele.s_insurer);
        const FinalData = Insurers?.map((item) => item?.product_type);
        return (
          <div className='truncate'>
            <MultiUserInitial data={FinalData} value='name' label='employee' showImage={true} />
          </div>
        );
      },
    },
    {
      title: 'Insurer',
      dataIndex: 's_insurer',
      key: 's_insurer',
      id: 's_insurer',
      render: (data, record) => {
        const Insurers = record?.products?.filter((ele) => ele.s_insurer);
        const FinalData = Insurers?.map((item) => item?.s_insurer);
        return (
          <div className='truncate'>
            <MultiUserInitial data={FinalData} value='name' label='employee' showImage={true} />
          </div>
        );
      },
    },
    {
      title: 'Client Name',
      dataIndex: 'customer',
      key: 'customer_id',
      id: 'customer_id',
      render: (data) => data?.name || data?.corp_name || '\u2014',
    },
    {
      title: 'Email',
      dataIndex: 'customer',
      key: 'customer_email',
      id: 'customer_email',
      render: (data) =>
        (
          <MultiEmail
            text={data?.email_address}
            button={false}
            titleClasses={'underline cursor-pointer'}
            imageRef='invoice-slip'
            emailData={data?.email_address}
          />
        ) || '\u2014',
    },
    {
      title: 'Created by',
      dataIndex: 'created_by',
      key: 'created_by',
      id: 'created_by',
      render: (text) => text?.name || '\u2014',
    },
    {
      title: 'Updated at',
      key: 'action',
      render: (_, record) => {
        const date = dayjs(record?.updated);
        const dateToCheck = dayjs(record?.updated);
        const currentDate = dayjs();

        let colorClass = '';
        if (currentDate.diff(dateToCheck, 'hour') < 1) {
          colorClass = 'text-green';
        } else if (
          currentDate.diff(dateToCheck, 'hour') > 1 &&
          currentDate.diff(dateToCheck, 'hour') < 2
        ) {
          colorClass = 'text-yellow';
        } else {
          colorClass = 'text-red';
        }

        return <span className={colorClass}>{dayjs(date).fromNow()}</span>;
      },
    },
    {
      title: 'Action',
      id: 'created_by',
      render: (text, data) => {
        const reqId = data?.s_insurer?.id + data?.s_product?.id;
        const reqData = data?.quote?.find((ele) => ele?.id === JSON.stringify(reqId));
        const finalData = {
          ...data,
          policy_type: 'new',
          quotation_id: { id: data?.id, name: data?.lead_id },
          contact: data?.contact,
          contact_no: data?.customer?.contact_number,
          email_address: data?.customer?.email_address,
          branch: data?.customer?.branch_of?.id,
          bs_nature: data?.customer?.nature_of_business,
          insurance_category: data?.s_product?.ins_category?.id,
          product_type: data?.s_product?.id,
          employee: data?.assigned_to,
          insurer: data?.s_insurer?.id,
          premium: reqData?.premium,
          sum_insured: reqData?.sum,
          ncb: reqData?.ncb,
        };
        return (
          <Button
            classes='!py-1 px-[10px]'
            text='Create Policy'
            onClick={() => {
              naviagte('/add-policy', { state: { defaultValues: finalData, activekey: 'all' } });
            }}
          ></Button>
        );
      },
    },
  ];
  return (
    <div className=' grid gap-3'>
      <ContentHeader
        permissions={permissions}
        title={<p className='font-semibold leading-normal text-s'>Proposals Listing</p>}
        onChange={handleSearch}
      />
      <Space align='center' size='middle' style={{ width: '100%', minHeight: '200px' }}>
        <Spin spinning={loading} size='default'>
          <TableComponent
            total={queryString ? searchTableData?.total_page : tableData?.total_page}
            getRowClassName={rowCommonClass}
            columns={InquiryColumns}
            dataSource={queryString ? searchTableData?.result : tableData?.result}
            onChange={handlePagination}
          />
        </Spin>
      </Space>
    </div>
  );
};

export default Proposals;
