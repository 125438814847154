export const columns = [
  {
    key: 'sell_obj',
    title: 'Employee',
    dataIndex: 'sell_obj',
    render: (employee, data) => employee?.name + '(' + data.sell_by + ')',
  },
  {
    key: 'amount',
    title: 'Transaction',
    dataIndex: 'amount',
  },
  {
    key: 'policy',
    title: 'Policy Number',
    dataIndex: 'policy',
    render: (data) => data?.policy_num,
  },
  {
    key: 'insurer',
    title: 'Insurer',
    dataIndex: 'insurer',
    render: (data) => data?.name,
  },
  {
    key: 'trans_pos_commission',
    title: 'Agent Commission',
    dataIndex: 'trans_pos_commission',
  },
  {
    key: 'net_amount',
    title: 'Net Amount',
    dataIndex: 'net_amount',
  },
];

export const parsedData = (data) => {
  const res = [];
  data.transactions?.forEach((item) => {
    res.push({
      employee: item?.sell_obj.name,
      ...item,
      policy: item?.policy.policy_num,
      insurer: item?.insurer.name,
    });
  });
  return res;
};
