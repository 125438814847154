import React from 'react';
/** internal deps */
import { TaskMeeting } from 'components';
import { SecureComponent } from 'permission-provider';
import { taskColumns, meetingColumns } from './data';

const Tasks = ({
  custFieldName,
  disableCustomer,
  employeeName,
  customerName,
  permissions = [],
  customer_id,
  employee_id,
}) => {
  return (
    <div className='mt-4'>
      <TaskMeeting
        custFieldName={custFieldName}
        disableCustomer={disableCustomer}
        employeeName={employeeName}
        customerName={customerName}
        permissions={permissions}
        taskColumns={taskColumns}
        showActions={true}
        meetingColumns={meetingColumns}
        customer_id={customer_id}
        employee_id={employee_id}
      />
    </div>
  );
};

export default SecureComponent(Tasks);
