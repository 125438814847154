import { Tooltip } from 'antd';
import React, { useState } from 'react';
import CustomModal from 'components/modal';
import { UserInitialIcon } from 'components/userinitialicon';

const MultiUserInitial = ({ data, value, label, showImage = false }) => {
  const [showModal, setShowModal] = useState(false);

  const renderList = (item, key) => {
    return (
      <div className='flex gap-3 '>
        <div className='flex items-center gap-4'>
          {showImage ? (
            <Tooltip className='truncate ' title={`${item?.emp_code}-${item?.name}`}>
              <>
                <UserInitialIcon
                  name={item?.name}
                  imageUrl={process.env.REACT_APP_DOC_URL + item?.image}
                />
              </>
            </Tooltip>
          ) : (
            <p>{key + 1}.</p>
          )}
          <p>{item[value]}</p>
        </div>
      </div>
    );
  };
  const mappedData = data?.map((item) => item[value]);

  return data?.length > 3 ? (
    <>
      <div
        className='flex items-center gap-2 cursor-pointer'
        onClick={setShowModal.bind(this, true)}
      >
        {mappedData.slice(0, 1).join(', ')}
        <div className='text-x text-primary-60'>+More</div>
      </div>
      {/** Employee Modal */}
      <CustomModal
        destroyOnClose={true}
        open={showModal}
        onCancel={setShowModal.bind(this, false)}
        footer={''}
        title={label.toUpperCase()}
      >
        <div className='flex flex-col'>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 '>List of all {label}</span>
          <div className='grid grid-cols-4 gap-2'>{data?.map(renderList)}</div>
        </div>
      </CustomModal>
    </>
  ) : (
    <Tooltip className='truncate' title={mappedData?.join(', ')}>
      {mappedData.length ? mappedData?.join(', ') : '\u2014'}
    </Tooltip>
  );
};

export default MultiUserInitial;
