import { Spin } from 'antd';
import { Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { leaveManagement } from '../../../../service/api-service';
import AcknowlegedLeaveRequest from 'screens/hr/leaves/Acknowledge';

const Leave = () => {
  const [loading, setLoading] = useState(false);
  const [leaveData, setLeaveData] = useState();
  const leaveDataSection = Array.isArray(leaveData) && leaveData.length && leaveData.slice(0, 4);

  const EmployeeNameAndStatus = (props) => {
    const { name, data } = props;
    return (
      <div className='flex justify-between mt-2 '>
        <div className='text-xxs'>{name}</div>
        <div>
          <AcknowlegedLeaveRequest record={data} showViewOnly={true} />
        </div>
      </div>
    );
  };

  const onGetLeave = async () => {
    setLoading(true);
    const resp = await leaveManagement({ statusVal: 'Drafted' });
    if (resp?.data?.status) {
      setLeaveData(resp?.data?.data?.result);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    onGetLeave();
  }, []);

  return (
    <div className='min-h-[213px] flex flex-col p-2  border-2 border-grey-70 rounded-lg bg-white'>
      <div>
        <p className='font-medium text-sm'>Leaves</p>
      </div>
      <div className='flex justify-between mt-3 mb-2'>
        <div className='text-xxs font-medium text-grey-90'>Employees Name</div>
      </div>

      {loading ? (
        <Spin size='large' />
      ) : (
        Array.isArray(leaveDataSection) &&
        leaveDataSection.length &&
        leaveDataSection.map((ele, i) => {
          return (
            <div key={i}>
              <EmployeeNameAndStatus name={ele?.emp_id?.name} userId={ele?.id} loading={loading} />
            </div>
          );
        })
      )}
      <Link to='/leaves'>
        <p className='text-primary-90 font-semibold mt-3'>View All Details</p>
      </Link>
    </div>
  );
};

export default Leave;
