import { SelectInput, TextInput, DateTime } from 'components';

const breadCrumbsObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Invoice',
    path: '/invoices',
  },
  {
    name: 'Crete Invoice',
    path: '',
  },
];

const addInvoiceFields = {
  invoice: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Client',
        name: 'client',
        id: 'client',
        placeholder: 'Select Client',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { value: 'Client 1', label: 'Client 1' },
          { value: 'Client 2', label: 'Client 2' },
          { value: 'Client 3', label: 'Client 3' },
        ],
      },
      {
        label: 'Project',
        name: 'project',
        id: 'project',
        placeholder: 'Select Project',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { value: 'Project 1', label: 'Project 1' },
          { value: 'Project 2', label: 'Project 2' },
          { value: 'Project 3', label: 'Project 3' },
        ],
      },
      // {
      //   label: "Invoice Name",
      //   name: "invoice_name",
      //   id: "invoice_name",
      //   placeholder: "Email",
      //   type: "text",
      //   required: true,
      //   component: TextInput,
      //   maxLength: 30,
      // },

      {
        label: 'Email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
        type: 'text',
        required: true,
        component: TextInput,
        // maxLength: 30,
      },
      {
        label: 'Tax',
        name: 'tax',
        id: 'tax',
        placeholder: 'Select Tax',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { value: 'TAX 1', label: 'TAX 1' },
          { value: 'TAX 2', label: 'TAX 2' },
          { value: 'TAX 3', label: 'TAX 3' },
        ],
      },
      {
        label: 'Client Address',
        name: 'client_address',
        id: 'client_address',
        placeholder: 'Client Address',
        type: 'text',
        required: true,
        component: TextInput,
        isMultiline: true,
        rows: 5,
      },
      {
        label: 'Billing Address',
        name: 'billing_address',
        id: 'billing_address',
        placeholder: 'Billing Address',
        type: 'text',
        required: true,
        component: TextInput,
        isMultiline: true,
        rows: 5,
      },
      {
        label: 'Invoice Date',
        name: 'date',
        id: 'invoice_date',
        placeholder: 'Invoice Date',
        type: 'date',
        component: DateTime,
        maxLength: 30,
        showTime: false,
      },
      {
        label: 'Due Date',
        name: 'due',
        id: 'due_date',
        placeholder: 'Due Date',
        type: 'date',
        component: DateTime,
        maxLength: 30,
        showTime: false,
      },
      {
        label: 'Discount',
        name: 'discount',
        id: 'discount',
        placeholder: 'Discount',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      // {
      //   label: "Status",
      //   name: "status",
      //   id: "status",
      //   placeholder: "Select Status",
      //   type: "select",
      //   required: true,
      //   component: SelectInput,
      //   options: [
      //     { value: "Paid", label: "Paid" },
      //     { value: "Pending", label: "Pending" },
      //   ],
      // },
      {
        label: 'Other Information',
        name: 'other_information',
        id: 'other_information',
        placeholder: 'Other Information',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
    ],
  },
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Invoice',
    path: '',
  },
];

export { addInvoiceFields, breadcrumbObj, breadCrumbsObj };
