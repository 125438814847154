import React, { useCallback, useEffect, useState } from 'react';
import { FormContainer, Toast, Button } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
import { addTax, editTax } from 'service/api-service';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { addtaxFields } from './data';

const TaxForm = ({ data, permissions, fetchData }) => {
  const [editInitials, setInitials] = useState();
  const [loading, setLoading] = useState(false);
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addtaxFields,
    fieldsToExclude: data ? ['name'] : [],
  });
  useEffect(() => {
    setInitials(data);
  }, [data]);

  const onAddTax = useCallback(async (data) => {
    setLoading(true);
    const resp = await addTax({ method: 'post', data });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      fetchData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, []);

  const onEditTax = useCallback(
    async (data) => {
      setLoading(true);
      const editFields = getEditedFields({ initialValues: editInitials, updatedData: data });
      if (Object.keys(editFields).length) {
        const resp = await editTax({
          method: 'patch',
          id: editInitials?.id,
          data: editFields,
        });
        if (resp?.data?.status) {
          Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        } else {
          Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
        }
      } else {
        Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
      }
      setLoading(false);
    },
    [editInitials],
  );

  return (
    <div className='bg-white px-6 rounded-lg'>
      <FormContainer
        accordionClass='grid grid-cols-2  gap-8'
        onSubmit={editInitials ? onEditTax : onAddTax}
        initialValues={editInitials || initialValues}
        fields={finalFields}
        customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
          return (
            <div className='bg-white flex flex-row justify-end py-4 mt-6'>
              <Button
                disabled={!isValid}
                permissions={permissions}
                id={
                  editInitials
                    ? elementData.branch.permissions.update
                    : elementData.branch.permissions.create
                }
                text={editInitials ? 'Update Tax' : 'Add Tax'}
                variant='primary'
                classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                onClick={submitForm}
                loading={loading}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default TaxForm;
