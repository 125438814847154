import React from 'react';
import Pdf from 'react-to-pdf';
import { Download, Whatsapp, Gmail } from 'assets';

const PDFGenerator = ({ filename = 'file.pdf', handleClickMail, children }) => {
  const ref = React.createRef();

  return (
    <>
      <Pdf targetRef={ref} filename={filename}>
        {({ toPdf }) => (
          <div className='p-2 flex gap-4'>
            <img
              alt='download'
              onClick={toPdf}
              src={Download}
              className='cursor-pointer w-8 opacity-60 transition-all duration-300 hover:opacity-80'
            />
            <img
              alt='whatsapp'
              disable={true}
              src={Whatsapp}
              className='cursor-pointer w-8 opacity-60 transition-all duration-300 hover:opacity-100'
            />
            <img
              alt='mail'
              onClick={handleClickMail}
              src={Gmail}
              className='cursor-pointer w-8 opacity-60 transition-all duration-300 hover:opacity-100'
            />
          </div>
        )}
      </Pdf>
      <div ref={ref}>{children}</div>
    </>
  );
};

export default PDFGenerator;
