//Retrieve data from the storage
export const getItem = (name) => {
  const jsonValue = localStorage.getItem(name); // get data from storage with given key
  try {
    return JSON.parse(jsonValue); // Return parssed data
  } catch (e) {
    return jsonValue;
  }
};

// Common function to set items to the storage
export const setItem = (name, data) => {
  try {
    const jsonValue = JSON.stringify(data); // stringify before setting
    localStorage.setItem(name, jsonValue);
    return true;
  } catch (e) {
    console.log('error', e);
    return false;
  }
};

//Delete data from the storage
export const deleteItem = (name) => {
  try {
    localStorage.removeItem(name);
    return true;
  } catch (error) {
    console.log('error:', error);
    return false;
  }
};

//Clear local storage
export const clearLocalStorage = () => {
  try {
    localStorage.clear();
    return true;
  } catch (error) {
    console.log('error:', error);
    return false;
  }
};
