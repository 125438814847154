/** external deps */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Tooltip } from 'antd';

/** external deps */
import { DELETE, EDIT, ImportIcon, LeadsIcons, Trash, View } from 'assets/icons';
import {
  ContentHeader,
  TableComponent,
  IconButton,
  Filter,
  TabsComponent,
  CustomModal,
  SearchAPI,
  Toast,
  MultiUserInitial,
  MultiFilter,
  DrawerTabsForm,
} from 'components';
import { elementData } from 'elements';
import { SecureComponent } from 'permission-provider';
import { assignEmployee } from 'service/api-service';
import { Context } from 'store/context';
import { debounce, getCustomerFields } from 'utils';
import {
  FOLLOWUP_COLUMNS,
  ShowPreference,
  excelClientFields,
  formatColumnData,
  sortByKeys,
} from './data';
import { getQueryParams, rowCommonClass } from 'utils/common';
import ClientAdvanceAssignments from './client-component/ClientAdvanceAssignment';
import { lowerCase } from 'lodash';
import Button from 'components/button';
import { LabelValue } from 'screens/quotation-engine/components';
import ContactModal from './client-component/ContactModal';
import { NOTIFICATION_MSG } from 'constants';
import AddCustomer from './add-customer';
import dayjs from 'dayjs';
import { useBasicHelper } from 'utils/BasicStore';

const MIN_FIELDS = 5;
const MAX_FIELDS = 800;
const PAGE_SIZE = 10;

export const clientTableClass = (record, index) => {
  let colorClass;
  const currentDate = dayjs();
  const followup = dayjs(record?.follow_up);
  const isDatePassed = followup.isBefore(currentDate);
  colorClass = isDatePassed ? 'bg-red-200' : index % 2 === 0 ? 'bg-[#FFF]' : 'bg-[#FAFAFA]';
  return colorClass;
};

const tabs = [
  {
    key: 'retail',
    id: elementData.client.permissions.view,
    label: `Retail`,
    Component: () => null,
  },
  {
    key: 'corporation',
    id: elementData.client.permissions.view,
    label: `Corporation`,
    Component: () => null,
  },
  {
    key: 'followup',
    id: elementData.client.permissions.view,
    label: `Follow Ups`,
    Component: () => null,
  },
];

const CustomerManagement = (props = {}) => {
  let navigate = useNavigate();
  const permissions = props?.permissions || [];
  const { tab } = getQueryParams();
  const { user } = useContext(Context);
  //  states */
  const [loading, setLoading] = useState(false);
  const [viewContact, setViewContact] = useState({ modal: false, data: null });
  const [searchQuery, setSearchQuery] = useState('');
  const [columns, setColumns] = useState([]);
  const [curColumns, setCurColumns] = useState([]);
  const [key, setActiveTabKey] = useState(tab || 'retail');
  const [show, setShow] = useState(false);
  const [showSubmitModal, setSubmitModal] = useState(false);
  const [assignee, setAssignee] = useState();
  const [filteredValues, setFilteredValues] = useState({});
  const [drawerLoading, setDrawerLoading] = useState(false);
  const [followUpModel, setFollowUpModel] = useState({ model: false, id: null });
  const initialData = React.useRef([]);
  const {
    customers: { list: customers, updateCustomers, total: totalPages, pageNum },
  } = useContext(Context);
  const isHead =
    user?.department?.find((item) => item?.head?.id === user?.id) ||
    lowerCase(user?.user_type) === 'client';

  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  /** funcs */
  const onAddClient = () => {
    setId(null);
    setOpen(true);
  };
  const onClose = () => {
    setDrawerLoading(true);
    initialData.current = [''];
    setId(null);
    setOpen(false);
    setTimeout(() => {
      setDrawerLoading(false);
    }, 1000);
  };

  const onEditClick = (record) => {
    setId({ initialValues: record });
    setOpen(true);
  };

  const handlePagination = async (pageNum) => {
    setLoading(true);
    const query = `${searchQuery}&branch_id=${filteredValues?.branch || ''}&type=${key}&filter_by=${
      filteredValues?.client || ''
    }&lead_source=[${filteredValues.leadSource || []}]`;
    if (pageNum?.current) {
      await updateCustomers({ pageNum: pageNum.current, query, type: key });
    }
    setLoading(false);
  };

  const handleRowSelect = () => {
    setShow(true);
  };
  const toggleSubmit = () => {
    setSubmitModal((pre) => !pre);
  };
  const assignTo = (e) => setAssignee(e.value);
  const handleCancelAssignee = () => {
    setAssignee('');
  };
  const handleCancel = () => {
    localStorage.removeItem('selected');
    setShow(false);
  };

  const addAssign = async (data) => {
    setLoading(true);
    const retrievedIdArray = JSON.parse(localStorage.getItem('selected'));
    const finalPayload = {
      customers: retrievedIdArray,
      assign_to: assignee,
    };
    const resp = await assignEmployee({ data: finalPayload });
    if (resp?.data?.status) {
      handlePagination({ current: pageNum });
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleSubmit();
      localStorage.removeItem('selected');
      setShow(false);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };
  const handleSelect = (e, value) => {
    const currData = customers;
    const AllIdData = currData?.map((ele) => ele?.id);
    const selectedData = JSON.parse(localStorage.getItem('selected')) || [];
    if (e?.target?.checked) {
      const newValue = [...selectedData, value];
      localStorage.setItem('selected', JSON.stringify(newValue));
      getColumns(newValue, AllIdData);
    } else if (!e.target.checked) {
      const newArray = selectedData?.filter((item) => item !== value);
      localStorage.setItem('selected', JSON.stringify(newArray));
      getColumns(newArray, AllIdData);
    }
  };

  const handleAllSelect = (e, column) => {
    const currData = customers;
    const AllIdData = currData?.map((ele) => ele?.id);
    const prev = JSON.parse(localStorage.getItem('selected')) || [];
    if (e.target?.checked) {
      const final = prev?.length ? [...prev, ...AllIdData] : AllIdData;
      localStorage.setItem('selected', JSON.stringify(final));

      getColumns(final, AllIdData);
    } else {
      const curr = prev?.length ? prev?.filter((item) => !AllIdData.includes(item)) : null;
      localStorage.setItem('selected', JSON.stringify(curr));
      getColumns(curr, AllIdData);
    }
  };

  const getColumns = async (val = [], tableData) => {
    setLoading(true);
    const fields = await getCustomerFields();
    const formatedData = formatColumnData(fields, setViewContact);
    const sortKeys =
      key === 'retail'
        ? [
            'customer_code',
            'customer_name',
            'contact_number',
            'email_address',
            'branch_of',
            'assign_to_employee',
          ]
        : ['customer_code', 'corp_name', 'cp_name', 'cp_desg', 'contact_number', 'email_address'];

    const sortedCols = sortByKeys(formatedData, sortKeys);
    const retailFinalSorted = sortedCols?.filter(
      (ele) =>
        ele?.key === 'customer_name' ||
        ele?.key === 'contact_number' ||
        ele?.key === 'assign_to_employee' ||
        ele?.key === 'lead_source',
    );
    const corpFinalSorted = sortedCols?.filter(
      (ele) =>
        ele?.key === 'corp_name' ||
        ele?.key === 'cp_name' ||
        ele?.key === 'cp_name' ||
        ele?.key === 'contact_number',
    );
    setColumns(sortedCols);
    const finalSorted = key === 'retail' ? retailFinalSorted : corpFinalSorted;
    setCurColumns([
      {
        id: 1000,
        key: 'customer_code',
        dataIndex: 'customer_code',
        title: (
          <p className='flex gap-4'>
            {show && (
              <Checkbox
                checked={tableData?.every((item) => val?.includes(item))}
                onChange={(e) => handleAllSelect(e, 'client_code')}
              />
            )}{' '}
            Client Code
          </p>
        ),

        render: (data, record) => (
          <div className='flex gap-4 truncate'>
            {show && (
              <Checkbox
                checked={
                  val?.length && Array.isArray(val) ? val?.find((itm) => itm === record?.id) : false
                }
                onChange={(e) => handleSelect(e, record?.id)}
              />
            )}
            <Tooltip title={data?.name || data} className='cursor-pointer truncate'>
              {data?.name || data || '\u2014'}
            </Tooltip>
          </div>
        ),
      },
      ...finalSorted,
      {
        id: 1001,
        key: 'preference_product',
        dataIndex: 'preference_product',
        title: 'Preference Product',
        render: (data) => (
          <div className='truncate '>
            {' '}
            {Array.isArray(data) ? (
              <MultiUserInitial
                data={ShowPreference(data, 'preference_product')}
                showImage
                value='name'
              />
            ) : (
              <Tooltip title={data?.name || data} className='cursor-pointer'>
                {data?.name || data || '\u2014'}
              </Tooltip>
            )}
          </div>
        ),
      },
    ]);
    setLoading(false);
  };

  /* search function */
  const handleOnChange = debounce(async (e) => {
    setLoading(true);
    const query = e?.target?.value;
    setSearchQuery(query);
    await updateCustomers({
      pageNum,
      query,
      type: key,
      followup: key === 'followup' ? true : false,
    });
    setLoading(false);
  }, 700);

  /** filter by branch */
  const handlefilters = async () => {
    setLoading(true);
    if (key === 'followup') {
      await updateCustomers({ pageNum: 1, query: '', followup: true });
    } else {
      const query = `&branch_id=${filteredValues?.branch || ''}&type=${key}&filter_by=${
        filteredValues?.client || ''
      }&lead_source=[${filteredValues.leadSource || []}]`;
      await updateCustomers({ pageNum: 1, query, type: key });
    }
    // setLoading(true);
    // const resp = await getCustomers({
    //   query: `&branch_id=${filteredValues?.branch || ''}&type=${key}&filter_by=${
    //     filteredValues?.client || ''
    //   }&lead_source=[${filteredValues.leadSource || []}]`,
    // });
    // if (resp?.data?.status) {
    //   setBranchData(resp?.data?.data?.result || []);
    // }
    setLoading(false);
  };

  useEffect(() => {
    if (!customers?.length) updateCustomers();
  }, []);

  // useEffect(() => {
  //   return () => resetBasicStore();
  // }, [open]);

  const toggleFollowUpModel = () => {
    setFollowUpModel({ model: false, id: null });
  };

  const handleDeleteFollowUp = async () => {
    const resp = await updateCustomers({
      pageNum: 1,
      query: '',
      followup: true,
      method: 'delete',
      id: followUpModel?.id,
    });
    Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Follow up Deleted Successfully');
    await updateCustomers({ pageNum: 1, query: '', followup: true });

    setFollowUpModel({ model: false, id: null });
  };
  useEffect(() => {
    handlefilters();
  }, [key, filteredValues]);

  useEffect(() => {
    const prevSelected = JSON.parse(localStorage.getItem('selected'));
    const currData = customers;
    const AllIdData = currData?.map((ele) => ele?.id);
    getColumns(prevSelected, AllIdData);
  }, [key, show, customers]);

  useEffect(() => {
    localStorage.setItem('clientCurrColumns', JSON.stringify(curColumns));
  });
  const onImport = () => [navigate('/import-clients', { state: excelClientFields })];

  const onChange = (key) => {
    const url = '/client?';
    if (url) {
      window.history.replaceState(null, null, `${url}tab=${key}`);
    }
    setActiveTabKey(key);
    setShow(false);
  };

  return (
    <div id='customer' className='grid gap-3 '>
      {!!curColumns.length && (
        <ContentHeader
          title={<h2 className='text-s font-semibold'>Client Listing</h2>}
          permissions={permissions}
          buttonId={elementData.client.permissions.create}
          // data={breadCrumbs}
          btnText='+ Client'
          onClick={onAddClient}
          onChange={handleOnChange}
          extraRightRender={() => (
            <div className='absolute right-3 flex items-center '>
              <MultiFilter
                setFilteredValues={setFilteredValues}
                filterBy={['branch', 'assign', 'lead']}
              />
            </div>
          )}
        >
          <div className='flex gap-2  mr-1  self-end '>
            <Filter
              customStyle='!pt-0 !pb-2'
              removeValFrom={[20]}
              allColumns={columns}
              activeColumns={curColumns}
              updateActiveColumns={setCurColumns}
              minFields={MIN_FIELDS}
              maxFields={MAX_FIELDS}
            />
          </div>
        </ContentHeader>
      )}

      <div className='relative flex items-center  justify-between'>
        <TabsComponent
          items={tabs}
          activeKey={key}
          setActiveTabKey={setActiveTabKey}
          onChange={onChange}
          url='/client?'
          renderTabBar={true}
        />
        <div className='flex items-center '>
          {isHead && (
            <ClientAdvanceAssignments
              handleAssignEmployee={handleRowSelect}
              activeKey={key}
              update={updateCustomers}
              classses={'flex items-center gap-[10px]'}
              showActive={show}
              // classses='absolute right-28 -mt-14'
            />
          )}

          <div onClick={onImport} title='Import'>
            {key !== 'followup' ? <ImportIcon svgClasses={' h-[26px] w-[26px]'} /> : null}
          </div>
        </div>
      </div>

      <TableComponent
        total={totalPages}
        getRowClassName={clientTableClass}
        pageSize={PAGE_SIZE}
        onChange={handlePagination}
        loading={loading}
        scroll={{
          x: curColumns.length > 8 ? 3000 : 500,
        }}
        columns={
          !!curColumns.length
            ? (key === 'followup' ? FOLLOWUP_COLUMNS : curColumns).concat([
                {
                  title: 'Action',
                  key: 'action',
                  // align: 'right',
                  render: (_, record) => (
                    <div className=' flex gap-3'>
                      <IconButton
                        title='Create Lead'
                        permissions={permissions}
                        id={elementData.Leads.permissions.view}
                        className='group disabled:cursor-not-allowed h-5 w-5 '
                        onClick={() =>
                          navigate(`/lead/create/new/1`, { state: { customer: record } })
                        }
                      >
                        <LeadsIcons />
                      </IconButton>
                      <IconButton
                        title='View'
                        permissions={permissions}
                        className='group disabled:cursor-not-allowed'
                        id={elementData.client.permissions.view}
                        onClick={() => navigate(`/view-client/${record.id}`)}
                      >
                        <View svgClass='stroke-primary group-disabled:stroke-grey' />
                      </IconButton>
                      {key === 'followup' && (
                        <IconButton
                          title='Delete'
                          permissions={permissions}
                          className='group disabled:cursor-not-allowed'
                          id={elementData.client.permissions.delete}
                          onClick={() => setFollowUpModel({ model: true, id: record?.id })}
                        >
                          <DELETE svgClass='stroke-green-90 group-disabled:stroke-grey' />
                        </IconButton>
                      )}

                      {key !== 'followup' && (
                        <IconButton
                          title='Edit'
                          permissions={permissions}
                          className='group disabled:cursor-not-allowed'
                          id={elementData.client.permissions.delete}
                          onClick={() => onEditClick(record)}
                        >
                          <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                        </IconButton>
                      )}
                    </div>
                  ),
                },
              ])
            : []
        }
        dataSource={customers}
      />
      {show && (
        <div className='flex gap-4 w-fit m-auto justify-center -mt-12 z-50'>
          <Button
            variant='tertiary'
            classes='z-10 bg-grey-80'
            text='Cancel'
            onClick={handleCancel}
          />
          <Button
            variant='primary'
            disabled={!JSON.parse(localStorage?.getItem('selected'))?.length}
            classes='mr-4 z-10'
            text='Submit'
            onClick={toggleSubmit}
          />
        </div>
      )}
      <CustomModal
        width={500}
        height={200}
        destroyOnClose={true}
        open={showSubmitModal}
        onCancel={toggleSubmit}
        footer={''}
      >
        <LabelValue
          vertical={true}
          label='Assign To'
          value={
            <SearchAPI
              type='search'
              placeholder='Assign To'
              field={{ name: 'employee' }}
              defaultValue={assignee}
              onSelect={assignTo}
              divClasses='w-full bg-white'
              onIconClick={handleCancelAssignee}
            />
          }
        />
        <Button
          loading={loading}
          variant='black'
          classes='flex item-center justify-center mt-10 m-auto'
          text='Submit'
          onClick={addAssign}
        />
      </CustomModal>

      {/* followup Delete Modal */}
      <CustomModal
        open={followUpModel?.model}
        closable={true}
        onCancel={toggleFollowUpModel}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the Follow up details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleFollowUpModel}
            />
            <Button
              permissions={permissions}
              id={elementData.department.permissions.delete}
              text='Delete Follow up'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={handleDeleteFollowUp}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>

      <ContactModal viewContact={viewContact} setViewContact={setViewContact} />

      {/* Add/Edit Drawer */}
      <DrawerTabsForm
        id={id}
        open={open}
        onClose={onClose}
        title={id ? 'Edit Client' : 'Add Client'}
        width='80%'
        // items={EmployeeFields({
        //   maxDate,
        //   setIfscCode,
        //   bankDetails,
        //   error,
        //   setPinCodeError,
        //   pincodeError,
        // })}
        drawerLoading={drawerLoading}
        permissions={permissions}
        children={
          <AddCustomer
            refreshToken={open}
            // entryId={id ? { initialValues: id } : null}
            entryId={id}
            setDrawerLoading={setDrawerLoading}
            props={props}
            permissions={permissions}
            onClose={onClose}
            initialData={initialData}
          />
        }
        type='client'
      />
    </div>
  );
};

export default SecureComponent(CustomerManagement);
