import { Drawer, Button as AntButton } from 'antd';
import { ActivityIcon, TimeLineICon } from 'assets/icons';
import { Toast } from 'components/toast';
import UpcomingActivity from 'components/upcoming-activities';
import { NOTIFICATION_MSG } from 'constants';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getLeadTimeline } from 'service/api-service';
import { Context } from 'store/context';

const InquiryTimeline = ({ state, leadId }) => {
  const { id } = useParams();
  const { user } = useContext(Context);
  const [timeLine, setTimeLine] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onGettimeline = async () => {
    setLoading(true);
    const model = state === 'pos' ? 'UserInquiry' : 'inquiry';
    const resp = await getLeadTimeline({
      model,
      model_id: id ?? leadId,
    });
    if (resp?.data?.status) {
      setTimeLine(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }

    setLoading(false);
  };

  useEffect(() => {
    onGettimeline();
  }, []);

  return (
    <>
      {/* <AntButton onClick={showDrawer} className='border-none flex items-center p-0'> */}
      <TimeLineICon svgClasses='cursor-pointer h-8 w-8 ' onClick={showDrawer} />
      {/* </AntButton> */}
      <Drawer title='Lead Timeline' placement='right' onClose={onClose} open={open}>
        <UpcomingActivity
          filterActivities={timeLine}
          noDataAction={onGettimeline}
          loading={loading}
          timeLine={timeLine}
          heading={'Recent Activities'}
          user={user}
          activityClass='!h-full'
        />
      </Drawer>
    </>
  );
};

export default InquiryTimeline;
