import { CustomSwitch, TextInput } from 'components';

const fields = {
  'Edit Clause': {
    fields: [
      {
        label: 'Name',
        name: 'name',
        id: 'name',
        placeholder: 'Name',
        type: 'text',
        required: true,
        isMultiline: true,
        rows: 2,
        divClasses: 'col-span-full',
        component: TextInput,
      },
      {
        label: 'Status',
        name: 'status',
        id: 'status',
        component: CustomSwitch,
        checkedChildren: 'Active',
        unCheckedChildren: 'Inactive',
      },
    ],
    showAccordionHeader: false,
  },
};

export { fields };
