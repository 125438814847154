import { TextInput, SelectInput, SearchAPI } from 'components';
import { getCityOptions, getCountryOptions, getStateOptions } from 'data/form-data';

const countryOptions = getCountryOptions();

const addBranchFields = {
  'Branch Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Branch Name',
        name: 'name',
        id: 'name',
        placeholder: 'Branch Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Branch HR',
        name: 'b_hr',
        id: 'b_hr',
        placeholder: 'Branch HR',
        type: 'select',
        component: SearchAPI,
      },
      {
        label: 'Address',
        name: 'address',
        id: 'address',
        placeholder: 'Address',
        type: 'text',
        required: true,
        component: TextInput,
        isMultiline: true,
        maxLength: 60,
      },
      {
        label: 'Country',
        name: 'country',
        id: 'country',
        placeholder: 'Select Country',
        type: 'select',
        required: true,
        searchable: true,
        component: SelectInput,
        options: countryOptions,
        customChange: (_, __, form) => {
          form?.setFieldValue('city', '');
        },
      },
      {
        label: 'State',
        name: 'state',
        id: 'state',
        placeholder: 'State',
        type: 'select',
        component: SelectInput,
        required: true,
        // component: TextInput,
        // maxLength: 30,
        options: [],
        dependency: ['country'],
        dependantFetchOptions: async (form) => {
          if (!form?.values?.country) return [];
          const country = form?.values?.country;
          const resp = await getStateOptions(country);
          if (resp) {
            return resp;
          } else return [];
        },
      },
      {
        label: 'City/Town',
        name: 'city',
        id: 'city',
        placeholder: 'City/Town',
        type: 'select',
        component: SelectInput,
        required: true,
        // component: TextInput,
        // maxLength: 30,
        options: [],
        dependency: ['country', 'state'],
        dependantFetchOptions: async (form) => {
          if (!form?.values?.country || !form?.values?.state) return [];
          const country = form?.values?.country;
          const state = form?.values?.state;
          const resp = await getCityOptions(country, state);
          if (resp) {
            return resp;
          } else return [];
        },
      },
      {
        label: 'Pincode',
        name: 'pincode',
        id: 'pincode',
        placeholder: 'Pincode',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 10,
      },
    ],
  },
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Master',
    // path: "/",
  },
  {
    name: 'Branches',
    path: '',
  },
];

export { addBranchFields, breadcrumbObj };
