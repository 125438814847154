import ViewLeads from './leads-components/view-leads';
import LeadsModule from './leads-components/add-edit';
import { useParams } from 'react-router-dom';

const Leads = ({ permissions }) => {
  const params = useParams();

  return params.mode === 'view' ? <ViewLeads /> : <LeadsModule permissions={permissions} />;
};

export default Leads;
