import { Select } from 'antd';
import { CSVIcon, DropdownIcon, ExcelIcon, PdfIcon } from 'assets/icons';
import React, { useCallback } from 'react';
import { CSVLink } from 'react-csv';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const DocExport = ({ data, file, id, type = 'p', showIcons = false }) => {
  const OPTIONS = [
    {
      label: <span>Export As PDF</span>,
      value: 'pdf',
    },
    {
      label: <span>Export As Excel</span>,
      value: 'excel',
    },
    {
      label: (
        <CSVLink data={data} fileName={file}>
          Export As CSV
        </CSVLink>
      ),
      value: 'csv',
    },
  ];
  /* excel and csv */
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (data, file) => {
    if (data.length > 0) {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const dataw = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(dataw, file + fileExtension);
    }
  };

  const handeSelect = useCallback(
    (value) => {
      if (value === 'excel') {
        exportToCSV(data, file);
      } else if (value === 'pdf') {
        handleDownloadPdf();
      }
    },
    [data, file],
  );
  /* PDF */
  const handleDownloadPdf = async () => {
    const input = document.getElementById(id);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        format: [type === 'landscape' ? 500 : 300, type === 'landScape' ? 300 : 450], // Custom width and height in inches
        orientation: type, // Optionally set to landscape orientation
      });
      pdf.addImage(imgData, 'JPEG', 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save(`${file}.pdf`);
    });
  };

  return showIcons ? (
    <div className='flex gap-4'>
      <div
        onClick={() => exportToCSV(data, file)}
        className='cursor-pointer h-8 w-8 flex justify-center items-center bg-white rounded-md'
      >
        <ExcelIcon svgClasses='flex  h-6 w-6 mt-1 ml-1' />
      </div>
      <CSVLink data={data} filename={file}>
        <div className='cursor-pointer h-8 w-8 bg-white rounded-md'>
          <CSVIcon svgClasses='ml-0.5  pt-0.5' />
        </div>
      </CSVLink>
      <div
        onClick={() => handleDownloadPdf()}
        className='cursor-pointer h-8 w-8 bg-white rounded-md'
      >
        <PdfIcon svgClasses='mt-0.5 ml-1.5 pt-0.5 ' />
      </div>
    </div>
  ) : (
    <Select
      className='grid items-center group border border-grey-90 hover:border-primary-90 hover:text-primary-90 rounded px-2'
      options={OPTIONS}
      defaultValue={OPTIONS[1]}
      suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
      onSelect={handeSelect}
      dropdownAlign={{ offset: [-10, 2] }}
      align='left'
    />
  );
};

export default DocExport;
