import React, { useState } from 'react';
import { passwordFields } from './data';
import { Button, FormContainer, Toast } from 'components';
import { prepareFormFieldsData } from 'utils';
import { changeUserPassword } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';

const PasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: passwordFields,
  });

  const authorization = JSON.parse(localStorage.getItem('AUTH')).access_token;

  const onFinish = async (data) => {
    const resp = await changeUserPassword({ data, authorization });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      window.location.reload();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to Change Password');
    }
  };

  return (
    <div className='h-[75vh] overflow-y-auto w-[84%] bg-white rounded-lg p-4'>
      <div className='flex justify-center items-center flex-col'>
        <span className='text-xl font-medium'>Change Password</span>
        <span className='text-grey-90 text-x mb-8 text-center max-w-l font-medium'>
          Enter your new password in the field below to Change your password.
        </span>
      </div>

      <FormContainer
        accordionClass='grid grid-cols-2 gap-8 '
        onSubmit={onFinish}
        initialValues={initialValues}
        fields={finalFields}
        customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
          return (
            <>
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={resetForm}
                />
                <Button
                  disabled={!isValid}
                  text={'Change Password'}
                  variant='primary'
                  classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default PasswordForm;
