import { CircleSvg } from 'assets/icons';
// import dayjs from 'dayjs';
import React from 'react';
import { Bar } from 'react-chartjs-2';

const PerformanceChart = ({ data }) => {
  let datail = {
    present: data?.some(({ status }) => status === 'present'),
    absent: data?.some(({ status }) => status === 'absent'),
    weekend: data?.some(({ status }) => status === 'weekend'),
    leave: data?.some(({ status }) => status === 'leave'),
  };

  const barGraphData = {
    labels: Object.keys(datail),
    datasets: [
      {
        data: Object.values(datail),
        backgroundColor: ['#FFBD39', '#FA423B', '#707070', '#e2e2e2', 'e2e2e2'],
        borderColor: ['#FFBD39', '#FA423B', '#707070', '#e2e2e2', 'e2e2e2'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className='pt-2 px-4'>
      <p className='font-medium mt-4'>Attendence</p>
      <div className='flex items-center w-full border-2 shadow-custom rounded-xl px-4 py-2'>
        <Bar
          data={barGraphData}
          height={250}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>

      {/* <div className='grid grid-cols-2 text-grey-90 text-xxs font-medium gap-3 pt-4 '>
        <div className='flex flex-row items-center gap-1'>
          <CircleSvg className='w-3 fill-[#25CBED]' />
          On Time:
          <span>{attendance?.on_time_count}</span>
        </div>
        <div className='flex flex-row items-center gap-1'>
          <CircleSvg className='w-3 fill-[#FFBD39]' />
          Late:<span>{attendance?.late_count}</span>
        </div>
        <div className='flex flex-row items-center gap-1'>
          <CircleSvg className='w-3 fill-[#FA423B]' />
          Absent: <span>{attendance?.total_absences}</span>
        </div>
        <div className='flex flex-row items-center gap-1'>
          <CircleSvg className='w-3 fill-[#707070]' />
          Overtime: <span>{attendance?.overtime_count}</span>
        </div>
        <div className='flex flex-row items-center gap-1'>
          <CircleSvg className='w-3 fill-[#707070]' />
          Leaves: <span>{leaves}</span>
        </div>
      </div>
      <div className='grid grid-cols-2 text-grey-90 text-xxs font-medium gap-3 pt-4 '>
        <h2 className='col-span-2'>Hours</h2>
        <div className='flex flex-row items-center gap-1'>
          <CircleSvg className='w-3 fill-[#FFBD39]' />
          Late:<span>{(attendance?.total_late_hours / 3600).toFixed(2)}</span>
        </div>
        <div className='flex flex-row items-center gap-1'>
          <CircleSvg className='w-3 fill-[#707070]' />
          Overtime: <span>{(attendance?.total_overtime_hours / 3600).toFixed(2)}</span>
        </div>
      </div> */}
    </div>
  );
};

export default PerformanceChart;
