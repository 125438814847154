import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { CustomModal, FormContainer, Button, TableComponent, IconButton, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { deleteEmergency, getEmergency, addEmergency, editEmergency } from 'service/api-service';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { addEmergencyContactFields, emergencyContactColumns } from './data';
import { rowCommonClass } from 'utils/common';

const EmergencyContact = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [editInitials, setInitials] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [initialValues, finalFields] = prepareFormFieldsData({ fields: addEmergencyContactFields });

  /* funcs  */
  const toggleAddModal = () => {
    setInitials(undefined);
    setShowAddModal((pre) => !pre);
  };
  const toggleDeleteModal = () => setDeleteModal((pre) => !pre);

  /* get emergency table list */
  const getEmergencyList = async () => {
    setLoading(true);
    const resp = await getEmergency();
    if (resp?.data?.status) {
      setTableData(resp?.data?.data);
    }
    setLoading(false);
  };

  /*   add emergency */
  const onAddEmergency = async (data) => {
    setLoading(true);
    const resp = await addEmergency({ data: data });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      getEmergencyList();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
    setShowAddModal(false);
  };

  /*   update emergency */
  const onEditEmergency = async (data) => {
    setLoading(true);
    const editFields = getEditedFields({
      initialValues: editInitials,
      updatedData: data,
    });
    if (Object.keys(editFields).length) {
      const resp = await editEmergency({
        id: selectedRow?.id,
        data: editFields,
      });
      toggleAddModal();
      if (resp?.data?.status) {
        getEmergencyList();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  /* Delete emergency */
  const handleDeleteEmergency = async () => {
    const id = selectedRow?.id;
    if (!id) return;
    setLoading(true);
    const resp = await deleteEmergency({ id, data: { deleted: true } });
    if (resp?.data?.status) {
      getEmergencyList();
      Toast('success', NOTIFICATION_MSG.success, 'Emergency contact deleted successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteModal();
    setLoading(false);
  };

  /*   edit record */
  const handleEditClick = (record) => {
    const finalInitials = {
      name: record?.name,
      home_phone: record?.home_phone,
      office_phone: record?.office_phone,
      mobile: record?.mobile,
      relationship: record?.relationship,
    };
    setInitials(finalInitials);
    setSelectedRow(record);
    setShowAddModal(true);
  };

  useEffect(() => {
    getEmergencyList();
  }, []);

  return (
    <main>
      <div className='bg-white rounded-2xl rounded-b-none p-4 flex justify-between items-center'>
        <h2 className='font-semibold text-l'>Emergency</h2>
        <div className='flex gap-4'>
          <Button text='+ Contact' variant='primary' classes='xl:px-6' onClick={toggleAddModal} />
        </div>
      </div>
      <TableComponent
        getRowClassName={rowCommonClass}
        columns={[
          ...emergencyContactColumns,
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            fixed: 'right',
            render: (_, record) => (
              <Space size='middle'>
                <IconButton
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    handleEditClick(record);
                  }}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>

                <IconButton
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    setSelectedRow(record);
                    toggleDeleteModal();
                  }}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton>
              </Space>
            ),
          },
        ]}
        dataSource={tableData}
        showPagination={false}
      />

      {/* add Contact modal */}
      <CustomModal
        title={editInitials ? 'Edit Contact' : 'Add Contact'}
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddModal}
        footer={''}
      >
        <div>
          <FormContainer
            accordionClass='grid grid-cols-2  gap-8'
            onSubmit={editInitials ? onEditEmergency : onAddEmergency}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddModal();
                    }}
                  />
                  <Button
                    text={editInitials ? 'Update Contact' : 'Add Contact'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting || !isValid ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                    disabled={!isValid}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* Delete Modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the Education details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              text='Delete Contact'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={handleDeleteEmergency}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </main>
  );
};

export default EmergencyContact;
