import React from 'react';
import clsx from 'clsx';

import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import { DefaultUser } from 'assets';

const PerformaceCalulator = ({ data, employee }) => {
  const leave = data?.data?.some(({ status }) => status === 'leave' || status === 'absent') || 0;
  const percentage = 100 - (leave / data?.data?.length).toFixed(2) * 100;

  return (
    <div>
      <div className='flex justify-between items-center p-4'>
        <div className='flex gap-6  items-center'>
          <div className='w-24 h-24 rounded-full overflow-hidden'>
            <img src={employee.image ? employee.image : DefaultUser} alt={employee?.name} />
          </div>
          <div className='text-xxs text-grey-60'>
            <p>{employee?.name}</p>
            <p>{employee?.contact_number}</p>
            <p>{employee?.email}</p>
          </div>
        </div>
        <div className='flex gap-4 items-center'>
          <div
            className={clsx('shadow-custom rounded-lg p-4 text-center w-max m-auto text-white', {
              'bg-green-600': percentage > 79,
              'bg-orange-600': percentage > 40 && percentage < 80,
              'bg-red-90': percentage < 40,
              'opacity-0': !data?.data?.length,
            })}
          >
            <div className='flex gap-6 items-center'>
              <h2 className='text-4xl font-bold'>{percentage || 0}%</h2>
              <div>
                {percentage > 79 ? (
                  <SmileOutlined className='text-4xl' />
                ) : percentage > 40 ? (
                  <FrownOutlined className='text-4xl' />
                ) : (
                  <MehOutlined className='text-4xl' />
                )}
              </div>
            </div>
            <p>Attendance</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformaceCalulator;
