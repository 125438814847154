import React from 'react';
import { useNavigate } from 'react-router-dom';

const ViewPolicyLink = ({ record }) => {
  const navigate = useNavigate();
  return (
    <>
      <button
        className='text-primary-90 '
        onClick={() => {
          navigate(`/view-policy/${record?.policy?.id}`, {
            state: { initialValues: record },
          });
        }}
      >
        {record?.policy?.policy_num}
      </button>
    </>
  );
};

export default ViewPolicyLink;
