import React, { useEffect, useState } from 'react';

import { Toast } from 'components/toast';
import UpcomingActivity from 'components/upcoming-activities';
import { NOTIFICATION_MSG } from 'constants';
import { getTaskTimeline } from 'service/api-service';

const Activity = ({ id }) => {
  /* state */
  const [timeLine, setTimeLine] = useState([]);
  const [loading, setLoading] = useState(false);

  const onGettimeline = async () => {
    setLoading(true);
    const resp = await getTaskTimeline({ id });
    if (resp?.data?.status) {
      setTimeLine(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    onGettimeline();
  }, []);

  return (
    <div>
      <UpcomingActivity
        // activityClass={'h-[400px]'}
        noDataAction={onGettimeline}
        loading={loading}
        timeLine={timeLine}
        heading={'Recent Activities'}
      />
    </div>
  );
};

export default Activity;
