import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_Decode from 'jwt-decode';

import SelectCard from './SelectCard';
import { getProductsByCategory, getTrainingMaterial } from 'service/api-service';
import { SecureComponent } from 'permission-provider';
import { ActionCard, Loader, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';

const PosHome = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [CardData, setCardData] = useState([]);
  const [category, setCategory] = useState([]);
  const Auth = localStorage.getItem('AUTH');
  const token = JSON.parse(Auth);
  const decode = jwt_Decode(token?.access_token);
  if (!token || decode?.pos_agent === false) {
    navigate('/pos-login');
  }

  const renderCards = (item) => {
    return (
      <ActionCard key={item.id} title={item.title} description={item.description} data={item} />
    );
  };

  const getPosTrainingList = async () => {
    setLoading(true);
    const resp = await getTrainingMaterial();

    if (resp?.data?.status) {
      setCardData(resp?.data?.data);
    }
    setLoading(false);
  };

  const getCategoryProducts = async (data) => {
    setLoading(true);
    const resp = await getProductsByCategory({ method: 'get' });
    if (resp?.data?.status) {
      const allProducts = resp?.data?.data?.map((i) => {
        return {
          text: i?.name,
          icon: i?.logo,
          href: '/',
          productDetails: {
            category: i?.ins_category?.name,
            categoryId: i?.ins_category?.id,
            formId: i?.formid || i?.formId?.id,
            label: i?.name,
            value: i?.id,
            id: i?.id,
          },
        };
      });
      setCategory(allProducts);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    getCategoryProducts();
    getPosTrainingList();
  }, []);
  if (loading) {
    return <Loader />;
  }

  return (
    <div className='pb-10 space-y-4'>
      <SelectCard productOptions={category} token={decode} />

      <p className='text-xl font-semibold'>Training</p>
      <div className=' grid grid-cols-1 md:grid-cols-4 w-full gap-3'>
        {CardData.map(renderCards)}
      </div>
    </div>
  );
};

export default SecureComponent(PosHome);
