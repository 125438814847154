import Request from './client';

export const getRaisedTickets = async ({ query = '', page = 1, status }) => {
  const resp = await Request({
    method: 'get',
    API: `inventory/ticket?page=${page}&q=${query}&status=${status}`,
  });
  return resp;
};

export const getMyTickets = async ({ id, query = '', page = 1 }) => {
  const resp = await Request({
    method: 'get',
    API: 'inventory/ticket?id=' + id + '&q=' + query + '&page=' + page,
  });
  return resp;
};

export const addTicket = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: 'inventory/ticket',
    data,
  });
  return resp;
};

export const deleteMyTicket = async ({ id, data }) => {
  const resp = await Request({
    method: 'patch',
    API: 'inventory/ticket?id=' + id,
    data,
  });
  return resp;
};

export const updateMyTicket = async ({ id, data }) => {
  const resp = await Request({
    method: 'patch',
    API: 'inventory/ticket?id=' + id,
    data,
  });
  return resp;
};
