import { TextInput, SelectInput, Uploadbox } from 'components';

const options = [
  { label: 'Video', value: 'video' },
  { label: 'Document', value: 'document' },
  { label: 'Link', value: 'link' },
];

const addPosTrainingFields = {
  payRole: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Name',
        name: 'title',
        id: 'title',
        placeholder: 'Title',
        type: 'search',
        required: true,
        component: TextInput,
      },
      {
        label: 'Description',
        name: 'description',
        id: 'description',
        placeholder: 'Description',
        type: 'search',
        required: true,
        component: TextInput,
      },
      // {
      //   label: "Asset",
      //   name: "asset",
      //   id: "asset",
      //   placeholder: "Asset",
      //   type: "search",
      //   required: true,
      //   component: TextInput,
      // },
      {
        label: 'Asset Type',
        // defaultValue: "link",
        name: 'asset_type',
        id: 'asset_type',
        placeholder: 'Asset Type',
        type: 'search',
        required: true,
        component: SelectInput,
        options: options,
      },
      {
        dependent: true,
        dependent_on: 'asset_type',
        dependent_value: 'link',
        label: 'Link',
        name: 'asset',
        id: 'asset',
        placeholder: 'Enter your link',
        type: 'text',
        component: TextInput,
      },
      // {
      //   label: "Asset Url",
      //   name: "asset",
      //   id: "asset",
      //   placeholder: "Asset Url",
      //   required: true,
      //   component: TextInput,
      //   accept: [".png, .jpeg, .jpg, .pdf, .txt , .doc"],
      // },
      {
        dependent: true,
        dependent_on: 'asset_type',
        dependent_value: 'video',
        label: 'Upload Asset',
        name: 'asset',
        id: 'asset',
        placeholder: 'asset',
        // required: true,
        component: Uploadbox,
        // accept: [".png, .jpeg, .jpg, .pdf, .txt , .doc"],
        divClasses: 'col-span-2',
      },
      {
        dependent: true,
        dependent_on: 'asset_type',
        dependent_value: 'document',
        label: 'Upload Asset',
        name: 'asset',
        id: 'asset',
        placeholder: 'asset',
        required: true,
        component: Uploadbox,
        // accept: [".png, .jpeg, .jpg, .pdf, .txt , .doc"],
        divClasses: 'col-span-2',
      },
    ],
  },
};

const breadcrumb = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Pos Training',
    path: '',
  },
];

const learningassets = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Pos Training',
    path: '/training',
  },
  {
    name: 'Learning asset',
    path: '',
  },
];

export { addPosTrainingFields, breadcrumb, learningassets };
