import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DocumentViewer, IconButton, TableComponent } from 'components';
import { elementData } from 'elements';
import { DocumentIcon, EDIT, View } from 'assets/icons';
import { useStore } from 'store/context';
import { policyManagement } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const AllPolicies = ({
  permissions,
  columns,
  onEditPolicy,
  activeTabKey,
  query = '',
  dateFilterValues,
}) => {
  const navigate = useNavigate();
  const [opened, dispatch] = useStore((state) => state?.dialerOpened);
  const [curActiveRecordDoc, setCurActiveRecordDoc] = useState(null);
  const [policyData, setPolicyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const isViewDocModalOpen = Boolean(curActiveRecordDoc);

  const handleCloseViewDocModal = () => setCurActiveRecordDoc(null);

  const toggleDialer = useCallback(
    (e, contact) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (opened) {
        dispatch({ type: 'DIALER_CLOSE' });
      } else {
        dispatch({ type: 'DIALER_OPEN', contact });
      }
    },
    [opened, dispatch],
  );

  const fetchData = async ({ q = '', page = 1, dates } = {}) => {
    setLoading(true);
    const resp = await policyManagement({
      method: 'get',
      page: page,
      q: q,
      customDate: dates,
    });
    if (resp?.data) {
      setPolicyData(resp?.data?.data);
    }
    setLoading(false);
  };

  const handlePagination = (pageNum) => {
    if (pageNum?.current) {
      fetchData({ page: pageNum?.current, dates: dateFilterValues });
    }
  };

  useEffect(() => {
    fetchData({ q: query, dates: dateFilterValues });
  }, [query, dateFilterValues]);

  return (
    <div className='mt-4'>
      <TableComponent
        loading={loading}
        onChange={handlePagination}
        scroll={{
          x: columns?.length > 8 ? 3000 : 500,
        }}
        getRowClassName={rowCommonClass}
        columns={[
          ...columns,
          {
            title: 'Action',
            key: 'action',
            // align: 'right',
            width: '200px',
            render: (_, record) => (
              <div className='flex gap-2'>
                {/* <IconButton
                title='Transaction'
                permissions={permissions}
                className='group disabled:cursor-not-allowed'
                id={elementData.policy.permissions.update}
                onClick={() => {
                  navigate(`/transactions/${record?.id}`, {
                    state: { policyRecord: record },
                  });
                }}
              >
                <TransactionIcon />
              </IconButton> */}
                {/* <IconButton
                title='Send Reminder'
                permissions={permissions}
                className='group disabled:cursor-not-allowed'
                id={elementData.policy.permissions.view}
              >
                <MultiEmail
                  text={<MailIcon />}
                  button={false}
                  titleClasses={'underline cursor-pointer'}
                  emailData={record?.customer?.email_address || ''}
                  mailSubject='Renewal Reminder'
                  summary={`Hello ${
                    record?.customer?.name || record?.customer?.corp_name || ''
                  },\nHope you are doing great. We are just reminding you about your ${
                    record?.product_type?.name
                  } Insurance renewal. The renewal date is ${
                    record?.renewal
                  }. If you have any questions, please feel free to reach out to us.`}
                />
              </IconButton> */}
                {/* 
              <IconButton
                title='Call'
                permissions={permissions}
                className='group disabled:cursor-not-allowed'
                onClick={(e) => toggleDialer(e, record?.customer?.contact_number)}
              >
                <PhoneIcon />
              </IconButton> */}
                <IconButton
                  title='View Attachment'
                  className='group disabled:cursor-not-allowed'
                  id={elementData.policy.permissions.view}
                  disabled={!record.doc}
                  onClick={() =>
                    setCurActiveRecordDoc(
                      record.doc ? `${process.env.REACT_APP_DOC_URL}${record.doc}` : null,
                    )
                  }
                >
                  <DocumentIcon className='w-6 h-6 fill-primary-90' />
                </IconButton>
                <IconButton
                  title='View'
                  className='group disabled:cursor-not-allowed'
                  id={elementData.policy.permissions.view}
                  onClick={() => {
                    navigate(`/view-policy/${record.id}`, {
                      state: { initialValues: record, activekey: activeTabKey },
                    });
                  }}
                >
                  <View svgClass='stroke-primary group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Edit'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.policy.permissions.update}
                  onClick={() => {
                    onEditPolicy(record);
                  }}
                >
                  <EDIT />
                </IconButton>
              </div>
            ),
          },
        ]}
        dataSource={policyData?.result}
        noDataAction={fetchData}
        pageSize={10}
        total={policyData?.total_page}
        defaultPage={policyData?.current_page}
      />
      <DocumentViewer
        src={curActiveRecordDoc}
        onClose={handleCloseViewDocModal}
        visible={isViewDocModalOpen}
      />
    </div>
  );
};

export default AllPolicies;
