/** external deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Space } from 'antd';
import Fuse from 'fuse.js';

/** internal deps */
import { EDIT, View } from 'assets/icons';
import { TableComponent, IconButton, Toast, ViewData, SearchComponent, Button } from 'components';
import { SecureComponent } from 'permission-provider';
import { contactManagement } from 'service/api-service';
import AddContact from './add-contact';
import { formatViewData } from './view-contact/data';
import { elementData } from 'elements';
import { NOTIFICATION_MSG } from 'constants';
import { rowCommonClass } from 'utils/common';

const PAGE_SIZE = 10;

const Contacts = (props = {}) => {
  const { id: customer_id } = useParams();
  const permissions = props?.permissions || [];
  const customerData = props?.customerData || {};

  /* states */
  const [addContact, setAddContact] = useState(false);
  const [viewContact, setViewContact] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [contacts, setContacts] = useState([]);
  const [curAddEntry, setCurAddEntry] = useState(null);

  /* view data */
  const data = formatViewData(selectedRow);

  /** funcs */
  const handleAddContact = (record) => {
    setCurAddEntry(record);
    setAddContact(true);
  };
  const handleViewData = () => setViewContact(true);

  const goBack = () => {
    setAddContact(false);
    setViewContact(false);
    setCurAddEntry(null);
  };

  // search table data
  const onChange = (e) => {
    if (!e.target.value) {
      return setSearchQuery('');
    }
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(contacts, {
      keys: ['name', 'contact_number', 'other_phone'],
      threshold: 0.2,
    }).search(e.target.value);
    setFilteredContacts(fuse.map((i) => i.item));
  };
  const columns = [
    {
      key: 'customer_name',
      title: 'Client Name',
      dataIndex: 'customer_name',
      // render: (created_by) => created_by?.name,
    },
    {
      key: 'branch_of',
      title: 'Branch',
      dataIndex: 'branch_of',
      render: (data) => data?.name,
    },
    {
      key: 'email_address',
      title: 'Email',
      dataIndex: 'email_address',
      // render: (days) => days,
    },
    {
      key: 'contact_number',
      title: 'Contact',
      dataIndex: 'contact_number',
    },
    {
      key: 'date_of_birth',
      title: 'D.O.B',
      dataIndex: 'date_of_birth',
    },
    {
      key: 'assign_to_employee',
      title: 'Created By',
      dataIndex: 'assign_to_employee',
      render: (data) => data?.name,
    },
    {
      title: 'Action',
      key: 'action',
      // align: 'right',
      render: (_, record) => (
        <div className='flex gap-2 '>
          <IconButton
            title='View'
            permissions={permissions}
            id={elementData.client.permissions.view}
            className='group disabled:cursor-not-allowed'
            onClick={() => {
              setSelectedRow(record);
              handleViewData();
            }}
          >
            <View svgClass='stroke-primary group-disabled:stroke-grey' />
          </IconButton>

          <IconButton
            title='Edit'
            id={elementData.client.permissions.update}
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            onClick={handleAddContact.bind(null, record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
        </div>
      ),
    },
  ];

  const onGetContacts = async (pageNum) => {
    setLoading(true);
    const resp = await contactManagement({ method: 'get', customer_id, pageNum: pageNum || 1 });
    if (resp?.data?.status) {
      const list = resp.data.data?.result;
      setContacts(list);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to fetch contacts from server');
    }
    setLoading(false);
  };

  const handlePagination = (pageNum) => (pageNum?.current ? onGetContacts(pageNum.current) : '');

  useEffect(() => {
    // getColumns();
    onGetContacts();
  }, []);

  if (addContact)
    return (
      <AddContact
        id={elementData.client.permissions.create}
        customer_id={props?.customer_id}
        permissions={permissions}
        getContacts={onGetContacts}
        goBack={goBack}
        initials={curAddEntry}
        customerData={customerData}
      />
    );
  if (viewContact)
    return (
      <div className='bg-white rounded-2xl p-4 mt-4'>
        <ViewData pageTitle='Contact Details' data={data} showGoToBack={true} goBack={goBack} />
      </div>
    );

  return (
    <main className='mt-4'>
      <div className='bg-white rounded-2xl rounded-b-none p-4 flex justify-between items-center'>
        <h2 className='font-semibold text-l'>Contacts</h2>
        <div className='flex gap-4'>
          <SearchComponent onChange={onChange} />
          <Button
            permissions={permissions}
            id={elementData.client.permissions.create}
            text='+ Contact'
            variant='primary'
            classes='xl:px-6'
            onClick={handleAddContact.bind(null, null)}
          />
        </div>
      </div>
      <div>
        {/* {!!curColumns.length && ( */}
        <TableComponent
          loading={loading}
          noDataAction={onGetContacts}
          onChange={handlePagination}
          getRowClassName={rowCommonClass}
          columns={columns}
          dataSource={searchQuery ? filteredContacts : contacts}
          pageSize={PAGE_SIZE}
          total={contacts.length}
        />
        {/* )} */}
      </div>
    </main>
  );
};

export default SecureComponent(Contacts);
