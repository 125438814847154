import { Divider } from 'antd';
import React, { useState } from 'react';
// import { showDates } from '../data';
import { CustomModal, DocumentViewer, IconButton, TableComponent } from 'components';
import { elementData } from 'elements';
import { showDates } from '../data';
import { rowCommonClass } from 'utils/common';
import Button from 'components/button';

const assigneeColumns = [
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'user',
    className: 'capitalize',
    render: (value) => value?.name || '--',
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (_, record) => (
      <div className='flex items-center'>
        <div
          className={`w-3 h-3 rounded-md mr-2 ${
            record?.status?.toLowerCase() === 'approved'
              ? 'bg-green-90'
              : record?.status?.toLowerCase() === 'declined'
              ? 'bg-red-90'
              : 'bg-yellow'
          }`}
        ></div>
        <span className='capitalize'>{record?.status}</span>
      </div>
    ),
  },
  {
    key: 'd_reason',
    title: 'Reason / Note',
    dataIndex: 'd_reason',
    render: (value, record) =>
      record?.status?.toLowerCase() === 'approved' ? record?.note || '--' : value || '--',
  },
];

const LabelValue = ({ label, value }) => (
  <div className='flex flex-col'>
    <div className='border-1 opacity-70'>{label}</div>
    <div className='break-all capitalize text-xs font-semibold'>{value}</div>
  </div>
);

const IMAGE_TYPES = ['png', 'jpg', 'jpeg'];

const ViewModal = ({
  open,
  onCancel,
  record,
  assignees,
  loading,
  leaveDocs,
  data,
  toggleFwdModal,
  leaveStatus,
  onReason,
  toggleApprModal,
  isRequesting,
  permissions,
}) => {
  const [curActiveRecordDoc, setCurActiveRecordDoc] = useState(null);
  const isViewDocModalOpen = Boolean(curActiveRecordDoc);
  const handleCloseViewDocModal = () => setCurActiveRecordDoc(null);

  return (
    <CustomModal
      title={
        <div className='flex gap-2 items-center'>
          <h1>Acknowledge Leave Request</h1>
          {record?.emrgncy && (
            <span className='text-xxxs bg-red py-1 px-2 rounded-md text-white'>Emergency</span>
          )}
        </div>
      }
      open={open}
      width={1000}
      footer={null}
      onCancel={onCancel}
    >
      <Divider />
      <div className='grid grid-cols-3 gap-4 '>
        <LabelValue label='Request by' value={record?.created_by?.name} />
        <LabelValue label='Total days' value={record?.days} />
        <LabelValue label='Leave' value={record?.paid ? 'Paid' : 'Unpaid'} />
        <LabelValue
          label='Leave Dates'
          value={
            <div className='flex flex-wrap gap-1'>
              {showDates(record)}
              {/* {!!record?.dates.length &&
                record?.dates?.map((date) => {
                  return <div className='p-1 bg-grey-30 rounded-md text-xxxs'>{date}</div>;
                })} */}
            </div>
          }
        />
        <div className='overflow-auto max-h-[60px] col-span-2'>
          <LabelValue label='Reason' value={record?.reason} />
        </div>
      </div>
      <Divider />
      {!!assignees.length && (
        <div className='mb-4'>
          <span className='text-semibold opacity-70'>Assignees</span>
          <TableComponent
            loading={loading}
            columns={assigneeColumns}
            dataSource={assignees}
            getRowClassName={rowCommonClass}
            showPagination={false}
          />
        </div>
      )}

      {!leaveDocs?.length && (
        <span className='self-center text-center'>No documents uploaded.</span>
      )}
      {!!leaveDocs?.length && (
        <div>
          <span className='text-semibold opacity-70'>Documents</span>
          <div className='grid grid-cols-3 gap-x-2 gap-y-4'>
            {leaveDocs?.map((doc, index) => {
              const ext = doc.doc.split('.').pop();
              const isImage = IMAGE_TYPES.includes(ext);
              return (
                <div key={index} className='flex flex-col gap-2'>
                  <span>
                    {index + 1}.{doc?.doc_name}
                  </span>
                  <Button text='Preview' onClick={() => setCurActiveRecordDoc(doc?.doc)} />
                  <DocumentViewer
                    src={curActiveRecordDoc}
                    onClose={handleCloseViewDocModal}
                    visible={isViewDocModalOpen}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      <Divider />
      {record?.status?.toLowerCase() === 'declined' && (
        <>
          <LabelValue label='Reason to Decline' value={data?.d_reason} />
          <Divider />
        </>
      )}
      {!isRequesting && (
        <div className='flex justify-end items-center'>
          &nbsp;
          <IconButton
            id={elementData.leaves_request.permissions.update}
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            onClick={toggleFwdModal}
          >
            <div className='border border-grey-40 rounded-md px-2 py-1 hover:border-primary-60 hover:text-primary-60'>
              Forward
            </div>
          </IconButton>
          &nbsp;
          {leaveStatus !== 'approved' && (
            <IconButton
              id={elementData.leaves_request.permissions.update}
              permissions={permissions}
              className='group disabled:cursor-not-allowed'
              onClick={() => toggleApprModal()}
            >
              <div className='bg-success text-white border rounded-md px-2 py-1'>Approve</div>
            </IconButton>
          )}
          &nbsp;
          {leaveStatus !== 'declined' && (
            <IconButton
              id={elementData.leaves_request.permissions.update}
              permissions={permissions}
              className='group disabled:cursor-not-allowed'
              onClick={onReason}
            >
              <div className='bg-danger text-white border rounded-md px-2 py-1'>Decline</div>
            </IconButton>
          )}
        </div>
      )}
    </CustomModal>
  );
};

export default ViewModal;
