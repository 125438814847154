import { getLabelByValue } from 'utils';
import formOptions from 'components/forms/formOptions';
import { CustomFieldArray, SelectDropDown, TextInput, Uploadbox } from 'components';

const productColumns = [
  {
    title: 'Product Icon',
    dataIndex: 'logo',
    key: 'logo',
    render: (logo) => (
      <img src={process.env.REACT_APP_DOC_URL + logo} className='h-10 w-10' alt={logo} />
    ),
  },
  {
    title: 'Product Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Form Type',
    dataIndex: 'formid',
    key: 'formid',
    render: (text) => text?.name || getLabelByValue(formOptions, text) || '\u2014',
  },
];

const productFields = ({ formOption, addCoverage, setAddCoverage }) => ({
  'Product Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Product Type',
        name: 'name',
        id: 'name',
        placeholder: ' Product Type',
        type: 'text',
        maxLength: 60,
        required: true,
        component: TextInput,
      },
      {
        label: 'Product Code',
        name: 'product_code',
        id: 'product_code',
        placeholder: ' Product Code',
        type: 'text',
        maxLength: 60,
        component: TextInput,
      },
      {
        label: 'Form Type',
        name: 'formid',
        id: 'formid',
        placeholder: ' Form Type',
        type: 'select',
        required: true,
        nodeTitle: true,
        component: SelectDropDown,
        customOnChange: (field, value, form) => {
          form?.setFieldValue('form_type', value);
        },
        options: formOption,
      },
      {
        label: 'Product Icon',
        name: 'logo',
        id: 'logo',
        type: 'image',
        required: true,
        component: Uploadbox,
        uploadNoteClass: 'flex flex-row items-center justify-center gap-2',
        accept: ['.png', '.svg', '.jpg', '.jpeg'],
        divClasses: 'h-32 col-span-2',
      },
    ],
  },
  Coverages: {
    showAccordionHeader: false,
    fields: [
      {
        name: 'coverages',
        type: 'fieldArray',
        id: 'coverage_name',
        addButtonText: '+ Coverage',
        valueSchema: { name: '', amount: '' },
        component: CustomFieldArray,
        divClasses: 'col-span-full',
        gridCols: 'grid-cols-3 py-2',
        label: <label className='text-xs xl:text-sm text-primary-90'>Coverages</label>,
        fields: [
          {
            name: 'name',
            id: 'name',
            label: 'Coverage Name',
            type: 'text',
            placeholder: 'Coverage Name',
            component: TextInput,
            customOnChange: ({ field, value, form }) => {
              if (value) {
                setAddCoverage(true);
              } else {
                setAddCoverage(false);
              }
            },
          },
          {
            name: 'amount',
            id: 'amount',
            label: 'Coverage Amount',
            placeholder: 'Coverage Amount',
            type: 'text',
            required: addCoverage,
            component: TextInput,
          },
        ],
      },
    ],
  },
});

export { productColumns, productFields };
