import React, { useState } from 'react';
import Sidebar from './sidebar';
import PasswordForm from './passwordform';
import CompanyForm from './companyform';

const Setting = () => {
  const [form, setForm] = useState('PasswordForm');

  return (
    <div className='flex justify-between bg-grey-light'>
      <Sidebar setForm={setForm} form={form} />
      {form === 'PasswordForm' && <PasswordForm />}
      {form === 'ComapnyForm' && <CompanyForm />}
    </div>
  );
};

export default Setting;
