import { CurrencyInput, SearchAPI, TextInput } from 'components';

const adjusterBreads = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Adjuster',
    path: '/adjuster',
  },
];

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Company Name',
    dataIndex: 'company',
    key: 'company',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Insurer',
    dataIndex: 'insurer_id',
    key: 'insurer_id',
    render: (text) => text || '\u2014',
  },

  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (text) => text || '\u2014',
  },
];

//add Adjuster fields
const addAdjusterBreadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Adjuster',
    path: '/adjuster',
  },
  {
    name: 'Add Adjuster',
    path: '',
  },
];

//add Contact fields
const adjusterFields = {
  'Adjuster Infromation': {
    fields: [
      {
        label: 'Adjuster Name',
        name: 'name',
        id: 'name',
        placeholder: 'Adjuster Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 40,
      },
      {
        label: 'Company Name',
        name: 'company',
        id: 'company',
        placeholder: 'Company Name',
        type: 'text',
        component: TextInput,
        maxLength: 40,
      },
      {
        label: 'Insurer',
        name: 'insurer_id',
        id: 'insurer_id',
        placeholder: 'Insurer',
        type: 'search',
        required: true,
        component: SearchAPI,
      },
      {
        label: 'Telephone Number',
        name: 'tel_no',
        id: 'tel_no',
        placeholder: 'Telephone Number',
        type: 'tel',
        component: TextInput,
        maxLength: 10,
      },
      {
        label: 'Email Address',
        name: 'email',
        id: 'email',
        placeholder: 'Email Address',
        type: 'text',
        required: true,
        component: TextInput,
        // maxLength: 40,
      },
      {
        label: 'Website',
        name: 'website',
        id: 'website',
        placeholder: 'Website',
        type: 'url',
        component: TextInput,
        maxLength: 70,
      },
      {
        label: 'Adjuster Fee',
        name: 'fee',
        id: 'fee',
        placeholder: 'Adjuster Fee',
        type: 'currency',
        required: true,
        component: CurrencyInput,
        maxLength: 70,
      },
      {
        label: 'Assign To',
        name: 'assign_employee',
        id: 'assign_employee',
        placeholder: 'Assign To',
        type: 'search',
        required: true,
        component: SearchAPI,
      },
    ],
  },
  Description: {
    fields: [
      {
        label: 'Description',
        name: 'description',
        id: 'description',
        placeholder: 'Add your Description here....',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        maxLength: 300,
        rows: 2,
        divClasses: 'col-span-full',
      },
    ],
  },
};

export { columns, adjusterFields, addAdjusterBreadCrumbs, adjusterBreads };
