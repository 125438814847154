import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DatePicker, Divider, Select, Space } from 'antd';
import dayjs from 'dayjs';
import { DropdownIcon } from 'assets/icons';
import { getInsurerList, searchApis } from 'service/api-service';
import { componentOptions, DateRangeOptions } from '../data';
import { Context } from 'store/context';
import { ProductTypePicker } from 'components';

const { RangePicker } = DatePicker;
const { Option } = Select;

const currentDate = dayjs();
const dateBefore30Days = currentDate.subtract(30, 'day');
const dateBefore3Months = currentDate.subtract(3, 'month');
const dateBefore6Months = currentDate.subtract(6, 'month');
const dateBefore1Year = currentDate.subtract(1, 'year');
const SELECT_ALL_OPTION = {
  value: '',
  label: 'All',
};

const FilterBar = ({
  setEndDate,
  setStartDate,
  setInsurer,
  setEmployee,
  selectedComponent,
  setSelectedBranch,
  setSelectedComponent,
  setProductType,
}) => {
  const { user } = useContext(Context);
  const userBranches = user?.branch?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
  const [insurer, setInsurerList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [empLoading, setEmpLoading] = useState(false);
  const [showdateRange, setShowDateRange] = useState(false);

  const fetchInsurer = async ({ page = 1, query = '' } = {}) => {
    setLoading(true);
    const resp = await getInsurerList({ page, query });
    if (resp?.data?.data) {
      setInsurerList(
        resp?.data?.data?.result?.map((item) => ({ ...item, label: item?.name, value: item?.id })),
      );
    }
    setLoading(false);
  };

  const onSelectInsurer = (value, data) => {
    setInsurer(data);
    setShowFilter(true);
  };
  const onSearchInsurer = (searchTxt) => {
    fetchInsurer({ query: searchTxt });
  };

  const fetchEmployees = async ({ page = 1, query = '' } = {}) => {
    setEmpLoading(true);
    const resp = await searchApis({ query: query, api: 'employee' });
    if (resp?.data?.status) {
      setEmpList(resp?.data?.data?.map((item) => ({ label: item?.name, value: item?.id })));
    }
    setEmpLoading(false);
  };

  const onSelectEmp = (value) => {
    setEmployee(value);
  };
  const onSearchEmp = (searchTxt) => {
    fetchEmployees({ query: searchTxt });
  };

  const onSelectDateRange = useCallback(
    (e) => {
      const dateRanges = {
        'last 30 days': dateBefore30Days,
        'last 3 months': dateBefore3Months,
        'last 6 months': dateBefore6Months,
        'last 1 year': dateBefore1Year,
      };

      if (e !== 'customRange') {
        setEndDate(dayjs().format('YYYY-MM-DD'));
      }

      if (dateRanges.hasOwnProperty(e)) {
        setStartDate(dayjs(dateRanges[e]).format('YYYY-MM-DD'));
        setShowDateRange(false);
      } else if (e === 'customRange') {
        setShowDateRange(true);
      }
    },
    [setStartDate, setEndDate],
  );

  const handleSelectcomponent = (e) => {
    if (selectedComponent.includes('all')) {
      setSelectedComponent(e.filter((item) => item !== 'all'));
    } else if (e.includes('all')) {
      setSelectedComponent(['all']);
    } else if (e.includes('graph') || e.includes('table')) {
      setSelectedComponent(e.filter((opt) => opt !== 'all'));
    } else {
      setSelectedComponent(e);
    }
  };

  const handleCustomRange = (e) => {
    const startDate = dayjs(e[0]).format('YYYY-MM-DD');
    const endDate = dayjs(e[1]).format('YYYY-MM-DD');
    if (startDate && endDate) {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const onSelectBranch = (id) => {
    setSelectedBranch(id);
  };

  useEffect(() => {
    fetchInsurer();
    fetchEmployees();
  }, []);

  return (
    <div
      id='reports-details'
      className='w-full bg-white bordered h-fit flex gap-2 px-4 justify-between items-center flex-wrap'
    >
      <Space wrap>
        <Select
          className='truncate max-w-[150px]'
          loading={loading}
          bordered={false}
          // options={insurer}
          showSearch
          onSearch={onSearchInsurer}
          onSelect={onSelectInsurer}
          placeholder='Select Insurer'
          // style={{
          //   width: 120,
          // }}
          notFoundContent={loading ? 'Loading...' : 'No options found'}
          defaultActiveFirstOption={false}
          filterOption={false}
        >
          {insurer.map((option) => (
            <Option key={option.value} value={option.value} {...option}>
              {option.label}
            </Option>
          ))}
        </Select>
        {showFilter && (
          <>
            <Divider orientation='center' type='vertical' className='h-8' />

            <Select
              bordered={false}
              options={DateRangeOptions}
              onSelect={onSelectDateRange}
              placeholder='Select Date'
              defaultValue='last 30 days'
              // style={{
              //   width: 120,
              // }}
            />
            <Divider orientation='center' type='vertical' className='h-8' />

            {showdateRange && (
              <>
                <div id='dashboard'>
                  <RangePicker onChange={handleCustomRange} allowClear={false} />
                </div>
                <Divider orientation='center' type='vertical' className='h-8' />
              </>
            )}

            <Select
              bordered={false}
              options={[SELECT_ALL_OPTION, ...(userBranches || [])]}
              onSelect={onSelectBranch}
              placeholder='Select Branch'
              style={{
                // width: 120,
                fontSize: 12,
              }}
            />

            <Divider orientation='center' type='vertical' className='h-8' />

            <ProductTypePicker
              classes='!w-max !border-0'
              onChange={setProductType}
              showSelectAll={true}
            />

            <Divider orientation='center' type='vertical' className='h-8' />

            <Select
              loading={empLoading}
              bordered={false}
              showSearch
              onSearch={onSearchEmp}
              onSelect={onSelectEmp}
              placeholder='Select Employee'
              notFoundContent={empLoading ? 'Loading...' : 'No options found'}
              defaultActiveFirstOption={false}
              filterOption={false}
            >
              {[SELECT_ALL_OPTION, ...(empList || [])]?.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </>
        )}
      </Space>
      <Select
        mode='multiple'
        options={componentOptions}
        value={selectedComponent}
        className='min-w-[170px] activitySelect group !bg-[#F9F9F9] !text-grey-90 rounded px-2'
        placeholder='Select Component'
        popupClassName='min-w-24'
        dropdownAlign={{ offset: [-5, 15] }}
        suffixIcon={<DropdownIcon svgClass={'w-2 fill-grey-90'} />}
        onChange={handleSelectcomponent}
      />
    </div>
  );
};

export default FilterBar;
