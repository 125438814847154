import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { formatDate } from 'utils';
import { Link } from 'react-router-dom';
import {
  ContentHeader,
  TableComponent,
  Toast,
  TabsComponent,
  MultiUserInitial,
  IconButton,
  DocumentViewer,
} from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { getInterviewList, updateInterview } from 'service/api-service';
import { breadCrumbs } from './data';
import { TableStatus } from 'components/table/TableStatus';
import { elementData } from 'elements';
import { rowCommonClass } from 'utils/common';
import { ViewCvIcon } from 'assets/svg';
//import { link } from 'fs';

export const APPLICATION_STATUS = {
  ON_HOLD: 'on_hold',
  HIRED: 'hired',
  REJECTED: 'rejected',
  PENDING: 'pending',
};

export const InterviewStatusList = [
  {
    label: <span className='w-full text-left'>Pending</span>,
    key: APPLICATION_STATUS.PENDING,
  },
  {
    label: <span className='w-full text-left'>Hired</span>,
    key: APPLICATION_STATUS.HIRED,
  },

  {
    label: <span className='w-full text-left'>Rejected</span>,
    key: APPLICATION_STATUS.REJECTED,
  },
  {
    label: <span className='w-full text-left'>On Hold</span>,
    key: APPLICATION_STATUS.ON_HOLD,
  },
];

const tabs = [
  {
    key: 'pending',
    label: `Pending`,
    Component: () => null,
  },
  {
    key: 'rejected',
    label: `Rejected`,
    Component: () => null,
  },

  {
    key: 'hired',
    label: `Hired`,
    Component: () => null,
  },
  {
    key: 'on_hold',
    label: `On Hold`,
    Component: () => null,
  },
];

const Interviewmanagement = (props = {}) => {
  const permissions = props?.permissions || {};
  const [tabledata, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabKey, setActiveTabKey] = useState('pending');
  const [curActiveRecordDoc, setCurActiveRecordDoc] = useState(null);
  const isViewDocModalOpen = Boolean(curActiveRecordDoc);
  const handleCloseViewDocModal = () => setCurActiveRecordDoc(null);

  const onStatusChange = async (e, record) => {
    try {
      const status = e?.key;

      if (String(status).toLowerCase() === String(record?.status).toLowerCase()) return;
      setLoading(true);

      const resp = await updateInterview({ id: record?.id, data: { status } });

      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, 'Job Recruitment Updated Successfully');
        getAllInterviews();
      } else Toast('error', NOTIFICATION_MSG.error, 'Failed To Updated Job Recruitment');
    } catch (error) {}
    setLoading(false);
  };

  const Columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => (
        <p className='truncate justify-center flex w-auto text-black'>
          {formatDate({
            dateString: date,
            toUtc: true,
            format: 'MMMM DD, YYYY h:mm A',
          })}
        </p>
      ),
    },
    {
      title: 'Designation',
      dataIndex: 'app_id',
      key: 'designation',
      render: (data) => {
        return data?.designation;
      },
    },
    {
      title: 'Candidate Name',
      dataIndex: 'app_id',
      key: 'app_id',
      render: (data) => data?.name,
    },
    {
      title: 'Assigned Person',
      dataIndex: 'employees',
      key: 'employees',
      render: (data) => (
        <div className='truncate'>
          {' '}
          <MultiUserInitial data={data} value='name' label='employee' />
        </div>
      ),
    },
    {
      title: 'CV',
      key: 'cv',
      render: (_, record) => {
        return (
          <IconButton title='view cv' onClick={() => setCurActiveRecordDoc(record?.app_id?.cv)}>
            <ViewCvIcon />
          </IconButton>
        );
      },
    },
    {
      title: 'Mode of Interview',
      dataIndex: 'app_id',
      key: 'interview_type',
      render: (data) => data?.type,
    },
    {
      title: 'Meeting Link',
      key: 'link',
      dataIndex: 'app_id',
      render: (data, colData) => {
        return data?.type === 'online' ? (
          <Link
            to={colData?.link.includes('https') ? colData.link : `https://${colData.link}`}
            target='_blank'
          >
            <button
              disabled={tabKey !== 'pending'}
              className='bg-primary-90 text-white bordered px-2 py-1  rounded-lg disabled:bg-grey disabled:cursor-not-allowed'
            >
              Join Meeting
            </button>
          </Link>
        ) : (
          <div className='pl-7 text-primary-90'>NA</div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        return (
          <TableStatus
            permissions={permissions}
            id={elementData.manageRecruitment.permissions.update}
            onChange={onStatusChange}
            record={record}
            items={InterviewStatusList}
            status={status}
          />
        );
      },
    },
  ];

  const getAllInterviews = async () => {
    setLoading(true);
    const resp = await getInterviewList({ status: tabKey });
    if (resp?.data?.status) {
      setTableData(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllInterviews();
  }, [tabKey]);

  return (
    <div className='grid gap-0'>
      <ContentHeader
        // data={breadCrumbs}
        title={<p className='font-semibold leading-normal text-s'>Interviews Listing</p>}
        showSearch={true}
      />
      <TabsComponent items={tabs} activeKey={tabKey} setActiveTabKey={setActiveTabKey} />
      <div className='bg-white w-full p-2 rounded-2xl h-full'>
        <TableComponent
          getRowClassName={rowCommonClass}
          columns={Columns}
          dataSource={tabledata}
          loading={loading}
        />
      </div>

      <DocumentViewer
        src={curActiveRecordDoc}
        onClose={handleCloseViewDocModal}
        visible={isViewDocModalOpen}
      />
    </div>
  );
};
export default Interviewmanagement;
