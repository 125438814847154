import { DateTime, SearchAPI } from 'components';
// import { searchApis } from 'service/api-service';

export const breadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Interviews',
    path: '',
  },
];

export const interviewFields = {
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Assigned To',
        name: 'assign_to_employee',
        id: 'assign_to_employee',
        placeholder: 'Select Employe',
        required: true,
        type: 'serach',
        component: SearchAPI,
        maxLength: 30,
        // divClasses: "col-span-2",
      },
      {
        label: 'Designation',
        name: 'designation',
        id: 'desigation',
        placeholder: 'Select Designation',
        type: 'serach',
        required: true,
        component: SearchAPI,
        maxLength: 30,
        // divClasses: "col-span-2",
      },
      {
        label: 'Date',
        name: 'date',
        id: 'date',
        placeholder: 'Select Date',
        type: 'text',
        required: true,
        component: DateTime,
        maxLength: 30,
        // divClasses: "col-span-2",
      },
    ],
  },
};
