import { SearchAPI, TextInput } from 'components';

const addPayRoleFields = {
  payRole: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Employee',
        name: 'employee',
        id: 'employee',
        placeholder: 'Employee Name',
        type: 'search',
        required: true,
        component: SearchAPI,
      },
      {
        label: 'Package(CTC) ',
        name: 'package',
        id: 'package',
        placeholder: 'Package(CTC) ',
        type: 'input',
        required: true,
        component: TextInput,
      },
      {
        label: 'Taxes',
        name: 'taxes',
        id: 'taxes',
        placeholder: 'Taxes',
        type: 'input',
        required: true,
        component: TextInput,
      },
      {
        label: 'Pending Payment',
        name: 'pending_payment',
        id: 'pending_payment',
        placeholder: 'Pending Payment',
        type: 'input',
        required: true,
        component: TextInput,
      },
      {
        label: 'Overtime Hours',
        name: 'overtime_hours',
        id: 'overtime_hours',
        placeholder: 'OverTime Hours',
        type: 'input',
        required: true,
        component: TextInput,
      },

      {
        label: 'Provident Fund',
        name: 'provident_fund',
        id: 'provident_fund',
        placeholder: 'Provident Fund',
        type: 'input',
        required: true,
        component: TextInput,
      },
    ],
  },
};

const breadcrumb = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Pay Role',
    path: '',
  },
];

export { breadcrumb, addPayRoleFields };
