/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Space } from 'antd';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  TableComponent,
  IconButton,
  Toast,
  Module,
} from 'components';
import { addExpenseTypeFields } from './data';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { getEditedFields, getPermission, prepareFormFieldsData } from 'utils';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { expenseTypeManagement } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { rowCommonClass } from 'utils/common';

const ExpenseType = (props = {}) => {
  const permissions = props?.permissions || [];
  const showTypeModal = props?.showTypeModal;
  const setTypeModal = props?.setTypeModal;
  /* states  */
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [editInitials, setInitials] = useState();
  const [selectedExpense, setSelectedExpense] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addExpenseTypeFields,
  });

  const columns = [
    {
      key: 'label',
      title: 'Label',
      dataIndex: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.expense_type.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.expense_type.permissions.delete}
            onClick={() => {
              setSelectedExpense(record);
              toggleDeleteExpense();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  /* funcs */

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await expenseTypeManagement({ method: 'get' });
    if (resp?.data?.data) {
      setTableData(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const onAddExpense = useCallback(async (data) => {
    setLoading(true);
    const resp = await expenseTypeManagement({ method: 'post', data: data });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleAddExpense();
      fetchData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, []);

  const onDeleteExpense = useCallback(async () => {
    setLoading(true);
    const resp = await expenseTypeManagement({ method: 'delete', id: selectedExpense?.id });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Expense deleted successfully');
      fetchData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteExpense();
    setLoading(false);
  }, [selectedExpense]);

  const onEditExpense = useCallback(
    async (data) => {
      setLoading(true);
      const editFields = getEditedFields({ initialValues: editInitials, updatedData: data });
      if (Object.keys(editFields).length) {
        const resp = await expenseTypeManagement({
          data: editFields,
          method: 'patch',
          id: selectedExpense?.id,
        });

        if (resp?.data?.status) {
          Toast(
            'success',
            NOTIFICATION_MSG.success,
            resp?.data?.msg || 'Expense updated successfully.',
          );
          fetchData();
        } else {
          Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
        }
        setLoading(false);
        toggleAddExpense();
      } else {
        setLoading(false);
        Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save.');
      }
    },
    [selectedExpense, editInitials],
  );

  const toggleAddExpense = useCallback(() => {
    setInitials(undefined);
    setTypeModal((pre) => !pre);
  }, []);

  const toggleDeleteExpense = useCallback(() => setDeleteModal((pre) => !pre), []);

  const onEditPress = useCallback((record) => {
    setInitials(record);
    setSelectedExpense(record);
    setTypeModal(true);
  }, []);

  return (
    <div className='grid gap-3'>
      {getPermission(permissions, elementData.expense_type.permissions.view)?.read ? (
        <>
          <TableComponent
            loading={loading}
            columns={columns}
            dataSource={tableData}
            getRowClassName={rowCommonClass}
          />

          {/* delete expense confirm modal */}
          <CustomModal
            open={showDeleteModal}
            closable={true}
            onCancel={toggleDeleteExpense}
            footer={''}
            width={450}
          >
            <div className='flex justify-center flex-col items-center py-4 2xl:py-14  '>
              <Trash className='h-16 w-16' />
              <span className='text-l xl:text-xl'>Are you sure?</span>
              <span className='text-center text-grey-80 text-xs pb-3'>
                Could you please confirm that you really wish to delete the expense type?
              </span>
              <div className='pt-4 flex flex-row justify-center bg-white'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={toggleDeleteExpense}
                />
                <Button
                  text='Delete Expense'
                  variant='primary'
                  classes={`ml-6 px-8   
                 bg-black
                `}
                  onClick={onDeleteExpense}
                  loading={loading}
                />
              </div>
            </div>
          </CustomModal>

          {/* add expense modal */}
          <CustomModal
            destroyOnClose={true}
            title={editInitials ? 'Edit Expense Type' : 'Add Expense Type'}
            open={showTypeModal}
            onCancel={toggleAddExpense}
            footer={''}
            width={600}
          >
            <div>
              <span>
                {editInitials ? 'To edit ' : 'To add a new '}expense type, enter the details in the
                input field below.
              </span>
              <FormContainer
                accordionClass='grid gap-8'
                onSubmit={editInitials ? onEditExpense : onAddExpense}
                initialValues={editInitials || initialValues}
                fields={finalFields}
                customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
                  return (
                    <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                      <Button
                        text='Cancel'
                        variant='text'
                        type='reset'
                        classes='border border-gray-90 text-black px-6'
                        onClick={() => {
                          resetForm();
                          toggleAddExpense();
                        }}
                      />
                      <Button
                        disabled={!isValid}
                        permissions={permissions}
                        id={
                          editInitials
                            ? elementData.expense_type.permissions.update
                            : elementData.expense_type.permissions.create
                        }
                        text={editInitials ? 'Update Expense Type' : 'Add Expense Type'}
                        variant='primary'
                        classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                        onClick={submitForm}
                        loading={loading}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </CustomModal>
        </>
      ) : (
        <Module />
      )}
    </div>
  );
};

export default SecureComponent(ExpenseType);
