import React, { useState } from 'react';
import Button from 'components/button';
import { NOTIFICATION_MSG } from 'constants';
import { Trash } from 'assets/icons';
import { CustomModal, Toast } from 'components';
import { deleteLeaveType } from 'service/api-service';

const DeleteLeaveTypeModal = ({
  showDeleteModal,
  toggleDeleteLeaveType,
  selectedLeaveType,
  getLeavesType,
}) => {
  const [loading, setLoading] = useState(false);

  const onDeleteLeaveType = async () => {
    setLoading(true);
    const id = selectedLeaveType?.id;
    if (!id) return;
    const resp = await deleteLeaveType({ id });
    if (resp?.data?.status) {
      getLeavesType();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleDeleteLeaveType();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  return (
    <CustomModal
      open={showDeleteModal}
      closable={true}
      onCancel={toggleDeleteLeaveType}
      footer={''}
      width={450}
    >
      <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
        <Trash className='h-16 w-16' />
        <span className='text-l xl:text-xl'>Are you sure?</span>
        <span className='text-center text-grey-80 text-xs pb-3'>
          Could you please confirm that you really wish to delete the leave type?
        </span>
        <div className='pt-4 flex flex-row justify-center bg-white'>
          <Button
            text='Cancel'
            variant='text'
            type='reset'
            classes='border border-gray-90 text-black px-6'
            onClick={toggleDeleteLeaveType}
          />
          <Button
            text='Delete Leave Type'
            variant='primary'
            classes='ml-6 px-8 bg-black'
            onClick={onDeleteLeaveType}
            loading={loading}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteLeaveTypeModal;
