import {
  Checkbox,
  CircularProgressBar,
  DateTime,
  SearchAPI,
  SelectInput,
  TextInput,
} from 'components';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { targetTypeManagement } from 'service/api-service';

export const DURATION_OPS = [
  { label: 'Monthly', value: 'M' },
  { label: 'Quarterly', value: 'Q' },
  { label: '6 Months', value: 'SM' },
  { label: 'Yearly', value: 'Y' },
];

dayjs.extend(customParseFormat);

const dateFormat = 'YYYY-MM-DD';

export const addGoalFields = ({ setEndDateRequired, endRequired }) => ({
  Target: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Goal Type',
        name: 'type',
        id: 'type',
        type: 'select',
        options: [],
        fetchOptions: async () => targetTypeManagement({ method: 'get' }),
        component: SelectInput,
        required: true,
      },
      {
        label: 'Number of Consignments',
        name: 'field',
        id: 'field',
        component: TextInput,
        placeholder: 'Number of Consignments',
        required: true,
        type: 'natural',
      },
      {
        label: 'Start Date',
        name: 'start',
        id: 'start',
        placeholder: 'YYYY-MM-DD',
        type: 'date',
        required: true,
        showTime: false,
        component: DateTime,
        maxLength: 30,
        disablePrev: true,
      },
      {
        label: 'Repetitive',
        name: 'repetitive',
        id: 'repetitive',
        placeholder: 'Repetitive',
        type: 'checkbox',
        component: Checkbox,
        customOnChange: (name, e, value) => setEndDateRequired(!e),
        divClasses: ' self-end',
      },
      {
        dependent: true,
        dependent_on: 'repetitive',
        dependent_value: true,
        label: 'Duration Type',
        name: 'durations',
        id: 'durations',
        placeholder: 'Duration',
        type: 'select',
        // required: true,
        component: SelectInput,
        options: DURATION_OPS,
      },
      {
        dependent: true,
        dependent_on: 'repetitive',
        dependent_value: false,
        label: 'End Date',
        name: 'end',
        id: 'end',
        placeholder: 'YYYY-MM-DD',
        type: 'date',
        required: endRequired,
        showTime: false,
        component: DateTime,
        maxLength: 30,
        disableCustomDate: 'start',
      },
      {
        label: 'Assign to ',
        name: 'employee',
        id: 'employee',
        placeholder: 'Assign to',
        type: 'search',
        component: SearchAPI,
        required: true,
      },
      {
        label: 'Description',
        name: 'desp',
        id: 'desp',
        placeholder: 'Add your Description here....',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        maxLength: 300,
        rows: 4,
        divClasses: 'col-span-full',
      },
    ],
  },
});

export const columns = [
  {
    title: 'Department',
    dataIndex: 'department',
    key: 'department',
    id: 'department',
    render: (val) => <span>{val?.name}</span> || '\u2014',
    displayFilter: ['assigned'],
  },
  {
    title: 'Assignee',
    dataIndex: 'employee',
    key: 'employee',
    className: 'capitalize',
    id: 'name',
    render: (val) => (val?.name ? <span>{val?.name}</span> : '\u2014'),
    displayFilter: ['assigned'],
  },
  {
    title: 'Goal Type',
    dataIndex: 'type',
    key: 'type',
    id: 'type',
    render: (val, record) => {
      if (record?.repetitive) return <span>{val?.name}</span>;
    },
  },
  {
    title: 'Due Date',
    dataIndex: 'end',
    key: 'end',
    id: 'end',
    render: (data, record) => {
      if (record?.repetitive) {
        const duration = DURATION_OPS.find((op) => op.value === record?.durations);
        return <span>{duration?.label ?? '-'}</span>;
      }

      return dayjs(data).format('MMM DD');
    },
  },
  {
    key: 'progress',
    title: 'Progress',
    render: (val) => {
      var init = val?.count;
      var total = val?.field;
      var percent = (init / total) * 100;
      return <CircularProgressBar percent={percent} />;
    },
  },
];

export const targetsBreads = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Goals',
    path: '/goals',
  },
];

export const getRanges = ({
  unit,
  fromFilters = false,
  isReverse = false,
  start = '',
  end = '',
}) => {
  if (!isReverse) {
    if (fromFilters) {
      if (unit === 'Monthly')
        return [dayjs().subtract(1, 'M').format(dateFormat), dayjs().format(dateFormat)];
      if (unit === 'Yearly')
        return [dayjs().subtract(1, 'y').format(dateFormat), dayjs().format(dateFormat)];
      if (unit === 'Quarterly')
        return [dayjs().subtract(3, 'M').format(dateFormat), dayjs().format(dateFormat)];
      if (unit === '6 Months')
        return [dayjs().subtract(6, 'M').format(dateFormat), dayjs().format(dateFormat)];
      if (unit === 'All') return ['', ''];
    } else {
      if (unit === 'M') return [dayjs().format(dateFormat), dayjs().add(1, 'M').format(dateFormat)];
      if (unit === 'y') return [dayjs().format(dateFormat), dayjs().add(1, 'y').format(dateFormat)];
      if (unit === 'q') return [dayjs().format(dateFormat), dayjs().add(3, 'M').format(dateFormat)];
      if (unit === '6m')
        return [dayjs().format(dateFormat), dayjs().add(6, 'M').format(dateFormat)];
    }
  } else {
    const dateEnd = dayjs(end);
    const dateStart = dayjs(start);
    const dif = dateEnd.diff(dateStart, 'month');

    if (dif <= 1) return 'M';
    if (dif <= 3) return 'q';
    if (dif <= 6) return '6m';
    if (dif <= 12) return 'y';
  }
};
