import React, { useCallback, useState } from 'react';
import InstaTemplate from './template';
import { Button, Form, Input, Upload } from 'antd';
import { PictureFilled } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { updateJob } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { Toast } from 'components/toast';

const SocialPost = (props = {}) => {
  const isPublished = props?.selectedAsset?.publish_status;
  const [loading, setLoading] = useState(false);

  const data = props?.selectedAsset;

  const updateTitle = useCallback(
    (key) => (evt) => {
      let value = typeof evt === 'string' ? evt : evt.target.value || '';
      props?.setTitle((s) => ({ ...s, [key]: value }));
    },
    [],
  );
  const updateDescription = useCallback(
    (key) => (evt) => {
      let value = typeof evt === 'string' ? evt : evt.target.value || '';
      props?.setDescription((s) => ({ ...s, [key]: value }));
    },
    [],
  );

  const onFinish = async (data) => {
    console.log('data', data);
    setLoading(true);
    const payload = {
      publish: {
        title: data?.title,
        description: data?.description,
        image: data?.upload[0]?.thumbUrl,
        accounts: ['twitter', 'linkedin', 'instagram', 'facebook'],
      },
      publish_status: true,
    };

    const resp = await updateJob({
      id: props?.selectedAsset?.id,
      data: payload,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Recruitment Published Successfully');
      props?.setAdvertModal(false);
      props?.refresh();
      setLoading(false);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.msg);
      props?.setAdvertModal(false);
      setLoading(false);
    }
    setLoading(false);
  };

  const normFile = (e) => {
    // props?.setUpload(e?.file?.thumbUrl);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      props?.setUpload(target.result);
      // setUploadButton(true);
    };
    reader.readAsDataURL(file);
    return false;
  };

  return (
    <div className='flex justify-between p-4 gap-3'>
      <InstaTemplate
        initialvalues={props?.selectedAsset}
        title={props?.title}
        description={props?.description}
        upload={props?.upload}
        tab={props?.tab}
      />
      <div className=' w-[50%] h-[100%] relative  '>
        <Form
          name='basic'
          labelCol={{
            span: 10,
          }}
          labelAlign='left'
          wrapperCol={{
            span: 26,
          }}
          style={{
            maxWidth: 600,
            height: 400,
          }}
          initialValues={{
            title: props?.title?.title
              ? props?.title?.title
              : data?.publish?.title
              ? data?.publish?.title
              : `${data?.designation?.name} Recruitment`,
            description: props?.description?.description
              ? props?.description?.description
              : data?.description,
            // upload : props?.upload,
          }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item
            label='Title'
            name='title'
            rules={[
              {
                // required: true,
                message: 'Please input your title!',
              },
            ]}
          >
            <Input.TextArea onChange={updateTitle('title')} height={100} />
          </Form.Item>

          <Form.Item
            label='Description'
            className='w-full '
            name='description'
            rules={[
              {
                // required: true,
                message: 'Please input your description!',
              },
            ]}
          >
            <TextArea rows={5} onChange={updateDescription('description')} />
          </Form.Item>
          <Form.Item
            name='upload'
            rules={[
              {
                required: false,
                message: 'Please upload picture',
              },
            ]}
            // label="Upload"
            valuePropName='fileList'
            getValueFromEvent={normFile}
          >
            <Upload beforeUpload={beforeUpload} name='logo' action='' listType='picture'>
              {/* <div></div> <div><PictureOutlined size={40} </div> Upload the Image</div> */}
              <div>
                {' '}
                <PictureFilled style={{ fontSize: 20 }} /> Upload the Image{' '}
              </div>
            </Upload>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button
              loading={loading}
              disabled={isPublished}
              className='absolute  right-0 '
              type='primary'
              htmlType='submit'
            >
              {isPublished ? 'Published' : 'Publish'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SocialPost;
