import { SelectInput, TextInput } from 'components';

export const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Master',
    // path: "/",
  },
  {
    name: 'Reasons',
    path: '',
  },
];

export const columns = [
  {
    key: 'name',
    title: 'Reasons Name',
    dataIndex: 'name',
    render: (text) => text || '\u2014',
  },
  {
    key: 'type',
    title: 'Type',
    dataIndex: 'type',
    render: (text) => text || '\u2014',
  },
];

export const addReasonsFields = {
  'Reasons Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Reasons Name',
        name: 'name',
        id: 'name',
        placeholder: 'Reasons Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 50,
        divClasses: 'col-span-2',
      },
      {
        label: 'Type',
        name: 'type',
        id: 'type',
        placeholder: 'Select',
        type: 'select',
        required: true,
        component: SelectInput,
        divClasses: 'col-span-2',
        options: [
          {
            label: 'Leaves',
            value: 'leaves',
          },
          {
            label: 'Recruitment',
            value: 'recruitment',
          },
          {
            label: 'Asset',
            value: 'asset',
          },
        ],
      },
    ],
  },
};
