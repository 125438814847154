import { TaobaoCircleOutlined } from '@ant-design/icons';
import { InstaShare, NotUploaded } from 'assets';
import { InstaComment, InstaLike } from 'assets/icons';
import React, { useContext } from 'react';
import { Context } from 'store/context';

const SocialPostTemplate = ({ tab, title, description, upload, initialvalues }) => {
  const { company } = useContext(Context);
  const date = new Date().getDate();
  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  const now = `${month}-${date}-${year}`;

  return (
    <>
      {/* FaceBook Template */}
      {tab === 'facebook' && (
        <div className='border-2 h-[480px] overflow-hidden w-[50%] rounded-lg border-grey-80 p-2'>
          <div className='flex items-center gap-2'>
            <img className='h-10 rounded-full' src={company?.logo} alt='' />
            <div className='flex flex-col gap-[-4px]'>
              <p className='text-[16px] text-grey-60 font-medium'>{company?.name}</p>
              <p className='text-xxs text-grey-60 '>Published by {company?.name}</p>
            </div>
          </div>

          <div>
            <p className='font-medium max-h-10 overflow-hidden my-2'>
              {' '}
              {title?.title ? title?.title : `${initialvalues?.designation?.name} Recruitment`}{' '}
            </p>
            <p className='max-h-16 overflow-y-auto '>
              {description?.description ? description?.description : initialvalues?.description}
            </p>
          </div>
          <img
            className='h-[250px] w-[100%] mt-2 border-2 rounded-lg bordered'
            src={upload ? upload : initialvalues?.publish?.image || NotUploaded}
            alt=''
          />
        </div>
      )}

      {/* Instagram Template */}

      {tab === 'instagram' && (
        <div className='border-2 w-[50%] h-[480px] overflow-hidden rounded-lg border-grey-80 p-2'>
          <div className='flex items-center gap-2'>
            <img className='h-10 rounded-full' src={company?.logo} alt='' />
            <div className='flex flex-col gap-[-4px]'>
              <p className='text-[16px] text-grey-60 font-medium'>{company?.name}</p>
              <p className='text-xxs text-grey-60 '>Published by {company?.name}</p>
            </div>
          </div>
          <img
            className='h-[250px] w-[100%] mt-2 border-2 rounded-lg bordered'
            src={upload ? upload : initialvalues?.publish?.image || NotUploaded}
            alt=''
          />

          <div className='flex gap-2 mt-4'>
            <InstaLike />
            <InstaComment />
            <img className='h-6 mt-[-2px]' src={InstaShare} alt=' ' />
          </div>
          <p className='text-xxs text-grey-60'>0 Likes</p>

          <div>
            <p className='font-medium max-h-10 overflow-y-auto  my-2'>
              {' '}
              {title?.title ? title?.title : `${initialvalues?.designation?.name} Recruitment`}{' '}
            </p>
            <p className='max-h-16 overflow-y-auto'>
              {description?.description ? description?.description : initialvalues?.description}
            </p>
          </div>
        </div>
      )}

      {tab === 'linkedin' && (
        <div className='border-2 h-[480px] overflow-hidden w-[50%] rounded-lg border-grey-80 p-2'>
          <div className='flex items-center gap-2'>
            <img className='h-10 rounded-full' src={company?.logo} alt='' />
            <div className='flex flex-col gap-[-4px]'>
              <p className='text-[16px] text-grey-60 font-medium'>{company?.name}</p>
              <p className='text-[8px] text-grey-60 '>360 k Followers </p>
              <p className='text-[8px] text-grey-60 '>
                now <TaobaoCircleOutlined />{' '}
              </p>
            </div>
          </div>

          <div>
            <p className='font-medium max-h-10 overflow-hidden my-2'>
              {' '}
              {title?.title ? title?.title : `${initialvalues?.designation?.name} Recruitment`}{' '}
            </p>
            <p className='max-h-20 overflow-y-auto '>
              {description?.description ? description?.description : initialvalues?.description}
            </p>
          </div>
          <img
            className='h-[250px] w-[100%] mt-2 border-2 rounded-lg bordered'
            src={upload ? upload : initialvalues?.publish?.image || NotUploaded}
            alt=''
          />
        </div>
      )}

      {tab === 'twitter' && (
        <div className='border-2 h-[480px] overflow-hidden w-[50%] rounded-lg border-grey-80 p-2'>
          <div className='flex items-center gap-2'>
            <img className='h-10 rounded-full' src={company?.logo} alt='' />
            <div className='flex flex-col gap-[-4px]'>
              <p className='text-[16px] text-grey-60 font-medium'>{company?.name}</p>
              <p className='text-[12px] text-grey-60 '>@{company?.name} </p>
            </div>
          </div>

          <div>
            <p className='font-medium max-h-10 overflow-hidden  my-2'>
              {' '}
              {title?.title ? title?.title : `${initialvalues?.designation?.name} Recruitment`}{' '}
            </p>
            <p className='max-h-20 overflow-y-auto '>
              {description?.description ? description?.description : initialvalues?.description}
            </p>
          </div>
          <img
            className='h-[250px] w-[100%] mt-2 border-2 rounded-lg bordered'
            src={upload ? upload : initialvalues?.publish?.image || NotUploaded}
            alt=''
          />

          <p className='text-[12px] text-grey-60 '>now .{now} </p>
        </div>
      )}
    </>
  );
};

export default SocialPostTemplate;
