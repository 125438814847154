/** external deps */
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
/** internal deps */
import { SecureComponent } from 'permission-provider';
import { BackArrow } from 'assets/icons';

const Button = ({
  text,
  variant = 'primary',
  classes,
  link,
  onClick,
  type = 'button',
  disabled = false,
  loading = false,
  icon,
}) => {
  const defaultClasses = `transition-all duration-300 text-xl md:text-xxs xl:text-xxs py-2 px-4 xl:px-4 py-2 2xl:py-3 font-normal w-fit rounded-md hover:bg-opacity-90 whitespace-nowrap
  ${disabled ? 'bg-grey-90 cursor-not-allowed' : ''}  
  ${variant === 'primary' ? 'bg-primary-60 text-white  border-grey-90' : ''}
  ${variant === 'old-primary' ? 'bg-[#26CBED] text-white  border-grey-90' : ''}
  ${variant === 'secondary' ? 'border text-primary-60 border-primary-60' : ''}
  ${variant === 'black' ? 'border bg-black !text-white border-black' : ''}
  ${variant === 'transparent' ? 'bg-transparent border border-grey-90' : ''}
  ${variant === 'tertiary' ? 'bg-grey-70' : ''}
  ${variant === 'green' ? 'bg-green-70 rounded-md text-white' : ''}
  ${variant === 'text' ? 'bg-[#C3C3C3] text-black' : ''}
  ${variant === 'white' ? 'bg-white text-black' : ''}
  ${classes}`;

  return (
    <>
      {type === 'button' || type === 'reset' ? (
        <button
          disabled={disabled || loading}
          type={type}
          className={defaultClasses}
          onClick={onClick}
        >
          {loading ? (
            <LoadingOutlined spin={true} />
          ) : (
            <div className='flex'>
              <span>{text}</span>
              {icon && <BackArrow svgClass='rotate-180 fill-white' />}
            </div>
          )}
        </button>
      ) : (
        <a className={defaultClasses} href={link}>
          {text}
        </a>
      )}
    </>
  );
};

export { Button };

export default SecureComponent(Button);
