/** external deps */
import React, { useState, useContext } from 'react';
import { breadcrumbObj } from './data';
import { ContentHeader, TableComponent, CommissionFilter } from 'components';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { Context } from 'store/context';
import { dummyData } from './dummy';
import { getEmployees } from '../../service/api-service';

const pageSize = 10;

const Commission = (props = {}) => {
  const permissions = props?.permissions || [];
  const {
    employees: { updateEmployees, total, loading: employeesLoading, pageNum },
  } = useContext(Context);

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'Policy Name',
      dataIndex: 'policy_name',
      key: 'policy_name',
      render: (data) => data || '\u2014',
    },
    {
      title: 'Sell By',
      dataIndex: 'sell_by',
      key: 'sell_by',
      render: (data) => data || '\u2014',
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'contact',
      render: (data) => data || '\u2014',
    },
    {
      title: 'Premium',
      dataIndex: 'Premium',
      key: 'premium',
      render: (data) => data || '\u2014',
    },
  ];

  const handlePagination = (pageNum) => updateEmployees(pageNum);

  const handleSelectedBranch = async (value) => {};

  return (
    <div className='grid gap-3'>
      <ContentHeader
        data={breadcrumbObj}
        btnText='+ Employee Commission'
        buttonId={elementData.employee.permissions.create}
        permissions={permissions}
        showSearch={false}
      >
        <CommissionFilter
          onSelect={handleSelectedBranch}
          placeholder='Sell By'
          getEmployees={getEmployees}
        />
        <CommissionFilter onSelect={handleSelectedBranch} placeholder='Premium' />
      </ContentHeader>
      <TableComponent
        loading={loading || employeesLoading}
        columns={columns}
        dataSource={dummyData}
        pageSize={pageSize}
        total={total}
        onChange={handlePagination}
        defaultPage={pageNum}
      />
    </div>
  );
};

export default SecureComponent(Commission);
