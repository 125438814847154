import { DateTime, SelectInput, TextInput, Uploadbox, MultiSelect } from 'components';
import { expenseTypeManagement, searchApis } from 'service/api-service';

export const addExpenseTypeFields = {
  'Form Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Name',
        name: 'name',
        id: 'name',
        placeholder: 'Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
    ],
  },
};

export const addExpenseFields = {
  'Form Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Expense Title',
        name: 'name',
        id: 'name',
        placeholder: 'Expense Title',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Expense',
        name: 'expense_type',
        id: 'expense_type',
        placeholder: 'Expense',
        type: 'select',
        required: true,
        component: SelectInput,
        dependantFetchOptions: async () => {
          const resp = await expenseTypeManagement();
          if (resp?.data?.status) {
            return resp?.data?.data.map((item) => ({
              id: item.id,
              name: item.name,
            }));
          }
        },
      },
      {
        label: 'Amount',
        name: 'amount',
        id: 'amount',
        placeholder: 'Amount',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 15,
      },
      {
        label: 'Date',
        name: 'date',
        id: 'date',
        placeholder: 'YYYY-DD-MM',
        type: 'date',
        required: true,
        component: DateTime,
        disableNext: true,
      },
      {
        label: 'Involved Person',
        name: 'inv_persons',
        id: 'inv_persons',
        placeholder: 'Involved Person',
        type: 'select',
        component: MultiSelect,
        required: true,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
        fetchOptions: async () => searchApis({ api: 'employee' }),
      },
      {
        label: 'Upload Bill',
        name: 'docs',
        id: 'docs',
        placeholder: 'Upload bill',
        type: 'upload',
        component: Uploadbox,
        maxCount: 100,
        divClasses: 'col-span-full',
        accept: ['.pdf', '.jpeg', '.jpg', '.png'],
      },
    ],
  },
};

export const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Company Expense',
    path: '/company-expense',
  },
];
