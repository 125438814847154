import React, { useRef } from 'react';
import { DocExport, TableComponent } from 'components';
import { columns, parsedData } from './data';
import { ColumnsToExport } from '../data';
import ReconciliationList from './list';
import { rowCommonClass } from 'utils/common';

const ReconciliationTable = ({ data, endDate, loading, startDate }) => {
  const printRef = useRef(null);
  return (
    <div>
      <div className='flex justify-end items-end pb-3'>
        <DocExport
          data={parsedData(ColumnsToExport({ data: data?.data, columns: columns }))}
          file={`Reconciliation_Report_from_${startDate}_to_${endDate}`}
          printRef={printRef.current}
          id='divToPrint'
          type='landscape'
        />
      </div>
      <div id='divToPrint' ref={printRef} className='grid gap-3'>
        <TableComponent
          loading={loading}
          columns={columns}
          dataSource={data?.data}
          showPagination={false}
          getRowClassName={rowCommonClass}
        />
      </div>
      <ReconciliationList data={data} />
    </div>
  );
};

export default ReconciliationTable;
