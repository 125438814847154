/** external deps */
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import OneSignalReact from 'react-onesignal';
/** external deps */
import { AuthLayout, Toast, FormContainer, TextInput, PasswordInput } from 'components';
import { initialValues } from './data';
import { login } from 'service/api-service';
import { setItem, getItem } from 'utils';
import { NOTIFICATION_MSG } from 'constants';
import { Context } from 'store/context';
// import { OneSignal } from "service/onesignal/OneSignal";
// import jwt_Decode from "jwt-decode";
import { ElisopsLogo } from 'assets/hr_icons';
import { EmailOutline } from 'assets/icons';
import Button from 'components/button';
import navigationService from 'service/navigation';

const extractAccessToken = () => {
  const tokens = getItem('AUTH');
  const { access_token } = tokens || { access_token: null };

  return access_token;
};

const Login = () => {
  const { user } = useContext(Context);

  const access_token = extractAccessToken();
  if (!!access_token) return <Navigate replace={true} to='/' />;

  const fields = {
    login: {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Email Address',
          name: 'email',
          id: 'email',
          placeholder: 'Email Address',
          type: 'email',
          required: true,
          component: TextInput,
          rightIcon: <EmailOutline />,
          InputClasses: '',
          // maxLength: 30,
        },
        {
          label: 'Password',
          name: 'password',
          id: 'password',
          placeholder: 'Password',
          type: 'password',
          required: true,
          maxLength: 128,
          validate: false,
          loginOnEnter: (e, form) => {
            if (e?.key === 'Enter') {
              onLogin(form?.values);
            }
          },
          component: PasswordInput,
          extraRightRender: () => {
            return (
              <Button
                text='Forgot Password ?'
                type='button'
                variant='text'
                classes={'flex-end text-xs !bg-white text-primary-90 !px-2'}
                onClick={() => {
                  if (navigationService.navigation) {
                    navigationService.navigation('forgot-password');
                  }
                }}
              />
            );
          },
        },
      ],
    },
  };
  const onLogin = async (data) => {
    try {
      const userId = process.env.NODE_ENV === 'development' ? '' : await OneSignalReact.getUserId();
      // const userId = await OneSignalReact.getUserId();

      const finalPayload = { ...data, pos_agent: false, player_id: userId || '' };
      // const finalPayload = { ...data, pos_agent: false, player_id: "" };

      const resp = await login({ data: finalPayload });
      if (resp?.data?.status) {
        const token = resp.data.tokens;

        setItem('AUTH', token);
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Login Success');
        if (user?.position === 'pos') {
          window.location.replace('/pos-home');
        } else {
          window.location.replace('/');
        }
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Login Failed');
      }
      return;
    } catch (error) {
      console.log('catchError', error);
    }
  };

  return (
    <AuthLayout showImage={true}>
      <div className='w-fit m-auto'>
        {/* <InsuranceLogo svgClass='w-[90px] h-16' />
        <ElisposLogo svgClass='w-[90px] h-16' /> */}
        <img src={ElisopsLogo} alt='--' className='h-16 w-[180px]' />
      </div>

      <div className='flex justify-center items-center flex-col mt-2'>
        <span className='text-l xl:text-xl font-medium'>Login to your account</span>
        <span className='text-grey-90 text-x pb-[30px] text-center font-medium'>
          In order to access your account, please enter your credentials.
        </span>
      </div>

      <FormContainer
        accordionClass='grid grid-cols-1  gap-8'
        onSubmit={onLogin}
        initialValues={initialValues}
        fields={fields}
        submitButtonProps={{ submitText: 'Log into your Account' }}
      />
    </AuthLayout>
  );
};

export default Login;
