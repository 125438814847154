import React from 'react';

const CircularProgressBar = ({ percent }) => {
  const status = percent < 40 ? '#DB0000' : percent > 60 ? '#2DAE32' : '#FF6F00';
  const fill = percent < 40 ? 'fill-[#DB0000]' : percent > 60 ? 'fill-[#2DAE32]' : 'fill-[#FF6F00]';
  return (
    <div className='w-max'>
      <svg viewBox='0 0 36 36' className={`circular-chart w-16`}>
        <path
          className='circle-bg '
          d='M18 2.0845
a 15.9155 15.9155 0 0 1 0 31.831
a 15.9155 15.9155 0 0 1 0 -31.831'
        />
        <path
          className='circle'
          stroke={status}
          strokeDasharray={`${percent?.toFixed()}, 100`}
          d='M18 2.0845
a 15.9155 15.9155 0 0 1 0 31.831
a 15.9155 15.9155 0 0 1 0 -31.831'
        />
        <text x='18' y='22' className={`!text-[0.5rem] percentage font-bold ${fill}`}>
          {percent?.toFixed()} %
        </text>
      </svg>
    </div>
  );
};

export default CircularProgressBar;
