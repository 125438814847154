import { FormContainer, Uploadbox } from 'components';
import DateTime from 'components/form-components/DateTime';
import TextInput from 'components/form-components/TextInput';
import dayjs from 'dayjs';
import { useState } from 'react';
import { SubmitButtons } from 'screens/leads/data';
import { prepareFormFieldsData } from 'utils';
import { formStyle } from 'utils/common';

const TravelForm = ({
  formData,
  setValues,
  insurer,
  setSelectedProduct,
  isEditing,
  submitProduct,
}) => {
  const maxDate = dayjs().subtract(18, 'years');
  const [wantExit, setWantExit] = useState(false);

  const onSubmit = (data) => {
    submitProduct(data, wantExit);
  };

  const quoteCustomerFields = {
    'Basic Details': {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Passport',
          name: 'passport',
          id: 'passport',
          placeholder: 'Passport',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Mobile Number',
          name: 'mobile',
          id: 'mobile',
          placeholder: 'Mobile Number',
          type: 'number',
          component: TextInput,
        },
        {
          label: 'Email Address',
          name: 'email',
          id: 'email',
          placeholder: 'Email Address',
          type: 'email',
          component: TextInput,
        },
        {
          label: 'DOB',
          name: 'dob',
          id: 'dob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          showTime: false,
          component: (props) => <DateTime {...props} disabledDate={maxDate} />,
        },
        {
          label: 'Pan Card',
          name: 'pan_card',
          id: 'pan_card',
          placeholder: 'Pan Card',
          type: 'text',
          component: TextInput,
        },
      ],
    },
    'Nominee Details': {
      headerToHeading: true,
      fields: [
        {
          label: 'Name',
          name: 'nominee_name',
          id: 'nominee_name',
          placeholder: 'Name',
          type: 'text',
          component: TextInput,
        },
        {
          label: 'Nominee DOB',
          name: 'nominee_dob',
          id: 'nominee_dob',
          placeholder: 'YYYY-MM-DD',
          type: 'date',
          component: DateTime,
        },
        {
          label: 'One id proof of proposer',
          name: 'proposer',
          id: 'proposer',
          divClasses: 'col-span-2',
          component: Uploadbox,
          dragger: false,
          accept: ['.png, .jpeg, .jpg'],
        },
        {
          label: 'In port case atleast 3 years previous policies copy',
          name: 'prev_copy',
          id: 'prev_copy',
          divClasses: 'col-span-2',
          component: Uploadbox,
          dragger: false,
          accept: ['.png, .jpeg, .jpg'],
        },
      ],
    },
    'Traveling Details': {
      headerToHeading: true,
      fields: [
        {
          label: 'Travel Start Date',
          name: 't_start_date',
          id: 't_start_date',
          placeholder: 'Travel Start Date',
          type: 'date',
          component: DateTime,
        },
        {
          label: 'Travel End Date',
          name: 't_end_date',
          id: 't_end_date',
          placeholder: 'Travel End Date',
          type: 'date',
          component: DateTime,
        },
        {
          label: 'Number of Days',
          name: 'days',
          id: 'days',
          placeholder: 'Number of Days',
          type: 'number',
          component: TextInput,
        },
        {
          label: 'Visiting Country Name',
          name: 'country',
          id: 'country',
          placeholder: 'Visiting Country Name',
          type: 'text',
          component: TextInput,
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: quoteCustomerFields,
    defaultValues: { dob: maxDate.format('YYYY-MM-DD'), ...formData },
  });

  return (
    <div className='bg-white'>
      <FormContainer
        returnFormatted={false}
        accordionClass={formStyle}
        fields={finalFields}
        initialValues={initialValues}
        onSubmit={onSubmit}
        customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, dirty }) => {
          return formData?.count ? (
            <></>
          ) : (
            <SubmitButtons
              show={true}
              classes='z-10'
              submit={submitForm}
              reset={resetForm}
              setWantExit={setWantExit}
            />
          );
        }}
      />
    </div>
  );
};

export default {
  Form: TravelForm,
};
