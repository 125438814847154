import React from 'react';
import { FieldArray } from 'formik';
import { Button } from 'components';
import FieldArrayField from './FieldArrayField';
import { DeleteFormIcon } from 'assets/icons';

const CustomFieldArray = ({
  form,
  customOnChange,
  limit,
  maxLimit,
  field,
  labelClass = '',
  ...props
}) => {
  return (
    <div className={`${props?.divClasses}`}>
      <span className={labelClass}>{props?.label}</span>
      <FieldArray name={field?.name}>
        {({ push, pop }) => {
          const keysLength = form?.values?.field?.name || props?.fields?.length;
          const gridCols =
            keysLength >= 3 ? 'grid-cols-3' : keysLength === 1 ? 'grid-cols-1' : 'grid-cols-2';

          const onChange = ({ type }) => {
            if (type === 'push') {
              if (maxLimit) {
                limit < maxLimit && push(props?.valueSchema);
              } else {
                push(props?.valueSchema);
              }
            } else {
              if (field?.value?.length > 1) {
                pop();
              }
            }
          };

          if (customOnChange) {
            customOnChange({ field, form });
          }

          return (
            <>
              <div>
                {form?.values?.[field?.name]?.length > 0 && (
                  <>
                    {props?.fieldLabels && (
                      <div className={`grid ${props?.gridCols || gridCols} gap-10`}>
                        {props?.fieldLabels?.map((label) => (
                          <span>{label}</span>
                        ))}
                      </div>
                    )}

                    {form?.values?.[field?.name]?.map((_, index) => {
                      return (
                        <>
                          <div key={index}>
                            {props.setlabel && (
                              <p className='text-xxs text-black font-semibold pt-2'>
                                #{index + 1} {props.setlabel}'s Details
                              </p>
                            )}
                            <div>
                              <div className={`grid ${props?.gridCols || gridCols} gap-10  `}>
                                {props?.fields.map((fl, ind) => {
                                  const finalObj = {
                                    ind: `${index}`,
                                    parentName: `${field?.name}`,
                                    fieldName: `${fl.name}`,
                                    ...fl,
                                    name: `${field?.name}.${index}.${fl.name}`,
                                    label: fl?.label,
                                  };
                                  return <FieldArrayField form={form} key={ind} {...finalObj} />;
                                })}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
              <div className={`flex flex-col  ${props.buttonsClassName ?? ''}`}>
                {shouldShowActionButtons(maxLimit) && (
                  <>
                    <Button
                      text={props?.addButtonText || '+ Add'}
                      variant={'white'}
                      classes='border-primary-90 !py-0  '
                      onClick={() => onChange({ type: 'push' })}
                    />

                    <Button
                      text={
                        <div className='flex items-center'>
                          <DeleteFormIcon svgClass='h-[14px] w-[14px] text-red stroke-red fill-red' />{' '}
                          {props.removeButtonText ?? 'Delete'}
                        </div>
                      }
                      variant={'white'}
                      classes='text-red-basic !py-0'
                      onClick={() => onChange({ type: 'pop' })}
                    />
                  </>
                )}
              </div>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export default CustomFieldArray;

const shouldShowActionButtons = (maxLimit) => {
  if (typeof maxLimit !== 'number') return true;
  if (maxLimit <= 1) return false;
  return true;
};
