import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import ContextProvider from 'store/context';
import TimerFunction from 'store/timer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ContextProvider>
      <TimerFunction>
        <App />
      </TimerFunction>
    </ContextProvider>
  </BrowserRouter>,
);
