import { useState, useEffect } from 'react';

const useDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries && entries.length > 0) {
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      }
    });

    // if (ref.current) {
    observer.observe(document.body);
    // }

    return () => {
      observer.disconnect();
    };
  }, []);

  return dimensions;
};

export default useDimensions;
