/** external deps */
import { useState, useCallback, useEffect, useContext, useRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Divider, Checkbox, Spin, Table, Space } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
/** internal deps */
import {
  SearchAPI,
  DateTime,
  ProductTypePicker,
  Button,
  CustomModal,
  SelectInput,
  IconButton,
  Toast,
  TextInput,
} from 'components';
import { getContacts } from 'data/form-data';
import { Context } from 'store/context';
import {
  customerCallLogs,
  getInquiryDetails,
  inquiryManagement,
  searchApis,
} from 'service/api-service';
import { elementData } from 'elements';
import AddCustomer from 'screens/customers/add-customer';
import { DELETE, EDIT, Trash } from 'assets/icons';
import CreateQoute from './lead-components/CreateQuote';
import InquiryTimeline from './lead-components/InquiryTimeline';
import CommentSection from './lead-components/CommentSection';
import { NOTIFICATION_MSG } from 'constants';
import PreviousProductsTable from './lead-components/PreviousProductsTable';
import { LeadForm } from './lead-components/SwitchForm';
import { responseOptions } from './data';

const LabelValue = ({ label, value, error, divClasses = '' }) => (
  <div className={divClasses}>
    <div className='border-1'>{label}</div>
    <div className='flex-1'>
      {value}
      {error ? <div className='text-red'>{error}</div> : null}
    </div>
  </div>
);

const Wrapper = ({ permissions, onSuccess, onError, producttype, backNavigate, ...props }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const LogData = state?.logValues;
  const [error, setError] = useState();
  const { user } = useContext(Context);
  const [loading, setLoading] = useState();
  const [contact, setContact] = useState();
  const [values, setValues] = useState([]);
  const [date, setRenewalDate] = useState();
  const [customer, setCustomer] = useState();
  const [assignee, setassignee] = useState();
  const [insurer, setInsurer] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [insured, setInsured] = useState(false);
  const [isFetching, setIsFetching] = useState();
  const [formValues, setFormValues] = useState({});
  const [insOptions, setInsOptions] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productType, setProductType] = useState([]);
  const [isSubmitted, setSubmitted] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [removeProducts, setremoveProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [isAddingCustomer, setAddingCustomer] = useState(false);
  const [showDeleteModal, setShowDeletModal] = useState(false);
  const [isInterested, setIsInterested] = useState(null);
  const [followUp, setFollowUp] = useState(null);
  const [reason, setReason] = useState(null);

  const onResponseChange = (fields, data) => {
    setIsInterested(data?.value);
  };
  const onFollowupChange = (data) => {
    setFollowUp(data?.val);
  };
  const HandleInputReason = (data) => {
    setReason(data?.value);
  };

  const toggleDeleteProduct = () => setShowDeletModal((pre) => !pre);

  const onCustomerSelect = useCallback(({ option, ...rest }) => {
    setCustomer(option);
  }, []);

  const onInsurerSelect = (name, val) => {
    const finalArray = val?.map((item) => item?.value);
    setInsurer(finalArray);
  };

  const assignTo = useCallback(({ value }) => {
    setassignee(value);
  }, []);

  const _onSubmit = useCallback(
    async (data) => {},

    [],
  );

  const submitlogs = async () => {
    let resp = {};
    if (!customer?.id) {
      Toast('info', NOTIFICATION_MSG.info, 'Client Field is Required');
    } else {
      const payload = {
        customer: customer?.id,
        status: isInterested,
        follow_up: followUp ? dayjs(followUp).format('YYYY-MM-DD') : null,
        reason: reason,
      };
      if (LogData) {
        resp = await customerCallLogs({ data: payload, method: 'patch', id: LogData?.id });
      } else {
        resp = await customerCallLogs({ data: payload, method: 'post' });
      }
      if (resp?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.msg || 'Data Saved successfully');
        setIsInterested(null);
        setReason('');
        setFollowUp(null);
        navigate(-1);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.msg || 'error');
      }
    }
  };

  const onPreSubmit = useCallback(async () => {
    if (!customer) return setError(1);

    if (!productType) return setError(2);

    const productsArray = values?.map((item) => {
      return {
        product_type: item?.productId,
        insurer: item?.insurer,
      };
    });
    const categoryId = values?.map((item) => item?.categoryId);
    const payload = {
      customer: customer?.id,
      assigned_to: typeof assignee === 'number' ? assignee : assignee?.id,
      insurance_details: values,
      source_ref: user?.id,
      products: productsArray,
      ins_cat_id: categoryId,
      p_insured: insured,
      renewal: date || null,
      contact: contact?.id,
      user_type: producttype ? 'pos' : 'employee',
      remove_products: removeProducts,
    };
    const resp = await inquiryManagement({
      method: id ? 'patch' : 'post',
      data: payload,
      id,
    });
    if (resp?.data?.status) {
      let msg = resp?.data?.data?.msg;
      if (!msg) {
        if (id) msg = 'Lead updated successfully';
        else msg = 'Lead created successfully';
      }
      if (id) {
        navigate('/lead');
        Toast('success', NOTIFICATION_MSG.success, msg || 'Lead Updated successfully');
      } else {
        navigate(producttype ? '/pos' : `/lead/edit/${resp?.data?.data?.id}/1`);
        Toast('success', NOTIFICATION_MSG.success, msg || 'Lead created successfully');
      }
      setSubmitted(true);
    } else {
      Toast('error', NOTIFICATION_MSG.error, 'error');
    }
    setLoading(false);
  }, [
    customer,
    values,
    productType,
    values,
    producttype,
    user,
    insured,
    date,
    contact,
    setLoading,
    onSuccess,
    onError,
    setError,
    assignee,
  ]);

  const load = useCallback(async () => {
    if (id) {
      setIsFetching(true);
      const resp = await getInquiryDetails({ id });

      if (resp?.data?.status) {
        setLeadData(resp?.data?.data);
        const lead = resp?.data?.data;
        const Products = lead?.products?.map((item) => item?.product_type);
        setCustomer({
          id: lead?.customer?.id,
          name: lead?.customer?.name,
          customer_type: lead?.customer?.customer_type,
          corp_name: lead?.customer?.corp_name,
        });
        setValues(id && lead && lead?.insurance_details);
        setContact(lead?.contact);
        setassignee(lead?.assigned_to);
        setFormValues(lead.insurance_details);
        setInsured(lead?.p_insured);
        setInsurer(lead?.insurer);
        setProductData(
          Products?.map((item, index) => ({ ...item, key: index + 1, value: item?.name })),
        );
        lead?.renewal && setRenewalDate(lead?.renewal);
      } else {
        onError('Failed to load lead information');
      }
      setIsFetching(false);
    }
  }, [id]);
  const handleDeleteProduct = () => {
    const deleteId = productData?.find((item) => item?.name === selectedProduct);
    const newValues = values?.filter(
      (item) =>
        item?.productName !== (selectedProduct?.name ? selectedProduct?.name : selectedProduct),
    );
    setValues(newValues);
    const newProducts = productData?.filter(
      (item) => item?.name !== (selectedProduct?.name ? selectedProduct?.name : selectedProduct),
    );
    setProductData(newProducts);
    if (id) {
      setremoveProducts((prev) => [...prev, selectedProduct?.id || deleteId?.id]);
    }
    setShowDeletModal(false);
  };
  // edit table data !!!

  const columns = [
    {
      title: 'Product Icon',
      dataIndex: 'logo',
      key: 'logo',
      render: (data) => {
        return (
          <div className=''>
            <img
              src={process.env.REACT_APP_DOC_URL + data}
              className='h-6 w-6 rounded-full'
              alt='img'
            />
          </div>
        );
      },
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Form Type',
      dataIndex: 'formid',
      key: 'formid',
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Actions',
      align: 'left',
      style: {},

      render: (_, record) => {
        return (
          <>
            <div className='flex items-center justify-cenitems-center'>
              <Space>
                <div
                  onClick={() => {
                    setSelectedProduct(record);
                    toggleDeleteProduct();
                  }}
                >
                  <IconButton title='Delete' className='group disabled:cursor-not-allowed'>
                    <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                  </IconButton>
                </div>
              </Space>
            </div>
          </>
        );
      },
    },
    Table.SELECTION_COLUMN,
  ];

  const onInsuredChange = useCallback((evt) => {
    setInsured(evt.target.checked);
  }, []);

  const getCustomerContact = useCallback((name, value) => {
    setContact(value);
  }, []);

  useEffect(() => {
    if ((customer && error === 1) || (productType && error === 2)) {
      setError('');
    }
  }, [customer, productType, error]);

  const toggleAddingCustomer = () => {
    setAddingCustomer((pre) => !pre);
  };

  const getInsurers = async () => {
    const resp = await searchApis({ query: '', api: 'insurer' });
    const data = resp?.data?.data;
    const options = data?.map((item) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    });

    setInsOptions(options);
  };

  useEffect(() => {
    if (id || isSubmitted) {
      load(); // getInquiry();
    }
    getInsurers();
    setCustomer({
      name: state?.customer?.customer_name,
      id: state?.customer?.id,
    });
    setassignee(state?.customer?.assign_to_employee);

    if (state?.logValues) {
      setCustomer(LogData?.customer);
      setIsInterested(LogData?.status);
      // setIsInterested({
      //   name :LogData?.status,
      //   value :LogData?.status,
      //   id :LogData?.status,
      // });
      setReason(LogData?.reason);
      setFollowUp(dayjs(LogData?.follow_up));
    }
  }, [isSubmitted, id, state]);

  const handleProductSelector = useCallback(
    (item) => {
      if (item === null || item[0] === null) return;
      localStorage.setItem('productForm', JSON.stringify(item[0] || item));
      if (productType.length >= 1) {
        setProductType([...productType, item[0] || item]);
      } else {
        setProductType([item[0] || item]);
      }
      setSelectedProduct(item[0] || item);
    },
    [setProductType, productType],
  );

  const onDeselect = useCallback(
    (item, option) => {
      if (!item) return;
      setSelectedProduct(item);
      setShowDeletModal(true);
    },
    [setValues, values, productData],
  );

  const handleSelectProduct = (item) => {
    const finalValue = leadData?.insurance_details?.find((ele) => ele?.productName == item?.name);
    setFormValues(finalValue);
    return finalValue;
  };

  const formId = selectedProduct?.formId?.name || selectedProduct?.formId;

  if (isFetching) {
    return (
      <div className='flex justify-center items-center h-full'>
        <Spin />
      </div>
    );
  }

  const handleCancelAssignee = () => {
    setassignee('');
  };
  return (
    <div>
      <div className='flex justify-between sticky top-0 bg-white z-10 p-4'>
        <span className='font-medium text-m'>
          {!LogData ? `${id ? 'Edit ' : 'Create New '} Lead ${id ? '- ' + id : ''}` : 'Edit Logs'}
        </span>

        {id && (
          <div className='flex gap-4 mt-2'>
            <CreateQoute state={state?.inquiryMethod} />
            <CommentSection id={id} />
            <InquiryTimeline state={state?.inquiryMethod} />
          </div>
        )}
      </div>

      <div className='px-4 relative'>
        <label className='font-medium'>Client Details</label>

        <Divider className='mt-2' />
        {values?.length >= 1 && !id && (
          <LabelValue
            divClasses='w-52  absolute right-2 top-8'
            value={
              <ProductTypePicker
                classes='w-16'
                onChange={(item) => {
                  handleProductSelector(item);
                }}
                selected={selectedProduct}
              />
            }
            error={error === 2 ? 'Please select Insurance Type' : ''}
          />
        )}
        <div className='grid grid-cols-3 gap-x-6 gap-y-5 p-2 mb-4 items-center'>
          <>
            <LabelValue
              label='Client'
              value={
                <SearchAPI
                  type='search'
                  placeholder='Select Client'
                  field={{ name: 'customer' }}
                  onSelect={onCustomerSelect}
                  defaultValue={customer}
                  searchParams='corp_name'
                  onIconClick={() => setCustomer('')}
                  extraLabelProps={'contact_number'}
                  extraRightRender={() => (
                    <button type='button' onClick={toggleAddingCustomer}>
                      <span className='text-primary-90'>+ Client</span>
                    </button>
                  )}
                />
              }
              error={error === 1 ? 'Please select customer' : ''}
            />

            <LabelValue
              label='Client Response'
              value={
                <SelectInput
                  required={true}
                  type='select'
                  defaultValue={isInterested}
                  placeholder={'Client Response'}
                  field={{ name: 'client_response' }}
                  options={responseOptions}
                  customOnChange={onResponseChange}
                />
              }
            />
            {isInterested !== 'interested' && isInterested !== null && (
              <>
                <LabelValue
                  label='Follow Up '
                  value={
                    <DateTime
                      required={true}
                      type='date'
                      defaultValue={followUp}
                      placeholder={'Follow Up '}
                      field={{ name: 'follow_up' }}
                      // options={responseOptions}
                      customOnChange={(val) => onFollowupChange(val)}
                    />
                  }
                />
                <LabelValue
                  label='Reason'
                  value={
                    <TextInput
                      type='textarea'
                      rows={4}
                      divClasses='col-span-full'
                      isMultiline={true}
                      required={true}
                      defaultValue={reason}
                      placeholder={'Reason'}
                      field={{ name: 'reason' }}
                      // options={responseOptions}
                      customOnChange={(val) => HandleInputReason(val)}
                    />
                  }
                />
              </>
            )}

            {customer && customer?.customer_type?.name === 'Corporation' && (
              <LabelValue
                label='Contact'
                value={
                  <SelectInput
                    type='select'
                    defaultValue={contact?.customer_name}
                    placeholder={'Select Contact'}
                    field={{ name: 'contact' }}
                    dependantFetchOptions={async () => {
                      const resp = await getContacts(customer?.id);
                      return resp || [];
                    }}
                    customOnChange={getCustomerContact}
                  />
                }
              />
            )}

            {isInterested === 'interested' && (
              <div>
                <LabelValue
                  label='Insurance Type'
                  value={
                    <ProductTypePicker
                      isMulti={values.length >= 1 ? true : false}
                      classes='!w-full'
                      handleDeselect={(item, option) => {
                        onDeselect(item, option);
                      }}
                      onChange={(item) => handleProductSelector(item)}
                      selected={
                        id
                          ? productData
                          : values?.length >= 1
                          ? values?.map((item) => item?.productName)
                          : selectedProduct
                      }
                      isValid={values?.length >= 1 ? false : true}
                    />
                  }
                  error={error === 2 ? 'Please select Insurance Type' : ''}
                />
              </div>
            )}
          </>

          {isInterested === 'interested' && (
            <>
              {!id && (
                <LabelValue
                  label='Insurance Company'
                  value={
                    <SelectInput
                      type='select'
                      placeholder='Select Insurer'
                      field={{ name: 'insurer', type: 'select' }}
                      options={insOptions}
                      customOnChange={onInsurerSelect}
                      isMulti={true}
                    />
                  }
                />
              )}

              {!producttype && (
                <>
                  <LabelValue
                    label='Assign To'
                    value={
                      <SearchAPI
                        type='search'
                        placeholder='Assign To'
                        field={{ name: 'employee' }}
                        defaultValue={assignee}
                        onSelect={assignTo}
                        divClasses='w-full bg-white'
                        onIconClick={handleCancelAssignee}
                      />
                    }
                  />
                </>
              )}
              <Checkbox checked={insured} onChange={onInsuredChange}>
                Presently Insured
              </Checkbox>

              {insured && (
                <LabelValue
                  label='Renewal Date'
                  value={
                    <DateTime
                      defaultValue={dayjs(date)}
                      placeholder='Select Due Date'
                      customOnChange={({ val }) => setRenewalDate(val)}
                    />
                  }
                />
              )}
            </>
          )}
        </div>

        {formId && (
          <>
            <label className='font-medium'>Insured Details</label>

            <Divider className='mt-2' />

            <div className='mt-2 px-2'>
              {formLoading ? (
                <LoadingOutlined spin={true} />
              ) : (
                <LeadForm
                  id={formId}
                  onSubmit={_onSubmit}
                  insurer={insurer}
                  setValues={setValues}
                  setSelectedProduct={setSelectedProduct}
                  setFormLoading={setFormLoading}
                  formValues={formValues}
                />
              )}
            </div>
          </>
        )}
      </div>
      {/* Add Client modal */}
      <CustomModal
        destroyOnClose={true}
        footer={''}
        open={isAddingCustomer}
        onCancel={toggleAddingCustomer}
      >
        <AddCustomer
          formClasses={'lg:!grid-cols-3'}
          setCustomer={setCustomer}
          onCancel={toggleAddingCustomer}
          onSuccess={toggleAddingCustomer}
          showBackButton={false}
          renderContactDetailsOnly={true}
          permissions={permissions}
          id={elementData?.client.permissions.create}
          removeEmptyFields={true}
          isPopup={true}
        />
      </CustomModal>

      {/* edit table */}
      {id && (
        <Table
          dataSource={productData}
          columns={columns}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => {
              const finalValue = handleSelectProduct(record);
              return (
                <div
                  style={{
                    margin: 0,
                  }}
                >
                  <LeadForm
                    formValues={finalValue}
                    id={record}
                    setFormLoading={setFormLoading}
                    isEditing={true}
                    setValues={setValues}
                    setSelectedProduct={setSelectedProduct}
                  />
                </div>
              );
            },
            expandIcon: ({ expanded, onExpand, record }) => {
              return (
                (!expanded || expanded) && (
                  <IconButton
                    onClick={(e) => {
                      return onExpand(record, e);
                    }}
                    title='Edit'
                    className='group disabled:cursor-not-allowed '
                  >
                    <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey ' />
                  </IconButton>
                )
              );
            },
          }}
        />
      )}

      {/* previous Products Table */}
      {id && leadData?.previous_product?.length > 0 && (
        <PreviousProductsTable data={leadData?.previous_product} />
      )}

      <div className='bg-white flex flex-row justify-center mt-4'>
        <Button
          text='Cancel'
          variant='text'
          type='reset'
          classes='border  bg-white px-6'
          onClick={id ? () => navigate('/lead') : backNavigate}
        />
        <Button
          disabled={loading}
          permissions={permissions}
          id={elementData.Leads.permissions.create}
          text={
            !loading
              ? isInterested !== 'interested'
                ? LogData
                  ? 'Update'
                  : 'Save '
                : 'Save Lead'
              : 'Saving...'
          }
          classes={`ml-6 px-8 bg-green-400`}
          onClick={isInterested !== 'interested' ? submitlogs : onPreSubmit}
          loading={loading}
        />
      </div>

      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteProduct}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the product ?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteProduct}
            />
            <Button
              id={elementData.Leads.permissions.delete}
              permissions={permissions}
              text='Delete Product'
              variant='primary'
              classes={`ml-6 px-8   
             bg-black
            `}
              onClick={() => {
                handleDeleteProduct();
              }}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Wrapper;
