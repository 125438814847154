/** external deps */
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

/** internal deps */
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { Button, FormContainer, IconButton, Toast } from 'components';
import { BackArrow } from 'assets/icons';
// import { elementData } from 'elements';
import { policyManagement, otherSourcepolicy } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { Context } from 'store/context';
import { addPolicyFields, parsePayload } from './data';

const AddPolicy = (props = {}) => {
  // const permissions = props?.permissions || {};
  const { user } = useContext(Context);

  const { state } = useLocation();
  const isEditing = state?.initialValues ? true : false;
  const policyType = state?.activekey;
  const [respData, setRespData] = useState([]);
  const [pincodeError, setPinCodeError] = useState();

  const excludeFields = () => {
    let array = [];
    if (user?.user_type === 'employee' && state?.activekey === 'other') {
      array = ['employee', 'policy_type', 'quotation_id'];
    } else if (user?.user_type === 'employee' && state?.activekey !== 'other') {
      array = ['employee'];
    } else if (user?.user_type !== 'employee' && state?.activekey === 'other') {
      array = ['policy_type', 'quotation_id'];
    } else if (user?.user_type !== 'employee' && state?.activekey !== 'other') {
      array = [];
    }
    return array;
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addPolicyFields({
      respData,
      setRespData,
      activekey: state?.activekey,
      setPinCodeError,
      pincodeError,
    }),
    fieldsToExclude: excludeFields(),
    defaultValues: {
      issue_date: dayjs().format('YYYY-MM-DD'),
      country: 'IN',
      ...state?.defaultValues,
    },
  });
  const navigate = useNavigate();

  const onAddPolicy = async (payload) => {
    const billing_address = {
      address: payload?.address,
      state: payload?.state,
      city: payload?.city,
      pincode: payload?.pincode,
      country: payload?.country,
    };
    const customer = {
      id: payload?.customer,
      contact_number: payload?.contact_no || '',
      email_address: payload?.email_address,
      nature_of_business: payload?.bs_nature,
    };
    if (policyType === 'all') {
      const finalPayload = {
        ...payload,
        // policy_num: payload?.policy_num === 'not-assigned' ? '' : payload?.policy_num,
        g_premium: payload && payload?.g_premium === '' ? 0 : payload?.g_premium,
        sum_insured: payload && payload?.sum_insured === '' ? 0 : payload?.sum_insured,
        premium: payload && payload?.premium === '' ? 0 : payload?.premium,
        gst_pre: payload && payload?.gst_pre === '' ? 0 : payload?.gst_pre,
        other_fees: payload && payload?.other_fees === '' ? 0 : payload?.other_fees,
        inquiry: payload?.quotation_id,
        category: payload?.insurance_category,
        bill_to: payload?.customer_bill_to,
        sell_id: user?.id,
        customer,
        address: billing_address,
        // registered_address: payload?.checkaddress
        //   ? billing_address
        //   : {
        //       r_address: payload?.r_address,
        //       r_state: payload?.r_state,
        //       r_city: payload?.r_city,
        //       r_pincode: payload?.r_pincode,
        //       r_country: payload?.r_country,
        //     },
        status: null,
      };

      const resp = await policyManagement({
        data: finalPayload,
        method: 'post',
      });

      if (resp?.data?.status) {
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.data?.msg || 'Policy Created Successfully',
        );
        navigate('/policy');
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
    } else {
      const finalPayload = {
        ...payload,
        g_premium: payload && payload?.g_premium === '' ? 0 : payload?.g_premium,
        sum_insured: payload && payload?.sum_insured === '' ? 0 : payload?.sum_insured,
        premium: payload && payload?.premium === '' ? 0 : payload?.premium,
        gst_pre: payload && payload?.gst_pre === '' ? 0 : payload?.gst_pre,
        other_fees: payload && payload?.other_fees === '' ? 0 : payload?.other_fees,
        category: payload?.insurance_category,
        sell_id: user?.id,
        customer,
        address: billing_address,
        other: true,
        status: null,
      };
      const resp = await otherSourcepolicy({
        data: finalPayload,
        method: 'post',
      });

      if (resp?.data?.status) {
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.data?.msg || 'Policy Created Successfully',
        );
        navigate(-1);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
    }
  };
  const onEditPolicy = async (payload) => {
    const editFields = getEditedFields({
      initialValues: state?.initialValues,
      updatedData: payload,
    });

    if (Object.keys(editFields).length) {
      const finalPayload = parsePayload(editFields);
      if (policyType === 'all') {
        const resp = await policyManagement({
          data: finalPayload,
          method: 'patch',
          id: state?.initialValues?.id,
        });

        if (resp?.data?.status) {
          Toast(
            'success',
            NOTIFICATION_MSG.success,
            resp?.data?.msg || 'Policy updated Successfully',
          );
          navigate(-1);
        } else {
          Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
        }
      } else {
        const resp = await otherSourcepolicy({
          data: finalPayload,
          method: 'patch',
          id: state?.initialValues?.id,
        });

        if (resp?.data?.status) {
          Toast(
            'success',
            NOTIFICATION_MSG.success,
            resp?.data?.msg || 'Policy updated Successfully',
          );
          navigate(-1);
        } else {
          Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
        }
      }
    } else {
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save.');
    }
  };

  return (
    <div>
      <div className='flex flex-col p-4 mt-4 bg-white rounded-lg'>
        <div className='flex justify-between px-2'>
          <div className='flex flex-col px-2'>
            <span className='font-medium text-l 2xl:text-xxl'>
              {state?.initialValues ? 'Edit' : 'Add'}{' '}
              {state?.activekey === 'other' ? 'Policy for other source' : 'Policy'}
            </span>
            <span className='text-xxs text-grey-80'>
              {state?.initialValues
                ? 'Edit details below'
                : 'To add a new Policy, enter the details of the Policy in the input field below.'}
            </span>
          </div>
          <IconButton onClick={() => navigate(`/policy`)}>
            <BackArrow svgClass='fill-grey-90 w-10 h-10' />
          </IconButton>
        </div>
        <FormContainer
          accordionClass='grid grid-cols-3 gap-8'
          onSubmit={isEditing ? onEditPolicy : onAddPolicy}
          initialValues={isEditing ? state?.initialValues : initialValues}
          fields={finalFields}
          customSubmitRender={({
            isSubmitting,
            submitForm,
            values,
            isValid,
            resetForm,
            disableForm,
          }) => {
            return (
              <>
                {/* <PolicyDetailsTable values={values} /> */}
                <div className='flex flex-row justify-end py-4 mt-6 bg-white'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      navigate(-1);
                    }}
                  />
                  <Button
                    // id={
                    //   state?.initialValues
                    //     ? elementData.policy.permissions.update
                    //     : elementData.policy.permissions.create
                    // }
                    // permissions={permissions}
                    // disabled={state?.policyType === 'all' ? disableForm || !isValid : false}
                    text={state?.initialValues ? 'Update Policy' : 'Add Policy'}
                    variant='primary'
                    classes={`ml-6 px-8 ${state?.initialValues ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
};

export default AddPolicy;
