import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

import { getQueryParams } from 'utils/common';
import './style.css';

const TabsComponent = ({
  items,
  activeKey,
  setActiveTabKey,
  extraRightRender,
  extraLeftRender,
  extraProps,
  tabPosition = 'top',
  classes,
  onChange,
  url,
  id = null,
  renderTabBar,
  renderTabBarClasses = '',
}) => {
  const TabsPane = Tabs.TabPane;
  const { tab } = getQueryParams();

  const _onChange = (key) => {
    if (url) {
      window.history.replaceState(null, null, `${url}tab=${key}`);
    }
    setActiveTabKey(key);
  };

  useEffect(() => {
    if (url && tab) {
      setActiveTabKey(tab);
    }
  }, [tab]);

  const renderNewTabBar = (props) => {
    return (
      <div className={` border border-primary-60 rounded-md w-fit flex  `}>
        {items &&
          items.map(({ key, label }) => {
            return (
              <div
                className={
                  key === activeKey
                    ? `bg-primary-60 cursor-pointer px-2 py-1  rounded font-semibold leading-normal text-xxs text-white`
                    : `bg-transparent cursor-pointer  px-2 py-1 mr-1 text-black rounded font-normal leading-normal text-xxs`
                }
                onClick={() => {
                  onChange ? onChange(key) : _onChange(key);
                }}
                {...props}
              >
                {label}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div>
      <Tabs
        id={id}
        tabPosition={tabPosition}
        activeKey={activeKey}
        onChange={onChange ? onChange : _onChange}
        destroyInactiveTabPane={true}
        tabBarExtraContent={{ right: extraRightRender, left: extraLeftRender }}
        className={classes}
        renderTabBar={renderTabBar ? renderNewTabBar : undefined}
      >
        {items.map((item) => {
          if (Object.keys(item).length <= 0) return null;
          const { Component } = item;
          return (
            <TabsPane tab={item.label} key={item.key}>
              <motion.div
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -10 }}
                transition={{ duration: 0.5 }}
              >
                <motion.span
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -10 }}
                  transition={{ duration: 0.5 }}
                >
                  <Component {...item?.props} {...extraProps} />
                </motion.span>
              </motion.div>
            </TabsPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default TabsComponent;
