/** external deps */
import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import Fuse from 'fuse.js';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
  Toast,
} from 'components';
import { AssetsTypeFields, typebreadcrumbObj } from './data';
import { EDIT, Trash } from 'assets/icons';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { getAssetType } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { rowCommonClass } from 'utils/common';

const AssetType = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [pageNum, setPageNum] = useState(1);
  const [total, setTotal] = useState(0);
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [selectedAsset, setSelectedAsset] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredAssets, setfilteredAssets] = useState([]);
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: AssetsTypeFields,
  });

  const columns = [
    {
      key: 'name',
      title: 'Asset Type',
      dataIndex: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.assetType.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  /* funcs */

  const fetchData = async () => {
    setLoading(true);
    const resp = await getAssetType({ method: 'get' });
    if (resp?.data?.data) {
      setTableData(resp?.data?.data?.result);
      setPageNum(resp?.data?.data.current_page);
      setTotal(resp?.data?.data.total_page);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onAddAssetType = async (data) => {
    const resp = await getAssetType({ method: 'post', data: data });

    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleAddAsset();
      fetchData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  const onDeleteAsset = async () => {
    setLoading(true);
    const resp = await getAssetType({ method: 'delete', id: selectedAsset?.id });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Asset Type deleted successfully');
      fetchData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteAsset();
    setLoading(false);
  };

  const onEditAssetType = async (data) => {
    setLoading(true);
    const editFields = getEditedFields({ initialValues, updatedData: data });
    if (Object.keys(editFields).length) {
      const resp = await getAssetType({
        data: editFields,
        method: 'patch',
        id: selectedAsset?.id,
      });

      if (resp?.data?.status) {
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.data?.msg || 'Asset Type updated successfully.',
        );
        fetchData();
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
      toggleAddAsset();
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save.');
    }
  };

  const toggleAddAsset = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  const toggleDeleteAsset = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(tableData, {
      keys: ['name'],
      threshold: 0.2,
    }).search(e.target.value);
    setfilteredAssets(fuse.map((i) => i.item));
  };

  const onEditPress = (record) => {
    setInitials(record);
    setSelectedAsset(record);
    setAddModal(true);
  };

  const handlePagination = async (pageNum) => {
    setLoading(true);
    const resp = await getAssetType({ method: 'get', page: pageNum });
    if (resp?.data?.data) {
      setTableData(resp?.data?.data?.result);
      setPageNum(resp?.data?.data.current_page);
      setTotal(resp?.data?.data.total_page);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  return (
    <div className='grid gap-3'>
      <ContentHeader
        // data={typebreadcrumbObj}
        title={<p className='font-semibold text-s'>Asset Type</p>}
        permissions={permissions}
        onChange={handleOnChange}
        btnText='+ Asset Type'
        buttonId={elementData?.assetType?.permissions?.create}
        onClick={toggleAddAsset}
      />
      <TableComponent
        loading={loading}
        columns={columns}
        dataSource={searchQuery ? filteredAssets : tableData}
        total={total}
        onChange={handlePagination}
        defaultPage={pageNum}
        getRowClassName={rowCommonClass}
      />

      {/* delete asset type confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteAsset}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the asset?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteAsset}
            />
            <Button
              text='Delete Asset'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteAsset}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>

      {/* add Asset type modal */}
      <CustomModal
        width={600}
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddAsset}
        title={!editInitials ? 'Add Asset Type' : 'Edit Asset Type'}
        footer={''}
      >
        <div>
          <span>
            {editInitials ? 'To Edit' : 'To add a new'} asset type, enter details in the fields
            given below.
          </span>
          <FormContainer
            accordionClass='grid gap-8'
            onSubmit={editInitials ? onEditAssetType : onAddAssetType}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddAsset();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.assetType.permissions.update
                        : elementData.assetType.permissions.create
                    }
                    text={editInitials ? 'Update Asset Type' : 'Add Asset Type'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(AssetType);
