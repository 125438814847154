var communications = {};
var currentData = {};

export const subscribe = (id, callback) => {
  if (typeof callback === 'function') {
    if (!communications[id]) {
      communications[id] = [];
    }
    communications[id].push(callback);
    if (currentData[id]) callback(currentData[id]);
  }
};

export const broadcast = (id, data, data2) => {
  if (communications[id]) {
    currentData[id] = data;
    for (let i = 0; i < communications[id].length; i++) {
      communications[id][i](data, data2);
    }
  }
};
