/** external deps */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

/** internal deps */
import { Button, FormContainer, Loader, Module, Toast } from 'components';
import { addEmployeeFormFields, parseData } from './data';

import { addEmployee, updateEmployee, getEmployee } from 'service/api-service';
import { SecureComponent } from 'permission-provider';
import { NOTIFICATION_MSG } from 'constants';
import { Context } from 'store/context';
import { deleteEmptyValues, getEditedFields, getPermission, prepareFormFieldsData } from 'utils';
import { elementData } from 'elements';
import useBankDetails from 'data/form-data/ifsc';
import { debounce } from 'lodash';

const maxDate = dayjs().subtract(18, 'years');

const AddEditEmployee = ({
  entryId,
  permissions = {},
  toggle,
  getFieldsForParent = () => {},
  onClose,
  initialData,
  setInitialData,
}) => {
  const refValues = React.useRef(null);
  let navigate = useNavigate();
  // const { id } = useParams();
  const isEditing = entryId ? true : false;
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [pincodeError, setPinCodeError] = useState();
  const [ifscCode, setIfscCode] = useState();
  const { bankDetails, error } = useBankDetails(ifscCode);

  const fetchEmployeeDetail = async () => {
    const resp = await getEmployee({ id: entryId });
    if (resp?.data?.status) {
      const data = resp?.data?.data;
      const formData = parseData(data);
      const modifyData = {
        ...formData,
        docs: formData?.docs.length ? formData.docs : [{ name: '', file: null }],
      };
      setEmployeeData(modifyData);
    }
  };

  useEffect(() => {
    if (entryId) fetchEmployeeDetail();
    else {
      // setInitialData({ ...initialValues, tcountry: 'IN', country: 'IN' });
    }
  }, [entryId]);

  const getFields = (fields) => {
    const obj = Object.keys(fields).map((d) => {
      return fields[d].fields.filter((field) => !field.required);
    });
    return obj.flat().map((d) => d.name);
  };

  const [initialValues, finalFormFields] = prepareFormFieldsData({
    fields: addEmployeeFormFields({
      maxDate,
      setIfscCode,
      bankDetails,
      error,
      setPinCodeError,
      pincodeError,
    }),
    fieldsToExclude: toggle
      ? getFields(
          addEmployeeFormFields({
            maxDate,
            setIfscCode,
            bankDetails,
            error,
            setPinCodeError,
            pincodeError,
          }),
        )
      : [],
    sectionsToExclude: [],
    // defaultValues: { dob: maxDate },
    toggle,
  });

  getFieldsForParent(finalFormFields);

  const {
    employees: { updateEmployees },
  } = useContext(Context);

  // add employee //
  const handleAddEmployee = async (data) => {
    // only sending those documents containing valid data.
    const formattedDocuments = [];
    let shouldShowDocumentNameRequiredError = false;
    if (data?.docs?.length) {
      data.docs.forEach((document) => {
        if (document.file && !document.name) shouldShowDocumentNameRequiredError = true;
        else if (document.file && document.name) formattedDocuments.push(document);
      });
    }
    if (shouldShowDocumentNameRequiredError)
      return Toast(
        'error',
        NOTIFICATION_MSG.error,
        'Please add a name corresponding to the document!',
      );

    setLoading(true);
    const address = {
      city: data?.city,
      state: data?.state,
      country: data?.country,
      pincode: data?.pincode,
      address: data?.address,
    };
    const finalPayload = {
      name: data?.name,
      dob: data?.dob,
      email: data.email,
      password: data?.cPassword,
      image: data?.image,
      address,
      role_id: data?.role_id,
      branches: data?.branch,
      emp_code: data?.emp_code,
      pos_agent: data?.pos_agent,
      joining: data?.joining,
      work_from: data?.work_from,
      referred_by: data?.referred_by,
      referred_id: data?.employee || null,
      temp_address: data?.checkaddress
        ? address
        : {
            city: data?.tcity,
            state: data?.tstate,
            country: data?.tcountry,
            pincode: data?.tpincode,
            address: data?.taddress,
          },
      dep_id: data?.department_id,
      des_id: data?.designation,
      report_to: data?.report_id,
      contact: data?.contact,
      ifsc_code: data?.ifsc_code,
      account: data?.account,
      docs: formattedDocuments,
      bank: data?.bank,
      alt_con: data?.alt_con,
    };
    const resp = await addEmployee({ data: finalPayload });

    if (resp?.data?.status) {
      updateEmployees();
      // navigate(-1);
      onClose();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      const head = resp?.data?.msg;
      if (typeof head === 'object') {
        Object.keys(head).forEach((key) => {
          Toast('error', NOTIFICATION_MSG.error, `${key} : ${head[key]}` || 'Please try again');
        });
      }
    }
    setLoading(false);
  };
  const parsePayload = (edited) => {
    let finalPayload = {
      address: {},
      temp_address: {},
      employee_info: {},
    };

    Object.keys(edited).forEach((item) => {
      if (item === 'name') finalPayload['name'] = edited?.name;
      if (item === 'dob') finalPayload['dob'] = edited?.dob;
      if (item === 'email') finalPayload['email'] = edited?.email;
      if (item === 'role_id') finalPayload['role_id'] = edited?.role_id;
      if (item === 'pos_agent') finalPayload['pos_agent'] = edited?.pos_agent;
      if (item === 'bank') finalPayload['bank'] = edited?.bank;
      if (item === 'alt_con') finalPayload['alt_con'] = edited?.alt_con;

      //address fields
      if (item === 'city') finalPayload['address']['city'] = edited?.city;
      if (item === 'state') finalPayload['address']['state'] = edited?.state;
      if (item === 'country') finalPayload['address']['country'] = edited?.country;
      if (item === 'address') finalPayload['address']['address'] = edited?.address;
      if (item === 'pincode') finalPayload['address']['pincode'] = edited?.pincode;

      //employee info fields
      if (item === 'contact') finalPayload['contact'] = edited?.contact;
      if (item === 'branch') finalPayload['branches'] = edited?.branch;
      if (item === 'designation') finalPayload['des_id'] = edited?.designation;
      if (item === 'work_from') finalPayload['work_from'] = edited?.work_from;
      if (item === 'referred_by') finalPayload['referred_by'] = edited?.referred_by;
      if (item === 'department_id') finalPayload['dep_id'] = edited?.department_id;
      // if (item === "department_id")
      //   finalPayload["dep_id"] = edited?.department_id?.map((i) => i.id);
      if (item === 'report_id') finalPayload['report_to'] = edited?.report_id;
      if (item === 'joining') finalPayload['joining'] = edited?.joining;
      if (item === 'image') finalPayload['image'] = edited?.image;
      if (item === 'employee') finalPayload['referred_id'] = edited?.employee;
      if (item === 'ifsc_code') finalPayload['ifsc_code'] = edited?.ifsc_code;
      if (item === 'account') finalPayload['account'] = edited?.account;

      // temp address
      if (item === 'tcity') finalPayload['temp_address']['city'] = edited?.tcity;
      if (item === 'tstate') finalPayload['temp_address']['state'] = edited?.tstate;
      if (item === 'tcountry') finalPayload['temp_address']['country'] = edited?.tcountry;
      if (item === 'taddress') finalPayload['temp_address']['address'] = edited?.taddress;
      if (item === 'tpincode') finalPayload['temp_address']['pincode'] = edited?.tpincode;
    });
    return deleteEmptyValues(finalPayload, 2);
  };
  const onEditSubmit = async (data) => {
    setLoading(true);
    const editedFields = getEditedFields({
      initialValues: employeeData,
      updatedData: { ...data, image: data?.image },
    });

    const editedDocsFields = getEditedDocsWithRemovedField(
      data.docs ?? [],
      employeeData.docs ?? [],
    );
    if (Object.keys(editedFields).length) {
      const finalPayload = parsePayload(editedFields);
      finalPayload.docs = editedDocsFields.docs;
      finalPayload.remove_docs = editedDocsFields.remove_docs;
      const resp = await updateEmployee({ data: finalPayload, id: entryId });
      if (resp?.data?.status) {
        updateEmployees();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        // navigate(-1);
        onClose();
      } else {
        const head = resp?.data?.msg;
        if (typeof head === 'object') {
          Object.keys(head).forEach((key) => {
            Toast('error', NOTIFICATION_MSG.error, `${key} : ${head[key]}` || 'Please try again');
          });
        }
      }
      setLoading(false);
    } else {
      setLoading(false);
      return Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
  };

  useEffect(() => {
    setValues(refValues.current);
  }, [toggle]);

  const setValues = debounce((data) => {
    setInitialData(data);
  }, 1000);
  if (loading) {
    return <Loader />;
  }

  return (
    <div className='grid gap-3 h-[75vh]'>
      {getPermission(permissions, elementData.employee.permissions.create)?.write ? (
        <>
          <div className='flex flex-col bg-white rounded-lg '>
            <FormContainer
              accordionClass='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-9 relative mb-14'
              fields={finalFormFields}
              initialValues={
                isEditing
                  ? employeeData
                  : Array.isArray(initialData)
                  ? { ...initialValues, tcountry: 'IN', country: 'IN' }
                  : initialData
              }
              onSubmit={isEditing ? onEditSubmit : handleAddEmployee}
              customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm, values }) => {
                // setValues(values);
                refValues.current = values;
                return (
                  <div className='flex flex-row justify-end items-center  mt-6 pr-4 h-16 w-full absolute right-0 bottom-0 space-x-3 bg-grey-light opacity-90'>
                    <div>
                      <Button
                        text='Cancel'
                        variant='text'
                        type='reset'
                        classes='border border-gray-90 text-black px-6 z-10 h-fit'
                        onClick={() => {
                          resetForm([{ ...initialValues, tcountry: 'IN', country: 'IN' }]);
                          onClose();
                        }}
                      />
                      <Button
                        id={
                          employeeData
                            ? elementData.employee.permissions.update
                            : elementData.employee.permissions.create
                        }
                        text={entryId ? 'Update Employee' : 'Add Employee'}
                        variant='primary'
                        classes={`ml-3 px-8 h-fit  ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                        onClick={submitForm}
                        loading={loading}
                      />
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </>
      ) : (
        <Module />
      )}
    </div>
  );
};

export default SecureComponent(AddEditEmployee);

const getEditedDocsWithRemovedField = (newDocs, oldDocs) => {
  const finalNewDocs = [];
  const finalRemovedIds = [];

  [...newDocs].forEach((doc) => {
    if (doc.id && !doc.file) return;

    if (doc.id && doc.file?.startsWith('data:image')) {
      finalNewDocs.push(doc);
    } else if (doc.id && !doc.file?.startsWith('data:image') && !oldDocs.includes(doc.name)) {
      finalNewDocs.push({ id: doc.id, name: doc.name });
    } else if (!doc.id && doc.file && doc.name) {
      finalNewDocs.push(doc);
    }
  });

  oldDocs.forEach((doc) => {
    if (
      !finalNewDocs
        .map((item) => item.id)
        .filter(Boolean)
        .includes(doc.id)
    )
      finalRemovedIds.push(doc.id);
  });
  return { docs: finalNewDocs, remove_docs: finalRemovedIds };
};
