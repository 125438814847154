import { DatePicker, Table } from 'antd';
import { ContentHeader, DocExport } from 'components';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getDailySalesReport } from 'service/api-service';
import { Context } from 'store/context';
import { columns, parsedData } from './data';

const DailySalesReport = (props = {}) => {
  const permissions = props?.permissions || [];
  const printRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const { user } = useContext(Context);

  const fetchData = async () => {
    setLoading(true);
    const resp = await getDailySalesReport({
      date: currentDate.format('YYYY-MM-DD'),
    });
    if (resp?.data?.status) {
      setData(resp?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getDateRange = async (e) => {
    setLoading(true);
    if (e) {
      const startDate = dayjs(e.$d).format('YYYY-MM-DD');
      setCurrentDate(startDate);
      const resp = await getDailySalesReport({
        date: startDate,
      });
      if (resp?.data?.status) {
        setData(resp?.data?.data);
      }
    } else {
      setCurrentDate('');
      const resp = await getDailySalesReport({ date: '' });
      if (resp?.data?.status) {
        setData(resp?.data?.data);
      }
    }
    setLoading(false);
  };

  return (
    <div className='grid gap-3'>
      <ContentHeader
        title={<h4>{user?.org_id?.name}</h4>}
        permissions={permissions}
        showSearch={false}
        appendChildren={false}
      >
        <DatePicker defaultValue={dayjs(currentDate, 'YYYY-MM-DD')} onChange={getDateRange} />
        <DocExport
          data={parsedData(data)}
          file={`Daily Sales Report ${currentDate}`}
          printRef={printRef.current}
          id='divToPrint'
        />
      </ContentHeader>
      <div id='divToPrint' ref={printRef}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data?.transactions}
          summary={(pageData) => {
            return (
              <Table.Summary.Row className='h-16 font-bold bg-grey-light text-s'>
                <Table.Summary.Cell colSpan={3}>Total</Table.Summary.Cell>
                <Table.Summary.Cell>
                  {data?.total_amount?.toFixed(2) || '\u2014'}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {data?.total_pos_commissions?.toFixed(2) || '\u2014'}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {data?.toal_net_amount?.toFixed(2) || '\u2014'}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </div>
    </div>
  );
};

export default DailySalesReport;
