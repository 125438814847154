export const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/dashboard',
  },
  {
    name: 'Roles',
    path: '/roles',
  },
  {
    name: 'Permissions',
    path: '',
  },
];

export const initialTableData = [];

/**
 *  takes data from api and transforms it to antd-friendly data
 * @param {{
 * id: number,
 * name: string,
 * read: boolean,
 * write: boolean,
 * children: [{
 * id: number,
 * name: string,
 * read: boolean,
 * write: boolean,
 * }]
 * }[]} rawData
 */
export const getFormattedData = (rawData) =>
  rawData.map((parent) => ({
    ...parent,
    key: parent.id,
    children: parent.children.map((child) => ({
      ...child,
      key: child.id,
      name: child.name.replaceAll('_', ' '),
    })),
  }));
