import { Link } from 'react-router-dom';
import { Logout } from 'service/api-service';
export const items = [
  {
    label: (
      <Link to='/profile' className='p-4'>
        Profile
      </Link>
    ),
    key: '0',
  },
  {
    label: (
      <Link to='/setting' className='p-4'>
        Setting
      </Link>
    ),
    key: '1',
  },
  {
    label: (
      <Link to='/login' className='p-4' onClick={Logout}>
        Logout
      </Link>
    ),
    key: '2',
  },
];
