/** external deps */
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

/** internal deps */
import { SecureComponent } from 'permission-provider';
import { ContentHeader, ViewData, TabsComponent } from 'components';
import { breadCrumbs, formatViewData } from './data';
import Overview from '../overview';
import Tasks from '../tasks';
import { elementData } from 'elements';
import Contacts from '../contact';
import Payment from '../payment';
import ViewPolicy from '../policy/view-policy';
import { getCustomer } from 'service/api-service';
import { generatePermittedTabs } from 'utils';
import { Context } from 'store/context';
import { formatInquiryViewData } from '../inquiry/data';
import Leads from '../leads';
import ClientCallLogs from '../client-call-logs';

const ViewCustomerDetails = ({ permissions = {} }) => {
  const { id } = useParams();
  const { id: customer_id } = useParams();

  /* states  */
  const [data, setData] = useState([]);
  const [key, setActiveTabKey] = useState('1');
  const [showInquiryTabs, setShowInquiryTabs] = useState(false);
  const { selectedData } = useContext(Context);

  const tabs = [
    {
      key: '1',
      label: `Overview`,
      id: elementData.client.permissions.view,
      Component: Overview,
      props: {
        customer_id: customer_id,
        permissions: permissions,
        id: elementData.client.permissions.view,
        data: data,
        setActiveTabKey: setActiveTabKey,
      },
    },
    {
      key: '2',
      id: elementData.client.permissions.view,
      label: `Client Details`,
      Component: ViewData,
      props: {
        permissions: permissions,
        id: elementData.client.permissions.view,
        data: formatViewData(data),
        classes: 'bg-white rounded-2xl p-4 mt-4',
      },
    },
    {
      key: '3',
      id: elementData.client.name,
      label: `Contacts`,
      Component: Contacts,
      props: {
        customer_id: customer_id,
        id: elementData.client.name,
        permissions: permissions,
        customerData: data,
      },
    },
    {
      key: '4',
      id: [elementData.task.name, elementData.meeting.name],
      label: `Tasks`,
      Component: Tasks,
      props: {
        customer_id: customer_id,
        permissions: permissions,
        id: elementData.client.permissions.view,
        customerName: { name: data?.customer_name, id: data?.id },
      },
    },

    {
      key: '5',
      label: `Leads`,
      id: elementData.Leads.name,
      Component: Leads,
      props: {
        customer: data,
        setTabs: setShowInquiryTabs,
        permissions: permissions,
        id: elementData.Leads.name,
      },
    },
    // {
    //   id: elementData.Quote.name,
    //   key: "6",
    //   label: `Quote`,
    //   Component: Quotes,
    //   props: {
    //     customer: { id: customer_id, name: data?.customer_name },
    //     permissions: permissions,
    //   },
    // },
    {
      key: '7',
      label: `Policy`,
      id: elementData.policy.name,
      Component: ViewPolicy,
      props: {
        customer: { id: customer_id, name: data?.customer_name },
        id: elementData.policy.name,
        permissions: permissions,
      },
    },
    // {
    //   key: '8',
    //   label: `Payment`,
    //   Component: Payment,
    // },
    {
      key: '9',
      label: `Call Logs`,
      id: elementData.client.permissions.view,
      Component: ClientCallLogs,
      props: {
        customer_id: customer_id,
        permissions: permissions,
        id: elementData.client.permissions.view,
        setActiveTabKey: setActiveTabKey,
      },
    },
  ];

  const inquiryTabs = [
    {
      key: '1',
      label: `Details`,
      id: elementData.Leads.permissions.view,
      Component: ViewData,
      props: {
        permissions: permissions,
        id: elementData.Leads.permissions.view,
        pageTitle: 'Inquiry Details',
        data: formatInquiryViewData(selectedData.data),
        classes: 'bg-white rounded-2xl p-4',
        showGoToBack: true,
        goBack: () => setShowInquiryTabs(false),
      },
    },
  ];

  /* funcs */
  const onGetCustomer = async () => {
    const resp = await getCustomer({ id });
    setData(resp?.data?.data || []);
  };

  useEffect(() => {
    onGetCustomer();
  }, []);

  useEffect(() => {
    setActiveTabKey('1');
  }, [showInquiryTabs]);

  return (
    <div className='grid gap-3'>
      <TabsComponent
        items={
          showInquiryTabs
            ? generatePermittedTabs(inquiryTabs, permissions)
            : generatePermittedTabs(tabs, permissions)
        }
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
        renderTabBar={true}
      />
    </div>
  );
};

export default SecureComponent(ViewCustomerDetails);
