import { endpoints } from 'constants/endpoints';
import Request from './client';

export const getBranches = async (data = { query: '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.branch.list(data?.query),
  });
  return resp;
};

export const addBranch = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.branch.create,
    data,
  });
  return resp;
};

export const deleteBranch = async ({ id, data }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.branch.delete(id),
    data,
  });
  return resp;
};

export const updateBranch = async ({ id, data }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.branch.update(id),
    data,
  });
  return resp;
};
