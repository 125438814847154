import { Space } from 'antd';
import { DELETE, Document, EDIT, View } from 'assets/icons';
import IconButton from 'components/button/IconButton';

const noop = () => {};

export const getColumns = (onClickEdit = noop, onclickView = noop, onClickDelete = noop) => [
  {
    key: 'name',
    title: 'Document Name',
    dataIndex: 'name',
    render: (dataIndex, record) => (
      <div className='flex gap-1 items-center'>
        <Document svgClass='w-6 h-6' />
        <span>{dataIndex}</span>
      </div>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'right',
    width: 50,
    render: (item) => (
      <Space size='middle'>
        <IconButton className='group disabled:cursor-not-allowed'>
          <View
            svgClass='stroke-primary group-disabled:stroke-grey w-6 h-6'
            onClick={() => onclickView(item)}
          />
        </IconButton>

        <IconButton className='group disabled:cursor-not-allowed'>
          <EDIT
            svgClass='stroke-green-90 group-disabled:stroke-grey w-6 h-6'
            onClick={() => onClickEdit(item)}
          />
        </IconButton>

        <IconButton className='group disabled:cursor-not-allowed'>
          <DELETE
            svgClass='stroke-red-90 group-disabled:stroke-grey w-6 h-6'
            onClick={() => onClickDelete(item)}
          />
        </IconButton>
      </Space>
    ),
  },
];
