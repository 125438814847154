/** external deps */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

/** internal deps */
import { Button, FormContainer, Module, Toast } from 'components';
import { contactFields, extraFields, validateClientDocumentArray } from './data';
import {
  addCustomer,
  getProductsByCategory,
  searchApis,
  updateCustomer,
} from 'service/api-service';
import { SecureComponent } from 'permission-provider';
import {
  formatData,
  getCustomerFields,
  getEditedFields,
  getPermission,
  retrieveRequiredFields,
} from 'utils';
import { Context } from 'store/context';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

const AddCustomer = (props) => {
  let navigate = useNavigate();
  const {
    customers: { updateCustomers },
    user,
  } = useContext(Context);
  const customerFields = JSON.parse(localStorage.getItem('customerFields')).find(
    (item) => item?.title === 'Insured Details',
  );
  const customerType = customerFields?.fields?.find((item) => item?.name === 'customer_type');
  const retailCustomerId = customerType?.options?.find(
    (item) => (item?.name).toLowerCase() === 'retail',
  )?.id;
  const renderOnlyRequiredFields = props?.renderRequiredOnly === true ? true : false;
  const renderContactDetailsOnly = props?.renderContactDetailsOnly === true ? true : false;
  const permissions = props?.permissions || {};
  const state = props?.entryId;
  // const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [addProduct, setAddProduct] = useState(false);
  const [fieldsLoading, setFeildsLoading] = useState(true);
  const [finalFields, setFinalFields] = useState({});
  const [initVals, setInitVals] = useState({});
  const userBranches = Array.isArray(user?.branch) ? user?.branch[0] : user?.branch;
  const productInitials = state?.initialValues?.products?.map((item) => {
    return {
      ...item,
      expiry_date: dayjs(item?.expiry_date),
    };
  });

  const newInitialValues = {
    ...state?.initialValues,
    insured_members: state?.initialValues?.insured_members
      ? JSON.parse(state?.initialValues?.insured_members)
      : [],
    spouse: state?.initialValues?.insure?.spouse,
    mother: state?.initialValues?.insure?.mother,
    father: state?.initialValues?.insure?.father,
    son: state?.initialValues?.insure?.son,
    daughter: state?.initialValues?.insure?.daughter,
    branch_of: state?.initialValues ? state?.initialValues?.branch_of?.id : userBranches?.id,
    email_address: state?.initialValues?.email_address || '',
    assign_to_employee: state ?? state?.initialValues?.assign_to_employee,
    products:
      state && state?.initialValues?.products.length !== 0
        ? productInitials
        : [
            {
              product: null,
              document: '',
              expiry_date: null,
            },
          ],
    preference_product:
      state && state?.initialValues?.preference_product?.length !== 0
        ? state?.initialValues?.preference_product?.map((item) => ({
            preference_category: item?.preference_category?.id,
            preference_product: item?.preference_product?.id,
            id: item?.id,
          }))
        : [{ preference_category: '', preference_product: '' }],
  };

  const getSortedFields = (fields) => {
    const newObj = { ...fields };
    Object.keys(fields).forEach((item, index) => {
      const newFields = props.toggle
        ? newObj[item].fields.filter(
            (d) =>
              (d.required || item === 'Insured Details') &&
              d.name !== 'meeting_date' &&
              d.name !== 'lead_source' &&
              d.name !== 'date_of_birth' &&
              d.name !== 'anniversary' &&
              d.name !== 'insured_members',
          )
        : newObj[item].fields;
      newObj[item] = {
        fields: newFields,
        id: item.toLowerCase().replace(/ /g, ''),
        subTitle: newFields.length ? (
          <h2 id={item.toLowerCase().replace(/ /g, '')} className='font-semibold text-s'>
            {item}
          </h2>
        ) : null,
        showAccordionHeader: false,
      };
    });
    return newObj;
  };

  const onGetFields = async () => {
    setFeildsLoading(true);
    if (props.setDrawerLoading) {
      props?.setDrawerLoading(true);
    }
    const ins_category = '';
    let productOption = [];
    let categoriesOptions = [];
    const resp = await getProductsByCategory(ins_category);
    if (resp?.status) {
      const withoutNoneProducts = resp?.data?.data?.map((item) => {
        return {
          name: item?.name,
          label: item?.name,
          value: item?.id,
        };
      });

      productOption = [
        {
          name: null,
          value: null,
          label: 'None',
          id: null,
        },
        ...withoutNoneProducts,
      ];
    }

    const resp2 = await searchApis({ api: 'insurance_category' });
    categoriesOptions = resp2?.data?.data?.map((item) => {
      return {
        label: item?.name,
        id: item?.id,
        value: item?.id,
        name: item?.name,
      };
    });
    const data = await getCustomerFields();
    data[0].fields.push(...extraFields);
    const [fields, initialValues] = formatData(
      data,
      productOption,
      addProduct,
      setAddProduct,
      categoriesOptions,
      {
        assign_to_employee: !state ? '' : initVals?.assign_to_employee,
        branch_of: !state ? '' : initVals?.branch_of?.id,
        country: state?.initialValues?.country || 'IN',
        r_country: state?.initialValues?.r_country || 'IN',
        customer_type: state?.initialValues?.customer_type?.id || '',
        lead_source: state?.initialValues?.lead_source?.id || '',
        local_status: state?.initialValues?.local_status?.id || '',
        bank_account_type: state?.initialValues?.bank_account_type?.id || '',
        credit_rating: state?.initialValues?.credit_rating?.id || '',
        credit_term: state?.initialValues?.credit_term?.id || '',
      },
    );
    const obj = getSortedFields(
      renderOnlyRequiredFields
        ? retrieveRequiredFields(fields)
        : renderContactDetailsOnly
        ? contactFields
        : fields,
    );
    setFinalFields(obj);
    if (props?.getFieldsForParent) {
      props?.getFieldsForParent(obj);
    }
    setInitVals(initialValues);
    setFeildsLoading(false);
    if (props.setDrawerLoading) {
      props?.setDrawerLoading(false);
    }
  };

  useEffect(() => {
    setFeildsLoading(true);
    setTimeout(() => {
      setFeildsLoading(false);
    }, 100);
  }, [state]);

  useEffect(() => {
    onGetFields();
  }, [props.toggle]);

  /** add customer */
  const handleAddCustomer = async (data) => {
    setLoading(true);
    const result = validateClientDocumentArray(data?.products);

    const finalPayload = {
      ...data,
      customer_type: renderContactDetailsOnly ? retailCustomerId : data?.customer_type,
      insured_members: JSON.stringify(data?.insured_members || ''),
      insure: {
        spouse: data?.spouse || '',
        mother: data?.mother || '',
        father: data?.father || '',
        son: data?.son || '',
        self: data?.self || '',
        daughter: data?.daughter || '',
      },
      date_of_birth: data?.date_of_birth || null,
      anniversary: data?.anniversary || null,
      products: data?.products?.[0]?.product ? data?.products : [],
    };

    const validate =
      finalPayload?.customer_type === retailCustomerId
        ? finalPayload?.customer_name
        : finalPayload?.corp_name;
    if (validate && result) {
      try {
        const resp = await addCustomer({ data: finalPayload });
        if (resp?.data?.status) {
          updateCustomers();
          if (props?.setCustomer) {
            props?.setCustomer({ name: data?.customer_name, id: resp?.data?.data?.id });
            props?.onSuccess();
            props?.onClose();
          } else if (props?.onSuccess) {
            props?.onSuccess();
          } else {
            // navigate('/client');
            props?.onClose();
          }
          props?.onClose();
          Toast('success', NOTIFICATION_MSG.success, resp?.data?.data?.msg);
        } else {
          Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
        }
      } catch (error) {
        console.log('error', error);
      }
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.info,
        result
          ? `${
              data?.customer_type === retailCustomerId ? 'Customer' : 'Corporation'
            } name is required`
          : 'kindly fill previous insurance expiry date',
      );
    }

    setLoading(false);
  };

  /**  edit customer */
  const handleUpdateCustomer = async (data) => {
    setLoading(true);
    const validate = data?.customer_name || data?.corp_name;
    const productsArray = data?.products?.map((item) => {
      return {
        ...item,
        expiry_date: item?.expiry_date ? dayjs(item?.expiry_date).format('YYYY-MM-DD') : null,
      };
    });
    const result = validateClientDocumentArray(productsArray);
    const orignalArray = state?.initialValues?.products.filter((obj1) =>
      productsArray.some((obj2) => obj2.id === obj1.id),
    );

    const removeProducts = state?.initialValues?.products.filter(
      (obj2) => !productsArray.some((obj1) => obj1.id === obj2.id),
    );
    const removePrefrence = state?.initialValues?.preference_product.filter(
      (obj2) => !data?.preference_product.some((obj1) => obj1.id === obj2.id),
    );
    const finalProducts = orignalArray
      ?.map((originalObj, index) => {
        const changedObj = productsArray[index];
        const id = originalObj.id;
        const changes = {};
        for (const key in originalObj) {
          if (key !== 'id' && originalObj[key] !== changedObj[key]) {
            changes[key] = changedObj[key];
          }
        }
        if (Object.keys(changes).length > 0) {
          return { id, ...changes };
        }
        return null;
      })
      .filter(Boolean);

    productsArray.forEach((changedObj) => {
      if (!orignalArray.some((obj) => obj.id === changedObj.id)) {
        finalProducts.push(changedObj);
      }
    });

    const PayloadProduct = () => {
      if (
        finalProducts?.length === 1 &&
        finalProducts[0]?.product === null &&
        finalProducts[0]?.document === '' &&
        finalProducts[0]?.expiry_date === null
      ) {
        return [];
      } else return finalProducts;
    };

    const id = state?.initialValues?.id;
    if (!id) return;
    if (validate && result) {
      let newEditedFields = {};
      const editedFields = getEditedFields({
        initialValues: newInitialValues,
        updatedData: data,
      });
      newEditedFields = {
        ...editedFields,
        insured_members: JSON.stringify(
          editedFields?.insured_members || newInitialValues?.insured_members,
        ),
        country: null,
        assign_to_employee:
          typeof data?.assign_to_employee === 'string'
            ? state?.initialValues?.assign_to_employee?.id
            : data?.assign_to_employee,
        // assign_to_employee: data?.assign_to_employee?.id ?? state?.initialValues?.assign_to_employee?.id,
        insure: {
          son: editedFields?.son || newInitialValues?.son,
          father: editedFields?.father || newInitialValues?.father,
          mother: editedFields?.mother || newInitialValues?.mother,
          daughter: editedFields?.daughter || newInitialValues?.daughter,
          spouse: editedFields?.spouse || newInitialValues?.spouse,
        },
        products: PayloadProduct(),
        remove_products: removeProducts?.map((item) => item?.id) || [],
        remove_preference_product: removePrefrence?.map((item) => item?.id) || [],
      };

      if (Object?.keys(newEditedFields).length >= 1) {
        const resp = await updateCustomer({ data: newEditedFields, id });
        if (resp?.data?.status) {
          updateCustomers();
          // navigate('/client');
          props?.onClose();
          Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        } else {
          const head = resp?.data?.msg;

          if (typeof head === 'object') {
            Object?.keys(head).forEach((key) => {
              Toast('error', NOTIFICATION_MSG.error, ` ${head[key]}` || 'Please try again');
            });
          } else {
            Toast('error', NOTIFICATION_MSG.error, resp?.msg, 'Please try again');
          }
        }

        setLoading(false);
      } else {
        setLoading(false);
        Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
      }
    } else {
      Toast(
        'info',
        NOTIFICATION_MSG.info,
        result
          ? `${
              data?.customer_type === retailCustomerId ? 'Customer' : 'Corporation'
            } name is required`
          : 'kindly fill previous insurance expiry date',
      );
    }
    setLoading(false);
  };

  const setValues = debounce((data) => {
    if (props.initialData) props.initialData.current = data;
  }, 500);

  return (
    <div className='grid gap-3'>
      {getPermission(permissions, elementData.client.permissions.create)?.write ? (
        <>
          <div className='flex flex-col p-4 bg-white rounded-lg'>
            {fieldsLoading ? (
              <div className='flex justify-center items-center h-screen bg-white'>
                <Spin size='large' />
              </div>
            ) : (
              <FormContainer
                key={props?.entryId || props?.refreshToken}
                accordionClass={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-6 ${props?.formClasses}`}
                fields={finalFields}
                initialValues={
                  state?.initialValues
                    ? {
                        ...newInitialValues,
                        customer_type: state?.initialValues?.customer_type?.id,
                        assign_to_employee: state?.initialValues?.assign_to_employee?.name,
                      }
                    : Array.isArray(props.initialData?.current)
                    ? newInitialValues
                    : props.initialData?.current ?? {}
                }
                onSubmit={state?.initialValues ? handleUpdateCustomer : handleAddCustomer}
                customSubmitRender={({
                  isValid,
                  isSubmitting,
                  submitForm,
                  resetForm,
                  values,
                  ...rest
                }) => {
                  if (!state?.initialValues && values) {
                    setValues(values);
                  }
                  return (
                    <div
                      className={`flex flex-row justify-end  p-4 mt-6 h-16 w-full absolute right-0 bottom-0 space-x-3 bg-grey-light opacity-90 ${
                        !props?.isPopup && 'fixed bottom-0 right-0'
                      } space-x-3`}
                    >
                      <Button
                        text='Cancel'
                        variant='text'
                        type='reset'
                        classes='border border-gray-90 text-black px-6 h-fit'
                        onClick={() => {
                          resetForm();
                          if (props?.onCancel) return props.onCancel();

                          props?.onClose();
                        }}
                      />

                      <Button
                        id={
                          state?.initialValues
                            ? elementData.client.permissions.update
                            : elementData.client.permissions.create
                        }
                        // disabled={!isValid}
                        text={state?.initialValues ? 'Update Client' : 'Add Client'}
                        disabled={!isValid}
                        type='button'
                        variant='primary'
                        classes={`ml-6 px-8 h-fit  ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                        onClick={submitForm}
                        loading={loading}
                      />
                    </div>
                  );
                }}
              />
            )}
          </div>
        </>
      ) : (
        <Module />
      )}
    </div>
  );
};

export default SecureComponent(AddCustomer);
