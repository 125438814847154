/** external deps */
import React, { useEffect, useState } from 'react';
/** internal deps */
import { getMeetings, meetingManagement } from 'service/api-service';
import { DragDropProvider, Toast } from 'components';
import { getPermission, insertElement } from 'utils';
import { elementData } from 'elements';
import { NOTIFICATION_MSG } from 'constants';
import MeetingTable from './table';
import { subscribe } from 'utils/communication';
import { REFRESH_MEET } from './data';
import dayjs from 'dayjs';

const Meetings = (props) => {
  const permissions = props?.permissions || [];
  const table = props?.showTable || false;
  const searchQuery = props?.searchQuery || '';
  const status = props?.status || '';
  const startDate = props?.startDate || '';
  const endDate = props?.endDate || '';
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total_page, setTotalPage] = useState();
  const [page, setPageNum] = useState(1);
  const [sections, setSections] = useState([]);
  const activeSections = props?.activeColumns || [];

  const getActiveColumns = (allSections) => {
    if (!activeSections || activeSections.length <= 0) return allSections;

    const temp = {};
    activeSections.forEach((section) => {
      temp[section?.value] = allSections[section?.value];
    });
    return temp;
  };

  const setCategoryTask = (taskList) => {
    let final = {
      not_started: {
        topborder: 'after:bg-red',
        id: 'not_started',
        title: 'Not started',
        tasks: [],
      },
      in_progress: {
        topborder: 'after:bg-primary-90',
        id: 'in_progress',
        title: 'In Progress',
        tasks: [],
      },
      pending_input: {
        topborder: 'after:bg-grey',
        id: 'pending_input',
        title: 'Pending Input',
        tasks: [],
      },
      backlog: {
        topborder: 'after:bg-yellow',
        id: 'backlog',
        title: 'Backlog',
        tasks: [],
      },
      archived: {
        topborder: 'after:bg-red-80',
        id: 'archived',
        title: 'Archived',
        tasks: [],
      },
      completed: {
        topborder: 'after:bg-green',
        id: 'completed',
        title: 'Completed',
        tasks: [],
      },
    };
    taskList.forEach((element) => {
      final[element?.status]['tasks'] = final[element?.status]['tasks'] = insertElement(
        final[element?.status]['tasks'],
        final[element?.status]['tasks']?.length,
        element,
      );
    });
    return getActiveColumns(final);
  };

  const onGetMeetings = async ({
    q = '',
    startDate = dayjs().format('YYYY-MM-DD'),
    endDate = dayjs().format('YYYY-MM-DD'),
    status,
    page = 1,
  } = {}) => {
    setLoading(true);
    const resp = await getMeetings({
      query: q,
      period: props?.period,
      endDate: endDate,
      startDate: startDate,
      status,
      page,
    });
    if (resp?.data?.status) {
      const list = resp.data?.data?.result;
      setSections(setCategoryTask(list));
      setPageNum(resp?.data?.data?.current_page);
      setTotalPage(resp?.data?.data?.total_page);
      setMeetings(list);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to fetch meetings from server');
    }
    setLoading(false);
  };

  useEffect(() => {
    subscribe(REFRESH_MEET, onGetMeetings);
  }, []);

  const fetchMoreData = async ({
    q = '',
    startDate = dayjs().format('YYYY-MM-DD'),
    endDate = dayjs().format('YYYY-MM-DD'),
    status,
    page = 1,
  } = {}) => {
    // setLoading(true);
    const resp = await getMeetings({
      query: q,
      period: props?.period,
      endDate: endDate,
      startDate: startDate,
      status,
      page,
    });
    if (resp.data?.data) {
      const list = resp.data?.data?.result || [];
      setSections(setCategoryTask([...meetings, ...list]));
      setPageNum(resp?.data?.data?.current_page);
      setTotalPage(resp?.data?.data?.total_page);
      setMeetings([...meetings, ...list]);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to fetch tasks from server');
    }
    // setLoading(false);
  };

  useEffect(() => {
    onGetMeetings({ q: searchQuery, startDate, endDate, status });
  }, [props?.period, searchQuery, startDate, endDate, status]);

  useEffect(() => {
    setSections(setCategoryTask(meetings));
  }, [activeSections?.length]);

  const onDragEnd = (result) => {
    if (!getPermission(permissions, elementData.task.permissions.update)?.write)
      return Toast('error', NOTIFICATION_MSG.error, "You don't have update right.");

    let intrim = sections;
    const { destination, source } = result;
    const destSection = intrim?.[destination.droppableId];
    const [dragedTask] = intrim?.[source.droppableId].tasks.splice(source?.index, 1);
    if (destination.droppableId !== source.droppableId) {
      meetingManagement({ method: 'patch', id: dragedTask?.id, data: { status: destSection?.id } });
    }
    insertElement(destSection?.tasks, destination?.index, dragedTask);
  };

  return (
    <div className='mt-[10px]'>
      {table ? (
        <MeetingTable
          total_page={total_page}
          page={page}
          tasks={meetings}
          permissions={permissions}
          onGetTasks={onGetMeetings}
          startDate={startDate}
          endDate={endDate}
        />
      ) : (
        <DragDropProvider
          loading={loading}
          onDragEnd={onDragEnd}
          sections={sections}
          onGetTasks={onGetMeetings}
          fetchMoreData={fetchMoreData}
          page={page}
          total_page={total_page}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </div>
  );
};

export default Meetings;
