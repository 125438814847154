import { FormContainer, SelectInput, Toast } from 'components';
import { ProductFields, SubmitButtons } from '../data';
import { prepareFormFieldsData } from 'utils';
import './styles.css';
import { useCallback, useContext, useEffect, useState } from 'react';
import { LeadForm } from 'components/lead-form/lead-components/SwitchForm';
import { getInsuranceCategory, searchApis, searchInsuranceApis } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { useLeads } from '../store';
import { useShallow } from 'zustand/react/shallow';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from 'store/context';
import IciciProtectForm from 'components/lead-form/forms/IciciProtectForm';

export const LabelValue = ({ label, value, error, divClasses = '' }) => (
  <div className={divClasses}>
    <div className='border-1'>{label}</div>
    <div className='flex-1'>
      {value}
      {error ? <div className='text-red'>{error}</div> : null}
    </div>
  </div>
);

const AddMultiProduct = ({ initials = {}, submitted, count, setCheckSubmit }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useContext(Context);

  const { addproductArray, productArray, buttonPressed, onSubmitLead, client } = useLeads(
    useShallow((state) => ({
      addproductArray: state.addproductArray,
      buttonPressed: state.buttonPressed,
      onSubmitLead: state.onSubmitLead,
      productArray: state.createLead.productArray,
      client: state.createLead.client,
    })),
  );

  const [product, setProduct] = useState(null);
  const [category, setcategory] = useState(null);
  const [insurer, setInsurer] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const [insurerOptions, setInsurerOptions] = useState();
  const [formLoading, setFormLoading] = useState();

  const formValues = {
    insurance_category: initials?.categoryId,
    product_type: initials?.productId,
    insurer: initials?.insurer,
  };

  const getInsurers = async () => {
    const resp = await searchApis({ query: '', api: 'insurer' });
    const data = resp?.data?.data;
    const options = data?.map((item) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    });

    const finalOptions = initials?.insurer?.map((item) => {
      const obj = options?.find((ele) => ele?.value === item);
      return {
        label: obj.label,
        id: obj.value,
        name: obj.label,
        value: obj.value,
      };
    });
    setInsurerOptions(finalOptions);
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: ProductFields({ setProduct, setInsurer, setcategory, category, insurerOptions, count }),
  });

  const submitSingleProduct = (formdata, wantExit) => {
    const isProductValid = category?.id && product?.id && insurer?.length;
    const errorMessage = `${!product ? 'Product' : !category ? 'Category' : 'Insurer'} is required`;
    const payload = {
      ...formdata,
      categoryId: category?.id,
      productId: product?.id,
      productName: product?.name,
      value: product?.formId,
      formId: product?.formId,
      insurer: insurer?.map((item) => item?.value),
    };

    if (buttonPressed === 'add') {
      if (isProductValid) {
        addproductArray(payload);
        setProduct(null);
        setcategory(null);
        setInsurer([]);
        setRefresh((prev) => !prev);
      } else {
        Toast('info', NOTIFICATION_MSG.info, errorMessage);
      }
    } else if (buttonPressed === 'saveAndNext') {
      if (!client?.id) {
        Toast('info', NOTIFICATION_MSG.info, 'Client Field is required');
      } else {
        if (product?.id) {
          if (isProductValid) {
            const wantNavigate = true;
            addproductArray(payload);
            setProduct(null);
            setcategory(null);
            setInsurer([]);
            onSubmitLead(navigate, params, user, wantNavigate);
            setRefresh((prev) => !prev);
          } else {
            Toast('info', NOTIFICATION_MSG.info, errorMessage);
          }
        } else {
          const wantNavigate = true;
          onSubmitLead(navigate, params, user, wantNavigate);
        }
      }
    } else if (buttonPressed === 'next') {
      if (!client?.id) {
        Toast('info', NOTIFICATION_MSG.info, 'Client Field is required');
      } else {
        if (product?.id) {
          if (isProductValid) {
            const wantNavigate = false;
            addproductArray(payload);
            setProduct(null);
            setcategory(null);
            setInsurer([]);
            onSubmitLead(navigate, params, user, wantNavigate);
            setRefresh((prev) => !prev);
          } else {
            Toast('info', NOTIFICATION_MSG.info, errorMessage);
          }
        } else {
          const wantNavigate = false;
          onSubmitLead(navigate, params, user, wantNavigate);
        }
      }
    }
  };

  useEffect(() => {
    if (count) {
      getInsurers();
    }
  }, []);

  return (
    <>
      <div id='' className='shadow-darkmd rounded-lg p-2 mt-8 pb-4 relative'>
        <p className='text-xs font-semibold'>
          {count ? `Insured details # ${count}` : 'Add New Insured Details '}{' '}
        </p>
        {/* div below is the reference for the radio field of two and four wheeler form */}
        <div id={`leads-custom-radio-${count}`} />
        <FormContainer
          key={refresh}
          accordionClass='grid grid-cols-3 gap-x-6 gap-y-5 p-2 mb-4 items-center'
          onSubmit={() => {}}
          resetAfterSubmit={true}
          initialValues={
            count
              ? formValues
              : {
                  insurance_category: null,
                  product_type: null,
                  insurer: null,
                }
          }
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
            <div></div>;
          }}
        />
        {product?.formId === 'ICICIProtectForm' ? (
          <IciciProtectForm.Form currProduct={product} submitProduct={submitSingleProduct} />
        ) : (
          <LeadForm
            id={product?.formId || initials?.formId || productArray[count]?.formId}
            submitProduct={submitSingleProduct}
            insurer={initials?.insurer || insurer}
            setCheckSubmit={setCheckSubmit}
            setFormLoading={setFormLoading}
            formValues={{ ...initials, count }}
          />
        )}
      </div>

      {productArray.length > 0 && !product?.id && !count && <SubmitButtons onlyNext={true} />}
    </>
  );
};

export default AddMultiProduct;
