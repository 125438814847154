/** external deps */
import React from 'react';
/** internal deps */
import { IconButton } from 'components';
import { BackArrow } from 'assets/icons';
import { Link } from 'react-router-dom';
import LeadDiscussion from './LeadDiscussion';

const ViewData = ({
  pageTitle,
  data,
  className = 'grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6',
  classes,
  goBack,
  showGoToBack = false,
  customButton,
  bottomSection,
  id,
}) => {
  return (
    <main className={`grid gap-2 w-full ${classes} h-max`}>
      {(pageTitle || showGoToBack) && (
        <div className={`flex justify-between items-center relative ${pageTitle ? 'h-10' : 'h-4'}`}>
          <div className='flex flex-row gap-4'>
            {goBack && (
              <IconButton onClick={goBack} className='group top-1 flex items-center'>
                {showGoToBack && (
                  <>
                    <BackArrow svgClass='fill-grey-90 w-8 h-8 group-hover:fill-primary-90 transition-all  duration-500 group-hover:-translate-x-1' />
                    <span className='text-xxs text-grey-90 group-hover:text-primary-90 font-medium'>
                      Go back
                    </span>
                  </>
                )}
              </IconButton>
            )}
            {pageTitle && <h1 className='font-medium text-sm'>{pageTitle}</h1>}
          </div>
          <div className='flex justify-end'>{customButton && customButton}</div>
        </div>
      )}
      <div className='flex justify-between gap-4'>
        <div>
          {Object.entries(data).map(([key, value], index) => (
            <section
              className='grid gap-2 border-b border-grey-70 pb-6 last-of-type:border-none'
              key={index}
            >
              {key !== '_' && <h2 className='font-semibold text-primary-90 text-sm'>{key}</h2>}
              <div className={`grid ${className} gap-4`}>
                {value?.map((val, index) => {
                  return (
                    <div className={`flex flex-col ${val?.className}`} key={index}>
                      <span
                        className='text-xs font-medium capitalize'
                        dangerouslySetInnerHTML={{ __html: val?.name }}
                      ></span>
                      <span className='text-xxs break-words '>
                        {
                          val?.type === 'link' ? (
                            <Link to={'/'}>{val?.value}</Link>
                          ) : val?.name === 'image' ? (
                            <img className='h-14 mt-2' src={val?.value} alt={val?.value} />
                          ) : typeof val?.value === 'boolean' ? (
                            val?.value === true ? (
                              'Yes'
                            ) : (
                              'No'
                            )
                          ) : typeof val?.value === 'string' ? (
                            val?.value || '\u2014'
                          ) : (
                            '\u2014'
                          ) /* entity representing long dash */
                        }
                      </span>
                    </div>
                  );
                })}
              </div>
            </section>
          ))}
          {bottomSection}
        </div>
        <section>
          <LeadDiscussion />
        </section>
      </div>
    </main>
  );
};

export default ViewData;
