/** external deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
/** internal deps */
import { Button } from 'components';
import { SecureComponent } from 'permission-provider';
import { RightArrowHead } from 'assets/icons';
import './style.css';

const Permissions = ({ getPermissions, handleSubmit, loading, data }) => {
  const navigate = useNavigate();
  const [dataToUpdate, setDataToUpdate] = useState([]); // will be used to send patch request to backend if any data is updated.

  /** funcs */
  const handleCheckboxChange = (event) => {
    const el = event.target;
    const checked = el.checked;
    const id = el.dataset.id;
    const type = el.dataset.type;
    const isParent = el.dataset.parent;
    const detailsEl = el.closest('details');
    const grandParentDetailsEl = detailsEl.closest('details[data-detail-main]');
    const dataToSend = [];

    if (isParent) {
      const grandParentEl = grandParentDetailsEl.querySelector(
        `[data-grandparent][data-parent][data-type="${type}"]`,
      );
      const allChildEls = grandParentDetailsEl.querySelectorAll(`[data-type="${type}"]`);

      detailsEl.querySelectorAll(`[data-type="${type}"]`).forEach((checkbox) => {
        // checking/unchecking all children elements of parent
        checkbox.checked = checked;
        // checking/unchecking grandparent element of parent
        if ([...allChildEls].some((el) => !el.dataset.parent && el.checked)) {
          if (grandParentEl) grandParentEl.checked = true;
        } else {
          if (grandParentEl) grandParentEl.checked = false;
        }

        dataToSend.push(
          { id: checkbox.dataset.id, [type]: checkbox.checked },
          { id: grandParentEl.dataset.id, [type]: grandParentEl.checked },
        );
      });
    } else {
      const grandParentEl = grandParentDetailsEl.querySelector(
        `[data-grandparent][data-parent][data-type="${type}"]`,
      );
      const parentEl = detailsEl.querySelector(`[data-parent][data-type="${type}"]`);
      const allEls = detailsEl.querySelectorAll(`[data-type="${type}"]`);

      if ([...allEls].some((el) => !el.dataset.parent && el.checked)) {
        parentEl.checked = true;
        if (grandParentEl) grandParentEl.checked = true;
      } else {
        parentEl.checked = false;
        if (grandParentEl) grandParentEl.checked = false;
      }

      dataToSend.push(
        { id: id, [type]: checked },
        { id: parentEl.dataset.id, [type]: parentEl.checked },
        { id: grandParentEl.dataset.id, [type]: grandParentEl.checked },
      );
    }

    setDataToUpdate((prev) => {
      const data = [];
      [...prev, ...dataToSend].forEach((item) => {
        const itemIndexInData = data.findIndex((dataItem) => dataItem.id === item.id);

        if (itemIndexInData !== -1) data[itemIndexInData] = { ...data[itemIndexInData], ...item };
        else data.push(item);
      });

      return data;
    });
  };

  const onSubmit = async () => {
    handleSubmit(dataToUpdate);
    navigate(-1);
  };
  const onCancel = () => navigate(-1);

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='bg-white flex flex-col p-4 rounded-lg' id='permission'>
      <h1 className='text-l 2xl:text-xxl'>Permissions</h1>
      <p className='text-xxs text-grey-80 '>
        If you would like to grant permissions, please check the boxes below.
      </p>
      <section className='space-y-3 mt-4'>
        <h2 className='grid grid-cols-[1fr,repeat(3,minmax(max-content,.1fr))] gap-4 px-4 py-3 bg-grey-light font-medium'>
          <span>Module</span>
          <span aria-hidden className='text-center'>
            Read
          </span>
          <span aria-hidden className='text-center'>
            Write
          </span>
        </h2>
        {data.map((section) => (
          <details key={section.key} className='group space-y-2' data-detail-main>
            <summary className='grid grid-cols-[1fr,repeat(3,minmax(max-content,.1fr))] gap-4 px-4 cursor-pointer'>
              <span className='capitalize group-open:text-primary-90'>{section.name}</span>
              <label className='checkbox-container'>
                <span className='sr-only'>Read</span>
                <input
                  type='checkbox'
                  name={section.name}
                  defaultChecked={section.read}
                  onChange={handleCheckboxChange}
                  data-grandparent
                  data-parent
                  data-id={section.id}
                  data-type='read'
                />
                <span className='checked-box w-6 h-6 '></span>
              </label>
              <label className='checkbox-container'>
                <span className='sr-only'>Write</span>
                <input
                  type='checkbox'
                  defaultChecked={section.write}
                  onChange={handleCheckboxChange}
                  data-grandparent
                  data-parent
                  data-id={section.id}
                  data-type='write'
                  name={section.name}
                />
                <span className='checked-box w-6 h-6 '></span>
              </label>
              <div aria-hidden className='grid place-content-center'>
                <RightArrowHead svgClass='group-open:fill-primary-90 group-open:rotate-90' />
              </div>
            </summary>
            <div className='space-y-2 border border-grey-70 rounded py-1.5'>
              {section?.children?.map((child) => {
                if (child?.children?.length > 0) {
                  return (
                    <details key={child.key} className='group/child bg-grey-light py-1 space-y-2'>
                      <summary className='grid grid-cols-[1fr,repeat(3,minmax(max-content,.1fr))] gap-4 pl-6 pr-4 cursor-pointer'>
                        <span className='capitalize font-medium'>{child.name}</span>
                        <label className='checkbox-container'>
                          <span className='sr-only'>Read</span>
                          <input
                            type='checkbox'
                            name={child.name}
                            defaultChecked={child.read}
                            onChange={handleCheckboxChange}
                            data-parent
                            data-id={child.id}
                            data-type='read'
                          />
                          <span className='checked-box w-6 h-6 '></span>
                        </label>
                        <label className='checkbox-container'>
                          <span className='sr-only'>Write</span>
                          <input
                            type='checkbox'
                            defaultChecked={child.write}
                            onChange={handleCheckboxChange}
                            data-parent
                            data-id={child.id}
                            data-type='write'
                            name={child.name}
                          />
                          <span className='checked-box w-6 h-6 '></span>
                        </label>
                        <div aria-hidden className='grid place-content-center'>
                          <RightArrowHead svgClass='group-open/child:fill-primary-90 group-open/child:rotate-90' />
                        </div>
                      </summary>
                      {/* sub child */}
                      {child.children?.map((child) => (
                        <p
                          key={child.key}
                          className='grid grid-cols-[1fr,repeat(3,minmax(max-content,.1fr))] gap-4 px-4'
                        >
                          <span className='capitalize ml-2 text-xxs text-grey-90'>
                            {child?.name}
                          </span>
                          <label className='checkbox-container'>
                            <span className='sr-only'>Read</span>
                            <input
                              type='checkbox'
                              name={child.name}
                              defaultChecked={child.read}
                              onChange={handleCheckboxChange}
                              data-type='read'
                              data-id={child.id}
                            />
                            <span className='checked-box w-5 h-5 '></span>
                          </label>
                          <label className='checkbox-container'>
                            <span className='sr-only'>Write</span>
                            <input
                              type='checkbox'
                              name={child.name}
                              defaultChecked={child.write}
                              onChange={handleCheckboxChange}
                              data-type='write'
                              data-id={child.id}
                            />
                            <span className='checked-box w-5 h-5 '></span>
                          </label>
                        </p>
                      ))}
                      {/*  */}
                    </details>
                  );
                }
                return (
                  <p
                    key={child.key}
                    className='grid grid-cols-[1fr,repeat(3,minmax(max-content,.1fr))] gap-4 px-4'
                  >
                    <span className='capitalize ml-2 text-xxs text-grey-90'>{child?.name}</span>
                    <label className='checkbox-container'>
                      <span className='sr-only'>Read</span>
                      <input
                        type='checkbox'
                        name={child.name}
                        defaultChecked={child.read}
                        onChange={handleCheckboxChange}
                        data-type='read'
                        data-id={child.id}
                      />
                      <span className='checked-box w-5 h-5 '></span>
                    </label>
                    <label className='checkbox-container'>
                      <span className='sr-only'>Write</span>
                      <input
                        type='checkbox'
                        name={child.name}
                        defaultChecked={child.write}
                        onChange={handleCheckboxChange}
                        data-type='write'
                        data-id={child.id}
                      />
                      <span className='checked-box w-5 h-5 '></span>
                    </label>
                  </p>
                );
              })}
            </div>
          </details>
        ))}
      </section>
      <div className='bg-white flex flex-row justify-end py-4 mt-6'>
        <Button
          text='Cancel'
          variant='text'
          type='reset'
          classes='border border-gray-90 text-black px-6'
          onClick={onCancel}
        />
        <Button
          text='Save'
          variant='primary'
          classes={`ml-6 px-8 ${dataToUpdate.length ? 'bg-black' : 'bg-grey'}`}
          onClick={onSubmit}
          loading={loading}
          disabled={!dataToUpdate.length}
        />
      </div>
    </div>
  );
};

export default SecureComponent(Permissions);
