/** external deps */
import React, { useCallback } from 'react';
/** internal deps */
import { IconButton, MultiEmail } from 'components';
import { BackArrow } from 'assets/icons';
import { useStore } from 'store/context';

const ViewData = ({
  pageTitle,
  data,
  className = 'grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5',
  classes,
  goBack,
  showGoToBack = false,
  customButton,
  titleClasses,
  valueClasses,
  extraRender,
}) => {
  const [opened, dispatch] = useStore((state) => state.dialerOpened);

  const toggleDialer = useCallback(
    (e, contact) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (opened) {
        dispatch({ type: 'DIALER_CLOSE' });
      } else {
        dispatch({ type: 'DIALER_OPEN', contact });
      }
    },
    [opened, dispatch],
  );

  return (
    <main className={`grid gap-2 w-full ${classes}`}>
      {(pageTitle || showGoToBack) && (
        <div className={`flex justify-between items-center relative ${pageTitle ? 'h-10' : 'h-4'}`}>
          {pageTitle && <h1 className='font-medium text-sm'>{pageTitle}</h1>}
          {goBack && (
            <IconButton
              onClick={() => goBack()}
              className='group absolute right-2 top-1 flex items-center'
            >
              {showGoToBack && (
                <>
                  <BackArrow svgClass='fill-grey-90 w-8 h-8 group-hover:fill-primary-90 transition-all  duration-500 group-hover:-translate-x-1' />
                  <span className='text-xxs text-grey-90 group-hover:text-primary-90 font-medium'>
                    {' '}
                    Go back
                  </span>
                </>
              )}
            </IconButton>
          )}
        </div>
      )}
      <div className='flex justify-end'>{customButton && customButton}</div>

      {Object.entries(data).map(([key, value], index) => (
        <section className='grid gap-2' key={index}>
          {key !== '_' && <h2 className='font-medium text-primary-90 text-xs'>{key}</h2>}
          <div className={`grid ${className} gap-4`}>
            {value?.map((val, index) => {
              return (
                <div className={`flex flex-col ${val?.className}`} key={index}>
                  {val?.name && (
                    <span className={`text-xs font-medium capitalize ${titleClasses}`}>
                      {val?.name?.replaceAll('_', ' ')}
                    </span>
                  )}
                  {val?.phone ? (
                    <span
                      className='cursor-pointer underline'
                      onClick={(e) => toggleDialer(e, val?.value)}
                    >
                      {val?.value || '\u2014'}
                    </span>
                  ) : val?.email ? (
                    <MultiEmail
                      text={val?.value}
                      button={false}
                      titleClasses={'underline cursor-pointer'}
                      emailData={val?.value}
                    />
                  ) : (
                    <span className={`text-xxs break-words capitalize ${valueClasses}`}>
                      {val?.name === 'image' ? (
                        <img
                          className='h-14 mt-2'
                          src={process.env.REACT_APP_DOC_URL + val?.value}
                          alt={val?.value}
                        />
                      ) : typeof val?.value === 'boolean' ? (
                        val?.value === true ? (
                          'Yes'
                        ) : (
                          'No'
                        )
                      ) : typeof val?.value === 'string' ? (
                        (val?.value).replaceAll('_', ' ') || '\u2014'
                      ) : typeof val?.value === 'number' ? (
                        val?.value || '\u2014'
                      ) : typeof val?.value === 'object' && val?.value?.length ? (
                        val?.value
                          ?.filter(Boolean)
                          .map((item) => item.name)
                          .join(', ') || '\u2014'
                      ) : typeof val?.value === 'object' ? (
                        val?.value?.name || val?.value?.corp_name || '\u2014'
                      ) : (
                        'No'
                      )}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </section>
      ))}
      {extraRender && extraRender}
    </main>
  );
};

export default ViewData;
