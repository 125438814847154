import React from 'react';

const View = ({ data, exclude = [], customRenders }) => {
  return (
    <>
      {Object.entries(data).map(([key, value], index) => {
        if (!exclude.includes(key)) {
          if (customRenders?.[key]) {
            return typeof customRenders?.[key] === 'function'
              ? customRenders?.[key](key, value, index)
              : customRenders?.[key];
          } else {
            return (
              <div key={index}>
                <span className='capitalize'>{key?.replaceAll('_', ' ')}</span>
                <span className={`block font-medium`}>
                  {' '}
                  {Array.isArray(value)
                    ? value?.map((item) => item?.name).join(',') || '\u2014'
                    : typeof value === 'object'
                    ? value?.name || '\u2014'
                    : value || '\u2014'}{' '}
                </span>
              </div>
            );
          }
        }
        return null;
      })}
    </>
  );
};

export default View;
