// import dayjs from "dayjs";

import {
  Checkbox,
  CurrencyInput,
  DateTime,
  PhoneNumberInput,
  SearchAPI,
  SelectInput,
  TextInput,
} from 'components';
import { getBranches } from 'service/api-service';

// const day = dayjs().subtract(18, 'years');

export const initialValues = {
  name: '',
  primary_email_address: '',
  contact_number: '',
  date_of_birth: '',
  other_phone: '',
  id_card_number: '',
  passport_number: '',
  passport_issuing_date: '',
  id_card_type: '',
  passport_issuing_country: '',
  nationality: '',
  contact_type: '',
  lead_source: '',
  source: '',
  assign_to_employee: '',
  credit_rating: '',
  credit_limit: '',
  credit_term: '',
  description: '',
  billing_address_1: '',
  billing_address_2: '',
  billing_address_3: '',
  billing_postcode: '',
  billing_country: '',
  company: '',
  facebook: '',
  profile_picture: '',
  instagram: '',
  'mas_&_unsaction_date': '',
};

export const ContactField = (leadSourceOptions) => ({
  'Insured Details': {
    showAccordionHeader: true,
    fields: [
      {
        id: 6,
        options: [],
        name: 'customer_name',
        label: 'Client Name',
        type: 'text',
        required: true,
        component: TextInput,
        org_id: 1,
        placeholder: 'Customer Name',
        dependent: true,
      },
      {
        id: 7,
        options: [],
        name: 'contact_number',
        label: 'Contact Number',
        required: true,
        type: 'number',
        maxLength: 10,
        component: TextInput,
        org_id: 1,
        placeholder: 'Contact Number',
      },
      {
        id: 8,
        options: [],
        name: 'email_address',
        label: 'Email Address',
        type: 'email',
        required: true,
        component: TextInput,
        org_id: 1,
        placeholder: 'Email Address',
      },
      {
        id: 11,
        options: [],
        name: 'assign_to_employee',
        label: 'Assign To Employee',
        type: 'search',
        required: false,
        org_id: 1,
        byBranch: true,
        component: SearchAPI,
        placeholder: 'Assign To Employee',
      },
      {
        id: 12,
        options: [],
        name: 'branch_of',
        label: 'Branch Of',
        type: 'select',
        required: false,
        org_id: 1,
        placeholder: 'Branch Of',
        byUser: true,
        component: SelectInput,
        fetchOptions: async () => await getBranches(),
      },
      {
        id: 13,
        options: [],
        name: 'date_of_birth',
        label: 'Date Of Birth',
        type: 'date',
        required: false,
        org_id: 1,
        placeholder: 'YYYY-MM-DD',
        component: DateTime,
        // disabledDate : dayjs(day).format("YYYY-MM-DD")
        // disabledDate: dayjs(day).format('YYYY-MM-DD'),
      },
      {
        id: 553,
        options: leadSourceOptions,
        name: 'lead_source',
        label: 'Lead Source',
        type: 'select',
        required: false,
        org_id: 1,
        placeholder: 'Lead Source',
        component: SelectInput,
      },
      {
        id: 554,
        options: [],
        name: 'nature_of_business',
        label: 'Nature Of Business',
        type: 'textarea',
        required: false,
        org_id: 1,
        placeholder: 'Nature Of Business',
        isMultiline: true,
        divClasses: 'col-span-full',
        rows: 2,
        component: TextInput,
      },
    ],
  },
  'Billing Details': {
    fields: [
      {
        id: 30,
        options: [],
        name: 'address',
        label: 'Address',
        type: 'text',
        required: false,
        org_id: 1,
        placeholder: 'Address',
        component: TextInput,
      },
      {
        id: 31,
        options: [],
        name: 'state',
        label: 'State',
        type: 'text',
        required: false,
        org_id: 1,
        placeholder: 'State',
        component: TextInput,
      },
      {
        id: 32,
        options: [],
        name: 'city',
        label: 'City',
        type: 'text',
        required: false,
        org_id: 1,
        placeholder: 'City',
        component: TextInput,
      },
      {
        id: 33,
        options: [],
        name: 'postcode',
        label: 'Postal Code',
        type: 'text',
        required: false,
        org_id: 1,
        placeholder: 'Postcode',
        component: TextInput,
      },
      {
        id: 34,
        options: [
          {
            value: 'AF',
            label: 'Afghanistan',
          },
          {
            value: 'AX',
            label: 'Åland',
          },
          {
            value: 'AL',
            label: 'Albania',
          },
          {
            value: 'DZ',
            label: 'Algeria',
          },
          {
            value: 'AS',
            label: 'American Samoa',
          },
          {
            value: 'AD',
            label: 'Andorra',
          },
          {
            value: 'AO',
            label: 'Angola',
          },
          {
            value: 'AI',
            label: 'Anguilla',
          },
          {
            value: 'AQ',
            label: 'Antarctica',
          },
          {
            value: 'AG',
            label: 'Antigua and Barbuda',
          },
          {
            value: 'AR',
            label: 'Argentina',
          },
          {
            value: 'AM',
            label: 'Armenia',
          },
          {
            value: 'AW',
            label: 'Aruba',
          },
          {
            value: 'AU',
            label: 'Australia',
          },
          {
            value: 'AT',
            label: 'Austria',
          },
          {
            value: 'AZ',
            label: 'Azerbaijan',
          },
          {
            value: 'BS',
            label: 'Bahamas',
          },
          {
            value: 'BH',
            label: 'Bahrain',
          },
          {
            value: 'BD',
            label: 'Bangladesh',
          },
          {
            value: 'BB',
            label: 'Barbados',
          },
          {
            value: 'BY',
            label: 'Belarus',
          },
          {
            value: 'BE',
            label: 'Belgium',
          },
          {
            value: 'BZ',
            label: 'Belize',
          },
          {
            value: 'BJ',
            label: 'Benin',
          },
          {
            value: 'BM',
            label: 'Bermuda',
          },
          {
            value: 'BT',
            label: 'Bhutan',
          },
          {
            value: 'BO',
            label: 'Bolivia',
          },
          {
            value: 'BQ',
            label: 'Bonaire',
          },
          {
            value: 'BA',
            label: 'Bosnia and Herzegovina',
          },
          {
            value: 'BW',
            label: 'Botswana',
          },
          {
            value: 'BV',
            label: 'Bouvet Island',
          },
          {
            value: 'BR',
            label: 'Brazil',
          },
          {
            value: 'IO',
            label: 'British Indian Ocean Territory',
          },
          {
            value: 'VG',
            label: 'British Virgin Islands',
          },
          {
            value: 'BN',
            label: 'Brunei',
          },
          {
            value: 'BG',
            label: 'Bulgaria',
          },
          {
            value: 'BF',
            label: 'Burkina Faso',
          },
          {
            value: 'BI',
            label: 'Burundi',
          },
          {
            value: 'KH',
            label: 'Cambodia',
          },
          {
            value: 'CM',
            label: 'Cameroon',
          },
          {
            value: 'CA',
            label: 'Canada',
          },
          {
            value: 'CV',
            label: 'Cape Verde',
          },
          {
            value: 'KY',
            label: 'Cayman Islands',
          },
          {
            value: 'CF',
            label: 'Central African Republic',
          },
          {
            value: 'TD',
            label: 'Chad',
          },
          {
            value: 'CL',
            label: 'Chile',
          },
          {
            value: 'CN',
            label: 'China',
          },
          {
            value: 'CX',
            label: 'Christmas Island',
          },
          {
            value: 'CC',
            label: 'Cocos [Keeling] Islands',
          },
          {
            value: 'CO',
            label: 'Colombia',
          },
          {
            value: 'KM',
            label: 'Comoros',
          },
          {
            value: 'CK',
            label: 'Cook Islands',
          },
          {
            value: 'CR',
            label: 'Costa Rica',
          },
          {
            value: 'HR',
            label: 'Croatia',
          },
          {
            value: 'CU',
            label: 'Cuba',
          },
          {
            value: 'CW',
            label: 'Curacao',
          },
          {
            value: 'CY',
            label: 'Cyprus',
          },
          {
            value: 'CZ',
            label: 'Czech Republic',
          },
          {
            value: 'CD',
            label: 'Democratic Republic of the Congo',
          },
          {
            value: 'DK',
            label: 'Denmark',
          },
          {
            value: 'DJ',
            label: 'Djibouti',
          },
          {
            value: 'DM',
            label: 'Dominica',
          },
          {
            value: 'DO',
            label: 'Dominican Republic',
          },
          {
            value: 'TL',
            label: 'East Timor',
          },
          {
            value: 'EC',
            label: 'Ecuador',
          },
          {
            value: 'EG',
            label: 'Egypt',
          },
          {
            value: 'SV',
            label: 'El Salvador',
          },
          {
            value: 'GQ',
            label: 'Equatorial Guinea',
          },
          {
            value: 'ER',
            label: 'Eritrea',
          },
          {
            value: 'EE',
            label: 'Estonia',
          },
          {
            value: 'ET',
            label: 'Ethiopia',
          },
          {
            value: 'FK',
            label: 'Falkland Islands',
          },
          {
            value: 'FO',
            label: 'Faroe Islands',
          },
          {
            value: 'FJ',
            label: 'Fiji',
          },
          {
            value: 'FI',
            label: 'Finland',
          },
          {
            value: 'FR',
            label: 'France',
          },
          {
            value: 'GF',
            label: 'French Guiana',
          },
          {
            value: 'PF',
            label: 'French Polynesia',
          },
          {
            value: 'TF',
            label: 'French Southern Territories',
          },
          {
            value: 'GA',
            label: 'Gabon',
          },
          {
            value: 'GM',
            label: 'Gambia',
          },
          {
            value: 'GE',
            label: 'Georgia',
          },
          {
            value: 'DE',
            label: 'Germany',
          },
          {
            value: 'GH',
            label: 'Ghana',
          },
          {
            value: 'GI',
            label: 'Gibraltar',
          },
          {
            value: 'GR',
            label: 'Greece',
          },
          {
            value: 'GL',
            label: 'Greenland',
          },
          {
            value: 'GD',
            label: 'Grenada',
          },
          {
            value: 'GP',
            label: 'Guadeloupe',
          },
          {
            value: 'GU',
            label: 'Guam',
          },
          {
            value: 'GT',
            label: 'Guatemala',
          },
          {
            value: 'GG',
            label: 'Guernsey',
          },
          {
            value: 'GN',
            label: 'Guinea',
          },
          {
            value: 'GW',
            label: 'Guinea-Bissau',
          },
          {
            value: 'GY',
            label: 'Guyana',
          },
          {
            value: 'HT',
            label: 'Haiti',
          },
          {
            value: 'HM',
            label: 'Heard Island and McDonald Islands',
          },
          {
            value: 'HN',
            label: 'Honduras',
          },
          {
            value: 'HK',
            label: 'Hong Kong',
          },
          {
            value: 'HU',
            label: 'Hungary',
          },
          {
            value: 'IS',
            label: 'Iceland',
          },
          {
            value: 'IN',
            label: 'India',
          },
          {
            value: 'ID',
            label: 'Indonesia',
          },
          {
            value: 'IR',
            label: 'Iran',
          },
          {
            value: 'IQ',
            label: 'Iraq',
          },
          {
            value: 'IE',
            label: 'Ireland',
          },
          {
            value: 'IM',
            label: 'Isle of Man',
          },
          {
            value: 'IL',
            label: 'Israel',
          },
          {
            value: 'IT',
            label: 'Italy',
          },
          {
            value: 'CI',
            label: 'Ivory Coast',
          },
          {
            value: 'JM',
            label: 'Jamaica',
          },
          {
            value: 'JP',
            label: 'Japan',
          },
          {
            value: 'JE',
            label: 'Jersey',
          },
          {
            value: 'JO',
            label: 'Jordan',
          },
          {
            value: 'KZ',
            label: 'Kazakhstan',
          },
          {
            value: 'KE',
            label: 'Kenya',
          },
          {
            value: 'KI',
            label: 'Kiribati',
          },
          {
            value: 'XK',
            label: 'Kosovo',
          },
          {
            value: 'KW',
            label: 'Kuwait',
          },
          {
            value: 'KG',
            label: 'Kyrgyzstan',
          },
          {
            value: 'LA',
            label: 'Laos',
          },
          {
            value: 'LV',
            label: 'Latvia',
          },
          {
            value: 'LB',
            label: 'Lebanon',
          },
          {
            value: 'LS',
            label: 'Lesotho',
          },
          {
            value: 'LR',
            label: 'Liberia',
          },
          {
            value: 'LY',
            label: 'Libya',
          },
          {
            value: 'LI',
            label: 'Liechtenstein',
          },
          {
            value: 'LT',
            label: 'Lithuania',
          },
          {
            value: 'LU',
            label: 'Luxembourg',
          },
          {
            value: 'MO',
            label: 'Macao',
          },
          {
            value: 'MK',
            label: 'Macedonia',
          },
          {
            value: 'MG',
            label: 'Madagascar',
          },
          {
            value: 'MW',
            label: 'Malawi',
          },
          {
            value: 'MY',
            label: 'Malaysia',
          },
          {
            value: 'MV',
            label: 'Maldives',
          },
          {
            value: 'ML',
            label: 'Mali',
          },
          {
            value: 'MT',
            label: 'Malta',
          },
          {
            value: 'MH',
            label: 'Marshall Islands',
          },
          {
            value: 'MQ',
            label: 'Martinique',
          },
          {
            value: 'MR',
            label: 'Mauritania',
          },
          {
            value: 'MU',
            label: 'Mauritius',
          },
          {
            value: 'YT',
            label: 'Mayotte',
          },
          {
            value: 'MX',
            label: 'Mexico',
          },
          {
            value: 'FM',
            label: 'Micronesia',
          },
          {
            value: 'MD',
            label: 'Moldova',
          },
          {
            value: 'MC',
            label: 'Monaco',
          },
          {
            value: 'MN',
            label: 'Mongolia',
          },
          {
            value: 'ME',
            label: 'Montenegro',
          },
          {
            value: 'MS',
            label: 'Montserrat',
          },
          {
            value: 'MA',
            label: 'Morocco',
          },
          {
            value: 'MZ',
            label: 'Mozambique',
          },
          {
            value: 'MM',
            label: 'Myanmar [Burma]',
          },
          {
            value: 'NA',
            label: 'Namibia',
          },
          {
            value: 'NR',
            label: 'Nauru',
          },
          {
            value: 'NP',
            label: 'Nepal',
          },
          {
            value: 'NL',
            label: 'Netherlands',
          },
          {
            value: 'NC',
            label: 'New Caledonia',
          },
          {
            value: 'NZ',
            label: 'New Zealand',
          },
          {
            value: 'NI',
            label: 'Nicaragua',
          },
          {
            value: 'NE',
            label: 'Niger',
          },
          {
            value: 'NG',
            label: 'Nigeria',
          },
          {
            value: 'NU',
            label: 'Niue',
          },
          {
            value: 'NF',
            label: 'Norfolk Island',
          },
          {
            value: 'KP',
            label: 'North Korea',
          },
          {
            value: 'MP',
            label: 'Northern Mariana Islands',
          },
          {
            value: 'NO',
            label: 'Norway',
          },
          {
            value: 'OM',
            label: 'Oman',
          },
          {
            value: 'PK',
            label: 'Pakistan',
          },
          {
            value: 'PW',
            label: 'Palau',
          },
          {
            value: 'PS',
            label: 'Palestine',
          },
          {
            value: 'PA',
            label: 'Panama',
          },
          {
            value: 'PG',
            label: 'Papua New Guinea',
          },
          {
            value: 'PY',
            label: 'Paraguay',
          },
          {
            value: 'PE',
            label: 'Peru',
          },
          {
            value: 'PH',
            label: 'Philippines',
          },
          {
            value: 'PN',
            label: 'Pitcairn Islands',
          },
          {
            value: 'PL',
            label: 'Poland',
          },
          {
            value: 'PT',
            label: 'Portugal',
          },
          {
            value: 'PR',
            label: 'Puerto Rico',
          },
          {
            value: 'QA',
            label: 'Qatar',
          },
          {
            value: 'CG',
            label: 'Republic of the Congo',
          },
          {
            value: 'RE',
            label: 'Réunion',
          },
          {
            value: 'RO',
            label: 'Romania',
          },
          {
            value: 'RU',
            label: 'Russia',
          },
          {
            value: 'RW',
            label: 'Rwanda',
          },
          {
            value: 'BL',
            label: 'Saint Barthélemy',
          },
          {
            value: 'SH',
            label: 'Saint Helena',
          },
          {
            value: 'KN',
            label: 'Saint Kitts and Nevis',
          },
          {
            value: 'LC',
            label: 'Saint Lucia',
          },
          {
            value: 'MF',
            label: 'Saint Martin',
          },
          {
            value: 'PM',
            label: 'Saint Pierre and Miquelon',
          },
          {
            value: 'VC',
            label: 'Saint Vincent and the Grenadines',
          },
          {
            value: 'WS',
            label: 'Samoa',
          },
          {
            value: 'SM',
            label: 'San Marino',
          },
          {
            value: 'ST',
            label: 'São Tomé and Príncipe',
          },
          {
            value: 'SA',
            label: 'Saudi Arabia',
          },
          {
            value: 'SN',
            label: 'Senegal',
          },
          {
            value: 'RS',
            label: 'Serbia',
          },
          {
            value: 'SC',
            label: 'Seychelles',
          },
          {
            value: 'SL',
            label: 'Sierra Leone',
          },
          {
            value: 'SG',
            label: 'Singapore',
          },
          {
            value: 'SX',
            label: 'Sint Maarten',
          },
          {
            value: 'SK',
            label: 'Slovakia',
          },
          {
            value: 'SI',
            label: 'Slovenia',
          },
          {
            value: 'SB',
            label: 'Solomon Islands',
          },
          {
            value: 'SO',
            label: 'Somalia',
          },
          {
            value: 'ZA',
            label: 'South Africa',
          },
          {
            value: 'GS',
            label: 'South Georgia and the South Sandwich Islands',
          },
          {
            value: 'KR',
            label: 'South Korea',
          },
          {
            value: 'SS',
            label: 'South Sudan',
          },
          {
            value: 'ES',
            label: 'Spain',
          },
          {
            value: 'LK',
            label: 'Sri Lanka',
          },
          {
            value: 'SD',
            label: 'Sudan',
          },
          {
            value: 'SR',
            label: 'Suriname',
          },
          {
            value: 'SJ',
            label: 'Svalbard and Jan Mayen',
          },
          {
            value: 'SZ',
            label: 'Swaziland',
          },
          {
            value: 'SE',
            label: 'Sweden',
          },
          {
            value: 'CH',
            label: 'Switzerland',
          },
          {
            value: 'SY',
            label: 'Syria',
          },
          {
            value: 'TW',
            label: 'Taiwan',
          },
          {
            value: 'TJ',
            label: 'Tajikistan',
          },
          {
            value: 'TZ',
            label: 'Tanzania',
          },
          {
            value: 'TH',
            label: 'Thailand',
          },
          {
            value: 'TG',
            label: 'Togo',
          },
          {
            value: 'TK',
            label: 'Tokelau',
          },
          {
            value: 'TO',
            label: 'Tonga',
          },
          {
            value: 'TT',
            label: 'Trinidad and Tobago',
          },
          {
            value: 'TN',
            label: 'Tunisia',
          },
          {
            value: 'TR',
            label: 'Turkey',
          },
          {
            value: 'TM',
            label: 'Turkmenistan',
          },
          {
            value: 'TC',
            label: 'Turks and Caicos Islands',
          },
          {
            value: 'TV',
            label: 'Tuvalu',
          },
          {
            value: 'UM',
            label: 'U.S. Minor Outlying Islands',
          },
          {
            value: 'VI',
            label: 'U.S. Virgin Islands',
          },
          {
            value: 'UG',
            label: 'Uganda',
          },
          {
            value: 'UA',
            label: 'Ukraine',
          },
          {
            value: 'AE',
            label: 'United Arab Emirates',
          },
          {
            value: 'GB',
            label: 'United Kingdom',
          },
          {
            value: 'US',
            label: 'United States',
          },
          {
            value: 'UY',
            label: 'Uruguay',
          },
          {
            value: 'UZ',
            label: 'Uzbekistan',
          },
          {
            value: 'VU',
            label: 'Vanuatu',
          },
          {
            value: 'VA',
            label: 'Vatican City',
          },
          {
            value: 'VE',
            label: 'Venezuela',
          },
          {
            value: 'VN',
            label: 'Vietnam',
          },
          {
            value: 'WF',
            label: 'Wallis and Futuna',
          },
          {
            value: 'EH',
            label: 'Western Sahara',
          },
          {
            value: 'YE',
            label: 'Yemen',
          },
          {
            value: 'ZM',
            label: 'Zambia',
          },
          {
            value: 'ZW',
            label: 'Zimbabwe',
          },
        ],
        label: 'Country',
        type: 'select',
        required: false,
        org_id: 1,
        placeholder: 'Country',
        // defaultValue: 'IN',
        name: 'country',
        component: SelectInput,
      },
      {
        id: 35,
        options: [],
        name: 'checkaddress',
        label: 'As with the Billing Address, the Registered address will also be the same.',
        type: 'checkbox',
        required: false,
        org_id: 1,
        placeholder:
          'Enter As with the Billing Address, the Registered address will also be the same.',
        checked: false,
        divClasses: 'col-span-3 self-end',
        component: Checkbox,
      },
    ],
  },
  'Registered Details': {
    dependent: true,
    dependent_on: 'checkaddress',
    dependent_value: false,
    fields: [
      {
        id: 42,
        options: [],
        name: 'r_address',
        label: 'Address',
        type: 'text',
        required: false,
        org_id: 1,
        placeholder: 'Address',
        component: TextInput,
      },
      {
        id: 43,
        options: [],
        name: 'r_state',
        label: 'State',
        type: 'text',
        required: false,
        org_id: 1,
        placeholder: 'State',
        component: TextInput,
      },
      {
        id: 44,
        options: [],
        name: 'r_city',
        label: 'City',
        type: 'text',
        required: false,
        org_id: 1,
        placeholder: 'City',
        component: TextInput,
      },
      {
        id: 45,
        options: [],
        name: 'r_postcode',
        label: 'Postal Code',
        type: 'text',
        required: false,
        org_id: 1,
        placeholder: 'Postcode',
        component: TextInput,
      },
      {
        id: 46,
        options: [
          {
            value: 'AF',
            label: 'Afghanistan',
          },
          {
            value: 'AX',
            label: 'Åland',
          },
          {
            value: 'AL',
            label: 'Albania',
          },
          {
            value: 'DZ',
            label: 'Algeria',
          },
          {
            value: 'AS',
            label: 'American Samoa',
          },
          {
            value: 'AD',
            label: 'Andorra',
          },
          {
            value: 'AO',
            label: 'Angola',
          },
          {
            value: 'AI',
            label: 'Anguilla',
          },
          {
            value: 'AQ',
            label: 'Antarctica',
          },
          {
            value: 'AG',
            label: 'Antigua and Barbuda',
          },
          {
            value: 'AR',
            label: 'Argentina',
          },
          {
            value: 'AM',
            label: 'Armenia',
          },
          {
            value: 'AW',
            label: 'Aruba',
          },
          {
            value: 'AU',
            label: 'Australia',
          },
          {
            value: 'AT',
            label: 'Austria',
          },
          {
            value: 'AZ',
            label: 'Azerbaijan',
          },
          {
            value: 'BS',
            label: 'Bahamas',
          },
          {
            value: 'BH',
            label: 'Bahrain',
          },
          {
            value: 'BD',
            label: 'Bangladesh',
          },
          {
            value: 'BB',
            label: 'Barbados',
          },
          {
            value: 'BY',
            label: 'Belarus',
          },
          {
            value: 'BE',
            label: 'Belgium',
          },
          {
            value: 'BZ',
            label: 'Belize',
          },
          {
            value: 'BJ',
            label: 'Benin',
          },
          {
            value: 'BM',
            label: 'Bermuda',
          },
          {
            value: 'BT',
            label: 'Bhutan',
          },
          {
            value: 'BO',
            label: 'Bolivia',
          },
          {
            value: 'BQ',
            label: 'Bonaire',
          },
          {
            value: 'BA',
            label: 'Bosnia and Herzegovina',
          },
          {
            value: 'BW',
            label: 'Botswana',
          },
          {
            value: 'BV',
            label: 'Bouvet Island',
          },
          {
            value: 'BR',
            label: 'Brazil',
          },
          {
            value: 'IO',
            label: 'British Indian Ocean Territory',
          },
          {
            value: 'VG',
            label: 'British Virgin Islands',
          },
          {
            value: 'BN',
            label: 'Brunei',
          },
          {
            value: 'BG',
            label: 'Bulgaria',
          },
          {
            value: 'BF',
            label: 'Burkina Faso',
          },
          {
            value: 'BI',
            label: 'Burundi',
          },
          {
            value: 'KH',
            label: 'Cambodia',
          },
          {
            value: 'CM',
            label: 'Cameroon',
          },
          {
            value: 'CA',
            label: 'Canada',
          },
          {
            value: 'CV',
            label: 'Cape Verde',
          },
          {
            value: 'KY',
            label: 'Cayman Islands',
          },
          {
            value: 'CF',
            label: 'Central African Republic',
          },
          {
            value: 'TD',
            label: 'Chad',
          },
          {
            value: 'CL',
            label: 'Chile',
          },
          {
            value: 'CN',
            label: 'China',
          },
          {
            value: 'CX',
            label: 'Christmas Island',
          },
          {
            value: 'CC',
            label: 'Cocos [Keeling] Islands',
          },
          {
            value: 'CO',
            label: 'Colombia',
          },
          {
            value: 'KM',
            label: 'Comoros',
          },
          {
            value: 'CK',
            label: 'Cook Islands',
          },
          {
            value: 'CR',
            label: 'Costa Rica',
          },
          {
            value: 'HR',
            label: 'Croatia',
          },
          {
            value: 'CU',
            label: 'Cuba',
          },
          {
            value: 'CW',
            label: 'Curacao',
          },
          {
            value: 'CY',
            label: 'Cyprus',
          },
          {
            value: 'CZ',
            label: 'Czech Republic',
          },
          {
            value: 'CD',
            label: 'Democratic Republic of the Congo',
          },
          {
            value: 'DK',
            label: 'Denmark',
          },
          {
            value: 'DJ',
            label: 'Djibouti',
          },
          {
            value: 'DM',
            label: 'Dominica',
          },
          {
            value: 'DO',
            label: 'Dominican Republic',
          },
          {
            value: 'TL',
            label: 'East Timor',
          },
          {
            value: 'EC',
            label: 'Ecuador',
          },
          {
            value: 'EG',
            label: 'Egypt',
          },
          {
            value: 'SV',
            label: 'El Salvador',
          },
          {
            value: 'GQ',
            label: 'Equatorial Guinea',
          },
          {
            value: 'ER',
            label: 'Eritrea',
          },
          {
            value: 'EE',
            label: 'Estonia',
          },
          {
            value: 'ET',
            label: 'Ethiopia',
          },
          {
            value: 'FK',
            label: 'Falkland Islands',
          },
          {
            value: 'FO',
            label: 'Faroe Islands',
          },
          {
            value: 'FJ',
            label: 'Fiji',
          },
          {
            value: 'FI',
            label: 'Finland',
          },
          {
            value: 'FR',
            label: 'France',
          },
          {
            value: 'GF',
            label: 'French Guiana',
          },
          {
            value: 'PF',
            label: 'French Polynesia',
          },
          {
            value: 'TF',
            label: 'French Southern Territories',
          },
          {
            value: 'GA',
            label: 'Gabon',
          },
          {
            value: 'GM',
            label: 'Gambia',
          },
          {
            value: 'GE',
            label: 'Georgia',
          },
          {
            value: 'DE',
            label: 'Germany',
          },
          {
            value: 'GH',
            label: 'Ghana',
          },
          {
            value: 'GI',
            label: 'Gibraltar',
          },
          {
            value: 'GR',
            label: 'Greece',
          },
          {
            value: 'GL',
            label: 'Greenland',
          },
          {
            value: 'GD',
            label: 'Grenada',
          },
          {
            value: 'GP',
            label: 'Guadeloupe',
          },
          {
            value: 'GU',
            label: 'Guam',
          },
          {
            value: 'GT',
            label: 'Guatemala',
          },
          {
            value: 'GG',
            label: 'Guernsey',
          },
          {
            value: 'GN',
            label: 'Guinea',
          },
          {
            value: 'GW',
            label: 'Guinea-Bissau',
          },
          {
            value: 'GY',
            label: 'Guyana',
          },
          {
            value: 'HT',
            label: 'Haiti',
          },
          {
            value: 'HM',
            label: 'Heard Island and McDonald Islands',
          },
          {
            value: 'HN',
            label: 'Honduras',
          },
          {
            value: 'HK',
            label: 'Hong Kong',
          },
          {
            value: 'HU',
            label: 'Hungary',
          },
          {
            value: 'IS',
            label: 'Iceland',
          },
          {
            value: 'IN',
            label: 'India',
          },
          {
            value: 'ID',
            label: 'Indonesia',
          },
          {
            value: 'IR',
            label: 'Iran',
          },
          {
            value: 'IQ',
            label: 'Iraq',
          },
          {
            value: 'IE',
            label: 'Ireland',
          },
          {
            value: 'IM',
            label: 'Isle of Man',
          },
          {
            value: 'IL',
            label: 'Israel',
          },
          {
            value: 'IT',
            label: 'Italy',
          },
          {
            value: 'CI',
            label: 'Ivory Coast',
          },
          {
            value: 'JM',
            label: 'Jamaica',
          },
          {
            value: 'JP',
            label: 'Japan',
          },
          {
            value: 'JE',
            label: 'Jersey',
          },
          {
            value: 'JO',
            label: 'Jordan',
          },
          {
            value: 'KZ',
            label: 'Kazakhstan',
          },
          {
            value: 'KE',
            label: 'Kenya',
          },
          {
            value: 'KI',
            label: 'Kiribati',
          },
          {
            value: 'XK',
            label: 'Kosovo',
          },
          {
            value: 'KW',
            label: 'Kuwait',
          },
          {
            value: 'KG',
            label: 'Kyrgyzstan',
          },
          {
            value: 'LA',
            label: 'Laos',
          },
          {
            value: 'LV',
            label: 'Latvia',
          },
          {
            value: 'LB',
            label: 'Lebanon',
          },
          {
            value: 'LS',
            label: 'Lesotho',
          },
          {
            value: 'LR',
            label: 'Liberia',
          },
          {
            value: 'LY',
            label: 'Libya',
          },
          {
            value: 'LI',
            label: 'Liechtenstein',
          },
          {
            value: 'LT',
            label: 'Lithuania',
          },
          {
            value: 'LU',
            label: 'Luxembourg',
          },
          {
            value: 'MO',
            label: 'Macao',
          },
          {
            value: 'MK',
            label: 'Macedonia',
          },
          {
            value: 'MG',
            label: 'Madagascar',
          },
          {
            value: 'MW',
            label: 'Malawi',
          },
          {
            value: 'MY',
            label: 'Malaysia',
          },
          {
            value: 'MV',
            label: 'Maldives',
          },
          {
            value: 'ML',
            label: 'Mali',
          },
          {
            value: 'MT',
            label: 'Malta',
          },
          {
            value: 'MH',
            label: 'Marshall Islands',
          },
          {
            value: 'MQ',
            label: 'Martinique',
          },
          {
            value: 'MR',
            label: 'Mauritania',
          },
          {
            value: 'MU',
            label: 'Mauritius',
          },
          {
            value: 'YT',
            label: 'Mayotte',
          },
          {
            value: 'MX',
            label: 'Mexico',
          },
          {
            value: 'FM',
            label: 'Micronesia',
          },
          {
            value: 'MD',
            label: 'Moldova',
          },
          {
            value: 'MC',
            label: 'Monaco',
          },
          {
            value: 'MN',
            label: 'Mongolia',
          },
          {
            value: 'ME',
            label: 'Montenegro',
          },
          {
            value: 'MS',
            label: 'Montserrat',
          },
          {
            value: 'MA',
            label: 'Morocco',
          },
          {
            value: 'MZ',
            label: 'Mozambique',
          },
          {
            value: 'MM',
            label: 'Myanmar [Burma]',
          },
          {
            value: 'NA',
            label: 'Namibia',
          },
          {
            value: 'NR',
            label: 'Nauru',
          },
          {
            value: 'NP',
            label: 'Nepal',
          },
          {
            value: 'NL',
            label: 'Netherlands',
          },
          {
            value: 'NC',
            label: 'New Caledonia',
          },
          {
            value: 'NZ',
            label: 'New Zealand',
          },
          {
            value: 'NI',
            label: 'Nicaragua',
          },
          {
            value: 'NE',
            label: 'Niger',
          },
          {
            value: 'NG',
            label: 'Nigeria',
          },
          {
            value: 'NU',
            label: 'Niue',
          },
          {
            value: 'NF',
            label: 'Norfolk Island',
          },
          {
            value: 'KP',
            label: 'North Korea',
          },
          {
            value: 'MP',
            label: 'Northern Mariana Islands',
          },
          {
            value: 'NO',
            label: 'Norway',
          },
          {
            value: 'OM',
            label: 'Oman',
          },
          {
            value: 'PK',
            label: 'Pakistan',
          },
          {
            value: 'PW',
            label: 'Palau',
          },
          {
            value: 'PS',
            label: 'Palestine',
          },
          {
            value: 'PA',
            label: 'Panama',
          },
          {
            value: 'PG',
            label: 'Papua New Guinea',
          },
          {
            value: 'PY',
            label: 'Paraguay',
          },
          {
            value: 'PE',
            label: 'Peru',
          },
          {
            value: 'PH',
            label: 'Philippines',
          },
          {
            value: 'PN',
            label: 'Pitcairn Islands',
          },
          {
            value: 'PL',
            label: 'Poland',
          },
          {
            value: 'PT',
            label: 'Portugal',
          },
          {
            value: 'PR',
            label: 'Puerto Rico',
          },
          {
            value: 'QA',
            label: 'Qatar',
          },
          {
            value: 'CG',
            label: 'Republic of the Congo',
          },
          {
            value: 'RE',
            label: 'Réunion',
          },
          {
            value: 'RO',
            label: 'Romania',
          },
          {
            value: 'RU',
            label: 'Russia',
          },
          {
            value: 'RW',
            label: 'Rwanda',
          },
          {
            value: 'BL',
            label: 'Saint Barthélemy',
          },
          {
            value: 'SH',
            label: 'Saint Helena',
          },
          {
            value: 'KN',
            label: 'Saint Kitts and Nevis',
          },
          {
            value: 'LC',
            label: 'Saint Lucia',
          },
          {
            value: 'MF',
            label: 'Saint Martin',
          },
          {
            value: 'PM',
            label: 'Saint Pierre and Miquelon',
          },
          {
            value: 'VC',
            label: 'Saint Vincent and the Grenadines',
          },
          {
            value: 'WS',
            label: 'Samoa',
          },
          {
            value: 'SM',
            label: 'San Marino',
          },
          {
            value: 'ST',
            label: 'São Tomé and Príncipe',
          },
          {
            value: 'SA',
            label: 'Saudi Arabia',
          },
          {
            value: 'SN',
            label: 'Senegal',
          },
          {
            value: 'RS',
            label: 'Serbia',
          },
          {
            value: 'SC',
            label: 'Seychelles',
          },
          {
            value: 'SL',
            label: 'Sierra Leone',
          },
          {
            value: 'SG',
            label: 'Singapore',
          },
          {
            value: 'SX',
            label: 'Sint Maarten',
          },
          {
            value: 'SK',
            label: 'Slovakia',
          },
          {
            value: 'SI',
            label: 'Slovenia',
          },
          {
            value: 'SB',
            label: 'Solomon Islands',
          },
          {
            value: 'SO',
            label: 'Somalia',
          },
          {
            value: 'ZA',
            label: 'South Africa',
          },
          {
            value: 'GS',
            label: 'South Georgia and the South Sandwich Islands',
          },
          {
            value: 'KR',
            label: 'South Korea',
          },
          {
            value: 'SS',
            label: 'South Sudan',
          },
          {
            value: 'ES',
            label: 'Spain',
          },
          {
            value: 'LK',
            label: 'Sri Lanka',
          },
          {
            value: 'SD',
            label: 'Sudan',
          },
          {
            value: 'SR',
            label: 'Suriname',
          },
          {
            value: 'SJ',
            label: 'Svalbard and Jan Mayen',
          },
          {
            value: 'SZ',
            label: 'Swaziland',
          },
          {
            value: 'SE',
            label: 'Sweden',
          },
          {
            value: 'CH',
            label: 'Switzerland',
          },
          {
            value: 'SY',
            label: 'Syria',
          },
          {
            value: 'TW',
            label: 'Taiwan',
          },
          {
            value: 'TJ',
            label: 'Tajikistan',
          },
          {
            value: 'TZ',
            label: 'Tanzania',
          },
          {
            value: 'TH',
            label: 'Thailand',
          },
          {
            value: 'TG',
            label: 'Togo',
          },
          {
            value: 'TK',
            label: 'Tokelau',
          },
          {
            value: 'TO',
            label: 'Tonga',
          },
          {
            value: 'TT',
            label: 'Trinidad and Tobago',
          },
          {
            value: 'TN',
            label: 'Tunisia',
          },
          {
            value: 'TR',
            label: 'Turkey',
          },
          {
            value: 'TM',
            label: 'Turkmenistan',
          },
          {
            value: 'TC',
            label: 'Turks and Caicos Islands',
          },
          {
            value: 'TV',
            label: 'Tuvalu',
          },
          {
            value: 'UM',
            label: 'U.S. Minor Outlying Islands',
          },
          {
            value: 'VI',
            label: 'U.S. Virgin Islands',
          },
          {
            value: 'UG',
            label: 'Uganda',
          },
          {
            value: 'UA',
            label: 'Ukraine',
          },
          {
            value: 'AE',
            label: 'United Arab Emirates',
          },
          {
            value: 'GB',
            label: 'United Kingdom',
          },
          {
            value: 'US',
            label: 'United States',
          },
          {
            value: 'UY',
            label: 'Uruguay',
          },
          {
            value: 'UZ',
            label: 'Uzbekistan',
          },
          {
            value: 'VU',
            label: 'Vanuatu',
          },
          {
            value: 'VA',
            label: 'Vatican City',
          },
          {
            value: 'VE',
            label: 'Venezuela',
          },
          {
            value: 'VN',
            label: 'Vietnam',
          },
          {
            value: 'WF',
            label: 'Wallis and Futuna',
          },
          {
            value: 'EH',
            label: 'Western Sahara',
          },
          {
            value: 'YE',
            label: 'Yemen',
          },
          {
            value: 'ZM',
            label: 'Zambia',
          },
          {
            value: 'ZW',
            label: 'Zimbabwe',
          },
        ],
        label: 'Country',
        type: 'select',
        required: false,
        org_id: 1,
        placeholder: 'Country',
        // defaultValue: 'IN',
        name: 'r_country',
        component: SelectInput,
      },
    ],
  },
  'Bank Details': {
    fields: [
      {
        id: 52,
        options: [],
        name: 'bank_ifsc_code',
        label: 'Bank IFSC Code',
        type: 'text',
        required: false,
        maxLength: 11,
        org_id: 1,
        placeholder: 'Bank IFSC Code',
        component: TextInput,
      },
      {
        id: 53,
        options: [],
        name: 'bank_account_number',
        label: 'Bank Account Number',
        type: 'text',
        required: false,
        org_id: 1,
        placeholder: 'Bank Account Number',
        component: TextInput,
      },
      {
        id: 54,
        options: [],
        name: 'bank_account_holder_name',
        label: 'Bank Account Holder Name',
        type: 'text',
        required: false,
        org_id: 1,
        placeholder: 'Bank Account Holder Name',
        component: TextInput,
      },
      {
        id: 55,
        options: [
          {
            id: 102,
            name: 'Saving Account',
            label: 'Saving Account',
            value: 102,
          },
          {
            id: 103,
            name: 'Current Account',
            label: 'Current Account',
            value: 103,
          },
          {
            id: 104,
            name: 'Joint Account',
            label: 'Joint Account',
            value: 104,
          },
        ],
        name: 'bank_account_type',
        label: 'Bank Account Type',
        type: 'select',
        required: false,
        org_id: 1,
        placeholder: 'Bank Account Type',
        component: SelectInput,
      },
    ],
  },
  // 'Control Listing': {
  //   fields: [
  //     {
  //       id: 59,
  //       options: [
  //         {
  //           id: 8,
  //           name: 'Black - No more business',
  //           label: 'Black - No more business',
  //           value: 8,
  //         },
  //         {
  //           id: 9,
  //           name: 'Red - Cannot quote due to Limit Exceed ',
  //           label: 'Red - Cannot quote due to Limit Exceed ',
  //           value: 9,
  //         },
  //         {
  //           id: 10,
  //           name: 'Green - Good to go',
  //           label: 'Green - Good to go',
  //           value: 10,
  //         },
  //         {
  //           id: 11,
  //           name: 'Yellow - Have not paid within the credit Period',
  //           label: 'Yellow - Have not paid within the credit Period',
  //           value: 11,
  //         },
  //       ],
  //       name: 'credit_rating',
  //       label: 'Credit Rating',
  //       type: 'select',
  //       required: false,
  //       org_id: 1,
  //       placeholder: 'Enter Credit Rating',
  //       component: SelectInput,
  //     },
  //     {
  //       id: 60,
  //       options: [],
  //       name: 'credit_amount',
  //       label: 'Credit Amount',
  //       type: 'currency',
  //       required: false,
  //       org_id: 1,
  //       placeholder: 'Enter Credit Amount',
  //       component: CurrencyInput,
  //     },
  //     {
  //       id: 61,
  //       options: [
  //         {
  //           id: 16,
  //           name: '7 Days',
  //           label: '7 Days',
  //           value: 16,
  //         },
  //         {
  //           id: 17,
  //           name: '14 Days',
  //           label: '14 Days',
  //           value: 17,
  //         },
  //         {
  //           id: 18,
  //           name: '30 Days',
  //           label: '30 Days',
  //           value: 18,
  //         },
  //         {
  //           id: 19,
  //           name: '60 Days',
  //           label: '60 Days',
  //           value: 19,
  //         },
  //         {
  //           id: 20,
  //           name: '90 Days',
  //           label: '90 Days',
  //           value: 20,
  //         },
  //       ],
  //       name: 'credit_term',
  //       label: 'Credit Term',
  //       type: 'select',
  //       required: false,
  //       org_id: 1,
  //       placeholder: 'Enter Credit Term',
  //       component: SelectInput,
  //     },
  //   ],
  // },
  Description: {
    fields: [
      {
        id: 66,
        options: [],
        name: 'description',
        label: 'Description',
        type: 'textarea',
        required: false,
        org_id: 1,
        placeholder: 'Description',
        isMultiline: true,
        divClasses: 'col-span-full',
        rows: 2,
        component: TextInput,
      },
    ],
  },
});
