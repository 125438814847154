import { CustomModal, FormContainer, IconButton, TableComponent, Toast } from 'components';
import Button from 'components/button';
import { elementData } from 'elements';
import { useEffect, useState } from 'react';
import { prepareFormFieldsData } from 'utils';
import { EditKraFields, KraFields } from './data';
import { NOTIFICATION_MSG } from 'constants';
import { Space, Spin } from 'antd';
import { DELETE, EDIT, Trash } from 'assets/icons';

import {
  deletekraManagement,
  getDesignation,
  getkraManagement,
  postkraManagement,
  updatekraManagement,
} from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const Kra = (props = {}) => {
  const permissions = props?.permissions || [];
  const [showModal, setShowModal] = useState(false);
  const [editmodal, setEditModal] = useState(false);
  const [designationData, setDesignationData] = useState([]);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editInitials, setInitials] = useState();
  const [selectedAsset, setSelectedAsset] = useState({});
  const [newData, setNewData] = useState([]);
  const [designationName, setDesignation] = useState();
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: KraFields,
    defaultValues: { designation: designationName?.id },
  });
  const [editinitialValues, editfinalFields] = prepareFormFieldsData({
    fields: EditKraFields,
  });
  const onEditpress = (data) => {
    setInitials(data);
  };
  const onDeletepress = (data) => {
    setSelectedAsset(data);
    setDeleteModal(true);
  };
  const columns = [
    {
      title: "KRA's",
      dataIndex: 'label',
      key: 'label',
      // render: (data) =>
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.jobKra.permissions.delete}
            onClick={() => {
              onDeletepress(record);
              // toggleDeleteModal();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.jobKra.permissions.update}
            onClick={() => {
              onEditpress(record);
              toggleEditModal();
            }}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };
  const toggleEditModal = () => {
    setEditModal((prev) => !prev);
  };
  const toggleDeleteAsset = () => {
    setDeleteModal((prev) => !prev);
  };
  const getKraData = async () => {
    setLoading(true);
    const resp = await getkraManagement();
    // setMainData(resp?.data?.data);
    const mainData = resp?.data?.data;
    const newArray = mainData.reduce((prev, cur) => {
      let value = cur.designation.name;
      prev[value] = prev[value] || [];
      // prev[value].push({
      //     ...cur
      // })
      prev[value].push(cur);
      return prev;
    }, {});
    setNewData(Object.entries(newArray).map(([key, value]) => value.map((item) => item)));
    setLoading(false);
  };
  const allDesignations = async () => {
    const resp = await getDesignation();
    setDesignationData(resp?.data?.data);
  };
  useEffect(() => {
    getKraData();
    allDesignations();
  }, []);
  const onEditKra = async (record) => {
    setLoading(true);
    const resp = await updatekraManagement({ id: record?.id, data: record });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      getKraData();
      setLoading(false);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.msg);
      setLoading(false);
    }
    setLoading(false);
  };
  const onDeleteAsset = async () => {
    const resp = await deletekraManagement({ id: selectedAsset?.id });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      getKraData();
      setLoading(false);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.msg);
      setLoading(false);
    }
    setLoading(false);
  };
  const onAddKra = async (payload) => {
    // const label = payload?.label.map((item) => item.kpr);
    // const finalPayload = { designation: payload.designation, label: label };
    setLoading(true);
    const resp = await postkraManagement({ data: payload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      getKraData();
      setLoading(false);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.msg);
      setLoading(false);
    }
    setLoading(false);
  };
  const AddButton = ({ onChange }) => {
    return (
      <Button
        onClick={onChange}
        text='+ KRA'
        varient='primary'
        classes=' h-10 w-28 flex justify-center items-center   bg-black text-white  rounded-3xl'
      ></Button>
    );
  };

  const parsedData = [];

  designationData.forEach((designation) => {
    const filteredDesginationData = newData
      .filter((item) => item[0].designation.name === designation.name)
      .flat();
    parsedData.push([designation, filteredDesginationData]);
  });

  return (
    <div className=' w-full bg-white relative rounded-lg pt-4 p-4 grid grid-cols-full gap-4 '>
      <CustomModal destroyOnClose={true} open={showModal} onCancel={toggleModal} footer={''}>
        <FormContainer
          accordionClass='grid grid-cols-2  gap-8'
          onSubmit={onAddKra}
          initialValues={initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    toggleModal();
                  }}
                />
                <Button
                  id={
                    editInitials
                      ? elementData.jobKra.permissions.update
                      : elementData.jobKra.permissions.create
                  }
                  text='Save'
                  variant='primary'
                  classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={() => {
                    submitForm();
                    toggleModal();
                  }}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </CustomModal>
      {loading && (
        <div className='h-[300px] w-screen flex justify-center items-center'>
          <Spin size='lg'></Spin>
        </div>
      )}

      {parsedData.map(([designation, dataSource]) => (
        <div className='h-fit w-full bordered rounded-3xl p-2 '>
          <div className='  w-full px-4 flex justify-between items-center bg-grey-light '>
            <h1 className=' font-semibold  py-4'> {designation.name}</h1>
            {/* {setDesignation(designation)} */}
            <AddButton
              onChange={() => {
                setDesignation(designation);
                toggleModal();
              }}
            />
          </div>
          <div className='w-full bordered '></div>
          <div className='h-full w-full'>
            <TableComponent
              columns={columns}
              dataSource={dataSource}
              showPagination={false}
              getRowClassName={rowCommonClass}
            />
          </div>
        </div>
      ))}

      {/* {newData.map((item) => {
                return (
                    <div className="h-fit w-full bordered rounded-3xl p-2 ">
                        <div className="  w-full pl-2 bg-grey-light ">
                            <h1 className=" font-semibold  py-4">{item[0].designation.name}</h1>
                            <div className="w-full bordered "></div>


                        </div>
                        <div className="h-full w-full">

                            <TableComponent
                                columns={columns}
                                dataSource={item}
                                showPagination={false}
                            />


                        </div>
                    </div>
                );
            })} */}

      <CustomModal destroyOnClose={true} open={editmodal} onCancel={toggleEditModal} footer={''}>
        <FormContainer
          accordionClass='grid grid-cols-2  gap-8'
          onSubmit={onEditKra}
          initialValues={editInitials}
          fields={editfinalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  id={
                    editInitials
                      ? elementData.jobKra.permissions.update
                      : elementData.jobKra.permissions.create
                  }
                  text='Save'
                  variant='primary'
                  classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={() => {
                    submitForm();
                    toggleEditModal();
                  }}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </CustomModal>

      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteAsset}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the selected KRA ?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteAsset}
            />
            <Button
              text='Delete'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={() => {
                onDeleteAsset();
                toggleDeleteAsset();
              }}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Kra;
