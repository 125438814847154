import { MultiUserInitial, SelectInput, Uploadbox } from 'components';
import dayjs from 'dayjs';
import { documentOptions } from 'utils/options';

export const createDocumentFields = {
  'Upload Document': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Document Name',
        name: 'name',
        id: 'name',
        placeholder: 'Document Name',
        type: 'text',
        component: SelectInput,
        options: documentOptions,
        maxLength: 30,
        required: true,
      },
      {
        label: 'Upload Document',
        name: 'file',
        id: 'file',
        placeholder: 'file',
        required: true,
        component: Uploadbox,
        accept: ['.png, .jpeg, .jpg, .pdf, .txt , .doc'],
      },
    ],
  },
};
// initial values add document
export const initialDocumentValues = {
  name: '',
  file: '',
};

export const activityColumns = [
  {
    key: 'activity',
    title: 'Activity',
    dataIndex: 'activity',
    render: (text) => text || '\u2014',
  },
  {
    key: 'subject',
    title: 'Subject',
    dataIndex: 'subject',
    render: (text) => text || '\u2014',
  },
  {
    key: 'assign_to_employee',
    title: 'Assigned To',
    dataIndex: 'assign_to_employee',
    render: (text) => (
      <div className='truncate'>
        {' '}
        <MultiUserInitial data={text} value={'name'} label={'employee'} />{' '}
      </div>
    ),
  },
  {
    key: 'from_date',
    title: 'From',
    dataIndex: 'from_date',
    render: (_) => {
      if (_) {
        return dayjs(_).format('ddd MM,YY, HH:mm');
      } else {
        return '\u2014';
      }
    },
  },
];
