import { Uploadbox } from 'components';

export const UploadFields = {
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        name: 'contract',
        id: 'contract',
        placeholder: 'Select Document',
        type: 'upload',
        component: Uploadbox,
      },
    ],
  },
};
