import { endpoints } from 'constants/endpoints';
import Request from './client';

export const createRecruitment = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.recruitment.create,
    data: data,
  });
  return resp;
};

export const updateRecruitment = async ({ data, id }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.recruitment.single(id),
    data: data,
  });
  return resp;
};

export const updateApplication = async ({ data, id }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.recruitment.update_application(id),
    data: data,
  });
  return resp;
};

export const getJobList = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.recruitment.list,
  });
  return resp;
};

export const getJobDetails = async ({ id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.recruitment.job_details(id),
  });
  return resp;
};

export const applyForJob = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.recruitment.job_apply,
    data,
  });
  return resp;
};

export const getRecruitmentDetails = async ({ id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.recruitment.single(id),
  });
  return resp;
};

//interview API starts here
export const inviteCandidate = async ({ data }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.recruitment.invite,
    data,
  });
  return resp;
};

export const getInterviewRequest = async ({ status = '', date = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.recruitment.interview_request(date, status),
  });
  return resp;
};

export const getParticularInterviewRequest = async ({ id = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.recruitment.getParticularInterview(id),
  });
  return resp;
};

export const getUpcomingInterview = async ({ status = '', date = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.recruitment.upcoming_interview(date, status),
  });
  return resp;
};

export const getInterviewList = async ({ status }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.recruitment.interview_list(status),
  });
  return resp;
};

export const updateInterview = async ({ data, id }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.recruitment.update_interview(id),
    data,
  });
  return resp;
};

export const deleteInterview = async ({ id }) => {
  const resp = await Request({
    method: 'delete',
    API: endpoints.recruitment.update_interview(id),
  });
  return resp;
};

export const getkraManagement = async ({}) => {
  const resp = await Request({
    method: 'get',
    API: `recruitment/job-kpr`,
  });
  return resp;
};

export const updatekraManagement = async ({ data = [], id = '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `recruitment/job-kpr/${id}`,
    data: data,
  });
  return resp;
};

export const deletekraManagement = async ({ id = '' }) => {
  const resp = await Request({
    method: 'delete',
    API: `recruitment/job-kpr/${id}`,
  });
  return resp;
};

export const postkraManagement = async ({ data = [] }) => {
  const resp = await Request({
    method: 'post',
    API: `recruitment/job-kpr`,
    data: data,
  });
  return resp;
};

export const getKpi = async ({}) => {
  const resp = await Request({
    method: 'get',
    API: `recruitment/job-kpi`,
  });
  return resp;
};

export const updateKpi = async ({ data = [], id = '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `recruitment/job-kpi/${id}`,
    data: data,
  });
  return resp;
};

export const postKpi = async ({ data = [], id = '' }) => {
  const resp = await Request({
    method: 'post',
    API: `recruitment/job-kpi`,
    data: data,
  });
  return resp;
};

export const deleteKpi = async ({ id = '' }) => {
  const resp = await Request({
    method: 'delete',
    API: `recruitment/job-kpi/${id}`,
  });
  return resp;
};

// jobRequirements

export const updateJob = async ({ data = [], id = '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `recruitment/job/${id}`,
    data: data,
  });
  return resp;
};

// ApplicationManagement

export const updateApplicationManagement = async ({ data = [], id = '' }) => {
  const resp = await Request({
    method: 'patch',
    API: `recruitment/job-application${`/${id}`}`,
    data: data,
  });
  return resp;
};
