import { ViewData } from 'components';
import { SecureComponent } from 'permission-provider';
import React, { useState } from 'react';
import Quote from 'screens/customers/quote';
// import ComparePolicy from "screens/customers/quote/add-quote/ComparePolicy";
import { formatQuoteViewData } from 'screens/customers/quote/data';
import PolicyData from './PolicyData';
// import { Context } from "store/context";

const Quotes = ({ employee_id, customer, permissions = {} }) => {
  const [showQuoteTabs, setShowQuoteTabs] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  // const { selectedData } = useContext(Context);

  const selectedQuote = (record) => {
    setSelectedData(record);
    setShowQuoteTabs(true);
  };

  return (
    <div>
      {showQuoteTabs ? (
        <div className='relative bg-white rounded-2xl p-4'>
          <ViewData
            classes='relative bg-white rounded-2xl p-4'
            pageTitle='Quote Details'
            data={formatQuoteViewData(selectedData)}
            showGoToBack={true}
            goBack={setShowQuoteTabs.bind(this, false)}
          />

          <PolicyData productsPolicy={selectedData?.product} data={selectedData} />
        </div>
      ) : (
        <Quote
          permissions={permissions}
          selectedData={selectedQuote}
          employee_id={employee_id}
          customer={customer}
        />
      )}
    </div>
  );
};

export default SecureComponent(Quotes);
