import { Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { DocExport, Filter, TableComponent } from 'components';
import { getInsuranceCategory, reportsManagement } from 'service/api-service';

import {
  ClientColumns,
  ColumnsToExport,
  EmployeeColumns,
  filterOptions,
  leadsColumns,
  parsedData,
} from './data';
import BussinessGraph from './bussinessGraph';
import BussinessHeader from './bussinessHeader';
import { getQueryParams } from 'utils/common';
import { rowCommonClass } from 'utils/common';

import './styles.css';
import { DropdownIcon } from 'assets/icons';
const currentDate = dayjs();
const dateBefore30Days = currentDate.subtract(30, 'day');
const MIN_FIELDS = 5;
const MAX_FIELDS = 800;
const placeholderClasses =
  '[&_.ant-select-selection-placeholder]:!text-black [&_.ant-select-selection-placeholder]:!text-xxs [&_.ant-select-selection-item]:!text-xxs';

const Reports = ({ permissions }) => {
  const { filterModule } = getQueryParams();
  const printRef = useRef(null);
  const [currPage, setCurrPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cardCount, setCardCount] = useState([]);
  const [TableData, setTableData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  const [filterType, setFilterType] = useState('Retails');
  const [selectedModule, setSelectedmodule] = useState(filterModule);
  const [selectedEmployee, setSelectedEmployee] = useState(undefined);
  const [selectedBranch, setSelectedBranch] = useState(undefined);
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [selectedComponent, setSelectedComponent] = useState(['all']);
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [curColumns, setCurColumns] = useState(EmployeeColumns.slice(0, 10));
  const [leadsCurColumns, setLeadsCurColumns] = useState(leadsColumns.slice(0, 9));
  const [clientCurColumns, setClientCurColumns] = useState(ClientColumns.slice(0, 9));
  const [startDate, setStartDate] = useState(dayjs(dateBefore30Days).format('YYYY-MM-DD'));

  const CategoryOptions = categories?.map((item) => {
    return {
      label: item?.name,
      value: item?.id,
    };
  });

  const handleCustomRange = (e) => {
    const startDate = dayjs(e[0]).format('YYYY-MM-DD');
    const endDate = dayjs(e[1]).format('YYYY-MM-DD');
    if (startDate && endDate) {
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };
  const handleFilterType = (e) => {
    setFilterType(e);
    if (e === 'clients') {
      setFilterType('retail');
    }
  };

  const handlecategories = (e) => {
    setSelectedCategory(e);
    const filter = categories?.find((item) => item.id === e);
    const products = filter?.product_type?.map((ele) => {
      return {
        label: ele?.name,
        value: ele?.id,
      };
    });
    setProducts(products);
  };

  const handleProducts = (e) => {
    setSelectedProduct(e);
  };

  const GetCategories = async () => {
    setLoading(true);
    const resp = await getInsuranceCategory();
    if (resp?.data?.status) {
      setCategories(resp?.data?.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    GetCategories();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const resp = await reportsManagement({
        start_date: startDate,
        end_date: endDate,
        page: currPage,
        modules: selectedModule,
        branch_id: selectedBranch,
        employee_id: selectedModule === 'leads' ? selectedEmployee : '',
        type: selectedModule === 'clients' ? filterType : '',
        category: selectedCategory || '',
        product: selectedProduct || '',
      });
      if (selectedModule === 'clients') {
        setTableData(resp?.data?.data?.result);
        setDataCount(resp?.data?.data?.total_page);
      } else {
        setTableData(resp?.data?.data);
        setDataCount(resp?.data?.data?.length);
      }
      setCardCount(resp?.data);
      setLoading(false);
    };

    fetchData();
  }, [
    startDate,
    endDate,
    selectedModule,
    selectedBranch,
    filterType,
    selectedCategory,
    selectedProduct,
    selectedEmployee,
    currPage,
  ]);

  const handlePagination = (vals) => {
    if (vals?.current) {
      setCurrPage(vals?.current);
    }
  };

  useEffect(() => {
    const exportData = ColumnsToExport({
      data: TableData,
      columns:
        selectedModule === 'employees'
          ? curColumns
          : selectedModule === 'clients'
          ? clientCurColumns
          : leadsColumns,
    });
    setDataToExport(parsedData(exportData));
  }, [selectedModule, curColumns, clientCurColumns, leadsCurColumns, TableData]);

  return (
    <div className='grid gap-4 '>
      <BussinessHeader
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        setFilterType={setFilterType}
        selectedModule={selectedModule}
        setSelectedmodule={setSelectedmodule}
        selectedComponent={selectedComponent}
        setSelectedBranch={setSelectedBranch}
        handleCustomRange={handleCustomRange}
        setSelectedProduct={setSelectedProduct}
        setSelectedEmployee={setSelectedEmployee}
        setSelectedComponent={setSelectedComponent}
        setSelectedCategory={setSelectedCategory}
      />
      {selectedModule && (
        <div className=' flex flex-col '>
          {selectedComponent?.find((ele) => ele === 'graph' || ele === 'all') && (
            <BussinessGraph selectedModule={selectedModule} cardCount={cardCount}>
              {selectedComponent?.find((ele) => ele === 'table' || ele === 'all') && (
                <>
                  <div className=' h-fit flex justify-between'>
                    <span className='capitalize text-sm font-semibold'>
                      {selectedModule} details
                    </span>
                    <div className=' flex gap-4'>
                      {selectedModule === 'leads' && (
                        <>
                          <Select
                            className={`bg-white ${placeholderClasses} rounded-md px-2`}
                            bordered={true}
                            options={CategoryOptions}
                            onSelect={handlecategories}
                            dropdownAlign={{ offset: [-8, 15] }}
                            suffixIcon={<DropdownIcon svgClass={'w-3 fill-black '} />}
                            placeholder='Category'
                            allowClear={true}
                            onClear={() => {
                              setSelectedCategory(undefined);
                            }}
                            style={{
                              width: 110,

                              // backgroundColor:'white'
                            }}
                          />

                          <Select
                            className={`bg-white ${placeholderClasses} rounded-md px-2`}
                            bordered={true}
                            options={products}
                            onSelect={handleProducts}
                            dropdownAlign={{ offset: [-8, 15] }}
                            suffixIcon={<DropdownIcon svgClass={'w-3 fill-black '} />}
                            placeholder='Products'
                            allowClear={true}
                            onClear={() => {
                              setSelectedProduct(undefined);
                            }}
                            style={{
                              width: 110,
                            }}
                          />
                        </>
                      )}
                      {selectedModule === 'clients' && (
                        <Select
                          className={`bg-white ${placeholderClasses} rounded-md px-2`}
                          bordered={true}
                          options={filterOptions}
                          dropdownAlign={{ offset: [-8, 15] }}
                          suffixIcon={<DropdownIcon svgClass={'w-3 fill-black '} />}
                          onSelect={handleFilterType}
                          placeholder='Customer Type'
                          defaultValue={{ value: 'retail', label: 'Retail' }}
                          allowClear={true}
                          onClear={() => {
                            setFilterType(undefined);
                          }}
                          style={{
                            width: 110,
                          }}
                        />
                      )}
                      <div className=' bg-primary-30 h-8 w-8  flex justify-center items-center rounded-md'>
                        <Filter
                          stroke='white'
                          removeValFrom={[10, 17, 30]}
                          allColumns={
                            selectedModule === 'clients'
                              ? ClientColumns
                              : selectedModule === 'employees'
                              ? EmployeeColumns
                              : leadsColumns
                          }
                          activeColumns={
                            selectedModule === 'clients'
                              ? clientCurColumns
                              : selectedModule === 'employees'
                              ? curColumns
                              : leadsCurColumns
                          }
                          updateActiveColumns={
                            selectedModule === 'clients'
                              ? setClientCurColumns
                              : selectedModule === 'employees'
                              ? setCurColumns
                              : setLeadsCurColumns
                          }
                          minFields={MIN_FIELDS}
                          maxFields={MAX_FIELDS}
                        />
                      </div>

                      <DocExport
                        data={parsedData(dataToExport)}
                        file={`Business Report from ${startDate} to ${endDate}`}
                        printRef={printRef.current}
                        id='divToPrint'
                        type='landscape'
                        showIcons={true}
                      />
                    </div>
                  </div>
                </>
              )}
            </BussinessGraph>
          )}
        </div>
      )}

      {selectedComponent?.find((ele) => ele === 'table' || ele === 'all') && selectedModule && (
        <div id='divToPrint' ref={printRef} className='w-full  '>
          <TableComponent
            loading={loading}
            total={dataCount}
            scroll={{
              x:
                (selectedModule === 'clients'
                  ? clientCurColumns.filter((i) =>
                      i.displayFilter ? i.displayFilter.includes(filterType) : true,
                    )
                  : selectedModule === 'employees'
                  ? curColumns
                  : leadsCurColumns
                )?.length > 8
                  ? 3000
                  : 500,
            }}
            getRowClassName={rowCommonClass}
            columns={
              selectedModule === 'clients'
                ? clientCurColumns.filter((i) =>
                    i.displayFilter ? i.displayFilter.includes(filterType) : true,
                  )
                : selectedModule === 'employees'
                ? curColumns
                : leadsCurColumns
            }
            dataSource={TableData}
            onChange={handlePagination}
          />
        </div>
      )}
      {!selectedModule && (
        <h2 className=' text-l text-grey-90 text-center absolute top-[50%] left-[40%] '>
          Please select module.
        </h2>
      )}
    </div>
  );
};

export default Reports;
