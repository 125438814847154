import { DefaultUser } from 'assets';
import { MultiEmail } from 'components';

export const breadCrumbsObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Leads',
    path: '',
  },
];

export const InquiryColumns = [
  // {
  //   title: "Source",
  //   dataIndex: "source",
  //   key: "source",
  //   className: "capitalize",
  //   width: 100,
  //   id: "source",
  //   render: (text) => {
  //     return text || "\u2014";
  //   },
  // },
  // {
  //   title: "Inquiry Name",
  //   dataIndex: "name",
  //   key: "name",
  //   className: "capitalize",
  //   id: "name",
  //   render: (text, data) => {
  //     if (data.source === "online") {
  //       return data?.type.replaceAll("_", " ") || "\u2014";
  //     }
  //     return text || "\u2014";
  //   },
  // },
  {
    title: 'Employee',
    dataIndex: 'assign_to_employee',
    key: 'assign_to_employee',
    id: 'assign_to_employee',
    render: (record) => (
      <div className='flex items-center'>
        <img
          src={record?.image || DefaultUser}
          className='h-6 w-6 mr-2'
          alt='name'
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = DefaultUser;
          }}
        />
        {record?.name}
      </div>
    ),
  },
  {
    title: 'Client Name',
    dataIndex: 'customer_id',
    key: 'customer_id',
    id: 'customer_id',
    render: (text, data) => {
      if (data.source === 'online') {
        return data?.name || '\u2014';
      }
      return text || '\u2014';
    },
  },
  // {
  //   title: "Contact Name",
  //   dataIndex: "contact_id",
  //   key: "contact_id",
  //   id: "contact_id",
  //   render: (text) => text || "\u2014",
  // },
  {
    title: 'Primary Email Address',
    dataIndex: 'primary_email_address',
    key: 'primary_email_address',
    id: 'primary_email_address',
    render: (text, data) => {
      if (data.source === 'online') {
        return (
          (
            <MultiEmail
              text={data?.email}
              button={false}
              titleClasses={'underline cursor-pointer'}
              imageRef='invoice-slip'
              emailData={data?.email}
            />
          ) || '\u2014'
        );
      }
      return (
        (
          <MultiEmail
            text={text}
            button={false}
            titleClasses={'underline cursor-pointer'}
            imageRef='invoice-slip'
            emailData={text}
          />
        ) || '\u2014'
      );
    },
  },
  {
    title: 'Category of Insurance',
    dataIndex: 'insurance_category',
    key: 'insurance_category',
    id: 'insurance_category',
    render: (data) => data?.name || '\u2014',
  },
  {
    title: 'Product Type',
    dataIndex: 'product_type',
    key: 'product_type',
    id: 'product_type',
    render: (data) => data?.name || '\u2014',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    id: 'status',
    render: (text) => (
      <div
        className={`${
          text === 'converted' ? 'bg-green' : text === 'quoted' ? 'bg-primary-90' : 'bg-grey-70'
        }  px-3 rounded flex justify-center w-max capitalize !text-x py-1`}
      >
        {text || 'DRAFTED'}
      </div>
    ),
  },
  {
    title: 'Contact Number',
    dataIndex: 'contact_number',
    key: 'contact_number',
    id: 'contact_number',
    render: (text, data) => {
      if (data.source === 'online') {
        return data?.contact || '\u2014';
      }
      return text || '\u2014';
    },
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    id: 'duration',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Period of Insurance: From',
    dataIndex: 'insurance_period_from',
    key: 'insurance_period_from',
    id: 'insurance_period_from',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Period of Insurance: To',
    dataIndex: 'insurance_period_to',
    key: 'insurance_period_to',
    id: 'insurance_period_to',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Expected Close Date',
    dataIndex: 'close_date',
    key: 'close_date',
    id: 'close_date',
    render: (text) => text || '\u2014',
  },
  // {
  //   title: "Probability",
  //   dataIndex: "probability",
  //   key: "probability",
  //   id: "probability",
  //   render: (text) => text || "\u2014",
  // },
  {
    title: 'Lead Source',
    dataIndex: 'lead_source',
    key: 'lead_source',
    id: 'lead_source',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Sales Stage',
    dataIndex: 'sales_stages',
    key: 'sales_stages',
    id: 'sales_stages',
    render: (text) => text || '\u2014',
  },
  // {
  //   title: "Next Step",
  //   dataIndex: "next_step",
  //   key: "next_step",
  //   id: "next_step",
  //   render: (text) => text || "\u2014",
  // },
  // {
  //   title: "Type of Policy",
  //   dataIndex: "policy",
  //   key: "policy",
  //   id: "policy",
  //   render: (text) => text || "\u2014",
  // },
  {
    title: 'Nature of Business',
    dataIndex: 'business_nature',
    key: 'business_nature',
    id: 'business_nature',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Submission Date',
    dataIndex: 'submission_date',
    key: 'submission_date',
    id: 'submission_date',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Request From',
    dataIndex: 'request_from',
    key: 'request_from',
    id: 'request_from',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Presently Insured',
    dataIndex: 'presently_insured',
    key: 'presently_insured',
    id: 'presently_insured',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    id: 'description',
    render: (text) => text || '\u2014',
  },
];

/**
 * @param {{
 * key: string,
 * name: string;
 * customer_name: string;
 * contact_id: string;
 * primary_email_address: string;
 * insurance_category: string;
 * duration: string;
 * insurance_period_from: string;
 * insurance_period_to: string;
 * close_date: string;
 * probability: string;
 * lead_source: string;
 * sales_stages: string;
 * employement: string;
 * next_step: string;
 * policy: string;
 * business_nature: string;
 * assign_to_employee: string;
 * submission_date: string;
 * request_from: string;
 * presently_insured: string;
 * description: string;
 * id: string;
 * name: string;
 * gender: string;
 * contact: string;
 * email: string;
 * type: string;
 * date_of_birth:string;
 * rg_no: string;
 * source:string;
 * source_ref:string;
 * extra_data:string;
 * }} data
 */
export const formatInquiryViewData = (data) => {
  if (data?.extra_data) {
    const res = { _: [] };
    Object.entries(data?.extra_data).forEach(([key, val]) => {
      res['_'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
    });
    return res;
  } else {
    const res = {
      'Basic Information': [],
      'Insured Information': [],
      // Remarks: [],
    };
    if (!data) return res;

    Object.entries(data).forEach(([key, val]) => {
      switch (key) {
        case 'name':
        case 'customer_name':
        case 'primary_email_address':
        case 'contact_number':
        case 'duration':
        case 'insurance_period_from':
        case 'insurance_period_to':
        case 'close_date':
        case 'probability':
        case 'lead_source':
        case 'sales_stages':
        case 'employement':
        case 'next_step':
        case 'policy':
        case 'request_from':
        case 'presently_insured':
          res['Basic Information'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
          break;
        case 'insurance_category':
        case 'product_type':
          res['Basic Information'].push({
            label: key,
            value: val?.name,
            name: key.replaceAll('_', ' '),
          });
          break;

        case 'business_nature':
          res['Basic Information'].push({
            label: key,
            value: val,
            name: key.replaceAll('_', ' '),
            className: 'col-span-full',
          });
          break;
        case 'insurance_details':
          if (val) {
            Object.entries(val).forEach(([key, val]) => {
              res['Insured Information'].push({
                label: key,
                value: typeof val === 'string' ? val : val?.name,
                name: key.replaceAll('_', ' '),
              });
            });
          }
          break;
        // case "remarks":
        //   res["Remarks"].push({ label: key, value: val, name: "" });
        //   break;
        default:
          break;
      }
    });
    return res;
  }
};

// leads formatter
export const formatLeadsViewData = (data) => {
  // if (data?.extra_data) {
  //   const res = { _: [] };
  //   Object.entries(data?.extra_data).forEach(([key, val]) => {
  //     res["_"].push({ label: key, value: val, name: key.replaceAll("_", " ") });
  //   });
  //   return res;
  // } else {
  const res = {
    'Basic Information': [],
    'Additional Information': [],
    'Lead Source': [],
  };
  if (!data) return res;

  Object.entries(data).forEach(([key, val]) => {
    switch (key) {
      case 'name':
      case 'gender':
      case 'contact':
      case 'email':
      case 'type':
      case 'date_of_birth':
      case 'rg_no':
        res['Basic Information'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
        break;
      case 'extra_data':
        if (val) {
          Object.entries(val).forEach(([key, val]) => {
            res['Additional Information'].push({
              label: key,
              value: val,
              name: key.replaceAll('_', ' '),
            });
          });
        }
        break;
      case 'source':
        res['Lead Source'].push({ label: key, value: val, name: key.replaceAll('_', ' ') });
        break;
      case 'source_ref':
        if (val?.name) {
          res['Lead Source'].push({
            label: 'Name',
            value: val?.name,
            name: 'Name',
            type: 'link',
          });
        }
        break;
      default:
        break;
    }
  });
  return res;
  // }
};

export const items = [
  {
    key: 1,
    label: `Draft `,
  },
  {
    key: 2,
    label: `Quoted `,
  },
  {
    key: 3,
    label: `Rejected `,
  },
  {
    key: 4,
    label: `Converted `,
  },
  {
    key: 5,
    label: `Loses `,
  },
];
