import { useCallback, useEffect, useState } from 'react';
import { getForm } from 'service/api-service';
import {
  ChildSavingForm,
  FourWheelerForm,
  HealthForm,
  InvestmentAndTaxPlanningForm,
  LifeForm,
  PensionAndRetirementForm,
  RenderCustomForm,
  TermLifeForm,
  TravelForm,
  TwoWheelerForm,
} from '../forms';
import IciciProtectForm from '../forms/IciciProtectForm';

const getIdToCompare = (data) => {
  if (!data) return null;
  if (typeof data === 'object' && !Array.isArray(data)) return data.formid;
  if (typeof data === 'string') return data;

  return null;
};

const DynamicForm = ({ id, forms, formValues, setSelectedProduct, setValues, isEditing }) => {
  const [schema, setSchema] = useState();

  const data = forms.find((i) => i?.name === getIdToCompare(id));

  useEffect(() => {
    setSchema();
    setTimeout(() => {
      setSchema(data);
    }, 100);
  }, [data]);

  return (
    schema && (
      <RenderCustomForm.Form
        formData={schema}
        setSelectedProduct={setSelectedProduct}
        setValues={setValues}
        formValues={formValues}
        isEditing={isEditing}
      />
    )
  );
};

export const LeadForm = ({
  isEditing = false,
  id,
  formValues,
  submitProduct,
  setCheckSubmit,
  insurer,
  setValues,
  setSelectedProduct,
  setFormLoading,
  ...rest
}) => {
  const [dynamicForms, setDynamicForm] = useState([]);
  const load = useCallback(async () => {
    const resp = await getForm();

    if (resp?.data?.status) {
      setDynamicForm(resp.data.data);
    }
  }, []);

  useEffect(() => {
    setFormLoading(true);
    load();
    setFormLoading(false);
  }, []);

  switch (id?.formid || id) {
    case 'TwoWheelerForm':
      return (
        <TwoWheelerForm.Form
          {...rest}
          formData={formValues}
          submitProduct={submitProduct}
          setSelectedProduct={setSelectedProduct}
          setValues={setValues}
          setCheckSubmit={setCheckSubmit}
          insurer={insurer}
          isEditing={isEditing}
        />
      );

    case 'FourWheelerForm':
      return (
        <FourWheelerForm.Form
          {...rest}
          formData={formValues}
          submitProduct={submitProduct}
          setCheckSubmit={setCheckSubmit}
          setSelectedProduct={setSelectedProduct}
          setValues={setValues}
          insurer={insurer}
          isEditing={isEditing}
        />
      );

    case 'LifeForm':
      return (
        <LifeForm.Form
          {...rest}
          formData={formValues}
          setCheckSubmit={setCheckSubmit}
          submitProduct={submitProduct}
          setSelectedProduct={setSelectedProduct}
          setValues={setValues}
          insurer={insurer}
          isEditing={isEditing}
        />
      );

    case 'HealthForm':
      return (
        <HealthForm.Form
          {...rest}
          formData={formValues}
          setCheckSubmit={setCheckSubmit}
          submitProduct={submitProduct}
          setSelectedProduct={setSelectedProduct}
          setValues={setValues}
          insurer={insurer}
          isEditing={isEditing}
        />
      );

    case 'ChildSavingForm':
      return (
        <ChildSavingForm.Form
          {...rest}
          formData={formValues}
          submitProduct={submitProduct}
          setSelectedProduct={setSelectedProduct}
          setValues={setValues}
          setCheckSubmit={setCheckSubmit}
          insurer={insurer}
          isEditing={isEditing}
        />
      );

    case 'PensionAndRetirementForm':
      return (
        <PensionAndRetirementForm.Form
          {...rest}
          formData={formValues}
          submitProduct={submitProduct}
          setSelectedProduct={setSelectedProduct}
          setValues={setValues}
          setCheckSubmit={setCheckSubmit}
          insurer={insurer}
          isEditing={isEditing}
        />
      );

    case 'InvestmentAndTaxPlanningForm':
      return (
        <InvestmentAndTaxPlanningForm.Form
          {...rest}
          formData={formValues}
          submitProduct={submitProduct}
          setSelectedProduct={setSelectedProduct}
          setValues={setValues}
          setCheckSubmit={setCheckSubmit}
          insurer={insurer}
          isEditing={isEditing}
        />
      );
    case 'TravelForm':
      return (
        <TravelForm.Form
          {...rest}
          formData={formValues}
          submitProduct={submitProduct}
          setSelectedProduct={setSelectedProduct}
          setValues={setValues}
          setCheckSubmit={setCheckSubmit}
          insurer={insurer}
          isEditing={isEditing}
        />
      );

    case 'TermLifeForm':
      return (
        <TermLifeForm.Form
          {...rest}
          formData={formValues}
          submitProduct={submitProduct}
          setSelectedProduct={setSelectedProduct}
          setValues={setValues}
          setCheckSubmit={setCheckSubmit}
          insurer={insurer}
          isEditing={isEditing}
        />
      );
    case 'ICICIProtectForm':
      return (
        <IciciProtectForm.Form
          {...rest}
          formData={formValues}
          submitProduct={submitProduct}
          setSelectedProduct={setSelectedProduct}
          setValues={setValues}
          setCheckSubmit={setCheckSubmit}
          insurer={insurer}
          isEditing={isEditing}
        />
      );

    default:
      return (
        <DynamicForm
          {...rest}
          id={id}
          forms={dynamicForms}
          submitProduct={submitProduct}
          setSelectedProduct={setSelectedProduct}
          setValues={setValues}
          setCheckSubmit={setCheckSubmit}
          insurer={insurer}
          isEditing={isEditing}
          formValues={formValues}
        />
      );
  }
};
