import { Checkbox, Divider, Radio } from 'antd';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useLeads } from 'screens/leads/store';
import CreatePolicy from './CreatePolicy';
import { Loader } from 'components';

const MultiProductpolicy = ({ permissions }) => {
  const quotations = useLeads((state) => state.quotations);
  const productsData = quotations && quotations?.data && quotations?.data?.products;
  Array.isArray(quotations?.data?.products) &&
    quotations?.data?.products.filter((d) => d.s_insurer);

  const [products, setProducts] = React.useState(productsData);
  const [selectedProduct, setSelectedProduct] = React.useState(productsData?.[0]?.id);

  useEffect(() => {
    setProducts(productsData);
  }, [productsData]);

  const sidebar = document.getElementById('policy-portal');

  const renderProducts = (product, index) => {
    return (
      <li key={product.id}>
        <Radio
          className='!ml-0'
          key={product.id}
          id={product.id}
          value={product.id}
          // checked={insurer.__toShow !== false}
          //   onChange={(e) =>
          //     toggleIsProductChecked(product.id, insurer.id, e.target.checked)
          //   }
        >
          <p>{product.product_type.name}</p>
        </Radio>
      </li>
    );
  };

  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  if (productsData?.length === 0) {
    return <Loader />;
  }
  const productData =
    products && Array.isArray(products) && products.filter((d) => d.id === selectedProduct);

  return (
    <div>
      <CreatePolicy
        setSelectedProduct={setSelectedProduct}
        products={products}
        setProducts={setProducts}
        selectedProduct={productData ? productData[0] : []}
        permissions={permissions}
      />
      {sidebar &&
        createPortal(
          <>
            <div className='grid  gap-2  mr-2'>
              <Divider className='bg-[#00000036] my-0 w-full ' />
            </div>
            <div className='grid overflow-x-scroll gap-2 p-4 mt-5 mr-2 bg-white rounded'>
              <p className='text-sm font-semibold'>Select Products</p>
              <ul className='grid gap-2'>
                <Radio.Group
                  value={selectedProduct}
                  className='flex flex-col gap-[10px]'
                  onChange={handleProductChange}
                >
                  {products ? (
                    products && Array.isArray(products) && products.map(renderProducts)
                  ) : (
                    <div>
                      <Loader />
                    </div>
                  )}
                </Radio.Group>
              </ul>
            </div>
          </>,
          sidebar,
        )}
    </div>
  );
};

export default MultiProductpolicy;
