export const responseOptions = [
  {
    label: 'Interested',
    name: 'interested',
    value: 'interested',
    id: 'intersted',
  },
  {
    label: 'Not Interested',
    name: 'not_interested',
    value: 'not_interested',
    id: 'not_intersted',
  },
  {
    label: "Doesn't Pick Up",
    name: 'not_pickup',
    value: 'not_pickup',
    id: 'not_pickup',
  },
  {
    label: ' Current Policy Expiring Soon',
    name: 'expiring',
    value: 'expiring',
    id: 'expiring',
  },
  {
    label: ' In Discussion with Another Insurer',
    name: 'other',
    value: 'other',
    id: 'other',
  },
];
