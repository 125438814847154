import React, { useCallback, useEffect, useState } from 'react';
import { Space } from 'antd';
/** internal deps */
import {
  ContentHeader,
  FormContainer,
  TableComponent,
  Toast,
  Button,
  CustomModal,
  IconButton,
} from 'components';
import { elementData } from 'elements';
import { addBasicFields, breadcrumbObjBasicInfo, payrollColumns } from './data';
import { NOTIFICATION_MSG } from 'constants';
import { debounce, getEditedFields, prepareFormFieldsData } from 'utils';
import { getSalary, addSalary, editSalary, deleteSalary } from 'service/api-service';
import { SecureComponent } from 'permission-provider';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { rowCommonClass } from 'utils/common';

const BasicInformation = (props = {}) => {
  const permissions = props?.permissions || [];
  const [loading, setLoading] = useState(false);
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [editInitials, setInitials] = useState();
  const [data, setData] = useState([]);
  const [selectedInfo, setSelectedInfo] = useState();
  const [total_page, setTotalPage] = useState();
  const [page, setPageNum] = useState(1);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addBasicFields,
    defaultValues: {
      allow_ded: [],
      taxes: [],
    },
  });
  /* func */

  const fetchData = useCallback(async ({ page = 1, q = '' } = {}) => {
    setLoading(true);
    const resp = await getSalary({ query: `page=${page}&q=${q}` });
    if (resp?.data?.data) {
      setData(resp?.data?.data?.result);
      setPageNum(resp?.data?.data?.current_page);
      setTotalPage(resp?.data?.data?.total_page);
    } else Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    setLoading(false);
  }, []);

  const saveBasicInfo = async (data) => {
    setLoading(true);
    const resp = await addSalary({ data: data });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      fetchData();
      toggleAddInfo();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onEditInfo = useCallback(
    async (data) => {
      setLoading(true);
      const editFields = getEditedFields({ initialValues: editInitials, updatedData: data });
      if (Object.keys(editFields).length) {
        const resp = await editSalary({
          id: selectedInfo?.id,
          data: editFields,
        });

        if (resp?.data?.status) {
          Toast(
            'success',
            NOTIFICATION_MSG.success,
            resp?.data?.msg || 'Data updated successfully.',
          );
          fetchData();
        } else {
          Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
        }
        toggleAddInfo();
        setLoading(false);
      } else {
        setLoading(false);
        Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save.');
      }
    },
    [editInitials, selectedInfo],
  );

  const onDeleteAllowance = async () => {
    const id = selectedInfo?.id;
    if (!id) return;
    setLoading(true);
    const resp = await deleteSalary({ id });
    if (resp?.data?.status) {
      fetchData();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Entry deleted successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteAllowance();
    setLoading(false);
  };

  const toggleAddInfo = () => {
    setInitials(undefined);
    setAddModal((pre) => {
      return !pre;
    });
  };
  const toggleDeleteAllowance = () => setDeleteModal((pre) => !pre);

  const handleEditClick = (record) => {
    setSelectedInfo(record);
    const payload = {
      ...record,
      allow_ded: record?.allow_ded.map((item) => item.id),
      taxes: record?.taxes.map((item) => item.id),
    };
    setInitials(payload);
    setAddModal((pre) => {
      return !pre;
    });
  };
  const handlePagination = (pageNum) => {
    if (pageNum?.current) fetchData({ page: pageNum?.current });
  };

  /* search function */
  const handleOnChange = debounce(async (e) => {
    const query = e?.target?.value;
    fetchData({ q: query });
  }, 700);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        onClick={toggleAddInfo}
        data={breadcrumbObjBasicInfo}
        btnText={'+ Payroll'}
        buttonId={elementData.basicInfo.permissions.create}
      />
      <TableComponent
        loading={loading}
        getRowClassName={rowCommonClass}
        columns={[
          ...payrollColumns,
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
              <Space size='middle'>
                <IconButton
                  title='Edit'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.basicInfo.permissions.update}
                  onClick={() => handleEditClick(record)}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Delete'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.basicInfo.permissions.delete}
                  onClick={() => {
                    setSelectedInfo(record);
                    toggleDeleteAllowance();
                  }}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton>
              </Space>
            ),
          },
        ]}
        dataSource={data}
        noDataAction={fetchData}
        pageSize={10}
        total={total_page}
        onChange={handlePagination}
        defaultPage={page}
      />
      {/* add basic info modal */}
      <CustomModal
        title={`${editInitials ? 'Edit' : 'Add'} Payroll Setup`}
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddInfo}
        footer={''}
      >
        <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 text-grey-80'>
          {editInitials
            ? 'Edit payroll setup details'
            : 'To add a new payroll setup, enter the details in the input field below.'}
        </span>

        <FormContainer
          accordionClass='grid grid-cols-2 gap-8'
          onSubmit={editInitials ? onEditInfo : saveBasicInfo}
          initialValues={editInitials || initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, values }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    toggleAddInfo();
                  }}
                />
                <Button
                  disabled={!isValid}
                  permissions={permissions}
                  id={
                    elementData.basicInfo.permissions.update ||
                    elementData.basicInfo.permissions.create
                  }
                  text={'Save'}
                  variant='primary'
                  classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </CustomModal>
      {/* delete info confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteAllowance}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteAllowance}
            />
            <Button
              text='Delete'
              variant='primary'
              classes={`ml-6 px-8`}
              onClick={onDeleteAllowance}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(BasicInformation);
