import $ from 'jquery';
import { useRef, useEffect, useState, useCallback } from 'react';
import { Input, Divider } from 'antd';

import { Button, IconButton } from 'components';
import { BackArrow } from 'assets/icons';

window.jQuery = $;
window.$ = $;

require('jquery-ui-sortable');
require('formBuilder');

var form;

const FormBuilder = ({
  onSave,
  onUpdate,
  loading,
  editedData,
  onClose,
  showHeaderButtons = true,
  showFooterButtons = false,
}) => {
  const [name, setName] = useState(editedData?.name);

  const fb = useRef();

  const save = useCallback(() => {
    const payload = {
      name,
      fields: JSON.parse(form.actions.getData('json')),
    };

    if (editedData) {
      onUpdate(payload);
    } else {
      onSave(payload);
    }
  }, [name]);

  const updateName = useCallback((evt) => {
    setName(evt.target.value);
  }, []);

  useEffect(() => {
    form = $(fb.current).formBuilder({ formData: editedData?.fields || [] });
  }, []);

  return (
    <div className='flex flex-col h-full'>
      <div className='flex justify-between'>
        <span className='text-m font-semibold'>{editedData ? 'Edit Form' : 'Create New Form'}</span>
        {showHeaderButtons ? (
          <div>
            <Button variant='secondary' disabled={loading} onClick={onClose} text='Cancel' />
            &nbsp;
            <Button disabled={loading} onClick={save} text={loading ? 'Saving...' : 'Save'} />
          </div>
        ) : (
          <IconButton onClick={() => onClose()}>
            <BackArrow svgClass='fill-grey-90 w-10 h-10' />
          </IconButton>
        )}
      </div>
      <hr className='mt-2 !mb-5' />
      <label>Form Name</label>
      <Input value={name} placeholder='Form Name' onChange={updateName} />
      <Divider className='mb-0' />

      <div className='overflow-auto flex-1'>
        <div className='mt-4'>
          <label className=''>Content</label>
          <div id='fb-editor' ref={fb} />
        </div>
      </div>
      <Divider />
      {showFooterButtons ? (
        <div className='flex justify-end space-x-3'>
          <Button variant='secondary' disabled={loading} onClick={onClose} text='Cancel' />
          &nbsp;
          <Button disabled={loading} onClick={save} text={loading ? 'Saving...' : 'Save'} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default FormBuilder;
