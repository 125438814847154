/** external deps */
import React, { useContext } from 'react';
/** internal deps */
import { Context } from 'store/context';
import { formatDate } from 'utils';
import { View } from 'components';

const MyProfile = () => {
  const { user } = useContext(Context);
  const renderDate = (key, value, index) => {
    return (
      <div key={index}>
        <span className='capitalize'>{key?.replaceAll('_', ' ')}</span>
        <span className={`block font-medium upperCase`}>
          {formatDate({
            dateString: value,
            toUtc: false,
            format: 'DD MMMM, YYYY, hh:mm A',
          })}
        </span>
      </div>
    );
  };

  return (
    <div className='bg-white flex flex-col p-4 rounded-lg h-96'>
      <div className='grid grid-cols-2 md:grid-cols-4 gap-y-6'>
        <View
          data={user}
          exclude={['image', 'branch', 'logo']}
          customRenders={{
            created: renderDate,
          }}
        />
      </div>
    </div>
  );
};

export default MyProfile;
