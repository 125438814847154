/** external deps */
import React from 'react';
import { useNavigate } from 'react-router-dom';
/** internal deps */
import { TableComponent, Button } from 'components';
import { User } from 'assets';
import { BackArrow } from 'assets/icons';
import { rowCommonClass } from 'utils/common';

const ProposalTable = (props) => {
  const { leads, inquiryLoading } = props;
  const navigate = useNavigate();
  const onError = (event) => {
    event.target.src = User;
  };

  const columns = [
    {
      key: 'lead_id',
      title: 'Id',
      dataIndex: 'lead_id',
    },
    {
      key: 'assigned_to',
      title: (
        <div className='w-max flex items-center gap-1'>
          Assigned To <BackArrow svgClass='-rotate-90 w-4 h-4' />
        </div>
      ),
      dataIndex: 'assigned_to',
      render: (data) => {
        return (
          <div className='flex items-center capitalize'>
            <img
              src={process.env.REACT_APP_DOC_URL + data?.image}
              onError={onError}
              className='h-6 w-6 mr-2 rounded-full'
              alt='name'
            />
            {data?.name}
          </div>
        );
      },
    },
    {
      title: 'Insurer',
      dataIndex: 's_insurer',
      key: 's_insurer',
      id: 's_insurer',
      render: (data) => data?.name || '\u2014',
    },
    {
      key: 'customer',
      title: 'Client Name',
      dataIndex: 'customer',
      render: (data) => (
        <span className='font-normal'>
          {data?.customer_type?.name === 'Corporation' ? data?.corp_name : data?.name || '\u2014'}
        </span>
      ),
    },
    {
      key: 's_product',
      title: 'Product',
      dataIndex: 's_product',
      render: (data, record) => data?.name || '\u2014',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
          text={record?.status === 'draft' ? 'Create Quotation' : 'View'}
          type='button'
          onClick={() => {
            handleView(record, _.id);
          }}
          variant='primary'
          classes={`!px-2 !py-1 border capitalize bg-white rounded-md !text-xxxs !font-medium border-black !text-black hover:border-primary-90 hover:!text-primary-90`}
        />
      ),
    },
  ];

  const handleView = (obj, id) => {
    obj?.status === 'draft' ? navigate(`/lead/edit/${id}/2`) : navigate(`/lead/edit/${id}/1`);
  };

  return (
    <div id='dashboard'>
      <TableComponent
        loading={inquiryLoading}
        dataSource={leads}
        columns={columns}
        showPagination={false}
        scroll={{ y: 170 }}
        getRowClassName={rowCommonClass}
      />
    </div>
  );
};

export default ProposalTable;
