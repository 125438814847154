import { SelectInput } from 'components';
import { documentOptions } from 'utils/options';
import { getLabelByValue } from 'utils';
import formOptions from 'components/forms/formOptions';

export const productColumns = [
  {
    title: 'Product Icon',
    dataIndex: 'logo',
    key: 'logo',
    render: (logo) => (
      <img src={process.env.REACT_APP_DOC_URL + logo} className='h-10 w-10' alt={logo} />
    ),
  },
  {
    title: 'Product Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Form Type',
    dataIndex: 'formid',
    key: 'formid',
    render: (text) => text?.name || getLabelByValue(formOptions, text) || '\u2014',
  },
];

export const breadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Insurance Category',
    path: '/insurance-category',
  },
  {
    name: 'View Insurance Category',
    path: '',
    target: '_blank',
  },
];

export const addConfigFields = {
  'Document Type Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Document',
        name: 'document',
        id: 'document',
        placeholder: 'Document',
        type: 'select',
        required: true,
        component: SelectInput,
        options: documentOptions,
        checkbox: true,
        isMulti: true,
        hideSelectedOptions: false,
        closeMenuOnSelect: false,
      },
    ],
  },
};

/**
 * @param {{
 * category: string;
 * code: string;
 * id: string;
 * name: string;
 * product_type: string;
 * status: string;
 * remarks: string;
 * jurisdiction:string;
 * liability_limit: string;
 * territorial_limit: string;
 * interest_insured: string;
 * indemnity_limit: string;
 * basic_coverage: string;
 * excess: string;
 * exclusion: string;
 * subject_to: string;
 * cover: string;
 * cover_eligibility: string;
 * geographical_area: string;
 * renewable_age: string;
 * headcount: string;
 * refund_formula: string;
 * benefits: string;
 * no_evidence: string;
 * precedent: string;
 * administration: string;
 * per_insured_person: string;
 * }} data
 * @returns
 */

export const formatViewData = (data) => {
  const res = {
    'Insurance Category Details': [],
    Description: [],
  };
  if (!data) return res;
  Object.entries(data).forEach(([key, val], i) => {
    switch (key) {
      case 'code':
      case 'name':
        res['Insurance Category Details'].push({
          label: key,
          value: val,
          name: key.replaceAll('_', ' '),
        });
        break;
      case 'image':
        res['Description'].push({
          label: key,
          value: val,
          name: key.replaceAll('_', ' '),
          // className: 'col-span-full',
        });
        break;
      case 'insurers':
        res['Description'].push({
          label: key,
          value: Array.isArray(val) ? val.map((item) => item.name).join(', ') : '\u2014',
          name: key.replaceAll('_', ' '),
          className: 'col-span-4',
        });
        break;
      default:
        break;
    }
  });
  return res;
};
