import React, { useState } from 'react';
import { ContentHeader, DateTime, SelectInput } from 'components';
import dayjs from 'dayjs';
import PerformaceCalulator from './PerformaceCalulator';
import PerformanceChart from './PerformanceChart';
import PerformanceTable from './PerformanceTable';
import { getMyAttendance, searchApis } from 'service/api-service';

const Performace = ({ permissions = {} }) => {
  const [data, setData] = useState();
  const [employee, setEmployee] = useState();

  const fetchData = async ({ date, id }) => {
    const start = date.startOf('month').format('YYYY-MM-DD');
    const end = date.endOf('month').format('YYYY-MM-DD');
    try {
      const res = await getMyAttendance({ start, end, id });
      if (res?.data?.data) {
        setData(res?.data?.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getEmployeeData = async (_, employee) => {
    setEmployee(employee);
    const date = dayjs();
    fetchData({ date, id: employee?.id });
  };

  const handleOnDateChange = async ({ val }) => {
    const date = dayjs(val);
    fetchData({ date, id: employee?.id });
  };

  return (
    <div className='flex flex-col gap-2 relative'>
      <ContentHeader
        title={<p className='text-s font-semibold'>View Performance</p>}
        showSearch={false}
      >
        <SelectInput
          type='select'
          placeholder={'Search Employee'}
          field={{ name: 'employee' }}
          divClasses='w-64'
          fetchOptions={() => searchApis({ api: 'employee' })}
          customOnChange={getEmployeeData}
        />

        <DateTime
          defaultValue={dayjs()}
          value={dayjs()}
          placeholder='Select Date'
          customOnChange={handleOnDateChange}
          picker='month'
        />
      </ContentHeader>

      {!employee && (
        <div className='flex h-40 opacity-30 w-full items-center justify-center'>
          <p className='text-xl'>Please Select Employee</p>
        </div>
      )}
      {employee && (
        <div className='bg-grey-light rounded-lg grid  grid-cols-4'>
          <div className='col-span-3'>
            <div className='mt-4 bg-white '>
              <PerformaceCalulator data={data} employee={employee} />
            </div>
            <div className='mt-4 bg-white '>
              <PerformanceTable data={data} />
            </div>
          </div>
          <div className='bg-white ml-4 mt-4 '>
            <PerformanceChart data={data?.data || []} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Performace;
