import { Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import React, { useEffect, useState } from 'react';
import { upComingEvents } from 'service/api-service';
import { Spin } from 'antd';
import dayjs from 'dayjs';

const EmployeeNameAndStatus = (props) => {
  const { eventName, date } = props;
  return (
    <div className='flex justify-between mt-4'>
      <div className='text-xxs font-semibold'>{eventName}</div>
      <div className='text-xxs font-m'>{date}</div>
    </div>
  );
};

// const employeeData = [
//   {
//     eventName: 'Event 1',
//     date: '10-1-2021',
//   },
//   {
//     eventName: 'Event 2',
//     date: '10-4-2021',
//   },
//   {
//     eventName: 'Event 3',
//     date: '10-6-2022',
//   },
//   {
//     eventName: 'Event 4',
//     date: '12-4-2023',
//   },
// ];

const UpcomingEvents = () => {
  const [loading, setLoading] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState();
  const currentDate = dayjs().format('YYYY-MM-DD');

  const onGetTarget = async () => {
    setLoading(true);
    const resp = await upComingEvents({
      date: currentDate,
    });
    if (resp?.data?.status) {
      setUpcomingEvents(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    onGetTarget();
  }, []);

  const upcomingEventSection =
    Array.isArray(upcomingEvents) && upcomingEvents.length && upcomingEvents.slice(0, 3);
  return (
    <div className='  border-2 border-grey-70 rounded-lg bg-white flex flex-col p-2'>
      <div>
        <p className='font-medium text-sm'>Upcoming Events</p>
      </div>
      <div className='flex justify-between mt-4 mb-3'>
        <div className='text-xxs font-medium text-grey-90'>Event Name</div>
        <div className='text-xxs font-medium text-grey-90'>Date</div>
      </div>

      {loading ? (
        <Spin size='large' />
      ) : (
        Array.isArray(upcomingEventSection) &&
        upcomingEventSection.length &&
        upcomingEventSection.map((ele, i) => {
          return (
            <div key={i}>
              <EmployeeNameAndStatus eventName={ele?.label} date={ele?.start} />
            </div>
          );
        })
      )}
    </div>
  );
};

export default UpcomingEvents;
