import { TextInput, SelectInput } from 'components';
import { searchApis, searchInsuranceApis } from 'service/api-service';

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Commission',
    path: '',
  },
];

const tabsdata = [
  {
    label: 'commission',
    value: 'commission',
  },
  {
    label: 'POS Agent',
    value: 'pos_agent',
  },
];
const InsurerFields = ({ setOptions, options }) => ({
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Insurance Category',
        name: 'insurance_category',
        id: 'category',
        placeholder: 'Category',
        type: 'select',
        required: true,
        component: SelectInput,
        fetchOptions: async () => searchApis({ api: 'insurance_category' }),
      },
      {
        label: 'Product Type',
        name: 'product_type',
        id: 'product_type',
        placeholder: '  Product Type',
        type: 'select',
        // required: true,
        component: SelectInput,
        options: [],
        dependency: ['insurance_category'],
        dependantFetchOptions: async (form) => {
          const id = form?.values.insurance_category;
          const resp = await searchInsuranceApis({ api: 'insurance_category', id });
          return resp?.data?.data[0]?.product_type?.map((i) => ({
            name: i.name,
            label: i.name,
            value: i.id,
            id: i.id,
          }));
        },
      },
      {
        label: 'Commission Type',
        name: 'comm_type',
        id: 'status',
        placeholder: 'Commission Type',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { value: 'fixed', label: 'Fixed' },
          { value: 'percentage', label: 'Percentage' },
        ],
      },
      {
        label: 'Commission Value',
        name: 'comm_value',
        id: 'value',
        placeholder: 'Commission Value',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
    ],
  },
});

const cardFields = {
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Commission Type',
        name: 'comm_type',
        id: 'status',
        placeholder: 'Commission Type',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { value: 'fixed', label: 'Fixed' },
          { value: 'percentage', label: 'Percentage' },
        ],
      },
      {
        label: 'Commission Value',
        name: 'comm_value',
        id: 'value',
        placeholder: 'Commission Value',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
    ],
  },
};

const commissionCols = [
  {
    key: 'insurance_category',
    title: 'Insurance Category',
    dataIndex: 'insurance_category',
    render: (data) => data?.name || '\u2014',
  },
  {
    key: 'product_type',
    title: 'Product Type',
    dataIndex: 'product_type',
    render: (data) => data?.name || '\u2014',
  },
  {
    key: 'comm_type',
    title: 'Type',
    dataIndex: 'comm_type',
  },
  {
    key: 'comm_value',
    title: 'Value',
    dataIndex: 'comm_value',
  },
];

export { InsurerFields, cardFields, breadcrumbObj, tabsdata, commissionCols };
