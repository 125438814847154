/** external deps */
import React, { useEffect, useState } from 'react';
import { DatePicker, Popover } from 'antd';
import dayjs from 'dayjs';

/** internal deps */
import { ContentHeader } from 'components';
import { breadcrumbObj } from './data';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import AddHolidayModal from './AddHolidayModal';
import { getHoliday } from 'service/api-service';

const months = dayjs.months();

const DetailCard = ({ note }) => (
  <div>
    <p>{note}</p>
  </div>
);

const Holidays = (props = {}) => {
  const permissions = props?.permissions || [];

  const [showAddModal, setAddModal] = useState(false);
  const [holidaysData, setHolidaysData] = useState(
    months.map((month) => ({ name: month, events: [] })),
  );

  // first time year data
  const fetchYearData = async (year) => {
    const getdate = new Date();
    const currentDate = year ? year : dayjs(getdate).$y;
    const resp = await getHoliday({
      year: currentDate.toString(),
    });
    const data = resp?.data?.data;

    let filterData = months.map((month, index) => {
      const getEvents = data?.filter((item) => +dayjs(item.start).format('M') === index + 1) || [];
      return { name: month, events: getEvents };
    });
    setHolidaysData(filterData);
  };
  useEffect(() => {
    fetchYearData();
  }, []);

  const toggleAddHoliday = () => {
    setAddModal((pre) => !pre);
  };

  const handleOnYearChange = async (e) => fetchYearData(e.$y);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        showSearch={false}
        // data={breadcrumbObj}
        title={<p className='font-semibold text-s'>Holidays</p>}
        btnText='+ Holiday'
        buttonId={elementData?.holidays_calender?.permissions?.create}
        onClick={toggleAddHoliday}
      >
        <div>
          <DatePicker
            label='select year'
            defaultValue={dayjs()}
            picker='year'
            onChange={handleOnYearChange}
          />
        </div>
      </ContentHeader>

      <div className='grid grid-cols-3 gap-4'>
        {holidaysData.map((data, index) => {
          return (
            <div key={index} className='bg-white p-4 flex flex-col h-full overflow-hidden'>
              <h4 className='font-semibold'>{data.name}</h4>
              <div className='mt-2 flex flex-col gap-1 h-full flex-1'>
                {!!data.events.length &&
                  data.events.map(({ id, label, start, end, notes }) => {
                    let date = dayjs(start).format('Do');
                    if (start !== end) {
                      let endDate = dayjs(end).format('Do');
                      date = `${date} to ${endDate}`;
                    }
                    return (
                      <Popover
                        key={id}
                        content={<DetailCard note={notes} />}
                        title='Note'
                        trigger='hover'
                      >
                        <div className='py-2 px-4 cursor-pointer text-xxs flex justify-between items-center bg-primary-70'>
                          <div>{date}</div>
                          <p className='font-semibold'>{label}</p>
                        </div>
                      </Popover>
                    );
                  })}
                {!data.events.length && (
                  <div className='text-center opacity-30 flex-1 text-xxs p-4 h-full grid place-content-center'>
                    <p>No Events</p>{' '}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* add holidays modal */}
      <AddHolidayModal
        open={showAddModal}
        onCancel={toggleAddHoliday}
        fetchYearData={fetchYearData}
        permissions={permissions}
      />

      {/* delete holidays confirm modal DeleteHolidayModal import  */}
    </div>
  );
};

export default SecureComponent(Holidays);
