import { Link } from 'react-router-dom';
import { renewalPolicyManagement } from 'service/api-service';
import { Context } from 'store/context';
import { rowCommonClass } from 'utils/common';

const { TableComponent } = require('components');
const { useState, useEffect, useContext } = require('react');

const PolicyRenewals = ({ Permissions }) => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const resp = await renewalPolicyManagement({
      method: 'get',
      employeeID: user?.user_type == 'client' ? '' : user?.id,
    });
    if (resp?.data) {
      setData(resp?.data?.data?.result);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: 'Policy No.',
      dataIndex: 'p_number',
      key: 'p_number',
    },
    {
      title: 'Product',
      dataIndex: 'product_type',
      key: 'product',
      render: (data) => data?.name || '\u2014',
    },
    {
      title: <p className='max-w-full truncate'>Customer Name</p>,
      dataIndex: 'customer',
      key: 'customer',
      render: (data) => <p>{data?.name || data?.corp_name || '\u2014'}</p>,
    },
    // {
    //   title: 'Insurer',
    //   dataIndex: 'insurer',
    //   key: 'insurer',
    //   render: (data) => data?.name || '\u2014',
    // },
    {
      title: 'Expiry Date',
      dataIndex: 'exp_date',
      key: 'exp_date',
      // render: (data) => data?.exp_date || '--',
    },
    // {
    //   title: 'Renewal Date',
    //   dataIndex: 'renewal',
    //   key: 'renewal',
    //   render: (data) => dayjs(data).fromNow(),
    //   sorter: (a, b) => dayjs(a.renewal) - dayjs(b.renewal),
    // },
    // {
    //   title: 'Premium',
    //   dataIndex: 'premium',
    //   key: 'premium',
    //   render: (data) => data?.toFixed(2) || '\u2014',
    // },
    // {
    //   title: 'Sum Insured',
    //   dataIndex: 'sum_insured',
    //   key: 'sum_insured',
    //   render: (data) => data?.toFixed(2) || '\u2014',
    // },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (data) => {
        if (data === '12') return 'Year';
        else if (data === '24') return 'Bi-annual';
        else if (data === '36') return 'Tri-annual';
        else return '\u2014';
      },
    },
    {
      title: 'Sell By',
      dataIndex: 'sell_by',
      key: 'sell_by',
      render: (type, data) =>
        data?.sell_obj?.name && type ? data?.sell_obj?.name + '(' + type + ')' : '\u2014',
      displayFilter: ['renewable', 'all'],
    },
  ];

  return (
    <div className='flex flex-col gap-3 justify-between pb-2 mt-1 mb-2'>
      <div className='flex flex-row justify-between items-start'>
        <div className='font-semibold leading-6 text-s'>Policy Renewals</div>
        <div className='text-[#0780BE] px-2 py-2 text-xxs'>
          <Link to='/policy?tab=renewable'>View All</Link>
        </div>
      </div>
      <div className='customTable' id='dashboard'>
        <TableComponent
          loading={loading}
          columns={columns}
          dataSource={data}
          showPagination={false}
          scroll={{ y: 175 }}
          getRowClassName={rowCommonClass}
        />
      </div>
    </div>
  );
};

export default PolicyRenewals;
