import { countries } from 'data/form-data';

import {
  TextInput,
  Checkbox,
  PhoneNumberInput,
  SelectInput,
  SearchAPI,
  Uploadbox,
  MultiSelect,
} from 'components';
import { getInsuranceCategory } from 'service/api-service';

const addInsurerFields = {
  'Insurer Details': {
    fields: [
      {
        label: 'Insurer Name',
        name: 'name',
        id: 'name',
        placeholder: 'Insurer Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 50,
      },
      {
        label: 'Primary Email',
        name: 'email',
        id: 'email',
        placeholder: 'Primary Email',
        type: 'email',
        required: true,
        component: TextInput,
        maxLength: 80,
      },
      {
        label: 'Insurer Active',
        name: 'active',
        id: 'active',
        placeholder: 'Insurer Active',
        type: 'checkbox',
        component: Checkbox,
        divClasses: 'self-end',
      },
      {
        label: 'Primary Phone',
        name: 'phone',
        id: 'phone',
        placeholder: 'Primary Phone',
        type: 'number',
        maxLength: 10,
        component: TextInput,
        checked: true,
        divClasses: 'self-end',
      },
      {
        label: 'Insurer Code',
        name: 'code',
        id: 'code',
        placeholder: 'Insurer Code',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
      {
        label: 'Website',
        name: 'website',
        id: 'website',
        placeholder: 'Website',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },

      {
        label: 'Fax',
        name: 'fax',
        id: 'fax',
        placeholder: 'Fax',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
      {
        label: 'GST Reg No',
        name: 'gst',
        id: 'gst',
        placeholder: 'GST Reg No',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
      {
        label: 'Company Registration No',
        name: 'company_no',
        id: 'company_no',
        placeholder: 'Company Regist No',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
      // {
      //   label: "GL Account",
      //   name: "GL_acct",
      //   id: "GL_acct",
      //   placeholder: "GL Account",
      //   type: "select",
      //   maxLength: 80,
      //   component: SelectInput,
      //   options: [
      //     { value: "300_S_S", label: "300 Sales-Software" },
      //     { value: "301_S_H", label: "301-Sales-Hardware" },
      //     { value: "305_S_O", label: "305-Sales-Order" },
      //     { value: "302_S_R", label: "302-Sales-Rental-Income" },
      //     { value: "306_I_S", label: "306-Interest-Sales" },
      //     { value: "302_R_I", label: "302-Rental-Income" },
      //     { value: "303_I_I", label: "303-Interest-Income" },
      //     { value: "304_S_S", label: "304-Sales-Software-Support" },
      //     { value: "305_S_OT", label: "305-Sales Other" },
      //     { value: "306_IN_S", label: "306-Internet-Sales" },
      //     { value: "307_S_L", label: "307-Service-Hardware-Labor" },
      //     { value: "308_S_B", label: "308-Sales-Books" },
      //   ],
      // },
      // {
      //   label: "Attn",
      //   name: "attn",
      //   id: "attn",
      //   placeholder: "Attn",
      //   type: "search",
      //   disabled: true,
      //   component: SearchAPI,
      // },
      {
        label: 'Assigned To',
        name: 'assign_employee',
        id: 'assign_employee',
        placeholder: 'Assigned To',
        type: 'search',
        required: true,
        component: SearchAPI,
      },
      {
        label: 'Category of Insurance',
        menuPlacement: 'bottom',
        name: 'category',
        id: 'category',
        placeholder: 'Category of Insurance',
        type: 'select',
        component: MultiSelect,
        required: true,
        fetchOptions: getInsuranceCategory,
      },
      {
        label: 'Commision %',
        name: 'comm_per',
        id: 'comm_per',
        placeholder: 'Commision %',
        type: 'number',
        component: TextInput,
        rightIcon: '%',
        maxLength: 30,
      },
      {
        label: 'Ins Debtor (B/S)',
        name: 'debtor',
        id: 'debtor',
        placeholder: 'Ins Debtor (B/S)',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
      {
        label: 'Currency',
        name: 'currency',
        id: 'currency',
        placeholder: 'Currency',
        type: 'select',
        component: SelectInput,
        options: [
          { value: 'SGD', label: 'Singapore, Dollars' },
          { value: 'USA', label: 'USA, Dollars' },
          { value: 'MYR', label: 'Malaysia, Ringgits' },
        ],
      },
      {
        label: 'GST A/C Firm Commision (B/S)',
        name: 'gst_comm',
        id: 'gst_comm',
        placeholder: 'GST A/C Firm Commision (B/S)',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
      {
        label: 'Ins Creditor (B/S)',
        name: 'creditor',
        id: 'creditor',
        placeholder: 'Ins Creditor (B/S)',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
      {
        label: 'Firm Commision income (P/I)',
        name: 'firm_comm',
        id: 'firm_comm',
        placeholder: 'Firm Commision income (P/I)',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
      {
        label: 'G Premium Receivable (P/I)',
        name: 'premium',
        id: 'premium',
        placeholder: 'G Premium Receivable (P/I)',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
      {
        label: 'Accrued Commission to Agent',
        name: 'accrued',
        id: 'accrued',
        placeholder: 'Accrued Commission to Agent',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
      {
        label: 'Commission Paid to Agent',
        name: 'comm_paid',
        id: 'comm_paid',
        placeholder: 'Commission Paid to Agent',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
      {
        label: 'Daily Transmission Code',
        name: 'transmission',
        id: 'transmission  ',
        placeholder: 'Daily Transmission Code',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
      {
        label: 'A/C of Discount to Client',
        name: 'discount',
        id: 'discount  ',
        placeholder: 'A/C of Discount to Client',
        type: 'text',
        maxLength: 80,
        component: TextInput,
      },
    ],
  },
  'Address Details': {
    fields: [
      {
        label: 'Registered Address',
        name: 'address',
        id: 'address',
        placeholder: 'Registered Address',
        type: 'text',
        isMultiline: true,
        rows: 2,
        component: TextInput,
        maxLength: 400,
        divClasses: 'col-span-full',
      },
      {
        label: 'PO Box',
        name: 'box',
        id: 'box',
        placeholder: 'PO Box',
        type: 'text',
        component: TextInput,
        maxLength: 80,
      },
      {
        label: 'City',
        name: 'city',
        id: 'city',
        placeholder: 'City',
        type: 'text',
        component: TextInput,
        maxLength: 80,
      },
      {
        label: 'State',
        name: 'state',
        id: 'state',
        placeholder: 'State',
        type: 'text',
        component: TextInput,
        maxLength: 80,
      },
      {
        label: 'Postal Code',
        name: 'pincode',
        id: 'pincode',
        placeholder: 'Postal Code',
        type: 'text',
        component: TextInput,
        maxLength: 40,
      },
      {
        label: 'Country',
        name: 'country',
        id: 'country',
        placeholder: 'Country',
        type: 'select',
        component: SelectInput,
        options: countries,
      },
      {
        label: 'Country Code',
        name: 'country_code',
        id: 'country_code',
        placeholder: 'Country Code',
        type: 'text',
        component: TextInput,
      },
    ],
  },
  'Portal User Infomation': {
    fields: [
      {
        label: 'Mobile User',
        name: 'user',
        id: 'user',
        placeholder: 'Mobile User',
        type: 'checkbox',
        component: Checkbox,
        divClasses: 'self-end',
      },
      {
        label: 'Email Verify',
        name: 'email_verify',
        id: 'email_verify',
        placeholder: 'Email Verify',
        type: 'checkbox',
        component: Checkbox,
        divClasses: 'self-end',
      },
      {
        label: 'Is Active',
        name: 'is_active',
        id: 'is_active',
        placeholder: 'Is Active',
        type: 'checkbox',
        component: Checkbox,
        divClasses: 'self-end',
      },
      {
        label: 'Username',
        name: 'user_name',
        id: 'user_name',
        placeholder: 'Username',
        type: 'text',
        component: TextInput,
        maxLength: 80,
      },
    ],
  },
  Description: {
    fields: [
      {
        label: 'Description',
        name: 'description',
        id: 'description',
        placeholder: 'Description',
        type: 'text',
        isMultiline: true,
        rows: 2,
        component: TextInput,
        maxLength: 400,
        divClasses: 'col-span-full',
      },
      {
        label: 'Insurer Image',
        name: 'image',
        id: 'image',
        type: 'file',
        component: Uploadbox,
      },
    ],
  },
  'Important Links': {
    fields: [
      {
        label: 'Illustration Link',
        name: 'illustration_link',
        id: 'illustration_link',
        placeholder: 'Illustration Link',
        type: 'text',
        isMultiline: true,
        component: TextInput,
        // divClasses: "col-span-full",
      },
      {
        label: 'Payment Link',
        name: 'payment_link',
        id: 'payment_link',
        placeholder: 'Payment Link',
        type: 'text',
        isMultiline: true,
        component: TextInput,
        // divClasses: "col-span-full",
      },
    ],
  },
};

const addInsurerBreadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Insurer',
    path: '/insurer',
  },
  {
    name: 'Add Insurer',
    path: '',
  },
];

const editInsurerBreadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Insurer',
    path: '/insurer',
  },
  {
    name: 'Edit Insurer',
    path: '',
    target: '_blank',
  },
];

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Insurer',
    path: '/insurer',
  },
];

export const columns = [
  {
    title: 'Isurer',
    key: 'image',
    render: (data) => {
      return (
        <div className='flex items-center space-x-2'>
          <div>
            <img
              src={process.env.REACT_APP_DOC_URL + data?.image}
              className='h-6 w-6 mr-2'
              alt='name'
            />
          </div>
          <div>{data?.name}</div>
        </div>
      );
    },
  },
  {
    key: 'code',
    title: 'Code',
    dataIndex: 'code',
    render: (text) => text || '\u2014',
  },
  {
    key: 'phone',
    title: 'Phone',
    dataIndex: 'phone',
    render: (text) => text || '\u2014',
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    render: (text) => text || '\u2014',
  },
  {
    key: 'address',
    title: 'Address',
    dataIndex: 'address',
    render: (text) => text || '\u2014',
  },
];

export { addInsurerFields, addInsurerBreadCrumbs, editInsurerBreadCrumbs, breadcrumbObj };
