/** external deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
/** internal deps */
import { Permissions, Toast, ContentHeader } from 'components';
import { getEmployeePermissions, updateEmployeePermissions } from 'service/api-service';
import { SecureComponent } from 'permission-provider';
import { breadcrumbObj } from './data';
import { NOTIFICATION_MSG } from 'constants';

const EmployeePermissions = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataToUpdate, setDataToUpdate] = useState([]); // will be used to send patch request to backend if any data is updated.
  const { id } = useParams();

  const getPermissions = async () => {
    setLoading(true);
    const resp = await getEmployeePermissions({ id });
    if (resp?.data?.status) {
      const rawData = resp.data.data;
      setData(rawData);
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Failed to get Permissions from server',
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (dataToUpdate) => {
    setLoading(true);
    const resp = await updateEmployeePermissions({ id, data: { data: dataToUpdate } });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
    setDataToUpdate([]);
  };

  return (
    <div className='grid gap-3'>
      <ContentHeader data={breadcrumbObj} showSearch={false} />
      <Permissions
        getPermissions={getPermissions}
        handleSubmit={handleSubmit}
        loading={loading}
        data={data}
        dataToUpdate={dataToUpdate}
      />
    </div>
  );
};

export default SecureComponent(EmployeePermissions);
