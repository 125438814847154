/** external deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space } from 'antd';
import Fuse from 'fuse.js';

/** internal deps */
import { DELETE, EDIT, Trash, View } from 'assets/icons';
import { ContentHeader, CustomModal, IconButton, TableComponent, Toast, Button } from 'components';
import { elementData } from 'elements';
import { SecureComponent } from 'permission-provider';
import { deleteInsuranceCategory, getInsuranceCategory } from 'service/api-service';
import { breadcrumbObj, columns } from './data';
import { NOTIFICATION_MSG } from 'constants';
import './style.css';
import { rowCommonClass } from 'utils/common';

const InsuranceCategory = (props = {}) => {
  const navigate = useNavigate();
  const permissions = props?.permissions;
  /* states */
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [classes, setClasses] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [filteredData, setFilteredData] = useState([]);

  /** funcs */
  const onGetCategories = async () => {
    setLoading(true);
    const resp = await getInsuranceCategory();
    if (resp?.data?.status) {
      setClasses(resp?.data?.data);
    }
    setLoading(false);
  };

  const onDeleteClass = async () => {
    const id = selectedRow?.id;
    if (!id) return;
    const resp = await deleteInsuranceCategory({ id, data: { deleted: true } });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Insurance Category Deleted Successfully');
      toggleDeleteModal();
      onGetCategories();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  const onEditClick = (record) => {
    const finalState = { ...record, insurance_type: record?.insurance_type?.name };
    navigate('/edit-category', { state: { initialValues: finalState } });
  };

  const toggleDeleteModal = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) {
      return setSearchQuery('');
    }
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(classes, {
      keys: ['name', 'code', 'product_type.name'],
      threshold: 0.2,
    }).search(e.target.value);
    setFilteredData(fuse.map((i) => i.item));
  };

  useEffect(() => {
    onGetCategories();
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        showSearch={true}
        // data={breadcrumbObj}
        title={<p className='font-semibold text-s'>Insurance Category</p>}
        btnText='+ Insurance Category'
        onClick={() => navigate('/add-category')}
        permissions={permissions}
        buttonId={elementData.insurance_category.permissions.create}
        onChange={handleOnChange}
      />
      <TableComponent
        loading={loading}
        noDataAction={onGetCategories}
        getRowClassName={rowCommonClass}
        columns={[
          ...columns,
          {
            title: <p className='mr-7'>Action</p>,
            key: 'action',
            align: 'right',
            render: (_, record) => (
              <div className='flex gap-2 justify-end'>
                <IconButton
                  title='View'
                  permissions={permissions}
                  id={elementData.insurance_category.permissions.view}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    navigate(`/insurance-category/view/${record.id}`, {
                      state: { initialValues: record },
                    });
                  }}
                >
                  <View svgClass='stroke-primary group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Edit'
                  permissions={permissions}
                  id={elementData.insurance_category.permissions.update}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => onEditClick(record)}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Delete'
                  permissions={permissions}
                  id={elementData.insurance_category.permissions.delete}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    setSelectedRow(record);
                    toggleDeleteModal();
                  }}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton>
              </div>
            ),
          },
        ]}
        total={classes.length}
        pageSize={10}
        dataSource={searchQuery ? filteredData : classes}
      />

      {/* delete class confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the class details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              permissions={permissions}
              id={elementData.insurance_category.permissions.update}
              text='Delete Category'
              variant='primary'
              classes={`ml-6 px-8 bg-black`}
              onClick={onDeleteClass}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(InsuranceCategory);
