import React, { useState, useEffect } from 'react';
import { Space } from 'antd';
import { DELETE, EDIT, Trash } from 'assets/icons';
import { CustomModal, FormContainer, Button, TableComponent, Toast, IconButton } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { deleteProject, getProject, addProject, editProject } from 'service/api-service';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { addProjectFields, projectColumns } from './data';
import { rowCommonClass } from 'utils/common';

const Project = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [editInitials, setInitials] = useState();
  const [initialValues, formFields] = prepareFormFieldsData({ fields: addProjectFields });

  /* funcs  */
  const toggleAddModal = () => {
    setInitials(undefined);
    setShowAddModal((pre) => !pre);
  };
  const toggleDeleteModal = () => setDeleteModal((pre) => !pre);

  /* get project table list */
  const getProjectList = async () => {
    setLoading(true);
    const resp = await getProject();
    if (resp?.data?.status) {
      setTableData(resp?.data?.data);
    }
    setLoading(false);
  };

  /*   add project */
  const onAddProject = async (data) => {
    setLoading(true);
    const resp = await addProject({ data });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      getProjectList();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
    setShowAddModal(false);
  };

  /*   update project */
  const onEditProject = async (data) => {
    setLoading(true);
    const editFields = getEditedFields({
      initialValues: editInitials,
      updatedData: data,
    });
    if (Object.keys(editFields).length) {
      const resp = await editProject({
        id: selectedRow?.id,
        data: editFields,
      });
      if (resp?.data?.status) {
        getProjectList();
        toggleAddModal();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
    } else {
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save');
    }
    setLoading(false);
  };

  /* Delete project */
  const handleDeleteProject = async () => {
    setLoading(true);
    const id = selectedRow?.id;
    if (!id) return;
    const resp = await deleteProject({ id, data: { deleted: true } });
    if (resp?.data?.status) {
      getProjectList();
      Toast('success', NOTIFICATION_MSG.success, 'Project deleted successfully');
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteModal();
    setLoading(false);
  };

  /*   edit record */
  const handleEditClick = (record) => {
    const finalInitials = {
      title: record?.title,
      description: record?.description,
      designation: record?.designation,
      date: record?.date,
      url: record?.url,
    };
    setInitials(finalInitials);
    setSelectedRow(record);
    setShowAddModal(true);
  };

  useEffect(() => {
    getProjectList();
  }, []);

  return (
    <main>
      <div className='bg-white rounded-2xl rounded-b-none p-4 flex justify-between items-center'>
        <h2 className='font-semibold text-l'>Project</h2>
        <div className='flex gap-4'>
          <Button text='+ Project' variant='primary' classes='xl:px-6' onClick={toggleAddModal} />
        </div>
      </div>
      <TableComponent
        getRowClassName={rowCommonClass}
        loading={loading}
        columns={[
          ...projectColumns,
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            fixed: 'right',
            render: (_, record) => (
              <Space size='middle'>
                <IconButton
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    handleEditClick(record);
                  }}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    setSelectedRow(record);
                    toggleDeleteModal();
                  }}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton>
              </Space>
            ),
          },
        ]}
        dataSource={tableData}
        showPagination={false}
      />

      {/* add Project modal */}
      <CustomModal
        title={editInitials ? 'Edit Project' : 'Add Project'}
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddModal}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 text-grey-80 '>
            {editInitials
              ? 'Edit Project'
              : 'To add project, enter the details in the input fields below.'}
          </span>
          <FormContainer
            accordionClass='grid grid-cols-2 gap-8'
            onSubmit={editInitials ? onEditProject : onAddProject}
            initialValues={editInitials || initialValues}
            fields={formFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddModal();
                    }}
                  />
                  <Button
                    text={editInitials ? 'Update Project' : 'Add Project'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting || !isValid ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                    disabled={!isValid}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* Delete Modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]'>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the Project details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              text='Delete Project'
              variant='primary'
              classes={`ml-6 px-8 bg-black`}
              onClick={handleDeleteProject}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </main>
  );
};

export default Project;
