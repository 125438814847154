import React, { useState } from 'react';
import { useLeads } from '../store';
import dayjs from 'dayjs';
import { inquiryManagement, loginInquiry, submitIcicPatner } from 'service/api-service';
import Button from 'components/button';
import { CustomModal, FormContainer, Toast } from 'components';
import { prepareFormFieldsData } from 'utils';
import { formatPartnerPayload, PartnerFields } from './QuoteData';
import { NOTIFICATION_MSG } from 'constants';
import { useShallow } from 'zustand/react/shallow';

const OnlineQuoteCard = () => {
  const quotationsData = useLeads((state) => state.quotations.data);
  const { isOnlineProduct, leadId, client } = useLeads(
    useShallow((state) => ({
      isOnlineProduct: state.isOnlineProduct,
      leadId: state.leadId,
      client: state.createLead?.client,
    })),
  );
  const [eligibleModal, setEligibleModal] = useState(false);
  const [successsModal, setSuccesModal] = useState(false);
  const [relationWithLa, setRelationWithLa] = useState('Self');
  const [maritalStatus, setMaritlStatus] = useState('697');
  const [mwpaOpted, setMwpaOpted] = useState('No');
  const [respData, setRespData] = useState('');
  const [loading, setLoading] = useState(false);
  const maxDate = dayjs().subtract(18, 'years');

  const quote = quotationsData?.insurance_details[0];
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: PartnerFields({
      maxDate,
      setRelationWithLa,
      setMaritlStatus,
      relationWithLa,
      setMwpaOpted,
    }),
    // fieldsToExclude: [...lifeAssuredFields(relationWithLa === "Self"), ...trusteeFields(maritalStatus === "697" && mwpaOpted === "No" )],
    defaultValues: {
      prosfrstNm: quote?.first_name,
      proslstNm: quote?.last_name,
      prosdob: dayjs(quote?.dob),
      prosgender: quote?.gender,
      prosmobNo: client?.contact_number,
      prosemail: client?.email_address,
      staff: quote?.staff,
      payfname: quote?.first_name,
      paylname: quote?.last_name,
      paygender: quote?.gender,
      trusteedob: dayjs(quote?.dob),
      nomdob: dayjs(quote?.dob),
      paydob_str: dayjs(quote?.dob),
      bendob: dayjs(quote?.dob),
      paysa: quote?.PremiumSummary?.DeathBenefit,
    },
  });

  const handleSubmit = async (data) => {
    setLoading(true);
    const payload = formatPartnerPayload(data, quotationsData?.insurance_details[0]);
    const resp = await loginInquiry();
    if (resp?.status) {
      const token = resp?.data?.token;
      const resp2 = await submitIcicPatner({ data: payload, token });
      if (resp?.status) {
        if (!resp2?.data?.data?.transID) {
          const errors = resp2?.data?.data?.responseRemarks.replaceAll('.', ` , `);
          Toast('info', NOTIFICATION_MSG.info, errors || 'Please try again');
        } else {
          const partnerDetails = {
            ...payload,
            partnerDetails: resp2?.data?.data,
          };
          const quoteResp = await inquiryManagement({
            data: {
              partner_details: partnerDetails,
              status: 'proposals',
              products: quotationsData?.products?.map((item) => ({
                id: item?.id,
                s_insurer: item?.insurer[0]?.id,
              })),
            },
            id: leadId,
            method: 'patch',
          });

          if (quoteResp?.status) {
            Toast('success', NOTIFICATION_MSG.success, 'Proposed successfully!');
          } else {
            Toast('error', NOTIFICATION_MSG.error, quoteResp?.error || 'Something went wrong!');
          }
          setRespData(resp2?.data?.data);
          setSuccesModal(true);
        }
      } else {
        console.log('error', resp);
      }
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }

    setLoading(false);
  };
  const handleCopyClick = () => {
    const textToCopy = respData?.URL;
    // Create a temporary textarea to copy the text
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    // Copy the text from the textarea
    textarea.select();
    document.execCommand('copy');

    // Clean up and remove the temporary textarea
    document.body.removeChild(textarea);
    // Optionally, you can show a notification or feedback to the user
    Toast('success', NOTIFICATION_MSG.success, 'Payment link copied to clipboard!');
  };

  const AgreeToQuote = async () => {
    setLoading(true);

    const quoteData = {
      products: quotationsData?.products?.map((item) => ({
        id: item?.id,
        insurer: item?.insurer?.map((ele) => ele?.id),
      })),
      remove_products: [],
      status: 'quoted',
      quote: quotationsData?.products?.map((item) => ({
        cover: [],
        id: item?.insurer[0]?.id,
        insurer: item?.insurer[0]?.name,
        ncb: '',
        premium: quotationsData?.insurance_details[0]?.PremiumSummary?.AnnualPremium,
        product: item?.product_type,
      })),
    };
    const resp = await inquiryManagement({ data: quoteData, id: leadId, method: 'patch' });
    if (resp?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Quotation has been created succesfully!');
      setEligibleModal(true);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const viewQuote = () => {
    setEligibleModal(true);
  };

  return (
    <>
      <div className='bg-orange-500 h-fit w-full p-6 rounded-2xl '>
        <div className='text-white'>
          Hi. here is your quotation for ICICI pru{' '}
          {quotationsData?.insurance_details[0]?.PremiumSummary?.ProductName}
          <p>Term Plan</p>
        </div>
        <div className='flex'>
          <div className='h-28 w-full bg-white p-2 my-4 flex justify-between'>
            <div>
              <p className='text-xxs text-gray-500'>
                {quotationsData?.insurance_details[0]?.PremiumSummary?.LifeCoverOption}
              </p>
              <p className='text-xl font-semibold leading-none'>
                {'\u20B9' +
                  (
                    quotationsData?.insurance_details[0]?.PremiumSummary?.DeathBenefit / 100000
                  ).toFixed(2)}{' '}
                Lakhs
              </p>
              <p className='text-blue-500 text-sm font-semibold'>
                For {quotationsData?.insurance_details[0]?.PremiumSummary?.Term} Years
              </p>
            </div>
            <div className='h-full'>
              <p>Premium</p>
              <p className='text-xxxs text-red-60'>{`(Inclusive of Tax)`}</p>
              <p className='text-xl font-semibold leading-none mt-2'>
                {'\u20B9' +
                  quotationsData?.insurance_details[0]?.PremiumSummary?.AnnualPremiumWithTax}
              </p>
            </div>
            <div className='h-full'>
              <p>Frequency</p>
              <p className='text-xl font-semibold leading-none mb-1  mt-2'>
                {quotationsData?.insurance_details[0]?.PremiumSummary?.ModeOfPayment}
              </p>
            </div>
            <div className=' h-full'>
              <p>Premium Payment Term</p>
              <p className='text-xl font-semibold leading-none mb-1  mt-2'>
                {quotationsData?.insurance_details[0]?.PremiumSummary?.PremiumPaymentTerm} Years
              </p>
            </div>
          </div>
        </div>
        <div className='w-full flex justify-end'>
          <Button
            text={quotationsData?.status === 'proposals' ? 'View' : 'AGREE AND CONTINUE'}
            classes='bg-red-700 rounded-none'
            onClick={quotationsData?.status === 'proposals' ? viewQuote : AgreeToQuote}
            loading={loading}
          />
        </div>
      </div>

      <CustomModal
        destroyOnClose={true}
        open={eligibleModal}
        onCancel={() => setEligibleModal(false)}
        footer={''}
        title=''
      >
        <div>
          <FormContainer
            accordionClass='grid grid-cols-3  gap-8'
            onSubmit={handleSubmit}
            initialValues={initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      setEligibleModal(false);
                    }}
                  />
                  <Button
                    // disabled={!isValid}
                    text={'Save'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      <CustomModal
        destroyOnClose={true}
        open={successsModal}
        onCancel={() => setSuccesModal(false)}
        footer={''}
        title=''
        width={400}
      >
        <div className=' flex items-center flex-col gap-8'>
          <p className='font-semibold text-lg'> Transaction Id : {respData?.transID}</p>
          <div className='flex gap-4'>
            <a href={respData?.URL} target='_blank' rel='noreferrer'>
              <Button
                // disabled={!isValid}
                text={'Go to payment link'}
                variant='primary'
                loading={loading}
              />
            </a>
            <Button
              // disabled={!isValid}
              text={'Copy payment link'}
              variant='primary'
              onClick={handleCopyClick}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default OnlineQuoteCard;

// const lifeAssuredFields = (show) => {
//   if (show) {
//     return ['lifefrstNm',
//       "lifelstNm",
//       "lifedob",
//       "lifemrtlSts",
//       "lifegender",
//       "lifepincode",
//       "lifemobNo",
//       "lifeaadharCardNo",
//       "lifeeducation",
//       "lifeidPrf",
//       "lifeaddPrf",
//       "lifeagePrf",
//       "lifeitPrf",
//       "lifeincomePrf",
//       "lifeorgType",
//       "lifeorgTypeDesc",
//       "prosrstSts",
//       "lifeanuuIncme",
//       "lifenationality",
//       "lifenameOfOrg",
//     ]
//   } else {
//     return [];
//   }
// }

// const trusteeFields = (show) => {
//   if (show) {
//     return ['trusteename',
//       "trusteedob",
//       "trusteerelationWithLa",
//       "trusteeaddress",
//       "trusteecity",
//       "trusteepincode",
//       "trusteestate",
//       "trusteeNameofBankAccountHolder",
//       "trusteeBankAccountNumber",
//       "trusteeaccounttype",
//       "trusteeIFSC",
//       "trusteepanNo",
//       "trusteemobileNo",
//       "trusteeemail",
//     ]
//   } else {
//     return [];
//   }
// }
// const BenificiaryFields = (show) => {
//   if (show) {
//     return ['trusteename',
//       "trusteedob",
//       "trusteerelationWithLa",
//       "trusteeaddress",
//       "trusteecity",
//       "trusteepincode",
//       "trusteestate",
//       "trusteeNameofBankAccountHolder",
//       "trusteeBankAccountNumber",
//       "trusteeaccounttype",
//       "trusteeIFSC",
//       "trusteepanNo",
//       "trusteemobileNo",
//       "trusteeemail",
//     ]
//   } else {
//     return [];
//   }
// }
