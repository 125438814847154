import { TextInput, SelectInput, SelectDropDown } from 'components';
import { searchApis, searchInsuranceApis } from 'service/api-service';

const handleGoalsDependantFeilds = ({ formVals, field }) => {
  const model = formVals?.model;

  if (field?.name === 'insurance_category' || field?.name === 'product_type') {
    if (model === 'policies' || model === 'quotations' || model === 'leads') return false;
    return true;
  }
};

const addTargetTypeFields = ({ dependentRequired, setDependentRequired }) => ({
  'Target Type Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Name',
        name: 'name',
        id: 'name',
        placeholder: 'Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Effective Term',
        name: 'model',
        id: 'model',
        required: true,
        placeholder: 'Effective Term',
        component: SelectInput,
        customOnChange: (field, value) =>
          value?.value === 'leads' || value?.value === 'policies' || value?.value === 'quotations'
            ? setDependentRequired(true)
            : setDependentRequired(false),
        options: [
          {
            label: 'Clients',
            value: 'clients',
            id: 'clients',
          },
          {
            label: 'Employees',
            value: 'employees',
            id: 'employees',
          },
          {
            label: 'Leads',
            value: 'leads',
            id: 'leads',
          },
          {
            label: 'Pos',
            value: 'pos',
            id: 'pos',
          },
          {
            label: 'Policies',
            value: 'policies',
            id: 'policies',
          },
          {
            label: 'Quotations',
            value: 'quotations',
            id: 'quotations',
          },
        ],
      },
      {
        dependent: true,
        label: 'Insurance Category',
        name: 'insurance_category',
        id: 'insurance_category',
        placeholder: 'Insurance category',
        type: 'select',
        required: dependentRequired,
        component: SelectInput,
        fetchOptions: async () => searchApis({ api: 'insurance_category' }),
        shouldHideField: handleGoalsDependantFeilds,
      },
      {
        dependent: true,
        shouldHideField: handleGoalsDependantFeilds,
        label: ' Product Type',
        name: 'product_type',
        id: 'product_type',
        required: dependentRequired,
        placeholder: 'Product Type',
        component: SelectInput,
        dependency: ['insurance_category'],
        dependantFetchOptions: async (form) => {
          const id = form?.values.insurance_category;
          const resp = await searchInsuranceApis({ api: 'insurance_category', id });
          return resp?.data?.data[0]?.product_type?.map((i) => ({
            name: i.name,
            label: i.name,
            value: i.id,
            id: i.id,
          }));
        },
      },
    ],
  },
});

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Goal Type',
    path: '',
  },
];

export { addTargetTypeFields, breadcrumbObj };
