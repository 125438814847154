import { SearchAPI, TextInput } from 'components';
export const KraFields = {
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Designation',
        name: 'designation',
        id: 'desigation',
        placeholder: 'Select Designation',
        type: 'serach',
        required: true,
        component: SearchAPI,
        maxLength: 30,
        divClasses: 'col-span-2',
      },

      {
        label: 'Job KRA',
        name: 'label',
        id: 'kpr',
        type: 'text',
        placeholder: 'Enter Job KRA',
        component: TextInput,
        divClasses: 'col-span-2',
      },
    ],
  },
};

export const EditKraFields = {
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Job KRA',
        name: 'label',
        id: 'add',
        placeholder: 'Enter Job KRA',
        type: 'text',
        // required: true,
        component: TextInput,
        maxLength: 30,
        divClasses: 'col-span-2',
      },
    ],
  },
};

export const kpiFields = {
  assets: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Job KPI',
        name: 'label',
        id: 'add',
        placeholder: 'Enter Job KPI',
        type: 'text',
        // required: true,
        component: TextInput,
        maxLength: 30,
        divClasses: 'col-span-2',
      },
    ],
  },
};
