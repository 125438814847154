import { React, useEffect, useState } from 'react';
import { Loader, ViewData, SelectSearchWithName } from 'components';
import { prepareViewData } from 'utils/common';
import { Divider, Modal } from 'antd';
import { getParticularInterviewRequest } from 'service/api-service';
import { useLocation } from 'react-router-dom';
import AddInterview from '../jobs/AddInterview';
import { Tooltip } from 'antd';
import { prepareFormFieldsData } from 'utils';
import { getReasons } from 'service/api-service/reasons';
import { elementData } from 'elements';
import { NOTIFICATION_MSG } from 'constants';
import { getRecruitmentDetails, updateApplication } from 'service/api-service';

import { CustomModal, FormContainer, Button, Toast, IconButton } from 'components';
import { toLower } from 'lodash';
import designation from 'screens/designation';

const fields = {
  approvedfields: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Rejection Reason',
        name: 'r_reason',
        id: 'r_reason',
        placeholder: 'Select a Rejection Reason',
        type: 'select',
        required: true,
        component: SelectSearchWithName,
        options: [],
        fetchOptions: async () => getReasons({ type: 'recruitment' }),
        valIndex: 'value',
        customOnChange: (field, value, form) => {
          form?.setFieldValue(field, value.value);
        },
      },
    ],
  },
};

export const SECTION_DATA_VIEW = [
  {
    title: 'Basic Details',
    keys: ['name', 'phone', 'email', 'address'],
  },
  {
    title: 'Other Details',
    keys: ['designation', 'exp'],
  },
];

const UserDetails = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [showReasonModal, setReasonModal] = useState(false);
  const [declinedVal, setDeclinedVal] = useState();
  const [cv, setCv] = useState();
  const [recruitmentDetails, setRectruitmentDetails] = useState({});
  const [applicationsData, setApplicationData] = useState([]);
  const id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const toggleReasonModal = () => {
    setReasonModal((pre) => !pre);
  };

  const getRecrDetails = async () => {
    setLoading(true);
    try {
      const resp = await getRecruitmentDetails({ id });
      if (resp?.data?.status) {
        setRectruitmentDetails(resp.data?.data);
        setApplicationData(resp?.data?.data?.applications);
      }
    } catch (error) {}
    setLoading(false);
  };

  const [initialValues, reasonFields] = prepareFormFieldsData({
    fields: fields,
  });
  const getApplicationData = async () => {
    setLoading(true);
    const resp = await getParticularInterviewRequest({ id });
    // console.info('first', resp?.data.data[0].cv);
    if (resp.data.status) {
      setCv(resp?.data.data[0].cv);
      const data = prepareViewData({
        data: resp.data.data[0],
        format: SECTION_DATA_VIEW,
        keys: {
          aval: 'Availability',
          type: 'interview type',
          exp_ctc: 'Expected CTC',
          designation: 'Position',
        },
        allowToReturnObjects: true,
        phone: 'phone',
        email: 'email',
      });
      setViewData(data);
      setLoading(false);
    }
  };
  const rejectApllicant = () => {
    setDeclinedVal(viewData);
    toggleReasonModal();
  };
  const onStatusUpdate = async (data) => {
    setLoading(true);

    const finalPayload = {
      status: data?.status || 'rejected',
      r_reason: data?.r_reason,
    };

    try {
      const resp = await updateApplication({ data: finalPayload, id: id || declinedVal?.id });

      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        getRecrDetails();
      } else Toast('error', NOTIFICATION_MSG.error, resp?.data?.msg);
    } catch (error) {}

    setReasonModal(false);
    setLoading(false);
  };

  useEffect(() => {
    getApplicationData();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className='bg-white rounded-2xl relative p-4'>
        <div className='flex flex-col'>
          <span className='font-medium text-l 2xl:text-xxl'>Applicant Details</span>
          <Divider />
          {/* CV */}
          <div className='grid grid-cols-2 gap-x-5'>
            <div className='flex flex-col'>
              <span className='font-medium text-s 2xl:text-l'>CV</span>
              {/* {cv.slice(cv.lastIndexOf('.')).toLowerCase() === '.pdf' ? (<embed
                  src={process.env.REACT_APP_DOC_URL + cv}
                  type="application/pdf"
                  width="100%"
                  height="500"
                />):(<img
                  src={process.env.REACT_APP_DOC_URL + cv}
                  alt="CV"
                  style={{ width: '100%', height: 'auto' }}
                />)
                } */}
              <a
                href={process.env.REACT_APP_DOC_URL + cv}
                target='_blank'
                rel='noreferrer'
                download
                style={{ display: 'block', height: '100%', width: '100%' }}
              >
                <div className='relative p-1 rounded-md bg-grey-40'>
                  <iframe
                    src={process.env.REACT_APP_DOC_URL + cv}
                    height={'400'}
                    width={'100%'}
                  ></iframe>
                  {/* <object height={'100%'} width={'100%'} data={process.env.REACT_APP_DOC_URL + cv}>
                    {''}
                  </object> */}
                </div>
              </a>
            </div>
            {/* User details */}
            <div>
              {/* <span className='font-medium text-s 2xl:text-l'>Details</span> */}
              <ViewData
                className='grid grid-cols-2'
                pageTitle='Application Details'
                data={viewData}
                showGoToBack={false}
                goBack={false}
              />
            </div>
          </div>
        </div>
        <div className='flex justify-end relative p-4 gap-6'>
          <AddInterview app_id={id} />
          <div>
            <Tooltip title='Reject Applicant'>
              <Button
                onClick={rejectApllicant}
                className={'text-white bg-grey  px-3 py-1 rounded-2xl'}
                text='Reject'
                // permissions={permissions}
                // id={elementData.interview.permissions.create}
              ></Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <CustomModal
        open={showReasonModal}
        title='Reason to Decline'
        footer={null}
        onCancel={toggleReasonModal}
      >
        <div>
          <FormContainer
            accordionClass='grid  gap-8'
            onSubmit={onStatusUpdate}
            initialValues={initialValues}
            fields={reasonFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleReasonModal();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    text={'Reject'}
                    variant='primary'
                    classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
    </>
  );
};

export default UserDetails;
