const providentFund = [
  {
    employee: 'Archna',
    provident_fund_type: 'Fixed',
    employee_share_percentage: '20%',
    organization_share_percentage: '25%',
  },
  {
    employee: 'Ritik',
    provident_fund_type: 'Percentage',
    employee_share_percentage: '30%',
    organization_share_percentage: '40%',
  },
  {
    employee: 'Mahabeer',
    provident_fund_type: 'Fixed',
    employee_share_percentage: '20%',
    organization_share_percentage: '21%',
  },
  {
    employee: 'Rohit',
    provident_fund_type: 'Percentage',
    employee_share_percentage: '10%',
    organization_share_percentage: '40%',
  },
];

export { providentFund };
