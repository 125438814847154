import { create } from 'zustand';
import { produce } from 'immer';
import {
  deleteUploadDocument,
  customerCallLogs,
  getInquiryDetails,
  inquiryManagement,
  postUploadDocument,
} from 'service/api-service';
import { Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants/index';
// typedefs
import './typedefs';
import dayjs from 'dayjs';

const initialLeadsState = {
  selectedModuleId: 1,
  highestVisitedStep: 1,
  buttonPressed: null,
  leadId: null,
  isOnlineProduct: false,
  proposals_Id: null,
  createLead: {
    client: {},
    clientContact: {},
    response: null,
    assignTo: {},
    renewalDate: null,
    followUpDate: null,
    reason: '',
    addProductsChecked: false,
    productArray: [],
  },
  quotations: {
    isFetching: false,
    /** @type {Quotation} data */
    data: null,
    error: null,
    currentlyOpenedModalName: null, // could be 'upload' or 'delete' if need to make those visible, else set back to null to close them.
    docIdToDelete: null,
    docDataToSend: null,
    /** @type {{id: null, insurerId: null}} selectedProduct */
    selectedProduct: null,
    touched: false,
  },
  proposals: {
    id: null,
    /** @type {Proposals} data */
    data: null,
    isFetching: false,
    error: null,
  },
};

export const useLeads = create((set, get) => ({
  ...initialLeadsState,
  updateHighestVisitedStep: (step) =>
    set(
      produce((state) => {
        if (state.highestVisitedStep < step) state.highestVisitedStep = step;
      }),
    ),
  // CREATE LEAD STUFF
  updateLeadId: (leadId) => set({ leadId }),
  setButtonPressed: (value) => set({ buttonPressed: value }),
  setIsOnlineProduct: (value) => set({ isOnlineProduct: value }),
  updateProposalsId: (proposalsId) => set({ proposals_Id: proposalsId }),
  addBasicLeadsDetails: (details) =>
    set(
      produce((state) => {
        if (details.client) state.createLead.client = details.client;
        if (details.clientContact) state.createLead.clientContact = details.clientContact;
        if (details.response) state.createLead.response = details.response;
        if (details.assignTo) state.createLead.assignTo = details.assignTo;
        if (details.renewalDate) state.createLead.renewalDate = details.renewalDate;
        if (details.followUpDate) state.createLead.followUpDate = details.followUpDate;
        if (details.reason) state.createLead.reason = details.reason;
        if (typeof details.addProductsChecked === 'boolean')
          state.createLead.addProductsChecked = details.addProductsChecked;
      }),
    ),
  addproductArray: (details) => {
    set(
      produce((state) => {
        state.createLead.productArray.push(details);
      }),
    );
  },
  setProductArray: (details) => {
    set(
      produce((state) => {
        state.createLead.productArray = details;
      }),
    );
  },
  resetLeadsStore: () => set(initialLeadsState),

  onSubmitLead: async (navigate, params, user, goBack) => {
    const NewProducts = get().createLead.productArray?.filter((obj) => {
      return !obj.id || obj.id === null;
    });
    const leadId = params.id;
    const response = get().createLead.response;
    const followUpDate = get().createLead.followUpDate;
    const ins_cat_id = get().createLead.productArray?.map((item) => item?.categoryId);
    const newPayloadIns_cat_id = NewProducts?.map((item) => item?.categoryId);
    const products = get().createLead.productArray?.map((item) => {
      return {
        insurer: item?.insurer,
        product_type: item?.productId,
      };
    });
    const newPayloadProducts = NewProducts?.map((item) => {
      return {
        insurer: item?.insurer,
        product_type: item?.productId,
      };
    });

    // Do not remove both console written below
    console.log('array', get().createLead.productArray);
    console.log('NewProducts', NewProducts);

    const finalPayload = {
      ins_cat_id: leadId !== 'new' ? newPayloadIns_cat_id : ins_cat_id,
      products: leadId !== 'new' ? newPayloadProducts : products,
      insurance_details: get().createLead.productArray,
      assigned_to: get().createLead.assignTo?.id,
      customer: get().createLead.client.id,
      p_insured: response === 'presently_insured' ? true : false,
      user_type: user?.user_type,
      response: get().createLead.response,
      renewal: get().createLead.renewalDate,
      online: get().isOnlineProduct,
    };
    if (
      response === 'interested' ||
      (response === 'presently_insured' && get().createLead.addProductsChecked)
    ) {
      const resp = await inquiryManagement({
        method: leadId !== 'new' ? 'patch' : 'post',
        id: leadId !== 'new' ? leadId : '',
        data: finalPayload,
      });
      if (resp?.status) {
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.msg || `${leadId ? 'Updated' : 'Created'} successfully`,
        );
        const lead_id = resp?.data?.data?.id;
        if (goBack) {
          navigate('/lead');
        } else {
          navigate(`/lead/${params.mode}/${lead_id}/2`);
        }
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.msg || 'An error occured');
      }
    } else {
      const payload = {
        customer: get().createLead.client?.id,
        status: response,
        follow_up: followUpDate ? dayjs(followUpDate).format('YYYY-MM-DD') : null,
        reason: get().createLead.reason,
        renewal_date: get().createLead.renewalDate,
      };

      const resp = await customerCallLogs({ data: payload, method: 'post' });

      if (resp?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.msg || 'Data Saved successfully');
        get().addBasicLeadsDetails({
          response: null,
          reason: '',
          followUpDate: null,
        });

        if (goBack) {
          navigate('/client?tab=followup');
        }
        return true;
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.msg || 'error');
      }
    }
  },

  // QUOTATIONS STUFF
  initQuotationData: async (leadIdFromQuery) => {
    // console.log('quotations: going to fetch');
    // if (get().quotations.touched) return;
    set(
      produce((state) => {
        state.quotations.isFetching = true;
        state.proposals.isFetching = true;
      }),
    );

    try {
      const localLeadId = get().leadId;
      if (!localLeadId)
        set(
          produce((state) => {
            state.leadId = leadIdFromQuery;
          }),
        );
      const leadId = localLeadId ?? leadIdFromQuery;

      if (!leadId) return;

      const { status, data, error } = await getInquiryDetails({ id: leadId });

      if (!status || !data) throw error;
      set(
        produce((state) => {
          state.quotations.data = data.data ? formattedQuotationDataWrtForm(data.data) : null;
          const values = formattedProposalsDataWrtForm(data.data, state.proposals.data);
          console.log('values', values);
          state.proposals.data = data.data ? values : null;
        }),
      );
    } catch (error) {
      Toast('error', NOTIFICATION_MSG.error, error || 'Failed to fetch enquiry.');
    } finally {
      set(
        produce((state) => {
          state.quotations.isFetching = false;
          state.proposals.isFetching = false;
        }),
      );
    }
  },
  toggleIsCoverageChecked: (productId, insurerId, coverageId, isChecked = true) => {
    set(
      produce((state) => {
        const product = state.quotations.data?.products.find((product) => product.id === productId);

        if (!product) return;

        const insurer = product.insurer.find((insurer) => insurer.id === insurerId);

        if (!insurer) return;
        /** any property with double underscores(__) from hereon signifies property added in front-end, and not given by backend */
        if (!Array.isArray(insurer.__coverages))
          insurer.__coverages = isChecked ? [coverageId] : [];
        else if (!isChecked)
          insurer.__coverages = insurer.__coverages.filter(
            (coverage) => coverage.id !== coverageId?.id,
          );
        else insurer.__coverages.push(coverageId);
      }),
    );
  },
  toggleIsProductChecked: (productId, insurerId, isChecked = true) => {
    set(
      produce((state) => {
        const product = state.quotations.data?.products.find((product) => product.id === productId);

        if (!product) return;

        const insurer = product.insurer.find((insurer) => insurer.id === insurerId);

        if (!insurer) return;

        if (typeof insurer.__toShow === 'undefined') insurer.__toShow = false;
        else insurer.__toShow = isChecked;
      }),
    );
  },
  onChangeInsurerFormFields: (productId, insurerId, values) => {
    set(
      produce((state) => {
        const product = state.quotations.data?.products.find((product) => product.id === productId);

        if (!product) return;

        const insurer = product.insurer.find((insurer) => insurer.id === insurerId);

        if (!insurer) return;
        if (!insurer.__formValues) insurer.__formValues = { ncb: '', premium: '', sum: '' };
        insurer.__formValues = values;
        state.quotations.touched = true;
      }),
    );
  },
  uploadDocs: async () => {
    const { docDataToSend, selectedProduct } = get().quotations;

    const docUploadResponse = await postUploadDocument({ data: docDataToSend });
    if (!docUploadResponse.status || !docUploadResponse.data) {
      Toast('error', NOTIFICATION_MSG.error, 'Failed to upload document.');
      return false;
    }
    set(
      produce((state) => {
        const product = state.quotations.data?.products.find(
          (product) => product.id === selectedProduct?.id,
        );

        if (!product) return;

        const insurer = product.insurer.find(
          (insurer) => insurer.id === selectedProduct?.insurerId,
        );

        if (!insurer) return;

        insurer.__docPayloadWithLink = {
          image: docUploadResponse.data?.data?.image,
          id: docUploadResponse.data?.data?.id,
        };
        state.quotations.selectedProduct = null;
      }),
    );

    Toast('success', NOTIFICATION_MSG.success, 'Document uploaded');
    return true;
  },
  deleteUploadedDocs: async () => {
    const { docIdToDelete, selectedProduct } = get().quotations;
    if (!docIdToDelete) return false;

    const docDeleteResponse = await deleteUploadDocument({ id: docIdToDelete });
    if (!docDeleteResponse.status) {
      Toast('error', NOTIFICATION_MSG.error, 'Failed to delete document.');
      return false;
    }

    set(
      produce((state) => {
        const product = state.quotations.data?.products.find(
          (product) => product.id === selectedProduct?.id,
        );

        if (!product) return;

        const insurer = product.insurer.find(
          (insurer) => insurer.id === selectedProduct?.insurerId,
        );

        if (!insurer) return;

        insurer.__docPayloadWithLink = null;
        state.quotations.selectedProduct = null;
        state.quotations.docIdToDelete = null;
      }),
    );

    Toast('success', NOTIFICATION_MSG.success, 'Document deleted');
    return true;
  },
  onSubmitQuotations: async (mode) => {
    // if (!get().quotations.touched) {
    //   // if (mode === 'create') {
    //   //   Toast('info', NOTIFICATION_MSG.error, 'Please fill at least one field');
    //   //   return false;
    //   // }
    //   return true;
    // }
    /** @type {Quotation} data */
    const data = get().quotations.data;
    const leadId = get().leadId;
    if (!data || !leadId) {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        "Couldn't fetch relevant data before creating quotations. Please create lead again",
      );
      return false;
    }
    let isAtLeastOneFieldFilled = false;

    const quotes = [];
    const productsToKeep = [];
    const removeProductsEntirely = [];

    data.products.forEach((product) => {
      if (product.insurer.every((insurer) => insurer.__toShow === false))
        removeProductsEntirely.push(product.id);

      product.insurer.forEach((insurer) => {
        // keeping checked insurances only
        if (insurer.__toShow !== false) {
          // checking for filled form amongst checked values
          console.log('insurer.__formValues', insurer.__formValues);
          if (Object.values(insurer.__formValues ?? {}).some(Boolean))
            isAtLeastOneFieldFilled = true;

          // adding explicit check as undefined equates to true in UI.
          const productIndexInProductsToKeep = productsToKeep.findIndex(
            (productToRemove) => productToRemove.id === product.id,
          );

          if (productIndexInProductsToKeep === -1) {
            productsToKeep.push({ id: product.id, insurer: [insurer.id] });
          } else {
            productsToKeep[productIndexInProductsToKeep].insurer.push(insurer.id);
          }

          // making them quoted as well
          quotes.push({
            cover: insurer.__coverages ?? [],
            id: insurer.id,
            insurer: insurer.name,
            ...(insurer.__formValues ?? {}),
            product: product.product_type,
          });
        }
      });
    });
    if (!isAtLeastOneFieldFilled) {
      Toast('info', NOTIFICATION_MSG.error, 'Please fill at least one field');
      return false;
    }

    const resp = await inquiryManagement({
      method: 'patch',
      data: {
        id: leadId,
        quote: quotes,
        products: productsToKeep,
        remove_products: removeProductsEntirely,
        status: 'quoted',
      },
      id: leadId,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Quotation added');
      set(produce((state) => void (state.quotations.touched = false)));
      return true;
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      return false;
    }
  },
  setDocIdToDelete: (docIdToDelete) =>
    set(
      produce((state) => {
        state.quotations.docIdToDelete = docIdToDelete;
      }),
    ),
  setCurrentlyOpenedModalName: (currentlyOpenedModalName) =>
    set(
      produce((state) => {
        state.quotations.currentlyOpenedModalName = currentlyOpenedModalName;
      }),
    ),
  setDocDataToSend: (docDataToSend) =>
    set(
      produce((state) => {
        state.quotations.docDataToSend = docDataToSend;
      }),
    ),
  setSelectedProduct: (selectedProduct) =>
    set(
      produce((state) => {
        state.quotations.selectedProduct = selectedProduct;
      }),
    ),
  // PROPOSALS
  submitProposals: async (mode) => {
    /** @type {Proposals} data */
    const data = get().proposals.data;
    const leadId = get().leadId;
    if (!data || !leadId) {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        "Couldn't fetch relevant data before creating proposals. Please create lead again",
      );
      return false;
    }
    const products = data.products
      .filter((product) => product.selectedInsurer)
      .map((product) => ({
        id: product.id,
        s_insurer: product.selectedInsurer,
      }));

    const resp = await inquiryManagement({
      method: 'patch',
      data: { products, status: 'proposals' },
      id: leadId,
    });
    console.log('resp policy', resp?.data?.data);
    if (resp.data?.status && resp.data.data?.lead_id) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Proposal added');
      set(
        produce((state) => {
          state.proposals.touched = false;
          state.proposals_Id = { leadId: resp.data.data.lead_id, id: resp?.data?.data?.id };
        }),
      );
      await get().initQuotationData(leadId);
      return true;
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      return false;
    }
  },
  /** @param {import('antd').RadioChangeEvent} event */
  onChangeRadioGroup: (event) => {
    console.log('event', event);
    set(
      produce((state) => {
        const product = state.proposals.data?.products.find(
          (product) => product.id === Number(event.target.name),
        );
        if (!product) return;

        product.selectedInsurer = event.target.value;
        state.proposals.touched = true;
      }),
    );
  },
}));

/** @param {Quotation} data */
const formattedQuotationDataWrtForm = (data) => {
  const quotes = data.quote;
  if (!quotes) return data;
  console.info('quotes are', quotes);

  data.products.forEach((product) =>
    product.insurer.forEach((insurer) => {
      const curQuote = quotes.find(
        (quote) => quote.product.id === product.product_type.id && quote.insurer === insurer.name,
      );
      if (!curQuote) return;

      insurer.__coverages = curQuote.cover ?? [];
      insurer.__formValues = {
        premium: curQuote.premium,
        sum: curQuote.sum,
        ncb: curQuote.ncb,
      };
      insurer.__toShow = true;
    }),
  );
  return data;
};

/** @param {Quotation} data
/** @param {Proposals} dataInStore
 * @returns {Proposals}
 */
const formattedProposalsDataWrtForm = (data, dataInStore) => ({
  clientName: data.customer?.name ?? data.customer?.corp_name,
  clientEmail: data.customer?.email_address,
  assignedTo: data.assigned_to?.name,
  phoneNumber: data.customer?.contact_number,
  products: data.products.map((product) => ({
    name: product.product_type.name,
    id: product.id,
    selectedInsurer:
      // you can blame backend for this mess.
      typeof product.s_insurer === 'number'
        ? product.s_insurer
        : product.s_insurer?.id ??
          dataInStore?.products.find((storeProduct) => storeProduct.id === product.id)
            ?.selectedInsurer,
    insurers: product.insurer.map((insurer) => ({
      name: insurer.name,
      id: insurer.id,
    })),
  })),
});
