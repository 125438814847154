import { useEffect, useRef, useState, useCallback } from 'react';
import { Spin, Tabs } from 'antd';

import { deleteTax, getTax } from 'service/api-service';
import { CustomModal, Toast, Button } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { Trash } from 'assets/icons';
import { elementData } from 'elements';

import TaxForm from './form';

const Taxes = (props = {}) => {
  const permissions = props?.permissions || [];

  /* states  */
  const [items, setItems] = useState([]);
  const [activeKey, setActiveKey] = useState(items[0]?.key);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [deleteInt, setDeleteInit] = useState();

  const newTabIndex = useRef(0);

  const fetchData = async () => {
    setLoading(true);
    const resp = await getTax();
    if (resp?.data?.status) {
      const data = resp?.data?.data || [];
      const newActiveKey = `newTab${newTabIndex.current++}`;
      // setData(data.reverse());

      const tabs = data.length
        ? data?.map((item) => ({
            key: item?.id,
            label: item?.name,
            children: <TaxForm data={item} permissions={permissions} fetchData={fetchData} />,
            // closable: false,
          }))
        : [
            {
              label: 'New Tax',
              children: <TaxForm permissions={permissions} fetchData={fetchData} />,
              key: newActiveKey,
            },
          ];
      setItems(tabs);
      setActiveKey(tabs[0]?.key);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteTaxField = useCallback(async () => {
    setLoading(true);
    const resp = await deleteTax({ method: 'delete', id: deleteInt });
    toggleDeleteTax();
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      fetchData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.msg);
    }
  }, [deleteInt]);

  const toggleDeleteTax = () => setDeleteModal((pre) => !pre);

  const onDeleteTax = useCallback((data) => {
    setDeleteInit(data);
    toggleDeleteTax();
  }, []);

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };
  const add = () => {
    const newActiveKey = `newTab${newTabIndex.current++}`;
    const newPanes = [...items];
    newPanes.push({
      label: 'New Tax',
      children: <TaxForm permissions={permissions} fetchData={fetchData} />,
      key: newActiveKey,
    });
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const remove = (targetKey) => {
    if (typeof targetKey === 'string') {
      let newActiveKey = activeKey;
      let lastIndex = -1;
      items.forEach((item, i) => {
        if (item.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      const newPanes = items.filter((item) => item.key !== targetKey);
      if (newPanes.length && newActiveKey === targetKey) {
        if (lastIndex >= 0) {
          newActiveKey = newPanes[lastIndex].key;
        } else {
          newActiveKey = newPanes[0].key;
        }
      }
      setItems(newPanes);
      setActiveKey(newActiveKey);
    }
    // onDeleteTax(data[targetKey - 1]);
    if (typeof targetKey === 'number') {
      onDeleteTax(targetKey);
    }
  };

  const onEdit = (targetKey, action) => {
    if (action === 'add') add();
    if (action === 'remove') remove(targetKey);
  };

  if (loading) {
    return (
      <div className='flex justify-center items-center h-36'>
        <Spin />
      </div>
    );
  }

  return (
    <>
      <Tabs
        type='editable-card'
        onChange={onChange}
        activeKey={activeKey}
        onEdit={onEdit}
        items={items}
        hideAdd={elementData.taxes.permissions.create}
      />
      {/* delete asset confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteTax}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the tax ?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteTax}
            />
            <Button
              text='Delete Tax'
              variant='primary'
              classes={`ml-6 px-8 bg-black`}
              onClick={deleteTaxField}
              loading={loading}
              permissions={permissions}
              id={elementData.taxes.permissions.delete}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Taxes;
