/** external deps */
import { notification } from 'antd';
import { Close, Error, Info, Success } from 'assets/icons';
import './style.css';

const close = () => {};
// type NotificationType = "success" | "info" | "warning" | "error"
export const Toast = (type, message, description, placement = 'bottomRight') => {
  const key = 'key';
  notification[type]({
    placement: placement,
    duration: 3,
    className:
      type === 'success'
        ? 'bg-green-light capitalize'
        : type === 'error'
        ? 'bg-red-light capitalize'
        : 'bg-primary-light capitalize',
    closeIcon: <Close className='w-12 h-12' />,
    icon:
      type === 'success' ? (
        <Success className='w-8 h-8' />
      ) : type === 'info' ? (
        <Info className='w-8 h-8' />
      ) : type === 'error' ? (
        <Error className='w-8 h-8' />
      ) : (
        ''
      ),
    message: <p className='capitalize'>{message}</p>,
    description: <p className='capitalize'>{description}</p>,
    key,
    onClose: close,
  });
};
