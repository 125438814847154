import { TextInput } from 'components';

export const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Master',
    // path: "/",
  },
  {
    name: 'Designation',
    path: '',
  },
];

export const columns = [
  {
    key: 'name',
    title: 'Designation Name',
    dataIndex: 'name',
    render: (text) => text || '\u2014',
  },
];

export const addDesignationFields = {
  'Designation Details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Designation Name',
        name: 'name',
        id: 'name',
        placeholder: 'Designation Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 50,
        divClasses: 'col-span-2',
      },
    ],
  },
};
