import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Select } from 'antd';
import dayjs from 'dayjs';

import { getLeadsGraph } from 'service/api-service';
import { DropdownIcon } from 'assets/icons';
import { Loader } from 'components';
import { timelineOptions } from './data';
import './style.css';

const PolicyGraphs = ({ startDate, endDate }) => {
  const componentRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dim, setDim] = useState({ height: 0, width: 0 });

  const fetchData = async () => {
    setLoading(true);
    const resp = await getLeadsGraph({ start: startDate, end: endDate });
    if (resp?.data?.status) {
      console.log('data?.data?.inquiry_by_date', data);
      setData(resp?.data?.data?.inquiry_by_date);
    }

    setLoading(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (componentRef.current) {
        const width = componentRef.current.clientWidth;
        const height = componentRef.current.clientHeight;
        setDim({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    // Initial setup
    handleResize();

    // Observer setup
    if (componentRef.current) {
      resizeObserver.observe(componentRef.current);
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [componentRef]);

  function toCapitalCase(str) {
    const words = str.split('_');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }

  useEffect(() => {
    if (startDate && endDate) fetchData();
  }, [startDate, endDate]);
  // Iterate over the keys you want to extract
  const keysToExtract = [
    { name: 'quoted_count', color: '#E12A2A' },
    { name: 'draft_count', color: '#26CBED' },
    { name: 'converted_count', color: '#F2C62A' },
    { name: 'proposals_count', color: '#0398FF' },
  ];
  const result = [];
  keysToExtract.forEach((key) => {
    const values = data?.map((item) => item[key.name]);
    result.push({
      label: toCapitalCase(key.name),
      data: values,
      fill: false,
      backgroundColor: key.color,
      borderColor: key.color,
      borderWidth: 2,
      lineTension: 0,
    });
  });

  const chartData = {
    labels: data?.map((item) => item.created_date),
    datasets: result,
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div ref={componentRef} className='cursor-pointer'>
      <Line
        {...dim}
        data={chartData}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
      />
    </div>
  );
};

const Header = ({ setStartDate, setEndDate }) => {
  const onSelectDateRange = useCallback((e) => {
    if (e !== 'customRange') {
      setEndDate(dayjs().add(1, 'day').format('YYYY-MM-DD'));
    }
    if (e === 'week') {
      setStartDate(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
    } else if (e === 'month') {
      setStartDate(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));
    } else if (e === 'quarter') {
      setStartDate(dayjs().subtract(3, 'month').format('YYYY-MM-DD'));
    } else if (e === 'half') {
      setStartDate(dayjs().subtract(6, 'month').format('YYYY-MM-DD'));
    } else if (e === 'year') {
      setStartDate(dayjs().subtract(12, 'month').format('YYYY-MM-DD'));
    }
  }, []);

  return (
    <div id='lead-tab' className='flex justify-between items-center'>
      <div className='flex flex-row justify-between items-start'>
        <div className='text-sm font-semibold leading-6'>Leads</div>
      </div>

      <Select
        className='px-2 rounded group hover:text-primary-90'
        suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
        bordered={false}
        options={timelineOptions}
        onSelect={onSelectDateRange}
        placeholder='Select Date'
        defaultValue='week'
      />
    </div>
  );
};

const LeadGraph = () => {
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(dayjs().add(1, 'day').format('YYYY-MM-DD'));

  return (
    <div>
      <Header setStartDate={setStartDate} setEndDate={setEndDate} />
      <PolicyGraphs startDate={startDate} endDate={endDate} />
    </div>
  );
};

export default LeadGraph;
