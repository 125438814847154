import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Loader = ({ size = 24 }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: size,
      }}
      spin
    />
  );

  return (
    <div className='flex items-center justify-center h-full'>
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Loader;
