import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CustomModal, FormContainer, Button, Toast, IconButton } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
import { inviteCandidate } from 'service/api-service';
import { prepareFormFieldsData } from 'utils';
import { InviteFields } from './management/data';
import { Tooltip } from 'antd';
import clsx from 'clsx';

const AddInterview = ({ editInitials, app_id = '', permissions }) => {
  //state is used to check if the user has navigated from ADD INTERVIEW button from dashboard
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [fieldRequired, setFieldRequired] = useState(false);
  const [showInviteModal, setInviteModal] = useState(state === 'add' ? true : false);

  const [inviteInitials, InviteFinalFields] = prepareFormFieldsData({
    fields: InviteFields(fieldRequired, setFieldRequired),
    defaultValues: { message: 'hi we are inviting you for a meeting round ' },
  });

  const toggleInviteModal = () => {
    setInviteModal((prev) => !prev);
  };

  const onInvite = async (data) => {
    setLoading(true);

    const finalpayload = {
      ...data,
      employees: data?.assign_to_employee,
      app_id,
    };
    const resp = await inviteCandidate({ data: finalpayload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleInviteModal();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.msg);
    }
    setLoading(false);
  };

  //console.log("fiels",fieldRequired);
  return (
    <>
      <Tooltip title='Invite Applicant'>
        <Button
          onClick={toggleInviteModal}
          permissions={permissions}
          id={elementData.interview.permissions.create}
          className={'text-white bg-primary-90  px-3 py-1 rounded-2xl'}
          text='Invite'
        ></Button>
      </Tooltip>
      <CustomModal
        open={showInviteModal}
        closable={true}
        onCancel={toggleInviteModal}
        footer={''}
        width={450}
      >
        <h1 className='text-xl font-semibold text-center'>Invite Applicant</h1>

        <FormContainer
          key={fieldRequired}
          accordionClass='grid gap-8'
          onSubmit={onInvite}
          initialValues={inviteInitials}
          fields={InviteFinalFields}
          customSubmitRender={({ isSubmitting, submitForm, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    toggleInviteModal();
                  }}
                />
                <Button
                  id={
                    editInitials
                      ? elementData.interview.permissions.update
                      : elementData.interview.permissions.create
                  }
                  text='Invite'
                  variant='primary'
                  classes={clsx('ml-6 px-8 bg-black', { 'bg-grey': isSubmitting })}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </CustomModal>
    </>
  );
};

export default AddInterview;
