import Request from 'service/api-service/client';

export const getAllocation = async ({ id, page = 1 } = {}) => {
  const resp = await Request({
    method: 'get',
    API: `inventory/allocation?user_id=${id}&page=${page}`,
  });
  return resp;
};

export const postAllocation = async ({ data = undefined } = {}) => {
  const resp = await Request({
    method: 'post',
    API: `inventory/allocation`,
    data,
  });
  return resp;
};

export const getAssetAllocation = async ({ status } = {}) => {
  const resp = await Request({
    method: 'get',
    API: `inventory/allocation?status=${status || ''}`,
  });
  return resp;
};
