import { EmailOutline } from 'assets/icons';
import { TextInput, PasswordInput } from '../../components';

const ForgotFields = {
  forgotPassword: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Email Address',
        name: 'email',
        id: 'email',
        placeholder: 'Email Address',
        type: 'email',
        required: true,
        component: TextInput,
        rightIcon: <EmailOutline />,
        // maxLength: 30,
      },
    ],
  },
};

const ForgotInitials = {
  email: '',
};

const ResetFields = {
  resetPassword: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
        type: 'password',
        required: true,
        component: PasswordInput,
        maxLength: 30,
      },
      {
        label: 'Confirm Password',
        name: 'cPassword',
        id: 'cPassword',
        placeholder: 'Confirm Password',
        type: 'password',
        required: true,
        component: PasswordInput,
        maxLength: 30,
      },
    ],
  },
};

const ResetInitials = {
  password: '',
  cPassword: '',
};
export { ForgotFields, ForgotInitials, ResetFields, ResetInitials };
