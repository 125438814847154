import React, { useContext } from 'react';

import { CustomModal, FormContainer, SearchAPI, SelectInput, Toast, Button } from 'components';
import { advancedAssign, searchApis } from 'service/api-service';
import { prepareFormFieldsData } from 'utils';
import { NOTIFICATION_MSG } from 'constants';
import { Context } from 'store/context';

export default function AssignModal({ open, onCancel, allCategories }) {
  const {
    leads: { list: leads, updateLeads },
  } = useContext(Context);
  const onSubmit = async (data) => {
    try {
      const payload = {
        ...data,
        assigned_to: data?.assign_to_employee,
      };
      const res = await advancedAssign({ data: payload });
      if (res?.status) {
        updateLeads();
        Toast('success', NOTIFICATION_MSG.success, res?.data.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, res?.data.msg || 'Please try again');
      }
      onCancel();
    } catch (error) {
      Toast('error', NOTIFICATION_MSG.error, 'Please try again');
    }
  };

  const fields = {
    assignFields: {
      showAccordionHeader: false,
      fields: [
        {
          label: 'Select Asignee',
          name: 'assign_to_employee',
          id: 'assign_to_employee',
          placeholder: 'Select Asignee',
          type: 'select',
          component: SearchAPI,
          required: true,
        },
        {
          label: 'Select Category',
          name: 'ins_cat_id',
          id: 'ins_cat_id',
          divClasses: 'mt-2',
          placeholder: 'Select Category',
          type: 'select',
          component: SelectInput,
          fetchOptions: async () => searchApis({ api: 'insurance_category' }),
          required: true,
        },
        {
          label: 'Select Product',
          name: 'product_type',
          id: 'product_type',
          placeholder: 'Select Product',
          type: 'select',
          divClasses: 'mt-2',
          component: SelectInput,
          options: [],
          required: true,
          dependency: ['ins_cat_id'],
          dependantFetchOptions: async (form) => {
            const id = form?.values?.ins_cat_id;
            const data = allCategories.filter((option) => id === option.id);
            const products = data[0]?.product_type?.map((product) => ({
              value: product.id,
              label: product.name,
              name: product.name,
              id: product.id,
            }));
            return products;
          },
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: fields,
  });

  return (
    <CustomModal
      title={'Advanced Assignment'}
      open={open}
      closable={true}
      onCancel={onCancel}
      footer={''}
      width={450}
    >
      <div className=''>
        <FormContainer
          accordionClass='grid grid-cols-1 gap-4'
          onSubmit={onSubmit}
          fields={finalFields}
          initialValues={initialValues}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    onCancel();
                    resetForm();
                  }}
                />
                <Button
                  disabled={!isValid}
                  text={'Save'}
                  variant='primary'
                  classes={`ml-6 px-8 bg-black`}
                  onClick={submitForm}
                  loading={isSubmitting}
                />
              </div>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}
