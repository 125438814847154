/** external deps */
import React, { useEffect, useState } from 'react';

/** internal deps */
import { ContentHeader, TabsComponent } from 'components';
import { REFRESH_EVT, breadcrumbObj } from './data';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';

import AddEditLeave from './AddEditLeave';
import LeaveTable from './LeaveTable';
import { broadcast } from 'utils/communication';
import { getQueryParams, rowCommonClass } from 'utils/common';

const tabs = [
  {
    key: 'drafted',
    id: elementData.company.permissions.view,
    label: `Pending`,
    Component: () => null,
  },
  {
    key: 'approved',
    id: elementData.expense_type.permissions.view,
    label: `Approved`,
    Component: () => null,
  },

  {
    key: 'declined',
    id: elementData.expense_type.permissions.view,
    label: `Decline`,
    Component: () => null,
  },
];

const Leaves = ({ permissions, isRequesting }) => {
  const { tab } = getQueryParams();
  /* states  */
  const [showAddModal, setAddModal] = useState(false);
  const [key, setActiveTabKey] = useState(tab);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [formLoading, setFormLoading] = useState(false);

  const toggleAddLeave = async () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    setActiveTabKey(tab);
    if (!tab) {
      setActiveTabKey('drafted');
    }
  }, []);

  return (
    <div className='grid gap-0'>
      <ContentHeader
        title={<p className='font-semibold leading-normal text-s'>Leave Requests</p>}
        permissions={permissions}
        onChange={handleOnChange}
        // data={breadcrumbObj}
        btnText={isRequesting ? '+ Request Leave' : null}
        buttonId={elementData.request_leave.permissions.create}
        onClick={toggleAddLeave}
      />
      <TabsComponent
        items={tabs}
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
        url='/leaves?'
      />
      <div className='bg-white p-2 rounded-md'>
        <LeaveTable
          getRowClassName={rowCommonClass}
          setActiveTabKey={setActiveTabKey}
          permissions={permissions}
          status={key}
          isRequesting={isRequesting}
          searchQuery={searchQuery}
        />
      </div>

      {/* add leaves modal */}
      <AddEditLeave
        open={showAddModal}
        toggleMethod={toggleAddLeave}
        loading={formLoading}
        setLoading={setFormLoading}
        getLeaves={() => broadcast(REFRESH_EVT)}
      />
    </div>
  );
};

export default SecureComponent(Leaves);
