import React, { useEffect, useRef } from 'react';
import './style.css';

export default function PdfViewerComponent(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let instance, PSPDFKit;

    const exportPdfFile = async (instance) => {
      instance.exportPDF().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/pdf' });

        // Function to convert Blob to base64
        const blobToBase64 = (blob) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result.split(',')[1]);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
        };

        blobToBase64(blob)
          .then((base64String) => {
            // Save the base64String or use it as needed
            // console.log(base64String);
            props?.setBase64String(base64String);
          })
          .catch((error) => {
            console.error('Error converting Blob to base64:', error);
          });
      });
    };

    const addSignatureField = async (instance) => {
      try {
        const widget = new PSPDFKit.Annotations.WidgetAnnotation({
          pageIndex: 0,

          boundingBox: new PSPDFKit.Geometry.Rect({
            top: 790,
            left: 450,
            width: 150,
            height: 50,
          }),
          formFieldName: 'My signature form field',
          id: PSPDFKit.generateInstantId(),
        });
        const formField = new PSPDFKit.FormFields.SignatureFormField({
          name: 'My signature form field',
          annotationIds: PSPDFKit.Immutable.List([widget.id]),
        });
        instance.create([widget, formField]);
        instance.addEventListener('inkSignatures.change', () => {
          exportPdfFile(instance);
        });
      } catch (error) {
        console.error('Error adding eSignature field:', error);
      }
    };

    (async function () {
      PSPDFKit = await import('pspdfkit');
      PSPDFKit.unload(container);

      instance = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document: props.document,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      });

      // Add eSignature field after PSPDFKit instance is loaded
      if (instance) {
        addSignatureField(instance);
      }
    })();
    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [props.document]);

  return <div ref={containerRef} id='pdf-file' />;
}
