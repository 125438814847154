import Request from '../client';

export const getAssetType = async ({
  data = undefined,
  method = 'get',
  id = '',
  page = 1,
  search = '',
} = {}) => {
  const resp = await Request({
    method: method,
    API:
      method === 'patch' || method === 'delete'
        ? `inventory/?id=${id}`
        : `inventory/?page=${page}&search=${search}`,
    data: data,
  });
  return resp;
};

export const getAssets = async ({
  data = undefined,
  method = 'get',
  id,
  page = 1,
  avail = '',
  asset_type = '',
  search = '',
} = {}) => {
  const resp = await Request({
    method,
    API:
      method === 'delete' || method === 'patch'
        ? `inventory/asset?id=${id}`
        : `inventory/asset?page=${page}&avail=${avail}&asset_type=${asset_type}&search=${search}`,
    data,
  });
  return resp;
};
export const getAllocatedAssets = async ({
  data = undefined,
  method = 'get',
  id,
  employee_id,
  status,
  page = 1,
} = {}) => {
  const resp = await Request({
    method,
    API:
      method === 'delete' || method === 'patch'
        ? `inventory/allocation?id=${id}`
        : `inventory/allocation?user_id=${employee_id || ''}&status=${status || ''}&page=${
            page || ''
          }`,
    data,
  });
  return resp;
};
