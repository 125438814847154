import { endpoints } from 'constants/endpoints';
import Request from '../client';

export const getSoldPolicies = async ({ start = '', end = '', category = '', product = [] }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.dashboard.policySold(start, end, category, product),
  });
  return resp;
};

export const getLeadsGraph = async ({ start = '', end = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.dashboard.leadGraph(start, end),
  });
  return resp;
};

export const getEmployeeGraph = async ({ start = '', end = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.dashboard.employeeGraph(start, end),
  });
  return resp;
};

export const getClientGraph = async ({ start = '', end = '', type = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.dashboard.clientGraph(start, end, type),
  });
  return resp;
};
