import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { getReconciliationReport } from 'service/api-service';
import FilterBar from './filter';
import ReconciliationGraph from './graph';
import ReconciliationTable from './table';

const Reconciliation = () => {
  const [selectedComponent, setSelectedComponent] = useState(['all']);
  const [data, setData] = useState();
  const [insurer, setInsurer] = useState();
  const [employee, setEmployee] = useState();
  const [productType, setProductType] = useState();
  const [loading, setLoading] = useState(false);
  const [branch, setSelectedBranch] = useState('');
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));

  const fetchData = async () => {
    setLoading(true);
    const resp = await getReconciliationReport({
      start_date: startDate,
      end_date: endDate,
      insurer: insurer?.value,
      branch: branch,
      product: productType?.value,
      employee: employee,
    });
    if (resp?.data?.status) {
      setData(resp?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (insurer) fetchData();
  }, [insurer, startDate, endDate, branch, productType, employee]);

  return (
    <div className='grid gap-3'>
      <FilterBar
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        setInsurer={setInsurer}
        setEmployee={setEmployee}
        setProductType={setProductType}
        selectedComponent={selectedComponent}
        setSelectedBranch={setSelectedBranch}
        setSelectedComponent={setSelectedComponent}
      />
      {insurer ? (
        <>
          {selectedComponent?.find((ele) => ele === 'graph' || ele === 'all') && (
            <ReconciliationGraph insurer={insurer} data={data} />
          )}
          {selectedComponent?.find((ele) => ele === 'table' || ele === 'all') && (
            <ReconciliationTable
              data={data}
              loading={loading}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </>
      ) : (
        <h2 className=' text-l text-grey-90 text-center absolute top-[50%] left-[40%] '>
          Please select insurer.
        </h2>
      )}
    </div>
  );
};

export default Reconciliation;
