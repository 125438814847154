import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, CustomModal, FormContainer, Toast } from 'components';
import { interviewFormFields } from './data';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { NOTIFICATION_MSG } from 'constants';
import { getJobDetails, updateApplicationManagement, applyForJob } from 'service/api-service';
import { formatFormPayload, getQueryParams } from 'utils/common';
import { BackArrow } from 'assets/icons';

const InterviewForm = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [recruitment, setRectruitment] = useState({});
  const [isValid, setIsVaild] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [DesModal, setDesModal] = useState(false);
  const status = recruitment?.status || '';
  const navigate = useNavigate();
  const { reqId } = getQueryParams();
  const [fieldRequired, setFieldRequired] = useState(false);
  const [checkboxRequired, setCheckboxRequired] = useState(false);

  const formatClosingDate = (cDate) => {
    if (!cDate) return '';

    const formattedDate = dayjs(cDate).format('DD-MM-YYYY');
    return formattedDate;
  };

  const toggleDesModal = () => {
    setDesModal((prev) => !prev);
  };

  const HandleDescriptionModal = () => {
    setDesModal(true);
  };
  if (!reqId) {
    Toast('error', NOTIFICATION_MSG.success, 'Invalid Url');
    navigate('/');
  }

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: interviewFormFields(
      fieldRequired,
      setFieldRequired,
      checkboxRequired,
      setCheckboxRequired,
    ),
  });
  const getRecruitmentDetails = async () => {
    const resp = await getJobDetails({ id: reqId });

    if (initialValues) {
      setIsVaild(true);
    } else if (!resp?.data?.data) {
      setIsVaild(false);
    } else {
      setIsVaild(true);
    }

    setRectruitment(resp?.data?.data);
  };

  useEffect(() => {
    getRecruitmentDetails();
  }, []);

  const scheduledDate = dayjs(`${state ? state?.rec_id?.closing_date : recruitment?.c_date}`);
  const result = dayjs().isAfter(scheduledDate);

  const onApplyPress = async (data) => {
    try {
      setLoading(true);

      const payload = {
        ...data,
        job_req: reqId,
      };

      const finalPayload = formatFormPayload(payload);

      const resp = await applyForJob({ data: finalPayload });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Applied successfuly.');
        setSubmitted(true);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Something went wrong.');
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onEditPress = async (data) => {
    setLoading(true);

    const newData = getEditedFields({ initialValues: state?.initialValues, updatedData: data });
    const resp = await updateApplicationManagement({
      id: data?.id,
      data: newData,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      navigate(-1);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.msg);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className='p-4 shadow-md bg-grey-light relative overflow-y-auto'>
      <div className='flex flex-col md:flex-row justify-between items-start'>
        <button onClick={navigate.bind(this, -1)}>
          <BackArrow svgClass='fill-grey-90 w-16 md:w-10 h-16 md:h-10' />
        </button>
        <div className='mb-4  flex justify-center items-center gap-4'>
          <h3 className='flex gap-4 font-semibold   text-3xl md:text-3xl '>
            Application Form for {recruitment?.designation?.name}
          </h3>
          {/* <div onClick={HandleDescriptionModal}>
            <Tooltip title='Description'>
              <InfoCircleOutlined className='text-xl md:text-xs' />
            </Tooltip>
          </div> */}
        </div>
        <div>
          {/* <p className='text-l md:text-xs'>Closing Date : {recruitment?.c_date}</p> */}
          <p className='text-l md:text-xs'>
            Closing Date : {formatClosingDate(recruitment?.c_date)}
          </p>
        </div>
      </div>

      {/*<div class='flex flex-col col-span-full mt-2'>
            <span className=''>Description</span>
            <span
              className='text-xxs text-grey- text-justify'
              dangerouslySetInnerHTML={{ __html: recruitment?.notes }}
            ></span>
          </div>*/}

      {/* description modal */}
      <CustomModal
        open={DesModal}
        closable={true}
        onCancel={toggleDesModal}
        footer={false}
        width={500}
      >
        <p className='mt-6'>
          <h2 className='font-semibold text-center mb-6  text-xxl md:text-xxl'>
            Description for {recruitment?.designation?.name}
          </h2>
          <div
            id='html-viewer'
            className='text-l md:text-xs'
            dangerouslySetInnerHTML={{ __html: recruitment?.notes }}
          ></div>
        </p>
      </CustomModal>

      {submitted ? (
        <div className='bg-white rounded-md text-center pt-[10%] h-[74vh] p-2'>
          <h2 className='text-xl font-semibold '> Thankyou </h2>
          <h3 className='text-l'>Your Application has been Submitted. </h3>
        </div>
      ) : (
        <div className='bg-white rounded-md  overflow-y-auto max-h-[74vh] p-2'>
          {(result === true || status === false) && (
            <div className='bg-white rounded-md text-center pt-[10%] h-[70vh] p-2'>
              <p className='text-xl '> This Recruitment is closed...</p>
              <button
                className='bg-black text-white mt-4  rounded-xl px-4 py-2 '
                onClick={navigate.bind(this, -1)}
              >
                {' '}
                Go Back{' '}
              </button>
            </div>
          )}

          {result === false && isValid === true && status === true && (
            <div className='grid grid-cols-2 gap-x-5'>
              <div>
                <p className='mt-6'>
                  <h2 className='font-semibold text-center mb-6  text-xxl md:text-xxl'>
                    Description for {recruitment?.designation?.name}
                  </h2>
                  <div
                    id='html-viewer'
                    className='text-l md:text-xs'
                    dangerouslySetInnerHTML={{ __html: recruitment?.notes }}
                  ></div>
                </p>
              </div>
              <FormContainer
                accordionClass='  grid  grid-cols-1 md:grid-cols-2  gap-6'
                onSubmit={state?.initialValues ? onEditPress : onApplyPress}
                initialValues={state?.initialValues ? state?.initialValues : initialValues}
                fields={finalFields}
                customSubmitRender={({ isSubmitting, isValid, submitForm, resetForm }) => {
                  return (
                    <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                      <Button
                        text='Back'
                        variant='text'
                        type='reset'
                        classes='border border-gray-90 text-black px-6'
                        onClick={navigate.bind(this, -1)}
                      />
                      <Button
                        text={state?.initialValues ? 'Edit' : 'Apply'}
                        variant='primary'
                        disable={!isValid}
                        classes={`ml-6 px-8   ${isSubmitting || !isValid ? 'bg-grey' : 'bg-black'}`}
                        onClick={submitForm}
                        loading={loading}
                      />
                    </div>
                  );
                }}
              />
            </div>
          )}
        </div>
      )}
      {!isValid && (
        <div className='bg-white rounded-md text-center pt-[10%] h-[70vh] p-2'>
          <p className='text-xl '> Invalid Url...</p>
          <button
            className='bg-black text-white mt-4  rounded-xl px-4 py-2 '
            onClick={navigate.bind(this, -1)}
          >
            Go Back
          </button>
        </div>
      )}
    </div>
  );
};

export default InterviewForm;
