import React from 'react';

const ReconciliationList = ({ data }) => {
  const TableRow = ({ label, value }) => (
    <tr className='even:bg-grey-light'>
      <td className='text-right text-gray-700 border px-2'>{label}</td>
      <td className='text-right text-gray-700 border px-2'>{value?.toFixed(2) || '\u2014'}</td>
    </tr>
  );
  return (
    <div className='p-2 bg-white'>
      <table className='w-full mb-8 border'>
        <thead>
          <tr className='bg-grey-light'>
            <th className='text-right font-bold text-gray-700 border px-2'>Description</th>
            <th className='text-right font-bold text-gray-700 border px-2'>Amount</th>
          </tr>
        </thead>
        <tbody>
          <TableRow label='Premium' value={data?.premium} />
          <TableRow label='(+) GST' value={data?.total_gst_premium} />
          <TableRow label='Gross Premium' value={data?.gross_premium} />
          <TableRow label='(-) Insurer Commission' value={data?.total_insurer_commission} />
          <TableRow label='(-) Pos Commission' value={data?.total_pos_commission} />
          <TableRow label='(-) Employee Commission' value={data?.total_employee_commission} />
          <TableRow label='(+) Other Fees' value={data?.total_other_fees} />
          {/* <TableRow label='(-) Discount' value={data?.total_discount} /> */}
        </tbody>
        <tfoot>
          <tr>
            <td className='text-right font-bold text-gray-700 border px-2'>
              Total Company Earning
            </td>
            <td className='text-right font-bold text-gray-700 border px-2'>
              {data?.total_company_earning?.toFixed(2) || '\u2014'}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default ReconciliationList;
