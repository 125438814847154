import OneSignal from 'react-onesignal';
// const stub = {
//   getUserId: async () => "",
//   init: async () => "",
//   showSlidedownPrompt: async () => "",
// };

// export const OneSignal =
//   process.env.NODE_ENV === "development" ? stub : await import("react-onesignal");

const appId = process.env.REACT_APP_ONE_SIGNAL_KEY || '';

const runOneSignal = async () => {
  // try {
  await OneSignal.init({
    appId,
  });
  await OneSignal.showSlidedownPrompt({ force: true });
  // } catch (error) {
  //   console.log("catch", error);
  //   throw error;
  // }
};

export default runOneSignal;
