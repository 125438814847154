import { TextInput, SelectInput, DateTime, Checkbox } from 'components';
import { formatDate } from 'utils';

export const policyColumns = [
  // {
  //   title: "Policy Id",
  //   dataIndex: "policy_id",
  //   key: "policy_id",
  //   render: (_, record) => <span>{record?.policy?.id}</span>,
  // },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Date',
    dataIndex: 'datetime',
    key: 'datetime',
    render: (_, record) => {
      return (
        <span>
          {' '}
          {formatDate({
            dateString: record?.date_time,
            toUtc: false,
            format: 'MMM DD, YYYY',
          })}
        </span>
      );
    },
  },
  {
    title: 'Payment Method',
    dataIndex: 'pay_method',
    key: 'pay_method',
  },
  {
    title: 'Receipt Number',
    dataIndex: 'receipt_num',
    key: 'receipt_num',
  },
];

export const customerTransactionFields = {
  transactions: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Amount',
        name: 'amount',
        id: 'amount',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 30,
        placeholder: 'Enter Amount',
      },
      {
        label: 'Number of Premiums',
        name: 'number',
        id: 'number',
        type: 'number',
        required: true,
        component: TextInput,
        maxLength: 30,
        placeholder: 'Enter Number of Premiums',
      },
      {
        label: 'Date',
        name: 'date_time',
        id: 'date_time',
        placeholder: 'Enter Date',
        required: true,
        type: 'date',
        component: DateTime,
      },
      {
        label: 'Payment Method',
        name: 'pay_method',
        id: 'pay_method',
        placeholder: 'Select Payment Method',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { value: 'online', label: 'Online' },
          { value: 'offline', label: 'Offline' },
        ],
      },
      {
        label: 'Receipt Number',
        name: 'receipt_num',
        id: 'receipt_num',
        placeholder: 'Receipt Number',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Agent Commission Paid',
        name: 'pos_comm',
        id: 'pos_comm',
        type: 'checkbox',
        checked: false,
        component: Checkbox,
        divClasses: 'col-span-2 self-end',
      },
    ],
  },
};
