import React from 'react';
import { NavLink } from 'react-router-dom';

const INACTIVE_CLASS = `text-xxs 2xl:text-xs text-grey after:content-[''] after:inline-block after:bg-grey after:mx-2 after:my-0 after:h-3.5 after:w-px after:align-middle text-xs`;

/* funcs */
const handleClick = (event) => event.preventDefault();

const renderNavItem = (item, index) => (
  <li key={index} className='group'>
    <NavLink
      to={item.path}
      target={item.target}
      className={`${INACTIVE_CLASS} group-last:after:hidden group-last:text-primary-90 dark:text-white transition-all duration-500  ${
        item.target
          ? 'hover:text-black cursor-default'
          : 'hover:text-primary-90 dark:hover:text-primary-90'
      }`}
    >
      <span>{item.name}</span>
    </NavLink>
  </li>
);

const Breadcrumbs = ({ data }) => {
  return (
    <nav aria-label='secondary navigation' onClick={handleClick}>
      <ul className='flex gap whitespace-nowrap'>{data.map(renderNavItem)}</ul>
    </nav>
  );
};

export default Breadcrumbs;
