import React from 'react';
import { Route, Routes } from 'react-router';
import { PosNavbar } from 'components';
import {
  PosForm,
  PosHome,
  ViewPosContract,
  PosReview,
  PosInactive,
  DigitalSignature,
} from 'screens';
import PosSetting from 'screens/pos/possetting';

const Pos = () => {
  return (
    <div className='bg-grey-light'>
      <PosNavbar />
      <main className='p-4 overflow-auto flex-[18px]'>
        <Routes>
          <Route path='/' element={<PosHome />} />
          <Route path='pos-form' element={<PosForm />} />
          <Route path='pos-setting' element={<PosSetting />} />
          <Route path='pos-review' element={<PosReview />} />
          <Route path='pos-inactive' element={<PosInactive />} />
          <Route path='pos-contract' element={<ViewPosContract />} />
          <Route path='digital-signature' element={<DigitalSignature />} />
        </Routes>
      </main>
    </div>
  );
};

export default Pos;
