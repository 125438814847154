/* external deps  */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Fuse from 'fuse.js';

/* internal deps  */
import { DropdownIcon, FilledFolder, PlusIcon, SubFolder } from 'assets/icons';
import IconButton from 'components/button/IconButton';
import SearchComponent from 'components/search';

// group-open/edit:fill-primary-90   // do not remote .
const svgClass = `transition-all duration-300 group-hover:fill-primary-90 `;
// const childClass = `w-4 h-4 `;

const Folders = ({ isOpen = true, handleAddClick, data = [] }) => {
  /** states */
  const [searchQuery, setSearchQuery] = useState();
  const [filteredFolder, setFilteredFolder] = useState([]);

  let defaultClassName = `cursor-pointer flex items-center justify-between p-2 text-black font-regular transition-all duration-500 group hover:text-primary-90 gap-2 mt-2 rounded-l group-open/edit:font-medium group-open/edit:bg-primary-90/[.08] ${
    isOpen ? 'ml-2' : 'ml-6 '
  }`;
  let defaultChidName = `flex items-center justify-between p-2 text-black transition-all duration-500 group hover:text-primary-90 gap-2 rounded-l ${
    isOpen ? 'ml-4' : 'ml-6'
  }`;
  let activeChidName = `group active p-2 hover:text-primary-90 ${isOpen ? 'ml-4' : 'ml-6'}`;
  /*closes details tag when clicked outside */
  var details = [...document.querySelectorAll('details')];
  document.addEventListener('click', function (e) {
    if (!details.some((f) => f.contains(e.target))) {
      details.forEach((f) => f.removeAttribute('open'));
    } else {
      details.forEach((f) => (!f.contains(e.target) ? f.removeAttribute('open') : ''));
    }
  });
  const renderSubmenu = (item, index) => (
    <NavLink
      key={index}
      to={item?.link}
      className={({ isActive }) => (isActive ? activeChidName : defaultChidName)}
    >
      <div className={`flex gap-2 items-center`}>
        <SubFolder className={`w-4 h-4 `} />
        <span className={`text-xxs 2xl:text-xs ${isOpen ? '' : 'hidden'}`}>{item?.name}</span>
      </div>
    </NavLink>
  );
  const renderMenu = (item, index) => (
    <details key={index} className='group/edit'>
      <summary to={item?.link} className={`group ${defaultClassName}`}>
        <FilledFolder className={`w-4 h-4 ${svgClass}`} />
        <span className={`text-xs mr-auto 2xl:text-s ${isOpen ? '' : 'hidden'}`}>{item?.name}</span>
        <DropdownIcon
          svgClass={`w-3 transition-all duration-500 group-hover:fill-primary-90 group-open/edit:rotate-180 group-open/edit:hover:fill-black`}
        />
      </summary>
      <div className={`flex flex-col ${isOpen ? 'ml-2' : 'ml-6 '}`}>
        {item?.submenu?.map((item, index) => {
          return renderSubmenu(item, index);
        })}
      </div>
    </details>
  );

  /* search  */
  const onChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(data, {
      keys: ['name'],
      threshold: 0.2,
    }).search(e.target.value);
    setFilteredFolder(fuse.map((i) => i.item));
  };

  return (
    <div className='bg-white p-4 rounded-2xl min-h-[24rem]'>
      <div className='flex justify-between items-center pb-3'>
        <span className='text-sm font-medium'>Folder</span>
        <IconButton onClick={handleAddClick}>
          <PlusIcon className='w-6 h-6 fill-primary-90' />
        </IconButton>
      </div>
      <SearchComponent onChange={onChange} placeholder='Search for folders...' />
      <div className='overflow-y-auto h-72 scrollbar-hide'>
        {searchQuery
          ? filteredFolder?.map(renderMenu)
          : data?.map(renderMenu) || (
              <div className='flex justify-center items-center h-72'> No Data </div>
            )}
      </div>
    </div>
  );
};

export default Folders;
