import { Checkbox, Spin } from 'antd';
import { NoPreview } from 'assets';
import SearchComponent from 'components/search';
import { debounce } from 'utils';
import './style.css';

const InsurerSideBar = ({
  checkbox,
  agent,
  setAgent,
  data,
  width,
  header,
  loading = false,
  allbutton = false,
  showSearch = false,
  onSearch,
}) => {
  const set = (e, item) => {
    setAgent(e.target.closest('button').dataset.id);
  };
  const setall = (e) => {
    setAgent(e.target.closest('button').dataset.value);
  };

  const filterSearch = debounce((e) => {
    onSearch({ q: e.target.value });
  }, 700);

  const renderInsurer = (item) => {
    return (
      <button
        value={item.name}
        onClick={(e) => set(e, item)}
        data-id={item.id}
        className={`flex justify-between  my-1 bordered py-1 p-2 items-center rounded-lg w-full ${
          +agent === item.id ? 'bg-primary-90 text-white hover:border-black' : ' '
        }   hover:border-primary-90`}
      >
        <div className='flex h-[100%] items-center truncate'>
          <img
            src={process.env.REACT_APP_DOC_URL + item.image || NoPreview}
            alt=''
            className='h-6 w-6 bordered rounded-full'
          ></img>
          <h3 className='ml-2'>{item.name}</h3>
        </div>
        <div>
          <Checkbox
            value={item.id}
            agent={+agent === +item.id}
            style={{ display: checkbox ? ' ' : 'none' }}
          />
        </div>
      </button>
    );
  };

  return (
    <div
      id='pos-sidebar'
      className={`w-[${width}px] bordered p-2 relative overflow-y-auto bg-white rounded-lg`}
    >
      <div className='my-1 py-1 p-2 static top-0 rounded-lg h-10 w-full font-semibold'>
        {header ? `Select ${header}` : 'Common Commission'}{' '}
      </div>
      {showSearch && <SearchComponent onChange={filterSearch} />}
      {allbutton && (
        <button
          data-value='pos_all'
          onClick={setall}
          className={` flex justify-center  my-1 bordered py-2 p-2 items-center ${
            agent === 'pos_all'
              ? 'bg-primary-90 text-white hover:border-black'
              : ' hover:border-primary-90 '
          } rounded-lg w-full  `}
        >
          <div className='flex h-[100%] items-center'>
            <h3 className='ml-2 items-start font-semibold'>All</h3>
          </div>
        </button>
      )}
      <div
        className={`h-max w-full flex flex-col ${loading ? 'justify-center items-center' : ' '}`}
      >
        {loading ? <Spin size='lg' /> : data?.map(renderInsurer)}
      </div>
    </div>
  );
};

export default InsurerSideBar;
