import { useEffect, useState } from 'react';
import { CustomModal, FormContainer, Toast } from 'components';
import Button from 'components/button';
import { prepareFormFieldsData } from 'utils';
import { AssignmentFields, ClientAssignmentInitials } from './data';
import { advanceAssignment } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { AdvanceAssignmentIcon } from 'assets/icons';
import './styles.css';

const ClientAdvanceAssignments = ({
  classses,
  update,
  activeKey,
  handleAssignEmployee,
  showActive = false,
}) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setselectedProduct] = useState('');
  const [selectedcategory, setselectedCategory] = useState('');
  const [totalUnassigned, setTotalUnasigned] = useState(0);
  const [unassigned, setUnasigned] = useState(0);
  const [enteredCount, setEnteredCount] = useState(0);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: AssignmentFields({
      setselectedProduct,
      setselectedCategory,
      unassigned,
      setEnteredCount,
      enteredCount,
    }),
    // defaultValues: ClientAssignmentInitials,
  });

  const handleAssignment = () => {
    setModal((prev) => !prev);
  };

  const handleCloseModal = () => {
    setModal((prev) => !prev);
  };

  const handleAddAssignment = async (data) => {
    const finalData = {
      ...data,
      assigned_to: data?.assign_to_employee,
    };
    setLoading(true);
    if (data.customer_count > unassigned) {
      Toast('info', NOTIFICATION_MSG.info, 'Customers should be less than unassigned count');
    } else {
      const resp = await advanceAssignment({ method: 'post', data: finalData });
      if (resp?.status) {
        setModal(false);
        update({ type: activeKey });
        setselectedCategory(null);
        setselectedProduct(null);
        setTotalUnasigned(0);
        setUnasigned(0);
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, 'Please try again');
      }
    }
    setLoading(false);
  };

  const handleGetClients = async () => {
    const resp = await advanceAssignment({
      category: selectedcategory?.id,
      product: selectedProduct?.id,
    });
    if (resp?.status) {
      setTotalUnasigned(resp?.data?.data?.total_unassigned_customers_count);
      setUnasigned(resp?.data?.data?.prefer_unassigned_count);
    }
  };
  useEffect(() => {
    handleGetClients();
  }, [selectedProduct, selectedcategory]);

  useEffect(() => {
    setselectedCategory(null);
    setselectedProduct(null);
    setUnasigned(0);
    setEnteredCount(0);
  }, [modal]);

  console.log('modal', modal);
  return (
    <div id='assignmentModal' className={classses}>
      {activeKey !== 'followup' ? (
        <Button
          classes={
            showActive
              ? 'bg-primary-60 !font-medium !text-white !text-x !py-1'
              : '!py-1 !bg-grey-80 !text-black !text-x'
          }
          text='Assign Employee'
          onClick={handleAssignEmployee}
        />
      ) : null}

      {activeKey !== 'followup' ? (
        <div className='w-8' onClick={handleAssignment}>
          <AdvanceAssignmentIcon svgClass={'h-[26px] w-[26px]'} />
        </div>
      ) : null}

      <CustomModal
        closable={true}
        open={modal}
        onCancel={handleCloseModal}
        width={450}
        title={'Advance Assignment'}
        footer={false}
      >
        <div className='flex flex-col  relative'>
          <p className='font-medium text-m '>Total Unassigned Clients - {totalUnassigned || 0} </p>
          <p className='font-medium text-xs absolute top-48 z-10 mb-10  whitespace-nowrap'>
            {' '}
            Unassigned client with Selected Product - {unassigned || 0}{' '}
          </p>
          <FormContainer
            key={modal === true ? 1 : 0}
            accordionClass='!px-0 -mt-6'
            onSubmit={handleAddAssignment}
            fields={finalFields}
            initialValues={initialValues}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, values }) => {
              console.log('values', values);
              return (
                <div className='bg-white flex flex-row justify-end py-6 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      setModal(false);
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    text={'Save'}
                    variant='primary'
                    classes={`ml-6 px-8 bg-black`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default ClientAdvanceAssignments;
