import { CustomFieldArray, PhoneNumberInput, TextInput } from 'components';

export const breadCrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Helplines',
    path: '',
    target: '_blank',
  },
];

export const helplineFields = {
  transactions: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Sales',
        name: 'sales',
        id: 'sales',
        placeholder: 'Sales',
        required: true,
        type: 'number',
        maxLength: 10,
        component: TextInput,
      },
      {
        label: 'Services',
        name: 'services',
        id: 'services',
        placeholder: 'Services',
        required: true,
        type: 'number',
        maxLength: 10,
        component: TextInput,
      },
      {
        label: 'Claims',
        name: 'claims',
        id: 'claims',
        placeholder: 'Claims',
        required: true,
        type: 'number',
        maxLength: 10,
        component: TextInput,
      },
      {
        name: 'extra',
        type: 'fieldArray',
        id: 'extra',
        label: 'Other helpline Numbers',
        labelClass: 'text-m text-black',
        addButtonText: '+ More',
        valueSchema: {
          label: '',
          number: '',
        },
        initialHide: true,
        component: CustomFieldArray,
        divClasses: 'col-span-full',
        gridCols: 'grid-cols-2 py-4',
        fields: [
          {
            label: 'Name',
            name: 'label',
            id: 'label',
            placeholder: 'Name',
            type: 'text',
            maxLength: 60,
            component: TextInput,
          },
          {
            label: 'Number',
            name: 'number',
            id: 'number',
            placeholder: 'Number',
            type: 'number',
            maxLength: 10,
            component: TextInput,
          },
        ],
      },
    ],
  },
};
