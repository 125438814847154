/** external deps */
import React, { useEffect, useState } from 'react';
/** internal deps */

import { helplineFields, breadCrumbs } from './data';
import { LoadingOutlined } from '@ant-design/icons';
import { ContentHeader, Button, FormContainer, Toast } from 'components';
import { prepareFormFieldsData } from 'utils';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
import { getHelpline, postHelpline, updateHelpline } from 'service/api-service';

const Helpline = () => {
  const [loading, setLoading] = useState(false);
  const [helplineData, setHelplineData] = useState();
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: helplineFields,
  });

  /** funcs */
  const getHelplines = async () => {
    setLoading(true);
    // const response = await getHelpline();
    // if (response.data.status) {
    // setHelplineData(response?.data?.data[0]);
    // }
    setLoading(false);
  };

  const setHelplinesNumbers = async (data) => {
    const payload = {
      claims: data?.claims || '0',
      sales: data?.sales || '0',
      services: data?.services || '0',
    };
    const extraData = data?.extra && data?.extra?.every((item) => item?.name && item?.file);
    let extra = {};
    if (!extraData) {
      extra = data?.extra;
    }

    // post or patch acording to data
    if (helplineData?.claims || helplineData?.services || helplineData?.sales) {
      const resp = await updateHelpline({
        data: { ...payload, extra },
        id: helplineData?.id,
      });

      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
    } else {
      const resp = await postHelpline({
        data: { ...payload, extra },
      });

      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
    }
    getHelplines();
  };

  useEffect(() => {
    getHelplines();
  }, []);

  return (
    <>
      <div>
        <ContentHeader
          // data={breadCrumbs}
          title={<p className='font-semibold text-s'>Helplines</p>}
          showSearch={false}
        ></ContentHeader>
        <div class=''>
          {loading ? (
            <LoadingOutlined spin={true} className='mx-auto w-full my-20 h-10 w-10' />
          ) : (
            <>
              <div className='flex flex-col bg-white mt-4 px-6 pt-4'>
                <span className='text-l 2xl:text-xl font-medium'>Helpline Numbers</span>
              </div>
              <FormContainer
                accordionClass='grid bg-white px-6 py-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-9'
                fields={finalFields}
                initialValues={helplineData || initialValues}
                onSubmit={setHelplinesNumbers}
                customSubmitRender={({ isValid, isSubmitting, submitForm, resetForm }) => {
                  return (
                    <div className='bg-white flex flex-row justify-end py-4 px-6'>
                      <Button
                        id={
                          helplineData
                            ? elementData.Helpline.permissions.update
                            : elementData.Helpline.permissions.create
                        }
                        disabled={!isValid}
                        text={'Add Helpline Numbers'}
                        variant='primary'
                        classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                        onClick={submitForm}
                        loading={loading}
                      />
                    </div>
                  );
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Helpline;
