const payRole = [
  {
    employee: 'Archna',
    package: '4.5 CTC',
    taxes: '20000',
    pending_payment: '10000',
    overtime_hours: '3 hrs ',
    provident_fund: '10%',
  },
  {
    employee: 'Mahabeer',
    package: '4.5 CTC',
    taxes: '20000',
    pending_payment: '10000',
    overtime_hours: '4 hrs',
    provident_fund: '10%',
  },
  {
    employee: 'Ritik',
    package: '4.5 CTC',
    taxes: '20000',
    pending_payment: '15000',
    overtime_hours: '3 hrs',
    provident_fund: '10%',
  },
  {
    employee: 'Pankaj',
    package: '4.5 CTC',
    taxes: '20000',
    pending_payment: '10000',
    overtime_hours: '3 hrs',
    provident_fund: '10%',
  },
];

export { payRole };
