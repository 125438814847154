import { DateTime, PhoneNumberInput, SelectInput, TextInput, Checkbox } from 'components';

//emergency contact tab data
export const addEmergencyContactFields = {
  'Contact details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Contact Name',
        name: 'name',
        id: 'name',
        placeholder: 'Contact Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Home Phone',
        name: 'home_phone',
        id: 'home_phone',
        placeholder: 'Home Phone',
        type: 'number',
        maxLength: 10,
        component: TextInput,
      },
      {
        label: 'Office Phone',
        name: 'office_phone',
        id: 'office_phone',
        placeholder: 'Office Phone',
        type: 'number',
        maxLength: 10,
        component: TextInput,
      },
      {
        label: 'Mobile',
        name: 'mobile',
        id: 'mobile',
        placeholder: 'Mobile',
        required: true,
        type: 'number',
        maxLength: 10,
        component: TextInput,
      },
      {
        label: 'Relationship',
        name: 'relationship',
        id: 'relationship',
        placeholder: 'Relationship',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { label: 'Husband', value: 'husband' },
          { label: 'Wife', value: 'wife' },
          { label: 'Father', value: 'father' },
          { label: 'Mother', value: 'mother' },
          { label: 'Brother', value: 'brother' },
          { label: 'Sister', value: 'sister' },
          { label: 'Cousin', value: 'cousin' },
          { label: 'Aunt', value: 'aunt' },
          { label: 'Uncle', value: 'uncle' },
        ],
      },
    ],
  },
};

export const emergencyContactColumns = [
  {
    title: 'Contact Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Home Phone',
    dataIndex: 'home_phone',
    key: 'home_phone',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Office Phone',
    dataIndex: 'office_phone',
    key: 'office_phone',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Mobile',
    dataIndex: 'mobile',
    key: 'mobile',
  },
  {
    title: 'Relationship',
    dataIndex: 'relationship',
    key: 'relationship',
  },
];

//project tab data
export const addProjectFields = {
  'Project details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Project Title',
        name: 'title',
        id: 'title',
        placeholder: 'Project Title',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Description',
        name: 'description',
        id: 'description',
        placeholder: 'Description',
        type: 'text',
        component: TextInput,
        required: true,
        isMultiline: true,
        maxLength: 300,
      },
      {
        label: 'Designation',
        name: 'designation',
        id: 'designation',
        placeholder: 'Designation',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Date',
        name: 'date',
        id: 'date',
        placeholder: 'MM-DD-YYYY',
        type: 'date',
        required: true,
        showTime: false,
        component: DateTime,
      },
      {
        label: 'Project URL',
        name: 'url',
        id: 'url',
        placeholder: 'Project URL',
        type: 'text',
        component: TextInput,
        maxLength: 200,
      },
    ],
  },
};

export const projectColumns = [
  {
    title: 'Project Name',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Designation',
    dataIndex: 'designation',
    key: 'designation',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Project URL',
    dataIndex: 'url',
    key: 'url',
  },
];

//education tab data
export const addEducationFields = {
  'Education details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Institution Name',
        name: 'name',
        id: 'name',
        placeholder: 'Institution Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Location',
        name: 'location',
        id: 'location',
        placeholder: 'Enter Location',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Start Date',
        name: 'start_date',
        id: 'start_date',
        placeholder: 'MM-DD-YYYY',
        type: 'date',
        required: true,
        showTime: false,
        component: DateTime,
        disableNext: true,
      },
      {
        label: 'Currently Studying',
        name: 'studying',
        id: 'studying',
        type: 'checkbox',
        component: Checkbox,
        divClasses: 'self-end',
      },
      {
        dependent: true,
        dependent_on: 'studying',
        dependent_value: false,
        label: 'End Date',
        name: 'end_date',
        id: 'end_date',
        placeholder: 'MM-DD-YYYY',
        type: 'date',
        showTime: false,
        component: DateTime,
      },

      {
        label: 'Education Level',
        name: 'level',
        id: 'level',
        placeholder: 'Education Level',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { label: 'Part time', value: 'Part time' },
          { label: 'Full time', value: 'Full time' },
        ],
      },
      {
        label: 'Area of Study',
        name: 'study',
        id: 'study',
        placeholder: 'Area of study',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { label: 'Part time', value: 'Part time' },
          { label: 'Full time', value: 'Full time' },
        ],
      },
      {
        label: 'Type of Course',
        name: 'course_type',
        id: 'course_type',
        placeholder: 'Type of Course',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { label: 'Part time', value: 'Part time' },
          { label: 'Full time', value: 'Full time' },
        ],
      },
      {
        label: 'Description',
        name: 'description',
        id: 'description',
        placeholder: 'Description',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        maxLength: 300,
      },
    ],
  },
};

export const educationColumns = [
  {
    title: 'Institute Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: 'start_date',
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    key: 'end_date',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Education Level',
    dataIndex: 'level',
    key: 'level',
  },
  {
    title: 'Area of Study',
    dataIndex: 'study',
    key: 'study',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Course Type',
    dataIndex: 'course_type',
    key: 'course_type',
  },
];

//work experience data

export const addExperienceFields = {
  'Work details': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Company Name',
        name: 'name',
        id: 'name',
        placeholder: 'Company Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Designation',
        name: 'designation',
        id: 'designation',
        placeholder: 'Designation',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Location',
        name: 'location',
        id: 'location',
        placeholder: 'Location',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Job Type',
        name: 'job_type',
        id: 'job_type',
        placeholder: 'Select Job Type',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { label: 'Part time', value: 'part time' },
          { label: 'Full time', value: 'full time' },
        ],
      },
      {
        label: 'Start Date',
        name: 'start_date',
        id: 'start_date',
        placeholder: 'MM-DD-YYYY',
        type: 'date',
        required: true,
        showTime: false,
        component: DateTime,
      },
      {
        label: 'Currently Working',
        name: 'working',
        id: 'working',
        type: 'checkbox',
        component: Checkbox,
        divClasses: 'self-end',
      },
      {
        dependent: true,
        dependent_on: 'working',
        dependent_value: false,
        label: 'End Date',
        name: 'end_date',
        id: 'end_date',
        placeholder: 'MM-DD-YYYY',
        type: 'date',
        showTime: false,
        component: DateTime,
      },
      {
        label: 'Description',
        name: 'description',
        id: 'description',
        placeholder: 'Description',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        maxLength: 300,
      },
    ],
  },
};

export const workExperienceColumns = [
  {
    title: 'Company Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Designation',
    dataIndex: 'designation',
    key: 'designation',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
  },
  {
    title: 'Job Type',
    dataIndex: 'job_type',
    key: 'job_type',
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: 'start_date',
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    key: 'end_date',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

//language Skills data
export const languageColumns = [
  {
    key: 'language',
    title: 'Language',
    dataIndex: 'language',
    render: (text) => text || '\u2014',
  },
  {
    key: 'status',
    title: 'level',
    dataIndex: 'level',
    render: (text) => text || '--',
  },
];

export const skillsColumns = [
  {
    key: 'skill',
    title: 'Skill',
    dataIndex: 'skill',
    render: (text) => text || '\u2014',
  },
  {
    key: 'proficiency',
    title: 'Proficiency',
    dataIndex: 'proficiency',
    render: (text) => text || '\u2014',
  },
];

export const addLanguageFields = {
  'Add Language': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Select Language',
        name: 'language',
        id: 'language',
        placeholder: 'Select Language',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          {
            label: 'Punjabi',
            value: 'Punjabi',
          },
          {
            label: 'English',
            value: 'English',
          },
          {
            label: 'Other',
            value: 'Other',
          },
        ],
      },
      {
        dependent: true,
        dependent_on: 'language',
        dependent_value: 'Other',
        label: 'Other Language',
        name: 'other',
        id: 'other',
        placeholder: 'Other Language',
        type: 'text',
        component: TextInput,
        maxLength: 50,
      },
      {
        label: 'Level',
        name: 'level',
        id: 'level',
        placeholder: 'Select Level',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          {
            label: 'Elementary',
            value: 'EY',
          },
          {
            label: 'Limited Working',
            value: 'LW',
          },
          {
            label: 'Native of Bilingual',
            value: 'NB',
          },
          {
            label: 'Full Professional',
            value: 'FP',
          },
          {
            label: 'Other',
            value: 'OT',
          },
        ],
      },
    ],
  },
};

export const addLanguageInitials = {
  language: '',
  level: '',
};

export const addSkillsFields = {
  'Add Skill': {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Skill',
        name: 'skill',
        id: 'skill',
        placeholder: 'Select Skill',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          {
            label: 'Communication',
            value: 'Communication',
          },
          {
            label: 'Adaptability',
            value: 'Adaptability',
          },
          {
            label: 'Leadership',
            value: 'Leadership',
          },
        ],
      },
      {
        label: 'Proficiency',
        name: 'proficiency',
        id: 'proficiency',
        placeholder: 'Select Proficiency',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          {
            label: 'Beginner',
            value: 'Beginner',
          },
          {
            label: 'Intermediate',
            value: 'Intermediate',
          },
          {
            label: 'Expert',
            value: 'Expert',
          },
        ],
      },
    ],
  },
};

export const addSkillsInitials = {
  skill: '',
  proficiency: '',
};
