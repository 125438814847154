import { endpoints } from 'constants/endpoints';
import Request from '../client';

export const getAllowance = async () => {
  const resp = await Request({
    method: 'get',
    API: endpoints.allowance.create,
  });
  return resp;
};
export const allowanceVariant = async ({ variant = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.allowance.variant(variant),
  });
  return resp;
};
export const createAllowance = async ({ data = undefined }) => {
  const resp = await Request({
    method: 'post',
    API: endpoints.allowance.create,
    data: data,
  });
  return resp;
};
export const editAllowance = async ({ id = '', data = undefined }) => {
  const resp = await Request({
    method: 'patch',
    API: endpoints.allowance.update(id),
    data: data,
  });
  return resp;
};
export const deleteAllowance = async ({ id = '' }) => {
  const resp = await Request({
    method: 'delete',
    API: endpoints.allowance.delete(id),
  });
  return resp;
};
