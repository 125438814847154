import Request from './client';
import { endpoints } from 'constants/endpoints';

export const reasonManagement = async (props = { data: '', method: 'get', id: '' }) => {
  const resp = await Request({
    method: props.method,
    API: props.method === 'patch' ? endpoints.reasons.patch(props.id) : endpoints.reasons.post,
    data: props?.data,
  });
  return resp;
};

export const getReasons = async ({ type = '' }) => {
  const resp = await Request({
    method: 'get',
    API: endpoints.reasons.getByType(type),
  });
  return resp;
};
