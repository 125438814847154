import { Detail } from '.';

const ProductCard = ({ productData, quotationsData, count }) => {
  let sorderData = [];
  const sortedProduct = quotationsData?.products?.find(
    (ele) => ele?.product_type?.id === productData?.productId,
  );
  const sortedCategory = quotationsData?.ins_cat_id?.find(
    (ele) => ele?.id === productData?.categoryId,
  );

  Object.entries(productData).forEach(([key, value]) => {
    const obj = {
      label: key,
      value: value?.name || value,
    };
    if (
      obj?.label !== 'formId' ||
      obj?.label !== 'value' ||
      obj?.label !== 'productId' ||
      obj?.label !== 'categoryId'
    ) {
      sorderData.push(obj);
    }
  });

  return (
    <div className='pl-4 pr-20 shadow-shadowmd py-4 rounded-2xl bg-white mt-4'>
      <div className='text-s font-semibold leading-normal'>
        <span>
          #{count} {sortedCategory?.name} -
        </span>
        <span className='!font-bold '>{sortedProduct?.product_type?.name}</span>
      </div>
      <div className='grid grid-cols-3 grid-rows-3 mt-4 gap-y-3'>
        {sorderData.map(({ label, value }) => {
          return <Detail label={label} value={value} />;
        })}
      </div>
    </div>
  );
};

export default ProductCard;
