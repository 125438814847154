import Request from 'service/api-service/client';

export const rtoNumberFetch = async ({ q = '' }) => {
  return await Request({
    method: 'get',
    API: `product/rto-number?q=${q}`,
  });
};

export const getVehicleBrands = async ({ type = 'car', q = '' }) => {
  return await Request({
    method: 'get',
    API: `product/brand?category=${type}&q=${q}`,
  });
};

export const getVehicleBrandModal = async ({ q = '', brand }) => {
  return await Request({
    method: 'get',
    API: `product/brand-model?id=${brand}&q=${q}`,
  });
};

export const getVehicleFuelType = async ({ model }) => {
  return await Request({
    method: 'get',
    API: `product/model-fuel?id=${model}`,
  });
};

export const getVehicleVarient = async ({ model, q = '', fuelType }) => {
  return await Request({
    method: 'get',
    API: `product/variants?id=${model}&fuel=${fuelType}&q=${q}`,
  });
};
