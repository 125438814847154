/** external deps */
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
/** internal deps */
import { getAdminStatistics, getEmployeeStaticCount } from 'service/api-service';
import { DisplayCard } from 'components';
import {
  AgentIcon,
  CustDashIcon,
  EmpDashIcon,
  InsurerIcon,
  LeadsIcon,
  PolicyIcon,
  RetailerIcon,
} from 'assets/icons';
import './style.css';
import { StatsIconClass } from 'utils/common';

const DashbaordStatistics = ({ permissions, user }) => {
  /* states */
  const [dataSource, setdataSource] = useState();

  /* func */
  const fetchData = async () => {
    if (user?.position === 'Admin') {
      const resp = await getAdminStatistics();
      const dataCount = resp?.data?.status;
      setdataSource(dataCount);
    } else {
      const resp = await getEmployeeStaticCount({ id: user?.id });
      const dataCount = resp?.data?.data;
      setdataSource(dataCount);
    }
  };

  let data = [
    {
      id: 'total_employee',
      text: 'Employees',
      link: 'employees',
      permission: permissions?.Employee?.read,
      icon: <EmpDashIcon svgClass='stroke-[#8FA1FF] h-[36px]' />,
      total: dataSource?.total_employees || 0,
      percent: dataSource?.total_employees_percentage_difference || 0,
      bg: 'bg-[#E9ECFC]',
      filterValue: 'employees',
    },
    {
      id: 'total_retail_customers',
      text: 'Retailers',
      permission: permissions?.Client?.read,
      link: 'client?tab=retail',
      icon: <RetailerIcon svgClass='fill-[#FDD7C1] h-[36px]' />,
      total: dataSource?.total_retail_customers || 0,
      percent: dataSource?.total_retail_customers_percentage_difference || 0,
      bg: 'bg-[#F9EFE9]',
      filterValue: 'clients',
    },
    {
      id: 'total_corporation_customers',
      text: 'Corporates',
      permission: permissions?.Client?.read,
      link: 'client?tab=corporation',
      icon: <CustDashIcon svgClass='stroke-[#FFC6E0] h-[36px]' />,
      total: dataSource?.total_corporation_customers || 0,
      percent: dataSource?.total_corporation_customers_percentage_difference || 0,
      bg: 'bg-[#F9EAF1]',
      filterValue: 'clients',
    },

    {
      id: 'lead',
      text: 'Leads',
      link: 'lead',
      permission: permissions?.Sales?.children?.Leads?.read,
      icon: <LeadsIcon svgClass='fill-[#8FA1FF] h-[36px]' />,
      total: dataSource?.total_leads || 0,
      percent: dataSource?.total_leads_percentage_difference || 0,
      bg: 'bg-[#DFF2FD]',
      filterValue: 'leads',
    },
    {
      id: 'total_insurer',
      text: 'Insurers',
      link: 'insurer',
      permission: permissions?.Operations?.children?.Insurer?.read,
      icon: <InsurerIcon svgClass='stroke-[#1FDE5E] h-[36px]' />,
      total: dataSource?.total_insurers || 0,
      percent: dataSource?.total_insurers_percentage_difference || 0,
      bg: 'bg-[#DDF2E4]',
    },
    {
      id: 'policy',
      text: 'Policies',
      link: 'policy',
      permission: user?.user_type === 'employee' ? false : permissions?.Policy?.read,
      icon: <PolicyIcon svgClass='stroke-[#8FA1FF] h-[36px]' />,
      total: dataSource?.total_policies || 0,
      percent: dataSource?.total_policies_percentage_difference || 0,
      bg: 'bg-[#F9C5CA]',
    },
    {
      id: 'agent',
      text: 'Agents',
      permission: user?.user_type === 'employee' ? false : permissions?.Sales?.children?.POS?.read,
      link: 'management',
      icon: <AgentIcon svgClass='stroke-[#8AD1FB] h-[36px]' />,
      total: dataSource?.total_agents || 0,
      percent: dataSource?.total_agents_percentage_difference || 0,
      bg: 'bg-[#F9FAE9]',
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const finalData = data?.filter((item) => item?.permission === true);

  return (
    <div className={`grid gap-4 md:grid-cols-5 lg:grid-cols-7 sm:grid-cols-3`}>
      {finalData?.map((data, index) => (
        <motion.div
          key={index}
          className='w-full'
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 0.6 }}
        >
          <DisplayCard {...data} />
        </motion.div>
      ))}
    </div>
  );
};

export default DashbaordStatistics;
