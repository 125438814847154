import { Button } from 'antd';
import React from 'react';
import './style.css';

const CustomTab = ({ label, period, setPeriod }) => {
  /*funcs  */
  const handleClick = () => {
    setPeriod(label?.value);
    window.history.replaceState(null, null, `/lead?active=${label?.value}`);
  };

  return (
    <Button
      type=''
      className={`capitalize border-0 ${period === label?.value ? 'bg-primary-60 text-white' : ''}`}
      onClick={handleClick}
    >
      {label?.label}
    </Button>
  );
};

export default CustomTab;
