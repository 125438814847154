/** external deps */
import React from 'react';
import { Modal } from 'antd';
/** internal deps */
import { Close, ModalCrossIcon } from 'assets/icons';
import './style.css';

const CustomModal = ({
  open,
  closable = true,
  title,
  footer,
  onCancel,
  children,
  centered = true,
  width = 900,
  height = 'auto',
  overflow,
  destroyOnClose = false,
}) => {
  return (
    <Modal
      className='rounded-2xl '
      destroyOnClose={destroyOnClose}
      closeIcon={<ModalCrossIcon classes='ml-3 -mt-4 ' />}
      bodyStyle={{ height: height, overflow: overflow }}
      footer={<div className={'pb-4'}>{footer}</div>}
      cancelText=''
      title={
        <div className='bg-primary-60 w-full h-9 text-white pl-4 flex items-center rounded-t-lg'>
          {title}
        </div>
      }
      open={open}
      closable={closable}
      onCancel={onCancel}
      centered={centered}
      width={width}
      maskClosable={false}
    >
      <div className={`px-4 ${footer ? '' : 'pb-4'}`}>{children}</div>
    </Modal>
  );
};

export default CustomModal;
