import { CustomModal, IconButton, SearchComponent, TableComponent, Toast } from 'components';
import { columns } from './data';
import { elementData } from 'elements';
import { Space } from 'antd';
import { DELETE, EDIT, Trash, View } from 'assets/icons';
import { useEffect, useState } from 'react';
import { customerCallLogs } from 'service/api-service';
import { useNavigate } from 'react-router-dom';
import Button from 'components/button';
import { NOTIFICATION_MSG } from 'constants';
import { rowCommonClass } from 'utils/common';

const ClientCallLogs = ({ customer_id, permissions }) => {
  const navigate = useNavigate();
  const [tabelData, setTabelData] = useState([]);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');

  const onsearch = (e, val) => {};
  const toggleDelete = () => setDeleteModal((pre) => !pre);

  const fetchCallLogs = async () => {
    const resp = await customerCallLogs({ q: query, customer_id: customer_id });
    if (resp?.status) {
      setTabelData(resp?.data?.data);
    }
  };
  const onDeleteLog = async () => {
    setLoading(true);
    const resp = await customerCallLogs({
      method: 'delete',
      q: query,
      customer_id: customer_id,
      id: selectedRow?.id,
    });
    if (resp?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Log deleted successfully');
      toggleDelete();
      fetchCallLogs();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCallLogs();
  }, [query]);

  return (
    <main className='mt-4'>
      <div className='bg-white rounded-2xl rounded-b-none p-4 flex justify-between items-center'>
        <h2 className='font-semibold text-l'>Call Logs</h2>
        <SearchComponent onChange={onsearch} />
      </div>
      <TableComponent
        getRowClassName={rowCommonClass}
        dataSource={tabelData}
        columns={[
          ...columns,
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (data, record) => (
              <Space size='middle'>
                <IconButton
                  title='View'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.client.permissions.view}
                  onClick={() => navigate('/view-client-log', { state: { initialValues: record } })}
                >
                  <View svgClass='stroke-primary group-disabled:stroke-grey' />
                </IconButton>

                <IconButton
                  title='Edit'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.client.permissions.delete}
                  onClick={() =>
                    navigate('/lead/create/new/1', {
                      state: {
                        logValues: {
                          ...record,
                          customer: { ...record?.cusomer, name: record?.customer?.customer_name },
                        },
                      },
                    })
                  }
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Delete'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.client.permissions.delete}
                  onClick={() => {
                    toggleDelete();
                    setSelectedRow(data);
                  }}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton>
              </Space>
            ),
          },
        ]}
      />
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDelete}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the log details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDelete}
            />
            <Button
              id={elementData.Leads.permissions.delete}
              permissions={permissions}
              text='Delete '
              variant='primary'
              classes={`ml-6 px-8   
             bg-black
            `}
              onClick={onDeleteLog}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </main>
  );
};

export default ClientCallLogs;
