/** external deps */
import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import dayjs from 'dayjs';
/** internal deps */
import { Button, CustomModal, FormContainer, TableComponent, Toast } from 'components';
import { allocateAssetsFields } from '../data';
import { Trash } from 'assets/icons';
import { prepareFormFieldsData } from 'utils';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { getAllocatedAssets } from 'service/api-service';
import { getFilteredColumns, getQueryParams, rowCommonClass } from 'utils/common';
import { NOTIFICATION_MSG } from 'constants';

const FilteredAssetRequest = (props = {}) => {
  const permissions = props?.permissions || [];
  const showAllocateModal = props?.showAllocateModal;
  const setAllocateModal = props?.setAllocateModal;
  const employee_id = props?.employee;
  const loading = props?.loading;
  const setLoading = props?.setLoading;
  const tableData = props?.tableData;
  const setTableData = props?.setTableData;
  /* states  */
  const [pageNum, setPageNum] = useState(1);
  const [total, setTotal] = useState(0);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [editInitials, setInitials] = useState();
  const [selectedAsset, setSelectedAsset] = useState();
  const { tab } = getQueryParams();

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: allocateAssetsFields,
    defaultValues: { employee: employee_id },
  });

  const AllocatedColumns = [
    {
      key: 'requested_by',
      title: 'Employee',
      dataIndex: 'requested_by',
      render: (data) => data?.name,
    },
    {
      key: 'asset_type',
      title: 'Asset Type',
      dataIndex: 'asset_type',
      render: (data) => {
        return (
          <div className='flex gap-1 flex-wrap'>
            {data.length > 0
              ? data?.map((item, index) => (
                  <div className='bg-grey-40 py-1 px-3 rounded-md text-xxxs' key={index}>
                    {item?.name}
                  </div>
                ))
              : '\u2014'}
          </div>
        );
      },
    },
    {
      key: 'allocation_date',
      title: 'Allocation Date',
      dataIndex: 'allocation_date',
    },
    {
      key: 'asset',
      title: 'Asset Name',
      dataIndex: 'asset',
      render: (data) => {
        return (
          <div className='flex gap-1 flex-wrap'>
            {data.length > 0
              ? data?.map((item, index) => (
                  <div className='bg-grey-40 py-1 px-3 rounded-md text-xxxs' key={index}>
                    {item?.name}({item?.ser_no})
                  </div>
                ))
              : '\u2014'}
          </div>
        );
      },
    },
    {
      key: 'updated',
      title: 'Revoked Date',
      dataIndex: 'updated',
      render: (data) => dayjs(data).format('YYYY-MM-DD'),
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <>
          {tab === 'revoked' ? null : tab === 'allocated' ? (
            <Button
              id={elementData.assetAllocation.permissions.update}
              permissions={permissions}
              classes={'!py-1'}
              text='Revoke'
              onClick={() => {
                setSelectedAsset(record);
                toggleDeleteAsset();
              }}
            />
          ) : (
            <Space size='middle'>
              <Button
                id={elementData.assetAllocation.permissions.update}
                permissions={permissions}
                classes={'!py-1'}
                text='Decline'
                variant='secondary'
                onClick={onDeclineAsset.bind(this, record)}
              />
              <Button
                id={elementData.assetAllocation.permissions.update}
                permissions={permissions}
                classes={'!py-1'}
                text='Allocate'
                variant='secondary'
                onClick={onAllocate.bind(this, record)}
              />
            </Space>
          )}
        </>
      ),
    },
  ];

  /* funcs */
  const getAllData = async () => {
    setLoading(true);
    const status = tab === 'allocated' ? 'approved' : tab === 'revoked' ? 'declined' : 'pending';
    const resp = await getAllocatedAssets({ method: 'get', status, employee_id });
    if (resp?.data?.data) {
      setTableData(resp?.data?.data?.result);
      setPageNum(resp?.data?.data.current_page);
      setTotal(resp?.data?.data.total_page);
    } else {
      Toast('error', NOTIFICATION_MSG.error, 'Please try again ' || resp?.error);
    }
    setLoading(false);
  };

  const onAllocateAsset = async (data) => {
    setLoading(true);
    // setEmployee(data?.employee);
    const payload = {
      ...data,
      requested_by: data?.employee ? data?.employee : employee_id,
      asset_type: [data?.asset_type],
      status: 'approved',
    };
    const resp = await getAllocatedAssets({ method: 'post', data: payload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      // getByEmployee(employee);
      getAllData();
      toggleAllocateAsset();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onApproveAllocate = async (data) => {
    setLoading(true);
    const payload = {
      asset: data?.asset,
      status: 'approved',
    };
    const resp = await getAllocatedAssets({
      method: 'patch',
      id: editInitials?.id,
      data: payload,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      // getByEmployee(employee);
      getAllData();
      toggleAllocateAsset();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onDeclineAsset = async (data) => {
    setLoading(true);
    const payload = {
      status: 'declined',
    };
    const resp = await getAllocatedAssets({
      method: 'patch',
      id: data?.id,
      data: payload,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      // getByEmployee(employee);
      getAllData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onDeleteAsset = async () => {
    setLoading(true);
    const resp = await getAllocatedAssets({
      method: 'patch',
      id: selectedAsset?.id,
      data: { status: 'declined' },
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Asset revoked successfully');
      // getByEmployee(employee);
      getAllData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteAsset();
    setLoading(false);
  };

  const onAllocate = (data) => {
    const initVals = { ...data, employee: data?.requested_by };
    setInitials(initVals);
    setAllocateModal((pre) => !pre);
  };

  const toggleAllocateAsset = () => {
    setInitials(undefined);
    setAllocateModal((pre) => !pre);
  };
  const toggleDeleteAsset = () => setDeleteModal((pre) => !pre);

  const handlePagination = async (pageNum) => {
    setLoading(true);
    const status = tab === '2' ? 'approved' : tab === '3' ? 'declined' : 'pending';
    const resp = await getAllocatedAssets({ method: 'get', status, page: pageNum });
    if (resp?.data?.data) {
      setTableData(resp?.data?.data?.result);
      setPageNum(resp?.data?.data.current_page);
      setTotal(resp?.data?.data.total_page);
    } else {
      Toast('error', NOTIFICATION_MSG.error, 'Please try again ' || resp?.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <div>
      <TableComponent
        getRowClassName={rowCommonClass}
        loading={loading}
        columns={getFilteredColumns(tab, AllocatedColumns)}
        dataSource={tableData}
        total={total}
        onChange={handlePagination}
        defaultPage={pageNum}
      />
      {/* Allocate Asset modal */}
      <CustomModal
        destroyOnClose={true}
        open={showAllocateModal}
        onCancel={toggleAllocateAsset}
        footer={''}
        width={600}
      >
        <FormContainer
          accordionClass='grid gap-8'
          onSubmit={editInitials ? onApproveAllocate : onAllocateAsset}
          initialValues={editInitials || initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    toggleAllocateAsset();
                  }}
                />
                <Button
                  disabled={!isValid}
                  permissions={permissions}
                  id={
                    editInitials
                      ? elementData.assetAllocation.permissions.update
                      : elementData.assetAllocation.permissions.create
                  }
                  text='Save'
                  variant='primary'
                  classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={submitForm}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </CustomModal>

      {/* delete asset confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteAsset}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to revoke the asset?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteAsset}
            />
            <Button
              text='Revoke Asset'
              variant='primary'
              classes='ml-6 px-8bg-black'
              onClick={onDeleteAsset}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(FilteredAssetRequest);
