import React, { useState } from 'react';

import { CustomModal, FormContainer, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { prepareFormFieldsData } from 'utils';
import { AssigneeFields } from '../data';
import Button from 'components/button';
import { elementData } from 'elements';
import { upadteShift } from 'service/api-service';

const AssignModal = ({ open, onCancel, permissions, id, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const [assingeeInitialValues, assigneeFinalFields] = prepareFormFieldsData({
    fields: AssigneeFields,
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const resp = await upadteShift({
      data,
      id,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      onSuccess();
      onCancel();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to update data');
    }
    setLoading(false);
  };

  return (
    <CustomModal
      title={'Add Assignees'}
      open={open}
      closable={true}
      onCancel={onCancel}
      footer={''}
      width={550}
    >
      <div>
        <span className='text-xxs 2xl:text-sm '>
          {'To add a new Assignees, enter the details of the shift in the input field below.'}
        </span>

        <FormContainer
          accordionClass='grid grid-cols-full  gap-8'
          onSubmit={onSubmit}
          initialValues={assingeeInitialValues}
          fields={assigneeFinalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm, values }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                />
                <Button
                  disabled={!isValid}
                  permissions={permissions}
                  id={elementData.attendance_settings.permissions.update}
                  text='Add'
                  variant='primary'
                  classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                  onClick={async () => {
                    await submitForm();
                    resetForm();
                  }}
                  loading={loading}
                />
              </div>
            );
          }}
        />
      </div>
    </CustomModal>
  );
};

export default AssignModal;
