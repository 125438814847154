/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { Skeleton, Checkbox, Tooltip } from 'antd';
/** internal deps */
import { selectStyles, DropdownIndicator } from './data';
import { InfoCircleOutlined } from '@ant-design/icons';
import { debounce } from 'utils';

const SELECT_ALL_OPTION = {
  value: 'select-all',
  label: 'All',
};

const CheckBoxComponent = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  values,
  ...rest
}) => {
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={innerProps}
    >
      <div className='flex justify-between'>
        {children}
        <Checkbox checked={isSelected || values?.includes(rest.value)} />
      </div>
    </components.Option>
  );
};

const AsyncMultiSelect = ({
  form,
  field,
  options = [],
  defaultValue,
  menuPlacement = 'auto',
  dependantFetchOptions,
  dependency = [],
  divClasses,
  closeMenuOnSelect = true,
  disabled = false,
  isClearable = true,
  isSearchable = true,
  placeholder = 'Select Option',
  required = false,
  customChange,
  remarks,
  extraRightRender,
  fetchOptions,
  customOnChange,
  paginatedSearch,
  ...props
}) => {
  const [animatedComponents] = useState(makeAnimated());
  const [apiOptions, setOptions] = useState(options || []);
  const [loading, setLoading] = useState(false);

  const isSelectAllSelected = (e) => {
    if (!e || e.length === 0) return false;
    return e?.length === apiOptions?.length;
  };

  const getValue = () => {
    if (isSelectAllSelected(form?.values[field?.name])) {
      return SELECT_ALL_OPTION;
    } else {
      const keys = field.name.split('.');
      const values = keys.length > 1 ? form?.values[keys[0]] : form?.values[field?.name];
      return apiOptions?.filter(
        (option) => values?.includes(option.value) || values?.includes(option.label),
      );
    }
  };

  const promiseOptions = debounce((...args) => {
    setLoading(true);
    fetchOptions().then((resp) => {
      if (paginatedSearch && resp && resp?.data && resp?.data.data) {
        setOptions(
          resp?.data?.data?.result.map((i) => {
            return {
              ...i,
              label: i?.name || i?.label,
              value: i?.id,
            };
          }),
        );
        return resp?.data?.data?.result.map((i) => {
          return {
            ...i,
            label: i?.name || i?.label,
            value: i?.id,
          };
        });
      }
    });
    setLoading(false);
  }, 700);

  const _onChange = (e, actionMeta) => {
    if (form?.setFieldValue) {
      const { action, option, removedValue } = actionMeta;
      let values = [];
      if (action === 'select-option' && option.value === SELECT_ALL_OPTION.value) {
        values = apiOptions;
      } else if (
        (action === 'deselect-option' && option.value === SELECT_ALL_OPTION.value) ||
        (action === 'remove-value' && removedValue.value === SELECT_ALL_OPTION.value)
      ) {
        values = [];
      } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected(e)) {
        values = apiOptions?.filter(({ value }) => value !== option.value);
      } else {
        values = e.filter((option) => option.value !== SELECT_ALL_OPTION.value);
      }

      if (customOnChange) {
        customOnChange(field.name, e, form);
      }
      return form?.setFieldValue(
        field.name,
        values.map((e) => e.value),
      );
    }
  };

  useEffect(() => {
    if (!options.length && fetchOptions) {
      setLoading(true);
      fetchOptions().then((resp) => {
        if (paginatedSearch && resp && resp?.data && resp?.data.data) {
          setOptions(
            resp?.data?.data?.result.map((i) => {
              return {
                ...i,
                label: i?.name || i?.label,
                value: i?.id,
              };
            }),
          );
        } else if (resp && resp?.data && resp?.data.status) {
          setOptions(
            resp?.data?.data.map((i) => {
              return {
                ...i,
                label: i?.name || i?.label,
                value: i?.id,
              };
            }),
          );
        }
      });
      setLoading(false);
    }
  }, []);

  useEffect(
    () => {
      if (dependantFetchOptions && typeof dependantFetchOptions === 'function') {
        setLoading(true);
        dependantFetchOptions(form).then((resp) => {
          if (resp?.length) {
            setOptions(
              resp.map((i) => {
                return {
                  ...i,
                  label: i?.name,
                  value: i?.id,
                };
              }),
            );
          }
          setLoading(false);
        });
      }
    },
    dependency.map((item) => form?.values?.[item]),
  );

  useEffect(() => {
    if (defaultValue) {
      form?.setFieldValue(field?.name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <div
      className={
        props?.subTitle && `flex justify-between  col-span-full border-t border-grey-80 pt-4`
      }
    >
      {props?.subTitle && props?.subTitle}
      <div
        id='select-list-item'
        className={`group ${divClasses}  ${disabled ? 'cursor-not-allowed' : ''}`}
      >
        <div className='flex pr-2 items-center justify-between'>
          <label
            className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xs text-black ${
              required ? 'required' : ''
            }`}
          >
            {props?.label}
          </label>
          {props?.tooltip && (
            <Tooltip title={props.tooltip}>
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </div>
        <div className='relative '>
          {loading ? (
            <Skeleton.Input active={true} size='large' />
          ) : (
            <AsyncSelect
              options={apiOptions.length > 0 ? [SELECT_ALL_OPTION, ...apiOptions] : []}
              value={getValue()}
              name={field?.name}
              isDisabled={disabled}
              isLoading={props?.loading || loading}
              classNamePrefix='select'
              isClearable={isClearable}
              isSearchable={isSearchable}
              defaultValue={() => {
                if (form?.initialValues[field?.name]) {
                  return (options.length > 0 ? options : apiOptions)?.find(
                    (item) =>
                      item?.value === form?.initialValues[field?.name] ||
                      item?.label === form?.initialValues[field?.name] ||
                      item?.value === form?.initialValues[field?.name]?.name ||
                      item?.label === form?.initialValues[field?.name]?.name,
                  );
                }
                return options.find(
                  (item) => item?.value === defaultValue || item?.label === defaultValue,
                );
              }}
              placeholder={placeholder}
              onChange={_onChange}
              cacheOptions
              defaultOptions
              loadOptions={promiseOptions}
              menuPlacement={menuPlacement}
              isMulti={true}
              styles={selectStyles}
              noOptionsMessage={() =>
                fetchOptions && required ? (
                  <span className='text-red-90'>Failed to load data please reload</span>
                ) : (
                  'No options'
                )
              }
              closeMenuOnSelect={closeMenuOnSelect}
              components={{
                animatedComponents,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option: (props) => (
                  <CheckBoxComponent {...props} values={form?.values[field?.name] || []} />
                ),
              }}
            />
          )}

          <div className={`absolute right-0 flex items-center cursor-pointer`}>
            {extraRightRender ? extraRightRender() : null}
          </div>

          {remarks && (
            <div
              className={`absolute bg-white ${
                extraRightRender ? 'max-w-[230px]' : ''
              } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
            >
              {remarks}
            </div>
          )}
          {form?.errors[field?.name] && form?.touched[field?.name] && (
            <div
              className={`absolute bg-white ${
                extraRightRender ? 'max-w-[230px]' : ''
              } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
            >
              {form?.errors[field?.name]}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AsyncMultiSelect;
