export const columns = [
  {
    title: 'Policy Number',
    key: 'policy_num',
    dataIndex: 'policy_num',
    render: (data) => data?.name || data || '\u2014',
  },
  {
    title: 'Insurer',
    key: 'insurer',
    dataIndex: 'insurer',
    render: (data) => data?.name || data || '\u2014',
  },
  {
    title: 'Insurance Category',
    key: 'category',
    dataIndex: 'category',
    render: (data) => data?.name || data || '\u2014',
  },
  {
    title: 'Product Type',
    key: 'product_type',
    dataIndex: 'product_type',
    render: (data) => data?.name || data || '\u2014',
  },
  {
    title: 'Premium',
    key: 'premium',
    dataIndex: 'premium',
    render: (data) => Number(data)?.toFixed(2) || '\u2014',
  },
  // {
  //   title: 'Discount',
  //   key: 'discount',
  //   dataIndex: 'discount',
  //   render: (data) => Number(data)?.toFixed(2) || '\u2014',
  // },
  {
    title: 'Other Fees',
    key: 'other_fees',
    dataIndex: 'other_fees',
    render: (data) => Number(data)?.toFixed(2) || '\u2014',
  },
  {
    title: 'GST (%)',
    key: 'gst_pre',
    dataIndex: 'gst_pre',
    render: (data) => Number(data)?.toFixed(2) || '\u2014',
  },
  {
    title: 'Insurer Commission',
    key: 'insurer_commission',
    dataIndex: 'insurer_commission',
    render: (data) => Number(data)?.toFixed(2) || '\u2014',
  },
  {
    title: 'Employee Commission',
    key: 'employee_commission',
    dataIndex: 'employee_commission',
    render: (data) => Number(data)?.toFixed(2) || '\u2014',
  },
  {
    title: 'Gross Premium',
    key: 'premium_after_adjustments',
    dataIndex: 'premium_after_adjustments',
    render: (data) => Number(data)?.toFixed(2) || '\u2014',
  },
];

export const parsedData = (data) => {
  const res = [];
  data?.forEach((item) => {
    res.push({
      'Policy Number': item?.policy_num,
      Insurer: item?.insurer,
      Category: item?.category,
      'Product Type': item?.product_type,
      Premium: item?.premium,
      Discount: item?.discount,
      'Other Fees': item?.other_fees,
      GST: item?.gst_pre,
      'Insurer Commission': item?.insurer_commission,
      'Gross Premium': item?.premium_after_adjustments,
    });
  });
  return res;
};
