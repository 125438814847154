/** external deps */
import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const formatAdd = (val) => {
  let inc = 1;
  let unit = 'M';

  if (val === 'y') {
    unit = 'y';
  }
  if (val === 'q') {
    inc = 3;
    unit = 'M';
  }

  if (val === '6m') {
    inc = 6;
    unit = 'M';
  }

  return [inc, unit];
};

const DateRangePicker = ({ form, field, divClasses, customValueSet, ...props }) => {
  const [selectedRange, setSelected] = useState([null, null]);
  const [datesDiffrence, setDateDiffrence] = useState();
  const _onChange = (total, val) => {
    //onchange function to use it without form
    if (props?.customOnChange) props?.customOnChange(val, form);
    if (props?.onRangeChange) props?.onRangeChange(val);

    if (form?.setFieldValue) form.setFieldValue(field?.name, val);
    const start_date = new Date(val[0]);
    const end_date = new Date(val[1]);
    const calculate = end_date.getTime() - start_date.getTime();
    setDateDiffrence(calculate / (1000 * 3600 * 24));
  };

  const disabledDate = (e) => {
    const addUnit = form?.values?.['duration_type'];
    const [inc, unit] = formatAdd(addUnit);
    if (selectedRange && selectedRange.length) {
      return e <= dayjs(selectedRange[0]).add(inc, unit);
    }
    return false;
  };
  const disabledDateRange = (current) => {
    if (props?.disablePrev) {
      //It is a temporary fix, need to work on it.Logically we have to subtract 1 day but this fixed the
      // issue thats why implemented now
      return current && current < dayjs().subtract(10, 'hours');
    }
    if (props?.disableNext) {
      return current && current.valueOf() > Date.now();
    }
    if (props.disabledDate) {
      return current.isAfter(props.disabledDate);
    }
    if (props.disableCustomDate) {
      return current && current < dayjs(form?.values[props?.disableCustomDate]);
    }
    // if(props.dateDiffrence){

    // }
  };

  const _onCalendarChange = (selected, _, info) => {
    setSelected(selected);
  };

  const customFormat = (value) => {
    return value.format(dateFormat);
  };

  useEffect(() => {
    if (form?.initialValues?.[field?.name]) {
      if (form?.setFieldValue) form.setFieldValue(field?.name, form?.initialValues?.[field?.name]);
    }
  }, []);

  return (
    <div className={`group ${divClasses}  ${props?.disabled ? 'cursor-not-allowed' : ''}`}>
      <label
        className={`group-focus-within:text-primary-60 block mb-1 text-xxs xl:text-xxs text-black ${
          props?.required ? 'required' : ''
        }`}
      >
        {props?.label}
      </label>
      <div className='relative'>
        <RangePicker
          {...(props?.showTime && { showTime: { format: 'HH' } })}
          {...(props?.disableDate && { disabledDate: disabledDate })}
          open={props?.open}
          className='p-2 py-[3px] text-xxs placeholder:!text-red w-full bg-[#F5FAFD]'
          disabled={props?.disabled}
          disabledDate={disabledDateRange}
          onChange={_onChange}
          size='large'
          renderExtraFooter={props?.renderExtraFooter}
          onCalendarChange={_onCalendarChange}
          format={customFormat}
          defaultValue={
            form?.initialValues[field?.name]
              ? [
                  dayjs(form?.initialValues[field?.name][0]),
                  dayjs(form?.initialValues[field?.name][1]),
                ]
              : undefined
          }
        />
        <div className={`absolute right-0 flex items-center cursor-pointer`}>
          {props?.extraRightRender ? props?.extraRightRender() : null}
        </div>

        {form?.errors[field?.name] && form?.touched[field?.name] && (
          <div
            className={`absolute bg-white ${
              props?.extraRightRender ? 'max-w-[230px]' : ''
            } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
          >
            {form?.errors[field?.name]}
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangePicker;
