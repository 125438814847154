import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { CircularProgressBar } from 'components';

export const Tablecolumns = [
  {
    title: 'Goal Type',
    dataIndex: 'type',
    key: 'type',
    id: 'type',
    render: (val) => (val?.name ? <span>{val?.name}</span> : '\u2014'),
  },
  {
    title: 'No. of Consignments',
    dataIndex: 'field',
    key: 'field',
    id: 'field',
    displayFilter: ['assigned'],
  },
  {
    title: 'Start Date',
    dataIndex: 'start',
    key: 'start',
    id: 'start',
    displayFilter: ['assigned'],
  },
  {
    title: 'End Date',
    dataIndex: 'end',
    key: 'end',
    id: 'end',
    render: (date) => date || '\u2014',
    displayFilter: ['assigned'],
  },
  {
    key: 'progress',
    title: 'Progress',
    dataIndex: 'count',
    render: (count, record) => {
      const percent = (count / record?.field) * 100;
      return <CircularProgressBar percent={percent} />;
    },
  },
  {
    title: 'Goal Status',
    dataIndex: 'status',
    key: 'status',
    id: 'status',
    render: (status) => {
      return (
        <>
          <div
            className={`w-24  px-2 text-xxs rounded-md flex items-center gap-2 justify-center
          ${
            status === 'on_going'
              ? 'bg-[#DFF2FC] text-[#0089CF]'
              : status === 'completed'
              ? 'bg-[#CBFFD0] text-[#3CCF4B]'
              : 'bg-[#FFD7D7] text-[#FF0000]'
          } `}
          >
            <div
              className={`h-2 w-2  rounded-full ${
                status === 'on_going'
                  ? 'bg-[#0089CF]'
                  : status === 'completed'
                  ? ' bg-[#3CCF4B]'
                  : 'bg-[#FF0000]'
              }`}
            ></div>

            {status === 'on_going' ? 'ongoing' : status === 'completed' ? 'completed' : 'rejected'}
          </div>
          {/* <Tag
            icon={
              status === 'on_going' ? (
                <div className='h-2 w-2 bg-blue-500 rounded-full'></div>
              ) : status === 'completed' ? (
                <CheckCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )
            }
            color={
              status === 'on_going' ? 'processing' : status === 'completed' ? 'success' : 'error'
            }
          >
            {status.replace('_', ' ')}
          </Tag> */}
        </>
      );
    },
    displayFilter: ['assigned'],
  },
  {
    title: 'Assigned To',
    dataIndex: 'employee',
    key: 'employee',
    id: 'employee',
    render: (val) => val?.name || '\u2014',
    displayFilter: ['assigned'],
  },
];

export const SortSuccessRate = (data) => {
  const monthCounts = {};
  data.forEach(({ completed, target_count }) => {
    const monthKey = new Date(completed).toLocaleDateString('en-US', { month: 'short' });

    if (monthCounts[monthKey]) {
      monthCounts[monthKey] += target_count;
    } else {
      monthCounts[monthKey] = target_count;
    }
  });

  const result = [
    {
      month: 'Jan',
      count: monthCounts?.Jan || 0,
    },
    {
      month: 'Feb',
      count: monthCounts?.Feb || 0,
    },
    {
      month: 'Mar',
      count: monthCounts?.Mar || 0,
    },
    {
      month: 'Apr',
      count: monthCounts?.Apr || 0,
    },
    {
      month: 'May',
      count: monthCounts?.May || 0,
    },
    {
      month: 'Jun',
      count: monthCounts?.Jun || 0,
    },
    {
      month: 'Jul',
      count: monthCounts?.Jul || 0,
    },
    {
      month: 'Aug',
      count: monthCounts?.Aug || 0,
    },
    {
      month: 'Sep',
      count: monthCounts?.Sep || 0,
    },
    {
      month: 'Oct',
      count: monthCounts?.Oct || 0,
    },
    {
      month: 'Nov',
      count: monthCounts?.Nov || 0,
    },
    {
      month: 'Dec',
      count: monthCounts?.Dec || 0,
    },
  ];

  return result;
};
