import React from 'react';
import { useNavigate } from 'react-router-dom';
import Customer from 'assets/png/customer.png';

const SelectCard = ({ token, productOptions }) => {
  const navigate = useNavigate();

  const renderCard = ({ icon, text, href, productDetails }, index) => {
    return (
      <button
        onClick={() => {
          navigate('/pos/pos-form', {
            state: { productDetails: productDetails, token: token },
          });
        }}
        className='p-3 xl:p-3 bg-white h-full items-center relative border border-primary-70 justify-center flex flex-col rounded-xl transition-all duration-400 hover:bg-primary-light hover:border-primary-80 text-sm'
      >
        <img
          className='h-24 w-24 xl:h-14 xl:w-14'
          src={process.env.REACT_APP_DOC_URL + icon || Customer}
          alt='policy'
        />
        <h3 className='font-semibold pt-2 text-l xl:text-s'>{text}</h3>
        {/* Insurance */}
      </button>
    );
  };

  return (
    <>
      <h2 className='text-l font-semibold'>Policy</h2>
      <div className={`grid grid-cols-2 md:grid-cols-4 w-full gap-3`}>
        {productOptions.map(renderCard)}
      </div>
    </>
  );
};

export default SelectCard;
