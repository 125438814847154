import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import './style.css';

const CustomTextInput = ({
  field,
  ref,
  form,
  rightIcon,
  leftIcon,
  onIconClick = () => null,
  extraRightRender,
  InputClasses,
  divClasses,
  dependent,
  disabled,
  maxLength,
  isUpperCase,
  isMultiline = false,
  customIcon,
  title,
  ...props
}) => {
  const [fieldValue, setValue] = useState();
  /* funcs */
  const _onChange = (val) => {
    let value = val.target.value;
    if (isUpperCase) {
      value = value.toUpperCase();
    }
    if (props?.customOnChange) {
      props?.customOnChange({ field, value, form });
    }
    form?.setFieldValue(field?.name, value);
    setValue(value);
    if (field?.onChange) return field?.onChange(val);
  };

  const handleClick = () => {
    if (fieldValue) return onIconClick(fieldValue);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      if (fieldValue) return onIconClick(fieldValue);
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setValue(form?.values[field?.name]);
  }, [form]);

  useEffect(() => {
    if (props?.dependent_field) {
      // form?.setFieldValue(props?.dependent_field,);
      props?.dependent_field({ field, fieldValue, form });
    }
  }, [props?.dependent_field]);

  return (
    <div className={`group ${divClasses}`}>
      <div className='flex justify-between'>
        <label
          className={`group-focus-within:text-primary-90 block mb-1 text-xxs xl:text-xxs text-black ${
            props?.required ? 'required' : ''
          }`}
        >
          {props.label}
        </label>
        {customIcon && (
          <Tooltip title={title} position='top'>
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </div>
      <div className='relative'>
        <div
          onClick={handleClick}
          className={`absolute inset-y-0 bg-white border-y border-[#D1EAF9] group-focus-within:border-primary-90  ${
            rightIcon && 'right-0 border-r px-3 rounded-r-md '
          } ${leftIcon && 'border-l px-3 rounded-l-full'} flex items-center cursor-pointer `}
        >
          {rightIcon
            ? typeof rightIcon === 'function'
              ? rightIcon(fieldValue)
              : rightIcon
            : leftIcon
            ? typeof leftIcon === 'function'
              ? leftIcon(fieldValue)
              : leftIcon
            : ''}
        </div>
        <input
          ref={ref}
          value={fieldValue}
          maxLength={maxLength}
          disabled={disabled}
          name={field?.name || props?.name}
          className={`${
            leftIcon ? '!pl-10 2xl:!pl-12' : ''
          } bg-[#F5FAFD] border border-[#D1EAF9] text-xxs xl:text-xxs rounded-md  block w-full p-[5.5px] xl:p-[5.5px] focus:outline-none focus:border-primary-90
          placeholder-grey-90 placeholder:text-grey-90 placeholder:text-xxs xl:placeholder:text-xxs disabled:bg-grey-light disabled:border-grey-light disabled:cursor-not-allowed
          disabled:text-grey-90 placeholder:capitalize
           ${InputClasses} ${isUpperCase ? 'uppercase' : ''}`}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          onChange={_onChange}
          // {...field}
          // {...props}
          onKeyDown={handleKeyPress}
        />

        <div className={`absolute right-0 flex items-center cursor-pointer`}>
          {extraRightRender ? extraRightRender() : null}
        </div>

        {/* {props?.error && form?.touched[field?.name] && (
          <div
            className={`absolute bg-white ${
              extraRightRender ? "max-w-[230px]" : ""
            } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
          >
            {props?.error}
          </div>
        )} */}
        {form?.errors[field?.name] && form?.touched[field?.name] && (
          <div
            className={`absolute bg-white ${
              extraRightRender ? 'max-w-[230px]' : ''
            } last:left-0 text-red-90 flex items-center cursor-pointer text-x mt-1 `}
          >
            {form?.errors[field?.name]}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTextInput;
