import React from 'react';
import { Link } from 'react-router-dom';

const Timeline = ({
  time,
  heading,
  content = '',
  link,
  linkname,
  activity,
  count,
  name,
  last_content,
}) => {
  const divClass =
    activity === 'Created'
      ? ' bg-green'
      : activity === 'Updated'
      ? ' bg-primary-90'
      : activity === 'Deleted'
      ? 'bg-red'
      : 'bg-primary';

  const borderClass =
    activity === 'Created'
      ? ' border-green'
      : activity === 'Updated'
      ? ' border-primary-90'
      : activity === 'Deleted'
      ? 'border-red'
      : 'border-grey';

  return (
    <ol className={`border-l last:border-0 group border-dashed ml-6 ${borderClass}`}>
      <li className='ml-4 pb-6 relative -top-2 group-last:pb-0 shadow-xl drop-shadow-xl'>
        <div
          className={`absolute w-5 h-5 rounded-full mt-2 -left-7  ${divClass} flex justify-center items-center p-3 text-white `}
        >
          {count}
        </div>

        <div className='rounded-lg shadow-darkmd p-3 ml-6'>
          <div className='flex justify-between '>
            <h3 className='text-s font-semibold text-gray-900 dark:text-white'>{heading}</h3>
            <time className='text-x text-primary-90'>{time}</time>
          </div>
          <p className='mb-2 text-xxs normal-case text-black '>
            {content} <span className='text-primary-90'>{name}</span> {last_content}{' '}
          </p>
          <Link to={link} className='text-xxs text-primary-90 decoration-inherit'>
            {linkname}
          </Link>
        </div>
      </li>
    </ol>
  );
};

export default Timeline;
