/** external deps */
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';

/** internal deps */
import { UserInitialIcon } from 'components';
import { formatDate } from 'utils';
import clsx from 'clsx';

const date = new Date();

const SingleTask = ({ index, taskData, section }) => {
  /*Checks whether task/meeting is overdue */
  const cardColor =
    section !== 'completed' && dayjs(taskData.to_date).isBefore(date)
      ? 'bg-red-light before:bg-red before:h-2'
      : 'bg-white';

  const renderSection = (item) => {
    return (
      <Tooltip title={`${item?.emp_code}-${item?.name}`}>
        <div>
          <UserInitialIcon
            name={item?.name}
            imageUrl={process.env.REACT_APP_DOC_URL + item?.image}
          />
        </div>
      </Tooltip>
    );
  };

  return (
    <main>
      <Draggable draggableId={taskData.id.toString()} index={index} key={taskData.id.toString()}>
        {(provided) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className={`p-2 pb-10 my-2 rounded-lg ${cardColor}`}
          >
            <div
              className={clsx(
                `relative before:content[''] ${cardColor} before:rounded-lg before:w-1/4 before:my-1.5 before:block`,
              )}
            >
              <span className='text-xxs font-medium break-all'>{taskData?.subject}</span>
              {taskData?.description && (
                <p
                  dangerouslySetInnerHTML={{ __html: taskData?.description }}
                  className='text-xxxs h-4 overflow-hidden'
                ></p>
              )}
              <p className='text-grey text-x'>
                {formatDate({
                  dateString: taskData?.from_date || taskData?.to_date,
                  toUtc: false,
                  format: 'DD MMMM, YYYY, hh:mm A',
                })}
              </p>
              <div className='absolute right-1 flex gap-1'>
                {taskData?.assign_to_employee.slice(0, 4).map(renderSection)}
                {taskData?.assign_to_employee.length > 4 && (
                  <div className='w-8 h-8 rounded-full grid place-content-center text-white font-bold bg-primary'>
                    +
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </main>
  );
};

export default SingleTask;
