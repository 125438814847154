import {
  ChartSelect,
  Checkbox,
  CustomRadio,
  DateTime,
  SelectInput,
  TextInput,
  CustomFieldArray,
} from 'components';

const breadcrumbObjAdd = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Reports',
    path: '/reports',
  },
  {
    name: 'Add Report',
    path: '',
    target: '_blank',
  },
];
const breadcrumbObjEdit = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Reports',
    path: '/reports',
  },
  {
    name: 'Edit Report',
    path: '',
    target: '_blank',
  },
];

const addReportDetailsFormFields = {
  'Add Report fields': {
    fields: [
      {
        label: 'Report Name',
        name: 'report_name',
        id: 'report_name',
        placeholder: 'Report Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Report Folder',
        name: 'report_folder',
        id: 'report_folder',
        placeholder: 'Report Folder',
        type: 'select',
        // required: true,
        component: SelectInput,
        options: [
          { value: 'public', label: 'Public' },
          { value: 'private', label: 'Private' },
          { value: 'shared', label: 'Shared' },
        ],
      },
      {
        label: 'Primary Module',
        name: 'primary_module',
        id: 'primary_module',
        placeholder: 'Primary Module',
        type: 'select',
        // required: true,
        component: SelectInput,
        options: [
          { value: 'public', label: 'Public' },
          { value: 'private', label: 'Private' },
          { value: 'shared', label: 'Shared' },
        ],
      },
      {
        label: 'Select Related Modules (MAX 2)',
        name: 'modules',
        id: 'modules',
        placeholder: 'Select Related Modules',
        type: 'text',
        // required: true,
        component: SelectInput,
        options: [
          { value: 'public', label: 'Public' },
          { value: 'private', label: 'Private' },
          { value: 'shared', label: 'Shared' },
        ],
        isMulti: false,
      },
      {
        label: 'Description',
        name: 'description',
        id: 'description',
        placeholder: 'Description',
        type: 'textarea',
        component: TextInput,
        isMultiline: true,
        divClasses: 'col-span-2',
        maxLength: 300,
        // rows: 3,
      },
      {
        label: 'Share report as',
        name: 'share',
        id: 'share',
        type: 'radio',
        component: CustomRadio,
        options: [
          { value: 'public', label: 'Public' },
          { value: 'private', label: 'Private' },
          { value: 'shared', label: 'Shared' },
        ],
        divClasses: 'col-span-full',
      },
      {
        label: 'Schedule Report',
        name: 'schedule',
        id: 'schedule',
        type: 'checkbox',
        // checked: false,
        component: Checkbox,
        divClasses: 'col-span-2 font-semibold',
      },
    ],
    showAccordionHeader: false,
  },
  'Insured Registered address': {
    dependent: true,
    dependent_on: 'schedule',
    dependent_value: true,
    fields: [
      {
        label: 'Run Report',
        name: 'report',
        id: 'report',
        placeholder: 'Run Report',
        type: 'text',
        required: false,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'At Time',
        name: 'time',
        id: 'time',
        placeholder: 'At Time',
        type: 'date',
        required: false,
        showTime: true,
        component: DateTime,
        maxLength: 30,
      },
      {
        label: 'Select Recipients',
        name: 'recipients',
        id: 'recipients',
        placeholder: 'Select Recipients',
        type: 'text',
        required: false,
        component: SelectInput,
        options: [
          { value: 'public', label: 'Public' },
          { value: 'private', label: 'Private' },
          { value: 'shared', label: 'Shared' },
        ],
      },
      {
        label: 'Send to specific email',
        name: 'email',
        id: 'email',
        placeholder: 'Send to specific email',
        type: 'email',
        required: false,
        component: TextInput,
        maxLength: 30,
      },
    ],
    showAccordionHeader: false,
  },
};

const addReportFilterFormFields = {
  'All Conditions': {
    subTitle: 'All Conditions',
    showAccordionHeader: false,
    fields: [
      {
        name: 'all_conditions',
        type: 'fieldArray',
        id: 'all_conditions',
        addButtonText: '+ Condition',
        valueSchema: { field: '', comparison: '', name: '' },
        component: CustomFieldArray,
        fields: [
          {
            name: 'field',
            type: 'select',
            placeholder: 'Selected Field',
            component: SelectInput,
            options: [
              { label: 'Field1', value: 'Field1' },
              { label: 'Field2', value: 'Field2' },
            ],
          },
          {
            name: 'comparison',
            type: 'select',
            placeholder: 'None',
            component: SelectInput,
            options: [
              { label: 'comparison1', value: 'comparison1' },
              { label: 'comparison2', value: 'comparison2' },
            ],
          },
          {
            name: 'name',
            id: 'name',
            placeholder: 'Name',
            type: 'text',
            component: TextInput,
          },
        ],
      },
    ],
  },
  'Any Conditions': {
    subTitle: 'Any Conditions',
    showAccordionHeader: false,
    fields: [
      {
        name: 'any_conditions',
        type: 'fieldArray',
        id: 'any_conditions',
        addButtonText: '+ Condition',
        valueSchema: { field: '', comparison: '', name: '' },
        fields: [
          {
            name: 'field',
            type: 'select',
            placeholder: 'Selected Field',
            component: SelectInput,
            options: [
              { label: 'Field1', value: 'Field1' },
              { label: 'Field2', value: 'Field2' },
            ],
          },
          {
            name: 'comparison',
            type: 'select',
            placeholder: 'None',
            component: SelectInput,
            options: [
              { label: 'comparison1', value: 'comparison1' },
              { label: 'comparison2', value: 'comparison2' },
            ],
          },
          {
            name: 'name',
            id: 'name',
            placeholder: 'Name',
            type: 'text',
            component: TextInput,
          },
        ],
        component: CustomFieldArray,
      },
    ],
  },
};

//export  const addReportFilterInitials = {
//   all_conditions: [{ field: "", comparison: "", name: "" }],
//   any_conditions: [{ field: "", comparison: "", name: "" }],
// };

const addReportChartFormFields = {
  'Add Chart fields': {
    fields: [
      {
        label: 'Select Chart Field',
        name: 'chart_field',
        id: 'chart_field',
        placeholder: 'Select Field',
        type: 'customComponent',
        required: false,
        Component: ChartSelect,
      },
      {
        label: 'Select Groupby Field',
        name: 'groupby_field',
        id: 'groupby_field',
        placeholder: 'Select Field',
        type: 'select',
        required: false,
        component: SelectInput,
        options: [
          { value: 'public', label: 'Public' },
          { value: 'private', label: 'Private' },
          { value: 'shared', label: 'Shared' },
        ],
        divClasses: 'col-span-2',
      },
      {
        label: 'Select Data Fields',
        name: 'data_field',
        id: 'data_field',
        placeholder: 'Select Data Field',
        type: 'select',
        required: false,
        component: SelectInput,
        options: [
          { value: 'public', label: 'Public' },
          { value: 'private', label: 'Private' },
          { value: 'shared', label: 'Shared' },
        ],
        divClasses: 'col-span-2',
      },
    ],
    showAccordionHeader: false,
  },
};

export {
  breadcrumbObjAdd,
  breadcrumbObjEdit,
  addReportDetailsFormFields,
  addReportFilterFormFields,
  addReportChartFormFields,
};
