import React, { useState } from 'react';

import { TabsComponent } from 'components';
import { elementData } from 'elements';
import { SecureComponent } from 'permission-provider';
import { HRDashboard, MyDashboard, TaskDashboard } from 'screens';
import { generatePermittedTabs } from 'utils';
import { useNavigate } from 'react-router-dom';
import { getQueryParams } from 'utils/common';
import { Select } from 'antd';
import './style.css';

const Dashboard = (props = {}) => {
  const permissions = props?.permissions || [];
  const { module } = getQueryParams();
  const [key, setActiveTabKey] = useState(module || 'my-dashboard');
  const navigate = useNavigate();
  const Auth = localStorage.getItem('AUTH');
  if (!Auth) {
    navigate('login');
  }

  const handleChangeDropDown = (value) => {
    setActiveTabKey(value);
  };

  const tabs = [
    {
      key: 'my-dashboard',
      label: `My Dashboard`,
      id: { read: true, write: true },
      Component: MyDashboard,
      props: {
        setActiveTabKey: setActiveTabKey,
        permissions: permissions,
      },
    },
    {
      key: 'task-dashboard',
      id: elementData.taskdashboard.name,
      label: `Task Dashboard`,
      Component: TaskDashboard,
      props: {
        permissions: permissions,
      },
    },
    {
      key: 'hr-dashboard',
      id: elementData.hrdashboard.name,
      label: `HR Dashboard`,
      Component: HRDashboard,
      props: {
        permissions: permissions,
      },
    },
  ];
  const permittedTabs = generatePermittedTabs(tabs, permissions);
  const nonEmptyObjects = Object.values(permittedTabs).filter((obj) => Object.keys(obj).length > 0);
  const nonEmptyObjectCounts = nonEmptyObjects.length;
  return nonEmptyObjectCounts && nonEmptyObjectCounts > 1 ? (
    <div>
      <div className='flex items-center justify-between'>
        <div className='text-sm font-semibold capitalize'>{key.replace('-', ' ')}</div>
        <div
          id='dashboard-selector'
          className={key === 'my-dashboard' ? 'mr-[20rem]' : 'mr-[13rem]'}
        >
          <Select
            defaultValue='my-dashboard'
            className='bg-white w-[180px] px-[9px] py-0.5 rounded-md text-xs'
            onChange={handleChangeDropDown}
            options={tabs.map((d) => {
              return { value: d.key, label: d.label };
            })}
          />
        </div>
      </div>
      <TabsComponent
        id='main-dashboard'
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
        items={generatePermittedTabs(tabs, permissions)}
      />
    </div>
  ) : (
    nonEmptyObjects.map((item) => {
      if (Object.keys(item).length <= 0) return null;
      const { Component } = item;
      return <Component {...item?.props} />;
    })
  );
};

export default SecureComponent(Dashboard);
