import { getAll } from 'countrycitystatejson';
import { post } from 'jquery';
import tickets from 'screens/hr/tickets';

export const endpoints = {
  auth: {
    login: 'auth/user/login',
    changeUserPwd: 'auth/user/change/password',
    requestResetLink: 'auth/user/forgot-password',
    resetPwd: 'auth/user/reset-password',
  },
  user: {
    profile: 'user/profile/',
    experience: {
      list: 'user/profile/experience',
      create: 'user/profile/experience',
      update: (id) => `user/profile/experience?id=${id}`,
      delete: (id) => `user/profile/experience?id=${id}`,
    },
    education: {
      list: `user/profile/education`,
      create: `user/profile/education`,
      update: (id) => `user/profile/education?id=${id}`,
      delete: (id) => `user/profile/education?id=${id}`,
    },
    project: {
      list: `user/profile/project`,
      create: `user/profile/project`,
      update: (id) => `user/profile/project?id=${id}`,
      delete: (id) => `user/profile/project?id=${id}`,
    },
    language: {
      list: `user/profile/language`,
      create: `user/profile/language`,
      update: (id) => `user/profile/language?id=${id}`,
      delete: (id) => `user/profile/language?id=${id}`,
    },
    skill: {
      list: `user/profile/skill`,
      create: `user/profile/skill`,
      update: (id) => `user/profile/skill?id=${id}`,
      delete: (id) => `user/profile/skill?id=${id}`,
    },
    emergency: {
      list: `user/profile/emergency/contact`,
      create: `user/profile/emergency/contact`,
      update: (id) => `user/profile/emergency/contact?id=${id}`,
      delete: (id) => `user/profile/emergency/contact?id=${id}`,
    },
  },
  branch: {
    list: (query = '') => `branch/?q=${query}`,
    create: 'branch/',
    delete: (id) => `branch/?id=${id}`,
    update: (id) => `branch/?id=${id}`,
  },
  employee: {
    create: 'employee/',
    delete: (id) => `employee/?id=${id}`,
    permissions: (id) => `employee/permission?id=${id}`,
    /**@deprecated use `create` */
    create_doc: 'employee/documents',
    list_doc: (id) => `employee/documents?employee_id=${id}`,
    performance_count: (id) => `dashboard/employee/static?id=${id}`,
    leads: (id) => `inquiry/?employee_id=${id}`,
    leaves: (id) => `leave/employee?id=${id}`,
  },
  role: {
    create: 'roles',
    list: 'roles',
    delete: (id) => `roles?id=${id}`,
    permissions: (id) => `roles/permission/${id}`,
  },
  customer: {
    create: 'customer/',
    update: (id) => `customer/?id=${id}`,
    create_doc: 'customer/documents',
    list_doc: (id) => `customer/documents?customer_id=${id}`,
    chat_data: (start_date, end_date) =>
      `customer/count?start_date=${start_date}&end_date=${end_date}`,
    event_data: (date, pageNum) => `dashboard/events?date=${date}&page=${pageNum}`,
    policies: (id) => `policies/?customer_id=${id}`,
    search: (query, page) => `customer/?q=${query}&page=${page}`,
    import: () => `customer/import`,
    advance_assignment: ({ category, product }) =>
      `customer/advance-customer?category=${category}&product=${product}`,
    assign_employee: () => `customer/assign-employee`,
    callLogs: (q, customer_id, id) => `customer/call-log?q=${q}&customer=${customer_id}&id=${id}`,
  },
  recruitment: {
    create: 'recruitment/',
    list: 'recruitment/',
    single: (id) => `recruitment/?id=${id}`,
    job_details: (id) => `recruitment/job-application?id=${Number(id)}`,
    job_apply: `recruitment/job-application`,
    update_application: (id) => `recruitment/job-application/update?id=${id}`,
    // interview
    invite: `recruitment/interview`,
    interview_request: (date, status) =>
      `recruitment/interview/request?date=${date}&status=${status}`,
    upcoming_interview: (date, status) =>
      `recruitment/upcoming/interview?date=${date}&status=${status}`,
    interview_list: (status) => `recruitment/interview?status=${status}`,
    getParticularInterview: (id) => `recruitment/interview/request?id=${id}`,
    update_interview: (id) => `recruitment/interview?id=${id}`,
    delete_interview: (id) => `recruitment/interview?id=${id}`,
  },
  payroll: {
    list: (id) => `payroll/user_salary?id=${id}`,
    check_list: 'payroll/check_list',
    create: 'payroll/',
    update: (id) => `payroll/?id=${id}`,
    checks: (date) => `payroll/?date=${date}`,
  },
  tax: {
    list: 'payroll/tax',
    create: 'payroll/tax',
    update: (id) => `payroll/tax?id=${id}`,
    delete: (id) => `payroll/tax?id=${id}`,
  },
  allowance: {
    list: 'payroll/allowance_deduction',
    create: 'payroll/allowance_deduction',
    update: (id) => `payroll/allowance_deduction?id=${id}`,
    delete: (id) => `payroll/allowance_deduction?id=${id}`,
    variant: (variant) => `payroll/allowance_deduction?variant=${variant || ''}`,
  },
  notification: {
    list: 'auth/user/notification',
    read: `auth/user/notification`,
  },
  goal: {
    employee: (id) =>
      `employee/target/success?employee=${id}&start_date=2024-01-01&end_date=2024-01-31`,
    view: (id, page, status) =>
      `target/?employee_id=${id}&page=${page}&paginated_by=5&assign=assign_to_me&status=${status}`,
    target: (employee_id, target_id) => `target/?id=${employee_id || target_id}`,
    employee_id: (start, end, employee_id, page) =>
      `target/?start=${start || ''}&end=${end || ''}&employee_id=${employee_id || ''}&page=${
        page || ''
      }`,
  },
  salary: {
    list: (query) => `payroll/salary?${query}`,
    create: 'payroll/salary',
    update: (id) => `payroll/salary?id=${id}`,
    delete: (id) => `payroll/salary?id=${id}`,
  },
  report: {
    list: (type, modules, start_date, end_date, employee_id, branch_id, category, product, page) =>
      `report/?start_date=${start_date}&end_date=${end_date}&module=${modules}&branch_id=${branch_id}&employee_id=${employee_id}&type=${type}&category=${category}&product=${product}&page=${page}`,
    reconciliation: (insurer, start_date, end_date, branch, product, employee) =>
      `report/reconciliation?insurer=${insurer}&start_date=${start_date}&end_date=${end_date}&branch=${branch}&product=${product}&employee=${employee}`,
  },
  statistics: {
    admin: 'dashboard/admin/statistics',
    employee: (id) => `dashboard/employee/static?id=${id}`,
  },
  task: {
    status: (status) => `task-meeting/task?status=${status}`,
    list: (q) => `task-meeting/task?${q}`,
    update: (id) => `task-meeting/task?id=${id || ''}`,
    activity: (id) => `employee/activity?model=Activities&model_id=${id || ''}`,
    single: (id) => `task-meeting/task?id=${id}`,
  },
  meeting: {
    upcoming: (date) => `task-meeting/meeting?date=${date}`,
    list: (q) => `task-meeting/meeting?${q}`,
    single: (id) => `task-meeting/meeting?id=${id}`,
    update: (id) => `task-meeting/meeting?id=${id || ''}`,
  },
  insurer: {
    search: `search/insurer`,
    list: (q) => `insurer/${q}`,
    create: 'insurer/',
    update: (id) => `insurer/?id=${id}`,
    delete: (id) => `insurer/?id=${id}`,
    contact: (insurer_id) => `insurer/insurer-contact?insurer_id=${insurer_id}`,
    create_contact: 'insurer/insurer-contact',
    update_contact: (id) => `insurer/insurer-contact?id=${id}`,
    delete_contact: (id) => `insurer/insurer-contact?id=${id}`,
  },
  ins_cat: {
    list: `insurance-category/`,
    single: (id) => `insurance-category/?id=${id}`,
    create: 'insurance-category/',
    update: (id) => `insurance-category/?id=${id}`,
    delete: (id) => `insurance-category/?id=${id}`,
  },
  ins_product: {
    list: (ins_category) => `insurance-category/product-type?ins_category=${ins_category}`,
    create: 'insurance-category/product-type',
    delete: (id) => `insurance-category/product-type?id=${id}`,
    update: (id) => `insurance-category/product-type?id=${id}`,
  },
  form: {
    list: `insurance-category/product-type/field`,
    create: `insurance-category/product-type/field`,
    delete: (id) => `insurance-category/product-type/field?id=${id}`,
    update: (id) => `insurance-category/product-type/field?id=${id}`,
  },
  claim: {
    status: (id) => `claim/status/${id}`,
    customer_id: (customer_id) => `claim/?customer_id=${customer_id}`,
  },
  company_policy: {
    leave: `leave/policy`,
    leaveId: (id) => `leave/policy?id=${id}`,
  },
  invoice: {
    create: 'invoice/',
    getInvoices: (table_id) => `invoice/?table_id=${table_id}`,
    delete: (id) => `invoice/${id}`,
  },
  // formManagement check again !!
  formManagement: {
    insuranceCategoryId: (id) => `insurance-category/product-type/field/${id}`,
    insuranceCategory: `insurance-category/product-type/field`,
  },
  getCustomer: {
    get: (id) => `customer/?id=${id}`,
  },
  getCustomers: {
    get: (query) => `customer/?${query}`,
    getfollow: (query) => `customer/follow-up?${query}`,
    deleteFollow: (id) => `customer/call-log?id=${id}`,
  },
  departmentManagement: {
    department: (id) => `department?id=${id}`,
    departmentQuery: (query) => `department?q=${query}`,
  },
  designation: {
    get: `designation`,
    designationId: (id) => `designation?id=${id}`,
  },
  reasons: {
    getByType: (type) => `recruitment/reasons?type=${type}`,
    post: `recruitment/reasons`,
    patch: (id) => `recruitment/reasons?id=${id}`,
  },
  tickets: {
    getAll: () => 'inventory/ticket',
    post: 'inventory/ticket',
    patch: (id) => 'inventory/ticket?id=${id}',
  },
  officeEvents: {
    post: 'inventory/office/events',
    get: (date) => `inventory/office/events?date=${date}`,
  },
  getEmployees: {
    employee: (query) => `employee/?${query}`,
  },
  getEmployee: {
    employeeId: (id) => `employee/?id=${id}`,
  },
  getAllEmployees: {
    searchEmployee: `search/employee`,
  },
  employeeExperience: {
    deletePatch: (id) => `user/profile/experience?id=${id}`,
    employee_id: (employee_id) => `user/profile/experience?employee_id=${employee_id}`,
  },
  employeeById: {
    employee_id: (employee_id) => `employee/?table_id=${employee_id}`,
  },
  dynamicTable: {
    get: (query, page, status, employee_id) =>
      `target/?q=${query || ''}&page=${page}&assign=${status}&employee_id=${employee_id || ''}`,
  },
  targetTypeManagement: {
    targetType: `target/type`,
    targetId: (id) => `target/type?id=${id}`,
  },
  getStatistics: {
    employee_id: (id) => `hr/statistics?employee_id=${id}`,
  },
  upComingEvents: {
    holidayId: (id) => `leave/holiday/${id}`,
    holidayDate: (date) => `leave/holiday?date=${date}`,
  },
  CustomerTransactions: {
    policy_id: (policy_id) => `policies/transaction?policy_id=${policy_id || ''}`,
  },
  getAllLeaves: {
    leaveTypes: `leave/types`,
  },
  deletePlayerId: {
    playerId: `auth/user/player-id`,
  },
  getFields: {
    customer: `customer/fields`,
    contacts: `contacts/fields`,
  },
  contactManagement: {
    customerId: (id) => `customer/?id=${id}`,
    contact: (customer_id, pageNum) =>
      `customer/contact?customer_id=${customer_id}&page=${
        typeof pageNum === 'number' ? pageNum : 1
      }`,
  },
  pos: {
    profile: 'pos/profile',
    list: ({ page, query, is_active, status }) =>
      `pos/?page=${page || ''}&q=${query || ''}&is_active=${is_active || ''}&status=${
        status || ''
      }`,
    create: `pos/`,
    update: (id) => `pos/?id=${id}`,
    delete: (id) => `pos/?id=${id}`,
    docs: (id) => `pos/?id=${id}`,
  },
  training: {
    list: `pos/training`,
    create: `pos/training`,
    update: (id) => `pos/training?id=${id}`,
    delete: (id) => `pos/training?id=${id}`,
  },
  terms: {
    details: `pos/terms-condition`,
    create: `pos/terms-condition`,
    update: (id) => `pos/terms-condition?id=${id}`,
  },
  contract: {
    details: `pos/agent-contract`,
    create: `pos/agent-contract`,
    update: (id) => `pos/agent-contract?id=${id}`,
  },
  shift: {
    list: `attendance/shift`,
  },
  dashboard: {
    policySold: (start, end, category, product) =>
      `dashboard/policy-graph?start_date=${start}&end_date=${end}&category=${
        category || ''
      }&products=[${product || ''}]`,
    leadGraph: (start, end) => `dashboard/inquiry-graph?start_date=${start}&end_date=${end}`,
    employeeGraph: (start, end) => `dashboard/employee-graph?start_date=${start}&end_date=${end}`,
    clientGraph: (start, end, type) =>
      `dashboard/customer-graph?start_date=${start}&end_date=${end}&customer_type=${type}`,
  },
  policy: {
    create: 'policies/',
  },
  reminders: {
    get: 'inquiry/reminder',
    update: 'inquiry/reminder',
    delete: (id) => `inquiry/reminder?id=${id}`,
  },
};
