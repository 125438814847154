import React from 'react';
import { AuthLogo2 } from 'assets/hr_icons';

const AuthLayout = ({ children, showImage }) => {
  return (
    <div className='flex items-center min-h-screen bg-grey-light lg:justify-center  '>
      <div className='flex flex-col overflow-hidden rounded-[18px] shadow-lg max sm:flex-row md:flex-1 max-w-3xl m-auto xl:max-w-[840px] h-screen md:h-fit  w-screen md:w-fit '>
        {showImage && (
          <div className=' hidden sm:block text-white bg-grey-50  w-full sm:w-5/12 md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center'>
            <div className=' h-full flex justify-center items-center'>
              <img className='object-cover' src={AuthLogo2} alt='' />
            </div>
          </div>
        )}
        {/* Form section */}
        <div className='py-[30px] px-4 md:px-8 xl:px-14 bg-white md:flex-1  h-screen md:h-fit'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
