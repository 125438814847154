import { IconDropdown } from 'assets/svg';
import CustomDropdown from 'components/form-components/CustomDropdown';
import { getPermission } from 'utils';
import clsx from 'clsx';

const getBgColor = (status) => {
  if (typeof status === 'boolean' || status === null) {
    switch (status) {
      case false:
        return 'bg-red-light text-red';
      case true:
        return 'bg-green-light text-green';
      case null:
        return 'bg-green-light text-green';

      default:
        return '';
    }
  }
  switch (status?.toLowerCase()) {
    case 'pending':
      return 'bg-yellow-light text-yellow';
    case 'converted':
      return 'bg-yellow-light text-yellow';
    case 'losses':
      return 'bg-red-light text-red';
    case 'hired':
      return 'bg-green-light text-green';
    case 'active':
      return 'bg-green-light text-green';
    case 'rejected':
      return 'bg-red-light text-red';
    case 'inactive':
      return 'bg-red-light text-red';
    case 'onhold':
      return 'bg-orange text-red';
    case 'in_discussion':
      return 'bg-blue-100 text-blue-500';
    case 'resolve':
      return 'bg-green-light text-green';

    default:
      return '';
  }
};

export const TableStatus = ({
  onChange,
  record,
  items,
  status,
  statusMapings = { true: 'Active', false: 'Closed', null: 'Select' },
  permissions,
  id,
}) => {
  const isEnabled = getPermission(permissions, id)?.write;

  return (
    <CustomDropdown
      items={items}
      trigger={['click']}
      onClick={(e) => {
        if (e?.key?.toLowerCase() !== record?.status?.toLowerCase) onChange(e, record);
      }}
      placement='bottom'
      disabled={!isEnabled}
    >
      <div
        className={clsx(
          `${getBgColor(
            status,
          )} cursor-pointer py-1 whitespace-nowrap px-2 text-xxs  capitalize gap-2 rounded-md flex items-center justify-between font-semibold`,
          {
            'cursor-not-allowed': !isEnabled,
          },
        )}
      >
        {typeof status === 'boolean' || status === null
          ? statusMapings[String(status)]
          : status?.replaceAll('_', ' ')}
        <IconDropdown className='w-2' />
      </div>
    </CustomDropdown>
  );
};
