export const LETTERS_TYPES = [
  {
    label: 'Joining Letter',
    value: 'joining',
    defaultTemplate: {
      heading: 'Letter of Joining',
      subject: 'Subject: An Intimation of Joining on Duty',
      description: `This letter is in response to your job offer letter dated [today_date], 
        I am glad to accept your job opportunity for the position of [designation] at the [company_name]. 
        I will be joining the job from [joining_date].`,
      upper_text: `To
        [candidate_name],
        [designation]
        [address]`,
      bottom_text: `Your faithfully/Sincerly
        [hr_name]
        [signature]
        [company_address]
        `,
    },
    fields: [''],
    title: 'Joining Letter Template',
    key: 'joining-letter',
  },
  {
    label: 'Relieving Letter',
    value: 'relieving',
    defaultTemplate: {
      heading: 'Letter of Relieving',
      subject: 'Subject: An Intimation of relieving from Duty',
      description: `This letter is in response to your job offer letter dated [today_date], 
        I am glad to accept your job opportunity for the position of [designation] at the [company_name]. 
        I will be joining the job from [joining_date].`,
      upper_text: `To
        [candidate_name],
        [designation]
        [address]`,
      bottom_text: `Your faithfully/Sincerly
        [hr_name]
        [signature]
        [company_address]
        `,
    },
    title: 'Relieving Letter Template',
    key: 'releiving-letter',
  },
];

const arrayToObject = (list) => {
  let result = {};
  list.forEach((item) => {
    result[item.value] = item;
  });

  return result;
};

export const tags = [
  '[candidate_name]',
  '[joining_date]',
  '[hr_name]',
  '[designation]',
  '[address]',
  '[today_date]',
  '[letter_number]',
  '[company_name]',
  '[signature]',
  '[company_address]',
];

export const settingsObject = arrayToObject(LETTERS_TYPES);
