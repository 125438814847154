import { SocialPost } from 'components';
import CustomSocialPostTemplate from 'components/social-post/customSocilpost';
import TabsComponent from 'components/tabs';
import React, { useState } from 'react';

const Advertise = ({ selectedAsset, setAdvertModal, refresh }) => {
  const [key, setActiveTabKey] = useState('1');
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [upload, setUpload] = useState();

  const tabs = [
    {
      key: '1',
      label: `Facebook`,
      Component: SocialPost,
      props: {
        tab: 'facebook',
        refresh,
        title,
        setTitle,
        description,
        setDescription,
        upload,
        setUpload,
        selectedAsset,
        setAdvertModal,
      },
    },
    {
      key: '2',
      label: `Instagram`,
      Component: SocialPost,
      props: {
        tab: 'instagram',
        refresh,
        title,
        setTitle,
        description,
        setDescription,
        upload,
        setUpload,
        selectedAsset,
        setAdvertModal,
      },
    },
    {
      key: '3',
      label: `Linkedin`,
      Component: SocialPost,
      props: {
        tab: 'linkedin',
        refresh,
        title,
        setTitle,
        description,
        setDescription,
        upload,
        setUpload,
        selectedAsset,
        setAdvertModal,
      },
    },
    {
      key: '4',
      label: `Twitter`,
      Component: SocialPost,
      props: {
        tab: 'twitter',
        refresh,
        title,
        setTitle,
        description,
        setDescription,
        upload,
        setUpload,
        selectedAsset,
        setAdvertModal,
      },
    },
    {
      key: '5',
      label: `Custom Template`,
      Component: CustomSocialPostTemplate,
      props: {
        tab: 'custom',
        refresh,
        title,
        setTitle,
        description,
        setDescription,
        upload,
        setUpload,
        selectedAsset,
        setAdvertModal,
      },
    },
  ];
  return (
    <div>
      <TabsComponent items={tabs} activeKey={key} setActiveTabKey={setActiveTabKey} />
    </div>
  );
};

export default Advertise;
