import React, { useState } from 'react';

import { CustomModal, FormContainer, SelectInput, Toast, Button } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { getAllocatedAssets, getAssets } from 'service/api-service';
import { prepareFormFieldsData } from 'utils';

const AllocateModal = ({ record, onGetAssets, btnClasses = '' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const addAllocationFields = {
    'Policy Details': {
      showAccordionHeader: false,
      fields: [
        {
          // label: `Available ${record?.asset_type[0]?.name}`,
          // placeholder: `Select ${record?.asset_type[0]?.name}`,
          label: 'Assets',
          placeholder: 'Assets',
          name: 'assets',
          id: 'assets',
          type: 'select',
          component: SelectInput,
          dependantFetchOptions: async (form) => {
            const resp = await getAssets();
            return resp ? resp?.data?.data?.result : [];
          },
          emptyOptions: `No ${record?.asset_type?.name} available`,
          required: true,
        },
      ],
    },
  };

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addAllocationFields,
  });
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onAllocateAsset = async (data) => {
    //TBD
    const asset = [];
    asset.push(data?.assets);
    const finalPayload = {
      // ...data,
      assets: asset,
      emp_id: record?.emp_id.id,
      asset_request: record?.id,
    };

    const resp = await getAllocatedAssets({ method: 'post', data: finalPayload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      handleCancel();
      onGetAssets();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };
  return (
    <div>
      <Button
        text={'Allocate'}
        type='button'
        onClick={showModal}
        disabled={record?.status === 'approved'}
        variant='primary'
        classes={`ml-6 px-6 !py-1 border capitalize bg-white rounded-md !text-xxs !font-medium border-black !text-black hover:border-primary-90 hover:!text-primary-90 ${btnClasses}`}
      />

      {/* Allocation Modal */}
      <CustomModal title='' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={''}>
        <FormContainer
          accordionClass='grid gap-8'
          onSubmit={onAllocateAsset}
          initialValues={initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    handleCancel();
                  }}
                />
                <Button
                  disabled={!isValid}
                  text={'Allocate'}
                  variant='primary'
                  classes={`ml-6 px-8 bg-black`}
                  onClick={submitForm}
                  loading={isSubmitting}
                />
              </div>
            );
          }}
        />
      </CustomModal>
    </div>
  );
};

export default AllocateModal;
