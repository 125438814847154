import React, { useState, useCallback, useEffect } from 'react';
import { List, Avatar } from 'antd';
import { Button, InputNumber, Divider, Upload } from 'antd';
import { DELETE, View, DocumentIcon } from 'assets/icons';
import { coverageOptions, priceFormatter, percentFormatter } from './config';
import { LabelValue, CoverageComponent } from './components';
import { PlusOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Toast, IconButton } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { Link } from 'react-router-dom';
import { usePrompt } from 'components/stop-navigation';
import { deleteUploadDocument, postUploadDocument } from 'service/api-service';

export const InforCard = ({ insurer, onUpdate, inquiry, handleSave, coverageOption, value }) => {
  const [values, setValue] = useState({});
  const [defaultSelected, setDefaultSelected] = useState([]);
  // const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileName, setFileName] = useState(false);
  const [docPayload, setDocPayload] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [showloading, setShowloading] = useState(false);
  const [documents, setDocument] = useState({});
  const [deleteId, setDeleteId] = useState();

  // const [changedData, setChangedData] = useState();
  /* funcs */
  usePrompt('Leave screen?', true);

  // const handleBeforeUnload = (event) => {
  //   if (!changedData?.length) {
  //     event.preventDefault();
  //     event.returnValue = ""; // This is necessary to show the confirmation dialog in some browsers
  //     console.info("first", event);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const resetForm = () => {
    setDocPayload({});
    setFileList(null);
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };
  const handleDeleteDocument = async () => {
    setShowloading(true);

    const resp = await deleteUploadDocument({ id: deleteId });
    if (resp?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Document Deleted Successfully');
      setDocument({
        document: {},
      });
      setValue({
        ...values,
        document: {},
      });
      setDeleteId(null);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setShowloading(false);
    toggleDeleteModal();
  };

  const uploadInfoDocument = async () => {
    setLoading(true);
    const resp = await postUploadDocument({ data: docPayload });
    if (resp?.status) {
      // onUpdate(insurer?.id, values);
      // handleSave();
      Toast('success', NOTIFICATION_MSG.success, 'Document Uploaded');
      setDocument({
        document: {
          doc: resp?.data?.data?.image,
          id: resp?.data?.data?.id,
        },
      });
      setValue({
        ...values,
        document: {
          name: fileName,
          doc: resp?.data?.data?.image,
          id: resp?.data?.data?.id,
        },
      });
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
    resetForm();
    toggleModal();
  };

  const onChange = useCallback(
    (key, subKey) => (value) => {
      // setChangedData(value); // To show unsaved changes popup
      if (subKey) {
        setValue((v) => {
          if (!v[key]) v[key] = {};
          v[key][subKey] = value;
          return { ...v };
        });
      } else {
        setValue((v) => ({ ...v, [key]: value }));
      }
    },
    [],
  );

  const beforeUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      // setData(target?.result);
      const image = file.name + ',' + target.result;
      const payload = {
        name: file.name,
        docs: image,
      };
      setFileName(file.name);
      setDocPayload(payload);
    };
    reader.readAsDataURL(file);
    return false;
  };

  const renderCoverage = useCallback(
    (label, idx) => {
      return (
        <LabelValue
          key={idx}
          label={label}
          w={64}
          w2={32}
          value={
            <InputNumber
              defaultValue={0}
              formatter={priceFormatter}
              placeholder=''
              onChange={onChange('coverAmount', label)}
              className='w-full border-0 font-semibold border-b border-grey-40'
              mode='multiple'
              value={values?.coverAmount?.[label]}
            />
          }
        />
      );
    },
    [values],
  );

  useEffect(() => {
    if (onUpdate)
      onUpdate(insurer?.id, { ...values, product: value?.product, insurer: insurer?.name });
  }, [onUpdate, values]);

  useEffect(() => {
    if (inquiry && inquiry?.length) {
      const inq = inquiry?.find((i) => i.id === JSON.stringify(insurer?.id + value?.product?.id));
      if (inq) {
        setValue(inq);
        if (inq.coverAmount) setDefaultSelected(Object.keys(inq.coverAmount));
      }
    }
  }, [inquiry]);
  return (
    <>
      <div className='bg-white m-4 rounded-md shadow p-4'>
        <List className='border-b border-grey-40'>
          <List.Item
          // extra={
          //   insurer?.illustration_link && (
          //     <Link to={insurer?.illustration_link} target='_blank'>
          //       <Button type='primary' className='mr-4'>
          //         Calculate Premium
          //       </Button>
          //     </Link>
          //   )
          // }
          >
            <List.Item.Meta
              avatar={<Avatar src={process.env.REACT_APP_DOC_URL + insurer?.image} />}
              title={insurer?.name}
              className='px-2 font-semibold'
            />
            <p>{value?.product?.name}</p>
          </List.Item>
        </List>

        <div className='flex'>
          <div className='flex-1 border-r border-grey-40'>
            <div className='grid w-full grid-cols-3'>
              <LabelValue
                label='Premium'
                value={
                  <InputNumber
                    defaultValue={0}
                    formatter={priceFormatter}
                    options={coverageOptions}
                    placeholder=''
                    onChange={onChange('premium')}
                    className='w-full border-0 font-semibold border-b border-grey-40'
                    mode='multiple'
                    value={values.premium}
                  />
                }
                vertical
              />
              <LabelValue
                label='Sum Insured'
                value={
                  <InputNumber
                    defaultValue={0}
                    formatter={priceFormatter}
                    options={coverageOptions}
                    placeholder=''
                    onChange={onChange('sum')}
                    className='w-full border-0 font-semibold border-b border-grey-40'
                    mode='multiple'
                    value={values.sum}
                  />
                }
                vertical
              />
              <LabelValue
                label='NCB'
                value={
                  <InputNumber
                    defaultValue={0}
                    formatter={percentFormatter}
                    placeholder=''
                    onChange={onChange('ncb')}
                    className='w-full border-0 font-semibold border-b border-grey-40'
                    mode='multiple'
                    value={values.ncb}
                  />
                }
                vertical
              />
            </div>
            <div className='flex flex-row items-center pt-4'>
              {documents?.document?.doc || values?.document?.doc ? (
                <>
                  <div className='flex flex-row font-medium items-center gap-2 w-full'>
                    <a
                      href={documents?.document?.doc || values?.document?.doc}
                      target='_blank'
                      rel='noreferrer'
                      className='flex flex-row font-medium items-center gap-2 w-fit'
                    >
                      <DocumentIcon className='h-6 w-6 fill-primary-90 my-auto' />
                      Document Uploaded
                      <View svgClass='stroke-primary group-disabled:stroke-grey' />
                    </a>

                    <IconButton
                      title='Delete'
                      onClick={() => {
                        setDeleteId(documents?.document?.id || values?.document?.id);
                        toggleDeleteModal();
                      }}
                    >
                      <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                    </IconButton>
                  </div>
                </>
              ) : (
                <Button
                  className='w-fit bg-primary-90 text-white font-medium mt-2 !border-primary-90 hover:!text-white'
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  Attach Prospect
                </Button>
              )}
            </div>

            <Divider className='mb-0' />
            <div className='grid w-full grid-cols-1'>
              {(values?.cover || [])?.map(renderCoverage)}
            </div>
          </div>
          <div className='w-72'>
            <CoverageComponent
              onChange={onChange('cover')}
              coverageOption={coverageOption}
              defaultSelected={defaultSelected}
            />
          </div>
        </div>
      </div>
      <Modal destroyOnClose={true} open={showModal} onCancel={toggleModal} footer={''}>
        <div className='flex flex-col py-6'>
          <span className='text-l font-medium mx-auto'>Please select document</span>
          <LabelValue
            label='Document'
            value={
              <Upload
                showUploadList={true}
                beforeUpload={beforeUpload}
                accept={['.csv', '.pdf']}
                maxCount={1}
                onChange={handleChange}
                onRemove={(file) => {
                  setFileList(file);
                }}
                fileList={fileList}
                className='w-full categoryUploaderClass'
              >
                <div className='border border-dashed border-grey-90 rounded mt-2 h-16 w-full px-10 flex items-center justify-center'>
                  <PlusOutlined />
                  Upload
                </div>
              </Upload>
            }
            vertical
          />

          <div className='bg-white flex flex-row justify-end py-4 mt-6 mx-auto'>
            <Button
              className='border border-gray-90 text-black px-6'
              onClick={() => {
                resetForm();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button
              className={`ml-6 px-8 !text-white !border-black  ${loading ? 'bg-grey' : 'bg-black'}`}
              onClick={uploadInfoDocument}
              loading={loading}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <Modal destroyOnClose={true} open={showDeleteModal} onCancel={toggleDeleteModal} footer={''}>
        <div className='flex flex-col py-6'>
          <span className='text-l font-medium mx-auto text-center'>
            Are you aure you want to delete the document ?
          </span>
          <div className='bg-white flex flex-row justify-end py-1 mt-6 mx-auto'>
            <Button
              className='border border-gray-90 text-black px-6'
              onClick={() => {
                toggleDeleteModal();
              }}
            >
              Cancel
            </Button>
            <Button
              className={`ml-6 px-8 !text-white !border-black  ${loading ? 'bg-grey' : 'bg-black'}`}
              onClick={async () => {
                await handleDeleteDocument();
                await handleSave();
              }}
              loading={showloading}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      {/* <RouterPrompt
        // when={changedData.length > 0}
        when={true}
        alert={() => true}
        // classes={classes}
        title="Do you want to Save your work before exiting?"
        // handleSave={handleSave}
      /> */}
    </>
  );
};
