import { TimePicker, SearchAPI, DateTime, Toast } from 'components';
import { Select, Tooltip } from 'antd';
import { DropdownIcon } from 'assets/icons';
import { NOTIFICATION_MSG } from 'constants';
import { updateAttendanceStatus } from 'service/api-service';

let STATUS = [
  { value: 'present', label: 'Present' },
  { value: 'absent', label: 'Absent' },
  { value: 'leave', label: 'Leave' },
];

const addAttendanceFields = {
  attendance: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Select Date',
        name: 'date',
        id: 'date',
        type: 'date',
        component: DateTime,
        showTime: false,
        picker: 'date',
        divClasses: 'col-span-full',
      },
      {
        label: 'Employee',
        name: 'employee_id',
        id: 'employee_id',
        placeholder: 'Employee Name',
        type: 'search',
        required: true,
        component: SearchAPI,
        divClasses: 'col-span-full',
      },
      {
        label: 'CheckIn Time',
        name: 'check_in',
        id: 'check_in',
        placeholder: 'Select Time',
        type: 'date',
        // required: true,
        component: TimePicker,
        divClasses: 'col-span-full',
      },
      {
        label: 'CheckOut Time',
        name: 'check_out',
        id: 'check_out',
        placeholder: 'Select Time',
        type: 'date',
        component: TimePicker,
        divClasses: 'col-span-full',
      },
    ],
  },
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Attendance',
    path: '',
  },
];

const columns = [
  {
    key: 'user',
    dataIndex: 'user',
    title: 'Employee',
    render: (value) => {
      return (
        <Tooltip title={value?.name} placement={'top'}>
          <div className='flex items-center pr-3 '>
            <img
              src='https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745'
              className='h-6 w-6 mr-2'
              alt='employee'
            />
            <span className='block  truncate'>{value?.name || '\u2014'}</span>
          </div>
        </Tooltip>
      );
    },
  },
  {
    key: 'check_in',
    title: 'Check In',
    dataIndex: 'check_in',
    render: (value) => <span>{value || '-'}</span>,
  },
  {
    key: 'check_out',
    title: 'Check Out',
    dataIndex: 'check_out',
    render: (value) => <span>{value || '-'}</span>,
  },
  {
    key: 'overtime',
    title: 'Over Time',
    dataIndex: 'overtime',
    render: (value) => <span>{value || '-'}</span>,
  },
  {
    key: 'late',
    title: 'Late Hour',
    dataIndex: 'late',
    render: (value) => <span>{(value / 3600).toFixed(2) + ' Hr' || '-'}</span>,
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: (value, record) => {
      return (
        <Select
          defaultValue={value}
          className='uppercase w-max !border-0 '
          popupClassName=''
          options={STATUS}
          suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
          onSelect={async (status) => {
            const data = { id: record?.id, status };
            try {
              const res = await updateAttendanceStatus({ data });
              if (res?.status) {
                Toast('success', NOTIFICATION_MSG.success, res?.data?.msg);
              } else {
                Toast('error', NOTIFICATION_MSG.error, res?.error || 'Please try again');
              }
            } catch (error) {
              Toast('error', NOTIFICATION_MSG.error, 'Please try again');
            }
          }}
        />
      );
    },
  },
];

export { addAttendanceFields, breadcrumbObj, columns };
