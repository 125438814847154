import { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { Layout, List, Button, DatePicker, Tabs, Select, Modal, Form, Input, Spin } from 'antd';
import { MinusCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import dayjs from 'dayjs';
import { IconButton, Toast } from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { elementData } from 'elements';
import { CircleSvg, DropdownIcon } from 'assets/icons';
import { SecureComponent } from 'permission-provider';
import { Context } from 'store/context';
import { TimerContext, formatTime } from 'store/timer';
import { ChangeSeconds } from 'utils';
import { deleteDailyGoal, getDailyGoal, postDailyGoal, updateDailyGoal } from 'service/api-service';

// const yesterdayDate = dayjs().subtract(1, "day");

const { Option } = Select;

Chart.register(CategoryScale);

const { Sider, Content, Header } = Layout;

const ETA_OPTIONS = [
  {
    value: 15,
    label: '15 minutes',
  },
  {
    value: 30,
    label: '30 minutes',
  },
  {
    value: 60,
    label: '1 hour',
  },
  {
    value: 120,
    label: '2 hours',
  },
  {
    value: 180,
    label: '3 hours',
  },
  {
    value: 240,
    label: '4 hours',
  },
  {
    value: 300,
    label: '5 hour',
  },
  {
    value: 360,
    label: '6 hours',
  },
  {
    value: 420,
    label: '7 hours',
  },
  {
    value: 480,
    label: '8 hours',
  },
];

const statuses = [
  {
    label: 'To Do',
    value: 'todo',
  },
  {
    label: 'On Going',
    value: 'on_going',
  },
  {
    label: 'Pause',
    value: 'pause',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
];

const today = dayjs();

const disableFuture = (date) => date.isAfter(today);

const DailyGoals = (props = {}) => {
  const { seconds, setStart, setSeconds } = useContext(TimerContext);
  const permissions = props?.permissions || [];
  /*states */
  const { user } = useContext(Context);
  const [goals, setGoals] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [showForm, setShowForm] = useState();
  const [selected, setSelected] = useState();
  const [eta, setETA] = useState();
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    labels: ['To Do', 'On Going', 'Completed'],
    datasets: [],
  });

  const [form] = Form.useForm();
  const EtaTime = formatTime(seconds);

  const save = (goals) => {
    setTimeout(() => {
      const key = dayjs().format('DD-MM-YYYY');
      let _goals = localStorage.getItem('daily-goals') || '{}';
      _goals = JSON.parse(_goals);
      _goals[key] = goals;
      localStorage.setItem('daily-goals', JSON.stringify(_goals));
    }, 1000);
  };

  const add = useCallback(
    async (data) => {
      const payload = {
        date: dayjs().format('YYYY-MM-DD'),
        eta: data?.eta,
        task: data?.task,
      };
      const resp = await postDailyGoal({ data: payload });
      if (resp?.data?.status) {
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Goals Created Successfully');
        fetchData();
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      form.resetFields();
      setShowForm(false);
    },
    [form],
  );

  const remove = useCallback(
    (id) => () => {
      Modal.confirm({
        title: 'Remove Goal',
        onOk: async () => {
          if (!id) return;
          setLoading(true);
          const resp = await deleteDailyGoal({ id });
          if (resp?.data?.status) {
            Toast(
              'success',
              NOTIFICATION_MSG.success,
              resp?.data?.msg || 'Daily goals deleted successfully',
            );
            fetchData();
          } else {
            Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
          }
          setLoading(false);
        },
        okText: 'Yes',
      });
    },
    [save],
  );

  const edit = useCallback(
    async (goal) => {
      const payload = { task: goal?.task, eta: goal?.eta };
      setLoading(true);
      const resp = await updateDailyGoal({
        id: goal?.id,
        data: payload,
      });
      if (resp?.data?.status) {
        fetchData();
        Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
      form.resetFields();
      setShowForm(false);
    },
    [save],
  );

  const openForm = useCallback(() => {
    setShowForm(true);
  }, []);

  const closeForm = useCallback(() => {
    setShowForm(false);
    setSelected();
  }, []);

  const editGoal = useCallback(
    (goal) => () => {
      form.setFieldsValue(goal);
      setSelected(goal);
      setShowForm(true);
    },
    [form],
  );

  const onFinish = useCallback(
    (data) => {
      if (selected) return edit(data);
      add(data);
    },
    [add, edit, selected],
  );

  const onStatusChange = useCallback(
    (goal) => async (value) => {
      setLoading(true);

      const check = goals?.filter((item) => item?.status === 'on_going');

      if (value === 'on_going' && check.length > 0) {
        Toast('info', NOTIFICATION_MSG.info, 'Another Task is Going On');
      }
      const payload = { status: value };
      const pausepayload = { status: value, time_spent: EtaTime };
      if (value !== 'on_going') {
        if (value === 'todo' || value === 'completed') {
          setSeconds(0);
        }
        const resp = await updateDailyGoal({
          id: goal?.id,
          data: value !== 'pause' ? payload : pausepayload,
        });
        if (resp?.data?.status) {
          fetchData();
          Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        } else {
          Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
        }
      }

      if (value === 'on_going' && check.length === 0) {
        // const oldTime = goal?.time_spent === null ? 0 : goal?.time_spent;
        // const restartTime = ChangeSeconds(oldTime);
        setSeconds(goal?.time_spent === null ? 0 : ChangeSeconds(goal?.time_spent));

        const resp = await updateDailyGoal({
          id: goal?.id,
          data: payload,
        });
        if (resp?.data?.status) {
          fetchData();
          Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
        } else {
          Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
        }

        setStart(true);
      }

      if (value === 'pause') {
        setStart(false);
      } else if (value === 'completed') {
        setStart(false);
        setSeconds(0);
      } else if (value === 'todo') {
        setStart(false);
      }

      if (value === 'todo' || value === 'completed') {
        setSeconds(0);
      }

      setLoading(false);
    },
    [goals, EtaTime],
  );

  const onDateChange = useCallback(async (date) => {
    const key = date.format('YYYY-MM-DD');
    setLoading(true);
    const resp = await getDailyGoal({ date: key });
    if (resp?.data?.status) {
      setGoals(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    setChartData((ch) => ({
      ...ch,
      datasets: [
        {
          data: [
            goals.filter((i) => i.status == 'todo').length,
            goals.filter((i) => i.status == 'on_going').length,
            goals.filter((i) => i.status == 'completed').length,
          ],
          backgroundColor: ['#b9b6a3bf', '#e1d577bf', '#8ad97cbf'],
        },
      ],
    }));
  }, [goals]);

  const handleSelect = useCallback(async (e) => {
    setLoading(true);
    const resp = await getDailyGoal({ date: dayjs().format('YYYY-MM-DD'), status: e });
    if (resp?.data?.status) {
      setGoals(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  }, []);

  const fetchData = async () => {
    setLoading(true);
    // const id = user?.user_type !== "client" ? user?.id : undefined;
    const resp = await getDailyGoal({ date: dayjs().format('YYYY-MM-DD') });
    if (resp?.data?.status) {
      setGoals(resp?.data?.data);
      setETA(resp?.data?.total_time);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const fecthHistory = async () => {
    setLoading(true);
    const resp = await getDailyGoal({
      date: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    });
    if (resp?.data?.status) {
      setGoals(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (activeTab != 1) {
      // setGoals([]);
      fecthHistory();
    } else {
      fetchData();
    }
  }, [activeTab]);

  // const saveCurrentTime = ()=>{
  //   const resp
  // }

  const onReloadPause = async (id, eta) => {
    console.log('saving time on reload...', eta);

    const payload = { status: 'pause', time_spent: eta };
    const resp = await updateDailyGoal({
      id: id,
      data: payload,
    });
    if (resp?.data?.status) {
      fetchData();
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  // useEffect(()=>{
  //   const onGoing = goals?.find((item) => item.status === "on_going");
  //   if(onGoing?.id){
  //     localStorage.setItem("onGoing", onGoing.id)
  //   }

  //   return () => {
  //     // if(localStorage.getItem("onGoing"))
  //     // onReloadPause(localStorage.getItem("onGoing"), localStorage.getItem("eta"));
  //     // localStorage.removeItem("onGoing")
  //     // localStorage.removeItem("eta")
  //   }
  // },[goals])

  // useEffect(() => {
  //   localStorage.setItem("eta", EtaTime)
  // }, [])

  const renderItem = useCallback(
    (item) => {
      return (
        <List.Item
          className='border-b border-grey-40'
          key={item.id}
          extra={
            <div>
              <Select
                className='w-32'
                defaultValue={1}
                options={statuses}
                onChange={onStatusChange(item)}
                value={item.status}
                disabled={activeTab != 1}
              />

              <IconButton
                permissions={permissions}
                className='disabled:cursor-not-allowed'
                id={
                  window.location.pathname.includes('daily')
                    ? elementData?.dailyGoals?.permissions?.update
                    : elementData?.goals_daily?.permissions?.update
                }
                onClick={editGoal(item)}
                disabled={activeTab != 1}
              >
                <Button
                  type='text'
                  className='ml-2 text-grey-50'
                  icon={<EditOutlined />}
                  // onClick={editGoal(item)}
                  disabled={activeTab != 1}
                ></Button>
              </IconButton>
              <IconButton
                permissions={permissions}
                className='disabled:cursor-not-allowed'
                id={
                  window.location.pathname.includes('daily')
                    ? elementData?.dailyGoals?.permissions?.delete
                    : elementData?.goals_daily?.permissions?.delete
                }
                onClick={remove(item.id)}
                disabled={activeTab != 1}
              >
                <Button
                  type='text'
                  className='ml-2 text-red'
                  icon={<MinusCircleOutlined />}
                  // onClick={remove(item.id)}
                  disabled={activeTab != 1}
                ></Button>
              </IconButton>
            </div>
          }
        >
          <List.Item.Meta title={<div className='break-words'>{item.task}</div>} />
          <List.Item.Meta
            title={` ${
              item.status === 'on_going'
                ? EtaTime
                : item?.status === 'pause'
                ? item?.time_spent
                : '00:00:00'
            }
              of  ${ETA_OPTIONS.filter((val) => val?.value === item?.eta)[0]?.label}`}
          />
        </List.Item>
      );
    },
    [activeTab, onStatusChange, remove, editGoal],
  );

  const tabs = useMemo(() => {
    return [
      {
        label: "Today's Goals",
        key: '1',
        children: loading ? (
          <div className='flex items-center justify-center h-64'>
            <Spin />
          </div>
        ) : (
          <List dataSource={goals} renderItem={renderItem} className='p-2 bg-white' />
        ),
      },
      {
        label: 'History',
        key: '2',
        children: loading ? (
          <div className='flex items-center justify-center h-64'>
            <Spin />
          </div>
        ) : (
          <List dataSource={goals} renderItem={renderItem} className='p-2 bg-white' />
        ),
      },
    ];
  }, [goals, renderItem]);

  return (
    <Layout className='bg-white h-full'>
      <Content>
        <Header className='bg-white border-b border-grey-40 flex justify-between items-center'>
          <span>Daily Goals</span>
          <div>Total ETA : {eta ? (eta < 60 ? eta + ' minutes' : eta / 60 + ' hour(s)') : 0} </div>
        </Header>
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={tabs}
          className='bg-white px-10'
          tabBarExtraContent={
            <div className='flex gap-4'>
              <Select
                onSelect={handleSelect}
                style={{ width: 100 }}
                placeholder='Select Status'
                dropdownAlign={{ offset: [-5, 15] }}
                suffixIcon={<DropdownIcon svgClass={'w-2 fill-grey-90'} />}
                className='min-w-[150px] activitySelect group !bg-[#F9F9F9] !text-grey-90 rounded px-2'
              >
                <Option value=''>All</Option>
                <Option value='todo'>To Do</Option>
                <Option value='on_going'>On Going</Option>
                <Option value='completed'>Completed</Option>
              </Select>

              {activeTab == 1 && (
                <IconButton
                  permissions={permissions}
                  id={
                    window.location.pathname.includes('daily')
                      ? elementData?.dailyGoals?.permissions?.create
                      : elementData?.goals_daily?.permissions?.create
                  }
                  onClick={openForm}
                >
                  Create New +
                </IconButton>
              )}
            </div>
          }
        />
      </Content>
      <Sider width={300} className='h-full' color='white'>
        <Header className='bg-white  border-b border-grey-40'>
          {activeTab !== 1 && (
            <DatePicker
              defaultValue={dayjs().subtract(1, 'day')}
              onChange={onDateChange}
              disabledDate={disableFuture}
            />
          )}
        </Header>
        <div className='p-4 border-l border-grey-40 flex-1'>
          <Pie
            width={250}
            data={chartData}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
          {goals.length > 0 && (
            <div className='grid grid-cols-2 text-grey-90 text-xxs font-medium gap-3 pt-4 '>
              <div className='flex flex-row items-center gap-1'>
                <CircleSvg className='w-3 fill-[#b9b6a3bf]' />
                To Do:
                <span>{chartData?.datasets[0]?.data[0]}</span>
              </div>
              <div className='flex flex-row items-center gap-1'>
                <CircleSvg className='w-3 fill-[#e1d577bf]' />
                On Going:<span>{chartData?.datasets[0]?.data[1]}</span>
              </div>
              <div className='flex flex-row items-center gap-1'>
                <CircleSvg className='w-3 fill-[#8ad97cbf]' />
                Completed: <span>{chartData?.datasets[0]?.data[2]}</span>
              </div>
            </div>
          )}
        </div>
      </Sider>
      <Modal
        open={showForm}
        title={selected ? 'Edit Goal' : 'Create New Goal'}
        onCancel={closeForm}
        footer={null}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item name='task'>
            <Input placeholder='Enter Goal Title' maxLength={250} />
          </Form.Item>
          <Form.Item name='eta'>
            {/* <DatePicker picker={"time"} placeholder="ETA" format={"HH:mm"} /> */}
            <Select
              className='min-w-[150px] activitySelect group !bg-[#F9F9F9] !text-grey-90 rounded px-2'
              dropdownAlign={{ offset: [-8, 12] }}
              suffixIcon={<DropdownIcon svgClass={'w-2 group-hover:fill-primary-90'} />}
              placeholder='Select ETA'
              options={ETA_OPTIONS}
            />
          </Form.Item>
          <Form.Item noStyle name='id'>
            <Input type='hidden' />
          </Form.Item>
          <Form.Item noStyle name='status'>
            <Input type='hidden' />
          </Form.Item>
          <Form.Item className='flex justify-end'>
            <Button type='primary' htmlType='submit'>
              SAVE
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default SecureComponent(DailyGoals);
