/** external deps */
import React, { useState, useEffect } from 'react';
import { Space } from 'antd';
/** internal deps */
import { FormContainer, IconButton, Toast, Button, TableComponent, CustomModal } from 'components';
import { SecureComponent } from 'permission-provider';
import {
  getProductsByCategory,
  addProducts,
  editProducts,
  deleteProducts,
  getForm,
} from 'service/api-service';
import { prepareFormFieldsData, getEditedFields } from 'utils';
import { productColumns, productFields } from './data';
import { NOTIFICATION_MSG } from 'constants';
import { EDIT, DELETE, Trash } from 'assets/icons';
import formOptions from 'components/forms/formOptions';
import { rowCommonClass } from 'utils/common';

const AddProducts = ({ productinitialValues }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editInitials, setEditInitials] = useState();
  const [editMode, setEditMode] = useState(false);
  const [customFormData, setCustomFormData] = useState([]);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [productsData, setProductsData] = useState(productinitialValues?.product_type);
  const [addCoverage, setAddCoverage] = useState(false);
  const formOption = [
    {
      title: 'Static Form',
      value: 'static',
      children: formOptions,
    },
    {
      title: 'Custom Form',
      value: 'custom',
      children: customFormData,
    },
  ];

  const toggleDeleteModal = () => setDeleteModal((pre) => !pre);

  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: productFields({ formOption, addCoverage, setAddCoverage }),
  });

  /** funcs */
  const handleCancel = () => {
    setEditMode(false);
    setShowModal(false);
  };

  const getCategoryDetails = async () => {
    setLoading(true);
    const resp = await getProductsByCategory({
      ins_category: productinitialValues?.id,
    });
    if (resp?.data?.data) {
      setProductsData(resp?.data?.data);
    }
    setLoading(false);
  };

  const editProduct = async (updatedData) => {
    const data = getEditedFields({
      initialValues: { ...editInitials, ins_category: productinitialValues?.id },
      updatedData,
    });
    const resp = await editProducts({
      data: { ...data },
      id: editInitials?.id,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Product Type updated successfully');
      getCategoryDetails();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleViewModal();
    setEditInitials();
  };

  const onDeleteProductType = async () => {
    const id = selectedRow?.id;
    if (!id) return;
    const resp = await deleteProducts({ id });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      getCategoryDetails();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteModal();
  };

  const addProduct = async (data) => {
    const resp = await addProducts({
      data: { ...data, ins_category: productinitialValues?.id },
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Product Type added successfully');
      getCategoryDetails();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleViewModal();
  };

  const toggleViewModal = () => setShowModal((pre) => !pre);

  const onEditClick = (record) => {
    setEditMode(true);
    const payload = {
      ...record,
      formid: record?.formid?.name || record?.formid,
      product_code: record?.product_code ?? '',
      coverages: record?.coverages || [{ name: '', amount: '' }],
    };
    setEditInitials(payload);
    toggleViewModal();
  };

  /* funcs */
  const fetchData = async () => {
    setLoading(true);
    const resp = await getForm();
    if (resp?.data?.data) {
      const data = resp?.data?.data.map((i) => ({
        title: i.name,
        value: i.id,
      }));
      setCustomFormData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='grid gap-3 pb-10 bg-white p-4 rounded-2xl'>
      <div className='flex justify-between	pb-4'>
        <span className='text-m font-medium'>Product Type</span>
        <Button text='+ Product' onClick={toggleViewModal} />
      </div>
      <TableComponent
        loading={loading}
        getRowClassName={rowCommonClass}
        columns={[
          ...productColumns,
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
              <>
                <Space size='middle'>
                  <IconButton
                    title='Edit'
                    className='group disabled:cursor-not-allowed'
                    onClick={() => onEditClick(record)}
                  >
                    <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                  </IconButton>
                  <IconButton
                    title='Delete'
                    className='group disabled:cursor-not-allowed'
                    onClick={() => {
                      setSelectedRow(record);
                      toggleDeleteModal();
                    }}
                  >
                    <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                  </IconButton>
                </Space>
              </>
            ),
          },
        ]}
        dataSource={productsData}
        showPagination={false}
      />

      <CustomModal
        destroyOnClose={true}
        open={showModal}
        onCancel={() => {
          toggleViewModal();
          handleCancel();
        }}
        footer={''}
      >
        <FormContainer
          accordionClass='grid grid-cols-2 gap-8'
          onSubmit={editMode ? editProduct : addProduct}
          initialValues={editMode ? editInitials : initialValues}
          fields={finalFields}
          customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
            return (
              <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                <Button
                  text='Cancel'
                  variant='text'
                  type='reset'
                  classes='border border-gray-90 text-black px-6'
                  onClick={() => {
                    resetForm();
                    handleCancel();
                  }}
                />
                <Button
                  disabled={!isValid}
                  text={'Save'}
                  variant='primary'
                  classes={`ml-6 px-8 bg-black`}
                  onClick={submitForm}
                  loading={isSubmitting}
                />
              </div>
            );
          }}
        />
      </CustomModal>

      {/* delete class confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the product type?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              text='Delete Product Type'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={onDeleteProductType}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(AddProducts);
