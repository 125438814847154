import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { EDIT, TransactionIcon, View } from 'assets/icons';
import { IconButton, TableComponent, SearchComponent } from 'components';
import { elementData } from 'elements';
import { useNavigate } from 'react-router-dom';
import { policyColumns } from 'screens/policy/data';
import { policyManagement } from 'service/api-service';
import { rowCommonClass } from 'utils/common';

const VIewPolicy = ({ customer, permissions = [], type = 'customer', user }) => {
  const [policyData, setPolicyData] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);

  useEffect(() => {
    const init = async () => {
      if (type === 'customer') {
        const resp = await policyManagement({
          method: 'get',
          customer_id: customer.id,
          page: currentpage,
        });
        setPolicyData(resp?.data?.data?.result);
      } else if (type === 'pos') {
        const resp = await policyManagement({
          method: 'get',
          pos_id: user.id,
          page: currentpage,
        });
        setPolicyData(resp?.data?.data?.result);
      }
    };

    init();
  }, [currentpage]);

  const onChange = () => {
    // console.log('searching....');
  };
  const handlePagination = (page) => {
    if (page?.current) {
      setCurrentpage(page?.current);
    }
  };

  let navigate = useNavigate();
  const onEditPolicy = async (record) => {
    navigate('/add-policy', { state: { initialValues: record } });
  };
  return (
    <div>
      <div className='bg-white rounded-2xl rounded-b-none p-4 flex justify-between items-center mt-4'>
        <h2 className='font-semibold text-l'>Policy</h2>
        <div className='flex gap-4'>
          <SearchComponent onChange={onChange} />
        </div>
      </div>

      <TableComponent
        onChange={handlePagination}
        pageSize={3}
        getRowClassName={rowCommonClass}
        columns={[
          ...policyColumns.slice(0, 7),
          {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (_, record) => (
              <Space size='middle'>
                <IconButton
                  title='Transaction'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed border rounded-full p-1'
                  id={elementData.client.permissions.update}
                  onClick={() => {
                    navigate(`/transactions/${record?.id}`, {
                      state: { policyRecord: record },
                    });
                  }}
                >
                  <TransactionIcon svgClass={'w-4 h-4 stroke-primary group-disabled:stroke-grey'} />
                </IconButton>
                <IconButton
                  title='View'
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    navigate(`/view-policy/${record.id}`, {
                      state: { initialValues: record },
                    });
                  }}
                >
                  <View svgClass='stroke-primary group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Edit'
                  permissions={permissions}
                  className='group disabled:cursor-not-allowed'
                  id={elementData.client.permissions.update}
                  onClick={() => {
                    onEditPolicy(record);
                  }}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
              </Space>
            ),
          },
        ]}
        dataSource={policyData}
      />
    </div>
  );
};

export default VIewPolicy;
