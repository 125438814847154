/** external deps */
import React, { useContext, useEffect, useState } from 'react';
import { Select, Button, Drawer } from 'antd';

/** internal deps */
import { Toast, UpcomingActivity } from 'components';
import { SecureComponent } from 'permission-provider';
import { NOTIFICATION_MSG, RECENT_ACTIVITY } from 'constants';
import { Context } from 'store/context';
import AllLeads from './component/DashboardEntities/AllLeads';
import Statistics from './component/DashboardEntities/Statistics';
import { DropdownIcon, LightingBolt } from 'assets/icons';
import './style.css';
import LeadAlert from './component/LeadAlert';
import { employeeItems, hrDashboardItems, dashboardItems } from './data';
import { getTimeline } from 'service/api-service';
import PolicyRenewals from './component/DashboardEntities/PolicyRenewals';
import PolicySold from './component/DashboardEntities/PolicySold';
import LeadGraph from './component/DashboardEntities/LeadsGraph';
import ClientGraph from './component/DashboardEntities/ClientGraph';
import MyPerformance from './component/DashboardEntities/MyPerformance';
import AssignedLeads from './component/DashboardEntities/AssignedLeads';

const MyDashboard = (props = {}) => {
  const permissions = props?.permissions || [];

  const employeeItemsWithPerm = employeeItems(permissions);
  const hrItemsWithPerm = hrDashboardItems(permissions);
  const dashboardItemsWithPerm = dashboardItems(permissions);
  /** states */
  const [timeLine, setTimeLine] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(Context);
  const [selectedEntities, setSelectedEntities] = useState(
    user?.position === 'HR'
      ? hrItemsWithPerm
      : user?.position === 'Admin'
      ? dashboardItemsWithPerm
      : employeeItemsWithPerm,
  );

  const [open, setOpen] = useState(false);

  const [filterActivity, setFilterActivity] = useState([]);
  /* funcs */
  const handleSelect = (e) => {
    if (e === 'All') {
      setFilterActivity(timeLine);
    } else {
      const filter = timeLine.filter((item) => item.module === e);
      setFilterActivity(filter);
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setFilterActivity(timeLine);
  };

  useEffect(() => {
    const showEntities = JSON.parse(localStorage.getItem('matchingEntities'));
    if (showEntities && showEntities.length) {
      setSelectedEntities(showEntities);
    } else {
      setSelectedEntities(
        user?.position === 'HR'
          ? hrItemsWithPerm
          : user?.position === 'Admin'
          ? dashboardItemsWithPerm
          : employeeItemsWithPerm,
      );
    }
  }, [selectedEntities.length]);

  /* funcs  */
  const onGettimeline = async () => {
    setLoading(true);
    const resp = await getTimeline();
    if (resp?.data?.status) {
      setTimeLine(resp?.data?.data);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const setEntities = (value, key) => {
    localStorage.setItem('matchingEntities', JSON.stringify(key));
    setSelectedEntities(key);
  };

  useEffect(() => {
    if (!loading) {
      if (user?.position === 'Admin' || user?.position === 'HR') {
        setFilterActivity(timeLine);
      }
    }
  }, [loading]);

  useEffect(() => {
    onGettimeline();
  }, []);

  return (
    <>
      <div className='flex flex-row justify-end px-3'>
        {/* <div className='font-semibold text-l'>Overview</div> */}
        <div className='flex absolute -top-[54px] flex-row gap-2 items-center'>
          <Select
            placeholder='Select Fields...'
            maxTagCount='responsive'
            onChange={setEntities}
            options={
              user?.position === 'HR'
                ? hrItemsWithPerm
                : user?.position === 'Admin'
                ? dashboardItemsWithPerm
                : employeeItemsWithPerm
            }
            mode='multiple'
            defaultValue={
              user?.position === 'HR'
                ? hrItemsWithPerm?.map((item) => item.value)
                : user?.position === 'Admin'
                ? dashboardItemsWithPerm?.map((item) => item.value)
                : employeeItemsWithPerm?.map((item) => item.value)
            }
            className='border border-[#e9e8e8] rounded-md p-1 !w-64 dashboardSelectItems bg-white'
          />
          {(user?.position === 'Admin' || user?.position === 'HR') && (
            <>
              <Button onClick={showDrawer} className='flex items-center p-0 border-none'>
                {/* <img src={ActivityIcon} alt='green tick' className='w-10 h-10 rounded-md' /> */}
                <LightingBolt svgClass={'w-[33px] h-[33px] fill-primary-90'} />
              </Button>
              <Drawer
                title='Recent Activities'
                placement='right'
                onClose={onClose}
                destroyOnClose={true}
                open={open}
                width='550'
                extra={
                  <Select
                    disabled={!timeLine?.length}
                    defaultValue='All'
                    className='min-w-[150px] activitySelect group !bg-[#F9F9F9] !text-grey-90 rounded px-2'
                    popupClassName='min-w-24'
                    dropdownAlign={{ offset: [-5, 15] }}
                    suffixIcon={<DropdownIcon svgClass={'w-2 fill-grey-90'} />}
                    options={RECENT_ACTIVITY.filter((i) =>
                      i.displayFilter ? i.displayFilter.includes(user?.position) : true,
                    )}
                    onSelect={handleSelect}
                  />
                }
              >
                <UpcomingActivity
                  filterActivities={filterActivity}
                  noDataAction={onGettimeline}
                  loading={loading}
                  timeLine={timeLine}
                  heading={'Recent Activities'}
                  user={user}
                  activityClass='!h-full'
                />
              </Drawer>
            </>
          )}
        </div>
      </div>
      <div className='px-5 pt-2.5'>
        <LeadAlert />
      </div>
      <div className='grid gap-4'>
        <Statistics permissions={permissions} />
        <section className='grid grid-cols-1 gap-4 md:grid-cols-2'>
          {selectedEntities?.map((item, i) => {
            return (
              <div
                key={item?.value ?? i}
                className={`empty:hidden ${
                  user?.position === 'Admin' && item?.value === 'statistics'
                    ? ''
                    : 'p-4 border border-grey-40 bg-white rounded-lg'
                }  ${item.value === 'allLeads' ? 'col-span-full' : ''}
                ${item?.value === 'allLeads' && 'hidden md:flex'}
                `}
              >
                {item?.value === 'upcomingActivities' && (
                  <UpcomingActivity
                    noDataAction={onGettimeline}
                    loading={loading}
                    timeLine={timeLine}
                    heading={'Recent Activities'}
                    user={user}
                  />
                )}
                {item?.value === 'allLeads' && <AllLeads />}
                {item?.value === 'policiessold' && <PolicySold />}
                {item?.value === 'leadgraph' && <LeadGraph />}
                {item?.value === 'policyrenewals' && <PolicyRenewals Permissions={permissions} />}
                {item?.value === 'clientgraph' && <ClientGraph />}
                {item?.value === 'myPerformance' && <MyPerformance />}
                {item?.value === 'assignedLeads' && <AssignedLeads Permissions={permissions} />}
              </div>
            );
          })}
        </section>
      </div>
    </>
  );
};

export default SecureComponent(MyDashboard);
