import React, { useState } from 'react';

import { ContentHeader, TabsComponent } from 'components';
import { elementData } from 'elements';
import { getAllocatedAssets } from 'service/api-service';
import { getQueryParams, Searchable } from 'utils/common';
import { allocateBreadObj } from '../data';
import FilteredAssetRequest from './FilteredAssetRequest';

const AssetAllocationTab = (props = {}) => {
  const permissions = props?.permissions || [];
  const [key, setActiveTabKey] = useState('declined');
  const [showAllocateModal, setAllocateModal] = useState(false);
  const [employee, setEmployee] = useState('');
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { tab } = getQueryParams();

  const tabs = [
    {
      key: 'declined',
      label: `Requests`,
      Component: FilteredAssetRequest,
      id: elementData.assetAllocation.permissions.view,
      props: {
        showAllocateModal,
        setAllocateModal,
        permissions,
        tableData,
        setTableData,
        loading,
        setLoading,
        employee,
      },
    },
    {
      key: 'allocated',
      label: `Allocated`,
      Component: FilteredAssetRequest,
      id: elementData.assetAllocation.permissions.view,
      props: {
        showAllocateModal,
        setAllocateModal,
        permissions,
        tableData,
        setTableData,
        loading,
        setLoading,
        employee,
      },
    },
    {
      key: 'revoked',
      label: `Revoked`,
      Component: FilteredAssetRequest,
      id: elementData.assetAllocation.permissions.view,
      props: {
        showAllocateModal,
        setAllocateModal,
        permissions,
        tableData,
        setTableData,
        loading,
        setLoading,
        employee,
      },
    },
  ];

  /* func */
  const getByEmployee = async (id) => {
    setEmployee(id);
    setLoading(true);
    const status = tab === 'allocated' ? 'approved' : tab === 'revoked' ? 'declined' : 'pending';
    const resp = await getAllocatedAssets({ method: 'get', employee_id: id, status });
    if (resp?.data?.data) {
      setTableData(resp?.data?.data?.result);
    }
    setLoading(false);
  };

  const toggleAllocateAsset = () => {
    setAllocateModal((pre) => !pre);
  };

  return (
    <div className='grid gap-3 '>
      <ContentHeader
        title={<p className='font-semibold leading-normal text-s'>Asset Allocation</p>}
        // data={allocateBreadObj}
        buttonId={elementData.assetAllocation.permissions.create}
        permissions={permissions}
        btnText='+ Allocate Asset'
        onClick={toggleAllocateAsset}
        showSearch={false}
      >
        <Searchable
          type='search'
          placeholder='Search Employee'
          field={{ name: 'employee' }}
          divClasses='w-64'
          onChange={(e) => getByEmployee(e?.id)}
          allowClear={true}
          onIconClick={getByEmployee.bind(this, '')}
        />
      </ContentHeader>
      <TabsComponent
        items={tabs}
        activeKey={key}
        setActiveTabKey={setActiveTabKey}
        url={'/hr/assets-allocation?'}
      />
    </div>
  );
};

export default AssetAllocationTab;
