/** external deps */
import React, { useEffect, useState } from 'react';
/** internal deps */
import {
  CustomModal,
  FormContainer,
  TableComponent,
  Toast,
  Button,
  DocumentViewer,
} from 'components';
import { NOTIFICATION_MSG } from 'constants';
import { EmployeeDocument, customerDocs, getPosDoc, editPos } from 'service/api-service';
import { getColumns } from './data';
import './style.css';
import { rowCommonClass } from 'utils/common';

const AddDocument = ({ fields, initialValues, user_id, customer_id, type = '' }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState();
  const [tableData, setTableData] = useState();
  const [curActiveRecordDoc, setCurActiveRecordDoc] = useState(null);
  const isViewDocModalOpen = Boolean(curActiveRecordDoc);
  const handleCloseViewDocModal = () => setCurActiveRecordDoc(null);

  /* funcs */
  const toggleModal = () => setShowModal((pre) => !pre);

  /* get list of documents */
  const onGetDocuments = async () => {
    setLoading(true);
    const resp = await (type === 'employee'
      ? EmployeeDocument({ method: 'get', id: user_id })
      : type === 'pos'
      ? getPosDoc({ id: user_id })
      : customerDocs({ method: 'get', id: customer_id }));
    if (resp?.data?.status) {
      const list = type === 'pos' ? resp.data.data?.docs : resp.data.data;
      setTableData(list);
    } else {
      Toast(
        'error',
        NOTIFICATION_MSG.error,
        resp?.error || 'Failed to fetch Documenets from server',
      );
    }
    setLoading(false);
  };

  /* Add Document */
  const handleAddDocument = async (data) => {
    setLoading(true);
    const CustomerPayload = {
      ...data,
      customer_id,
    };
    const EmployeePayload = {
      ...data,
      user_id,
    };
    const PosPayload = {
      docs: [data],
    };
    const resp = await (type === 'employee'
      ? EmployeeDocument({ method: 'post', data: EmployeePayload })
      : type === 'pos'
      ? editPos({ id: user_id, data: PosPayload })
      : customerDocs({ method: 'post', data: CustomerPayload }));
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg || 'Document added Successful');
      setLoading(false);
      onGetDocuments();
      toggleModal();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Failed to add document.');
    }
    setLoading(false);
  };

  useEffect(() => {
    onGetDocuments();
  }, []);

  return (
    <section id='add-document'>
      <div className='flex justify-between items-center'>
        <h2 className='font-semibold text-s'>Documents</h2>
        <Button text='+ Documents' variant='primary' classes='xl:px-6' onClick={toggleModal} />
      </div>
      <TableComponent
        scroll={{
          y: 225,
        }}
        loading={loading}
        showHeader={false}
        showPagination={false}
        columns={getColumns(setCurActiveRecordDoc)}
        getRowClassName={rowCommonClass}
        dataSource={tableData}
      />
      <CustomModal
        title='Add Document'
        destroyOnClose={true}
        open={showModal}
        onCancel={toggleModal}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 '>
            To add a new document, fill the details in the input field below.
          </span>

          <FormContainer
            accordionClass='grid grid-cols-2 gap-8'
            fields={fields}
            initialValues={initialValues}
            onSubmit={handleAddDocument}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleModal();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    // permissions={permissions}
                    text={'Add Document'}
                    variant='primary'
                    classes={`ml-6 px-8   ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={isSubmitting}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>
      <DocumentViewer
        src={curActiveRecordDoc}
        onClose={handleCloseViewDocModal}
        visible={isViewDocModalOpen}
      />
    </section>
  );
};

export default AddDocument;
