/** external deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
/** internal deps */
import { Accordion, Button } from 'components';
import { createSchema, formatFormValues } from 'utils';

const CustomForm = ({
  disableForm = false,
  initialValues,
  fields,
  onSubmit,
  previousButton,
  previousButtonProps = {
    previousText: 'Previous',
  },
  submitButtonProps = {
    submitText: 'Submit',
    outerContainerStyle: {},
    buttonStyle: '',
  },
  innerRef,
  customSubmitRender,
  resetAfterSubmit = false,
  formClasses,
  accordionClass,
  returnFormatted = true,
}) => {
  const [formSchema, setSchema] = useState();
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);
  const generateSchema = useCallback(() => {
    let finalFields = [];
    Object.keys(fields).forEach((i) => {
      finalFields = [...finalFields, ...fields[i]?.fields];
    });
    return setSchema(createSchema(finalFields, initialValues));
  }, [fields, initialValues]);

  useEffect(() => {
    generateSchema();
  }, [fields, generateSchema]);

  return (
    <div>
      <Formik
        innerRef={innerRef}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={async (values, form) => {
          if (returnFormatted) {
            const formatted = formatFormValues(values);
            return await onSubmit(formatted);
            // return;
          } else {
            return await onSubmit(values);
            // return;
          }
        }}
        validationSchema={formSchema}
        validateOnBlur={true}
      >
        {(props) => {
          const { values, submitForm, isSubmitting, handleChange, isValid, resetForm, dirty } =
            props;

          return (
            <Form id='custom-form'>
              <div className={`grid grid-cols-1 gap-x-8 ${formClasses}`}>
                {Object.keys(fields).map((i, index) => {
                  //handling dependent fields(whole section hide/show)

                  const dependentOnValue = values[fields[i].dependent_on];
                  const dependentValue = fields[i].dependent_value;
                  // console.log("dependentValue",fields[i]?.fields);
                  const shouldntRender = Array.isArray(dependentValue)
                    ? !dependentValue?.includes(dependentOnValue)
                    : dependentValue !== dependentOnValue;
                  if (fields[i].dependent && shouldntRender) return null;

                  const customHeadRender = fields[i]?.customHeadRender;
                  const info = fields[i]['fields']?.map((i, index) => {
                    // const requiredOnValue = values[i.required_on ?? ''];
                    // const requiredValue = i.required_value;
                    // let shouldBeRequired = false;
                    // if( Array.isArray(requiredValue)) {
                    //   shouldBeRequired = requiredValue.includes(requiredOnValue);
                    // }
                    //   else if (typeof requiredOnValue === 'object' && requiredOnValue && 'name' in requiredOnValue) {
                    //     shouldBeRequired = requiredOnValue.name?.toLowerCase?.() === requiredValue;
                    //   }
                    //   else if(requiredValue === requiredOnValue) {
                    //     shouldBeRequired = true;
                    //   }
                    //handling dependent field(particular field hide/show) for select with multiple values

                    if (
                      i?.dependentArray &&
                      (i.reverseDependent
                        ? values[i?.dependent_on]?.includes(i?.dependent_value)
                        : !values[i?.dependent_on]?.includes(i?.dependent_value))
                    ) {
                      return <></>;
                    }
                    if (i?.hideField && i?.dependent && values[i?.dependent_on]) {
                      return (
                        <Field
                          key={index + JSON.stringify(values[i.dependent_on])}
                          disabled={disableForm || i?.disabled}
                          {...i}
                          onChange={handleChange}
                        />
                      );
                    }

                    if (i?.shouldHideField && i?.dependent) {
                      // shouldHideField should return boolean true=hide false=show dont send dependent_value in this case
                      const shouldHide = i?.shouldHideField({ formVals: values, field: i });

                      if (shouldHide) return <></>;
                    } else if (i?.reverseDependent) {
                      if (i?.dependent && values[i?.dependent_on] === i?.dependent_value) {
                        return <></>;
                      }
                    } else {
                      if (i?.dependent && values[i?.dependent_on] !== i?.dependent_value) {
                        return <></>;
                      }
                    }

                    if (i?.type === 'customComponent' && i?.Component) {
                      /**
                       * render custom component like field array etc.
                       * NOTE: We have to pass type='customComponent' and component as 'Component
                       *  */

                      const { Component } = i;
                      return <Component key={index} {...props} {...i} />;
                    }
                    return (
                      <Field
                        key={index}
                        disabled={disableForm || i?.disabled}
                        {...i}
                        // required={shouldBeRequired || i.required}
                        onChange={handleChange}
                      />
                    );
                  });

                  return (
                    <Accordion
                      showAccordionHeader={fields[i]?.showAccordionHeader}
                      headerToHeading={fields[i]?.headerToHeading}
                      customHeadRender={customHeadRender}
                      classes={accordionClass}
                      title={i}
                      info={info}
                      subTitle={fields[i]?.subTitle}
                      key={index}
                      customClasses={fields[i].customClasses}
                    />
                  );
                })}
              </div>
              {customSubmitRender ? (
                customSubmitRender({
                  isSubmitting,
                  goBack,
                  submitForm,
                  resetForm,
                  disableForm,
                  isValid,
                  dirty,
                  values,
                })
              ) : (
                <div className={`flex ${previousButton ? 'justify-around' : 'justify-center'}`}>
                  {!!previousButton && (
                    <Button
                      disabled={isSubmitting}
                      type='button'
                      onClick={goBack}
                      classes={`flex justify-center items-center rounded-full w-2/6 mt-16 h-10 p-9 py-3 text-xs self-center text-white text-thin bg-grey-80`}
                      text={previousButtonProps?.previousText}
                    />
                  )}

                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting || disableForm || !isValid}
                    type='button'
                    onClick={submitForm}
                    classes={`flex justify-center items-center rounded-full w-3/5 mt-16 h-10 p-9 py-3  text-xl md:text-xs self-center text-white text-thin ${
                      isSubmitting || !isValid ? 'bg-grey' : 'bg-black'
                    } ${previousButton ? 'w-2/5' : 'w-3/5'} ${submitButtonProps.buttonStyle}`}
                    text={submitButtonProps.submitText}
                  />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CustomForm;
