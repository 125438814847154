/** external deps */
import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import Fuse from 'fuse.js';
import { FileTextOutlined } from '@ant-design/icons';
/** internal deps */
import {
  Button,
  CustomModal,
  FormContainer,
  ContentHeader,
  TableComponent,
  IconButton,
  Toast,
  ViewData,
  Description,
  DocumentViewer,
} from 'components';
import { addAssetsFields, breadcrumbObj } from './data';
import { DELETE, EDIT, Trash, View } from 'assets/icons';
import { getEditedFields, prepareFormFieldsData } from 'utils';
import { SecureComponent } from 'permission-provider';
import { elementData } from 'elements';
import { getAssets } from 'service/api-service';
import { NOTIFICATION_MSG } from 'constants';
import { prepareViewData, rowCommonClass } from 'utils/common';

export const SECTION_DATA_VIEW = [
  {
    title: 'Basic Details',
    keys: [
      'asset_type',
      'name',
      'manufacturer',
      'purchase_from',
      'ser_no',
      'model',
      'amount',
      'warranty',
    ],
  },
  {
    title: 'Other Details',
    keys: ['purchase_date', 'condition', 'description', 'assignee'],
  },
];

const AllAssets = (props = {}) => {
  const permissions = props?.permissions || [];
  /* states  */
  const [pageNum, setPageNum] = useState(1);
  const [total, setTotal] = useState(0);
  const [showAddModal, setAddModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [editInitials, setInitials] = useState();
  const [selectedAsset, setSelectedAsset] = useState();
  const [selectedViewAsset, setSelectedViewAsset] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredAssets, setfilteredAssets] = useState([]);
  const [ShowViewModal, setViewModal] = useState(false);
  const [initialValues, finalFields] = prepareFormFieldsData({
    fields: addAssetsFields,
  });
  const [curActiveRecordDoc, setCurActiveRecordDoc] = useState(null);
  const isViewDocModalOpen = Boolean(curActiveRecordDoc);
  const handleCloseViewDocModal = () => setCurActiveRecordDoc(null);
  const columns = [
    {
      key: 'ser_no',
      title: 'Serial Number',
      dataIndex: 'ser_no',
    },
    {
      key: 'name',
      title: 'Asset Name',
      dataIndex: 'name',
    },
    {
      key: 'asset_type',
      title: 'Asset Type',
      dataIndex: 'asset_type',
      render: (data) => data?.name,
    },
    {
      key: 'purchase_date',
      title: 'Purchase Date',
      dataIndex: 'purchase_date',
    },
    {
      key: 'assignee',
      title: 'Assigned Person',
      dataIndex: 'assignee',
      render: (data) => data?.name || '\u2014',
    },
    {
      key: 'condition',
      title: 'Conditions',
      dataIndex: 'condition',
      render: (data) => data || '\u2014',
    },
    {
      key: 'warranty',
      title: 'Warranty',
      dataIndex: 'warranty',
      render: (data) => data || '\u2014',
    },
    {
      key: 'description',
      title: 'Notes',
      dataIndex: 'description',
      render: (data) => <Description content={data} label={false} />,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          <IconButton
            title='Bill'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.assets.permissions.view}
            onClick={() => onBillPress(record?.bills[0]?.bill)}
          >
            <FileTextOutlined className='border border-grey rounded-full p-1 w-6 h-6' />
          </IconButton>
          <IconButton
            title='View'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.assets.permissions.view}
            onClick={() => onViewPress(record)}
          >
            <View svgClass='stroke-primary group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Edit'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.assets.permissions.update}
            onClick={() => onEditPress(record)}
          >
            <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
          </IconButton>
          <IconButton
            title='Delete'
            permissions={permissions}
            className='group disabled:cursor-not-allowed'
            id={elementData.assets.permissions.delete}
            onClick={() => {
              setSelectedAsset(record);
              toggleDeleteAsset();
            }}
          >
            <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
          </IconButton>
        </Space>
      ),
    },
  ];

  /* funcs */

  const fetchData = async ({ page = 1 } = {}) => {
    setLoading(true);
    const resp = await getAssets({ method: 'get', page });
    if (resp?.data?.data) {
      setTableData(resp?.data?.data?.result);
      setPageNum(resp?.data?.data.current_page);
      setTotal(resp?.data?.data.total_page);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onAddAsset = async (data) => {
    setLoading(true);
    const resp = await getAssets({ method: 'post', data: data });

    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      toggleAddAsset();
      fetchData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onDeleteAsset = async () => {
    setLoading(true);
    const resp = await getAssets({ method: 'delete', id: selectedAsset?.id });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Asset deleted successfully');
      fetchData();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    toggleDeleteAsset();
    setLoading(false);
  };

  const onEditAsset = async (data) => {
    setLoading(true);
    const editFields = getEditedFields({ initialValues: editInitials, updatedData: data });
    if (Object.keys(editFields).length) {
      const resp = await getAssets({
        data: editFields,
        method: 'patch',
        id: selectedAsset?.id,
      });

      if (resp?.data?.status) {
        Toast(
          'success',
          NOTIFICATION_MSG.success,
          resp?.data?.msg || 'Asset updated successfully.',
        );
        fetchData();
      } else {
        Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
      }
      setLoading(false);
      toggleAddAsset();
    } else {
      setLoading(false);
      Toast('info', NOTIFICATION_MSG.info, 'Please edit any field to save.');
    }
  };

  const toggleAddAsset = () => {
    setInitials(undefined);
    setAddModal((pre) => !pre);
  };

  const toggleDeleteAsset = () => setDeleteModal((pre) => !pre);

  /* search function */
  const handleOnChange = (e) => {
    if (!e.target.value) setSearchQuery('');
    const query = e.target.value;
    setSearchQuery(query);
    const fuse = new Fuse(tableData, {
      keys: ['name', 'purchase_date', 'warranty', 'supplier', 'amount', 'status', 'assignee.name'],
      threshold: 0.2,
    }).search(e.target.value);
    setfilteredAssets(fuse.map((i) => i.item));
  };

  const onEditPress = (record) => {
    const initVals = { ...record, asset_type: record?.asset_type.name };
    setInitials(initVals);
    setSelectedAsset(record);
    setAddModal(true);
  };

  const onViewPress = (record) => {
    const data = prepareViewData({
      data: record,
      format: SECTION_DATA_VIEW,
      keys: {},
      allowToReturnObjects: true,
    });
    setSelectedViewAsset(data);
    setViewModal(true);
  };

  const ToggleViewModal = () => {
    setViewModal((prev) => !prev);
  };

  const onBillPress = (record) => {
    setCurActiveRecordDoc(record);
  };

  const handlePagination = (pageNum) => {
    if (pageNum?.current) {
      fetchData({ page: pageNum?.current });
    }
  };

  return (
    <div className='grid gap-3'>
      <ContentHeader
        permissions={permissions}
        onChange={handleOnChange}
        // data={breadcrumbObj}
        title={<p className='font-semibold text-s'>Assets</p>}
        btnText='+ Asset'
        buttonId={elementData?.assets?.permissions?.create}
        onClick={toggleAddAsset}
      />
      <TableComponent
        loading={loading}
        columns={columns}
        dataSource={searchQuery ? filteredAssets : tableData}
        total={total}
        onChange={handlePagination}
        defaultPage={pageNum}
        getRowClassName={rowCommonClass}
      />

      {/* delete asset confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteAsset}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the asset?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteAsset}
            />
            <Button
              text='Delete Asset'
              variant='primary'
              classes={`ml-6 px-8 bg-black`}
              onClick={onDeleteAsset}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>

      {/* add Asset modal */}
      <CustomModal
        title={editInitials ? 'Edit Asset' : 'Add Asset'}
        destroyOnClose={true}
        open={showAddModal}
        onCancel={toggleAddAsset}
        footer={''}
      >
        <div>
          <span className='text-xxs 2xl:text-sm pb-4 2xl:pb-10 '>
            {editInitials
              ? 'Edit Asset details'
              : 'To add a new asset, enter the details of the asset in the input field below.'}
          </span>

          <FormContainer
            accordionClass='grid grid-cols-3 gap-8'
            onSubmit={editInitials ? onEditAsset : onAddAsset}
            initialValues={editInitials || initialValues}
            fields={finalFields}
            customSubmitRender={({ isSubmitting, submitForm, isValid, resetForm }) => {
              return (
                <div className='bg-white flex flex-row justify-end py-4 mt-6'>
                  <Button
                    text='Cancel'
                    variant='text'
                    type='reset'
                    classes='border border-gray-90 text-black px-6'
                    onClick={() => {
                      resetForm();
                      toggleAddAsset();
                    }}
                  />
                  <Button
                    disabled={!isValid}
                    permissions={permissions}
                    id={
                      editInitials
                        ? elementData.assets.permissions.update
                        : elementData.assets.permissions.create
                    }
                    text={editInitials ? 'Update Asset' : 'Add Asset'}
                    variant='primary'
                    classes={`ml-6 px-8 ${isSubmitting ? 'bg-grey' : 'bg-black'}`}
                    onClick={submitForm}
                    loading={loading}
                  />
                </div>
              );
            }}
          />
        </div>
      </CustomModal>

      {/* View Modal */}
      <CustomModal
        open={ShowViewModal}
        closable={true}
        onCancel={ToggleViewModal}
        footer={''}
        width={650}
        title={'Asset Details'}
      >
        <div>
          <ViewData data={selectedViewAsset} className='grid-cols-3' />
        </div>
      </CustomModal>

      <DocumentViewer
        src={curActiveRecordDoc}
        onClose={handleCloseViewDocModal}
        visible={isViewDocModalOpen}
      />
    </div>
  );
};

export default SecureComponent(AllAssets);
