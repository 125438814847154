/** external deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Space } from 'antd';

/** internal deps */
import { DELETE, EDIT, Trash, View } from 'assets/icons';
import { ContentHeader, CustomModal, IconButton, TableComponent, Toast, Button } from 'components';
import { elementData } from 'elements';
import { deleteInsurer, getInsurerList } from 'service/api-service';
import { breadcrumbObj, columns } from './data';
import { NOTIFICATION_MSG } from 'constants';
import { SecureComponent } from 'permission-provider';
import { debounce } from 'utils';
import { rowCommonClass } from 'utils/common';

const Insurer = (props = {}) => {
  const navigate = useNavigate();
  const permissions = props?.permissions;

  /* states  */
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [insurer, setInsurer] = useState([]);
  const [total_page, setTotalPage] = useState();
  const [page, setPageNum] = useState(1);

  /** funcs */
  const toggleDeleteModal = () => setDeleteModal((pre) => !pre);

  const getInsurer = async ({ page = 1, query = '' }) => {
    setLoading(true);
    const resp = await getInsurerList({ page, query });
    if (resp?.data?.status) {
      const insurerData = resp?.data?.data?.result?.map((resp) => {
        return {
          ...resp?.address_id,
          ...resp,
        };
      });
      setInsurer(insurerData);
      setPageNum(resp?.data?.data?.current_page);
      setTotalPage(resp?.data?.data?.total_page);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error);
    }
    setLoading(false);
  };

  const handleDeleteInsurer = async () => {
    setLoading(true);
    const id = selectedRow?.id;
    if (!id) return;

    const resp = await deleteInsurer({
      id,
      data: { deleted: true },
    });

    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, 'Insurer Deleted successfully');
      getInsurer({ page });
      toggleDeleteModal();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
    setLoading(false);
  };

  const onEditClick = (record) => {
    const payload = {
      ...record,
      address: record?.address,
      city: record?.city,
      state: record?.state,
      country: record?.country,
      pincode: record?.pincode, //used when address is empty
    };
    navigate('/add-insurer', { state: { initialValues: payload } });
  };

  /* search function */
  const handleOnChange = debounce(async (e) => {
    const query = e.target.value;
    getInsurer({ query });
  }, 700);

  const handlePagination = (pageNum) => {
    if (pageNum?.current) getInsurer({ page: pageNum?.current });
  };
  useEffect(() => {
    getInsurer({});
  }, []);

  return (
    <div className='grid gap-3'>
      <ContentHeader
        showSearch={true}
        buttonId={elementData.insurer.permissions.create}
        permissions={permissions}
        // data={breadcrumbObj}
        title={<p className='font-semibold text-s'>Insurer</p>}
        btnText='+ Insurer'
        onClick={() => navigate('/add-insurer')}
        onChange={handleOnChange}
      />
      <TableComponent
        loading={loading}
        noDataAction={getInsurer}
        getRowClassName={rowCommonClass}
        columns={[
          ...columns,
          {
            title: 'Action',
            key: 'action',
            // align: 'right',
            render: (_, record) => (
              <div className='flex gap-2 '>
                <IconButton
                  title='View'
                  permissions={permissions}
                  id={elementData.insurer.permissions.view}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    navigate(`/view-insurer/${record.id}`, {
                      state: { initialValues: record },
                    });
                  }}
                >
                  <View svgClass='stroke-primary group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Edit'
                  permissions={permissions}
                  id={elementData.insurer.permissions.update}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => onEditClick(record)}
                >
                  <EDIT svgClass='stroke-green-90 group-disabled:stroke-grey' />
                </IconButton>
                <IconButton
                  title='Delete'
                  permissions={permissions}
                  id={elementData.insurer.permissions.delete}
                  className='group disabled:cursor-not-allowed'
                  onClick={() => {
                    setSelectedRow(record);
                    toggleDeleteModal();
                  }}
                >
                  <DELETE svgClass='stroke-red-90 group-disabled:stroke-grey' />
                </IconButton>
              </div>
            ),
          },
        ]}
        dataSource={insurer}
        pageSize={10}
        total={total_page}
        onChange={handlePagination}
        defaultPage={page}
      />

      {/* delete class confirm modal */}
      <CustomModal
        open={showDeleteModal}
        closable={true}
        onCancel={toggleDeleteModal}
        footer={''}
        width={450}
      >
        <div className='flex justify-center flex-col items-center py-4 2xl:py-[60px]  '>
          <Trash className='h-16 w-16' />
          <span className='text-l xl:text-xl'>Are you sure?</span>
          <span className='text-center text-grey-80 text-xs pb-3'>
            Could you please confirm that you really wish to delete the insurer details?
          </span>
          <div className='pt-4 flex flex-row justify-center bg-white'>
            <Button
              text='Cancel'
              variant='text'
              type='reset'
              classes='border border-gray-90 text-black px-6'
              onClick={toggleDeleteModal}
            />
            <Button
              permissions={permissions}
              id={elementData.insurer.permissions.delete}
              text='Delete Insurer'
              variant='primary'
              classes={`ml-6 px-8   
                 bg-black
                `}
              onClick={handleDeleteInsurer}
              loading={loading}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default SecureComponent(Insurer);
