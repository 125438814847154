/** external deps */
import React from 'react';
import { Skeleton } from 'antd';
import { DragDropContext } from 'react-beautiful-dnd';
import DragNDrop from '.';

const DragDropProvider = ({
  onDragEnd,
  sections = [],
  loading = false,
  onGetTasks,
  page,
  fetchMoreData,
  total_page,
  startDate,
  endDate,
}) => {
  if (loading)
    return (
      <div className='grid grid-flow-col gap-4 px-2 py-5 overflow-x-auto auto-cols-[20rem] bg-white'>
        <Skeleton className='bg-grey-light p-2 rounded-lg' paragraph={{ rows: 4 }}></Skeleton>
        <Skeleton className='bg-grey-light p-2 rounded-lg' paragraph={{ rows: 4 }}></Skeleton>
        <Skeleton className='bg-grey-light p-2 rounded-lg' paragraph={{ rows: 4 }}></Skeleton>
        <Skeleton className='bg-grey-light p-2 rounded-lg' paragraph={{ rows: 4 }}></Skeleton>
        <Skeleton className='bg-grey-light p-2 rounded-lg' paragraph={{ rows: 4 }}></Skeleton>
        <Skeleton className='bg-grey-light p-2 rounded-lg' paragraph={{ rows: 4 }}></Skeleton>
      </div>
    );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='bg-white'>
        <DragNDrop
          sections={sections}
          onGetTasks={onGetTasks}
          page={page}
          fetchMoreData={fetchMoreData}
          total_page={total_page}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </DragDropContext>
  );
};

export default DragDropProvider;
