import Request from 'service/api-service/client';

export const quotesManagement = async ({ employee_id = '', customer_id = '', page = 1 }) => {
  const resp = await Request({
    method: 'get',
    API: `quote/?employee_id=${employee_id || ''}&customer_id=${customer_id || ''}&page=${
      page || ''
    }`,
  });
  return resp;
};

export const addQuoteApi = async (data = '') => {
  return await Request({
    method: 'post',
    API: 'quote/',
    data,
  });
};

export const getQuoteApi = async ({ employee_id = '', customer_id = '', stage = '' }) => {
  return await Request({
    method: 'get',
    API: `quote/?employee_id=${employee_id || ''}&customer_id=${customer_id || ''}&stage=${stage}`,
  });
};
