import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Table, Button, Modal, Select, InputNumber } from 'antd';
import {
  PlusCircleOutlined,
  MinusCircleFilled,
  SaveOutlined,
  SendOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { inquiryManagement, sendEmail, getInsurerProduct } from 'service/api-service';
import { SearchAPI, Toast } from 'components';
// import PDFComponent from './pdf';
// import MailModal from 'components/mail-modal';
import { NOTIFICATION_MSG } from 'constants';
// import { Link } from 'react-router-dom';

const createHtml = (data, { company, customer, companyEmail, sender }) =>
  `<html>
    <style>
      table {
        width: 100%;
        border: 1px solid #eee;
      }
      thead {
        background: #ddd;
        font-weight: bold;
      }
      td {
        border: 1px solid #eee;
        padding: 5px;
      }
      th {
        border: 1px solid #eee;
        text-align: left;
        padding: 5px;
      }
    </style>
    <body>
      <p>Hello, ${customer}</p>
      <br>
      <p>Thank you for your interest in ${company}. In regards to your query, we are delighted to make you the following offers. You can
      directly buy any policy by simply click on BUT NOW link or you can contact with us on ${companyEmail}</p>
      <br>
      <h2>Quotation</h2>
      <br>
      <table>
        <thead>
          <tr>
            <th>Company</th>
            <th>Premium</th>
            <th>Coverage</th>
            <th>Coverage Amount</th>
            <th>Sum Insured</th>
            <th>NCB</th>
            <th></th>
          </tr>
        </thead>
      <tbody>
        ${data
          .map(
            (d) =>
              `<tr>
            <td>${d.insurer}</td>
            <td>${d.premium}</td>
            <td>${d.cover?.join('<br>')}</td>
            <td>${Object.keys(d.coverAmount || {})
              .map((key) => d.coverAmount[key])
              .join('<br>')}</td>
            <td>${d.sum}</td>
            <td>${d.ncb}</td>
            <td><a href="${d.id}">BUY</a></td>
          </tr>`,
          )
          .join(' ')}
      </tbody>
      </table>
      <br>
      <p>Please let us know if you require any further information. Please note that the Early Bird Discount is a limited time offer and will only be available until</p>
      <br>
      <p>We look forward to hearing from you.</p>
      <br>
      <p>Kind regards</p>
      <p>${sender}</p>
      <p>${company}</p>
    </body>
  </html>`;

const motorCoverage = [
  'All',
  'Third-Party Liability Only Cover',
  'Collision Damage',
  'Own Damage',
  'Personal Accident Cover',
  'Zero Depreciation Insurance',
  'Comprehensive Car Insurance',
];

const healthCoverage = [
  'Critical Illness',
  'Individual Health Insurance',
  'Family Floater Health Insurance',
  'Senior Citizen Health Insurance',
  'Group Health Insurance',
  'Personal Accident Cover',
  'Maternity Health Insurance',
  'Mediclaim Insurance',
  'Disease-specific (Corona Kavach, etc.)',
  'Hospital Daily Cash',
];

const lV = (v) => ({ label: v, value: v });

const coverageOptions = [...motorCoverage.map(lV), ...healthCoverage.map(lV)];

const _columns = (onChange, removeRow) => [
  {
    title: 'Insurer',
    key: 'insurer',
    render: (a, b, idx) => (
      <SearchAPI
        type='insurer'
        placeholder={'Select Insurer '}
        field={{ name: 'insurer' }}
        onSelect={() => {
          onChange('insurer', idx);
        }}
        defaultValue={{ label: a?.insurer, name: a?.insurer }}
      />
    ),
    width: 170,
  },
  {
    title: 'Coverage',
    key: 'cover',
    render: (a, b, idx) => (
      <Select
        options={coverageOptions}
        value={a?.cover}
        placeholder=''
        onChange={onChange('cover', idx)}
        className='w-full'
        mode='multiple'
      />
    ),
    width: 300,
  },
  {
    title: 'Coverage Amount',
    key: 'coverAmount',
    render: (a, b, idx) => (
      <>
        {(a.cover || []).map((cover) => (
          <div key={cover} className='flex items-center justify-between w-ful my-1'>
            <div>{cover}</div>
            <InputNumber
              defaultValue={0}
              value={a?.coverAmount?.[cover]}
              formatter={(value) => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              // parser={(value) => value && value.replace(/\₹\s?|(,*)/g, '')}
              placeholder='Add Cover Amount'
              onChange={onChange('coverAmount', idx, cover)}
            />
          </div>
        ))}
      </>
    ),
  },
  {
    title: 'Premium',
    key: 'premium',
    render: (a, b, idx) => (
      <InputNumber
        defaultValue={0}
        value={a?.premium}
        formatter={(value) => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        // parser={(value) => value && value.replace(/\₹\s?|(,*)/g, '')}
        placeholder='Add Premium'
        onChange={onChange('premium', idx)}
      />
    ),
    width: 120,
  },
  {
    title: 'Sum Insured',
    key: 'sum',
    render: (a, b, idx) => (
      <InputNumber
        defaultValue={0}
        value={a?.sum}
        formatter={(value) => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        // parser={(value) => value && value.replace(/\₹\s?|(,*)/g, '')}
        placeholder='Add Sum Insured'
        onChange={onChange('sum', idx)}
      />
    ),
    width: 120,
  },
  {
    title: 'NCB',
    key: 'ncb',
    render: (a, b, idx) => (
      <InputNumber
        defaultValue={0}
        value={a?.ncb}
        min={0}
        max={100}
        formatter={(value) => `${value}%`}
        // parser={(value) => value && value.replace('%', '')}
        placeholder='NCB'
        onChange={onChange('ncb', idx)}
      />
    ),
    width: 120,
  },
  {
    title: 'Action',
    width: '20px',
    key: 'action',
    render: (a, b, idx) => (
      <Button
        className='flex items-center justify-center'
        onClick={removeRow(idx)}
        danger
        icon={<MinusCircleFilled />}
      />
    ),
  },
  {
    title: 'Illustration Link',
    width: '20px',
    key: 'illustration_link',
    render: (a, b, idx) => (
      // <Link to="illustration_link" target="_blank">
      <Button className='flex items-center justify-center' danger>
        {' '}
        <p>Calculate</p>{' '}
      </Button>
      // </Link>
    ),
  },
  // {
  //   title: "Illutrator Link",
  //   key: "benefits",
  //   dataIndex: "illustrator",
  //   render: (illustrator) => (illustrator ? <a href={illustrator}>Visit</a> : null),
  // },
];

const QuotationTable = ({
  handleFormNavigation,
  formData,
  setFormData,
  data,
  inquiry_id,
  getInquiry,
  goBack,
  company,
  customer,
  companyEmail,
  sender,
}) => {
  const [rows, setRows] = useState([{}]);
  const [insurerId, setInsurerId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showMailModal, setShowMailModal] = useState(false);
  const [insuranceId, setInsuranceId] = useState('');
  const [options, setOptions] = useState([]);

  const [preview, setPreview] = useState();
  const [showPreview, setShowPreview] = useState();
  // const navigate = useNavigate();
  const handleClickMail = () => setShowMailModal(true);
  const handleCloseMailModal = () => {
    setShowMailModal(false);
  };
  const getProducts = async () => {
    const resp = await getInsurerProduct({ insurer_id: insuranceId });
    setOptions(resp?.data.data);
  };
  useEffect(() => {
    getProducts();
  }, [insuranceId]);

  useEffect(() => {
    if (data?.quote && data?.quote.length) setRows(data?.quote);
  }, [data]);

  const onChange = useCallback(
    (key, idx, nested) => (evt) => {
      setRows((r) => {
        if (nested) {
          if (!r[idx][key]) r[idx][key] = {};
          r[idx][key][nested] = evt?.target?.value || evt?.option?.name || evt;
        } else r[idx][key] = evt?.target?.value || evt?.option?.name || evt;

        setInsurerId(evt?.option);
        return [...r];
      });
    },
    [rows],
  );

  const addRow = useCallback(() => {
    setRows((rows) => [...rows, { id: rows.length + 1 }]);
  }, []);

  const removeRow = useCallback(
    (idx) => () => {
      setRows((rows) => rows.filter((i, id) => id !== idx));
    },
    [],
  );

  const columns = useMemo(() => {
    return _columns(onChange, removeRow);
  }, [onChange, removeRow]);

  const handleViewQuoteClick = () => {
    toggleViewModal();
  };

  const handleAddQuote = async () => {
    const finalPayload = {
      quote: rows,
      // id: state?.inquiryId,
      id: inquiry_id,
      status: 'quoted',
    };
    const resp = await inquiryManagement({
      method: 'patch',
      data: finalPayload,
      // id: state?.inquiryId,
      id: inquiry_id,
    });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
      getInquiry();
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  };

  const toggleViewModal = () => setShowModal((pre) => !pre);
  const sendToEmail = useCallback(async () => {
    const html = createHtml(rows, {
      company,
      customer,
      companyEmail,
      sender,
    });

    const payload = {
      to: data?.primary_email_address,
      subject: `Quotation for ` + data?.insurance_category?.name + ` Insurance`,
      body: html,
    };

    const resp = await sendEmail({ data: payload });
    if (resp?.data?.status) {
      Toast('success', NOTIFICATION_MSG.success, resp?.data?.msg);
    } else {
      Toast('error', NOTIFICATION_MSG.error, resp?.error || 'Please try again');
    }
  }, [rows]);

  const onPreview = useCallback(() => {
    const html = createHtml(rows, {
      company,
      customer,
      companyEmail,
      sender,
    });
    setPreview(html);
    setShowPreview(true);
  }, [rows]);

  const closePreview = useCallback(() => {
    setShowPreview(false);
  }, [rows]);

  return (
    <div className='pb-8'>
      <div className='my-4 flex justify-between'>
        <div className='text-l'>Enter Quotation Details</div>
        <div className='flex'>
          <Button
            className='flex items-center justify-center bg-black text-white ml-4'
            onClick={handleAddQuote}
            icon={<SaveOutlined />}
            size='large'
            // type="primary"
          >
            Save Quotation
          </Button>
          <Button
            className='flex items-center justify-center bg-black text-white ml-4'
            onClick={sendToEmail}
            icon={<SendOutlined />}
            size='large'
            // type="primary"
          >
            Send to Customer
          </Button>
          <Button
            className='flex items-center justify-center bg-black text-white ml-4'
            onClick={handleAddQuote}
            icon={<PrinterOutlined />}
            size='large'
            // type="primary"
          >
            Print
          </Button>
          <Button
            className='flex items-center justify-center bg-black text-white ml-4'
            onClick={onPreview}
            icon={<PrinterOutlined />}
            size='large'
            // type="primary"
          >
            Preview
          </Button>
          <Button
            className='flex items-center justify-center ml-4'
            onClick={addRow}
            icon={<PlusCircleOutlined />}
            size='large'
          >
            Add New Entry
          </Button>
        </div>
      </div>

      <Table columns={columns} dataSource={rows} pagination={false} />
      <Modal
        title='Quotation'
        width={window.innerWidth - 100}
        open={showPreview}
        onCancel={closePreview}
        footer={false}
      >
        <div className='p-4 borderded' dangerouslySetInnerHTML={{ __html: preview }}></div>
      </Modal>
    </div>
  );
};

export default QuotationTable;
