import React, { useContext, useEffect } from 'react';
import { List } from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { ContentHeader } from 'components';
import { Context } from 'store/context';
import { readNotifications } from 'service/api-service';
// import { getNotifications } from '@services/api';

export const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Notifications',
    path: '/notifications',
  },
];

const Notifications = () => {
  const {
    notifications: { list: notificationData, loading, updateNotifications },
  } = useContext(Context);

  const onReadNotifications = async () => {
    try {
      const resp = await readNotifications();
      // NOTE: IMPLEMENT THE BELOW BEHAVIOUR WHEN DO PAGINATIONS
      // console.log('resp===>', resp);
      // if (resp?.data && resp?.data?.status) {
      // }
      updateNotifications();
    } catch (error) {}
  };

  useEffect(() => {
    onReadNotifications();
  }, []);

  const renderNotifications = (item, index) => {
    return (
      <List.Item
        key={item.created}
        extra={
          <span className='text-xxs text-secondary-60 dark:text-secondary-40'>
            {dayjs(item.created).fromNow()}
          </span>
        }
        className={clsx(
          'border  mb-2 border-l-4 border-l-blue-600 rounded-br-lg rounded-tr-lg max-h-20',
          {
            'border-l-4 border-l-orange ': index % 2 === 0,
            'border-l-4 border-l-green ': index % 3 === 0,
          },
        )}
      >
        <div className='font-semibold text-xs mb-2 text-secondary'>{item?.by?.name}</div>
        <span
          className='text-xs text-secondary-80 dark:text-secondary-50'
          dangerouslySetInnerHTML={{ __html: item?.content || '' }}
        ></span>
      </List.Item>
    );
  };

  return (
    <>
      <ContentHeader data={breadcrumbObj} showSearch={false} />
      <div className='bg-white h-screen p-4 mt-2 rounded-md'>
        <List
          itemLayout='vertical'
          size='large'
          loading={loading}
          dataSource={notificationData}
          renderItem={renderNotifications}
        />
      </div>
    </>
  );
};

export default Notifications;
