import React from 'react';

const Stepper = ({ steps, activeStep }) => {
  return (
    <>
      <div className='grid grid-cols-3 gap-2 pb-6'>
        {steps.map((step, index) => {
          return (
            <div className='flex items-center' key={index}>
              <div key={index} className='relative items-center gap-2'>
                <button
                  className={`cursor-default font-medium text-xs p-2 transition-all duration-500 ${
                    index + 1 === activeStep ? 'bg-primary-90 text-white' : 'bg-grey-70 text-grey '
                  } ${
                    index + 1 < activeStep ? '!border-primary !bg-primary-90' : ''
                  } grid h-8 w-8 place-content-center rounded-full `}
                >
                  {index + 1 < activeStep ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='w-4 stroke-white'
                      viewBox='0 0 12.422 8.893'
                    >
                      <path
                        id='Icon_feather-check'
                        data-name='Icon feather-check'
                        d='M16.3,9,9.219,16.082,6,12.863'
                        transform='translate(-4.939 -7.939)'
                        fill='none'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='1.5'
                      />
                    </svg>
                  ) : (
                    index + 1
                  )}
                </button>
              </div>
              {steps.length - 1 !== index ? (
                <div
                  className={`flex-auto border  ${
                    index + 1 < activeStep ? 'border-primary-90' : 'border-grey-70'
                  } w-full rounded-full transition duration-500 ease-in-out`}
                />
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Stepper;
