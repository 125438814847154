import { SelectInput, TextInput, Uploadbox } from 'components';
import DateRangePicker from 'components/form-components/DateRangePicker';

export const breadcrumbs = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Employees',
    path: '/employees',
  },
  {
    name: 'View Employee Details',
    path: '',
  },
];

export const SECTION_DATA_VIEW = [
  {
    title: 'Personal Details',
    keys: [
      'name',
      'contact',
      'email',
      'dep_id',
      'branches',
      'dob',
      'des_id',
      'ifsc_code',
      'account',
      'role_id',
      'report_to',
      'emp_code',
      'joining',
      'referred_by',
      'referred_id',
      'work_from',
    ],
  },
  {
    title: 'Additional Details',
    keys: ['address', 'country', 'state', 'city', 'pincode'],
  },
  {
    title: 'Temporary Address',
    keys: [
      'temp_address.address',
      'temp_address.country',
      'temp_address.state',
      'temp_address.city',
      'temp_address.pincode',
    ],
  },
];

export const formatOverViewEmployeeData = (data) => {
  const res = {
    _: [],
  };
  if (!data) return res;

  Object.entries(data).forEach(([key, val]) => {
    switch (key) {
      case 'role_id':
        res._.push({
          label: key,
          value: val,
          name: 'Role',
        });
        break;
      case 'name':
      case 'designation':
        res._.push({ label: key, value: val, name: key.replaceAll('_', ' ') });
        break;
      case 'email':
        res._.push({ label: key, value: val, name: key.replaceAll('_', ' '), email: true });
        break;
      case 'contact':
        res._.push({
          label: key,
          value: val,
          name: key.replaceAll('_', ' '),
          phone: val ? true : false,
        });
        break;
      case 'branch':
        res._.push({ label: key, value: val.join(', '), name: key.replaceAll('_', ' ') });
        break;
      default:
        break;
    }
  });
  return res;
};

export const addExperienceFields = {
  'Work details': {
    // showAccordionHeader: false,
    fields: [
      {
        label: 'Company Name',
        name: 'name',
        id: 'name',
        placeholder: 'Company Name',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Designation',
        name: 'designation',
        id: 'designation',
        placeholder: 'Designation',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Location',
        name: 'location',
        id: 'location',
        placeholder: 'Location',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 60,
      },
      {
        label: 'Job Type',
        name: 'job_type',
        id: 'job_type',
        placeholder: 'Select Job Type',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { label: 'Part time', value: 'part time' },
          { label: 'Full time', value: 'full time' },
        ],
      },
      // {
      //   label: "",
      //   name: "job_type",
      //   id: "job_type",
      //   placeholder: "Select Job Type",
      //   type: "select",
      //   required: true,
      //   component: SelectInput,
      // },
      // {
      //   label: "Start Date",
      //   name: "start_date",
      //   id: "start_date",
      //   placeholder: "MM-DD-YYYY",
      //   type: "date",
      //   required: true,
      //   showTime: false,
      //   component: DateTime,
      // },
      // {
      //   label: "Currently Working",
      //   name: "working",
      //   id: "working",
      //   type: "checkbox",
      //   component: Checkbox,
      //   checked: true,
      //   divClasses: "self-end",
      // },
      {
        label: 'Working Period',
        name: 'working_period',
        id: 'working_period',
        required: true,
        placeholder: 'MM-DD-YYYY',
        type: 'date',
        showTime: false,
        component: DateRangePicker,
        disableNext: true,
        monthDiffrence: true,
      },

      {
        label: 'Reason To Leave',
        name: 'reason',
        id: 'reason',
        placeholder: 'Reason To Leave',
        type: 'text',
        component: TextInput,
        required: true,
        isMultiline: true,
        maxLength: 300,
        divClasses: 'col-span-full',
      },
      {
        label: 'Description',
        name: 'description',
        id: 'description',
        placeholder: 'Description',
        type: 'text',
        component: TextInput,
        isMultiline: true,
        maxLength: 300,
        divClasses: 'col-span-full',
      },
    ],
  },
  Documents: {
    fields: [
      {
        label: 'Salary Slip',
        name: 'salary_slip',
        id: 'salary_slip',
        placeholder: 'Upload Salary Slip',
        component: Uploadbox,
        required: true,
        accept: ['.png, .jpeg, .jpg'],
      },
      {
        label: 'Relieving Letter',
        name: 'rel_letter',
        id: 'rel_letter',
        placeholder: 'Upload relieving Letter',
        required: true,
        component: Uploadbox,
        accept: ['.png, .jpeg, .jpg'],
      },
    ],
  },
};

export const workExperienceColumns = [
  {
    title: 'Company Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Designation',
    dataIndex: 'designation',
    key: 'designation',
  },
  {
    title: 'Job Type',
    dataIndex: 'job_type',
    key: 'job_type',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: 'start_date',
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    key: 'end_date',
    render: (text) => text || '\u2014',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];
