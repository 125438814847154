const taxes = [
  {
    tax_name: 'TAX 1',
    tax_percentage: '30%',
    status: 'Active',
  },
  {
    tax_name: 'TAX 2',
    tax_percentage: '20%',
    status: 'Inactive',
  },
  {
    tax_name: 'TAX 3',
    tax_percentage: '13%',
    status: 'Inactive',
  },
  {
    tax_name: 'TAX 4',
    tax_percentage: '3%',
    status: 'Active',
  },
];

export { taxes };
