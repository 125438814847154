import { TextInput, SelectInput, SearchAPI } from 'components';

const addProvidentFundFields = {
  providentFund: {
    showAccordionHeader: false,
    fields: [
      {
        label: 'Employee',
        name: 'employee',
        id: 'employee',
        placeholder: 'Employee Name',
        type: 'search',
        required: true,
        component: SearchAPI,
      },
      {
        label: 'Provident Fund Type',
        name: 'provident_fund_type',
        id: 'provident_fund_type',
        placeholder: 'Select Type',
        type: 'select',
        required: true,
        component: SelectInput,
        options: [
          { value: 'Fixed', label: 'Fixed Amount' },
          { value: 'Percentage', label: 'Percentage of Basic Salary' },
        ],
      },
      {
        label: 'Employee Share (Amount)',
        name: 'employee_share_amt',
        id: 'employee_share_amt',
        placeholder: 'Employee Share (Amount)',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Organization Share (Amount)',
        name: 'organization_share_amt',
        id: 'organization_share_amt',
        placeholder: 'Organization Share (Amount)',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Employee Share (%)',
        name: 'employee_share_percentage',
        id: 'employee_share_percentage',
        placeholder: 'Employee Share (%)',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Organization Share (%)',
        name: 'organization_share_percentage',
        id: 'organization_share_percentage',
        placeholder: 'Organization Share (%)',
        type: 'text',
        required: true,
        component: TextInput,
        maxLength: 30,
      },
      {
        label: 'Description',
        name: 'description',
        id: 'description',
        placeholder: 'Description',
        type: 'text',
        required: true,
        component: TextInput,
        isMultiline: true,
        rows: 5,
        divClasses: 'col-span-full',
      },
    ],
  },
};

const breadcrumbObj = [
  {
    name: 'Dashboard',
    path: '/',
  },
  {
    name: 'Provident Fund',
    path: '',
  },
];

export { addProvidentFundFields, breadcrumbObj };
